/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { formActions } from 'reducers/ticketGroupForm'
import { compose, bindActionCreators } from 'redux'
import { getTicketGroupInventoryState } from 'reducers/activityResale/ticketGroupInventory'
import { TicketGroupModal as TicketGroupModalComponent } from './TicketGroupModal'

const mapStateToProps = state => ({
  ...state.ticketGroupForm,
  ...getTicketGroupInventoryState(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({ ...formActions }, dispatch)

export const TicketGroupModal = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TicketGroupModalComponent)
