/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { UNIQUE_PHONE_NUMBER, PROGRAM } from 'constants/searchTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'

export const uniqPhoneNumberTableColumns = [
  {
    Header: 'Phone Number',
    accessor: 'number',
    id: buildSortPropertyByType('number', UNIQUE_PHONE_NUMBER)
  },
  {
    Header: 'Program Name',
    accessor: 'programName',
    id: buildSortPropertyByType('name', PROGRAM)
  }
]
