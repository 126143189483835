/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { DDDD_MMM_DD_YYYY_AT_TIME } from 'constants/date'
import moment from 'moment'
import get from 'lodash/get'
import { isUpdateAction } from '../ProgramHistoryContent/helpers'
import {
  ACTION_ACTIVATE,
  ACTION_APPROVE,
  ACTION_CANCELED,
  ACTION_CREATE,
  ACTION_DEACTIVATE,
  ACTION_DELETE,
  ACTION_UPDATE
} from '../ProgramHistoryContent/types'
import {
  ELIGIBILITY_DETAILS_EVENT,
  MARKETING_DETAILS_EVENT,
  PROGRAM_DETAILS_EVENT,
  PREBOOK_INFO_EVENT,
  SEASON_DETAILS_EVENT,
  SEASON_RATE_EVENT,
  EXCLUSIVE_ACTIVITY_EVENT,
  DELAYED_DEACTIVATION_EVENT,
  PROGRAM_STATUS_EVENT,
  PMS_CHANGE_EVENT,
  PROGRAM_DEACTIVATION_BY_SYSTEM_EVENT,
  SEASON_APP_EXCLUSION_BY_SYSTEM_EVENT,
  SMART_HOST_FEE_EVENT,
  SMART_HOST_MOBILE_FEE_EVENT,
  UNIT_ASSIGNMENT_EVENT,
  UNIT_UPDATE_EVENT,
  UNIT_SYSTEM_UPDATE_EVENT,
  UNIT_SYSTEM_ASSIGNMENT_EVENT
} from '../types'
import {
  PROGRAM_DETAILS_CLASS_NAME,
  PROGRAM_DETAILS_LABEL,
  ELIGIBILITY_DETAILS_CLASS_NAME,
  ELIGIBILITY_DETAILS_LABEL,
  MARKETING_DETAILS_CLASS_NAME,
  MARKETING_DETAILS_LABEL,
  SEASONS_UPDATED_LABEL,
  SEASONS_UPDATED_CLASS_NAME,
  SEASONS_DETAILS_CLASS_NAME,
  SEASONS_DETAILS_LABEL,
  SEASON_DETAILS_LABEL,
  ACTION_DELETED_LABEL,
  ACTION_UPDATED_LABEL,
  ACTION_ADDED_LABEL,
  NAME_SEASON_LABEL,
  EXCLUSIVE_ACTIVITY_LABEL,
  EXCLUSIVE_ACTIVITY_CLASS_NAME,
  DELAYED_DEACTIVATION_CLASS_NAME,
  PROGRAM_STATUS_CLASS_NAME,
  DELAYED_DEACTIVATION_SCHEDULED_LABEL,
  DELAYED_DEACTIVATION_UPDATED_LABEL,
  DELAYED_DEACTIVATION_CANCELED_LABEL,
  PROGRAM_ACTIVATED_STATUS_LABEL,
  PROGRAM_DEACTIVATED_STATUS_LABEL,
  PMS_CHANGE_CLASS_NAME,
  PMS_CHANGE_LABEL,
  PROGRAM_DEACTIVATED_LABEL,
  SEASON_DETAILS_UPDATED_LABEL,
  UNIT_ASSIGMENT_LABEL,
  UNIT_ASSIGMENT_CLASS_NAME,
  UNIT_UPDATE_LABEL,
  UNIT_UPDATE_CLASS_NAME,
  PROGRAM_APPROVED_STATUS_LABEL
} from './types'

export function getEventClassName(event) {
  switch (event) {
    case PROGRAM_DETAILS_EVENT:
      return PROGRAM_DETAILS_CLASS_NAME
    case ELIGIBILITY_DETAILS_EVENT:
      return ELIGIBILITY_DETAILS_CLASS_NAME
    case MARKETING_DETAILS_EVENT:
      return MARKETING_DETAILS_CLASS_NAME
    case PREBOOK_INFO_EVENT:
    case SEASON_RATE_EVENT:
    case SMART_HOST_FEE_EVENT:
    case SMART_HOST_MOBILE_FEE_EVENT:
      return SEASONS_UPDATED_CLASS_NAME
    case SEASON_DETAILS_EVENT:
    case SEASON_APP_EXCLUSION_BY_SYSTEM_EVENT:
      return SEASONS_DETAILS_CLASS_NAME
    case EXCLUSIVE_ACTIVITY_EVENT:
      return EXCLUSIVE_ACTIVITY_CLASS_NAME
    case DELAYED_DEACTIVATION_EVENT:
    case PROGRAM_DEACTIVATION_BY_SYSTEM_EVENT:
      return DELAYED_DEACTIVATION_CLASS_NAME
    case PROGRAM_STATUS_EVENT:
      return PROGRAM_STATUS_CLASS_NAME
    case PMS_CHANGE_EVENT:
      return PMS_CHANGE_CLASS_NAME
    case UNIT_ASSIGNMENT_EVENT:
      return UNIT_ASSIGMENT_CLASS_NAME
    case UNIT_UPDATE_EVENT:
      return UNIT_UPDATE_CLASS_NAME
    default:
      return ''
  }
}

export function getSeasonLabel(action) {
  switch (action) {
    case ACTION_UPDATE:
      return SEASONS_DETAILS_LABEL
    case ACTION_CREATE:
    case ACTION_DELETE:
    default:
      return SEASON_DETAILS_LABEL
  }
}

export function getDelayedDeactivationLabel(details) {
  const { action } = details

  switch (action) {
    case ACTION_CREATE:
      return DELAYED_DEACTIVATION_SCHEDULED_LABEL
    case ACTION_UPDATE:
      return DELAYED_DEACTIVATION_UPDATED_LABEL
    case ACTION_CANCELED:
    default:
      return DELAYED_DEACTIVATION_CANCELED_LABEL
  }
}

export function getProgramStatusLabel(details) {
  const { action } = details

  switch (action) {
    case ACTION_APPROVE:
      return PROGRAM_APPROVED_STATUS_LABEL
    case ACTION_ACTIVATE:
      return PROGRAM_ACTIVATED_STATUS_LABEL
    case ACTION_DEACTIVATE:
    default:
      return PROGRAM_DEACTIVATED_STATUS_LABEL
  }
}

export function getEventLabel(historyItem) {
  const { event, action, details } = historyItem

  switch (event) {
    case PROGRAM_DETAILS_EVENT:
      return PROGRAM_DETAILS_LABEL
    case ELIGIBILITY_DETAILS_EVENT:
      return ELIGIBILITY_DETAILS_LABEL
    case MARKETING_DETAILS_EVENT:
      return MARKETING_DETAILS_LABEL
    case SEASON_RATE_EVENT:
    case PREBOOK_INFO_EVENT:
    case SMART_HOST_FEE_EVENT:
    case SMART_HOST_MOBILE_FEE_EVENT:
      return SEASONS_UPDATED_LABEL
    case EXCLUSIVE_ACTIVITY_EVENT:
      return EXCLUSIVE_ACTIVITY_LABEL
    case SEASON_DETAILS_EVENT:
      return getSeasonLabel(action)
    case DELAYED_DEACTIVATION_EVENT:
      return getDelayedDeactivationLabel(details)
    case PROGRAM_STATUS_EVENT:
      return getProgramStatusLabel(details)
    case PMS_CHANGE_EVENT:
      return PMS_CHANGE_LABEL
    case PROGRAM_DEACTIVATION_BY_SYSTEM_EVENT:
      return PROGRAM_DEACTIVATED_LABEL
    case SEASON_APP_EXCLUSION_BY_SYSTEM_EVENT:
      return SEASON_DETAILS_UPDATED_LABEL
    case UNIT_ASSIGNMENT_EVENT:
    case UNIT_SYSTEM_ASSIGNMENT_EVENT:
      return UNIT_ASSIGMENT_LABEL
    case UNIT_UPDATE_EVENT:
    case UNIT_SYSTEM_UPDATE_EVENT:
      return UNIT_UPDATE_LABEL
    default:
      return null
  }
}

export function getActionLabel(action, event) {
  if (
    event === DELAYED_DEACTIVATION_EVENT ||
    event === PROGRAM_STATUS_EVENT ||
    event === PROGRAM_DEACTIVATION_BY_SYSTEM_EVENT
  ) {
    return null
  }

  switch (action) {
    case ACTION_CREATE:
      return ACTION_ADDED_LABEL
    case ACTION_DELETE:
      return ACTION_DELETED_LABEL
    case ACTION_UPDATE:
    default:
      return ACTION_UPDATED_LABEL
  }
}

export function convertTimestampToDateTime(timestamp) {
  return moment(timestamp).format(DDDD_MMM_DD_YYYY_AT_TIME)
}

export const isSystemEvent = event => {
  switch (event) {
    case UNIT_SYSTEM_UPDATE_EVENT:
    case UNIT_SYSTEM_ASSIGNMENT_EVENT:
      return true
    default:
      return false
  }
}

export function getVacationSpecialistFullName(vacationSpecialist, event) {
  if (isSystemEvent(event)) {
    return 'System'
  }
  const firstName = get(vacationSpecialist, 'firstName', '')
  const lastName = get(vacationSpecialist, 'lastName', '')

  if (!firstName && !lastName) {
    return 'Unknown'
  }

  return `${firstName} ${lastName}`.trim()
}

export function getSeasonHeaderLabels(historyItem) {
  const { action, name } = historyItem

  if (!isUpdateAction(action)) {
    return []
  }

  return [
    {
      label: NAME_SEASON_LABEL,
      value: name
    }
  ]
}

export function getSeasonRateHeaderLabels(historyItem) {
  return [
    {
      label: NAME_SEASON_LABEL,
      value: get(historyItem, 'details.0.name', 'Unknown Name')
    }
  ]
}

export function getSeasonAppExclusionBySystemHeaderLabels([{ name }]) {
  return [{ label: NAME_SEASON_LABEL, value: name }]
}

export function getHeaderLabels(historyItem) {
  const { event, details } = historyItem

  switch (event) {
    case SEASON_DETAILS_EVENT:
      return getSeasonHeaderLabels(historyItem)
    case SEASON_RATE_EVENT:
      return getSeasonRateHeaderLabels(historyItem)
    case SEASON_APP_EXCLUSION_BY_SYSTEM_EVENT:
      return getSeasonAppExclusionBySystemHeaderLabels(details)
    default:
      return []
  }
}

export function getDataForView(historyItem) {
  const { event, vacationSpecialist, timestamp, action, name } = historyItem
  const actionLabel = getActionLabel(action, event)

  return {
    action,
    actionLabel,
    headerLabels: getHeaderLabels(historyItem),
    eventLabel: getEventLabel(historyItem),
    eventClassName: getEventClassName(event),
    name,
    vacationSpecialistFullName: getVacationSpecialistFullName(vacationSpecialist, event),
    dateTime: convertTimestampToDateTime(timestamp)
  }
}
