/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import find from 'lodash/find'

export const getAddressFormState = state => state.addressForm

/**
 *
 * @param {Object} state
 */
export const getLocations = state => getAddressFormState(state).locations
/**
 *
 * @param {Object} state
 */
export const getCountries = state =>
  getAddressFormState(state).locations.map(value => value.location)

/**
 *
 * @param {Object} state
 * @param {string} countryName
 */
export const getStatesByCountryName = (state, countryName) => {
  const { locations } = getAddressFormState(state)
  const location = find(locations, ['location.name', countryName])
  return location ? location.children.map(value => value.location) : []
}
