/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { asField } from 'informed'
import { WeekSelector } from '@xplorie/ui-commons'
import { Field } from 'components/FormComponents'

function WeekSelectorInputComponent(props) {
  const {
    fieldState: { error, value },
    fieldApi: { setValue, setTouched },
    fieldProps,
    label,
    onChange,
    ...rest
  } = props

  const handleChange = useCallback(
    (newValue, event) => {
      setValue(newValue)
      setTouched(true)
      if (onChange) {
        onChange(newValue, event)
      }
    },
    [onChange, setTouched, setValue]
  )

  return (
    <Field error={error} label={label} {...fieldProps}>
      <WeekSelector value={value} onChange={handleChange} {...rest} />
    </Field>
  )
}

WeekSelectorInputComponent.propTypes = {
  fieldProps: PropTypes.object,
  fieldApi: PropTypes.object.isRequired,
  fieldState: PropTypes.object.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func
}

WeekSelectorInputComponent.defaultProps = {
  label: '',
  onChange: null,
  fieldProps: {}
}
export const WeekSelectorInput = asField(WeekSelectorInputComponent)
