/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getAccordionList } from 'services/http/accordionDictionary'

import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE
} = helpers

const getAccordionDictionaryList = (options = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await getAccordionList(options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, request.data))
    return 'getAccordionDictionaryList success'
  }

  return 'getAccordionDictionaryList failed'
}
const resetList = () => ({
  type: RESET_DATA_STATE
})

export default {
  getAccordionDictionaryList,
  resetList
}
