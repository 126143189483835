/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import isString from 'lodash/isString'
import { DATA_STATE_REQUESTING, DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { types } from 'connectors/reducers/ticketTypesList'
import get from 'lodash/get'
import { togglePercentageSymbol } from 'helpers/formHelpers'

export const getTicketTypeId = props => {
  const { dataId = null, formDataState = '', response = null } = props
  if (dataId && isString(dataId)) {
    return dataId
  }
  if (formDataState === DATA_STATE_RECIEVED && isString(response)) {
    return response
  }
  return null
}

const buildPayload = (formValues = {}) => {
  const extrasPrice = get(formValues, 'extrasPrice')
  const isPercentageExtrasPrice = get(formValues, 'percentageExtrasPrice')
  const xploriePrice = get(formValues, 'xploriePrice')
  const isPercentageXploriePrice = get(formValues, 'percentageXploriePrice')

  const finalExtrasPriceValue = isPercentageExtrasPrice
    ? togglePercentageSymbol(extrasPrice)
    : extrasPrice
  const finalXploriePriceValue = isPercentageXploriePrice
    ? togglePercentageSymbol(xploriePrice)
    : xploriePrice

  const payload = {
    ...formValues,
    extrasPrice: finalExtrasPriceValue,
    xploriePrice: finalXploriePriceValue
  }
  return payload
}

export const onSubmitHandler = props => formValues => {
  const { putForm, activityId, postForm } = props

  const action = formValues && formValues.id ? putForm : postForm
  action(activityId, buildPayload(formValues))
}

export const onDeleteHandler = props => () => {
  const { activityId, removeEntityById, entityData } = props
  const id = getTicketTypeId(props)
  if (removeEntityById && id) {
    removeEntityById(activityId, id, entityData)
  }
}

export const isLoadingFormBuilder = props => {
  const { entityDataState } = props
  return [entityDataState].some(dataState => dataState === DATA_STATE_REQUESTING)
}

export const isDisabledFormBuilder = props => {
  const { removeDataState, entityDataState, formDataState } = props
  return [removeDataState, entityDataState, formDataState].some(
    dataState => dataState === DATA_STATE_REQUESTING
  )
}

export const buildTicketGroupOptions = data => {
  const ticketGroups = [...data]
  const noneValue = { label: 'None', value: '' }
  return [
    noneValue,
    ...ticketGroups
      .filter(value => value.name !== types.UNASSIGNED_TYPES)
      .map(group => ({ label: group.name, value: group.id }))
  ]
}
