/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDataStatesComparison } from 'hooks'
import { SimpleTable } from 'components/Tables'

import { LIST_HEADER } from './types'
import { buildTableColumns } from './helpers'

export function ProgramListComponent(props) {
  const {
    dataState,
    itemsCount,
    itemsPerPage,
    formDataState,
    data,
    onGetData,
    needUpdate,
    extendedOptions,
    TableComponent,
    getApi,
    baseColumns,
    // TODO: Need remove it after refactoring.
    dataStateWatchers,
    //
    ...rest
  } = props
  const listApi = useRef()

  const onGetListApi = useCallback(
    api => {
      listApi.current = api
      if (getApi) {
        getApi(api)
      }
    },
    [getApi]
  )

  useDataStatesComparison(() => {
    onGetData(listApi.current.getListParameters())
  }, dataStateWatchers)

  return (
    <TableComponent
      getApi={onGetListApi}
      dataState={dataState}
      columns={buildTableColumns(props, baseColumns)}
      onGetData={onGetData}
      itemsPerPage={itemsPerPage}
      itemsCount={itemsCount}
      tableHeader={LIST_HEADER}
      data={data}
      withBasicAuth={false}
      //
      {...rest}
    />
  )
}

ProgramListComponent.propTypes = {
  dataState: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemsCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  formDataState: PropTypes.string,
  onGetData: PropTypes.func.isRequired,
  needUpdate: PropTypes.bool,
  extendedOptions: PropTypes.object,
  TableComponent: PropTypes.func,
  dataStateWatchers: PropTypes.array.isRequired,
  getApi: PropTypes.func,
  baseColumns: PropTypes.array
}

ProgramListComponent.defaultProps = {
  formDataState: null,
  extendedOptions: {},
  needUpdate: false,
  TableComponent: SimpleTable,
  getApi: null,
  baseColumns: undefined
}
