/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { formInitialState } from './reducer'

/**
 *
 * @param {object} state
 * @param {string} programId
 */
function getProgramPrebookInfoStateByProgramId(state, programId) {
  return state.program.prebookInfoForm[programId] || formInitialState
}

export default {
  getProgramPrebookInfoStateByProgramId
}
