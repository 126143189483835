/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getLocationDependenciesById } from 'services/http/locations'

import helpers from './helpers'

const {
  REQUEST_PROGRAMS_AND_ACTIVITIES,
  RESPONSE_PROGRAMS_AND_ACTIVITIES,
  RESPONSE_PROGRAMS_AND_ACTIVITIES_ERROR,
  RESET_DATA_STATE
} = helpers

const getLocationProgramsAndActivities = id => async dispatch => {
  dispatch(actionCreater(REQUEST_PROGRAMS_AND_ACTIVITIES))
  try {
    const request = await getLocationDependenciesById(id)
    const { data } = request
    dispatch(actionCreater(RESPONSE_PROGRAMS_AND_ACTIVITIES, { ...data }))

    return data
  } catch ({ send, message }) {
    if (send) {
      dispatch(send(message))
    }
    dispatch(actionCreater(RESPONSE_PROGRAMS_AND_ACTIVITIES_ERROR))

    return []
  }
}

const resetLocationProgramsAndActivities = () => dispatch => {
  dispatch(actionCreater(RESET_DATA_STATE))
}

export default {
  resetLocationProgramsAndActivities,
  getLocationProgramsAndActivities
}
