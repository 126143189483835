/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'

export function TickIcon(props) {
  return (
    <svg width="100" height="100" x="0px" y="0px" viewBox="0 0 100 100" {...props}>
      <ellipse
        ry="35.5"
        rx="35.5"
        cy="249.64"
        cx="10.84"
        strokeWidth="1.5"
        transform="matrix(1.26,0,0,1.26,36.35,-264.34)"
      />
      <path
        d="m77.61,29.94c-1.11,-1.11 -2.91,-1.11 -4.02,0l-34.08,34.08l-13.01,-13.01c-1.11,-1.11 -2.91,-1.11 -4.02,0c-1.11,1.11 -1.11,2.91 0,4.02l15.11,15.11c1.11,1.11 2.91,1.11 4.02,0l36.09,-36.09c1.11,-1.11 1.11,-2.911778 0,-4.02z"
        stroke="null"
      />
    </svg>
  )
}
