/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import isArray from 'lodash/isArray'
import { httpWrapper, buildURLForGettingEntityById } from 'services/http/helpers'
import { ACCOUNT_URL } from 'endpoints'

export const updateRole = (authKey, accountId, roleIds = []) => {
  const roles = isArray(roleIds) ? roleIds.join() : String(roleIds)
  return httpWrapper({
    url: `${buildURLForGettingEntityById(ACCOUNT_URL, accountId)}/roles/${roles}`,
    method: 'put'
  })
}
