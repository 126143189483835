/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './header.scss'

function Header(props) {
  const { title } = props
  return (
    <div className={classNames(styles.headerWrapper)}>
      <div className={classNames(styles.title, 'page-title')}>{title}</div>
      <NavLink to="/">
        <div className={classNames(styles.logo)} />
      </NavLink>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired
}

export default Header
