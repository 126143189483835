import React from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

class CurtainPortal extends React.Component {
  constructor(props) {
    super(props)
    this.modalContainer = document.getElementById('curtain-container')
    this.el = document.createElement('div')
  }

  componentDidMount() {
    this.modalContainer.appendChild(this.el)
  }

  componentWillUnmount() {
    this.modalContainer.removeChild(this.el)
  }

  render() {
    return createPortal(this.props.children, this.el)
  }
}
CurtainPortal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired
}
export default CurtainPortal
