/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { TILE_ACCORDION } from 'constants/searchTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'

export const tileAccordionTableColumns = [
  {
    Header: 'Tile Name',
    accessor: 'displayName',
    id: buildSortPropertyByType('displayName', TILE_ACCORDION)
  },
  {
    Header: 'Accordion',
    accessor: 'name',
    id: buildSortPropertyByType('name', TILE_ACCORDION)
  }
]
