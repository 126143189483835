/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { buildSortPropertyByType } from 'helpers/tableHelpers'
import { ACTIVITY } from 'helpers/searchHelpers'

export function buildSearchContract(keyword) {
  return {
    type: ACTIVITY,
    sort: buildSortPropertyByType('name', ACTIVITY),
    keyword
  }
}
