/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { RESALE_REPORTS_INVENTORY_API_URL } from 'endpoints'

export const checkTicketInventory = () =>
  httpWrapper({
    url: RESALE_REPORTS_INVENTORY_API_URL,
    method: 'post'
  })
