/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './SimpleStub.scss'

export const SimpleStub = ({ message }) => (
  <div className={classNames('has-text-centered', styles.commonWrapper)}>
    <div className={classNames('notification', 'is-info', styles.textWrapper)}>{message}</div>
  </div>
)
SimpleStub.propTypes = {
  message: PropTypes.string.isRequired
}
