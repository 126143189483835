/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { compose } from 'redux'
import { withActivityData } from 'modules/ActivityProducts/ActivityResaleTab/decorators'
import { TicketType as TicketTypeComponent } from './TicketType'

export const TicketType = compose(withActivityData)(TicketTypeComponent)
