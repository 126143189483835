/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'

import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { DeactivateConfirmationModal } from 'modules/Programs'

import { BUTTON_TITLE } from './types'

export function DeactivateButton(props) {
  const { authUser, deactivateProgramAction, programId, name, ...rest } = props
  const modalApi = useRef()

  const onDeactivateProgram = useCallback(
    ({ date, deactivationUnassignUnits: unassignUnits }) => {
      if (deactivateProgramAction) {
        const { date: currentDate } = date
        const contractData = { currentDate, date, unassignUnits }
        deactivateProgramAction(programId, contractData)
        modalApi.current.toggleModal()
      }
    },
    [deactivateProgramAction, programId]
  )

  const onClickHandler = useCallback(() => {
    if (modalApi.current) {
      modalApi.current.toggleModal()
    }
  }, [])

  const onGetApi = useCallback(api => {
    modalApi.current = api
  }, [])

  return (
    <>
      <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onClickHandler} {...rest}>
        {BUTTON_TITLE}
      </BasicButton>
      <DeactivateConfirmationModal
        getApi={onGetApi}
        onAction={onDeactivateProgram}
        name={name}
        {...rest}
      />
    </>
  )
}

DeactivateButton.propTypes = {
  authUser: PropTypes.object.isRequired,
  deactivateProgramAction: PropTypes.func.isRequired,
  programId: PropTypes.string,
  name: PropTypes.string.isRequired
}

DeactivateButton.defaultProps = {
  programId: null
}
