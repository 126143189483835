import { STATE, STATE_CODE, DESTINATION, DESTINATION_CODE } from './types'

export const getLabel = type => {
  switch (type) {
    case STATE:
      return STATE_CODE
    case DESTINATION:
      return DESTINATION_CODE
    default:
      return null
  }
}
