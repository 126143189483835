/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import {
  createCampaign as createCampaignRequest,
  updateCampaign as updateCampaignRequest,
  getCampaignById as getCampaignByIdRequest,
  getCampaignActivitiesById as getCampaignActivitiesByIdRequest,
  saveCampaignActivitiesById as saveCampaignActivitiesByIdRequest,
  activateCampaign as activateCampaignRequest,
  deactivateCampaign as deactivateCampaignRequest,
  makeDraftCampaign as makeDraftCampaignRequest
} from 'services/http/campaigns'
import { getActivitiesListByLocationId } from 'services/http/activity'

import helpers, { buildKey } from './helpers'
import selectors from './selectors'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_ENTITY_ACTIVITIES,
  RESPONSE_GET_ENTITY_ACTIVITIES,
  RESPONSE_GET_ENTITY_ACTIVITIES_ERROR,
  REQUEST_SAVE_ENTITY_ACTIVITIES,
  RESPONSE_SAVE_ENTITY_ACTIVITIES,
  RESPONSE_SAVE_ENTITY_ACTIVITIES_ERROR,
  REQUEST_GET_ENTITY_ACTIVITIES_BY_LOCATION,
  RESPONSE_GET_ENTITY_ACTIVITIES_BY_LOCATION,
  RESPONSE_GET_ENTITY_ACTIVITIES_BY_LOCATION_ERROR,
  REQUEST_CHANGE_STATUS,
  RESPONSE_CHANGE_STATUS,
  RESPONSE_CHANGE_STATUS_ERROR,
  UPDATE_STATE_LIST_ACTIVITIES
} = helpers

const { getCampaignState } = selectors

const createCampaign = (accessKey, contractData) => async dispatch => {
  const key = buildKey(accessKey)
  try {
    dispatch(actionCreater(REQUEST_FORM, { key }))
    const request = await createCampaignRequest(contractData)
    const campaignData = await getCampaignByIdRequest(request.data)
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM, { data: campaignData.data, key }))
    return Promise.resolve(campaignData.data)
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
    return Promise.reject()
  }
}

const updateCampaign = (accessKey, contractData) => async (dispatch, getState) => {
  const key = buildKey(accessKey)
  const { activities } = getCampaignState(getState(), accessKey)
  try {
    dispatch(actionCreater(REQUEST_FORM, { key }))
    const request = await updateCampaignRequest(contractData)
    await saveCampaignActivitiesByIdRequest(contractData.id, activities)
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM, { data: contractData, key }))
    return Promise.resolve(contractData)
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
    return Promise.reject()
  }
}

const getCampaignById = (accessKey, id) => async dispatch => {
  const key = buildKey(accessKey)
  try {
    dispatch(actionCreater(REQUEST_GET_ENTITY_DATA, { key }))
    const request = await getCampaignByIdRequest(id)
    dispatch(actionCreater(RESPONSE_GET_ENTITY_DATA, { data: request.data, key }))
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ENTITY_DATA_ERROR, { key }))
  }
}

const getCampaignActivitiesById = (accessKey, id) => async dispatch => {
  const key = buildKey(accessKey)
  try {
    dispatch(actionCreater(REQUEST_GET_ENTITY_ACTIVITIES, { key }))
    const request = await getCampaignActivitiesByIdRequest(id)
    dispatch(actionCreater(RESPONSE_GET_ENTITY_ACTIVITIES, { data: request.data, key }))
    return request.data
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ENTITY_ACTIVITIES_ERROR, { key }))
    return Promise.reject(message)
  }
}

const saveCampaignActivitiesById = (accessKey, id, data) => async (dispatch, getState) => {
  const key = buildKey(accessKey)
  const { activities } = getCampaignState(getState(), id)
  try {
    dispatch(actionCreater(REQUEST_SAVE_ENTITY_ACTIVITIES, { data, key }))
    await saveCampaignActivitiesByIdRequest(id, data)
    dispatch(actionCreater(RESPONSE_SAVE_ENTITY_ACTIVITIES, { data, key }))
    return data
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_SAVE_ENTITY_ACTIVITIES_ERROR, { data: activities, key }))
    return Promise.reject(message)
  }
}

const getActivitiesByLocation = (accessKey, id, locationId) => async dispatch => {
  const key = buildKey(accessKey)
  try {
    dispatch(actionCreater(REQUEST_GET_ENTITY_ACTIVITIES_BY_LOCATION, { key }))
    const request = await getActivitiesListByLocationId(locationId)
    dispatch(actionCreater(RESPONSE_GET_ENTITY_ACTIVITIES_BY_LOCATION, { data: request.data, key }))
    return request.data
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ENTITY_ACTIVITIES_BY_LOCATION_ERROR, { key }))
    return Promise.reject(message)
  }
}

const activateCampaign = (accessKey, id, values) => async (dispatch, getState) => {
  const key = buildKey(accessKey)
  const { activities } = getCampaignState(getState(), accessKey)

  try {
    dispatch(actionCreater(REQUEST_CHANGE_STATUS, { key }))

    // update entity only when status not equals inactive
    if (values) {
      await Promise.all([
        updateCampaignRequest(values),
        saveCampaignActivitiesByIdRequest(id, activities)
      ])
    }

    await activateCampaignRequest(id)
    const request = await getCampaignByIdRequest(id)
    const activitiesRequest = await getCampaignActivitiesByIdRequest(id)

    dispatch(
      actionCreater(RESPONSE_CHANGE_STATUS, {
        data: request.data,
        activities: activitiesRequest.data,
        key
      })
    )
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    return request.data
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_CHANGE_STATUS_ERROR, { key }))
    return Promise.reject(message)
  }
}

const deactivateCampaign = (accessKey, id) => async dispatch => {
  const key = buildKey(accessKey)
  try {
    dispatch(actionCreater(REQUEST_CHANGE_STATUS, { key }))
    await deactivateCampaignRequest(id)
    const request = await getCampaignByIdRequest(id)
    const activitiesRequest = await getCampaignActivitiesByIdRequest(id)
    dispatch(
      actionCreater(RESPONSE_CHANGE_STATUS, {
        data: request.data,
        activities: activitiesRequest.data,
        key
      })
    )
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    return request.data
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_CHANGE_STATUS_ERROR, { key }))
    return Promise.reject(message)
  }
}

const makeDraftCampaign = (accessKey, id) => async dispatch => {
  const key = buildKey(accessKey)
  try {
    dispatch(actionCreater(REQUEST_CHANGE_STATUS, { key }))
    await makeDraftCampaignRequest(id)
    const request = await getCampaignByIdRequest(id)
    const activitiesRequest = await getCampaignActivitiesByIdRequest(id)
    dispatch(
      actionCreater(RESPONSE_CHANGE_STATUS, {
        data: request.data,
        activities: activitiesRequest.data,
        key
      })
    )
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    return request.data
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_CHANGE_STATUS_ERROR, { key }))
    return Promise.reject(message)
  }
}

const updateActivitiesState = (accessKey, data) => dispatch =>
  dispatch(actionCreater(UPDATE_STATE_LIST_ACTIVITIES, { key: buildKey(accessKey), data }))

const resetState = accessKey => dispatch =>
  dispatch(actionCreater(RESET_DATA_STATE, { key: buildKey(accessKey) }))

export default {
  createCampaign,
  updateCampaign,
  getCampaignById,
  getCampaignActivitiesById,
  saveCampaignActivitiesById,
  getActivitiesByLocation,
  activateCampaign,
  deactivateCampaign,
  resetState,
  updateActivitiesState,
  makeDraftCampaign
}
