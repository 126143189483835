/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import { buildKey } from 'connectors/reducers/deals/dealCommon/helpers'

import { FORM_NAME, ACTIVATE_DEAL } from './types'

const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

const REQUEST_FORM = `${REQUEST_TYPE}_${FORM_NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${FORM_NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${FORM_NAME}`

const REQUEST_ACTIVATE_DEAL = `${REQUEST_TYPE}_${ACTIVATE_DEAL}`
const RESPONSE_ACTIVATE_DEAL = `${RESPONSE_TYPE}_${ACTIVATE_DEAL}`
const RESPONSE_ACTIVATE_DEAL_ERROR = `${RESPONSE_ERROR_TYPE}_${ACTIVATE_DEAL}`

export { buildKey }
export default {
  RESET_DATA_STATE,
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  buildKey,
  REQUEST_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL_ERROR
}
