/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { compose } from 'redux'
import { OverlayComponent as OverlayComponentComponent } from './OverlayComponent'

export const OverlayComponent = compose()(OverlayComponentComponent)
