/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'informed'
import { FormContent, SelectField } from 'components/FormComponents'
import { SubmitButton } from 'components/Buttons'
import { ORANGE_SCHEMA, SMALLER_SIZE, SAVE_TITLE } from 'constants/buttonsOptions'
import { TooltipComponent } from 'components/Tooltip'
import get from 'lodash/get'
import {
  CHOOSE_APP_TOLTIP_MESSAGE,
  SELECT_GROUP_PLACEHOLDER,
  dealGroupsTypeOptions
} from 'modules/Discounts/types'
import { DeleteMinusIcon } from 'icons'
import { ActivityTable } from '../ActivityTable'

import styles from './NewActivitySection.scss'

export function NewActivityForm({ data, onDeleteApp, onSubmit, isFormDisabled }) {
  const formApi = useRef(null)
  const ticketTypes = get(data, 'ticketTypes', [])
  const [isSubmitDisabled, setIsSubmitDisabledState] = useState(true)
  const isSaveButtonDisabled = Array.prototype.some.call(
    [!ticketTypes, !ticketTypes.length, isFormDisabled, isSubmitDisabled],
    value => Boolean(value)
  )

  const onGetFormApi = useCallback(
    api => {
      formApi.current = api
    },
    [formApi]
  )

  useEffect(() => {
    if (data && data.activity.id) {
      formApi.current.setValues({ ...data })
    }
  }, [formApi, data])

  const formOnChangeHandler = useCallback(
    formState => {
      const { type } = formState.values
      setIsSubmitDisabledState(Array.prototype.some.call([type], value => !value))
    },
    [setIsSubmitDisabledState]
  )

  return (
    <div>
      <Form onSubmit={onSubmit} getApi={onGetFormApi} onChange={formOnChangeHandler}>
        <FormContent disabled={isFormDisabled}>
          <ActivityTable
            editMode
            renderControl={() => (
              <div className={styles.control}>
                <TooltipComponent
                  className={styles.tooltip}
                  contentTooltip={CHOOSE_APP_TOLTIP_MESSAGE}
                />
                <SelectField
                  fieldName="type"
                  options={dealGroupsTypeOptions}
                  fieldProps={{
                    classNameWrapper: styles.activityTypeWrapper,
                    isRequired: false,
                    error: null
                  }}
                  className={styles.select}
                  placeholder={SELECT_GROUP_PLACEHOLDER}
                  isRequired
                />
                <DeleteMinusIcon className={styles.deleteIcon} onClick={onDeleteApp} />
              </div>
            )}
          />
          <div className={styles.buttons}>
            <SubmitButton
              colorSchema={ORANGE_SCHEMA}
              size={SMALLER_SIZE}
              disabled={isSaveButtonDisabled}
            >
              {SAVE_TITLE}
            </SubmitButton>
          </div>
        </FormContent>
      </Form>
    </div>
  )
}
NewActivityForm.propTypes = {
  data: PropTypes.object.isRequired,
  onDeleteApp: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getApi: PropTypes.func.isRequired,
  isFormDisabled: PropTypes.bool.isRequired
}
