/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function ExitFullScreen(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.102 15.5103H0.408164C0.182773 15.5103 0 15.3275 0 15.1021V0.408208C0 0.182817 0.182773 4.3869e-05 0.408164 4.3869e-05H15.102C15.3274 4.3869e-05 15.5102 0.182817 15.5102 0.408208V15.1021C15.5102 15.3275 15.3275 15.5103 15.102 15.5103ZM14.6939 0.816373H0.816328V14.6939H14.6939V0.816373Z"
        fill="black"
      />
      <path
        d="M19.6341 20H4.94018C4.71478 20 4.48975 19.8446 4.48975 19.6193V16.7621C4.48975 16.5366 4.67252 16.3539 4.89791 16.3539C5.1233 16.3539 5.30607 16.5366 5.30607 16.7621V19.1837H19.1836V5.30613H17.1851C16.9597 5.30613 16.7769 5.12336 16.7769 4.89797C16.7769 4.67258 16.9597 4.4898 17.1851 4.4898H19.634C19.8594 4.4898 19.9999 4.69992 19.9999 4.92539V19.6193C20 19.8446 19.8595 20 19.6341 20Z"
        fill="black"
      />
    </svg>
  )
}
