/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { BasicButton } from '@xplorie/ui-commons'
import { buildShowAPPsPath, buildShowProgramsPath, canDeleteMarket } from 'modules/Market'
import { BasicModal } from 'components/Modals'
import { withModalWindow } from 'decorators'
import { PIECurtainLoader } from 'components/Loaders'
import { useLoading } from 'hooks'
import {
  ORANGE_SCHEMA,
  YES_TITLE,
  NO_TITLE,
  YELLOW_GREEN_SCHEMA,
  SMALLER_SIZE,
  DELETE_TITLE
} from 'constants/buttonsOptions'
import {
  MESSAGE_FIRST_PART,
  MESSAGE_SECOND_PART,
  DELETE_MODAL_TITLE,
  PROGRAMS_LINK,
  APPS_LINK
} from './types'
import styles from './DeleteButton.scss'

const ButtonWithModal = withModalWindow(BasicButton)

export function DeleteButton(props) {
  const { data, dataState, authUser, deleteMarketById } = props
  const canDelete = useMemo(() => canDeleteMarket(authUser, data), [authUser, data])
  const [isRequesting] = useLoading(dataState)
  const programsPath = useMemo(() => buildShowProgramsPath(data.id), [data])
  const appsPath = useMemo(() => buildShowAPPsPath(data.id), [data])

  const onDeleteHandler = useCallback(() => {
    const { id } = data
    if (id) {
      deleteMarketById(id)
    }
  }, [deleteMarketById, data])

  return (
    <>
      {isRequesting && <PIECurtainLoader />}
      <ButtonWithModal
        colorSchema={ORANGE_SCHEMA}
        onAction={onDeleteHandler}
        size={SMALLER_SIZE}
        disabled={!canDelete}
        renderModal={({ isShown, onConfirm, onHide }) => (
          <BasicModal
            isOpen={isShown}
            onClose={onHide}
            title={DELETE_MODAL_TITLE}
            renderBody={() => (
              <section className={styles.wrapper}>
                <div className={styles.messageWrapper}>
                  {MESSAGE_FIRST_PART}
                  <span className={styles.bold}>{data.name}</span>
                  {MESSAGE_SECOND_PART}
                  <span className={styles.showPrograms}>
                    <Link to={programsPath} target="_blank">
                      {PROGRAMS_LINK}
                    </Link>
                    <span>,</span>
                    <Link to={appsPath} target="_blank">
                      {APPS_LINK}
                    </Link>
                  </span>
                </div>
              </section>
            )}
          >
            <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onConfirm}>
              {YES_TITLE}
            </BasicButton>
            <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onHide}>
              {NO_TITLE}
            </BasicButton>
          </BasicModal>
        )}
      >
        {DELETE_TITLE}
      </ButtonWithModal>
    </>
  )
}

DeleteButton.propTypes = {
  authUser: PropTypes.object.isRequired,
  data: PropTypes.object,
  dataState: PropTypes.string.isRequired,
  deleteMarketById: PropTypes.func.isRequired
}

DeleteButton.defaultProps = {
  data: null
}
