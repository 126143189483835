/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildListParams, buildURLForGettingExtendedList } from 'services/http/helpers'
import { ACTIVITY_PROVIDER_URL } from 'endpoints'

export const getListExtended = options =>
  httpWrapper({
    url: buildURLForGettingExtendedList(ACTIVITY_PROVIDER_URL),
    method: 'get',
    params: buildListParams(options)
  })
