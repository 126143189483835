/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import { withAuthContext } from 'helpers/withAuthContext'
import { actions, getAppLifeCycletateByAppId } from 'reducers/app/appLifeCycle'

import { ActivateButton as ActivateButtonComponent } from './ActivateButton'

export const mapStateToProps = (state, { dataId }) => {
  const appState = getAppLifeCycletateByAppId(state, dataId)

  return {
    ...appState
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ ...actions }, dispatch)

export const ActivateButton = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ActivateButtonComponent)
