/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import {
  FORM_NAME,
  GET_UNIT_BY_ID,
  GET_UNIT_ADDRESS_BY_ID,
  GET_UNIT_AMENITIES_BY_ID,
  GET_UNIT_IMAGES_BY_ID,
  GET_UNIT_SMART_HOST_BY_ID,
  UPDATE_UNIT_SMART_HOST_BY_ID
} from './types'

export const REQUEST_UNIT_BY_ID = `${REQUEST_TYPE}_${GET_UNIT_BY_ID}`
export const RESPONSE_UNIT_BY_ID = `${RESPONSE_TYPE}_${GET_UNIT_BY_ID}`
export const RESPONSE_UNIT_BY_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_UNIT_BY_ID}`

export const REQUEST_UNIT_ADDRESS_BY_ID = `${REQUEST_TYPE}_${GET_UNIT_ADDRESS_BY_ID}`
export const RESPONSE_UNIT_ADDRESS_BY_ID = `${RESPONSE_TYPE}_${GET_UNIT_ADDRESS_BY_ID}`
export const RESPONSE_UNIT_ADDRESS_BY_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_UNIT_ADDRESS_BY_ID}`

export const REQUEST_UNIT_AMENITIES_BY_ID = `${REQUEST_TYPE}_${GET_UNIT_AMENITIES_BY_ID}`
export const RESPONSE_UNIT_AMENITIES_BY_ID = `${RESPONSE_TYPE}_${GET_UNIT_AMENITIES_BY_ID}`
export const RESPONSE_UNIT_AMENITIES_BY_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_UNIT_AMENITIES_BY_ID}`

export const REQUEST_UNIT_IMAGES_BY_ID = `${REQUEST_TYPE}_${GET_UNIT_IMAGES_BY_ID}`
export const RESPONSE_UNIT_IMAGES_BY_ID = `${RESPONSE_TYPE}_${GET_UNIT_IMAGES_BY_ID}`
export const RESPONSE_UNIT_IMAGES_BY_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_UNIT_IMAGES_BY_ID}`

export const REQUEST_UNIT_SMART_HOST_BY_ID = `${REQUEST_TYPE}_${GET_UNIT_SMART_HOST_BY_ID}`
export const RESPONSE_UNIT_SMART_HOST_BY_ID = `${RESPONSE_TYPE}_${GET_UNIT_SMART_HOST_BY_ID}`
export const RESPONSE_UNIT_SMART_HOST_BY_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_UNIT_SMART_HOST_BY_ID}`

export const REQUEST_UPDATE_UNIT_SMART_HOST_BY_ID = `${REQUEST_TYPE}_${UPDATE_UNIT_SMART_HOST_BY_ID}`
export const RESPONSE_UPDATE_UNIT_SMART_HOST_BY_ID = `${RESPONSE_TYPE}_${UPDATE_UNIT_SMART_HOST_BY_ID}`
export const RESPONSE_UPDATE_UNIT_SMART_HOST_BY_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${UPDATE_UNIT_SMART_HOST_BY_ID}`

export const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`
