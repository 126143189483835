/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { combineReducers } from 'redux'

import pmcPrograms from './pmcPrograms'
import appPrograms from './appPrograms'
import marketPrograms from './marketPrograms'
import locationPrograms from './locationPrograms'
import destinationPrograms from './destinationPrograms'
import programMaterialsForm from './programMaterialsForm'
import pmcProgramsWithUnits from './pmcProgramsWithUnits'
import prebookInfoForm from './prebookInfoForm'
import programHistory from './programHistory'
import smartHostFee from './smartHostFee'
import smartHostMobileFee from './smartHostMobileFee'

export default combineReducers({
  pmcPrograms,
  appPrograms,
  marketPrograms,
  locationPrograms,
  destinationPrograms,
  programMaterialsForm,
  pmcProgramsWithUnits,
  prebookInfoForm,
  programHistory,
  smartHostFee,
  smartHostMobileFee
})
