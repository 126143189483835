import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'informed'
import { useUpdateSomeDataState } from 'hooks'
import { DATA_STATE_RECIEVED, DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { InnerContent } from './InnerContent'

export function ForgotPasswordForm(props) {
  const { onClose, dataState, isEmailError, sendRecoveryPasswordLink, resetDataState } = props

  const isDisabled = dataState === DATA_STATE_REQUESTING

  useEffect(() => {
    if (dataState === DATA_STATE_RECIEVED) {
      resetDataState()
    }
  }, [])

  useUpdateSomeDataState(
    () => {
      if (!isEmailError) {
        onClose()
      }
    },
    [dataState, isEmailError],
    DATA_STATE_RECIEVED
  )

  const onSubmit = formValues => {
    const { email } = formValues
    sendRecoveryPasswordLink({ email })
  }

  return (
    <Form onSubmit={onSubmit}>
      {({ formApi }) => (
        <InnerContent
          resetDataState={resetDataState}
          isEmailError={isEmailError}
          formApi={formApi}
          disabled={isDisabled}
          dataState={dataState}
        />
      )}
    </Form>
  )
}

ForgotPasswordForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  dataState: PropTypes.string.isRequired,
  isEmailError: PropTypes.bool.isRequired,
  sendRecoveryPasswordLink: PropTypes.func.isRequired,
  resetDataState: PropTypes.func.isRequired
}
