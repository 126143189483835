/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { actions as notifActions } from 'components/ReduxNotifications'
import { ReduxNotifComponent } from './ReduxNotification'

const { notifDismiss } = notifActions
const mapStateToProps = () => ({})

export const ReduxNotification = connect(
  mapStateToProps,
  { notifDismiss }
)(ReduxNotifComponent)
