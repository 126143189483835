/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { TagElement } from './TagElement'

import {
  MY_DRAFTS_TYPE,
  PENDING_TYPE,
  ITEM_TAB_MY_DRAFTS,
  ITEM_TAB_PENDING,
  DRAFTS_TYPE,
  ITEM_TAB_DRAFTS,
  ON_BOARDING_TYPE,
  ITEM_TAB_BOARDING
} from './types'

export const Tabs = ({ activeType, onChange }) => (
  <>
    <TagElement id="my-drafts" currentType={activeType} type={MY_DRAFTS_TYPE} onChange={onChange}>
      {ITEM_TAB_MY_DRAFTS}
    </TagElement>
    <TagElement id="all-drafts" currentType={activeType} type={DRAFTS_TYPE} onChange={onChange}>
      {ITEM_TAB_DRAFTS}
    </TagElement>
    <TagElement
      id="on-boarding"
      currentType={activeType}
      type={ON_BOARDING_TYPE}
      onChange={onChange}
    >
      {ITEM_TAB_BOARDING}
    </TagElement>
    <TagElement id="pending" currentType={activeType} type={PENDING_TYPE} onChange={onChange}>
      {ITEM_TAB_PENDING}
    </TagElement>
  </>
)

Tabs.propTypes = {
  onChange: PropTypes.func.isRequired,
  activeType: PropTypes.string.isRequired
}
