/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

export default {
  PROGRAMS_BY_APP_NAME: 'PROGRAMS_BY_APP',
  APP_ONE_NAME: 'ACTIVITY_PRODUCT_ONE',
  EXPORT_PROGRAMS_BY_APP: 'PROGRAMS_BY_APP_EXPORT'
}
