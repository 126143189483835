/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { DotsButton } from '@xplorie/ui-commons'
import { useSliderApi } from 'hooks'
import { isActionAllowed } from 'helpers/permissionHelper'
import { ADMIN_LEVEL } from 'constants/permissionTypes'
import { ACTIVITY_CATEGORY } from 'constants/formCodes'
import { DefaultTemplate } from 'modules/Library'
import { openNewTab } from 'helpers/routeHelpers'
import { ActivityCategoryList, buildShowApsPath, buildShowAppsPath } from 'modules/ActivityCategory'

import { ADD_NEW_LABEL, APS_WITHOUT_CATEGORY_LABEL, APPS_WITHOUT_CATEGORY_LABEL } from './types'

export function ActivityCategoryContent(props) {
  const { authUser } = props
  const isAdmin = useMemo(() => isActionAllowed(ADMIN_LEVEL, authUser), [authUser])

  const sliderApi = useSliderApi(ACTIVITY_CATEGORY)

  const addNewHandler = useCallback(() => sliderApi.open(), [sliderApi])
  const redirectToHandler = useCallback(url => () => openNewTab(url), [])

  const buildOptions = useCallback(() => {
    const options = [
      {
        label: APS_WITHOUT_CATEGORY_LABEL,
        props: { onClick: redirectToHandler(buildShowApsPath()) }
      },
      {
        label: APPS_WITHOUT_CATEGORY_LABEL,
        props: { onClick: redirectToHandler(buildShowAppsPath()) }
      }
    ]

    if (isAdmin) {
      options.unshift({ label: ADD_NEW_LABEL, props: { onClick: addNewHandler } })
    }

    return options
  }, [addNewHandler, isAdmin, redirectToHandler])

  return (
    <DefaultTemplate Actions={<DotsButton align="left" options={buildOptions()} />} {...props}>
      <ActivityCategoryList />
    </DefaultTemplate>
  )
}

ActivityCategoryContent.propTypes = {
  authUser: PropTypes.object.isRequired
}
