/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildKeywordSearchOptions } from 'services/http/helpers'
import { SEARCH_URL } from 'endpoints'

export const UNSAFE_searchByKeyword = (authKey, options, contractData = []) =>
  httpWrapper({
    url: `${SEARCH_URL}`,
    method: 'post',
    params: buildKeywordSearchOptions(options),
    data: contractData
  })

export const searchByKeyword = (contractData = [], options) =>
  httpWrapper({
    url: `${SEARCH_URL}`,
    method: 'post',
    params: buildKeywordSearchOptions(options),
    data: contractData
  })
