/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_ACCOUNT,
  RESPONSE_ACCOUNT,
  RESPONSE_ACCOUNT_ERROR,
  RESET_DATA_STATE
} = helpers

export const initialState = {}

export const accountFormInitialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  accountDataState: DATA_STATE_NOT_REQUESTED,
  accountData: null
}

const accountFormReducer = (state = initialState, action) => {
  switch (action.type) {
    // Account form state
    case REQUEST_FORM:
      return {
        ...state,
        [action.payload.key]: {
          ...accountFormInitialState,
          ...state[action.payload.key],
          dataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_FORM_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...accountFormInitialState,
          ...state[action.payload.key],
          dataState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_FORM:
      return {
        ...state,
        [action.payload.key]: {
          ...accountFormInitialState,
          ...state[action.payload.key],
          dataState: DATA_STATE_RECIEVED
        }
      }

    // Account state
    case REQUEST_ACCOUNT:
      return {
        ...state,
        [action.payload.key]: {
          ...accountFormInitialState,
          ...state[action.payload.key],
          accountDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_ACCOUNT_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...accountFormInitialState,
          ...state[action.payload.key],
          accountDataState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_ACCOUNT:
      return {
        ...state,
        [action.payload.key]: {
          ...accountFormInitialState,
          ...state[action.payload.key],
          accountData: action.payload.accountData,
          accountDataState: DATA_STATE_RECIEVED
        }
      }

    case RESET_DATA_STATE:
      return (() => {
        const { [action.payload.key]: removedState, ...restState } = state
        return {
          ...restState
        }
      })()

    default:
      return state
  }
}

export default filterActions(accountFormReducer, [
  REQUEST_FORM,
  RESPONSE_FORM_ERROR,
  RESPONSE_FORM,
  RESET_DATA_STATE,
  REQUEST_ACCOUNT,
  RESPONSE_ACCOUNT,
  RESPONSE_ACCOUNT_ERROR
])
