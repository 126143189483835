/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions as editQueue } from 'reducers/editQueue'
import { actions } from 'reducers/activityModuleList'
import { formActions } from 'reducers/activityModuleForm'
import { withAuthContext } from 'helpers/withAuthContext'

import { ActivityModuleList as ActivityModuleListComponent } from './ActivityModuleList'

export const mapStateToProps = state => ({
  ...state.activityModuleList,
  removeDataState: state.activityModuleForm.removeDataState,
  formDataState: state.activityModuleForm.formDataState
})

export const mapDispatchToProps = dispatch => {
  const { getActivityModuleList: onGetData, ...rest } = actions
  return bindActionCreators(
    { ...editQueue, ...formActions, onGetData, ...rest },
    dispatch
  )
}

export const ActivityModuleList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ActivityModuleListComponent)
