/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'
import { getDealFormState, formActions } from 'connectors/reducers/deals/dealForm'
import {
  actions as dealFormGroupsActions,
  getDealFormGroupsState
} from 'reducers/deals/dealFormGroups'
import { DiscountsForm as DiscountsFormComponent } from './DiscountsForm'

function mapStateToProps(state) {
  const dealState = getDealFormState(state)
  const dealFormGroupsState = getDealFormGroupsState(state)
  return {
    ...dealState,
    assignDataState: dealFormGroupsState.assignDataState
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...formActions,
      ...dealFormGroupsActions
    },
    dispatch
  )
}

export const DiscountsForm = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DiscountsFormComponent)
