/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import types from './types'

const { NAME } = types

const generateId = () => +new Date()

const ENQUEUE_MODAL = `ENQUEUE_MODAL_${NAME}`
const DEQUEUE_MODAL = `DEQUEUE_MODAL_${NAME}`
const RESET_ALL_MODALS = `RESET_ALL_MODALS_${NAME}`

export default {
  generateId,
  ENQUEUE_MODAL,
  DEQUEUE_MODAL,
  RESET_ALL_MODALS
}
