/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_ACCOUNT_SETTINGS,
  RESPONSE_ACCOUNT_SETTINGS,
  RESPONSE_ACCOUNT_SETTINGS_ERROR
} = helpers

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  accountSettingsDataState: DATA_STATE_NOT_REQUESTED,
  accountSettingsData: null
}

const accountSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FORM:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_FORM_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case RESPONSE_FORM:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED
      }
    case REQUEST_ACCOUNT_SETTINGS:
      return {
        ...state,
        accountSettingsDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_ACCOUNT_SETTINGS_ERROR:
      return {
        ...state,
        accountSettingsDataState: DATA_STATE_ERROR
      }
    case RESPONSE_ACCOUNT_SETTINGS:
      return {
        ...state,
        accountSettingsDataState: DATA_STATE_RECIEVED,
        accountSettingsData: action.payload
      }
    case RESET_DATA_STATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default filterActions(accountSettingsReducer, [
  REQUEST_FORM,
  RESPONSE_FORM_ERROR,
  RESPONSE_FORM,
  RESET_DATA_STATE,
  REQUEST_ACCOUNT_SETTINGS,
  RESPONSE_ACCOUNT_SETTINGS,
  RESPONSE_ACCOUNT_SETTINGS_ERROR
])
