/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { JustTable } from '@xplorie/ui-commons'
import { BouncingLoader } from 'components/Loaders'
import { useDidMount, useLoading } from 'hooks'
import { TABLE_HEADER, TABLE_HEAD_XPLORIE_NAME, TABLE_HEAD_DATA_TYPE } from './types'

import styles from './PMSEligibilityList.scss'

export function PMSEligibilityList({ data, dataId, resetList, getList, dataState }) {
  const [, { isLoaded, isError }] = useLoading(dataState)

  const showLoader = !isLoaded && !isError

  useDidMount(() => {
    if (dataId && !isLoaded) {
      getList(dataId)
    }

    return () => {
      resetList(dataId)
    }
  })

  return showLoader ? (
    <BouncingLoader />
  ) : (
    <section className={styles.wrapper}>
      <JustTable
        title={TABLE_HEADER}
        data={data}
        columns={[
          { id: 1, Header: TABLE_HEAD_XPLORIE_NAME, accessor: 'parameter.label' },
          {
            id: 2,
            Header: TABLE_HEAD_DATA_TYPE,
            accessor: 'parameter.type',
            cellClassName: styles.type
          }
        ]}
      />
    </section>
  )
}
PMSEligibilityList.propTypes = {
  data: PropTypes.array.isRequired,
  dataId: PropTypes.string.isRequired,
  getList: PropTypes.func.isRequired,
  dataState: PropTypes.string.isRequired,
  resetList: PropTypes.func.isRequired
}
