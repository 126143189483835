/* eslint-disable max-lines */
/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import moment from 'moment'
import find from 'lodash/find'
import get from 'lodash/get'
import isNaN from 'lodash/isNaN'
import { DEFAULT_TIME_FORMAT, FORMAT } from 'constants/date'
import { PRIMARY_ADDRESS_TYPE } from 'constants/addressTypes'
import { CANADA_TYPE, USA_TYPE } from 'constants/countriesTypes'
import {
  MULTI_EMAIL_VALIDATION,
  REGEX_ANY_NUMBER_VALIDATION,
  REGEX_DATE_VALIDATION,
  REGEX_DATE_WITH_WEEK_DAY_VALIDATION,
  REGEX_DECIMALS_ONE_DIGIT_VALIDATION,
  REGEX_DECIMALS_PERIOD_VALIDATION,
  REGEX_DECIMALS_VALIDATION,
  REGEX_DECIMALS_VALIDATION_FEE,
  REGEX_EMAIL_VALIDATION,
  REGEX_ENTITY_NAME_PRE_VALIDATOR,
  REGEX_LATITUDE_VALIDATION,
  REGEX_LINK_VALIDATION,
  REGEX_LONGITUDE_VALIDATION,
  REGEX_NUMBER_VALIDATION,
  REGEX_PHONE_NUMBER_PRE_VALIDATION,
  REGEX_PHONE_NUMBER_VALIDATION,
  REGEX_PHONE_VALIDATION,
  REGEX_THEE_TREE_FOUR_PHONE_NUMBER_VALIDATION,
  REGEX_ZIP_CANADA_VALIDATION,
  REGEX_ZIP_USA_VALIDATION,
  REGEX_DECIMALS_TWO_DIGIT_VALIDATION
} from 'constants/regExps'

import { getPath, safeArray } from 'helpers/utilities'
import { DAYS_TYPE } from 'modules/ActivityProducts/ActivityResaleTab/Settings/SettingsBlocks/types'
import {
  AFTER_DATE_VALIDATION_MESSAGE,
  ALLOWED_CHAR_LIMIT_MESSAGE,
  ALREADY_SEARCHED_MESSAGE,
  BEFORE_DATE_VALIDATION_MESSAGE,
  BETWEEN_DATE_VALIDATION_MESSAGE,
  DATE_IN_PAST_VALIDATION_MESSAGE,
  DATE_WITH_WEEK_DAY_VALIDATION_MESSAGE,
  DECIMAL_VALIDATION_MESSAGE,
  DECIMAL_VALIDATION_PERIOD_MESSAGE,
  DEFAULT_SYMBOL_COUNT,
  HOME_SEARCH_VALIDATION_MESSAGE,
  INVALID_FEE_NUMBER,
  LATITUDE_VALIDATION_MESSAGE,
  LINK_VALIDATION_MESSAGE,
  LONGITUDE_VALIDATION_MESSAGE,
  MULTI_EMAIL_VALIDATION_MESSAGE,
  NOT_BE_BLANK_MESSAGE,
  NOT_VALID_DATE_MESSAGE,
  NOT_VALID_EMAIL_MESSAGE,
  NUMBER_VALIDATION_MESSAGE,
  PHONE_THEE_FOUR_TREE_FORMAT_VALIDATION_MESSAGE,
  PHONE_VALIDATION_MESSAGE,
  VALID_VALUE_EXCEEDED_MESSAGE,
  ZIP_CANADA_VALIDATION_MESSAGE,
  ZIP_USA_VALIDATION_MESSAGE,
  RANGE_INTERSECTION_ERROR,
  POSITIVE_NUMBER_VALIDATION_MESSAGE,
  DATE_IN_FUTURE_VALIDATION_MESSAGE,
  AFTER_TIME_VALIDATION_MESSAGE,
  BEFORE_TIME_VALIDATION_MESSAGE,
  MIN_NUMBER_ERROR,
  MAX_NUMBER_ERROR,
  MIN_VALUE_INVALID_MESSAGE,
  MAX_VALUE_INVALID_MESSAGE,
  GREATER_THEN_ZERO_MESSAGE,
  DEFAULT_CHARACTER_LIMIT
} from './types/formHelpers'

export const generateDataSelectList = list =>
  safeArray(list)
    .filter(item => item !== null && item !== undefined)
    .map(listObj => ({
      label: listObj.name,
      value: listObj.id
    }))

// TODO: Not covered yet with test
export const withValidation = (currentValidator, nextValidator) => (...args) => {
  const hasError = currentValidator(...args)
  return !hasError ? nextValidator(...args) : hasError
}

// TODO: Not covered yet with test
export const basicTextValidate = value =>
  value && String(value).length > DEFAULT_CHARACTER_LIMIT ? ALLOWED_CHAR_LIMIT_MESSAGE : null

export const searchValidate = withValidation(
  (stateValue = null, formState = { touched: {} }) => (value = '') => {
    const isTouched = Object.keys(formState.touched).length > 0
    const trimmedValue = value.trim()
    if (trimmedValue === '' || String(trimmedValue).length < 2) {
      return HOME_SEARCH_VALIDATION_MESSAGE
    }
    if (stateValue && value && isTouched && value === stateValue) {
      return ALREADY_SEARCHED_MESSAGE
    }
    return null
  },
  basicTextValidate
)

// TODO: Not covered yet with test
export const searchOrderValidate = withValidation(
  (value = '') =>
    String(value).length > 0 && String(value).length < 2 ? HOME_SEARCH_VALIDATION_MESSAGE : null,
  basicTextValidate
)

export const blankValidateWithMessage = (message = NOT_BE_BLANK_MESSAGE) => value =>
  (!value && value !== 0) || !String(value).trim().length ? message : null

export const blankValidate = blankValidateWithMessage(NOT_BE_BLANK_MESSAGE)

export const dateValidate = withValidation(
  value => (!value || REGEX_DATE_VALIDATION.test(value) ? null : NOT_VALID_DATE_MESSAGE),
  basicTextValidate
)

// TODO: Not covered yet with test
export const dayWithDateValidate = value =>
  !value || REGEX_DATE_WITH_WEEK_DAY_VALIDATION.test(value)
    ? null
    : DATE_WITH_WEEK_DAY_VALIDATION_MESSAGE

export const emailValidate = withValidation(
  value => (!value || REGEX_EMAIL_VALIDATION.test(value) ? null : NOT_VALID_EMAIL_MESSAGE),
  basicTextValidate
)

// TODO: Not covered yet with test
export const multiEmailValidate = withValidation(
  value => (!value || MULTI_EMAIL_VALIDATION.test(value) ? null : MULTI_EMAIL_VALIDATION_MESSAGE),
  basicTextValidate
)

// TODO: Not covered yet with test
export const latitudeValidate = value =>
  !value || REGEX_LATITUDE_VALIDATION.test(value) ? null : LATITUDE_VALIDATION_MESSAGE

// TODO: Not covered yet with test
export const longitudeValidate = value =>
  !value || REGEX_LONGITUDE_VALIDATION.test(value) ? null : LONGITUDE_VALIDATION_MESSAGE

// TODO: Not covered yet with test
export const withBlankValidation = validator => (...args) => {
  const hasBlankError = blankValidate(...args)
  return !hasBlankError ? validator(...args) : hasBlankError
}

export const linkValidate = value => {
  if (value) {
    if (!REGEX_LINK_VALIDATION.test(value)) {
      return LINK_VALIDATION_MESSAGE
    }
    if (String(value).length > 512) {
      return ALLOWED_CHAR_LIMIT_MESSAGE
    }
  }
  return null
}

export const phoneValidate = withValidation(
  value => (!value || REGEX_PHONE_VALIDATION.test(value) ? null : PHONE_VALIDATION_MESSAGE),
  basicTextValidate
)

export const phoneTheeFourTheeFormatValidate = withValidation(
  value =>
    !value || REGEX_THEE_TREE_FOUR_PHONE_NUMBER_VALIDATION.test(value)
      ? null
      : PHONE_THEE_FOUR_TREE_FORMAT_VALIDATION_MESSAGE,
  basicTextValidate
)

// TODO: Not covered yet with test
export const validateUniquePhoneNumber = value =>
  !value || !REGEX_PHONE_NUMBER_VALIDATION.test(value) || value.length < 10 || value.length < 12
    ? 'The field should be with according to this format: "###-###-####"'
    : undefined

// TODO: Not covered yet with test
export const phoneNumberMask = value => {
  let replacedValue = value

  if (value && replacedValue.length >= 12) {
    replacedValue =
      value && value.replace(REGEX_PHONE_NUMBER_PRE_VALIDATION, '$1-$2-$3').slice(0, 12)
  }

  return replacedValue
}

export const zipCanadaValidate = withValidation(
  value =>
    !value || REGEX_ZIP_CANADA_VALIDATION.test(value) ? null : ZIP_CANADA_VALIDATION_MESSAGE,
  basicTextValidate
)

export const zipUSAValidate = withValidation(
  value => (!value || REGEX_ZIP_USA_VALIDATION.test(value) ? null : ZIP_USA_VALIDATION_MESSAGE),
  basicTextValidate
)

// TODO: Not covered yet with test
export const getZipValidation = countryType => {
  switch (countryType) {
    case CANADA_TYPE:
      return zipCanadaValidate
    case USA_TYPE:
      return zipUSAValidate
    default:
      return zipUSAValidate
  }
}

// TODO: Not covered yet with test
export const entityNamePreValidator = value => {
  if (value) {
    return String(value).replace(REGEX_ENTITY_NAME_PRE_VALIDATOR, '')
  }
  return null
}

export const numberValidateWithMessage = message => value => {
  if (value) {
    if (!REGEX_NUMBER_VALIDATION.test(value)) {
      return message
    }
    if (Number(value) > 2147483647) {
      return VALID_VALUE_EXCEEDED_MESSAGE
    }
  }
  return null
}

export const numberValidate = numberValidateWithMessage(NUMBER_VALIDATION_MESSAGE)

export const numberValidationWithoutLimit = value => {
  if (value) {
    if (!REGEX_NUMBER_VALIDATION.test(value)) {
      return GREATER_THEN_ZERO_MESSAGE
    }
  }
  return null
}

export const numberMinMaxValuesValidation = (min, max) => value => {
  if (value) {
    if (min && Number(value) < min) {
      return MIN_VALUE_INVALID_MESSAGE(min)
    }
    if (max && Number(value) > max) {
      return MAX_VALUE_INVALID_MESSAGE(max)
    }
  }
  return null
}

export const validateMinNumber = path => (currentValue, values) => {
  const hasBlankError = blankValidate(currentValue)
  if (hasBlankError) {
    return hasBlankError
  }

  const connectedValue = get(values, path)
  if (currentValue && connectedValue) {
    const isValid = Number(currentValue) <= Number(connectedValue)

    if (!isValid) {
      return MIN_NUMBER_ERROR
    }
  }
  return null
}

export const validateMaxNumber = path => (currentValue, values) => {
  const connectedValue = get(values, path)
  if (currentValue && connectedValue) {
    const isValid = Number(currentValue) >= Number(connectedValue)

    if (!isValid) {
      return MAX_NUMBER_ERROR
    }
  }
  return null
}

export const decimalsValidationFee = value =>
  !value || REGEX_DECIMALS_VALIDATION_FEE.test(value) ? null : INVALID_FEE_NUMBER

export const twoDigitDecimalsValidation = value =>
  !value || REGEX_DECIMALS_TWO_DIGIT_VALIDATION.test(value) ? null : GREATER_THEN_ZERO_MESSAGE

export const decimalsValidation = value =>
  !value || REGEX_DECIMALS_VALIDATION.test(value) ? null : DECIMAL_VALIDATION_MESSAGE

export const parseDecimals = (figure, decimals = 2) => Number.parseFloat(figure).toFixed(decimals)

export const validateBeforeDate = (path, options = {}) => (currentValue, values) => {
  const formatValidator = get(options, 'formatValidator', dateValidate)
  const format = get(options, 'format', FORMAT)
  const dateError = formatValidator(currentValue)
  if (dateError) {
    return dateError
  }
  const connectedValue = get(values, path)
  if (currentValue && connectedValue) {
    const isValid = moment(moment(currentValue, format)).isSameOrBefore(
      moment(connectedValue, format)
    )
    if (!isValid) {
      return BEFORE_DATE_VALIDATION_MESSAGE
    }
  }
  return null
}

// TODO: Not covered yet with test
export const validateBeforeDateObject = (ownDatePath, connectedPath) => (currentValue, values) => {
  const currentDate = get(currentValue, ownDatePath)
  const dateError = dateValidate(currentDate)
  if (dateError) {
    return dateError
  }
  const connectedValue = get(values, connectedPath)
  if (currentDate && connectedValue) {
    const isValid = moment(moment(currentDate, FORMAT)).isSameOrBefore(
      moment(connectedValue, FORMAT)
    )
    if (!isValid) {
      return BEFORE_DATE_VALIDATION_MESSAGE
    }
  }
  return null
}

export const validateAfterDate = (path, options = {}) => (currentValue, values) => {
  const formatValidator = get(options, 'formatValidator', dateValidate)
  const format = get(options, 'format', FORMAT)
  const dateError = formatValidator(currentValue)
  if (dateError) {
    return dateError
  }
  const connectedValue = get(values, path)
  if (currentValue && connectedValue) {
    const isValid = moment(moment(currentValue, format)).isSameOrAfter(
      moment(connectedValue, format)
    )
    if (!isValid) {
      return AFTER_DATE_VALIDATION_MESSAGE
    }
  }
  return null
}

export const validateAfterTime = (path, options = {}) => (currentValue, values) => {
  const format = get(options, 'format', DEFAULT_TIME_FORMAT)

  const connectedValue = get(values, path)
  if (currentValue && connectedValue) {
    const isValid = moment(moment(currentValue, format)).isSameOrAfter(
      moment(connectedValue, format)
    )
    if (!isValid) {
      return AFTER_TIME_VALIDATION_MESSAGE
    }
  }
  return null
}

export const validateBeforeTime = (path, options = {}) => (currentValue, values) => {
  const format = get(options, 'format', DEFAULT_TIME_FORMAT)

  const connectedValue = get(values, path)
  if (currentValue && connectedValue) {
    const isValid = moment(moment(currentValue, format)).isSameOrBefore(
      moment(connectedValue, format)
    )
    if (!isValid) {
      return BEFORE_TIME_VALIDATION_MESSAGE
    }
  }
  return null
}

// TODO: Not covered yet with test
export const validateAfterDateObject = (ownDatePath, connectedPath) => (currentValue, values) => {
  const currentDate = get(currentValue, ownDatePath)
  const dateError = dateValidate(currentDate)
  if (dateError) {
    return dateError
  }
  const connectedValue = get(values, connectedPath)
  if (currentDate && connectedValue) {
    const isValid = moment(moment(currentDate, FORMAT)).isSameOrAfter(
      moment(connectedValue, FORMAT)
    )
    if (!isValid) {
      return AFTER_DATE_VALIDATION_MESSAGE
    }
  }
  return null
}

export const characterLimitValidation = (limit = DEFAULT_CHARACTER_LIMIT) => value =>
  value && String(value).length > limit ? ALLOWED_CHAR_LIMIT_MESSAGE : null

// TODO: Not covered yet with test
export const validateBetweenDate = (beforePath, afterPath) => (currentValue, values) => {
  const dateError = dateValidate(currentValue)
  if (dateError) {
    return dateError
  }
  const beforeValue = get(values, beforePath)
  const afterValue = get(values, afterPath)
  if (currentValue && beforeValue && afterValue) {
    const isValid = moment(moment(currentValue, FORMAT)).isBetween(
      moment(beforeValue, FORMAT),
      moment(afterValue, FORMAT)
    )
    if (!isValid) {
      return BETWEEN_DATE_VALIDATION_MESSAGE
    }
  }
  return null
}

// TODO: Not covered yet with test
export const convertToISO = value => moment(value, FORMAT).format(FORMAT)

// TODO: Not covered yet with test
export const parseCategoryOptions = activityCategories =>
  activityCategories.map(category => ({
    label: category.name,
    value: category.id
  }))

// TODO: Not covered yet with test
export const timeZoneOptions = [
  { label: 'CST (Central Standard)', value: 'CST' },
  { label: 'EST (Eastern Standard)', value: 'EST' },
  { label: 'HST (Hawaii Standard)', value: 'HST' },
  { label: 'MST (Mountain Standard)', value: 'MST' },
  { label: 'PST (Pacific Standard)', value: 'PST' }
]

// TODO: Not covered yet with test
export const findFirstPrimaryAddress = (addresses = []) =>
  find(addresses, { type: PRIMARY_ADDRESS_TYPE })

// TODO: Not covered yet with test
export const needFillAddressFields = (addresses, fields) => {
  const needFill = []
  addresses.every(({ address }) => {
    const addressData = {
      addressLine1: getPath(['addressLine1'])(address),
      addressLine2: getPath(['addressLine2'])(address),
      state: getPath(['state'])(address),
      city: getPath(['city'])(address),
      label: getPath(['label'])(address),
      zip: getPath(['zip'])(address),
      latitude: getPath(['geoLocation', 'latitude'])(address),
      longitude: getPath(['geoLocation', 'longitude'])(address)
    }
    return Object.keys(fields)
      .filter(value => fields[value])
      .forEach(value => {
        if (!addressData[value]) {
          needFill.push(value)
        }
      })
  })
  return needFill
}

// TODO: Not covered yet with test
// convert number to number.00
export const formatCostValue = (value, fixedCount = 2) => {
  const numberValue = Number(value)
  if (isNaN(numberValue)) {
    return Number(0).toFixed(fixedCount)
  }
  return numberValue.toFixed(fixedCount)
}

// TODO: Not covered yet with test
export const numberInventNotifValidate = value => {
  const numberValue = Number(value)
  if (Number(value) === 0) {
    return DECIMAL_VALIDATION_PERIOD_MESSAGE
  }
  return !numberValue || REGEX_DECIMALS_PERIOD_VALIDATION.test(numberValue)
    ? null
    : DECIMAL_VALIDATION_PERIOD_MESSAGE
}

// TODO: Not covered yet with test
export const acceptFileValidator = (accept = []) => files => {
  const formats = accept.join('|')
  if (files) {
    return Array.prototype.every.call(files, file =>
      new RegExp(`\\.(${formats})$`, 'i').test(file.name)
    )
      ? null
      : `The file should have a correct format`
  }
  return null
}

export function anyNumberValidation(value) {
  return !value || REGEX_ANY_NUMBER_VALIDATION.test(value) ? null : DECIMAL_VALIDATION_MESSAGE
}

// TODO: Not covered yet with test
export function dateInPastValidation(value, format = FORMAT) {
  return moment(value, format).isBefore(moment(), 'day') ? DATE_IN_PAST_VALIDATION_MESSAGE : null
}

// TODO: Not covered yet with test
export function dateInFutureValidation(value, format = FORMAT) {
  return moment(value, format).isAfter(moment(), 'day') ? DATE_IN_FUTURE_VALIDATION_MESSAGE : null
}

// TODO: Not covered yet with test
export function symbolCountValidation(value, count = DEFAULT_SYMBOL_COUNT) {
  return value && String(value).length > count ? ALLOWED_CHAR_LIMIT_MESSAGE : null
}

export function togglePercentageSymbol(value, isPercentage = false) {
  if (!value) {
    return ''
  }
  return !isPercentage
    ? value.replace(/[^0-9.]/g, '')
    : `${value.replace(/(\d+\.\d{2})(\d+$)/, '')} %`
}

export function validateDateRanges({ startDate, endDate }, ranges) {
  const currentRange = moment.range(moment(startDate, FORMAT), moment(endDate, FORMAT))

  const rangeError = ranges
    .filter(range => range && range.startDate && range.endDate)
    .map(range => moment.range(moment(range.startDate, FORMAT), moment(range.endDate, FORMAT)))
    .some(range => currentRange.overlaps(range))

  return rangeError ? RANGE_INTERSECTION_ERROR : null
}

export function positiveNumberValidation(value) {
  return withValidation(anyNumberValidation, v =>
    Number(v) >= 0 ? null : POSITIVE_NUMBER_VALIDATION_MESSAGE
  )(value)
}

export const validateBookingWindowField = type => value => {
  if (type === DAYS_TYPE) {
    return value && (!REGEX_NUMBER_VALIDATION.test(value) || value <= 0)
      ? GREATER_THEN_ZERO_MESSAGE
      : null
  }

  return value && (!REGEX_DECIMALS_ONE_DIGIT_VALIDATION.test(value) || value <= 0)
    ? GREATER_THEN_ZERO_MESSAGE
    : null
}

export const greaterThenZeroValidation = value =>
  value && value <= 0 ? GREATER_THEN_ZERO_MESSAGE : null
