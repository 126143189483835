/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import {
  actions as accountPasswordActions,
  getAccountPasswordStateById
} from 'reducers/account/accountPassword'
import { withAuthContext } from 'helpers/withAuthContext'
import { ChangePasswordForm as ChangePasswordFormComponent } from './ChangePasswordForm'

const mapStateToProps = (state, props) => {
  const { dataId } = props
  const accountPasswordState = getAccountPasswordStateById(state, dataId)
  return {
    changePassDataState: accountPasswordState.changePassDataState
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(accountPasswordActions, dispatch)

const ChangePasswordForm = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ChangePasswordFormComponent)

export { ChangePasswordFormComponent, ChangePasswordForm }
