/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import {
  AND_OPERATOR,
  buildSearchColumn,
  ACTIVITY,
  STRICT_MODE,
  NULL_MODE
} from 'helpers/searchHelpers'
import { STATUS_ACTIVE } from 'constants/entityStatus'
import { isNullable } from 'helpers/utilities'
import types from './types'

const { LIST_NAME } = types

const REQUEST_LIST = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

const STATUS_FIELD = 'status'
const MARKET_ID_FIELD = 'marketId'

const getRequestFilters = id => {
  const marketFilter = isNullable(id)
    ? { entity: ACTIVITY, field: MARKET_ID_FIELD, mode: NULL_MODE }
    : { entity: ACTIVITY, field: MARKET_ID_FIELD, keyword: id, mode: STRICT_MODE }
  return [
    buildSearchColumn(marketFilter),
    buildSearchColumn({
      entity: ACTIVITY,
      field: STATUS_FIELD,
      keyword: STATUS_ACTIVE,
      operator: AND_OPERATOR,
      mode: STRICT_MODE
    })
  ]
}

const getRequestOptions = options => ({ type: ACTIVITY, ...options })

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  getRequestOptions,
  getRequestFilters
}
