/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { buildPermissions } from 'modules/ActivityProducts'
import { ViewEntityButton, UpdateEntityButton } from 'components/Buttons'
import { ORANGE_SCHEMA, SMALLER_SIZE, EDIT_TITLE, VIEW_TITLE } from 'constants/buttonsOptions'
import { useSliderApi } from 'hooks/useSliderApi'
import { ACTIVITY_PRODUCT } from 'constants/formCodes'

export function EditButton(props) {
  const { authUser, data } = props
  const { canUpdate } = buildPermissions({ authUser, data: data.original })
  const dynamicTitleButton = canUpdate ? EDIT_TITLE : VIEW_TITLE
  const Button = canUpdate ? UpdateEntityButton : ViewEntityButton
  const activityProductSlider = useSliderApi(ACTIVITY_PRODUCT)

  const onOpenSlider = useCallback(() => {
    const { id, name } = data.original
    activityProductSlider.open(id, { headerProps: { title: name } })
  }, [activityProductSlider, data])

  return (
    <Button
      size={SMALLER_SIZE}
      colorSchema={ORANGE_SCHEMA}
      data={data.original}
      onClick={onOpenSlider}
    >
      {dynamicTitleButton}
    </Button>
  )
}

EditButton.propTypes = {
  authUser: PropTypes.object.isRequired,
  data: PropTypes.object
}

EditButton.defaultProps = {
  data: null
}
