/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESET_STATE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE
} from 'helpers/actionHelpers'

import types from './types'

const {
  FORM_NAME,
  DELETE_PROGRAM_ACTION,
  PROGRAM_ENTITY_DATA,
  ASSIGN_PHONE_BY_ID,
  UNASSIGN_PHONE_BY_ID,
  CREATE_EMPTY_PROGRAM
} = types

const REQUEST_FORM = `${REQUEST_TYPE}_${FORM_NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${FORM_NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${FORM_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

const REQUEST_DELETE_PROGRAM = `${REQUEST_TYPE}_${DELETE_PROGRAM_ACTION}`
const RESPONSE_DELETE_PROGRAM = `${RESPONSE_TYPE}_${DELETE_PROGRAM_ACTION}`
const RESPONSE_DELETE_PROGRAM_ERROR = `${RESPONSE_ERROR_TYPE}_${DELETE_PROGRAM_ACTION}`

const REQUEST_PROGRAM_ENTITY_DATA = `${REQUEST_TYPE}_${PROGRAM_ENTITY_DATA}`
const RESPONSE_PROGRAM_ENTITY_DATA = `${RESPONSE_TYPE}_${PROGRAM_ENTITY_DATA}`
const RESPONSE_PROGRAM_ENTITY_DATA_ERROR = `${RESPONSE_ERROR_TYPE}_${PROGRAM_ENTITY_DATA}`

const REQUEST_ASSIGN_PHONE_BY_ID = `${REQUEST_TYPE}_${ASSIGN_PHONE_BY_ID}`
const RESPONSE_ASSIGN_PHONE_BY_ID = `${RESPONSE_TYPE}_${ASSIGN_PHONE_BY_ID}`
const RESPONSE_ASSIGN_PHONE_BY_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${ASSIGN_PHONE_BY_ID}`

const REQUEST_UNASSIGN_PHONE_BY_ID = `${REQUEST_TYPE}_${UNASSIGN_PHONE_BY_ID}`
const RESPONSE_UNASSIGN_PHONE_BY_ID = `${RESPONSE_TYPE}_${UNASSIGN_PHONE_BY_ID}`
const RESPONSE_UNASSIGN_PHONE_BY_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${UNASSIGN_PHONE_BY_ID}`

const REQUEST_CREATE_EMPTY_PROGRAM = `${REQUEST_TYPE}_${CREATE_EMPTY_PROGRAM}`
const RESPONSE_CREATE_EMPTY_PROGRAM = `${RESPONSE_TYPE}_${CREATE_EMPTY_PROGRAM}`
const RESPONSE_CREATE_EMPTY_PROGRAM_ERROR = `${RESPONSE_ERROR_TYPE}_${CREATE_EMPTY_PROGRAM}`

export default {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_DELETE_PROGRAM,
  RESPONSE_DELETE_PROGRAM,
  RESPONSE_DELETE_PROGRAM_ERROR,
  REQUEST_PROGRAM_ENTITY_DATA,
  RESPONSE_PROGRAM_ENTITY_DATA,
  RESPONSE_PROGRAM_ENTITY_DATA_ERROR,
  REQUEST_ASSIGN_PHONE_BY_ID,
  RESPONSE_ASSIGN_PHONE_BY_ID,
  RESPONSE_ASSIGN_PHONE_BY_ID_ERROR,
  REQUEST_UNASSIGN_PHONE_BY_ID,
  RESPONSE_UNASSIGN_PHONE_BY_ID,
  RESPONSE_UNASSIGN_PHONE_BY_ID_ERROR,
  REQUEST_CREATE_EMPTY_PROGRAM,
  RESPONSE_CREATE_EMPTY_PROGRAM,
  RESPONSE_CREATE_EMPTY_PROGRAM_ERROR
}
