/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { initialProgramLifeCycleState } from './reducer'

export const getProgramsLifeCycleProcessState = state => state.programLifeCycleProcess

export const getProgramLifeCycleProcessState = (state, id) =>
  state.programLifeCycleProcess[id] || initialProgramLifeCycleState

export const isProgramLifeCycleProcessPending = (state, id) => {
  const currentState = getProgramLifeCycleProcessState(state, id)
  return [currentState.activateProgramDataState, currentState.deactivateProgramDataState].some(
    value => value === DATA_STATE_REQUESTING
  )
}
