/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const NAME = 'TICKET_GROUP_TEMPLATE'
export const UPLOAD_TEMPLATE = `${NAME}_UPLOAD_TEMPLATE`
export const REMOVE_TEMPLATE = `${NAME}_REMOVE_TEMPLATE`
export const DOWNLOAD_TEMPLATE = `${NAME}_DOWNLOAD_TEMPLATE`
export const DOWNLOAD_BARCODE_TEMPLATE = `${NAME}_DOWNLOAD_BARCODE_TEMPLATE`

export default {
  NAME,
  UPLOAD_TEMPLATE,
  REMOVE_TEMPLATE,
  DOWNLOAD_TEMPLATE,
  DOWNLOAD_BARCODE_TEMPLATE
}
