/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { getComponentByStep } from './helpers'
import { STEPS_COUNT } from './types'

export function APsActivation(props) {
  const { onSubmit, onHide } = props
  const [step, setStep] = useState(1)
  const Modal = useMemo(() => getComponentByStep(step), [step])

  const onConfirmAction = useCallback(() => {
    if (step < STEPS_COUNT) {
      setStep(step + 1)
    } else if (onSubmit) {
      onSubmit()
      setStep(1)
    }
  }, [onSubmit, step])

  const onHideAction = useCallback(() => {
    if (onHide) {
      onHide()
    }
    setStep(1)
  }, [onHide])

  return <Modal {...props} onClose={onHideAction} onSubmit={onConfirmAction} />
}

APsActivation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
}
APsActivation.defaultProps = {}
