/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isActionAllowed } from 'helpers/permissionHelper'
import { ADMIN_LEVEL } from 'constants/permissionTypes'

import { AddEntityButton } from 'components/Buttons'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { DefaultTemplate } from 'modules/Library'
import { useSliderApi } from 'hooks/useSliderApi'
import { buildAddButtonText, getOptionsByType } from './helpers'

export function LibraryTabContent(props) {
  const { currentType, authUser } = props
  const isAdmin = useMemo(() => isActionAllowed(ADMIN_LEVEL, authUser), [authUser])
  const { Component, showAction, sliderType } = useMemo(() => getOptionsByType(currentType), [
    currentType
  ])
  const sliderApi = useSliderApi(sliderType)
  const addButtonText = useMemo(() => buildAddButtonText(sliderType), [sliderType])
  const onAddNew = useCallback(() => {
    sliderApi.open()
  }, [sliderApi])

  return (
    <DefaultTemplate
      Actions={
        showAction &&
        isAdmin && (
          <AddEntityButton
            id="add-new-library-item-btn"
            colorSchema={ORANGE_SCHEMA}
            onClick={onAddNew}
          >
            {addButtonText}
          </AddEntityButton>
        )
      }
      {...props}
    >
      {Component && <Component />}
    </DefaultTemplate>
  )
}

LibraryTabContent.propTypes = {
  currentType: PropTypes.string.isRequired,
  authUser: PropTypes.object.isRequired
}
