export const APP_TYPES = {
  MARKETING: 'MARKETING',
  NO_RESERVATION_REQUIRED: 'NO_RESERVATION_REQUIRED',
  RESERVATION_REQUIRED_FIT: 'RESERVATION_REQUIRED_FIT'
}

export const REDEMPTION_METHOD = {
  DOWNLOAD_TICKETS: 'TICKET',
  PRESENT_PHOTO_ID: 'PHOTO_ID',
  PRESENT_CONFIRMATION_NUMBER: 'CONFIRMATION_ID'
}

export const AGREEMENT_TYPE = {
  XPLORIE_FIT: 'XPLORIE_FIT',
  PROVIDER_FIT: 'PROVIDER_FIT',
  TRADITIONAL: 'TRADITIONAL',
  HYBRID: 'HYBRID'
}

export const AGREEMENT_TYPE_VALUE = {
  XPLORIE_FIT: 'Xplorie FIT',
  PROVIDER_FIT: 'Provider FIT',
  TRADITIONAL: 'Traditional',
  HYBRID: 'Hybrid'
}
