/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { WEEK_SELECTOR_DAYS } from '@xplorie/ui-commons'
import { FORMAT } from 'constants/date'
import moment from 'moment'
import filter from 'lodash/filter'
import map from 'lodash/map'
import some from 'lodash/some'
import every from 'lodash/every'
import sortBy from 'lodash/sortBy'
import forEach from 'lodash/forEach'
import flatten from 'lodash/flatten'
import compose from 'lodash/fp/compose'

import { daysInRange, filterAvailableWeekDays, isValidRange } from '../helpers'

function formatAvailabilitiesDate(availability) {
  const { datePeriod, ...rest } = availability

  return {
    ...rest,
    datePeriod: {
      startDate: moment(datePeriod.startDate, FORMAT).format(FORMAT),
      endDate: moment(datePeriod.endDate, FORMAT).format(FORMAT)
    }
  }
}

function availabilityPeriodForEach(availability, callback) {
  const { days, datePeriod } = availability

  if (!isValidRange(datePeriod)) {
    return
  }

  const endDate = moment(datePeriod.endDate, FORMAT)

  for (
    let date = moment(datePeriod.startDate, FORMAT);
    date.isSameOrBefore(endDate);
    date.add(1, 'day')
  ) {
    const day = date.format('ddd').toUpperCase()
    if (days.includes(day)) {
      callback(date, day)
    }
  }
}

function getInitialDays() {
  return [
    WEEK_SELECTOR_DAYS.MONDAY,
    WEEK_SELECTOR_DAYS.TUESDAY,
    WEEK_SELECTOR_DAYS.WEDNESDAY,
    WEEK_SELECTOR_DAYS.THURSDAY,
    WEEK_SELECTOR_DAYS.FRIDAY,
    WEEK_SELECTOR_DAYS.SATURDAY,
    WEEK_SELECTOR_DAYS.SUNDAY
  ]
}

function hasSameDates(currentDays, days) {
  return every(currentDays, currentDay => days.includes(currentDay))
}

export function createRange(datePeriod) {
  return moment.range(moment(datePeriod.startDate, FORMAT), moment(datePeriod.endDate, FORMAT))
}

function filterByRange(availabilities, range) {
  return filter(availabilities, ({ datePeriod }) => {
    const periodRange = createRange(datePeriod)

    // Delete when current period is same include period or include period contains current period
    return (
      !periodRange.isSame(range) &&
      !range.contains(periodRange, {
        excludeStart: false,
        excludeEnd: false
      }) &&
      datePeriod.startDate &&
      datePeriod.endDate
    )
  })
}

function isSameStartDateOrContainEndDate(currentRange, nextRange) {
  return (
    nextRange.start.isSame(currentRange.start) ||
    (currentRange.contains(nextRange.end) && !currentRange.contains(nextRange.start))
  )
}

function isSameEndDateOrContainStartDate(currentRange, nextRange) {
  return (
    nextRange.end.isSame(currentRange.end) ||
    (!currentRange.contains(nextRange.end) && currentRange.contains(nextRange.start))
  )
}

export function isAvailableRangeInPeriods(range, periods) {
  const rangeDays = filterAvailableWeekDays(daysInRange(range.start, range.end), getInitialDays())
  return some(
    periods,
    ({ datePeriod, days }) =>
      hasSameDates(days, rangeDays) && createRange(datePeriod).contains(range)
  )
}

function hasSameDateInOthers(dates, date) {
  return some(dates, compareDate => compareDate.getTime() === date.getTime())
}

function appendRangeToExcludedPeriod(availabilities, diffPeriods) {
  const periodWithRange = availabilities[0]
  return diffPeriods.map(diffPeriod => ({
    ...diffPeriod,
    from: periodWithRange.from,
    to: periodWithRange.to
  }))
}

function combineAdjacentRanges(availabilities, range, onlyIncluded = true) {
  const excludeRange = moment.range(
    moment(range.startDate, FORMAT).subtract(1, 'day'),
    moment(range.endDate, FORMAT).add(1, 'day')
  )
  // find excluded and adjacent periods
  const adjacentAvailabilities = filter(availabilities, ({ datePeriod, included }) => {
    const currentRange = createRange(datePeriod)

    return (
      included === onlyIncluded &&
      (currentRange.adjacent(excludeRange) || !!currentRange.intersect(excludeRange))
    )
  })
  adjacentAvailabilities.push({
    datePeriod: range
  })

  // find all non adjacent periods
  const diffPeriods = filter(availabilities, period => !adjacentAvailabilities.includes(period))

  diffPeriods.push({
    datePeriod: {
      startDate: moment.min(
        adjacentAvailabilities.map(({ datePeriod }) => moment(datePeriod.startDate, FORMAT))
      ),
      endDate: moment.max(
        adjacentAvailabilities.map(({ datePeriod }) => moment(datePeriod.endDate, FORMAT))
      )
    },
    included: onlyIncluded,
    days: getInitialDays()
  })

  return map(appendRangeToExcludedPeriod(availabilities, diffPeriods), formatAvailabilitiesDate)
}

function appendPeriodToIntersects(range) {
  return intersectPeriods =>
    map(intersectPeriods, availability => {
      const { datePeriod, id, ...restAvailability } = availability

      const datePeriodRange = createRange(datePeriod)
      const intersectRange = datePeriodRange.intersect(range, {
        adjacent: true
      })

      // if include period starts as current - move current start date forward
      if (isSameStartDateOrContainEndDate(datePeriodRange, range)) {
        return {
          ...availability,
          datePeriod: {
            startDate: range.end.add(1, 'day'),
            endDate: moment.max(range.end, datePeriodRange.end)
          }
        }
      }

      // if include period ends as current - move current end date backward
      if (isSameEndDateOrContainStartDate(datePeriodRange, range)) {
        return {
          ...availability,
          datePeriod: {
            startDate: datePeriodRange.start,
            endDate: range.start.subtract(1, 'day')
          }
        }
      }

      return intersectRange
        ? [
            {
              ...restAvailability,
              datePeriod: {
                startDate: datePeriodRange.start,
                endDate: intersectRange.start.subtract(1, 'day')
              }
            },
            {
              ...restAvailability,
              datePeriod: {
                startDate: intersectRange.end.add(1, 'day'),
                endDate: datePeriodRange.end
              }
            }
          ]
        : availability
    })
}

function combinePeriodWithExists(availabilities, range, onlyIncluded = true) {
  const intersectPeriods = filter(availabilities, ({ datePeriod, included }) => {
    if (included === onlyIncluded) {
      return false
    }

    const datePeriodRange = createRange(datePeriod)
    const intersectRange = datePeriodRange.intersect(range, {
      adjacent: true
    })

    return (
      isSameStartDateOrContainEndDate(datePeriodRange, range) ||
      isSameEndDateOrContainStartDate(datePeriodRange, range) ||
      intersectRange
    )
  })
  const diffPeriods = filter(availabilities, period => !intersectPeriods.includes(period))

  if (intersectPeriods.length) {
    const result = compose(
      flatten,
      appendPeriodToIntersects(range)
    )(intersectPeriods)

    return [...diffPeriods, ...flatten(result)]
  }

  return diffPeriods
}

/**
 * Methods for Builder API
 */

export function configureAvailabilities(availabilities) {
  return map(availabilities, ({ startDate, endDate, days, ...rest }) => ({
    ...rest,
    days,
    datePeriod: {
      startDate,
      endDate
    }
  }))
}

export function includeAvailability(availabilities, includePeriod) {
  const includeRange = createRange(includePeriod)

  // remove same periods
  return compose(
    array => map(array, formatAvailabilitiesDate),
    array => combinePeriodWithExists(array, includeRange),
    array => filterByRange(array, includeRange)
  )(availabilities)
}

export function excludeAvailability(availabilities, excludePeriod) {
  return combineAdjacentRanges(availabilities, excludePeriod, false)
}

export const configureCalendarModifiers = availabilities => {
  const includeDates = []
  const excludeDates = []

  if (!Array.isArray(availabilities) || !availabilities.length) {
    return {}
  }

  // configure modifiers for calendar by datePeriod
  const sortByIncluded = sortBy(availabilities, item => (item.included ? 0 : 1))
  forEach(sortByIncluded, availability => {
    availabilityPeriodForEach(availability, date => {
      const nextDate = moment(date)
        .utc()
        .toDate()

      if (availability.included) {
        includeDates.push(nextDate)
        return
      }

      const hasExcludeDate = hasSameDateInOthers(includeDates, nextDate)
      if (hasExcludeDate) {
        excludeDates.push(nextDate)
      }
    })
  })

  return {
    include: filter(includeDates, includeDate => !hasSameDateInOthers(excludeDates, includeDate)),
    exclude: excludeDates
  }
}

export function getInitialPeriod() {
  return {
    from: undefined,
    to: undefined
  }
}
