/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import { actions as seasonFormActions } from 'reducers/seasonForm'
import { SeasonsList as SeasonsListComponent } from './SeasonsList'

const { cloneProgramSeasonById } = seasonFormActions

// const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = dispatch => bindActionCreators({ cloneProgramSeasonById }, dispatch)

export const SeasonsList = compose(
  connect(
    null,
    mapDispatchToProps
  )
)(SeasonsListComponent)
