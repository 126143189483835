/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const LIGHTLY_ORANGE_SCHEMA = 'lightly_orange'
export const ORANGE_SCHEMA = 'orange'
export const GRAY_SCHEMA = 'gray'
export const YELLOW_GREEN_SCHEMA = 'yellow_green'
export const WHITE_SCHEMA = 'white'
export const LIGHT_RED_SCHEMA = 'light-red'
export const LIGHT_BLUE_SCHEMA = 'light-blue'
export const LIGHT_GREEN_SCHEMA = 'light-green'
export const BLUE_SCHEMA = 'blue'
