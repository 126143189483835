/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { UNIT } from 'constants/searchTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'
import get from 'lodash/get'

import {
  LEAVING_MANAGEMENT_TYPE,
  LEAVING_MANAGEMENT_VALUE,
  DUPLICATE_TYPE,
  DUPLICATE_VALUE,
  NOT_ON_XPLORIE_TYPE,
  NOT_ON_XPLORIE_VALUE,
  OUTSIDE_MARKET_TYPE,
  OUTSIDE_MARKET_VALUE,
  EXCLUDE_FROM_PROGRAM_TYPE,
  EXCLUDE_FROM_PROGRAM_VALUE,
  CONFIRMATION_REQUIRED_TYPE,
  CONFIRMATION_REQUIRED_VALUE,
  CONFIRMED_TYPE,
  CONFIRMED_VALUE,
  SYSTEM_CONFIRMED_TYPE,
  SYSTEM_CONFIRMED_VALUE,
  SYSTEM_UPDATED_TYPE,
  SYSTEM_UPDATED_VALUE,
  PENDING_CHANGE_TYPE,
  PENDING_CHANGE_VALUE,
  LEAVING_MANAGEMENT_DESCRIPTION,
  OUTSIDE_MARKET_DESCRIPTION,
  EXCLUDE_FROM_PROGRAM_DESCRIPTION,
  CONFIRMATION_REQUIRED_DESCRIPTION,
  SYSTEM_CONFIRMED_DESCRIPTION,
  SYSTEM_UPDATED_DESCRIPTION,
  PENDING_CHANGE_DESCRIPTION
} from 'constants/units'

function getStatusNoteValue(value) {
  switch (value.statusNote) {
    case LEAVING_MANAGEMENT_TYPE:
      return LEAVING_MANAGEMENT_VALUE
    case DUPLICATE_TYPE:
      return DUPLICATE_VALUE
    case NOT_ON_XPLORIE_TYPE:
      return NOT_ON_XPLORIE_VALUE
    case OUTSIDE_MARKET_TYPE:
      return OUTSIDE_MARKET_VALUE
    case EXCLUDE_FROM_PROGRAM_TYPE:
      return EXCLUDE_FROM_PROGRAM_VALUE
    case CONFIRMATION_REQUIRED_TYPE:
      return CONFIRMATION_REQUIRED_VALUE
    case CONFIRMED_TYPE:
      return CONFIRMED_VALUE
    case SYSTEM_CONFIRMED_TYPE:
      return SYSTEM_CONFIRMED_VALUE
    case SYSTEM_UPDATED_TYPE:
      return SYSTEM_UPDATED_VALUE
    case PENDING_CHANGE_TYPE:
      return PENDING_CHANGE_VALUE
    default:
      return ''
  }
}

export function getSmartHostDisableReasonValue(value) {
  switch (value) {
    case LEAVING_MANAGEMENT_DESCRIPTION:
      return LEAVING_MANAGEMENT_VALUE
    case OUTSIDE_MARKET_DESCRIPTION:
      return OUTSIDE_MARKET_VALUE
    case EXCLUDE_FROM_PROGRAM_DESCRIPTION:
      return EXCLUDE_FROM_PROGRAM_VALUE
    case CONFIRMATION_REQUIRED_DESCRIPTION:
      return CONFIRMATION_REQUIRED_VALUE
    case SYSTEM_CONFIRMED_DESCRIPTION:
      return SYSTEM_CONFIRMED_VALUE
    case SYSTEM_UPDATED_DESCRIPTION:
      return SYSTEM_UPDATED_VALUE
    case PENDING_CHANGE_DESCRIPTION:
      return PENDING_CHANGE_VALUE
    default:
      return value
  }
}

const PMCColumn = {
  Header: 'Property Management Company',
  accessor: 'pmc',
  id: buildSortPropertyByType('pmc', UNIT)
}

const UnitNameColumn = {
  Header: 'Unit Name',
  accessor: 'name',
  id: buildSortPropertyByType('name', UNIT)
}

const PropertyTypeColumn = {
  Header: 'Property Type',
  accessor: 'type',
  id: buildSortPropertyByType('type', UNIT)
}

const AddressColumn = {
  Header: 'Address',
  accessor: 'address',
  id: buildSortPropertyByType('address', UNIT)
}

const UnitIdColumn = {
  Header: 'Unit ID',
  accessor: 'businessId',
  id: buildSortPropertyByType('businessId', UNIT)
}

const UnitAddressColumn = {
  Header: 'Unit Address',
  accessor: 'address',
  id: buildSortPropertyByType('address', UNIT)
}

const UnitCodeColumn = {
  Header: 'Unit Code',
  accessor: 'code',
  id: buildSortPropertyByType('code', UNIT)
}

const CompanyIdColumn = {
  Header: 'Company ID',
  accessor: 'serviceId',
  id: buildSortPropertyByType('pmcGateId', UNIT)
}

const SmartHostIdColumn = {
  Header: 'SmartHost ID',
  accessor: 'businessKey',
  id: 'businessKey'
}

const UnitStatusColumn = {
  Header: 'Unit Status',
  accessor: 'actualStatus',
  id: buildSortPropertyByType('actualStatus', UNIT)
}

const UnitStatusNoteColumn = {
  Header: 'Status Note',
  accessor: getStatusNoteValue,
  id: buildSortPropertyByType('statusNote', UNIT),
  pathToProperty: 'statusNote',
  valueMap: {
    [LEAVING_MANAGEMENT_TYPE]: LEAVING_MANAGEMENT_VALUE,
    [DUPLICATE_TYPE]: DUPLICATE_VALUE,
    [NOT_ON_XPLORIE_TYPE]: NOT_ON_XPLORIE_VALUE,
    [OUTSIDE_MARKET_TYPE]: OUTSIDE_MARKET_VALUE,
    [EXCLUDE_FROM_PROGRAM_TYPE]: EXCLUDE_FROM_PROGRAM_VALUE,
    [CONFIRMATION_REQUIRED_TYPE]: CONFIRMATION_REQUIRED_VALUE,
    [CONFIRMED_TYPE]: CONFIRMED_VALUE,
    [SYSTEM_CONFIRMED_TYPE]: SYSTEM_CONFIRMED_VALUE,
    [SYSTEM_UPDATED_TYPE]: SYSTEM_UPDATED_VALUE,
    [PENDING_CHANGE_TYPE]: PENDING_CHANGE_VALUE
  }
}

const StatusDateColumn = {
  Header: 'Status date',
  accessor: 'statusDate',
  id: buildSortPropertyByType('statusDate', UNIT)
}

const DeviceTypeColumn = {
  Header: 'Device type',
  accessor: 'smartHost.device.type',
  id: 'smartHost.device.type'
}

const SmartHostEnabledColumn = {
  Header: 'SmartHost Enabled',
  accessor: 'smartHost.status',
  id: 'smartHost.status'
}

const SmartHostDisableReasonColumn = {
  Header: 'SmartHost Disable Reason',
  id: 'smartHost.description',
  accessor: value => getSmartHostDisableReasonValue(get(value, 'smartHost.description')),
  pathToProperty: 'smartHost.description',
  valueMap: {
    [LEAVING_MANAGEMENT_DESCRIPTION]: LEAVING_MANAGEMENT_VALUE,
    [OUTSIDE_MARKET_DESCRIPTION]: OUTSIDE_MARKET_VALUE,
    [EXCLUDE_FROM_PROGRAM_DESCRIPTION]: EXCLUDE_FROM_PROGRAM_VALUE,
    [CONFIRMATION_REQUIRED_DESCRIPTION]: CONFIRMATION_REQUIRED_VALUE,
    [SYSTEM_CONFIRMED_DESCRIPTION]: SYSTEM_CONFIRMED_VALUE,
    [SYSTEM_UPDATED_DESCRIPTION]: SYSTEM_UPDATED_VALUE,
    [PENDING_CHANGE_DESCRIPTION]: PENDING_CHANGE_VALUE
  }
}

const SmartHostDateColumn = {
  Header: 'SmartHost Date',
  accessor: 'smartHost.date',
  id: 'smartHost.date'
}

export const CalendarIntegrationColumn = {
  columnName: 'Calendar Integration',
  pathToProperty: 'smartHost.calendarIntegration'
}

export const EmailCadenceColumn = {
  columnName: 'Email Cadence',
  pathToProperty: 'smartHost.emailCadence'
}

const AmenitiesColum = {
  Header: 'Amenities',
  accessor: 'amenities',
  id: buildSortPropertyByType('amenities', UNIT)
}

export const unitTableColumns = [
  UnitNameColumn,
  UnitIdColumn,
  UnitAddressColumn,
  CompanyIdColumn,
  UnitStatusColumn,
  UnitStatusNoteColumn,
  StatusDateColumn
]

export const exportUnitTableColumns = [
  PMCColumn,
  UnitNameColumn,
  PropertyTypeColumn,
  AddressColumn,
  UnitIdColumn,
  UnitCodeColumn,
  CompanyIdColumn,
  UnitStatusColumn,
  UnitStatusNoteColumn,
  StatusDateColumn,
  SmartHostIdColumn,
  AmenitiesColum
]

export const unitWithSmartHostTableColumns = [
  ...exportUnitTableColumns,
  DeviceTypeColumn,
  SmartHostEnabledColumn,
  SmartHostDisableReasonColumn,
  SmartHostDateColumn
]

export const exportUnitsColumns = [
  ...exportUnitTableColumns.map(column => ({
    columnName: column.Header,
    pathToProperty: column.pathToProperty || column.accessor,
    valueMap: column.valueMap
  }))
]

export const exportUnitsWithSmartHostColumns = [
  ...unitWithSmartHostTableColumns.map(column => ({
    columnName: column.Header,
    pathToProperty: column.pathToProperty || column.accessor,
    valueMap: column.valueMap
  }))
]
