/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getCountriesTree as getCountriesTreeRequest } from 'services/http/locations'
import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_LIST_ERROR, buildStatesTreeByCountries } = helpers

const getCountriesTree = (authKey, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))

  const request = await getCountriesTreeRequest(authKey, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_LIST_ERROR))
  })

  if (request) {
    const statesTreeByCountries = buildStatesTreeByCountries(request.data)

    dispatch(actionCreater(RESPONSE_LIST, statesTreeByCountries))

    return 'getCountriesTree success'
  }

  return 'getCountriesTree failed'
}

export default getCountriesTree
