/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CloseIcon } from 'icons'

import styles from './AssignedEligibilityTable.scss'

export function DeleteButton({ onClick, disabled }) {
  const onClickHandler = useCallback(
    e => {
      if (onClick && !disabled) {
        onClick(e)
      }
    },
    [disabled, onClick]
  )

  return (
    <div className={styles.deleteButtonWrapper}>
      <CloseIcon
        dynamicClassName={classNames(styles.deleteIcon, { [styles.disabled]: disabled })}
        onClick={onClickHandler}
      />
    </div>
  )
}
DeleteButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}
DeleteButton.defaultProps = {
  onClick: null,
  disabled: false
}
