/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback } from 'react'

import { UPDATE_LEVEL } from 'constants/permissionTypes'
import { accountTableColumns, buildDefaultButtonColumn } from 'helpers/dataTablesHelpers'
import { isActionAllowed } from 'helpers/permissionHelper'
import { ViewEntityButton, UpdateEntityButton } from 'components/Buttons'
import { ORANGE_SCHEMA, SMALLER_SIZE, EDIT_TITLE, VIEW_TITLE } from 'constants/buttonsOptions'
import { useSliderApi } from 'hooks/useSliderApi'
import { ACCOUNT } from 'constants/formCodes'

export function TableColumns(props, authUser) {
  const canUpdate = isActionAllowed(UPDATE_LEVEL, authUser)
  const TITLE = canUpdate ? EDIT_TITLE : VIEW_TITLE
  const Button = canUpdate ? UpdateEntityButton : ViewEntityButton
  const programSlider = useSliderApi(ACCOUNT)

  const onEditClick = useCallback(
    data => () => {
      const { id, identityId } = data.original
      programSlider.open(id, {
        headerProps: { title: identityId },
        props: { formData: data.original }
      })
    },
    [programSlider]
  )

  return accountTableColumns.concat({
    ...buildDefaultButtonColumn(),
    Cell: data => (
      <Button size={SMALLER_SIZE} colorSchema={ORANGE_SCHEMA} onClick={onEditClick(data)}>
        {TITLE}
      </Button>
    )
  })
}

export default TableColumns
