/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { usePrevious } from 'hooks'
import get from 'lodash/get'
import classNames from 'classnames'
import { asField } from 'informed'
import { Field } from 'components/FormComponents'
import styles from './FileField.scss'

function FileInputWrapper(props) {
  const {
    fieldState: { error, value },
    fieldApi: { setValue },
    fieldApi,
    fieldState,
    label,
    isRequired,
    fieldProps,
    children,
    id,
    placeholder,
    className,
    disabled,
    forwardedRef,
    onChange,
    multiple,
    preValidate,
    getFileName,
    ...rest
  } = props
  const inputId = `file-upload-${id}`
  const inputRef = useRef()
  const prevValue = usePrevious(value)
  const accepts = rest.accept && rest.accept.split(',')

  const onSetValue = async (files, event) => {
    let filesList = null
    if (preValidate) {
      filesList = await preValidate(files)
    }
    setValue(filesList)
    onChange(filesList, event)
  }

  const onDropHandler = ev => {
    ev.preventDefault()
    let { files } = ev.dataTransfer
    if (accepts) {
      files = [...files].filter(item =>
        accepts.some(accept => item.name.endsWith(accept) || item.type === accept)
      )
    }
    onSetValue(files, ev)
  }

  const onDragOverHandler = ev => {
    ev.preventDefault()
  }

  const onChangeHandler = ev => {
    onSetValue(ev.target.files, ev)
  }

  const visibleFileName = () => {
    const firstFileName = get(value, '0.name')

    if (multiple) {
      return placeholder
    }

    if (firstFileName) {
      return firstFileName
    }

    if (getFileName) {
      return getFileName(value) || placeholder
    }

    return placeholder
  }

  // reset input value
  useEffect(() => {
    if (prevValue !== value && prevValue && !value) {
      inputRef.current.value = ''
    }
  }, [prevValue, value])

  const inputText = visibleFileName()

  return (
    <Field id={id} label={label} isRequired={isRequired} error={error} {...fieldProps}>
      <label
        htmlFor={inputId}
        className={classNames(styles.customFileUpdalod, { [styles.disabled]: disabled }, className)}
        onDrop={onDropHandler}
        onDragOver={onDragOverHandler}
      >
        {inputText}
      </label>
      <input
        ref={inputRef}
        className={styles.fileInput}
        id={inputId}
        disabled={disabled}
        onChange={onChangeHandler}
        multiple={multiple}
        {...rest}
      />
      {children}
    </Field>
  )
}

FileInputWrapper.propTypes = {
  fieldApi: PropTypes.object.isRequired,
  children: PropTypes.object,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  fieldState: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  fieldProps: PropTypes.object,
  id: PropTypes.sting,
  placeholder: PropTypes.sting,
  className: PropTypes.sting,
  disabled: PropTypes.bool,
  forwardedRef: PropTypes.object,
  multiple: PropTypes.bool,
  preValidate: PropTypes.func,
  getFileName: PropTypes.func
}

FileInputWrapper.defaultProps = {
  onChange: () => null,
  onFocus: () => null,
  children: null,
  className: '',
  placeholder: null,
  isRequired: false,
  preValidate: null,
  fieldApi: {},
  fieldState: {},
  fieldProps: {},
  multiple: false,
  disabled: false,
  forwardedRef: null,
  getFileName: null,
  label: '',
  id: ''
}

export const FileInput = asField(FileInputWrapper)
