/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { searchByColumns } from 'services/http/search'
import {
  buildSearchColumn,
  PM_COMPANY,
  PROGRAM,
  STRICT_MODE,
  AND_OPERATOR
} from 'helpers/searchHelpers'
import { STATUS_ACTIVE } from 'constants/entityStatus'
import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST, RESET_DATA_STATE } = helpers

const getPmcProgramsById = (pmcId, options) => async dispatch => {
  const key = pmcId
  dispatch(actionCreater(REQUEST_LIST, { key }))
  const request = await await searchByColumns(
    [
      buildSearchColumn({
        entity: PM_COMPANY,
        field: 'pmCompanyId',
        keyword: pmcId,
        mode: STRICT_MODE
      }),
      buildSearchColumn({
        entity: PROGRAM,
        field: 'status',
        keyword: STATUS_ACTIVE,
        mode: STRICT_MODE,
        operator: AND_OPERATOR
      })
    ],
    { type: PROGRAM, ...options }
  ).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { ...request.data, key }))
    return 'getProgramsByPmcId success'
  }

  return 'getProgramsByPmcId failed'
}

const resetList = pmcId => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key: pmcId }))

export default {
  getPmcProgramsById,
  resetList
}
