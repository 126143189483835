/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { getProgramDataState } from 'reducers/programsForm'
import { actions, selectors } from 'reducers/program/programMaterialsForm'
import { withAuthContext } from 'helpers/withAuthContext'
import { MarketingMaterialsForm as MarketingMaterialsFormComponent } from './MarketingMaterialsForm'

const { updateMaterialsForm } = actions
const { getProgramMarketingFormStateById } = selectors

function mapStateToProps(state, { dataId }) {
  const marketingState = getProgramMarketingFormStateById(state, dataId)
  const programFormState = getProgramDataState(state, dataId)
  const { programData } = programFormState
  const { formData, dataState, formDataState } = marketingState
  return { programData, formData, formDataState, dataState }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMaterialsForm
    },
    dispatch
  )
}

export const MarketingMaterialsForm = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MarketingMaterialsFormComponent)
