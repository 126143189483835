/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_ASSIGN_UNITS,
  RESPONSE_ASSIGN_UNITS,
  RESPONSE_ERROR_ASSIGN_UNITS,
  REQUEST_UNASSIGN_UNITS,
  RESPONSE_UNASSIGN_UNITS,
  RESPONSE_ERROR_UNASSIGN_UNITS,
  RESET_DATA_STATE
} = helpers

export const initialState = {}

export const programInitialState = {
  assignUnitsDataState: DATA_STATE_NOT_REQUESTED,
  unassignUnitsDataState: DATA_STATE_NOT_REQUESTED
}

const programUnitsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ASSIGN_UNITS:
      return {
        ...state,
        [action.payload.key]: {
          ...programInitialState,
          ...state[action.payload.key],
          assignUnitsDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_ASSIGN_UNITS:
      return {
        ...state,
        [action.payload.key]: {
          ...programInitialState,
          ...state[action.payload.key],
          assignUnitsDataState: DATA_STATE_RECIEVED
        }
      }
    case RESPONSE_ERROR_ASSIGN_UNITS:
      return {
        ...state,
        [action.payload.key]: {
          ...programInitialState,
          ...state[action.payload.key],
          assignUnitsDataState: DATA_STATE_ERROR
        }
      }
    // unassign
    case REQUEST_UNASSIGN_UNITS:
      return {
        ...state,
        [action.payload.key]: {
          ...programInitialState,
          ...state[action.payload.key],
          unassignUnitsDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_UNASSIGN_UNITS:
      return {
        ...state,
        [action.payload.key]: {
          ...programInitialState,
          ...state[action.payload.key],
          unassignUnitsDataState: DATA_STATE_RECIEVED
        }
      }
    case RESPONSE_ERROR_UNASSIGN_UNITS:
      return {
        ...state,
        [action.payload.key]: {
          ...programInitialState,
          ...state[action.payload.key],
          unassignUnitsDataState: DATA_STATE_ERROR
        }
      }
    case RESET_DATA_STATE:
      return (() => {
        const { [action.payload.key]: removedState, ...restState } = state
        return {
          ...restState
        }
      })()
    default:
      return state
  }
}

export default filterActions(programUnitsListReducer, [
  REQUEST_ASSIGN_UNITS,
  RESPONSE_ASSIGN_UNITS,
  RESPONSE_ERROR_ASSIGN_UNITS,
  RESET_DATA_STATE,
  REQUEST_UNASSIGN_UNITS,
  RESPONSE_UNASSIGN_UNITS,
  RESPONSE_ERROR_UNASSIGN_UNITS
])
