/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'

export function InfoIcon(props) {
  return (
    <svg width="100" height="100" x="0px" y="0px" viewBox="0 0 100 100" {...props}>
      <ellipse
        ry="35.5"
        rx="35.5"
        cy="249.64"
        cx="10.84"
        strokeWidth="1.5"
        transform="matrix(1.26,0,0,1.26,36.35,-264.34)"
      />
      <g transform="matrix(1.11,0,0,1.11,0,0)">
        <path d="m39.06,45.06c1.105,0 2,0.895 2,2l0,8c0,1.105 -0.895,2 -2,2l-2,0l0,4l16,0l0,-4l-1.992,0c-1.102,0 -2,-0.895 -2,-2l-0.008,-14l-12,0l0,4l2,0z" />
        <circle cx="45.06" cy="33.06" r="4" />
      </g>
    </svg>
  )
}
