/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'

import helpers from './helpers'

const { ENQUEUE_MODAL, DEQUEUE_MODAL, RESET_ALL_MODALS, generateId } = helpers

const enqueue = (Component, props) => dispatch => {
  const key = generateId()
  dispatch(actionCreater(ENQUEUE_MODAL, { key, Component, props }))
  return key
}

const dequeue = modalId => dispatch => {
  dispatch(actionCreater(DEQUEUE_MODAL, { key: modalId }))
}

const resetAll = () => dispatch => {
  dispatch(actionCreater(RESET_ALL_MODALS))
}

export default { enqueue, dequeue, resetAll }
