/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */
export const TITLE_PAGE = 'Campaigns'
export const TEXT_BTN_ADD_NEW = 'Add New'

export const EXPORT_FILENAME = 'campaigns_list.csv'
export const NEW_CAMPAIGN_TITLE = 'New Campaign'
