/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildURLForGettingEntityById } from 'services/http/helpers'
import { RMS_URL } from 'endpoints'

export const createEntity = contractData =>
  httpWrapper({
    url: RMS_URL,
    method: 'post',
    data: contractData
  })

export const updateEntity = contractData =>
  httpWrapper({
    url: RMS_URL,
    method: 'put',
    data: contractData
  })

export const getEntityById = (id, params) =>
  httpWrapper({
    url: buildURLForGettingEntityById(RMS_URL, id),
    method: 'get',
    params
  })

export const deleteEntityById = (id, params) =>
  httpWrapper({
    url: buildURLForGettingEntityById(RMS_URL, id),
    method: 'delete',
    params
  })
