/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { BouncingLoader } from 'components/Loaders'

export function LoaderWrapper(props) {
  const { children, isLoading, Loader } = props
  return isLoading ? <Loader /> : children
}

LoaderWrapper.propTypes = {
  children: PropTypes.node,
  Loader: PropTypes.func,
  isLoading: PropTypes.bool
}

LoaderWrapper.defaultProps = {
  Loader: BouncingLoader,
  isLoading: false,
  children: null // TODO: Need check
}
