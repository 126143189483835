/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { ACTIVITY } from 'constants/searchTypes'

export const RATE_ADJUSTMENT_LIST = 'RATE_ADJUSTMENT'
export const SET_RATE_MODE = 'SET_RATE_MODE'
export const ENTITY_TYPE = ACTIVITY

export const SUCCESS_RATE_ADJUSTMENT_MESSAGE = 'Rate Adjustments are successfully added'
