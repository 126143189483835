/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

export const FALLBACK_MESSAGE = 'Sorry, something went wrong :('

export const GO_HOMEPAGE = 'Go to homepage'
