/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useLoading, useUpdateSomeDataState } from 'hooks'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { getProgramUnitsListState } from 'reducers/units/programUnitsList'
import { PIECurtainLoader } from 'components/Loaders'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { UnitsList } from 'modules/Units'
import { buildTableColumns } from './helpers'

export function SmartHostUnitsList(props) {
  const { columns, actions, programData, getApi, onGetData, ...rest } = props
  const dataId = useMemo(() => get(programData, 'id'), [programData])
  const { smartHostStatusDataState } = useSelector(state => getProgramUnitsListState(state, dataId))
  const [isSmartHostStatusUpdating] = useLoading(smartHostStatusDataState)

  const listColumns = useMemo(() => buildTableColumns({ programData, dataId, columns, actions }), [
    columns,
    dataId,
    programData,
    actions
  ])

  const listApi = useRef()

  const onGetListApi = useCallback(
    api => {
      listApi.current = api
      if (getApi) {
        getApi(api)
      }
    },
    [getApi]
  )
  useUpdateSomeDataState(
    () => {
      onGetData(listApi.current.getListParameters())
    },
    [smartHostStatusDataState],
    DATA_STATE_RECIEVED
  )

  return (
    <>
      {isSmartHostStatusUpdating && <PIECurtainLoader />}
      <UnitsList getApi={onGetListApi} columns={listColumns} onGetData={onGetData} {...rest} />
    </>
  )
}

SmartHostUnitsList.propTypes = {
  columns: PropTypes.array.isRequired,
  programData: PropTypes.object.isRequired,
  onGetData: PropTypes.func.isRequired,
  getApi: PropTypes.func,
  actions: PropTypes.array
}
SmartHostUnitsList.defaultProps = {
  getApi: null,
  actions: []
}
