/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const MODAL_TITLE = 'Warning!'

export const DEACTIVATION_MESSAGE_START = `Please select a date when you want `
export const DEACTIVATION_MESSAGE_END = ` to become inactive.`

export const DATE_INPUT_LABEL = 'Deactivation Date'
export const DATE_INPUT_PLACEHOLDER = 'Deactivation Date'

export const DATE_FIELD = 'date'

export const PICK_TODAY_BUTTON_TEXT = 'Pick today'
export const DEACTIVATE_TEXT = 'Deactivate'
export const DECLINE_TEXT = 'Decline'
export const UPDATE_TEXT = 'Update'
export const CANCEL_TEXT = 'Cancel Deactivation'
