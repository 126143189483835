/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

const NAVIGATION_TYPE = 'NAVIGATION_TYPE'

export default {
  NAVIGATION_TYPE
}
