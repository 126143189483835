/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ContentWithNav } from 'components/ContentWithNav'
import withAuthorization from 'helpers/withAuthorization'
import { HeaderWrapper, ButtonsWrapper } from 'components/Wrappers'
import { AddEntityButton } from 'components/Buttons'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { LEFT_ALIGN, RIGHT_ALIGN } from 'constants/alignTypes'
import { ChangePasswordForm } from 'modules/ChangePasswordForm'
import { ProfileInfo } from 'modules/Profile'
import { ResetCommunication } from 'modules/ResetCommunication'
import { CheckTicketInventory } from 'modules/CheckTicketInventory'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { isActionAllowed } from 'helpers/permissionHelper'
import { USER_LEVEL } from 'constants/permissionTypes'
import { useSliderApi } from 'hooks/useSliderApi'
import { ACCOUNT } from 'constants/formCodes'

import {
  TITLE_PROFILE_DATA_AREA,
  TITLE_PAGE,
  UPDATE_PROFILE_BUTTON,
  CHECK_TICKET_INVENTORY_BUTTON,
  RESET_COMMUNICATION_BUTTON
} from './types'
import styles from './profile.scss'

function Profile(props) {
  const { profileData, profileDataState, authUser } = props
  const isDisabledButton = profileDataState !== DATA_STATE_RECIEVED
  const isVisibleBtn = isActionAllowed(USER_LEVEL, authUser)
  const programSlider = useSliderApi(ACCOUNT)

  const onEditClick = useCallback(() => {
    const { id, identityId } = profileData
    programSlider.open(id, { headerProps: { title: identityId }, props: { formData: profileData } })
  }, [programSlider, profileData])

  return (
    <ContentWithNav>
      <section className={styles.customWrapper}>
        <HeaderWrapper title={TITLE_PAGE} />
        <div className={styles.containerWrapper}>
          {isVisibleBtn && (
            <ButtonsWrapper align={RIGHT_ALIGN}>
              <Column>
                <AddEntityButton
                  id="update-profile-btn"
                  onClick={onEditClick}
                  colorSchema={ORANGE_SCHEMA}
                  disabled={isDisabledButton}
                >
                  {UPDATE_PROFILE_BUTTON}
                </AddEntityButton>
              </Column>
            </ButtonsWrapper>
          )}
          <div className="container">
            <div className="tile is-ancestor">
              <div className="tile is-vertical is-7">
                <div className="tile">
                  <div className="tile is-parent is-vertical">
                    <article
                      className={classNames('tile', 'is-child', 'is-light', styles.profileData)}
                    >
                      <h2 className="title is-5">{TITLE_PROFILE_DATA_AREA}</h2>
                      <ProfileInfo />
                      <div className={styles.syncButtonWrapper}>
                        <ButtonsWrapper align={LEFT_ALIGN}>
                          <Column>
                            <CheckTicketInventory>
                              {CHECK_TICKET_INVENTORY_BUTTON}
                            </CheckTicketInventory>
                            <ResetCommunication>{RESET_COMMUNICATION_BUTTON}</ResetCommunication>
                          </Column>
                        </ButtonsWrapper>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              <ChangePasswordForm />
            </div>
          </div>
        </div>
      </section>
    </ContentWithNav>
  )
}
Profile.propTypes = {
  profileData: PropTypes.object,
  authUser: PropTypes.object.isRequired,
  profileDataState: PropTypes.string.isRequired
}
Profile.defaultProps = {
  profileData: {}
}
export default withAuthorization(Profile)
