/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { TableField, FilteredSearch } from 'modules/FilteredSearch'
import { ProgramsList } from 'modules/Programs/ProgramsList'
import { FormFilter } from 'components/FormComponents'
import { buildFilters } from 'containers/Programs/Programs/helpers'
import { PROGRAM } from 'constants/searchTypes'
import { programsTableColumns } from 'helpers/dataTablesHelpers'
import { DotsButtonWithExport } from 'modules/ExportEntity'
import {
  PROGRAM_FORM_FILTERS,
  PROGRAM_INITIAL_FILTERS,
  EXPORT_FILENAME
} from 'containers/Programs/Programs/types'

import styles from './ProgramsSearchResults.scss'

export function ProgramsSearchResults(props) {
  const { onChange, data, state, dataState, itemsPerPage, pageNumber, itemsCount, enqueue } = props
  const filteredSearchApi = useRef()

  const onChangeHandler = useCallback(
    ({ filters, params }) => {
      onChange({
        parameters: params,
        filters: buildFilters(filters)
      })
    },
    [onChange]
  )

  const onGetDataHandler = useCallback(
    params => {
      onChange({ parameters: params })
    },
    [onChange]
  )
  const onGetApi = useCallback(api => {
    filteredSearchApi.current = api
  }, [])

  const getFilters = useCallback(() => buildFilters(filteredSearchApi.current.getFilters()), [])
  const getParams = useCallback(
    () => ({ ...state.parameters, ...filteredSearchApi.current.getParams() }),
    [state.parameters]
  )

  return (
    <FilteredSearch
      getApi={onGetApi}
      onChange={onChangeHandler}
      initialFilters={PROGRAM_INITIAL_FILTERS}
    >
      <div className={styles.filterBarWrapper}>
        <FormFilter filters={PROGRAM_FORM_FILTERS} />
        <DotsButtonWithExport
          onGetFilters={getFilters}
          onGetParams={getParams}
          entity={PROGRAM}
          columns={programsTableColumns}
          filename={EXPORT_FILENAME}
        />
      </div>
      <TableField
        Component={ProgramsList}
        enqueue={enqueue}
        dataState={dataState}
        onGetData={onGetDataHandler}
        data={data}
        itemsPerPage={itemsPerPage}
        itemsCount={itemsCount}
        pageNumber={pageNumber}
        // TODO: Need adjust and refactor it with reimplement this functionality.
        withBasicAuth={false}
        needFirstGetData={false}
      />
    </FilteredSearch>
  )
}

ProgramsSearchResults.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array,
  dataState: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number,
  itemsCount: PropTypes.number,
  pageNumber: PropTypes.number,
  enqueue: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired
}

ProgramsSearchResults.defaultProps = {
  data: [],
  itemsCount: 0,
  itemsPerPage: 10,
  pageNumber: 1
}
