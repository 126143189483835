/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef, useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import classNames from 'classnames'
import { Form } from 'informed'
import { SelectField } from 'components/FormComponents'
import { AssignedEligibilityTable } from 'modules/Eligibility'
import { buildEligibilityOptions, createValue } from './helpers'

import styles from './TableFormEligibility.scss'

export function TableFormEligibility({
  eligibilityParams,
  selectProps,
  getApi,
  isHidden,
  showSelect,
  disabled,
  onChange,
  showLevelColumn,
  ...rest
}) {
  const tableApi = useRef()
  const formApi = useRef()
  const eligibilityRef = useRef()

  const [eligibilityOptions, setEligibilityOptions] = useState([])

  const onGetTableApi = useCallback(
    api => {
      tableApi.current = api
      if (getApi) {
        getApi(api)
      }
    },
    [getApi]
  )

  const prepareEligibilityOptions = useCallback(() => {
    setEligibilityOptions(buildEligibilityOptions(eligibilityRef.current))
  }, [])

  const onGetGetApi = useCallback(api => {
    formApi.current = api
  }, [])

  const onSelectHandler = useCallback(
    value => {
      const newValue = find(eligibilityParams, ['id', value])
      if (newValue) {
        tableApi.current.addValue(createValue(newValue))
        formApi.current.reset()
      }
    },
    [eligibilityParams]
  )

  const onChangeHandler = useCallback(
    (...args) => {
      if (onChange) {
        onChange(...args)
      }
      prepareEligibilityOptions()
    },
    [onChange, prepareEligibilityOptions]
  )

  useEffect(() => {
    eligibilityRef.current = eligibilityParams
    prepareEligibilityOptions()
  }, [eligibilityParams, prepareEligibilityOptions])

  return (
    <div
      className={classNames(styles.formWrapper, { [styles.hidden]: isHidden })}
      data-testid="tableFormEligibility"
    >
      {showSelect && (
        <Form getApi={onGetGetApi}>
          <SelectField
            fieldName="eligibility"
            options={eligibilityOptions}
            onSelectHandler={onSelectHandler}
            disabled={disabled}
            {...selectProps}
          />
        </Form>
      )}
      <div className={styles.tableWrapper}>
        <AssignedEligibilityTable
          getApi={onGetTableApi}
          disabled={disabled}
          onChange={onChangeHandler}
          showLevelColumn={showLevelColumn}
          {...rest}
        />
      </div>
    </div>
  )
}
TableFormEligibility.propTypes = {
  eligibilityParams: PropTypes.array,
  selectProps: PropTypes.object,
  getApi: PropTypes.func,
  isHidden: PropTypes.bool,
  showSelect: PropTypes.bool,
  showLevelColumn: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}
TableFormEligibility.defaultProps = {
  eligibilityParams: [],
  selectProps: {},
  getApi: null,
  isHidden: false,
  showLevelColumn: false,
  showSelect: true,
  disabled: false,
  onChange: null
}
