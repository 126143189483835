/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

const UNIT_TASKS_LIST_NAME = 'UNIT_TASKS_LIST'
const UNIT_TASKS_LIST_COUNT_NAME = 'UNIT_TASKS_LIST_COUNT'
const UNIT_TASKS_EXPORT = 'UNIT_TASKS_EXPORT'
const DEFAULT_PAGE_NUMBER = 1
const DEFAULT_ITEMS_PER_PAGE = 10

export default {
  UNIT_TASKS_LIST_NAME,
  UNIT_TASKS_LIST_COUNT_NAME,
  UNIT_TASKS_EXPORT,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ITEMS_PER_PAGE
}
