/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { UnitsList } from 'modules/Units'
import { buildDefaultButtonColumn } from 'helpers/dataTablesHelpers'
import { CheckBoxDropdownButton } from 'components/Buttons'
import { CheckBoxButton } from '@xplorie/ui-commons'
import { STATUS_ACTIVE, STATUS_INELIGIBLE, STATUS_INACTIVE } from 'constants/entityStatus'
import { buildUnitsColumns } from 'modules/Units/UnitsList/helpers'
import { useUpdateSomeDataState } from 'hooks'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { ListStats } from './ListStats'
import {
  dropdownOptions,
  ACTIVE_OPTION_LABEL,
  INACTIVE_OPTION_LABEL,
  INELIGIBLE_OPTION_LABEL
} from './types'
import { checkUnit, isStatusEqual } from './helpers'

import styles from './ProgramUnitsTable.scss'

export function ProgramUnitsTable(props) {
  const {
    dataId,
    onGetData,
    data,
    dataState,
    itemsPerPage,
    itemsCount,
    pageNumber,
    onCheck,
    onTaskCheck,
    checked,
    Header,
    programId,
    pmcData,
    getApi,
    columns,
    ListComponent,
    checkedTasks,
    ...restProps
  } = props

  const [isChecked, setIsChecked] = useState(false)

  const listApi = useRef()

  const unitTableColumns = columns || (pmcData && buildUnitsColumns(pmcData.pmSystem, programId))

  const onGetUnits = (options = {}) => {
    onGetData({ dataId, programId, options })
  }

  const onListParameterChanged = options => {
    onGetUnits({ ...options })
  }

  const unCheckAll = () => {
    onCheck({})
    onTaskCheck([])
  }
  const checkAll = () => {
    onCheck({
      ...data.reduce(checkUnit, {})
    })
    onTaskCheck(data.map(i => i.taskId))
  }

  const onToggleCheck = () => {
    if (isChecked) {
      unCheckAll()
    } else {
      checkAll()
    }
  }

  const onCheckByCategory = value => {
    const { label } = value
    let checkedOptions = []
    switch (label) {
      case ACTIVE_OPTION_LABEL:
        checkedOptions = data.filter(isStatusEqual(STATUS_ACTIVE))
        break
      case INACTIVE_OPTION_LABEL:
        checkedOptions = data.filter(isStatusEqual(STATUS_INACTIVE))
        break
      case INELIGIBLE_OPTION_LABEL:
        checkedOptions = data.filter(isStatusEqual(STATUS_INELIGIBLE))
        break
      default:
        throw new Error('type not found')
    }
    onCheck({
      ...checkedOptions.reduce(checkUnit, {})
    })
    onTaskCheck(checkedOptions.map(i => i.taskId))
  }

  const onCheckOne = unitData => () => {
    onCheck({ ...checked, [unitData.id]: checked[unitData.id] ? null : unitData.actualStatus })
    const checkedUnitTasks = checkedTasks.includes(unitData.taskId)
      ? checkedTasks.filter(id => id !== unitData.taskId)
      : [...checkedTasks, unitData.taskId]
    onTaskCheck(checkedUnitTasks)
  }

  useEffect(() => {
    setIsChecked(
      Object.keys(checked).length && Object.keys(checked).some(id => Boolean(checked[id]))
    )
  }, [checked])

  const onGetListApi = useCallback(
    api => {
      listApi.current = api
      if (getApi) {
        getApi(api)
      }
    },
    [getApi]
  )

  useUpdateSomeDataState(
    () => {
      unCheckAll()
    },
    [dataState],
    DATA_STATE_RECIEVED
  )

  return (
    <div className={styles.listWrapper}>
      <div className={styles.header}>{Header}</div>
      <div className={styles.body}>
        <ListComponent
          getApi={onGetListApi}
          data={data}
          dataState={dataState}
          itemsPerPage={itemsPerPage}
          itemsCount={itemsCount}
          pageNumber={pageNumber}
          showPaginationBottom={false}
          onGetData={onListParameterChanged}
          columns={[
            {
              ...buildDefaultButtonColumn({ width: 40 }),
              Header: () => (
                <CheckBoxDropdownButton
                  id={`${dataId}_${programId}`}
                  value={isChecked}
                  onClick={onToggleCheck}
                  onOptionClick={onCheckByCategory}
                  options={dropdownOptions}
                />
              ),
              accessor: 'checkbox',
              sortable: false,
              resizable: false,
              Cell: columnData => (
                <div className={styles.checkboxWrapper}>
                  <CheckBoxButton
                    id={`${dataId}_${columnData.original.id}`}
                    value={Boolean(checked[columnData.original.id])}
                    onClick={onCheckOne(columnData.original)}
                  />
                </div>
              )
            },
            ...unitTableColumns.map(value => ({
              ...value,
              getProps: (state, rowInfo) => ({
                style: { cursor: 'pointer' },
                onClick: () => {
                  onCheckOne(rowInfo.original)()
                }
              })
            }))
          ]}
          // TODO: Need adjust and refactor it with reimplement this functionality.
          showHeader={false}
          {...restProps}
        />
        <ListStats data={data} checked={checked} />
      </div>
    </div>
  )
}

ProgramUnitsTable.propTypes = {
  dataId: PropTypes.string,
  onGetData: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  dataState: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  onCheck: PropTypes.func,
  checked: PropTypes.object.isRequired,
  Header: PropTypes.func.isRequired,
  programId: PropTypes.string,
  getApi: PropTypes.func,
  pmcData: PropTypes.object.isRequired,
  columns: PropTypes.array,
  ListComponent: PropTypes.func,
  checkedTasks: PropTypes.array,
  onTaskCheck: PropTypes.func
}
ProgramUnitsTable.defaultProps = {
  dataId: null,
  onCheck: null,
  Header: null,
  programId: null,
  getApi: null,
  columns: null,
  checkedTasks: [],
  onTaskCheck: () => {},
  ListComponent: UnitsList
}
