/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const BLOCK_NAME = 'ACCOUNT_SETTINGS'
export const GET_ENTITY_NAME = 'GET_ACCOUNT_SETTINGS'

export default { BLOCK_NAME, GET_ENTITY_NAME }
