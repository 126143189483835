/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useContext } from 'react'
import { RateFormContext } from '../types'
import { RateFormBox } from '../RateFormBox'

function RateEditMode() {
  const { rates } = useContext(RateFormContext)

  // eslint-disable-next-line react/no-array-index-key
  return rates.map((item, index) => <RateFormBox key={index} index={index} item={item} />)
}

export default RateEditMode
