/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'

import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import { YELLOW_GREEN_SCHEMA, ORANGE_SCHEMA, YES_TITLE, NO_TITLE } from 'constants/buttonsOptions'

import { TITLE, MESSAGE } from '../types'

import styles from '../AppDeactivation.scss'

export function InitialModal(props) {
  const { isOpen, appName, onClose, onSubmit } = props
  const appNameCleared = appName.trim()

  return (
    <BasicModal
      isOpen={isOpen}
      title={TITLE}
      onClose={onClose}
      renderBody={() => (
        <section className={styles.wrapper}>
          <div className={styles.messageWrapper}>
            <span className={styles.mainMessage}>{MESSAGE}</span>
            <span className={styles.appName}>{appNameCleared}</span>
            <span className={styles.question} />
          </div>
        </section>
      )}
    >
      <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onSubmit}>
        {YES_TITLE}
      </BasicButton>
      <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
        {NO_TITLE}
      </BasicButton>
    </BasicModal>
  )
}

InitialModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  appName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

InitialModal.defaultProps = {
  appName: null
}
