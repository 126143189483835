/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { AND_OPERATOR, buildSearchColumn, DEAL, LIKE_START_WITH_MODE } from 'helpers/searchHelpers'
import { STATUS_ACTIVE, STATUS_DRAFT, STATUS_INACTIVE } from 'constants/entityStatus'
import { DEAL_COMPLIMENTARY_TYPE, DEAL_NON_COMPLIMENTARY_TYPE } from 'constants/dealsTypes'

export const TITLE_PAGE = 'Discounts'
export const TEXT_BTN_ADD_NEW = 'Add New Discount'

export const ACTIVE_FILTER_KEY = 'ACTIVE_FILTER_KEY'
export const ACTIVE_FILTER_LABEL = 'Active'

export const DRAFT_FILTER_KEY = 'DRAFT_FILTER_KEY'
export const DRAFT_FILTER_LABEL = 'Draft'

export const INACTIVE_FILTER_KEY = 'INACTIVE_FILTER_KEY'
export const INACTIVE_FILTER_LABEL = 'Inactive'

export const COMPLIMENTARY_FILTER_KEY = 'COMPLIMENTARY_FILTER_KEY'
export const COMPLIMENTARY_FILTER_LABEL = 'Complimentary'

export const NON_COMPLIMENTARY_FILTER_KEY = 'NON_COMPLIMENTARY_FILTER_KEY'
export const NON_COMPLIMENTARY_FILTER_LABEL = 'Exclusive'

export const DEALS_INITIAL_FILTERS = {
  [ACTIVE_FILTER_KEY]: true,
  [DRAFT_FILTER_KEY]: true,
  [COMPLIMENTARY_FILTER_KEY]: true,
  [NON_COMPLIMENTARY_FILTER_KEY]: true
}

export const DEALS_FILTERS = [
  { label: ACTIVE_FILTER_LABEL, field: ACTIVE_FILTER_KEY },
  { label: DRAFT_FILTER_LABEL, field: DRAFT_FILTER_KEY },
  { label: INACTIVE_FILTER_LABEL, field: INACTIVE_FILTER_KEY },
  { label: COMPLIMENTARY_FILTER_LABEL, field: COMPLIMENTARY_FILTER_KEY },
  { label: NON_COMPLIMENTARY_FILTER_LABEL, field: NON_COMPLIMENTARY_FILTER_KEY }
]

export const ACTIVE_FILTER = buildSearchColumn({
  entity: DEAL,
  field: 'status',
  operator: AND_OPERATOR,
  keyword: STATUS_ACTIVE,
  mode: LIKE_START_WITH_MODE
})

export const DRAFT_FILTER = buildSearchColumn({
  entity: DEAL,
  field: 'status',
  operator: AND_OPERATOR,
  keyword: STATUS_DRAFT
})

export const INACTIVE_FILTER = buildSearchColumn({
  entity: DEAL,
  field: 'status',
  operator: AND_OPERATOR,
  keyword: STATUS_INACTIVE
})

export const COMPLIMENTARY_FILTER = buildSearchColumn({
  entity: DEAL,
  field: 'deal_type',
  operator: AND_OPERATOR,
  keyword: DEAL_COMPLIMENTARY_TYPE,
  mode: LIKE_START_WITH_MODE
})

export const NON_COMPLIMENTARY_FILTER = buildSearchColumn({
  entity: DEAL,
  field: 'deal_type',
  operator: AND_OPERATOR,
  keyword: DEAL_NON_COMPLIMENTARY_TYPE
})

export const EXPORT_FILENAME = 'discounts_list.csv'
