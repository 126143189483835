/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { BasicButton } from '@xplorie/ui-commons'
import { useUpdateSomeDataState } from 'hooks'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { ORANGE_SCHEMA, SMALLER_SIZE, UPLOAD_TITLE } from 'constants/buttonsOptions'
import { Field, FileField } from 'components/FormComponents'
import { UPLOAD_INVENTORY_LABEL, SELECT_FILE_LABEL, SELECT_FILE_PLACEHOLDER } from './types'
import styles from './UploadFilesForm.scss'

export function UploadFilesForm(props) {
  const { onSubmit, uploadDataState } = props
  const [selectedFiles, setSelectedFilesState] = useState([])
  const formFiles = useRef(null)

  const onChangeFileshandler = files => {
    setSelectedFilesState(Array.from(files))
    formFiles.current = files
  }

  useUpdateSomeDataState(
    () => {
      formFiles.current = null
      setSelectedFilesState([])
    },
    [uploadDataState],
    DATA_STATE_RECIEVED
  )

  return (
    <>
      <Field label={UPLOAD_INVENTORY_LABEL}>
        <FileField
          id="bulk-files"
          label={SELECT_FILE_LABEL}
          fieldName="files"
          placeholder={SELECT_FILE_PLACEHOLDER}
          fieldProps={{
            className: styles.inputWrapper,
            classNameLabel: styles.inputLabel,
            classNameWrapper: styles.fieldWrapper
          }}
          onChange={onChangeFileshandler}
          multiple
        />
        <ul className={styles.fileList}>
          {selectedFiles.map(file => (
            <li key={file.name}>{file.name}</li>
          ))}
        </ul>
      </Field>
      <div className={styles.buttons}>
        <BasicButton
          className={styles.uploadButton}
          colorSchema={ORANGE_SCHEMA}
          size={SMALLER_SIZE}
          disabled={!selectedFiles.length}
          onClick={() => {
            onSubmit(formFiles.current)
          }}
        >
          {UPLOAD_TITLE}
        </BasicButton>
      </div>
    </>
  )
}
UploadFilesForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  uploadDataState: PropTypes.string.isRequired
}
