/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { httpWrapper, buildListParams } from 'services/http/helpers'
import { DEALS_ENDPOINT, DISCOUNT_DEAL_URL, DISCOUNT_DEAL_URL_ID, DRAFT_DEAL_URL } from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'

export const getEntityById = (id, params) =>
  httpWrapper({
    url: `${DEALS_ENDPOINT}/${id}`,
    method: 'get',
    params
  })

export const updateEntity = (id, contractData, params) =>
  httpWrapper({
    url: `${DEALS_ENDPOINT}/${id}`,
    method: 'put',
    data: contractData,
    params
  })

export const createEntity = (contractData, params) =>
  httpWrapper({
    url: DEALS_ENDPOINT,
    method: 'post',
    data: contractData,
    params
  })

export const assignGroups = (dealId, contractData, params) =>
  httpWrapper({
    url: `${DEALS_ENDPOINT}/${dealId}/groups`,
    method: 'put',
    data: contractData,
    params
  })

export const removeEntity = (dealId, params) =>
  httpWrapper({
    url: `${DEALS_ENDPOINT}/${dealId}`,
    method: 'delete',
    params
  })

export const formDealTicketTypesByActivity = (dealId, activityId, params) =>
  httpWrapper({
    url: `${DEALS_ENDPOINT}/${dealId}/types/${activityId}`,
    method: 'get',
    params
  })

export const activateEntity = (dealId, params) =>
  httpWrapper({
    url: `${DEALS_ENDPOINT}/${dealId}/activate`,
    method: 'put',
    params
  })

export const deactivateEntity = (dealId, params) =>
  httpWrapper({
    url: `${DEALS_ENDPOINT}/${dealId}/deactivate`,
    method: 'put',
    params
  })

export const getActivitiesByDeal = (dealId, params) =>
  httpWrapper({
    url: `${DEALS_ENDPOINT}/${dealId}/activities`,
    method: 'get',
    params: buildListParams(params)
  })

export const createDiscountDeal = data =>
  httpWrapper({
    url: buildPath(DISCOUNT_DEAL_URL),
    method: 'post',
    data
  })
export const updateDiscountDeal = (id, data) =>
  httpWrapper({
    url: buildPath(DISCOUNT_DEAL_URL_ID, { id }),
    method: 'put',
    data
  })

export const draftDeal = id =>
  httpWrapper({
    url: buildPath(DRAFT_DEAL_URL, { id }),
    method: 'put'
  })
