/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef, useCallback, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { BouncingLoader, PIECurtainLoader } from 'components/Loaders'
import { AddingEntityBox } from 'components/FormComponents'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { ProgramSeasonContext } from 'modules/Programs/helpers'
import { useLoadingDataState, useLoading } from 'hooks'

import { SeasonsValidateBoardInfo } from './SeasonsValidateBoard'
import { SeasonsList } from './SeasonsList'

import { ProgramsDiff } from './ProgramsDiff'

import { SEASONS_TITLE_SPOT, ADD_SEASON } from './types'

export function ProgramSeasons(props) {
  const {
    seasonsListState,
    seasonsData,
    dataId,
    getApi,
    onChange,
    isProgramActionPending,
    programData,
    cloneDataState,
    programDataState,
    programFormState,
    seasonsState,
    setSeasonsState
  } = props
  const [seasonsBoardState, setSeasonsBoardState] = useState({ errors: [], values: {} })
  const { permissions, conditions } = useContext(ProgramSeasonContext)
  const seasonListApi = useRef(null)
  const [isListLoading, { isLoaded }] = useLoadingDataState(seasonsListState)
  const [, { isLoaded: isProgramLoaded }] = useLoading(programDataState)
  const [isCloning] = useLoading(cloneDataState)
  const canAddNewSeason = permissions.canCreate || permissions.canUpdateSeasons
  const { isVisibleProgramDiff } = conditions
  const programType = get(programFormState, 'values.type')

  const showCurtainLoader = isCloning

  const onGetSeasonListApi = useCallback(
    api => {
      seasonListApi.current = api
      if (getApi) {
        getApi(api)
      }
    },
    [getApi]
  )

  const onAddNewSeason = useCallback(() => {
    seasonListApi.current.onAddNew({ programType })
  }, [programType])

  const onChangeHandler = type => {
    if (onChange) {
      onChange()
    }
    if (seasonListApi.current) {
      setSeasonsBoardState({
        errors: seasonListApi.current.onValidate(type),
        ...seasonListApi.current.getState()
      })
    }
  }

  return (
    <AddingEntityBox
      title={SEASONS_TITLE_SPOT}
      isVisibleSearch={false}
      AddEntityButton={
        canAddNewSeason && (
          <BasicButton
            disabled={isProgramActionPending}
            colorSchema={ORANGE_SCHEMA}
            onClick={onAddNewSeason}
          >
            {ADD_SEASON}
          </BasicButton>
        )
      }
    >
      {isListLoading && <BouncingLoader />}
      {showCurtainLoader && <PIECurtainLoader />}
      {isLoaded && seasonsBoardState && seasonsBoardState.errors.length > 0 && (
        <SeasonsValidateBoardInfo data={seasonsBoardState} />
      )}
      {isVisibleProgramDiff && isProgramLoaded && <ProgramsDiff formData={programData} />}
      <SeasonsList
        getApi={onGetSeasonListApi}
        data={seasonsData}
        dataId={dataId}
        onChange={onChangeHandler}
        isRequestPending={isProgramActionPending}
        programFormState={programFormState}
        seasonsState={seasonsState}
        setSeasonsState={setSeasonsState}
      />
    </AddingEntityBox>
  )
}
ProgramSeasons.propTypes = {
  seasonsListState: PropTypes.string.isRequired,
  seasonsData: PropTypes.array,
  dataId: PropTypes.string,
  getApi: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  isProgramActionPending: PropTypes.bool.isRequired,
  programData: PropTypes.object.isRequired,
  programDataState: PropTypes.string.isRequired,
  cloneDataState: PropTypes.string.isRequired,
  programFormState: PropTypes.object,
  seasonsState: PropTypes.array,
  setSeasonsState: PropTypes.func
}
ProgramSeasons.defaultProps = {
  seasonsData: [],
  dataId: null,
  onChange: () => null,
  programFormState: {},
  seasonsState: [],
  setSeasonsState: () => {}
}
