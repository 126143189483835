import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID_ERROR,
  REQUEST_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID_ERROR,
  REQUEST_VALIDATE_CATEGORY_BY_ID,
  RESPONSE_VALIDATE_CATEGORY_BY_ID,
  RESPONSE_VALIDATE_CATEGORY_BY_ID_ERROR
} = helpers

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  formDataState: DATA_STATE_NOT_REQUESTED,
  entityDataState: DATA_STATE_NOT_REQUESTED,
  entityData: null,
  removeDataState: DATA_STATE_NOT_REQUESTED,
  updateDataState: DATA_STATE_NOT_REQUESTED,
  validateCategoryDataState: DATA_STATE_NOT_REQUESTED,
  validateCategoryData: DATA_STATE_NOT_REQUESTED
}

const activityCategoryFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FORM:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING,
        formDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_FORM_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR,
        formDataState: DATA_STATE_ERROR
      }
    case RESPONSE_FORM:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        formDataState: DATA_STATE_RECIEVED
      }
    case REQUEST_GET_ENTITY_BY_ID:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING,
        entityDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_GET_ENTITY_BY_ID_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR,
        entityDataState: DATA_STATE_ERROR
      }
    case RESPONSE_GET_ENTITY_BY_ID:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        entityDataState: DATA_STATE_RECIEVED,
        entityData: action.payload
      }
    case REQUEST_REMOVE_ENTITY_BY_ID:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING,
        removeDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_REMOVE_ENTITY_BY_ID_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR,
        removeDataState: DATA_STATE_ERROR
      }
    case RESPONSE_REMOVE_ENTITY_BY_ID:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        removeDataState: DATA_STATE_RECIEVED
      }
    case REQUEST_VALIDATE_CATEGORY_BY_ID:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING,
        validateCategoryDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_VALIDATE_CATEGORY_BY_ID:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        validateCategoryData: action.payload,
        validateCategoryDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_VALIDATE_CATEGORY_BY_ID_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR,
        validateCategoryDataState: DATA_STATE_ERROR
      }
    case RESET_DATA_STATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default filterActions(activityCategoryFormReducer, [
  REQUEST_FORM,
  RESPONSE_FORM_ERROR,
  RESPONSE_FORM,
  RESET_DATA_STATE,
  REQUEST_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID_ERROR,
  REQUEST_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID_ERROR,
  REQUEST_VALIDATE_CATEGORY_BY_ID,
  RESPONSE_VALIDATE_CATEGORY_BY_ID,
  RESPONSE_VALIDATE_CATEGORY_BY_ID_ERROR
])
