/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getEntityByLocationId, updateEntityByLocationId } from 'services/http/locationMarketing'

import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_FORM_DATA,
  RESPONSE_GET_FORM_DATA,
  RESPONSE_GET_FORM_DATA_ERROR
} = helpers

const getLocationMarketingById = id => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_GET_FORM_DATA, { key }))
  const request = await getEntityByLocationId(id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_FORM_DATA_ERROR, { key }))
  })

  if (!request) {
    return 'getLocationMarketingById failed'
  }

  dispatch(actionCreater(RESPONSE_GET_FORM_DATA, { key, data: request.data }))
  return 'getLocationMarketingById success'
}

const updateLocationMarketing = (id, contractData) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_FORM, { key }))
  const request = await updateEntityByLocationId(id, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
  })

  if (!request) {
    return 'updateLocationMarketing failed'
  }

  const {
    message: { message, send }
  } = request
  dispatch(send(message))
  dispatch(actionCreater(RESPONSE_FORM, { key }))
  return 'updateLocationMarketing success'
}
const resetForm = key => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key }))

export default {
  getLocationMarketingById,
  updateLocationMarketing,
  resetForm
}
