/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import classNames from 'classnames'
import {
  YELLOW_GREEN_SCHEMA,
  ORANGE_SCHEMA,
  SUBMIT_TITLE,
  CANCEL_TITLE,
  OK_TITLE
} from 'constants/buttonsOptions'
import { ControlButtonsContainer } from 'components/FormComponents'
import { LoaderWrapper } from 'components/Wrappers/LoaderWrapper/LoaderWrapper'
import { useLoadingDataState } from 'hooks/hooks'
import { buildShowApsPath, buildShowAppsPath } from 'modules/ActivityCategory/helpers'
import { Link } from 'react-router-dom'
import styles from './styles.scss'
import { CATEGORY_NOT_VALID_MESSAGE, RELATED_AP, RELATED_APP } from './types'

export function DeleteCategoryModal(props) {
  const {
    isOpen,
    onClose,
    message,
    onSubmit,
    title,
    categoryId,
    validateCategoryDataState,
    validateCategoryData
  } = props
  const [isLoading] = useLoadingDataState(validateCategoryDataState)
  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit()
    }

    onClose()
  }

  const activitiesPath = useMemo(() => buildShowAppsPath(categoryId), [categoryId])
  const providersPath = useMemo(() => buildShowApsPath(categoryId), [categoryId])
  const hasProviders = validateCategoryData && validateCategoryData.hasProviders
  const hasActivities = validateCategoryData && validateCategoryData.hasActivities
  const isValid = !hasProviders && !hasActivities

  return (
    <BasicModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      wrapperClassName={classNames(styles.modalWrapper)}
      renderBody={() => (
        <LoaderWrapper isLoading={isLoading}>
          <section className={styles.wrapper}>
            <span className={styles.message}>{isValid ? message : CATEGORY_NOT_VALID_MESSAGE}</span>
            {!isValid && (
              <div className={styles.linksContainer}>
                {hasProviders && (
                  <Link className={styles.showPrograms} to={providersPath} target="_blank">
                    {RELATED_AP}
                  </Link>
                )}
                {hasActivities && (
                  <Link className={styles.showPrograms} to={activitiesPath} target="_blank">
                    {RELATED_APP}
                  </Link>
                )}
              </div>
            )}
          </section>
          <ControlButtonsContainer className={styles.controlButtonsContainer}>
            {isValid ? (
              <>
                <BasicButton colorSchema={ORANGE_SCHEMA} onClick={handleSubmit}>
                  {SUBMIT_TITLE}
                </BasicButton>
                <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
                  {CANCEL_TITLE}
                </BasicButton>
              </>
            ) : (
              <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
                {OK_TITLE}
              </BasicButton>
            )}
          </ControlButtonsContainer>
        </LoaderWrapper>
      )}
    />
  )
}

DeleteCategoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  validateCategoryData: PropTypes.shape({
    hasActivities: PropTypes.bool.isRequired,
    hasProviders: PropTypes.bool.isRequired
  }).isRequired,
  id: PropTypes.string.isRequired,
  validateCategoryDataState: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired
}
