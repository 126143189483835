/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import withAuthorization from 'helpers/withAuthorization'
import { Library } from './Library'

export default compose(
  withAuthorization,
  withRouter
)(Library)
