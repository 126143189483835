/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const AND_OPERATOR = 'AND'
export const OR_OPERATOR = 'OR'

export const OPEN_GROUP_OPERATOR = 'OPEN_GROUP'
export const CLOSE_GROUP_OPERATOR = 'CLOSE_GROUP'

export const LIKE_START_WITH_MODE = 'LIKE_START_WITH'
export const LIKE_END_WITH_MODE = 'LIKE_END_WITH'
export const NULL_MODE = 'NULL'
export const STRICT_MODE = 'STRICT'
