/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { buildPath } from 'helpers/httpHelpers'

import { SMART_HOST_SUBSCRIPTION_FEE_URL, SMART_HOST_MOBILE_FEE_URL } from 'endpoints'

export const getSmartHostFeeByProgramId = programId =>
  httpWrapper({
    url: buildPath(SMART_HOST_SUBSCRIPTION_FEE_URL, { programId }),
    method: 'get'
  })

export const saveSmartHostFeeByProgramId = (programId, contractData = {}) =>
  httpWrapper({
    url: buildPath(SMART_HOST_SUBSCRIPTION_FEE_URL, { programId }),
    method: 'put',
    data: contractData
  })

export const getSmartHostMobileFeeByProgramId = programId =>
  httpWrapper({
    url: buildPath(SMART_HOST_MOBILE_FEE_URL, { programId }),
    method: 'get'
  })

export const saveSmartHostMobileFeeByProgramId = (programId, contractData = {}) =>
  httpWrapper({
    url: buildPath(SMART_HOST_MOBILE_FEE_URL, { programId }),
    method: 'put',
    data: contractData
  })
