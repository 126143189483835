/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const RMSForm = loadable(
  () => import(/* webpackChunkName: 'RMSForm', webpackPrefetch: true */ './RMSForm'),
  {
    resolveComponent: components => components.RMSForm
  }
)

export { RMSForm }
export { RMSList } from './RMSList'
export * from './helpers'
