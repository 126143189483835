/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import {
  FORM_NAME,
  GET_DATA_BY_ID,
  CREATE_DEAL_OPTIONS,
  UPDATE_DEAL_OPTIONS,
  DELETE_DEAL,
  ACTIVATE_DEAL,
  DEACTIVATE_DEAL
} from './types'

const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

const REQUEST_GET_DATA_BY_ID = `${REQUEST_TYPE}_${GET_DATA_BY_ID}`
const RESPONSE_GET_DATA_BY_ID = `${RESPONSE_TYPE}_${GET_DATA_BY_ID}`
const RESPONSE_GET_DATA_BY_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_DATA_BY_ID}`

const REQUEST_CREATE_DEAL_OPTIONS = `${REQUEST_TYPE}_${CREATE_DEAL_OPTIONS}`
const RESPONSE_CREATE_DEAL_OPTIONS = `${RESPONSE_TYPE}_${CREATE_DEAL_OPTIONS}`
const RESPONSE_CREATE_DEAL_OPTIONS_ERROR = `${RESPONSE_ERROR_TYPE}_${CREATE_DEAL_OPTIONS}`

const REQUEST_UPDATE_DEAL_OPTIONS = `${REQUEST_TYPE}_${UPDATE_DEAL_OPTIONS}`
const RESPONSE_UPDATE_DEAL_OPTIONS = `${RESPONSE_TYPE}_${UPDATE_DEAL_OPTIONS}`
const RESPONSE_UPDATE_DEAL_OPTIONS_ERROR = `${RESPONSE_ERROR_TYPE}_${UPDATE_DEAL_OPTIONS}`

const REQUEST_DELETE_DEAL = `${REQUEST_TYPE}_${DELETE_DEAL}`
const RESPONSE_DELETE_DEAL = `${RESPONSE_TYPE}_${DELETE_DEAL}`
const RESPONSE_DELETE_DEAL_ERROR = `${RESPONSE_ERROR_TYPE}_${DELETE_DEAL}`

const REQUEST_ACTIVATE_DEAL = `${REQUEST_TYPE}_${ACTIVATE_DEAL}`
const RESPONSE_ACTIVATE_DEAL = `${RESPONSE_TYPE}_${ACTIVATE_DEAL}`
const RESPONSE_ACTIVATE_DEAL_ERROR = `${RESPONSE_ERROR_TYPE}_${ACTIVATE_DEAL}`

const REQUEST_DEACTIVATE_DEAL = `${REQUEST_TYPE}_${DEACTIVATE_DEAL}`
const RESPONSE_DEACTIVATE_DEAL = `${RESPONSE_TYPE}_${DEACTIVATE_DEAL}`
const RESPONSE_DEACTIVATE_DEAL_ERROR = `${RESPONSE_ERROR_TYPE}_${DEACTIVATE_DEAL}`

function buildActivateSuccessMessage(dealName) {
  return `The ${dealName} has been successfully ACTIVATED`
}

function buildDeactivateSuccessMessage(dealName) {
  return `The ${dealName} has been successfully DEACTIVATED`
}

export default {
  RESET_DATA_STATE,
  REQUEST_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID_ERROR,
  REQUEST_CREATE_DEAL_OPTIONS,
  RESPONSE_CREATE_DEAL_OPTIONS,
  RESPONSE_CREATE_DEAL_OPTIONS_ERROR,
  REQUEST_UPDATE_DEAL_OPTIONS,
  RESPONSE_UPDATE_DEAL_OPTIONS,
  RESPONSE_UPDATE_DEAL_OPTIONS_ERROR,
  REQUEST_DELETE_DEAL,
  RESPONSE_DELETE_DEAL,
  RESPONSE_DELETE_DEAL_ERROR,
  REQUEST_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL_ERROR,
  REQUEST_DEACTIVATE_DEAL,
  RESPONSE_DEACTIVATE_DEAL,
  RESPONSE_DEACTIVATE_DEAL_ERROR,
  buildActivateSuccessMessage,
  buildDeactivateSuccessMessage
}
