/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import { NAME } from './types'

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`
const RESET_DATA_TYPE_STATE = `${RESET_STATE}_TYPE_${NAME}`
const REQUEST_SEARCH = `${REQUEST_TYPE}_${NAME}`
const RESPONSE_SEARCH = `${RESPONSE_TYPE}_${NAME}`
const RESPONSE_SEARCH_ERROR = `${RESPONSE_ERROR_TYPE}_${NAME}`

const isOldRequest = (payload, currentState) => {
  const externalTimeStamp = get(payload, 'meta.timestamp')
  const currentTimeStamp = get(currentState, 'meta.timestamp')
  return (
    isNumber(externalTimeStamp) &&
    isNumber(currentTimeStamp) &&
    currentTimeStamp > externalTimeStamp
  )
}

export default {
  RESET_DATA_STATE,
  REQUEST_SEARCH,
  RESPONSE_SEARCH,
  RESPONSE_SEARCH_ERROR,
  RESET_DATA_TYPE_STATE,
  isOldRequest
}
