/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'

import { FormFilter } from 'components/FormComponents'
import { ContentWithNav } from 'components/ContentWithNav'
import { ButtonsWrapper, HeaderWrapper } from 'components/Wrappers'
import { ActivityProductList } from 'modules/ActivityProducts/ActivityProductList'
import withAuthorization from 'helpers/withAuthorization'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { RIGHT_ALIGN } from 'constants/alignTypes'
import { ACTIVITY } from 'constants/searchTypes'
import { activityProductTableColumns } from 'helpers/dataTablesHelpers'
import { DotsButtonWithExport } from 'modules/ExportEntity'

import { buildFilters, canAddApp } from 'containers/ActivityProducts/helpers'
import { useSliderApi, useDidMount } from 'hooks'
import { ACTIVITY_PRODUCT, TICKETS_SETTINGS } from 'constants/formCodes'
import { FilteredSearch, TableField, useFilteredSearch } from 'modules/FilteredSearch'
import { canSeeTicketsTab } from 'modules/ActivityProducts/helpers'
import styles from './ActivityProducts.scss'
import {
  APP_FILTERS,
  APP_INITIAL_FILTERS,
  TEXT_BTN_ADD_NEW,
  TITLE_PAGE,
  EXPORT_FILENAME
} from './types'

function ActivityProducts(props) {
  const { authUser, onGetData, getAPPbyId, entityData, entityDataState } = props
  const { id: activityProductId } = useParams()
  const { name, id } = entityData
  const activityProductSlider = useSliderApi(ACTIVITY_PRODUCT)
  const canRedirectToTicketsTab = useMemo(
    () => activityProductId && canSeeTicketsTab(entityData, authUser),
    [authUser, entityData, activityProductId]
  )

  const {
    onGetApi,
    onChange,
    getFilters,
    getParams,
    onGetData: onGetDataHandler
  } = useFilteredSearch({
    onGetData,
    buildFilters
  })

  const onAddNew = useCallback(() => {
    activityProductSlider.open()
  }, [activityProductSlider])

  const buildDotsButtonOptions = useCallback(() => {
    const options = []

    if (canAddApp(authUser)) {
      options.push({
        label: TEXT_BTN_ADD_NEW,
        props: { onClick: onAddNew }
      })
    }

    return options
  }, [onAddNew, authUser])

  useDidMount(() => {
    if (activityProductId) {
      getAPPbyId(authUser, activityProductId)
    }
  })

  useEffect(() => {
    if (entityDataState !== DATA_STATE_RECIEVED) {
      return
    }

    const initialTab = canRedirectToTicketsTab ? TICKETS_SETTINGS : ACTIVITY_PRODUCT

    activityProductSlider.open(id, {
      headerProps: { title: name },
      props: { initialTab }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canRedirectToTicketsTab, entityDataState])

  return (
    <ContentWithNav>
      <FilteredSearch getApi={onGetApi} onChange={onChange} initialFilters={APP_INITIAL_FILTERS}>
        <section className={styles.customWrapper}>
          <HeaderWrapper title={TITLE_PAGE} />
          <div className={styles.containerWrapper}>
            <ButtonsWrapper className={styles.buttonsPanelWithFilters} align={RIGHT_ALIGN}>
              <Column className={styles.customColumnWrapper}>
                <DotsButtonWithExport
                  options={buildDotsButtonOptions()}
                  onGetFilters={getFilters}
                  onGetParams={getParams}
                  entity={ACTIVITY}
                  columns={activityProductTableColumns}
                  filename={EXPORT_FILENAME}
                />
              </Column>
              <Column>
                <div className={styles.filterBarWrapper}>
                  <FormFilter filters={APP_FILTERS} />
                </div>
              </Column>
            </ButtonsWrapper>
            <TableField
              onGetData={onGetDataHandler}
              Component={ActivityProductList}
              // TODO: Need adjust and refactor it with reimplement this functionality.
              needFirstGetData={false}
            />
          </div>
        </section>
      </FilteredSearch>
    </ContentWithNav>
  )
}

ActivityProducts.propTypes = {
  onGetData: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired,
  getAPPbyId: PropTypes.func.isRequired,
  entityData: PropTypes.object.isRequired,
  entityDataState: PropTypes.object.isRequired
}

export default withAuthorization(ActivityProducts)
