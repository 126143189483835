/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_RE_ORDER_LIST,
  RESPONSE_RE_ORDER_LIST,
  RESPONSE_ERROR_RE_ORDER_LIST,
  REQUEST_RE_ORDER_TICKET_TYPES,
  RESPONSE_RE_ORDER_TICKET_TYPES,
  RESPONSE_ERROR_RE_ORDER_TICKET_TYPES
} = helpers

export const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  orderDataState: DATA_STATE_NOT_REQUESTED,
  orderTicketTypesDataState: DATA_STATE_NOT_REQUESTED,
  data: []
}

const ticketGroupsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_LIST:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        data: action.payload
      }
    case RESPONSE_ERROR_LIST:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case REQUEST_RE_ORDER_LIST:
      return {
        ...state,
        orderDataState: DATA_STATE_REQUESTING,
        data: action.payload
      }
    case RESPONSE_RE_ORDER_LIST:
      return {
        ...state,
        orderDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_ERROR_RE_ORDER_LIST:
      return {
        ...state,
        orderDataState: DATA_STATE_ERROR,
        data: action.payload
      }
    case REQUEST_RE_ORDER_TICKET_TYPES:
      return {
        ...state,
        orderTicketTypesDataState: DATA_STATE_REQUESTING,
        data: action.payload
      }
    case RESPONSE_RE_ORDER_TICKET_TYPES:
      return {
        ...state,
        orderTicketTypesDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_ERROR_RE_ORDER_TICKET_TYPES:
      return {
        ...state,
        orderTicketTypesDataState: DATA_STATE_ERROR,
        data: action.payload
      }
    case RESET_DATA_STATE:
      return initialState
    default:
      return state
  }
}

export default filterActions(ticketGroupsListReducer, [
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_RE_ORDER_LIST,
  RESPONSE_RE_ORDER_LIST,
  RESPONSE_ERROR_RE_ORDER_LIST,
  REQUEST_RE_ORDER_TICKET_TYPES,
  RESPONSE_RE_ORDER_TICKET_TYPES,
  RESPONSE_ERROR_RE_ORDER_TICKET_TYPES
])
