/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { useDispatch, useSelector } from 'react-redux'
import { useLoading } from 'hooks/hooks'
import { selectors, actions } from 'reducers/location/locationProgramsAndActivities'
import { useCallback } from 'react'
import { useRootModal } from 'hooks/useRootModal'
import { useAction } from 'hooks/useAction'
import get from 'lodash/get'

import { ProgramsAndActivitiesModal } from './ProgramsAndActivitiesModal'

const { getLocationProgramsAndActivitiesState } = selectors

export function useLocationProgramsAndActivities(cb) {
  const { dataState } = useSelector(state => getLocationProgramsAndActivitiesState(state))
  const dispatch = useDispatch()
  const rootModal = useRootModal()
  const getLocationProgramsAndActivities = useAction(actions.getLocationProgramsAndActivities)
  const resetLocationProgramsAndActivities = useAction(actions.resetLocationProgramsAndActivities)
  const [isLoading] = useLoading(dataState)

  const resetProgramsAndActivities = useCallback(
    () => dispatch(resetLocationProgramsAndActivities),
    [dispatch, resetLocationProgramsAndActivities]
  )

  const onClose = useCallback(
    id => {
      resetProgramsAndActivities()
      rootModal.dequeue()
      cb(id)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetProgramsAndActivities, rootModal]
  )

  const getProgramsAndActivities = useCallback(
    async id => {
      const data = await getLocationProgramsAndActivities(id)
      const programCount = get(data, 'programs.count', null)
      const activitiesCount = get(data, 'activities.count', null)
      const canProceed = !programCount && !activitiesCount

      if (!canProceed) {
        rootModal.enqueue(ProgramsAndActivitiesModal, {
          isOpen: true,
          onClose: () => onClose(id),
          data: {
            programCount,
            activitiesCount,
            locationId: id
          }
        })
      }

      return {
        canProceed,
        data
      }
    },
    [rootModal, getLocationProgramsAndActivities, onClose]
  )

  return {
    isLoading,
    getProgramsAndActivities,
    resetProgramsAndActivities
  }
}
