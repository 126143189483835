/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withValidation, blankValidate } from 'helpers/formHelpers'

import { getComponentByMode } from './helpers'
import { NumberFieldInput } from './NumberFieldInput'

export function NumberField(props) {
  const { mode, fieldName, isRequired, ...rest } = props

  const { Component, props: fieldProps } = getComponentByMode(mode)
  const { validate, ...restFieldProps } = fieldProps

  const validateStrategy = useMemo(
    () => (isRequired ? withValidation(blankValidate, validate) : validate),
    [isRequired, validate]
  )

  return (
    <NumberFieldInput
      mode={mode}
      field={fieldName}
      validate={validateStrategy}
      Component={Component}
      options={restFieldProps}
      validateOnChange
      {...rest}
    />
  )
}

NumberField.propTypes = {
  mode: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool
}
NumberField.defaultProps = {
  isRequired: false
}
