/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { getListByProgramId, deleteById } from 'services/http/programSeason'

import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_SEASONS_LIST,
  RESPONSE_SEASONS_LIST,
  RESPONSE_ERROR_SEASONS_LIST,
  REQUEST_SEASON_REMOVE_ACTION,
  RESPONSE_SEASON_REMOVE_ACTION,
  RESPONSE_ERROR_SEASON_REMOVE_ACTION
} = helpers

const getSeasonsList = (programId, options) => async dispatch => {
  const key = programId
  dispatch(actionCreater(REQUEST_SEASONS_LIST, { key }))
  const request = await getListByProgramId(programId, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_SEASONS_LIST, { key }))
  })
  if (request) {
    dispatch(
      actionCreater(RESPONSE_SEASONS_LIST, {
        key,
        data: request.data
      })
    )
    return 'getSeasonsList success'
  }

  return 'getSeasonsList failed'
}

const deleteSeasonById = (programId, id) => async dispatch => {
  const key = programId
  dispatch(actionCreater(REQUEST_SEASON_REMOVE_ACTION, { key }))
  const request = await deleteById(programId, id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_SEASON_REMOVE_ACTION, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(
      actionCreater(RESPONSE_SEASON_REMOVE_ACTION, {
        key,
        responseData: id
      })
    )
    dispatch(send(message))
    return 'deleteSeasonById success'
  }

  return 'deleteSeasonById failed'
}
const resetList = key => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key }))

export default {
  resetList,
  getSeasonsList,
  deleteSeasonById
}
