/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { buildPath } from 'helpers/httpHelpers'

import { CREDENTIALS_URL } from 'endpoints'

export const markAsRead = (accountId, activityId, type) =>
  httpWrapper({
    url: buildPath(CREDENTIALS_URL, { accountId, activityId }),
    method: 'post',
    params: { type }
  })
