/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ContentWithNav } from 'components/ContentWithNav'
import { HeaderWrapper } from 'components/Wrappers'
import { ContentWrapper } from 'modules/Library'
import { getQueryStringParams, buildRequestParams } from 'helpers/searchParamsHelper'
import { TITLE_PAGE, ACTIVITY_CATEGORY_TYPE } from './types'
import styles from './Library.scss'

export function Library(props) {
  const { location, history } = props
  const { type = ACTIVITY_CATEGORY_TYPE, ...restParams } = useMemo(
    () => getQueryStringParams(location.search),
    [location.search]
  )

  const onSetCurrentType = useCallback(
    newType => {
      if (newType !== type) {
        history.push({
          search: buildRequestParams({ ...restParams, type: newType })
        })
      }
    },
    [history, restParams, type]
  )

  return (
    <ContentWithNav>
      <section className={styles.customWrapper}>
        <HeaderWrapper title={TITLE_PAGE} />
        <div className={styles.containerWrapper}>
          <ContentWrapper currentType={type} setCurrentType={onSetCurrentType} />
        </div>
      </section>
    </ContentWithNav>
  )
}
Library.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
