/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import isObject from 'lodash/isObject'
import { actionCreater } from 'helpers/actionHelpers'
import {
  logOut as logOutRequest,
  changePassword as changePasswordRequest,
  getAccountById
} from 'services/http/account'

import { authAction, getUser, refreshTokenAction } from 'services/http/oauth'
import {
  updateUserData,
  savedAuthState,
  saveAuthTokens,
  clearAuthCookie,
  getAuthToken,
  getRefreshToken
} from 'services/auth'
import * as Sentry from '@sentry/browser'
import {
  REQUEST_AUTH,
  RESPONSE_AUTH,
  RESPONSE_AUTH_ERROR,
  REQUEST_CHANGE_PASS_ACTION,
  RESPONSE_CHANGE_PASS_ACTION,
  RESPONSE_CHANGE_PASS_ACTION_ERROR,
  REQUEST_LOG_OUT_ACTION,
  RESPONSE_LOG_OUT_ACTION,
  RESPONSE_LOG_OUT_ACTION_ERROR,
  RESET_DATA_STATE,
  REQUEST_PROFILE_ACTION,
  RESPONSE_PROFILE_ACTION,
  RESPONSE_PROFILE_ACTION_ERROR,
  REQUEST_REFRESH_TOKEN_ACTION,
  RESPONSE_REFRESH_TOKEN_ACTION,
  RESPONSE_REFRESH_TOKEN_ACTION_ERROR
} from './helpers'

export const logIn = authData => async dispatch => {
  dispatch(actionCreater(REQUEST_AUTH))
  const request = await authAction(authData).catch(() => {
    dispatch(actionCreater(RESPONSE_AUTH_ERROR))
  })
  if (!request) {
    return 'logIn failed'
  }
  saveAuthTokens(request.data)
  const requestUser = await getUser().catch(() => {
    dispatch(actionCreater(RESPONSE_AUTH_ERROR))
  })
  if (!requestUser) {
    clearAuthCookie()
    return 'logIn failed'
  }
  const {
    principal: { userId }
  } = requestUser.data
  dispatch(actionCreater(REQUEST_PROFILE_ACTION))
  const profileRequest = await getAccountById(null, userId).catch(() => {
    dispatch(actionCreater(RESPONSE_AUTH_ERROR))
    dispatch(actionCreater(RESPONSE_PROFILE_ACTION_ERROR))
  })

  if (profileRequest) {
    const authUser = updateUserData(profileRequest.data, requestUser.data)
    dispatch(actionCreater(RESPONSE_AUTH, { authUser }))
    dispatch(
      actionCreater(RESPONSE_PROFILE_ACTION, {
        profileData: profileRequest.data
      })
    )
    Sentry.setUser({ email: authUser.email })
    return 'logIn success'
  }

  updateUserData(null)
  clearAuthCookie()
  return 'logIn failed'
}

export const logOut = (authKey, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_LOG_OUT_ACTION))
  const request = await logOutRequest(authKey, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_LOG_OUT_ACTION_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LOG_OUT_ACTION, { authUser: null }))
    updateUserData(null)
    clearAuthCookie()
    return 'logOut success'
  }
  updateUserData(null)
  clearAuthCookie()
  return 'logOut failed'
}

export const changePassword = (authUser, contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_CHANGE_PASS_ACTION))
  const request = await changePasswordRequest(authUser, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_CHANGE_PASS_ACTION_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_CHANGE_PASS_ACTION))
    dispatch(send(message))
    return 'changePassword success'
  }

  return 'changePassword failed'
}

export const checkAuth = () => async dispatch => {
  dispatch(actionCreater(REQUEST_AUTH))
  if (!getAuthToken() || !getRefreshToken()) {
    dispatch(actionCreater(RESPONSE_AUTH_ERROR))
    clearAuthCookie()
    return 'checkAuth failed'
  }

  const requestUser = await getUser().catch(() => {
    dispatch(actionCreater(RESPONSE_AUTH_ERROR))
  })
  if (!requestUser) {
    dispatch(actionCreater(RESPONSE_AUTH_ERROR))
    clearAuthCookie()
    return 'checkAuth failed'
  }

  const savedAuthUser = savedAuthState()
  // request user & profile data if haven't
  if (!savedAuthUser || !isObject(savedAuthUser) || !savedAuthUser.id) {
    const {
      principal: { userId }
    } = requestUser.data
    dispatch(actionCreater(REQUEST_PROFILE_ACTION))
    const profileRequest = await getAccountById(null, userId).catch(() => {
      dispatch(actionCreater(RESPONSE_AUTH_ERROR))
      dispatch(actionCreater(RESPONSE_PROFILE_ACTION_ERROR))
    })

    if (profileRequest) {
      const authUser = updateUserData(profileRequest.data, requestUser.data)
      dispatch(actionCreater(RESPONSE_AUTH, { authUser }))
      dispatch(
        actionCreater(RESPONSE_PROFILE_ACTION, {
          profileData: profileRequest.data
        })
      )
      return 'checkAuth success'
    }

    dispatch(actionCreater(RESPONSE_AUTH_ERROR))
    clearAuthCookie()
    return 'checkAuth failed'
  }

  dispatch(actionCreater(RESPONSE_AUTH, { authUser: savedAuthUser }))
  return 'checkAuth success'
}

export const getProfile = (authUser, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_PROFILE_ACTION))
  const request = await getAccountById(authUser, params).catch(() => {
    dispatch(actionCreater(RESPONSE_PROFILE_ACTION_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_PROFILE_ACTION, { profileData: request.data }))
    return 'getProfile success'
  }

  return 'getProfile failed'
}

export const refreshToken = token => async dispatch => {
  dispatch(actionCreater(REQUEST_REFRESH_TOKEN_ACTION))
  const request = await refreshTokenAction(token).catch(() => {
    dispatch(actionCreater(RESPONSE_REFRESH_TOKEN_ACTION_ERROR))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_REFRESH_TOKEN_ACTION))
    saveAuthTokens(request.data)
    return Promise.resolve(request.data)
  }
  updateUserData(null)
  clearAuthCookie()
  dispatch(actionCreater(RESPONSE_AUTH_ERROR))
  return Promise.reject(new Error('refreshToken error'))
}

export const resetData = () => ({
  type: RESET_DATA_STATE
})

export default {
  logIn,
  logOut,
  changePassword,
  checkAuth,
  resetData,
  getProfile,
  refreshToken
}
