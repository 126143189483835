/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { BasicButton, DotsButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA } from 'constants/colorSchemas'
import { SMART_HOST_FEE_MODE } from 'constants/smartHostFee'
import {
  ADD_SMART_HOST_FEE_LABEL,
  ADD_SMART_HOST_MOBILE_FEE_LABEL,
  CANCEL_LABEL,
  SAVE_LABEL,
  SMART_HOST_SUBSCRIPTION_TYPE
} from '../types'
import styles from './SmartHostFeeActions.scss'

function SmartHostFeeActions(props) {
  const { mode, hasFees, options, setAddMode, onSave, onCancel, type } = props

  if (SMART_HOST_FEE_MODE.LIST === mode) {
    return hasFees ? (
      <DotsButton align="left" options={options} />
    ) : (
      <BasicButton className={styles.button} onClick={setAddMode} colorSchema={ORANGE_SCHEMA}>
        {type === SMART_HOST_SUBSCRIPTION_TYPE
          ? ADD_SMART_HOST_FEE_LABEL
          : ADD_SMART_HOST_MOBILE_FEE_LABEL}
      </BasicButton>
    )
  }

  return hasFees || mode === SMART_HOST_FEE_MODE.ADD ? (
    <>
      <BasicButton className={styles.saveButton} onClick={onSave} colorSchema={ORANGE_SCHEMA}>
        {SAVE_LABEL}
      </BasicButton>
      <BasicButton className={styles.button} onClick={onCancel} colorSchema={ORANGE_SCHEMA}>
        {CANCEL_LABEL}
      </BasicButton>
    </>
  ) : (
    <BasicButton className={styles.button} onClick={onCancel} colorSchema={ORANGE_SCHEMA}>
      {CANCEL_LABEL}
    </BasicButton>
  )
}

SmartHostFeeActions.propTypes = {
  mode: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  hasFees: PropTypes.bool.isRequired,
  setAddMode: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
}

export default SmartHostFeeActions
