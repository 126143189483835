/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  RESET_STATE,
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE
} from 'helpers/actionHelpers'

import types from './types'

const { SEASONS_LIST_NAME, SEASON_REMOVE_ACTION } = types

const REQUEST_SEASONS_LIST = `${REQUEST_TYPE}_${SEASONS_LIST_NAME}`
const RESPONSE_SEASONS_LIST = `${RESPONSE_TYPE}_${SEASONS_LIST_NAME}`
const RESPONSE_ERROR_SEASONS_LIST = `${RESPONSE_ERROR_TYPE}_${SEASONS_LIST_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${SEASONS_LIST_NAME}`

const REQUEST_SEASON_REMOVE_ACTION = `${REQUEST_TYPE}_${SEASON_REMOVE_ACTION}`
const RESPONSE_SEASON_REMOVE_ACTION = `${RESPONSE_TYPE}_${SEASON_REMOVE_ACTION}`
const RESPONSE_ERROR_SEASON_REMOVE_ACTION = `${RESPONSE_ERROR_TYPE}_${SEASON_REMOVE_ACTION}`

export default {
  RESET_DATA_STATE,
  REQUEST_SEASONS_LIST,
  RESPONSE_SEASONS_LIST,
  RESPONSE_ERROR_SEASONS_LIST,
  REQUEST_SEASON_REMOVE_ACTION,
  RESPONSE_SEASON_REMOVE_ACTION,
  RESPONSE_ERROR_SEASON_REMOVE_ACTION
}
