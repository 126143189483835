/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { asField } from 'informed'
import get from 'lodash/get'

import { TypeaheadSearch } from 'modules/TypeaheadSearch'
import { Field } from 'components/FormComponents'

import { CurrentValue } from './CurrentValue'

const TypeaheadWrapper = props => {
  const {
    suggestions,
    suggestionsFetch,
    clearSuggestions,
    fieldApi: { setValue, setTouched },
    fieldState: { value, error },
    fieldState,
    fieldApi,
    onChange,
    onBeforeChange,
    onValueConverter,
    field,
    forwardedRef,
    label,
    isRequired,
    fieldProps,
    disabled,
    id,
    isShowCurrentValue,
    initValue,
    ...rest
  } = props
  const [currentValue, setCurrentValue] = useState(initValue)

  const onSelectHandler = useCallback(
    selectedValue => {
      const { value: nextValue } = onValueConverter(selectedValue)
      setTouched()
      setValue(nextValue)
      if (onChange) {
        onChange(nextValue, selectedValue)
      }
    },
    [setValue, setTouched, onChange, onValueConverter]
  )

  useEffect(() => {
    if (!value) {
      setCurrentValue(null)
      return
    }
    const shownValue = value ? onValueConverter(value) : null

    setCurrentValue(get(shownValue, 'title', null))
  }, [value, onValueConverter])

  return (
    <Field id={id} label={label} isRequired={isRequired} error={error} {...fieldProps}>
      <TypeaheadSearch
        {...rest}
        id={id}
        ref={forwardedRef}
        suggestions={suggestions}
        suggestionsFetch={suggestionsFetch}
        clear={clearSuggestions}
        onSelect={onSelectHandler}
        onBeforeChange={onBeforeChange}
        disabled={disabled}
        initValue={initValue}
      />
      {!disabled && isShowCurrentValue && <CurrentValue value={currentValue} />}
    </Field>
  )
}

TypeaheadWrapper.propTypes = {
  fieldApi: PropTypes.object.isRequired,
  suggestions: PropTypes.array,
  clearSuggestions: PropTypes.func,
  suggestionsFetch: PropTypes.func.isRequired,
  onValueConverter: PropTypes.func,
  initValue: PropTypes.string,
  disabled: PropTypes.bool,
  isShowCurrentValue: PropTypes.bool,
  onChange: PropTypes.func,
  onBeforeChange: PropTypes.func,
  fieldState: PropTypes.object,
  field: PropTypes.string,
  forwardedRef: PropTypes.any,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  fieldProps: PropTypes.object,
  id: PropTypes.string
}

TypeaheadWrapper.defaultProps = {
  clearSuggestions: () => [],
  suggestions: [],
  onValueConverter: () => ({ title: '', value: '' }),
  initValue: '',
  onChange: () => {},
  onBeforeChange: null,
  fieldState: {},
  forwardedRef: null,
  field: '',
  label: '',
  isRequired: false,
  fieldProps: {},
  disabled: false,
  isShowCurrentValue: true,
  id: null
}

export const TypeaheadConnected = asField(TypeaheadWrapper)
