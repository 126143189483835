/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const DELETE_MODAL_TITLE = 'Warning!'
export const MESSAGE_FIRST_PART = 'Are you sure you want to delete the '
export const MESSAGE_SECOND_PART = ' Market from the Library and all related Programs?'
export const PROGRAMS_LINK = 'View Programs'
export const APPS_LINK = 'View APPs'
