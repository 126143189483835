/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { ACTIVITY_URL } from 'endpoints'

export const buildTicketSettingsURL = activityId => `${ACTIVITY_URL}/${activityId}/tickets/settings`
export const buildTicketSettingsEmailURL = activityId =>
  `${ACTIVITY_URL}/${activityId}/tickets/settings/emails`
