/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { getUnitsByPmcId, exportPmcUnitsList } from 'services/http/pmcUnits'

import helpers, { buildAccessKey } from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_EXPORT_PMC_UNITS,
  RESPONSE_EXPORT_PMC_UNITS,
  RESPONSE_ERROR_EXPORT_PMC_UNITS
} = helpers

const getUnitsListByPmcIdAndProgramId = (pmcId, programId, options) => async dispatch => {
  const key = buildAccessKey(pmcId, programId)
  dispatch(actionCreater(REQUEST_LIST, { key }))
  const request = await getUnitsByPmcId(pmcId, { programId, ...options }).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_ERROR_LIST, { key }))
    }
  )
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { data: request.data, key }))
    return 'getUnitsListByPmcId success'
  }

  return 'getUnitsListByPmcId failed'
}

const exportUnitsListByPmcIdAndProgramId = (pmcId, programId, data, options) => async dispatch => {
  const key = buildAccessKey(pmcId, programId)
  dispatch(actionCreater(REQUEST_EXPORT_PMC_UNITS, { key }))
  const request = await exportPmcUnitsList(pmcId, data, { programId, ...options }).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_ERROR_EXPORT_PMC_UNITS, { key }))
    }
  )
  if (request) {
    dispatch(actionCreater(RESPONSE_EXPORT_PMC_UNITS, { key }))
    return 'exportUnitsListByPmcIdAndProgramId success'
  }

  return 'exportUnitsListByPmcIdAndProgramId failed'
}

const resetList = (pmcId, programId) => dispatch =>
  dispatch(actionCreater(RESET_DATA_STATE, { key: buildAccessKey(pmcId, programId) }))

export default {
  getUnitsListByPmcIdAndProgramId,
  exportUnitsListByPmcIdAndProgramId,
  resetList
}
