/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'
import withAuthorization from 'helpers/withAuthorization'
import { APPROVE_LEVEL } from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import { STATUS_INACTIVE, STATUS_ACTIVE } from 'constants/entityStatus'
import { DiscountsContext } from './context'

function DiscountsDataProviderComponent(props) {
  const { children, authUser, onChange } = props

  const canApprove = isActionAllowed([APPROVE_LEVEL], authUser)

  const onBuildPermissions = (data = {}, params = {}) => {
    const isExist = Boolean(data && data.id)
    const isReadOnlyMode = params && params.readOnly

    return {
      isExist,
      isCreateAllowed: canApprove && !isReadOnlyMode,
      isEditAllowed: isExist && canApprove && !isReadOnlyMode,
      isDeleteAllowed: isExist && canApprove && !isReadOnlyMode,
      isActivateAllowed:
        isExist && canApprove && data.status === STATUS_INACTIVE && !isReadOnlyMode,
      isDeactivateAllowed: isExist && canApprove && data.status === STATUS_ACTIVE && !isReadOnlyMode
    }
  }

  const [permissions, setPermissionsState] = useState({
    isViewAllowed: canApprove,
    isEditAllowed: canApprove,
    isCreateAllowed: canApprove,
    isDeleteAllowed: false,
    isExist: false,
    onChange: (data, params) => {
      setPermissionsState({ ...permissions, ...onBuildPermissions(data, params) })
    }
  })

  useEffect(() => {
    onChange(permissions)
  }, [onChange, permissions])

  return (
    <DiscountsContext.Provider value={permissions}>
      {isFunction(children) ? children(permissions) : children}
    </DiscountsContext.Provider>
  )
}

DiscountsDataProviderComponent.propTypes = {
  children: PropTypes.node.isRequired,
  authUser: PropTypes.object.isRequired,
  onChange: PropTypes.func
}
DiscountsDataProviderComponent.defaultProps = {
  onChange: () => null
}

export const DiscountsDataProvider = withAuthorization(DiscountsDataProviderComponent)
