/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Field } from 'components/FormComponents'
import { TypeaheadSearch } from 'modules/TypeaheadSearch'
import { BouncingLoader } from 'components/Loaders'
import {
  SELECT_APP_LABEL,
  SELECT_APP_PLACEHOLDER,
  NO_RESULT_MESSAGE
} from 'modules/Discounts/types'
import { useUpdateSomeDataState, useLoadingDataState } from 'hooks'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'

import { NewActivityForm } from './NewActivityForm'

import { buildSearchContract } from './helpers'

import styles from './NewActivitySection.scss'

export function NewActivitySection(props) {
  const {
    data,
    dataState,
    resetSearch,
    searchActivitiesByDealId,
    assignDataState,
    onSubmit,
    formTicketTypesDataState,
    ticketTypesData,
    formDealTicketTypes,
    dealId,
    isEditAllowed
  } = props
  const [selectedActivityBufer, setSelectedActivityBuferState] = useState(null)
  const [activity, setActivity] = useState(null)
  const isSelectedActivity = Boolean(activity)
  const [isLoadingResult, { isLoaded: isLoadedResult }] = useLoadingDataState(dataState)
  const [isLoadingTicketTypes] = useLoadingDataState(formTicketTypesDataState)
  const [isAssigment] = useLoadingDataState(assignDataState)
  const isFormDisabled = isAssigment || isLoadingTicketTypes || !isEditAllowed
  const isTypeaheadDisabled = isLoadingTicketTypes || !isEditAllowed
  const isShownNoResultMessage = [!isFormDisabled, !data.length, !activity, isLoadedResult].every(
    value => Boolean(value)
  )

  const onSearch = useCallback(
    keyword => {
      searchActivitiesByDealId(buildSearchContract(keyword))
    },
    [searchActivitiesByDealId]
  )

  const onClearSearch = useCallback(() => {
    resetSearch()
  }, [resetSearch])

  const onDeleteSelectedApp = useCallback(() => {
    setActivity(null)
    setSelectedActivityBuferState(null)
  }, [setSelectedActivityBuferState, setActivity])

  const onSelectActivity = useCallback(
    activityData => {
      setSelectedActivityBuferState(activityData)
      formDealTicketTypes(dealId, activityData.id)
    },
    [dealId, formDealTicketTypes, setSelectedActivityBuferState]
  )

  useUpdateSomeDataState(
    () => {
      onDeleteSelectedApp()
    },
    [assignDataState],
    DATA_STATE_RECIEVED
  )

  useUpdateSomeDataState(
    () => {
      const { id, name } = selectedActivityBufer
      setActivity({ activity: { id, name }, ticketTypes: ticketTypesData })
      setSelectedActivityBuferState(null)
    },
    [formTicketTypesDataState],
    DATA_STATE_RECIEVED
  )

  useEffect(() => {
    // clear app data when edit was disabled
    if (!isEditAllowed && activity) {
      onDeleteSelectedApp()
    }
  }, [isEditAllowed, onDeleteSelectedApp, activity])

  return (
    <section className={styles.wrapper}>
      <Field label={SELECT_APP_LABEL} isRequired>
        <TypeaheadSearch
          initValue={''}
          className={styles.typeaheadInput}
          placeholder={SELECT_APP_PLACEHOLDER}
          suggestions={data}
          suggestionsFetch={onSearch}
          onSelect={onSelectActivity}
          clear={onClearSearch}
          disabled={isTypeaheadDisabled}
          isCleared
        />
        {isLoadingResult && <BouncingLoader className={styles.typeaheadLoader} />}
        {isShownNoResultMessage && <span className={styles.noResult}>{NO_RESULT_MESSAGE}</span>}
      </Field>
      {isSelectedActivity && (
        <div className={styles.activityCurtain}>
          <NewActivityForm
            data={activity}
            onDeleteApp={onDeleteSelectedApp}
            onSubmit={onSubmit}
            isFormDisabled={isFormDisabled}
          />
        </div>
      )}
    </section>
  )
}
NewActivitySection.propTypes = {
  data: PropTypes.array.isRequired,
  dataState: PropTypes.string.isRequired,
  resetSearch: PropTypes.func.isRequired,
  searchByType: PropTypes.func.isRequired,
  assignDataState: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  searchActivitiesByDealId: PropTypes.func.isRequired,
  formTicketTypesDataState: PropTypes.string.isRequired,
  ticketTypesData: PropTypes.object.isRequired,
  formDealTicketTypes: PropTypes.func.isRequired,
  dealId: PropTypes.string.isRequired,
  isEditAllowed: PropTypes.bool.isRequired
}
