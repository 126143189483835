/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import {
  actions as programsFormActions,
  isProgramDataLoading,
  getProgramDataState
} from 'reducers/programsForm'
import {
  actions as programSeasonListActions,
  getSeasonsListState
} from 'reducers/programSeasonList'
import { withAuthContext } from 'helpers/withAuthContext'

import { ProgramSeasonContainer as ProgramSeasonContainerComponent } from './ProgramSeasonContainer'

const mapStateToProps = (state, props) => {
  const { dataId } = props
  const programFormState = getProgramDataState(state, dataId)
  const seasonsListState = getSeasonsListState(state, dataId)
  return {
    isProgramDataLoading: isProgramDataLoading(state, dataId),
    programData: programFormState.programData,
    programDataState: programFormState.programDataState,
    seasonsListState: seasonsListState.dataState,
    cloneDataState: state.seasonForm.cloneDataState
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const { dataId } = props
  const { resetForm, getProgramById } = programsFormActions
  const { getSeasonsList } = programSeasonListActions

  return bindActionCreators(
    {
      resetProgram: resetForm.bind(null, dataId),
      getSeasonsList,
      getProgramById: getProgramById.bind(null, dataId)
    },
    dispatch
  )
}

export const ProgramSeasonContainer = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProgramSeasonContainerComponent)
