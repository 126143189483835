/*
 * COPYRIGHT:     Copyright © 2023 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import {
  YELLOW_GREEN_SCHEMA,
  ORANGE_SCHEMA,
  SUBMIT_TITLE,
  CANCEL_TITLE
} from 'constants/buttonsOptions'
import { ControlButtonsContainer } from 'components/FormComponents'
import styles from './styles.scss'
import { FUTURE_SEASONS_MESSAGE } from './types'

export function ChangeProgramTypeModal(props) {
  const { isOpen, onClose, message = FUTURE_SEASONS_MESSAGE, onSubmit, title } = props
  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit()
    }

    onClose()
  }

  return (
    <BasicModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      wrapperClassName={styles.modalWrapper}
      renderBody={() => (
        <>
          <section className={styles.wrapper}>
            <span className={styles.message}>{message}</span>
          </section>
          <ControlButtonsContainer className={styles.controlButtonsContainer}>
            <>
              <BasicButton colorSchema={ORANGE_SCHEMA} onClick={handleSubmit}>
                {SUBMIT_TITLE}
              </BasicButton>
              <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
                {CANCEL_TITLE}
              </BasicButton>
            </>
          </ControlButtonsContainer>
        </>
      )}
    />
  )
}

ChangeProgramTypeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}
