/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { compose } from 'redux'
import values from 'lodash/values'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import {
  getPrebookInfoByProgramId,
  savePrebookInfoByProgramId,
  deletePrebookInfoByProgramId
} from 'services/http/programPrebookInfo'
import helpers from './helpers'
import types from './types'

const everyEmpty = v => every(v, isEmpty)

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_PREBOOK_INFO,
  RESPONSE_GET_PREBOOK_INFO,
  RESPONSE_GET_PREBOOK_INFO_ERROR,
  RESET_DATA_STATE
} = helpers
const { CHARGE_METHOD_FIELD } = types

const savePrebookInfo = (id, contractData) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_FORM, { key }))
  const data = omit(contractData, [CHARGE_METHOD_FIELD])
  const httpRequest = compose(
    everyEmpty,
    values
  )(data)
    ? deletePrebookInfoByProgramId
    : savePrebookInfoByProgramId
  const request = await httpRequest(id, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM, { data: contractData, key }))
    return 'savePrebookInfo success'
  }

  return 'savePrebookInfo failed'
}

const getPrebookInfo = id => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_GET_PREBOOK_INFO, { key }))
  const request = await getPrebookInfoByProgramId(id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_PREBOOK_INFO_ERROR, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_PREBOOK_INFO, { data: request.data, key }))
    return 'getPrebookInfo success'
  }

  return 'getPrebookInfo failed'
}

const resetState = id => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key: id }))

export default {
  savePrebookInfo,
  getPrebookInfo,
  resetState
}
