/*
* COPYRIGHT:     Copyright © 2018 Xplorie LLC
* Warning:       This product is protected by United States and international copyright laws.
*                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
*/

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { ACTIVITY_NAME, ACTIVITY_LIST_NAME } from './types'

const REQUEST_ACTIVITY_LIST = `${REQUEST_TYPE}_${ACTIVITY_LIST_NAME}`
const RESPONSE_ACTIVITY_LIST = `${RESPONSE_TYPE}_${ACTIVITY_LIST_NAME}`
const RESPONSE_ACTIVITY_LIST_ERROR = `${RESPONSE_ERROR_TYPE}_${ACTIVITY_LIST_NAME}`
const RESET_LIST_STATE = `${RESET_STATE}_${ACTIVITY_LIST_NAME}`

const REQUEST_ACTIVITY = `${REQUEST_TYPE}_${ACTIVITY_NAME}`
const RESPONSE_ACTIVITY = `${RESPONSE_TYPE}_${ACTIVITY_NAME}`
const RESPONSE_ACTIVITY_ERROR = `${RESPONSE_ERROR_TYPE}_${ACTIVITY_NAME}`

export default {
  REQUEST_ACTIVITY_LIST,
  RESPONSE_ACTIVITY_LIST,
  RESPONSE_ACTIVITY_LIST_ERROR,
  RESET_LIST_STATE,
  REQUEST_ACTIVITY,
  RESPONSE_ACTIVITY,
  RESPONSE_ACTIVITY_ERROR
}
