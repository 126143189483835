/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { MARKET_ENDPOINT } from 'endpoints'

export const getEntityById = (id, params) =>
  httpWrapper({
    url: `${MARKET_ENDPOINT}/${id}`,
    method: 'get',
    params
  })

export const updateEntity = data =>
  httpWrapper({
    url: MARKET_ENDPOINT,
    method: 'put',
    data
  })

export const createEntity = data =>
  httpWrapper({
    url: MARKET_ENDPOINT,
    method: 'post',
    data
  })

export const deleteEntityById = id =>
  httpWrapper({
    url: `${MARKET_ENDPOINT}/${id}`,
    method: 'delete'
  })
