/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import discountDealFormHelpers from 'connectors/reducers/discountDeal/discountDealForm/helpers'

import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID_ERROR,
  REQUEST_DELETE_DEAL,
  RESPONSE_DELETE_DEAL,
  RESPONSE_DELETE_DEAL_ERROR,
  REQUEST_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL_ERROR,
  REQUEST_DEACTIVATE_DEAL,
  RESPONSE_DEACTIVATE_DEAL,
  RESPONSE_DEACTIVATE_DEAL_ERROR,
  REQUEST_DRAFT_DEAL,
  RESPONSE_DRAFT_DEAL,
  RESPONSE_DRAFT_DEAL_ERROR
} = helpers

export const initialState = {}

export const formInitialState = {
  entityDataState: DATA_STATE_NOT_REQUESTED,
  deleteDataState: DATA_STATE_NOT_REQUESTED,
  activateDataState: DATA_STATE_NOT_REQUESTED,
  deactivateDataState: DATA_STATE_NOT_REQUESTED,
  draftDataState: DATA_STATE_NOT_REQUESTED,
  entityData: {}
}

const buildData = (state, action) => data => ({
  ...state,
  [action.payload.key]: {
    ...formInitialState,
    ...state[action.payload.key],
    ...data
  }
})

const formReducer = (state = initialState, action) => {
  const append = buildData(state, action)

  switch (action.type) {
    // get deal by id
    case REQUEST_GET_DATA_BY_ID:
      return append({ entityDataState: DATA_STATE_REQUESTING })
    case RESPONSE_GET_DATA_BY_ID:
      return append({ entityDataState: DATA_STATE_RECIEVED, entityData: action.payload.data })
    case RESPONSE_GET_DATA_BY_ID_ERROR:
      return append({ entityDataState: DATA_STATE_ERROR })
    // delete deal
    case REQUEST_DELETE_DEAL:
      return append({ deleteDataState: DATA_STATE_REQUESTING })
    case RESPONSE_DELETE_DEAL:
      return append({ deleteDataState: DATA_STATE_RECIEVED })
    case RESPONSE_DELETE_DEAL_ERROR:
      return append({ deleteDataState: DATA_STATE_ERROR })
    // activate deal
    case REQUEST_ACTIVATE_DEAL:
      return append({ activateDataState: DATA_STATE_REQUESTING })
    case RESPONSE_ACTIVATE_DEAL:
      return append({ activateDataState: DATA_STATE_RECIEVED, entityData: action.payload })
    case RESPONSE_ACTIVATE_DEAL_ERROR:
      return append({ activateDataState: DATA_STATE_ERROR })
    // deactivate deal
    case REQUEST_DEACTIVATE_DEAL:
      return append({ deactivateDataState: DATA_STATE_REQUESTING })
    case RESPONSE_DEACTIVATE_DEAL:
      return append({ deactivateDataState: DATA_STATE_RECIEVED, entityData: action.payload.data })
    case RESPONSE_DEACTIVATE_DEAL_ERROR:
      return append({ deactivateDataState: DATA_STATE_ERROR })
    // to draft deal
    case REQUEST_DRAFT_DEAL:
      return append({ draftDataState: DATA_STATE_REQUESTING })
    case RESPONSE_DRAFT_DEAL:
      return append({ draftDataState: DATA_STATE_RECIEVED, entityData: action.payload.data })
    case RESPONSE_DRAFT_DEAL_ERROR:
      return append({ draftDataState: DATA_STATE_ERROR })
    // formSubmits
    case discountDealFormHelpers.RESPONSE_ACTIVATE_DEAL:
    case discountDealFormHelpers.RESPONSE_FORM:
      return append({ entityData: action.payload.data })
    // reset
    case RESET_DATA_STATE: {
      const { [action.payload.key]: removedState, ...restState } = state
      return {
        ...restState
      }
    }

    default:
      return state
  }
}

export default filterActions(formReducer, [
  RESET_DATA_STATE,
  REQUEST_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID_ERROR,
  REQUEST_DELETE_DEAL,
  RESPONSE_DELETE_DEAL,
  RESPONSE_DELETE_DEAL_ERROR,
  REQUEST_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL_ERROR,
  REQUEST_DEACTIVATE_DEAL,
  RESPONSE_DEACTIVATE_DEAL,
  RESPONSE_DEACTIVATE_DEAL_ERROR,
  discountDealFormHelpers.RESPONSE_FORM,
  discountDealFormHelpers.RESPONSE_ACTIVATE_DEAL,
  REQUEST_DRAFT_DEAL,
  RESPONSE_DRAFT_DEAL,
  RESPONSE_DRAFT_DEAL_ERROR
])
