/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import { OK_TITLE, ORANGE_SCHEMA } from 'constants/buttonsOptions'

import {
  TITLE,
  WARNING_MESSAGE_PROGRAMS,
  WARNING_MESSAGE_ACTIVITIES,
  VIEW_PROGRAMS_LINK,
  VIEW_ACTIVITIES_LINK
} from './types'
import { buildProgramsByDestinationPath, buildAppsByDestinationPath } from '../helpers'

import styles from './styles.scss'

export function ProgramsAndActivitiesModal(props) {
  const {
    isOpen,
    onClose,
    data: { programCount, activitiesCount, locationId }
  } = props

  const onHideAction = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <BasicModal
      isOpen={isOpen}
      title={TITLE}
      onClose={onClose}
      renderBody={() => (
        <section className={styles.wrapper}>
          {!!programCount && (
            <div className={styles.messageWrapper}>
              {WARNING_MESSAGE_PROGRAMS}
              <div className={styles.linkWrapper}>
                <Link to={buildProgramsByDestinationPath(locationId)} target="_blank">
                  {VIEW_PROGRAMS_LINK}
                </Link>
              </div>
            </div>
          )}
          {!!activitiesCount && (
            <div className={styles.messageWrapper}>
              {WARNING_MESSAGE_ACTIVITIES}
              <div className={styles.linkWrapper}>
                <Link to={buildAppsByDestinationPath(locationId)} target="_blank">
                  {VIEW_ACTIVITIES_LINK}
                </Link>
              </div>
            </div>
          )}
        </section>
      )}
    >
      <BasicButton
        colorSchema={ORANGE_SCHEMA}
        className={styles.centerButton}
        onClick={onHideAction}
      >
        {OK_TITLE}
      </BasicButton>
    </BasicModal>
  )
}

ProgramsAndActivitiesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  isProgam: PropTypes.bool.isRequired,
  isActivities: PropTypes.bool.isRequired
}
