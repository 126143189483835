/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const DEFAULT_PAGE_SIZE_OPTIONS = [25, 50, 100]

export const PREV_BUTTON_TEXT = 'Previous'
export const NEXT_BUTTON_TEXT = 'Next'
export const PAGE_SIZE_OPTION_POSTFIX = 'rows'
export const PAGE_JUMP_PREFIX = 'Page'
export const PAGE_JUMP_POSTFIX = 'of'

export const LOADER_TEXT = 'Loading...'

export const DEFAULT_PAGE_SIZE = 25
