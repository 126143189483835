import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import types from './types'

const { CATEGORY_LIST_NAME, DEFAULT_OPTIONS, CATEGORY_LIST_BY_PROVIDER } = types

const REQUEST_LIST = `${REQUEST_TYPE}_${CATEGORY_LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${CATEGORY_LIST_NAME}`
const RESPONSE_LIST_ERROR = `${RESPONSE_ERROR_TYPE}_${CATEGORY_LIST_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${CATEGORY_LIST_NAME}`

const REQUEST_LIST_BY_PROVIDER = `${REQUEST_TYPE}_${CATEGORY_LIST_BY_PROVIDER}`
const RESPONSE_LIST_BY_PROVIDER = `${RESPONSE_TYPE}_${CATEGORY_LIST_BY_PROVIDER}`
const RESPONSE_LIST_BY_PROVIDER_ERROR = `${RESPONSE_ERROR_TYPE}_${CATEGORY_LIST_BY_PROVIDER}`

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_LIST_ERROR,
  RESET_DATA_STATE,
  DEFAULT_OPTIONS,
  REQUEST_LIST_BY_PROVIDER,
  RESPONSE_LIST_BY_PROVIDER,
  RESPONSE_LIST_BY_PROVIDER_ERROR
}
