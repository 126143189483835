/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { ActivityResaleContext } from 'context'

export function withActivityData(Component) {
  function WithActivityData(props) {
    return (
      <ActivityResaleContext.Consumer>
        {context => <Component {...context} {...props} />}
      </ActivityResaleContext.Consumer>
    )
  }

  const componentName = Component.displayName || Component.name || 'Component'

  WithActivityData.displayName = `withActivityData(${componentName})`
  return WithActivityData
}
