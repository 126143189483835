/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const FORM_NAME = 'ADDRESS_FORM'

export const POST_FORM_DATA = 'POST_ADDRESS_FORM_DATA'
export const FORM_POST_SUCCESS = 'ADDRESS_FORM_POST_SUCCESS'
export const FORM_POST_ERROR = 'ADDRESS_FORM_POST_ERROR'
export const RESET_FORM = 'RESET_ADDRESS_FORM'

export const LOCATIONS_LIST = `${FORM_NAME}_COUNTRIES_LIST`

export default {
  FORM_NAME,
  FORM_POST_SUCCESS,
  FORM_POST_ERROR,
  POST_FORM_DATA,
  RESET_FORM,
  LOCATIONS_LIST
}
