/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { isDraftProgram } from 'modules/Programs'
import pick from 'lodash/pick'
import uniqueId from 'lodash/uniqueId'
import isEmpty from 'lodash/isEmpty'
import compose from 'lodash/fp/compose'
import { MAX_DECIMAL_PLACES } from './types'

const PREFIX = 'rate_adjustment_'

export function canChangeRates(programData) {
  if (programData && programData.id) {
    return isDraftProgram(programData)
  }
  return false
}

export const removeInvalidRates = rates =>
  rates
    .map(item => pick(item, ['value', 'startDate', 'endDate', 'reason', 'id']))
    .filter(item => !isEmpty(item))

export const convertRates = rates =>
  rates.map(item => ({
    id: item.id.includes(PREFIX) ? null : item.id,
    value: Number(item.value),
    startDate: item.startDate,
    endDate: item.endDate,
    reason: item.reason
  }))

export const prepareRates = compose(
  convertRates,
  removeInvalidRates
)

export const setEditable = (rates, editable) => rates.map(item => ({ ...item, editable }))

export const convertRateValue = value => (value ? Number(value).toFixed(MAX_DECIMAL_PLACES) : '')

export const appendEmptyRate = rates => [
  ...rates,
  {
    id: uniqueId(PREFIX),
    editable: true
  }
]
