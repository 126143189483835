/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { CollapseBox, Tag } from '@xplorie/ui-commons'
import { useDidMount, useLoading, useUpdateSomeDataState } from 'hooks/hooks'
import { withLoader } from 'decorators/withLoader'
import { DATA_STATE_ERROR } from 'helpers/types/actionHelpersTypes'
import { SMART_HOST_FEE_MODE } from 'constants/smartHostFee'
import compose from 'lodash/fp/compose'
import isEmpty from 'lodash/isEmpty'
import styles from './SmartHostFee.scss'
import {
  getComponentByMode,
  getSmartHostFeeOptions,
  SMART_HOST_FEE_TITLE,
  SmartHostFeeFormContext,
  SMART_HOST_MOBILE_FEE_TITLE,
  SMART_HOST_SUBSCRIPTION_TYPE
} from './types'
import { SmartHostFeeActions } from './SmartHostFeeActions'
import { appendEmptyFee, setEditable, isSmartHostEnabled, getSmartHostTag } from './helpers'

const CollapseBoxWithLoader = withLoader(CollapseBox)

function SmartHostFee(props) {
  const { formApi, fees, setFees } = useContext(SmartHostFeeFormContext)
  const {
    dataId,
    getSmartHostFee,
    resetData,
    smartHostFees,
    setSmartHostFeeMode,
    canChangeFees,
    programData,
    type
  } = props
  const { dataState, mode } = smartHostFees
  const [isLoading] = useLoading(dataState)
  const isEmptyFees = useMemo(() => !isEmpty(fees), [fees])
  const [isOpen, setIsOpen] = useState(isEmptyFees)
  const smartHostEnabled = isSmartHostEnabled(type, programData)
  const smartHostTag = getSmartHostTag(type, smartHostEnabled)

  useEffect(() => {
    setIsOpen(isEmptyFees)
  }, [isEmptyFees])

  useDidMount(() => {
    if (dataId) {
      getSmartHostFee(dataId)
    }

    return () => {
      resetData()
    }
  })

  useUpdateSomeDataState(
    () => {
      formApi.setValues({ fees })
    },
    [dataState],
    DATA_STATE_ERROR
  )

  useEffect(() => {
    setFees(smartHostFees.fees)
    setSmartHostFeeMode(SMART_HOST_FEE_MODE.LIST)
  }, [setSmartHostFeeMode, setFees, smartHostFees.fees])

  const onCancel = useCallback(() => {
    setFees(smartHostFees.fees)
    setSmartHostFeeMode(SMART_HOST_FEE_MODE.LIST)
    setIsOpen(false)
  }, [setSmartHostFeeMode, setFees, smartHostFees.fees])

  const setAddMode = useCallback(() => {
    const nextFees = compose(
      appendEmptyFee,
      setEditable
    )(fees, false)

    formApi.setValues({ fees: nextFees })
    setFees(nextFees)
    setSmartHostFeeMode(SMART_HOST_FEE_MODE.ADD)
    setIsOpen(true)
  }, [setSmartHostFeeMode, setFees, formApi, fees])

  const onSave = useCallback(() => formApi.submitForm(), [formApi])

  const setEditMode = useCallback(() => {
    formApi.setValues({ fees })
    setSmartHostFeeMode(SMART_HOST_FEE_MODE.EDIT)
    setIsOpen(true)
  }, [setSmartHostFeeMode, formApi, fees])

  const hasFees = useMemo(() => smartHostFees.fees.length > 0, [smartHostFees])
  const options = useMemo(() => getSmartHostFeeOptions(setAddMode, setEditMode), [
    setAddMode,
    setEditMode
  ])
  const Content = useMemo(() => getComponentByMode(mode), [mode])
  const canExpand = useMemo(() => mode === SMART_HOST_FEE_MODE.LIST && hasFees, [mode, hasFees])

  const title = (
    <div className={styles.title}>
      {type === SMART_HOST_SUBSCRIPTION_TYPE ? SMART_HOST_FEE_TITLE : SMART_HOST_MOBILE_FEE_TITLE}
      <Tag className={smartHostEnabled ? styles.statusEnabled : styles.statusDisabled}>
        {smartHostTag}
      </Tag>
    </div>
  )

  return (
    <CollapseBoxWithLoader
      className={styles.wrapper}
      titleClassName
      title={title}
      isOpen={isOpen}
      isLoading={isLoading}
      canExpand={canExpand}
      ActionsComponent={
        canChangeFees && (
          <SmartHostFeeActions
            mode={mode}
            options={options}
            hasFees={hasFees}
            setAddMode={setAddMode}
            onSave={onSave}
            onCancel={onCancel}
            type={type}
          />
        )
      }
    >
      <Content />
    </CollapseBoxWithLoader>
  )
}

SmartHostFee.propTypes = {
  smartHostFees: PropTypes.object.isRequired,
  resetData: PropTypes.func.isRequired,
  setSmartHostFeeMode: PropTypes.func.isRequired,
  getSmartHostFee: PropTypes.func.isRequired,
  dataId: PropTypes.string.isRequired,
  canChangeFees: PropTypes.bool.isRequired,
  smartHostEnabled: PropTypes.bool.isRequired,
  programData: PropTypes.object,
  type: PropTypes.string.isRequired
}

SmartHostFee.defaultProps = {
  programData: {}
}

export default SmartHostFee
