/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { formActions } from 'reducers/ticketTypeForm'
import { withAuthContext } from 'helpers/withAuthContext'
import { withActivityData } from 'modules/ActivityProducts/ActivityResaleTab/decorators'
import { TicketTypeModal as TicketTypeModalComponent } from './TicketTypeModal'
import { buildTicketGroupOptions } from './helpers'

const mapStateToProps = state => ({
  ...state.ticketTypeForm,
  ticketGroupsOptions: buildTicketGroupOptions(state.ticketGroupsList.data)
})

const mapDispatchToProps = dispatch => bindActionCreators({ ...formActions }, dispatch)

export const TicketTypeModal = compose(
  withAuthContext,
  withActivityData,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TicketTypeModalComponent)
