/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './BouncingLoader.scss'

export function BouncingLoader({ className, ...rest }) {
  return (
    <div className={classNames(styles.bouncingLoader, className)} {...rest}>
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
    </div>
  )
}
BouncingLoader.propTypes = {
  className: PropTypes.string
}
BouncingLoader.defaultProps = {
  className: ''
}
