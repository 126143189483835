/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'
import { actions, selectors } from 'reducers/program/prebookInfoForm'
import { getProgramDataState } from 'reducers/programsForm'

import { PrebookInfo as PrebookInfoComponent } from './PrebookInfo'

const { savePrebookInfo, getPrebookInfo } = actions
const { getProgramPrebookInfoStateByProgramId } = selectors

const mapStateToProps = (state, { dataId }) => {
  const prebookState = getProgramPrebookInfoStateByProgramId(state, dataId)
  const programFormState = getProgramDataState(state, dataId)

  return {
    formDataState: prebookState.formDataState,
    entityDataState: prebookState.entityDataState,
    entityData: prebookState.entityData,
    programData: programFormState.programData
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ savePrebookInfo, getPrebookInfo }, dispatch)

export const PrebookInfo = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PrebookInfoComponent)
