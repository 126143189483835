/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import { actions, getModalsValues } from 'reducers/reduxModals'

import { ReduxModalsContainer as ReduxModalsContainerComponent } from './ReduxModalsContainer'

const { enqueue, dequeue, resetAll } = actions

const mapStateToProps = state => {
  const modals = getModalsValues(state)
  return { modals, authDataState: state.currentUser.dataState }
}

const mapDispatchToProps = dispatch => bindActionCreators({ enqueue, dequeue, resetAll }, dispatch)

export const ReduxModalsContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReduxModalsContainerComponent)
