/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useMemo, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { SimpleTable } from 'components/Tables'
import { useDidMount, useDataStatesComparison } from 'hooks'
import { eligibilityTableColumns, buildDefaultButtonColumn } from 'helpers/dataTablesHelpers'
import { EditButton } from './Buttons'
import { HEADER } from './types'

export function EligibilityList(props) {
  const {
    dataState,
    itemsCount,
    itemsPerPage,
    data,
    onGetData,
    authUser,
    TableComponent,
    getApi,
    listWatchers,
    ...rest
  } = props
  const listApi = useRef()

  useDidMount(() => {
    onGetData()
  })

  const columns = useMemo(
    () => [
      ...eligibilityTableColumns,
      {
        ...buildDefaultButtonColumn(),
        Cell: eligibility => <EditButton data={eligibility.original} authUser={authUser} />
      }
    ],
    [authUser]
  )

  const onGetListApi = useCallback(
    api => {
      listApi.current = api
      if (getApi) {
        getApi(api)
      }
    },
    [getApi]
  )

  /* watch by the dataStates from redux state
     and close update list if neded */
  useDataStatesComparison(() => {
    onGetData(listApi.current.getListParameters())
  }, listWatchers)

  return (
    <TableComponent
      getApi={onGetListApi}
      dataState={dataState}
      columns={columns}
      onGetData={onGetData}
      itemsPerPage={itemsPerPage}
      itemsCount={itemsCount}
      tableHeader={HEADER}
      data={data}
      // TODO: Need adjust and refactor it with reimplement this functionality.
      needFirstGetData={false}
      withBasicAuth={false}
      //
      {...rest}
    />
  )
}

EligibilityList.propTypes = {
  dataState: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemsCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number,
  onGetData: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired,
  TableComponent: PropTypes.func,
  getApi: PropTypes.func,
  listWatchers: PropTypes.array.isRequired
}

EligibilityList.defaultProps = {
  TableComponent: SimpleTable,
  itemsPerPage: 10,
  getApi: null
}
