/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const FORM_NAME = 'LOCATIONS_FORM'
export const GET_ENTITY_NAME = 'LOCATION_GET_ENTITY_NAME'
export const LINK_CHILD_LOCATION = 'LINK_CHILD_LOCATION'
export const CREATE_COUNTRY = 'CREATE_COUNTRY'
export const CREATE_LOCATION_AND_LINK = 'CREATE_LOCATION_AND_LINK'
export const UPDATE_AND_ADD_CHILDREN = `UPDATE_AND_ADD_CHILDREN_${FORM_NAME}`

export const NEW_KEY = 'new_location'

export default {
  FORM_NAME,
  GET_ENTITY_NAME,
  LINK_CHILD_LOCATION,
  CREATE_COUNTRY,
  CREATE_LOCATION_AND_LINK,
  NEW_KEY
}
