/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { bindActionCreators, compose } from 'redux'
import withAuthorization from 'helpers/withAuthorization'
import { actions } from 'reducers/deals/dealsList'
import { connect } from 'react-redux'
import { Discounts as DiscountsComponent } from './Discounts'

export const mapDispatchToProps = dispatch => {
  const { getDealsList: onGetData } = actions
  return bindActionCreators({ onGetData }, dispatch)
}

export const Discounts = compose(
  withAuthorization,
  connect(
    null,
    mapDispatchToProps
  )
)(DiscountsComponent)
