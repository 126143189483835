/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { withFormContext } from 'components/FormComponents/helpers'
import { WeekSelectorInput } from './WeekSelectorInput'

export const WeekSelectorFieldComponent = props => {
  const { disabled, fieldName, ...rest } = props

  return <WeekSelectorInput {...rest} field={fieldName} disabled={disabled} />
}
WeekSelectorFieldComponent.propTypes = {
  disabled: PropTypes.bool,
  fieldName: PropTypes.string.isRequired
}
WeekSelectorFieldComponent.defaultProps = { disabled: false }

export const WeekSelectorField = withFormContext(WeekSelectorFieldComponent)
