/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { searchByColumns } from 'services/http/search'

import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  REQUEST_PAYLOAD,
  REQUEST_DEFAULT_OPTIONS
} = helpers

const getProgramsList = (filters = REQUEST_PAYLOAD, options = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await searchByColumns(filters, {
    ...REQUEST_DEFAULT_OPTIONS,
    ...options
  }).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { ...request.data }))
    return 'getProgramsList success'
  }

  return 'getProgramsList failed'
}

export default {
  getProgramsList
}
