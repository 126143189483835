/*
 * COPYRIGHT:     Copyright © 2019-2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { ACTIVITY_URL } from 'endpoints'

export const buildTicketGroupsURL = (activityId = '') =>
  `${ACTIVITY_URL}/${activityId}/tickets/groups`

export const buildTicketGroupsListURL = (activityId = '') =>
  `${ACTIVITY_URL}/${activityId}/tickets/groups/`

export const buildTicketGroupsURLByGroupId = (activityId = '', groupId = '') =>
  `${ACTIVITY_URL}/${activityId}/tickets/groups/${groupId}`

export const buildInventoryURLByGroupId = (activityId = '', groupId = '') =>
  `${ACTIVITY_URL}/${activityId}/tickets/groups/${groupId}/inventory`

export const buildInventoryURLByGroupIdAndInvId = (activityId = '', groupId = '', invId = '') =>
  `${ACTIVITY_URL}/${activityId}/tickets/groups/${groupId}/inventory/${invId}`

export const buildDownloadTicketURL = (activityId, groupId, id) =>
  `${ACTIVITY_URL}/${activityId}/tickets/groups/${groupId}/inventory/${id}`

export const removeTicketURL = (activityId, groupId) =>
  `${ACTIVITY_URL}/${activityId}/tickets/groups/${groupId}/inventory/remove`

export const assingTicketTypeURL = (activityId, groupId, typeId) =>
  `${ACTIVITY_URL}/${activityId}/tickets/groups/${groupId}/types/${typeId}`

export const generateBarcodeByIdURL = (activityId, groupId) =>
  `${ACTIVITY_URL}/${activityId}/tickets/groups/${groupId}/inventory/barcodes`

export const buildAssignGroupsOrderURL = activityId =>
  `${ACTIVITY_URL}/${activityId}/tickets/groups/ordering`

export const buildGroupTemplateURL = (activityId, groupId) =>
  `${ACTIVITY_URL}/${activityId}/tickets/groups/${groupId}/template`

export const buildGroupBarcodesURL = activityId =>
  `${ACTIVITY_URL}/${activityId}/tickets/groups/barcodes`
