/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions, selectors } from 'reducers/marketDictionary/marketDictionaryList'

import { SelectMarketField as MarketFieldComponent } from './SelectMarketField'
import { buildOptions } from './helpers'

const { getMarketList } = actions
const { getMarketListState } = selectors

function mapStateToProps(state) {
  const listState = getMarketListState(state)
  return { options: buildOptions(listState.data), dataState: listState.dataState }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getList: getMarketList }, dispatch)
}

export const SelectMarketField = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MarketFieldComponent)
