/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import {
  NAME,
  UPLOAD_TEMPLATE,
  REMOVE_TEMPLATE,
  DOWNLOAD_TEMPLATE,
  DOWNLOAD_BARCODE_TEMPLATE
} from './types'

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

const REQUEST_UPLOAD_TEMPLATE = `${REQUEST_TYPE}_${UPLOAD_TEMPLATE}`
const RESPONSE_UPLOAD_TEMPLATE = `${RESPONSE_TYPE}_${UPLOAD_TEMPLATE}`
const RESPONSE_UPLOAD_TEMPLATE_ERROR = `${RESPONSE_ERROR_TYPE}_${UPLOAD_TEMPLATE}`

const REQUEST_REMOVE_TEMPLATE = `${REQUEST_TYPE}_${REMOVE_TEMPLATE}`
const RESPONSE_REMOVE_TEMPLATE = `${RESPONSE_TYPE}_${REMOVE_TEMPLATE}`
const RESPONSE_REMOVE_TEMPLATE_ERROR = `${RESPONSE_ERROR_TYPE}_${REMOVE_TEMPLATE}`

const REQUEST_DOWNLOAD_TEMPLATE = `${REQUEST_TYPE}_${DOWNLOAD_TEMPLATE}`
const RESPONSE_DOWNLOAD_TEMPLATE = `${RESPONSE_TYPE}_${DOWNLOAD_TEMPLATE}`
const RESPONSE_DOWNLOAD_TEMPLATE_ERROR = `${RESPONSE_ERROR_TYPE}_${DOWNLOAD_TEMPLATE}`

const REQUEST_DOWNLOAD_BARCODE_TEMPLATE = `${REQUEST_TYPE}_${DOWNLOAD_BARCODE_TEMPLATE}`
const RESPONSE_DOWNLOAD_BARCODE_TEMPLATE = `${RESPONSE_TYPE}_${DOWNLOAD_BARCODE_TEMPLATE}`
const RESPONSE_DOWNLOAD_BARCODE_TEMPLATE_ERROR = `${RESPONSE_ERROR_TYPE}_${DOWNLOAD_BARCODE_TEMPLATE}`

export default {
  RESET_DATA_STATE,
  REQUEST_UPLOAD_TEMPLATE,
  RESPONSE_UPLOAD_TEMPLATE,
  RESPONSE_UPLOAD_TEMPLATE_ERROR,
  REQUEST_REMOVE_TEMPLATE,
  RESPONSE_REMOVE_TEMPLATE,
  RESPONSE_REMOVE_TEMPLATE_ERROR,
  REQUEST_DOWNLOAD_TEMPLATE,
  RESPONSE_DOWNLOAD_TEMPLATE,
  RESPONSE_DOWNLOAD_TEMPLATE_ERROR,
  REQUEST_DOWNLOAD_BARCODE_TEMPLATE,
  RESPONSE_DOWNLOAD_BARCODE_TEMPLATE,
  RESPONSE_DOWNLOAD_BARCODE_TEMPLATE_ERROR
}
