/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { ContentWithNav } from 'components/ContentWithNav'
import { AddEntityButton } from 'components/Buttons'
import { ButtonsWrapper, HeaderWrapper } from 'components/Wrappers'
import { AccountList } from 'modules/Accounts/AccountList'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { HORIZONTAL_ALIGN } from 'constants/alignTypes'
import { FormFilter } from 'components/FormComponents'
import { FilteredSearch, TableField } from 'modules/FilteredSearch'
import { useDidMount } from 'hooks/hooks'
import { ACCOUNT } from 'constants/formCodes'
import { useSliderApi } from 'hooks/useSliderApi'
import { ACCOUNT_FILTERS, ACCOUNT_INITIAL_FILTERS, TEXT_BTN_ADD_NEW, TITLE_PAGE } from './types'
import styles from './Accounts.scss'
import { buildFilters } from './helpers'

const Accounts = props => {
  const { onGetData } = props
  const filteredSearchApi = useRef()
  const sliderApi = useSliderApi(ACCOUNT)

  const onClickHandler = useCallback(() => {
    sliderApi.open()
  }, [sliderApi])

  const onChangeHandler = useCallback(
    ({ filters, params }) => {
      onGetData(params, buildFilters(filters))
    },
    [onGetData]
  )
  const onGetApi = useCallback(api => {
    filteredSearchApi.current = api
  }, [])

  useDidMount(() => {
    filteredSearchApi.current.emitChange()
  })

  return (
    <ContentWithNav>
      <section className={styles.customWrapper}>
        <HeaderWrapper title={TITLE_PAGE} />
        <FilteredSearch
          getApi={onGetApi}
          onChange={onChangeHandler}
          initialFilters={ACCOUNT_INITIAL_FILTERS}
        >
          <div className={styles.containerWrapper}>
            <ButtonsWrapper align={HORIZONTAL_ALIGN}>
              <Column>
                <FormFilter filters={ACCOUNT_FILTERS} />
              </Column>
              <Column>
                <AddEntityButton
                  id="new-account-btn"
                  onClick={onClickHandler}
                  colorSchema={ORANGE_SCHEMA}
                >
                  {TEXT_BTN_ADD_NEW}
                </AddEntityButton>
              </Column>
            </ButtonsWrapper>
            <TableField Component={AccountList} needFirstGetData={false} />
          </div>
        </FilteredSearch>
      </section>
    </ContentWithNav>
  )
}

Accounts.propTypes = {
  onGetData: PropTypes.func.isRequired
}

export default Accounts
