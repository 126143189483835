/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const SEARCH_BTN_TEXT = 'Search'

export const PROGRAM_PLACEHOLDER = 'Search for a Program here...'
export const PM_COMPANY_PLACEHOLDER = 'Search for a Property Mangement Company here...'
export const ACTIVITY_PLACEHOLDER = 'Search for an Activity Provider Product here...'
export const ACTIVITY_PROVIDER_PLACEHOLDER = 'Search for an Activity Provider here...'
export const DEALS_PLACEHOLDER = 'Search for Discounts here...'
