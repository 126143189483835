/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const shiftItems = ({ data = [], dragIndex = 0, hoverIndex = 0 }) => {
  const dragCard = data[dragIndex]
  const newArray = [...data]
  newArray.splice(dragIndex, 1)
  newArray.splice(hoverIndex, 0, dragCard)
  return newArray
}

export const canShift = ({ dragIndex, hoverIndex, monitor, ref }) => {
  if (dragIndex === hoverIndex) {
    return false
  }
  const hoverBoundingRect = ref.current.getBoundingClientRect()

  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
  const clientOffset = monitor.getClientOffset()
  const hoverClientY = clientOffset.y - hoverBoundingRect.top
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return false
  }
  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    return false
  }
  return true
}
