/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function SearchIcon(props) {
  return (
    <svg viewBox="100 75 600 600" {...props}>
      <path
        d="M586.473,523.853L483.867,421.247c30.219-56.954,21.348-129.206-26.621-177.169c-58.855-58.84-154.27-58.84-213.116,0.017
c-58.841,58.848-58.841,154.253,0,213.102c0.004,0.009,0.01,0.009,0.014,0.017c47.927,47.915,120.097,56.807,177.023,26.675
l102.545,102.543c0.383,0.398,0.773,0.788,1.171,1.177c17.603,17.002,45.664,16.517,62.672-1.082
C604.561,568.919,604.079,540.863,586.473,523.853z M269.171,432.239c-0.014-0.017-0.029-0.026-0.043-0.043
c-44.91-45.023-44.91-117.895,0-162.919v-0.009c44.991-45.015,117.956-45.032,162.971-0.043
c45.015,44.989,45.034,117.956,0.043,162.971C387.151,477.211,314.186,477.228,269.171,432.239z M564.271,560.315
c-2.344,4.579-7.953,6.38-12.529,4.043c-1.774-0.909-3.209-2.372-4.094-4.155l-91.658-91.484l12.587-12.587l91.658,91.657
C564.81,550.134,566.617,555.744,564.271,560.315z"
      />
    </svg>
  )
}
