/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const GET_ACTIVITY_CATEGORY_TAGS = 'GET_ACTIVITY_CATEGORY_TAGS'
export const GET_ACTIVITY_CATEGORY_TAGS_BY_ID = 'GET_ACTIVITY_CATEGORY_TAGS_BY_ID'

export default {
  GET_ACTIVITY_CATEGORY_TAGS,
  GET_ACTIVITY_CATEGORY_TAGS_BY_ID
}
