/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export function buildMapper(columns) {
  return columns.map(value => {
    const newValue = {
      columnName: value.Header,
      pathToProperty: value.pathToProperty || value.accessor
    }

    if (value.valueMap) {
      newValue.valueMap = value.valueMap
    }

    return newValue
  })
}

export function buildExportContract(columns, filters) {
  return {
    mapping: buildMapper(columns),
    searchRequests: filters
  }
}
