/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'informed'
import classNames from 'classnames'
import { logInRedirect } from 'services/auth'
import get from 'lodash/get'
import { DATA_STATE_REQUESTING, DATA_STATE_RECIEVED, DATA_STATE_ERROR } from 'helpers/actionHelpers'
import { FormContent, ControlButtonsContainer, TextField } from 'components/FormComponents'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { ForgotPasswordModal } from 'modules/Password/ForgotPassword'
import {
  PASSWORD_FIELD_LABEL,
  EMAIL_FIELD_LABEL,
  SUBMIT_BUTTON_TEXT,
  INCORRECT_PASSWORD_MESSAGE,
  FORGOT_PASSWORD_BUTTON_TEXT
} from './types'

import styles from './LoginForm.scss'

const onSubmit = action => contractData => {
  action(contractData)
}

export class LoginForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAuthError: false,
      isSubmitDisabled: true,
      isShowModal: false
    }

    this.formApi = null
  }

  componentDidUpdate(prevProps) {
    const { dataState, history, location } = this.props
    if (prevProps.dataState !== dataState && dataState === DATA_STATE_RECIEVED) {
      history.push(logInRedirect(location))
    }
    if (prevProps.dataState !== dataState && dataState === DATA_STATE_REQUESTING) {
      this.setState({ showAuthError: false })
    }
    if (prevProps.dataState !== dataState && dataState === DATA_STATE_ERROR) {
      this.setState({ showAuthError: true })
    }
  }

  onCloseAuthError = () => this.setState({ showAuthError: false })

  onSubmitHandler = () => {
    if (!this.state.isSubmitDisabled) {
      this.formApi.submitForm()
    }
  }

  onKeyDownHandler = ev => {
    if (ev.keyCode && ev.keyCode === 13) {
      this.onSubmitHandler()
    }
  }

  onGetFormApi = api => {
    this.formApi = api
  }

  componentDidMount() {
    // crutch to fix disabling submit button when it was auto filled
    // global chrome bug do not allow to do it correctly
    if (typeof document !== 'undefined') {
      setTimeout(() => {
        const filledInputs = document.querySelectorAll('input:-internal-autofill-selected')
        if (filledInputs.length === 2) {
          this.setState({ isSubmitDisabled: false })
        }
      }, 1000)
    }
  }

  onChangeHandler = formState => {
    const { invalid, values } = formState
    const email = get(values, 'email')
    const password = get(values, 'password')

    const isSubmitDisabled = invalid || !email || !password

    this.setState({ isSubmitDisabled })
  }

  onOpenModal = () => this.setState({ ...this.state, isShowModal: true })

  onCloseModal = () => this.setState({ ...this.state, isShowModal: false })

  render() {
    const { dataState, logIn } = this.props
    const { showAuthError, isSubmitDisabled } = this.state
    const isLoading = dataState === DATA_STATE_REQUESTING
    const isFormDisabled = isLoading
    const isButtonDisabled = isFormDisabled || isSubmitDisabled
    return (
      <>
        <Form getApi={this.onGetFormApi} onSubmit={onSubmit(logIn)} onChange={this.onChangeHandler}>
          {({ formApi }) => (
            <FormContent formApi={formApi} disabled={isFormDisabled}>
              <div
                className={classNames(styles.loginFormWrapper)}
                onKeyDown={this.onKeyDownHandler}
              >
                <TextField
                  label={EMAIL_FIELD_LABEL}
                  id="email"
                  type="text"
                  fieldName="email"
                  className={classNames(styles.loginInput)}
                />
                <TextField
                  label={PASSWORD_FIELD_LABEL}
                  id="password"
                  fieldName="password"
                  type="password"
                  className={classNames(styles.loginInput)}
                />
                <ControlButtonsContainer>
                  <BasicButton
                    id="login_btn"
                    colorSchema={ORANGE_SCHEMA}
                    disabled={isButtonDisabled}
                    onClick={this.onSubmitHandler}
                  >
                    {SUBMIT_BUTTON_TEXT}
                  </BasicButton>
                </ControlButtonsContainer>
                <div onClick={this.onOpenModal} className={classNames(styles.forgotButton)}>
                  {FORGOT_PASSWORD_BUTTON_TEXT}
                </div>
              </div>
            </FormContent>
          )}
        </Form>
        {showAuthError && (
          <div className="notification is-danger">
            <button className="delete" onClick={this.onCloseAuthError} />
            {INCORRECT_PASSWORD_MESSAGE}
          </div>
        )}
        <ForgotPasswordModal isOpen={this.state.isShowModal} onClose={this.onCloseModal} />
      </>
    )
  }
}

LoginForm.propTypes = {
  dataState: PropTypes.string.isRequired,
  logIn: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}
LoginForm.defaultProps = {}
