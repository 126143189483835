/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { getSeasonsListState } from 'reducers/programSeasonList'
import get from 'lodash/get'
import {
  isProgramLifeCycleProcessPending,
  getProgramsLifeCycleProcessState
} from 'reducers/programLifeCycleProcess'
import { isProgramApprovalPending, getProgramsApprovalState } from 'reducers/programApproval'
import { selectors } from 'reducers/program/programMaterialsForm'
import { programInitialState } from './reducer'

const { getProgramMarketingFormIds, getProgramMarketingFormDataStatesById } = selectors

export const getProgramState = state => state.programsForm

export const getProgramDataState = (state, id) => state.programsForm[id] || programInitialState

export const getPmcDataByProgramDataState = dataState => get(dataState, 'programData.pmCompany')

export const isProgramDataLoading = (state, id) => {
  const programState = getProgramDataState(state, id)
  const seasonsListState = getSeasonsListState(state, id)
  return (
    programState.programDataState === DATA_STATE_REQUESTING ||
    seasonsListState.dataState === DATA_STATE_REQUESTING
  )
}

export const isProgramActionPending = (state, id) => {
  const programState = getProgramDataState(state, id)
  const seasonsListState = getSeasonsListState(state, id)
  return [
    [
      programState.programDataState,
      programState.dataState,
      programState.deleteProgramDataState,
      programState.unassignPhoneDataState,
      programState.assignPhoneDataState,
      seasonsListState.seasonRemoveState,
      seasonsListState.dataState,
      state.seasonForm.cloneDataState
    ].some(value => value === DATA_STATE_REQUESTING),
    isProgramApprovalPending(state, id),
    isProgramLifeCycleProcessPending(state, id)
  ].some(value => Boolean(value))
}

export const getProgramListWatchers = state => {
  const programState = getProgramState(state)
  const approvalStates = getProgramsApprovalState(state)
  const programLifeCycleProcessStates = getProgramsLifeCycleProcessState(state)

  return Object.values(programState)
    .reduce(
      (acc, value) => [
        ...acc,
        value.dataState,
        value.deleteProgramDataState,
        value.unassignPhoneDataState,
        value.assignPhoneDataState,
        value.emptyProgramDataState
      ],
      []
    )
    .concat(
      Object.values(approvalStates).reduce(
        (acc, value) => [...acc, value.approvalState, value.programDraftState],
        []
      )
    )
    .concat(
      Object.values(programLifeCycleProcessStates).reduce(
        (acc, value) => [...acc, value.activateProgramDataState, value.deactivateProgramDataState],
        []
      )
    )
    .concat(
      getProgramMarketingFormIds(state).reduce(
        (acc, id) => [...acc, ...getProgramMarketingFormDataStatesById(state, id)],
        []
      )
    )
    .filter(value => Boolean(value))
}

export const getPrebookFlagById = (state, id) => {
  const programState = getProgramDataState(state, id)
  return get(programState, 'programData.programInfo.prebookOnly', false)
}

export const getEmptyProgramDataState = (state, id) => {
  const programState = getProgramDataState(state, id)
  return programState.emptyProgramDataState
}
