/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import find from 'lodash/find'
import { InvTypeOptions } from 'mockData/ticketGroup'
import {
  togglePercentageSymbol,
  phoneTheeFourTheeFormatValidate,
  linkValidate
} from 'helpers/formHelpers'
import { LINK_REGISTRATION_TYPE, CALL_2_BOOK_REGISTRATION_TYPE } from 'constants/resell'
import { REDEMPTION_METHOD } from 'constants/app'
import { E_TICKETS, BARCODES, WILL_CALL, NOT_REQUIRED } from 'constants/ticketFormats'
import { selectorFeeField } from './selectors'
import {
  BUTTON_LINK_LABEL,
  AP_PHONE_NUMBER_LABEL,
  BUTTON_LINK_PLACEHOLDER,
  AP_PHONE_NUMBER_PLACEHOLDER,
  REDEMPTIONS_METHOD_LABELS_MAP
} from './types'

export const getTicketTypeTitleByCode = code => {
  const option = find(InvTypeOptions, ['value', code]) || {}
  return option.label || 'None'
}

export function checkPercentageSymbol(value) {
  return /%$/g.test(value)
}

export function checkValidFieldValue(value) {
  return /^\d+$/g.test(value)
}

export function allowStartFillingWithDot(value) {
  return /^\./g.test(value)
}

export const preValidateMask = isPercentage => fieldValue => {
  if (!fieldValue) {
    return null
  }

  if (isPercentage) {
    return fieldValue
  }

  if (allowStartFillingWithDot(fieldValue)) {
    return '0.'
  }

  return fieldValue.replace(/[^0-9\\.]/, '').replace(/(\d+\.\d{2})(\d+$)/, (match, p1) => p1)
}

// TODO: better to create that function like pure function
export const onBlurPercentageMask = ({ formApi, percentageValue, selectorValue }) => {
  const boolOption = formApi.getValue(percentageValue)
  const fieldValue = formApi.getValue(selectorValue)

  const setNewValue = formApi.setValue.bind(null, selectorFeeField)
  const isValidFieldValue = checkValidFieldValue(fieldValue)

  if (!boolOption) {
    return isValidFieldValue ? setNewValue(`${fieldValue}.00`) : null
  }

  return isValidFieldValue ? setNewValue(togglePercentageSymbol(fieldValue, boolOption)) : null
}

function prepareFieldProps(label, placeholder, validate) {
  return { label, placeholder, validate }
}

export function getRegHeaderByRegType(type) {
  switch (type) {
    case LINK_REGISTRATION_TYPE:
      return prepareFieldProps(BUTTON_LINK_LABEL, BUTTON_LINK_PLACEHOLDER, linkValidate)
    case CALL_2_BOOK_REGISTRATION_TYPE:
      return prepareFieldProps(
        AP_PHONE_NUMBER_LABEL,
        AP_PHONE_NUMBER_PLACEHOLDER,
        phoneTheeFourTheeFormatValidate
      )
    default:
      return prepareFieldProps()
  }
}

/**
 * @returns {options:Array, default:string} Array of select options
 */
export function buildRedemptionsOptions(ticketFormat) {
  let option = { options: [], default: null }

  switch (ticketFormat) {
    case E_TICKETS:
    case BARCODES:
    case WILL_CALL:
      option = {
        options: [REDEMPTION_METHOD.DOWNLOAD_TICKETS],
        default: REDEMPTION_METHOD.DOWNLOAD_TICKETS
      }
      break
    case NOT_REQUIRED:
      option = {
        options: [
          REDEMPTION_METHOD.PRESENT_PHOTO_ID,
          REDEMPTION_METHOD.PRESENT_CONFIRMATION_NUMBER
        ],
        default: null
      }
      break
    default:
      option = {
        options: [
          REDEMPTION_METHOD.DOWNLOAD_TICKETS,
          REDEMPTION_METHOD.PRESENT_PHOTO_ID,
          REDEMPTION_METHOD.PRESENT_CONFIRMATION_NUMBER
        ],
        default: null
      }
  }

  return {
    ...option,
    options: option.options.map(value => ({ label: REDEMPTIONS_METHOD_LABELS_MAP[value], value }))
  }
}

/**
 *
 * @param {string} redemptionMethod
 */
export function canChangeConfirmationRequired(redemptionMethod) {
  return redemptionMethod && redemptionMethod !== REDEMPTION_METHOD.PRESENT_CONFIRMATION_NUMBER
}
