/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { PMC_EXPORT_MASTER_URL } from 'endpoints'
import { downloadFile } from 'helpers/downloadHelper'

export const exportReport = (authKey, fileFormat) =>
  downloadFile(`${PMC_EXPORT_MASTER_URL}/${fileFormat}`, `pmc_report.${fileFormat}`)
