/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'

import PropTypes from 'prop-types'
import { useSliderApi } from 'hooks'
import { canUpdateEligibility } from 'modules/Eligibility'
import { BasicButton } from '@xplorie/ui-commons'
import { ELIGIBILITY } from 'constants/formCodes'
import { ORANGE_SCHEMA, SMALLER_SIZE, EDIT_TITLE, VIEW_TITLE } from 'constants/buttonsOptions'

export function EditButton(props) {
  const { data, authUser } = props
  const sliderApi = useSliderApi(ELIGIBILITY)
  const canUpdate = canUpdateEligibility(authUser)
  const TITLE = canUpdate ? EDIT_TITLE : VIEW_TITLE

  const onClickHandler = useCallback(() => {
    sliderApi.open(data.id, { headerProps: { title: data.label } })
  }, [data, sliderApi])

  return (
    <BasicButton size={SMALLER_SIZE} colorSchema={ORANGE_SCHEMA} onClick={onClickHandler}>
      {TITLE}
    </BasicButton>
  )
}

EditButton.propTypes = {
  authUser: PropTypes.object.isRequired,
  data: PropTypes.object
}

EditButton.defaultProps = {
  data: null
}
