/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const RANGE_FIELD_LABEL = 'Start & End Date'
export const RANGE_FIELD_PLACEHOLDER = 'Start & End Date'
export const RANGE_FIELD_FIELD = 'datePeriod'

export const ADD_DAY_FIELD_LABEL = 'Full day'
export const ADD_DAY_FIELD = 'allDay'

export const CONFIRMED_BY_FIELD_LABEL = 'Confirmed by'
export const CONFIRMED_BY_FIELD_PLACEHOLDER = 'Confirmed by'
export const CONFIRMED_BY_FIELD_FIELD = 'confirmedBy'

export const TIME_SLOTS_LABEL = 'Time Slots:'
export const TIME_PERIODS_FIELD = 'timePeriods'

export const REASON_FIELD_LABEL = 'Reason'
export const REASON_FIELD_PLACEHOLDER = 'Reason'
export const REASON_FIELD_FIELD = 'reason'

export const DATE_FIELD = 'date'
