/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildListParams } from 'services/http/helpers'
import { PROGRAMS_HISTORY_URL, PROGRAMS_HISTORY_YEARS_URL } from 'endpoints'

import { buildPath } from 'helpers/httpHelpers'

export const getProgramHistoryById = (programId, year, params, data) =>
  httpWrapper({
    url: buildPath(PROGRAMS_HISTORY_URL, { programId, year }),
    method: 'post',
    params: buildListParams(params),
    data
  })

export const getProgramHistoryYears = (programId, data) =>
  httpWrapper({
    url: buildPath(PROGRAMS_HISTORY_YEARS_URL, { programId }),
    method: 'post',
    data
  })
