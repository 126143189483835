/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_EXPORT_LIST,
  RESPONSE_EXPORT_LIST,
  RESPONSE_EXPORT_LIST_ERROR,
  REQUEST_SMART_HOST_STATUS,
  RESPONSE_SMART_HOST_STATUS,
  RESPONSE_SMART_HOST_STATUS_ERROR
} = helpers

export const initialState = {}

export const programUnitListInitialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  data: [],
  itemsCount: 0,
  itemsPerPage: 10,
  pageNumber: 1,
  exportDataState: DATA_STATE_NOT_REQUESTED,
  smartHostStatusDataState: DATA_STATE_NOT_REQUESTED
}

const buildState = (state, action) => data => ({
  ...state,
  [action.payload.key]: { ...programUnitListInitialState, ...state[action.payload.key], ...data }
})

const programUnitsListReducer = (state = initialState, action) => {
  const appendData = buildState(state, action)

  switch (action.type) {
    // get list
    case REQUEST_LIST:
      return appendData({ dataState: DATA_STATE_REQUESTING })
    case RESPONSE_LIST:
      return appendData({
        dataState: DATA_STATE_RECIEVED,
        data: action.payload.data.data,
        ...action.payload.data.pagination
      })
    case RESPONSE_ERROR_LIST:
      return appendData({ dataState: DATA_STATE_ERROR })
    // export
    case REQUEST_EXPORT_LIST:
      return appendData({ exportDataState: DATA_STATE_REQUESTING })
    case RESPONSE_EXPORT_LIST:
      return appendData({ exportDataState: DATA_STATE_RECIEVED })
    case RESPONSE_EXPORT_LIST_ERROR:
      return appendData({ exportDataState: DATA_STATE_ERROR })
    // update smartHost status
    case REQUEST_SMART_HOST_STATUS:
      return appendData({ smartHostStatusDataState: DATA_STATE_REQUESTING })
    case RESPONSE_SMART_HOST_STATUS:
      return appendData({ smartHostStatusDataState: DATA_STATE_RECIEVED })
    case RESPONSE_SMART_HOST_STATUS_ERROR:
      return appendData({ smartHostStatusDataState: DATA_STATE_ERROR })
    case RESET_DATA_STATE: {
      const { [action.payload.key]: removedState, ...restState } = state
      return {
        ...restState
      }
    }

    default:
      return state
  }
}

export default filterActions(programUnitsListReducer, [
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_EXPORT_LIST,
  RESPONSE_EXPORT_LIST,
  RESPONSE_EXPORT_LIST_ERROR,
  REQUEST_SMART_HOST_STATUS,
  RESPONSE_SMART_HOST_STATUS,
  RESPONSE_SMART_HOST_STATUS_ERROR
])
