/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useEffect } from 'react'
import classNames from 'classnames'
import { usePrevious } from 'hooks'
import PropTypes from 'prop-types'

import { PERIOD } from 'constants/eligibility'

export function DateFieldInput(props) {
  const {
    mode,
    Component,
    options,
    validation,
    fieldState,
    fieldApi,
    fieldApi: { setValue },
    fieldState: { error },
    errorClassName,
    ...rest
  } = props
  const prevMode = usePrevious(mode)

  useEffect(() => {
    // reset value when change input type from/to period
    if (prevMode && mode && prevMode !== mode && [prevMode, mode].includes(PERIOD)) {
      setValue('')
    }
  }, [mode, prevMode, setValue])

  return (
    <Component
      {...options}
      {...rest}
      fieldState={fieldState}
      fieldApi={fieldApi}
      errorClassName={classNames({ [errorClassName]: Boolean(error) })}
    />
  )
}

DateFieldInput.propTypes = {
  fieldState: PropTypes.object.isRequired,
  fieldApi: PropTypes.object.isRequired,
  Component: PropTypes.element.isRequired,
  options: PropTypes.object.isRequired,
  validation: PropTypes.func.isRequired,
  errorClassName: PropTypes.string,
  mode: PropTypes.string.isRequired
}
DateFieldInput.defaultProps = {
  errorClassName: ''
}
