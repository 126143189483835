/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import axios from 'axios'
import store from 'connectors'
import isUndefined from 'lodash/isUndefined'
import { helpers, actions } from 'reducers/currentUser'
import get from 'lodash/get'
import { getAuthToken, clearAccessToken, getRefreshToken } from 'services/auth'
import { messageCreator, actionCreater, DATA_STATE_REQUESTING } from 'helpers/actionHelpers'

const noAccessErrorStub = { response: { status: 401 } }

function getAuthTokenModule() {
  let refreshTokenPromise = null
  return async function getToken(options) {
    const { refreshTokenDataState } = store.getState().currentUser
    if (refreshTokenDataState === DATA_STATE_REQUESTING) {
      return refreshTokenPromise
    }
    refreshTokenPromise = new Promise(async (resolve, reject) => {
      const secure = get(options, 'secure')
      if ((!isUndefined(secure) && !secure) || getAuthToken()) {
        return resolve()
      }
      const refreshToken = getRefreshToken()
      if (!refreshToken) {
        store.dispatch(actionCreater(helpers.RESPONSE_AUTH_ERROR))
        return reject(noAccessErrorStub)
      }
      try {
        await actions.refreshToken(refreshToken)(store.dispatch)
        return resolve()
      } catch (err) {
        return reject(err)
      }
    })

    return refreshTokenPromise
  }
}

const checkToken = getAuthTokenModule()

function commonAuthHeaders() {
  return { Authorization: `Bearer ${getAuthToken()}` }
}

export const getBasicAuthHeader = () => ({
  Authorization: 'Basic Zm9vOmZvb3NlY3JldA=='
})

function buildCommonHeaders(options = {}) {
  const isAuthRequest = get(options, 'isAuthRequest')
  return isAuthRequest ? getBasicAuthHeader() : commonAuthHeaders()
}

function httpRequest(options = {}, config = {}) {
  const { url, headers = {}, ...restOptions } = options
  return axios(url, {
    headers: {
      ...headers,
      ...buildCommonHeaders(config)
    },
    ...restOptions
  })
}

export async function httpWrapper(requestOptions, moduleOptions = {}) {
  const isSecondTry = get(moduleOptions, 'isSecondTry', false)
  const isAuthRequest = get(moduleOptions, 'isAuthRequest')
  try {
    await checkToken(moduleOptions)
    const requestData = await httpRequest(requestOptions, moduleOptions)
    return Promise.resolve({
      data: requestData.data,
      message: messageCreator({ response: { response: requestData } }),
      requestData
    })
  } catch (err) {
    const statusCode = get(err, 'response.status')
    if (statusCode === 401) {
      if (!isSecondTry && !isAuthRequest) {
        clearAccessToken()
        return httpWrapper(requestOptions, { ...moduleOptions, isSecondTry: true })
      }
      store.dispatch(actionCreater(helpers.RESPONSE_AUTH_ERROR))
    }
    const rejectError = { ...messageCreator({ response: err }), error: err, statusCode }
    return Promise.reject(rejectError)
  }
}
