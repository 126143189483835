/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'

export function OrderIcon(props) {
  return (
    <svg viewBox="0 0 144 144" {...props}>
      <g>
        <path
          d="M75.1,17.7c20.3,2.7,34.7,21.4,32,41.8c-2.7,20.3-21.4,34.7-41.8,32c-20.3-2.7-34.7-21.4-32-41.8
          c1.7-12.7,9.6-23.5,21.2-28.9C60.9,17.9,68,16.8,75.1,17.7z M66.1,85.3c16.9,2.2,32.5-9.7,34.7-26.6S91.1,26.2,74.2,24
          c-5.9-0.8-11.8,0.1-17.1,2.6c-9.6,4.5-16.2,13.5-17.6,24C37.3,67.5,49.2,83,66.1,85.3z"
        />
        <path
          d="M113.4,132.2c-4-0.8-7-4.5-7.4-4.9l-0.2-0.2L79.8,87.2l14.2-7.8l25.8,37.5c3,3.8,4.3,9.8-0.2,13.5
          c-1.6,1.4-3.6,2-5.6,1.7C113.8,132.2,113.6,132.2,113.4,132.2z M111.1,123.5c0.8,1,2.5,2.4,3.7,2.5c0.3,0,0.6,0,0.9-0.3
          c1.8-1.5-0.8-4.9-0.9-5l-22.7-33l-3.3,1.8L111.1,123.5z"
        />
        <path d="M57.3,64.9" />
        <path d="M59.9,65.5" />
        <path d="M57.3,64.9l8.1-18.4c0.1-0.3,0.5-0.4,0.9-0.2l1.1,0.5c0.3,0.2,0.5,0.5,0.4,0.8l-7.9,18" />
        <circle cx="67.3" cy="43.1" r="5.5" />
        <path
          d="M55.4,65.1C52,66,49.9,69.6,50.9,73c0.9,3.4,4.4,5.5,7.9,4.5c3.4-0.9,5.5-4.4,4.5-7.9
          C62.3,66.2,58.8,64.2,55.4,65.1"
        />
        <path
          d="M95.9,50.5l9.2-20.9c0.3-0.7,0-1.4-0.7-1.7v0c-0.7-0.3-1.4,0-1.7,0.7l-9.2,20.9c-0.3,0.7,0,1.4,0.7,1.7l0,0
          C94.8,51.5,95.6,51.2,95.9,50.5z"
        />
        <circle cx="106.2" cy="23.9" r="5.8" />
        <circle cx="93.1" cy="56.4" r="6.4" />
        <path
          d="M88.5,51l-15.8-6.9c-0.7-0.3-1.4,0-1.7,0.7v0c-0.3,0.7,0,1.4,0.7,1.7l15.8,6.9c0.7,0.3,1.4,0,1.7-0.7v0
          C89.5,52.1,89.2,51.3,88.5,51z"
        />
      </g>
    </svg>
  )
}
