/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import styles from './styles'

export const PROGRAM_DETAILS_LABEL = 'Program Details'
export const PROGRAM_DETAILS_CLASS_NAME = styles['--program']

export const ELIGIBILITY_DETAILS_LABEL = 'Eligibility Parameters'
export const ELIGIBILITY_DETAILS_CLASS_NAME = styles['--eligibility']

export const MARKETING_DETAILS_LABEL = 'Marketing Details'
export const MARKETING_DETAILS_CLASS_NAME = styles['--marketing']

export const SEASONS_UPDATED_LABEL = 'Seasons Details'
export const SEASONS_UPDATED_CLASS_NAME = styles['--seasons']

export const SEASONS_DETAILS_LABEL = 'Seasons Details'
export const SEASON_DETAILS_LABEL = 'Season'
export const SEASONS_DETAILS_CLASS_NAME = styles['--seasons']

export const EXCLUSIVE_ACTIVITY_LABEL = 'Exclusive Offer Details'
export const EXCLUSIVE_ACTIVITY_CLASS_NAME = styles['--exclusive']

export const DELAYED_DEACTIVATION_SCHEDULED_LABEL = 'Delayed Deactivation has been scheduled'
export const DELAYED_DEACTIVATION_UPDATED_LABEL = 'Scheduled Deactivation has been updated'
export const DELAYED_DEACTIVATION_CANCELED_LABEL = 'Scheduled Deactivation has been canceled'
export const DELAYED_DEACTIVATION_CLASS_NAME = styles['--delayed']

export const PROGRAM_ACTIVATED_STATUS_LABEL = 'Program was Activated'
export const PROGRAM_DEACTIVATED_STATUS_LABEL = 'Program was Deactivated'
export const PROGRAM_APPROVED_STATUS_LABEL = 'Draft was Approved'
export const PROGRAM_STATUS_CLASS_NAME = styles['--status']

export const PROGRAM_DEACTIVATED_LABEL = 'Program Deactivated'
export const SEASON_DETAILS_UPDATED_LABEL = 'Season Details'

export const PMS_CHANGE_LABEL = 'PMS'
export const PMS_CHANGE_CLASS_NAME = styles['--pms']

export const UNIT_ASSIGMENT_LABEL = 'Unit Program'
export const UNIT_ASSIGMENT_CLASS_NAME = styles['--unit']

export const UNIT_UPDATE_LABEL = 'Unit Details'
export const UNIT_UPDATE_CLASS_NAME = styles['--unit']

export const ACTION_ADDED_LABEL = 'added'
export const ACTION_DELETED_LABEL = 'deleted'
export const ACTION_UPDATED_LABEL = 'updated'

export const NAME_SEASON_LABEL = 'Name:'
export const REASON_LABEL = 'Reason:'
export const PERFORMED_BY_LABEL = 'Performed by:'
export const DATE_LABEL = 'Date:'
