/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { getPreviousPrograms, getProgramByIdExtended } from 'services/http/program'
import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  REQUEST_GET_PROGRAM,
  RESPONSE_GET_PROGRAM,
  RESPONSE_ERROR_GET_PROGRAM
} = helpers

const getPreviousProgramsList = (id, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await getPreviousPrograms(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, request.data))
    return 'getPreviousProgramsList success'
  }

  return 'getPreviousProgramsList failed'
}

const getCurrentProgramById = (id, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_PROGRAM))
  const request = await getProgramByIdExtended(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_GET_PROGRAM))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_PROGRAM, request.data))
    return 'getCurrentProgramById success'
  }

  return 'getCurrentProgramById failed'
}

export default {
  getPreviousProgramsList,
  getCurrentProgramById
}
