/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const LINK_REGISTRATION_TYPE = 'LINK'
export const CALL_2_BOOK_REGISTRATION_TYPE = 'CALL_TO_BOOK'

export const STRIPE_REQUIRED_VALUE = true
export const STRIPE_NOT_REQUIRED_VALUE = false
