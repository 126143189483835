/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { getListWatcherStates } from 'reducers/deals/dealCommon'
import { formActions } from 'reducers/ticketSettingsForm'
import { actions, getActivityDealsListState } from 'reducers/deals/activityDealsList'
import { withAuthContext } from 'helpers/withAuthContext'

import { ActivityDiscountsTab as ActivityDiscountsTabComponent } from './ActivityDiscountsTab'

export const mapStateToProps = state => {
  const listState = getActivityDealsListState(state)
  return {
    activity: state.activityProductForm.entityData,
    ticketSettingsState: state.ticketSettingsForm,
    listProps: {
      // TODO: Need adjust this functionality.
      dataStateWatchers: getListWatcherStates(state),
      ...listState
    }
  }
}

export const mapDispatchToProps = (dispatch, props) => {
  const { getDealsListByActivityId } = actions
  const { dataId } = props
  return bindActionCreators(
    {
      onGetData: getDealsListByActivityId.bind(null, dataId),
      getActivitySettingsById: formActions.getEntityDataById
    },
    dispatch
  )
}

export const ActivityDiscountsTab = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ActivityDiscountsTabComponent)
