/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function PeopleIcon(props) {
  return (
    <svg viewBox="0 0 144 144" {...props}>
      <circle cx="33.4" cy="47.1" r="14.6" />
      <path d="M59.4,86.4c-4.9,9.4-14.7,13.7-26,13.7c-11.3,0-21.2-4.3-26-13.7c1.5-13.1,12.6-23.2,26-23.2	C46.9,63.1,58,73.3,59.4,86.4z" />
      <circle cx="109.9" cy="47.1" r="14.6" />
      <path d="M135.9,86.4c-4.9,9.4-14.7,13.7-26,13.7c-11.3,0-21.2-4.3-26-13.7c1.5-13.1,12.6-23.2,26-23.2	C123.3,63.1,134.4,73.3,135.9,86.4z" />
      <circle cx="71.6" cy="60.1" r="14.6" />
      <path d="M97.6,99.4c-4.9,9.4-14.7,13.7-26,13.7c-11.3,0-21.2-4.3-26-13.7c1.5-13.1,12.6-23.2,26-23.2	C85.1,76.1,96.2,86.3,97.6,99.4z" />
    </svg>
  )
}
