/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const CHANGE_PASS_ACTION = 'CHANGE_PASS_ACTION'
export const RESET_PASSWORD = 'RESET_PASSWORD'

export default { CHANGE_PASS_ACTION, RESET_PASSWORD }
