/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import { LIST_NAME, ACTIVITY_CATEGORIES_TREE_STATE, REMOVE_ACTIVITY_CATEGORY } from './types'

const REQUEST_LIST = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_LIST_ERROR = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

const UPDATE_TREE_STATE = `UPDATE_${ACTIVITY_CATEGORIES_TREE_STATE}`

const REQUEST_REMOVE_ACTIVITY_CATEGORY = `${REQUEST_TYPE}_${REMOVE_ACTIVITY_CATEGORY}`
const RESPONSE_REMOVE_ACTIVITY_CATEGORY = `${RESPONSE_TYPE}_${REMOVE_ACTIVITY_CATEGORY}`
const RESPONSE_REMOVE_ACTIVITY_CATEGORY_ERROR = `${RESPONSE_ERROR_TYPE}_${REMOVE_ACTIVITY_CATEGORY}`

const buildIdForGlobalTree = (id = '', parentId = null) => `${parentId ? `${parentId}-` : ''}${id}`

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_LIST_ERROR,
  RESET_DATA_STATE,
  UPDATE_TREE_STATE,
  REQUEST_REMOVE_ACTIVITY_CATEGORY,
  RESPONSE_REMOVE_ACTIVITY_CATEGORY,
  RESPONSE_REMOVE_ACTIVITY_CATEGORY_ERROR,
  buildIdForGlobalTree
}
