import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import {
  REQUEST_RECOVERY_PASSWORD_LINK_ACTION,
  RESPONSE_RECOVERY_PASSWORD_LINK_ACTION,
  RESPONSE_RECOVERY_PASSWORD_LINK_ACTION_ERROR,
  REQUEST_RECOVERY_TOKEN_ACTION,
  RESPONSE_RECOVERY_TOKEN_ACTION,
  RESPONSE_RECOVERY_TOKEN_ACTION_ERROR,
  REQUEST_RECOVERY_PASSWORD_ACTION,
  RESPONSE_RECOVERY_PASSWORD_ACTION,
  RESPONSE_RECOVERY_PASSWORD_ACTION_ERROR,
  RESET_DATA_STATE
} from './helpers'

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  recoveryTokenDataState: DATA_STATE_NOT_REQUESTED,
  recoveryPasswordDataState: DATA_STATE_NOT_REQUESTED,
  isEmailError: false,
  tokenError: null
}

const accountPasswordRecoveryReducer = (state = initialState, action) => {
  switch (action.type) {
    // Reducers for send recovery email
    case REQUEST_RECOVERY_PASSWORD_LINK_ACTION:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }

    case RESPONSE_RECOVERY_PASSWORD_LINK_ACTION_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }

    case RESPONSE_RECOVERY_PASSWORD_LINK_ACTION:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        isEmailError: !!action.payload
      }

    // Reducers for check recovery token
    case REQUEST_RECOVERY_TOKEN_ACTION:
      return {
        ...state,
        recoveryTokenDataState: DATA_STATE_REQUESTING
      }

    case RESPONSE_RECOVERY_TOKEN_ACTION_ERROR:
      return {
        ...state,
        recoveryTokenDataState: DATA_STATE_ERROR
      }

    case RESPONSE_RECOVERY_TOKEN_ACTION:
      return {
        ...state,
        recoveryTokenDataState: DATA_STATE_RECIEVED,
        tokenError: action.payload,
      }

    // Reducers for recover password
    case REQUEST_RECOVERY_PASSWORD_ACTION:
      return {
        ...state,
        recoveryTokenDataState: DATA_STATE_RECIEVED,
        recoveryPasswordDataState: DATA_STATE_REQUESTING
      }

    case RESPONSE_RECOVERY_PASSWORD_ACTION_ERROR:
      return {
        ...state,
        recoveryTokenDataState: DATA_STATE_RECIEVED,
        recoveryPasswordDataState: DATA_STATE_ERROR
      }

    case RESPONSE_RECOVERY_PASSWORD_ACTION:
      return {
        ...state,
        recoveryTokenDataState: DATA_STATE_RECIEVED,
        recoveryPasswordDataState: DATA_STATE_RECIEVED
      }

    case RESET_DATA_STATE:
      return { ...initialState }

    default:
      return state
  }
}

export default filterActions(accountPasswordRecoveryReducer, [
  REQUEST_RECOVERY_PASSWORD_LINK_ACTION,
  RESPONSE_RECOVERY_PASSWORD_LINK_ACTION,
  RESPONSE_RECOVERY_PASSWORD_LINK_ACTION_ERROR,
  REQUEST_RECOVERY_TOKEN_ACTION,
  RESPONSE_RECOVERY_TOKEN_ACTION,
  RESPONSE_RECOVERY_TOKEN_ACTION_ERROR,
  REQUEST_RECOVERY_PASSWORD_ACTION,
  RESPONSE_RECOVERY_PASSWORD_ACTION,
  RESPONSE_RECOVERY_PASSWORD_ACTION_ERROR,
  RESET_DATA_STATE
])
