/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import get from 'lodash/get'
import moment from 'moment'
import { FORMAT } from 'constants/date'
import {
  INVALID_RATE_FEE,
  MAX_RATE_FEE,
  MIN_RATE_FEE,
  REGEX_DECIMALS_VALIDATION_RATE_FEE
} from '../types'

/**
 * Validate value between min and max values
 * @param value
 * @returns {*}
 */
export const borderValidationRateFee = value =>
  !value || (value >= MIN_RATE_FEE && value <= MAX_RATE_FEE) ? null : INVALID_RATE_FEE

/**
 * Test the value for decimals
 * @param value
 * @returns {*}
 */
export const decimalsValidationRateFee = value =>
  !value || REGEX_DECIMALS_VALIDATION_RATE_FEE.test(value) ? null : INVALID_RATE_FEE

/**
 * Convert string to Date and add days to converted date
 * @param {string|Date} value
 * @param {number} days
 * @returns {null|Date}
 */
export const addDaysToDate = (value, days = 1) => {
  if (!value) {
    return null
  }

  return moment(value, FORMAT)
    .add(days, 'days')
    .toDate()
}

/**
 * Create match for dayPicker by key
 * @param {string|Date} value
 * @param {string} key
 * @returns {{}}
 */
export const createMatch = (value, key) => {
  if (!value) {
    return {}
  }

  return {
    [key]: moment(value, FORMAT).toDate()
  }
}

/**
 * Create match for dayPicker by the before key
 * @param {string|Date} value
 * @returns {*|{}}
 */
export const createMatchBefore = value => createMatch(value, 'before')

/**
 * Create match for dayPicker by the after key
 * @param {string|Date} value
 * @returns {*|{}}
 */
export const createMatchAfter = value => createMatch(value, 'after')

/**
 * Create object with disabledDays for Calendar component
 * @param additionalDate
 * @param otherRates
 * @returns {{disabledDays: *[]}}
 */
export const createDisabledDays = (additionalDate, otherRates) => {
  const disabledDays = [...otherRates]

  if (additionalDate) {
    disabledDays.push(additionalDate)
  }

  return {
    disabledDays
  }
}

/**
 * Convert all string dates to Date
 * @param rates
 * @returns {*}
 */
export const convertDates = rates =>
  rates.map(item => ({
    ...item,
    startDate: moment(item.startDate, FORMAT).toDate(),
    endDate: moment(item.endDate, FORMAT).toDate()
  }))

/**
 * Sort rates by DESC use endDate time
 * @param a
 * @param b
 * @returns {number}
 */
const comparatorByEndDate = (a, b) => b.endDate.getTime() - a.endDate.getTime()

/**
 * Sort rates by ASC use startDate time
 * @param a
 * @param b
 * @returns {number}
 */
const comparatorByStartDate = (a, b) => a.startDate.getTime() - b.startDate.getTime()

/**
 * Get ranges which available to select
 * @param rates
 * @param item
 * @returns {{}[]|*}
 */
export const getDisabledRanges = (rates, item) => {
  // Display ranges when rate don't have startDate
  if (!get(item, 'startDate')) {
    return rates.map(rate => ({
      ...createMatch(rate.startDate, 'from'),
      ...createMatch(rate.endDate || rate.startDate, 'to')
    }))
  }

  // Sort all rates, for correctly range detecting at the next steps
  const sortedRates = rates.sort(comparatorByStartDate)
  const currentIndex = rates.findIndex(rate => rate.id === item.id)

  // Detect ranges before and after currentItem
  const beforeRates = sortedRates.slice(0, currentIndex)
  const afterRates = sortedRates.slice(currentIndex + 1, rates.length)

  // Sorted prev ranges to find highest and lowest date
  const beforeSorted = beforeRates.sort(comparatorByEndDate)
  const afterSorted = afterRates.sort(comparatorByStartDate)

  const beforeDate = get(beforeSorted, '0.endDate')
  const afterDate = get(afterSorted, '0.startDate')

  return [
    {
      ...createMatchBefore(addDaysToDate(beforeDate)),
      ...createMatchAfter(addDaysToDate(afterDate, -1))
    }
  ]
}
