/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { YYY_MM_DD_AT_TIME } from 'constants/date'
import get from 'lodash/get'
import { DataHouseIcon, PIEIcon } from 'icons'
import {
  UPLOAD_STATUS_ACCESSOR,
  FILENAME_TITLE,
  FILENAME_ACCESSOR,
  UPLOAD_AT_ACCESSOR,
  UPLOAD_AT_TITLE,
  UPLOAD_BY_ACCESSOR,
  UPLOAD_BY_TITLE,
  VIEW_FILE_TITLE
} from './types'
import { isSuccessUploaded } from './helpers'
import { StatusCell } from './Cells/StatusCell'
import { DownloadCell } from './Cells/DownloadCell'
import { DWHStatusCell } from './Cells/DWHStatusCell'

import styles from './GuestsList.scss'

export const buildRows = () => ({
  rowProps: value => ({
    className: classNames({
      [styles.errorRow]: !isSuccessUploaded(value[UPLOAD_STATUS_ACCESSOR])
    })
  })
})

export const buildColumns = ({ onDelete }) => [
  {
    id: 'filename',
    Header: FILENAME_TITLE,
    accessor: FILENAME_ACCESSOR
  },
  {
    id: 'upload_at',
    Header: UPLOAD_AT_TITLE,
    accessor: original => {
      const date = get(original, UPLOAD_AT_ACCESSOR)
      const momentDate = moment(date)
      return momentDate.isValid() ? momentDate.format(YYY_MM_DD_AT_TIME) : null
    }
  },
  { id: 'upload_by_title', Header: UPLOAD_BY_TITLE, accessor: UPLOAD_BY_ACCESSOR },
  {
    id: 'upload_status',
    Header: <PIEIcon className={styles.pieIcon} />,
    headerCellClassName: styles.iconCell,
    accessor: UPLOAD_STATUS_ACCESSOR,
    cellClassName: styles.iconCell,
    Cell: ({ value }) => <StatusCell status={value} />
  },
  {
    id: 'upload_dwh_status',
    Header: <DataHouseIcon />,
    headerCellClassName: styles.iconCell,
    cellClassName: styles.iconCell,
    Cell: ({ original }) => (
      <DWHStatusCell
        status={original.integrationStatus}
        createDate={get(original, UPLOAD_AT_ACCESSOR)}
      />
    )
  },
  {
    id: 'view_file',
    Header: VIEW_FILE_TITLE,
    cellClassName: styles.downloadCell,
    Cell: ({ original }) => (
      <DownloadCell
        status={original[UPLOAD_STATUS_ACCESSOR]}
        fileId={original.id}
        onClick={onDelete}
      />
    )
  }
]
