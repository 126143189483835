/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withFormContext } from 'components/FormComponents/helpers'
import { withValidation, blankValidate } from 'helpers/formHelpers'

import styles from './FileField.scss'
import { FileInput } from './FileInput'

export const FileFieldComponent = props => {
  const {
    disabled,
    placeholder,
    fieldName,
    validate,
    isRequired,
    className,
    preValidate,
    children,
    ...rest
  } = props
  const validateStrategy = isRequired ? withValidation(blankValidate, validate) : validate

  return (
    <>
      <FileInput
        isRequired={isRequired}
        className={className}
        field={fieldName}
        placeholder={placeholder}
        disabled={disabled}
        validate={validateStrategy}
        preValidate={preValidate}
        type="file"
        validateOnChange
        {...rest}
      />
      {children}
    </>
  )
}

FileFieldComponent.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.func,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  preValidate: PropTypes.func,
  children: PropTypes.node
}

FileFieldComponent.defaultProps = {
  placeholder: '',
  disabled: false,
  validate: () => null,
  isRequired: false,
  className: styles.input,
  label: '',
  preValidate: (value = '') => value,
  children: null
}

export const FileField = withFormContext(FileFieldComponent)
