/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

const LIST_NAME = 'ACTIVITY_TAGS_LIST'
const GET_ENTITY_BY_ID = `${LIST_NAME}_GET_ENTITY_BY_ID`
const GET_PROVIDER_ENTITY_BY_ID = `${LIST_NAME}_GET_PROVIDER_ENTITY_BY_ID`
const DEFAULT_PAGE_NUMBER = 1
const DEFAULT_ITEMS_PER_PAGE = 25

export default {
  LIST_NAME,
  GET_ENTITY_BY_ID,
  GET_PROVIDER_ENTITY_BY_ID,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ITEMS_PER_PAGE
}
