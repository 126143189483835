/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { BasicModal } from 'components/Modals'
import { ControlButtonsContainer } from 'components/FormComponents'
import { SubmitButton } from 'components/Buttons'
import { BasicButton } from '@xplorie/ui-commons'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { useDidMount, useAction, useLoading, useUpdateSomeDataState } from 'hooks'
import { useSelector } from 'react-redux'
import { actions, selectors } from 'reducers/closures/closuresForm'
import {
  ORANGE_SCHEMA,
  YELLOW_GREEN_SCHEMA,
  SUBMIT_TITLE,
  CANCEL_TITLE
} from 'constants/buttonsOptions'
import { useRootModal } from 'hooks/useRootModal'
import { ModalStrategy } from './helpers'
import { ADD_MODAL_TITLE, EDIT_MODAL_TITLE } from './types'
import { ClosureForm } from '../ClosureForm'
import styles from './styles.scss'
import { useTimePeriodApi } from './useTimePeriodApi'

export function ClosureModal({ id, activityId, day, isOpen, onClose, onChange, initialValues }) {
  const formApi = useRef()
  const rootModal = useRootModal()
  const createClosure = useAction(actions.createClosure)
  const updateClosureSeries = useAction(actions.updateClosureSeries)
  const getClosureById = useAction(actions.getClosureById)
  const resetState = useAction(actions.resetState)
  const updateClosureOccurrence = useAction(actions.updateClosureOccurrence)
  const getOccurrenceByIdAndDay = useAction(actions.getOccurrenceByIdAndDay)
  const getIntersections = useAction(actions.getClosureIntersections)

  const { formDataState, entityDataState } = useSelector(state =>
    selectors.getClosureStateById(state, activityId)
  )

  const [isFormRequesting] = useLoading(formDataState)
  const [isDataLoading] = useLoading(entityDataState)

  const isFormDisabled = isFormRequesting || isDataLoading

  const strategyManager = ModalStrategy({
    id,
    activityId,
    createClosure,
    updateClosureSeries,
    getClosureById,
    updateClosureOccurrence,
    getOccurrenceByIdAndDay,
    day,
    getIntersections,
    rootModal
  })

  const { mapToFormValues, onSubmit, getData, mapToInitialValues } = strategyManager.getStrategy()
  const isEdit = Boolean(getData)

  const initialFormValues = mapToInitialValues(initialValues)
  const {
    addTimePeriod,
    editTimePeriod,
    canSeeRemoveTimePeriod,
    removeTimePeriod,
    getTimePeriod,
    changeAllDay
  } = useTimePeriodApi(formApi)

  const handleGetApi = api => {
    formApi.current = api
  }

  useDidMount(() => {
    if (isEdit) {
      getData().then(data => {
        const formValues = mapToFormValues(data)
        formApi.current.setValues(formValues)
      })
    }

    return () => {
      resetState(activityId)
    }
  })

  useUpdateSomeDataState(
    () => {
      if (onChange) {
        const formState = formApi.current.getState()
        onChange(formState)
      }
      if (onClose) {
        onClose()
      }
    },
    [formDataState],
    DATA_STATE_RECIEVED
  )

  return (
    <BasicModal
      title={id ? EDIT_MODAL_TITLE : ADD_MODAL_TITLE}
      isOpen={isOpen}
      onClose={onClose}
      renderBody={() => (
        <div className={styles.bodyWrapper}>
          <ClosureForm
            getApi={handleGetApi}
            onSubmit={onSubmit}
            isEdit={isEdit}
            closureApi={{
              changeAllDay,
              getTimePeriod,
              addTimePeriod,
              editTimePeriod,
              removeTimePeriod,
              canSeeRemoveTimePeriod
            }}
            disabled={isFormDisabled}
            initialValues={initialFormValues}
          >
            {formProps => (
              <ControlButtonsContainer className={styles.buttons}>
                <SubmitButton {...formProps} colorSchema={ORANGE_SCHEMA}>
                  {SUBMIT_TITLE}
                </SubmitButton>
                <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
                  {CANCEL_TITLE}
                </BasicButton>
              </ControlButtonsContainer>
            )}
          </ClosureForm>
        </div>
      )}
    />
  )
}

ClosureModal.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  activityId: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  initialValues: PropTypes.object,
  day: PropTypes.string
}
ClosureModal.defaultProps = {
  id: null,
  onChange: null,
  initialValues: null,
  day: null
}
