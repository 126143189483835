/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildListParams } from 'services/http/helpers'
import { STATES_LOCATION_TREE_URL } from 'endpoints'

export const getCountriesTree = (authKey, options) =>
  httpWrapper({
    url: STATES_LOCATION_TREE_URL,
    method: 'get',
    params: buildListParams(options)
  })
