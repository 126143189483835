/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useContext } from 'react'
import { SmartHostFeeFormContext } from '../types'
import { SmartHostFeeFormBox } from '../SmartHostFeeFormBox'

function SmartHostFeeEditMode() {
  const { fees } = useContext(SmartHostFeeFormContext)

  // eslint-disable-next-line react/no-array-index-key
  return fees.map((item, index) => <SmartHostFeeFormBox key={index} index={index} item={item} />)
}

export default SmartHostFeeEditMode
