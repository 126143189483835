/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { formActions } from 'reducers/unitsSlider'
import { UnitsAmenitiesTab as UnitsAmenitiesTabComponent } from './UnitsAmenitiesTab'

const mapStateToProps = state => ({
  amenitiesDataState: state.unitsSlider.amenitiesDataState,
  amenitiesData: state.unitsSlider.amenitiesData,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...formActions },
    dispatch
  )

export const UnitsAmenitiesTab = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitsAmenitiesTabComponent)
