/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import { BoxColumns } from 'components/FormComponents'
import { ToggleVisibilityArea } from 'components/ToggleVisibility'
import { PreviousProgramsButton } from 'modules/Programs/PreviousProgramsButton'

import { AdditionalDataField } from './AdditionalDataField'
import { VERSION_LABEL, PROGRAM_CODE_LABEL, FORM_TITLE } from './types'

import styles from './AdditionalField.scss'

export function ProgramAdditionalData(props) {
  const { formData } = props
  const version = get(formData, 'versionData.version')
  const programCode = get(formData, 'programCode')
  return (
    formData && (
      <ToggleVisibilityArea titleArea={FORM_TITLE} classNameWrapper={styles.bottomIndent}>
        <BoxColumns>
          <AdditionalDataField label={VERSION_LABEL} value={version} />
          <AdditionalDataField label={PROGRAM_CODE_LABEL} value={programCode} />
        </BoxColumns>
        <PreviousProgramsButton {...props} />
      </ToggleVisibilityArea>
    )
  )
}

ProgramAdditionalData.propTypes = {
  formData: PropTypes.shape({
    versionData: PropTypes.shape({
      version: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    programCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })
}

ProgramAdditionalData.defaultProps = {
  formData: {
    versionData: null,
    programCode: null
  }
}
