/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

export const SUBTITLE_TEXT = 'Change password'
export const TYPE_INPUT_PASSWORD = 'password'

export const TYPE_OLD_PASSWORD = 'oldPassword'
export const TYPE_NEW_PASSWORD = 'newPassword'
export const TYPE_CONFIRM_NEW_PASSWORD = 'confirmNewPassword'

export const LABEL_OLD_PASSWORD = 'Old password'
export const LABEL_NEW_PASSWORD = 'New password'
export const LABEL_CONFIRM_PASSWORD = 'Confirm new password'

export const PLACEHOLDER_OLD_PASSWORD = 'Old password'
export const PLACEHOLDER_NEW_PASSWORD = 'New password'
export const PLACEHOLDER_CONFIRM_PASSWORD = 'Confirm new password'

export const ERROR_MESSAGE_NEW_PASSWORD_AND_CONFIRM_NEW_PASSWORD =
  'Error! The new password and confirm new password not match!'
export const ERROR_MESSAGE_OLD_PASSWORD_AND_NEW_PASSWORD =
  'Error! The old password and new password match!'
