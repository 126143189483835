/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'

import styles from './OverlayComponent.scss'
import { ENABLE_HOLIDAY_LABEL } from '../types'

export function OverlayComponent({ classNames, children, selectedDay, ...props }) {
  const {
    overlayProps: { enableHoliday = true, enableHolidayChanged }
  } = children.props

  const onHolidayEnableChange = () => {
    if (enableHolidayChanged) {
      enableHolidayChanged(!enableHoliday)
    }
  }

  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay}>
        {children}
        {enableHolidayChanged && (
          <div className={styles.holidayCheckboxWrapper}>
            <input
              type="checkbox"
              id="holiday"
              checked={enableHoliday}
              onChange={onHolidayEnableChange}
            />
            <span className={styles.checkBoxLabel}>{ENABLE_HOLIDAY_LABEL}</span>
          </div>
        )}
      </div>
    </div>
  )
}

OverlayComponent.propTypes = {
  classNames: PropTypes.string,
  children: PropTypes.any,
  selectedDay: PropTypes.object
}

OverlayComponent.defaultProps = {
  children: null,
  classNames: null,
  selectedDay: null
}
