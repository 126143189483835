/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import isObject from 'lodash/isObject'
import classNames from 'classnames'
import find from 'lodash/find'
import { asField } from 'informed'

import get from 'lodash/get'

export function Select(props) {
  const {
    isComplex,
    fieldApi,
    options,
    path,
    onChange,
    fieldState,
    onBlur,
    forwardedRef,
    field,
    children,
    onSelectHandler,
    preventAutoSave,
    className,
    errorClassName,
    error,
    ...rest
  } = props
  const { value: fieldValue } = fieldState
  const { setTouched, setValue } = fieldApi
  const handleChange = e => {
    const selectedValue = e.target.value
    const newShownValue = isComplex
      ? find(options, ({ value }) => get(value, path) === selectedValue) || {
          value: ''
        }
      : selectedValue

    if (!preventAutoSave) {
      if (isObject(newShownValue) && newShownValue.value) {
        setValue(newShownValue.value)
      } else {
        setValue(newShownValue)
      }
    }

    if (onChange) {
      onChange(e)
    }

    if (onSelectHandler) {
      onSelectHandler(newShownValue)
    }

    setTouched(true)
  }

  const onBlurHandler = e => {
    if (onBlur) {
      onBlur(e)
    }
  }

  const shownValue = isComplex ? get(fieldValue, path) : fieldValue

  return (
    <select
      {...rest}
      className={classNames(className, { [errorClassName]: Boolean(error) })}
      name={field}
      value={shownValue ?? ''}
      onChange={handleChange}
      onBlur={onBlurHandler}
    >
      {children}
    </select>
  )
}

Select.propTypes = {
  isComplex: PropTypes.number,
  fieldApi: PropTypes.object.isRequired,
  options: PropTypes.array,
  path: PropTypes.array,
  onChange: PropTypes.func,
  fieldState: PropTypes.object,
  onBlur: PropTypes.func,
  field: PropTypes.string,
  forwardedRef: PropTypes.object,
  children: PropTypes.any,
  onSelectHandler: PropTypes.func,
  preventAutoSave: PropTypes.bool,
  errorClassName: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string
}

Select.defaultProps = {
  isComplex: null,
  options: [],
  path: null,
  onChange: () => {},
  onBlur: () => {},
  onSelectHandler: () => {},
  fieldState: {},
  field: '',
  children: null,
  forwardedRef: null,
  preventAutoSave: false,
  errorClassName: '',
  className: '',
  error: null
}

export default asField(Select)
