/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { NOTIF_SEND, NOTIF_DISMISS, NOTIF_CLEAR } from './actions'

export default function notifs(domain = [], action) {
  if (!action || !action.type) {
    return domain
  }

  switch (action.type) {
    case NOTIF_SEND:
      return [action.payload, ...domain.filter(({ id }) => id !== action.payload.id)]
    case NOTIF_DISMISS:
      return domain.filter(notif => notif.id !== action.payload)
    case NOTIF_CLEAR:
      return []
    default:
      return domain
  }
}
