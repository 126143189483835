/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { createPMC, updatePMC, getPMCById } from 'services/http/pmc'

import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_PMC_ITEM,
  RESPONSE_PMC_ITEM,
  RESPONSE_PMC_ITEM_ERROR
} = helpers
const putPmcsForm = (contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  try {
    const request = await updatePMC(contractData)
    if (request) {
      const {
        message: { message, send }
      } = request

      dispatch(
        actionCreater(RESPONSE_FORM, {
          responseData: request.data
        })
      )
      dispatch(send(message))
      return 'putPmcsForm success'
    }
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  }
  return 'putPmcsForm failed'
}

const postPmcsForm = (contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await createPMC(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(
      actionCreater(RESPONSE_FORM, {
        responseData: request.data
      })
    )
    dispatch(send(message))
    return 'postPmcsForm success'
  }

  return 'postPmcsForm failed'
}

const getPMCItemById = (id, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_PMC_ITEM))
  const request = await getPMCById(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_PMC_ITEM_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_PMC_ITEM, request.data))
    return 'getPMCItemById success'
  }

  return 'getPMCItemById failed'
}

const resetForm = () => ({
  type: RESET_DATA_STATE
})

export default {
  postPmcsForm,
  resetForm,
  putPmcsForm,
  getPMCItemById
}
