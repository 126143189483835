/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getComponentByType } from './helpers'

export function ContentWrapper(props) {
  const { currentType } = props
  const Component = useMemo(() => getComponentByType(currentType), [currentType])

  return <Component {...props} />
}

ContentWrapper.propTypes = {
  currentType: PropTypes.string.isRequired
}
