/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const DEFAULT_PAGE_SIZE_OPTIONS = [25, 50, 100]
export const DEFAULT_PAGE_SIZE = 25
export const DEFAULT_ENTITY_TYPE = 'DEFAULT_ENTITY_TYPE'
export const DEFAULT_TABLE_STYLE = {
  height: '620px'
}
