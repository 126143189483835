/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { isActionAllowed } from 'helpers/permissionHelper'
import { CREATE_LEVEL } from 'constants/permissionTypes'
import { createFilterBuilder } from 'modules/FilteredSearch/helpers'
import {
  ACTIVE_FILTER,
  ACTIVE_FILTER_KEY,
  IGNORE_ACTIVE_FILTER,
  IGNORE_ARCHIVED_FILTER,
  IGNORE_DRAFT_FILTER,
  IGNORE_INACTIVE_FILTER,
  IGNORE_PENDING_FILTER,
  INACTIVE_FILTER,
  INACTIVE_FILTER_KEY
} from './types'

export const buildFilters = createFilterBuilder(
  {
    [ACTIVE_FILTER_KEY]: ACTIVE_FILTER,
    [INACTIVE_FILTER_KEY]: INACTIVE_FILTER
  },
  columnFilters =>
    columnFilters.length
      ? [...columnFilters, IGNORE_DRAFT_FILTER, IGNORE_ARCHIVED_FILTER]
      : [
          ...columnFilters,
          IGNORE_ACTIVE_FILTER,
          IGNORE_INACTIVE_FILTER,
          IGNORE_PENDING_FILTER,
          IGNORE_DRAFT_FILTER,
          IGNORE_ARCHIVED_FILTER
        ]
)

export const canAddProgram = authUser => isActionAllowed(CREATE_LEVEL, authUser)
