/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const onSubmit = (props = {}) => (formValues = {}) => {
  const { postPMSForm, putPMSForm, dataId } = props
  if (postPMSForm && putPMSForm) {
    const requestAction = dataId ? putPMSForm : postPMSForm
    requestAction(formValues)
  }
}
