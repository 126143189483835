/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { RIGHT_ALIGN, LEFT_ALIGN } from 'constants/alignTypes'
import styles from './ButtonsWrapper.scss'

export function ButtonsWrapper(props) {
  const { children, align, className } = props

  return (
    <div
      className={classNames(
        styles.buttonsWrapper,
        {
          [styles.alignRight]: align === RIGHT_ALIGN
        },
        className
      )}
    >
      {children}
    </div>
  )
}
ButtonsWrapper.defaultProps = {
  align: LEFT_ALIGN,
  className: null
}
ButtonsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf([RIGHT_ALIGN, LEFT_ALIGN]),
  className: PropTypes.string
}
