import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'informed'
import { TimePickerField, ControlButtonsContainer } from 'components/FormComponents'
import { SubmitButton } from 'components/Buttons'
import { BasicButton } from '@xplorie/ui-commons'
import {
  ORANGE_SCHEMA,
  YELLOW_GREEN_SCHEMA,
  SUBMIT_TITLE,
  CANCEL_TITLE
} from 'constants/buttonsOptions'
import { validateAfterTime, validateBeforeTime } from 'helpers/formHelpers'
import {
  END_FIELD_FIELD,
  END_FIELD_LABEL,
  END_FIELD_PLACEHOLDER,
  START_FIELD_FIELD,
  START_FIELD_LABEL,
  START_FIELD_PLACEHOLDER,
  TIME_PERIOD_FORMAT
} from './types'
import styles from './styles.scss'

export function TimePeriodsForm({ onClose, ...rest }) {
  return (
    <Form {...rest}>
      {formProps => (
        <>
          <div className={styles.timePickers}>
            <TimePickerField
              label={START_FIELD_LABEL}
              placeholder={START_FIELD_PLACEHOLDER}
              fieldName={START_FIELD_FIELD}
              className={styles.timePickerInput}
              fieldProps={{ classNameWrapper: styles.timePickerField }}
              format={TIME_PERIOD_FORMAT}
              validate={validateBeforeTime(END_FIELD_FIELD)}
              notify={[END_FIELD_FIELD]}
              isRequired
            />
            <TimePickerField
              label={END_FIELD_LABEL}
              placeholder={END_FIELD_PLACEHOLDER}
              fieldName={END_FIELD_FIELD}
              className={styles.timePickerInput}
              fieldProps={{ classNameWrapper: styles.timePickerField }}
              format={TIME_PERIOD_FORMAT}
              validate={validateAfterTime(START_FIELD_FIELD)}
              notify={[START_FIELD_FIELD]}
              isRequired
            />
          </div>
          <ControlButtonsContainer className={styles.buttons}>
            <SubmitButton {...formProps} className={styles.button} colorSchema={ORANGE_SCHEMA}>
              {SUBMIT_TITLE}
            </SubmitButton>
            <BasicButton
              className={styles.button}
              colorSchema={YELLOW_GREEN_SCHEMA}
              onClick={onClose}
            >
              {CANCEL_TITLE}
            </BasicButton>
          </ControlButtonsContainer>
        </>
      )}
    </Form>
  )
}

TimePeriodsForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

TimePeriodsForm.defaultProps = {
  children: null
}
