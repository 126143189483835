/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import moment from 'moment'
import { FORMAT } from 'constants/date'
import { EFFECTIVENESS_DATE_VALIDATE_MESSAGE } from './types'

export function buildEffectivenessDateBorderDates() {
  const now = moment()
  const yearAgo = moment().subtract(1, 'years')
  return { nowDate: now.toDate(), yearAgoDate: yearAgo.toDate() }
}

export const validateEffectivenessDate = (nowDate, yearAgoDate) => value => {
  if (!value) {
    return null
  }
  const date = moment(value, FORMAT)

  if (!date.isValid()) {
    return null
  }
  return date.isSameOrBefore(moment(nowDate), 'days') &&
    date.isSameOrAfter(moment(yearAgoDate), 'days')
    ? null
    : EFFECTIVENESS_DATE_VALIDATE_MESSAGE
}
