/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { SelectField } from 'components/FormComponents'
import { NOT_BE_BLANK_MESSAGE } from 'helpers/types/formHelpers'
import {
  SELECT_PROGRAM_LABEL,
  PROGRAM_FIELD_NAME,
  PROGRAM_OPTION_FIELD_NAME,
  SELECT_PROGRAM_PLACEHOLDER,
  ASSIGMENT_TYPES
} from './types'
import styles from './styles.scss'

export function SelectProgram({ options, onChange, disabled }) {
  const onSelect = useCallback(
    ({ value }) => {
      onChange(value)
    },
    [onChange]
  )

  const validateOnSubmit = value =>
    value && value.id === ASSIGMENT_TYPES.NONE ? NOT_BE_BLANK_MESSAGE : null

  return (
    <>
      <SelectField
        className={styles.required}
        label={SELECT_PROGRAM_LABEL}
        placeholder={SELECT_PROGRAM_PLACEHOLDER}
        fieldName={PROGRAM_OPTION_FIELD_NAME}
        options={options}
        onSelectHandler={onSelect}
        disabled={disabled}
        path={['id']}
        validate={validateOnSubmit}
        fieldProps={{
          classNameLabel: styles.required
        }}
      />
      <SelectField
        fieldProps={{
          className: styles.hidden
        }}
        fieldName={PROGRAM_FIELD_NAME}
      />
    </>
  )
}

SelectProgram.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
}
