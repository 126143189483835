/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */
import { buildSearchColumn, LIKE_START_WITH_MODE, AND_OPERATOR } from 'helpers/searchHelpers'
import { STATUS_ACTIVE, STATUS_INACTIVE } from 'constants/entityStatus'
import { PM_COMPANY } from 'constants/searchTypes'

export const TITLE_PAGE = 'Property Management Companies'
export const TEXT_BTN_ADD_NEW = 'Add New PMC'

export const ACTIVE_FILTER_KEY = 'ACTIVE_FILTER_KEY'
export const ACTIVE_FILTER_LABEL = 'Active'

export const INACTIVE_FILTER_KEY = 'INACTIVE_FILTER_KEY'
export const INACTIVE_FILTER_LABEL = 'Inactive'

export const EXPORT_FILENAME = 'pm_company.csv'

export const PMC_INITIAL_FILTERS = {
  [ACTIVE_FILTER_KEY]: true,
  [INACTIVE_FILTER_KEY]: false
}

export const PMC_FILTERS = [
  { label: ACTIVE_FILTER_LABEL, field: ACTIVE_FILTER_KEY },
  { label: INACTIVE_FILTER_LABEL, field: INACTIVE_FILTER_KEY }
]

export const ACTIVE_FILTER = buildSearchColumn({
  entity: PM_COMPANY,
  field: 'status',
  operator: AND_OPERATOR,
  keyword: STATUS_ACTIVE,
  mode: LIKE_START_WITH_MODE
})

export const INACTIVE_FILTER = buildSearchColumn({
  entity: PM_COMPANY,
  field: 'status',
  operator: AND_OPERATOR,
  keyword: STATUS_INACTIVE,
  mode: LIKE_START_WITH_MODE
})

export const IGNORE_ACTIVE_FILTER = buildSearchColumn({
  entity: PM_COMPANY,
  field: 'status',
  operator: AND_OPERATOR,
  keyword: STATUS_ACTIVE,
  ignore: true,
  mode: LIKE_START_WITH_MODE
})

export const IGNORE_INACTIVE_FILTER = buildSearchColumn({
  entity: PM_COMPANY,
  field: 'status',
  operator: AND_OPERATOR,
  keyword: STATUS_INACTIVE,
  ignore: true,
  mode: LIKE_START_WITH_MODE
})
