/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import { LIST_NAME, LOCATION_TREE_STATE, REMOVE_LOCATION, MOVE_LOCATION } from './types'

const REQUEST_LIST = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_LIST_ERROR = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

const UPDATE_TREE_STATE = `UPDATE_${LOCATION_TREE_STATE}`

const REQUEST_REMOVE_LOCATION = `${REQUEST_TYPE}_${REMOVE_LOCATION}`
const RESPONSE_REMOVE_LOCATION = `${RESPONSE_TYPE}_${REMOVE_LOCATION}`
const RESPONSE_REMOVE_LOCATION_ERROR = `${RESPONSE_ERROR_TYPE}_${REMOVE_LOCATION}`

const REQUEST_MOVE_LOCATION = `${REQUEST_TYPE}_${MOVE_LOCATION}`
const RESPONSE_MOVE_LOCATION = `${RESPONSE_TYPE}_${MOVE_LOCATION}`
const RESPONSE_MOVE_LOCATION_ERROR = `${RESPONSE_ERROR_TYPE}_${MOVE_LOCATION}`

const buildIdForGlobalTree = (id = '', parentId = null) => `${parentId ? `${parentId}-` : ''}${id}`

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_LIST_ERROR,
  RESET_DATA_STATE,
  UPDATE_TREE_STATE,
  REQUEST_REMOVE_LOCATION,
  RESPONSE_REMOVE_LOCATION,
  RESPONSE_REMOVE_LOCATION_ERROR,
  REQUEST_MOVE_LOCATION,
  RESPONSE_MOVE_LOCATION,
  RESPONSE_MOVE_LOCATION_ERROR,
  buildIdForGlobalTree
}
