/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const NUMBER_VALIDATION_MESSAGE = 'The value must be positive integer number'
export const POSITIVE_NUMBER_VALIDATION_MESSAGE = 'The value must be positive number'
export const VALID_VALUE_EXCEEDED_MESSAGE = 'Valid value exceeded'
export const ALLOWED_CHAR_LIMIT_MESSAGE = 'value exceeds the allowed characters limit'
export const HOME_SEARCH_VALIDATION_MESSAGE = 'Input must contain at least 2 characters'
export const NOT_BE_BLANK_MESSAGE = 'Input must not be blank'
export const INVALID_FEE_NUMBER =
  'Invalid of number; This value should be less then 100.00; exp: 99.99'
export const NOT_VALID_DATE_MESSAGE = 'Date must be valid and in format mm/dd/yyyy'
export const NOT_VALID_EMAIL_MESSAGE =
  'Email must be in format email@domain (domain must include at least one ".")'
export const LATITUDE_VALIDATION_MESSAGE = 'The value must be between -90 and 90'
export const LONGITUDE_VALIDATION_MESSAGE = 'The value must be between -180 and 180'
export const LINK_VALIDATION_MESSAGE = 'URL must be valid, try adding "http://" to uncommon URLS'
export const PHONE_VALIDATION_MESSAGE =
  'Must include valid area code and prefix. Formats: xxxxxxxxxx, xxx-xxx-xxxx, (xxx)xxx-xxxx, etc.'
export const ZIP_CANADA_VALIDATION_MESSAGE =
  'Zip code must be in format xxx xxx or xxx-xxx (ex: V0N 1B4)'
export const ZIP_USA_VALIDATION_MESSAGE =
  'Zip code must be in format xxxxx, xxxxx-xxxx, or xxxxx xxxx'
export const BEFORE_DATE_VALIDATION_MESSAGE =
  'date must be earlier than the end date or be the same'
export const BEFORE_TIME_VALIDATION_MESSAGE =
  'time must be earlier than the end time or be the same'
export const AFTER_DATE_VALIDATION_MESSAGE = 'date must be after the start date or be the same'
export const AFTER_TIME_VALIDATION_MESSAGE = 'time must be after the start time or be the same'
export const BETWEEN_DATE_VALIDATION_MESSAGE = 'date must be between start and end'
export const ALREADY_SEARCHED_MESSAGE = 'Already searched'
export const DECIMAL_VALIDATION_MESSAGE = 'Invalid number'
export const DECIMAL_VALIDATION_PERIOD_MESSAGE = 'The number should be between 1 and 999'
export const DATE_WITH_WEEK_DAY_VALIDATION_MESSAGE =
  'Date must be in format [day of the week]. mm/dd/yyyy'
export const MULTI_EMAIL_VALIDATION_MESSAGE =
  'Email must be in format. [email], [email], … , [email]'
export const DATE_IN_PAST_VALIDATION_MESSAGE = 'Date can not be in the past'
export const DATE_IN_FUTURE_VALIDATION_MESSAGE = 'Date can not be in the future'
export const DEFAULT_SYMBOL_COUNT = 512
export const DEFAULT_CHARACTER_LIMIT = 255
export const PHONE_THEE_FOUR_TREE_FORMAT_VALIDATION_MESSAGE =
  'The field should be with according to this format: "###-###-####"'
export const VALUE_VALIDATION_MESSAGE = 'Invalid value'
export const RANGE_INTERSECTION_ERROR = 'Date have intersections with other dates'
export const MIN_NUMBER_ERROR = 'Min number can not be more than Max number'
export const MAX_NUMBER_ERROR = 'Max number can not be less than Min number'
export const GREATER_THEN_ZERO_MESSAGE = 'The value should be greater than 0'
export const PERIOD_OVERLAP_MESSAGE = 'Period cannot overlap with existing one'

export const MIN_VALUE_INVALID_MESSAGE = min => `Cannot be less than ${min}`
export const MAX_VALUE_INVALID_MESSAGE = max => `Cannot be more than ${max}`
