/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const DATE_PARAMETER_PLACEHOLDER = 'Date'

export const START_DATE_PLACEHOLDER = 'Start Date'
export const END_DATE_PLACEHOLDER = 'End Date'
