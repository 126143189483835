/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'
import types from './types'

const initialState = {
  panelStack: {},
  activePanel: null
}

const editQueueReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ENQUEUE:
      return {
        ...state,
        panelStack: {
          ...state.panelStack,
          [action.payload.id]: { ...action.payload }
        },
        activePanel: action.payload.id
      }
    case types.DEQUEUE:
      return (() => {
        const panelId = action.payload
        const { [panelId]: removedPanel, ...restPanels } = state.panelStack
        return {
          ...state,
          panelStack: {
            ...restPanels
          },
          activePanel: Object.keys(restPanels).pop()
        }
      })()
    case types.UPDATE_SLIDE:
      return (() => {
        const { id, data } = action.payload
        const { headerProps: prevHeaderProps, props: prevProps, ...prevRest } = state.panelStack[id]
        const { headerProps, props, ...rest } = data

        return {
          ...state,
          panelStack: {
            ...state.panelStack,
            [id]: {
              ...prevRest,
              ...rest,
              headerProps: {
                ...prevHeaderProps,
                ...headerProps
              },
              props: {
                ...prevProps,
                ...props
              }
            }
          }
        }
      })()
    case types.CLEAR_QUEUE:
      return { ...initialState }
    default:
      return state
  }
}

export default filterActions(editQueueReducer, [
  types.ENQUEUE,
  types.DEQUEUE,
  types.UPDATE_SLIDE,
  types.CLEAR_QUEUE
])
