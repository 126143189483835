/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { RATE_ADJUSTMENT_LIST, SET_RATE_MODE } from './types'

export const REQUEST_LIST = `${REQUEST_TYPE}_${RATE_ADJUSTMENT_LIST}`
export const RESPONSE_LIST = `${RESPONSE_TYPE}_${RATE_ADJUSTMENT_LIST}`
export const RESPONSE_LIST_ERROR = `${RESPONSE_ERROR_TYPE}_${RATE_ADJUSTMENT_LIST}`
export const RESET_DATA_STATE = `${RESET_STATE}_${RATE_ADJUSTMENT_LIST}`
export const RATE_MODE = `${SET_RATE_MODE}_${RATE_ADJUSTMENT_LIST}`
