/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import { AddEntityButton } from 'components/Buttons'
import { AddingEntityBox } from 'components/FormComponents'
import { usePrevious } from 'hooks'

import { ORANGE_SCHEMA, SMALLER_SIZE } from 'constants/buttonsOptions'
import { YearAvailability } from './YearAvailability'
import { AvailabilityItem } from './AvailabilityItem'
import { groupAvailabilityByStartYear, buildId, validateDate, findAvlbIndex } from './helpers'
import { ADD_AVAILABILITY_BUTTON_TEXT, SECTION_TITLE } from './types'

export function AvailabilitySection(props) {
  const { fieldState, fieldApi, formState, disabled, ...rest } = props
  const value = get(fieldState, 'value', [])
  const errors = get(fieldState, 'error', {})
  const [nanDates, groupedAvailability] = groupAvailabilityByStartYear(value)
  const prevGroupedAvailability = usePrevious(groupedAvailability)

  const isSectionDisabled = disabled
  const isAddButtonDisabled = (nanDates && isArray(nanDates) && nanDates.length > 0) || disabled

  useEffect(() => {
    // onSubmit validation
    if (formState.submits > 0) {
      validateDate({
        value,
        fieldApi
      })
    }
  }, [fieldApi, formState.submits, value])

  const onSomeAvailabilityChanged = (newValue, originalValue) => {
    const dateIndex = findAvlbIndex(originalValue, value)
    if (dateIndex !== -1) {
      value.splice(dateIndex, 1)
      const newArray = [...value, newValue]
      fieldApi.setValue(newArray)
      validateDate({
        value: newArray,
        fieldApi
      })
    }
  }

  const onAddNewAvlbItem = () => {
    if (!isAddButtonDisabled && !isSectionDisabled) {
      fieldApi.setValue([...value, { startDate: {}, endDate: {}, id: buildId() }])
    }
  }

  const onDeleteHandler = originalValue => {
    if (!isSectionDisabled) {
      const dateIndex = findAvlbIndex(originalValue, value)
      if (dateIndex !== -1) {
        value.splice(dateIndex, 1)
        const newArray = [...value]
        fieldApi.setValue(newArray)
        validateDate({
          value: newArray,
          fieldApi
        })
      }
    }
  }

  return (
    <AddingEntityBox
      title={SECTION_TITLE}
      isVisibleSearch={false}
      AddEntityButton={
        !isSectionDisabled && (
          <AddEntityButton
            colorSchema={ORANGE_SCHEMA}
            size={SMALLER_SIZE}
            disabled={isAddButtonDisabled}
            onClick={onAddNewAvlbItem}
          >
            {ADD_AVAILABILITY_BUTTON_TEXT}
          </AddEntityButton>
        )
      }
    >
      {isAddButtonDisabled &&
        nanDates.map((item, index) => (
          <AvailabilityItem
            {...rest}
            key={item.id || index}
            value={item}
            onChange={onSomeAvailabilityChanged}
            onDelete={onDeleteHandler}
            errors={errors}
            disabled={isSectionDisabled}
          />
        ))}
      {Object.keys(groupedAvailability).map(year => {
        const isNew = Boolean(
          isObject(prevGroupedAvailability) &&
            Object.keys(prevGroupedAvailability).length > 0 &&
            !prevGroupedAvailability[year] &&
            groupedAvailability[year]
        )
        return (
          <YearAvailability
            {...rest}
            key={year}
            year={year}
            masterData={groupedAvailability[year]}
            onChange={onSomeAvailabilityChanged}
            onDelete={onDeleteHandler}
            errors={errors}
            disabled={isSectionDisabled}
            isNew={isNew}
          />
        )
      })}
    </AddingEntityBox>
  )
}

AvailabilitySection.propTypes = {
  fieldState: PropTypes.object.isRequired,
  fieldApi: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired
}
