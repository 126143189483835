/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import get from 'lodash/get'
import { SMART_HOST_FEE_TITLE } from 'modules/Programs/SmartHostFee/types'
import {
  LABEL_DESTINATION,
  LABEL_PROGRAM_NAME,
  LABEL_PMC_NAME,
  NAME_FIELD_KEY,
  PMC_NAME_FIELD_KEY,
  LOCATION_FIELD_KEY,
  PROGRAM_TYPE_FIELD_LABEL,
  PROGRAM_TYPE_FIELD_KEY,
  SMARTHOST_FIELD_KEY
} from '../types'

// TODO: crutch for add current program value to free list
export const buildOptionsUniqPhoneNumbers = (arrayPhoneNumbers = [], currentObj) => {
  const uniquePhoneNumber = get(currentObj, 'uniquePhoneNumber')

  const currentPhoneNumber =
    (uniquePhoneNumber && {
      key: uniquePhoneNumber.id,
      label: uniquePhoneNumber.number,
      value: uniquePhoneNumber
    }) ||
    {}

  const resetItem = {
    key: '0',
    label: 'None',
    value: null
  }

  const initialObject = {
    key: '1',
    label: '',
    value: {}
  }
  const collectionPhoneNumbers = arrayPhoneNumbers.map(item => ({
    ...initialObject,
    key: item.id,
    label: item.number,
    value: item
  }))

  if (uniquePhoneNumber && !arrayPhoneNumbers.some(item => item.id === uniquePhoneNumber.id)) {
    collectionPhoneNumbers.unshift(currentPhoneNumber)
  }

  collectionPhoneNumbers.unshift(resetItem)

  return collectionPhoneNumbers
}

// used for build errors (invalid modal)
export const getLabelByFieldKey = key => {
  switch (key) {
    case NAME_FIELD_KEY:
      return LABEL_PROGRAM_NAME
    case PMC_NAME_FIELD_KEY:
      return LABEL_PMC_NAME
    case LOCATION_FIELD_KEY:
      return LABEL_DESTINATION
    case PROGRAM_TYPE_FIELD_KEY:
      return PROGRAM_TYPE_FIELD_LABEL
    case SMARTHOST_FIELD_KEY:
      return SMART_HOST_FEE_TITLE
    default:
      return key
  }
}
