/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/reportExport'
import { withAuthContext } from 'helpers/withAuthContext'
import { ReportsExporter as ReportsExporterComponent } from './ReportsExporter'

const mapStateToProps = state => ({
  reportType: state.reportsList.reportType,
  dataState: state.reportExport.dataState
})
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

export const ReportsExporter = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReportsExporterComponent)
