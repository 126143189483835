/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

// Constants of types
export const MY_DRAFTS_TYPE = 'my-draft-programs'
export const DRAFTS_TYPE = 'drafts-programs'
export const PENDING_TYPE = 'all-pending-programs'
export const ON_BOARDING_TYPE = 'on-boarding-programs'

// Constants of headers
export const DRAFT_LIST_HEADER = 'Draft List'
export const PENDING_LIST_HEADER = 'Pending List'
export const BOARDING_LIST_HEADER = 'On-Boarding'

// Constants of Constants for tab menu
export const ITEM_TAB_MY_DRAFTS = 'My Drafts'
export const ITEM_TAB_DRAFTS = 'All Drafts'
export const ITEM_TAB_PENDING = 'Pending'
export const ITEM_TAB_BOARDING = 'On-Boarding'

export const TITLE_PAGE = 'My Work'
export const TEXT_BTN_ADD_NEW = 'Create New Program'
