/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getList, assignGroupsOrder, regenerateGroupBarcodes } from 'services/http/ticketGroup'
import { assignTicketTypesOrder } from 'services/http/ticketType'
import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  REQUEST_RE_ORDER_LIST,
  RESPONSE_RE_ORDER_LIST,
  RESPONSE_ERROR_RE_ORDER_LIST,
  REQUEST_RE_ORDER_TICKET_TYPES,
  RESPONSE_RE_ORDER_TICKET_TYPES,
  RESPONSE_ERROR_RE_ORDER_TICKET_TYPES
} = helpers

const getTicketGroupsList = (activityId, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))

  const request = await getList(activityId, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, request.data))
    return 'getTicketGroupsList success'
  }
  return 'getTicketGroupsList failed'
}

const updateTicketGroupsOrder = (activityId, { payload, array, oldArray }) => async dispatch => {
  dispatch(actionCreater(REQUEST_RE_ORDER_LIST, array))

  const request = await assignGroupsOrder(activityId, payload).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_RE_ORDER_LIST, oldArray))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_RE_ORDER_LIST))

    return 'updateTicketGroupsOrder success'
  }
  return 'updateTicketGroupsOrder failed'
}

const updateTicketTypesOrder = (
  activityId,
  groupId,
  { array, oldArray, payload }
) => async dispatch => {
  dispatch(actionCreater(REQUEST_RE_ORDER_TICKET_TYPES, array))

  const request = await assignTicketTypesOrder(activityId, groupId, payload).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_ERROR_RE_ORDER_TICKET_TYPES, oldArray))
    }
  )
  if (request) {
    dispatch(actionCreater(RESPONSE_RE_ORDER_TICKET_TYPES))

    return 'updateTicketTypesOrder success'
  }
  return 'updateTicketTypesOrder failed'
}

const regenerateBarcodes = activityId => async dispatch => {
  const request = await regenerateGroupBarcodes(activityId)
  if (request) {
    dispatch(getTicketGroupsList(activityId))

    return 'regenerateBarcodes success'
  }
  return 'regenerateBarcodes failed'
}

export default {
  getTicketGroupsList,
  updateTicketGroupsOrder,
  updateTicketTypesOrder,
  regenerateBarcodes
}
