/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { CalendarInput } from 'modules/CalendarInput'

import styles from './DateInput.scss'

export function DateInput(props) {
  const {
    fieldState: { value },
    fieldApi: { setValue, setTouched },
    errorClassName,
    placeholder,
    onChange,
    disabled
  } = props

  const onChangeHandler = useCallback(
    newValue => {
      setValue(newValue)
      setTouched(true)
      if (onChange) {
        onChange(newValue)
      }
    },
    [onChange, setTouched, setValue]
  )

  return (
    <div className={styles.wrapper}>
      <CalendarInput
        value={value}
        onChange={onChangeHandler}
        className={classNames(styles.input, errorClassName)}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  )
}
DateInput.propTypes = {
  fieldState: PropTypes.object.isRequired,
  fieldApi: PropTypes.object.isRequired,
  errorClassName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
DateInput.defaultProps = {
  onChange: null,
  disabled: false
}
