/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'

export function WarningIcon(props) {
  return (
    <svg width="100" height="100" x="0px" y="0px" viewBox="0 0 100 100" {...props}>
      <ellipse
        ry="35.5"
        rx="35.5"
        cy="249.64"
        cx="10.84"
        strokeWidth="1.5"
        transform="matrix(1.26,0,0,1.26,36.35,-264.34)"
      />
      <g transform="matrix(0.12,0,0,0.12,0,0)">
        <polygon points="378.47,259.32 388.92,477.73 431.38,477.73 441.86,259.32" />
        <rect className="fillPath" height="40.52" width="40.53" y="520.49" x="389.88" />
      </g>
    </svg>
  )
}
