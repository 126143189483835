/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { ACTUAL_DATE, ARRIVAL_DATE, DEPARTURE_DATE } from 'constants/billingTypes'

export const BILLING_TYPE_OPTIONS = [
  { label: 'Actual Date', value: ACTUAL_DATE },
  { label: 'Arrival Date', value: ARRIVAL_DATE },
  { label: 'Departure Date', value: DEPARTURE_DATE }
]
