/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function SettingsIcon(props) {
  return (
    <svg viewBox="0 0 144 144" {...props}>
      <circle cx="72.7" cy="74.3" r="9" />
      <path d="M88.4,95.6c-2.9,5.7-8.9,8.3-15.7,8.3c-6.9,0-12.8-2.6-15.8-8.3c0.9-7.9,7.6-14.1,15.8-14.1	C80.8,81.5,87.6,87.6,88.4,95.6z" />
      <path
        d="M120.3,81.1l-7-3.6c0.8-4.5,0.8-9.1,0-13.7l7-3.6c1.5-0.8,2.5-2.8,2-4.4c-1-3.4-2.4-6.6-4-9.7
		c-0.8-1.5-2.9-2.3-4.5-1.7l-7.5,2.4c-2.7-3.8-6-7.1-9.7-9.7l2.4-7.4c0.5-1.6-0.2-3.7-1.7-4.6c-1.5-0.8-3.1-1.6-4.7-2.3
		c-1.6-0.7-3.3-1.2-5-1.7c-1.7-0.5-3.7,0.5-4.4,2l-3.6,7c-4.5-0.8-9.1-0.8-13.7,0l-3.6-7c-0.8-1.5-2.8-2.5-4.4-2
		c-3.3,1-6.6,2.4-9.7,4c-1.5,0.8-2.3,2.9-1.7,4.5l2.4,7.4c-3.8,2.7-7.1,6-9.7,9.7l-7.5-2.4c-1.6-0.5-3.7,0.2-4.6,1.7
		c-0.8,1.5-1.6,3.1-2.3,4.7c-0.7,1.6-1.2,3.3-1.7,5c-0.5,1.7,0.5,3.7,2,4.4l7,3.6c-0.8,4.5-0.8,9.1,0,13.7l-7,3.6
		c-1.5,0.8-2.5,2.8-2,4.4c1,3.4,2.3,6.6,4,9.7c0.8,1.5,2.9,2.3,4.5,1.7l7.5-2.4c2.7,3.8,6,7.1,9.7,9.7l-2.4,7.5
		c-0.5,1.6,0.2,3.7,1.7,4.6c1.5,0.8,3.1,1.6,4.7,2.3c1.6,0.7,3.3,1.2,5,1.7c1.7,0.5,3.7-0.5,4.4-2l3.5-7c4.5,0.8,9.1,0.8,13.7,0
		l3.6,7c0.8,1.5,2.8,2.5,4.4,2c3.4-1,6.6-2.3,9.7-4c1.5-0.8,2.3-2.9,1.7-4.5l-2.4-7.4c3.8-2.7,7.1-6,9.7-9.7l7.5,2.4
		c1.6,0.5,3.7-0.2,4.6-1.7c0.8-1.5,1.6-3.1,2.3-4.7c0.7-1.6,1.2-3.3,1.7-5C122.7,83.9,121.8,81.9,120.3,81.1z M83.7,97.2
		c-14.6,6.1-31.4-0.9-37.5-15.5C40.1,67,47.1,50.2,61.7,44.2c14.6-6.1,31.4,0.9,37.5,15.5C105.2,74.3,98.3,91.1,83.7,97.2z"
      />
    </svg>
  )
}
