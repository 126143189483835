/* eslint-disable import/no-extraneous-dependencies */
/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

// List of used icons from ant-design library
// this needs for minify build size

export { default as ClockCircleOutline } from '@ant-design/icons/lib/outline/ClockCircleOutline'
export { default as CloseCircleFill } from '@ant-design/icons/lib/fill/CloseCircleFill'
