/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildListParams } from 'services/http/helpers'
import { UNIT_TASKS_URL, UNIT_TASKS_EXPORT_URL, UNIT_TASKS_BY_PROGRAM_URL } from 'endpoints'
import { buildRequestParams } from 'helpers/searchParamsHelper'
import { responseToFile } from 'helpers/downloadHelper'

export const getList = options => {
  const params = buildRequestParams(buildListParams(options))

  return httpWrapper({
    url: `${UNIT_TASKS_URL}${params}`,
    method: 'get'
  })
}

export const getListByProgram = options => {
  const params = buildRequestParams(buildListParams(options))

  return httpWrapper({
    url: `${UNIT_TASKS_BY_PROGRAM_URL}${params}`,
    method: 'get'
  })
}

export const exportUnitTasks = (data, options) =>
  httpWrapper({
    url: UNIT_TASKS_EXPORT_URL,
    method: 'post',
    data,
    params: buildListParams(options),
    responseType: 'blob'
  }).then(responseToFile)
