/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'

export function Column(props) {
  const { children } = props
  const classNameWrapper = get(children, 'props.className', '')

  return <div className={classNames('column', classNameWrapper)}>{children}</div>
}

Column.propTypes = {
  children: PropTypes.node.isRequired
}
