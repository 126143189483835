/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose } from 'redux'
import { getPrebookFlagById } from 'reducers/programsForm'
import { withAuthContext } from 'helpers/withAuthContext'
import { UploadGuestsTab as UploadGuestsTabComponent } from './UploadGuestsTab'

function mapStateToProps(state, { dataId }) {
  return {
    prebookOnly: getPrebookFlagById(state, dataId)
  }
}

export const UploadGuestsTab = compose(
  withAuthContext,
  connect(mapStateToProps)
)(UploadGuestsTabComponent)
