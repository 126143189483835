/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import isObject from 'lodash/isObject'

export const buildDealOptionsContract = fieldValues => {
  const { availabilities = [], ...rest } = fieldValues
  return { availabilities: availabilities.filter(value => isObject(value)), ...rest }
}

export const buildAssignNewGroupContract = formData => formData
