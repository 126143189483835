/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'
import programFormHelpers from '../programsForm/helpers'

const {
  RESET_APPROVAL_STATE,
  REQUEST_APPROVAL,
  RESPONSE_APPROVAL,
  RESPONSE_APPROVAL_ERROR,
  REQUEST_PROGRAM_DRAFT,
  RESPONSE_PROGRAM_DRAFT,
  RESPONSE_PROGRAM_DRAFT_ERROR
} = helpers

export const initialState = {}
export const initialProgramApprovalState = {
  approvalState: DATA_STATE_NOT_REQUESTED,
  programDraftState: DATA_STATE_NOT_REQUESTED
}

const programApprovalReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_APPROVAL:
      return {
        ...state,
        [action.payload.key]: {
          ...initialProgramApprovalState,
          ...state[action.payload.key],
          approvalState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_APPROVAL_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...initialProgramApprovalState,
          ...state[action.payload.key],
          approvalState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_APPROVAL:
      return {
        ...state,
        [action.payload.key]: {
          ...initialProgramApprovalState,
          ...state[action.payload.key],
          approvalState: DATA_STATE_RECIEVED
        }
      }
    case REQUEST_PROGRAM_DRAFT:
      return {
        ...state,
        [action.payload.key]: {
          ...initialProgramApprovalState,
          ...state[action.payload.key],
          programDraftState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_PROGRAM_DRAFT_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...initialProgramApprovalState,
          ...state[action.payload.key],
          programDraftState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_PROGRAM_DRAFT:
      return {
        ...state,
        [action.payload.key]: {
          ...initialProgramApprovalState,
          ...state[action.payload.key],
          programDraftState: DATA_STATE_RECIEVED
        }
      }
    case RESET_APPROVAL_STATE:
    case programFormHelpers.RESET_DATA_STATE:
      return (() => {
        const { [action.payload.key]: removedElement, ...restElements } = state
        return { ...restElements }
      })()
    default:
      return state
  }
}

export default filterActions(programApprovalReducer, [
  RESET_APPROVAL_STATE,
  RESPONSE_PROGRAM_DRAFT,
  RESPONSE_PROGRAM_DRAFT_ERROR,
  REQUEST_PROGRAM_DRAFT,
  REQUEST_APPROVAL,
  RESPONSE_APPROVAL,
  RESPONSE_APPROVAL_ERROR,
  programFormHelpers.RESET_DATA_STATE
])
