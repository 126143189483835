/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { listInitialState } from './reducer'

/**
 *
 * @param {object} state
 * @param {string} destinationId
 */
function getAppListStateByDestinationId(state, destinationId) {
  return state.app.appsByDestination[destinationId] || listInitialState
}

// state.app.appsByAp[appId]

export default {
  getAppListStateByDestinationId
}
