/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { isUndefined } from "lodash"

export const getQueryStringParams = query =>
  query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        const [key, value] = param.split('=')
        // eslint-disable-next-line no-param-reassign
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
        return params
      }, {})
    : {}

export const buildRequestParams = (params = {}) => {
  const esc = encodeURIComponent
  const query = Object.keys(params)
    .filter(k => !isUndefined(params[k]))
    .map(k => `${esc(k)}=${esc(params[k])}`)
    .join('&')
  return `?${query}`
}

export function buildUrlWithParams(path, params = {}) {
  return `${path}${buildRequestParams(params)}`
}
