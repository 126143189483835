/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { FORM_NAME, ENTITY_DATA } from './types'

const REQUEST_FORM = `${REQUEST_TYPE}_${FORM_NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${FORM_NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${FORM_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

const REQUEST_ENTITY_DATA = `${REQUEST_TYPE}_${ENTITY_DATA}`
const RESPONSE_ENTITY_DATA = `${RESPONSE_TYPE}_${ENTITY_DATA}`
const RESPONSE_ENTITY_DATA_ERROR = `${RESPONSE_ERROR_TYPE}_${ENTITY_DATA}`

export default {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_ENTITY_DATA,
  RESPONSE_ENTITY_DATA,
  RESPONSE_ENTITY_DATA_ERROR
}
