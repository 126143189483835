/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { STATUS_ACTIVE, STATUS_INACTIVE, STATUS_INELIGIBLE } from 'constants/entityStatus'
import { ACTIVE_LABEL, INACTIVE_LABEL, INELIGIBLE_LABEL } from './types'
import { getCountUnitsByStatus } from './helpers'
import styles from './UnitsStats.scss'

export function UnitsStats({ data }) {
  const activeCount = useMemo(() => getCountUnitsByStatus(data, STATUS_ACTIVE), [data])
  const inactiveCount = useMemo(() => getCountUnitsByStatus(data, STATUS_INACTIVE), [data])
  const ineligibleCount = useMemo(() => getCountUnitsByStatus(data, STATUS_INELIGIBLE), [data])

  return (
    <div className={styles.wrapper}>
      <div className={styles.col}>
        <div className={styles.field}>
          <span className={styles.label}>{ACTIVE_LABEL}</span>
          <span className={styles.value}>{activeCount}</span>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.field}>
          <span className={styles.label}>{INACTIVE_LABEL}</span>
          <span className={styles.value}>{inactiveCount}</span>
        </div>
        <div className={styles.field}>
          <span className={styles.label}>{INELIGIBLE_LABEL}</span>
          <span className={styles.value}>{ineligibleCount}</span>
        </div>
      </div>
    </div>
  )
}

UnitsStats.propTypes = {
  data: PropTypes.array.isRequired
}
