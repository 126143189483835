/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const NAME = 'IBE_CONNECTION'
export const UPDATE_XPLORIE = `${NAME}_UPDATE_XPLORIE`
export const CHECK_UPDATE_PROGRESS = `${NAME}_CHECK_UPDATE_PROGRESS`

export default {
  NAME,
  UPDATE_XPLORIE,
  CHECK_UPDATE_PROGRESS
}
