/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { buildPath } from 'helpers/httpHelpers'
import { responseToFile } from 'helpers/downloadHelper'

import { ACTIVITY_HOW_TO_URL } from 'endpoints'

export const uploadHowToFile = (activityId, file, type) => {
  const data = new FormData()
  data.append('file', file)

  return httpWrapper({
    url: buildPath(ACTIVITY_HOW_TO_URL, { activityId, type }),
    method: 'post',
    data
  })
}

export const downloadHowToFile = (activityId, type) =>
  httpWrapper({
    url: buildPath(ACTIVITY_HOW_TO_URL, { activityId, type }),
    method: 'get',
    responseType: 'blob'
  }).then(responseToFile)

export const removeHowToFile = (activityId, type) =>
  httpWrapper({ url: buildPath(ACTIVITY_HOW_TO_URL, { activityId, type }), method: 'delete' })
