/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { httpWrapper } from 'services/http/helpers'
import { SEASON_EXCLUSIVE_ACTIVITIES_URL, EXCLUSIVE_ACTIVITY_BY_ID_URL } from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'

export const getExclusiveOfferActivities = programId =>
  httpWrapper({
    url: buildPath(SEASON_EXCLUSIVE_ACTIVITIES_URL, { programId }),
    method: 'get'
  })

export const removeExclusiveOfferActivity = (programId, activityId) =>
  httpWrapper({
    url: buildPath(EXCLUSIVE_ACTIVITY_BY_ID_URL, { programId, activityId }),
    method: 'delete'
  })

export const addExclusiveOfferActivity = (programId, activityId) =>
  httpWrapper({
    url: buildPath(EXCLUSIVE_ACTIVITY_BY_ID_URL, { programId, activityId }),
    method: 'put'
  })
