/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const ACTIVE_OPTION_LABEL = 'Active'
export const INACTIVE_OPTION_LABEL = 'Inactive'
export const INELIGIBLE_OPTION_LABEL = 'Ineligible'

export const dropdownOptions = [
  { label: ACTIVE_OPTION_LABEL },
  { label: INACTIVE_OPTION_LABEL },
  { label: INELIGIBLE_OPTION_LABEL }
]

export const FROM_LABEL = 'from'
