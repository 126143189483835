/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_DOWNLOAD_UNITS_TEMPLATE,
  RESPONSE_DOWNLOAD_UNITS_TEMPLATE,
  RESPONSE_ERROR_DOWNLOAD_UNITS_TEMPLATE,
  RESET_DATA_STATE
} = helpers

export const initialState = {
  downloadUnitsTemplateDataState: DATA_STATE_NOT_REQUESTED
}

const lifeCycleReducer = (state = initialState, action) => {
  switch (action.type) {
    // changing status
    case REQUEST_DOWNLOAD_UNITS_TEMPLATE:
      return {
        ...state,
        downloadUnitsTemplateDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_DOWNLOAD_UNITS_TEMPLATE:
      return {
        ...state,
        downloadUnitsTemplateDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_ERROR_DOWNLOAD_UNITS_TEMPLATE:
      return {
        ...state,
        downloadUnitsTemplateDataState: DATA_STATE_ERROR
      }
    // reset
    case RESET_DATA_STATE:
      return { ...initialState }
    default:
      return state
  }
}

export default filterActions(lifeCycleReducer, [
  REQUEST_DOWNLOAD_UNITS_TEMPLATE,
  RESPONSE_DOWNLOAD_UNITS_TEMPLATE,
  RESPONSE_ERROR_DOWNLOAD_UNITS_TEMPLATE,
  RESET_DATA_STATE
])
