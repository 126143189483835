/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { DEAL } from 'constants/searchTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'
import { DEAL_COMPLIMENTARY_TYPE, DEAL_NON_COMPLIMENTARY_TYPE } from 'constants/dealsTypes'
import {
  COMPLIMENTARY_FILTER_LABEL,
  NON_COMPLIMENTARY_FILTER_LABEL
} from 'containers/Discounts/types'
import {
  STATUS_DRAFT,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  VALUE_ACTIVE,
  VALUE_DRAFT,
  VALUE_INACTIVE
} from 'constants/entityStatus'
import { getStatusValue } from './commonHelpers'

export const dealsTableColumns = [
  {
    Header: 'Discount Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', DEAL)
  },
  {
    Header: 'Discount Type',
    accessor: 'type',
    id: buildSortPropertyByType('type', DEAL)
  },
  {
    Header: 'Status',
    accessor: getStatusValue,
    id: buildSortPropertyByType('status', DEAL)
  }
]

export const dealsExportTableColumns = [
  {
    Header: 'Discount Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', DEAL)
  },
  {
    Header: 'PMC Name',
    accessor: 'pmcName',
    id: buildSortPropertyByType('pmcName', DEAL)
  },
  {
    Header: 'Program Name',
    accessor: 'programName',
    id: buildSortPropertyByType('programName', DEAL)
  },
  {
    Header: 'Program Destination',
    accessor: 'programDestination',
    id: buildSortPropertyByType('programDestination', DEAL)
  },
  {
    Header: 'Activity Provider Product Name',
    accessor: 'activityName',
    id: buildSortPropertyByType('activityName', DEAL)
  },
  {
    Header: 'Discount Description',
    accessor: 'description',
    id: buildSortPropertyByType('description', DEAL)
  },
  {
    Header: 'Discount Status',
    accessor: 'status',
    id: buildSortPropertyByType('status', DEAL),
    valueMap: {
      [STATUS_ACTIVE]: VALUE_ACTIVE,
      [STATUS_DRAFT]: VALUE_DRAFT,
      [STATUS_INACTIVE]: VALUE_INACTIVE
    }
  },
  {
    Header: 'Discount Offer Type',
    accessor: 'type',
    id: buildSortPropertyByType('type', DEAL),
    valueMap: {
      [DEAL_COMPLIMENTARY_TYPE]: COMPLIMENTARY_FILTER_LABEL,
      [DEAL_NON_COMPLIMENTARY_TYPE]: NON_COMPLIMENTARY_FILTER_LABEL
    }
  },
  {
    Header: 'Discount Start Date',
    accessor: 'startDate'
  },
  {
    Header: 'Discount End Date',
    accessor: 'endDate'
  }
]
