/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'

import { NOT_INDICATED, NOT_LABELED } from 'constants/field'

import styles from '../../profile.scss'

export function ProfileField(props) {
  const { value, label } = props

  return (
    <div className={styles.customStylesFild}>
      <span>{label}: </span>
      <strong>{value}</strong>
    </div>
  )
}

ProfileField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string
}

ProfileField.defaultProps = {
  value: NOT_INDICATED,
  label: NOT_LABELED
}
