/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const NAME = 'UNITS_LIFE_CYCLE'
const CHANGE_STATUS_BULK = `${NAME}_CHANGE_STATUS_BULK`
const UPLOAD_UNITS_FILE = `${NAME}_UPLOAD_UNITS_FILE`

export default {
  NAME,
  CHANGE_STATUS_BULK,
  UPLOAD_UNITS_FILE
}
