/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'informed'
import { CalendarField, FormContent } from 'components/FormComponents'
import { buildEffectivenessDateBorderDates, validateEffectivenessDate } from './helpers'
import { EFFECTIVE_DATE_LABEL, EFFECTIVE_DATE_FIELD, EFFECTIVE_DATE_PLACEHOLDER } from './types'

export function EligibilityFieldsForm({ disabled, getApi, onSubmit, onChange }) {
  const { nowDate, yearAgoDate } = useMemo(() => buildEffectivenessDateBorderDates(), [])

  return (
    <Form getApi={getApi} onSubmit={onSubmit} onChange={onChange}>
      <FormContent disabled={disabled}>
        <CalendarField
          fieldName={EFFECTIVE_DATE_FIELD}
          label={EFFECTIVE_DATE_LABEL}
          placeholder={EFFECTIVE_DATE_PLACEHOLDER}
          validate={validateEffectivenessDate(nowDate, yearAgoDate)}
          additionalProps={{
            disabledDays: [
              {
                after: nowDate,
                before: yearAgoDate
              }
            ]
          }}
        />
      </FormContent>
    </Form>
  )
}

EligibilityFieldsForm.propTypes = {
  disabled: PropTypes.bool,
  getApi: PropTypes.func,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func
}
EligibilityFieldsForm.defaultProps = {
  disabled: false,
  onChange: () => {},
  onSubmit: () => {},
  getApi: () => {}
}
