/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { UploadCloudIcon } from 'icons'
import { DEFAULT_TEXT } from './types'
import styles from './UploadPad.scss'

export function UploadPad(props) {
  const { accept, validate, onChange, className } = props
  const [isOver, seIsOverState] = useState(false)

  const setValue = useCallback(
    (files, ev) => {
      if (!onChange) {
        return
      }
      if (!validate) {
        onChange(files, ev)
        return
      }
      if (!validate(files)) {
        onChange(files, ev)
      }
    },
    [onChange, validate]
  )

  const onChangeHandler = useCallback(
    ev => {
      setValue(ev.target.files, ev)
    },
    [setValue]
  )

  const toggleIsOver = useCallback(() => {
    seIsOverState(!isOver)
  }, [isOver])

  const onDropHandler = useCallback(
    ev => {
      ev.preventDefault()
      setValue(ev.dataTransfer.files, ev)
      if (isOver) {
        toggleIsOver()
      }
    },
    [setValue, isOver, toggleIsOver]
  )

  const onDragOverHandler = useCallback(ev => {
    ev.preventDefault()
  }, [])

  const onDragEnterHandler = useCallback(
    ev => {
      ev.preventDefault()
      toggleIsOver()
    },
    [toggleIsOver]
  )

  const onDragLeaveHandler = useCallback(
    ev => {
      ev.preventDefault()
      toggleIsOver()
    },
    [toggleIsOver]
  )
  return (
    <label
      className={classNames(styles.wrapper, { [styles.isOver]: isOver }, className)}
      onDrop={onDropHandler}
      onDragOver={onDragOverHandler}
      onDragEnter={onDragEnterHandler}
      onDragLeave={onDragLeaveHandler}
    >
      <span className={styles.content}>
        <UploadCloudIcon className={styles.icon} />
        <span className={styles.text}>{DEFAULT_TEXT}</span>
      </span>
      <input className={styles.input} type="file" onChange={onChangeHandler} accept={accept} />
    </label>
  )
}
UploadPad.propTypes = {
  accept: PropTypes.string,
  validate: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string
}
UploadPad.defaultProps = {
  accept: null,
  validate: null,
  onChange: null,
  className: null
}
