/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const defaultLabelTooltip = '?'
export const TOP_DIRECTION = 'top'
export const RIGHT_DIRECTION = 'right'
export const BOTTOM_DIRECTION = 'bottom'
export const LEFT_DIRECTION = 'left'
