/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const FORM_NAME = 'ACCORDION_FORM'
export const GET_ENTITY_BY_ID = `${FORM_NAME}_GET_ENTITY_BY_ID`
export const REMOVE_ENTITY_BY_ID = `${FORM_NAME}_REMOVE_ENTITY_BY_ID`

export default {
  FORM_NAME,
  GET_ENTITY_BY_ID,
  REMOVE_ENTITY_BY_ID
}
