/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React from 'react'
import classNames from 'classnames'

import withAuthorization from 'helpers/withAuthorization'
import { ContentWithNav } from 'components/ContentWithNav'

import styles from './adminHome.scss'

export function AdminHome() {
  return (
    <ContentWithNav>
      <div className={classNames(styles.wrapper)}>
        <section className={classNames(styles.content)}>
          <div className={classNames(styles.pieLogo)}>
            <img className={classNames(styles.xplLogo)} src="/powered-by-logo.jpg" />
          </div>
        </section>
      </div>
    </ContentWithNav>
  )
}

export default withAuthorization(AdminHome)
