/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { WARNING_MESSAGE, QUESTION_MARK } from './types'
import { prepareName } from './helpers'

export function MessageContent({ name, startMessage, endMessage }) {
  const preparedName = prepareName(name)
  return (
    <span>
      {startMessage && startMessage}
      <b>{preparedName}</b>
      {endMessage && endMessage}
    </span>
  )
}

MessageContent.propTypes = {
  name: PropTypes.string.isRequired,
  startMessage: PropTypes.string,
  endMessage: PropTypes.string
}

MessageContent.defaultProps = {
  startMessage: WARNING_MESSAGE,
  endMessage: QUESTION_MARK
}
