import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import {
  USER_AUTH,
  CHANGE_PASS_ACTION,
  LOG_OUT_ACTION,
  PROFILE_ACTION,
  REFRESH_TOKEN_ACTION
} from './types'

export const REQUEST_AUTH = `${REQUEST_TYPE}_${USER_AUTH}`
export const RESPONSE_AUTH = `${RESPONSE_TYPE}_${USER_AUTH}`
export const RESPONSE_AUTH_ERROR = `${RESPONSE_ERROR_TYPE}_${USER_AUTH}`

export const REQUEST_CHANGE_PASS_ACTION = `${REQUEST_TYPE}_${CHANGE_PASS_ACTION}`
export const RESPONSE_CHANGE_PASS_ACTION = `${RESPONSE_TYPE}_${CHANGE_PASS_ACTION}`
export const RESPONSE_CHANGE_PASS_ACTION_ERROR = `${RESPONSE_ERROR_TYPE}_${CHANGE_PASS_ACTION}`

export const REQUEST_LOG_OUT_ACTION = `${REQUEST_TYPE}_${LOG_OUT_ACTION}`
export const RESPONSE_LOG_OUT_ACTION = `${RESPONSE_TYPE}_${LOG_OUT_ACTION}`
export const RESPONSE_LOG_OUT_ACTION_ERROR = `${RESPONSE_ERROR_TYPE}_${LOG_OUT_ACTION}`

export const REQUEST_PROFILE_ACTION = `${REQUEST_TYPE}_${PROFILE_ACTION}`
export const RESPONSE_PROFILE_ACTION = `${RESPONSE_TYPE}_${PROFILE_ACTION}`
export const RESPONSE_PROFILE_ACTION_ERROR = `${RESPONSE_ERROR_TYPE}_${PROFILE_ACTION}`

export const REQUEST_REFRESH_TOKEN_ACTION = `${REQUEST_TYPE}_${REFRESH_TOKEN_ACTION}`
export const RESPONSE_REFRESH_TOKEN_ACTION = `${RESPONSE_TYPE}_${REFRESH_TOKEN_ACTION}`
export const RESPONSE_REFRESH_TOKEN_ACTION_ERROR = `${RESPONSE_ERROR_TYPE}_${REFRESH_TOKEN_ACTION}`

export const RESET_DATA_STATE = `${RESET_STATE}_${USER_AUTH}`

export default {
  REQUEST_AUTH,
  RESPONSE_AUTH,
  RESPONSE_AUTH_ERROR,
  REQUEST_CHANGE_PASS_ACTION,
  RESPONSE_CHANGE_PASS_ACTION,
  RESPONSE_CHANGE_PASS_ACTION_ERROR,
  REQUEST_LOG_OUT_ACTION,
  RESPONSE_LOG_OUT_ACTION,
  RESPONSE_LOG_OUT_ACTION_ERROR,
  REQUEST_PROFILE_ACTION,
  RESPONSE_PROFILE_ACTION,
  RESPONSE_PROFILE_ACTION_ERROR,
  REQUEST_REFRESH_TOKEN_ACTION,
  RESPONSE_REFRESH_TOKEN_ACTION,
  RESPONSE_REFRESH_TOKEN_ACTION_ERROR,
  RESET_DATA_STATE
}
