/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'
import { PM_COMPANY, PROGRAM, PM_SYSTEM } from 'constants/searchTypes'
import { EXPERIENCE_LIGHT, EXPERIENCE_PRO } from 'constants/programTypes'
import { PRIMARY_ADDRESS } from 'helpers/types/dataTablesTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'
import { mapProgramType, mapSmartHost, mapEmailCadence } from 'modules/Programs/helpers'
import { getStatusValue } from './commonHelpers'

const defaultProgramsColumns = [
  {
    Header: 'PMC',
    accessor: 'pmCompany.name',
    id: buildSortPropertyByType('name', PM_COMPANY)
  },
  {
    Header: 'Program Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', PROGRAM)
  },
  {
    Header: 'PMS',
    accessor: 'pmsInfo.name',
    id: buildSortPropertyByType('name', PM_SYSTEM)
  },
  {
    Header: 'Unit Count',
    accessor: 'programInfo.unitCount',
    id: buildSortPropertyByType('unitCount', PROGRAM)
  },
  {
    Header: 'State/Province',
    accessor: 'addressState',
    id: buildSortPropertyByType('state', PRIMARY_ADDRESS)
  }
]

export const programsTableColumns = [
  ...defaultProgramsColumns,
  {
    Header: 'Commencement Date',
    accessor: 'commencementDate',
    id: buildSortPropertyByType('commencementDate', PROGRAM)
  },
  {
    Header: 'Renewal Date',
    accessor: 'renewalDate',
    id: buildSortPropertyByType('renewalDate', PROGRAM)
  },
  {
    Header: 'Status',
    accessor: getStatusValue,
    id: buildSortPropertyByType('status', PROGRAM),
    pathToProperty: 'status'
  }
]

export const workPageListTableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', PROGRAM)
  },
  {
    Header: 'Commencement Date',
    accessor: 'commencementDate',
    id: buildSortPropertyByType('commencementDate', PROGRAM)
  },
  {
    Header: 'Created By',
    accessor: 'metadata.createdBy',
    id: buildSortPropertyByType('createdBy', PROGRAM)
  }
]

export const programsByPmcColumns = [
  {
    Header: 'Program Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', PROGRAM)
  },
  {
    Header: 'PMC',
    accessor: 'pmCompany.name',
    id: buildSortPropertyByType('name', PM_COMPANY)
  },
  {
    Header: 'Unit Count',
    accessor: 'programInfo.unitCount',
    id: buildSortPropertyByType('unitCount', PROGRAM)
  },
  {
    Header: 'State/Province',
    accessor: 'addressState',
    id: buildSortPropertyByType('state', PRIMARY_ADDRESS)
  }
]

export const programsByMarketColumns = [
  {
    Header: 'Market',
    accessor: 'programInfo.market.name',
    sortable: false
  },
  {
    Header: 'PMC',
    accessor: 'pmCompany.name',
    id: buildSortPropertyByType('name', PM_COMPANY)
  },
  {
    Header: 'Program Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', PROGRAM)
  },
  {
    Header: 'PMS',
    accessor: 'pmsInfo.name',
    id: buildSortPropertyByType('name', PM_SYSTEM)
  },
  {
    Header: 'Unit Count',
    accessor: 'programInfo.unitCount',
    id: buildSortPropertyByType('unitCount', PROGRAM)
  },
  {
    Header: 'State/Province',
    accessor: 'addressState',
    id: buildSortPropertyByType('state', PRIMARY_ADDRESS)
  }
]

export const programsByLocationColumns = [
  {
    Header: 'PMC',
    accessor: 'pmcName',
    id: buildSortPropertyByType('pmc_name', PROGRAM)
  },
  {
    Header: 'Program Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', PROGRAM)
  },
  {
    Header: 'PMS',
    accessor: 'pmsName',
    id: buildSortPropertyByType('pms_name', PROGRAM)
  },
  {
    Header: 'Unit Count',
    accessor: 'unitCount',
    id: buildSortPropertyByType('unit_count', PROGRAM)
  },
  {
    Header: 'State/Province',
    accessor: 'state',
    id: buildSortPropertyByType('state', PROGRAM)
  },
  {
    Header: 'Status',
    accessor: 'status',
    id: buildSortPropertyByType('status', PROGRAM)
  }
]

export const programsPageTableColumns = [
  {
    Header: 'Type',
    accessor: value => mapProgramType(value.type),
    id: buildSortPropertyByType('type', PROGRAM),
    pathToProperty: 'type',
    valueMap: {
      [EXPERIENCE_LIGHT]: mapProgramType(EXPERIENCE_LIGHT),
      [EXPERIENCE_PRO]: mapProgramType(EXPERIENCE_PRO)
    }
  },
  ...defaultProgramsColumns,
  {
    Header: 'Commencement Date',
    accessor: 'commencementDate',
    id: buildSortPropertyByType('commencementDate', PROGRAM)
  },
  {
    Header: 'Renewal Date',
    accessor: 'renewalDate',
    id: buildSortPropertyByType('renewalDate', PROGRAM)
  },
  {
    Header: 'SmartHost',
    accessor: value => mapSmartHost(get(value, 'programInfo.smartHost.enabled')),
    id: 'program_smart_host.enabled',
    pathToProperty: 'programInfo.smartHost.enabled',
    valueMap: {
      true: 'Enabled',
      false: 'Disabled'
    }
  },
  {
    Header: 'Megaphone',
    accessor: value => mapEmailCadence(get(value, 'programInfo.cadenceEmail')),
    id: buildSortPropertyByType('cadenceEmail', PROGRAM),
    pathToProperty: 'programInfo.cadenceEmail',
    valueMap: {
      true: 'True',
      false: 'False'
    }
  },
  {
    Header: 'Status',
    accessor: getStatusValue,
    id: buildSortPropertyByType('status', PROGRAM),
    pathToProperty: 'status'
  }
]
