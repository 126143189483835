/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const NAME = 'PMC_ENTITY_LIFE_CYCLE'

const PMC_DEACTIVATION = `${NAME}_DEACTIVATION`
const PMC_ACTIVATION = `${NAME}_ACTIVATION`

const ACTIVATION_SUCCESS_MESSAGE = 'The Property Management Company has been activated.'

export default {
  NAME,
  PMC_DEACTIVATION,
  PMC_ACTIVATION,
  ACTIVATION_SUCCESS_MESSAGE
}
