/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const { REQUEST_EXPORT, RESPONSE_EXPORT, RESPONSE_ERROR_EXPORT, RESET_DATA_STATE } = helpers

export const initial = {}

export const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED
}

const buildState = (state, action) => data => ({
  ...state,
  // action.payload.key = Entity Key
  [action.payload.key]: { ...initialState, ...state[action.payload.key], ...data }
})

const reducer = (state = initial, action) => {
  const appendData = buildState(state, action)

  switch (action.type) {
    case REQUEST_EXPORT:
      return appendData({ dataState: DATA_STATE_REQUESTING })
    case RESPONSE_EXPORT:
      return appendData({ dataState: DATA_STATE_RECIEVED })
    case RESPONSE_ERROR_EXPORT:
      return appendData({ dataState: DATA_STATE_ERROR })
    case RESET_DATA_STATE: {
      const { [action.payload.key]: removedState, ...restState } = state
      return {
        ...restState
      }
    }
    default:
      return state
  }
}

export default filterActions(reducer, [
  REQUEST_EXPORT,
  RESPONSE_EXPORT,
  RESPONSE_ERROR_EXPORT,
  RESET_DATA_STATE
])
