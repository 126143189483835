/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_DEACTIVATION,
  RESPONSE_DEACTIVATION,
  RESPONSE_ERROR_DEACTIVATION,
  REQUEST_ACTIVATION,
  RESPONSE_ACTIVATION,
  RESPONSE_ERROR_ACTIVATION,
  RESET_DATA_STATE
} = helpers

export const initialState = {}

export const entityInitialState = {
  deactivationDataState: DATA_STATE_NOT_REQUESTED,
  activationDataState: DATA_STATE_NOT_REQUESTED
}

const apLifeCycleReducer = (state = initialState, action) => {
  switch (action.type) {
    //  deactivation
    case REQUEST_DEACTIVATION:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          deactivationDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_DEACTIVATION:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          deactivationDataState: DATA_STATE_RECIEVED
        }
      }
    case RESPONSE_ERROR_DEACTIVATION:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          deactivationDataState: DATA_STATE_ERROR
        }
      }
    //  activation
    case REQUEST_ACTIVATION:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          activationDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_ACTIVATION:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          activationDataState: DATA_STATE_RECIEVED
        }
      }
    case RESPONSE_ERROR_ACTIVATION:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          activationDataState: DATA_STATE_ERROR
        }
      }
    case RESET_DATA_STATE:
      return (() => {
        const { [action.payload.key]: removedState, ...restState } = state
        return {
          ...restState
        }
      })()
    default:
      return state
  }
}

export default filterActions(apLifeCycleReducer, [
  REQUEST_DEACTIVATION,
  RESPONSE_DEACTIVATION,
  RESPONSE_ERROR_DEACTIVATION,
  REQUEST_ACTIVATION,
  RESPONSE_ACTIVATION,
  RESPONSE_ERROR_ACTIVATION,
  RESET_DATA_STATE
])
