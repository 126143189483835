/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Badge.scss'

export function Badge(props) {
  const { value, maxValue, className } = props

  const count = Number(value) > Number(maxValue) ? `${maxValue}+` : value

  return <div className={classNames(styles.badgeWrapper, className)}>{count}</div>
}

Badge.propTypes = {
  value: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  maxValue: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  className: PropTypes.string
}

Badge.defaultProps = {
  value: 0,
  maxValue: 99,
  className: ''
}
