/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { updateAddress, createAddress } from 'services/http/address'
import { getCountriesTree } from 'services/http/locations'

import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_LOCATIONS_LIST,
  RESPONSE_LOCATIONS_LIST,
  RESPONSE_LOCATIONS_LIST_ERROR
} = helpers

const postAddressForm = (authKey, contractData = {}, withoutRequest = false) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  if (withoutRequest) {
    dispatch(
      actionCreater(RESPONSE_FORM, {
        responseData: null
      })
    )
    return 'postAddressForm success'
  }
  const request = await createAddress(authKey, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM, { responseData: request.data }))
    dispatch(send(message))
    return 'postAddressForm success'
  }
  return 'postAddressForm failed'
}
const putAddressForm = (authKey, contractData = {}, withoutRequest = false) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  if (withoutRequest) {
    dispatch(
      actionCreater(RESPONSE_FORM, {
        responseData: contractData.id
      })
    )
    return 'putAddressForm success'
  }
  const request = await updateAddress(authKey, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM, { responseData: contractData.id }))
    dispatch(send(message))
    return 'putAddressForm success'
  }
  return 'putAddressForm failed'
}

const getLocations = options => async dispatch => {
  dispatch(actionCreater(REQUEST_LOCATIONS_LIST))
  const request = await getCountriesTree(null, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_LOCATIONS_LIST_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LOCATIONS_LIST, request.data))
    return 'getLocations success'
  }
  return 'getLocations failed'
}

const resetForm = () => ({
  type: RESET_DATA_STATE
})

export default {
  postAddressForm,
  resetForm,
  putAddressForm,
  getLocations
}
