/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { LOCATIONS_URL } from 'endpoints'

export const buildURLForLocationTree = () => `${LOCATIONS_URL}/tree`

export const buildURLForLinkLocations = (childId = '0', parentId = '0') =>
  `${buildURLForLocationTree()}/child/${childId}/parent/${parentId}`
