/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'informed'
import { BasicButton } from '@xplorie/ui-commons'
import get from 'lodash/get'
import { openNewTab } from 'helpers/routeHelpers'
import { ORANGE_SCHEMA, SUBMIT_TITLE } from 'constants/buttonsOptions'
import { FormContent, ControlButtonsContainer } from 'components/FormComponents'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import {
  buildShowProgramsPath,
  buildShowAPPsPath,
  canUpdateMarketForm,
  canSeeAppsList
} from 'modules/Market'
import { PIECurtainLoader } from 'components/Loaders'
import { useUpdateSomeDataState, useDidMount, useLoading } from 'hooks'
import { getListOfFields } from './helpers'
import { VIEW_PROGRAMS_BUTTONS_TEXT, VIEW_APPS_BUTTON_TEXT } from './types'

export function MarketForm(props) {
  const {
    dataId,
    authUser,
    updateMarketForm,
    createMarketForm,
    dataState,
    formDataState,
    formData,
    onChange
  } = props
  const [isDisabledSubmit, setIsDisabledSubmitState] = useState(true)
  const formApi = useRef()
  const canViewApps = useMemo(() => canSeeAppsList(authUser), [authUser])
  const canUpdate = useMemo(() => canUpdateMarketForm(authUser, formData), [authUser, formData])
  const [isRequesting] = useLoading(dataState)
  const fields = useMemo(getListOfFields, [])
  const totalPrograms = useMemo(() => get(formData, 'totalPrograms'), [formData])
  const totalActivities = useMemo(() => get(formData, 'totalActivities'), [formData])
  const isDisabled = useMemo(() => isRequesting || !canUpdate, [canUpdate, isRequesting])
  const isDisabledViewPrograms = useMemo(() => !dataId || !totalPrograms, [dataId, totalPrograms])
  const isDisabledViewApps = useMemo(() => !dataId || !totalActivities, [dataId, totalActivities])

  const onGetFormApi = useCallback(api => {
    formApi.current = api
  }, [])

  const onSubmitHandler = useCallback(
    formValues => {
      const entityId = formValues && formValues.id
      const request = entityId ? updateMarketForm : createMarketForm
      request(formValues)
    },
    [createMarketForm, updateMarketForm]
  )

  const onClickSubmitHandler = useCallback(() => {
    formApi.current.submitForm()
  }, [])

  const onChangeHandler = useCallback(
    formState => {
      const notTouched = !Object.keys(formState.touched).length
      const isButtonDisabled = formState.invalid || formState.pristine || notTouched
      setIsDisabledSubmitState(isButtonDisabled)
      if (onChange) {
        onChange(formState)
      }
    },
    [onChange]
  )

  const onClickViewProgramsHandler = useCallback(() => {
    openNewTab(buildShowProgramsPath(dataId))
  }, [dataId])

  const onClickViewAppsHandler = useCallback(() => {
    openNewTab(buildShowAPPsPath(dataId))
  }, [dataId])

  const setFormValues = useCallback(values => {
    if (values && formApi.current) {
      formApi.current.setValues(values)
    }
  }, [])

  useDidMount(() => {
    if (formData) {
      setFormValues(formData)
    }
  })

  useUpdateSomeDataState(
    () => {
      setFormValues(formData)
    },
    [formDataState],
    DATA_STATE_RECIEVED
  )

  return (
    <Form getApi={onGetFormApi} onSubmit={onSubmitHandler} onChange={onChangeHandler}>
      {isRequesting && <PIECurtainLoader />}
      <FormContent disabled={isDisabled}>
        {fields.map(({ key, Component, props: fieldProps }) => (
          <Component key={key} {...fieldProps} />
        ))}
        <ControlButtonsContainer>
          <BasicButton
            colorSchema={ORANGE_SCHEMA}
            disabled={isDisabledSubmit}
            onClick={onClickSubmitHandler}
          >
            {SUBMIT_TITLE}
          </BasicButton>
          <BasicButton
            colorSchema={ORANGE_SCHEMA}
            onClick={onClickViewProgramsHandler}
            disabled={isDisabledViewPrograms}
          >
            {VIEW_PROGRAMS_BUTTONS_TEXT}
          </BasicButton>
          {canViewApps && (
            <BasicButton
              colorSchema={ORANGE_SCHEMA}
              onClick={onClickViewAppsHandler}
              disabled={isDisabledViewApps}
            >
              {VIEW_APPS_BUTTON_TEXT}
            </BasicButton>
          )}
        </ControlButtonsContainer>
      </FormContent>
    </Form>
  )
}
MarketForm.propTypes = {
  authUser: PropTypes.object.isRequired,
  updateMarketForm: PropTypes.func.isRequired,
  createMarketForm: PropTypes.func.isRequired,
  dataState: PropTypes.string.isRequired,
  formDataState: PropTypes.string.isRequired,
  formData: PropTypes.object,
  onChange: PropTypes.func,
  dataId: PropTypes.string
}
MarketForm.defaultProps = {
  formData: null,
  onChange: null,
  dataId: null
}
