/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildURLForGettingEntityByIdExtended } from 'services/http/helpers'
import { ACTIVITY_PROVIDER_URL } from 'endpoints'

export const createActivityProvider = (authKey, contractData) =>
  httpWrapper({
    url: ACTIVITY_PROVIDER_URL,
    method: 'post',
    data: contractData
  })

export const updateActivityProvider = (authKey, contractData) =>
  httpWrapper({
    url: ACTIVITY_PROVIDER_URL,
    method: 'put',
    data: contractData
  })

export const getActivityProviderById = (authKey, id, options) =>
  httpWrapper({
    url: buildURLForGettingEntityByIdExtended(ACTIVITY_PROVIDER_URL, id),
    method: 'get',
    options
  })

export const deactivateById = (id, data) =>
  httpWrapper({
    url: `${ACTIVITY_PROVIDER_URL}/${id}/deactivation`,
    method: 'put',
    data
  })

export const activateById = (id, options) =>
  httpWrapper({
    url: `${ACTIVITY_PROVIDER_URL}/${id}/activate`,
    method: 'put',
    options
  })

export const removeDeactivatingById = (id, options) =>
  httpWrapper({
    url: `${ACTIVITY_PROVIDER_URL}/${id}/deactivation`,
    method: 'delete',
    options
  })

export const updateDeactivationById = (id, data) =>
  httpWrapper({
    url: `${ACTIVITY_PROVIDER_URL}/${id}/deactivation/update`,
    method: 'put',
    data
  })
