/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'
import { actions, selectors } from 'reducers/marketDictionary/marketDictionaryList'

import { MarketList as MarketListComponent } from './MarketList'

const { getMarketListState, getMarketListWatchers } = selectors

function mapStateToProps(state) {
  const listState = getMarketListState(state)
  const listWatchers = getMarketListWatchers(state)
  return {
    listWatchers,
    dataState: listState.dataState,
    itemsPerPage: listState.itemsPerPage,
    itemsCount: listState.itemsCount,
    data: listState.data,
    pageNumber: listState.pageNumber
  }
}

function mapDispatchToProps(dispatch) {
  const { getMarketList } = actions
  return bindActionCreators({ onGetData: getMarketList }, dispatch)
}

export const MarketList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MarketListComponent)
