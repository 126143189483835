/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { campaignsTableColumns, buildDefaultButtonColumn } from 'helpers/dataTablesHelpers'
import { EditButton } from './AdditionalColumns'

export const buildTableColumns = ({ authUser }) =>
  campaignsTableColumns.concat({
    ...buildDefaultButtonColumn(),
    id: 'edit-button',
    disableSortBy: true,
    disableResizing: true,
    Cell: ({ row }) => <EditButton authUser={authUser} data={row.original} />
  })
