/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { combineReducers } from 'redux'

import activityProductResellSearch from './activityProductResellSearch'
import activityResellHowTo from './activityResellHowTo'
import activityProductHistory from './activityProductHistory'

export default combineReducers({
  activityProductResellSearch,
  activityResellHowTo,
  activityProductHistory
})
