/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { PIELoader } from 'components/Loaders'
import { BasicModal } from 'components/Modals'
import { UploadPad } from 'shared'
import classNames from 'classnames'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, SMALLER_SIZE, CANCEL_TITLE } from 'constants/buttonsOptions'
import styles from './UploadModal.scss'

export function UploadModal({
  title,
  getApi,
  isLoading,
  message,
  onChange,
  validate,
  accept,
  progress,
  onCancel,
  isInitiallyOpen
}) {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen)
  const [error, setError] = useState(null)

  const errorClassName = useMemo(() => ({ [styles.error]: Boolean(error) }), [error])

  const bottomMessage = useMemo(() => error || message, [error, message])

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const onCancelHandler = useCallback(() => {
    if (onCancel) {
      onCancel()
    }
  }, [onCancel])

  const onCloseHandler = useCallback(() => {
    if (isLoading) {
      onCancelHandler()
      return
    }
    toggleModal()
  }, [isLoading, onCancelHandler, toggleModal])

  useEffect(() => {
    if (getApi) {
      getApi({ toggleModal, setError })
    }
  }, [getApi, toggleModal])

  return (
    <BasicModal
      isOpen={isOpen}
      title={title}
      onClose={onCloseHandler}
      wrapperClassName={styles.modalWrapper}
      renderBody={() => (
        <div className={styles.wrapper}>
          {isLoading ? (
            <div className={styles.progressWrapper}>
              <span>{progress ? `Uploading(${progress || 0}%)...` : 'Uploading...'}</span>
              <PIELoader />
              {onCancel && (
                <BasicButton
                  colorSchema={ORANGE_SCHEMA}
                  size={SMALLER_SIZE}
                  onClick={onCancelHandler}
                >
                  {CANCEL_TITLE}
                </BasicButton>
              )}
            </div>
          ) : (
            <div className={styles.uploadWrapper}>
              <div className={styles.uploadPad}>
                <UploadPad
                  className={classNames(styles.panel, errorClassName)}
                  validate={validate}
                  onChange={onChange}
                  accept={accept}
                />
              </div>
              <div className={styles.bottomContent}>
                {bottomMessage && (
                  <span className={classNames(styles.message, errorClassName)}>
                    {bottomMessage}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    />
  )
}
UploadModal.propTypes = {
  getApi: PropTypes.func,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  message: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.func,
  accept: PropTypes.string,
  progress: PropTypes.number,
  onCancel: PropTypes.func,
  isInitiallyOpen: PropTypes.bool
}
UploadModal.defaultProps = {
  getApi: null,
  title: 'Upload File',
  message: null,
  isLoading: false,
  onChange: null,
  validate: null,
  accept: null,
  progress: null,
  onCancel: null,
  isInitiallyOpen: false
}
