/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'

import { SimpleModalWrapper } from 'components/SimpleModalWrapper'
import { BasicButton } from '@xplorie/ui-commons'

import styles from './UnsavedDataModal.scss'

import { CANCEL_BUTTON_TEXT } from './types'

export const UnsavedDataModal = props => {
  const { actionCallback, cancelCallback, confirmationButtonText, modalMessage } = props
  return (
    props.originalData && (
      <SimpleModalWrapper cancelHandler={cancelCallback}>
        <div className={`notification is-danger ${styles.wrapper}`}>
          <div className={styles.content}>{modalMessage}</div>
          <div className={styles.buttons}>
            <BasicButton onClick={actionCallback} className="is-warning">
              {confirmationButtonText}
            </BasicButton>
            <BasicButton onClick={cancelCallback}>{CANCEL_BUTTON_TEXT}</BasicButton>
          </div>
        </div>
      </SimpleModalWrapper>
    )
  )
}

UnsavedDataModal.propTypes = {
  originalData: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  actionCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  modalMessage: PropTypes.string.isRequired,
  confirmationButtonText: PropTypes.string
}

UnsavedDataModal.defaultProps = {
  confirmationButtonText: 'Yes'
}
