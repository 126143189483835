/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'informed'
import isFunction from 'lodash/isFunction'
import {
  FormContent,
  TextField,
  RangePickerField,
  SwitchField,
  CalendarField
} from 'components/FormComponents'
import { HORIZONTAL_ALIGN } from 'constants/alignTypes'
import { CirclePlusIcon } from 'icons'
import classNames from 'classnames'
import { TimePeriodsList } from './TimePeriodsList/TimePeriodsList'
import { TimePeriodsPopover } from './TimePeriodsPopover'
import styles from './styles.scss'
import {
  RANGE_FIELD_LABEL,
  RANGE_FIELD_PLACEHOLDER,
  RANGE_FIELD_FIELD,
  ADD_DAY_FIELD_LABEL,
  ADD_DAY_FIELD,
  NOTE_FIELD_LABEL,
  NOTE_FIELD_PLACEHOLDER,
  NOTE_FIELD_FIELD,
  TIME_SLOTS_LABEL,
  TIME_PERIODS_FIELD,
  DATE_FIELD
} from './types'
/**
 *
 * @param {Object} param0
 * @param {boolean} param0.disabled
 * @param {boolean} param0.isEdit
 * @param {*} param0.children
 * @param {Object} param0.scheduleApi
 * @param {Function} param0.scheduleApi.changeAllDay
 * @param {Function} param0.scheduleApi.getTimePeriod
 * @param {Function} param0.scheduleApi.addTimePeriod
 * @param {Function} param0.scheduleApi.editTimePeriod
 * @param {Function} param0.scheduleApi.removeTimePeriod
 * @param {Function} param0.scheduleApi.canSeeRemoveTimePeriod
 * @returns
 */
export function ScheduleForm({ disabled, isEdit, children, scheduleApi, ...rest }) {
  const initialValues = {
    startTime: null,
    endTime: null,
    weekDays: null
  }

  return (
    <Form {...rest}>
      {formProps => {
        const { formState } = formProps
        const { allDay, date } = formState.values
        return (
          <FormContent disabled={disabled}>
            {date ? (
              <CalendarField
                label={RANGE_FIELD_LABEL}
                placeholder={RANGE_FIELD_PLACEHOLDER}
                fieldName={DATE_FIELD}
                disabled
                isRequired
              />
            ) : (
              <RangePickerField
                label={RANGE_FIELD_LABEL}
                placeholder={RANGE_FIELD_PLACEHOLDER}
                fieldName={RANGE_FIELD_FIELD}
                onChange={scheduleApi.setTimePeriodDays}
                isRequired
              />
            )}
            <div className={styles.timePeriodWrapper}>
              <div className={styles.timePeriodLabelWrapper}>
                <span className={styles.label}>{TIME_SLOTS_LABEL}</span>
                {!isEdit && (
                  <TimePeriodsPopover
                    onSubmit={scheduleApi.addTimePeriod}
                    initialValues={initialValues}
                    disabled={allDay}
                  >
                    <CirclePlusIcon
                      width="1.1rem"
                      height="1.1rem"
                      className={classNames({ [styles.disabledPopover]: Boolean(allDay) })}
                    />
                  </TimePeriodsPopover>
                )}
              </div>
              <SwitchField
                label={ADD_DAY_FIELD_LABEL}
                field={ADD_DAY_FIELD}
                fieldProps={{
                  align: HORIZONTAL_ALIGN,
                  classNameLabel: styles.allDayLabel
                }}
                onChange={scheduleApi.changeAllDay}
              />
            </div>
            <TimePeriodsList
              timePeriods={scheduleApi.getTimePeriod()}
              onEditTimePeriod={scheduleApi.editTimePeriod}
              onRemoveTimePeriod={scheduleApi.removeTimePeriod}
              canSeeRemoveTimePeriod={scheduleApi.canSeeRemoveTimePeriod}
              formState={formState.values}
              getWeekSelectorMapper={scheduleApi.getWeekSelectorMapper}
            />
            <TextField fieldName={TIME_PERIODS_FIELD} type="hidden" />
            <TextField
              label={NOTE_FIELD_LABEL}
              placeholder={NOTE_FIELD_PLACEHOLDER}
              fieldName={NOTE_FIELD_FIELD}
            />
            {isFunction(children) ? children(formProps) : children}
          </FormContent>
        )
      }}
    </Form>
  )
}

ScheduleForm.propTypes = {
  disabled: PropTypes.bool,
  isEdit: PropTypes.bool,
  scheduleApi: PropTypes.shape({
    changeAllDay: PropTypes.func.isRequired,
    getTimePeriod: PropTypes.func.isRequired,
    addTimePeriod: PropTypes.func.isRequired,
    editTimePeriod: PropTypes.func.isRequired,
    removeTimePeriod: PropTypes.func.isRequired,
    canSeeRemoveTimePeriod: PropTypes.func.isRequired,
    setTimePeriodDays: PropTypes.func.isRequired,
    getWeekSelectorMapper: PropTypes.func.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
}

ScheduleForm.defaultProps = {
  isEdit: false,
  disabled: false,
  children: null
}
