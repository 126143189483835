/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions as campaignsAction } from 'reducers/campaigns/campaignsList'

import Campaigns from './Campaigns'

const mapStateToProps = state => ({
  panelStack: state.editQueue.panelStack
})

const mapDispatchToProps = dispatch => {
  const { getCampaignsList } = campaignsAction
  return bindActionCreators({ onGetData: getCampaignsList }, dispatch)
}

const CampaignsConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Campaigns)

export default CampaignsConnect
