/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { httpWrapper, buildURLForGettingEntityById } from 'services/http/helpers'
import {
  ACCOUNT_URL,
  ACCOUNT_PASSWORD_URL,
  LOGOUT_URL,
  SETTINGS_URL,
  ACCOUNT_PASSWORD_RECOVERY_URL
} from 'endpoints'

export const createAccount = (authKey, contractData) =>
  httpWrapper({
    url: ACCOUNT_URL,
    method: 'post',
    data: contractData
  })

export const updateAccount = (authKey, contractData) =>
  httpWrapper({
    url: ACCOUNT_URL,
    method: 'put',
    data: contractData
  })

export const getAccountById = (authKey, id) =>
  httpWrapper({
    url: buildURLForGettingEntityById(ACCOUNT_URL, id),
    method: 'get'
  })

export const logOut = (authKey, params) =>
  httpWrapper({
    url: LOGOUT_URL,
    method: 'post',
    params
  })

export const changePassword = (authKey, contractData) =>
  httpWrapper({
    url: ACCOUNT_PASSWORD_URL,
    method: 'put',
    data: contractData
  })

export const resetPassword = (authKey, id) =>
  httpWrapper({
    url: `${buildURLForGettingEntityById(ACCOUNT_URL, id)}/password/reset`,
    method: 'put'
  })

export const sendRecoveryPasswordLink = data =>
  httpWrapper(
    {
      url: `${ACCOUNT_PASSWORD_RECOVERY_URL}`,
      method: 'post',
      data
    },
    { secure: false, isAuthRequest: true }
  )

export const checkRecoveryToken = params =>
  httpWrapper(
    {
      url: `${ACCOUNT_PASSWORD_RECOVERY_URL}`,
      method: 'get',
      params
    },
    { secure: false, isAuthRequest: true }
  )

export const recoverPassword = data =>
  httpWrapper(
    {
      url: `${ACCOUNT_PASSWORD_RECOVERY_URL}`,
      method: 'put',
      data
    },
    { secure: false, isAuthRequest: true }
  )

export const getSettingsByAccountId = (authKey, id) =>
  httpWrapper({
    url: `${buildURLForGettingEntityById(SETTINGS_URL, id)}`,
    method: 'get'
  })

export const updateSettingsByAccountId = (id, contractData) =>
  httpWrapper({
    url: `${buildURLForGettingEntityById(SETTINGS_URL, id)}`,
    method: 'put',
    data: contractData
  })

export const activateAccountById = (accountId, contractData) =>
  httpWrapper({
    url: `${ACCOUNT_URL}/${accountId}/activate`,
    method: 'put',
    data: contractData
  })

export const deactivateAccountById = (accountId, contractData) =>
  httpWrapper({
    url: `${ACCOUNT_URL}/${accountId}/deactivate`,
    method: 'put',
    data: contractData
  })
