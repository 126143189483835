/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { ADMIN_LEVEL } from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'

export function canUpdateRMSForm(authUser) {
  return isActionAllowed(ADMIN_LEVEL, authUser)
}
export function canDeleteRMS(authUser) {
  return isActionAllowed(ADMIN_LEVEL, authUser)
}
