/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { combineReducers } from 'redux'

import eligibilityForm from './eligibilityForm'
import eligibilityList from './eligibilityList'

// PMC Eligibility Reducers
import pmcEligibilityList from './pmcEligibilityList'
import pmcEligibilityForm from './pmcEligibilityForm'
import pmcEligibilityParametersList from './pmcEligibilityParametersList'
import pmcProgramsEligibilityValues from './pmcProgramsEligibilityValues'

// Programs Eligibility Reducers
import programEligibilityParamList from './programEligibilityParamList'
import programEligibilityValuesList from './programEligibilityValuesList'
import programEligibilityForm from './programEligibilityForm'
import programPmcEligibilityValuesList from './programPmcEligibilityValuesList'

// PMS Eligibility Reducers
import pmsAssignedEligibilityList from './pmsAssignedEligibilityList'

export default combineReducers({
  eligibilityForm,
  eligibilityList,
  pmcEligibilityList,
  pmcEligibilityForm,
  pmcEligibilityParametersList,
  programEligibilityForm,
  programEligibilityValuesList,
  programEligibilityParamList,
  programPmcEligibilityValuesList,
  pmsAssignedEligibilityList,
  pmcProgramsEligibilityValues
})
