/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { getAPLifeCycleState } from 'reducers/activityProvider/apLifeCycle'

export function getListWatchers(state) {
  const lifeCycleState = getAPLifeCycleState(state)

  return [
    state.activityProviderForm.dataState,
    ...Object.keys(lifeCycleState).reduce(
      (acc, apId) => [
        ...acc,
        lifeCycleState[apId].activationDataState,
        lifeCycleState[apId].deactivationDataState
      ],
      []
    )
  ]
}
