/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { buildListParams, httpWrapper } from 'services/http/helpers'
import {
  CAMPAIGNS_DATA_URL,
  CAMPAIGN_DATA_URL,
  CAMPAIGN_DATA_BY_ID_URL,
  CAMPAIGN_ACTIVITIES_BY_ID_URL,
  CAMPAIGN_ACTIVATE_URL,
  CAMPAIGN_DEACTIVATE_URL,
  CAMPAIGN_DRAFT_URL
} from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'

export const searchCampaigns = options =>
  httpWrapper({
    url: CAMPAIGNS_DATA_URL,
    method: 'get',
    params: buildListParams(options)
  })

export const createCampaign = data =>
  httpWrapper({
    url: CAMPAIGN_DATA_URL,
    method: 'post',
    data
  })

export const updateCampaign = data =>
  httpWrapper({
    url: CAMPAIGN_DATA_URL,
    method: 'put',
    data
  })

export const getCampaignById = id =>
  httpWrapper({
    url: buildPath(CAMPAIGN_DATA_BY_ID_URL, { id }),
    method: 'get'
  })

export const getCampaignActivitiesById = id =>
  httpWrapper({
    url: buildPath(CAMPAIGN_ACTIVITIES_BY_ID_URL, { id }),
    method: 'get'
  })

export const saveCampaignActivitiesById = (id, data) =>
  httpWrapper({
    url: buildPath(CAMPAIGN_ACTIVITIES_BY_ID_URL, { id }),
    method: 'put',
    data
  })

export const activateCampaign = id =>
  httpWrapper({
    url: buildPath(CAMPAIGN_ACTIVATE_URL, { id }),
    method: 'put'
  })

export const deactivateCampaign = id =>
  httpWrapper({
    url: buildPath(CAMPAIGN_DEACTIVATE_URL, { id }),
    method: 'put'
  })

export const makeDraftCampaign = id =>
  httpWrapper({
    url: buildPath(CAMPAIGN_DRAFT_URL, { id }),
    method: 'put'
  })
