/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withLoader } from 'decorators'
import { DATA_STATE_RECIEVED, DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { useDataStatesComparison, useUpdateSomeDataState } from 'hooks/hooks'
import classNames from 'classnames'
import styles from './ActivityCategoryList.scss'
import { ActivityCategoryContainer } from './ActivityCategoryContainer'
import { ActivityCategoryContextMenu } from './ActivityCategoryContextMenu'

const ActivityCategoryContainerWithLoader = withLoader(ActivityCategoryContainer)
export function ActivityCategoryList(props) {
  const {
    dataState,
    data,
    onGetData,
    authUser,
    removeActivityCategoryById,
    updateListDataStates,
    updateTreeState,
    treeState
  } = props
  const listWrapperRef = useRef(null)
  const [hasMenuOpened, setMenuOpenedState] = useState(false)
  const [menuProps, setMenuState] = useState({ pageX: 0, pageY: 0 })
  const [categoriesTreeData, setCategoriesTreeState] = useState([])
  const isLoading = dataState === DATA_STATE_REQUESTING

  const setMenuOpenedStateHandler = (value = {}, event = {}) => {
    if (value.parentId) {
      return
    }
    setMenuState({ ...value, pageX: event.pageX, pageY: event.pageY })
    setMenuOpenedState(true)
  }

  useUpdateSomeDataState(() => {
    setCategoriesTreeState(data)
  }, [dataState])

  const onCloseHandler = () => {
    setMenuOpenedState(false)
  }

  useEffect(() => {
    onGetData(authUser)
  }, [authUser, onGetData])

  useDataStatesComparison(
    () => {
      onGetData(authUser)
    },
    updateListDataStates,
    DATA_STATE_RECIEVED
  )

  return (
    <div
      ref={listWrapperRef}
      className={classNames(styles.listWrapper, {
        [styles.isLoading]: isLoading
      })}
      onScroll={onCloseHandler}
    >
      <ActivityCategoryContainerWithLoader
        menuProps={menuProps}
        isLoading={isLoading}
        data={categoriesTreeData}
        hasMenuOpened={hasMenuOpened}
        treeState={treeState}
        updateTreeState={updateTreeState}
        setMenuOpenedState={setMenuOpenedStateHandler}
      />
      {hasMenuOpened && (
        <ActivityCategoryContextMenu
          {...menuProps}
          onClose={onCloseHandler}
          onRemoveCategory={removeActivityCategoryById}
        />
      )}
    </div>
  )
}
ActivityCategoryList.defaultProps = {
  updateTreeState: () => {},
  treeState: {},
  data: []
}

ActivityCategoryList.propTypes = {
  updateTreeState: PropTypes.func,
  dataState: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  onGetData: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired,
  removeActivityCategoryById: PropTypes.func.isRequired,
  updateListDataStates: PropTypes.array.isRequired,
  treeState: PropTypes.object
}
