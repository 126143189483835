/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */
import { STATUS_ARCHIVED, STATUS_ACTIVE, STATUS_DRAFT } from 'constants/entityStatus'

import { buildSearchColumn, AND_OPERATOR, LIKE_START_WITH_MODE } from 'helpers/searchHelpers'

import { PM_COMPANY, ACTIVITY, ACTIVITY_PROVIDER, PROGRAM, DISCOUNT } from 'constants/searchTypes'
import { defaultActivityFilters } from 'containers/ActivityProducts'

import { ActivitySearchResults } from './ActivitySearchResults'
import { APSearchResult } from './APSearchResult'
import { ProgramsSearchResults } from './ProgramsSearchResults'
import { PMCSearchResult } from './PMCSearchResult'
import { DiscountsSearchResult } from './DiscountsSearchResult'

export const getResultComponent = searchType => {
  switch (searchType) {
    case PM_COMPANY:
      return PMCSearchResult
    case ACTIVITY:
      return ActivitySearchResults
    case ACTIVITY_PROVIDER:
      return APSearchResult
    case PROGRAM:
      return ProgramsSearchResults
    case DISCOUNT:
      return DiscountsSearchResult
    default:
      return null
  }
}

export const getInitialFilters = searchType => {
  switch (searchType) {
    case PM_COMPANY:
      return [
        buildSearchColumn({
          entity: PM_COMPANY,
          field: 'status',
          keyword: STATUS_ACTIVE,
          operator: AND_OPERATOR,
          mode: LIKE_START_WITH_MODE
        })
      ]
    case ACTIVITY_PROVIDER:
      return [
        buildSearchColumn({
          entity: ACTIVITY_PROVIDER,
          field: 'status',
          keyword: STATUS_ACTIVE,
          operator: AND_OPERATOR,
          mode: LIKE_START_WITH_MODE
        })
      ]
    case ACTIVITY:
      return defaultActivityFilters
    case PROGRAM:
      return [
        buildSearchColumn({
          entity: PROGRAM,
          field: 'status',
          keyword: STATUS_DRAFT,
          ignore: true,
          operator: AND_OPERATOR
        }),
        buildSearchColumn({
          entity: PROGRAM,
          field: 'status',
          keyword: STATUS_ARCHIVED,
          ignore: true,
          operator: AND_OPERATOR
        }),
        buildSearchColumn({
          entity: PROGRAM,
          field: 'status',
          keyword: STATUS_ACTIVE,
          operator: AND_OPERATOR,
          mode: LIKE_START_WITH_MODE
        })
      ]
    default:
      return []
  }
}
