/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import { FORMAT } from 'constants/date'

const moment = extendMoment(Moment)

export const isShownAddButtonBuilder = ({ availabilityValue, originalValue }) => {
  if (!isArray(availabilityValue) || !isArray(originalValue)) {
    return false
  }
  const preparedOriginalValue = originalValue.filter(value => isObject(value))
  const preparedAvailabilityValue = availabilityValue.filter(value => isObject(value))
  return (
    preparedAvailabilityValue.length &&
    preparedOriginalValue &&
    preparedOriginalValue.length &&
    preparedOriginalValue.length === preparedAvailabilityValue.length &&
    preparedOriginalValue.every(value =>
      [value.startDate, value.endDate].every(subValue => !isEmpty(subValue))
    )
  )
}

export const isShownRemoveButtonBuilder = ({ availabilityValue }) => {
  if (!isArray(availabilityValue)) {
    return false
  }
  return availabilityValue.filter(value => isObject(value)).length > 1
}

export const buildAvailabilityKey = (value, index) => index

export const buildCalendarModificators = ({ originalValue }) => {
  if (!isArray(originalValue)) {
    return { disabledDays: { before: moment().toDate() } }
  }
  const selectedDates = originalValue
    .filter(value => isObject(value))
    .map(value => ({
      from: moment(value.startDate, FORMAT) ? moment(value.startDate, FORMAT).toDate() : null,
      to: moment(value.endDate, FORMAT).isValid() ? moment(value.endDate, FORMAT).toDate() : null
    }))
  return {
    modifiersStyles: {
      selectedPeriod: {
        color: '#b2d235'
      },
      selected: {
        backgroundColor: '#00d1b2',
        color: 'black'
      }
    },
    modifiers: { selectedPeriod: selectedDates },
    disabledDays: [{ before: moment().toDate() }]
  }
}

export const rangeHasOverlaps = ({ originalValue, index }) => {
  const ranges = [...originalValue]
  const currentElement = ranges[index]
  ranges.splice(index, 1)
  if (
    !currentElement ||
    !isObject(currentElement) ||
    !currentElement.startDate ||
    !currentElement.endDate
  ) {
    return false
  }
  const currentRange = moment.range(
    moment(currentElement.startDate, FORMAT),
    moment(currentElement.endDate, FORMAT)
  )
  return ranges
    .filter(value => isObject(value) && value.startDate && value.endDate)
    .map(value => moment.range(moment(value.startDate, FORMAT), moment(value.endDate, FORMAT)))
    .some(value => currentRange.overlaps(value))
}
