/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'
import { anyNumberValidation, blankValidateWithMessage } from 'helpers/formHelpers'
import { DECIMAL_VALIDATION_MESSAGE } from 'helpers/types/formHelpers'
import { RATE_VALUE_BLANK_VALIDATION_MESSAGE, START_VALUE_FIELD } from './types'

const rateBlankValidate = blankValidateWithMessage(RATE_VALUE_BLANK_VALIDATION_MESSAGE)

export function newRateValidate(value, values) {
  const startValue = get(values, START_VALUE_FIELD, null)

  if (startValue) {
    return rateBlankValidate(value)
  }

  if (!value && value !== 0) {
    return null
  }

  return anyNumberValidation(value) || Number(value) < 0.01 || Number(value) > 100000
    ? DECIMAL_VALIDATION_MESSAGE
    : null
}
