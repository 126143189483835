/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { BasicButton } from '@xplorie/ui-commons'
import { YELLOW_GREEN_SCHEMA } from 'constants/buttonsOptions'

export function SearchTab(props) {
  const { children, currentType, type, onChange, ...rest } = props
  const isActive = currentType === type

  const onClickHandler = useCallback(
    e => {
      onChange(type, e)
    },
    [onChange, type]
  )

  return (
    <BasicButton
      className="search-type-btn"
      colorSchema={YELLOW_GREEN_SCHEMA}
      onClick={onClickHandler}
      disabled={isActive}
      isActive={isActive}
      {...rest}
    >
      {children}
    </BasicButton>
  )
}

SearchTab.propTypes = {
  children: PropTypes.node.isRequired,
  currentType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

SearchTab.defaultProps = {}
