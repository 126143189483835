import { httpWrapper, buildListParams } from 'services/http/helpers'
import { DISCOUNT_HISTORY_URL, DISCOUNT_HISTORY_YEARS_URL } from 'endpoints'

import { buildPath } from 'helpers/httpHelpers'

export const getDiscountHistoryById = (discountId, year, params) =>
  httpWrapper({
    url: buildPath(DISCOUNT_HISTORY_URL, { discountId, year }),
    method: 'get',
    params: buildListParams(params)
  })

export const getDiscountHistoryYears = discountId =>
  httpWrapper({
    url: buildPath(DISCOUNT_HISTORY_YEARS_URL, { discountId }),
    method: 'get'
  })
