/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import types from './types'

const { NAVIGATION_TYPE } = types

const UPDATE_NAVIGATION_TYPE_STATE = `UPDATE_${NAVIGATION_TYPE}`

export default {
  UPDATE_NAVIGATION_TYPE_STATE
}
