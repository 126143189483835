import { httpWrapper, buildListParams } from 'services/http/helpers'
import { ACTIVITY_PRODUCTS_HISTORY_URL, ACTIVITY_PRODUCTS_HISTORY_YEARS_URL } from 'endpoints'

import { buildPath } from 'helpers/httpHelpers'

export const getActivityProductHistoryById = (activityProductId, year, params) =>
  httpWrapper({
    url: buildPath(ACTIVITY_PRODUCTS_HISTORY_URL, { activityProductId, year }),
    method: 'get',
    params: buildListParams(params)
  })

export const getActivityProductHistoryYears = activityProductId =>
  httpWrapper({
    url: buildPath(ACTIVITY_PRODUCTS_HISTORY_YEARS_URL, { activityProductId }),
    method: 'get'
  })
