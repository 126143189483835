/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { FormFilter } from 'components/FormComponents'
import { ButtonsWrapper, HeaderWrapper } from 'components/Wrappers'
import { useProgramSlider } from 'hooks'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { RIGHT_ALIGN } from 'constants/alignTypes'
import { PIECurtainLoader } from 'components/Loaders'
import { ContentWithNav } from 'components/ContentWithNav'
import { ProgramsList } from 'modules/Programs/ProgramsList'
import { PROGRAM } from 'constants/searchTypes'
import { programsPageTableColumns } from 'helpers/dataTablesHelpers'
import { DotsButtonWithExport } from 'modules/ExportEntity'

import withAuthorization from 'helpers/withAuthorization'

import { TableField, FilteredSearch, useFilteredSearch } from 'modules/FilteredSearch'
import { buildFilters, canAddProgram } from './helpers'
import {
  ADD_ENTITY_BUTTON,
  PROGRAM_FORM_FILTERS,
  PROGRAM_INITIAL_FILTERS,
  TITLE_PAGE,
  EXPORT_FILENAME
} from './types'

import styles from './Programs.scss'

function Programs(props) {
  const { authUser, onGetData } = props
  const {
    onGetApi,
    onGetData: onGetDataHandler,
    onChange,
    getParams,
    getFilters
  } = useFilteredSearch({
    onGetData,
    buildFilters
  })

  const { open, isCreating } = useProgramSlider()

  const onCreateNewProgram = useCallback(() => {
    open()
  }, [open])

  const buildDotsButtonOptions = useCallback(() => {
    const options = []

    if (canAddProgram(authUser)) {
      options.push({
        label: ADD_ENTITY_BUTTON,
        props: { onClick: onCreateNewProgram }
      })
    }

    return options
  }, [authUser, onCreateNewProgram])

  return (
    <ContentWithNav>
      {isCreating && <PIECurtainLoader />}
      <FilteredSearch
        getApi={onGetApi}
        onChange={onChange}
        initialFilters={PROGRAM_INITIAL_FILTERS}
      >
        <section className={styles.customWrapper}>
          <HeaderWrapper title={TITLE_PAGE} />
          <div className={styles.containerWrapper}>
            <ButtonsWrapper className={styles.buttonsPanelWithFilters} align={RIGHT_ALIGN}>
              <Column>
                <DotsButtonWithExport
                  options={buildDotsButtonOptions()}
                  onGetFilters={getFilters}
                  onGetParams={getParams}
                  entity={PROGRAM}
                  columns={programsPageTableColumns}
                  filename={EXPORT_FILENAME}
                />
              </Column>
              <Column>
                <div className={styles.filterBarWrapper}>
                  <FormFilter filters={PROGRAM_FORM_FILTERS} />
                </div>
              </Column>
            </ButtonsWrapper>
            <TableField
              onGetData={onGetDataHandler}
              Component={ProgramsList}
              // TODO: Need adjust and refactor it with reimplement this functionality.
              needFirstGetData={false}
              baseColumns={programsPageTableColumns}
            />
          </div>
        </section>
      </FilteredSearch>
    </ContentWithNav>
  )
}

Programs.propTypes = {
  authUser: PropTypes.object.isRequired,
  onGetData: PropTypes.func.isRequired
}

export default withAuthorization(Programs)
