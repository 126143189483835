/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo } from 'react'
import { CollapseBox } from '@xplorie/ui-commons'
import { Form } from 'informed'
import { CustomNestedField, FormContent } from 'components/FormComponents'
import isEmpty from 'lodash/isEmpty'

import { useDealApi } from '../hooks'

import { AVAILABILITIES_FIELD, SECTION_TITLE } from './types'
import styles from './styles.scss'
import { DiscountPeriodField } from './DiscountPeriodField'
import { useDealPeriodsApi, useDealPeriodsState } from './hooks'
import DiscountPeriodButtons from './DiscountPeriodButtons'
import { DiscountCalendar } from './DiscountCalendar'
import { useDealOptionsState } from '../DiscountOptions/hooks'
import { getDealAvailabilities } from './helpers'
import { isPeriodSpecificActivity } from '../helpers'

export function DiscountPeriods() {
  const optionsFormState = useDealOptionsState()
  const periodsFormState = useDealPeriodsState()
  const dealApi = useDealApi()
  const dealPeriodsApi = useDealPeriodsApi()

  const availabilities = getDealAvailabilities(periodsFormState.values)
  const items = dealPeriodsApi.getPeriods(availabilities)

  const isNewDiscount = !dealApi.getId()
  const isPeriodSpecific = isPeriodSpecificActivity(optionsFormState.values)
  const isDisabled = useMemo(() => !dealApi.canEditForm() || !dealApi.canEditPeriods(), [dealApi])

  const canAddPeriod =
    dealApi.canEditPeriods() &&
    items.every(item => !isEmpty(item.startDate) && !isEmpty(item.endDate))
  const canRemovePeriod = useMemo(
    () => !isDisabled && items.filter(item => item.included).length > 1,
    [isDisabled, items]
  )

  return (
    <Form getApi={dealPeriodsApi.getApi} onChange={dealPeriodsApi.onChange} className={styles.form}>
      <CollapseBox className={styles.section} title={SECTION_TITLE} isOpen softExpand>
        <DiscountCalendar />
        <FormContent disabled={isDisabled}>
          <CustomNestedField field={AVAILABILITIES_FIELD}>
            {items.map(item => (
              <DiscountPeriodField
                key={item.id}
                item={item}
                canRemove={canRemovePeriod}
                isNewDiscount={isNewDiscount}
                isPeriodSpecific={isPeriodSpecific}
              />
            ))}
          </CustomNestedField>
          {!isPeriodSpecific && <DiscountPeriodButtons canAddPeriod={canAddPeriod} />}
        </FormContent>
      </CollapseBox>
    </Form>
  )
}
