/*
 * COPYRIGHT:     Copyright © 2023 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import get from 'lodash/get'
import { isFutureSeason } from 'modules/Programs/ProgramSeasons/SeasonItem/helpers'
import { CHARGE_PER_NIGHT_VALUE } from 'modules/Programs/SeasonForm/types'

export const updateSeason = async (dataId, season, onUpdate, saveRateAdjustments) => {
  const seasonId = get(season, 'data.id')
  const seasonData = get(season, 'data')

  await saveRateAdjustments(seasonId, dataId, [], false)
  onUpdate(dataId, seasonData, false)
}

export const isSeasonShouldBeUpdated = season => {
  const activities = get(season, 'data.activities')
  const xplorieFee = get(season, 'data.xplorieData.xplorieFee')
  const xplorieFeeType = get(season, 'data.xplorieData.chargeMethod')
  return (
    (activities && activities.length) ||
    xplorieFee ||
    (xplorieFeeType && xplorieFeeType !== CHARGE_PER_NIGHT_VALUE)
  )
}

export const prepareSeasonForUpdate = season => {
  const seasonData = get(season, 'data')
  const seasonXplorieData = get(season, 'data.xplorieData')

  return {
    ...season,
    data: {
      ...seasonData,
      activities: [],
      xplorieData: {
        ...seasonXplorieData,
        xplorieFee: null,
        chargeMethod: CHARGE_PER_NIGHT_VALUE
      }
    }
  }
}

export const updateSeasonsAfterProgramTypeChange = async (
  dataId,
  seasonsState = [],
  onUpdate,
  saveRateAdjustments,
  isOnBoarded
) => {
  const seasons = isOnBoarded
    ? seasonsState
    : seasonsState.filter(season => {
        const startDate = get(season, 'data.startDate')
        return isFutureSeason(startDate)
      })

  if (!seasons || !seasons.length) {
    return null
  }

  const seasonsToUpdate = seasons.filter(isSeasonShouldBeUpdated)

  if (!seasonsToUpdate || !seasonsToUpdate.length) {
    return null
  }

  const updatedSeasons = seasonsToUpdate.map(prepareSeasonForUpdate)

  updatedSeasons.forEach(season => {
    updateSeason(dataId, season, onUpdate, saveRateAdjustments)
  })

  return null
}
