/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'
import programFormHelpers from '../programsForm/helpers'

const {
  RESET_DATA_STATE,
  REQUEST_SEASONS_LIST,
  RESPONSE_SEASONS_LIST,
  RESPONSE_ERROR_SEASONS_LIST,
  REQUEST_SEASON_REMOVE_ACTION,
  RESPONSE_SEASON_REMOVE_ACTION,
  RESPONSE_ERROR_SEASON_REMOVE_ACTION
} = helpers

export const initialState = {}
export const initialSeasonState = {
  responseData: null,
  seasons: null,
  dataState: DATA_STATE_NOT_REQUESTED,
  seasonRemoveState: DATA_STATE_NOT_REQUESTED
}

const programSeasonListReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SEASONS_LIST:
      return {
        ...state,
        [action.payload.key]: {
          ...initialSeasonState,
          ...state[action.payload.key],
          dataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_ERROR_SEASONS_LIST:
      return {
        ...state,
        [action.payload.key]: {
          ...initialSeasonState,
          ...state[action.payload.key],
          dataState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_SEASONS_LIST:
      return {
        ...state,
        [action.payload.key]: {
          ...initialSeasonState,
          ...state[action.payload.key],
          seasons: action.payload.data.data,
          dataState: DATA_STATE_RECIEVED
        }
      }
    case REQUEST_SEASON_REMOVE_ACTION:
      return {
        ...state,
        [action.payload.key]: {
          ...initialSeasonState,
          ...state[action.payload.key],
          seasonRemoveState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_ERROR_SEASON_REMOVE_ACTION:
      return {
        ...state,
        [action.payload.key]: {
          ...initialSeasonState,
          ...state[action.payload.key],
          seasonRemoveState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_SEASON_REMOVE_ACTION:
      return {
        ...state,
        [action.payload.key]: {
          ...initialSeasonState,
          ...state[action.payload.key],
          seasonRemoveState: DATA_STATE_RECIEVED,
          responseData: action.payload.responseData
        }
      }
    case RESET_DATA_STATE:
    case programFormHelpers.RESET_DATA_STATE:
      return (() => {
        const { [action.payload.key]: removedSeasons, ...restSeasonsList } = state
        return { ...restSeasonsList }
      })()
    default:
      return state
  }
}

export default filterActions(programSeasonListReducer, [
  RESET_DATA_STATE,
  REQUEST_SEASONS_LIST,
  RESPONSE_SEASONS_LIST,
  RESPONSE_ERROR_SEASONS_LIST,
  REQUEST_SEASON_REMOVE_ACTION,
  RESPONSE_SEASON_REMOVE_ACTION,
  RESPONSE_ERROR_SEASON_REMOVE_ACTION,
  programFormHelpers.RESET_DATA_STATE
])
