/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'
import { actions as searchExportActions, selectors } from 'reducers/exportEntity/searchExport'
import {
  actions as discountsExportActions,
  selectors as discountsExportselectors
} from 'reducers/exportEntity/discountsExport'

import { DotsButtonWithExport as DotsButtonWithExportComponent } from './DotsButtonWithExport'

const { exportEntityData } = searchExportActions
const { exportDiscounts } = discountsExportActions
const { getSearchExportStateByEntityKey } = selectors
const { getDiscountsExportState } = discountsExportselectors

const mapStateToProps = (state, { entity }) => {
  const exportState = getSearchExportStateByEntityKey(state, entity)
  const exportDiscountsState = getDiscountsExportState(state)
  return {
    exportDataState: exportState.dataState,
    exportDiscountsState: exportDiscountsState.dataState
  }
}

export const mapDispatchToProps = (dispatch, { entity }) =>
  bindActionCreators(
    {
      exportEntityData: exportEntityData.bind(null, entity),
      exportDiscounts
    },
    dispatch
  )

export const DotsButtonWithExport = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DotsButtonWithExportComponent)
