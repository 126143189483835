/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import {
  actions as campaignsActions,
  getCampaignsListState,
  getListWatchers
} from 'reducers/campaigns/campaignsList'
import { withAuthContext } from 'helpers/withAuthContext'

import { CampaignsListComponent } from './CampaignsList'

export const mapStateToProps = state => {
  const campaignsListState = getCampaignsListState(state)

  return {
    dataState: campaignsListState.dataState,
    data: campaignsListState.data,
    itemsCount: campaignsListState.itemsCount,
    itemsPerPage: campaignsListState.itemsPerPage,
    pageNumber: campaignsListState.pageNumber,
    listWatchers: getListWatchers(state)
  }
}

export const mapDispatchToProps = dispatch => {
  const { getCampaignsList } = campaignsActions

  return bindActionCreators(
    {
      onGetData: getCampaignsList
    },
    dispatch
  )
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps
})

export const CampaignsList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )
)(CampaignsListComponent)
