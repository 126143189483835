/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ContentWithNav } from 'components/ContentWithNav'
import { FormFilter } from 'components/FormComponents'
import { HeaderWrapper, ButtonsWrapper } from 'components/Wrappers'
import { ActivityProviderList } from 'modules/ActivityProviders/ActivityProviderList'
import { ACTIVITY_PROVIDER } from 'constants/searchTypes'
import { ACTIVITY_PROVIDER as ACTIVITY_PROVIDER_SLIDER } from 'constants/formCodes'
import { activityProvidersTableColumns } from 'helpers/dataTablesHelpers'
import { DotsButtonWithExport } from 'modules/ExportEntity'
import withAuthorization from 'helpers/withAuthorization'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { RIGHT_ALIGN } from 'constants/alignTypes'
import { useSliderApi } from 'hooks/useSliderApi'
import { FilteredSearch, TableField, useFilteredSearch } from 'modules/FilteredSearch'

import {
  TITLE_PAGE,
  TEXT_BTN_ADD_NEW,
  AP_FORM_FILTERS,
  AP_INITIAL_FILTERS,
  EXPORT_FILENAME
} from './types'
import { buildFilters, canAddAP } from './helpers'

import styles from './ActivityProviders.scss'

function ActivityProviders(props) {
  const { authUser, onGetData } = props
  const { onGetApi, onChange, getFilters, getParams } = useFilteredSearch({
    onGetData,
    buildFilters
  })
  const activityProviderSlider = useSliderApi(ACTIVITY_PROVIDER_SLIDER)

  const onAddNew = useCallback(() => {
    activityProviderSlider.open()
  }, [activityProviderSlider])

  const buildDotsButtonOptions = useCallback(() => {
    const options = []

    if (canAddAP(authUser)) {
      options.push({
        label: TEXT_BTN_ADD_NEW,
        props: { onClick: onAddNew }
      })
    }

    return options
  }, [onAddNew, authUser])

  return (
    <ContentWithNav>
      <FilteredSearch getApi={onGetApi} onChange={onChange} initialFilters={AP_INITIAL_FILTERS}>
        <section className={styles.customWrapper}>
          <HeaderWrapper title={TITLE_PAGE} />
          <div className={styles.containerWrapper}>
            <ButtonsWrapper className={styles.buttonsPanelWithFilters} align={RIGHT_ALIGN}>
              <Column className={styles.customColumnWrapper}>
                <DotsButtonWithExport
                  options={buildDotsButtonOptions()}
                  onGetFilters={getFilters}
                  onGetParams={getParams}
                  entity={ACTIVITY_PROVIDER}
                  columns={activityProvidersTableColumns}
                  filename={EXPORT_FILENAME}
                />
              </Column>
              <Column>
                <div className={styles.filterBarWrapper}>
                  <FormFilter filters={AP_FORM_FILTERS} />
                </div>
              </Column>
            </ButtonsWrapper>
            <TableField
              Component={ActivityProviderList}
              // TODO: Need adjust and refactor it with reimplement this functionality.
              needFirstGetData={false}
            />
          </div>
        </section>
      </FilteredSearch>
    </ContentWithNav>
  )
}

ActivityProviders.propTypes = {
  onGetData: PropTypes.func.isRequired,
  authUser: PropTypes.func.isRequired
}

export default withAuthorization(ActivityProviders)
