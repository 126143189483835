/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_DOWNLOAD,
  RESPONSE_DOWNLOAD,
  RESPONSE_ERROR_DOWNLOAD,
  REQUEST_REMOVE,
  RESPONSE_REMOVE,
  RESPONSE_ERROR_REMOVE,
  REQUEST_UPLOAD,
  RESPONSE_UPLOAD,
  RESPONSE_ERROR_UPLOAD,
  RESET_DATA_STATE
} = helpers

export const initialState = {}

export const howToInitialState = {
  downloadDataState: DATA_STATE_NOT_REQUESTED,
  removeDataState: DATA_STATE_NOT_REQUESTED,
  uploadDataState: DATA_STATE_NOT_REQUESTED
}

const buildData = (state, action) => data => ({
  ...state,
  [action.payload.key]: {
    ...howToInitialState,
    ...state[action.payload.key],
    ...data
  }
})

const howToReducer = (state = initialState, action) => {
  const append = buildData(state, action)
  switch (action.type) {
    // upload file
    case REQUEST_UPLOAD:
      return append({ uploadDataState: DATA_STATE_REQUESTING })
    case RESPONSE_UPLOAD:
      return append({
        uploadDataState: DATA_STATE_RECIEVED
      })
    case RESPONSE_ERROR_UPLOAD:
      return append({ uploadDataState: DATA_STATE_ERROR })

    // download file
    case REQUEST_DOWNLOAD:
      return append({ downloadDataState: DATA_STATE_REQUESTING })
    case RESPONSE_DOWNLOAD:
      return append({
        downloadDataState: DATA_STATE_RECIEVED
      })
    case RESPONSE_ERROR_DOWNLOAD:
      return append({ downloadDataState: DATA_STATE_ERROR })

    // remove file
    case REQUEST_REMOVE:
      return append({ removeDataState: DATA_STATE_REQUESTING })
    case RESPONSE_REMOVE:
      return append({
        removeDataState: DATA_STATE_RECIEVED
      })
    case RESPONSE_ERROR_REMOVE:
      return append({ removeDataState: DATA_STATE_ERROR })
    case RESET_DATA_STATE: {
      const { [action.payload.key]: removedState, ...restState } = state
      return {
        ...restState
      }
    }
    default:
      return state
  }
}

export default filterActions(howToReducer, [
  REQUEST_DOWNLOAD,
  RESPONSE_DOWNLOAD,
  RESPONSE_ERROR_DOWNLOAD,
  REQUEST_REMOVE,
  RESPONSE_REMOVE,
  RESPONSE_ERROR_REMOVE,
  REQUEST_UPLOAD,
  RESPONSE_UPLOAD,
  RESPONSE_ERROR_UPLOAD,
  RESET_DATA_STATE
])
