/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getSchedules } from 'services/http/schedules'
import moment from 'moment'
import { FORMAT } from 'constants/date'
import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST, RESET_DATA_STATE } = helpers

/**
 *
 * @param {string} id - app id
 * @param {Object} period - closures in period
 * @param {Date} period.start - start date
 * @param {Date} period.end - end date
 * @returns {Promise}
 */
const getClosuresByPeriod = (id, period) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_LIST, { key }))

  try {
    const request = await getSchedules(id, {
      startDate: moment(period.start).format(FORMAT),
      endDate: moment(period.end).format(FORMAT)
    })
    dispatch(actionCreater(RESPONSE_LIST, { data: request.data, key }))
    return Promise.resolve(request.data)
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST, { key }))

    return Promise.reject(err)
  }
}

const resetList = id => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key: id }))

export default {
  getClosuresByPeriod,
  resetList
}
