/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const SECTION_TITLE = 'Discount Periods'

export const START_DATE_LABEL = 'Start Date'
export const START_DATE_PLACEHOLDER = 'Start Date'
export const START_DATE_FIELD = 'startDate'

export const END_DATE_LABEL = 'End Date'
export const END_DATE_PLACEHOLDER = 'End Date'
export const END_DATE_FIELD = 'endDate'

export const DAYS_LABEL = 'Valid On'
export const DAYS_FIELD = 'days'

export const MIN_RESERVATION_DURATION_LABEL = 'Min Reservation Duration'
export const MAX_RESERVATION_DURATION_LABEL = 'Max Reservation Duration'
export const MIN_RESERVATION_DURATION_PLACEHOLDER = 'Min Reservation Duration'
export const MAX_RESERVATION_DURATION_PLACEHOLDER = 'Max Reservation Duration'
export const MIN_RESERVATION_DURATION_FIELD = 'from'
export const MAX_RESERVATION_DURATION_FIELD = 'to'

export const AVAILABILITIES_FIELD = 'availabilities'

export const NO_END_DATE_VALUE = '12/31/9999'

export const NEW_DISCOUNT_PREFIX = 'NEW'
