/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { withFormContext } from 'components/FormComponents/helpers'
import { withBlankValidation } from 'helpers/formHelpers'
import { TextEditor } from './TextEditor'

export function TextEditorFieldComponent(props) {
  const { fieldName, validate, isRequired, ...rest } = props

  const validateStrategy = isRequired ? withBlankValidation(validate) : validate

  return (
    <TextEditor
      field={fieldName}
      validate={validateStrategy}
      isRequired={isRequired}
      validateOnChange
      {...rest}
    />
  )
}

TextEditorFieldComponent.propTypes = {
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.func,
  isRequired: PropTypes.bool
}

TextEditorFieldComponent.defaultProps = {
  validate: () => null,
  isRequired: false
}

export const TextEditorField = withFormContext(TextEditorFieldComponent)
