/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import debounce from 'lodash/debounce'
import isFunction from 'lodash/isFunction'

import { buildCountCharactersError, canFetch } from './helpers'

import styles from './TypeheadSearch.scss'

const renderSuggestion = suggestion => <span>{suggestion.name}</span>

export class TypeaheadSearch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.initValue
    }

    this.onSuggestionsFetchRequested = debounce(this.onSuggestionsFetchRequested, props.delay)
  }

  componentDidUpdate(prevProps) {
    const { initValue } = this.props
    if (initValue !== prevProps.initValue) {
      this.setState({ value: initValue })
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    const { suggestionsFetch, minCountCharacters } = this.props
    if (isFunction(suggestionsFetch) && canFetch(value, minCountCharacters)) {
      suggestionsFetch(value)
    }
  }

  onChangeHandler = (event, { newValue }) => {
    this.setState({
      value: newValue
    })
  }

  onSelectSuggestionHandler = (event, { suggestion }) => {
    const { onSelect, isCleared, onBeforeChange } = this.props

    if (onBeforeChange) {
      onBeforeChange(suggestion, onSelect, newValue => this.setState({ value: newValue }))
    } else {
      onSelect(suggestion)
    }
    if (isCleared) {
      this.setState({ value: '' })
    }
  }

  getSuggestionValue = suggestion => {
    const { propertyName } = this.props
    return suggestion[propertyName]
  }

  shouldRenderSuggestions = value => {
    const { minCountCharacters } = this.props
    return canFetch(value, minCountCharacters)
  }

  render() {
    const { value } = this.state
    const {
      suggestions,
      clear,
      placeholder,
      minCountCharacters,
      renderer,
      disabled,
      children
    } = this.props
    const inputProps = {
      placeholder,
      value,
      onChange: this.onChangeHandler,
      className: styles.input,
      disabled
    }

    return (
      <>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionSelected={this.onSelectSuggestionHandler}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={renderer || renderSuggestion}
          inputProps={inputProps}
          onSuggestionsClearRequested={clear}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          theme={styles}
        />
        {value.length > 0 &&
          !canFetch(value, minCountCharacters) &&
          buildCountCharactersError(minCountCharacters)}
        {children}
      </>
    )
  }
}

TypeaheadSearch.defaultProps = {
  suggestionsFetch: () => {},
  clear: () => {},
  onSelect: () => {},
  onBeforeChange: null,
  initValue: '',
  suggestions: [],
  children: null,
  placeholder: '',
  delay: 300,
  propertyName: 'name',
  minCountCharacters: 1,
  renderer: null,
  isCleared: false,
  disabled: false
}

TypeaheadSearch.propTypes = {
  suggestions: PropTypes.oneOfType([PropTypes.any]),
  clear: PropTypes.func,
  children: PropTypes.element,
  placeholder: PropTypes.string,
  minCountCharacters: PropTypes.number,
  renderer: PropTypes.func,
  onSelect: PropTypes.func,
  onBeforeChange: PropTypes.func,
  propertyName: PropTypes.string,
  suggestionsFetch: PropTypes.func,
  initValue: PropTypes.string,
  delay: PropTypes.number,
  isCleared: PropTypes.bool,
  disabled: PropTypes.bool
}
