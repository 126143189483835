/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'

import { SubmitWithCommentModal, UnsavedDataModal } from 'components/FormComponents'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'

import { CONFIRMATION_BUTTON_TEXT, MODAL_MESSAGE_UNSAVED } from './types'

export class ProgramButtonComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showCommentModal: false, showUnsavedModal: false }
  }

  onActionCommentModalHandler = value => {
    const { onClick } = this.props
    onClick(value)
    this.hideCommentModal()
  }

  onActionUnsavedModal = action => e => {
    action(e)
    this.cancelUnsavedModal()
  }

  showCommentModal = () => this.setState({ showCommentModal: true })

  hideCommentModal = () => this.setState({ showCommentModal: false })

  showUnsavedModal = () => this.setState({ showUnsavedModal: true })

  cancelUnsavedModal = () => this.setState({ showUnsavedModal: false })

  render() {
    const {
      isVisible = true,
      ButtonComponent,
      children,
      withComment,
      checkUnsavedData,
      onClick,
      formState: { touched },
      ...rest
    } = this.props
    const { showCommentModal, showUnsavedModal } = this.state
    let onClickAction = onClick
    if (checkUnsavedData && Object.keys(touched).length > 0) {
      onClickAction = this.showUnsavedModal
    } else if (withComment) {
      onClickAction = this.showCommentModal
    }
    const unsavedAction = withComment ? this.showCommentModal : onClick
    return (
      <>
        {isVisible && (
          <ButtonComponent {...rest} colorSchema={ORANGE_SCHEMA} onClick={onClickAction}>
            {children}
          </ButtonComponent>
        )}
        <SubmitWithCommentModal
          onCancel={this.hideCommentModal}
          onAction={this.onActionCommentModalHandler}
          isOpen={showCommentModal}
        />
        <UnsavedDataModal
          originalData={showUnsavedModal}
          actionCallback={this.onActionUnsavedModal(unsavedAction)}
          cancelCallback={this.cancelUnsavedModal}
          confirmationButtonText={CONFIRMATION_BUTTON_TEXT}
          modalMessage={MODAL_MESSAGE_UNSAVED}
        />
      </>
    )
  }
}

ProgramButtonComponent.propTypes = {
  isVisible: PropTypes.bool,
  withComment: PropTypes.bool,
  ButtonComponent: PropTypes.any.isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func,
  formApi: PropTypes.object,
  formState: PropTypes.object,
  checkUnsavedData: PropTypes.bool
}

ProgramButtonComponent.defaultProps = {
  isVisible: true,
  children: <span>Simple text</span>,
  onClick: () => null,
  withComment: false,
  checkUnsavedData: false,
  formApi: null,
  formState: {}
}

export const ProgramButton = ProgramButtonComponent
