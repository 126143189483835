/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

const LIST_NAME = 'PROGRAM_HISTORY_LIST'
const GET_YEARS = `${LIST_NAME}_GET_YEARS`

export default {
  LIST_NAME,
  GET_YEARS
}
