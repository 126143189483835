/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { ACTIVITY_PROVIDER, ACTIVITY_CATEGORY } from 'constants/searchTypes'
import { PRIMARY_ADDRESS } from 'helpers/types/dataTablesTypes'
import {
  buildSortPropertyByType,
  convertBooleanToString,
  prepareDestinationsToView
} from 'helpers/tableHelpers'
import get from 'lodash/get'
import { findFirstPrimaryAddress } from 'helpers/formHelpers'

import { getStatusValue } from './commonHelpers'

export const activityProvidersDefaultColumns = [
  {
    Header: 'City',
    accessor: value => {
      const addressees = get(value, 'addresses.addressValues')
      const address = findFirstPrimaryAddress(addressees)
      return get(address, 'address.city')
    },
    id: buildSortPropertyByType('city', PRIMARY_ADDRESS),
    pathToProperty: 'addresses.addressValues[0].address.city'
  },
  {
    Header: 'State/Province',
    accessor: value => {
      const addressees = get(value, 'addresses.addressValues')
      const address = findFirstPrimaryAddress(addressees)
      return get(address, 'address.state')
    },
    id: buildSortPropertyByType('state', PRIMARY_ADDRESS),
    pathToProperty: 'addresses.addressValues[0].address.state'
  }
]

export const apsColumns = [
  {
    Header: 'AP',
    accessor: 'name',
    id: buildSortPropertyByType('name', ACTIVITY_PROVIDER),
    sortable: false
  },
  ...activityProvidersDefaultColumns
].map(column => ({ ...column, sortable: false }))

export const activityProvidersTableColumns = [
  {
    Header: 'Provider Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', ACTIVITY_PROVIDER)
  },
  ...activityProvidersDefaultColumns,
  {
    Header: 'Status',
    accessor: getStatusValue,
    id: buildSortPropertyByType('status', ACTIVITY_PROVIDER),
    pathToProperty: 'status'
  }
]

export const apsByActivityTagColumns = [
  ...apsColumns,
  {
    Header: 'Status',
    accessor: getStatusValue,
    id: buildSortPropertyByType('status', ACTIVITY_PROVIDER),
    pathToProperty: 'status',
    sortable: false
  }
]

export const apsByActivityCategoryColumns = [
  {
    Header: 'Activity Category',
    accessor: value => {
      const activityCategories = get(value, 'activityCategories')
      if (activityCategories.length) {
        return activityCategories[0].name
      }
      return 'None'
    },
    id: buildSortPropertyByType('name', ACTIVITY_CATEGORY),
    sortable: false
  },
  ...apsColumns
]

export const activityProviderReportsColumns = [
  { Header: 'Activity Provider', accessor: 'activityProviderName' },
  { Header: 'APP name', accessor: 'activityName' },
  { Header: 'APP Short  name', accessor: 'activityShortName' },
  { Header: 'APP type', accessor: 'activityType' },
  { Header: 'Agreement type', accessor: 'activityAgreementType' },
  { Header: 'Activity Category', accessor: 'activityCategoryName' },
  { Header: 'Offer', accessor: 'activityOffer' },
  { Header: 'Destination State', accessor: 'destinationState' },
  {
    Header: 'Destinations',
    accessor: 'destinations',
    Cell: props => {
      const value = get(props, 'value', null)
      return prepareDestinationsToView(value)
    }
  },
  { Header: 'Market', accessor: 'marketName' },
  { Header: 'RMS name', accessor: 'rmsName' },
  { Header: 'Ticket format', accessor: 'ticketFormat' },
  { Header: 'Redemption method', accessor: 'redemptionMethod' },
  {
    Header: 'Stripe payment enabled',
    accessor: 'stripeEnabled',
    Cell: props => {
      const value = get(props, 'value', null)
      return convertBooleanToString(value)
    }
  },
  {
    Header: 'Date specific?',
    accessor: 'dateSpecific',
    Cell: props => {
      const value = get(props, 'value', null)
      return convertBooleanToString(value)
    }
  },
  {
    Header: 'Date Period Specific?',
    accessor: 'periodSpecific',
    Cell: props => {
      const value = get(props, 'value', null)
      return convertBooleanToString(value)
    }
  },
  {
    Header: 'Time specific?',
    accessor: 'timeSpecific',
    Cell: props => {
      const value = get(props, 'value', null)
      return convertBooleanToString(value)
    }
  },
  {
    Header: 'Confirmation # required?',
    accessor: 'confirmationNumberRequired',
    Cell: props => {
      const value = get(props, 'value', null)
      return convertBooleanToString(value)
    }
  },
  {
    Header: 'Deactivate retail?',
    accessor: 'retailDeactivated',
    Cell: props => {
      const value = get(props, 'value', null)
      return convertBooleanToString(value)
    }
  },
  {
    Header: 'Registration page?',
    accessor: 'externalRegistrationRequired',
    Cell: props => {
      const value = get(props, 'value', null)
      return convertBooleanToString(value)
    }
  },
  { Header: 'Cancellation Policy', accessor: 'cancellationPolicy' },
  { Header: 'BCC Email', accessor: 'extraBcc' },
  { Header: 'Taxes', accessor: 'taxes' },
  { Header: 'Taxable fees (value)', accessor: 'fees' },
  { Header: 'Taxable fees (%/$)', accessor: 'percentageFees' },
  { Header: 'Taxable fees (Per Order/Per Ticket)', accessor: 'perTicketFee' },
  { Header: 'Non-Taxable fees (value)', accessor: 'nonTaxableFees' },
  { Header: 'Non-Taxable fees (%/$)', accessor: 'percentageNonTaxableFees' },
  { Header: 'Platform Date Text', accessor: 'activityPlatformVerbiage' },
  { Header: 'Hours of operation', accessor: 'activityHoursOfOperation' },
  { Header: 'Activity Module', accessor: 'activityModule' },
  { Header: 'Tile name', accessor: 'tile' },
  { Header: 'The accordion', accessor: 'tileAccordion' },
  { Header: 'Smart block', accessor: 'activitySmartBlock' },
  { Header: 'XE photo', accessor: 'activityXePhoto' },
  { Header: 'N2K/CSR Photo', accessor: 'activityCsrPhoto' },
  { Header: 'Activity Square Image URL', accessor: 'activitySquareImageUrl' },
  { Header: 'CSR Page', accessor: 'activityCsrPage' },
  { Header: 'Customer N2K Page', accessor: 'activityNeedToKnowPage' },
  { Header: 'Long description', accessor: 'activityLongDescription' },
  { Header: 'Short description', accessor: 'activityShortDescription' },
  { Header: 'AP website URL', accessor: 'activityProductLogoLink' },
  { Header: 'Address Line 1', accessor: 'addressLine' },
  { Header: 'Address Line 2', accessor: 'addressLine2' },
  { Header: 'Country', accessor: 'addressCountry' },
  { Header: 'State/Province', accessor: 'addressState' },
  { Header: 'City', accessor: 'addressCity' },
  { Header: 'Zip', accessor: 'addressZip' },
  { Header: 'Latitude', accessor: 'addressLatitude' },
  { Header: 'Longitude', accessor: 'addressLongitude' },
  { Header: 'Status', accessor: 'activityStatus' }
]
