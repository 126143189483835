/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const EligibilitySlider = loadable(
  () =>
    import(
      /* webpackChunkName: 'EligibilitySlider', webpackPrefetch: true */ './EligibilitySlider'
    ),
  {
    resolveComponent: components => components.EligibilitySlider
  }
)

export { EligibilitySlider }
export * from './EligibilityForm'
export * from './context'
export * from './EligibilityList'
export * from './AssignedEligibilityTable'
export * from './TableFormEligibility'
export * from './helpers'
export * from './EligibilityFieldsForm'
export * from './ExtendedEligibilityForm'
