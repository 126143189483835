/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { buildAccessKey } from './helpers'
import { programInitialState } from './reducer'

export const getProgramUnitsStateByPmcIdAndProgramId = (state, pmcId, programId) =>
  state.units.programUnits[buildAccessKey(pmcId, programId)] || programInitialState

export const getProgramUnitsState = state => state.units.programUnits
