/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

const FORM_NAME = 'LOCATION_MARKETING_FORM'
const GET_FORM_DATA = `GET_FORM_DATA_${FORM_NAME}`

export default {
  FORM_NAME,
  GET_FORM_DATA
}
