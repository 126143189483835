/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const LIST_NAME = 'PMC_PROGRAM_UNITS_LIST'
const EXPORT_PMC_UNITS = `${LIST_NAME}_EXPORT_PMC_UNITS`

export default {
  LIST_NAME,
  EXPORT_PMC_UNITS
}
