/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const NOTIF_SEND = 'NOTIF_SEND'
export const NOTIF_DISMISS = 'NOTIF_DISMISS'
export const NOTIF_CLEAR = 'NOTIF_CLEAR'

/**
 * Publish a notification,
 * - if `dismissAfter` was set, the notification will be auto dismissed after the given period.
 * - if id wasn't specified, a time based id will be generated.``
 */
export function notifSend(notif) {
  const payload = { ...notif }
  if (!payload.id) {
    payload.id = new Date().getTime()
  }
  return dispatch => {
    dispatch({ type: NOTIF_SEND, payload })

    if (payload.dismissAfter) {
      setTimeout(() => {
        dispatch({
          type: NOTIF_DISMISS,
          payload: payload.id
        })
      }, payload.dismissAfter)
    }
  }
}

/**
 * Dismiss a notification by the given id.
 */
export function notifDismiss(id) {
  return { type: NOTIF_DISMISS, payload: id }
}

/**
 * Clear all notifications
 */
export function notifClear() {
  return { type: NOTIF_CLEAR }
}
