/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'
import { RoundRadioButton, BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, LINK_SCHEMA, SMALLER_SIZE } from 'constants/buttonsOptions'
import { SelectField, Field, FileField } from 'components/FormComponents'
import { barcodeOptions } from 'mockData/ticketGroup'
import {
  BARCODE_TYPE_LABEL,
  SELECT_FILE_CODE_LIST_LABEL,
  TICKET_TEMPLATE_LABEL,
  DEFAULT_HEADER_TEMPLATE,
  SELECT_FILE_CODE_LIST_PLACEHOLDER,
  BARCODE_TYPE_PLACEHOLDER,
  GENERATE_TEXT,
  SELECT_TEMPLATE_PLACEHOLDER,
  DEFAULT_TEMPLATE_RADIO_TYPE,
  CUSTOM_TEMPLATE_RADIO_TYPE,
  CUSTOM_HEADER_TEMPLATE,
  DOWNLOAD_TEMPLATE,
  REMOVE_TEMPLATE,
  TEMPLATE_LOADED_LABEL,
  DOWNLOAD_TEMPLATE_LABEL
} from './types'
import styles from './UploadBarcodes.scss'

export function UploadBarcodes({
  formApi,
  isDisabled,
  onSubmit,
  downloadCustomTemplate,
  removeCustomTemplate,
  downloadBarcodeTemplate
}) {
  const formValues = formApi.getState().values
  const [currentTemplateType, setCurrentTemplateType] = useState(DEFAULT_TEMPLATE_RADIO_TYPE)
  const [template, setTemplate] = useState(null)
  const [codeFile, setCodeFile] = useState(null)
  const isFileSelected = Boolean(codeFile)
  const customTemplate = get(formValues, 'customTemplate')
  const groupName = get(formValues, 'name')
  const isBarcodeTypeDisabled = (formValues.id && formValues.barcodeType) || isDisabled
  const isDisabledGenerateButton =
    !formValues.barcodeType || !groupName || isDisabled || !isFileSelected
  const selectedCustomTemplate = currentTemplateType === CUSTOM_TEMPLATE_RADIO_TYPE
  const isDisabledCustomTemplate = !formValues.barcodeType || isDisabled || !selectedCustomTemplate

  const onChangeTemplateSwitcher = useCallback(newKey => {
    setCurrentTemplateType(newKey)
  }, [])

  const onChangeFilesHandler = useCallback(selectedFiles => {
    const selectedFile = get(selectedFiles, '0')
    setCodeFile(selectedFile)
  }, [])

  const onChangeTemplateHandler = useCallback(selectedFiles => {
    const selectedFile = get(selectedFiles, '0')
    setTemplate(selectedFile)
  }, [])

  const onDownloadTemplate = useCallback(() => {
    downloadBarcodeTemplate()
  }, [downloadBarcodeTemplate])

  const onSubmitHandler = () => {
    if (!isDisabledGenerateButton) {
      onSubmit({
        template: selectedCustomTemplate ? template : null,
        codeFile,
        useDefault: currentTemplateType === DEFAULT_TEMPLATE_RADIO_TYPE
      })
    }
  }

  useEffect(() => {
    const type = customTemplate ? CUSTOM_TEMPLATE_RADIO_TYPE : DEFAULT_TEMPLATE_RADIO_TYPE
    setCurrentTemplateType(type)
  }, [customTemplate])

  return (
    <>
      <SelectField
        label={BARCODE_TYPE_LABEL}
        fieldName="barcodeType"
        placeholder={BARCODE_TYPE_PLACEHOLDER}
        options={barcodeOptions}
        disabled={isBarcodeTypeDisabled}
        fieldProps={{
          className: classNames('hero select', styles.inputWrapper),
          classNameLabel: styles.inputLabel,
          classNameWrapper: styles.fieldWrapper,
          error: null
        }}
        isRequired
      />
      <FileField
        id="select-code-list"
        label={SELECT_FILE_CODE_LIST_LABEL}
        fieldName="files"
        placeholder={SELECT_FILE_CODE_LIST_PLACEHOLDER}
        fieldProps={{
          className: styles.inputWrapper,
          classNameLabel: styles.inputLabel,
          classNameWrapper: styles.fieldWrapper,
          // eslint-disable-next-line react/prop-types
          renderInner: ({ className, children }) => (
            <div className={className}>
              {children}
              <a className={styles.link} onClick={onDownloadTemplate}>
                {DOWNLOAD_TEMPLATE_LABEL}
              </a>
            </div>
          )
        }}
        onChange={onChangeFilesHandler}
        accept=".csv"
      />
      <Field
        label={TICKET_TEMPLATE_LABEL}
        className={styles.filedTemplateWrapper}
        classNameLabel={styles.ticketTemplateLabel}
        classNameWrapper={styles.ticketTemplateWrapper}
      >
        <div className={styles.switherWrapper}>
          <div className={styles.optionWrapper}>
            <RoundRadioButton
              id={DEFAULT_TEMPLATE_RADIO_TYPE}
              value={DEFAULT_TEMPLATE_RADIO_TYPE}
              type="checkbox"
              onChange={onChangeTemplateSwitcher}
              currentValue={currentTemplateType}
            />
            <span className={styles.checkBoxLabel}>{DEFAULT_HEADER_TEMPLATE}</span>
          </div>
          <div className={styles.optionWrapper}>
            <RoundRadioButton
              id={CUSTOM_TEMPLATE_RADIO_TYPE}
              value={CUSTOM_TEMPLATE_RADIO_TYPE}
              type="checkbox"
              onChange={onChangeTemplateSwitcher}
              currentValue={currentTemplateType}
            />
            <span className={styles.checkBoxLabel}>{CUSTOM_HEADER_TEMPLATE}</span>
          </div>
          {customTemplate ? (
            <div>
              <span>{TEMPLATE_LOADED_LABEL}</span>
              <BasicButton
                className={styles.downloadTemplate}
                colorSchema={LINK_SCHEMA}
                size={SMALLER_SIZE}
                onClick={downloadCustomTemplate}
              >
                {DOWNLOAD_TEMPLATE}
              </BasicButton>
              <span className={styles.slash} />
              <BasicButton
                className={styles.removeTemplate}
                colorSchema={LINK_SCHEMA}
                size={SMALLER_SIZE}
                onClick={removeCustomTemplate}
              >
                {REMOVE_TEMPLATE}
              </BasicButton>
            </div>
          ) : (
            <FileField
              id="select-custom-template"
              fieldName="template"
              placeholder={SELECT_TEMPLATE_PLACEHOLDER}
              fieldProps={{
                classNameWrapper: styles.templateFieldWrapper
              }}
              onChange={onChangeTemplateHandler}
              accept=".html"
              disabled={isDisabledCustomTemplate}
            />
          )}
        </div>
        <div className={styles.buttons}>
          <BasicButton
            className={styles.uploadButton}
            colorSchema={ORANGE_SCHEMA}
            size={SMALLER_SIZE}
            onClick={onSubmitHandler}
            disabled={isDisabledGenerateButton}
          >
            {GENERATE_TEXT}
          </BasicButton>
        </div>
      </Field>
    </>
  )
}
UploadBarcodes.propTypes = {
  formApi: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploadDataState: PropTypes.string.isRequired,
  downloadCustomTemplate: PropTypes.func.isRequired,
  removeCustomTemplate: PropTypes.func.isRequired,
  downloadBarcodeTemplate: PropTypes.func.isRequired
}
