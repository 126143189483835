/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { compose } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'

import { PMSEligibility as PMSEligibilityComponent } from './PMSEligibility'

export const PMSEligibility = compose(withAuthContext)(PMSEligibilityComponent)
