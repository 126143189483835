/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { searchByColumns } from 'services/http/search'

import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  getRequestFilters,
  getRequestOptions
} = helpers

const getMarketAppsById = (marketId, options = {}) => async dispatch => {
  const key = marketId
  dispatch(actionCreater(REQUEST_LIST, { key }))
  const request = await searchByColumns(
    getRequestFilters(marketId),
    getRequestOptions(options)
  ).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { data: request.data, key }))
    return 'getMarketAppsById success'
  }

  return 'getMarketAppsById failed'
}

const resetList = marketId => dispatch =>
  dispatch(actionCreater(RESET_DATA_STATE, { key: marketId }))

export default {
  getMarketAppsById,
  resetList
}
