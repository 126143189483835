/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions as editQueue } from 'reducers/editQueue'
import { actions } from 'reducers/accordionDictionary'
import { formActions } from 'reducers/accordionForm'
import { withAuthContext } from 'helpers/withAuthContext'

import { TileAccordionList as TileAccordionListComponent } from './TileAccordionList'

export const mapStateToProps = state => ({
  data: state.accordionDictionary.listData,
  dataState: state.accordionDictionary.listDataState,
  ...state.accordionDictionary.listPagination,
  removeDataState: state.accordionForm.removeDataState,
  formDataState: state.accordionForm.formDataState
})

export const mapDispatchToProps = dispatch => {
  const { getAccordionDictionaryList: onGetData, ...rest } = actions
  return bindActionCreators(
    { ...editQueue, ...formActions, onGetData, ...rest },
    dispatch
  )
}

export const TileAccordionList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TileAccordionListComponent)
