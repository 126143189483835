/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
} from 'helpers/actionHelpers'

import { GET_ACTIVITY_CATEGORY_TAGS, GET_ACTIVITY_CATEGORY_TAGS_BY_ID } from './types'

const REQUEST_GET_ACTIVITY_CATEGORY_TAGS = `${REQUEST_TYPE}_${GET_ACTIVITY_CATEGORY_TAGS}`
const RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_ACTIVITY_CATEGORY_TAGS}`
const RESPONSE_GET_ACTIVITY_CATEGORY_TAGS = `${RESPONSE_TYPE}_${GET_ACTIVITY_CATEGORY_TAGS}`

const REQUEST_GET_ACTIVITY_CATEGORY_TAGS_BY_ID = `${REQUEST_TYPE}_${GET_ACTIVITY_CATEGORY_TAGS_BY_ID}`
const RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_BY_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_ACTIVITY_CATEGORY_TAGS_BY_ID}`
const RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_BY_ID = `${RESPONSE_TYPE}_${GET_ACTIVITY_CATEGORY_TAGS_BY_ID}`

export default {
  REQUEST_GET_ACTIVITY_CATEGORY_TAGS,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_ERROR,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS,
  REQUEST_GET_ACTIVITY_CATEGORY_TAGS_BY_ID,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_BY_ID_ERROR,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_BY_ID
}
