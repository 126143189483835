/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Form, Text } from 'informed'
import classNames from 'classnames'

import { useUpdateSomeDataState, usePrevious } from 'hooks'
import get from 'lodash/get'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { E_TICKETS, BARCODES } from 'constants/ticketFormats'
import { TextField, FormContent } from 'components/FormComponents'
import { numberInventNotifValidate } from 'helpers/formHelpers'

import { TypeOfTicketField } from './TypeOfTicketField'
import { UploadFilesForm } from './UploadFilesForm'
import { UploadBarcodes } from './UploadBarcodes'
import {
  GROUP_NAME_PLACEHOLDER,
  INV_NOTIFICATION_LABEL,
  INV_NOTIFICATION_PLACEHOLDER
} from './types'

import styles from './GroupForm.scss'

// TODO: need refactoring

export function GroupForm({
  isDisabled,
  getApi,
  onUpload,
  formData,
  onSubmit,
  uploadDataState,
  entityDataState,
  ticketSettings,
  onGenerateBarcode,
  downloadCustomTemplate,
  removeCustomTemplate,
  removeTemplateDataState,
  downloadBarcodeTemplate,
  onGetData
}) {
  const formApi = useRef(null)
  const ticketFormat = get(ticketSettings, 'ticketReservationSettings.ticketFormat')

  const prevFormDataId = usePrevious(get(formData, 'id'))

  useEffect(() => {
    getApi(formApi.current)
  }, [formData, getApi])

  useEffect(() => {
    if (formData && prevFormDataId !== formData.id) {
      formApi.current.setValues(formData)
    }
  }, [formData, formData.id, prevFormDataId])

  useUpdateSomeDataState(
    () => {
      if (formData) {
        formApi.current.setValues(formData)
      }
    },
    [entityDataState],
    DATA_STATE_RECIEVED
  )

  useUpdateSomeDataState(
    () => {
      onGetData()
    },
    [uploadDataState, removeTemplateDataState],
    DATA_STATE_RECIEVED
  )

  return (
    <Form
      getApi={api => {
        formApi.current = api
      }}
      className={styles.form}
      onSubmit={onSubmit}
    >
      {() => (
        <FormContent disabled={isDisabled}>
          <Text field="id" type="hidden" />
          <Text field="activityId" type="hidden" />
          <Text field="customTemplate" type="hidden" />
          <div className={styles.contentHeader}>
            <TextField fieldName="name" placeholder={GROUP_NAME_PLACEHOLDER} isRequired />
          </div>
          <div className={classNames(styles.row, styles.content)}>
            <div className={styles.column}>
              <TypeOfTicketField field="ticketTypes" />
            </div>
            <div className={classNames(styles.column, styles.uploadFilesWrapper)}>
              <TextField
                label={INV_NOTIFICATION_LABEL}
                fieldName="minTicketThreshold"
                placeholder={INV_NOTIFICATION_PLACEHOLDER}
                type="number"
                fieldProps={{
                  classNameWrapper: `${styles.fieldWrapper} ${styles.fieldExtraStyles}`,
                  className: styles.inputWrapper,
                  classNameLabel: styles.inputLabel
                }}
                validate={numberInventNotifValidate}
                isRequired
              />
              {ticketFormat === E_TICKETS && (
                <UploadFilesForm
                  uploadDataState={uploadDataState}
                  onSubmit={formFiles => {
                    onUpload(formFiles, formApi.current)
                  }}
                />
              )}
              {ticketFormat === BARCODES && (
                <UploadBarcodes
                  downloadCustomTemplate={downloadCustomTemplate}
                  removeCustomTemplate={removeCustomTemplate}
                  downloadBarcodeTemplate={downloadBarcodeTemplate}
                  isDisabled={isDisabled}
                  onSubmit={formFiles => {
                    onGenerateBarcode(formFiles, formApi.current)
                  }}
                />
              )}
            </div>
          </div>
        </FormContent>
      )}
    </Form>
  )
}
GroupForm.propTypes = {
  dataId: PropTypes.string,
  formData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  getApi: PropTypes.func,
  isDisabled: PropTypes.bool,
  uploadFiles: PropTypes.object.isRequired,
  onUpload: PropTypes.func.isRequired,
  entityDataState: PropTypes.string.isRequired,
  uploadDataState: PropTypes.string.isRequired,
  ticketSettings: PropTypes.object.isRequired,
  onGenerateBarcode: PropTypes.func.isRequired,
  downloadCustomTemplate: PropTypes.func.isRequired,
  removeCustomTemplate: PropTypes.func.isRequired,
  removeTemplateDataState: PropTypes.string.isRequired,
  onGetData: PropTypes.func.isRequired,
  downloadBarcodeTemplate: PropTypes.func.isRequired
}
GroupForm.defaultProps = {
  dataId: null,
  getApi: () => null,
  isDisabled: false
}
