/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const UNKNOWN_ERROR_MESSAGE = 'Oops! Something went wrong. \n Please try again later.'
export const INTERNAL_SERVER_ERROR =
  'There was a problem loading the resource. \n Please try again later. \n  If the problem persists, please contact support.'
export const INTERNAL_ERROR_MESSAGE = 'Inform an error code to the support team.'
export const FORBIDDEN_ERROR = 'Access is denied'
export const NOT_AUTH_ERROR = 'Please log in to perform this request.'
export const BUSINESS_EXCEPTION = 'BUSINESS_EXCEPTION'
export const VALIDATION_ERROR = 'VALIDATION_ERROR'
export const SUCCESS_MESSAGE = 'The operation was successful!'

export const NO_AUTH_KEY_ERROR = 'You are not authorized. \n Log in and try again.'

export const MESSAGE_KIND_INFO = 'info'
export const MESSAGE_KIND_SUCCESS = 'success'
export const MESSAGE_KIND_WARNING = 'warning'
export const MESSAGE_KIND_DANGER = 'danger'

export const messagesKinds = [
  MESSAGE_KIND_INFO,
  MESSAGE_KIND_SUCCESS,
  MESSAGE_KIND_WARNING,
  MESSAGE_KIND_DANGER
]

export default {
  UNKNOWN_ERROR_MESSAGE,
  INTERNAL_SERVER_ERROR,
  INTERNAL_ERROR_MESSAGE,
  FORBIDDEN_ERROR,
  NOT_AUTH_ERROR,
  BUSINESS_EXCEPTION,
  VALIDATION_ERROR,
  SUCCESS_MESSAGE,
  MESSAGE_KIND_INFO,
  MESSAGE_KIND_SUCCESS,
  MESSAGE_KIND_WARNING,
  MESSAGE_KIND_DANGER,
  messagesKinds,
  NO_AUTH_KEY_ERROR
}
