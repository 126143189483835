/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function DestinationIcon(props) {
  return (
    <svg viewBox="0 0 144 144" {...props}>
      <path
        d="M111.9,58.5c0,26.9-24,56.6-34.8,68.6c-2.8,3.1-7.5,3.1-10.3,0c-10.8-12-34.8-41.7-34.8-68.6
	c0-22,17.9-39.9,39.9-39.9C94,18.6,111.9,36.5,111.9,58.5z M81.8,86.7c11.4-4.1,19-15,19-27c0-15.9-12.9-28.8-28.8-28.8
	c-15.9,0-28.8,12.9-28.8,28.8c0,12,7.7,22.9,19,27c1.6,0.5,3.2-0.2,3.8-1.8c0.6-1.5-0.2-3.2-1.8-3.8C55.2,77.9,49,69.2,49,59.7
	c0-12.7,10.3-23,23-23c12.7,0,23,10.3,23,23c0,9.6-6.1,18.2-15.2,21.5c-1.5,0.6-2.3,2.2-1.8,3.8c0.4,1.2,1.6,1.9,2.8,1.9
	C81.1,86.9,81.4,86.8,81.8,86.7z M71.9,40.2c-0.8,0-1.5,0.3-2.1,0.9c-0.6,0.6-0.9,1.3-0.9,2.2l0,22c0,0.9,0.3,1.6,0.9,2.2
	c0.6,0.6,1.3,0.9,2.1,0.9c0.9,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.2l0-22c0-0.9-0.3-1.6-0.9-2.2C73.5,40.5,72.8,40.2,71.9,40.2
	 M69,74.5c0,0.8,0.3,1.5,0.9,2.1c0.6,0.6,1.3,0.9,2.1,0.9c0.9,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.1c0-0.9-0.3-1.6-0.9-2.2
	c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.5,0.3-2.1,0.9C69.3,72.9,69,73.6,69,74.5"
      />
    </svg>
  )
}
