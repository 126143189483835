/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { getListBySeasonId } from 'services/http/activity'

import helpers from './helpers'

const {
  RESET_LIST_STATE,
  REQUEST_ACTIVITY_LIST,
  RESPONSE_ACTIVITY_LIST,
  RESPONSE_ACTIVITY_LIST_ERROR
} = helpers

const getActivityList = (programId, seasonId, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_ACTIVITY_LIST))
  const request = await getListBySeasonId(programId, seasonId, options).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_ACTIVITY_LIST_ERROR))
    }
  )
  if (request) {
    dispatch(actionCreater(RESPONSE_ACTIVITY_LIST, { ...request.data }))
    return 'getActivityList success'
  }
  return 'getActivityList failed'
}

const resetList = () => ({
  type: RESET_LIST_STATE
})

export default {
  getActivityList,
  resetList
}
