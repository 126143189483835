import PropTypes from 'prop-types'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { useOnClickOutside, useDidMount } from 'hooks'
import classNames from 'classnames'
import styles from './styles.scss'

export function ContextMenuPopover({ x, y, onClose, items, header }) {
  const [wrapperStyles, setWrapperStyles] = useState({ left: x, top: y })
  const menuRef = useRef()
  useOnClickOutside(menuRef, onClose)

  const handleClose = () => {
    onClose()
  }

  const handleItemClick = option => event => {
    if (option.onClick) {
      option.onClick(event)
    }

    if (!option.options) {
      handleClose()
    }
  }

  useDidMount(() => {
    window.addEventListener('resize', handleClose)
    return () => {
      window.removeEventListener('resize', handleClose)
    }
  })

  useLayoutEffect(() => {
    const offset = window.innerWidth - x
    if (offset <= 450) {
      setWrapperStyles({ right: 0, top: y })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x])

  const renderListItem = option => (
    <div key={option.label} className={styles.item} onClick={handleItemClick(option)}>
      {option.label}
      {option.options && (
        <div className={styles.subMenuWrapper}>{option.options.map(renderListItem)}</div>
      )}
    </div>
  )

  return (
    <div ref={menuRef} className={classNames(styles.wrapper)} style={wrapperStyles}>
      <div className={styles.header}>{header}</div>
      <div className={styles.body}>{items.map(renderListItem)}</div>
    </div>
  )
}

ContextMenuPopover.propTypes = {
  onClose: PropTypes.func.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  header: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      options: PropTypes.arrayOf(PropTypes.object)
    })
  ).isRequired
}
