/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export function getModalsState(state) {
  return state.reduxModals
}

export function getModalsValues(state) {
  return Object.values(state.reduxModals)
}

export function getModalStateById(state, id) {
  return state.reduxModals[id]
}
