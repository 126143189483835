import { connect } from 'react-redux'

import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/account/accountPasswordRecovery'
import { ForgotPasswordForm as ForgotPasswordFormComponent } from './ForgotPasswordForm'

const mapStateToProps = state => ({
  dataState: state.account.accountPasswordRecovery.dataState,
  isEmailError: state.account.accountPasswordRecovery.isEmailError
})

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

export const ForgotPasswordForm = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ForgotPasswordFormComponent)
