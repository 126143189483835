/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { VERTICAL_ALIGN, HORIZONTAL_ALIGN } from 'constants/alignTypes'
import styles from './Field.scss'

export function Field(props) {
  const {
    children,
    label,
    id,
    error,
    isRequired,
    renderHeader,
    renderInner,
    renderFooter,
    render,
    className,
    classNameWrapper,
    classNameError,
    classNameLabel,
    align
  } = props

  const fieldId = id ? `field-${id}` : null
  const labelId = id ? `label-${id}` : null
  const errorId = id ? `error-${id}` : null
  const labelClassName = classNames(
    styles.label,
    { [styles.required]: label && isRequired },
    classNameLabel
  )
  const contentClassName = classNames(styles.content, className)
  const errorClassName = classNames(styles.error, classNameError)

  return render ? (
    render(props)
  ) : (
    <div
      id={fieldId}
      className={classNames(
        styles.wrapper,
        { [styles.horizontal]: align === HORIZONTAL_ALIGN },
        classNameWrapper
      )}
    >
      {renderHeader
        ? renderHeader({ className: labelClassName })
        : label && (
            <span id={labelId} className={labelClassName}>
              {label}
            </span>
          )}
      {renderInner ? (
        renderInner({ className: contentClassName, children, error, errorClassName })
      ) : (
        <div className={contentClassName}>
          {children}
          {!renderFooter && error && (
            <p id={errorId} className={errorClassName}>
              {error}
            </p>
          )}
        </div>
      )}
      {renderFooter && renderFooter({ className: errorClassName, error })}
    </div>
  )
}

Field.propTypes = {
  renderHeader: PropTypes.func,
  render: PropTypes.func,
  renderInner: PropTypes.func,
  renderFooter: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  children: PropTypes.node,
  label: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.string,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  classNameWrapper: PropTypes.string,
  classNameError: PropTypes.string,
  classNameLabel: PropTypes.string,
  align: PropTypes.string
}
Field.defaultProps = {
  renderHeader: null,
  render: null,
  renderInner: null,
  renderFooter: null,
  children: null,
  label: null,
  id: null,
  error: null,
  isRequired: false,
  className: '',
  classNameWrapper: '',
  classNameError: '',
  classNameLabel: '',
  align: VERTICAL_ALIGN
}
