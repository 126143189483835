/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { DEAL_OPTIONS_TITLE, DEAL_POSTFIX } from 'modules/Discounts/types'

export function buildCollapseTitle({ isExpand, isExist, dealType }) {
  if (!isExpand && dealType && isExist) {
    return `${dealType} ${DEAL_POSTFIX}`
  }
  return DEAL_OPTIONS_TITLE
}
