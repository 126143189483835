/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import ReactTable from 'react-table'
import PropTypes from 'prop-types'
import isNumber from 'lodash/isNumber'
import classNames from 'classnames'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED
} from 'helpers/actionHelpers'
import get from 'lodash/get'
import { useUpdateSomeDataState, useLoadingDataState, usePrevious } from 'hooks'
import { buildPageSizeOptions, useTableScrollReset } from '../helpers'
import { DEFAULT_PAGE_SIZE, DEFAULT_TABLE_STYLE } from '../types'

import 'react-table/react-table.css'
import styles from '../styles.scss'

export function SimpleTable(props) {
  const {
    style,
    data,
    withBasicAuth,
    itemsPerPage,
    itemsCount,
    dataState,
    columns,
    tableHeader,
    onGetData,
    authUser,
    pageNumber,
    // TODO: remove it after refactoring
    needUpdateList,
    dataStateWatchers,
    needFirstGetData,
    showPagination,
    showHeader,
    isFirstRender,
    //
    getApi,
    ...rest
  } = props

  const parametersRef = useRef({})

  const [isLoading] = useLoadingDataState(dataState)

  const currentPageSize = itemsPerPage || DEFAULT_PAGE_SIZE
  const pagesCount = Math.ceil(itemsCount / currentPageSize)
  const tablePage = isNumber(pageNumber) ? pageNumber - 1 : 0
  const pageSizeOptions = buildPageSizeOptions(data, itemsPerPage || DEFAULT_PAGE_SIZE)

  const hasPagination = useMemo(() => !(!showPagination || !pagesCount), [
    showPagination,
    pagesCount
  ])
  const [tableData, setTableData] = useState(data || [])
  const [additionalParams, setAdditionalParams] = useState({ order: 'asc' })
  const prevNeedUpdateList = usePrevious(needUpdateList)
  const { tableRef, resetScroll } = useTableScrollReset()

  const fetchData = useCallback(
    options => {
      // TODO: need to support old code
      const { authKey = 'deprecated' } = authUser
      if (withBasicAuth) {
        onGetData(authKey, options)
      } else {
        onGetData(options)
      }
    },
    [authUser, onGetData, withBasicAuth]
  )

  const getListParameters = () => ({
    ...parametersRef.current
  })

  const onChangeParameters = useCallback(
    ({ page, pageSize, sorted }) => {
      if (isFirstRender) return
      const needSort = get(sorted, '0')
      const sort = get(needSort, 'id')
      const order = get(needSort, 'desc') ? 'desc' : 'asc'
      if (page < 0) {
        return
      }

      if (
        page !== tablePage ||
        currentPageSize !== pageSize ||
        additionalParams.sort !== sort ||
        additionalParams.order !== order
      ) {
        // we do not save information about sort and order in redux/query, but should save it in state
        setAdditionalParams({ sort, order })

        fetchData({
          pageNumber: page + 1,
          itemsPerPage: pageSize,
          sort,
          order
        })
      }
    },
    [
      additionalParams.order,
      additionalParams.sort,
      currentPageSize,
      fetchData,
      tablePage,
      isFirstRender
    ]
  )

  useUpdateSomeDataState(
    () => {
      setTableData(data)
      resetScroll()
    },
    [dataState],
    DATA_STATE_RECIEVED
  )

  useUpdateSomeDataState(
    () => {
      setTableData([])
    },
    [dataState],
    DATA_STATE_REQUESTING
  )

  // TODO: we shoud remove this function from core compoenent
  useEffect(() => {
    if (onGetData && needFirstGetData) {
      onGetData()
    }
    if (getApi) {
      getApi({ getListParameters })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // TODO: remove it after refactoring. We shoud not update list from core component
  useEffect(() => {
    if (prevNeedUpdateList !== needUpdateList && needUpdateList) {
      fetchData({
        pageNumber,
        itemsPerPage,
        ...additionalParams
      })
    }
  }, [
    additionalParams,
    fetchData,
    itemsPerPage,
    needUpdateList,
    onGetData,
    pageNumber,
    prevNeedUpdateList
  ])
  // TODO: We shoud not update list from core component
  useUpdateSomeDataState(
    () => {
      fetchData({
        pageNumber,
        itemsPerPage,
        ...additionalParams
      })
    },
    dataStateWatchers,
    DATA_STATE_RECIEVED
  )
  // this hack need to get options via link
  useEffect(() => {
    parametersRef.current = { pageNumber, itemsPerPage, ...additionalParams }
  })

  return (
    <ReactTable
      ref={tableRef}
      data={tableData}
      className={classNames('-striped', '-highlight', styles.commonWrapper)}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      pages={pagesCount}
      onFetchData={onChangeParameters}
      pageSizeOptions={pageSizeOptions}
      loading={isLoading}
      manual
      columns={
        showHeader
          ? [
              {
                Header: tableHeader,
                columns
              }
            ]
          : columns
      }
      style={style}
      showPagination={hasPagination}
      {...rest}
    />
  )
}

SimpleTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  onGetData: PropTypes.func,
  dataState: PropTypes.string,
  itemsPerPage: PropTypes.number,
  itemsCount: PropTypes.number,
  tableHeader: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  needUpdateList: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showPagination: PropTypes.bool,
  authUser: PropTypes.object.isRequired,
  style: PropTypes.object,
  dataStateWatchers: PropTypes.arrayOf(PropTypes.string),
  pageNumber: PropTypes.number.isRequired,
  getApi: PropTypes.func,
  // TODO: Need adjust and refactor it with reimplement this functionality.
  withBasicAuth: PropTypes.bool,
  needFirstGetData: PropTypes.bool,
  showHeader: PropTypes.bool,
  isFirstRender: PropTypes.bool
}

SimpleTable.defaultProps = {
  columns: [],
  onGetData: () => null,
  dataState: DATA_STATE_NOT_REQUESTED,
  itemsPerPage: DEFAULT_PAGE_SIZE,
  itemsCount: 10,
  tableHeader: 'Default Header',
  data: [],
  needUpdateList: false,
  extendedOptions: {},
  showPagination: true,
  style: DEFAULT_TABLE_STYLE,
  dataStateWatchers: [],
  getApi: () => null,
  // TODO: Need adjust and refactor it with reimplement this functionality.
  withBasicAuth: true, // TODO: for compatibility
  needFirstGetData: true, // TODO: for compatibility,
  showHeader: true,
  isFirstRender: false
}
