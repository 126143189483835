/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLoadingDataState } from 'hooks'
import get from 'lodash/get'
import { PM_COMPANY } from 'constants/searchTypes'
import { SelectField, TypeaheadField } from 'components/FormComponents'
import { STATUS_ACTIVE, STATUS_ARCHIVED } from 'constants/entityStatus'
import { disabledSelectingPMC } from 'modules/Programs/helpers'
import { onSearch } from 'modules/LocationsField/helpers'
import {
  buildSearchColumn,
  LIKE_START_WITH_MODE,
  AND_OPERATOR,
  NULL_MODE
} from 'helpers/searchHelpers'
import { getLocationOptionForArchivedProgram } from './helpers'
import {
  LOADING_MESSAGE,
  LABEL_DESTINATION,
  PLACEHOLDER_DESTINATION,
  LABEL_PMC_NAME,
  PLACEHOLDER_PMC_NAME,
  PMC_NAME_FIELD_KEY,
  LOCATION_FIELD_KEY
} from '../types'

export function PMCField(props) {
  const {
    dataId,
    pmcSearchResult,
    clearPMCSearchResult,
    searchPMCByKeyword,
    formData,
    getLocationsList,
    locationListOptions,
    locationsState,
    disabled
  } = props
  const isArchivedProgrm = get(formData, 'status') === STATUS_ARCHIVED
  const isDisabledPmc = useMemo(() => disabledSelectingPMC(formData) || disabled, [
    disabled,
    formData
  ])
  const pmcName = (dataId && get(formData, 'pmCompany.name')) || ''
  const location = (dataId && get(formData, 'location.id')) || ''
  const [isLocationLoading, { isLoaded: isLocationsReceived }] = useLoadingDataState(locationsState)

  const locationOptions = !isArchivedProgrm
    ? locationListOptions
    : getLocationOptionForArchivedProgram(formData)

  const onPMCSearchFetch = useCallback(
    keyword => {
      searchPMCByKeyword(
        [
          buildSearchColumn({
            entity: PM_COMPANY,
            field: 'deactivationDate',
            mode: NULL_MODE,
            keyword: null,
            operator: AND_OPERATOR
          }),
          buildSearchColumn({
            entity: PM_COMPANY,
            field: 'status',
            mode: LIKE_START_WITH_MODE,
            keyword: STATUS_ACTIVE,
            operator: AND_OPERATOR
          })
        ],
        {
          type: PM_COMPANY,
          keyword
        }
      )
    },
    [searchPMCByKeyword]
  )

  const onPmcConverter = useCallback(value => ({ title: value.name, value: value.id }), [])

  useEffect(() => onSearch({ searchByType: getLocationsList })(), [getLocationsList])

  return (
    <>
      <TypeaheadField
        label={LABEL_PMC_NAME}
        fieldName={PMC_NAME_FIELD_KEY}
        initValue={pmcName}
        placeholder={PLACEHOLDER_PMC_NAME}
        suggestions={pmcSearchResult}
        suggestionsFetch={onPMCSearchFetch}
        clearSuggestions={clearPMCSearchResult}
        onValueConverter={onPmcConverter}
        disabled={isDisabledPmc}
        isRequired
      />
      <SelectField
        label={LABEL_DESTINATION}
        isLoading={isLocationLoading}
        loadingMessage={LOADING_MESSAGE}
        showSelect={isArchivedProgrm || isLocationsReceived}
        fieldName={LOCATION_FIELD_KEY}
        options={locationOptions}
        initial={location}
        placeholder={PLACEHOLDER_DESTINATION}
        isRequired
      />
    </>
  )
}
PMCField.propTypes = {
  dataId: PropTypes.string,
  pmcSearchResult: PropTypes.array.isRequired,
  clearPMCSearchResult: PropTypes.func.isRequired,
  searchPMCByKeyword: PropTypes.func.isRequired,
  formData: PropTypes.object,
  getLocationsList: PropTypes.func.isRequired,
  locationListOptions: PropTypes.array,
  locationsState: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
}
PMCField.defaultProps = {
  dataId: null,
  formData: {},
  locationListOptions: []
}
