import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import {
  AND_OPERATOR,
  buildSearchColumn,
  STRICT_MODE,
  ACTIVITY,
  OPEN_GROUP_OPERATOR,
  CLOSE_GROUP_OPERATOR,
  OR_OPERATOR,
  NULL_MODE
} from 'helpers/searchHelpers'
import { STATUS_ACTIVE, STATUS_TESTING } from 'constants/entityStatus'
import {
  PAGE_TYPE_WITH_CATEGORY,
  PAGE_TYPE_WITHOUT_CATEGORY
} from 'containers/ActivityProducts/AppsByActivityCategory/types'
import types, { ACTIVITY_CATEGORY_ID_FIELD, STATUS_FIELD } from './types'

const { LIST_NAME } = types

const REQUEST_LIST = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

const defaultColumns = [
  buildSearchColumn({
    entity: ACTIVITY,
    field: STATUS_FIELD,
    keyword: STATUS_ACTIVE,
    mode: STRICT_MODE,
    groupOperator: OPEN_GROUP_OPERATOR
  }),
  buildSearchColumn({
    entity: ACTIVITY,
    field: STATUS_FIELD,
    keyword: STATUS_TESTING,
    operator: OR_OPERATOR,
    mode: STRICT_MODE,
    groupOperator: CLOSE_GROUP_OPERATOR
  })
]

const getCategoryColumns = id => [
  ...defaultColumns,
  buildSearchColumn({
    entity: ACTIVITY,
    field: ACTIVITY_CATEGORY_ID_FIELD,
    keyword: id,
    operator: AND_OPERATOR,
    mode: STRICT_MODE
  })
]

const getWithoutCategoryColumns = () => [
  ...defaultColumns,
  buildSearchColumn({
    entity: ACTIVITY,
    field: ACTIVITY_CATEGORY_ID_FIELD,
    mode: NULL_MODE,
    operator: AND_OPERATOR
  })
]

const getRequestFilters = (id, pageType) => {
  switch (pageType) {
    case PAGE_TYPE_WITH_CATEGORY:
      return getCategoryColumns(id)
    case PAGE_TYPE_WITHOUT_CATEGORY:
    default:
      return getWithoutCategoryColumns()
  }
}

const getRequestOptions = options => ({ type: ACTIVITY, ...options })

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  getRequestOptions,
  getRequestFilters
}
