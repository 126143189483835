/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */
import {
  buildSearchColumn,
  LIKE_START_WITH_MODE,
  ACCOUNT,
  ROLE,
  AND_OPERATOR
} from 'helpers/searchHelpers'
import { STATUS_ACTIVE, STATUS_INACTIVE } from 'constants/entityStatus'
import { VAC_SPECIALIST_ROLE } from 'constants/rolesTypes'

export const TITLE_PAGE = 'Accounts'
export const TEXT_BTN_ADD_NEW = 'Add New Account'

export const ACTIVE_FILTER_LABEL = 'Active'
export const INACTIVE_FILTER_LABEL = 'Inactive'
export const DESTINATION_GUIDE_FILTER_LABEL = 'Destination Guide'

export const ACTIVE_FILTER_KEY = 'active'
export const INACTIVE_FILTER_KEY = 'inactive'
export const DESTINATION_GUIDE_FILTER_KEY = 'destinationGuide'

export const ACCOUNT_INITIAL_FILTERS = {
  [ACTIVE_FILTER_KEY]: true
}

export const ACCOUNT_FILTERS = [
  { label: ACTIVE_FILTER_LABEL, field: ACTIVE_FILTER_KEY },
  { label: INACTIVE_FILTER_LABEL, field: INACTIVE_FILTER_KEY },
  { label: DESTINATION_GUIDE_FILTER_LABEL, field: DESTINATION_GUIDE_FILTER_KEY }
]

export const ACTIVE_FILTER = buildSearchColumn({
  entity: ACCOUNT,
  field: 'status',
  keyword: STATUS_ACTIVE,
  mode: LIKE_START_WITH_MODE
})

export const INACTIVE_FILTER = buildSearchColumn({
  entity: ACCOUNT,
  field: 'status',
  keyword: STATUS_INACTIVE,
  mode: LIKE_START_WITH_MODE
})

export const DESTINATION_GUIDE_FILTER = buildSearchColumn({
  entity: ROLE,
  field: 'name',
  keyword: VAC_SPECIALIST_ROLE,
  operator: AND_OPERATOR
})
