/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { FORM_NAME, ACCOUNT_PROP_NAME, ROLES_PROP_NAME, RESET_PASSWORD } from './types'

const REQUEST_FORM = `${REQUEST_TYPE}_${FORM_NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${FORM_NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${FORM_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

const REQUEST_ACCOUNT = `${REQUEST_TYPE}_${ACCOUNT_PROP_NAME}`
const RESPONSE_ACCOUNT = `${RESPONSE_TYPE}_${ACCOUNT_PROP_NAME}`
const RESPONSE_ACCOUNT_ERROR = `${RESPONSE_ERROR_TYPE}_${ACCOUNT_PROP_NAME}`

const REQUEST_ROLES = `${REQUEST_TYPE}_${ROLES_PROP_NAME}`
const RESPONSE_ROLES = `${RESPONSE_TYPE}_${ROLES_PROP_NAME}`
const RESPONSE_ROLES_ERROR = `${RESPONSE_ERROR_TYPE}_${ROLES_PROP_NAME}`

const REQUEST_RESET_PASSWORD = `${REQUEST_TYPE}_${RESET_PASSWORD}`
const RESPONSE_RESET_PASSWORD = `${RESPONSE_TYPE}_${RESET_PASSWORD}`
const RESPONSE_RESET_PASSWORD_ERROR = `${RESPONSE_ERROR_TYPE}_${RESET_PASSWORD}`

export default {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_ACCOUNT,
  RESPONSE_ACCOUNT,
  RESPONSE_ACCOUNT_ERROR,
  REQUEST_ROLES,
  RESPONSE_ROLES,
  RESPONSE_ROLES_ERROR,
  REQUEST_RESET_PASSWORD,
  RESPONSE_RESET_PASSWORD,
  RESPONSE_RESET_PASSWORD_ERROR
}
