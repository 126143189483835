/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function NotebookIcon(props) {
  return (
    <svg viewBox="0 0 144 144" {...props}>
      <path
        d="M129.9,40.8l-3.3-2.5c-2.1-1.6-5.2-0.9-6.9,1.4l-1.5,2c1.8,1.2,3.6,2.4,5.5,3.8c1.9,1.4,3.7,2.9,5.4,4.3l1.5-2
	C132.3,45.5,132,42.3,129.9,40.8z M116.4,44L84.3,87c0,0,0,0,0,0.1l-0.5,0.7c-0.2,0.2-0.3,0.5-0.3,0.7l-1.5,11.2
	c-0.1,0.5,0.1,1,0.5,1.3c0.3,0.2,0.7,0.3,1,0.2c0.1,0,0.2-0.1,0.3-0.1l10.3-4.6c0.2-0.1,0.5-0.3,0.6-0.5l32.7-43.7
	c-1.6-1.4-3.5-2.9-5.4-4.3C120,46.4,118.2,45.2,116.4,44z"
      />
      <path
        d="M113.8,42.1C113.8,42.1,113.8,42,113.8,42.1l0-13c0-4.8-3.9-8.6-8.6-8.6H37.5c-4.8,0-8.6,3.9-8.6,8.6v86.2
	c0,4.8,3.9,8.6,8.6,8.6h67.6c4.8,0,8.6-3.9,8.6-8.6V75.7l-7.3,9.7v29.8c0,0.7-0.6,1.3-1.3,1.3H37.5c-0.7,0-1.3-0.6-1.3-1.3V29.1
	c0-0.7,0.6-1.3,1.3-1.3h67.6c0.7,0,1.3,0.6,1.3,1.3v22.7L113.8,42.1z M50.6,96.1c-1.9,0-3.5,1.6-3.5,3.5c0,1.9,1.6,3.5,3.5,3.5H72
	c1.9,0,3.5-1.6,3.5-3.5c0-1.9-1.6-3.5-3.5-3.5H50.6z M87,77.7H50.6c-1.9,0-3.5,1.6-3.5,3.5c0,1.9,1.6,3.5,3.5,3.5h31.2L87,77.7z
	 M95.6,62.9c0-1.9-1.6-3.5-3.5-3.5H50.6c-1.9,0-3.5,1.6-3.5,3.5c0,1.9,1.6,3.5,3.5,3.5h41.5C94,66.4,95.6,64.8,95.6,62.9z M92.1,41
	H50.6c-1.9,0-3.5,1.6-3.5,3.5c0,1.9,1.6,3.5,3.5,3.5h41.5c1.9,0,3.5-1.6,3.5-3.5C95.6,42.6,94,41,92.1,41z"
      />
    </svg>
  )
}
