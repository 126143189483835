/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback } from 'react'
import moment from 'moment'
import { BasicButton } from '@xplorie/ui-commons'
import { FORMAT } from 'constants/date'
import { TooltipComponent } from 'components/Tooltip'
import { LINK_SCHEMA } from 'constants/buttonsOptions'
import { CalendarField } from 'components/FormComponents'
import {
  DATE_FIELD,
  PICK_TODAY_BUTTON_TEXT,
  DATE_INPUT_LABEL,
  DATE_INPUT_PLACEHOLDER,
  TOP_DIRECTION
} from './types'
import styles from './styles.scss'

export function useSmartHostDateField(formApi) {
  const onClickTodayHandler = useCallback(() => {
    if (formApi.current) {
      formApi.current.setValue(DATE_FIELD, moment().format(FORMAT))
    }
  }, [formApi])

  return (
    <CalendarField
      fieldName={DATE_FIELD}
      label={DATE_INPUT_LABEL}
      placeholder={DATE_INPUT_PLACEHOLDER}
      isRequired
      fieldProps={{
        renderInner: ({ className, children: Component, error }) => (
          <div className={className}>
            {Component}
            {error && (
              <div className={styles.errorTooltipWrapper}>
                <TooltipComponent directionOption={TOP_DIRECTION} contentTooltip={error} />
              </div>
            )}
            <BasicButton
              colorSchema={LINK_SCHEMA}
              className={styles.todayButton}
              onClick={onClickTodayHandler}
            >
              {PICK_TODAY_BUTTON_TEXT}
            </BasicButton>
          </div>
        )
      }}
    />
  )
}
