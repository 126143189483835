/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { createActivity, updateActivity, getActivityById } from 'services/http/activity'

import helpers from './helpers'
import { STATUS_REQUEST_MAP } from './constants'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID_ERROR,
  REQUEST_SWITCH_STATUS,
  RESPONSE_SWITCH_STATUS,
  RESPONSE_SWITCH_STATUS_ERROR
} = helpers

const postProductForm = (authKey, contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await createActivity(authKey, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM, request.data))
    dispatch(send(message))
    return 'postProductForm success'
  }

  return 'postProductForm failed'
}
const putProductForm = (authKey, contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await updateActivity(authKey, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM, request.data))
    dispatch(send(message))
    return 'putProductForm success'
  }
  return 'putProductForm failed'
}

const getAPPbyId = (authKey, id, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_DATA_BY_ID))
  const request = await getActivityById(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_DATA_BY_ID_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_DATA_BY_ID, request.data))
    return Promise.resolve(request.data)
  }
  return Promise.reject(new Error('getAPPbyId failed'))
}

const switchStatus = (id, status) => async dispatch => {
  dispatch(actionCreater(REQUEST_SWITCH_STATUS))
  try {
    const request = STATUS_REQUEST_MAP[status]
    await request(id)
    dispatch(actionCreater(RESPONSE_SWITCH_STATUS, { status }))
    return 'switchStatus success'
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_SWITCH_STATUS_ERROR))
    return 'switchStatus failed'
  }
}

const resetForm = () => ({
  type: RESET_DATA_STATE
})

export default {
  postProductForm,
  resetForm,
  putProductForm,
  getAPPbyId,
  switchStatus
}
