/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

export const ACCOUNT = 'account'
export const ROLE = 'role'
export const PRIMARY_ADDRESS = 'primary_address'

export const YES_VALUE = 'Yes'
export const NO_VALUE = 'No'
