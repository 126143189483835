/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import isString from 'lodash/isString'
import { DATA_STATE_REQUESTING, DATA_STATE_RECIEVED } from 'helpers/actionHelpers'

export const buildInStockTitle = countFiles => `${countFiles} In Stock`

export const getGroupFormId = props => {
  const { dataId = null, formDataState = '', response = null } = props
  if (dataId && isString(dataId)) {
    return dataId
  }
  if (formDataState === DATA_STATE_RECIEVED && isString(response)) {
    return response
  }
  return null
}

const buildPayload = (formValues = {}) => {
  const payload = {
    ...formValues
  }
  delete payload.files
  delete payload.template
  return payload
}

export const onSubmitHandler = props => formValues => {
  const { putForm, postForm } = props
  const action = formValues && formValues.id ? putForm : postForm

  action(buildPayload(formValues))
}

export const onDeleteHandler = (props, formApi) => () => {
  const { activityId, removeEntityById } = props
  const groupFormId = getGroupFormId(props)
  if (removeEntityById && groupFormId && activityId) {
    removeEntityById(activityId, groupFormId, formApi.current.getState().values)
  }
}

export const onUploadFilesHandler = props => (files, formApi, options = {}) => {
  const { activityId, uploadTickets } = props
  const groupFormState = formApi.getState()
  const groupFormId = getGroupFormId(props)
  uploadTickets(activityId, groupFormId, {
    files,
    groupFormData: buildPayload(groupFormState.values),
    ...options
  })
}

export const onGenerateBarcode = props => (files = {}, formApi, options = {}) => {
  const { generateBarcode } = props
  const { codeFile, template, useDefault } = files
  const groupFormState = formApi.getState()
  const groupFormId = getGroupFormId(props)

  generateBarcode(groupFormId, {
    codeFile,
    template,
    useDefault,
    groupFormData: buildPayload(groupFormState.values),
    ...options
  })
}

export const onUploadFile = props => ({ file }) => {
  const { activityId, uploadTickets } = props
  const groupFormId = getGroupFormId(props)
  uploadTickets(activityId, groupFormId, {
    files: [file]
  })
}

export const onDownLoadFileHandler = props => params => {
  const { activityId, downloadFileById } = props
  const groupFormId = getGroupFormId(props)
  if (params.id && downloadFileById) {
    downloadFileById(activityId, groupFormId, params)
  }
}

export const groupFormLoadingBuilder = (props = {}) => {
  const { entityDataState } = props
  return entityDataState === DATA_STATE_REQUESTING
}

export const groupFormDisabledBuilder = (props = {}) => {
  const { dataState, uploadFiles, downloadTemplateDataState, removeTemplateDataState } = props

  return (
    dataState === DATA_STATE_REQUESTING ||
    Object.values(uploadFiles.data).some(value => value.dataState === DATA_STATE_REQUESTING) ||
    downloadTemplateDataState === DATA_STATE_REQUESTING ||
    removeTemplateDataState === DATA_STATE_REQUESTING
  )
}
export const fileListLoadingBuilder = (props = {}) => {
  const { groupFilesDataState, uploadFiles } = props
  return (
    groupFilesDataState === DATA_STATE_REQUESTING ||
    Object.values(uploadFiles.data).some(
      value =>
        value.downloadFileDataState === DATA_STATE_REQUESTING ||
        value.dataState === DATA_STATE_REQUESTING
    )
  )
}

export const onRemoveFilesHandler = props => (files = []) => {
  const { activityId, removeFiles } = props
  const groupFormId = getGroupFormId(props)

  if (files && removeFiles && files.length) {
    removeFiles(activityId, groupFormId, files)
  }
}
/**
 *
 * @param {string} groupName
 * @returns {string} name for barcode custom template
 */
export const buildTemplateName = groupName =>
  groupName ? `${groupName}_template` : 'group_template'
