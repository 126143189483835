/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { DEFAULT_OPTIONS } from './types'

import styles from './ToggleRadio.scss'

export function ToggleRadio({
  onChange,
  value,
  options,
  disabled,
  wrapperClassName,
  optionClassName
}) {
  const onChangeHandler = useCallback(
    newValue => event => {
      if (disabled || !onChange) {
        return
      }
      onChange(newValue, event)
    },
    [disabled, onChange]
  )

  return (
    <div className={classNames(styles.wrapper, { [styles.disabled]: disabled }, wrapperClassName)}>
      {options.map(({ key, label, value: optionValue }) => (
        <label key={key || label} className={styles.label}>
          <input
            className={styles.input}
            type="radio"
            checked={optionValue === value}
            onChange={onChangeHandler(optionValue)}
            value={optionValue}
          />
          <span className={classNames(styles.option, optionClassName)}>{label}</span>
        </label>
      ))}
    </div>
  )
}
ToggleRadio.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired
    })
  ),
  wrapperClassName: PropTypes.string,
  optionClassName: PropTypes.string
}
ToggleRadio.defaultProps = {
  onChange: null,
  value: false,
  options: DEFAULT_OPTIONS,
  disabled: false,
  wrapperClassName: null,
  optionClassName: null
}
