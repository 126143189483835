/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'
import { DATE_SPECIFIC, PERIOD_SPECIFIC } from 'modules/Discounts/DiscountForm/types'

export function getSpecificType(state) {
  const dateSpecific = get(state, 'entityData.ticketReservationSettings.dateSpecific', false)

  return dateSpecific ? DATE_SPECIFIC : PERIOD_SPECIFIC
}
