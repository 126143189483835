/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { APP_TYPES, AGREEMENT_TYPE, AGREEMENT_TYPE_VALUE } from 'constants/app'

// Labels
export const PROVIDER_PRODUCT_NAME_LABEL = 'Activity Product Name'
export const APP_SHORT_NAME_LABEL = 'Activity Product Short Name'
export const ACTIVITY_PROVIDER_LABEL = 'Activity Provider'
export const PRODUCT_WEBSITE_LINK_LABEL = 'AP website URL'
export const RMS_NAME_LABEL = 'RMS name'
export const RESERVATION_REQUIRED = 'Reservation required?'
export const RESALE_CONDITION = 'Resell?'
export const OFFER_LABEL = 'Offer'
export const DESCRIPTION_LABEL = 'Long Description'
export const SHORT_DESCRIPTION_LABEL = 'Short Description'
export const PLATFORM_DATE_TEXT_LABEL = 'Platform Date Text'
export const HOUSE_OF_OPERATION_LABEL = 'Hours Of Operation'
export const MARKET_LABEL = 'Market'
export const REGION_SERVICED_LABEL = 'Regions Serviced'
export const ACTIVITY_CATEGORY_LABEL = 'Activity Category'
export const ACTIVITY_SUB_CATEGORY_LABEL = 'Activity Sub-Category'
export const ACTIVITY_MODULE_LABEL = 'Activity Module'
export const TILE_NAME_LABEL = 'Tile Name'
export const TILE_ACCORDION_LABEL = 'Tile Accordion'
export const SMART_BLOCK_LABEL = 'Smart Block'
export const XE_PHOTO_LABEL = 'Xe Photo'
export const N2K_CSR_PHOTO_LABEL = 'N2K/CSR Photo'
export const ACTIVITY_SQUARE_IMAGE_URL_LABEL = 'Activity Square Image URL'
export const CSR_PAGE_LABEL = 'CSR Page'
export const N2K_PAGE_LABEL = 'Customer N2K Page'
export const XE_APP_ID_LABEL = 'XE Activity ID'
export const NETSUITE_ID_LABEL = 'NetSuite Internal ID'
export const TYPE_LABEL = 'Activity Product Type'
export const AGREEMENT_TYPE_LABEL = 'Agreement Type'

// Placeholders
export const PROVIDER_PRODUCT_NAME_PLACEHOLDER = 'Activity Product Name'
export const APP_SHORT_NAME_PLACEHOLDER = 'Activity Product Short Name'
export const ACTIVITY_PROVIDER_PLACEHOLDER = 'Select Activity provider from the drop-down list'
export const PRODUCT_WEBSITE_LINK_PLACEHOLDER = 'AP website URL'
export const RMS_NAME_PLACEHOLDER = 'RMS name'
export const OFFER_PLACEHOLDER = 'Offer'
export const DESCRIPTION_PLACEHOLDER = 'Long Description'
export const SHORT_DESCRIPTION_PLACEHOLDER = 'Short Description'
export const MARKET_PLACEHOLDER = 'Market'
export const PLATFORM_DATE_TEXT_PLACEHOLDER = 'Platform Date Text'
export const HOUSE_OF_OPERATION_PLACEHOLDER = 'Hours Of Operation'
export const REGION_SERVICED_ONE_PLACEHOLDER = 'Regions Serviced 1'
export const REGION_SERVICED_TWO_PLACEHOLDER = 'Regions Serviced 2'
export const REGION_SERVICED_THREE_PLACEHOLDER = 'Regions Serviced 3'
export const REGION_SERVICED_FOUR_PLACEHOLDER = 'Regions Serviced 4'
export const REGION_SERVICED_FIVE_PLACEHOLDER = 'Regions Serviced 5'
export const ACTIVITY_CATEGORY_PLACEHOLDER = 'Select Activity Category...'
export const ACTIVITY_MODULE_PLACEHOLDER = 'Activity Module'
export const TILE_NAME_PLACEHOLDER = 'Tile Name'
export const TILE_ACCORDION_PLACEHOLDER = 'Tile Accordion'
export const SMART_BLOCK_PLACEHOLDER = 'Smart Block'
export const XE_PHOTO_PLACEHOLDER = 'Xe Photo'
export const N2K_CSR_PHOTO_PLACEHOLDER = 'N2K/CSR Photo'
export const ACTIVITY_SQUARE_IMAGE_URL_PLACEHOLDER = 'Activity Square Image URL'
export const CSR_PAGE_PLACEHOLDER = 'CSR Page'
export const N2K_PAGE_PLACEHOLDER = 'Customer N2K Page'
export const XE_APP_ID_PLACEHOLDER = 'XE Activity ID'
export const NETSUITE_ID_PLACEHOLDER = 'NetSuite Internal ID'
export const TYPE_PLACEHOLDER = 'Activity Product type'
export const AGREEMENT_TYPE_PLACEHOLDER = 'Agreement Type'

// Other constants for helpers/component
export const NO_AUTH_USER_ERROR = 'NO_AUTH_USER_ERROR'
export const METHOD_IS_NOT_FUNCTION_ERROR = 'METHOD_IS_NOT_FUNCTION_ERROR'
export const noneOption = { label: 'None', value: '' }
export const NONE_OPTION_VALUE = 'none'
export const noneOptionToForm = { label: 'None', value: NONE_OPTION_VALUE }
export const SEARCH_AP_KEY = 'SEARCH_AP_BY_KEYWORD_IN_APP_FORM'
export const defaultResetDropDownItem = 'No Value'
export const NONE_SELECT_LABEL = 'None'
export const RESET_SELECT_OPTION = { key: 0, label: NONE_SELECT_LABEL, value: null }

export const APP_TYPE_OPTIONS = [
  { label: 'Marketing', value: APP_TYPES.MARKETING },
  { label: 'No Reservation required', value: APP_TYPES.NO_RESERVATION_REQUIRED },
  { label: 'Reservation required', value: APP_TYPES.RESERVATION_REQUIRED_FIT }
]

export const AGREEMENT_TYPE_OPTIONS = [
  { label: AGREEMENT_TYPE_VALUE.XPLORIE_FIT, value: AGREEMENT_TYPE.XPLORIE_FIT },
  { label: AGREEMENT_TYPE_VALUE.PROVIDER_FIT, value: AGREEMENT_TYPE.PROVIDER_FIT },
  { label: AGREEMENT_TYPE_VALUE.TRADITIONAL, value: AGREEMENT_TYPE.TRADITIONAL },
  { label: AGREEMENT_TYPE_VALUE.HYBRID, value: AGREEMENT_TYPE.HYBRID }
]
