/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import classNames from 'classnames'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { useUpdateSomeDataState } from 'hooks'
import { XplPagination } from '@xplorie/ui-commons'

import { buildInStockTitle } from '../helpers'
import { FileItem } from './FileItem'
import styles from './FilesList.scss'

export function FilesList(props) {
  const {
    uploadFiles: uploadFilesState,
    onDeleteFiles,
    removeInvsDataState,
    checkedFiles,
    onSelectFile,
    uploadDataState,
    onGetFiles,
    isLoading,
    ...rest
  } = props
  const {
    data: uploadFiles,
    pagination: { pageNumber, itemsPerPage, itemsCount }
  } = uploadFilesState
  const filesCount = Object.keys(uploadFiles).length
  const loadedInventory = Object.values(uploadFiles).filter(
    value => value.dataState === DATA_STATE_RECIEVED
  )
  const loadedInventoryCount = loadedInventory.length
  const pageCountByFilesCount = Math.ceil(loadedInventoryCount / itemsPerPage)
  const pageCount = Math.ceil(itemsCount / itemsPerPage)
  const stubElementsCount = itemsPerPage - filesCount

  const onGetFilesHandler = (page, params = {}) => {
    if (pageCountByFilesCount > 0) {
      onGetFiles({ pageNumber: page, ...params })
    } else {
      onGetFiles({ pageNumber: 1, ...params })
    }
  }

  useUpdateSomeDataState(
    () => {
      onGetFilesHandler(pageNumber, { itemsPerPage })
    },
    [uploadDataState, removeInvsDataState],
    DATA_STATE_RECIEVED
  )

  return (
    <section className={classNames(styles.wrapper)}>
      <div className={styles.listHeader}>
        <h2 className={styles.label}>{buildInStockTitle(itemsCount)}</h2>
        {itemsCount > 0 && (
          <XplPagination
            shownCount={itemsPerPage}
            pageCount={pageCount}
            currentPage={pageNumber}
            onClick={onGetFilesHandler}
          />
        )}
      </div>
      <div className={styles.listWrapper}>
        <ul>
          {Object.keys(uploadFiles).map(fileName => {
            const file = uploadFiles[fileName]
            const progressValue = get(uploadFiles, `${fileName}.value`)
            const { data } = file
            const isSelected = Object.prototype.hasOwnProperty.call(checkedFiles, get(data, 'id'))
            return (
              <FileItem
                {...rest}
                key={fileName}
                progressValue={progressValue}
                {...data}
                {...file}
                isActive={isSelected}
                onSelect={onSelectFile}
              />
            )
          })}
          {stubElementsCount > 0 &&
            Array.from({ length: stubElementsCount }, (value, index) => index).map(value => (
              <li key={value} />
            ))}
        </ul>
      </div>
      {isLoading && <div className={styles.curtain} />}
    </section>
  )
}
FilesList.propTypes = {
  uploadFiles: PropTypes.object,
  checkedFiles: PropTypes.object,
  onDownload: PropTypes.func.isRequired,
  onDeleteFiles: PropTypes.func.isRequired,
  removeInvsDataState: PropTypes.string.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  uploadDataState: PropTypes.string.isRequired,
  onGetFiles: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}
FilesList.defaultProps = {
  uploadFiles: {},
  checkedFiles: {}
}
