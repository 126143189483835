/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export {
  getEntityById,
  getEntityAddressById,
  getEntityAmenitiesById,
  getEntityImagesById,
  getEntitySmartHostById,
  updateSmartHostById
} from './oneEntity'
