/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, OK_TITLE } from 'constants/buttonsOptions'
import styles from './WarningModal.scss'

export function WarningModal({ isOpen, title, message, onHide, onConfirm }) {
  return (
    <BasicModal isOpen={isOpen} title={title} message={message} onClose={onHide}>
      <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onConfirm} className={styles.button}>
        {OK_TITLE}
      </BasicButton>
    </BasicModal>
  )
}

WarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}
WarningModal.defaultProps = {
  title: 'Warning!'
}
