/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useDealPeriodsApi, useDealPeriodsState } from '../hooks'

export function useDealCalendarApi() {
  const { calendarApi } = useDealPeriodsApi()

  return calendarApi
}

export function useDealCalendarState() {
  const { calendarState } = useDealPeriodsState()

  return calendarState
}
