/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'

import { withFormContext } from 'components/FormComponents/helpers'
import { withValidation, blankValidate, basicTextValidate } from 'helpers/formHelpers'
import { TypeaheadConnected } from './TypeaheadConnected'

export function TypeaheadFieldComponent(props) {
  const { fieldName, validate, isRequired, ...rest } = props
  const validateStrategy = isRequired
    ? withValidation(blankValidate, validate)
    : withValidation(validate, basicTextValidate)

  return (
    <TypeaheadConnected
      {...rest}
      field={fieldName}
      isRequired={isRequired}
      validate={validateStrategy}
      validateOnChange
    />
  )
}
TypeaheadFieldComponent.propTypes = {
  fieldProps: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.func,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  errorPath: PropTypes.array
}
TypeaheadFieldComponent.defaultProps = {
  fieldProps: {},
  placeholder: '',
  disabled: false,
  validate: basicTextValidate,
  isRequired: false,
  className: 'input',
  label: '',
  errorPath: []
}

export const TypeaheadField = withFormContext(TypeaheadFieldComponent)
