/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const PLACEHOLDER_DESTINATION = 'Select Destination from dropdown list'
export const ADD_DESTINATION_LABEL = 'Add Destination'
export const TITLE = 'Destinations'

export const SEARCH_KEY = 'LOCATION_FIELD_LOCATION'
