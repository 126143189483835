/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import get from 'lodash/get'
import { useContext } from 'react'
import {
  convertDates,
  getDisabledRanges
} from './helpers'
import { SmartHostFeeFormContext } from '../types'

export const useCalendarValidation = index => {
  const { formApi } = useContext(SmartHostFeeFormContext)
  const fees = formApi.getValue('fees') || []
  const currentItem = get(fees, index)

  const disabledRanges = getDisabledRanges(convertDates(fees), currentItem)

  return { disabledRanges };
}
