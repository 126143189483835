/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withDiscountsPermissions } from 'containers/Discounts'
import { getDealFormState } from 'connectors/reducers/deals/dealForm'
import { getActivities } from 'connectors/reducers/deals/dealFormActivities'

import { ControlPanel as ControlPanelComponent } from './ControlPanel'

import { isActivateDisabledBuilder } from './helpers'

function mapStateToProps(state) {
  const dealState = getDealFormState(state)
  const activities = getActivities(state)
  return {
    isActivateDisabled: isActivateDisabledBuilder(dealState.entityData, activities)
  }
}

export const ControlPanel = compose(
  withDiscountsPermissions,
  connect(mapStateToProps)
)(ControlPanelComponent)
