/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions as queueActions } from 'reducers/editQueue'
import { actions as activityProvidersAction } from 'reducers/activityProviderList'

import ActivityProviders from './ActivityProviders'

const mapStateToProps = state => ({
  panelStack: state.editQueue.panelStack
})

const mapDispatchToProps = dispatch => {
  const { getProvidersList } = activityProvidersAction
  return bindActionCreators({ onGetData: getProvidersList, ...queueActions }, dispatch)
}

const ActivityProvidersConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityProviders)

export default ActivityProvidersConnect
