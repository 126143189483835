/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import isArray from 'lodash/isArray'
import { DEAL_GROUP_TRIGGER_TYPE } from 'constants/dealsTypes'

/**
 *
 * @param {Object} entityData
 * @param {Array} activities
 * @returns {boolean}
 */
export function isActivateDisabledBuilder(entityData, activities) {
  if (!entityData || !isArray(entityData.availabilities) || !isArray(activities)) {
    return true
  }
  return !Array.prototype.every.call(
    [
      entityData.availabilities.length,
      activities.length,
      activities.some(value => value.type === DEAL_GROUP_TRIGGER_TYPE) &&
        activities.some(value => value.type !== DEAL_GROUP_TRIGGER_TYPE)
    ],
    value => Boolean(value)
  )
}
