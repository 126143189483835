/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { REQUEST_TYPE, RESPONSE_TYPE, RESPONSE_ERROR_TYPE } from 'helpers/actionHelpers'

import { EXCLUSIVE_ACTIVITY_LIST_NAME, EXCLUSIVE_ACTIVITY_NAME } from './types'

const REQUEST_EXCLUSIVE_ACTIVITIES_LIST = `${REQUEST_TYPE}_${EXCLUSIVE_ACTIVITY_LIST_NAME}`
const RESPONSE_EXCLUSIVE_ACTIVITIES_ERROR = `${RESPONSE_ERROR_TYPE}_${EXCLUSIVE_ACTIVITY_LIST_NAME}`
const RESPONSE_EXCLUSIVE_ACTIVITIES_LIST = `${RESPONSE_TYPE}_${EXCLUSIVE_ACTIVITY_LIST_NAME}`

const REQUEST_REMOVE_EXCLUSIVE_ACTIVITY = `${RESPONSE_TYPE}_${EXCLUSIVE_ACTIVITY_NAME}`
const RESPONSE_REMOVE_EXCLUSIVE_ACTIVITY = `${RESPONSE_TYPE}_${EXCLUSIVE_ACTIVITY_NAME}`
const RESPONSE_REMOVE_EXCLUSIVE_ACTIVITY_ERROR = `${RESPONSE_TYPE}_${EXCLUSIVE_ACTIVITY_NAME}`

const REQUEST_ADD_EXCLUSIVE_ACTIVITY = `${RESPONSE_TYPE}_${EXCLUSIVE_ACTIVITY_NAME}`
const RESPONSE_ADD_EXCLUSIVE_ACTIVITY = `${RESPONSE_TYPE}_${EXCLUSIVE_ACTIVITY_NAME}`
const RESPONSE_ADD_EXCLUSIVE_ACTIVITY_ERROR = `${RESPONSE_TYPE}_${EXCLUSIVE_ACTIVITY_NAME}`

export default {
  REQUEST_EXCLUSIVE_ACTIVITIES_LIST,
  RESPONSE_EXCLUSIVE_ACTIVITIES_ERROR,
  RESPONSE_EXCLUSIVE_ACTIVITIES_LIST,
  REQUEST_REMOVE_EXCLUSIVE_ACTIVITY,
  RESPONSE_REMOVE_EXCLUSIVE_ACTIVITY,
  RESPONSE_REMOVE_EXCLUSIVE_ACTIVITY_ERROR,
  REQUEST_ADD_EXCLUSIVE_ACTIVITY,
  RESPONSE_ADD_EXCLUSIVE_ACTIVITY,
  RESPONSE_ADD_EXCLUSIVE_ACTIVITY_ERROR
}
