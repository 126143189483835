/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CustomNestedField } from 'components/FormComponents'
import { useUpdateSomeDataState } from 'hooks'
import isObject from 'lodash/isObject'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { DeleteMinusIcon, AddPlusIcon } from 'icons'
import { TITLE_ADD_NEW_PERIOD } from 'modules/Discounts/types'
import {
  isShownAddButtonBuilder,
  isShownRemoveButtonBuilder,
  buildAvailabilityKey
} from './helpers'
import { RangeInputs } from './RangeInputs'

import styles from './DealAvailabilityField.scss'

export function DealAvailabilityField(props) {
  const {
    entityDataState,
    entityData,
    field,
    fieldState: { value: originalValue },
    disabled
  } = props

  const [availabilityValue, setAvailabilityState] = useState([{}])

  const onAddNewFields = () => {
    setAvailabilityState([...availabilityValue, {}])
  }

  const onRemoveField = index => () => {
    availabilityValue.splice(index, 1, null)
    setAvailabilityState([...availabilityValue])
  }

  useUpdateSomeDataState(
    () => {
      setAvailabilityState([...entityData.availabilities])
    },
    [entityDataState],
    DATA_STATE_RECIEVED
  )

  return (
    <div className={styles.wrapper}>
      {availabilityValue.map(
        (value, index) =>
          isObject(value) && (
            <CustomNestedField
              field={`${field}[${index}]`}
              key={buildAvailabilityKey(value, index)}
            >
              <div className={styles.fieldContent}>
                <RangeInputs
                  originalValue={originalValue}
                  prefix={`${field}[${index}]`}
                  index={index}
                />
                <div className={styles.control}>
                  {!disabled && isShownRemoveButtonBuilder({ availabilityValue, index }) && (
                    <DeleteMinusIcon className={styles.deleteIcon} onClick={onRemoveField(index)} />
                  )}
                </div>
              </div>
            </CustomNestedField>
          )
      )}
      <div className={styles.addNewCalendarFields}>
        {!disabled && isShownAddButtonBuilder({ availabilityValue, originalValue }) && (
          <>
            <AddPlusIcon className={styles.addIcon} onClick={onAddNewFields} />
            <span onClick={onAddNewFields}>{TITLE_ADD_NEW_PERIOD}</span>
          </>
        )}
      </div>
    </div>
  )
}
DealAvailabilityField.propTypes = {
  entityDataState: PropTypes.string.isRequired,
  fieldState: PropTypes.object.isRequired,
  fieldApi: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  entityData: PropTypes.object,
  disabled: PropTypes.bool
}
DealAvailabilityField.defaultProps = {
  entityData: {},
  disabled: false
}
