/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole
 *                or in part, is prohibited.
 */

import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'

import debounce from 'lodash/debounce'
import envsConfig from '../../config/helpers'

import rootReducer from './reducers/index'

const { envs } = envsConfig
const { DEVELOPMENT_ENV, LOCAL_DEV_ENV } = envs
/* global global */

if (typeof document === 'undefined') {
  global.window = {}
}

/* eslint-disable no-underscore-dangle */
// Note: this API requires redux@>=3.1.0

const logger = createLogger({
  collapsed: true
})
/* eslint-disable */

const storeProps =
  env === DEVELOPMENT_ENV || LOCAL_DEV_ENV
    ? [
        applyMiddleware(thunk, logger),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
      ]
    : [applyMiddleware(thunk)]

const store = createStore(rootReducer, {}, ...storeProps)

/* eslint-enable */

// Subscribe to the resize event so we can style components for mobile/tablet/desktop without device detection
if (window.addEventListener)
  window.addEventListener(
    'resize',
    debounce(event => {
      store.dispatch({
        type: 'SCREEN_RESIZE',
        payload: event.target.innerWidth
      })
    }, 300)
  )

export default store
