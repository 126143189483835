/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { asField } from 'informed'

import styles from './ActivityTable.scss'

export function TicketTypesFieldComponent(props) {
  const {
    fieldState: { error }
  } = props
  return error ? <p className={styles.warningMessage}>{error}</p> : null
}

TicketTypesFieldComponent.propTypes = {
  fieldState: PropTypes.object.isRequired
}

TicketTypesFieldComponent.defaultProps = {}

export const TicketTypesField = asField(TicketTypesFieldComponent)
