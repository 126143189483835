import React from 'react'
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom'
import { buildErrorMessage } from "./helper";
import styles from './InvalidPage.scss'

export function InvalidPage({ tokenError }) {
  const { errorType } = tokenError
  const errorMessage = buildErrorMessage(errorType)

  return (
    <div className={styles.wrapper}>
      <header className={styles.headerWrapper}>
        <NavLink to="/">
          <div className={styles.logo} />
        </NavLink>
      </header>
      <section className={styles.content}>
        <div className={styles.icon} />
        <div className={styles.message} dangerouslySetInnerHTML={{ __html: errorMessage }} />
      </section>
    </div>
  )
}
InvalidPage.propTypes = {
  tokenError: PropTypes.object.isRequired,
}

