/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_ELIGIBILITY_ASSIGN_BULK,
  RESPONSE_ELIGIBILITY_ASSIGN_BULK,
  RESPONSE_ERROR_ELIGIBILITY_ASSIGN_BULK,
  RESET_DATA_STATE
} = helpers

export const initialState = {}

export const entityInitialState = {
  bulkAssigmentDataState: DATA_STATE_NOT_REQUESTED
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // parameters assigment
    case REQUEST_ELIGIBILITY_ASSIGN_BULK:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          bulkAssigmentDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_ELIGIBILITY_ASSIGN_BULK:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          bulkAssigmentDataState: DATA_STATE_RECIEVED
        }
      }
    case RESPONSE_ERROR_ELIGIBILITY_ASSIGN_BULK:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          bulkAssigmentDataState: DATA_STATE_ERROR
        }
      }
    case RESET_DATA_STATE:
      return (() => {
        const { [action.payload.key]: removedState, ...restState } = state
        return {
          ...restState
        }
      })()
    default:
      return state
  }
}

export default filterActions(reducer, [
  REQUEST_ELIGIBILITY_ASSIGN_BULK,
  RESPONSE_ELIGIBILITY_ASSIGN_BULK,
  RESPONSE_ERROR_ELIGIBILITY_ASSIGN_BULK,
  RESET_DATA_STATE
])
