/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA_ERROR,
  REQUEST_DELETE_SERIES,
  RESPONSE_DELETE_SERIES,
  RESPONSE_DELETE_SERIES_ERROR,
  RESET_DATA_STATE,
  REQUEST_DELETE_OCCURRENCE,
  RESPONSE_DELETE_OCCURRENCE,
  RESPONSE_DELETE_OCCURRENCE_ERROR,
  REQUEST_CANCEL_SERIES,
  RESPONSE_CANCEL_SERIES,
  RESPONSE_CANCEL_SERIES_ERROR,
  REQUEST_CANCEL_OCCURRENCE,
  RESPONSE_CANCEL_OCCURRENCE,
  RESPONSE_CANCEL_OCCURRENCE_ERROR,
  REQUEST_ACTIVATE_SERIES,
  RESPONSE_ACTIVATE_SERIES,
  RESPONSE_ACTIVATE_SERIES_ERROR,
  REQUEST_ACTIVATE_OCCURRENCE,
  RESPONSE_ACTIVATE_OCCURRENCE,
  RESPONSE_ACTIVATE_OCCURRENCE_ERROR
} = helpers

export const initialState = {}

export const formInitialState = {
  formDataState: DATA_STATE_NOT_REQUESTED,
  entityDataState: DATA_STATE_NOT_REQUESTED,
  deleteSeriesDataState: DATA_STATE_NOT_REQUESTED,
  deleteOccurrenceDataState: DATA_STATE_NOT_REQUESTED,
  cancelSeriesDataState: DATA_STATE_NOT_REQUESTED,
  cancelOccurrenceDataState: DATA_STATE_NOT_REQUESTED,
  activateOccurrenceDataState: DATA_STATE_NOT_REQUESTED,
  activateSeriesDataState: DATA_STATE_NOT_REQUESTED,
  entityData: {}
}

const buildData = (state, action) => data => ({
  ...state,
  [action.payload.key]: {
    ...formInitialState,
    ...state[action.payload.key],
    ...data
  }
})

const reducer = (state = initialState, action) => {
  const appendData = buildData(state, action)

  switch (action.type) {
    case REQUEST_FORM:
      return appendData({
        formDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_FORM:
      return appendData({
        formDataState: DATA_STATE_RECIEVED
      })
    case RESPONSE_FORM_ERROR:
      return appendData({
        formDataState: DATA_STATE_ERROR
      })
    // get entity data
    case REQUEST_GET_ENTITY_DATA:
      return appendData({
        entityDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_GET_ENTITY_DATA:
      return appendData({
        entityDataState: DATA_STATE_RECIEVED,
        entityData: action.payload.data
      })
    case RESPONSE_GET_ENTITY_DATA_ERROR:
      return appendData({
        entityDataState: DATA_STATE_ERROR
      })
    // delete series
    case REQUEST_DELETE_SERIES:
      return appendData({
        deleteSeriesDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_DELETE_SERIES:
      return appendData({
        deleteSeriesDataState: DATA_STATE_RECIEVED
      })
    case RESPONSE_DELETE_SERIES_ERROR:
      return appendData({
        deleteSeriesDataState: DATA_STATE_ERROR
      })
    // delete Occurrence
    case REQUEST_DELETE_OCCURRENCE:
      return appendData({
        deleteOccurrenceDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_DELETE_OCCURRENCE:
      return appendData({
        deleteOccurrenceDataState: DATA_STATE_RECIEVED
      })
    case RESPONSE_DELETE_OCCURRENCE_ERROR:
      return appendData({
        deleteOccurrenceDataState: DATA_STATE_ERROR
      })
    // cancel series
    case REQUEST_CANCEL_SERIES:
      return appendData({
        cancelSeriesDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_CANCEL_SERIES:
      return appendData({
        cancelSeriesDataState: DATA_STATE_RECIEVED
      })
    case RESPONSE_CANCEL_SERIES_ERROR:
      return appendData({
        cancelSeriesDataState: DATA_STATE_ERROR
      })
    // cancel Occurrence
    case REQUEST_CANCEL_OCCURRENCE:
      return appendData({
        cancelOccurrenceDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_CANCEL_OCCURRENCE:
      return appendData({
        cancelOccurrenceDataState: DATA_STATE_RECIEVED
      })
    case RESPONSE_CANCEL_OCCURRENCE_ERROR:
      return appendData({
        cancelOccurrenceDataState: DATA_STATE_ERROR
      })
    // activate series
    case REQUEST_ACTIVATE_SERIES:
      return appendData({
        activateSeriesDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_ACTIVATE_SERIES:
      return appendData({
        activateSeriesDataState: DATA_STATE_RECIEVED
      })
    case RESPONSE_ACTIVATE_SERIES_ERROR:
      return appendData({
        activateSeriesDataState: DATA_STATE_ERROR
      })
    // activate Occurrence
    case REQUEST_ACTIVATE_OCCURRENCE:
      return appendData({
        activateOccurrenceDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_ACTIVATE_OCCURRENCE:
      return appendData({
        activateOccurrenceDataState: DATA_STATE_RECIEVED
      })
    case RESPONSE_ACTIVATE_OCCURRENCE_ERROR:
      return appendData({
        activateOccurrenceDataState: DATA_STATE_ERROR
      })
    case RESET_DATA_STATE: {
      const { [action.payload.key]: removedState, ...restState } = state
      return {
        ...restState
      }
    }
    default:
      return state
  }
}

export default filterActions(reducer, [
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA_ERROR,
  REQUEST_DELETE_SERIES,
  RESPONSE_DELETE_SERIES,
  RESPONSE_DELETE_SERIES_ERROR,
  REQUEST_DELETE_OCCURRENCE,
  RESPONSE_DELETE_OCCURRENCE,
  RESPONSE_DELETE_OCCURRENCE_ERROR,
  REQUEST_CANCEL_SERIES,
  RESPONSE_CANCEL_SERIES,
  RESPONSE_CANCEL_SERIES_ERROR,
  REQUEST_CANCEL_OCCURRENCE,
  RESPONSE_CANCEL_OCCURRENCE,
  RESPONSE_CANCEL_OCCURRENCE_ERROR,
  REQUEST_ACTIVATE_SERIES,
  RESPONSE_ACTIVATE_SERIES,
  RESPONSE_ACTIVATE_SERIES_ERROR,
  REQUEST_ACTIVATE_OCCURRENCE,
  RESPONSE_ACTIVATE_OCCURRENCE,
  RESPONSE_ACTIVATE_OCCURRENCE_ERROR,
  RESET_DATA_STATE
])
