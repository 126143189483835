/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import { actions } from 'reducers/currentUser'
import { actions as actionsNavigationType } from 'reducers/navigation'

import { withAuthContext } from 'helpers/withAuthContext'

import { Nav as NavComponent } from './Nav'

const mapStateToProps = state => ({
  dataState: state.currentUser.dataState,
  navigationType: state.navigation.navigationType
})

const mapDispatchToProps = dispatch => {
  const { updateNavigationType } = actionsNavigationType

  return bindActionCreators(
    {
      ...actions,
      updateNavigationType
    },
    dispatch
  )
}

export const Nav = compose(
  withAuthContext,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NavComponent)
