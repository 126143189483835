/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import { actions, selectors } from 'reducers/program/programMaterialsForm'
import { MarketingTab as MarketingTabComponent } from './MarketingTab'

const { getMaterialsById } = actions
const { getProgramMarketingFormStateById, getCloseFormDataStates } = selectors

function mapStateToProps(state, { dataId }) {
  const marketingState = getProgramMarketingFormStateById(state, dataId)
  const closeFormDataStates = getCloseFormDataStates(state, dataId)

  const { formData, formDataState } = marketingState
  return { data: formData, dataState: formDataState, closeFormDataStates }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMaterialsById
    },
    dispatch
  )
}

export const MarketingTab = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MarketingTabComponent)
