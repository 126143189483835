/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  uploadTicketById,
  getTicketsList,
  downloadTicket,
  removeTicketById,
  generateBarcodeById
} from 'services/http/ticketGroup'
import { formActions as ticketGroupFormActions } from 'reducers/ticketGroupForm'
import { calcProgressValue } from 'helpers/downloadHelper'
import helpers from './helpers'

const {
  buildDELETETicketsSuccessMessage,
  RESET_DATA_STATE,
  REQUEST_UPLOAD_FILES,
  RESPONSE_UPLOAD_FILES,
  RESPONSE_UPLOAD_FILES_ERROR,
  UPDATED_UPLOAD_FILE_PROGRESS,
  REQUEST_UPLOAD_FILES_PROCCESS,
  RESPONSE_UPLOAD_FILES_PROCCESS,
  RESPONSE_UPLOAD_FILES_PROCCESS_ERROR,
  REQUEST_FILES_BY_GROUP_ID,
  RESPONSE_FILES_BY_GROUP_ID,
  RESPONSE_FILES_BY_GROUP_ID_ERROR,
  REQUEST_DOWNLOAD_TICKET,
  RESPONSE_DOWNLOAD_TICKET,
  RESPONSE_DOWNLOAD_TICKET_ERROR,
  REQUEST_REMOVE_INTENTORIES,
  RESPONSE_REMOVE_INTENTORIES,
  REQUEST_REMOVE_INTENTORIE,
  RESPONSE_REMOVE_INTENTORIE,
  RESPONSE_REMOVE_INTENTORIE_ERROR,
  REMOVE_INVENTORIE_FROM_STATE
} = helpers

const uploadTickets = (
  activityId,
  id = null,
  { files = null, groupFormData = {} }
) => async dispatch => {
  if (!files || !files.length) {
    return 'uploadTickets error'
  }
  dispatch(actionCreater(REQUEST_UPLOAD_FILES_PROCCESS))
  let groupFormId = id
  if (!groupFormId) {
    groupFormId = await ticketGroupFormActions.postForm(groupFormData)(dispatch)
    if (!groupFormId) {
      dispatch(actionCreater(RESPONSE_UPLOAD_FILES_PROCCESS_ERROR))
      return 'error'
    }
  }
  const promises = Array.from(files).map(async file => {
    const fileId = file.name
    const formData = new FormData()
    formData.append('file', file)
    dispatch(
      actionCreater(REQUEST_UPLOAD_FILES, {
        fileId,
        data: {
          id: fileId,
          name: fileId
        }
      })
    )
    try {
      await uploadTicketById(activityId, groupFormId, formData, {
        onUploadProgress: ev => {
          dispatch(
            actionCreater(UPDATED_UPLOAD_FILE_PROGRESS, { fileId, value: calcProgressValue(ev) })
          )
        }
      })
      dispatch(
        actionCreater(RESPONSE_UPLOAD_FILES, {
          fileId,
          oldId: fileId,
          data: {
            id: fileId,
            name: fileId
          }
        })
      )
    } catch (err) {
      const { send, message } = err
      dispatch(send(message))
      dispatch(
        actionCreater(RESPONSE_UPLOAD_FILES_ERROR, {
          fileId,
          data: {
            id: fileId,
            name: fileId,
            file
          }
        })
      )
    }
  })
  await Promise.all(promises)
  dispatch(actionCreater(RESPONSE_UPLOAD_FILES_PROCCESS))
  return 'success'
}

const getFilesByGroupId = (activityId, id, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_FILES_BY_GROUP_ID))
  const request = await getTicketsList(activityId, id, params).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FILES_BY_GROUP_ID_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_FILES_BY_GROUP_ID, request.data))
    return 'getFilesByGroupId success'
  }

  return 'getFilesByGroupId failed'
}

const downloadFileById = (activityId, groupId, { id: fileId, name }) => async dispatch => {
  dispatch(actionCreater(REQUEST_DOWNLOAD_TICKET, { fileId }))
  const request = await downloadTicket({ activityId, groupId, fileId, name }).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_DOWNLOAD_TICKET_ERROR, { fileId }))
    }
  )
  if (request) {
    dispatch(actionCreater(RESPONSE_DOWNLOAD_TICKET, { fileId }))
    return 'downloadFileById success'
  }

  return 'downloadFileById failed'
}

const removeFiles = (activityId, groupId, files = []) => async dispatch => {
  const removableFiles = files.map(value => value.id)

  dispatch(actionCreater(REQUEST_REMOVE_INTENTORIES))
  const request = await removeTicketById(activityId, groupId, removableFiles).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_REMOVE_INTENTORIE_ERROR))
    }
  )
  if (request) {
    const {
      message: { send, message }
    } = request

    removableFiles.forEach(fileId => {
      dispatch(actionCreater(REQUEST_REMOVE_INTENTORIE, { fileId }))
      dispatch(actionCreater(RESPONSE_REMOVE_INTENTORIE, { fileId }))
      dispatch(actionCreater(REMOVE_INVENTORIE_FROM_STATE, { fileId }))
    })

    dispatch(actionCreater(RESPONSE_REMOVE_INTENTORIES))
    dispatch(send({ ...message, message: buildDELETETicketsSuccessMessage(files) }))
    return 'removeFiles success'
  }

  return 'removeFiles error'
}

const generateBarcode = (
  id = null,
  { codeFile = null, template = null, useDefault, groupFormData = {} }
) => async dispatch => {
  if (!codeFile) {
    return 'generateBarcode error'
  }
  dispatch(actionCreater(REQUEST_UPLOAD_FILES_PROCCESS))
  let groupFormId = id
  if (!groupFormId) {
    groupFormId = await ticketGroupFormActions.postForm(groupFormData)(dispatch)
    if (!groupFormId) {
      dispatch(actionCreater(RESPONSE_UPLOAD_FILES_PROCCESS_ERROR))
      return 'error'
    }
  }
  const fileId = codeFile.name
  dispatch(
    actionCreater(REQUEST_UPLOAD_FILES, {
      fileId,
      data: {
        id: fileId,
        name: fileId
      }
    })
  )
  try {
    const request = await generateBarcodeById(
      groupFormData.activityId,
      groupFormId,
      { codeFile, template, useDefault },
      {
        onUploadProgress: ev => {
          dispatch(
            actionCreater(UPDATED_UPLOAD_FILE_PROGRESS, { fileId, value: calcProgressValue(ev) })
          )
        }
      }
    )
    dispatch(
      actionCreater(RESPONSE_UPLOAD_FILES, {
        fileId: request.data,
        oldId: fileId,
        data: {
          id: request.data,
          name: fileId
        }
      })
    )
    dispatch(actionCreater(RESPONSE_UPLOAD_FILES_PROCCESS))
  } catch (err) {
    const { message, send } = err
    dispatch(send(message))
    dispatch(
      actionCreater(RESPONSE_UPLOAD_FILES_ERROR, {
        fileId,
        data: {
          id: fileId,
          name: fileId,
          file: codeFile
        }
      })
    )
    dispatch(actionCreater(RESPONSE_UPLOAD_FILES_PROCCESS_ERROR))
  }
  return 'success'
}

const resetInventory = () => ({
  type: RESET_DATA_STATE
})

export default {
  resetInventory,
  uploadTickets,
  getFilesByGroupId,
  downloadFileById,
  removeFiles,
  generateBarcode
}
