/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { ActivityProductList } from 'modules/ActivityProducts/ActivityProductList'
import { FormFilter } from 'components/FormComponents'
import { TableField, FilteredSearch, useFilteredSearch } from 'modules/FilteredSearch'
import { ACTIVITY } from 'constants/searchTypes'
import { activityProductTableColumns } from 'helpers/dataTablesHelpers'
import { DotsButtonWithExport } from 'modules/ExportEntity'
import { buildFilters } from 'containers/ActivityProducts/helpers'
import {
  APP_INITIAL_FILTERS,
  APP_FILTERS,
  EXPORT_FILENAME
} from 'containers/ActivityProducts/types'

import styles from './ActivitySearchResults.scss'

export function ActivitySearchResults(props) {
  const { onChange, data, dataState, state, itemsPerPage, pageNumber, itemsCount, enqueue } = props

  const onGetData = useCallback(
    (filters, parameters) => {
      onChange({
        filters,
        parameters
      })
    },
    [onChange]
  )

  const {
    onGetApi,
    onChange: handleChange,
    getFilters,
    getParams,
    onGetData: onGetDataHandler
  } = useFilteredSearch({
    onGetData,
    buildFilters,
    ignoreDidMount: true
  })

  const getExportParams = useCallback(
    () => ({
      ...state.parameters,
      ...getParams()
    }),
    [state, getParams]
  )

  return (
    <FilteredSearch getApi={onGetApi} onChange={handleChange} initialFilters={APP_INITIAL_FILTERS}>
      <div className={styles.filterBarWrapper}>
        <FormFilter filters={APP_FILTERS} />
        <DotsButtonWithExport
          onGetFilters={getFilters}
          onGetParams={getExportParams}
          entity={ACTIVITY}
          columns={activityProductTableColumns}
          filename={EXPORT_FILENAME}
        />
      </div>
      <TableField
        Component={ActivityProductList}
        enqueue={enqueue}
        dataState={dataState}
        onGetData={onGetDataHandler}
        data={data}
        itemsPerPage={itemsPerPage}
        itemsCount={itemsCount}
        pageNumber={pageNumber}
        // TODO: Need adjust and refactor it with reimplement this functionality.
        withBasicAuth={false}
        needFirstGetData={false}
      />
    </FilteredSearch>
  )
}

ActivitySearchResults.propTypes = {
  filters: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array,
  dataState: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number,
  itemsCount: PropTypes.number,
  pageNumber: PropTypes.number,
  enqueue: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired
}

ActivitySearchResults.defaultProps = {
  filters: [],
  data: [],
  itemsCount: 0,
  itemsPerPage: 10,
  pageNumber: 1
}
