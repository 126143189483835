/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { BasicButton } from '@xplorie/ui-commons'
import { DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { PDF_FORMAT, CSV_FORMAT, XLS_FORMAT } from 'constants/exportFormat'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'

import { getButtonTextByType, getActionByType } from './helpers'

export function ReportsExporter(props) {
  const { reportType, dataState, authUser } = props
  const isLoading = dataState === DATA_STATE_REQUESTING
  return (
    <>
      <BasicButton
        id="save-pdf-btn"
        disabled={isLoading}
        onClick={() => getActionByType(reportType, props)(authUser, PDF_FORMAT)}
        colorSchema={ORANGE_SCHEMA}
      >
        {getButtonTextByType(reportType, PDF_FORMAT)}
      </BasicButton>
      <BasicButton
        id="save-xls-btn"
        disabled={isLoading}
        onClick={() => getActionByType(reportType, props)(authUser, XLS_FORMAT)}
        colorSchema={ORANGE_SCHEMA}
      >
        {getButtonTextByType(reportType, XLS_FORMAT)}
      </BasicButton>
      <BasicButton
        id="save-csv-btn"
        disabled={isLoading}
        onClick={() => getActionByType(reportType, props)(authUser, CSV_FORMAT)}
        colorSchema={ORANGE_SCHEMA}
      >
        {getButtonTextByType(reportType, CSV_FORMAT)}
      </BasicButton>
    </>
  )
}

ReportsExporter.propTypes = {
  reportType: PropTypes.string.isRequired,
  dataState: PropTypes.string.isRequired,
  authUser: PropTypes.object.isRequired
}
