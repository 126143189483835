/*
 * COPYRIGHT:     Copyright © 2019 - 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { useRef, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDidMount } from 'hooks'
import get from 'lodash/get'
import {
  togglePercentageSymbol,
  positiveNumberValidation,
  withValidation,
  twoDigitDecimalsValidation,
  numberMinMaxValuesValidation
} from 'helpers/formHelpers'
import { checkPercentageSymbol } from '../../Settings/SettingsBlocks/helpers'
import {
  SELECTOR_EXTRAS_PRICE,
  SELECTOR_PERCENTAGE_EXTRAS_PRICE,
  SELECTOR_XPLORIE_PRICE,
  SELECTOR_PERCENTAGE_XPLORIE_PRICE
} from './selectors'
import { onUpdateStateForm, getPercentageSelector } from './helpers'
import { LIST_PRICE_LIMIT } from './types'

export function useTicketTypeFormPresenter(props) {
  const { isDisabled, formData } = props

  const formDataId = get(formData, 'id')
  const isDisabledDeleteButton = isDisabled || !formDataId
  const formApi = useRef(null)
  const isXplorieTicketsShown = get(formData, 'xplorieTicketEnabled')
  const [showListPrice, setShowListPrice] = useState(isXplorieTicketsShown)
  const onXplorieTicketsChange = value => setShowListPrice(value)
  const listPriceValidation = withValidation(
    twoDigitDecimalsValidation,
    numberMinMaxValuesValidation(null, LIST_PRICE_LIMIT)
  )

  const { isPercentageExtrasPrice, extrasPrice, isPercentageXploriePrice, xploriePrice } = useMemo(
    () => ({
      isPercentageExtrasPrice: get(formData, SELECTOR_PERCENTAGE_EXTRAS_PRICE),
      extrasPrice: get(formData, SELECTOR_EXTRAS_PRICE),
      isPercentageXploriePrice: get(formData, SELECTOR_PERCENTAGE_XPLORIE_PRICE),
      xploriePrice: get(formData, SELECTOR_XPLORIE_PRICE)
    }),
    [formData]
  )

  const onClearFormHandler = useCallback(() => {
    if (formApi.current) {
      formApi.current.reset()
      formApi.current.setValue('id', formDataId)
    }
  }, [formDataId])

  const onChangeHandler = useCallback(
    selector => isPercentage => {
      const priceValue = formApi.current && formApi.current.getValue(selector)

      if ((isPercentage && checkPercentageSymbol(priceValue)) || !priceValue) {
        return null
      }

      const toggledPercentageValue = togglePercentageSymbol(priceValue, isPercentage)
      return formApi.current.setValue(selector, toggledPercentageValue)
    },
    []
  )

  const onFocusHandler = useCallback(
    (selectorPrice, selectorPercentage) => () => {
      const priceValue = formApi.current.getValue(selectorPrice)
      const isPercentage = formApi.current.getValue(selectorPercentage)
      if (isPercentage && priceValue && checkPercentageSymbol(priceValue)) {
        const currentPriceValue = togglePercentageSymbol(priceValue)
        formApi.current.setValue(selectorPrice, currentPriceValue)
      }
    },
    []
  )

  const onBlurPriceHandler = useCallback(val => {
    const { name: selectorPrice } = val.currentTarget
    const selectorPercentage = getPercentageSelector(selectorPrice)
    const percentageValue = formApi.current.getValue(selectorPercentage)
    if (percentageValue) {
      onUpdateStateForm(formApi.current, selectorPrice, selectorPercentage)
    }
  }, [])

  useDidMount(() => {
    if (formDataId && formApi.current) {
      formApi.current.setValues(formData)

      const extrasPriceValueWithSymbol =
        isPercentageExtrasPrice &&
        extrasPrice &&
        togglePercentageSymbol(extrasPrice, isPercentageExtrasPrice)

      if (extrasPriceValueWithSymbol) {
        formApi.current.setValue(SELECTOR_EXTRAS_PRICE, extrasPriceValueWithSymbol)
      }

      const xploriePriceValueWithSymbol =
        isPercentageXploriePrice &&
        xploriePrice &&
        togglePercentageSymbol(xploriePrice, isPercentageXploriePrice)

      if (xploriePriceValueWithSymbol) {
        formApi.current.setValue(SELECTOR_XPLORIE_PRICE, xploriePriceValueWithSymbol)
      }
    }
  })

  const validatePrices = useCallback(value => {
    if (!value) {
      return null
    }
    const preparedValue = String(value).replace(/\s|%/g, '')
    return positiveNumberValidation(preparedValue)
  }, [])

  return [
    {
      isDisabledDeleteButton,
      showListPrice,
      formApi
    },
    {
      validatePrices,
      onBlurPriceHandler,
      onFocusHandler,
      onChangeHandler,
      onClearFormHandler,
      onXplorieTicketsChange,
      listPriceValidation
    }
  ]
}

useTicketTypeFormPresenter.propTypes = {
  isDisabled: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired
}
