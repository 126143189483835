/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import {
  deactivateById as deactivateRequest,
  activateById as activateRequest,
  removeDeactivatingById,
  updateDeactivationById
} from 'services/http/activityProvider'

import helpers from './helpers'

const {
  REQUEST_DEACTIVATION,
  RESPONSE_DEACTIVATION,
  RESPONSE_ERROR_DEACTIVATION,
  REQUEST_ACTIVATION,
  RESPONSE_ACTIVATION,
  RESPONSE_ERROR_ACTIVATION,
  RESET_DATA_STATE,
  ACTIVATION_SUCCESS_MESSAGE,
  buildSuccessDeactivatingMessage,
  buildSuccessCancelDeactivatingMessage
} = helpers

const deactivateAPById = (id, options) => async dispatch => {
  const key = id
  const { prevDate, currentDate, date, isInactive = false } = options

  dispatch(actionCreater(REQUEST_DEACTIVATION, { key }))

  const deactivationRequest = isInactive ? updateDeactivationById : deactivateRequest

  const request = await deactivationRequest(id, date).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_DEACTIVATION, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_DEACTIVATION, { key }))
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildSuccessDeactivatingMessage(prevDate, currentDate) }))
    return 'deactivateAPById success'
  }

  return 'deactivateAPById failed'
}

const activateAPById = (id, options) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_ACTIVATION, { key }))
  const request = await activateRequest(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_ACTIVATION, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_ACTIVATION, { key }))
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: ACTIVATION_SUCCESS_MESSAGE }))
    return 'activateAPById success'
  }

  return 'activateAPById failed'
}

const deleteDelayDeactivate = (id, options) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_DEACTIVATION, { key }))
  const request = await removeDeactivatingById(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_DEACTIVATION, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_DEACTIVATION, { key }))
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildSuccessCancelDeactivatingMessage() }))
    return 'deleteDelayDeactivate success'
  }

  return 'deleteDelayDeactivate failed'
}

const resetState = id => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key: id }))

export default {
  deactivateAPById,
  activateAPById,
  resetState,
  deleteDelayDeactivate
}
