/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'
import { STATUS_ACTIVE, STATUS_INACTIVE } from 'constants/entityStatus'
import { APPS_BY_AP_PATH } from 'router'
import {
  DELAY_DEACTIVATION_MESSAGE,
  INACTIVE_MESSAGE,
  UPDATE_DATE_MODE,
  UPDATE_DEACTIVATED_DATE_MODE,
  SET_DEACTIVATION_MODE
} from './types'

/**
 *
 * @param {string} dataId
 */
export function buildEntitiesPath(dataId = null) {
  return `${APPS_BY_AP_PATH}${dataId}`
}

export function buildInfoTitle(entityData) {
  const status = get(entityData, 'status')
  return status === STATUS_ACTIVE ? DELAY_DEACTIVATION_MESSAGE : INACTIVE_MESSAGE
}

export function getDeactivationMode(entityData) {
  const deactivationDate = get(entityData, 'deactivationDate.date')
  const status = get(entityData, 'status')

  if (!deactivationDate && status === STATUS_ACTIVE) {
    return SET_DEACTIVATION_MODE
  }

  if (status === STATUS_INACTIVE && deactivationDate) {
    return UPDATE_DEACTIVATED_DATE_MODE
  }

  return UPDATE_DATE_MODE
}
