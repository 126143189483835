/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { ELIGIBILITY_PARAMETER } from 'constants/searchTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'

export const eligibilityTableColumns = [
  {
    Header: 'Parameter Name',
    accessor: 'label',
    id: buildSortPropertyByType('label', ELIGIBILITY_PARAMETER)
  },
  {
    Header: 'Parameter Type',
    accessor: 'type',
    id: buildSortPropertyByType('type', ELIGIBILITY_PARAMETER)
  }
]
