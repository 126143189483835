/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { useDidMount, useDataStatesComparison } from 'hooks'
import get from 'lodash/get'
import { DATA_STATE_REQUESTING } from 'helpers/actionHelpers'

import { ProfileData } from './ProfileData'

export function ProfileInfo(props) {
  const { profileData, profileDataState, getProfile, authUser, listWatchersState } = props

  const accountId = get(authUser, 'id')
  const firstName = get(profileData, 'firstName')
  const lastName = get(profileData, 'lastName')
  const email = get(profileData, 'identityId')
  const roles = get(profileData, 'roles', [])

  const isLoading = profileDataState === DATA_STATE_REQUESTING

  const getProfileData = useCallback(() => {
    if (accountId) {
      getProfile(authUser, accountId)
    }
  }, [accountId, authUser, getProfile])

  useDidMount(() => {
    getProfileData()
  })

  //  watch the dataStates from redux of account state
  useDataStatesComparison(() => {
    getProfileData()
  }, listWatchersState)

  return (
    <ProfileData
      firstName={firstName}
      lastName={lastName}
      email={email}
      roles={roles}
      isLoading={isLoading}
    />
  )
}

ProfileInfo.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profileData: PropTypes.object,
  profileDataState: PropTypes.string.isRequired,
  authUser: PropTypes.object.isRequired,
  listWatchersState: PropTypes.array
}

ProfileInfo.defaultProps = {
  profileData: null,
  listWatchersState: []
}
