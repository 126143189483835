import { useState } from 'react'

export function useFullScreenApi(defaultValue) {
  const [fullScreen, setFullScreen] = useState(defaultValue)

  const toggleFullScreen = () => {
    setFullScreen(value => !value)
  }

  const minimizeFullScreen = () => {
    setFullScreen(false)
  }

  const maximizeFullScreen = () => {
    setFullScreen(true)
  }

  return {
    active: fullScreen,
    minimize: minimizeFullScreen,
    maximize: maximizeFullScreen,
    toggle: toggleFullScreen
  }
}
