/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { STATUS_ACTIVE, STATUS_TESTING } from 'constants/entityStatus'
import { switchTesting, activateAPP } from 'services/http/activity'

export const STATUS_REQUEST_MAP = {
  [STATUS_ACTIVE]: activateAPP,
  [STATUS_TESTING]: switchTesting
}
