/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  FormContent,
  ControlButtonsContainer,
  TextField,
  ImageField,
  TextAreaField
} from 'components/FormComponents'
import isEmpty from 'lodash/isEmpty'
import { useDidMount } from 'hooks'
import { Form } from 'informed'
import { BasicButton } from '@xplorie/ui-commons'
import { canUpdateMarketing } from 'modules/Locations'
import { ORANGE_SCHEMA, SUBMIT_TITLE } from 'constants/buttonsOptions'
import { linkValidate, symbolCountValidation } from 'helpers/formHelpers'
import {
  META_TITLE_LABEL,
  META_TITLE_PLACEHOLDER,
  META_TITLE_FIELD,
  DISPLAY_NAME_LABEL,
  DISPLAY_NAME_PLACEHOLDER,
  DISPLAY_NAME_FIELD,
  COVER_IMAGE_LABEL,
  COVER_IMAGE_PLACEHOLDER,
  COVER_IMAGE_FIELD,
  META_DESCRIPTION_PLACEHOLDER,
  META_DESCRIPTION_LABEL,
  META_DESCRIPTION_FIELD,
  META_DESCRIPTION_MAX_SYMBOLS_COUNT,
  META_KEYWORDS_LABEL,
  META_KEYWORDS_PLACEHOLDER,
  META_KEYWORDS_FIELD,
  DESTINATION_SQ_LABEL,
  DESTINATION_SQ_PLACEHOLDER,
  DESTINATION_SQ_FIELD,
  DESTINATION_MAP_LABEL,
  DESTINATION_MAP_PLACEHOLDER,
  DESTINATION_MAP_FIELD
} from './types'

export function MarketingForm(props) {
  const { authUser, onChange, formData, dataId, updateLocationMarketing } = props
  const formApi = useRef()
  const canUpdate = useMemo(() => canUpdateMarketing(authUser), [authUser])
  const isFormDisabled = useMemo(() => !canUpdate, [canUpdate])
  const [isSubmitDisabled, setIsSubmitDisabledState] = useState(true)

  const onChangeHandler = useCallback(
    formState => {
      const { invalid, pristine, touched } = formState
      const isTouched = !isEmpty(touched)
      const submitDisabled = isFormDisabled || invalid || pristine || !isTouched
      setIsSubmitDisabledState(submitDisabled)
      if (onChange) {
        onChange(formState)
      }
    },
    [isFormDisabled, onChange]
  )

  const setFormValues = useCallback(
    values => {
      if (values) {
        formApi.current.setValues(formData)
      }
    },
    [formData]
  )

  useDidMount(() => {
    setFormValues(formData)
  })

  const onGetApi = useCallback(api => {
    formApi.current = api
  }, [])

  const descriptionValidation = useCallback(
    value => symbolCountValidation(value, META_DESCRIPTION_MAX_SYMBOLS_COUNT),
    []
  )

  const onSubmitHandler = useCallback(
    formValues => {
      if (formValues) {
        updateLocationMarketing(dataId, formValues)
      }
    },
    [dataId, updateLocationMarketing]
  )

  const onClickSubmitHandler = useCallback(() => {
    formApi.current.submitForm()
  }, [])

  return (
    <Form onChange={onChangeHandler} getApi={onGetApi} onSubmit={onSubmitHandler}>
      <FormContent disabled={isFormDisabled}>
        <TextField
          fieldName={DISPLAY_NAME_FIELD}
          label={DISPLAY_NAME_LABEL}
          placeholder={DISPLAY_NAME_PLACEHOLDER}
        />
        <TextField
          fieldName={META_TITLE_FIELD}
          label={META_TITLE_LABEL}
          placeholder={META_TITLE_PLACEHOLDER}
        />
        <TextAreaField
          fieldName={META_DESCRIPTION_FIELD}
          label={META_DESCRIPTION_LABEL}
          placeholder={META_DESCRIPTION_PLACEHOLDER}
          validate={descriptionValidation}
        />
        <TextAreaField
          fieldName={META_KEYWORDS_FIELD}
          label={META_KEYWORDS_LABEL}
          placeholder={META_KEYWORDS_PLACEHOLDER}
        />
        <ImageField
          fieldName={COVER_IMAGE_FIELD}
          label={COVER_IMAGE_LABEL}
          validate={linkValidate}
          placeholder={COVER_IMAGE_PLACEHOLDER}
        />
        <ImageField
          fieldName={DESTINATION_SQ_FIELD}
          label={DESTINATION_SQ_LABEL}
          placeholder={DESTINATION_SQ_PLACEHOLDER}
          validate={linkValidate}
        />
        <TextField
          fieldName={DESTINATION_MAP_FIELD}
          label={DESTINATION_MAP_LABEL}
          placeholder={DESTINATION_MAP_PLACEHOLDER}
          validate={linkValidate}
        />
        <ControlButtonsContainer>
          <BasicButton
            colorSchema={ORANGE_SCHEMA}
            disabled={isSubmitDisabled}
            onClick={onClickSubmitHandler}
          >
            {SUBMIT_TITLE}
          </BasicButton>
        </ControlButtonsContainer>
      </FormContent>
    </Form>
  )
}
MarketingForm.propTypes = {
  formData: PropTypes.object,
  authUser: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  dataId: PropTypes.string.isRequired,
  updateLocationMarketing: PropTypes.func.isRequired
}

MarketingForm.defaultProps = {
  onChange: null,
  formData: null
}
