/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { BasicButton } from '@xplorie/ui-commons'
import { BasicModal } from 'components/Modals'
import { withModalWindow } from 'decorators'
import {
  ORANGE_SCHEMA,
  YELLOW_GREEN_SCHEMA,
  DELETE_TITLE,
  ACTIVATE_TITLE,
  DEACTIVATE_TITLE,
  YES_TITLE,
  NO_TITLE
} from 'constants/buttonsOptions'
import { DELETE_MODAL_TITLE, DELETE_MODAL_MESSAGE } from 'modules/Discounts/types'
import styles from './ControlPanel.scss'

const ButtonWithModal = withModalWindow(BasicButton)

export function ControlPanel(props) {
  const {
    isDeleteAllowed,
    isActivateAllowed,
    isDeactivateAllowed,
    isActivateDisabled,
    onDelete,
    onActivate,
    onDeactivate
  } = props
  const onClickActivateHandler = useCallback(() => {
    if (isActivateAllowed) {
      onActivate()
    }
  }, [isActivateAllowed, onActivate])

  const onClickDeactivateHandler = useCallback(() => {
    if (isDeactivateAllowed) {
      onDeactivate()
    }
  }, [isDeactivateAllowed, onDeactivate])

  const onClickDeleteHandler = useCallback(() => {
    if (isDeleteAllowed) {
      onDelete()
    }
  }, [isDeleteAllowed, onDelete])

  return (
    <div className={styles.buttonsWrapper}>
      {isActivateAllowed && (
        <BasicButton
          colorSchema={ORANGE_SCHEMA}
          onClick={onClickActivateHandler}
          disabled={isActivateDisabled}
        >
          {ACTIVATE_TITLE}
        </BasicButton>
      )}
      {isDeactivateAllowed && (
        <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onClickDeactivateHandler}>
          {DEACTIVATE_TITLE}
        </BasicButton>
      )}
      {isDeleteAllowed && (
        <ButtonWithModal
          colorSchema={ORANGE_SCHEMA}
          onAction={onClickDeleteHandler}
          renderModal={({ isShown, onConfirm, onHide }) => (
            <BasicModal
              isOpen={isShown}
              onClose={onHide}
              message={DELETE_MODAL_MESSAGE}
              title={DELETE_MODAL_TITLE}
            >
              <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onConfirm}>
                {YES_TITLE}
              </BasicButton>
              <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onHide}>
                {NO_TITLE}
              </BasicButton>
            </BasicModal>
          )}
        >
          {DELETE_TITLE}
        </ButtonWithModal>
      )}
    </div>
  )
}
ControlPanel.propTypes = {
  isDeleteAllowed: PropTypes.bool.isRequired,
  isActivateAllowed: PropTypes.bool.isRequired,
  isDeactivateAllowed: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  isExist: PropTypes.bool.isRequired,
  isActivateDisabled: PropTypes.bool.isRequired
}
