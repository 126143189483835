/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, YELLOW_GREEN_SCHEMA, YES_TITLE, NO_TITLE } from 'constants/buttonsOptions'
import styles from './styles.scss'

export function DeactivateWarning({ onClose, onAction, Message }) {
  return (
    <div className={styles.bodyWrapper}>
      {Message}
      <div className={styles.buttons}>
        <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onAction}>
          {YES_TITLE}
        </BasicButton>
        <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
          {NO_TITLE}
        </BasicButton>
      </div>
    </div>
  )
}
DeactivateWarning.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  Message: PropTypes.node.isRequired
}
DeactivateWarning.defaultProps = {}
