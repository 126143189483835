import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { FORMAT } from 'constants/date'
import { WEEK_SELECTOR_DAYS } from '@xplorie/ui-commons'
import indexOf from 'lodash/indexOf'
import filter from 'lodash/filter'
import { createTimePeriodWithUniqId } from '../mappers'
import { FULL_DAY_TIME_PERIOD } from './types'
import { WEEK_DAYS_FIELD } from '../ScheduleForm/TimePeriodsList/types'

const moment = extendMoment(Moment)

const WEEK_SELECTOR_MAP = {
  [WEEK_SELECTOR_DAYS.MONDAY]: { isoWeekday: 1, label: 'Mon' },
  [WEEK_SELECTOR_DAYS.TUESDAY]: { isoWeekday: 2, label: 'Tue' },
  [WEEK_SELECTOR_DAYS.WEDNESDAY]: { isoWeekday: 3, label: 'Wed' },
  [WEEK_SELECTOR_DAYS.THURSDAY]: { isoWeekday: 4, label: 'Thu' },
  [WEEK_SELECTOR_DAYS.FRIDAY]: { isoWeekday: 5, label: 'Fri' },
  [WEEK_SELECTOR_DAYS.SATURDAY]: { isoWeekday: 6, label: 'Sat' },
  [WEEK_SELECTOR_DAYS.SUNDAY]: { isoWeekday: 7, label: 'Sun' },
  [WEEK_SELECTOR_DAYS.ALL]: { label: 'All Days' }
}

export const DEFAULT_DAYS = [
  WEEK_SELECTOR_DAYS.MONDAY,
  WEEK_SELECTOR_DAYS.TUESDAY,
  WEEK_SELECTOR_DAYS.WEDNESDAY,
  WEEK_SELECTOR_DAYS.THURSDAY,
  WEEK_SELECTOR_DAYS.FRIDAY,
  WEEK_SELECTOR_DAYS.SATURDAY,
  WEEK_SELECTOR_DAYS.SUNDAY
]

export function useTimePeriodApi(formApi) {
  const appendTimePeriodsToForm = timePeriods => {
    const { values } = formApi.current.getState()

    formApi.current.setValues({
      ...values,
      timePeriods: {
        ...values.timePeriods,
        ...timePeriods
      }
    })
  }

  const fullDaysWeek = [1, 2, 3, 4, 5, 6, 7]

  const daysInRange = (start, end) => {
    const startDate = moment(start, FORMAT)
    const endDate = moment(end, FORMAT)

    const daysCount = endDate.diff(startDate, 'days')
    if (daysCount > 7) {
      return fullDaysWeek
    }

    const range = moment.range(startDate, endDate)
    const dayCodes = Array.from(range.by('days', { step: 1 }))

    return dayCodes.map(m => m.isoWeekday())
  }

  function filterAvailableWeekDays(availableDaysOfWeek, weekDaysValues = []) {
    return filter(
      weekDaysValues,
      code => indexOf(availableDaysOfWeek, WEEK_SELECTOR_MAP[code].isoWeekday) !== -1
    )
  }

  const getPeriodDays = (startDate, endDate, item) => {
    if (!startDate || !endDate) {
      return null
    }
    return filterAvailableWeekDays(
      daysInRange(startDate, endDate),
      item.weekDays ? item.weekDays : DEFAULT_DAYS
    )
  }

  const getTimePeriod = () => {
    const { values } = formApi.current.getState()

    return values.timePeriods
  }

  const setTimePeriodDays = () => {
    const { values } = formApi.current.getState()
    const range = values.datePeriod
    const timePeriodIds = keys(values.timePeriods)
    const isRangeExist = range && range.from && range.to

    timePeriodIds.forEach(timePeriodId => {
      values.timePeriods[timePeriodId] = {
        ...values.timePeriods[timePeriodId],
        weekDays: isRangeExist
          ? getPeriodDays(range.from, range.to, values.timePeriods[timePeriodId])
          : []
      }
    })

    formApi.current.setValues({
      ...values
    })
  }

  const addTimePeriod = timePeriod => {
    const { values } = formApi.current.getState()
    const newTimePeriod = {
      ...timePeriod,
      weekDays: getPeriodDays(values.datePeriod.from, values.datePeriod.to, timePeriod)
    }
    const timePeriods = createTimePeriodWithUniqId(values.timePeriods, newTimePeriod)

    appendTimePeriodsToForm(timePeriods)
  }

  const editTimePeriod = timePeriodId => timePeriodValue => {
    const timePeriods = { [timePeriodId]: timePeriodValue }

    appendTimePeriodsToForm(timePeriods)
  }

  const changeAllDay = allDay => {
    const { values } = formApi.current.getState()

    if (!allDay && (isEmpty(values.timePeriods) || values.timePeriods.weekDays)) {
      const fullDayTimePeriod = {
        ...FULL_DAY_TIME_PERIOD,
        weekDays: values.timePeriods.weekDays || []
      }
      const timePeriods = createTimePeriodWithUniqId({}, fullDayTimePeriod)

      formApi.current.setValues({
        ...values,
        timePeriods: {
          ...timePeriods
        }
      })
    }
  }

  const canSeeRemoveTimePeriod = () => {
    const { values } = formApi.current.getState()

    return keys(values.timePeriods).filter(key => key !== WEEK_DAYS_FIELD).length > 1
  }

  const removeTimePeriod = timePeriodId => () => {
    const { values } = formApi.current.getState()
    const { [timePeriodId]: removedTimePeriod, ...timePeriods } = values.timePeriods

    formApi.current.setValues({
      ...values,
      timePeriods
    })
  }

  const buildWeekSelectorMapper = (availableDaysOfWeek = [], datePeriod) => {
    const hasDatePeriod = datePeriod.from && datePeriod.to

    return keys(WEEK_SELECTOR_MAP).reduce((acc, dayCode) => {
      const { isoWeekday, ...restParams } = WEEK_SELECTOR_MAP[dayCode]
      const abc = {
        ...acc,
        [dayCode]: {
          ...restParams,
          disabled:
            !hasDatePeriod || (isoWeekday && indexOf(availableDaysOfWeek, isoWeekday) === -1)
        }
      }

      return abc
    }, {})
  }

  const getWeekSelectorMapper = datePeriod =>
    buildWeekSelectorMapper(daysInRange(datePeriod.from, datePeriod.to), datePeriod)

  return {
    appendTimePeriodsToForm,
    changeAllDay,
    getTimePeriod,
    addTimePeriod,
    editTimePeriod,
    canSeeRemoveTimePeriod,
    removeTimePeriod,
    setTimePeriodDays,
    getWeekSelectorMapper
  }
}
