/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import {
  getDealFormGroupsState,
  actions as dealFormGroupsActions
} from 'reducers/deals/dealFormGroups'
import { getActivities } from 'reducers/deals/dealFormActivities'
import { withDiscountsPermissions } from 'containers/Discounts'

import { NewActivitySection as NewActivitySectionComponent } from './NewActivitySection'

const mapStateToProps = state => {
  const dealFormGroupsState = getDealFormGroupsState(state)

  return {
    dataState: dealFormGroupsState.searchDataState,
    data: dealFormGroupsState.searchData,
    assignDataState: dealFormGroupsState.assignDataState,
    selectedActivities: getActivities(state),
    formTicketTypesDataState: dealFormGroupsState.formTicketTypesDataState,
    ticketTypesData: dealFormGroupsState.ticketTypesData
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const { dealId } = props
  const { formDealTicketTypes, searchActivitiesByDealId, resetSearchData } = dealFormGroupsActions

  return bindActionCreators(
    {
      resetSearch: resetSearchData,
      searchActivitiesByDealId: searchActivitiesByDealId.bind(null, dealId),
      formDealTicketTypes
    },
    dispatch
  )
}

export const NewActivitySection = compose(
  withDiscountsPermissions,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NewActivitySectionComponent)
