/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { BACK_END_API_VERSION_URL } from 'endpoints'
import { httpWrapper } from 'services/http/helpers'

export const backEndAPIVersion = () =>
  httpWrapper({
    url: BACK_END_API_VERSION_URL,
    method: 'get'
  })
