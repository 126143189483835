/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

export const NOT_INDICATED = 'not indicated'
export const NOT_LABELED = 'not labeled'
export const BLANK_VALUE = 'BLANK'
