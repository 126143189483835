/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { updateAccount, createAccount, getAccountById } from 'services/http/account'

import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_ACCOUNT,
  RESPONSE_ACCOUNT,
  RESPONSE_ACCOUNT_ERROR
} = helpers

const createAccountAction = (authKey, contractData = {}) => async dispatch => {
  const key = 'new'
  dispatch(actionCreater(REQUEST_FORM, { key }))
  const request = await createAccount(authKey, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM, { key }))
    return 'createAccountAction success'
  }

  return 'createAccountAction failed'
}

const updateAccountAction = (authKey, contractData = {}) => async dispatch => {
  const key = contractData && contractData.id
  dispatch(actionCreater(REQUEST_FORM, { key }))
  const request = await updateAccount(authKey, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM, { key }))
    dispatch(send(message))
    return 'updateAccountAction success'
  }

  return 'updateAccountAction failed'
}

const getAccount = (authKey, id) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_ACCOUNT, { key }))
  const request = await getAccountById(authKey, id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ACCOUNT_ERROR, { key }))
  })
  if (request) {
    dispatch(
      actionCreater(RESPONSE_ACCOUNT, {
        key,
        accountData: request.data
      })
    )
    return 'getAccount success'
  }

  return 'getAccount failed'
}

const resetForm = key => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key }))

export default {
  createAccountAction,
  updateAccountAction,
  resetForm,
  getAccount
}
