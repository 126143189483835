/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { asField } from 'informed'
import { FORMAT } from 'constants/date'
import { Field } from 'components/FormComponents'
import { RangePicker } from 'shared'
import classNames from 'classnames'
import get from 'lodash/get'
import { createRange, createValue } from './helpers'
import styles from './styles.scss'

export function RangePickerInputComponent(props) {
  const {
    fieldState: { value: fieldValue, error },
    fieldApi: { setValue, setTouched },
    fieldState,
    fieldApi,
    onChange,
    label,
    fieldProps,
    isRequired,
    id,
    format,
    ...rest
  } = props

  const handleChange = value => {
    const range = createValue(value, format)
    setValue(range)

    if (onChange) {
      onChange(range, value)
    }

    setTouched(true)
  }

  const value = createRange(fieldValue, format)

  return (
    <Field
      id={id}
      label={label}
      isRequired={isRequired}
      error={error}
      {...fieldProps}
      className={classNames(styles.fieldContent, get(fieldProps, 'className', ''))}
    >
      <RangePicker {...rest} id={id} format={format} value={value} onChange={handleChange} />
    </Field>
  )
}

RangePickerInputComponent.propTypes = {
  fieldApi: PropTypes.object.isRequired,
  fieldProps: PropTypes.object.isRequired,
  fieldState: PropTypes.object.isRequired,
  format: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func
}

RangePickerInputComponent.defaultProps = {
  label: null,
  onChange: null,
  isRequired: false,
  id: null,
  format: FORMAT
}

export const RangePickerInput = asField(RangePickerInputComponent)
