/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { withDiscountsPermissions } from 'containers/Discounts'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getDealFormState } from 'connectors/reducers/deals/dealForm'
import { DealOptionsForm as DealOptionsFormComponent } from './DealOptionsForm'

function mapStateToProps(state) {
  const dealState = getDealFormState(state)
  return {
    ...dealState
  }
}

export const DealOptionsForm = compose(
  withDiscountsPermissions,
  connect(mapStateToProps)
)(DealOptionsFormComponent)
