/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { BasicButton } from '@xplorie/ui-commons'
import { YELLOW_GREEN_SCHEMA } from 'constants/buttonsOptions'
import { MENU_TABS } from './helpers'

export function NavTabs(props) {
  const { setCurrentType, currentType } = props

  const onSetLibType = useCallback(
    type => () => {
      if (currentType !== type) {
        setCurrentType(type)
      }
    },
    [currentType, setCurrentType]
  )

  return MENU_TABS.map(({ title, type }) => (
    <BasicButton
      key={type}
      id={`lib_${type}-btn`}
      colorSchema={YELLOW_GREEN_SCHEMA}
      onClick={onSetLibType(type)}
      disabled={currentType === type}
    >
      {title}
    </BasicButton>
  ))
}

NavTabs.propTypes = {
  setCurrentType: PropTypes.func.isRequired,
  currentType: PropTypes.string.isRequired
}
