/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import get from 'lodash/get'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import {
  exportUnitsColumns,
  exportUnitsWithSmartHostColumns,
  CalendarIntegrationColumn,
  EmailCadenceColumn,
  getSmartHostDisableReasonValue
} from 'helpers/dataTablesHelpers'
import { DISABLED, ENABLED, PENDING_DISABLE, PENDING_ENABLE } from 'constants/smartHostStatus'
import { SmartHostCell, CalendarIntegrationCell, EmailCadenceCell } from './SmartHostCells'
import {
  DEVICE_TYPE_DOT,
  DEVICE_TYPE_SHOW,
  DEVICE_TYPE_DOT_LABEL,
  DEVICE_TYPE_SHOW_LABEL,
  DEVICE_TYPE_NONE_LABEL,
  POPUP_DISABLE_LABEL,
  POPUP_ENABLE_LABEL
} from './types'
import styles from './styles.scss'

function getSmartHostEnabled(programData) {
  return get(programData, 'programInfo.smartHost.enabled', false)
}

function getCalendarIntegrationEnabled(programData) {
  return get(programData, 'programInfo.smartHost.calendarIntegration', false)
}

function getEmailCadenceEnabled(programData) {
  return get(programData, 'programInfo.smartHost.emailCadence', false)
}

function getSmartHostStatusByDate(isDisableAction) {
  return isDisableAction ? DISABLED : ENABLED
}

export function buildSmartHostPayload(isDisableAction) {
  return formData => {
    const { description, date } = formData
    const status = getSmartHostStatusByDate(isDisableAction)

    return omitBy(
      {
        description,
        date,
        status,
        calendarIntegration: !isDisableAction,
        emailCadence: false
      },
      isUndefined
    )
  }
}

export function buildSmartHostExportUnitsColumns(programData) {
  const isSmartHostEnabled = getSmartHostEnabled(programData)
  const isCalendarIntegrationEnabled = getCalendarIntegrationEnabled(programData)
  const isEmailCadenceEnabled = getEmailCadenceEnabled(programData)
  const unitsWithSmartHostColumns = [...exportUnitsWithSmartHostColumns]

  if (!isSmartHostEnabled) {
    return exportUnitsColumns
  }

  if (isCalendarIntegrationEnabled) {
    unitsWithSmartHostColumns.push(CalendarIntegrationColumn)
  }

  if (isEmailCadenceEnabled) {
    unitsWithSmartHostColumns.push(EmailCadenceColumn)
  }

  return unitsWithSmartHostColumns
}

export function buildPopupOptions(smartHostStatus) {
  const options = []

  if ([DISABLED, PENDING_DISABLE, PENDING_ENABLE].includes(smartHostStatus)) {
    options.push({ label: POPUP_ENABLE_LABEL })
  }

  if ([ENABLED, PENDING_DISABLE, PENDING_ENABLE].includes(smartHostStatus)) {
    options.push({ label: POPUP_DISABLE_LABEL })
  }

  return options
}

const getDeviceTypeLabel = type => {
  switch (type) {
    case DEVICE_TYPE_DOT:
      return DEVICE_TYPE_DOT_LABEL
    case DEVICE_TYPE_SHOW:
      return DEVICE_TYPE_SHOW_LABEL
    default:
      return DEVICE_TYPE_NONE_LABEL
  }
}

export function buildTableColumns(props) {
  const { programData, dataId, columns, actions } = props
  const isSmartHostEnabled = getSmartHostEnabled(programData)
  const isCalendarIntegrationEnabled = getCalendarIntegrationEnabled(programData)
  const isEmailCadenceEnabled = getEmailCadenceEnabled(programData)

  const tableColumns = [...columns]

  const smartHostColumns = [
    {
      Header: 'Device type',
      accessor: value => {
        const type = get(value, 'smartHost.device.type')
        return getDeviceTypeLabel(type)
      },
      id: 'smartHost.deviceType'
    },
    {
      Header: 'SmartHost enabled',
      accessor: value => get(value, 'smartHost'),
      id: 'smartHost.status',
      className: styles.smartHostColumn,
      Cell: cellData => <SmartHostCell cellData={cellData} dataId={dataId} />
    },
    {
      Header: 'SmartHost Disable Reason',
      id: 'smartHost.disablingReason',
      accessor: value => getSmartHostDisableReasonValue(get(value, 'smartHost.description')),
      pathToProperty: 'smartHost.description'
    },
    {
      Header: 'SmartHost Date',
      accessor: 'smartHost.date',
      id: 'smartHost.statusUpdateDate'
    }
  ]

  const calendarIntegrationColumn = {
    Header: 'Calendar Integration',
    accessor: value => get(value, 'smartHost.calendarIntegration'),
    id: 'smartHost.calendarIntegration',
    className: styles.smartHostColumn,
    Cell: cellData => <CalendarIntegrationCell cellData={cellData} dataId={dataId} />
  }

  const emailCadenceColumn = {
    Header: 'Email Cadence',
    accessor: value => get(value, 'smartHost.emailCadence'),
    id: 'smartHost.emailCadence',
    className: styles.smartHostColumn,
    Cell: cellData => <EmailCadenceCell cellData={cellData} dataId={dataId} />
  }

  if (isSmartHostEnabled) {
    tableColumns.push(...smartHostColumns)

    if (isCalendarIntegrationEnabled) {
      tableColumns.push(calendarIntegrationColumn)
    }

    if (isEmailCadenceEnabled) {
      tableColumns.push(emailCadenceColumn)
    }
  }

  return [...tableColumns, ...actions]
}
