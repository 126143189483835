/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { PM_COMPANY, PM_SYSTEM } from 'constants/searchTypes'
import { PRIMARY_ADDRESS } from 'helpers/types/dataTablesTypes'
import { buildSortPropertyByType, convertBooleanToString } from 'helpers/tableHelpers'
import get from 'lodash/get'
import { findFirstPrimaryAddress } from 'helpers/formHelpers'

import { getStatusValue } from './commonHelpers'

export const pmcTableColumns = [
  {
    Header: 'PMC',
    accessor: 'name',
    id: buildSortPropertyByType('name', PM_COMPANY)
  },
  {
    Header: 'PMS',
    accessor: 'pmSystem.name',
    id: buildSortPropertyByType('name', PM_SYSTEM)
  },
  {
    Header: 'Total Unit Count',
    accessor: 'pmCompanyInfo.totalUnitCount',
    id: buildSortPropertyByType('totalUnitCount', PM_COMPANY)
  },
  {
    Header: 'State/Province',
    accessor: value => {
      const addressees = get(value, 'addresses.addressValues')
      const address = findFirstPrimaryAddress(addressees)
      return get(address, 'address.state')
    },
    pathToProperty: 'addresses.addressValues[0].address.state',
    id: buildSortPropertyByType('state', PRIMARY_ADDRESS)
  },
  {
    Header: 'Status',
    accessor: getStatusValue,
    id: buildSortPropertyByType('status', PM_COMPANY),
    pathToProperty: 'status'
  }
]

export const pmcReportColumns = [
  {
    Header: 'PMC Name',
    accessor: 'pmCompanyName'
  },
  {
    Header: 'Program Name',
    accessor: 'programName'
  },
  {
    Header: 'Program type',
    accessor: 'programType'
  },
  {
    Header: 'Unit Count',
    accessor: 'unitCount'
  },
  {
    Header: 'Program Commencement Date',
    accessor: 'programCommencementDate'
  },
  {
    Header: 'Program Renewal Date',
    accessor: 'programRenewalDate'
  },
  {
    Header: 'State',
    accessor: 'state'
  },
  {
    Header: 'Destinations',
    accessor: 'destination'
  },
  {
    Header: 'Market',
    accessor: 'programMarket'
  },
  {
    Header: 'PMS',
    accessor: 'pms'
  },
  {
    Header: 'Billing Type',
    accessor: 'programBillingType'
  },
  {
    Header: 'Prebook (Mode/Value)',
    accessor: 'prebook'
  },
  {
    Header: 'Prebook Rate',
    accessor: 'prebookRate'
  },
  {
    Header: 'Prebook Rate start date',
    accessor: 'prebookRateStartDate'
  },
  {
    Header: 'Unique Phone Number',
    accessor: 'programUniquePhoneNumber'
  },
  {
    Header: 'Keyword',
    accessor: 'programCallKeyword'
  },
  {
    Header: 'Xplorie Mobile URL',
    accessor: 'programXplorieMobileURL'
  },
  {
    Header: 'Time Zone',
    accessor: 'programTimeZone'
  },
  {
    Header: 'Text Cadence?',
    accessor: 'programCadenceText',
    Cell: props => {
      const value = get(props, 'value', null)
      return convertBooleanToString(value)
    }
  },
  {
    Header: 'Email Cadence?',
    accessor: 'programCadenceEmail',
    Cell: props => {
      const value = get(props, 'value', null)
      return convertBooleanToString(value)
    }
  },
  {
    Header: 'SmartHost?',
    accessor: 'programSmartHostEnabled',
    Cell: props => {
      const value = get(props, 'value', null)
      return convertBooleanToString(value)
    }
  },
  {
    Header: 'Disable Units?',
    accessor: 'programUnitsDisabled',
    Cell: props => {
      const value = get(props, 'value', null)
      return convertBooleanToString(value)
    }
  },
  {
    Header: 'Disable Reservation #?',
    accessor: 'programReservationNumbersDisabled',
    Cell: props => {
      const value = get(props, 'value', null)
      return convertBooleanToString(value)
    }
  },
  {
    Header: 'Email',
    accessor: 'programEmail'
  },
  {
    Header: 'Phone Number',
    accessor: 'programContactPhoneNumber'
  },
  {
    Header: 'Additional Phone Number',
    accessor: 'programAdditionalContactPhoneNumber'
  },
  {
    Header: 'Confirmation Image URL',
    accessor: 'programConfirmationImageUrl'
  },
  {
    Header: 'Phone Image URL',
    accessor: 'programPhoneImageUrl'
  },
  {
    Header: 'PMC Square Image URL',
    accessor: 'programPmcSquareImageUrl'
  },
  {
    Header: 'Welcome Hero Image URL',
    accessor: 'programWelcomeHeroImageUrl'
  },
  {
    Header: 'Landing Site URL',
    accessor: 'programLandingSiteUrl'
  },
  {
    Header: 'PMC Description',
    accessor: 'programPmcDescription'
  },
  {
    Header: 'Card Type',
    accessor: 'pmCompanyCardType'
  },
  {
    Header: 'Flyers',
    accessor: 'pmCompanyFlyers'
  },
  {
    Header: 'Stands',
    accessor: 'pmCompanyStands'
  },
  {
    Header: 'Poster',
    accessor: 'pmCompanyPoster'
  },
  {
    Header: 'Source ID',
    accessor: 'pmCompanySourceId'
  },
  {
    Header: 'PMC Website',
    accessor: 'pmCompanyWebsite'
  },
  {
    Header: 'Address line 1',
    accessor: 'addressLine'
  },
  {
    Header: 'Address line 2',
    accessor: 'addressLine2'
  },
  {
    Header: 'Address City',
    accessor: 'addressCity'
  },
  {
    Header: 'Address State',
    accessor: 'addressState'
  },
  {
    Header: 'Address Zip',
    accessor: 'addressZip'
  },
  {
    Header: 'Description',
    accessor: 'pmCompanyDescription'
  }
]
