/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { GroupSelect } from '@xplorie/ui-commons'
import { asField } from 'informed'
import { Field } from 'components/FormComponents'

import { DEFAULT_PLACEHOLDER, LOADING_MESSAGE } from './types'

function SelectComponent({
  disabled,
  options,
  placeholder,
  isRequired,
  label,
  fieldProps,
  onChange,
  isLoading,
  convertToFormValue,
  convertToShownValue,
  fieldApi,
  fieldState: { value, error }
}) {
  const onChangeHandler = useCallback(
    event => {
      const selectValue = convertToFormValue
        ? convertToFormValue(event.target.value)
        : event.target.value

      fieldApi.setTouched(true)
      fieldApi.setValue(selectValue)

      if (onChange) {
        onChange(selectValue, event)
      }
    },
    [fieldApi, onChange, convertToFormValue]
  )

  const selectValue = useMemo(() => convertToShownValue(value), [convertToShownValue, value])
  const dynamicPlaceholder = useMemo(() => (isLoading ? LOADING_MESSAGE : placeholder), [
    isLoading,
    placeholder
  ])

  return (
    <Field label={label} isRequired={isRequired} error={error} {...fieldProps}>
      <GroupSelect
        options={options}
        placeholder={dynamicPlaceholder}
        disabled={disabled}
        value={selectValue}
        onChange={onChangeHandler}
      />
    </Field>
  )
}

SelectComponent.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  fieldApi: PropTypes.object.isRequired,
  fieldState: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  fieldProps: PropTypes.object,
  onChange: PropTypes.func,
  convertToShownValue: PropTypes.func,
  convertToFormValue: PropTypes.func,
  isLoading: PropTypes.bool
}

SelectComponent.defaultProps = {
  label: null,
  disabled: false,
  isRequired: false,
  fieldProps: {},
  onChange: null,
  convertToFormValue: null,
  convertToShownValue: value => value,
  placeholder: DEFAULT_PLACEHOLDER,
  isLoading: false
}

export const Select = asField(SelectComponent)
