/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getLocationCampaignsById } from 'services/http/locations'
import { CAMPAIGN } from 'constants/searchTypes'

import helpers from './helpers'

const {
  REQUEST_CAMPAIGNS,
  RESPONSE_CAMPAIGNS,
  RESPONSE_CAMPAIGNS_ERROR,
  RESET_DATA_STATE
} = helpers

const getLocationCampaigns = (contractData = []) => async dispatch => {
  dispatch(actionCreater(REQUEST_CAMPAIGNS))
  try {
    const request = await getLocationCampaignsById(
      {
        keyword: '',
        type: CAMPAIGN,
        itemsPerPage: 0
      },
      contractData
    )
    const { data } = request
    dispatch(actionCreater(RESPONSE_CAMPAIGNS, { ...data }))

    return data
  } catch ({ send, message }) {
    if (send) {
      dispatch(send(message))
    }
    dispatch(actionCreater(RESPONSE_CAMPAIGNS_ERROR))

    return []
  }
}

const resetForm = () => dispatch => {
  dispatch(actionCreater(RESET_DATA_STATE))
}

export default {
  resetForm,
  getLocationCampaigns
}
