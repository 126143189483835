/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { CurrencyIcon } from 'icons'
import styles from '../SmartHostFeeFormBox.scss'

function SmartHostFeeFormItem(props) {
  const { label, fieldName, Field, hasCurrency, ...rest } = props
  const fieldProps = useMemo(
    () =>
      hasCurrency && {
        // eslint-disable-next-line react/prop-types
        renderInner: ({ children, errorClassName, error }) => (
          <div>
            <span className={styles.currency}>
              <CurrencyIcon />
            </span>
            {children}
            {error && <span className={errorClassName}>{error}</span>}
          </div>
        ),
        classNameWrapper: styles.wrapper
      },
    [hasCurrency]
  )

  return (
    <div className={styles.item}>
      <span className={styles.label}>{label}</span>
      <Field fieldName={fieldName} fieldProps={fieldProps} isRequired {...rest} />
    </div>
  )
}

SmartHostFeeFormItem.propTypes = {
  Field: PropTypes.any.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  hasCurrency: PropTypes.bool
}

SmartHostFeeFormItem.defaultProps = {
  hasCurrency: false,
  label: ''
}

export default SmartHostFeeFormItem
