/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/pmcsList'
import { withAuthContext } from 'helpers/withAuthContext'
import { actions as editQueueActions } from 'reducers/editQueue'

import PMCs from './PMCs'

const { getPmcsList } = actions

const mapStateToProps = state => ({
  panelStack: state.editQueue.panelStack
})

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onGetData: getPmcsList,
      ...editQueueActions
    },
    dispatch
  )

const PMCsConnect = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PMCs)

export default PMCsConnect
