/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import ticketGroupFormHelpers from '../../ticketGroupForm/helpers'
import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_UPLOAD_TEMPLATE,
  RESPONSE_UPLOAD_TEMPLATE,
  RESPONSE_UPLOAD_TEMPLATE_ERROR,

  REQUEST_REMOVE_TEMPLATE,
  RESPONSE_REMOVE_TEMPLATE,
  RESPONSE_REMOVE_TEMPLATE_ERROR,

  REQUEST_DOWNLOAD_TEMPLATE,
  RESPONSE_DOWNLOAD_TEMPLATE,
  RESPONSE_DOWNLOAD_TEMPLATE_ERROR,

  REQUEST_DOWNLOAD_BARCODE_TEMPLATE,
  RESPONSE_DOWNLOAD_BARCODE_TEMPLATE,
  RESPONSE_DOWNLOAD_BARCODE_TEMPLATE_ERROR
} = helpers

export const initialState = {
  uploadDataState: DATA_STATE_NOT_REQUESTED,
  downloadDataState: DATA_STATE_NOT_REQUESTED,
  removeDataState: DATA_STATE_NOT_REQUESTED,
  downloadBarcodeTemplateDataState: DATA_STATE_NOT_REQUESTED
}

const ticketGroupTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    // upload template
    case REQUEST_UPLOAD_TEMPLATE:
      return {
        ...state,
        uploadDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_UPLOAD_TEMPLATE:
      return {
        ...state,
        uploadDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_UPLOAD_TEMPLATE_ERROR:
      return {
        ...state,
        uploadDataState: DATA_STATE_ERROR
      }
    // download template
    case REQUEST_DOWNLOAD_TEMPLATE:
      return {
        ...state,
        downloadDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_DOWNLOAD_TEMPLATE:
      return {
        ...state,
        downloadDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_DOWNLOAD_TEMPLATE_ERROR:
      return {
        ...state,
        downloadDataState: DATA_STATE_ERROR
      }
    // remove template
    case REQUEST_REMOVE_TEMPLATE:
      return {
        ...state,
        removeDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_REMOVE_TEMPLATE:
      return {
        ...state,
        removeDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_REMOVE_TEMPLATE_ERROR:
      return {
        ...state,
        removeDataState: DATA_STATE_ERROR
      }
    // download barcode template
    case REQUEST_DOWNLOAD_BARCODE_TEMPLATE:
      return {
        ...state,
        downloadBarcodeTemplateDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_DOWNLOAD_BARCODE_TEMPLATE:
      return {
        ...state,
        downloadBarcodeTemplateDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_DOWNLOAD_BARCODE_TEMPLATE_ERROR:
      return {
        ...state,
        downloadBarcodeTemplateDataState: DATA_STATE_ERROR
      }
    case RESET_DATA_STATE:
      return {
        ...initialState
      }
    case ticketGroupFormHelpers.RESET_DATA_STATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default filterActions(ticketGroupTemplateReducer, [
  REQUEST_UPLOAD_TEMPLATE,
  RESPONSE_UPLOAD_TEMPLATE,
  RESPONSE_UPLOAD_TEMPLATE_ERROR,
  REQUEST_REMOVE_TEMPLATE,
  RESPONSE_REMOVE_TEMPLATE,
  RESPONSE_REMOVE_TEMPLATE_ERROR,
  REQUEST_DOWNLOAD_TEMPLATE,
  RESPONSE_DOWNLOAD_TEMPLATE,
  RESPONSE_DOWNLOAD_TEMPLATE_ERROR,
  REQUEST_DOWNLOAD_BARCODE_TEMPLATE,
  RESPONSE_DOWNLOAD_BARCODE_TEMPLATE,
  RESPONSE_DOWNLOAD_BARCODE_TEMPLATE_ERROR,
  RESET_DATA_STATE,
  ticketGroupFormHelpers.RESET_DATA_STATE
])
