/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function RefreshIcon(props) {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19.584 4.667L15.25 9h3.25c0 3.586-2.914 6.5-6.5 6.5a6.36 6.36 0 01-3.033-.758l-1.582 1.581A8.592 8.592 0 0012 17.667 8.664 8.664 0 0020.667 9h3.25l-4.334-4.333zM5.5 9c0-3.586 2.914-6.5 6.5-6.5a6.36 6.36 0 013.034.758l1.581-1.581A8.592 8.592 0 0012 .333 8.664 8.664 0 003.334 9H.084l4.333 4.333L8.75 9H5.5z" />
    </svg>
  )
}
