/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { LOCATION_PROGRAMS_AND_ACTIVITIES_NAME } from './types'

const REQUEST_PROGRAMS_AND_ACTIVITIES = `${REQUEST_TYPE}_${LOCATION_PROGRAMS_AND_ACTIVITIES_NAME}`
const RESPONSE_PROGRAMS_AND_ACTIVITIES = `${RESPONSE_TYPE}_${LOCATION_PROGRAMS_AND_ACTIVITIES_NAME}`
const RESPONSE_PROGRAMS_AND_ACTIVITIES_ERROR = `${RESPONSE_ERROR_TYPE}_${LOCATION_PROGRAMS_AND_ACTIVITIES_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${LOCATION_PROGRAMS_AND_ACTIVITIES_NAME}`

export default {
  REQUEST_PROGRAMS_AND_ACTIVITIES,
  RESPONSE_PROGRAMS_AND_ACTIVITIES,
  RESPONSE_PROGRAMS_AND_ACTIVITIES_ERROR,
  RESET_DATA_STATE
}
