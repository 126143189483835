/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { getListByProgram } from 'services/http/unitTasks'

import helpers, { buildAccessKey } from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST, RESET_DATA_STATE } = helpers

const getUnitTasksByProgramList = (pmcId, programId, options) => async dispatch => {
  const key = buildAccessKey(pmcId, programId)
  dispatch(actionCreater(REQUEST_LIST, { key }))
  const payload = programId ? { programId } : { pmcId }
  const request = await getListByProgram({ ...payload, ...options }).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { data: request.data, key }))
    return 'getUnitTasksByProgramList success'
  }

  return 'getUnitTasksByProgramList failed'
}

const resetList = (pmcId, programId) => dispatch =>
  dispatch(actionCreater(RESET_DATA_STATE, { key: buildAccessKey(pmcId, programId) }))

export default {
  getUnitTasksByProgramList,
  resetList
}
