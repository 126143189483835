/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { useUpdateSomeDataState } from 'hooks'
import { DATA_STATE_ERROR } from 'helpers/actionHelpers'

export function ReduxModalsContainer({ modals, dequeue, authDataState, resetAll }) {
  useUpdateSomeDataState(
    () => {
      resetAll()
    },
    [authDataState],
    DATA_STATE_ERROR
  )

  return modals.map(({ Component, id, props }) => (
    <Component key={id} dequeue={dequeue.bind(null, id)} {...props} />
  ))
}
