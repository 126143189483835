/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { useAuthUser, useLoading, useUpdateSomeDataState, useAction } from 'hooks'
import { getActivityProductForm, formActions } from 'reducers/activityProductForm'
import { canEditSchedulesForm, convertToContractValues } from 'modules/ActivityProducts'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { useSelector } from 'react-redux'
import { useShowCalendar } from './useShowCalendar'

export function useTabPresenter(props) {
  const { readOnly, onClose, fullScreen } = props
  const { entityData, entityDataState, dataState } = useSelector(state =>
    getActivityProductForm(state)
  )
  const authUser = useAuthUser()
  const updateFormData = useAction(formActions.putProductForm)
  const [isLoading] = useLoading(entityDataState)
  const [formRequesting] = useLoading(dataState)
  const { showCalendar, handleShowCalendar } = useShowCalendar(fullScreen)

  const formDisabled = Boolean(!canEditSchedulesForm(authUser) || readOnly || formRequesting)

  const handleAvailabilitySubmit = values => {
    updateFormData(null, {
      ...convertToContractValues(entityData),
      ...values
    })
  }

  useUpdateSomeDataState(
    () => {
      onClose()
    },
    [dataState],
    DATA_STATE_RECIEVED
  )

  return {
    id: entityData.id,
    loading: isLoading,
    form: { disabled: formDisabled, data: entityData, onSubmit: handleAvailabilitySubmit },
    closures: { visible: showCalendar, onAction: handleShowCalendar }
  }
}
