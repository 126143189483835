/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import { YELLOW_GREEN_SCHEMA, ORANGE_SCHEMA, YES_TITLE, NO_TITLE } from 'constants/buttonsOptions'
import styles from './styles.scss'

export function ChangeAPPWarningModal({ isOpen, onClose, message, onSubmit, onCancel }) {
  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit()
    }

    onClose()
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }

    onClose()
  }
  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      wrapperClassName={styles.modalWrapper}
      renderBody={() => (
        <section className={styles.wrapper}>
          <span className={styles.message}>{message}</span>
        </section>
      )}
    >
      <BasicButton colorSchema={ORANGE_SCHEMA} onClick={handleSubmit}>
        {YES_TITLE}
      </BasicButton>
      <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={handleCancel}>
        {NO_TITLE}
      </BasicButton>
    </BasicModal>
  )
}

ChangeAPPWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}
