/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_CHANGE_STATUS_BULK,
  RESPONSE_CHANGE_STATUS_BULK,
  RESPONSE_ERROR_CHANGE_STATUS_BULK,
  RESET_DATA_STATE,
  REQUEST_UPLOAD_UNITS_FILE,
  RESPONSE_UPLOAD_UNITS_FILE,
  RESPONSE_ERROR_UPLOAD_UNITS_FILE
} = helpers

export const initialState = {
  changeStatusDataState: DATA_STATE_NOT_REQUESTED,
  uploadingDataState: DATA_STATE_NOT_REQUESTED
}

const lifeCycleReducer = (state = initialState, action) => {
  switch (action.type) {
    // changing status
    case REQUEST_CHANGE_STATUS_BULK:
      return {
        ...state,
        changeStatusDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_CHANGE_STATUS_BULK:
      return {
        ...state,
        changeStatusDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_ERROR_CHANGE_STATUS_BULK:
      return {
        ...state,
        changeStatusDataState: DATA_STATE_ERROR
      }
    // uploading
    case REQUEST_UPLOAD_UNITS_FILE:
      return {
        ...state,
        uploadingDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_UPLOAD_UNITS_FILE:
      return {
        ...state,
        uploadingDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_ERROR_UPLOAD_UNITS_FILE:
      return {
        ...state,
        uploadingDataState: DATA_STATE_ERROR
      }
    // reset
    case RESET_DATA_STATE:
      return { ...initialState }
    default:
      return state
  }
}

export default filterActions(lifeCycleReducer, [
  REQUEST_CHANGE_STATUS_BULK,
  RESPONSE_CHANGE_STATUS_BULK,
  RESPONSE_ERROR_CHANGE_STATUS_BULK,
  RESET_DATA_STATE,
  REQUEST_UPLOAD_UNITS_FILE,
  RESPONSE_UPLOAD_UNITS_FILE,
  RESPONSE_ERROR_UPLOAD_UNITS_FILE
])
