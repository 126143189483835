/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'
import get from 'lodash/get'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  SET_UPLOADING_PROGRESS,
  SET_CANCELLATION_TOKEN
} = helpers

export const initialState = {}

export const entityInitialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  progress: null,
  cancelToken: null
}

const formReducer = (state = initialState, action) => {
  const key = get(action, 'payload.key')
  const prevEntityState = { ...entityInitialState, ...state[key] }

  switch (action.type) {
    case REQUEST_FORM:
      return {
        ...state,
        [key]: {
          ...prevEntityState,
          dataState: DATA_STATE_REQUESTING,
          progress: null,
          cancelToken: null
        }
      }
    case RESPONSE_FORM_ERROR:
      return {
        ...state,
        [key]: {
          ...prevEntityState,
          dataState: DATA_STATE_ERROR,
          progress: null,
          cancelToken: null
        }
      }
    case RESPONSE_FORM:
      return {
        ...state,
        [key]: {
          ...prevEntityState,
          dataState: DATA_STATE_RECIEVED,
          progress: null,
          cancelToken: null
        }
      }
    case SET_UPLOADING_PROGRESS:
      return {
        ...state,
        [key]: {
          ...prevEntityState,
          progress: action.payload.progress
        }
      }
    case SET_CANCELLATION_TOKEN:
      return {
        ...state,
        [key]: {
          ...prevEntityState,
          cancelToken: action.payload.cancelToken
        }
      }
    case RESET_DATA_STATE: {
      const { [key]: removedState, ...restState } = state
      return {
        ...restState
      }
    }
    default:
      return state
  }
}

export default filterActions(formReducer, [
  REQUEST_FORM,
  RESPONSE_FORM_ERROR,
  RESPONSE_FORM,
  RESET_DATA_STATE,
  SET_UPLOADING_PROGRESS,
  SET_CANCELLATION_TOKEN
])
