/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import styles from './SeasonsValidateBoard.scss'

export const GetContentErrorMessages = props => {
  const { errors } = props

  return errors.map(itemErr => (
    <li key={itemErr.id} className={styles.listStyleIndent}>
      {itemErr.message}
    </li>
  ))
}
GetContentErrorMessages.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.object)
}
GetContentErrorMessages.defaultProps = {
  errors: []
}
