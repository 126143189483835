/* eslint-disable no-undef */
/* eslint-disable import/newline-after-import */
/* eslint-disable no-console */
/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { backEndAPIVersion } from 'services/http/service'
import get from 'lodash/get'
;(() => {
  if (typeof document !== 'undefined') {
    console.info(`%cApplication version: %c${uiAppVersion}`, 'color: #209cee', 'color: #00d1b2')

    window.API = {
      getVersion: function getVersion() {
        backEndAPIVersion()
          .then(response => {
            const apiVersion = get(response, 'data.api', 'NONE')
            const services = get(response, 'data.services', {})
            console.info(`%cAPI version: %c${apiVersion}`, 'color: #209cee', 'color: #f26a24')
            Object.entries(services).forEach(([key, value]) =>
              console.info(`%c${key}: %c${value}`, 'color: #b2d235', 'color: #00d1b2')
            )
          })
          .catch(() => {
            console.info(`%cFAILED`, 'color: red')
          })
      }
    }
  }
})()
