/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useEffect } from 'react'
import { Empty } from 'antd'
import { BouncingLoader } from 'components/Loaders'
import { Filter } from '@xplorie/ui-commons'
import PropTypes from 'prop-types'
import { useProgramHistoryApi } from './useProgramHistoryApi'
import { ProgramHistorySeason } from './ProgramHistorySeason'
import { getPreparedFilters } from './helpers'

export function ProgramHistory(props) {
  const { filters, setFilters, dataId } = props
  const [{ isLoading, history, onGetData }] = useProgramHistoryApi(props)

  useEffect(() => {
    onGetData(getPreparedFilters(filters))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const onApply = filter => setFilters(filter)

  if (isLoading) {
    return <BouncingLoader />
  }

  if (!history.length) {
    return (
      <>
        <Filter filters={filters} onChange={onApply} />
        <Empty />
      </>
    )
  }

  return (
    <>
      <Filter filters={filters} onChange={onApply} />
      {history.map(({ year }) => (
        <ProgramHistorySeason
          dataId={dataId}
          key={year}
          year={year}
          filters={getPreparedFilters(filters)}
        />
      ))}
    </>
  )
}

ProgramHistory.propTypes = {
  filters: PropTypes.array.isRequired,
  setFilters: PropTypes.func.isRequired,
  dataId: PropTypes.string.isRequired
}
