/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { PM_COMPANY, ACTIVITY } from 'constants/searchTypes'

import { PMC_MASTERLIST_TEXT, APP_MASTERLIST_TEXT } from './types'
import { setReportType } from './helpers'
import { ReportTab } from './ReportTab'

export function ReportsBuilderComponent(props) {
  const { reportType } = props
  const setReportTypeAction = setReportType.bind(null, props)
  return (
    <>
      <ReportTab
        id="pmc-master-btn"
        reportType={reportType}
        currentType={PM_COMPANY}
        setReportType={setReportTypeAction}
      >
        {PMC_MASTERLIST_TEXT}
      </ReportTab>
      <ReportTab
        id="app-master-btn"
        reportType={reportType}
        currentType={ACTIVITY}
        setReportType={setReportTypeAction}
      >
        {APP_MASTERLIST_TEXT}
      </ReportTab>
    </>
  )
}

ReportsBuilderComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  reportType: PropTypes.string
}
ReportsBuilderComponent.defaultProps = {
  reportType: ''
}
