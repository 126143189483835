/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useMemo, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  DATE_LABEL,
  PERFORMED_BY_LABEL,
  REASON_LABEL
} from 'modules/Programs/ProgramHistory/ProgramHistoryItem/types'
import { PROGRAM_DEACTIVATION_BY_SYSTEM_EVENT } from 'modules/Programs/ProgramHistory/types'
import {
  UNIT_NAME_LABEL,
  FIELD_NAME_LABEL,
  PREVIOUS_VALUE_LABEL,
  NEXT_VALUE_LABEL,
  ADDED_VALUE_LABEL,
  DELETED_VALUE_LABEL,
  NEXT_VALUE_LABEL_STATUS,
  PREVIOUS_VALUE_STATUS_LABEL
} from './types'

import styles from './styles.scss'
import {
  getDetailsConverter,
  getSystemDetailsConverter,
  isCreateAction,
  isDeleteAction,
  isUnitTypeEvent
} from './helpers'

export function ProgramHistoryContent(props) {
  const { item } = props
  const { details, event, action } = item

  const convertDetails = useMemo(() => getDetailsConverter(event), [event])
  const detailsView = useMemo(() => convertDetails(details), [details, convertDetails])

  const convertSystemDetails = useMemo(() => getSystemDetailsConverter(event), [event])
  const systemDetailsView = useMemo(
    () => (convertSystemDetails ? convertSystemDetails(details, event) : null),
    [details, convertSystemDetails]
  )

  const [isCreate, isDelete] = useMemo(() => [isCreateAction(action), isDeleteAction(action)], [
    action
  ])
  const isSingleCell = isCreate || isDelete

  const SystemAction = useMemo(() => {
    if (!systemDetailsView) {
      return null
    }
    const { reason, name, performedBy, dateTime } = systemDetailsView

    return (
      <>
        <div className={styles.systemAction}>
          <div>
            <span className={styles.systemActionLabel}>{REASON_LABEL}</span>
            {name && (
              <>
                <span className={styles.systemActionField}>{'The'}</span>
                <span className={styles.systemActionName}>{name}</span>
              </>
            )}
            <span className={styles.systemActionField}>{reason}</span>
          </div>
          <div>
            <span className={styles.systemActionLabel}>{PERFORMED_BY_LABEL}</span>
            <span className={styles.systemActionField}>{performedBy}</span>
          </div>
          <div>
            <span className={styles.systemActionLabel}>{DATE_LABEL}</span>
            <span className={styles.systemActionField}>{dateTime}</span>
          </div>
        </div>
      </>
    )
  }, [systemDetailsView])

  const HeaderColumns = useMemo(
    () => (
      <Fragment>
        {!isSingleCell && (
          <Fragment>
            <span className={styles.header}>
              {systemDetailsView ? PREVIOUS_VALUE_STATUS_LABEL : PREVIOUS_VALUE_LABEL}
            </span>
            <span className={styles.header}>
              {systemDetailsView ? NEXT_VALUE_LABEL_STATUS : NEXT_VALUE_LABEL}
            </span>
          </Fragment>
        )}
        {isCreate && (
          <span className={classNames(styles.header, styles.singleCell)}>{ADDED_VALUE_LABEL}</span>
        )}
        {isDelete && (
          <span className={classNames(styles.header, styles.singleCell)}>
            {DELETED_VALUE_LABEL}
          </span>
        )}
      </Fragment>
    ),
    [isSingleCell, isCreate, isDelete]
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>{SystemAction}</div>

      {event !== PROGRAM_DEACTIVATION_BY_SYSTEM_EVENT && (
        <>
          <div className={styles.row}>
            {isUnitTypeEvent(event) && <span className={styles.header}>{UNIT_NAME_LABEL}</span>}
            <span className={styles.header}>{FIELD_NAME_LABEL}</span>
            {HeaderColumns}
          </div>
          {detailsView.map(
            ({ id, fieldName, previousValue, nextValue, isAdded, isBold, isRemoved, unitName }) => {
              const addedClassName = classNames(styles.cell, {
                [styles.singleCell]: isSingleCell,
                [styles.bold]: isBold,
                [styles.added]: isAdded
              })
              const removedClassName = classNames(styles.cell, {
                [styles.bold]: isBold,
                [styles.removed]: isRemoved
              })
              return (
                <div className={styles.row} key={id || fieldName}>
                  {unitName && (
                    <span className={styles.cell} title={unitName}>
                      {unitName}
                    </span>
                  )}
                  <span className={styles.cell}>{fieldName}</span>
                  {isSingleCell ? (
                    <span className={addedClassName} title={nextValue}>
                      {isRemoved ? previousValue : nextValue}
                    </span>
                  ) : (
                    <Fragment>
                      <span className={addedClassName} title={previousValue}>
                        {previousValue}
                      </span>
                      <span className={removedClassName} title={nextValue}>
                        {nextValue}
                      </span>
                    </Fragment>
                  )}
                </div>
              )
            }
          )}
        </>
      )}
    </div>
  )
}

ProgramHistoryContent.propTypes = {
  item: PropTypes.shape({
    event: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired
  }).isRequired
}
