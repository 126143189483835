/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_GET_TICKET_TYPES,
  RESPONSE_GET_TICKET_TYPES,
  RESPONSE_GET_TICKET_TYPES_ERROR
} = helpers

export const initialState = {}

export const formInitialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  data: []
}

const buildData = state => data => ({
  ...state,
  ...data
})

const formReducer = (state = initialState, action) => {
  const appendData = buildData(state, action)

  switch (action.type) {
    // activate discount deal
    case REQUEST_GET_TICKET_TYPES:
      return appendData({ dataState: DATA_STATE_REQUESTING })
    case RESPONSE_GET_TICKET_TYPES:
      return appendData({ dataState: DATA_STATE_RECIEVED, data: action.payload })
    case RESPONSE_GET_TICKET_TYPES_ERROR:
      return appendData({ dataState: DATA_STATE_ERROR })
    // reset
    case RESET_DATA_STATE: {
      return {
        ...formInitialState
      }
    }
    default:
      return state
  }
}

export default filterActions(formReducer, [
  RESET_DATA_STATE,
  REQUEST_GET_TICKET_TYPES,
  RESPONSE_GET_TICKET_TYPES,
  RESPONSE_GET_TICKET_TYPES_ERROR
])
