/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const NAME = 'CAMPAIGNS_FORM_FORM'
const GET_ENTITY_DATA = `${NAME}_GET_ENTITY_DATA`
const GET_ENTITY_ACTIVITIES = `${NAME}_GET_ENTITY_ACTIVITIES`
const SAVE_ENTITY_ACTIVITIES = `${NAME}_SAVE_ENTITY_ACTIVITIES`
const UPDATE_STATE_LIST_ACTIVITIES = `${NAME}_UPDATE_STATE_LIST_ACTIVITIES`
const GET_ENTITY_ACTIVITIES_BY_LOCATION = `${NAME}_GET_ENTITY_ACTIVITIES_BY_LOCATION`
const CHANGE_STATUS = `${NAME}_CHANGE_STATUS`

export default {
  NAME,
  GET_ENTITY_DATA,
  GET_ENTITY_ACTIVITIES,
  SAVE_ENTITY_ACTIVITIES,
  GET_ENTITY_ACTIVITIES_BY_LOCATION,
  CHANGE_STATUS,
  UPDATE_STATE_LIST_ACTIVITIES
}
