/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { httpWrapper, buildListParams, buildURLForGettingList } from 'services/http/helpers'
import { ELIGIBILITY_ENDPOINT, PMCS_URL, PROGRAMS_URL, PMS_URL } from 'endpoints'

export const getList = options =>
  httpWrapper({
    url: buildURLForGettingList(ELIGIBILITY_ENDPOINT),
    method: 'get',
    params: buildListParams(options)
  })

export const getListValuesByPMCId = (id, options) =>
  httpWrapper({
    url: `${PMCS_URL}/${id}/eligibilities`,
    method: 'get',
    params: buildListParams(options)
  })

export const getListParametersByPMCId = (id, options) =>
  httpWrapper({
    url: `${PMCS_URL}/${id}/eligibilities/parameters`,
    method: 'get',
    params: buildListParams(options)
  })

export const updateEligibilityValuesByPmcIdBulk = (id, data = []) =>
  httpWrapper({
    url: `${PMCS_URL}/${id}/eligibilities`,
    method: 'put',
    data
  })

export const getProgramsEligibilityListValuesByPmcId = (pmcId, options) =>
  httpWrapper({
    url: `${PMCS_URL}/${pmcId}/programs/eligibilities`,
    method: 'get',
    params: buildListParams(options)
  })

export const getListParametersByProgramId = (id, options) =>
  httpWrapper({
    url: `${PROGRAMS_URL}/${id}/eligibilities/parameters`,
    method: 'get',
    params: buildListParams(options)
  })

export const getListValuesByProgramId = (id, options) =>
  httpWrapper({
    url: `${PROGRAMS_URL}/${id}/eligibilities`,
    method: 'get',
    params: buildListParams(options)
  })

export const updateProgramEligibilityValues = (id, data) =>
  httpWrapper({
    url: `${PROGRAMS_URL}/${id}/eligibilities`,
    method: 'put',
    data
  })

export const getPMCListValuesByProgramId = (id, options) =>
  httpWrapper({
    url: `${PROGRAMS_URL}/${id}/pmc/eligibilities`,
    method: 'get',
    params: buildListParams(options)
  })

// PMS Eligibilities calls
export const getListValuesByPmsId = (pmsId, options) =>
  httpWrapper({
    url: `${PMS_URL}/${pmsId}/eligibilities`,
    method: 'get',
    params: buildListParams(options)
  })
