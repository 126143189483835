/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose } from 'redux'
import get from 'lodash/get'
import { withAuthContext } from 'helpers/withAuthContext'
import { withLoader } from 'decorators'
import { isWillCallTicketFormat } from 'modules/ActivityProducts/ActivityResaleTab'
import { TicketTypeForm as TicketTypeFormComponent } from './TicketTypeForm'

const mapStateToProps = state => ({
  ...state.ticketGroupForm,
  isWillCallTicketFormat: isWillCallTicketFormat(
    get(state.ticketSettingsForm.entityData, 'ticketReservationSettings.ticketFormat')
  )
})

export const TicketTypeForm = compose(
  withAuthContext,
  withLoader,
  connect(mapStateToProps)
)(TicketTypeFormComponent)
