/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import uniqueId from 'lodash/uniqueId'
import values from 'lodash/values'
import keys from 'lodash/keys'
import reduce from 'lodash/reduce'
import isInteger from 'lodash/isInteger'
import isNumber from 'lodash/isNumber'

import { formatCostValue } from 'helpers/formHelpers'

import { RETAIL_PRICE_PATH, TICKET_TYPE_PATH, TICKET_TYPES_FIELD, UNKNOWN_LABEL } from './types'

export const getTicketTypes = formValues => get(formValues, `groups.0.${TICKET_TYPES_FIELD}`, [])

export const getFormTicketTypes = state => get(state, `values.${TICKET_TYPES_FIELD}`, [])

export const getUniqueTicketId = item => get(item, 'ticketType.id', uniqueId('ticket'))

export const calculateFinalPrice = (retailPrice, discount, percentage) => {
  if (!percentage) {
    return retailPrice - discount
  }

  return retailPrice - (retailPrice / 100) * discount
}

export const convertTicketTypes = ticketTypes =>
  values(ticketTypes).reduce((acc, item) => {
    const { discount, percentageDiscount } = item
    const ticketTypeName = get(item, TICKET_TYPE_PATH, UNKNOWN_LABEL)
    const retailPrice = get(item, RETAIL_PRICE_PATH, 0)
    const finalPrice = calculateFinalPrice(retailPrice, discount, percentageDiscount)
    const id = getUniqueTicketId(item)
    return {
      ...acc,
      [id]: {
        ...item,
        id,
        ticketTypeName,
        retailPrice: formatCostValue(retailPrice),
        finalPrice: formatCostValue(finalPrice)
      }
    }
  }, {})

export const convertContractToTicketTypesFormValues = formValues => {
  if (isEmpty(formValues)) {
    return {}
  }

  const ticketTypes = getTicketTypes(formValues).reduce((acc, item) => {
    const id = getUniqueTicketId(item)
    const { discount } = item
    const formattedDiscount =
      discount && isNumber(discount) && !isInteger(discount) ? discount.toFixed(2) : discount

    return {
      ...acc,
      [id]: {
        id,
        ...item,
        discount: formattedDiscount
      }
    }
  }, {})

  return {
    ticketTypes
  }
}

export const convertGroupTicketTypesToFormValues = (data = []) => {
  if (!data.length) {
    return {}
  }

  return data
    .reduce((acc, item) => [...acc, ...(item.ticketTypes || [])], [])
    .reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: {
          id: item.id,
          discount: 0,
          finalPrice: Number(item.retailPrice),
          included: true,
          percentageDiscount: true,
          ticketType: {
            id: item.id,
            name: item.name,
            parameters: {
              retailPrice: Number(item.retailPrice)
            }
          }
        }
      }),
      {}
    )
}

export const prepareDealTicketTypesPayload = formValues => {
  const { ticketTypes } = formValues
  return {
    ticketTypes: values(ticketTypes)
  }
}

export const mergeTicketTypeFromVales = (currentTicketTypes, inputTicketTypes) => {
  if (!currentTicketTypes) {
    return inputTicketTypes
  }

  return reduce(
    keys(inputTicketTypes),
    (acc, id) => ({
      ...acc,
      [id]: {
        ...inputTicketTypes[id],
        ...get(currentTicketTypes, id, {})
      }
    }),
    {}
  )
}
