/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import {
  assignGroups,
  formDealTicketTypesByActivity,
  getEntityById,
  getActivitiesByDeal
} from 'services/http/deals'

import helpers from './helpers'
import { getActivities } from '../dealFormActivities/selectors'

const {
  RESET_DATA_STATE,
  REQUEST_ASSIGN_GROUPS,
  RESPONSE_ASSIGN_GROUPS,
  RESPONSE_ASSIGN_GROUPS_ERROR,
  REQUEST_FILL_TICKET_TYPES,
  RESPONSE_FILL_TICKET_TYPES,
  RESPONSE_FILL_TICKET_TYPES_ERROR,
  REQUEST_GET_ACTIVITIES_BY_DEAL_ID,
  RESPONSE_GET_ACTIVITIES_BY_DEAL_ID_ERROR,
  RESPONSE_GET_ACTIVITIES_BY_DEAL_ID,
  RESET_SEARCH_DATA_STATE
} = helpers

const assignDealToGroup = (dealId, contractData, params) => async (dispatch, getState) => {
  dispatch(actionCreater(REQUEST_ASSIGN_GROUPS))
  const activities = [...getActivities(getState()), contractData]
  try {
    await assignGroups(dealId, activities, params)
    const dealDataRequest = await getEntityById(dealId)
    dispatch(actionCreater(RESPONSE_ASSIGN_GROUPS, dealDataRequest.data))
    return 'assignDealToGroup success'
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ASSIGN_GROUPS_ERROR))
  }
  return 'assignDealToGroup failed'
}

const formDealTicketTypes = (dealId, activityId, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_FILL_TICKET_TYPES))
  try {
    const request = await formDealTicketTypesByActivity(dealId, activityId, params)
    dispatch(actionCreater(RESPONSE_FILL_TICKET_TYPES, request.data))
    return 'formDealTicketTypes success'
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FILL_TICKET_TYPES_ERROR))
  }
  return 'formDealTicketTypes failed'
}

const searchActivitiesByDealId = (dealId, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_ACTIVITIES_BY_DEAL_ID))
  const request = await getActivitiesByDeal(dealId, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ACTIVITIES_BY_DEAL_ID_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_ACTIVITIES_BY_DEAL_ID, request.data))
    return 'searchActivitiesByDealId success'
  }

  return 'searchActivitiesByDealId failed'
}

const resetState = () => ({
  type: RESET_DATA_STATE
})

const resetSearchData = () => ({
  type: RESET_SEARCH_DATA_STATE
})

export default {
  assignDealToGroup,
  resetState,
  formDealTicketTypes,
  searchActivitiesByDealId,
  resetSearchData
}
