/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { ACTIVITY, AND_OPERATOR, buildSearchColumn, STRICT_MODE } from 'helpers/searchHelpers'

const ACTIVITY_PROVIDER_ID_FIELD = 'activityProviderId'

export const APP_OPTIONS = {
  itemsPerPage: 0
}

export const createAPPFilters = dataId => [
  buildSearchColumn({
    entity: ACTIVITY,
    field: ACTIVITY_PROVIDER_ID_FIELD,
    keyword: dataId,
    operator: AND_OPERATOR,
    mode: STRICT_MODE
  })
]
