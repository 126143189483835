/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { PROGRAM_SEASON } from 'constants/searchTypes'

const FORM_NAME = 'PROGRAM_FORM_NAME'
const PROGRAM_DRAFT = 'DRAFT_FOR_PROGRAM_CREATING'
const SEASONS_LIST_NAME = 'SEASONS_LIST_NAME'
const SEASON_REMOVE_ACTION = 'SEASON_REMOVE_ACTION'
const DEFAULT_PAGE_NUMBER = 1
const DEFAULT_ITEMS_PER_PAGE = 10
const PROGRAM_SEASON_TYPE = PROGRAM_SEASON
const DELETE_PROGRAM_ACTION = 'DELETE_PROGRAM_ACTION'
const PROGRAM_ENTITY_DATA = 'PROGRAM_ENTITY_DATA'
const ASSIGN_PHONE_BY_ID = 'ASSIGN_PHONE_BY_ID'
const UNASSIGN_PHONE_BY_ID = 'UNASSIGN_PHONE_BY_ID'
const CREATE_EMPTY_PROGRAM = `${FORM_NAME}_CREATE_EMPTY_PROGRAM`

export default {
  FORM_NAME,
  SEASONS_LIST_NAME,
  PROGRAM_SEASON_TYPE,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ITEMS_PER_PAGE,
  SEASON_REMOVE_ACTION,
  PROGRAM_DRAFT,
  DELETE_PROGRAM_ACTION,
  PROGRAM_ENTITY_DATA,
  ASSIGN_PHONE_BY_ID,
  UNASSIGN_PHONE_BY_ID,
  CREATE_EMPTY_PROGRAM
}
