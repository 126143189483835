/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useState } from 'react'
import isFunction from 'lodash/isFunction'
import PropTypes from 'prop-types'

export function withModalWindow(Component) {
  function WithModalWindow(props) {
    const [isShownModal, setIsShownState] = useState(false)
    const [originalArguments, setOriginalArguments] = useState([])
    const { renderModal, isDisabledModal, eventName, onAction, ...rest } = props

    const onHideHandler = () => {
      setIsShownState(false)
      setOriginalArguments([])
    }

    const onShowHandler = (...args) => {
      if (isDisabledModal) {
        onAction(...args)
        return
      }
      setIsShownState(true)
      setOriginalArguments(args)
    }

    const onToggleHandler = () => {
      setIsShownState(!isShownModal)
    }

    const onConfirmHandler = (...args) => {
      if (isFunction(onAction)) {
        onAction(...originalArguments, ...args)
      }
      onHideHandler()
    }

    return (
      <>
        <Component
          {...{
            [eventName]: onShowHandler
          }}
          {...rest}
        />
        {renderModal &&
          renderModal({
            isShown: isShownModal,
            onConfirm: onConfirmHandler,
            onHide: onHideHandler,
            onShow: onShowHandler,
            onToggle: onToggleHandler,
            originalArguments
          })}
      </>
    )
  }

  const componentName = Component.displayName || Component.name || 'Component'

  WithModalWindow.displayName = `withModalWindow(${componentName})`

  WithModalWindow.propTypes = {
    onClick: PropTypes.func.isRequired,
    renderModal: PropTypes.func.isRequired,
    eventName: PropTypes.string,
    onAction: PropTypes.func,
    isDisabledModal: PropTypes.bool
  }
  WithModalWindow.defaultProps = {
    eventName: 'onClick',
    onAction: () => null,
    isDisabledModal: false
  }

  return WithModalWindow
}
