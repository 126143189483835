/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useMemo, useCallback, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { BasicButton } from '@xplorie/ui-commons'
import { Form } from 'informed'
import get from 'lodash/get'
import { ORANGE_SCHEMA, SUBMIT_TITLE } from 'constants/buttonsOptions'
import {
  FormContent,
  ControlButtonsContainer,
  BoxNestedFields,
  CustomNestedField,
  TextField,
  TextAreaField,
  ImageField,
  ConditionalYesNoField
} from 'components/FormComponents'
import {
  phoneValidate,
  emailValidate,
  linkValidate,
  characterLimitValidation
} from 'helpers/formHelpers'
import { PIECurtainLoader } from 'components/Loaders'
import { useUpdateSomeDataState, useDidMount, useLoading } from 'hooks'
import { canUpdateProgramMarketing, canSeeDraftInProgress } from 'modules/Programs'
import { DraftInProgressButton } from 'modules/Programs/ProgramsForm/Buttons'
import styles from './MarketingMaterialsForm.scss'

import {
  CONTRACT_DATA_FIELD,
  MATERIALS_FIELD,
  LABEL_FOR_XPLORIE,
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  EMAIL_FIELD,
  PHONE_NUMBER_LABEL,
  PHONE_NUMBER_PLACEHOLDER,
  PHONE_FIELD,
  ADDITIONAL_PHONE_NUMBER_LABEL,
  ADDITIONAL_PHONE_NUMBER_PLACEHOLDER,
  ADDITIONAL_PHONE_NUMBER_FIELD,
  DESTINATION_TITLE_LABEL,
  DESTINATION_TITLE_PLACEHOLDER,
  DESTINATION_TITLE_FIELD,
  PMC_DESCRIPTION_LABEL,
  PMC_DESCRIPTION_PLACEHOLDER,
  PMC_DESCRIPTION_FIELD,
  CONFIRMATION_IMG_URL_LABEL,
  CONFIRMATION_IMG_URL_PLACEHOLDER,
  CONFIRMATION_IMG_URL_FIELD,
  PHONE_IMAGE_URL_LABEL,
  PHONE_IMAGE_URL_PLACEHOLDER,
  PHONE_IMAGE_URL_FIELD,
  PMC_SQUARE_IMAGE_URL_LABEL,
  PMC_SQUARE_IMAGE_URL_PLACEHOLDER,
  PMC_SQUARE_IMAGE_URL_FIELD,
  WELCOME_HERO_IMAGE_URL_LABEL,
  WELCOME_HERO_IMAGE_URL_PLACEHOLDER,
  WELCOME_HERO_IMAGE_URL_FIELD,
  TEXT_LANDING_SITE_URL_LABEL,
  TEXT_LANDING_SITE_URL_PLACEHOLDER,
  TEXT_LANDING_SITE_URL_FIELD,
  CARD_FORM_URL_LABEL,
  CARD_FORM_URL_PLACEHOLDER,
  CARD_FORM_URL_FIELD,
  CADENCE_TEXT_LABEL,
  CADENCE_TEXT_FIELD,
  CADENCE_EMAIL_FIELD,
  CADENCE_EMAIL_LABEL,
  SMART_HOST_FIELDS,
  SMART_HOST_FIELD,
  SMART_HOST_LABEL,
  SMART_HOST_MOBILE_LABEL,
  SMART_HOST_MOBILE_FIELD,
  EMAIL_CADENCE_LABEL,
  EMAIL_CADENCE_FIELD,
  CALENDAR_INTEGRATION_FIELD,
  CALENDAR_INTEGRATION_LABEL,
  AMAZON_PROVISIONING_FIELDS,
  AMAZON_PROVISIONING_ACCOUNT_FIELD,
  AMAZON_PROVISIONING_ACCOUNT_LABEL,
  AMAZON_PROVISIONING_ACCOUNT_PLACEHOLDER,
  AMAZON_PROVISIONING_PASSWORD_FIELD,
  AMAZON_PROVISIONING_PASSWORD_LABEL,
  AMAZON_PROVISIONING_PASSWORD_PLACEHOLDER
} from './types'

export function MarketingMaterialsForm(props) {
  const {
    authUser,
    programData,
    formDataState,
    formData,
    dataState,
    updateMaterialsForm,
    onChange,
    dataId,
    onClose
  } = props
  const formApi = useRef()
  const [isDisabledSubmit, setIsDisabledSubmitState] = useState(true)
  const canUpdate = useMemo(() => canUpdateProgramMarketing(authUser, programData), [
    authUser,
    programData
  ])

  const limitValidate = characterLimitValidation()
  const smartHostEnabled = get(formData, 'materials.smartHost.enabled')
  const [isVisibleSmartHostFields, setIsVisibleSmartHostFields] = useState(smartHostEnabled)

  const isVisibleDraftInProgress = useMemo(() => canSeeDraftInProgress(programData), [programData])

  const [isRequesting] = useLoading(dataState)
  const isDisabled = useMemo(() => isRequesting || !canUpdate, [canUpdate, isRequesting])

  const onGetFormApi = useCallback(api => {
    formApi.current = api
  }, [])

  const setFormValues = useCallback(values => {
    if (values && formApi.current) {
      formApi.current.setValues(values)
    }
  }, [])

  const onSubmitHandler = useCallback(
    formValues => {
      updateMaterialsForm(dataId, formValues)
    },
    [dataId, updateMaterialsForm]
  )

  const onClickSubmitHandler = useCallback(() => {
    formApi.current.submitForm()
  }, [])

  const onClickDraftInProgressHandler = useCallback(
    applyDraftId => {
      const draftId = get(programData, 'draftIds.0')

      applyDraftId(draftId)
      onClose()
    },
    [onClose, programData]
  )

  const onChangeHandler = useCallback(
    formState => {
      const { touched, invalid, pristine } = formState
      const isTouched = Object.keys(touched).length > 0

      const isButtonDisabled = invalid || pristine || !isTouched
      setIsDisabledSubmitState(isButtonDisabled)

      if (onChange) {
        onChange(formState)
      }
    },
    [onChange]
  )

  const onSmartHostChange = smartHost => {
    setIsVisibleSmartHostFields(smartHost)
    formApi.current.setValue('materials.smartHost.calendarIntegration', smartHost)
    formApi.current.setValue('materials.smartHost.emailCadence', false)
    formApi.current.setValue('materials.smartHost.mobile', false)
  }

  useDidMount(() => {
    if (formData) {
      setFormValues(formData)
    }
  })

  useUpdateSomeDataState(
    () => {
      setFormValues(formData)
    },
    [formDataState],
    DATA_STATE_RECIEVED
  )

  return (
    <Form getApi={onGetFormApi} onSubmit={onSubmitHandler} onChange={onChangeHandler}>
      {isRequesting && <PIECurtainLoader />}
      <FormContent disabled={isDisabled}>
        <BoxNestedFields label={LABEL_FOR_XPLORIE}>
          <CustomNestedField field={CONTRACT_DATA_FIELD}>
            <TextField
              label={EMAIL_LABEL}
              fieldName={EMAIL_FIELD}
              placeholder={EMAIL_PLACEHOLDER}
              validate={emailValidate}
            />
            <TextField
              label={PHONE_NUMBER_LABEL}
              fieldName={PHONE_FIELD}
              placeholder={PHONE_NUMBER_PLACEHOLDER}
              validate={phoneValidate}
            />
            <TextField
              label={ADDITIONAL_PHONE_NUMBER_LABEL}
              fieldName={ADDITIONAL_PHONE_NUMBER_FIELD}
              placeholder={ADDITIONAL_PHONE_NUMBER_PLACEHOLDER}
              validate={phoneValidate}
            />
            <TextField
              label={DESTINATION_TITLE_LABEL}
              fieldName={DESTINATION_TITLE_FIELD}
              placeholder={DESTINATION_TITLE_PLACEHOLDER}
            />
          </CustomNestedField>
          <TextAreaField
            label={PMC_DESCRIPTION_LABEL}
            fieldName={PMC_DESCRIPTION_FIELD}
            placeholder={PMC_DESCRIPTION_PLACEHOLDER}
          />
        </BoxNestedFields>
        <CustomNestedField field={MATERIALS_FIELD}>
          <ImageField
            label={CONFIRMATION_IMG_URL_LABEL}
            fieldName={CONFIRMATION_IMG_URL_FIELD}
            validate={linkValidate}
            placeholder={CONFIRMATION_IMG_URL_PLACEHOLDER}
          />
          <ImageField
            label={PHONE_IMAGE_URL_LABEL}
            fieldName={PHONE_IMAGE_URL_FIELD}
            validate={linkValidate}
            placeholder={PHONE_IMAGE_URL_PLACEHOLDER}
          />
          <ImageField
            label={PMC_SQUARE_IMAGE_URL_LABEL}
            fieldName={PMC_SQUARE_IMAGE_URL_FIELD}
            validate={linkValidate}
            placeholder={PMC_SQUARE_IMAGE_URL_PLACEHOLDER}
          />
          <ImageField
            label={WELCOME_HERO_IMAGE_URL_LABEL}
            fieldName={WELCOME_HERO_IMAGE_URL_FIELD}
            validate={linkValidate}
            placeholder={WELCOME_HERO_IMAGE_URL_PLACEHOLDER}
          />
          <TextField
            label={TEXT_LANDING_SITE_URL_LABEL}
            fieldName={TEXT_LANDING_SITE_URL_FIELD}
            validate={linkValidate}
            placeholder={TEXT_LANDING_SITE_URL_PLACEHOLDER}
          />
          <TextField
            fieldName={CARD_FORM_URL_FIELD}
            label={CARD_FORM_URL_LABEL}
            placeholder={CARD_FORM_URL_PLACEHOLDER}
            validate={linkValidate}
          />
          <ConditionalYesNoField fieldName={CADENCE_TEXT_FIELD} label={CADENCE_TEXT_LABEL} />
          <ConditionalYesNoField fieldName={CADENCE_EMAIL_FIELD} label={CADENCE_EMAIL_LABEL} />
          <CustomNestedField field={SMART_HOST_FIELDS}>
            <ConditionalYesNoField
              onChange={onSmartHostChange}
              fieldName={SMART_HOST_FIELD}
              label={SMART_HOST_LABEL}
            />
            <div
              data-testid="smartHostFields"
              className={classNames({ [styles.hidden]: !isVisibleSmartHostFields })}
            >
              <ConditionalYesNoField
                fieldName={SMART_HOST_MOBILE_FIELD}
                label={SMART_HOST_MOBILE_LABEL}
              />
              <ConditionalYesNoField
                fieldName={CALENDAR_INTEGRATION_FIELD}
                label={CALENDAR_INTEGRATION_LABEL}
              />
              <ConditionalYesNoField fieldName={EMAIL_CADENCE_FIELD} label={EMAIL_CADENCE_LABEL} />
              <CustomNestedField field={AMAZON_PROVISIONING_FIELDS}>
                <TextField
                  fieldName={AMAZON_PROVISIONING_ACCOUNT_FIELD}
                  label={AMAZON_PROVISIONING_ACCOUNT_LABEL}
                  placeholder={AMAZON_PROVISIONING_ACCOUNT_PLACEHOLDER}
                  validate={limitValidate}
                />
                <TextField
                  fieldName={AMAZON_PROVISIONING_PASSWORD_FIELD}
                  label={AMAZON_PROVISIONING_PASSWORD_LABEL}
                  placeholder={AMAZON_PROVISIONING_PASSWORD_PLACEHOLDER}
                  validate={limitValidate}
                />
              </CustomNestedField>
            </div>
          </CustomNestedField>
        </CustomNestedField>
        <ControlButtonsContainer>
          {isVisibleDraftInProgress ? (
            <DraftInProgressButton onClick={onClickDraftInProgressHandler} />
          ) : (
            <BasicButton
              colorSchema={ORANGE_SCHEMA}
              disabled={isDisabledSubmit}
              onClick={onClickSubmitHandler}
            >
              {SUBMIT_TITLE}
            </BasicButton>
          )}
        </ControlButtonsContainer>
      </FormContent>
    </Form>
  )
}
MarketingMaterialsForm.propTypes = {
  authUser: PropTypes.object.isRequired,
  programData: PropTypes.object,
  formDataState: PropTypes.string.isRequired,
  formData: PropTypes.object,
  dataState: PropTypes.string.isRequired,
  updateMaterialsForm: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  dataId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}
MarketingMaterialsForm.defaultProps = {
  programData: {},
  formData: null,
  onChange: null
}
