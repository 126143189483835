/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { buildPermissions } from 'modules/Programs'
import uniqueId from 'lodash/uniqueId'
import get from 'lodash/get'
import {
  DISABLED_SMART_HOST_LABEL,
  DISABLED_SMART_HOST_MOBILE_LABEL,
  ENABLED_SMART_HOST_LABEL,
  ENABLED_SMART_HOST_MOBILE_LABEL,
  MAX_DECIMAL_PLACES,
  NO_END_DATE_VALUE,
  SMART_HOST_MOBILE_TYPE
} from './types'

export const PREFIX = 'smart_host_fee_'

export function canChangeFees(programData, authUser) {
  const { isUser, isAllowedApprove, isDraftProgram, isOwnProgram } = buildPermissions({
    authUser,
    data: programData
  })

  if (isDraftProgram && (isAllowedApprove || (isUser && isOwnProgram))) {
    return true
  }

  return false
}

export const convertFees = fees =>
  fees.map(item => ({
    ...item,
    id: item.id.includes(PREFIX) ? null : item.id,
    fee: Number(item.fee).toFixed(2),
    period: {
      startDate: item.period.startDate,
      endDate: item.noEndDate ? NO_END_DATE_VALUE : item.period.endDate
    }
  }))

export const setEditable = (fees, editable) => fees.map(item => ({ ...item, editable }))

export const convertFeeValue = value => (value ? Number(value).toFixed(MAX_DECIMAL_PLACES) : '')

export const appendEmptyFee = fees => [
  ...fees,
  {
    id: uniqueId(PREFIX),
    editable: true
  }
]

export const isSmartHostEnabled = (type, programData) => {
  const smartHostEnabled = get(programData, 'programInfo.smartHost.enabled', false)

  if (type === SMART_HOST_MOBILE_TYPE) {
    return (smartHostEnabled && get(programData, 'programInfo.smartHost.mobile')) || false
  }

  return smartHostEnabled
}

export const getSmartHostTag = (type, smartHostEnabled) => {
  if (type === SMART_HOST_MOBILE_TYPE) {
    return smartHostEnabled ? ENABLED_SMART_HOST_MOBILE_LABEL : DISABLED_SMART_HOST_MOBILE_LABEL
  }

  return smartHostEnabled ? ENABLED_SMART_HOST_LABEL : DISABLED_SMART_HOST_LABEL
}
