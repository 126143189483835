import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST, RESET_DATA_STATE } = helpers

export const initialState = {}

export const tabState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  data: [],
  itemsCount: 0,
  itemsPerPage: 10,
  pageNumber: 1
}

const myWorkReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      return {
        ...state,
        [action.payload.key]: {
          ...tabState,
          ...state[action.payload.key],
          dataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_LIST:
      return {
        ...state,
        [action.payload.key]: {
          ...tabState,
          ...state[action.payload.key],
          dataState: DATA_STATE_RECIEVED,
          data: action.payload.data,
          ...action.payload.pagination
        }
      }
    case RESPONSE_ERROR_LIST:
      return {
        ...state,
        [action.payload.key]: {
          ...tabState,
          ...state[action.payload.key],
          dataState: DATA_STATE_ERROR
        }
      }
    case RESET_DATA_STATE:
      return { ...initialState }
    default:
      return state
  }
}

export default filterActions(myWorkReducer, [
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE
])
