/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { PROVIDERS_LIST_NAME, PROVIDERS_LIST_COUNT_NAME } = types

const REQUEST_LIST = `${REQUEST_TYPE}_${PROVIDERS_LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${PROVIDERS_LIST_NAME}`
const RESPONSE_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${PROVIDERS_LIST_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${PROVIDERS_LIST_NAME}`

const REQUEST_LIST_COUNT = `${REQUEST_TYPE}_${PROVIDERS_LIST_COUNT_NAME}`
const RESPONSE_LIST_COUNT = `${RESPONSE_TYPE}_${PROVIDERS_LIST_COUNT_NAME}`
const RESPONSE_ERROR_LIST_COUNT = `${RESPONSE_ERROR_TYPE}_${PROVIDERS_LIST_COUNT_NAME}`

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_LIST_COUNT,
  RESPONSE_LIST_COUNT,
  RESPONSE_ERROR_LIST_COUNT
}
