/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import { Nav } from 'modules/Nav'
import findIndex from 'lodash/findIndex'
import { NAVIGATION_RESALE_TYPE } from 'constants/navigationTypes'
import { VAC_SPECIALIST_TIER_2_LEVEL, VAC_SPECIALIST_LEVEL } from 'constants/permissionTypes'

import { TestModeSwitcher } from './TestModeSwitcher'

export function NavigationWithTestMode() {
  const prepareNavData = useCallback(navItems => {
    const resaleItems = [...navItems[NAVIGATION_RESALE_TYPE]]
    const separatorIndex = findIndex(resaleItems, ['id', 'separator'])

    if (separatorIndex > -1) {
      resaleItems.splice(separatorIndex + 1, 0, {
        id: 'test-mode-switcher',
        navItemText: '',
        navItemBehavior: false,
        activeClassName: false,
        navPath: '',
        accessLevels: [VAC_SPECIALIST_TIER_2_LEVEL, VAC_SPECIALIST_LEVEL],
        component: TestModeSwitcher
      })
    }

    return { ...navItems, [NAVIGATION_RESALE_TYPE]: [...resaleItems] }
  }, [])

  return <Nav prepareNavData={prepareNavData} />
}
