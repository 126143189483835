import React from 'react'

export function SandClockIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M394.667 449.963v-80.576a96.032 96.032 0 00-32.789-72.256L314.859 256l47.019-41.131a96.029 96.029 0 0032.789-72.256V62.037C407.061 57.621 416 45.888 416 32c0-17.643-14.357-32-32-32H128c-17.643 0-32 14.357-32 32 0 13.888 8.939 25.621 21.333 30.037v80.576a96.032 96.032 0 0032.789 72.256L197.141 256l-47.019 41.131a96.029 96.029 0 00-32.789 72.256v80.576C104.939 454.379 96 466.112 96 480c0 17.643 14.357 32 32 32h256c17.643 0 32-14.357 32-32 0-13.888-8.939-25.621-21.333-30.037zM373.333 448H138.667v-78.613a74.725 74.725 0 0125.493-56.192l56.192-49.173c2.325-2.027 3.648-4.949 3.648-8.021s-1.323-5.995-3.648-8.021l-56.192-49.173a74.725 74.725 0 01-25.493-56.192V64h234.667v78.613a74.725 74.725 0 01-25.493 56.192l-56.192 49.173c-2.326 2.027-3.649 4.95-3.649 8.022s1.323 5.995 3.648 8.021l56.192 49.173a74.652 74.652 0 0125.493 56.192V448z" />
      <path d="M349.675 409.344l-85.333-106.667c-4.053-5.056-12.608-5.056-16.661 0l-85.333 106.667a10.682 10.682 0 00-1.28 11.285 10.645 10.645 0 009.6 6.037h170.667a10.7 10.7 0 009.621-6.037 10.686 10.686 0 00-1.281-11.285zM308.523 177.259a10.667 10.667 0 00-9.856-6.592h-85.333a10.667 10.667 0 00-9.856 6.592c-1.664 3.989-.747 8.555 2.304 11.627l42.667 42.667a10.716 10.716 0 0015.104 0l42.667-42.667a10.646 10.646 0 002.303-11.627z" />
    </svg>
  )
}
