/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import moment from 'moment'
import {
  STATUS_DRAFT,
  STATUS_PENDING,
  STATUS_ACTIVE,
  STATUS_ARCHIVED,
  STATUS_INACTIVE,
  VALUE_DRAFT,
  VALUE_PENDING,
  VALUE_ACTIVE,
  VALUE_ARCHIVED,
  VALUE_INACTIVE,
  STATUS_TESTING,
  VALUE_TESTING,
  STATUS_CANCELLED,
  VALUE_CANCELLED
} from 'constants/entityStatus'
import { FORMAT } from 'constants/date'
import { AGREEMENT_TYPE, AGREEMENT_TYPE_VALUE } from 'constants/app'

export const getStatusValue = value => {
  switch (value.status) {
    case STATUS_DRAFT:
      return VALUE_DRAFT
    case STATUS_PENDING:
      return VALUE_PENDING
    case STATUS_ACTIVE:
      return VALUE_ACTIVE
    case STATUS_ARCHIVED:
      return VALUE_ARCHIVED
    case STATUS_INACTIVE:
      return VALUE_INACTIVE
    case STATUS_TESTING:
      return VALUE_TESTING
    case STATUS_CANCELLED:
      return VALUE_CANCELLED
    default:
      return value.status
  }
}

export const getDateWithHoliday = ({
  value = {},
  holidayEnabled = true,
  dateFormat = FORMAT,
  shownFormat = FORMAT
}) => {
  if (!value || !value.date) {
    return null
  }
  const formatedValue = moment(value.date, dateFormat)
  if (!formatedValue.isValid()) {
    return null
  }
  return holidayEnabled && value.description ? value.description : formatedValue.format(shownFormat)
}
export const buildDefaultColumn = options => ({
  Header: '',
  accessor: '',
  width: 85,
  ...options
})

export const buildDefaultButtonColumn = options => ({
  sortable: false,
  className: 'column-button',
  ...buildDefaultColumn(options)
})

export const getAgreementTypeValue = value => {
  switch (value.agreementType) {
    case AGREEMENT_TYPE.XPLORIE_FIT:
      return AGREEMENT_TYPE_VALUE.XPLORIE_FIT
    case AGREEMENT_TYPE.PROVIDER_FIT:
      return AGREEMENT_TYPE_VALUE.PROVIDER_FIT
    case AGREEMENT_TYPE.TRADITIONAL:
      return AGREEMENT_TYPE_VALUE.TRADITIONAL
    case AGREEMENT_TYPE.HYBRID:
      return AGREEMENT_TYPE_VALUE.HYBRID
    default:
      return value.agreementType
  }
}
