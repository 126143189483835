/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getList, getListByTagId, getListOfProviders } from 'services/http/activityTags'

import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  REQUEST_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID,
  RESPONSE_ERROR_GET_ENTITY_BY_ID,
  REQUEST_GET_PROVIDER_ENTITY_BY_ID,
  RESPONSE_GET_PROVIDER_ENTITY_BY_ID,
  RESPONSE_ERROR_GET_PROVIDER_ENTITY_BY_ID
} = helpers

const getActivityTagsList = options => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))

  const request = await getList(options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { ...request.data }))
    return 'getActivityTagsList success'
  }

  return 'getActivityTagsList failed'
}

const getAppsActivityTags = id => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_ENTITY_BY_ID))

  const request = await getListByTagId(id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_GET_ENTITY_BY_ID))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID, request.data))
    return 'getActivityTagsList success'
  }

  return 'getActivityTagsList failed'
}

const getActivityProvidersTags = id => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_PROVIDER_ENTITY_BY_ID))

  const request = await getListOfProviders(id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_GET_PROVIDER_ENTITY_BY_ID))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_GET_PROVIDER_ENTITY_BY_ID, request.data))
    return 'getActivityTagsList success'
  }

  return 'getActivityTagsList failed'
}

export default {
  getActivityTagsList,
  getAppsActivityTags,
  getActivityProvidersTags
}
