/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const SUBMIT_BUTTON_TEXT = 'Submit for approval'
export const APPROVE_BUTTON_TEXT = 'Approve draft'
export const REJECT_BUTTON_TEXT = 'Reject draft'
export const SAVE_BTN = 'Submit'
export const MODAL_MESSAGE_UNSAVED =
  'This form contains unsaved changes. Submit without saving these changes?'
export const CONFIRMATION_BUTTON_TEXT = 'Yes, submit.'
export const BASIC_BTN_VALUE = 'Initiate Update'
export const DRAFT_IN_PROGRESS_BTN = 'Draft In Progress'

export const PROGRAM_TITLE = 'Program'
export const SEASONS_TITLE = 'Seasons'
