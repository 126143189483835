/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import { FORM_NAME, LOCATIONS_LIST } from './types'

const REQUEST_FORM = `${REQUEST_TYPE}_${FORM_NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${FORM_NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${FORM_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

const REQUEST_LOCATIONS_LIST = `${REQUEST_TYPE}_${LOCATIONS_LIST}`
const RESPONSE_LOCATIONS_LIST = `${RESPONSE_TYPE}_${LOCATIONS_LIST}`
const RESPONSE_LOCATIONS_LIST_ERROR = `${RESPONSE_ERROR_TYPE}_${LOCATIONS_LIST}`

export default {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_LOCATIONS_LIST,
  RESPONSE_LOCATIONS_LIST,
  RESPONSE_LOCATIONS_LIST_ERROR
}
