/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { filteredExportData } from 'services/http/searchExport'

import helpers from './helpers'

const { REQUEST_EXPORT, RESPONSE_EXPORT, RESPONSE_ERROR_EXPORT, RESET_DATA_STATE } = helpers

const exportFilteredEntityData = (entityKey, data, options, filename) => async dispatch => {
  const key = entityKey
  dispatch(actionCreater(REQUEST_EXPORT, { key }))
  const request = await filteredExportData(
    data,
    { keyword: '', ...options, type: entityKey },
    filename
  ).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_EXPORT, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_EXPORT, { key }))
    return 'exportFilteredEntityData success'
  }

  return 'exportFilteredEntityData failed'
}

const reset = entityKey => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key: entityKey }))

export default {
  exportFilteredEntityData,
  reset
}
