/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { TooltipComponent, TOP_DIRECTION } from 'components/Tooltip'
import { FORMAT } from 'constants/date'
import { Form } from 'informed'
import {
  withValidation,
  blankValidate,
  dateValidate,
  dateInFutureValidation
} from 'helpers/formHelpers'
import { CalendarField, CheckBoxField, SQUARE_TYPE } from 'components/FormComponents'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, LINK_SCHEMA, YELLOW_GREEN_SCHEMA } from 'constants/buttonsOptions'
import styles from './styles.scss'
import {
  DATE_INPUT_LABEL,
  DATE_INPUT_PLACEHOLDER,
  DATE_FIELD,
  PICK_TODAY_BUTTON_TEXT,
  DEACTIVATE_TEXT,
  DECLINE_TEXT,
  UPDATE_TEXT,
  CANCEL_TEXT,
  DEACTIVATION_UNASSIGN_UNITS_FIELD_ID,
  DEACTIVATION_UNASSIGN_UNITS_FIELD,
  DEACTIVATION_UNASSIGN_UNITS_LABEL
} from './types'
import { UPDATE_DATE_MODE, UPDATE_DEACTIVATED_DATE_MODE, SET_DEACTIVATION_MODE } from '../types'

const validation = withValidation(blankValidate, dateValidate)
const updateDeactivatedDateValidation = withValidation(validation, dateInFutureValidation)

export function DeactivateModal({ onAction, onClose, onCancel, initialValues, Message, mode }) {
  const formApi = useRef()
  const [isDisabledAction, setIsDisabledState] = useState(true)

  const onGetApi = useCallback(api => {
    formApi.current = api
  }, [])

  const onFormChangeHandler = useCallback(formState => {
    const isDisabled = formState.invalid || formState.pristine
    setIsDisabledState(isDisabled)
  }, [])

  const onClickTodayHandler = useCallback(() => {
    if (formApi.current) {
      formApi.current.setValue(DATE_FIELD, { date: moment().format(FORMAT) })
    }
  }, [])

  const validateDate = value =>
    mode === UPDATE_DEACTIVATED_DATE_MODE
      ? updateDeactivatedDateValidation(value.date)
      : validation(value.date)

  const onCancelDeactivationHandler = useCallback(() => {
    if (onCancel) {
      onCancel()
    }
  }, [onCancel])

  const onSubmitHandler = useCallback(
    formValues => {
      if (onAction && formValues) {
        onAction(formValues)
      }
    },
    [onAction]
  )

  const onClickDeactivate = useCallback(() => {
    formApi.current.submitForm()
  }, [])

  return (
    <div className={styles.bodyWrapper}>
      {Message}
      <Form
        onSubmit={onSubmitHandler}
        className={styles.formWrapper}
        getApi={onGetApi}
        initialValues={initialValues}
        onChange={onFormChangeHandler}
      >
        <CalendarField
          fieldName={DATE_FIELD}
          label={DATE_INPUT_LABEL}
          placeholder={DATE_INPUT_PLACEHOLDER}
          isRequired
          withHolidays
          validate={validateDate}
          fieldProps={{
            renderInner: ({ className, children: Component, error }) => (
              <div className={className}>
                {Component}
                {error && (
                  <div className={styles.errorTooltipWrapper}>
                    <TooltipComponent directionOption={TOP_DIRECTION} contentTooltip={error} />
                  </div>
                )}
                <BasicButton
                  colorSchema={LINK_SCHEMA}
                  className={styles.todayButton}
                  onClick={onClickTodayHandler}
                >
                  {PICK_TODAY_BUTTON_TEXT}
                </BasicButton>
              </div>
            )
          }}
        />
        <div className={styles.checkboxWrapper}>
          <CheckBoxField
            id={DEACTIVATION_UNASSIGN_UNITS_FIELD_ID}
            type="checkbox"
            styleType={SQUARE_TYPE}
            field={DEACTIVATION_UNASSIGN_UNITS_FIELD}
          />
          <label htmlFor={DEACTIVATION_UNASSIGN_UNITS_FIELD_ID} className={styles.checkboxLabel}>
            {DEACTIVATION_UNASSIGN_UNITS_LABEL}
          </label>
        </div>
      </Form>
      <div className={styles.buttons}>
        {mode === UPDATE_DATE_MODE && (
          <>
            <BasicButton
              colorSchema={ORANGE_SCHEMA}
              onClick={onClickDeactivate}
              disabled={isDisabledAction}
            >
              {UPDATE_TEXT}
            </BasicButton>
            <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onCancelDeactivationHandler}>
              {CANCEL_TEXT}
            </BasicButton>
          </>
        )}
        {mode === SET_DEACTIVATION_MODE && (
          <>
            <BasicButton
              colorSchema={ORANGE_SCHEMA}
              onClick={onClickDeactivate}
              disabled={isDisabledAction}
            >
              {DEACTIVATE_TEXT}
            </BasicButton>
            <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
              {DECLINE_TEXT}
            </BasicButton>
          </>
        )}
        {mode === UPDATE_DEACTIVATED_DATE_MODE && (
          <>
            <BasicButton
              colorSchema={ORANGE_SCHEMA}
              onClick={onClickDeactivate}
              disabled={isDisabledAction}
            >
              {UPDATE_TEXT}
            </BasicButton>
            <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
              {DECLINE_TEXT}
            </BasicButton>
          </>
        )}
      </div>
    </div>
  )
}
DeactivateModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
  error: PropTypes.string,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    date: PropTypes.string,
    unassignUnits: PropTypes.bool
  }),
  Message: PropTypes.node.isRequired,
  mode: PropTypes.string.isRequired
}
DeactivateModal.defaultProps = {
  error: null,
  children: null,
  className: '',
  initialValues: {}
}
