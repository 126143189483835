/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const LIST_NAME = 'LOCATIONS_TREE'
export const LOCATION_TREE_STATE = 'LOCATION_TREE_STATE'
export const REMOVE_LOCATION = 'LOCATIONS_TREE_REMOVE_LOCATION'
export const MOVE_LOCATION = 'LOCATIONS_TREE_MOVE_LOCATION'
export default {
  LIST_NAME,
  LOCATION_TREE_STATE,
  REMOVE_LOCATION,
  MOVE_LOCATION
}
