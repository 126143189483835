/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ContentWithNav } from 'components/ContentWithNav'
import { HeaderWrapper, ButtonsWrapper } from 'components/Wrappers'
import { CAMPAIGN } from 'constants/searchTypes'
import { CAMPAIGNS } from 'constants/formCodes'
import { STATUS_DRAFT } from 'constants/entityStatus'
import { campaignsTableColumns } from 'helpers/dataTablesHelpers'
import { DotsButtonWithExport } from 'modules/ExportEntity'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { RIGHT_ALIGN } from 'constants/alignTypes'
import withAuthorization from 'helpers/withAuthorization'
import { CampaignsList } from 'modules/Campaigns'
import { useSliderApi } from 'hooks/useSliderApi'

import { TITLE_PAGE, TEXT_BTN_ADD_NEW, EXPORT_FILENAME, NEW_CAMPAIGN_TITLE } from './types'
import { canAddCampaign } from './helpers'

import styles from './styles.scss'

function Campaigns(props) {
  const { authUser, onGetData } = props
  const sliderApi = useSliderApi(CAMPAIGNS)

  const onAddNewCampaign = useCallback(() => {
    sliderApi.open(null, { headerProps: { title: NEW_CAMPAIGN_TITLE, info: STATUS_DRAFT } })
  }, [sliderApi])

  const buildDotsButtonOptions = useCallback(() => {
    const options = []

    if (canAddCampaign(authUser)) {
      options.push({
        label: TEXT_BTN_ADD_NEW,
        props: { onClick: onAddNewCampaign }
      })
    }

    return options
  }, [authUser, onAddNewCampaign])

  return (
    <ContentWithNav>
      <section className={styles.customWrapper}>
        <HeaderWrapper title={TITLE_PAGE} />
        <div className={styles.containerWrapper}>
          <ButtonsWrapper className={styles.buttonsPanelWithFilters} align={RIGHT_ALIGN}>
            <Column className={styles.customColumnWrapper}>
              <DotsButtonWithExport
                options={buildDotsButtonOptions()}
                entity={CAMPAIGN}
                columns={campaignsTableColumns}
                filename={EXPORT_FILENAME}
              />
            </Column>
          </ButtonsWrapper>
          <CampaignsList onGetData={onGetData} />
        </div>
      </section>
    </ContentWithNav>
  )
}

Campaigns.propTypes = {
  onGetData: PropTypes.func.isRequired,
  authUser: PropTypes.func.isRequired
}

export default withAuthorization(Campaigns)
