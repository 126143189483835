/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, SMALLER_SIZE, UNIT_MANAGER_TITLE } from 'constants/buttonsOptions'
import { useSlider } from 'hooks/useSliderApi'
import { UNIT_MANAGER } from 'constants/formCodes'
import { get } from 'lodash'

export function UnitManagerButton(props) {
  const { data, onGetData, pageNumber, itemsPerPage } = props
  const pmcId = get(data, 'original.pmcId', null)
  const programId = get(data, 'original.programId', null)

  const [, unitManagerApi] = useSlider(`${pmcId}_${UNIT_MANAGER}`, UNIT_MANAGER, {})

  const onBeforeClose = () => {
    onGetData({ pageNumber, itemsPerPage })
  }
  const openUnitManagerHandler = useCallback(() => {
    unitManagerApi.open({ props: { programId, pmcId, isTask: true, onBeforeClose } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId, pmcId])

  return (
    <BasicButton size={SMALLER_SIZE} colorSchema={ORANGE_SCHEMA} onClick={openUnitManagerHandler}>
      {UNIT_MANAGER_TITLE}
    </BasicButton>
  )
}

UnitManagerButton.propTypes = {
  authUser: PropTypes.object.isRequired,
  data: PropTypes.object,
  onGetData: PropTypes.func,
  pageNumber: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired
}

UnitManagerButton.defaultProps = {
  data: null,
  onGetData: null
}
