/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export function buildEligibilityOptions(data) {
  return data.map(value => ({ label: value.parameter.name, value: value.id }))
}

export function buildContract({ values = [], ...restValues }) {
  return {
    values: values.map(({ parameter, ...rest }) => ({
      ...rest
    })),
    ...restValues
  }
}
