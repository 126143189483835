/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { ACTIVITY_PROVIDER } from 'constants/searchTypes'

const PROVIDERS_LIST_NAME = 'PROVIDERS_LIST'
const PROVIDERS_LIST_COUNT_NAME = 'PROVIDERS_LIST_COUNT'
const DEFAULT_PAGE_NUMBER = 1
const DEFAULT_ITEMS_PER_PAGE = 10
const ENTITY_TYPE = ACTIVITY_PROVIDER

export default {
  PROVIDERS_LIST_NAME,
  PROVIDERS_LIST_COUNT_NAME,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ITEMS_PER_PAGE,
  ENTITY_TYPE
}
