/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { appProgramsListInitialState } from './reducer'

export const getProgramsListStateByAppId = (state, appId) =>
  state.program.appPrograms[appId] || appProgramsListInitialState
