/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { isActionAllowed } from 'helpers/permissionHelper'
import { CREATE_LEVEL } from 'constants/permissionTypes'
import { createFilterBuilder } from 'modules/FilteredSearch/helpers'
import {
  INACTIVE_FILTER,
  INACTIVE_FILTER_KEY,
  ACTIVE_FILTER_KEY,
  ACTIVE_FILTER,
  IGNORE_INACTIVE_FILTER,
  IGNORE_ACTIVE_FILTER
} from './types'

export const buildFilters = createFilterBuilder(
  {
    [ACTIVE_FILTER_KEY]: ACTIVE_FILTER,
    [INACTIVE_FILTER_KEY]: INACTIVE_FILTER
  },
  filters => (!filters.length ? [IGNORE_ACTIVE_FILTER, IGNORE_INACTIVE_FILTER] : filters)
)

export const canAddNewPmc = authUser => isActionAllowed(CREATE_LEVEL, authUser)
