/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withModalWindow } from 'decorators'
import { WarningModal } from 'modules/Modals'
import { SandClockIcon, CloseMarkIcon, MarkIcon } from 'icons'
import { SUCCESS_STATUS, ONGOING_STATUS } from 'constants/entityStatus'
import { DWH_MAX_MINUTES_DELAY, DWH_ERROR_MESSAGE } from '../types'
import styles from '../GuestsList.scss'

const CloseMarkWithModal = withModalWindow(CloseMarkIcon)

export function DWHStatusCell({ status, createDate }) {
  const isOutOfTime = useMemo(
    () => moment().diff(moment(createDate), 'minutes') > DWH_MAX_MINUTES_DELAY,
    [createDate]
  )
  if (status === SUCCESS_STATUS) {
    return <MarkIcon className={styles.markIcon} />
  }
  if (status === ONGOING_STATUS && !isOutOfTime) {
    return <SandClockIcon className={styles.sandClockIcon} />
  }
  return (
    <CloseMarkWithModal
      className={styles.closeIcon}
      renderModal={({ isShown, onConfirm, onHide }) => (
        <WarningModal
          isOpen={isShown}
          onConfirm={onConfirm}
          onHide={onHide}
          message={DWH_ERROR_MESSAGE}
        />
      )}
    />
  )
}

DWHStatusCell.propTypes = {
  status: PropTypes.string.isRequired,
  createDate: PropTypes.string.isRequired
}
