/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import get from 'lodash/get'
import dealFormHelpers from '../dealForm/helpers'
import dealFormGroupsHelpers from '../dealFormGroups/helpers'

import helpers from './helpers'

const {
  RESET_DATA_STATE,
  RESET_ACTIVITY_DATA_STATE,
  REQUEST_UPDATE_ACTIVITY,
  RESPONSE_UPDATE_ACTIVITY,
  RESPONSE_UPDATE_ACTIVITY_ERROR,
  activityStateKeyBuilder,
  REQUEST_UPDATE_ACTIVITY_TYPE,
  RESPONSE_UPDATE_ACTIVITY_TYPE,
  RESPONSE_UPDATE_ACTIVITY_TYPE_ERROR,
  REQUEST_DELETE_ACTIVITY,
  RESPONSE_DELETE_ACTIVITY,
  RESPONSE_DELETE_ACTIVITY_ERROR,
  CHANGE_EDIT_MODE,
  buildActivitiesState
} = helpers

export const defaultActivityState = {
  updateDataState: DATA_STATE_NOT_REQUESTED,
  updateTypeDataState: DATA_STATE_NOT_REQUESTED,
  deleteDataState: DATA_STATE_NOT_REQUESTED,
  data: {},
  editMode: false
}

export const initialState = {
  activities: {}
}

const dealFormActivitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    // update activity
    case REQUEST_UPDATE_ACTIVITY:
      return {
        ...state,
        activities: {
          ...state.activities,
          [action.payload]: {
            ...state.activities[action.payload],
            updateDataState: DATA_STATE_REQUESTING
          }
        }
      }
    case RESPONSE_UPDATE_ACTIVITY:
      return {
        ...state,
        activities: {
          ...state.activities,
          [action.payload.key]: {
            ...state.activities[action.payload.key],
            updateDataState: DATA_STATE_RECIEVED,
            data: action.payload.data,
            editMode: false
          }
        }
      }
    case RESPONSE_UPDATE_ACTIVITY_ERROR:
      return {
        ...state,
        activities: {
          ...state.activities,
          [action.payload]: {
            ...state.activities[action.payload],
            updateDataState: DATA_STATE_ERROR
          }
        }
      }
    // update activity type
    case REQUEST_UPDATE_ACTIVITY_TYPE:
      return {
        ...state,
        activities: {
          ...state.activities,
          [action.payload]: {
            ...state.activities[action.payload],
            updateTypeDataState: DATA_STATE_REQUESTING
          }
        }
      }
    case RESPONSE_UPDATE_ACTIVITY_TYPE:
      return {
        ...state,
        activities: {
          ...state.activities,
          [action.payload.key]: {
            ...state.activities[action.payload.key],
            updateTypeDataState: DATA_STATE_RECIEVED,
            data: {
              ...state.activities[action.payload.key].data,
              ...action.payload.data,
              type: action.payload.data.type
            }
          }
        }
      }
    case RESPONSE_UPDATE_ACTIVITY_TYPE_ERROR:
      return {
        ...state,
        activities: {
          ...state.activities,
          [action.payload]: {
            ...state.activities[action.payload],
            updateTypeDataState: DATA_STATE_ERROR
          }
        }
      }
    // delete activity from list
    case REQUEST_DELETE_ACTIVITY:
      return {
        ...state,
        activities: {
          ...state.activities,
          [action.payload]: {
            ...state.activities[action.payload],
            deleteDataState: DATA_STATE_REQUESTING
          }
        }
      }
    case RESPONSE_DELETE_ACTIVITY:
      return (() => {
        const { [action.payload]: removedActivity, ...restActivities } = state.activities

        return {
          ...state,
          activities: { ...restActivities }
        }
      })()
    case RESPONSE_DELETE_ACTIVITY_ERROR:
      return {
        ...state,
        activities: {
          ...state.activities,
          [action.payload]: {
            ...state.activities[action.payload],
            deleteDataState: DATA_STATE_ERROR
          }
        }
      }
    // control edit mode
    case CHANGE_EDIT_MODE:
      return {
        ...state,
        activities: {
          ...state.activities,
          [action.payload.key]: {
            ...state.activities[action.payload.key],
            editMode: action.payload.data
          }
        }
      }
    // reset state when form reseted
    case dealFormHelpers.RESET_DATA_STATE:
      return { ...initialState }

    // fill activities
    case dealFormHelpers.RESPONSE_GET_DATA_BY_ID:
      return buildActivitiesState(state, action, defaultActivityState)
    case dealFormHelpers.RESPONSE_ACTIVATE_DEAL:
      return buildActivitiesState(state, action, defaultActivityState)
    case dealFormHelpers.RESPONSE_DEACTIVATE_DEAL:
      return buildActivitiesState(state, action, defaultActivityState)

    case dealFormGroupsHelpers.RESPONSE_ASSIGN_GROUPS:
      return (() => {
        const { id, groups } = action.payload
        return {
          ...state,
          activities: {
            ...state.activities,
            ...groups.reduce((acc, value) => {
              const key = activityStateKeyBuilder(id, get(value, 'activity.id'))
              const isExits = Boolean(state.activities[key])
              return isExits
                ? acc
                : {
                    ...acc,
                    [key]: {
                      ...defaultActivityState,
                      data: value
                    }
                  }
            }, {})
          }
        }
      })()
    case RESET_DATA_STATE:
      return {
        ...initialState
      }
    case RESET_ACTIVITY_DATA_STATE:
      return (() => {
        const { [action.payload]: removedActivityState, ...restActivitiesStates } = state.activities
        return { ...state, activities: { ...restActivitiesStates } }
      })()
    default:
      return state
  }
}

export default filterActions(dealFormActivitiesReducer, [
  RESET_DATA_STATE,
  RESET_ACTIVITY_DATA_STATE,
  REQUEST_UPDATE_ACTIVITY,
  RESPONSE_UPDATE_ACTIVITY,
  RESPONSE_UPDATE_ACTIVITY_ERROR,
  dealFormHelpers.RESPONSE_GET_DATA_BY_ID,
  REQUEST_UPDATE_ACTIVITY_TYPE,
  RESPONSE_UPDATE_ACTIVITY_TYPE,
  RESPONSE_UPDATE_ACTIVITY_TYPE_ERROR,
  REQUEST_DELETE_ACTIVITY,
  RESPONSE_DELETE_ACTIVITY,
  RESPONSE_DELETE_ACTIVITY_ERROR,
  CHANGE_EDIT_MODE,
  dealFormGroupsHelpers.RESPONSE_ASSIGN_GROUPS,
  dealFormHelpers.RESET_DATA_STATE,
  dealFormHelpers.RESPONSE_ACTIVATE_DEAL,
  dealFormHelpers.RESPONSE_DEACTIVATE_DEAL
])
