/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildListParams, buildURLForGettingList } from 'services/http/helpers'
import { ROLE_URL } from 'endpoints'

// TODO delete getRoleList after custom roles deployment
export const getRoleList = (authKey, options) =>
  httpWrapper({
    url: buildURLForGettingList(ROLE_URL),
    method: 'get',
    params: buildListParams(options)
  })
