/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { BouncingLoader } from 'components/Loaders'

export function withLoader(Component, LoaderComponent = BouncingLoader) {
  function WithLoader(props) {
    const { isLoading, ...rest } = props
    return isLoading ? <LoaderComponent /> : <Component {...rest} />
  }

  WithLoader.propTypes = {
    isLoading: PropTypes.bool
  }
  WithLoader.defaultProps = {
    isLoading: false
  }

  const componentName = Component.displayName || Component.name || 'Component'

  WithLoader.displayName = `withLoader(${componentName})`
  return WithLoader
}
