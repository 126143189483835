/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import isArray from 'lodash/isArray'

import { useLoading } from 'hooks'
import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import { BouncingLoader } from 'components/Loaders'
import {
  YELLOW_GREEN_SCHEMA,
  DEACTIVATE_TITLE,
  DECLINE_TITLE,
  ORANGE_SCHEMA
} from 'constants/buttonsOptions'

import {
  TITLE,
  MAKE_INACTIVE_MESSAGE,
  MESSAGE_NO_PROGRAMS,
  DEACTIVATED_PROGRAM_EXTRA_MESSAGE
} from '../types'

import styles from '../AppDeactivation.scss'

export function AppDeactivationModal(props) {
  const {
    isOpen,
    getAppProgramsById,
    appProgramDataState,
    appPrograms,
    onClose,
    onSubmit,
    dataId
  } = props
  const [isLoading, { isLoaded }] = useLoading(appProgramDataState)

  useEffect(() => {
    if (dataId && !isLoaded && isOpen) {
      getAppProgramsById(dataId)
    }
  }, [dataId, getAppProgramsById, isLoaded, isOpen])

  const modalBodyContent = () => {
    const isExistedPrograms = appPrograms && isArray(appPrograms) && appPrograms.length > 0

    return (
      <section className={styles.wrapper}>
        <span>{isExistedPrograms ? MAKE_INACTIVE_MESSAGE : MESSAGE_NO_PROGRAMS}</span>
        {isLoading ? (
          <BouncingLoader />
        ) : (
          isExistedPrograms && (
            <ul className={styles.list}>
              {appPrograms.map(itemProgram => {
                const { id, name, parameters } = itemProgram
                const { deactivate } = parameters
                return (
                  <li key={id} className={styles.listItem}>
                    {name}
                    {deactivate && (
                      <span className={styles.warningMessage}>
                        {DEACTIVATED_PROGRAM_EXTRA_MESSAGE}
                      </span>
                    )}
                  </li>
                )
              })}
            </ul>
          )
        )}
      </section>
    )
  }

  return (
    <BasicModal isOpen={isOpen} title={TITLE} onClose={onClose} renderBody={modalBodyContent}>
      <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onSubmit} disabled={isLoading}>
        {DEACTIVATE_TITLE}
      </BasicButton>
      <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
        {DECLINE_TITLE}
      </BasicButton>
    </BasicModal>
  )
}

AppDeactivationModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  appPrograms: PropTypes.array.isRequired,
  appProgramDataState: PropTypes.string.isRequired,
  getAppProgramsById: PropTypes.func.isRequired,
  dataId: PropTypes.string
}

AppDeactivationModal.defaultProps = {
  dataId: null
}
