/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { RESET_STATE } from 'helpers/actionHelpers'
import types from './types'

const { NAME } = types

const SET_MODE_DISABLE = `SET_MODE_DISABLE_${NAME}`
const SET_MODE_VALUE = `SET_MODE_VALUE_${NAME}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

export default {
  SET_MODE_DISABLE,
  SET_MODE_VALUE,
  RESET_DATA_STATE
}
