/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Field.scss'

export function Field(props) {
  const { label, children, wrapperClassNames, labelClassNames, contentClassNames } = props
  return (
    <div className={classNames(styles.fieldWrapper, wrapperClassNames)}>
      <span className={classNames(styles.label, labelClassNames)}>{label}</span>
      <span className={classNames(styles.content, contentClassNames)}>{children}</span>
    </div>
  )
}

Field.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  wrapperClassNames: PropTypes.string,
  labelClassNames: PropTypes.string,
  contentClassNames: PropTypes.string
}

Field.defaultProps = {
  wrapperClassNames: '',
  labelClassNames: '',
  contentClassNames: ''
}
