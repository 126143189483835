/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import {
  getScheduleSeriesById,
  createScheduleSeries,
  updateScheduleSeriesById,
  deleteScheduleSeriesById,
  deleteScheduleOccurrences,
  updateScheduleOccurrenceById,
  getClosureOverlaps,
  getScheduleOccurrenceByIdAndDay
} from 'services/http/schedules'
import helpers, { buildKey } from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA_ERROR,
  RESET_DATA_STATE,
  SUCCESS_ADD_MESSAGE,
  SUCCESS_UPDATED_MESSAGE,
  REQUEST_DELETE_SERIES,
  RESPONSE_DELETE_SERIES,
  RESPONSE_DELETE_SERIES_ERROR,
  REQUEST_DELETE_OCCURRENCE,
  RESPONSE_DELETE_OCCURRENCE,
  RESPONSE_DELETE_OCCURRENCE_ERROR,
  REQUEST_DELETE_OCCURRENCES,
  RESPONSE_DELETE_OCCURRENCES,
  RESPONSE_DELETE_OCCURRENCES_ERROR,
  SUCCESS_DELETE_OCCURRENCES_MESSAGE,
  ERROR_DELETE_OCCURRENCES_MESSAGE,
  REQUEST_INTERSECTIONS,
  RESPONSE_INTERSECTIONS,
  RESPONSE_INTERSECTIONS_ERROR
} = helpers

const createClosure = (activityId, contractData) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_FORM, { key }))
    const request = await createScheduleSeries(activityId, contractData)
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: SUCCESS_ADD_MESSAGE }))
    dispatch(actionCreater(RESPONSE_FORM, { data: request.data, key }))

    return Promise.resolve(request.data)
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))

    return Promise.reject()
  }
}

const updateClosureSeries = (activityId, seriesId, contractData) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_FORM, { key }))
    const request = await updateScheduleSeriesById(activityId, seriesId, contractData)
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: SUCCESS_UPDATED_MESSAGE }))
    dispatch(actionCreater(RESPONSE_FORM, { data: contractData, key }))

    return Promise.resolve(contractData)
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))

    return Promise.reject()
  }
}

const getClosureIntersections = (activityId, contractData) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_INTERSECTIONS, { key }))
    const request = await getClosureOverlaps(activityId, contractData)
    dispatch(actionCreater(RESPONSE_INTERSECTIONS, { data: request.data, key }))

    return Promise.resolve(request.data)
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_INTERSECTIONS_ERROR, { key }))

    return Promise.reject()
  }
}

const updateClosureOccurrence = (activityId, seriesId, contractData) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_FORM, { key }))
    const request = await updateScheduleOccurrenceById(activityId, seriesId, contractData)
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: SUCCESS_UPDATED_MESSAGE }))
    dispatch(actionCreater(RESPONSE_FORM, { data: contractData, key }))

    return Promise.resolve(contractData)
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))

    return Promise.reject()
  }
}

const getClosureById = (activityId, id) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_GET_ENTITY_DATA, { key }))
    const request = await getScheduleSeriesById(activityId, id)
    dispatch(actionCreater(RESPONSE_GET_ENTITY_DATA, { data: request.data, key }))

    return Promise.resolve(request.data)
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ENTITY_DATA_ERROR, { key }))

    return Promise.reject()
  }
}

const deleteSeriesById = (activityId, seriesId, successMessage, errorMessage) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_DELETE_SERIES, { key }))
    const request = await deleteScheduleSeriesById(activityId, seriesId)
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_DELETE_SERIES, { data: request.data, key }))
    dispatch(send({ ...message, message: successMessage }))

    return Promise.resolve(request.data)
  } catch ({ send, message }) {
    dispatch(send({ ...message, message: errorMessage }))
    dispatch(actionCreater(RESPONSE_DELETE_SERIES_ERROR, { key }))

    return Promise.reject()
  }
}

const getOccurrenceByIdAndDay = (activityId, seriesId, day) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_GET_ENTITY_DATA, { key }))
    const request = await getScheduleOccurrenceByIdAndDay(activityId, seriesId, day)
    dispatch(actionCreater(RESPONSE_GET_ENTITY_DATA, { data: request.data, key }))

    return Promise.resolve(request.data)
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ENTITY_DATA_ERROR, { key }))

    return Promise.reject()
  }
}

const deleteOccurrenceByIdAndDay = (
  activityId,
  seriesId,
  day,
  succesMessage,
  errorMessage
) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_DELETE_OCCURRENCE, { key }))
    const request = await deleteScheduleOccurrences(activityId, [{ seriesId, days: [day] }])
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_DELETE_OCCURRENCE, { data: request.data, key }))
    dispatch(send({ ...message, message: succesMessage }))

    return Promise.resolve(request.data)
  } catch ({ send, message }) {
    dispatch(send({ ...message, message: errorMessage }))
    dispatch(actionCreater(RESPONSE_DELETE_OCCURRENCE_ERROR, { key }))

    return Promise.reject()
  }
}

const deleteOccurrencesByIdAndArray = (activityId, occurrences) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_DELETE_OCCURRENCES, { key }))
    const request = await deleteScheduleOccurrences(activityId, occurrences)
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_DELETE_OCCURRENCES, { data: request.data, key }))
    dispatch(send({ ...message, message: SUCCESS_DELETE_OCCURRENCES_MESSAGE }))

    return Promise.resolve(request.data)
  } catch ({ send, message }) {
    dispatch(send({ ...message, message: ERROR_DELETE_OCCURRENCES_MESSAGE }))
    dispatch(actionCreater(RESPONSE_DELETE_OCCURRENCES_ERROR, { key }))

    return Promise.reject()
  }
}

const resetState = accessKey => dispatch =>
  dispatch(actionCreater(RESET_DATA_STATE, { key: buildKey(accessKey) }))

export default {
  createClosure,
  updateClosureSeries,
  getClosureById,
  resetState,
  deleteSeriesById,
  deleteOccurrenceByIdAndDay,
  deleteOccurrencesByIdAndArray,
  updateClosureOccurrence,
  getOccurrenceByIdAndDay,
  getClosureIntersections
}
