/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { SimpleBox } from '@xplorie/ui-commons'
import { UploadGuestsForm, GuestsList } from 'modules/Programs'
import { SliderRoundedLabel } from 'modules/SliderContainer'

import { PREBOOK_LABEL } from './types'

import styles from './UploadGuestsTab.scss'

export function UploadGuestsTab({ dataId, prebookOnly }) {
  return (
    <SimpleBox className={styles.wrapper}>
      {prebookOnly && <SliderRoundedLabel>{PREBOOK_LABEL}</SliderRoundedLabel>}
      <div className={styles.buttons}>
        <UploadGuestsForm dataId={dataId} />
      </div>
      <div className={styles.content}>
        <GuestsList dataId={dataId} />
      </div>
    </SimpleBox>
  )
}
UploadGuestsTab.propTypes = {
  dataId: PropTypes.string.isRequired,
  prebookOnly: PropTypes.bool.isRequired
}
