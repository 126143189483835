/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  E_TICKETS,
  BARCODES,
  WILL_CALL,
  NOT_REQUIRED,
  CODE_39,
  CODE_128,
  CODE_QR
} from 'constants/ticketFormats'

export const InvTypeOptions = [
  {
    label: 'E-Tickets',
    value: E_TICKETS
  },
  {
    label: 'Barcodes',
    value: BARCODES
  },
  {
    label: 'Xplorie Voucher',
    value: WILL_CALL
  },
  {
    label: 'Ticket not Required',
    value: NOT_REQUIRED
  }
]

export const barcodeOptions = [
  {
    label: 'Code 39',
    value: CODE_39
  },
  {
    label: 'Code 128',
    value: CODE_128
  },
  {
    label: 'QR Code',
    value: CODE_QR
  }
]
