/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const LIST_NAME = 'PROGRAM_GUESTS_DATA_LIST'
const DOWNLOAD_FILE = `DOWNLOAD_FILE_${LIST_NAME}`

export default {
  LIST_NAME,
  DOWNLOAD_FILE
}
