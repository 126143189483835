/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ButtonsWrapper } from 'components/Wrappers'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { RIGHT_ALIGN } from 'constants/alignTypes'
import { WHITE_SCHEMA, LIGHT_RED_SCHEMA, LIGHT_BLUE_SCHEMA } from 'constants/colorSchemas'

import { buildColorSchemaByType } from './helpers'

import styles from './EntityBox.scss'

export function EntityBox(props) {
  const { children, title, ButtonsComponent, className, colorSchema, titleClassName } = props
  const colorSchemaClass = buildColorSchemaByType(colorSchema)
  return (
    <div className={classNames(styles.wrapper, colorSchemaClass, className)}>
      <div className={styles.headerWrapper}>
        {title && <h1 className={classNames(styles.title, titleClassName)}>{title}</h1>}
        <ButtonsWrapper align={RIGHT_ALIGN}>
          <Column>{ButtonsComponent()}</Column>
        </ButtonsWrapper>
      </div>
      <div className={styles.contentWrapper}>{children}</div>
    </div>
  )
}
EntityBox.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  ButtonsComponent: PropTypes.func,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  colorSchema: PropTypes.oneOf([WHITE_SCHEMA, LIGHT_RED_SCHEMA, LIGHT_BLUE_SCHEMA])
}
EntityBox.defaultProps = {
  title: '',
  titleClassName: null,
  ButtonsComponent: () => null,
  className: 'box',
  children: null,
  colorSchema: WHITE_SCHEMA
}
