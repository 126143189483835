import React from 'react'
import PropTypes from 'prop-types'
import { FormContent, ControlButtonsContainer, TextField } from 'components/FormComponents'
import { SubmitButton } from 'components/Buttons'
import { ORANGE_SCHEMA, SUBMIT_TITLE } from 'constants/buttonsOptions'
import { emailValidate } from 'helpers/formHelpers'
import classNames from 'classnames'
import { DATA_STATE_RECIEVED } from 'helpers/types/actionHelpersTypes'
import styles from './styles.scss'
import { EMAIL_LABEL, EMAIL_PLACEHOLDER, EMAIL_ERROR_TEXT } from '../types'

export function InnerContent(props) {
  const { disabled, isEmailError, dataState, resetDataState } = props

  const resetState = () => {
    if (dataState === DATA_STATE_RECIEVED) {
      resetDataState()
    }
  }

  return (
    <FormContent disabled={disabled}>
      <div className={styles.textFieldWrapper}>
        <TextField
          onChange={resetState}
          fieldName="email"
          validate={emailValidate}
          label={EMAIL_LABEL}
          placeholder={EMAIL_PLACEHOLDER}
          className={classNames('input', styles.emailField)}
          isRequired
        />
        {isEmailError && <p className={styles.errorClassName}>{EMAIL_ERROR_TEXT}</p>}
      </div>
      <ControlButtonsContainer>
        <SubmitButton colorSchema={ORANGE_SCHEMA} className={styles.submitButton}>
          {SUBMIT_TITLE}
        </SubmitButton>
      </ControlButtonsContainer>
    </FormContent>
  )
}

InnerContent.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isEmailError: PropTypes.bool.isRequired,
  dataState: PropTypes.bool.isRequired,
  resetDataState: PropTypes.func.isRequired
}
