/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { GROUP_POSTFIX } from 'modules/Discounts/types'
import get from 'lodash/get'
import { ActivityForm } from './ActivityForm'

import styles from './GroupsContent.scss'

export function Group({ name, data, ...rest }) {
  return (
    <div className={styles.groupWrapper}>
      <span className={styles.groupTitle}>{`${name} ${GROUP_POSTFIX}`}</span>
      <div className={styles.groupContent}>
        {data.map(value => (
          <ActivityForm
            {...rest}
            key={value.id}
            id={value.id}
            activityId={get(value, 'activity.id')}
          />
        ))}
      </div>
    </div>
  )
}
Group.propTypes = {
  data: PropTypes.array,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}
Group.defaultProps = {
  data: []
}
