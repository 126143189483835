/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

// name field
export const NAME_LABEL = 'Name'
export const NAME_PLACEHOLDER = 'Name'
export const NAME_FIELD = 'name'

// altName field
export const ALT_LABEL = 'Alternative Name'
export const ALT_PLACEHOLDER = 'Alternative Name'
export const ALT_FIELD = 'altName'

// type field
export const TYPE_LABEL = 'Type'
export const TYPE_PLACEHOLDER = 'Type'
export const TYPE_FIELD = 'type'

// select labels
export const STATE_LABEL = 'State'
export const DESTINATION_LABEL = 'Destination'
export const COUNTRY_LABEL = 'Country'
// Obsolete types
export const CITY_LABEL = 'City'
export const DISTRICT_LABEL = 'District'

// other
export const ADD_NEW_BUTTON = 'Add New'

// warning modal
export const WARNING_TITLE = 'Warning'
export const WARNING_MESSAGE =
  'You can’t change the type of this Location since it is assigned to Active Programs.'
export const PROGRAMS_LINK = 'View Programs'
