/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import { YELLOW_GREEN_SCHEMA, OK_TITLE, SMALLER_SIZE } from 'constants/buttonsOptions'
import { MESSAGE, TITLE } from './types'
import styles from './InvalidFormModal.scss'

export function InvalidFormModal({ getApi }) {
  const [isOpen, setIsOpen] = useState(false)
  const [errors, setErrors] = useState({})

  const modalPromise = useRef()
  const modalResolve = useRef()

  const onOpenAction = useCallback((errorsData = {}) => {
    setIsOpen(true)
    if (errorsData) {
      setErrors(errorsData)
    }
    modalPromise.current = new Promise(resolve => {
      modalResolve.current = resolve
    })
    return modalPromise.current
  }, [])

  const onHideAction = useCallback(() => {
    setIsOpen(false)
    modalResolve.current(false)
  }, [])

  useEffect(() => {
    if (getApi) {
      getApi({ open: onOpenAction, hide: onHideAction })
    }
  }, [getApi, onHideAction, onOpenAction])

  return (
    <BasicModal
      isOpen={isOpen}
      title={TITLE}
      message={MESSAGE}
      wrapperClassName={styles.wrapper}
      onClose={onHideAction}
    >
      <div className={styles.content}>
        <div className={styles.messagesContainer}>
          {Object.keys(errors).map(label => (
            <ul className={styles.group} key={label}>
              <span className={styles.label}>{label}</span>
              <ul className={styles.list}>
                {errors[label].map(error => (
                  <li
                    key={`${label} - ${error.field} - ${error.message}`}
                    className={styles.listItem}
                  >
                    {error.field && (
                      <>
                        <span className={styles.fieldName}>{error.field}</span>
                        <span className={styles.dash} />
                      </>
                    )}
                    <span className={styles.message}>{error.message}</span>
                  </li>
                ))}
              </ul>
            </ul>
          ))}
        </div>
        <div className={styles.buttons}>
          <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onHideAction} size={SMALLER_SIZE}>
            {OK_TITLE}
          </BasicButton>
        </div>
      </div>
    </BasicModal>
  )
}
InvalidFormModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  getApi: PropTypes.func
}
InvalidFormModal.defaultProps = {
  getApi: () => null
}
