/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useCallback, useState } from 'react'
import isEmpty from 'lodash/isEmpty'

export function useDealContextMenuApiBuilder() {
  const [menuState, setMenuState] = useState({})

  const isMenuOpened = !isEmpty(menuState)

  const onOpenContextMenu = useCallback(data => {
    setMenuState(data)
  }, [])

  const onCloseContextMenu = useCallback(() => {
    setMenuState({})
  }, [])

  return [
    {
      menuState,
      isMenuOpened
    },
    {
      onCloseContextMenu,
      onOpenContextMenu
    }
  ]
}
