/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESET_STATE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE
} from 'helpers/actionHelpers'

import types from './types'

const { NAME, GET_CURRENT_PROGRAM, GET_RELATED_PROGRAM } = types

const REQUEST_GET_CURRENT_PROGRAM = `${REQUEST_TYPE}_${GET_CURRENT_PROGRAM}`
const RESPONSE_GET_CURRENT_PROGRAM = `${RESPONSE_TYPE}_${GET_CURRENT_PROGRAM}`
const RESPONSE_GET_CURRENT_PROGRAM_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_CURRENT_PROGRAM}`

const REQUEST_GET_RELATED_PROGRAM = `${REQUEST_TYPE}_${GET_RELATED_PROGRAM}`
const RESPONSE_GET_RELATED_PROGRAM = `${RESPONSE_TYPE}_${GET_RELATED_PROGRAM}`
const RESPONSE_GET_RELATED_PROGRAM_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_RELATED_PROGRAM}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

const compareProgramAndSeasonData = (programData = {}, seasonData = {}) => ({
  ...programData,
  seasons: seasonData.data
})

export default {
  REQUEST_GET_CURRENT_PROGRAM,
  RESPONSE_GET_CURRENT_PROGRAM,
  RESPONSE_GET_CURRENT_PROGRAM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_RELATED_PROGRAM,
  RESPONSE_GET_RELATED_PROGRAM,
  RESPONSE_GET_RELATED_PROGRAM_ERROR,
  compareProgramAndSeasonData
}
