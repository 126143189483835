/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { listInitialState } from './reducer'

/**
 *
 * @param {State} state
 * @param {string} appId Activity id
 */
const selectStateById = (state, appId) => state.closures.closuresList[appId] || listInitialState

export default {
  selectStateById
}
