/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export const DiscountsContext = React.createContext({
  isExist: false,
  isCreateAllowed: false,
  isEditAllowed: false,
  isDeleteAllowed: false,
  isActivateAllowed: false,
  isDeactivateAllowed: false,
  onChange: () => null
})
