/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { selectors } from 'reducers/campaigns/campaignsForm'
import keys from 'lodash/keys'

const { getCampaignStates, getCampaignState } = selectors

function getFormDataStates(state) {
  return keys(getCampaignStates(state)).reduce((acc, id) => {
    const formState = getCampaignState(state, id)
    return [...acc, formState.formDataState, formState.statusDataState]
  }, [])
}

export function getListWatchers(state) {
  return [...getFormDataStates(state)]
}

export const getCampaignsListState = state => state.campaigns.campaignsList
