/*
* COPYRIGHT:     Copyright © 2018 Xplorie LLC
* Warning:       This product is protected by United States and international copyright laws.
*                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
*/
import React from 'react'
import { AuthContext } from 'context'

export function withAuthContext(Component) {
  function WithAuthContext(props) {
    return (
      <AuthContext.Consumer>
        {authUser => <Component {...props} authUser={authUser} />}
      </AuthContext.Consumer>
    )
  }

  const componentName = Component.displayName || Component.name || 'Component'

  WithAuthContext.displayName = `withAuthContext(${componentName})`
  return WithAuthContext
}
