/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { formInitialState } from './reducer'
import { buildKey } from './helpers'

function getCampaignStates(state) {
  return state.campaigns.campaignsForm
}

/**
 *
 * @param {object} state
 * @param {string} campaignId
 */
function getCampaignState(state, campaignId) {
  return getCampaignStates(state)[buildKey(campaignId)] || formInitialState
}

export default {
  getCampaignState,
  getCampaignStates
}
