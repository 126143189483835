/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import {
  getExclusiveOfferActivities,
  removeExclusiveOfferActivity,
  addExclusiveOfferActivity
} from 'services/http/exclusiveOffers'

import helpers from './helpers'

const {
  REQUEST_EXCLUSIVE_ACTIVITIES_LIST,
  RESPONSE_EXCLUSIVE_ACTIVITIES_ERROR,
  RESPONSE_EXCLUSIVE_ACTIVITIES_LIST,
  REQUEST_REMOVE_EXCLUSIVE_ACTIVITY,
  RESPONSE_REMOVE_EXCLUSIVE_ACTIVITY,
  RESPONSE_REMOVE_EXCLUSIVE_ACTIVITY_ERROR,
  REQUEST_ADD_EXCLUSIVE_ACTIVITY,
  RESPONSE_ADD_EXCLUSIVE_ACTIVITY,
  RESPONSE_ADD_EXCLUSIVE_ACTIVITY_ERROR
} = helpers

const getProgramExclusiveActivitiesList = programId => async dispatch => {
  dispatch(actionCreater(REQUEST_EXCLUSIVE_ACTIVITIES_LIST))
  const request = await getExclusiveOfferActivities(programId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_EXCLUSIVE_ACTIVITIES_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_EXCLUSIVE_ACTIVITIES_LIST, { data: request.data }))
    return 'getExclusiveActivitiesList success'
  }
  return 'getExclusiveActivitiesList failed'
}

const removeProgramExclusiveActivity = (programId, activityId) => async dispatch => {
  dispatch(actionCreater(REQUEST_REMOVE_EXCLUSIVE_ACTIVITY))
  const request = await removeExclusiveOfferActivity(programId, activityId).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_REMOVE_EXCLUSIVE_ACTIVITY_ERROR))
    }
  )
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_REMOVE_EXCLUSIVE_ACTIVITY, { data: request.data }))
    dispatch(send(message))
    return 'removeProgramExclusiveActivity success'
  }
  return 'removeProgramExclusiveActivity failed'
}

const addProgramExclusiveActivity = (programId, activityId) => async dispatch => {
  dispatch(actionCreater(REQUEST_ADD_EXCLUSIVE_ACTIVITY))
  const request = await addExclusiveOfferActivity(programId, activityId).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_ADD_EXCLUSIVE_ACTIVITY_ERROR))
    }
  )

  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_ADD_EXCLUSIVE_ACTIVITY, { data: request.data }))
    dispatch(send(message))
    return 'addProgramExclusiveActivity success'
  }

  return 'addProgramExclusiveActivity failed'
}

export default {
  getProgramExclusiveActivitiesList,
  removeProgramExclusiveActivity,
  addProgramExclusiveActivity
}
