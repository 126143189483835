/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */
// Types for buttons schema
export {
  YELLOW_GREEN_SCHEMA,
  ORANGE_SCHEMA,
  LIGHTLY_ORANGE_SCHEMA as DEFAULT_SCHEMA,
  LIGHTLY_ORANGE_SCHEMA
} from './colorSchemas'

// Titles for buttons
export const SAVE_TITLE = 'Save'
export const EDIT_TITLE = 'Edit'
export const VIEW_TITLE = 'View'
export const SUBMIT_TITLE = 'Submit'
export const DELETE_TITLE = 'Delete'
export const ADD_NEW_TITLE = 'Add New'
export const CLEAR_TITLE = 'Clear'
export const UPLOAD_TITLE = 'Upload'
export const PREVIEW_TITLE = 'Preview'
export const SEARCH_TITLE = 'Search'
export const ACTIVATE_TITLE = 'Activate'
export const DEACTIVATE_TITLE = 'Deactivate'
export const DECLINE_TITLE = 'Decline'
export const YES_TITLE = 'Yes'
export const NO_TITLE = 'No'
export const OK_TITLE = 'Ok'
export const CANCEL_TITLE = 'Cancel'
export const UNIT_MANAGER_TITLE = 'Unit Manager'

export const HIDE_BUTTON_TITLE = 'Hide'
export const SHOW_BUTTON_TITLE = 'Show'

// Button sizes
export const SMALLER_SIZE = 'smaller'
export const DEFAULT_SIZE = 'default'

// additional schemas
export const LINK_SCHEMA = 'link'

// shape schemas
export const ROUND_SHAPE_SCHEMA = 'round'
export const SQUARE_SHAPE_SCHEMA = 'square'
