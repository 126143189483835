/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import find from 'lodash/find'
import {
  DEAL_GROUP_TRIGGER_TYPE,
  DEAL_GROUP_ANY_TYPE,
  DEAL_GROUP_OR_TYPE
} from 'constants/dealsTypes'
import { dealGroupsTypeOptions } from 'modules/Discounts/types'

export function getGravityByType(type) {
  switch (type) {
    case DEAL_GROUP_TRIGGER_TYPE:
      return 100
    case DEAL_GROUP_ANY_TYPE:
      return 50
    case DEAL_GROUP_OR_TYPE:
      return 25
    default:
      return -1
  }
}

export function getGroupNameByType(type) {
  const value = find(dealGroupsTypeOptions, ['value', type])
  return value ? value.label.toUpperCase() : 'Unknown'
}
