/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { OAUTH_URL, PROFILE_URL } from 'endpoints'

export const authAction = ({ email, password }) =>
  httpWrapper(
    {
      url: OAUTH_URL,
      method: 'post',
      data: `grant_type=password&username=${email}&password=${password}`
    },
    { secure: false, isAuthRequest: true }
  )

export const getUser = () =>
  httpWrapper({
    url: PROFILE_URL,
    method: 'get'
  })

export const refreshTokenAction = refreshToken =>
  httpWrapper(
    {
      url: OAUTH_URL,
      method: 'post',
      params: {
        grant_type: 'refresh_token',
        refresh_token: refreshToken
      }
    },
    { secure: false, isAuthRequest: true }
  )
