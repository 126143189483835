import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_GET_ACTIVITY_CATEGORY_TAGS,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_ERROR,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS,
  REQUEST_GET_ACTIVITY_CATEGORY_TAGS_BY_ID,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_BY_ID_ERROR,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_BY_ID
} = helpers

const initialState = {
  tags: [],
  tagsById: [],
  tagsState: DATA_STATE_NOT_REQUESTED,
  tagsByIdState: DATA_STATE_NOT_REQUESTED
}

const activityCategoryFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_ACTIVITY_CATEGORY_TAGS:
      return {
        ...state,
        tagsState: DATA_STATE_REQUESTING,
        tags: [],
      }
    case RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_ERROR:
      return {
        ...state,
        tagsState: DATA_STATE_ERROR,
        tags: []
      }
    case RESPONSE_GET_ACTIVITY_CATEGORY_TAGS:
      return {
        ...state,
        tagsState: DATA_STATE_RECIEVED,
        tags: action.payload
      }
    case REQUEST_GET_ACTIVITY_CATEGORY_TAGS_BY_ID:
      return {
        ...state,
        tagsByIdState: DATA_STATE_REQUESTING,
        tagsById: [],
      }
    case RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_BY_ID_ERROR:
      return {
        ...state,
        tagsByIdState: DATA_STATE_ERROR,
        tagsById: []
      }
    case RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_BY_ID:
      return {
        ...state,
        tagsByIdState: DATA_STATE_RECIEVED,
        tagsById: action.payload
      }
    default:
      return state
  }
}

export default filterActions(activityCategoryFormReducer, [
  REQUEST_GET_ACTIVITY_CATEGORY_TAGS,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_ERROR,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS,
  REQUEST_GET_ACTIVITY_CATEGORY_TAGS_BY_ID,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_BY_ID_ERROR,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_BY_ID
])
