/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { CalendarField, CustomNestedField, WeekSelectorField } from 'components/FormComponents'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { TrashIcon } from 'icons/svg/TrashIcon'
import { FORMAT } from 'constants/date'
import moment from 'moment'
import { validateAfterDate, validateBeforeDate } from 'helpers/formHelpers'
import DiscountRangeSelector from './DiscountRangeSelector'

import { useDealPeriodsApi } from './hooks'
import { CalendarEndDateCheckbox } from './CalendarEndDateCheckbox'
import styles from './styles.scss'
import {
  AVAILABILITIES_FIELD,
  END_DATE_FIELD,
  END_DATE_LABEL,
  END_DATE_PLACEHOLDER,
  NO_END_DATE_VALUE,
  START_DATE_FIELD,
  START_DATE_LABEL,
  START_DATE_PLACEHOLDER,
  DAYS_LABEL,
  DAYS_FIELD
} from './types'
import {
  validateDate,
  buildWeekSelectorMapper,
  daysInRange,
  filterAvailableWeekDays,
  DEFAULT_DAYS
} from './helpers'

export function DiscountPeriodField(props) {
  const { item, canRemove, isNewDiscount, isPeriodSpecific } = props
  const dealPeriodsApi = useDealPeriodsApi()
  const { noEndDateEnabled, included } = item

  const onRemoveClick = () => {
    dealPeriodsApi.onRemove(item.id)
  }

  const changePeriod = value => {
    dealPeriodsApi.onUpdate({
      ...item,
      ...value
    })
  }

  const onEndDateCheckboxChange = value => {
    changePeriod({
      endDate: value ? '' : item[END_DATE_FIELD],
      noEndDateEnabled: value
    })
  }

  const getPeriodDays = ({ startDate, endDate }) =>
    filterAvailableWeekDays(
      daysInRange(startDate, endDate),
      isPeriodSpecific ? DEFAULT_DAYS : item.days
    )

  const onStartDateChange = value => {
    changePeriod({
      startDate: value,
      days: getPeriodDays({ startDate: value, endDate: item[END_DATE_FIELD] })
    })
  }

  const onEndDateChange = value => {
    changePeriod({
      endDate: value,
      days: getPeriodDays({ startDate: item[START_DATE_FIELD], endDate: value })
    })
  }

  const onWeekdayChange = value => {
    changePeriod({
      days: value
    })
  }

  const getWeekSelectorMapper = () =>
    buildWeekSelectorMapper(daysInRange(item[START_DATE_FIELD], item[END_DATE_FIELD]))

  const startValidateField = `${AVAILABILITIES_FIELD}.${item.id}.${START_DATE_FIELD}`
  const endValidateField = `${AVAILABILITIES_FIELD}.${item.id}.${END_DATE_FIELD}`

  const startDateValidator = validateDate(
    validateBeforeDate,
    endValidateField,
    item.id,
    isNewDiscount
  )
  const endDateValidator = validateDate(
    validateAfterDate,
    startValidateField,
    item.id,
    isNewDiscount
  )

  return (
    <CustomNestedField field={item.id}>
      <div className={classNames(styles.dealPeriod, { [styles.hidden]: !included })}>
        <CalendarField
          fieldProps={{
            className: styles.field,
            isRequired: true
          }}
          fieldName={START_DATE_FIELD}
          label={START_DATE_LABEL}
          placeholder={START_DATE_PLACEHOLDER}
          validate={startDateValidator}
          notify={[endValidateField]}
          shownFormat={FORMAT}
          onChange={onStartDateChange}
        />
        <CalendarField
          fieldProps={{
            isRequired: true,
            className: styles.field
          }}
          fieldName={END_DATE_FIELD}
          label={END_DATE_LABEL}
          placeholder={END_DATE_PLACEHOLDER}
          validate={endDateValidator}
          notify={[startValidateField]}
          shownFormat={FORMAT}
          readOnly={noEndDateEnabled}
          overlayComponent={CalendarEndDateCheckbox}
          onChange={onEndDateChange}
          additionalProps={{
            disabledDays: noEndDateEnabled
              ? [{ before: moment(NO_END_DATE_VALUE, FORMAT).toDate() }]
              : [],
            overlayProps: {
              noEndDateEnabled,
              onChange: onEndDateCheckboxChange
            }
          }}
        />
        {isPeriodSpecific ? (
          <DiscountRangeSelector item={item} />
        ) : (
          <WeekSelectorField
            label={DAYS_LABEL}
            fieldName={DAYS_FIELD}
            mapper={getWeekSelectorMapper()}
            onChange={onWeekdayChange}
          />
        )}
        {canRemove && (
          <div className={styles.remove}>
            <TrashIcon className={styles.removeIcon} onClick={onRemoveClick} />
          </div>
        )}
      </div>
    </CustomNestedField>
  )
}

DiscountPeriodField.propTypes = {
  item: PropTypes.object.isRequired,
  canRemove: PropTypes.bool.isRequired,
  isNewDiscount: PropTypes.bool.isRequired,
  isPeriodSpecific: PropTypes.bool.isRequired
}
