/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Form, Text } from 'informed'
import { BasicButton, RoundButton } from '@xplorie/ui-commons'
import { withRemovalModal } from 'decorators'
import { SubmitButton } from 'components/Buttons'
import {
  TextField,
  SelectField,
  SwitchField,
  ToggleField,
  ControlButtonsContainer,
  FormContent
} from 'components/FormComponents'
import { decimalsValidation } from 'helpers/formHelpers'

import { HORIZONTAL_ALIGN } from 'constants/alignTypes'
import {
  ORANGE_SCHEMA,
  SUBMIT_TITLE,
  DELETE_TITLE,
  SMALLER_SIZE,
  CLEAR_TITLE
} from 'constants/buttonsOptions'
import { DEFAULT_CHARACTER_LIMIT } from 'helpers/types/formHelpers'
import {
  TICKET_TITLE_LABEL,
  XPLORIE_PRICE_LABEL,
  EXTRAS_PRICE_LABEL,
  DESCRIPTION_LABEL,
  TICKET_TO_GROUP_LABEL,
  TICKET_TITLE_PLACEHOLDER,
  XPLORIE_PRICE_PLACEHOLDER,
  EXTRAS_PRICE_PLACEHOLDER,
  DESCRIPTION_PLACEHOLDER,
  TICKET_TO_GROUP_PLACEHOLDER,
  RETAIL_PRICE_LABEL,
  RETAIL_PRICE_PLACEHOLDER,
  DELETE_MESSAGE,
  YES_TEXT,
  NO_TEXT,
  switcherOptions,
  SHOW_XPLORIE_TICKET_LABEL,
  LIST_PRICE_LABEL,
  LIST_PRICE_PLACEHOLDER
} from './types'
import {
  SELECTOR_EXTRAS_PRICE,
  SELECTOR_PERCENTAGE_EXTRAS_PRICE,
  SELECTOR_XPLORIE_PRICE,
  SELECTOR_PERCENTAGE_XPLORIE_PRICE
} from './selectors'

import styles from './TicketTypeForm.scss'
import { useTicketTypeFormPresenter } from './TicketTypeFormPresenter'

const DeleteButton = withRemovalModal(BasicButton, {
  message: DELETE_MESSAGE,
  cancelText: NO_TEXT,
  actionText: YES_TEXT
})

const fieldPropsStyles = {
  className: styles.inputWrapper,
  classNameLabel: styles.inputLabel,
  classNameWrapper: styles.fieldWrapper
}

export function TicketTypeForm(props) {
  const { onSubmit, isDisabled, ticketGroupsOptions, onDelete, isWillCallTicketFormat } = props

  const [
    { isDisabledDeleteButton, showListPrice, formApi },
    {
      validatePrices,
      onBlurPriceHandler,
      onFocusHandler,
      onChangeHandler,
      onClearFormHandler,
      onXplorieTicketsChange,
      listPriceValidation
    }
  ] = useTicketTypeFormPresenter(props)

  return (
    <Form
      getApi={api => {
        formApi.current = api
      }}
      onSubmit={onSubmit}
    >
      {() => (
        <FormContent disabled={isDisabled}>
          <Text field="id" type="hidden" />
          <TextField
            fieldProps={fieldPropsStyles}
            label={TICKET_TITLE_LABEL}
            fieldName="name"
            placeholder={TICKET_TITLE_PLACEHOLDER}
            isRequired
          />
          <TextField
            fieldProps={{
              align: HORIZONTAL_ALIGN,
              className: styles.inputWrapper,
              classNameWrapper: styles.fieldWrapper,
              renderInner: ({ children, error, errorClassName }) => (
                <div className={styles.toggleFieldContainer}>
                  <div className={styles.toggleFieldWrapper}>
                    {children}
                    <ToggleField
                      field={SELECTOR_PERCENTAGE_XPLORIE_PRICE}
                      options={switcherOptions}
                      classNameWrapper={styles.toggleFieldInner}
                      className={styles.button}
                      initialValue={false}
                      onChange={onChangeHandler(SELECTOR_XPLORIE_PRICE)}
                    />
                  </div>
                  {error && <span className={errorClassName}>{error}</span>}
                </div>
              )
            }}
            maxLength={10}
            label={XPLORIE_PRICE_LABEL}
            placeholder={XPLORIE_PRICE_PLACEHOLDER}
            fieldName={SELECTOR_XPLORIE_PRICE}
            onFocus={onFocusHandler(SELECTOR_XPLORIE_PRICE, SELECTOR_PERCENTAGE_XPLORIE_PRICE)}
            onBlur={onBlurPriceHandler}
            validate={validatePrices}
            isRequired
          />
          <TextField
            fieldProps={{
              align: HORIZONTAL_ALIGN,
              className: styles.inputWrapper,
              classNameWrapper: styles.fieldWrapper,
              renderInner: ({ children, error, errorClassName }) => (
                <div className={styles.toggleFieldContainer}>
                  <div className={styles.toggleFieldWrapper}>
                    {children}
                    <ToggleField
                      field={SELECTOR_PERCENTAGE_EXTRAS_PRICE}
                      options={switcherOptions}
                      classNameWrapper={styles.toggleFieldInner}
                      className={styles.button}
                      initialValue={false}
                      onChange={onChangeHandler(SELECTOR_EXTRAS_PRICE)}
                    />
                  </div>
                  {error && <span className={errorClassName}>{error}</span>}
                </div>
              )
            }}
            label={EXTRAS_PRICE_LABEL}
            placeholder={EXTRAS_PRICE_PLACEHOLDER}
            fieldName={SELECTOR_EXTRAS_PRICE}
            maxLength={10}
            onFocus={onFocusHandler(SELECTOR_EXTRAS_PRICE, SELECTOR_PERCENTAGE_EXTRAS_PRICE)}
            onBlur={onBlurPriceHandler}
            validate={validatePrices}
          />
          {showListPrice && (
            <TextField
              fieldProps={{
                align: HORIZONTAL_ALIGN,
                renderInner: textFieldProps => {
                  const { children, error, errorClassName } = textFieldProps
                  return (
                    <div className={styles.feeContainer}>
                      <div className={styles.feeWrapper}>
                        {children}
                        <RoundButton isActive className={styles.feeButton} />
                      </div>
                      {error && <span className={errorClassName}>{error}</span>}
                    </div>
                  )
                }
              }}
              maxLength={DEFAULT_CHARACTER_LIMIT}
              label={LIST_PRICE_LABEL}
              fieldName="listPrice"
              placeholder={LIST_PRICE_PLACEHOLDER}
              validate={listPriceValidation}
            />
          )}
          <TextField
            fieldProps={fieldPropsStyles}
            label={RETAIL_PRICE_LABEL}
            fieldName="retailPrice"
            placeholder={RETAIL_PRICE_PLACEHOLDER}
            validate={decimalsValidation}
            isRequired
          />
          <TextField
            fieldProps={fieldPropsStyles}
            label={DESCRIPTION_LABEL}
            fieldName="description"
            placeholder={DESCRIPTION_PLACEHOLDER}
          />
          {!isWillCallTicketFormat && (
            <SelectField
              fieldProps={{
                className: classNames('hero select', styles.inputWrapper),
                classNameLabel: styles.inputLabel,
                classNameWrapper: styles.fieldWrapper
              }}
              label={TICKET_TO_GROUP_LABEL}
              fieldName="ticketGroupId"
              placeholder={TICKET_TO_GROUP_PLACEHOLDER}
              options={ticketGroupsOptions}
            />
          )}
          <hr className={styles.hr} />
          <SwitchField
            fieldProps={fieldPropsStyles}
            label={SHOW_XPLORIE_TICKET_LABEL}
            field="xplorieTicketEnabled"
            onChange={onXplorieTicketsChange}
          />
          <ControlButtonsContainer className={styles.buttons}>
            <BasicButton
              colorSchema={ORANGE_SCHEMA}
              size={SMALLER_SIZE}
              disabled={isDisabled}
              onClick={onClearFormHandler}
            >
              {CLEAR_TITLE}
            </BasicButton>
            <DeleteButton
              colorSchema={ORANGE_SCHEMA}
              size={SMALLER_SIZE}
              onClick={onDelete}
              disabled={isDisabledDeleteButton}
            >
              {DELETE_TITLE}
            </DeleteButton>
            <SubmitButton colorSchema={ORANGE_SCHEMA} size={SMALLER_SIZE}>
              {SUBMIT_TITLE}
            </SubmitButton>
          </ControlButtonsContainer>
        </FormContent>
      )}
    </Form>
  )
}

TicketTypeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.func.isRequired,
  ticketGroupsOptions: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  isWillCallTicketFormat: PropTypes.bool.isRequired,
  children: PropTypes.node,
  error: PropTypes.string,
  errorClassName: PropTypes.string,
  className: PropTypes.string
}

TicketTypeForm.defaultProps = {
  children: null,
  error: null,
  errorClassName: '',
  className: ''
}
