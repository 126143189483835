/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import { pmsTableColumns, buildDefaultButtonColumn } from 'helpers/dataTablesHelpers'
import { canDeletePMS } from 'modules/PMS'
import { EditButton, DeleteButton } from './AdditionalColumns'

export const buildTableColumns = props => {
  const { removePMSDataById, authUser } = props
  const canDelete = canDeletePMS(authUser)

  const tableColumns = pmsTableColumns.concat({
    ...buildDefaultButtonColumn(),
    Cell: data => <EditButton {...props} data={data} />
  })

  if (canDelete) {
    tableColumns.push({
      ...buildDefaultButtonColumn({ width: 95 }),
      Cell: data => <DeleteButton {...props} data={data} deleteAction={removePMSDataById} />
    })
  }

  return tableColumns
}
