/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { asField } from 'informed'
import { Field } from 'components/FormComponents'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

function TextEditorComponent(props) {
  const {
    fieldState: { error, value },
    fieldApi: { setValue, setTouched },
    label,
    isRequired,
    fieldProps,
    id,
    disabled,
    placeholder,
    className
  } = props

  const onChangeHandler = useCallback(
    (content, delta, source, editor) => {
      if (disabled) {
        return
      }

      const contentText = editor.getText().trim()
      const contentValue = contentText ? content : ''

      setValue(contentValue)
      setTouched(true)
    },
    [disabled, setTouched, setValue]
  )

  const modules = useMemo(
    () => ({
      toolbar: disabled
        ? false
        : [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean']
          ]
    }),
    [disabled]
  )

  const quillValue = useMemo(() => value || '', [value])

  return (
    <Field id={id} label={label} isRequired={isRequired} error={error} {...fieldProps}>
      <ReactQuill
        className={className}
        value={quillValue}
        onChange={onChangeHandler}
        disabled={disabled}
        placeholder={placeholder}
        modules={modules}
        readOnly={disabled}
      />
    </Field>
  )
}

TextEditorComponent.propTypes = {
  fieldState: PropTypes.object,
  fieldApi: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  fieldProps: PropTypes.object,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string
}

TextEditorComponent.defaultProps = {
  fieldState: {},
  fieldApi: {},
  label: '',
  isRequired: false,
  fieldProps: {},
  id: null,
  disabled: false,
  placeholder: '',
  className: null
}

export const TextEditor = asField(TextEditorComponent)
