/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import isArray from 'lodash/isArray'

import { asField, Option } from 'informed'

import get from 'lodash/get'
import { Field } from 'components/FormComponents'
import { useInitialFieldValue } from 'hooks'

import SelectComponent from './SelectComponent'
import styles from './Select.scss'

function SelectWrapper(props) {
  const {
    fieldState: { value: fieldValue, error },
    fieldApi,
    options,
    placeholder,
    label,
    isRequired,
    fieldProps,
    fieldChildren,
    id,
    path,
    initial,
    ...rest
  } = props
  const { className, ...restFieldProps } = fieldProps
  const isComplex = path && isArray(path) && path.length
  const currentValue = isComplex ? get(fieldValue, path, '') : fieldValue

  useInitialFieldValue(() => fieldApi.setValue(initial), initial, fieldValue)

  return (
    <Field
      id={id}
      label={label}
      isRequired={isRequired}
      className={className || 'hero select'}
      error={error}
      renderFooter={({ error: fieldError, className: errorClassName }) =>
        fieldError && <span className={errorClassName}>{fieldError}</span>
      }
      {...restFieldProps}
    >
      <SelectComponent
        {...rest}
        error={error}
        fieldApi={fieldApi}
        options={options}
        id={id}
        required
        isComplex={isComplex}
        path={path}
      >
        <Option className={styles.placeholder} value="" disabled>
          {placeholder}
        </Option>
        {options.map((option, index) => {
          const key = option.key || `${option.value}[${index}]`
          const value = isComplex ? get(option.value, path, '') : option.value
          const isSelected = currentValue === value
          return (
            <Option key={key} value={value} hidden={option.hidden}>
              {(isSelected && option.selectedLabel) || option.label}
            </Option>
          )
        })}
      </SelectComponent>
    </Field>
  )
}
SelectWrapper.propTypes = {
  fieldApi: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array,
  fieldState: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  fieldProps: PropTypes.object,
  id: PropTypes.string,
  path: PropTypes.array,
  fieldChildren: PropTypes.any,
  initial: PropTypes.any
}
SelectWrapper.defaultProps = {
  options: [],
  fieldState: {},
  label: '',
  isRequired: false,
  fieldProps: {},
  id: null,
  path: null,
  fieldChildren: null,
  initial: null
}

export const Select = asField(SelectWrapper)
