/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useMemo } from 'react'
import { SimpleBox } from '@xplorie/ui-commons'
import { Form } from 'informed'
import { FormContent, TextField, SelectField, TextEditorField } from 'components/FormComponents'
import { symbolCountValidation, linkValidate } from 'helpers/formHelpers'

import {
  TYPES_OPTIONS,
  SECTION_TITLE,
  NAME_LABEL,
  NAME_PLACEHOLDER,
  NAME_FIELD,
  TYPE_LABEL,
  TYPE_PLACEHOLDER,
  TYPE_FIELD,
  DESCRIPTION_LABEL,
  DESCRIPTION_PLACEHOLDER,
  DESCRIPTION_FIELD,
  MAX_SYMBOL_COUNT_DEAL_NAME,
  SQUARE_IMAGE_LABEL,
  SQUARE_IMAGE_PLACEHOLDER,
  SQUARE_IMAGE_FIELD
} from './types'
import { SelectAppField } from './SelectAppField'
import { useDealApi } from '../hooks'
import { useDealOptionsApi, useDealOptionsState } from './hooks'

import styles from './styles.scss'
import { DiscountPrograms } from './DiscountPrograms/index'

export function DiscountOptions() {
  const api = useDealApi()
  const dealOptionsApi = useDealOptionsApi()
  const isDisabled = useMemo(() => !api.canEditForm(), [api])
  const state = useDealOptionsState()
  const isSelectProgramDisabled = state.values && (!state.values.type || !state.values.activity)

  const validateDealName = useCallback(
    value => symbolCountValidation(value, MAX_SYMBOL_COUNT_DEAL_NAME),
    []
  )

  return (
    <SimpleBox>
      <span className={styles.sectionTitle}>{SECTION_TITLE}</span>
      <Form
        getApi={dealOptionsApi.getApi}
        onChange={dealOptionsApi.onChange}
        className={styles.form}
      >
        <FormContent disabled={isDisabled}>
          <TextField
            label={NAME_LABEL}
            placeholder={NAME_PLACEHOLDER}
            fieldName={NAME_FIELD}
            validate={validateDealName}
            isRequired
          />
          <SelectAppField />
          <SelectField
            label={TYPE_LABEL}
            placeholder={TYPE_PLACEHOLDER}
            fieldName={TYPE_FIELD}
            options={TYPES_OPTIONS}
            isRequired
          />
          <DiscountPrograms
            api={dealOptionsApi}
            offerType={state.values && state.values.type}
            activity={state.values && state.values.activity}
            disabled={isDisabled || isSelectProgramDisabled}
            discountId={state.values && state.values.id}
            program={state.values && state.values.program}
          />
          <TextEditorField
            label={DESCRIPTION_LABEL}
            placeholder={DESCRIPTION_PLACEHOLDER}
            fieldName={DESCRIPTION_FIELD}
          />
          <TextField
            label={SQUARE_IMAGE_LABEL}
            placeholder={SQUARE_IMAGE_PLACEHOLDER}
            fieldName={SQUARE_IMAGE_FIELD}
            validate={linkValidate}
          />
        </FormContent>
      </Form>
    </SimpleBox>
  )
}
