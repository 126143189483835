/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'components/FormComponents'
import { TypeaheadSearch } from 'modules/TypeaheadSearch'
import { ButtonsWrapper } from 'components/Wrappers'
import { Column } from 'components/Wrappers/ButtonsWrapper'

import styles from './AddingEntityBox.scss'

export const AddingEntityBox = ({
  title,
  label,
  AddEntityButton,
  children,
  isVisibleSearch,
  typeaheadProps,
  error
}) => {
  const customTypeaheadProps = {
    ...typeaheadProps,
    isCleared: true
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <ButtonsWrapper>
          <Column>
            <h1 className={styles.title}>{title}</h1>
          </Column>
          {AddEntityButton && <Column>{AddEntityButton}</Column>}
        </ButtonsWrapper>
      </div>
      {isVisibleSearch && (
        <Field label={label}>
          <TypeaheadSearch {...customTypeaheadProps} />
        </Field>
      )}
      {children}
      <p className="help is-danger">{error}</p>
    </div>
  )
}
AddingEntityBox.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  AddEntityButton: PropTypes.node,
  children: PropTypes.node,
  isVisibleSearch: PropTypes.bool,
  typeaheadProps: PropTypes.object,
  error: PropTypes.string
}
AddingEntityBox.defaultProps = {
  label: '',
  children: null,
  AddEntityButton: null,
  isVisibleSearch: false,
  typeaheadProps: {},
  error: ''
}
