import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'informed'
import { DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { FormContent, ControlButtonsContainer, TextField } from 'components/FormComponents'
import { withValidation, blankValidate } from 'helpers/formHelpers'
import { checkConfirmNewPassword } from 'modules/ChangePasswordForm/helpers'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { SubmitButton } from 'components/Buttons'
import { useHistory } from 'react-router-dom'
import { DEFAULT_HOME_REDIRECT_URL } from 'services/auth'
import {
  TYPE_NEW_PASSWORD,
  TYPE_CONFIRM_NEW_PASSWORD,
  LABEL_NEW_PASSWORD,
  LABEL_CONFIRM_PASSWORD,
  PLACEHOLDER_NEW_PASSWORD,
  PLACEHOLDER_CONFIRM_PASSWORD,
  SUBMIT_BUTTON_TEXT,
  TYPE_INPUT_PASSWORD,
  PASSWORDS_NOT_MATCH
} from './types'
import styles from './PasswordRecoveryForm.scss'

export const PasswordRecoveryForm = ({
  recoveryToken,
  recoverPassword,
  recoveryPasswordDataState
}) => {
  const history = useHistory()
  const formApi = useRef({})
  const isFormDisabled = recoveryPasswordDataState === DATA_STATE_REQUESTING
  const passwordsMatchValidate = checkConfirmNewPassword(TYPE_NEW_PASSWORD, PASSWORDS_NOT_MATCH)
  const passwordsValidate = withValidation(blankValidate, passwordsMatchValidate)

  const getApi = api => {
    formApi.current = api
  }

  const redirectToLoginPage = () => {
    history.push(DEFAULT_HOME_REDIRECT_URL)
  }

  const onKeyDown = e => {
    if (e.keyCode && e.keyCode === 13) {
      formApi.current.submitForm()
    }
  }

  const onSubmit = formValues => {
    recoverPassword({ ...formValues, ...recoveryToken }, redirectToLoginPage)
  }

  return (
    <Form onSubmit={onSubmit} getApi={getApi} onKeyDown={onKeyDown}>
      {() => (
        <FormContent formApi={formApi} disabled={isFormDisabled}>
          <div className={styles.formContentWrapper}>
            <div className={styles.textFieldWrapper}>
              <TextField
                fieldName={TYPE_NEW_PASSWORD}
                label={LABEL_NEW_PASSWORD}
                placeholder={PLACEHOLDER_NEW_PASSWORD}
                type={TYPE_INPUT_PASSWORD}
                validate={passwordsValidate}
                notify={[TYPE_CONFIRM_NEW_PASSWORD]}
                className={styles.passwordInput}
              />
            </div>
            <div className={styles.textFieldWrapper}>
              <TextField
                fieldName={TYPE_CONFIRM_NEW_PASSWORD}
                label={LABEL_CONFIRM_PASSWORD}
                placeholder={PLACEHOLDER_CONFIRM_PASSWORD}
                type={TYPE_INPUT_PASSWORD}
                validate={passwordsValidate}
                notify={[TYPE_NEW_PASSWORD]}
                className={styles.passwordInput}
              />
            </div>
            <ControlButtonsContainer>
              <SubmitButton colorSchema={ORANGE_SCHEMA}>{SUBMIT_BUTTON_TEXT}</SubmitButton>
            </ControlButtonsContainer>
          </div>
        </FormContent>
      )}
    </Form>
  )
}

PasswordRecoveryForm.propTypes = {
  recoveryPasswordDataState: PropTypes.string.isRequired,
  recoveryToken: PropTypes.object.isRequired,
  recoverPassword: PropTypes.func.isRequired
}
