/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import {
  FORM_NAME,
  GET_ENTITY_NAME,
  LINK_CHILD_LOCATION,
  CREATE_COUNTRY,
  CREATE_LOCATION_AND_LINK,
  UPDATE_AND_ADD_CHILDREN,
  NEW_KEY
} from './types'

const REQUEST_FORM = `${REQUEST_TYPE}_${FORM_NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${FORM_NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${FORM_NAME}`

const REQUEST_COUNTRY_FORM = `${REQUEST_TYPE}_${CREATE_COUNTRY}`
const RESPONSE_COUNTRY_FORM = `${RESPONSE_TYPE}_${CREATE_COUNTRY}`
const RESPONSE_COUNTRY_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${CREATE_COUNTRY}`

const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

const REQUEST_LOCATION = `${REQUEST_TYPE}_${GET_ENTITY_NAME}`
const RESPONSE_LOCATION = `${RESPONSE_TYPE}_${GET_ENTITY_NAME}`
const RESPONSE_LOCATION_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_ENTITY_NAME}`

const REQUEST_LINK_CHILD_LOCATION = `${REQUEST_TYPE}_${LINK_CHILD_LOCATION}`
const RESPONSE_LINK_CHILD_LOCATION = `${RESPONSE_TYPE}_${LINK_CHILD_LOCATION}`
const RESPONSE_LINK_CHILD_LOCATION_ERROR = `${RESPONSE_ERROR_TYPE}_${LINK_CHILD_LOCATION}`

const REQUEST_CREATE_LOCATION_AND_LINK = `${REQUEST_TYPE}_${CREATE_LOCATION_AND_LINK}`
const RESPONSE_CREATE_LOCATION_AND_LINK = `${RESPONSE_TYPE}_${CREATE_LOCATION_AND_LINK}`
const RESPONSE_CREATE_LOCATION_AND_LINK_ERROR = `${RESPONSE_ERROR_TYPE}_${CREATE_LOCATION_AND_LINK}`

const REQUEST_UPDATE_AND_ADD_CHILDREN = `${REQUEST_TYPE}_${UPDATE_AND_ADD_CHILDREN}`
const RESPONSE_UPDATE_AND_ADD_CHILDREN = `${RESPONSE_TYPE}_${UPDATE_AND_ADD_CHILDREN}`
const RESPONSE_UPDATE_AND_ADD_CHILDREN_ERROR = `${RESPONSE_ERROR_TYPE}_${UPDATE_AND_ADD_CHILDREN}`

export default {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_COUNTRY_FORM,
  RESPONSE_COUNTRY_FORM,
  RESPONSE_COUNTRY_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_LOCATION,
  RESPONSE_LOCATION,
  RESPONSE_LOCATION_ERROR,
  REQUEST_LINK_CHILD_LOCATION,
  RESPONSE_LINK_CHILD_LOCATION,
  RESPONSE_LINK_CHILD_LOCATION_ERROR,
  REQUEST_CREATE_LOCATION_AND_LINK,
  RESPONSE_CREATE_LOCATION_AND_LINK,
  RESPONSE_CREATE_LOCATION_AND_LINK_ERROR,
  NEW_KEY,
  REQUEST_UPDATE_AND_ADD_CHILDREN,
  RESPONSE_UPDATE_AND_ADD_CHILDREN,
  RESPONSE_UPDATE_AND_ADD_CHILDREN_ERROR
}
