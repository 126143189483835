/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import {
  actions as pmsAssignedEligibilityListActions,
  getPmsAssignedEligibilityListStateByPmsId
} from 'reducers/eligibility/pmsAssignedEligibilityList'

import { withAuthContext } from 'helpers/withAuthContext'

import { PMSEligibilityList as PMSEligibilityListComponent } from './PMSEligibilityList'

const mapStateToProps = (state, { dataId }) => {
  const listValuesState = getPmsAssignedEligibilityListStateByPmsId(state, dataId)
  return {
    data: listValuesState.data,
    dataState: listValuesState.dataState
  }
}

const mapDispatchToProps = dispatch => {
  const { getAssignedEligibilityListByPmsId, resetList } = pmsAssignedEligibilityListActions

  return bindActionCreators({ getList: getAssignedEligibilityListByPmsId, resetList }, dispatch)
}

export const PMSEligibilityList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PMSEligibilityListComponent)
