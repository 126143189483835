/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const SEASONS_LABEL_CONFLICTS = 'The created seasons contain some conflicts or errors'
export const FOOTNOTE_SELECTED_POINT = 'These dates have already been selected'
export const FOOTNOTE_WRONG_SELECTED_POINT = 'There are conflicts with the selected dates'

// error messages
export const BEGIN_END_MONTH_ERROR_MESSAGE =
  'The date of the end of the season can not be earlier than the date of the beginning'
export const NULL_ACTIVITIES_ERROR_MESSAGE = 'In a season there must be at least one activity'
export const INCCORECT_DATE_SEASON_ERROR_MESSAGE = 'Dates are incorrect'
export const UNKNOWN_ERROR_MESSAGE = 'Unknown error'
export const DATE_INTERSECTIONS_SEASONS_ERROR_MESSAGE = 'The seasons contain some conflicts'
export const NULL_SEASONS_ERROR_MESSAGE = 'There must be one or more seasons.'
