/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { NAME, CHANGE_STATUS_BULK, UPLOAD_UNITS_FILE } = types

const REQUEST_CHANGE_STATUS_BULK = `${REQUEST_TYPE}_${CHANGE_STATUS_BULK}`
const RESPONSE_CHANGE_STATUS_BULK = `${RESPONSE_TYPE}_${CHANGE_STATUS_BULK}`
const RESPONSE_ERROR_CHANGE_STATUS_BULK = `${RESPONSE_ERROR_TYPE}_${CHANGE_STATUS_BULK}`

const REQUEST_UPLOAD_UNITS_FILE = `${REQUEST_TYPE}_${UPLOAD_UNITS_FILE}`
const RESPONSE_UPLOAD_UNITS_FILE = `${RESPONSE_TYPE}_${UPLOAD_UNITS_FILE}`
const RESPONSE_ERROR_UPLOAD_UNITS_FILE = `${RESPONSE_ERROR_TYPE}_${UPLOAD_UNITS_FILE}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

const UNIT_STATUS_UPDATED_MESSAGE = 'Unit status successfully updated!'
const UNITS_LOADED_MESSAGE = 'Units have been loaded!'

export default {
  REQUEST_CHANGE_STATUS_BULK,
  RESPONSE_CHANGE_STATUS_BULK,
  RESPONSE_ERROR_CHANGE_STATUS_BULK,
  RESET_DATA_STATE,
  UNIT_STATUS_UPDATED_MESSAGE,
  REQUEST_UPLOAD_UNITS_FILE,
  RESPONSE_UPLOAD_UNITS_FILE,
  RESPONSE_ERROR_UPLOAD_UNITS_FILE,
  UNITS_LOADED_MESSAGE
}
