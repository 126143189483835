/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  getDiscountDealFormStateById,
  getDiscountDealFormStates
} from 'connectors/reducers/discountDeal/discountDealForm'
import { compose } from 'redux'
import values from 'lodash/values'
import reduce from 'lodash/reduce'
import pick from 'lodash/pick'

import { buildKey } from './helpers'

import { formInitialState } from './reducer'

const reduceKeys = (keys = []) => array =>
  reduce(array, (acc, state) => [...acc, ...values(pick(state, keys))], [])

export const getCommonDealFormState = (state, accessKey) =>
  state.deals.dealCommon[buildKey(accessKey)] || formInitialState

export const getCommonDealFormStates = state => state.deals.dealCommon

export const sliderTitleWatchers = (state, key) => {
  const commonDealState = getCommonDealFormState(state, key)
  const discountDealState = getDiscountDealFormStateById(state, key)

  return [
    commonDealState.entityDataState,
    commonDealState.deactivateDataState,
    commonDealState.draftDataState,
    commonDealState.activateDataState,
    commonDealState.deleteDataState,
    discountDealState.formDataState,
    discountDealState.activateDataState
  ]
}

export const getListWatcherStates = state => {
  const commonWatchers = compose(
    reduceKeys(['deactivateDataState', 'draftDataState', 'activateDataState', 'deleteDataState']),
    values
  )(getCommonDealFormStates(state))
  const discountWatchers = compose(
    reduceKeys(['formDataState', 'activateDataState']),
    values
  )(getDiscountDealFormStates(state))

  return [...discountWatchers, ...commonWatchers]
}
