/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef, useCallback, useEffect, useState, useReducer } from 'react'
import PropTypes from 'prop-types'
import { usePrevious } from 'hooks'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import classNames from 'classnames'
import { TableFormEligibility /* EligibilityFieldsForm */ } from 'modules/Eligibility'
import { reducer, initialState } from './helpers'
import { /* FIELDS_CHANGED, */ TABLE_CHANGED } from './types'
import styles from './styles.scss'

export function ExtendedEligibilityForm({
  onSubmit,
  onChange,
  getApi,
  isHidden,
  disabled,
  ...rest
}) {
  const rootApi = useRef()
  // const fieldsFormApi = useRef()
  const tableFormApi = useRef()

  const [state, dispatch] = useReducer(reducer, initialState)
  const prevState = usePrevious(state)

  const [isReady, setReadyState] = useState(false)

  const toggleReadyState = useCallback(() => {
    if (/* fieldsFormApi.current && */ tableFormApi.current) {
      setReadyState(true)
    }
  }, [])

  // const onGetFieldsApi = useCallback(
  //   api => {
  //     fieldsFormApi.current = api
  //     toggleReadyState()
  //   },
  //   [toggleReadyState]
  // )

  const onGetTableApi = useCallback(
    api => {
      tableFormApi.current = api
      toggleReadyState()
    },
    [toggleReadyState]
  )

  const reset = useCallback(() => {
    tableFormApi.current.reset()
    // fieldsFormApi.current.reset()
  }, [])

  const setValues = useCallback(formValues => {
    if (isObject(formValues) && !isArray(formValues)) {
      const { values /* ...restValues */ } = formValues
      tableFormApi.current.setValue(values)
      // fieldsFormApi.current.setValues(restValues)
    }
  }, [])

  const submit = useCallback(() => {
    tableFormApi.current.submit()
    // fieldsFormApi.current.submitForm()
  }, [])

  const prepareTableState = ({ values, ...restState }) => ({
    ...restState,
    values: { values: tableFormApi.current.buildValues(values) }
  })

  // const onFieldsChangedHandler = useCallback(formState => {
  //   dispatch({
  //     type: FIELDS_CHANGED,
  //     payload: {
  //       formState
  //     }
  //   })
  // }, [])

  const onTableChangedHandler = useCallback(tableState => {
    dispatch({
      type: TABLE_CHANGED,
      payload: {
        formState: prepareTableState(tableState)
      }
    })
  }, [])

  useEffect(() => {
    if (
      onSubmit &&
      prevState &&
      prevState !== state &&
      !state.tableState.invalid &&
      prevState.tableState.submits !== state.tableState.submits &&
      state.tableState.submits > 0
    ) {
      onSubmit(state.formState.values)
    }
  }, [onSubmit, prevState, state])

  useEffect(() => {
    if (onChange && state.formState) {
      onChange(state.formState)
    }
  }, [onChange, state])

  useEffect(() => {
    if (isReady) {
      const { removeById } = tableFormApi.current
      rootApi.current = { reset, setValues, removeParameterById: removeById, submit }
    }
  })

  useEffect(() => {
    if (isReady && getApi) {
      getApi(rootApi.current)
    }
  }, [getApi, isReady])

  return (
    <div
      className={classNames({ [styles.isHidden]: isHidden })}
      data-testid="extendedEligibilityForm"
    >
      {/* <EligibilityFieldsForm */}
      {/*  getApi={onGetFieldsApi} */}
      {/*  disabled={disabled} */}
      {/*  onChange={onFieldsChangedHandler} */}
      {/* /> */}
      <TableFormEligibility
        {...rest}
        onChange={onTableChangedHandler}
        getApi={onGetTableApi}
        disabled={disabled}
      />
    </div>
  )
}

ExtendedEligibilityForm.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  getApi: PropTypes.func,
  isHidden: PropTypes.bool,
  disabled: PropTypes.bool
}
ExtendedEligibilityForm.defaultProps = {
  disabled: false,
  isHidden: false,
  getApi: null,
  onChange: null,
  onSubmit: null
}
