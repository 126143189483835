/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { INTEGRATION_URL } from 'endpoints'

export const checkUpdateProgress = (authKey, params) =>
  httpWrapper({
    url: INTEGRATION_URL,
    method: 'get',
    params
  })
