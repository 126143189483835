/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'

import { buildTicketSettingsURL, buildTicketSettingsEmailURL } from './helpers'

export const createEntity = (activityId, contractData) =>
  httpWrapper({ url: buildTicketSettingsURL(activityId), method: 'post', data: contractData })

export const updateEntity = (activityId, contractData) =>
  httpWrapper({ url: buildTicketSettingsURL(activityId), method: 'put', data: contractData })

export const getEntityById = activityId =>
  httpWrapper({ url: buildTicketSettingsURL(activityId), method: 'get' })

export const sendEmailPreview = (activityId, options) =>
  httpWrapper({
    url: buildTicketSettingsEmailURL(activityId),
    method: 'post',
    options
  })
