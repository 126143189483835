/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/unitTasks/unitTasksList'
import { withAuthContext } from 'helpers/withAuthContext'
import { actions as editQueueActions } from 'reducers/editQueue'

import UnitTasks from './UnitTasks'

const { getUnitTasksList, exportUnitTasks } = actions

const mapStateToProps = state => ({
  dataState: state.unitTasks.unitTasksList.dataState,
  exportDataState: state.unitTasks.unitTasksList.exportDataState,
  data: state.unitTasks.unitTasksList.data,
  panelStack: state.editQueue.panelStack
})

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onGetData: getUnitTasksList,
      exportUnitTasks,
      ...editQueueActions
    },
    dispatch
  )

const UnitTasksConnect = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UnitTasks)

export default UnitTasksConnect
