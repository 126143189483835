/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_PMC_DEACTIVATION,
  RESPONSE_PMC_DEACTIVATION,
  RESPONSE_ERROR_PMC_DEACTIVATION,
  REQUEST_PMC_ACTIVATION,
  RESPONSE_PMC_ACTIVATION,
  RESPONSE_ERROR_PMC_ACTIVATION,
  RESET_DATA_STATE
} = helpers

export const initialState = {}

export const pmcInitialState = {
  deactivationDataState: DATA_STATE_NOT_REQUESTED,
  activationDataState: DATA_STATE_NOT_REQUESTED
}

const pmcLifeCycleReducer = (state = initialState, action) => {
  switch (action.type) {
    // pmc deactivation
    case REQUEST_PMC_DEACTIVATION:
      return {
        ...state,
        [action.payload.key]: {
          ...pmcInitialState,
          ...state[action.payload.key],
          deactivationDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_PMC_DEACTIVATION:
      return {
        ...state,
        [action.payload.key]: {
          ...pmcInitialState,
          ...state[action.payload.key],
          deactivationDataState: DATA_STATE_RECIEVED
        }
      }
    case RESPONSE_ERROR_PMC_DEACTIVATION:
      return {
        ...state,
        [action.payload.key]: {
          ...pmcInitialState,
          ...state[action.payload.key],
          deactivationDataState: DATA_STATE_ERROR
        }
      }
    // pmc activation
    case REQUEST_PMC_ACTIVATION:
      return {
        ...state,
        [action.payload.key]: {
          ...pmcInitialState,
          ...state[action.payload.key],
          activationDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_PMC_ACTIVATION:
      return {
        ...state,
        [action.payload.key]: {
          ...pmcInitialState,
          ...state[action.payload.key],
          activationDataState: DATA_STATE_RECIEVED
        }
      }
    case RESPONSE_ERROR_PMC_ACTIVATION:
      return {
        ...state,
        [action.payload.key]: {
          ...pmcInitialState,
          ...state[action.payload.key],
          activationDataState: DATA_STATE_ERROR
        }
      }
    case RESET_DATA_STATE:
      return (() => {
        const { [action.payload.key]: removedState, ...restState } = state
        return {
          ...restState
        }
      })()
    default:
      return state
  }
}

export default filterActions(pmcLifeCycleReducer, [
  REQUEST_PMC_DEACTIVATION,
  RESPONSE_PMC_DEACTIVATION,
  RESPONSE_ERROR_PMC_DEACTIVATION,
  REQUEST_PMC_ACTIVATION,
  RESPONSE_PMC_ACTIVATION,
  RESPONSE_ERROR_PMC_ACTIVATION,
  RESET_DATA_STATE
])
