/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { SwitchButton } from '@xplorie/ui-commons'
import { Field } from 'components/FormComponents'

export function SwitchField(props) {
  const {
    fieldState: { value, error },
    fieldApi: { setValue, setTouched },
    fieldProps,
    id,
    label,
    isRequired,
    disabled,
    onChange,
    ...rest
  } = props

  const handleChange = useCallback(
    e => {
      setValue(!value)
      setTouched(true)
      if (onChange) {
        onChange(!value, e)
      }
    },
    [setValue, setTouched, onChange, value]
  )

  return (
    <Field id={id} label={label} isRequired={isRequired} error={error} {...fieldProps}>
      <SwitchButton onChange={handleChange} checked={value} disabled={disabled} {...rest} />
    </Field>
  )
}

SwitchField.propTypes = {
  fieldState: PropTypes.object.isRequired,
  fieldApi: PropTypes.object.isRequired,
  fieldProps: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}
SwitchField.defaultProps = {
  fieldProps: {},
  id: null,
  label: null,
  isRequired: false,
  disabled: false,
  onChange: () => null
}
