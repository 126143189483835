/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

// All possible Search Types from Search Service
export const ACTIVITY = 'activity'
export const ACCOUNT = 'account'
export const ROLE = 'role'
export const ACTIVITY_PROVIDER = 'activity_provider'
export const ACTIVITY_PROVIDER_CATEGORY = 'activity_provider_category'
export const CAMPAIGN = 'campaign'
export const PM_COMPANY = 'pm_company'
export const PROGRAM = 'program'
export const PROGRAM_SEASON = 'program_season'
export const ADDRESS = 'address'
export const ACTIVITY_CATEGORY = 'activity_category'
export const ACTIVITY_TAG = 'activity_tag'
export const PM_SYSTEM = 'pm_system'
export const LOCATION = 'location'
export const LOCATIONS = 'locations'
export const DESTINATION = 'destination'
export const TILE_ACCORDION = 'tile_accordion'
export const ACTIVITY_MODULE = 'activity_module'
export const ACTIVITY_TAGS = 'activity_tags'
export const RMS = 'rms'
export const UNIQUE_PHONE_NUMBER = 'unique_phone_number'
export const ORDER = 'order'
export const DEAL = 'deal'
export const DISCOUNT = 'discount'
export const MARKET = 'market'
export const RESALE_SETTINGS = 'resale_settings'
export const EXCLUSIVE_ACTIVITY = 'exclusive_activity'
// These options are defined on back-end but not provided to api
export const UNIT = 'unit'
export const ELIGIBILITY_PARAMETER = 'parameter'

// TODO: Not use option
export const TICKET_GROUP = 'ticket_group'
export const TASK_GROUP = 'task_group'
