/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { buildAccessKey } from './helpers'
import { pmcUnitListInitialState } from './reducer'

export const getPmcUnitsListStateByPmcIdAndProgramId = (state, pmcId, programId) =>
  state.units.pmcProgramUnitsList[buildAccessKey(pmcId, programId)] || pmcUnitListInitialState

export const getPmcProgramUnitsListState = state => state.units.pmcProgramUnitsList
