/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import store from 'connectors'
import { actionCreater, DATA_STATE_NOT_REQUESTED, DATA_STATE_ERROR } from 'helpers/actionHelpers'
import { getList } from 'services/http/holidaysDictionary'

import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST, RESET_DATA_STATE } = helpers

const getHolidaysByYear = (options = {}) => async dispatch => {
  if (!options || !options.year) {
    return 'getHolidaysByYear failed'
  }
  const state = store.getState().holidaysDictionary.years[options.year]
  if (
    state &&
    (state.dataState !== DATA_STATE_NOT_REQUESTED || state.dataState !== DATA_STATE_ERROR)
  ) {
    return 'getHolidaysByYear failed'
  }
  dispatch(actionCreater(REQUEST_LIST, { year: options.year }))
  const request = await getList(options).catch(() => {
    dispatch(actionCreater(RESPONSE_ERROR_LIST, { year: options.year }))
  })
  if (request) {
    dispatch(
      actionCreater(RESPONSE_LIST, {
        data: request.data.holidays,
        year: options.year
      })
    )
    return 'getHolidaysByYear success'
  }

  return 'getHolidaysByYear failed'
}

const resetDataState = () => ({
  type: RESET_DATA_STATE
})
export default {
  getHolidaysByYear,
  resetDataState
}
