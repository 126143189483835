/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/programsList'
import { getProgramListWatchers } from 'reducers/programsForm'
import { actions as queueActions } from 'reducers/editQueue'
import { withAuthContext } from 'helpers/withAuthContext'

import { ProgramListComponent } from './ProgramsList'

export const mapStateToProps = state => {
  const dataStateWatchers = getProgramListWatchers(state)
  return {
    dataState: state.programsList.dataState,
    data: state.programsList.data,
    dataStateWatchers,
    itemsPerPage: state.programsList.itemsPerPage,
    itemsCount: state.programsList.itemsCount,
    pageNumber: state.programsList.pageNumber
  }
}

export const mapDispatchToProps = dispatch => {
  const { getProgramsList } = actions
  return bindActionCreators({ onGetData: getProgramsList, ...queueActions }, dispatch)
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps
})

export const ProgramsList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )
)(ProgramListComponent)
