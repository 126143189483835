/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React, { useEffect, useRef, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'informed'
import get from 'lodash/get'
import { DATA_STATE_RECIEVED, DATA_STATE_ERROR } from 'helpers/actionHelpers'
import { withLoader } from 'decorators'
import { ProgramSeasonContext } from 'modules/Programs/helpers'
import { useUpdateSomeDataState, usePrevious, useDidMount, useLoading } from 'hooks'

import { ProgramsFormInnerContent } from './ProgramFormFields'
import { onGetFullFreeUniqPhoneList } from './helpers'

const InnerContentWithLoader = withLoader(ProgramsFormInnerContent)

function ProgramsForm(props) {
  const {
    dataId,
    getProgramById,
    programData,
    onError,
    onClose,
    dataState,
    programDataState,
    resetFreeList,
    unassignPhoneDataState,
    getApi,
    isProgramActionPending,
    pmcLifeCycleListDataStates,
    onChange
  } = props
  const formApi = useRef(null)
  const { permissions, conditions } = useContext(ProgramSeasonContext)
  const { canFormEnabled } = conditions
  const [programLoading, { isLoaded }] = useLoading(programDataState)
  const prevIsLoaded = usePrevious(isLoaded)
  const isFormEnabled = !isProgramActionPending && canFormEnabled
  const programType = get(props, 'programData.type')

  const setProgramDataInForm = useCallback(() => {
    const { location, pmCompany, ...rest } = programData
    formApi.current.setValues({
      ...rest,
      location: { id: get(location, 'id') },
      pmCompany: { id: get(pmCompany, 'id') }
    })
  }, [programData])

  const onGetFortApi = useCallback(
    api => {
      formApi.current = api
      if (getApi) {
        getApi({ ...api })
      }
    },
    [getApi]
  )

  const onChangeHandler = useCallback(() => {
    if (onChange) {
      onChange()
    }
  }, [onChange])

  useUpdateSomeDataState(
    () => {
      onError()
    },
    [programDataState],
    DATA_STATE_ERROR
  )

  useUpdateSomeDataState(
    () => {
      if (dataId) {
        getProgramById(dataId)
      }
      if (resetFreeList) {
        resetFreeList()
      }
      onGetFullFreeUniqPhoneList(props, programType)
    },
    [unassignPhoneDataState, ...pmcLifeCycleListDataStates],
    DATA_STATE_RECIEVED
  )

  useUpdateSomeDataState(
    () => {
      onClose()
    },
    [dataState],
    DATA_STATE_RECIEVED
  )

  useEffect(() => {
    if (prevIsLoaded !== isLoaded && isLoaded) {
      setProgramDataInForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, prevIsLoaded, setProgramDataInForm])

  useDidMount(() => {
    if (resetFreeList) {
      resetFreeList()
    }
  })

  return (
    <Form getApi={onGetFortApi} onSubmit={onChangeHandler} onChange={onChangeHandler}>
      {({ formApi: api, formState }) => (
        <InnerContentWithLoader
          {...permissions}
          {...conditions}
          {...props}
          formEnabled={isFormEnabled}
          isLoading={programLoading}
          formApi={api}
          formState={formState}
          formData={programData}
        />
      )}
    </Form>
  )
}

ProgramsForm.propTypes = {
  dataId: PropTypes.string,
  getProgramById: PropTypes.func.isRequired,
  programData: PropTypes.object,
  onError: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  dataState: PropTypes.string.isRequired,
  programDraftState: PropTypes.string.isRequired,
  programApprovalState: PropTypes.string.isRequired,
  programDataState: PropTypes.string.isRequired,
  resetFreeList: PropTypes.func.isRequired,
  getFreeUniqPhoneNumberList: PropTypes.func.isRequired,
  unassignPhoneDataState: PropTypes.string.isRequired,
  getApi: PropTypes.func,
  isProgramActionPending: PropTypes.bool.isRequired,
  isProgramDataLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  activateProgramDataState: PropTypes.string.isRequired,
  deactivateProgramDataState: PropTypes.string.isRequired,
  pmcLifeCycleListDataStates: PropTypes.array.isRequired
}

ProgramsForm.defaultProps = {
  dataId: null,
  programData: {},
  getApi: () => null
}

export default ProgramsForm
