/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useContext } from 'react'
import { SmartHostFeeFormContext } from '../types'
import { SmartHostFeeBox } from './SmartHostFeeBox'

function SmartHostFeeListMode() {
  const { fees } = useContext(SmartHostFeeFormContext)

  return (
    <>
      {fees.map(item => (
        <SmartHostFeeBox item={item} key={item.id} />
      ))}
    </>
  )
}

export default SmartHostFeeListMode
