/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'
import { getProgramOptionByAssigmentType } from './DiscountPrograms/helpers'
import { ASSIGMENT_TYPES } from './DiscountPrograms/types'

export function convertToFormValues(values) {
  if (!values) {
    return {}
  }

  const { groups, ...restValues } = values
  const activity = get(groups, '0.activity', {})
  return {
    ...restValues,
    programOption: values.program && getProgramOptionByAssigmentType(values.program.assignmentType),
    activity: {
      id: activity.id,
      name: activity.name,
      parameters: activity.parameters
    }
  }
}

const convertValuesToProgramPayload = formValues => {
  const { program, programOption } = formValues
  if (!programOption) {
    return {
      items: program,
      assignmentType: ASSIGMENT_TYPES.SPECIFIC
    }
  }

  return {
    items: [],
    assignmentType: programOption.id
  }
}

export function convertToPayload(values) {
  const { activity, programOption, ...restValues } = values
  return {
    activityId: get(activity, 'id'),
    specificType: get(activity, 'parameters.specificType'),
    ...restValues,
    program: convertValuesToProgramPayload(values)
  }
}
