/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

// Labels
export const UNIT_NAME_LABEL = 'Unit Name'
export const UNIT_ID_LABEL = 'Unit ID'
export const PMC_NAME_LABEL = 'PMC Name'
export const PROPERTY_TYPE_LABEL = 'Property Type'
export const SHORT_DESCRIPTION_LABEL = 'Short Description'
export const LONG_DESCRIPTION_LABEL = 'Long Description'
export const SLEEPS_LABEL = 'Sleeps'
export const BEDROOMS_LABEL = 'Bedrooms'
export const BATHROOMS_LABEL = 'Bathrooms'
export const PMC_LOGO_URL_LABEL = 'PMC Logo URL'
export const PMC_PHONE_NUMBER_LABEL = 'PMC Phone Number'
export const PMC_EMAIL_LABEL = 'PMC Email'
