/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { SimpleBox } from '@xplorie/ui-commons'
import classNames from 'classnames'
import { TrashButton } from 'components/Buttons'
import styles from './styles.scss'

export function Program({ program, onDelete, disabled }) {
  const onClickDeleteHandler = useCallback(() => {
    onDelete(program)
  }, [program, onDelete])

  if (!program) {
    return null
  }

  return (
    <SimpleBox className={classNames(styles.program)}>
      <div className={styles.header}>
        <span className={styles.title}>{program.name}</span>
        <div className={styles.actions}>
          {!disabled && <TrashButton onClick={onClickDeleteHandler} disabled={disabled} />}
        </div>
      </div>
    </SimpleBox>
  )
}

Program.propTypes = {
  program: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}
