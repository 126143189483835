/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import {
  UNIT_DETAILS_URL,
  UNIT_ADDRESS_URL,
  UNIT_AMENITY_URL,
  UNIT_IMAGES_URL,
  UNIT_SMART_HOST_URL,
} from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'

export const getEntityById = unitId =>
  httpWrapper({
    url: buildPath(UNIT_DETAILS_URL, { unitId }),
    method: 'get'
  })

export const getEntityAddressById = unitId =>
  httpWrapper({
    url: buildPath(UNIT_ADDRESS_URL, { unitId }),
    method: 'get'
  })

export const getEntityAmenitiesById = unitId =>
  httpWrapper({
    url: buildPath(UNIT_AMENITY_URL, { unitId }),
    method: 'get'
  })

export const getEntityImagesById = unitId =>
  httpWrapper({
    url: buildPath(UNIT_IMAGES_URL, { unitId }),
    method: 'get'
  })

export const getEntitySmartHostById = unitId =>
  httpWrapper({
    url: buildPath(UNIT_SMART_HOST_URL, { unitId }),
    method: 'get'
  })

export const updateSmartHostById = (contractData, unitId) =>
  httpWrapper({
    url: buildPath(UNIT_SMART_HOST_URL, { unitId }),
    method: 'put',
    data: contractData
  })