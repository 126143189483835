/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo } from 'react'
import { SimpleBox } from '@xplorie/ui-commons'
import { Form } from 'informed'
import { FormContent } from 'components/FormComponents'

import { useDealApi } from '../hooks'

import { DiscountTicketType } from './DiscountTicketType'
import { useDealTicketTypesApi } from './hooks'
import { TICKET_TYPES_FIELD } from './types'
import styles from './styles.scss'

export function DiscountTicketTypes() {
  const api = useDealApi()
  const ticketTypesApi = useDealTicketTypesApi()
  const ticketTypes = ticketTypesApi.getTicketTypes()
  const isDisabled = useMemo(() => !api.canEditForm(), [api])

  return (
    <Form getApi={ticketTypesApi.getApi} onChange={ticketTypesApi.onChange} className={styles.form}>
      {ticketTypes.length > 0 && (
        <SimpleBox className={styles.section}>
          <span className={styles.title}>{ticketTypesApi.ticketTypeTitle}</span>
          <FormContent field={TICKET_TYPES_FIELD} disabled={isDisabled}>
            {ticketTypes.map(item => (
              <DiscountTicketType item={item} key={item.id} />
            ))}
          </FormContent>
        </SimpleBox>
      )}
    </Form>
  )
}
