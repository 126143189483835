/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { assignUnitsProgram, unassignUnitsProgram } from 'services/http/pmcUnits'

import helpers, { buildAccessKey, buildSuccessMessage } from './helpers'

const {
  REQUEST_ASSIGN_UNITS,
  RESPONSE_ASSIGN_UNITS,
  RESPONSE_ERROR_ASSIGN_UNITS,
  RESET_DATA_STATE
} = helpers

const assignUnitsToProgram = (pmcId, programId, data) => async dispatch => {
  const key = buildAccessKey(pmcId, programId)
  dispatch(actionCreater(REQUEST_ASSIGN_UNITS, { key }))
  const request = await assignUnitsProgram(pmcId, programId, data).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_ASSIGN_UNITS, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildSuccessMessage() }))
    dispatch(actionCreater(RESPONSE_ASSIGN_UNITS, { key }))
    return 'assignUnitsToProgram success'
  }

  return 'assignUnitsToProgram failed'
}

const unassignUnitsFromProgram = (pmcId, programId, data) => async dispatch => {
  const key = buildAccessKey(pmcId, programId)
  dispatch(actionCreater(REQUEST_ASSIGN_UNITS, { key }))
  const request = await unassignUnitsProgram(pmcId, programId, data).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_ASSIGN_UNITS, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildSuccessMessage() }))
    dispatch(actionCreater(RESPONSE_ASSIGN_UNITS, { key }))
    return 'unassignUnitsFromProgram success'
  }

  return 'unassignUnitsFromProgram failed'
}

const resetState = (pmcId, programId) => dispatch =>
  dispatch(actionCreater(RESET_DATA_STATE, { key: buildAccessKey(pmcId, programId) }))

export default {
  assignUnitsToProgram,
  unassignUnitsFromProgram,
  resetState
}
