/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withDiscountsPermissions } from 'containers/Discounts'
import { withFieldApi, withFieldState } from 'informed'
import { getDealFormState } from 'connectors/reducers/deals/dealForm'

import { withFormContext } from 'components/FormComponents/helpers'
import { DealAvailabilityField as DealAvailabilityFieldComponent } from './DealAvailabilityField'

const mapStateToProps = state => {
  const dealState = getDealFormState(state)
  return {
    entityDataState: dealState.entityDataState,
    entityData: dealState.entityData
  }
}

export const DealAvailabilityField = compose(
  withDiscountsPermissions,
  withFormContext,
  withFieldState('availabilities'),
  withFieldApi('availabilities'),
  connect(mapStateToProps)
)(DealAvailabilityFieldComponent)
