/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { SimpleTable } from 'components/Tables'
import { useDataStatesComparison } from 'hooks'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { HEADER } from './types'
import { buildTableColumns } from './helpers'

export function DiscountsList(props) {
  const {
    dataState,
    itemsCount,
    itemsPerPage,
    data,
    onGetData,
    needUpdate,
    authUser,
    TableComponent,
    // TODO: Need remove it after refactoring.
    dataStateWatchers,
    //
    extendedOptions,
    getApi,
    ...rest
  } = props

  const listApi = useRef()

  const onGetListApi = useCallback(
    api => {
      listApi.current = api
      if (getApi) {
        getApi(api)
      }
    },
    [getApi]
  )

  useDataStatesComparison(
    () => {
      onGetData(listApi.current.getListParameters())
    },
    dataStateWatchers,
    DATA_STATE_RECIEVED,
    { ignoreLength: true }
  )

  return (
    <TableComponent
      {...rest}
      getApi={onGetListApi}
      dataState={dataState}
      columns={buildTableColumns(props)}
      onGetData={onGetData}
      itemsPerPage={itemsPerPage}
      itemsCount={itemsCount}
      tableHeader={HEADER}
      data={data}
      // TODO: Need remove it after refactoring.
      needUpdateList={needUpdate}
      withBasicAuth={false}
    />
  )
}

DiscountsList.propTypes = {
  dataState: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemsCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number,
  dataStateWatchers: PropTypes.array.isRequired,
  onGetData: PropTypes.func.isRequired,
  needUpdate: PropTypes.bool,
  authUser: PropTypes.object.isRequired,
  TableComponent: PropTypes.func,
  extendedOptions: PropTypes.object,
  getApi: PropTypes.func
}

DiscountsList.defaultProps = {
  extendedOptions: {},
  needUpdate: false,
  TableComponent: SimpleTable,
  itemsPerPage: 10,
  getApi: null
}
