/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const NOT_ACCEPTABLE_FILE_MESSAGE =
  'This file has incorrect extension. Only csv file is acceptable'
export const TOO_LARGE_FILE_MESSAGE = 'This file is too large to upload. The max file size: 1MB'
export const FILE_FORMAT_REGEX = /\.csv$/
// Max size 1Mb
export const MAX_FILE_SIZE = 10 ** 6
