/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { formInitialState } from './reducer'
import { buildKey } from './helpers'

function getScheduleStates(state) {
  return state.schedules.schedulesForm
}

/**
 *
 * @param {Object} state
 * @param {string} activityId
 */
function getScheduleStateById(state, activityId) {
  return getScheduleStates(state)[buildKey(activityId)] || formInitialState
}

export default {
  getScheduleStates,
  getScheduleStateById
}
