/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/editQueue'
import { actions as programActions } from 'reducers/programsForm'
import { actions as myWorkActions, getMyWorkListState } from 'reducers/myWork'
import { withAuthContext } from 'helpers/withAuthContext'
import withAuthorization from 'helpers/withAuthorization'
import { MyWork } from './MyWork'

const mapStateToProps = state => ({
  panelStack: state.editQueue.panelStack,
  getListState: type => getMyWorkListState(state, type)
})

const mapDispatchToProps = dispatch => {
  const { getList } = myWorkActions
  const { deleteProgram } = programActions
  return bindActionCreators({ ...actions, getList, deleteProgram }, dispatch)
}

export default compose(
  withAuthContext,
  withAuthorization,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MyWork)
