/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { entityInitialState } from './reducer'
import helpers from './helpers'

const { NEW_ENTITY_KEY } = helpers

function getMarketDictionaryFormState(state) {
  return state.marketDictionary.marketDictionaryForm
}

function getMarketFormStateById(state, id) {
  const accessKey = id || NEW_ENTITY_KEY
  const marketStates = getMarketDictionaryFormState(state)
  return marketStates[accessKey] || entityInitialState
}

function getMarketFormDataStatesById(state, id) {
  const marketState = getMarketFormStateById(state, id)
  return [marketState.dataState, marketState.deleleDataState]
}

function getMarketFormIds(state) {
  return Object.keys(getMarketDictionaryFormState(state))
}

function getCloseFormDataStates(state, id) {
  const marketState = getMarketFormStateById(state, id)
  return [marketState.dataState]
}

export default {
  getMarketFormStateById,
  getMarketDictionaryFormState,
  getMarketFormDataStatesById,
  getMarketFormIds,
  getCloseFormDataStates
}
