/*
 * COPYRIGHT:     Copyright © 2018 - 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */
import get from 'lodash/get'
import { ACTIVITY_PACKAGE } from 'constants/programTypes'
import {
  PROGRAM_STATUS_FIELD_KEY,
  PROGRAM_ON_BOARDED_FIELD_KEY,
  STATUS_DRAFT,
  PROGRAM_TYPE_FIELD_KEY,
  PROGRAM_TYPE_OPTIONS
} from '../types'

export const isOnboardingProgram = formData => {
  const status = get(formData, PROGRAM_STATUS_FIELD_KEY)
  const isOnboarding = get(formData, PROGRAM_ON_BOARDED_FIELD_KEY, false)

  return status === STATUS_DRAFT && isOnboarding
}

export const isActivityPackageType = formData => {
  const type = get(formData, PROGRAM_TYPE_FIELD_KEY)

  return type === ACTIVITY_PACKAGE
}

export const isProgramTypePopulated = formValues =>
  Boolean(get(formValues, PROGRAM_TYPE_FIELD_KEY, false))

export const canChangeProgramType = (formData, isApprover, isSysadmin, isOwnProgram, isDraft) =>
  (isDraft && (!isActivityPackageType(formData) && isSysadmin)) ||
  (isOnboardingProgram(formData) && (isOwnProgram || isApprover))

export const buildProgramTypeOptions = formData => {
  const canSeeActivityPackage = isOnboardingProgram(formData) || isActivityPackageType(formData)

  if (canSeeActivityPackage) {
    return [...PROGRAM_TYPE_OPTIONS, { label: 'Activity Package', value: ACTIVITY_PACKAGE }]
  }

  return PROGRAM_TYPE_OPTIONS
}
