/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import styles from './SeasonsValidateBoard.scss'

export const FootnoteBoard = props => {
  const { classNamesChild, message } = props
  const comparedClassNamesList = `${styles.ItemFootnoteCircle} ${classNamesChild} is-marginless`
  const classNamesMsgWrap = `${styles.CustomLevelItem} level-left level-item`
  return (
    <div className="level">
      <div className={comparedClassNamesList}>!</div>
      <div className={classNamesMsgWrap}> - {message}</div>
    </div>
  )
}
FootnoteBoard.propTypes = {
  classNamesChild: PropTypes.string,
  message: PropTypes.string
}
FootnoteBoard.defaultProps = {
  classNamesChild: '',
  message: ''
}
