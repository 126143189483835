/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { isActionAllowed } from 'helpers/permissionHelper'
import { CREATE_LEVEL } from 'constants/permissionTypes'
import { createFilterBuilder } from 'modules/FilteredSearch/helpers'
import {
  ACTIVE_FILTER,
  ACTIVE_FILTER_KEY,
  APP_INITIAL_FILTERS,
  IGNORE_FILTERS,
  INACTIVE_FILTER,
  INACTIVE_FILTER_KEY,
  MARKETING_FILTER,
  MARKETING_FILTER_KEY,
  NO_RESERVATION_REQUIRED_FILTER,
  NO_RESERVATION_REQUIRED_FILTER_KEY,
  RESERVATION_REQUIRED_FILTER,
  RESERVATION_REQUIRED_FILTER_KEY,
  TRANSFER_TO_TEAM_LEAD_FILTER,
  TRANSFER_TO_TEAM_LEAD_FILTER_KEY,
  STATUS_FILTERS,
  TESTING_FILTER,
  TESTING_FILTER_KEY
} from 'containers/ActivityProducts/types'

export const isStatusColumn = item => item.column === 'activity.status'
export const isTypeColumn = item => item.column === 'activity.business_type'
export const isSettingColumn = item => item.column === 'resale_settings.transfer_to_lead'

export const buildFilters = createFilterBuilder(
  {
    [ACTIVE_FILTER_KEY]: ACTIVE_FILTER,
    [INACTIVE_FILTER_KEY]: INACTIVE_FILTER,
    [TESTING_FILTER_KEY]: TESTING_FILTER,
    [MARKETING_FILTER_KEY]: MARKETING_FILTER,
    [NO_RESERVATION_REQUIRED_FILTER_KEY]: NO_RESERVATION_REQUIRED_FILTER,
    [RESERVATION_REQUIRED_FILTER_KEY]: RESERVATION_REQUIRED_FILTER,
    [TRANSFER_TO_TEAM_LEAD_FILTER_KEY]: TRANSFER_TO_TEAM_LEAD_FILTER
  },
  filters => {
    if (filters.some(isStatusColumn)) {
      return filters
    }

    if (filters.some(isSettingColumn)) {
      return filters
    }

    return filters.some(isTypeColumn) ? [...filters, ...STATUS_FILTERS] : IGNORE_FILTERS
  }
)

export const defaultActivityFilters = buildFilters(APP_INITIAL_FILTERS)

export const canAddApp = authUser => isActionAllowed(CREATE_LEVEL, authUser)
