/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { AND_OPERATOR, buildSearchColumn, CAMPAIGN } from 'helpers/searchHelpers'
import { PROGRAMS_BY_DESTINATION_PATH, APPS_BY_DESTINATION_PATH } from 'router'

export const buildSearchByCampaignId = id =>
  buildSearchColumn({
    entity: CAMPAIGN,
    field: 'location_id',
    keyword: id,
    operator: AND_OPERATOR
  })

export function buildProgramsByDestinationPath(id) {
  return `${PROGRAMS_BY_DESTINATION_PATH}${id}`
}

export function buildAppsByDestinationPath(id) {
  return `${APPS_BY_DESTINATION_PATH}${id}`
}
