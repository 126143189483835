import { actionCreater } from 'helpers/actionHelpers'
import {
  sendRecoveryPasswordLink as sendRecoveryPasswordLinkRequest,
  checkRecoveryToken as checkRecoveryTokenRequest,
  recoverPassword as recoverPasswordRequest
} from 'services/http/account'
import { RECOVERY_PASSWORD_LINK_SUCCESS_MESSAGE } from './types'
import {
  REQUEST_RECOVERY_PASSWORD_LINK_ACTION,
  RESPONSE_RECOVERY_PASSWORD_LINK_ACTION,
  RESPONSE_RECOVERY_PASSWORD_LINK_ACTION_ERROR,
  REQUEST_RECOVERY_TOKEN_ACTION,
  RESPONSE_RECOVERY_TOKEN_ACTION,
  REQUEST_RECOVERY_PASSWORD_ACTION,
  RESPONSE_RECOVERY_PASSWORD_ACTION,
  RESPONSE_RECOVERY_PASSWORD_ACTION_ERROR,
  RESET_DATA_STATE,
  buildUnknownErrorMessage
} from './helpers'

export const sendRecoveryPasswordLink = (data = {}) => async dispatch => {
  const recoveryUrl = `${window.location.origin}/password/recovery`
  const body = { ...data, recoveryUrl }

  dispatch(actionCreater(REQUEST_RECOVERY_PASSWORD_LINK_ACTION))

  const request = await sendRecoveryPasswordLinkRequest(body).catch(err => {
    if (err.error.response.data.body.keys[0] === '85') {
      dispatch(actionCreater(RESPONSE_RECOVERY_PASSWORD_LINK_ACTION, { err }))
      return
    }
    dispatch(err.send({ ...err.message, ...buildUnknownErrorMessage() }))
    dispatch(actionCreater(RESPONSE_RECOVERY_PASSWORD_LINK_ACTION_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_RECOVERY_PASSWORD_LINK_ACTION))
    dispatch(send({ ...message, message: RECOVERY_PASSWORD_LINK_SUCCESS_MESSAGE }))

    return 'sendRecoveryPasswordLink success'
  }

  return 'sendRecoveryPasswordLink failed'
}

export const checkRecoveryToken = (params = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_RECOVERY_TOKEN_ACTION))

  const request = await checkRecoveryTokenRequest(params).catch(err => {
    const errorType = err.error.response.data.body.keys[0];
    dispatch(actionCreater(RESPONSE_RECOVERY_TOKEN_ACTION, { errorType }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_RECOVERY_TOKEN_ACTION))

    return 'sendRecoveryPasswordLink success'
  }

  return 'sendRecoveryPasswordLink failed'
}

export const recoverPassword = (data = {}, callBack = null) => async dispatch => {
  dispatch(actionCreater(REQUEST_RECOVERY_PASSWORD_ACTION))

  const request = await recoverPasswordRequest(data).catch(err => {
    dispatch(actionCreater(RESPONSE_RECOVERY_PASSWORD_ACTION_ERROR))
    dispatch(err.send({ ...err.message, ...buildUnknownErrorMessage() }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_RECOVERY_PASSWORD_ACTION))

    if (callBack) {
      callBack()
    }

    return 'recoverPassword success'
  }

  return 'recoverPassword failed'
}

const resetDataState = () => dispatch => {
  dispatch(actionCreater(RESET_DATA_STATE))
}

export default {
  sendRecoveryPasswordLink,
  checkRecoveryToken,
  recoverPassword,
  resetDataState
}
