/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { BasicModal } from 'components/Modals'
import isEmpty from 'lodash/isEmpty'
import { BasicButton, Input } from '@xplorie/ui-commons'
import {
  ORANGE_SCHEMA,
  YELLOW_GREEN_SCHEMA,
  SUBMIT_TITLE,
  CANCEL_TITLE
} from 'constants/buttonsOptions'
import styles from './styles.scss'

export function PromptModal({ title, isOpen, onClose, onSubmit, placeholder }) {
  const [value, setValue] = useState()

  const onChangeHandler = useCallback(event => {
    const { value: newValue } = event.target
    setValue(newValue)
  }, [])

  const onSubmitHandler = useCallback(() => {
    if (onSubmit) {
      onSubmit(value)
    }
    onClose()
  }, [onClose, onSubmit, value])

  return (
    <BasicModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      renderBody={() => (
        <div className={styles.bodyWrapper}>
          <Input onChange={onChangeHandler} value={value} placeholder={placeholder} />
        </div>
      )}
    >
      <BasicButton disabled={isEmpty(value)} colorSchema={ORANGE_SCHEMA} onClick={onSubmitHandler}>
        {SUBMIT_TITLE}
      </BasicButton>
      <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
        {CANCEL_TITLE}
      </BasicButton>
    </BasicModal>
  )
}

PromptModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string
}
PromptModal.defaultProps = {
  isOpen: true,
  title: 'PIE says',
  placeholder: 'Enter message',
  onClose: () => null,
  onSubmit: () => null
}
