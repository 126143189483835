/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import uniqueId from 'lodash/uniqueId'
import { Form } from 'informed'
import { FormContent } from 'components/FormComponents'
import { useDidMount } from 'hooks'
import { JustTable } from '@xplorie/ui-commons'
import { EligibilityRow } from './EligibilityRow'
import { buildIsInvalid, buildContract } from './helpers'
import validate from './validation'

import {
  XPL_NAME_LABEL,
  DATA_TYPE_LABEL,
  MODE_LABEL,
  LEVEL_LABEL,
  PARAMETER_LABEL,
  NEW_PARAMETER_PREFIX
} from './types'

export function AssignedEligibilityTable({
  name,
  getApi,
  onSubmit,
  HeaderControl,
  onChange,
  disabled,
  onDelete,
  // TODO: Need adjust and refactor it with reimplement this functionality.
  showHeader,
  //
  showLevelColumn,
  readOnly
}) {
  const formApi = useRef()
  const dataRef = useRef()
  const [data, setData] = useState([])

  const reset = useCallback(() => {
    formApi.current.reset()
  }, [])

  const setValue = useCallback((value = []) => {
    setData(value)
  }, [])

  const onGetFormApi = useCallback(api => {
    formApi.current = api
  }, [])

  const onSubmitHandler = useCallback(
    formValues => {
      if (onSubmit) {
        onSubmit(buildContract(formValues))
      }
    },
    [onSubmit]
  )

  const buildValues = useCallback(values => buildContract(values), [])

  const getValue = useCallback(() => {
    const values = get(formApi.current.getState(), 'values')
    return Object.values(values)
  }, [])

  const addValue = useCallback(
    value => {
      const id = get(value, 'id') || uniqueId(NEW_PARAMETER_PREFIX)
      setData([...getValue(), { ...value, id }])
    },
    [getValue]
  )

  const removeById = useCallback(
    id => {
      const currentData = getValue()
      const parameterIndex = findIndex(currentData, ['id', id])
      if (parameterIndex !== -1) {
        setData(currentData.filter((v, ind) => ind !== parameterIndex))
      }
    },
    [getValue]
  )

  const onDeleteHandler = useCallback(
    id => {
      if (id && onDelete) {
        onDelete(id)
      }
    },
    [onDelete]
  )

  const validateEligibility = useCallback(() => {
    const { values, errors, touched } = formApi.current.getState()
    const newErrors = validate(values, errors)
    formApi.current.setState({
      touched,
      values,
      errors: newErrors
    })
  }, [])

  useDidMount(() => {
    if (getApi) {
      getApi({
        setValue,
        addValue,
        submit: formApi.current.submitForm,
        getState: formApi.current.getState,
        buildValues,
        removeById,
        getValue,
        reset
      })
    }
  })

  useEffect(() => {
    formApi.current.setValues({
      ...data.reduce((acc, value) => ({ ...acc, [value.id]: value }), {})
    })
    validateEligibility()
  }, [data, validateEligibility])

  useEffect(() => {
    dataRef.current = data
  })

  const onChangeHandler = useCallback(
    formState => {
      if (onChange) {
        onChange({
          ...formState,
          invalid: buildIsInvalid(formState)
        })
      }
    },
    [onChange]
  )

  const columns = useMemo(() => {
    const defaultColumns = [
      { id: XPL_NAME_LABEL, Header: XPL_NAME_LABEL },
      { id: DATA_TYPE_LABEL, Header: DATA_TYPE_LABEL },
      { id: MODE_LABEL, Header: MODE_LABEL },
      { id: PARAMETER_LABEL, Header: PARAMETER_LABEL }
    ]
    if (showLevelColumn) {
      defaultColumns.splice(1, 0, { id: LEVEL_LABEL, Header: LEVEL_LABEL })
    }
    if (!readOnly) {
      defaultColumns.push({ id: '', Header: '' })
    }
    return defaultColumns
  }, [readOnly, showLevelColumn])

  return (
    <Form getApi={onGetFormApi} onSubmit={onSubmitHandler} onChange={onChangeHandler}>
      {({ formState }) => (
        <FormContent disabled={disabled || readOnly}>
          <JustTable
            showHeader={showHeader}
            title={name}
            HeaderControl={HeaderControl}
            data={data}
            columns={columns}
            renderRow={({ value }) =>
              value.parameter && (
                <EligibilityRow
                  key={`${value.parameter.id}_${value.id}`}
                  value={value}
                  formState={formState}
                  formApi={formApi.current}
                  data={data}
                  onDelete={onDeleteHandler}
                  disabled={disabled}
                  readOnly={readOnly}
                  validate={validateEligibility}
                  showLevelColumn={showLevelColumn}
                />
              )
            }
          />
        </FormContent>
      )}
    </Form>
  )
}
AssignedEligibilityTable.propTypes = {
  getApi: PropTypes.func,
  onSubmit: PropTypes.func,
  HeaderControl: PropTypes.element,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  // TODO: Need adjust and refactor it with reimplement this functionality.
  showHeader: PropTypes.bool,
  //
  showLevelColumn: PropTypes.bool,
  readOnly: PropTypes.bool
}
AssignedEligibilityTable.defaultProps = {
  getApi: null,
  onSubmit: null,
  HeaderControl: null,
  onChange: null,
  name: '',
  disabled: false,
  onDelete: null,
  // TODO: Need adjust and refactor it with reimplement this functionality.
  showHeader: true,
  //
  showLevelColumn: false,
  readOnly: false
}
