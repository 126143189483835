/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import get from 'lodash/get'
import {
  updateEligibilityValuesByPmcIdBulk,
  savePmcEligibilityProperties
} from 'services/http/eligibility'

import helpers from './helpers'

const {
  REQUEST_PMC_ELIGIBILITY_ASSIGN_BULK,
  RESPONSE_PMC_ELIGIBILITY_ASSIGN_BULK,
  RESPONSE_ERROR_PMC_ELIGIBILITY_ASSIGN_BULK,
  RESET_DATA_STATE,
  buildUpdatedMessage
} = helpers

const updateEligibilityParamsByPmc = (pmcId, data) => async dispatch => {
  const key = pmcId
  try {
    dispatch(actionCreater(REQUEST_PMC_ELIGIBILITY_ASSIGN_BULK, { key }))
    const effectivenessDate = get(data, 'effectivenessDate')
    const values = get(data, 'values', [])
    const request = await updateEligibilityValuesByPmcIdBulk(pmcId, values)
    await savePmcEligibilityProperties(pmcId, effectivenessDate)
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildUpdatedMessage() }))
    dispatch(actionCreater(RESPONSE_PMC_ELIGIBILITY_ASSIGN_BULK, { key }))
    return 'updateEligibilityParamsByPmc success'
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_PMC_ELIGIBILITY_ASSIGN_BULK, { key }))
  }
  return 'updateEligibilityParamsByPmc failed'
}

const resetState = pmcId => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key: pmcId }))

export default {
  updateEligibilityParamsByPmc,
  resetState
}
