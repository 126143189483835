/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'
import { getAccountListState, getWatchersState } from 'reducers/account/accountList'
import 'reducers/account/accountForm'

import AccountList from './AccountList'

const mapStateToProps = state => {
  const accountListState = getAccountListState(state)
  return {
    dataState: accountListState.dataState,
    data: accountListState.data,
    itemsCount: accountListState.itemsCount,
    itemsPerPage: accountListState.itemsPerPage,
    pageNumber: accountListState.pageNumber,
    listWatchersState: getWatchersState(state)
  }
}

export default compose(
  withAuthContext,
  connect(mapStateToProps)
)(AccountList)
