/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

// Constants for 'STATUS'
export const STATUS_DRAFT = 'DRAFT'
export const STATUS_PENDING = 'PENDING'
export const STATUS_ACTIVE = 'ACTIVE'
export const STATUS_ARCHIVED = 'ARCHIVED'
export const STATUS_INACTIVE = 'INACTIVE'
export const STATUS_TESTING = 'TESTING'
export const STATUS_INELIGIBLE = 'INELIGIBLE'
export const STATUS_CANCELLED = 'CANCELLED'
export const STATUS_CANCELED = 'CANCELED'
export const STATUS_SOLD = 'SOLD'

// Constants for Value of 'STATUS'
export const VALUE_DRAFT = 'Draft'
export const VALUE_PENDING = 'Pending'
export const VALUE_ACTIVE = 'Active'
export const VALUE_ARCHIVED = 'Archived'
export const VALUE_INACTIVE = 'Inactive'
export const VALUE_INELIGIBLE = 'Ineligible'
export const VALUE_TESTING = 'Testing'
export const VALUE_CANCELLED = 'Cancelled'

// Operation Statuses
export const SUCCESS_STATUS = 'SUCCESS'
export const ONGOING_STATUS = 'ONGOING'
export const REJECTED_STATUS = 'REJECTED'
export const FAILURE_STATUS = 'FAILURE'
