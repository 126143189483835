/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const TICKET_TYPES_FIELD = 'ticketTypes'
export const PERCENTAGE_DISCOUNT_FIELD = 'percentageDiscount'

export const DISCOUNT_LABEL = 'Discount:'
export const DISCOUNT_PLACEHOLDER = 'Discount'
export const DISCOUNT_FIELD = 'discount'

export const TICKET_TYPE_LABEL = 'Ticket Type:'
export const TICKET_TYPE_PATH = 'ticketType.name'

export const RETAIL_PRICE_LABEL = 'Retail Price:'
export const RETAIL_PRICE_PATH = 'ticketType.parameters.retailPrice'

export const FINAL_PRICE_LABEL = 'Final Price:'

export const UNKNOWN_LABEL = 'Unknown'

export const percentageDiscountOptions = [{ value: false, label: '$' }, { value: true, label: '%' }]
