/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import accountFormHelpers from '../accountForm/helpers'
import helpers from './helpers'

const {
  REQUEST_ACTIVATION_ACCOUNT,
  RESPONSE_ACTIVATION_ACCOUNT,
  RESPONSE_ACTIVATION_ACCOUNT_ERROR,
  REQUEST_DEACTIVATION_ACCOUNT,
  RESPONSE_DEACTIVATION_ACCOUNT,
  RESPONSE_DEACTIVATION_ACCOUNT_ERROR,
  RESET_ACCOUNT_LIFE_CYCLE_DATA_STATE
} = helpers

export const initialState = {}

export const initialAccountLifeCycleState = {
  activateAccountDataState: DATA_STATE_NOT_REQUESTED,
  deactivateAccountDataState: DATA_STATE_NOT_REQUESTED
}

const accountLifeCycleReducer = (state = initialState, action) => {
  switch (action.type) {
    // Activation account
    case REQUEST_ACTIVATION_ACCOUNT:
      return {
        ...state,
        [action.payload.key]: {
          ...initialAccountLifeCycleState,
          ...state[action.payload.key],
          activateAccountDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_ACTIVATION_ACCOUNT_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...initialAccountLifeCycleState,
          ...state[action.payload.key],
          activateAccountDataState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_ACTIVATION_ACCOUNT:
      return {
        ...state,
        [action.payload.key]: {
          ...initialAccountLifeCycleState,
          ...state[action.payload.key],
          activateAccountDataState: DATA_STATE_RECIEVED
        }
      }

    // Deactivation account
    case REQUEST_DEACTIVATION_ACCOUNT:
      return {
        ...state,
        [action.payload.key]: {
          ...initialAccountLifeCycleState,
          ...state[action.payload.key],
          deactivateAccountDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_DEACTIVATION_ACCOUNT_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...initialAccountLifeCycleState,
          ...state[action.payload.key],
          deactivateAccountDataState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_DEACTIVATION_ACCOUNT:
      return {
        ...state,
        [action.payload.key]: {
          ...initialAccountLifeCycleState,
          ...state[action.payload.key],
          deactivateAccountDataState: DATA_STATE_RECIEVED
        }
      }

    case accountFormHelpers.RESET_DATA_STATE:
    case RESET_ACCOUNT_LIFE_CYCLE_DATA_STATE:
      return (() => {
        const { [action.payload.key]: removedState, ...restState } = state
        return {
          ...restState
        }
      })()

    default:
      return state
  }
}

export default filterActions(accountLifeCycleReducer, [
  REQUEST_ACTIVATION_ACCOUNT,
  RESPONSE_ACTIVATION_ACCOUNT,
  RESPONSE_ACTIVATION_ACCOUNT_ERROR,
  REQUEST_DEACTIVATION_ACCOUNT,
  RESPONSE_DEACTIVATION_ACCOUNT,
  RESPONSE_DEACTIVATION_ACCOUNT_ERROR,
  RESET_ACCOUNT_LIFE_CYCLE_DATA_STATE
])
