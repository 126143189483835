/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { BoxColumns, CalendarField } from 'components/FormComponents'
import {
  START_DATE_LABEL,
  END_DATE_LABEL,
  START_DATE_PLACEHOLDER,
  END_DATE_PLACEHOLDER,
  RANGE_INTERSECTION_ERROR
} from 'modules/Discounts/types'
import { validateBeforeDate, validateAfterDate } from 'helpers/formHelpers'

import { buildCalendarModificators, rangeHasOverlaps } from './helpers'

export function RangeInputs(props) {
  const { originalValue, index, prefix } = props

  const calerdarProps = {
    additionalProps: {
      ...buildCalendarModificators({
        originalValue
      })
    }
  }

  const onValidateDate = originalValidator => (...args) => {
    const error = originalValidator(...args)
    if (error) {
      return error
    }
    return rangeHasOverlaps({
      originalValue,
      index
    })
      ? RANGE_INTERSECTION_ERROR
      : null
  }

  return (
    <BoxColumns>
      <CalendarField
        fieldName="startDate"
        label={START_DATE_LABEL}
        placeholder={START_DATE_PLACEHOLDER}
        isRequired
        validate={onValidateDate(validateBeforeDate(`${prefix}.endDate`))}
        notify={[`${prefix}.endDate`]}
        {...calerdarProps}
      />
      <CalendarField
        fieldName="endDate"
        label={END_DATE_LABEL}
        placeholder={END_DATE_PLACEHOLDER}
        isRequired
        validate={onValidateDate(validateAfterDate(`${prefix}.startDate`))}
        notify={[`${prefix}.startDate`]}
        {...calerdarProps}
      />
    </BoxColumns>
  )
}
RangeInputs.propTypes = {
  originalValue: PropTypes.array,
  prefix: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
}
RangeInputs.defaultProps = {
  originalValue: []
}
