/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { REQUEST_TYPE, RESPONSE_TYPE, RESPONSE_ERROR_TYPE } from 'helpers/actionHelpers'

import { CHANGE_PASS_ACTION, RESET_PASSWORD } from './types'

export const REQUEST_CHANGE_PASS_ACTION = `${REQUEST_TYPE}_${CHANGE_PASS_ACTION}`
export const RESPONSE_CHANGE_PASS_ACTION = `${RESPONSE_TYPE}_${CHANGE_PASS_ACTION}`
export const RESPONSE_CHANGE_PASS_ACTION_ERROR = `${RESPONSE_ERROR_TYPE}_${CHANGE_PASS_ACTION}`

export const REQUEST_RESET_PASSWORD = `${REQUEST_TYPE}_${RESET_PASSWORD}`
export const RESPONSE_RESET_PASSWORD = `${RESPONSE_TYPE}_${RESET_PASSWORD}`
export const RESPONSE_RESET_PASSWORD_ERROR = `${RESPONSE_ERROR_TYPE}_${RESET_PASSWORD}`

export const RESET_DATA_STATE = `RESET_DATA_STATE_${CHANGE_PASS_ACTION}`

export default {
  REQUEST_CHANGE_PASS_ACTION,
  RESPONSE_CHANGE_PASS_ACTION,
  RESPONSE_CHANGE_PASS_ACTION_ERROR,
  REQUEST_RESET_PASSWORD,
  RESPONSE_RESET_PASSWORD,
  RESPONSE_RESET_PASSWORD_ERROR,
  RESET_DATA_STATE
}
