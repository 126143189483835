/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useContext } from 'react'
import { CirclePlusIcon } from 'icons'
import { SmartHostFeeFormContext } from '../types'
import { SmartHostFeeFormBox } from '../SmartHostFeeFormBox'
import { appendEmptyFee } from '../helpers'
import styles from './SmartHostFeeAddMode.scss'

function SmartHostFeeAddMode() {
  const { fees, onRemoveFee, formApi, setFees } = useContext(SmartHostFeeFormContext)

  const onAddFee = useCallback(() => {
    const formFees = formApi.getValue('fees') || []
    const nextFees = appendEmptyFee(formFees)

    formApi.setValues({ fees: nextFees })
    setFees(nextFees)
  }, [formApi, setFees])

  return (
    <>
      {fees.map((item, index) => (
        <SmartHostFeeFormBox key={item.id} index={index} item={item} onRemoveFee={onRemoveFee} />
      ))}
      <div className={styles.buttonWrapper}>
        <CirclePlusIcon onClick={onAddFee} />
      </div>
    </>
  )
}

export default SmartHostFeeAddMode
