import get from 'lodash/get'
import { entityInitialState, listDataState } from './reducer'

function getActivityProductHistoryState(state) {
  return state.activityProduct.activityProductHistory
}

function getActivityProductHistoryStateById(state, id) {
  const historyStates = getActivityProductHistoryState(state)
  return historyStates[id] || entityInitialState
}

function getActivityProductYearHistoryStateById(state, id, year) {
  return get(getActivityProductHistoryStateById(state, id), ['years', year], listDataState)
}

function getActivityProductHistoryIds(state) {
  return Object.keys(getActivityProductHistoryState(state))
}

export default {
  getActivityProductHistoryState,
  getActivityProductHistoryStateById,
  getActivityProductHistoryIds,
  getActivityProductYearHistoryStateById
}
