/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React from 'react'
import { HeaderWrapper } from 'components/Wrappers'
import { NAME_APPLICATION } from 'constants/common'
import classNames from 'classnames'

import styles from './FallbackPage.scss'
import { FALLBACK_MESSAGE, GO_HOMEPAGE } from './types'

const FallbackPage = () => (
  <section className={styles.customWrapper}>
    <HeaderWrapper title={NAME_APPLICATION} customClassNameTitleWrapper={styles.customHeroInner} />
    <div className={classNames('flex', styles.NotFoundPageWrapper)}>
      <div>{FALLBACK_MESSAGE}</div>
      <p>
        <a href="/">{GO_HOMEPAGE}</a>
      </p>
    </div>
  </section>
)

export default FallbackPage
