/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const COMMON_ICON_SIZE = 15
export const DEFAULT_STROKE_COLOR = '#000000'
export const DEFAULT_CIRCLE_FILL = 'transparent'
export const commonStrokeWidth = 2
export const defaultCircleWrapperSize = 10
