/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  updateProgramSeason,
  getSeasonById,
  createProgramSeason,
  cloneSeasonById
} from 'services/http/programSeason'

import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_SEASON_BY_ID,
  RESPONSE_GET_SEASON_BY_ID,
  RESPONSE_GET_SEASON_BY_ID_ERROR,
  REQUEST_CLONE_SEASON,
  RESPONSE_CLONE_SEASON,
  RESPONSE_CLONE_SEASON_ERROR
} = helpers

const postSeasonForm = (programId, seasonData = {}) => async dispatch => {
  try {
    dispatch(actionCreater(REQUEST_FORM))
    const request = await createProgramSeason(programId, seasonData)
    const {
      message: { message, send }
    } = request
    dispatch(
      actionCreater(RESPONSE_FORM, {
        responseData: request.data
      })
    )
    dispatch(send(message))

    return Promise.resolve(request.data)
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))

    return Promise.reject(err)
  }
}

const putSeasonForm = (programId, seasonData = {}, withNotif = true) => async dispatch => {
  try {
    dispatch(actionCreater(REQUEST_FORM))
    const request = await updateProgramSeason(programId, seasonData)
    const {
      message: { message, send }
    } = request
    dispatch(
      actionCreater(RESPONSE_FORM, {
        responseData: request.data
      })
    )
    if (withNotif) {
      dispatch(send(message))
    }

    return Promise.resolve(seasonData.id)
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))

    return Promise.reject(err)
  }
}

const getSeasonDataById = (programId, seasonId) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_SEASON_BY_ID))
  const request = await getSeasonById(programId, seasonId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_SEASON_BY_ID_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_SEASON_BY_ID, request.data))
    return 'getSeasonDataById success'
  }

  return 'getSeasonDataById failed'
}

const cloneProgramSeasonById = (programId, seasonId) => async dispatch => {
  try {
    dispatch(actionCreater(REQUEST_CLONE_SEASON))
    const request = await cloneSeasonById(programId, seasonId)
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_CLONE_SEASON, request.data))
    dispatch(send(message))

    return Promise.resolve(request.data)
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_CLONE_SEASON_ERROR))

    return Promise.reject(err)
  }
}

const resetForm = () => ({
  type: RESET_DATA_STATE
})

export default {
  postSeasonForm,
  putSeasonForm,
  resetForm,
  getSeasonDataById,
  cloneProgramSeasonById
}
