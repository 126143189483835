/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React from 'react'
import { HeaderWrapper } from 'components/Wrappers'
import { NAME_APPLICATION } from 'constants/common'
import classNames from 'classnames'

import styles from './NotFoundPage.scss'
import { NOT_FOUND_PAGE_MESSAGE, GO_HOMEPAGE } from './types'

export default class extends React.Component {
  state = {
    ready: false
  }

  componentDidMount() {
    this.makeReady()
  }

  makeReady = () => {
    const { ready } = this.state
    if (!ready) {
      this.setState({
        ready: true
      })
    }
  }

  render() {
    const { ready } = this.state
    return ready ? (
      <section className={styles.customWrapper}>
        <HeaderWrapper
          title={NAME_APPLICATION}
          customClassNameTitleWrapper={styles.customHeroInner}
        />
        <div className={classNames('flex', styles.NotFoundPageWrapper)}>
          <div>{NOT_FOUND_PAGE_MESSAGE}</div>
          <p>
            <a href="/">{GO_HOMEPAGE}</a>
          </p>
        </div>
      </section>
    ) : null
  }
}
