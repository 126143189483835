/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildListParams } from 'services/http/helpers'
import { DEALS_ENDPOINT, DISCOUNTS_EXPORT_URL } from 'endpoints'
import { responseToFile } from 'helpers/downloadHelper'

export const getList = options =>
  httpWrapper({
    url: DEALS_ENDPOINT,
    method: 'get',
    params: buildListParams(options)
  })

export const exportDiscountsData = (data, params, filename) =>
  httpWrapper({
    url: DISCOUNTS_EXPORT_URL,
    method: 'post',
    data,
    responseType: 'blob',
    params: buildListParams(params)
  }).then(response => responseToFile(response, filename))
