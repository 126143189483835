/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { LIST_NAME, EXPORT_PMC_UNITS } = types

const REQUEST_LIST = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`

const REQUEST_EXPORT_PMC_UNITS = `${REQUEST_TYPE}_${EXPORT_PMC_UNITS}`
const RESPONSE_EXPORT_PMC_UNITS = `${RESPONSE_TYPE}_${EXPORT_PMC_UNITS}`
const RESPONSE_ERROR_EXPORT_PMC_UNITS = `${RESPONSE_ERROR_TYPE}_${EXPORT_PMC_UNITS}`

const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

export const buildAccessKey = (pmcId, programId) => `${pmcId}_${programId}`

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  REQUEST_EXPORT_PMC_UNITS,
  RESPONSE_EXPORT_PMC_UNITS,
  RESPONSE_ERROR_EXPORT_PMC_UNITS,
  RESET_DATA_STATE
}
