/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'
import programFormHelpers from '../programsForm/helpers'

const {
  REQUEST_ACTIVATE_PROGRAM,
  RESPONSE_ACTIVATE_PROGRAM,
  RESPONSE_ACTIVATE_PROGRAM_ERROR,
  REQUEST_DEACTIVATE_PROGRAM,
  RESPONSE_DEACTIVATE_PROGRAM,
  RESPONSE_DEACTIVATE_PROGRAM_ERROR,
  RESET_STATE_PROGRAM_LIFE_CYCLE
} = helpers

export const initialState = {}
export const initialProgramLifeCycleState = {
  activateProgramDataState: DATA_STATE_NOT_REQUESTED,
  deactivateProgramDataState: DATA_STATE_NOT_REQUESTED
}

const programLifeCycleProcessReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTIVATE_PROGRAM:
      return {
        ...state,
        [action.payload.key]: {
          ...initialProgramLifeCycleState,
          ...state[action.payload.key],
          activateProgramDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_ACTIVATE_PROGRAM_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...initialProgramLifeCycleState,
          ...state[action.payload.key],
          activateProgramDataState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_ACTIVATE_PROGRAM:
      return {
        ...state,
        [action.payload.key]: {
          ...initialProgramLifeCycleState,
          ...state[action.payload.key],
          activateProgramDataState: DATA_STATE_RECIEVED
        }
      }
    case REQUEST_DEACTIVATE_PROGRAM:
      return {
        ...state,
        [action.payload.key]: {
          ...initialProgramLifeCycleState,
          ...state[action.payload.key],
          deactivateProgramDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_DEACTIVATE_PROGRAM_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...initialProgramLifeCycleState,
          ...state[action.payload.key],
          deactivateProgramDataState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_DEACTIVATE_PROGRAM:
      return {
        ...state,
        [action.payload.key]: {
          ...initialProgramLifeCycleState,
          ...state[action.payload.key],
          deactivateProgramDataState: DATA_STATE_RECIEVED
        }
      }
    case RESET_STATE_PROGRAM_LIFE_CYCLE:
    case programFormHelpers.RESET_DATA_STATE:
      return (() => {
        const { [action.payload.key]: removedElement, ...restElements } = state
        return { ...restElements }
      })()
    default:
      return state
  }
}

export default filterActions(programLifeCycleProcessReducer, [
  REQUEST_ACTIVATE_PROGRAM,
  RESPONSE_ACTIVATE_PROGRAM,
  RESPONSE_ACTIVATE_PROGRAM_ERROR,
  REQUEST_DEACTIVATE_PROGRAM,
  RESPONSE_DEACTIVATE_PROGRAM,
  RESPONSE_DEACTIVATE_PROGRAM_ERROR,
  RESET_STATE_PROGRAM_LIFE_CYCLE,
  programFormHelpers.RESET_DATA_STATE
])
