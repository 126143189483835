/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { RadioGroup } from 'informed'
import { withFormContext } from 'components/FormComponents/helpers'

function RadioGroupFieldComponent(props) {
  const { children, ...rest } = props

  return <RadioGroup {...rest}>{children}</RadioGroup>
}

RadioGroupFieldComponent.propTypes = {
  children: PropTypes.node.isRequired
}

export const RadioGroupField = withFormContext(RadioGroupFieldComponent)
