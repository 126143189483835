/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './ControlButtonsContainer.scss'

export const ControlButtonsContainer = ({ className, children }) => (
  <div className={classNames(styles.buttonsWrapper, className)}>{children}</div>
)
ControlButtonsContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}
ControlButtonsContainer.defaultProps = {
  className: null
}
