/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { NAVIGATION_DEFAULT_TYPE } from 'constants/navigationTypes'
import { useTestModeRouteControl } from 'modules/ResellTestMode'
import { NavItemMenu } from './NavItemMenu'
import { buildNavigationItems, getNavigationTypeByPath } from './helpers'

import styles from './Nav.scss'

export function Nav(props) {
  const { authUser, navigationType, logOut, match, updateNavigationType, prepareNavData } = props

  useEffect(() => {
    const navigationTypeByUrl = getNavigationTypeByPath(match.path)

    if (navigationType !== navigationTypeByUrl) {
      updateNavigationType(navigationTypeByUrl)
    }
  }, [])

  const navigationItems = buildNavigationItems(authUser, prepareNavData, navigationType)

  // controlling resell test mode value when we going between routes
  useTestModeRouteControl()

  return (
    <nav className={classNames(styles.nav)}>
      <input className={classNames(styles.hamburger)} type="checkbox" id="hamburger" />
      <label className={classNames(styles.menuicon)} htmlFor="hamburger">
        <span id="hamburger-icon" />
      </label>
      <div id="left-menu" className={classNames(styles.menu)}>
        {navigationItems.length > 0 &&
          navigationItems.map(itemMenu => (
            <NavItemMenu
              key={itemMenu.id}
              {...itemMenu}
              updateNavigationType={updateNavigationType}
              logOut={logOut}
              authUser={authUser}
            />
          ))}
      </div>
    </nav>
  )
}

Nav.propTypes = {
  authUser: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  navigationType: PropTypes.string,
  updateNavigationType: PropTypes.func.isRequired,
  prepareNavData: PropTypes.func,
  logOut: PropTypes.func.isRequired
}

Nav.defaultProps = {
  navigationType: NAVIGATION_DEFAULT_TYPE,
  prepareNavData: items => items
}
