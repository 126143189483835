/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  getEntityById,
  updateDiscountDeal,
  createDiscountDeal,
  activateEntity
} from 'services/http/deals'

import helpers, { buildKey } from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL_ERROR
} = helpers

/**
 *
 * @param {string} dealId - deal id
 * @param {Object} entityData - contract deal data
 * @param {Object} [params] - request params
 */
const updateDeal = (dealId, entityData, params) => async dispatch => {
  const key = buildKey(dealId)
  dispatch(actionCreater(REQUEST_FORM, { key }))
  try {
    const updateRequest = await updateDiscountDeal(entityData.id, entityData, params)
    const getEntityRequest = await getEntityById(entityData.id)
    dispatch(actionCreater(RESPONSE_FORM, { data: getEntityRequest.data, key }))
    const {
      message: { message, send }
    } = updateRequest
    dispatch(send(message))
    return getEntityRequest.data
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
    return Promise.reject()
  }
}

/**
 *
 * @param {Object} entityData - contract data
 * @param {Object} [params] - request params
 */
const createDeal = (dealId, entityData, params) => async dispatch => {
  const key = buildKey(dealId)
  dispatch(actionCreater(REQUEST_FORM, { key }))
  try {
    const createRequest = await createDiscountDeal(entityData, params)
    const getEntityRequest = await getEntityById(createRequest.data)
    dispatch(actionCreater(RESPONSE_FORM, { data: getEntityRequest.data, key }))
    const {
      message: { message, send }
    } = createRequest
    dispatch(send(message))
    return getEntityRequest.data
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
    return Promise.reject()
  }
}

/**
 * @param {string | null} accessId - store key
 * @param {string} dealId - deal id
 * @param {Object} entityData - current form state
 */
const activateDiscountDeal = (accessId, dealId, entityData) => async dispatch => {
  const key = buildKey(accessId)
  dispatch(actionCreater(REQUEST_ACTIVATE_DEAL, { key }))
  try {
    await updateDiscountDeal(dealId, entityData)
    await activateEntity(dealId)
    const request = await getEntityById(dealId)
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ACTIVATE_DEAL, { data: request.data, key }))
    return request.data
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ACTIVATE_DEAL_ERROR, { key }))
  }
  return Promise.reject()
}

const resetForm = dealId => dispatch => {
  const key = buildKey(dealId)
  dispatch(actionCreater(RESET_DATA_STATE, { key }))
}

export default {
  activateDiscountDeal,
  resetForm,
  createDeal,
  updateDeal
}
