import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { APPROVE_LEVEL, isActionAllowed } from 'helpers/permissionHelper'
import { useAuthUser } from 'hooks/useAuthUser'
import { DDDD_MMM_DD_YYYY } from 'constants/date'
import { FullCalendar } from './Calendar'
import { ContextMenuPopover } from './ContextMenuPopover'
import { ClosurePopover } from './ClosurePopover/ClosurePopover'
import { useClosurePopoverPresenter } from './ClosurePopover/useClosurePopoverPresenter'
import styles from './styles.scss'

/**
 * @param contextMenuItems should return array of objects {label, onClick}
 */
export const FullCalendarPopover = React.forwardRef((props, ref) => {
  const {
    dayMenuContext,
    eventMenuContext,
    onDayContextMenu,
    onEventContextMenu,
    dayContextMenuItems,
    eventContextMenuItems,
    ...rest
  } = props

  const [popover, setPopoverState] = useState(null)
  const wrapperRef = useRef(null)
  const authUser = useAuthUser()
  const isGuestOrUser = !isActionAllowed(APPROVE_LEVEL, authUser)

  const {
    closurePopover,
    handleCloseClosurePopover,
    handleEventClick
  } = useClosurePopoverPresenter(wrapperRef)

  const showPopover = (info, items, title) => {
    info.jsEvent.preventDefault()

    const wrapperRect = wrapperRef.current.getBoundingClientRect()
    const dayRect = info.el.getBoundingClientRect()
    const y = dayRect.top + info.jsEvent.offsetY - wrapperRect.top
    const x = dayRect.left + info.jsEvent.offsetX - wrapperRect.left

    setPopoverState({
      x,
      y,
      info,
      items,
      title
    })
  }

  const handleOpenDayPopover = info => {
    if (info.isDisabled || isGuestOrUser) {
      return
    }

    showPopover(info, dayContextMenuItems(info), moment(info.date).format(DDDD_MMM_DD_YYYY))
  }

  const handleOpenEventPopover = info => {
    showPopover(info, eventContextMenuItems(info), info.event.title)
  }

  const handleClosePopover = () => {
    setPopoverState(null)
  }

  const handleDayContextMenu = info => {
    if (onDayContextMenu) {
      onDayContextMenu(info)
    }

    if (dayMenuContext) {
      handleOpenDayPopover(info)
    }
  }

  const handleEventContextMenu = info => {
    info.jsEvent.stopPropagation()

    if (onEventContextMenu) {
      onDayContextMenu(info)
    }

    if (eventMenuContext) {
      handleOpenEventPopover(info)
    }
  }

  return (
    <div ref={wrapperRef} className={styles.wrapper}>
      {closurePopover && (
        <ClosurePopover
          x={closurePopover.x}
          y={closurePopover.y}
          onClose={handleCloseClosurePopover}
          item={closurePopover.item}
          header={closurePopover.title}
        />
      )}
      {popover && (
        <ContextMenuPopover
          x={popover.x}
          y={popover.y}
          onClose={handleClosePopover}
          items={popover.items}
          header={popover.title}
        />
      )}
      <FullCalendar
        {...rest}
        eventClick={handleEventClick}
        onDayContextMenu={handleDayContextMenu}
        onEventContextMenu={handleEventContextMenu}
        ref={ref}
      />
    </div>
  )
})

FullCalendarPopover.propTypes = {
  dayCellDidMount: PropTypes.func,
  dayCellWillUnmount: PropTypes.func,
  onDayContextMenu: PropTypes.func,
  dayMenuContext: PropTypes.bool,
  eventMenuContext: PropTypes.bool,
  dayContextMenuItems: PropTypes.func,
  eventContextMenuItems: PropTypes.func,
  onEventContextMenu: PropTypes.func
}

FullCalendarPopover.defaultProps = {
  dayCellDidMount: null,
  dayCellWillUnmount: null,
  onDayContextMenu: null,
  onEventContextMenu: null,
  dayMenuContext: false,
  dayContextMenuItems: () => [],
  eventContextMenuItems: () => [],
  eventMenuContext: false
}
