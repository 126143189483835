/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { createRef } from 'react'
import ReactTable from 'react-table'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_ERROR,
  DATA_STATE_NEED_UPDATE
} from 'helpers/actionHelpers'
import get from 'lodash/get'
import { buildPageSizeOptions, resetTableScrollByRef } from '../helpers'
import { DEFAULT_ENTITY_TYPE, DEFAULT_PAGE_SIZE, DEFAULT_TABLE_STYLE } from '../types'
import { READY_DATA_STATE, INITIAL_DATA_STATE, ENTITY_TYPE_UPDATED_DATA_STATE } from './types'

import styles from '../styles.scss'

import 'react-table/react-table.css'

const initialState = {
  pagesCount: 0,
  pageNumber: 1,
  sorted: [],
  size: DEFAULT_PAGE_SIZE,
  localState: INITIAL_DATA_STATE
}

export class ControlledTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...initialState }
    this.tableRef = createRef(null)
  }

  componentDidMount() {
    const { onGetData, onDidMountRequest } = this.props
    if (onDidMountRequest) {
      onGetData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dataState, itemsPerPage, itemsCount, entityType, onGetData } = this.props
    const { sorted, pageNumber, size } = this.state
    if (dataState !== prevProps.dataState) {
      if (dataState === DATA_STATE_RECIEVED || dataState === DATA_STATE_ERROR) {
        this.setState({ localState: READY_DATA_STATE })
      }
      if (dataState === DATA_STATE_NEED_UPDATE) {
        this.onGetDataWithParams()
      }
    }
    if (prevState.sorted !== sorted || prevState.pageNumber !== pageNumber) {
      this.onGetDataWithParams()
    }
    if (prevProps.itemsPerPage !== itemsPerPage && itemsPerPage !== size) {
      this.pageSizeChangeHandler(itemsPerPage)
    }
    if (prevProps.itemsCount !== itemsCount || prevState.size !== size) {
      const pagesCount = this.calcPageCount(size, itemsCount)
      if (pageNumber > pagesCount && pagesCount > 0) {
        this.setState({ pageNumber: pagesCount })
      } else if (prevState.size !== size) {
        this.onGetDataWithParams()
      }
    }
    if (prevProps.entityType !== entityType && entityType !== DEFAULT_ENTITY_TYPE) {
      this.setState({ ...initialState, localState: ENTITY_TYPE_UPDATED_DATA_STATE }, onGetData())
    }
  }

  resetTableScroll = () => {
    resetTableScrollByRef(this.tableRef)
  }

  onGetDataWithParams = () => {
    const { onGetData } = this.props
    const { sorted, pageNumber, size, localState } = this.state
    if (localState !== ENTITY_TYPE_UPDATED_DATA_STATE) {
      const needSort = get(sorted, '0')
      const sort = get(needSort, 'id')
      const order = get(needSort, 'desc') ? 'desc' : 'asc'

      const options = {
        pageNumber,
        itemsPerPage: size,
        sort,
        order
      }
      onGetData(options)
      this.resetTableScroll()
    }
  }

  calcPageCount = (size, itemsCount) => Math.ceil(itemsCount / size)

  sortedChangeHandler = sorted => {
    this.setState({
      sorted
    })
  }

  pageChangedHandler = pageNumber => {
    this.setState({ pageNumber: pageNumber + 1 })
  }

  pageSizeChangeHandler = size => {
    this.setState({ size })
  }

  render() {
    const { pageNumber, size, sorted } = this.state
    const {
      columns,
      itemsPerPage,
      tableHeader,
      entityType,
      data,
      defaultPageSize,
      style,
      dataState,
      itemsCount,
      ...rest
    } = this.props
    const page = pageNumber - 1
    const pagesCount = this.calcPageCount(size, itemsCount)

    return (
      <ReactTable
        {...rest}
        ref={this.tableRef}
        defaultPageSize={defaultPageSize}
        data={data}
        className={classNames('-striped', '-highlight', styles.commonWrapper)}
        pages={pagesCount}
        sorted={sorted}
        page={page}
        pageSize={size}
        pageSizeOptions={buildPageSizeOptions(data, size)}
        onSortedChange={this.sortedChangeHandler}
        onPageSizeChange={this.pageSizeChangeHandler}
        onPageChange={this.pageChangedHandler}
        loading={dataState === DATA_STATE_REQUESTING}
        manual
        columns={[
          {
            Header: tableHeader,
            columns
          }
        ]}
        style={style}
      />
    )
  }
}

ControlledTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  onGetData: PropTypes.func,
  dataState: PropTypes.string,
  entityType: PropTypes.string,
  itemsPerPage: PropTypes.number,
  itemsCount: PropTypes.number,
  tableHeader: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  formDataStates: PropTypes.arrayOf(PropTypes.string),
  defaultPageSize: PropTypes.number,
  style: PropTypes.object,
  onDidMountRequest: PropTypes.bool
}

ControlledTable.defaultProps = {
  columns: [],
  onGetData: () => null,
  dataState: DATA_STATE_NOT_REQUESTED,
  itemsPerPage: DEFAULT_PAGE_SIZE,
  itemsCount: 10,
  tableHeader: 'Default Header',
  entityType: DEFAULT_ENTITY_TYPE,
  data: [],
  showPagination: true,
  formDataStates: [],
  defaultPageSize: DEFAULT_PAGE_SIZE,
  style: DEFAULT_TABLE_STYLE,
  onDidMountRequest: true
}
