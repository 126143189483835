/* eslint-disable no-console */
/* eslint-disable no-unreachable */
/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const HOST_PARAMETER = '--url'

const PRODUCTION_ENV = 'production'
const DEVELOPMENT_ENV = 'development'
const STAGE_ENV = 'stage'
const LOCAL_DEV_ENV = 'local'
const LOCAL_WEBPACK_DEV_ENV = 'local-dev'
const FEATURE_BRANCH_DEV_ENV = 'feature-env'

const PLEASE_TYPE_CORRECT_HOST = `Please type yarn start-feature ${HOST_PARAMETER} https://some.env.com`

function getHostFromConsole() {
  const hostIndex = process.argv.findIndex(value => value === HOST_PARAMETER)
  if (hostIndex === -1) {
    console.error(
      `[ERROR]: ${HOST_PARAMETER} parameter did not found.\n[SUGGESTION]: ${PLEASE_TYPE_CORRECT_HOST}`
    )
    process.exit(1)
  }

  const hostParameter = process.argv[hostIndex + 1]

  if (!hostParameter) {
    console.warn(
      `[ERROR]: ${HOST_PARAMETER} is empty but it is required. \n[SUGGESTION]: ${PLEASE_TYPE_CORRECT_HOST}`
    )
    process.exit(1)
  }

  return hostParameter
}

const hosts = {
  [PRODUCTION_ENV]: () => 'https://pie.xplorie.com',
  [DEVELOPMENT_ENV]: () => 'https://pie.dev.xplorie.com',
  [STAGE_ENV]: () => 'https://pie.stage.xplorie.com',
  [LOCAL_DEV_ENV]: () => 'http://epbyvitw0052.minsk.epam.com:9160',
  [LOCAL_WEBPACK_DEV_ENV]: () => 'http://localhost:3000', // This environment needs to e2e tests,
  [FEATURE_BRANCH_DEV_ENV]: getHostFromConsole
}

const defineEnv = env => {
  const knownEnv = env ? Object.keys(hosts).some(host => host === env) : false

  if (!knownEnv) {
    return DEVELOPMENT_ENV
  }

  return env
}

const defineHost = env => {
  const environment = defineEnv(env)
  return hosts[environment]()
}

module.exports.defineEnv = defineEnv
module.exports.defineHost = defineHost
module.exports.hosts = hosts
module.exports.envs = {
  PRODUCTION_ENV,
  DEVELOPMENT_ENV,
  STAGE_ENV,
  LOCAL_DEV_ENV,
  LOCAL_WEBPACK_DEV_ENV
}
