/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { UploadModal } from 'modules/Modals'
import { DATA_STATE_RECIEVED, DATA_STATE_ERROR } from 'helpers/actionHelpers'
import { BasicButton } from '@xplorie/ui-commons'
import { canUploadProgramGuests } from 'modules/Programs'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { useLoading, useUpdateSomeDataState } from 'hooks'
import { validate } from 'helpers/uploadHelper'
import {
  BUTTON_TEXT,
  FILE_FORMAT,
  MODAL_TITLE,
  MAX_SIZE_MESSAGE,
  BUTTON_TEMPLATE_TEXT
} from './types'
import styles from './UploadGuestsForm.scss'

export function UploadGuestsForm(props) {
  const {
    dataState,
    authUser,
    programData,
    progress,
    dataId,
    uploadGuestDataFile,
    cancelUploading,
    downloadTemplate
  } = props
  const canUpload = canUploadProgramGuests(authUser, programData)
  const modalApi = useRef()
  const [isLoading] = useLoading(dataState)

  const onGetModalApi = useCallback(api => {
    modalApi.current = api
  }, [])

  const onClickUploadHandler = useCallback(() => {
    modalApi.current.toggleModal()
  }, [])

  const onClickDownloadHandler = useCallback(() => {
    downloadTemplate(dataId)
  }, [downloadTemplate, dataId])

  const onFilesChangeHandler = useCallback(
    files => {
      if (files.length) {
        const [file] = files
        uploadGuestDataFile(dataId, file)
      }
    },
    [dataId, uploadGuestDataFile]
  )

  const onCancelRequestHandler = useCallback(() => {
    cancelUploading(dataId)
  }, [cancelUploading, dataId])

  const validateFile = useCallback(files => {
    const error = validate(files)
    modalApi.current.setError(error)
    return error
  }, [])

  useUpdateSomeDataState(
    () => {
      modalApi.current.toggleModal()
    },
    [dataState],
    DATA_STATE_RECIEVED
  )

  useUpdateSomeDataState(
    () => {
      modalApi.current.toggleModal()
    },
    [dataState],
    DATA_STATE_ERROR
  )

  return (
    <>
      <BasicButton
        className={styles.downloadBtn}
        colorSchema={ORANGE_SCHEMA}
        onClick={onClickDownloadHandler}
      >
        {BUTTON_TEMPLATE_TEXT}
      </BasicButton>
      <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onClickUploadHandler} disabled={!canUpload}>
        {BUTTON_TEXT}
      </BasicButton>
      <UploadModal
        validate={validateFile}
        onChange={onFilesChangeHandler}
        onCancel={onCancelRequestHandler}
        getApi={onGetModalApi}
        title={MODAL_TITLE}
        message={MAX_SIZE_MESSAGE}
        accept={FILE_FORMAT}
        progress={progress}
        isLoading={isLoading}
      />
    </>
  )
}
UploadGuestsForm.propTypes = {
  programData: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  dataState: PropTypes.string.isRequired,
  progress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataId: PropTypes.string.isRequired,
  uploadGuestDataFile: PropTypes.func.isRequired,
  downloadTemplate: PropTypes.func.isRequired,
  cancelUploading: PropTypes.func.isRequired
}
UploadGuestsForm.defaultProps = {
  progress: null
}
