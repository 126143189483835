/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const modifiersStyles = {
  holiday: {
    color: '#ffc107',
    backgroundColor: '#fffdee'
  },
  selected: {
    border: '2px solid #4a90e2'
  }
}

export const ENABLE_HOLIDAY_LABEL = 'Enable Holiday'
