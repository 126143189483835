/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { ActivityCategoryItem } from '../ActivityCategoryItem'

export function ActivityCategoryContainer(props) {
  const { data, ...rest } = props

  return data.map(value => <ActivityCategoryItem key={value.id} category={value} {...rest} />)
}
