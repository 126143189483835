/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import PropTypes from 'prop-types'
import React, { useRef, useEffect, useState, useCallback } from 'react'
import classNames from 'classnames'
import Tooltip from 'antd/es/tooltip'
import styles from './styles.scss'

export function EllipsisText({ children, className }) {
  const spanRef = useRef()
  const [isEllipsis, setIsEllipsis] = useState(false)
  const [isVisible, setVisibleState] = useState(false)

  useEffect(() => {
    const { scrollWidth, clientWidth } = spanRef.current
    setIsEllipsis(scrollWidth > clientWidth)
  }, [children])

  const handleVisibility = useCallback(
    visibility => {
      if ((visibility && isEllipsis) || !visibility) {
        setVisibleState(visibility)
      }
    },
    [isEllipsis]
  )

  return (
    <Tooltip title={children} visible={isVisible} onVisibleChange={handleVisibility}>
      <span ref={spanRef} className={classNames(styles.text, className)}>
        {children}
      </span>
    </Tooltip>
  )
}

EllipsisText.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string
}
EllipsisText.defaultProps = {
  className: ''
}
