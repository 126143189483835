/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import * as Sentry from '@sentry/react'

import 'bulma/css/bulma.min.css'
import 'antd/es/time-picker/style/index.css'
import 'antd/dist/antd.min.css'

import './globalHelpers'
import store from './connectors'
// Your top level component
import App from './App'

// Export your top level component as JSX (for static rendering)
export default App

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  autoSessionTracking: false,
  enabled: window.location.hostname !== 'localhost'
})

// Render your app

if (typeof document !== 'undefined') {
  const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate || ReactDOM.render

  const render = Comp => {
    renderMethod(
      <DndProvider backend={Backend}>
        <Provider store={store}>
          <Router>
            <Comp />
          </Router>
        </Provider>
      </DndProvider>,
      document.getElementById('root')
    )
  }

  // Render!
  render(App)

  // Hot Module Replacement
  if (module.hot) {
    // eslint-disable-next-line global-require
    module.hot.accept('./App', () => render(require('./App').default))
  }
}
