/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/programApproval'
import { withAuthContext } from 'helpers/withAuthContext'
import { getSmartHostFeeState } from 'reducers/program/smartHostFee'
import { actions as programsFormActions, isProgramActionPending } from 'reducers/programsForm'
import { actions as seasonFormActions } from 'reducers/seasonForm'
import { actions as rateAdjustmentActions } from 'connectors/reducers/rateAdjustments'

import { ButtonContainer as ButtonContainerComponent } from './ButtonContainer'

const { putSeasonForm } = seasonFormActions
const { saveList } = rateAdjustmentActions

const mapStateToProps = (state, props) => {
  const { dataId } = props
  return {
    isProgramActionPending: isProgramActionPending(state, dataId),
    smartHostFormState: getSmartHostFeeState(state, dataId)
  }
}

const mapDispatchToProps = dispatch => {
  const { postProgramsForm, putProgramsForm } = programsFormActions
  return bindActionCreators(
    {
      postProgramsForm,
      putProgramsForm,
      onUpdateSeason: putSeasonForm,
      saveRateAdjustmentList: saveList,
      ...actions
    },
    dispatch
  )
}

export const ButtonContainer = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ButtonContainerComponent)
