/* eslint-disable max-lines */
/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import isArray from 'lodash/isArray'
import get from 'lodash/get'
import {
  STATUS_DRAFT,
  STATUS_PENDING,
  STATUS_ACTIVE,
  STATUS_INACTIVE
} from 'constants/entityStatus'
import {
  CREATE_LEVEL,
  UPDATE_LEVEL,
  APPROVE_LEVEL,
  ADMIN_LEVEL,
  DELETE_LEVEL,
  USER_LEVEL,
  USER_ROLE_LEVEL
} from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import {
  EXPERIENCE_PRO,
  ACTIVITY_PACKAGE,
  EXPERIENCE_LIGHT,
  SMARTHOST_STANDALONE
} from 'constants/programTypes'

export const canSeeDraftInProgress = data => {
  const dataId = get(data, 'id')
  const status = get(data, 'status', STATUS_DRAFT)
  const draftId = get(data, 'draftIds.0')

  return dataId && draftId && status === STATUS_ACTIVE
}

export const buildPermissions = ({ authUser, data = {} }) => {
  const isExist = Boolean(get(data, 'id'))
  const status = get(data, 'status', STATUS_DRAFT)
  const authUserEmail = get(authUser, 'email')
  const createdBy = get(data, 'metadata.createdBy')
  const isUser = isActionAllowed(USER_LEVEL, authUser)
  const isAdmin = isActionAllowed(ADMIN_LEVEL, authUser)
  const isAllowedCreate = isActionAllowed(CREATE_LEVEL, authUser)
  const isAllowedApprove = isActionAllowed(APPROVE_LEVEL, authUser)
  const isAllowedUpdate =
    isActionAllowed(UPDATE_LEVEL, authUser, data) || isActionAllowed(APPROVE_LEVEL, authUser)
  const isAllowedDelete = isActionAllowed(DELETE_LEVEL, authUser, data)

  const isDraftProgram = status === STATUS_DRAFT
  const isPendingProgram = status === STATUS_PENDING
  const isActiveProgram = status === STATUS_ACTIVE
  const isInactiveProgram = status === STATUS_INACTIVE

  const isOwnProgram = authUserEmail === createdBy

  const canUpdateProgramData =
    isAllowedUpdate && isExist && [STATUS_DRAFT, STATUS_PENDING, STATUS_ACTIVE].includes(status)

  const canCreate = isAllowedCreate && !isExist
  const canApprove = isPendingProgram && isExist && isAllowedApprove && (!isOwnProgram || isAdmin)
  const canInitUpdate = isActiveProgram && isExist && (isOwnProgram || isAllowedApprove)
  const canReject = isPendingProgram && isAllowedApprove && isExist

  const canActivate = isInactiveProgram && isAdmin && isExist
  const canDeactivate = isActiveProgram && isAllowedApprove && isExist
  const canDelete = isAllowedDelete && isAllowedUpdate && isDraftProgram && isExist

  const canUpdateSeasons = isDraftProgram && isAllowedUpdate

  return {
    isUser,
    isAdmin,
    canUpdateProgramData,
    canUpdateSeasons,
    canCreate,
    canApprove,
    canActivate,
    canDeactivate,
    canDelete,
    canInitUpdate,
    canReject,
    isDraftProgram,
    isPendingProgram,
    isActiveProgram,
    isInactiveProgram,
    isOwnProgram,
    isAllowedApprove
  }
}

export const buildFormConditions = ({ dataId, data, permissions = {} }) => {
  const {
    canCreate,
    canUpdateProgramData,
    canInitUpdate,
    canApprove,
    canReject,
    isDraftProgram,
    isOwnProgram,
    isPendingProgram,
    isInactiveProgram,
    isAdmin,
    isActiveProgram,
    isAllowedApprove
  } = permissions

  const status = get(data, 'status', STATUS_DRAFT)
  const draftIds = get(data, 'draftIds', [])
  const deactivationDate = get(data, 'deactivationDate.date')
  const uniquePhoneNumberId = get(data, 'uniquePhoneNumber.id', null)

  const isEmptyDrafts = isArray(draftIds) && draftIds.length === 0
  const isExistEntity = Boolean(dataId && data && data.id)
  const showComments = !isActiveProgram
  const canInitDraft = canInitUpdate && isExistEntity && isEmptyDrafts
  const showApprovalButton = canApprove
  const showRejectButton = canReject
  const showSaveToDraftButton =
    (canUpdateProgramData || canCreate) && [STATUS_DRAFT, STATUS_ACTIVE].includes(status)

  const showInitUpdateButton = canInitUpdate && isEmptyDrafts

  const showForApprovalButton = isDraftProgram && isOwnProgram && canUpdateProgramData

  const canFormEnabled = canUpdateProgramData || (!dataId && canCreate)

  const isVisibleProgramDiff = isPendingProgram

  const isVisibleActivateButton = isAdmin && isExistEntity && isInactiveProgram

  const isVisibleDeactivateButton =
    isAllowedApprove && isActiveProgram && isExistEntity && !deactivationDate

  const isVisibleUnassignPhoneNumberButton = Boolean(
    uniquePhoneNumberId &&
      (isActiveProgram || isInactiveProgram) &&
      isExistEntity &&
      isAllowedApprove
  )

  const canUnassignPhoneNumber = (isActiveProgram || isInactiveProgram) && isAllowedApprove
  const isVisibleDraftInProgressButton = canSeeDraftInProgress(data)

  return {
    isExistEntity,
    showComments,
    canInitDraft,
    showApprovalButton,
    showRejectButton,
    showSaveToDraftButton,
    showInitUpdateButton,
    showForApprovalButton,
    isVisibleProgramDiff,
    isVisibleActivateButton,
    isVisibleDeactivateButton,
    canUnassignPhoneNumber,
    isVisibleUnassignPhoneNumberButton,
    canFormEnabled,
    isVisibleDraftInProgressButton
  }
}

export const ProgramSeasonContext = React.createContext({
  permissions: {
    isAdmin: false,
    canUpdateSeasons: false,
    canCreate: false,
    canApprove: false,
    canActivate: false,
    canDeactivate: false,
    canDelete: false,
    canInitUpdate: false,
    canReject: false,
    isDraftProgram: false,
    isPendingProgram: false,
    isActiveProgram: false,
    isInactiveProgram: false,
    isOwnProgram: false,
    isAllowedApprove: false
  },
  conditions: buildFormConditions({})
})

/**
 * Program is onboard if it do not has any prev versions
 * @param {Object} programData
 */
export const isOnboardingProgram = programData => Boolean(programData && programData.onBoarded)

export const isDraftProgram = programData => {
  const status = get(programData, 'status')
  return status === STATUS_DRAFT
}

export const isPendingProgram = programData => {
  const status = get(programData, 'status')
  return status === STATUS_PENDING
}

export const hasPreviousVersion = programData => {
  const previousId = get(programData, 'versionData.previousId')
  return Boolean(previousId)
}

export const isProgramActive = programData => {
  const status = get(programData, 'status')
  return status === STATUS_ACTIVE
}

export const canUpdateEligibilityProgram = (authUser, programData) => {
  const canApprove = isActionAllowed(APPROVE_LEVEL, authUser)
  const status = get(programData, 'status')
  return canApprove && [STATUS_DRAFT, STATUS_PENDING, STATUS_ACTIVE].includes(status)
}

export const canUpdateProgramMarketing = (authUser, programData) => {
  const canUpdate = isActionAllowed(UPDATE_LEVEL, authUser)
  const isUser = isActionAllowed(USER_ROLE_LEVEL, authUser)
  const status = get(programData, 'status')

  return canUpdate && [STATUS_DRAFT, STATUS_PENDING, STATUS_ACTIVE].includes(status) && !isUser
}

export const canUploadProgramGuests = (authUser, programData) => {
  const canUpdate = isActionAllowed(APPROVE_LEVEL, authUser)
  const status = get(programData, 'status')
  return canUpdate && [STATUS_DRAFT, STATUS_PENDING, STATUS_ACTIVE].includes(status)
}

export const canViewGuestsData = authUser => isActionAllowed(USER_LEVEL, authUser)

export const disabledSelectingPMC = programData => {
  const status = get(programData, 'status')
  return status === STATUS_ACTIVE || (status === STATUS_DRAFT && !isOnboardingProgram(programData))
}

export const canDeactivateProgram = (authUser, programData) =>
  [STATUS_ACTIVE, STATUS_INACTIVE].includes(get(programData, 'status')) &&
  isActionAllowed(ADMIN_LEVEL, authUser) &&
  Boolean(get(programData, 'id'))

export const canExportUnits = authUser => isActionAllowed(USER_LEVEL, authUser)

export const canChangePrebookInfo = (authUser, programData) =>
  isActionAllowed(APPROVE_LEVEL, authUser) &&
  (isDraftProgram(programData) || isOnboardingProgram(programData))

export const canSeePrebookInfo = programData => programData && programData.id

export const canSeeSmartHostFees = programData =>
  programData && programData.programInfo && programData.programInfo.smartHost

export const canEditProgramUnits = authUser => isActionAllowed(USER_LEVEL, authUser)

export const mapProgramType = value => {
  switch (value) {
    case EXPERIENCE_LIGHT:
      return 'Experience Light'
    case EXPERIENCE_PRO:
      return 'Experience Pro'
    case ACTIVITY_PACKAGE:
      return 'Activity Package'
    case SMARTHOST_STANDALONE:
      return 'SmartHost Standalone'
    default:
      return value
  }
}

export const mapSmartHost = value => {
  if (value) {
    return 'Active'
  }

  return 'No Devices'
}

export const mapEmailCadence = value => {
  if (value) {
    return 'Active'
  }

  return 'Inactive'
}

export const isSmartHostStandaloneType = programType => programType === SMARTHOST_STANDALONE
