/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const buildUrlForApprove = (basicURL, id) => `${basicURL}/${id}/approve`
export const buildUrlForReject = (basicURL, id) => `${basicURL}/${id}/reject`
export const buildUrlToPending = (basicURL, id) => `${basicURL}/${id}/submit`
export const buildUrlForCreatingDraft = (basicURL, id) =>
  `${basicURL}/${id}/draft`
