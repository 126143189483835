/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const ActivityTagsForm = loadable(
  () =>
    import(/* webpackChunkName: 'ActivityTagsForm', webpackPrefetch: true */ './ActivityTagsForm'),
  {
    resolveComponent: components => components.ActivityTagsForm
  }
)

export { ActivityTagsList } from './ActivityTagsList'
export { ActivityTagsForm }
