/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import { YELLOW_GREEN_SCHEMA, ORANGE_SCHEMA, YES_TITLE, NO_TITLE } from 'constants/buttonsOptions'

import { TITLE, MESSAGE } from './types'

import styles from './AppActivation.scss'

export function AppActivation(props) {
  const { appName, isOpen, onSubmit, onHide } = props

  const onConfirmAction = useCallback(() => {
    if (onSubmit) {
      onSubmit()
    }
  }, [onSubmit])

  const onHideAction = useCallback(() => {
    if (onHide) {
      onHide()
    }
  }, [onHide])

  const bodySection = () => (
    <section className={styles.wrapper}>
      <div className={styles.messageWrapper}>
        <span className={styles.mainMessage}>{MESSAGE}</span>
        <span className={styles.nameOption}>{appName}</span>
        <span className={styles.question} />
      </div>
    </section>
  )

  return (
    <BasicModal isOpen={isOpen} title={TITLE} onClose={onHideAction} renderBody={bodySection}>
      <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onConfirmAction}>
        {YES_TITLE}
      </BasicButton>
      <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onHideAction}>
        {NO_TITLE}
      </BasicButton>
    </BasicModal>
  )
}

AppActivation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  appName: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
}

AppActivation.defaultProps = {}
