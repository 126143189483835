/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'
import classNames from 'classnames'
import get from 'lodash/get'

import { SimpleModalWrapper } from 'components/SimpleModalWrapper'
import { CloseIcon } from 'icons'
import { BasicButton } from '@xplorie/ui-commons'
import { YELLOW_GREEN_SCHEMA, ORANGE_SCHEMA } from 'constants/buttonsOptions'

import { MODAL_MESSAGE, REMOVE_BUTTON, CANCEL_BUTTON } from './types'

import styles from './ModalRemoval.scss'

export function ModalRemoval(props) {
  const {
    message,
    cancelText,
    actionText,
    originalData,
    wrapperClassName,
    renderBody,
    footerClassName,
    title,
    children
  } = props

  const onActionHandler = e => {
    e.preventDefault()
    const actionCallback = get(props, 'actionCallback')
    if (!isFunction(actionCallback)) {
      return 'actionCallback is not a function'
    }
    actionCallback({ ...props })
    return 'success'
  }

  const onCancelHandler = e => {
    e.preventDefault()
    const actionCallback = get(props, 'cancelCallback')
    if (!isFunction(actionCallback)) {
      return 'cancelCallback is not a function'
    }
    actionCallback({ ...props })
    return 'success'
  }

  return originalData ? (
    <SimpleModalWrapper cancelHandler={onCancelHandler}>
      <div className={classNames(styles.wrapper, wrapperClassName)}>
        <div className={styles.header}>
          <span className={styles.title}>{title}</span>
          <CloseIcon dynamicClassName={styles.icon} dynamicEvent={onCancelHandler} />
        </div>
        {renderBody ? renderBody() : <div className={styles.body}>{message}</div>}
        <div className={classNames(styles.footerDefaultClassName, footerClassName)}>
          {children || (
            <>
              <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onActionHandler}>
                {actionText}
              </BasicButton>
              <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onCancelHandler}>
                {cancelText}
              </BasicButton>
            </>
          )}
        </div>
      </div>
    </SimpleModalWrapper>
  ) : null
}

ModalRemoval.propTypes = {
  originalData: PropTypes.any,
  message: PropTypes.string,
  cancelText: PropTypes.string,
  actionText: PropTypes.string,
  wrapperClassName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  renderBody: PropTypes.func,
  footerClassName: PropTypes.string
}

ModalRemoval.defaultProps = {
  originalData: null,
  message: MODAL_MESSAGE,
  cancelText: CANCEL_BUTTON,
  actionText: REMOVE_BUTTON,
  title: 'Warning!',
  children: null,
  wrapperClassName: '',
  renderBody: null,
  footerClassName: null
}

export default ModalRemoval
