/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import get from 'lodash/get'
import { STATUS_DRAFT, STATUS_PENDING, STATUS_ACTIVE } from 'constants/entityStatus'
import { workPageListTableColumns, buildDefaultButtonColumn } from 'helpers/dataTablesHelpers'
import { DeleteButton, EditButton } from 'modules/Programs/ProgramsList'
import {
  buildSearchColumn,
  PROGRAM,
  LIKE_START_WITH_MODE,
  AND_OPERATOR
} from 'helpers/searchHelpers'
import {
  MY_DRAFTS_TYPE,
  PENDING_TYPE,
  DRAFT_LIST_HEADER,
  PENDING_LIST_HEADER,
  DRAFTS_TYPE,
  ON_BOARDING_TYPE,
  BOARDING_LIST_HEADER
} from './types'

export const buildContractForGettingMyDrafts = createdBy => [
  buildSearchColumn({ entity: PROGRAM, field: 'status', keyword: STATUS_DRAFT }),
  buildSearchColumn({
    entity: PROGRAM,
    field: 'createdBy',
    keyword: createdBy,
    operator: AND_OPERATOR
  })
]

export const buildContractForGettingDrafts = () => [
  buildSearchColumn({ entity: PROGRAM, field: 'status', keyword: STATUS_DRAFT })
]

export const buildContractForGettingMyActive = createdBy => [
  buildSearchColumn({ entity: PROGRAM, field: 'status', keyword: STATUS_ACTIVE }),
  buildSearchColumn({
    entity: PROGRAM,
    field: 'createdBy',
    keyword: createdBy,
    operator: AND_OPERATOR,
    mode: LIKE_START_WITH_MODE
  })
]

export const buildContractForGettingPending = () => [
  buildSearchColumn({ entity: PROGRAM, field: 'status', keyword: STATUS_PENDING })
]

export const buildContractForGettingAllPrograms = () => [
  buildSearchColumn({ entity: PROGRAM, field: 'status', keyword: '' })
]

export const getParamsBySearchType = (authUser, type) => {
  const email = get(authUser, 'email')
  switch (type) {
    case MY_DRAFTS_TYPE:
      return {
        contractData: buildContractForGettingMyDrafts(email),
        listHeader: DRAFT_LIST_HEADER
      }
    case DRAFTS_TYPE:
      return {
        contractData: buildContractForGettingDrafts(email),
        listHeader: DRAFT_LIST_HEADER
      }
    case PENDING_TYPE:
      return {
        contractData: buildContractForGettingPending(),
        listHeader: PENDING_LIST_HEADER
      }
    case ON_BOARDING_TYPE:
      return {
        contractData: null,
        listHeader: BOARDING_LIST_HEADER
      }
    default:
      return {
        contractData: buildContractForGettingMyDrafts(email),
        listHeader: DRAFT_LIST_HEADER
      }
  }
}

export const tabTypeExist = type =>
  [MY_DRAFTS_TYPE, DRAFTS_TYPE, ON_BOARDING_TYPE, PENDING_TYPE].some(value => value === type)

export const onDeleteProgram = props => (authKey, options, contractData) => {
  const { deleteProgram } = props
  if (!authKey || !options || !deleteProgram) {
    return 'invalid params'
  }
  deleteProgram(authKey, options, contractData)
  return 'success onDeleteProgram'
}

export const buildTableColumns = (type, props) => {
  const { enqueue, authUser } = props
  const columns = [
    ...workPageListTableColumns,
    {
      ...buildDefaultButtonColumn({ width: 85 }),
      Cell: data => <EditButton data={data} authUser={authUser} enqueue={enqueue} />
    }
  ]

  switch (true) {
    case [MY_DRAFTS_TYPE, DRAFTS_TYPE].some(value => value === type):
      return [
        ...columns,
        {
          ...buildDefaultButtonColumn({ width: 100 }),
          Cell: data => (
            <DeleteButton data={data} authUser={authUser} deleteProgram={onDeleteProgram(props)} />
          )
        }
      ]
    default:
      return columns
  }
}
