/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { DiscountsListComponent, canCreateNewDealFromApp } from 'modules/Discounts'
import { ButtonsWrapper } from 'components/Wrappers'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { BouncingLoader } from 'components/Loaders'
import { RIGHT_ALIGN } from 'constants/alignTypes'
import { useSliderApi, useAuthUser, useDidMount, useLoading } from 'hooks'
import has from 'lodash/has'
import { DEAL } from 'constants/formCodes'
import { BasicButton } from '@xplorie/ui-commons'
import { TEXT_BTN_ADD_NEW } from './types'
import { getSpecificType } from './helpers'

export function ActivityDiscountsTab(props) {
  const {
    listProps,
    onGetData,
    readOnly,
    activity,
    ticketSettingsState,
    getActivitySettingsById
  } = props
  const dealSliderApi = useSliderApi(DEAL)
  const authUser = useAuthUser()
  const canCreate = useMemo(() => canCreateNewDealFromApp(authUser), [authUser])
  const [isLoading] = useLoading(ticketSettingsState.dataState)

  const getDealApi = useCallback(
    api => {
      api.dealOptions.disableApp()
      api.dealOptions.setAppValue({
        id: activity.id,
        name: activity.name,
        parameters: {
          specificType: getSpecificType(ticketSettingsState)
        }
      })
    },
    [activity, ticketSettingsState]
  )

  const onAddNewHandler = () => {
    dealSliderApi.open(null, {
      headerProps: { title: 'New Discount' },
      props: {
        getApi: getDealApi
      }
    })
  }

  useDidMount(() => {
    if (activity.id) {
      getActivitySettingsById(activity.id)
    }
  })

  return (
    <div>
      {isLoading ? (
        <BouncingLoader />
      ) : (
        <>
          <ButtonsWrapper align={RIGHT_ALIGN}>
            <Column>
              {has(activity, 'id') && (
                <BasicButton
                  id="new-deal-btn"
                  onClick={onAddNewHandler}
                  colorSchema={ORANGE_SCHEMA}
                  disabled={!canCreate}
                >
                  {TEXT_BTN_ADD_NEW}
                </BasicButton>
              )}
            </Column>
          </ButtonsWrapper>
          <DiscountsListComponent {...listProps} onGetData={onGetData} readOnly={readOnly} />
        </>
      )}
    </div>
  )
}

ActivityDiscountsTab.propTypes = {
  listProps: PropTypes.object.isRequired,
  onGetData: PropTypes.func.isRequired,
  getActivitySettingsById: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  activity: PropTypes.object,
  ticketSettingsState: PropTypes.object
}
ActivityDiscountsTab.defaultProps = {
  readOnly: false,
  activity: {},
  ticketSettingsState: {}
}
