/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { useDidMount } from 'hooks'
import get from 'lodash/get'
import { withLoader } from 'decorators'
import { PM_COMPANY } from 'constants/searchTypes'
import { buildRequestParams } from 'helpers/searchParamsHelper'
import {
  FormContent,
  ControlButtonsContainer,
  TextField,
  ConditionalYesNoField,
  ToggleRadioField
} from 'components/FormComponents'
import { SubmitButton } from 'components/Buttons'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, SUBMIT_TITLE } from 'constants/buttonsOptions'
import { SEARCH_PATH_NAME } from 'router'
import {
  NAME_LABEL,
  NAME_PLACEHOLDER,
  TOTAL_PMCS_LABEL,
  TOTAL_UNIT_COUNT_LABEL,
  GUEST_API_LABEL,
  LISTING_API_LABEL,
  TOTAL_PMCS_PLACEHOLDER,
  TOTAL_UNIT_COUNT_PLACEHOLDER,
  VIEW_PMC_TITLE,
  PREBOOK_FIELD_LABEL,
  PREBOOK_FIELD_OPTIONS,
  PREBOOK_FIELD,
  COMPANY_ID_LABEL,
  GUEST_API_FIELD,
  LISTING_API_FIELD,
  COMPANY_ID_ENABLED_FIELD
} from '../types'

import styles from './InnerContent.scss'

function InnerContentComponent(props) {
  const { formApi, dataId, formData, disabled, onClose } = props
  const pmcTotalCount = get(formData, 'pmcTotalCount')
  const history = useHistory()
  const isDisabledViewPmcButton = !dataId || !pmcTotalCount

  const onClickViewPmcHandler = useCallback(() => {
    const pmsName = get(formData, 'name')
    if (pmsName) {
      const newParam = buildRequestParams({ type: PM_COMPANY, keyword: pmsName })
      history.push({
        search: newParam,
        pathname: SEARCH_PATH_NAME
      })
      onClose()
    }
  }, [history, onClose, formData])

  useDidMount(() => {
    if (formApi && formData) {
      formApi.setValues(formData)
    }
  })

  return (
    <FormContent disabled={disabled}>
      <TextField
        fieldName="name"
        label={NAME_LABEL}
        placeholder={NAME_PLACEHOLDER}
        fieldProps={{
          classNameWrapper: styles.commonIndent
        }}
        isRequired
      />
      <TextField
        fieldName="pmcTotalCount"
        label={TOTAL_PMCS_LABEL}
        placeholder={TOTAL_PMCS_PLACEHOLDER}
        fieldProps={{
          classNameWrapper: styles.commonIndent
        }}
        disabled
      />
      <TextField
        fieldName="unitTotalCount"
        label={TOTAL_UNIT_COUNT_LABEL}
        placeholder={TOTAL_UNIT_COUNT_PLACEHOLDER}
        fieldProps={{
          classNameWrapper: styles.commonIndent
        }}
        disabled
      />
      <div className={styles.extraFormIndent}>
        <ConditionalYesNoField
          fieldProps={{
            classNameWrapper: styles.commonIndent
          }}
          label={GUEST_API_LABEL}
          fieldName={GUEST_API_FIELD}
        />
        <ConditionalYesNoField label={LISTING_API_LABEL} fieldName={LISTING_API_FIELD} />
        <ConditionalYesNoField label={COMPANY_ID_LABEL} fieldName={COMPANY_ID_ENABLED_FIELD} />
        <ToggleRadioField
          label={PREBOOK_FIELD_LABEL}
          fieldName={PREBOOK_FIELD}
          options={PREBOOK_FIELD_OPTIONS}
        />
      </div>
      <ControlButtonsContainer>
        <SubmitButton colorSchema={ORANGE_SCHEMA}>{SUBMIT_TITLE}</SubmitButton>
        <BasicButton
          colorSchema={ORANGE_SCHEMA}
          onClick={onClickViewPmcHandler}
          disabled={isDisabledViewPmcButton}
        >
          {VIEW_PMC_TITLE}
        </BasicButton>
      </ControlButtonsContainer>
    </FormContent>
  )
}

InnerContentComponent.propTypes = {
  formApi: PropTypes.object,
  dataId: PropTypes.string,
  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  formData: PropTypes.object
}

InnerContentComponent.defaultProps = {
  formApi: null,
  dataId: null,
  disabled: true,
  formData: {}
}

export const InnerContent = withLoader(InnerContentComponent)
