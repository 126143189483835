/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CollapseBox, DotsButton } from '@xplorie/ui-commons'
import { SAVE_TITLE, CANCEL_TITLE } from 'constants/buttonsOptions'
import { canChangePrebookInfo } from 'modules/Programs'
import { Form } from 'informed'
import { useDidMount, useLoading } from 'hooks'
import { BouncingLoader, PIECurtainLoader } from 'components/Loaders'
import { FormContent } from 'components/FormComponents'
import { PrebookForm } from './PrebookForm'
import { PrebookRateForm } from './PrebookRateForm'
import { SECTION_TITLE } from './types'
import styles from './PrebookInfo.scss'

export function PrebookInfo({
  authUser,
  dataId,
  formDataState,
  entityDataState,
  entityData,
  savePrebookInfo,
  getPrebookInfo,
  programData
}) {
  const formApi = useRef()
  const [isLoading, { isLoaded }] = useLoading(entityDataState)
  const [isProcessing] = useLoading(formDataState)

  const isDisabled = useMemo(
    () => !canChangePrebookInfo(authUser, programData) || isLoading || isProcessing,
    [authUser, isLoading, isProcessing, programData]
  )

  const hasPrebookData = useMemo(() => Object.values(entityData).some(value => value !== null), [
    entityData
  ])

  const [isSaveDisabled, setIsDisabled] = useState(true)
  const [canExpand, setCanExpandState] = useState(true)
  const [isOpen, setIsOpen] = useState(hasPrebookData)

  useDidMount(() => {
    if (dataId) {
      getPrebookInfo(dataId)
    }
  })

  const onSetFormValues = useCallback(() => {
    if (isLoaded && formApi.current && entityData) {
      formApi.current.setValues(entityData)
    }
  }, [entityData, isLoaded])

  useEffect(() => {
    setIsOpen(hasPrebookData)
  }, [hasPrebookData])

  useEffect(() => {
    if (isOpen) {
      onSetFormValues()
    }
  }, [isOpen, onSetFormValues])

  const onSubmit = useCallback(
    formValues => {
      if (dataId) {
        savePrebookInfo(dataId, formValues)
      }
    },
    [dataId, savePrebookInfo]
  )

  const onSave = useCallback(() => {
    formApi.current.submitForm()
  }, [])

  const onCancel = useCallback(() => {
    formApi.current.reset()
    setIsOpen(false)
  }, [])

  const onFormChange = useCallback(formState => {
    const { invalid, touched } = formState
    const isTouched = Object.keys(touched).length > 0
    setIsDisabled(invalid)
    setCanExpandState(!isTouched)
  }, [])

  const onExpandHandler = useCallback(isExpand => {
    setIsOpen(isExpand)
  }, [])

  const onGetFormApi = useCallback(api => {
    formApi.current = api
  }, [])

  const options = useMemo(
    () => [
      { label: SAVE_TITLE, props: { onClick: onSave, disabled: isSaveDisabled } },
      { label: CANCEL_TITLE, props: { onClick: onCancel } }
    ],
    [isSaveDisabled, onCancel, onSave]
  )

  if (isLoading) {
    return <BouncingLoader />
  }

  return (
    <Form getApi={onGetFormApi} onChange={onFormChange} onSubmit={onSubmit}>
      {isProcessing && <PIECurtainLoader />}
      <CollapseBox
        className={styles.wrapper}
        title={SECTION_TITLE}
        canExpand={canExpand}
        isOpen={isOpen}
        onExpand={onExpandHandler}
      >
        {!isDisabled && (
          <div className={styles.buttons}>
            <DotsButton options={options} />
          </div>
        )}
        <FormContent disabled={isDisabled}>
          <PrebookForm />
          <PrebookRateForm entityData={entityData} />
        </FormContent>
      </CollapseBox>
    </Form>
  )
}

PrebookInfo.propTypes = {
  authUser: PropTypes.object.isRequired,
  dataId: PropTypes.string.isRequired,
  formDataState: PropTypes.string.isRequired,
  entityDataState: PropTypes.string.isRequired,
  entityData: PropTypes.object.isRequired,
  savePrebookInfo: PropTypes.func.isRequired,
  getPrebookInfo: PropTypes.func.isRequired,
  programData: PropTypes.object.isRequired
}
