/*
* COPYRIGHT:     Copyright © 2018 Xplorie LLC
* Warning:       This product is protected by United States and international copyright laws.
*                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
*/
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import { PDF_FORMAT } from 'constants/exportFormat'
import helpers from './helpers'

const {
  REQUEST_EXPORT,
  RESPONSE_EXPORT,
  RESPONSE_ERROR_EXPORT,
  RESET_DATA_STATE,
  SET_REPORT_TYPE
} = helpers

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  format: PDF_FORMAT
}

const reportExportReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_EXPORT:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_EXPORT:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        ...action.payload
      }
    case RESPONSE_ERROR_EXPORT:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case RESET_DATA_STATE:
      return initialState
    default:
      return state
  }
}

export default filterActions(reportExportReducer, [
  REQUEST_EXPORT,
  RESPONSE_EXPORT,
  RESPONSE_ERROR_EXPORT,
  RESET_DATA_STATE,
  SET_REPORT_TYPE
])
