/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function PencilIcon(props) {
  return (
    <svg viewBox="0 0 16 16" fill="#B2D135" {...props}>
      <path d="M1.75324 15C1.81425 15 1.87526 14.9924 1.93552 14.9774L4.94855 14.2233C5.08112 14.1902 5.2024 14.1215 5.29881 14.025L14.5586 4.75579C14.8433 4.47077 15 4.09225 15 3.6896C15 3.28695 14.8433 2.90842 14.5586 2.6234L13.3639 1.42752C12.7945 0.857474 11.8032 0.857474 11.2337 1.42752L1.97394 10.6968C1.87757 10.7933 1.80912 10.9142 1.77583 11.0466L1.02258 14.0627C0.994755 14.1739 0.992618 14.29 1.01633 14.4021C1.04004 14.5142 1.08898 14.6195 1.15942 14.7098C1.22986 14.8002 1.31995 14.8733 1.42285 14.9236C1.52575 14.9739 1.63874 15 1.75324 15ZM12.2988 2.49371L13.4935 3.6896L12.2988 4.88548L11.1042 3.6896L12.2988 2.49371ZM3.18668 11.6152L10.0391 4.75579L11.2337 5.95167L4.38059 12.811L2.78821 13.2092L3.18668 11.6152Z" />
    </svg>
  )
}
