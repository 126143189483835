/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useUpdateSomeDataState } from 'hooks'
import { DATA_STATE_RECIEVED, DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { TicketGroupForm } from 'modules/ActivityProducts/ActivityResaleTab'
import { SliderModal } from 'modules/ActivityProducts/ActivityResaleTab/SliderModal'

export function TicketGroupModal(props) {
  const {
    cancelHandler,
    removeInvsDataState,
    uploadDataState,
    groupFilesDataState,
    resetForm,
    removeDataState,
    formDataState,
    ...rest
  } = props

  const isFormRequesting = [
    removeDataState,
    groupFilesDataState,
    uploadDataState,
    removeInvsDataState
  ].some(dataState => dataState === DATA_STATE_REQUESTING)

  const onCancelHandler = () => {
    if (!isFormRequesting) {
      cancelHandler()
    }
  }

  useUpdateSomeDataState(
    () => {
      onCancelHandler()
    },
    [removeDataState, formDataState],
    DATA_STATE_RECIEVED
  )

  return (
    <SliderModal cancelHandler={onCancelHandler}>
      <TicketGroupForm {...rest} />
    </SliderModal>
  )
}

TicketGroupModal.propTypes = {
  cancelHandler: PropTypes.func.isRequired,
  formDataState: PropTypes.string,
  removeDataState: PropTypes.string,
  removeInvsDataState: PropTypes.string,
  uploadDataState: PropTypes.string,
  groupFilesDataState: PropTypes.string,
  resetForm: PropTypes.func
}

TicketGroupModal.defaultProps = {
  formDataState: null,
  removeDataState: null,
  removeInvsDataState: null,
  uploadDataState: null,
  groupFilesDataState: null,
  resetForm: () => {}
}
