/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import {
  activateProgram,
  deactivateProgram,
  deleteProgramDeactivate,
  updateProgramDeactivation
} from 'services/http/program'
import { helpers as programFormHelpers } from 'connectors/reducers/programsForm'
import helpers from './helpers'

const {
  REQUEST_ACTIVATE_PROGRAM,
  RESPONSE_ACTIVATE_PROGRAM,
  RESPONSE_ACTIVATE_PROGRAM_ERROR,
  REQUEST_DEACTIVATE_PROGRAM,
  RESPONSE_DEACTIVATE_PROGRAM,
  RESPONSE_DEACTIVATE_PROGRAM_ERROR,
  RESET_STATE_PROGRAM_LIFE_CYCLE,
  buildSuccessDeactivatingMessage,
  buildSuccessCancelDeactivatingMessage
} = helpers

const activateProgramAction = (authKey, id, contractData) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_ACTIVATE_PROGRAM, { key }))

  const request = await activateProgram(authKey, id, contractData).catch(error => {
    const { send, message } = error
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ACTIVATE_PROGRAM_ERROR, { key }))
    dispatch(actionCreater(programFormHelpers.RESPONSE_FORM_ERROR, { key }))
    return Promise.reject(error)
  })
  const {
    message: { message, send }
  } = request
  dispatch(actionCreater(RESPONSE_ACTIVATE_PROGRAM, { key }))
  dispatch(send(message))
  return Promise.resolve(request.data)
}

const deactivateProgramAction = (id, parameters = {}) => async dispatch => {
  const key = id
  const { prevDate, currentDate, date, unassignUnits, isInactive = false } = parameters
  const params = {
    unassignUnits
  }
  dispatch(actionCreater(REQUEST_DEACTIVATE_PROGRAM, { key }))
  const deactivationRequest = isInactive ? updateProgramDeactivation : deactivateProgram
  const request = await deactivationRequest(id, date, params).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DEACTIVATE_PROGRAM_ERROR, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_DEACTIVATE_PROGRAM, { key }))
    dispatch(send({ ...message, message: buildSuccessDeactivatingMessage(prevDate, currentDate) }))
    return 'deactivateProgramAction success'
  }

  return 'deactivateProgramAction failed'
}

const deleteProgramDelayDeactivate = (id, parameters) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_DEACTIVATE_PROGRAM, { key }))
  const request = await deleteProgramDeactivate(id, parameters).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DEACTIVATE_PROGRAM_ERROR, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_DEACTIVATE_PROGRAM, { key }))
    dispatch(send({ ...message, message: buildSuccessCancelDeactivatingMessage() }))
    return 'deleteProgramDelayDeactivate success'
  }

  return 'deleteProgramDelayDeactivate failed'
}

const resetState = key => dispatch =>
  dispatch(actionCreater(RESET_STATE_PROGRAM_LIFE_CYCLE, { key }))

export default {
  resetState,
  activateProgramAction,
  deactivateProgramAction,
  deleteProgramDelayDeactivate
}
