/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'informed'
import { useUpdateSomeDataState, useLoading, usePrevious } from 'hooks'

import { DATA_STATE_ERROR } from 'helpers/actionHelpers'

import { InnerContent } from './InnerContent'

export function UnitsDetailsTab(props) {
  const { dataId, entityDataState, entityData, onError } = props
  const formApi = useRef(null)
  const [isUnitLoading, { isLoaded }] = useLoading(entityDataState)
  const prevIsLoaded = usePrevious(isLoaded)

  const onGetFormApi = useCallback(api => {
    formApi.current = api
  }, [])

  const setFormValues = useCallback(() => {
    formApi.current.setValues({ ...entityData })
  }, [entityData])

  useUpdateSomeDataState(
    () => {
      onError()
    },
    [entityDataState],
    DATA_STATE_ERROR
  )

  useEffect(() => {
    if (prevIsLoaded !== isLoaded && isLoaded) {
      setFormValues()
    }
  }, [isLoaded, prevIsLoaded, setFormValues])

  return (
    <Form getApi={onGetFormApi}>
      <InnerContent dataId={dataId} disabled={isUnitLoading} isLoading={isUnitLoading} />
    </Form>
  )
}

UnitsDetailsTab.propTypes = {
  // data
  dataId: PropTypes.string,
  entityDataState: PropTypes.string.isRequired,
  entityData: PropTypes.object.isRequired,
  // callbacks
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
}

UnitsDetailsTab.defaultProps = {
  dataId: null,
  programId: null,
  entityData: null
}
