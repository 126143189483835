/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { httpWrapper } from 'services/http/helpers'
import { buildOrderTicketTypesURL } from './helpers'

export const assignTicketTypesOrder = (activityId, groupId, contractData) =>
  httpWrapper({
    url: buildOrderTicketTypesURL(activityId, groupId),
    method: 'put',
    data: contractData
  })
