import Result from 'folktale/result' // Result === Either Monad
import { APPLICATION_LOCALSTORAGE_NAME } from 'services/auth'
import get from 'lodash/get'

// We're making localStorage a parameter in our functions so that we can run tests as if it's
// undefined without modifying the browser settings or global window object

const validator = lsVal => (lsVal ? Result.Ok(lsVal) : Result.Error(lsVal))

const safeGetStorage = locStore => dynamicKey => {
  if (dynamicKey) {
    // TODO fix this as it returns a different type from the code below
    return JSON.parse(locStore.getItem(dynamicKey))
  }

  const final = Result.try(() =>
    // wraps in try / catch in case localstorage is undefined
    JSON.parse(locStore.getItem(APPLICATION_LOCALSTORAGE_NAME))
  ).chain(validator)
  // We use validator to check for null also since localstore.getItem returns null
  // if we haven't set localStorage on a first visit
  return final
}

const safeSetStorage = locStore => storeName => state => {
  Result.try(() => locStore.setItem(storeName, JSON.stringify(state)))
}

const removeItemStorage = locStore => keyItem => {
  if (keyItem && get(locStore, 'removeItem')) {
    locStore.removeItem(keyItem)
  }
}

export { validator, safeSetStorage, safeGetStorage, removeItemStorage }
