/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './RoundedLabel.scss'

export function RoundedLabel({ className, children }) {
  return <div className={classNames(styles.wrapper, className)}>{children}</div>
}
RoundedLabel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}
RoundedLabel.defaultProps = {
  className: null
}
