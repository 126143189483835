/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import dealFormHelpers from '../dealForm/helpers'
import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_ASSIGN_GROUPS,
  RESPONSE_ASSIGN_GROUPS,
  RESPONSE_ASSIGN_GROUPS_ERROR,
  REQUEST_FILL_TICKET_TYPES,
  RESPONSE_FILL_TICKET_TYPES,
  RESPONSE_FILL_TICKET_TYPES_ERROR,
  REQUEST_GET_ACTIVITIES_BY_DEAL_ID,
  RESPONSE_GET_ACTIVITIES_BY_DEAL_ID_ERROR,
  RESPONSE_GET_ACTIVITIES_BY_DEAL_ID,
  RESET_SEARCH_DATA_STATE
} = helpers

export const initialState = {
  searchDataState: DATA_STATE_NOT_REQUESTED,
  searchData: [],
  assignDataState: DATA_STATE_NOT_REQUESTED,
  formTicketTypesDataState: DATA_STATE_NOT_REQUESTED,
  ticketTypesData: {}
}

const dealFormGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    // assign groups
    case REQUEST_ASSIGN_GROUPS:
      return {
        ...state,
        assignDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_ASSIGN_GROUPS:
      return {
        ...state,
        assignDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_ASSIGN_GROUPS_ERROR:
      return {
        ...state,
        assignDataState: DATA_STATE_ERROR
      }
    // form ticket types
    case REQUEST_FILL_TICKET_TYPES:
      return {
        ...state,
        formTicketTypesDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_FILL_TICKET_TYPES:
      return {
        ...state,
        formTicketTypesDataState: DATA_STATE_RECIEVED,
        ticketTypesData: action.payload
      }
    case RESPONSE_FILL_TICKET_TYPES_ERROR:
      return {
        ...state,
        formTicketTypesDataState: DATA_STATE_ERROR
      }
    // form getting activities by dealId
    case REQUEST_GET_ACTIVITIES_BY_DEAL_ID:
      return {
        ...state,
        searchDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_GET_ACTIVITIES_BY_DEAL_ID:
      return {
        ...state,
        searchDataState: DATA_STATE_RECIEVED,
        searchData: action.payload.data
      }
    case RESPONSE_GET_ACTIVITIES_BY_DEAL_ID_ERROR:
      return {
        ...state,
        searchDataState: DATA_STATE_ERROR
      }
    case RESET_SEARCH_DATA_STATE:
      return {
        ...state,
        searchDataState: initialState.searchDataState,
        searchData: initialState.searchData
      }
    // reset state when form reseted
    case dealFormHelpers.RESET_DATA_STATE:
      return { ...initialState }
    case RESET_DATA_STATE:
      return {
        ...initialState
      }

    default:
      return state
  }
}

export default filterActions(dealFormGroupsReducer, [
  RESET_DATA_STATE,
  REQUEST_ASSIGN_GROUPS,
  RESPONSE_ASSIGN_GROUPS,
  RESPONSE_ASSIGN_GROUPS_ERROR,
  REQUEST_FILL_TICKET_TYPES,
  RESPONSE_FILL_TICKET_TYPES,
  RESPONSE_FILL_TICKET_TYPES_ERROR,
  REQUEST_GET_ACTIVITIES_BY_DEAL_ID,
  RESPONSE_GET_ACTIVITIES_BY_DEAL_ID_ERROR,
  RESPONSE_GET_ACTIVITIES_BY_DEAL_ID,
  RESET_SEARCH_DATA_STATE,
  dealFormHelpers.RESET_DATA_STATE
])
