/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { PM_COMPANY, ACTIVITY } from 'constants/searchTypes'
import { EXPORT_BUTTON_TEXT, EXPORT_PMC_TEXT, EXPORT_AP_TEXT } from './types'

const buildButtonTextByTypeAndFormat = (type, format) => `${EXPORT_BUTTON_TEXT} in ${format}`

export const getButtonTextByType = (type, format) => {
  switch (type) {
    case PM_COMPANY:
      return buildButtonTextByTypeAndFormat(EXPORT_PMC_TEXT, format)
    case ACTIVITY:
      return buildButtonTextByTypeAndFormat(EXPORT_AP_TEXT, format)

    default:
      return buildButtonTextByTypeAndFormat(EXPORT_PMC_TEXT, format)
  }
}

export const getActionByType = (type, props) => {
  const { exportAPPReport, exportPMCReport } = props

  switch (type) {
    case PM_COMPANY:
      return exportPMCReport
    case ACTIVITY:
      return exportAPPReport
    default:
      return exportPMCReport
  }
}
