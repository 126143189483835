/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { SimpleBox, Tag } from '@xplorie/ui-commons'
import { EntityBox } from 'components/FormComponents'
import { LIGHT_BLUE_SCHEMA, LIGHT_GREEN_SCHEMA } from 'constants/colorSchemas'
import { BouncingLoader } from 'components/Loaders'
import { useLoading } from 'hooks'

import styles from './UnitsAmenitiesTab.scss'


export function UnitsAmenitiesTab(props) {
  const { dataId, getUnitAmenitiesById, amenitiesDataState, amenitiesData } = props;
  const [isLoading] = useLoading(amenitiesDataState)

  useEffect(() => {
    if (dataId) {
      getUnitAmenitiesById(dataId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataId])

  if (isLoading) {
    return <BouncingLoader />
  }

  return (
    <SimpleBox>
      {amenitiesData && amenitiesData.map(item => (
        <EntityBox
          title={item.name} 
          colorSchema={LIGHT_BLUE_SCHEMA} 
          key={item.name}
        >
          <div className={styles.listWrapper}>
            { item.amenities && item.amenities.map(el => (
              <Tag 
                colorSchema={LIGHT_GREEN_SCHEMA} 
                key={el.name}
              >
                {el.name}
              </Tag>
            ))}
          </div>
        </EntityBox>
      ))}
    </SimpleBox>
  
  )
}

UnitsAmenitiesTab.propTypes = {
  dataId: PropTypes.string,
  amenitiesData: PropTypes.array.isRequired,
  getUnitAmenitiesById: PropTypes.func.isRequired,
  amenitiesDataState: PropTypes.string.isRequired,
}

UnitsAmenitiesTab.defaultProps = {
  dataId: null,
}
