/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { TrashIcon } from 'icons'
import styles from './styles.scss'

export function TrashButton({ disabled, onClick }) {
  const onClickHandler = useCallback(
    event => {
      if (disabled || !onClick) {
        return
      }

      onClick(event)
    },
    [disabled, onClick]
  )

  return (
    <TrashIcon
      className={classNames(styles.button, { [styles.disabled]: disabled })}
      onClick={onClickHandler}
    />
  )
}

TrashButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}
