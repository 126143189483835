/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { ACTIVITY_TAG } from 'constants/searchTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'

export const activityTagsTableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', ACTIVITY_TAG),
    sortable: false
  }
]
