/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
// butttons
export const ADD_AVAILABILITY_BUTTON_TEXT = 'Add New Availability'

// labels
export const START_DATE_LABEL = 'Start Date'
export const END_DATE_LABEL = 'End Date'
export const SECTION_TITLE = 'Availability'

// phaceholders
export const START_DATE_PLACEHOLDER = 'Start Date'
export const END_DATE_PLACEHOLDER = 'End Date'

// messages
export const START_DATE_VALIDATION_MESSAGE =
  'Date must be earlier than end date or after previous availability period'
export const END_DATE_VALIDATION_MESSAGE = 'Date must be after the start date'
export const FILL_START_DATE_MESSAGE = 'Please fill in the start date'
export const FILL_END_DATE_MESSAGE = 'Please fill in the end date'
