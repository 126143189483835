/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_PREBOOK_INFO,
  RESPONSE_GET_PREBOOK_INFO,
  RESPONSE_GET_PREBOOK_INFO_ERROR,
  RESET_DATA_STATE
} = helpers

export const initialState = {}

export const formInitialState = {
  formDataState: DATA_STATE_NOT_REQUESTED,
  entityDataState: DATA_STATE_NOT_REQUESTED,
  entityData: {}
}

const buildData = (state, action) => data => ({
  ...state,
  [action.payload.key]: {
    ...formInitialState,
    ...state[action.payload.key],
    ...data
  }
})

const listReducer = (state = initialState, action) => {
  const appendData = buildData(state, action)

  switch (action.type) {
    case REQUEST_FORM:
      return appendData({
        formDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_FORM:
      return appendData({
        formDataState: DATA_STATE_RECIEVED,
        entityData: action.payload.data
      })
    case RESPONSE_FORM_ERROR:
      return appendData({
        formDataState: DATA_STATE_ERROR
      })
    // get prebook info
    case REQUEST_GET_PREBOOK_INFO:
      return appendData({
        entityDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_GET_PREBOOK_INFO:
      return appendData({
        entityDataState: DATA_STATE_RECIEVED,
        entityData: action.payload.data
      })
    case RESPONSE_GET_PREBOOK_INFO_ERROR:
      return appendData({
        entityDataState: DATA_STATE_ERROR
      })
    case RESET_DATA_STATE: {
      const { [action.payload.key]: removedState, ...restState } = state
      return {
        ...restState
      }
    }
    default:
      return state
  }
}

export default filterActions(listReducer, [
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_PREBOOK_INFO,
  RESPONSE_GET_PREBOOK_INFO,
  RESPONSE_GET_PREBOOK_INFO_ERROR,
  RESET_DATA_STATE
])
