/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Notifs } from 'components/ReduxNotifications'
import { Notification } from 'components/Notifications'

export const ReduxNotifComponent = props => {
  const { notifDismiss } = props
  return <Notifs onActionClick={notifDismiss} CustomComponent={Notification} actionLabel="close" />
}
ReduxNotifComponent.propTypes = {
  notifDismiss: PropTypes.func
}
ReduxNotifComponent.defaultProps = {
  notifDismiss: () => null
}
