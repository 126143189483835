/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { initialProgramApprovalState } from './reducer'

export const getProgramsApprovalState = state => state.programApproval

export const getProgramApprovalState = (state, id) =>
  state.programApproval[id] || initialProgramApprovalState

export const isProgramApprovalPending = (state, id) => {
  const currentState = getProgramApprovalState(state, id)
  return [currentState.approvalState, currentState.programDraftState].some(
    value => value === DATA_STATE_REQUESTING
  )
}
