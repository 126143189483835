/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export const UploadIcon = React.forwardRef((props, ref) => (
  <svg
    viewBox="0 0 451.111 451.111"
    width="512"
    height="512"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    ref={ref}
  >
    <path d="m225.556 354.445 145-145-48.333-48.333-64.444 64.444v-225.556h-64.444v225.556l-64.444-64.444-48.333 48.333z" />
    <path d="m0 386.667h451.111v64.444h-451.111z" />
  </svg>
))
