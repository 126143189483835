/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'

import { SimpleTable } from 'components/Tables'
import { HEADER } from './types'
import { buildTableColumns } from './helpers'

export function UniqPhoneNumberList(props) {
  const {
    dataState,
    itemsCount,
    itemsPerPage,
    formDataState,
    removeDataState,
    data,
    onGetData,
    needUpdate,
    authUser,
    TableComponent,
    ...rest
  } = props
  return (
    <TableComponent
      {...rest}
      dataState={dataState}
      columns={buildTableColumns(props, authUser)}
      onGetData={onGetData}
      itemsPerPage={itemsPerPage}
      itemsCount={itemsCount}
      tableHeader={HEADER}
      data={data}
      // TODO: Need remove it after refactoring.
      needUpdateList={needUpdate}
      dataStateWatchers={[removeDataState, formDataState]}
      withBasicAuth={false}
    />
  )
}

UniqPhoneNumberList.propTypes = {
  dataState: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemsCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number,
  formDataState: PropTypes.string,
  removeDataState: PropTypes.string,
  onGetData: PropTypes.func.isRequired,
  needUpdate: PropTypes.bool,
  authUser: PropTypes.object.isRequired,
  TableComponent: PropTypes.func
}

UniqPhoneNumberList.defaultProps = {
  formDataState: null,
  removeDataState: null,
  extendedOptions: {},
  needUpdate: false,
  TableComponent: SimpleTable,
  itemsPerPage: 10
}
