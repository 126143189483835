/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const NAME = 'SCHEDULE_FORM'
const GET_ENTITY_DATA = `${NAME}_GET_ENTITY_DATA`
const DELETE_SERIES = `${NAME}_DELETE_SERIES`
const DELETE_OCCURRENCE = `${NAME}_DELETE_OCCURRENCE`
const DELETE_OCCURRENCES = `${NAME}_DELETE_OCCURRENCES`
const CANCEL_SERIES = `${NAME}_CANCEL_SERIES`
const CANCEL_OCCURRENCE = `${NAME}_CANCEL_OCCURRENCE`
const ACTIVATE_SERIES = `${NAME}_ACTIVATE_SERIES`
const ACTIVATE_OCCURRENCE = `${NAME}_ACTIVATE_OCCURRENCE`

export default {
  NAME,
  GET_ENTITY_DATA,
  DELETE_SERIES,
  DELETE_OCCURRENCE,
  DELETE_OCCURRENCES,
  CANCEL_SERIES,
  CANCEL_OCCURRENCE,
  ACTIVATE_SERIES,
  ACTIVATE_OCCURRENCE
}
