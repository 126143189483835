/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import styles from './ReactTable.scss'
import { LOADER_TEXT } from './types'

export function Loader() {
  return <div className={styles.loaderWrapper}>{LOADER_TEXT}</div>
}
