/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { appsByMarketInitialState } from './reducer'

const getAppsByMarketStateByAppId = (state, appId) =>
  state.app.appsByMarket[appId] || appsByMarketInitialState

export default {
  getAppsByMarketStateByAppId
}
