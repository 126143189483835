/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const USER_DATA_PERMISSION_FIELD = 'permission'

export const READ_LEVEL = 'read'
export const USER_LEVEL = 'user'
export const CREATE_LEVEL = 'create'
export const UPDATE_LEVEL = 'update'
export const UPDATE_OWNED_LEVEL = 'updateOwned'
export const APPROVE_LEVEL = 'approve'
export const DELETE_LEVEL = 'delete'
export const DELETE_OWNED_LEVEL = 'deleteOwned'
export const ADMIN_LEVEL = 'admin'
export const SYS_ADMIN_LEVEL = 'sysadmin'
export const VAC_SPECIALIST_LEVEL = 'vac_specialist'
export const USER_ROLE_LEVEL = 'user_role'
export const VAC_SPECIALIST_TIER_2_LEVEL = 'vac_specialist_tier_2'
export const UNIT_TASKS_MANAGE_LEVEL = 'unit_tasks_manage'
