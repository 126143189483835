/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import compose from 'lodash/fp/compose'
import { TogglePopupButton } from '@xplorie/ui-commons'
import { withModalWindow } from 'decorators'
import { DisableSmartHostModal } from 'modules/Units/UnitManager/DisableSmartHostModal'
import { EnableSmartHostModal } from 'modules/Units/UnitManager/EnableSmartHostModal'
import { ENABLED } from 'constants/smartHostStatus'

import { buildSmartHostPayload, buildPopupOptions } from '../helpers'
import { POPUP_DISABLE_LABEL, TOGGLE_MODAL_EVENT_NAME } from '../types'
import { getLabelByStatus } from './helpers'
import styles from './styles'

const TogglePopupButtonWithModal = withModalWindow(TogglePopupButton)

export function SmartHostCell(props) {
  const { cellData, updateSmartHostByProgramId, dataId } = props
  const smartHostStatus = useMemo(() => get(cellData, 'original.smartHost.status', false), [
    cellData
  ])
  const label = useMemo(() => getLabelByStatus(smartHostStatus), [smartHostStatus])
  const unitId = useMemo(() => get(cellData, 'original.id', false), [cellData])
  const options = useMemo(() => buildPopupOptions(smartHostStatus), [smartHostStatus])
  const togglePopupProps = useMemo(
    () => ({
      options,
      disabled: smartHostStatus !== ENABLED,
      label
    }),
    [options, smartHostStatus, label]
  )

  const onSmartHostUpdate = useCallback(
    payload => {
      updateSmartHostByProgramId(dataId, {
        unitIds: [unitId],
        smartHost: {
          ...payload
        }
      })
    },
    [updateSmartHostByProgramId, dataId, unitId]
  )

  return (
    <TogglePopupButtonWithModal
      {...togglePopupProps}
      className={styles.toggle}
      eventName={TOGGLE_MODAL_EVENT_NAME}
      renderModal={({ isShown, onHide, originalArguments }) => {
        const action = get(originalArguments, '0.label')
        const isDisableAction = action === POPUP_DISABLE_LABEL
        const ActionModal = isDisableAction ? DisableSmartHostModal : EnableSmartHostModal
        const onConfirm = compose(
          onHide,
          onSmartHostUpdate,
          buildSmartHostPayload(isDisableAction)
        )

        return <ActionModal isShown={isShown} onConfirm={onConfirm} onHide={onHide} />
      }}
    />
  )
}

SmartHostCell.propTypes = {
  dataId: PropTypes.string.isRequired,
  cellData: PropTypes.object.isRequired,
  updateSmartHostByProgramId: PropTypes.func.isRequired,
  programUnitsState: PropTypes.object.isRequired
}
