/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import get from 'lodash/get'
import { YES_VALUE, NO_VALUE } from 'helpers/types/dataTablesTypes'

import { PM_SYSTEM } from 'constants/searchTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'

function buildHumanReadableValue(option) {
  return valueObject => {
    const value = get(valueObject, option)
    return value ? YES_VALUE : NO_VALUE
  }
}

export const pmsTableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', PM_SYSTEM)
  },
  {
    Header: 'Total PMCs',
    accessor: 'pmcTotalCount',
    id: buildSortPropertyByType('pmcTotalCount', PM_SYSTEM)
  },
  {
    Header: 'Total Units Count',
    accessor: 'unitTotalCount',
    id: buildSortPropertyByType('unitTotalCount', PM_SYSTEM)
  },
  {
    Header: 'Guest API',
    accessor: value => buildHumanReadableValue('guestApi')(value),
    id: buildSortPropertyByType('guestApi', PM_SYSTEM)
  },
  {
    Header: 'Listing API',
    accessor: value => buildHumanReadableValue('listingApi')(value),
    id: buildSortPropertyByType('listingApi', PM_SYSTEM)
  }
]
