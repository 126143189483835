/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { SimpleBox } from '@xplorie/ui-commons'
import {
  CalendarField,
  CustomNestedField,
  FormContent,
  TextAreaField,
  TextField
} from 'components/FormComponents'
import { TrashIcon } from 'icons'
import { anyNumberValidation, withValidation } from 'helpers/formHelpers'
import styles from './RateFormBox.scss'
import {
  END_DATE_LABEL,
  MAX_REASON_LENGTH,
  NEW_RATE_LABEL,
  RateFormContext,
  REASON_LABEL,
  START_DATE_LABEL
} from '../types'
import { RateFormItem } from './RateFormItem'
import { borderValidationRateFee, decimalsValidationRateFee } from './helpers'
import { useCalendarValidation } from './hooks'

function RateFormBox(props) {
  const { item, index } = props
  const { setRates, formApi } = useContext(RateFormContext)
  const itemName = useMemo(() => `rates[${index}]`, [index])
  const disabled = useMemo(() => !item.editable, [item.editable])

  const onRemoveClick = useCallback(() => {
    const formRates = formApi.getValue('rates') || []
    const nextRates = formRates.filter(rate => rate.id !== item.id)

    formApi.setValues({ rates: nextRates })
    setRates(nextRates)
  }, [item.id, formApi, setRates])

  const valueValidate = withValidation(
    withValidation(anyNumberValidation, borderValidationRateFee),
    decimalsValidationRateFee
  )
  const [startDateProps, endDateProps] = useCalendarValidation(index)

  return (
    <FormContent disabled={disabled}>
      <SimpleBox className={styles.box}>
        <CustomNestedField field={itemName}>
          {!disabled && (
            <div className={styles.remove}>
              <TrashIcon onClick={onRemoveClick} />
            </div>
          )}
          <div className={styles.row}>
            <div className={styles.column}>
              <div className={styles.valueField}>
                <RateFormItem
                  Field={TextField}
                  fieldName="value"
                  label={NEW_RATE_LABEL}
                  value={`$${item.value}`}
                  validate={valueValidate}
                  hasCurrency
                />
              </div>
              <div className={styles.dateField}>
                <RateFormItem
                  Field={CalendarField}
                  fieldName="startDate"
                  label={START_DATE_LABEL}
                  value={item.startDate}
                  additionalProps={startDateProps}
                  readOnly
                />
                <RateFormItem
                  Field={CalendarField}
                  fieldName="endDate"
                  label={END_DATE_LABEL}
                  value={item.endDate}
                  additionalProps={endDateProps}
                  readOnly
                />
              </div>
            </div>
            <div className={styles.reasonField}>
              <RateFormItem
                Field={TextAreaField}
                fieldName="reason"
                label={REASON_LABEL}
                value={item.reason}
                maxLength={MAX_REASON_LENGTH}
              />
            </div>
          </div>
        </CustomNestedField>
      </SimpleBox>
    </FormContent>
  )
}

RateFormBox.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired
}

export default RateFormBox
