/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import filter from 'lodash/filter'
import React from 'react'
import { useDidMount } from 'hooks'
import PropTypes from 'prop-types'
import { AddingEntityBox, EntityBox } from 'components/FormComponents'
import { LIGHT_BLUE_SCHEMA, LIGHT_GREEN_SCHEMA, GRAY_SCHEMA } from 'constants/colorSchemas'
import { Column, Field } from 'components/FormComponents/Boxes/EntityBox'
import { useRootModal } from 'hooks/useRootModal'
import { ExclusiveOffersWarningModal } from './ExclusiveOffersWarningModal'
import styles from './ProgramExclusiveOffers.scss'
import {
  LABEL_EXCLUSIVE_OFFERS,
  LABEL_EXCLUSIVE_OFFERS_ACTIVITIES,
  LABEL_ACTIVITIES,
  LABEL_EXCLUDED_ACTIVITIES
} from './types'
import { TagWithAction } from './TagWithAction/TagWithAction'
import { EXCLUDE_ACTION_TYPE, INCLUDE_ACTION_TYPE } from './TagWithAction/types'

export function ProgramExclusiveOffers(props) {
  const {
    getProgramExclusiveActivitiesList,
    removeProgramExclusiveActivity,
    addProgramExclusiveActivity,
    dataId,
    exclusiveOffersData
  } = props

  const rootModal = useRootModal()
  const excludedActivitiesData = filter(
    exclusiveOffersData,
    activity => !activity.parameters.included
  )
  const includedActivitiesData = filter(
    exclusiveOffersData,
    activity => activity.parameters.included
  )

  const handleRemoveActivity = async activityId => {
    await removeProgramExclusiveActivity(dataId, activityId)
    getProgramExclusiveActivitiesList(dataId)
  }

  const handleDeleteActivity = (name, id) => () => {
    rootModal.enqueue(ExclusiveOffersWarningModal, {
      isOpen: true,
      onClose: rootModal.dequeue,
      message: `Do you really want to remove ${name} Product from Exclusive Offers?`,
      onSubmit: () => handleRemoveActivity(id)
    })
  }

  const handleAddActivity = async activityId => {
    await addProgramExclusiveActivity(dataId, activityId)
    getProgramExclusiveActivitiesList(dataId)
  }

  const handleIncludeActivity = (name, id) => () => {
    rootModal.enqueue(ExclusiveOffersWarningModal, {
      isOpen: true,
      onClose: rootModal.dequeue,
      message: `Do you really want to include ${name} Product to Exclusive Offers?`,
      onSubmit: () => handleAddActivity(id)
    })
  }

  useDidMount(() => {
    if (dataId) {
      getProgramExclusiveActivitiesList(dataId)
    }
  })

  return (
    <AddingEntityBox title={LABEL_EXCLUSIVE_OFFERS} isVisibleSearch={false}>
      <EntityBox
        titleClassName={styles.entityBoxLabel}
        title={LABEL_EXCLUSIVE_OFFERS_ACTIVITIES}
        colorSchema={LIGHT_BLUE_SCHEMA}
      >
        <Column>
          <Field
            wrapperClassNames={styles.tagWrapper}
            label={LABEL_ACTIVITIES}
            labelClassNames={styles.label}
          >
            <div className={styles.tags}>
              {includedActivitiesData.map(activity => (
                <TagWithAction
                  key={activity.id}
                  colorSchema={LIGHT_GREEN_SCHEMA}
                  innerContent={activity.name}
                  actionType={EXCLUDE_ACTION_TYPE}
                  action={handleDeleteActivity(activity.name, activity.id)}
                />
              ))}
            </div>
          </Field>
          {excludedActivitiesData && Boolean(excludedActivitiesData.length) && (
            <Field
              wrapperClassNames={styles.tagWrapper}
              label={LABEL_EXCLUDED_ACTIVITIES}
              labelClassNames={styles.label}
            >
              <div className={styles.tags}>
                {excludedActivitiesData.map(activity => (
                  <TagWithAction
                    key={activity.id}
                    colorSchema={GRAY_SCHEMA}
                    innerContent={activity.name}
                    actionType={INCLUDE_ACTION_TYPE}
                    action={handleIncludeActivity(activity.name, activity.id)}
                  />
                ))}
              </div>
            </Field>
          )}
        </Column>
      </EntityBox>
    </AddingEntityBox>
  )
}
ProgramExclusiveOffers.propTypes = {
  getProgramExclusiveActivitiesList: PropTypes.func.isRequired,
  removeProgramExclusiveActivity: PropTypes.func.isRequired,
  addProgramExclusiveActivity: PropTypes.func.isRequired,
  includeProgramExclusiveActivity: PropTypes.func.isRequired,
  dataId: PropTypes.string.isRequired,
  exclusiveOffersData: PropTypes.object.isRequired
}
