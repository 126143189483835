/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { useUpdateSomeDataState, useDidMount } from 'hooks'
import { BoxLabel } from 'components/Basic/Labels'
import { TicketTypeForm } from 'modules/ActivityProducts/ActivityResaleTab'
import { SliderModal } from 'modules/ActivityProducts/ActivityResaleTab/SliderModal'
import {
  isDisabledFormBuilder,
  isLoadingFormBuilder,
  onSubmitHandler,
  onDeleteHandler
} from './helpers'
import styles from './TicketTypeModal.scss'
import { ADD_TICKET_TYPE, EDIT_TICKET_TYPE } from './types'

export function TicketTypeModal(props) {
  const {
    cancelHandler,
    dataId,
    getEntityDataById,
    formDataState,
    entityData,
    resetForm,
    removeDataState,
    ticketGroupsOptions,
    formData = { id: null, ...entityData },
    ...rest
  } = props
  const isFormLoading = isLoadingFormBuilder(props)
  const isDisabled = isDisabledFormBuilder(props)
  const headingLabel = entityData && entityData.id ? EDIT_TICKET_TYPE : ADD_TICKET_TYPE

  const onCancelHandler = useCallback(() => {
    cancelHandler()
  }, [cancelHandler])

  useUpdateSomeDataState(
    () => {
      onCancelHandler()
    },
    [removeDataState, formDataState],
    DATA_STATE_RECIEVED
  )

  useDidMount(() => {
    if (dataId) {
      getEntityDataById(dataId)
    }
    return () => {
      resetForm()
    }
  })

  return (
    <SliderModal className={styles.boxWrapper} cancelHandler={onCancelHandler}>
      {!isFormLoading && <BoxLabel>{headingLabel}</BoxLabel>}
      <TicketTypeForm
        isLoading={isFormLoading}
        isDisabled={isDisabled}
        onSubmit={onSubmitHandler(props)}
        onDelete={onDeleteHandler(props)}
        formData={formData}
        ticketGroupsOptions={ticketGroupsOptions}
        {...rest}
      />
    </SliderModal>
  )
}

TicketTypeModal.propTypes = {
  cancelHandler: PropTypes.func.isRequired,
  entityData: PropTypes.object,
  dataId: PropTypes.string,
  getEntityDataById: PropTypes.string,
  formDataState: PropTypes.string,
  removeDataState: PropTypes.string,
  resetForm: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  ticketGroupsOptions: PropTypes.array
}

TicketTypeModal.defaultProps = {
  entityData: {},
  formDataState: null,
  removeDataState: null,
  dataId: null,
  getEntityDataById: null,
  ticketGroupsOptions: []
}
