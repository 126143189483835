/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { combineReducers } from 'redux'

import unitTasksList from './unitTasksList'
import unitTasksByProgramList from './unitTasksByProgram'

export default combineReducers({
  unitTasksList,
  unitTasksByProgramList
})
