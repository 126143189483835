/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useEffect, useContext, useCallback, useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import findIndex from 'lodash/findIndex'
import isObject from 'lodash/isObject'
import get from 'lodash/get'
import { useSliderApi, usePrevious } from 'hooks'
import { CollapseWrapper } from 'components/Wrappers'
import { isCurrentYear } from 'helpers/dateHelpers'
import { SEASON } from 'constants/formCodes'
import { ProgramSeasonContext } from 'modules/Programs/helpers'

import { SeasonItem } from '../SeasonItem'

import {
  groupSeasonsByYear,
  buildCollapseTitle,
  validateDateIntersections,
  createSeasonItem,
  getSeasonId,
  validateBasicErrors
} from './helpers'
import { SEASONS_TITLE_SPOT } from './types'

export function SeasonsList(props) {
  const {
    data,
    getApi,
    dataId,
    onChange,
    isRequestPending,
    cloneProgramSeasonById,
    programFormState,
    seasonsState,
    setSeasonsState
  } = props
  const isTouched = useRef(false)
  const sliderApi = useSliderApi(SEASON)
  const prevData = usePrevious(data)
  const prevGetApi = usePrevious(getApi)
  const prevSeasonsState = usePrevious(seasonsState)
  const seasons = useRef([])
  const groupedSeasons = groupSeasonsByYear(seasonsState)
  const { permissions } = useContext(ProgramSeasonContext)
  const { canUpdateSeasons: canUpdatePermission } = permissions
  const canUpdate = !isRequestPending && canUpdatePermission
  const programType = get(programFormState, 'values.type')
  const prevProgramType = usePrevious(programType)

  const onChangeList = useCallback(
    type => {
      if (onChange) {
        onChange(type)
      }
    },
    [onChange]
  )

  const findSeasonIndexById = useCallback(
    (id, array = seasons.current) => findIndex(array, value => getSeasonId(value) === id),
    []
  )

  const onGetSeasonApi = useCallback(
    season => api => {
      if (!season.api) {
        const seasonIndex = findSeasonIndexById(getSeasonId(season), seasonsState)
        if (seasonIndex !== -1) {
          const bufferArray = [...seasonsState]
          bufferArray.splice(seasonIndex, 1, { ...season, api })
          setSeasonsState([...bufferArray])
        }
      }
    },
    [findSeasonIndexById, seasonsState]
  )

  const addNewSeason = useCallback((seasonId, formValues) => {
    setSeasonsState([...seasons.current, createSeasonItem({ id: seasonId, ...formValues })])
  }, [])

  const updateSeason = useCallback(
    (seasonId, formValues) => {
      const seasonIndex = findSeasonIndexById(seasonId)
      if (seasonIndex !== -1) {
        const season = createSeasonItem({ id: seasonId, ...formValues })
        const bufferArray = [...seasons.current]
        bufferArray.splice(seasonIndex, 1, season)
        setSeasonsState([...bufferArray])
      }
    },
    [findSeasonIndexById]
  )

  const onAddNew = useCallback(
    (sliderProps = {}) => {
      sliderApi.open(null, {
        props: {
          canUpdate: true,
          programId: dataId,
          onSubmitFulfilled: addNewSeason,
          ...sliderProps
        }
      })
    },
    [sliderApi, dataId, addNewSeason]
  )

  const onUpdate = useCallback(
    (id, sliderProps = {}) => {
      const { props: sliderPropsOptions, ...restParameters } = sliderProps
      sliderApi.open(id, {
        ...restParameters,
        props: {
          ...sliderPropsOptions,
          programId: dataId,
          programType,
          onSubmitFulfilled: updateSeason
        }
      })
    },
    [dataId, sliderApi, updateSeason, programType]
  )

  const onDelete = useCallback(
    id => {
      const seasonIndex = findSeasonIndexById(id)
      if (seasonIndex !== -1) {
        const bufferArray = [...seasons.current]
        bufferArray.splice(seasonIndex, 1)
        setSeasonsState([...bufferArray])
      }
    },
    [findSeasonIndexById]
  )

  const onValidate = useCallback(type => {
    if (isArray(seasons.current)) {
      const listErrors = validateDateIntersections(seasons.current)
      const basicErrors = validateBasicErrors(seasons.current)
      const errors = seasons.current
        .filter(value => value.api && isObject(value.api))
        .map(value => value.api)
        .reduce((acc, api) => {
          const seasonErrors = api.onValidate(type)
          return Object.keys(seasonErrors).length ? [...acc, seasonErrors] : acc
        }, [])
      return [...errors, ...listErrors, ...basicErrors]
    }
    return null
  }, [])

  const getState = useCallback(() => {
    const output = { values: null, isTouched: isTouched.current }
    if (isArray(seasons.current)) {
      output.values = seasons.current.map(value => value.data)
      return output
    }
    return output
  }, [seasons])

  const onViewHandler = formData => () => {
    const seasonData = get(formData, 'data')
    const seasonName = get(seasonData, 'name')
    sliderApi.open(getSeasonId(formData), {
      props: { formData: seasonData, programId: dataId, programType },
      headerProps: { title: seasonName }
    })
  }

  const onUpdateHandler = formData => () => {
    const seasonData = get(formData, 'data')
    const seasonName = get(seasonData, 'name')
    onUpdate(getSeasonId(formData), {
      props: { formData: seasonData, canUpdate },
      headerProps: { title: seasonName }
    })
  }

  const onDeleteHandler = formData => () => {
    onDelete(getSeasonId(formData))
  }

  const onCloneHandler = useCallback(
    seasonId => {
      cloneProgramSeasonById(dataId, seasonId).then(id => {
        sliderApi.open(id, {
          props: { programId: dataId, canUpdate, onSubmitFulfilled: updateSeason, programType }
        })
      })
    },
    [canUpdate, cloneProgramSeasonById, dataId, sliderApi, updateSeason, programType]
  )

  useEffect(() => {
    if (prevData !== data && isArray(data)) {
      setSeasonsState(data.map(value => createSeasonItem(value)))
      isTouched.current = false
    }
  }, [data, prevData])

  useEffect(() => {
    if (prevGetApi !== getApi) {
      getApi({ onAddNew, onDelete, onUpdate, getState, onValidate })
    }
  }, [prevGetApi, getApi, onAddNew, onDelete, getState, onUpdate, onValidate])

  useEffect(() => {
    if (isArray(seasonsState)) {
      seasons.current = seasonsState
    }
  })

  useEffect(() => {
    if (prevSeasonsState !== seasonsState || prevProgramType !== programType) {
      onChangeList(programType)
    }
  }, [onChangeList, prevProgramType, prevSeasonsState, programType, seasonsState])

  return Object.keys(groupedSeasons).map(year => {
    const seasonsByYear = groupedSeasons[year]
    return (
      <CollapseWrapper
        key={year}
        title={buildCollapseTitle(SEASONS_TITLE_SPOT, year)}
        expand={isCurrentYear(year)}
      >
        {!isEmpty(seasonsByYear) &&
          seasonsByYear.map(season => (
            <SeasonItem
              getApi={onGetSeasonApi(season)}
              onChange={onChangeList}
              key={getSeasonId(season)}
              value={season.data}
              onView={onViewHandler(season)}
              onUpdate={onUpdateHandler(season)}
              onDelete={onDeleteHandler(season)}
              canUpdate={canUpdate}
              canDelete={canUpdate}
              canView={!canUpdate}
              canClone={canUpdate}
              onClone={onCloneHandler}
              programId={dataId}
              programType={programType}
            />
          ))}
      </CollapseWrapper>
    )
  })
}
