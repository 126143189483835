export const CALENDAR_EVENT_COLOR = '#1a252f'
export const SCHEDULE_EVENT_LABEL = 'Schedule'
export const CLOSURE_EVENT_LABEL = 'Closure'
export const CANCELLATION_LABEL = 'Cancellation'
export const FULL_DAY_SCHEDULE_EVENT_LABEL = 'Full day Schedule'
export const FULL_DAY_CLOSURE_EVENT_LABEL = 'Full day Closure'
export const DELETE_SCHEDULE_OCCURRENCE_MESSAGE = 'Do you really want to delete Schedule?'
export const CANCEL_SCHEDULE_SERIES_MESSAGE = 'Do you really want to cancel Schedule?'
export const DELETE_CLOSURE_OCCURRENCE_MESSAGE = 'Do you really want to delete Closure?'
export const ACTIVATE_SCHEDULE_MESSAGE = 'Do you really want to activate Schedule?'

export const SUCCESS_DELETE_CLOSURE_MESSAGE = 'Closure has been removed!'
export const ERROR_DELETE_CLOSURE_MESSAGE = 'Closure is not removed!'

export const SUCCESS_DELETE_SCHEDULE_MESSAGE = 'Schedule has been removed!'
export const ERROR_DELETE_SCHEDULE_MESSAGE = 'Schedule is not removed!'

export const CANCEL_SCHEDULE_MODAL_TITLE = 'Cancel Schedule'
export const RECURRENCE_ICON = '/reccurence-icon.svg'
export const ACTIVATE_SCHEDULE_MODAL_TITLE = 'Activate Schedule'
