import { TextField } from 'components/FormComponents/Inputs/TextField/TextField'
import { withFormContext } from 'components/FormComponents/helpers'
import React from 'react'
import PropTypes from 'prop-types'
import { validateMinNumber, validateMaxNumber } from 'helpers/formHelpers'
import styles from './styles.scss'
import {
  AVAILABILITIES_FIELD,
  MIN_RESERVATION_DURATION_FIELD,
  MAX_RESERVATION_DURATION_FIELD,
  MIN_RESERVATION_DURATION_LABEL,
  MAX_RESERVATION_DURATION_LABEL,
  MAX_RESERVATION_DURATION_PLACEHOLDER,
  MIN_RESERVATION_DURATION_PLACEHOLDER
} from './types'
import { validateNumberRange } from './helpers'
import { useDealPeriodsApi } from './hooks'

function DiscountRangeSelector(props) {
  const { item } = props
  const dealPeriodsApi = useDealPeriodsApi()
  const maxDurationField = `${AVAILABILITIES_FIELD}.${item.id}.${MAX_RESERVATION_DURATION_FIELD}`
  const minDurationField = `${AVAILABILITIES_FIELD}.${item.id}.${MIN_RESERVATION_DURATION_FIELD}`

  const minDuration = validateNumberRange(validateMinNumber, maxDurationField)
  const maxDuration = validateNumberRange(validateMaxNumber, minDurationField)

  const onMinChange = event => {
    dealPeriodsApi.onUpdate({
      ...item,
      from: event.target.value
    })
  }

  const onMaxChange = event => {
    dealPeriodsApi.onUpdate({
      ...item,
      to: event.target.value
    })
  }

  return (
    <div className={styles.rangeWrapper}>
      <TextField
        fieldProps={{
          className: styles.field,
          isRequired: true
        }}
        label={MIN_RESERVATION_DURATION_LABEL}
        placeholder={MIN_RESERVATION_DURATION_PLACEHOLDER}
        fieldName={MIN_RESERVATION_DURATION_FIELD}
        validate={minDuration}
        notify={[maxDurationField]}
        onChange={onMinChange}
      />
      <TextField
        fieldProps={{
          className: styles.field
        }}
        label={MAX_RESERVATION_DURATION_LABEL}
        placeholder={MAX_RESERVATION_DURATION_PLACEHOLDER}
        fieldName={MAX_RESERVATION_DURATION_FIELD}
        validate={maxDuration}
        notify={[minDurationField]}
        onChange={onMaxChange}
      />
    </div>
  )
}

DiscountRangeSelector.propTypes = {
  item: PropTypes.object.isRequired
}

export default withFormContext(DiscountRangeSelector)
