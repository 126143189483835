/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const PMSSlider = loadable(
  () => import(/* webpackChunkName: 'PMSSlider', webpackPrefetch: true */ './PMSSlider'),
  {
    resolveComponent: components => components.PMSSlider
  }
)

export { PMSForm } from './PMSForm'
export { PMSList } from './PMSList'
export { PMSEligibility } from './PMSEligibility'
export { PMSSlider }
export * from './helpers'
