/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import {
  actions,
  getPmcUnitsListStateByPmcIdAndProgramId
} from 'reducers/units/pmcProgramUnitsList'
import { actions as pmcProgramsActions } from 'reducers/program/pmcProgramsWithUnits'
import { withAuthContext } from 'helpers/withAuthContext'

import { ProgramUnitsTable as ProgramUnitsTableComponent } from './ProgramUnitsTable'

const { getPmcProgramsById } = pmcProgramsActions
const { resetList } = actions

const mapStateToProps = (state, { dataId }) => ({
  pmcData: state.pmcsForm.pmcItem,
  getProgramUnitsState: getPmcUnitsListStateByPmcIdAndProgramId.bind(null, state, dataId)
})

const mapDispatchToProps = (dispatch, { dataId }) =>
  bindActionCreators(
    {
      getPmcProgramsById,
      resetList: resetList.bind(null, dataId)
    },
    dispatch
  )

export const ProgramUnitsTable = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProgramUnitsTableComponent)
