/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { httpWrapper, buildListParams } from 'services/http/helpers'
import { SEARCH_EXPORT_URL, FILTERED_SEARCH_EXPORT_URL } from 'endpoints'
import { responseToFile } from 'helpers/downloadHelper'

export const exportData = (data, params, filename) =>
  httpWrapper({
    url: SEARCH_EXPORT_URL,
    method: 'post',
    data,
    responseType: 'blob',
    params: buildListParams(params)
  }).then(response => responseToFile(response, filename))

export const filteredExportData = (data, params, filename) =>
  httpWrapper({
    url: FILTERED_SEARCH_EXPORT_URL,
    method: 'post',
    data,
    responseType: 'blob',
    params: buildListParams(params)
  }).then(response => responseToFile(response, filename))
