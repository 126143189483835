/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { SimpleBox } from '@xplorie/ui-commons'
import { BoxLabel } from 'components/Basic/Labels'
import classNames from 'classnames'
import styles from './TicketTypeItem.scss'

export function TicketTypeItem(props) {
  const { onClick, name, isActive } = props

  return (
    <a onClick={onClick}>
      <SimpleBox className={classNames(styles.wrapper, { [styles.active]: isActive })}>
        <BoxLabel className={styles.label}>{name}</BoxLabel>
      </SimpleBox>
    </a>
  )
}
TicketTypeItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  name: PropTypes.string
}
TicketTypeItem.defaultProps = {
  name: ''
}
