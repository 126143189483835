/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  ACTIVITY_CATEGORY_TYPE,
  ACTIVITY_MODULE_TYPE,
  LOCATIONS_TYPE,
  PMS_NAME_TYPE,
  RMS_NAME_TYPE,
  TILE_ACCORDIONS_TYPE,
  UNIQ_PHONE_NUMBER_TYPE,
  ELIGIBILITY_TYPE,
  MARKET_TYPE,
  ACTIVITY_TAGS_TYPE
} from 'containers/Library/types'

import {
  ACTIVITY_CATEGORY_TITLE,
  ACTIVITY_MODULE_TITLE,
  LOCATIONS_TITLE,
  PMS_NAME_TITLE,
  RMS_NAME_TITLE,
  TILE_ACCORDIONS_TITLE,
  UNIQ_PHONE_NUMBER_TITLE,
  ELIGIBILITY_TITLE,
  MARKET_TITLE,
  ACTIVITY_TAGS_TITLE
} from './types'

export const MENU_TABS = [
  {
    title: ACTIVITY_CATEGORY_TITLE,
    type: ACTIVITY_CATEGORY_TYPE
  },
  {
    title: ACTIVITY_TAGS_TITLE,
    type: ACTIVITY_TAGS_TYPE
  },
  {
    title: ACTIVITY_MODULE_TITLE,
    type: ACTIVITY_MODULE_TYPE
  },
  {
    title: LOCATIONS_TITLE,
    type: LOCATIONS_TYPE
  },
  {
    title: MARKET_TITLE,
    type: MARKET_TYPE
  },
  {
    title: PMS_NAME_TITLE,
    type: PMS_NAME_TYPE
  },
  {
    title: RMS_NAME_TITLE,
    type: RMS_NAME_TYPE
  },
  {
    title: TILE_ACCORDIONS_TITLE,
    type: TILE_ACCORDIONS_TYPE
  },
  {
    title: UNIQ_PHONE_NUMBER_TITLE,
    type: UNIQ_PHONE_NUMBER_TYPE
  },
  {
    title: ELIGIBILITY_TITLE,
    type: ELIGIBILITY_TYPE
  }
]
