/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import { actions as editQueue } from 'reducers/editQueue'
import { actions } from 'reducers/activityCategoriesTree'
import { formActions } from 'reducers/activityCategoryForm'
import { withAuthContext } from 'helpers/withAuthContext'

import { ActivityCategoryList as ActivityCategoryListComponent } from './ActivityCategoryList'

export const mapStateToProps = state => ({
  ...state.activityCategoriesTree,
  updateListDataStates: [
    state.activityCategoryForm.formDataState,
    state.activityCategoryForm.removeDataState,
    state.activityCategoryForm.updateDataState
  ]
})

export const mapDispatchToProps = dispatch => {
  const { getActivityCategoriesTree: onGetData, ...rest } = actions
  return bindActionCreators({ ...editQueue, ...formActions, onGetData, ...rest }, dispatch)
}

export const ActivityCategoryList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ActivityCategoryListComponent)
