/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */
import moment from 'moment'
import { FORMAT } from 'constants/date'
import get from 'lodash/get'
import isObject from 'lodash/isObject'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import { parseDecimals } from 'helpers/formHelpers'
import { SIGN_CURRENCY } from 'icons/char/CurrencyIcon/types'
import {
  BEGIN_END_DATE_SEASON_ERROR_KEY,
  NULL_ACTIVITIES_SEASONS_ERROR_KEY,
  INCORRECT_DATE_SEASON_ERROR_KEY
} from 'modules/Programs/ProgramSeasons/validationTypes'
import { STATUS_ACTIVE, STATUS_TESTING } from 'constants/entityStatus'
import { ORANGE_SCHEMA, LIGHT_GREEN_SCHEMA } from 'constants/colorSchemas'
import { isSmartHostStandaloneType } from 'modules/Programs/helpers'
import {
  NOT_INDICATED,
  BEGIN_END_MONTH_ERROR_MESSAGE,
  NULL_ACTIVITIES_ERROR_MESSAGE,
  INCCORECT_DATE_SEASON_ERROR_MESSAGE,
  UNKNOWN_ERROR_MESSAGE,
  LABEL_ACTIVITIES_ACTIVE,
  LABEL_ACTIVITIES_TESTING
} from './types'

export const isPastSeason = endDate => {
  const today = moment(new Date()).format(FORMAT)
  const momentTodayDate = moment(today, FORMAT)
  const momentEndDate = moment(endDate, FORMAT)

  return momentTodayDate.isAfter(momentEndDate)
}

export const isFutureSeason = startDate => {
  const today = moment(new Date()).format(FORMAT)
  const momentTodayDate = moment(today, FORMAT)
  const momentStartDate = moment(startDate, FORMAT)

  return momentStartDate.isAfter(momentTodayDate)
}

export const isCurrentSeason = (startDate, endDate) => {
  const today = moment(new Date()).format(FORMAT)
  const momentTodayDate = moment(today, FORMAT)
  const momentStartDate = moment(startDate, FORMAT)
  const momentEndDate = moment(endDate, FORMAT)

  return (
    momentTodayDate.isSameOrAfter(momentStartDate) && momentTodayDate.isSameOrBefore(momentEndDate)
  )
}

export const isInvalidSeason = (activities, endDate, errors, programType) =>
  (!isSmartHostStandaloneType(programType) && !isPastSeason(endDate) && activities.length === 0) ||
  errors.length > 0

export const getXplorieFee = value =>
  !value ? NOT_INDICATED : `${SIGN_CURRENCY} ${parseDecimals(value)}`

export const sortActivities = activities => sortBy(activities, [({ activity }) => activity.name])

export const buildErrorMessageByType = errorKey => {
  switch (errorKey) {
    case BEGIN_END_DATE_SEASON_ERROR_KEY:
      return BEGIN_END_MONTH_ERROR_MESSAGE
    case NULL_ACTIVITIES_SEASONS_ERROR_KEY:
      return NULL_ACTIVITIES_ERROR_MESSAGE
    case INCORRECT_DATE_SEASON_ERROR_KEY:
      return INCCORECT_DATE_SEASON_ERROR_MESSAGE
    default:
      return UNKNOWN_ERROR_MESSAGE
  }
}

/**
 *
 * @param {object} data - Program Season
 * @returns {object} key - error key , body - meta information
 */
export const validateSeason = (data = {}, programType) => {
  const errors = {}
  if (!isObject(data)) {
    return errors
  }
  const activities = get(data, 'activities', [])
  const startData = get(data, 'startDate')
  const endData = get(data, 'endDate')
  const momentStartDate = moment(startData, FORMAT)
  const momentEndData = moment(endData, FORMAT)
  if (!momentStartDate.isValid() || !momentEndData.isValid()) {
    errors[INCORRECT_DATE_SEASON_ERROR_KEY] = null
  }
  if (momentStartDate.isAfter(momentEndData)) {
    errors[BEGIN_END_DATE_SEASON_ERROR_KEY] = null
  }

  if (
    !isSmartHostStandaloneType(programType) &&
    !isPastSeason(endData) &&
    (!activities || !activities.length)
  ) {
    errors[NULL_ACTIVITIES_SEASONS_ERROR_KEY] = null
  }
  return Object.keys(errors).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        meta: { id: data.id, name: data.name },
        timestamp: +new Date(),
        message: buildErrorMessageByType(key)
      }
    }),
    {}
  )
}

export function prepareActivities(activities) {
  const groupActivities = groupBy(activities, 'activity.parameters.status')

  return [
    {
      label: LABEL_ACTIVITIES_ACTIVE,
      data: get(groupActivities, STATUS_ACTIVE, []),
      colorSchema: LIGHT_GREEN_SCHEMA
    },
    {
      label: LABEL_ACTIVITIES_TESTING,
      data: get(groupActivities, STATUS_TESTING, []),
      colorSchema: ORANGE_SCHEMA
    }
  ]
    .filter(({ data }) => data.length > 0)
    .map(({ data, ...rest }) => ({ ...rest, data: sortActivities(data) }))
}
