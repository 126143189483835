/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_COUNTRY_FORM,
  RESPONSE_COUNTRY_FORM,
  RESPONSE_COUNTRY_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_LOCATION,
  RESPONSE_LOCATION,
  RESPONSE_LOCATION_ERROR,
  REQUEST_LINK_CHILD_LOCATION,
  RESPONSE_LINK_CHILD_LOCATION,
  RESPONSE_LINK_CHILD_LOCATION_ERROR,
  REQUEST_CREATE_LOCATION_AND_LINK,
  RESPONSE_CREATE_LOCATION_AND_LINK,
  RESPONSE_CREATE_LOCATION_AND_LINK_ERROR,
  REQUEST_UPDATE_AND_ADD_CHILDREN,
  RESPONSE_UPDATE_AND_ADD_CHILDREN,
  RESPONSE_UPDATE_AND_ADD_CHILDREN_ERROR
} = helpers

export const initialState = {}

export const entityInitialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  countryDataState: DATA_STATE_NOT_REQUESTED,
  locationDataState: DATA_STATE_NOT_REQUESTED,
  locationData: null,
  linkLocationDataState: DATA_STATE_NOT_REQUESTED,
  updateAndLinkChildrenDataState: DATA_STATE_NOT_REQUESTED
}

const buildState = (state, action) => data => ({
  ...state,
  [action.payload.key]: { ...entityInitialState, ...state[action.payload.key], ...data }
})

const locationFormReducer = (state = initialState, action) => {
  const appendData = buildState(state, action)

  switch (action.type) {
    // create/update form
    case REQUEST_FORM:
      return appendData({ dataState: DATA_STATE_REQUESTING })
    case RESPONSE_FORM_ERROR:
      return appendData({ dataState: DATA_STATE_ERROR })
    case RESPONSE_FORM:
      return appendData({ dataState: DATA_STATE_RECIEVED, locationData: action.payload.data })
    case REQUEST_COUNTRY_FORM:
      return appendData({ countryDataState: DATA_STATE_REQUESTING })
    case RESPONSE_COUNTRY_FORM_ERROR:
      return appendData({ countryDataState: DATA_STATE_ERROR })
    case RESPONSE_COUNTRY_FORM:
      return appendData({ countryDataState: DATA_STATE_RECIEVED, locationData: action.payload.data })
    // get entity by id
    case REQUEST_LOCATION:
      return appendData({
        locationDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_LOCATION_ERROR:
      return appendData({
        locationDataState: DATA_STATE_ERROR
      })
    case RESPONSE_LOCATION:
      return appendData({
        locationDataState: DATA_STATE_RECIEVED,
        locationData: action.payload.data
      })
    // link one child
    case REQUEST_LINK_CHILD_LOCATION:
      return appendData({
        linkLocationDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_LINK_CHILD_LOCATION_ERROR:
      return appendData({
        linkLocationDataState: DATA_STATE_ERROR
      })
    case RESPONSE_LINK_CHILD_LOCATION:
      return appendData({
        linkLocationDataState: DATA_STATE_RECIEVED
      })
    // create and link
    case REQUEST_CREATE_LOCATION_AND_LINK:
      return appendData({
        linkLocationDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_CREATE_LOCATION_AND_LINK_ERROR:
      return appendData({
        linkLocationDataState: DATA_STATE_ERROR
      })
    case RESPONSE_CREATE_LOCATION_AND_LINK:
      return appendData({
        linkLocationDataState: DATA_STATE_RECIEVED
      })
    // update location and link children
    case REQUEST_UPDATE_AND_ADD_CHILDREN:
      return appendData({ updateAndLinkChildrenDataState: DATA_STATE_REQUESTING })
    case RESPONSE_UPDATE_AND_ADD_CHILDREN:
      return appendData({
        updateAndLinkChildrenDataState: DATA_STATE_RECIEVED,
        locationData: action.payload.data
      })
    case RESPONSE_UPDATE_AND_ADD_CHILDREN_ERROR:
      return appendData({ updateAndLinkChildrenDataState: DATA_STATE_ERROR })
    case RESET_DATA_STATE: {
      const { [action.payload.key]: removedState, ...restState } = state
      return {
        ...restState
      }
    }
    default:
      return state
  }
}

export default filterActions(locationFormReducer, [
  REQUEST_FORM,
  RESPONSE_FORM_ERROR,
  RESPONSE_FORM,
  REQUEST_COUNTRY_FORM,
  RESPONSE_COUNTRY_FORM,
  RESPONSE_COUNTRY_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_LOCATION,
  RESPONSE_LOCATION,
  RESPONSE_LOCATION_ERROR,
  REQUEST_LINK_CHILD_LOCATION,
  RESPONSE_LINK_CHILD_LOCATION,
  RESPONSE_LINK_CHILD_LOCATION_ERROR,
  REQUEST_CREATE_LOCATION_AND_LINK,
  RESPONSE_CREATE_LOCATION_AND_LINK,
  RESPONSE_CREATE_LOCATION_AND_LINK_ERROR,
  REQUEST_UPDATE_AND_ADD_CHILDREN,
  RESPONSE_UPDATE_AND_ADD_CHILDREN,
  RESPONSE_UPDATE_AND_ADD_CHILDREN_ERROR
])
