/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { asField, BasicText } from 'informed'
import { Field } from 'components/FormComponents'
import { NO_IMAGE_URL } from './types'

class ImageInputWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      previewUrl: ''
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fieldState: { value }
    } = this.props
    if (prevProps.fieldState.value !== value) {
      this.setState({ previewUrl: value })
    }
  }

  onErrorPreviewHandler = () => {
    const { previewUrl } = this.state
    if (previewUrl !== NO_IMAGE_URL) {
      this.setState({ previewUrl: NO_IMAGE_URL })
    }
  }

  render() {
    const {
      fieldState: { error, value },
      fieldState,
      fieldCommonProps,
      label,
      isRequired,
      fieldProps,
      children,
      id,
      ...rest
    } = this.props
    const { previewUrl } = this.state

    return (
      <Field id={id} label={label} isRequired={isRequired} error={error} {...fieldProps}>
        <BasicText {...rest} id={id} fieldState={fieldState} />
        {!error && value && (
          <img
            src={previewUrl}
            height="200px"
            width="200px"
            vspace="10"
            onError={this.onErrorPreviewHandler}
          />
        )}
        {children}
      </Field>
    )
  }
}

ImageInputWrapper.propTypes = {
  fieldApi: PropTypes.object.isRequired,
  children: PropTypes.object,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  fieldState: PropTypes.object,
  fieldCommonProps: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  fieldProps: PropTypes.object,
  id: PropTypes.string
}

ImageInputWrapper.defaultProps = {
  onChange: () => null,
  onFocus: () => null,
  children: null,
  fieldState: {},
  label: null,
  isRequired: false,
  fieldProps: null,
  fieldCommonProps: null,
  id: null
}

export const ImageInput = asField(ImageInputWrapper)
