/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'

import withAuthorization from 'helpers/withAuthorization'
import { ContentWithNav } from 'components/ContentWithNav'
import { HeaderWrapper } from 'components/Wrappers'
import { HomeSearch } from 'modules/HomeSearch'

import { TITLE_PAGE } from './types'
import styles from './home.scss'

function Home({ match }) {
  return (
    <ContentWithNav>
      <section className={styles.customWrapper}>
        <HeaderWrapper title={TITLE_PAGE} />
        <div className={styles.containerWrapper}>
          <HomeSearch match={match} />
        </div>
      </section>
    </ContentWithNav>
  )
}

Home.propTypes = {
  match: PropTypes.object.isRequired
}

export default withAuthorization(Home)
