/*
 * COPYRIGHT:     Copyright © 2019-2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import loadable from '@loadable/component'
import { Route, Redirect, Switch } from 'react-router-dom'
import {
  READ_LEVEL,
  APPROVE_LEVEL,
  ADMIN_LEVEL,
  VAC_SPECIALIST_LEVEL,
  USER_LEVEL,
  UNIT_TASKS_MANAGE_LEVEL
} from 'constants/permissionTypes'
import Home from 'containers/Home'
import AdminHome from 'containers/AdminHome'
import MyWork from 'containers/MyWork'
import Login from 'containers/Login'
import PMC from 'containers/PMCs'
import ActivityProviders from 'containers/ActivityProviders'
import ActivityProducts from 'containers/ActivityProducts'
import Campaigns from 'containers/Campaigns'
import Programs from 'containers/Programs/Programs'
import Profile from 'containers/Profile'
import Accounts from 'containers/Accounts'
import Reports from 'containers/Reports'
import NotFoundPage from 'containers/NotFoundPage'
import Library from 'containers/Library'
import UnitTasks from 'containers/UnitTasks'
import { Discounts } from 'containers/Discounts'
import { DEFAULT_PIE_REDIRECT_URL, DEFAULT_HOME_REDIRECT_URL } from 'services/auth'
import { PasswordRecovery } from 'modules/Password/PasswordRecovery'
import { HomeRoute, PrivateRoute } from './RoutesBucket'
import {
  APP_PROGRAMS_PATH,
  SEARCH_PATH_NAME,
  PROGRAMS_BY_PMC_PATH,
  PROGRAMS_BY_MARKET_PATH,
  APPS_BY_MARKET_PATH,
  ACTIVITY_PRODUCTS_PATH,
  PROGRAMS_BY_LOCATION_PATH,
  MY_WORK_PATH,
  CAMPAIGNS_PATH,
  APPS_BY_AP_PATH,
  APS_BY_ACTIVITY_CATEGORY_PATH,
  APPS_BY_ACTIVITY_CATEGORY_PATH,
  APPS_BY_ACTIVITY_TAG_PATH,
  APS_BY_ACTIVITY_TAG_PATH,
  PROGRAMS_BY_DESTINATION_PATH,
  APPS_BY_DESTINATION_PATH
} from './types'

const AppsByMarket = loadable(() =>
  import(
    /* webpackChunkName: 'AppsByMarket', webpackPrefetch: true */ 'containers/ActivityProducts/AppsByMarket'
  )
)
const AppsByActivityProvider = loadable(() =>
  import(
    /* webpackChunkName: 'AppsByActivityProvider', webpackPrefetch: true */ 'containers/ActivityProducts/AppsByActivityProvider'
  )
)
const AppsByDestination = loadable(() =>
  import(
    /* webpackChunkName: 'AppsByDestination', webpackPrefetch: true */ 'containers/ActivityProducts/AppsByDestination'
  )
)
const PreviousPrograms = loadable(() =>
  import(
    /* webpackChunkName: 'PreviousPrograms', webpackPrefetch: true */ 'containers/Programs/PreviousPrograms'
  )
)
const ProgramsByApp = loadable(() =>
  import(
    /* webpackChunkName: 'ProgramsByApp', webpackPrefetch: true */ 'containers/Programs/ProgramsByApp'
  )
)
const ProgramsByPmc = loadable(() =>
  import(
    /* webpackChunkName: 'ProgramsByPmc', webpackPrefetch: true */ 'containers/Programs/ProgramsByPmc'
  )
)
const ProgramsByMarket = loadable(() =>
  import(
    /* webpackChunkName: 'ProgramsByMarket', webpackPrefetch: true */ 'containers/Programs/ProgramsByMarket'
  )
)
const ProgramsByLocation = loadable(() =>
  import(
    /* webpackChunkName: 'ProgramsByLocation', webpackPrefetch: true */ 'containers/Programs/ProgramsByLocation'
  )
)
const ProgramsByDestination = loadable(() =>
  import(
    /* webpackChunkName: 'ProgramsByDestination', webpackPrefetch: true */ 'containers/Programs/ProgramsByDestination'
  )
)
const DiscountsByApp = loadable(
  () =>
    import(
      /* webpackChunkName: 'DiscountsByApp', webpackPrefetch: true */ 'containers/Discounts/DiscountsByApp'
    ),
  {
    resolveComponent: components => components.DiscountsByApp
  }
)
const ApsByActivityCategory = loadable(() =>
  import(
    /* webpackChunkName: 'ApsByActivityCategory', webpackPrefetch: true */ 'containers/ActivityProviders/ApsByActivityCategory'
  )
)
const AppsByActivityCategory = loadable(() =>
  import(
    /* webpackChunkName: 'AppsByActivityCategory', webpackPrefetch: true */ 'containers/ActivityProducts/AppsByActivityCategory'
  )
)
const AppsByActivityTag = loadable(() =>
  import(
    /* webpackChunkName: 'AppsByActivityTag', webpackPrefetch: true */ 'containers/ActivityProducts/AppsByActivityTag'
  )
)
const ApsByActivityTag = loadable(() =>
  import(
    /* webpackChunkName: 'ApsByActivityTag', webpackPrefetch: true */ 'containers/ActivityProviders/ApsByActivityTag'
  )
)

export default function Routes() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <HomeRoute path="/home" component={AdminHome} />
      <PrivateRoute path={SEARCH_PATH_NAME} component={Home} accessLevels={[READ_LEVEL]} />
      <PrivateRoute path={MY_WORK_PATH} component={MyWork} accessLevels={[READ_LEVEL]} />
      <PrivateRoute
        path="/admin/unit-tasks"
        component={UnitTasks}
        accessLevels={[UNIT_TASKS_MANAGE_LEVEL]}
      />
      <PrivateRoute
        path="/admin/property-management-companies"
        component={PMC}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        path="/admin/activity-providers"
        component={ActivityProviders}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute path={CAMPAIGNS_PATH} component={Campaigns} accessLevels={[READ_LEVEL]} />
      <PrivateRoute
        exact
        path={ACTIVITY_PRODUCTS_PATH}
        component={ActivityProducts}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path={`${ACTIVITY_PRODUCTS_PATH}/:id`}
        component={ActivityProducts}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path={`${APPS_BY_MARKET_PATH}:id`}
        component={AppsByMarket}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path={`${APPS_BY_ACTIVITY_CATEGORY_PATH}:id`}
        component={AppsByActivityCategory}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path={`${APPS_BY_ACTIVITY_TAG_PATH}:id`}
        component={AppsByActivityTag}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path={`${APS_BY_ACTIVITY_TAG_PATH}:id`}
        component={ApsByActivityTag}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path="/admin/programs/"
        component={Programs}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path={`${PROGRAMS_BY_MARKET_PATH}:id`}
        component={ProgramsByMarket}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path={`${APS_BY_ACTIVITY_CATEGORY_PATH}:id`}
        component={ApsByActivityCategory}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path={`${APPS_BY_AP_PATH}:id`}
        component={AppsByActivityProvider}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path={`${APPS_BY_DESTINATION_PATH}:id`}
        component={AppsByDestination}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path={`${PROGRAMS_BY_LOCATION_PATH}:id`}
        component={ProgramsByLocation}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path={`${PROGRAMS_BY_PMC_PATH}:id`}
        component={ProgramsByPmc}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path={`${PROGRAMS_BY_DESTINATION_PATH}:id`}
        component={ProgramsByDestination}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path="/admin/programs/previous/"
        component={PreviousPrograms}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        exact
        path={APP_PROGRAMS_PATH}
        component={ProgramsByApp}
        accessLevels={[READ_LEVEL]}
      />
      <PrivateRoute
        path="/admin/profile"
        component={Profile}
        accessLevels={[READ_LEVEL, VAC_SPECIALIST_LEVEL]}
      />
      <PrivateRoute
        path="/resale/profile"
        component={Profile}
        accessLevels={[VAC_SPECIALIST_LEVEL]}
      />
      <PrivateRoute path="/profile" component={Profile} accessLevels={[READ_LEVEL]} />
      <PrivateRoute path="/admin/accounts" component={Accounts} accessLevels={[ADMIN_LEVEL]} />

      <PrivateRoute
        path="/admin/reports"
        component={Reports}
        accessLevels={[ADMIN_LEVEL, APPROVE_LEVEL, USER_LEVEL]}
      />
      <PrivateRoute path="/admin/library" component={Library} accessLevels={[READ_LEVEL]} />

      <PrivateRoute
        path="/admin/deals"
        component={Discounts}
        accessLevels={[APPROVE_LEVEL]}
        exact
      />
      <PrivateRoute
        path="/admin/deals/:id"
        component={DiscountsByApp}
        accessLevels={[APPROVE_LEVEL]}
      />

      <Route exact path="/password/recovery/:recoveryToken" component={PasswordRecovery} />
      <Route exact path="/admin" render={() => <Redirect to={DEFAULT_PIE_REDIRECT_URL} />} />
      <Route exact path="/" render={() => <Redirect to={DEFAULT_HOME_REDIRECT_URL} />} />
      <Route component={NotFoundPage} />
    </Switch>
  )
}
