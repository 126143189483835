/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { READ_LEVEL, ADMIN_LEVEL } from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import get from 'lodash/get'
import find from 'lodash/find'
import {
  STATE_TYPE,
  DESTINATION_TYPE,
  COUNTRY_TYPE,
  LOCATIONS_TYPES_RELEVANCE
} from 'constants/locationsTypes'
import { buildSearchColumn, LOCATION, AND_OPERATOR } from 'helpers/searchHelpers'
import { STATE_LABEL, DESTINATION_LABEL, COUNTRY_LABEL } from './types'

export function canSeeMarketingTab(authUser, location) {
  const canRead = isActionAllowed(READ_LEVEL, authUser)
  const locationType = get(location, 'type')
  return canRead && locationType === DESTINATION_TYPE
}

export function canUpdateMarketing(authUser) {
  const isAdmin = isActionAllowed(ADMIN_LEVEL, authUser)
  return isAdmin
}

export function canUpdateLocation(authUser) {
  const canUpdate = isActionAllowed(ADMIN_LEVEL, authUser)
  return canUpdate
}

export const getLocationOptionByType = type => {
  switch (type) {
    case COUNTRY_TYPE:
      return { label: COUNTRY_LABEL, value: COUNTRY_TYPE }
    case STATE_TYPE:
      return { label: STATE_LABEL, value: STATE_TYPE }
    case DESTINATION_TYPE:
      return { label: DESTINATION_LABEL, value: DESTINATION_TYPE }
    // CITY_TYPE and DISTRICT_TYPE are obsolete
    // case CITY_TYPE:
    //   return { label: CITY_LABEL, value: CITY_TYPE }
    // case DISTRICT_TYPE:
    //   return { label: DISTRICT_LABEL, value: DISTRICT_TYPE }
    default:
      return {}
  }
}

export const buildLocationTypeOptions = (parentType, children = []) => {
  const childrenMaxRelevance = children.length
    ? Math.max(...children.map(({ location }) => LOCATIONS_TYPES_RELEVANCE[location.type]))
    : 0
  const parentRelevance = parentType ? LOCATIONS_TYPES_RELEVANCE[parentType] : Infinity

  return Object.keys(LOCATIONS_TYPES_RELEVANCE)
    .filter(
      key =>
        LOCATIONS_TYPES_RELEVANCE[key] < parentRelevance &&
        LOCATIONS_TYPES_RELEVANCE[key] > childrenMaxRelevance
    )
    .map(key => getLocationOptionByType(key))
}

export const buildFilterParams = (allowedOptions = []) => ({ keyword }) => [
  buildSearchColumn({ entity: LOCATION, field: 'name', keyword }),
  ...Object.keys(LOCATIONS_TYPES_RELEVANCE)
    .filter(value => !find(allowedOptions, ['value', value]))
    .map(type =>
      buildSearchColumn({
        entity: LOCATION,
        field: 'type',
        keyword: type,
        ignore: true,
        operator: AND_OPERATOR
      })
    )
]
