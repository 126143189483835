/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function TicketIcon(props) {
  return (
    <svg viewBox="0 0 144 144" {...props}>
      <path
        d="M13.2,84.5c-1.8,1.8-1.8,4.8,0,6.6l12.6,12.6c0.9,0.9,2.2,1.4,3.5,1.4c1.3-0.1,2.5-0.7,3.4-1.7
			c0.2-0.2,0.3-0.4,0.5-0.5c2.8-2.8,7.4-2.8,10.2,0c2.8,2.8,2.8,7.4,0,10.2c-0.2,0.2-0.4,0.3-0.5,0.5c-1,0.8-1.6,2.1-1.7,3.4
			c-0.1,1.3,0.4,2.6,1.4,3.5l12.6,12.6c0.9,0.9,2.1,1.4,3.3,1.4c1.2,0,2.4-0.5,3.3-1.4L111,83.9L62.4,35.3L13.2,84.5z"
      />
      <path d="M135.4,52.9l-12.6-12.6c-1.8-1.8-4.8-1.8-6.6,0c-2.8,2.8-7.4,2.8-10.2,0c-2.8-2.8-2.8-7.4,0-10.2 c1.8-1.8,1.8-4.8,0-6.6L93.4,10.9c-1.8-1.8-4.8-1.8-6.6,0L69.1,28.6l48.6,48.6l17.7-17.7C137.2,57.7,137.2,54.7,135.4,52.9z" />
    </svg>
  )
}
