/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { ACTIVITY_CATEGORY_TYPE, MARKET_TYPE } from 'containers/Library/types'
import {
  LibraryTabContent,
  MarketContent,
  ActivityCategoryContent,
} from 'modules/Library'

export const getComponentByType = type => {
  switch (type) {
    case ACTIVITY_CATEGORY_TYPE:
      return ActivityCategoryContent
    case MARKET_TYPE:
      return MarketContent
    default:
      return LibraryTabContent
  }
}
