/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

export function useAction(action) {
  const dispatch = useDispatch()
  return useCallback((...args) => dispatch(action(...args)), [action, dispatch])
}
