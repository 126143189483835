/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const COUNTRIES_TREE = 'COUNTRIES_TREE_STATE'

export default {
  COUNTRIES_TREE
}
