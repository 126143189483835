/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { searchByColumns } from 'services/http/search'
import { getOnboardedProgramsList } from 'services/http/program'
import { ON_BOARDING_TYPE } from 'containers/MyWork/types'
import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST, RESET_DATA_STATE, ENTITY_TYPE } = helpers

const getList = (key, options = {}, contractData = []) => async dispatch => {
  const requestOptions = {
    ...options,
    type: ENTITY_TYPE
  }
  dispatch(actionCreater(REQUEST_LIST, { key }))
  let request
  try {
    switch (key) {
      case ON_BOARDING_TYPE:
        request = await getOnboardedProgramsList(options)
        break
      default:
        request = await searchByColumns(contractData, requestOptions)
        break
    }
    dispatch(
      actionCreater(RESPONSE_LIST, {
        key,
        ...request.data
      })
    )
    return 'getList success'
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST, { key }))
  }
  return 'getList failed'
}
const resetList = () => ({
  type: RESET_DATA_STATE
})
export default {
  getList,
  resetList
}
