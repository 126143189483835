/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { combineReducers } from 'redux'

import columnSearch from './columnSearch'
import keywordSearch from './keywordSearch'

export default combineReducers({
  columnSearch,
  keywordSearch
})
