/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './BoxLabel.scss'

export function BoxLabel(props) {
  const { children, className } = props
  return <h3 className={classNames(styles.boxTitle, className)}>{children}</h3>
}

BoxLabel.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
}
BoxLabel.defaultProps = {
  children: null,
  className: ''
}
