/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getRateAdjustmentList, postRateAdjustmentList } from 'services/http/programAdjustments'

import {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_LIST_ERROR,
  RESET_DATA_STATE,
  RATE_MODE
} from './helpers'

import { SUCCESS_RATE_ADJUSTMENT_MESSAGE } from './types'

export const setRateMode = mode => dispatch => {
  dispatch(
    actionCreater(RATE_MODE, {
      mode
    })
  )

  return 'setRateMode success'
}

export const fetchList = (id, programId) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const response = await getRateAdjustmentList(id, programId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_LIST_ERROR))
  })

  if (response) {
    dispatch(
      actionCreater(RESPONSE_LIST, {
        rates: response.data.map(item => ({
          ...item,
          editable: true
        }))
      })
    )
    return 'getAdjustmentList success'
  }

  return 'getAdjustmentList failed'
}

export const saveList = (id, programId, data, withNotif = true) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await postRateAdjustmentList(id, programId, data).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_LIST_ERROR))
  })

  if (request) {
    const {
      message: { message, send }
    } = request
    if (withNotif) {
      dispatch(send({ ...message, message: SUCCESS_RATE_ADJUSTMENT_MESSAGE }))
    }
    dispatch(fetchList(id, programId))
    return 'postRateAdjustmentList success'
  }

  return 'postRateAdjustmentList failed'
}

export const resetData = () => ({
  type: RESET_DATA_STATE
})
