/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useAction, useDidMount, useLoading } from 'hooks'
import { useSelector } from 'react-redux'
import { selectors, actions } from 'connectors/reducers/closures/closuresList'
import {
  actions as formActions,
  selectors as formSelectors
} from 'connectors/reducers/closures/closuresForm'
import {
  actions as scheduleActions,
  selectors as scheduleSelectors
} from 'connectors/reducers/schedules/schedulesForm'
import { mapToEvents } from 'modules/Closures'

export function useCalendarPagePresenter({ id }) {
  const { dataState, data } = useSelector(state => selectors.selectStateById(state, id))
  const closureFormState = useSelector(state => formSelectors.getClosureStateById(state, id))
  const scheduleFormState = useSelector(state => scheduleSelectors.getScheduleStateById(state, id))
  const [isLoading] = useLoading(dataState)
  const [isDeletingSeries] = useLoading(closureFormState.deleteSeriesDataState)
  const [isDeletingOccurrence] = useLoading(closureFormState.deleteOccurrenceDataState)
  const [isCancelingSeries] = useLoading(scheduleFormState.cancelSeriesDataState)
  const [isCancelingOccurrence] = useLoading(scheduleFormState.cancelOccurrenceDataState)
  const [isActivatingSeries] = useLoading(scheduleFormState.activateSeriesDataState)
  const [isActivatingOccurrence] = useLoading(scheduleFormState.activateOccurrenceDataState)
  const getListById = useAction(actions.getClosuresByPeriod)
  const resetList = useAction(actions.resetList)
  const resetFormState = useAction(formActions.resetState)
  const deleteSeries = useAction(formActions.deleteSeriesById)
  const cancelSeries = useAction(scheduleActions.cancelSeriesById)
  const cancelOccurrence = useAction(scheduleActions.cancelOccurrenceById)
  const deleteOccurrence = useAction(formActions.deleteOccurrenceByIdAndDay)
  const deleteOccurrences = useAction(formActions.deleteOccurrencesByIdAndArray)
  const activateSeries = useAction(scheduleActions.activateSeriesById)
  const activateOccurrence = useAction(scheduleActions.activateOccurrenceById)

  const showLoading =
    isLoading ||
    isDeletingSeries ||
    isDeletingOccurrence ||
    isCancelingSeries ||
    isCancelingOccurrence ||
    isActivatingSeries ||
    isActivatingOccurrence

  useDidMount(() => () => {
    resetList(id)
    resetFormState(id)
  })

  const handleFetch = ({ start, end }) =>
    getListById(id, {
      start,
      end
    })

  const handleDeleteSeries = (seriesId, successMessage, errorMessage) =>
    deleteSeries(id, seriesId, successMessage, errorMessage)
  const handleDeleteOccurrence = (seriesId, day, successMessage, errorMessage) =>
    deleteOccurrence(id, seriesId, day, successMessage, errorMessage)
  const handleDeleteOccurrences = occurrences => deleteOccurrences(id, occurrences)
  const handleCancelSeries = (seriesId, payload) => cancelSeries(id, seriesId, payload)
  const handleCancelOccurrence = payload => cancelOccurrence(id, payload)
  const handleActivateSeries = (seriesId, payload) => activateSeries(id, seriesId, payload)
  const handleActivateOccurrence = payload => activateOccurrence(id, payload)

  return {
    fetch: handleFetch,
    data: mapToEvents(data),
    loading: showLoading,
    id,
    series: {
      onDelete: handleDeleteSeries,
      onCancel: handleCancelSeries,
      onActivate: handleActivateSeries
    },
    occurrence: {
      onDelete: handleDeleteOccurrence,
      onCancel: handleCancelOccurrence,
      onActivate: handleActivateOccurrence
    },
    occurrences: {
      onDelete: handleDeleteOccurrences
    }
  }
}
