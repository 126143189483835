/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import {
  actions as appProgramsActions,
  getProgramsListStateByAppId
} from 'reducers/program/appPrograms'

import { AppDeactivationModal as AppDeactivationComponent } from './AppDeactivationModal'

export const mapStateToProps = (state, { dataId }) => {
  const programsByAppState = getProgramsListStateByAppId(state, dataId)

  return {
    appPrograms: programsByAppState.data,
    appProgramDataState: programsByAppState.dataState
  }
}

export const mapDispatchToProps = dispatch => {
  const { getAppProgramsById } = appProgramsActions

  return bindActionCreators({ getAppProgramsById }, dispatch)
}

export const AppDeactivationModal = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AppDeactivationComponent)
