/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const ActivityModuleForm = loadable(
  () =>
    import(
      /* webpackChunkName: 'ActivityModuleForm', webpackPrefetch: true */ './ActivityModuleForm'
    ),
  {
    resolveComponent: components => components.ActivityModuleForm
  }
)

export { ActivityModuleForm }
export { ActivityModuleList } from './ActivityModuleList'
