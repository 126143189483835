/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { SET_DEACTIVATION_MODE } from 'modules/ActivityProducts/DelayedDeactivation/types'

import { getComponentByStep } from './helpers'

import { STEPS_COUNT } from './types'

export function AppDeactivation(props) {
  const { onSubmit, onHide, stepsCount, initialStep, mode } = props
  const [step, setStep] = useState(initialStep)
  const Modal = useMemo(() => getComponentByStep(step), [step])

  const onConfirmAction = useCallback(
    formValues => {
      if (step < stepsCount) {
        setStep(step + 1)
      } else if (onSubmit) {
        onSubmit(formValues)
        setStep(initialStep)
      }
    },
    [onSubmit, step, stepsCount, initialStep]
  )

  const onHideAction = useCallback(() => {
    if (onHide) {
      onHide()
    }
    setStep(initialStep)
  }, [onHide, initialStep])

  return <Modal {...props} mode={mode} onClose={onHideAction} onSubmit={onConfirmAction} />
}

AppDeactivation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  stepsCount: PropTypes.number,
  initialStep: PropTypes.number,
  mode: PropTypes.string
}

AppDeactivation.defaultProps = {
  initialStep: 1,
  stepsCount: STEPS_COUNT,
  mode: SET_DEACTIVATION_MODE
}
