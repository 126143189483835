/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { GearIcon } from 'icons'
import { SliderRoundedLabel } from 'modules/SliderContainer'
import styles from './DeactivationInfo.scss'

export function DeactivationInfo({ message, date, onClick, showIcon, disabled }) {
  const onClickHandler = useCallback(
    event => {
      if (onClick && !disabled) {
        onClick(event)
      }
    },
    [disabled, onClick]
  )

  return (
    <SliderRoundedLabel>
      <span className={styles.message}>
        <span className={styles.text}>{message}</span>
        <span className={styles.date}>{date}</span>
      </span>
      {showIcon && (
        <span className={styles.iconWrapper}>
          <GearIcon
            className={classNames(styles.icon, { [styles.disabled]: disabled })}
            onClick={onClickHandler}
          />
        </span>
      )}
    </SliderRoundedLabel>
  )
}
DeactivationInfo.propTypes = {
  message: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
  disabled: PropTypes.bool
}
DeactivationInfo.defaultProps = {
  showIcon: true,
  disabled: false
}
