/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getProgramHistoryById, getProgramHistoryYears } from 'services/http/programHistory'

import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_GET_DATA,
  RESPONSE_GET_DATA,
  RESPONSE_GET_DATA_ERROR,
  REQUEST_GET_YEARS,
  RESPONSE_GET_YEARS,
  RESPONSE_GET_YEARS_ERROR
} = helpers

const getHistoryById = (id, year, params, data) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_GET_DATA, { key, year }))
  const request = await getProgramHistoryById(id, year, params, data).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_DATA_ERROR, { key, year }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_DATA, { key, year, data: request.data }))
    return 'getHistoryById success'
  }
  return 'getHistoryById failed'
}

const getHistoryYears = (id, params) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_GET_YEARS, { key }))
  const request = await getProgramHistoryYears(id, params).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_YEARS_ERROR, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_YEARS, { key, data: request.data }))
    return 'getHistoryById success'
  }
  return 'getHistoryById failed'
}

const resetHistory = key => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key }))

export default {
  getHistoryById,
  getHistoryYears,
  resetHistory
}
