/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const ADD_MODAL_TITLE = 'Add Closure'
export const EDIT_MODAL_TITLE = 'Edit Closure'

export const FULL_DAY_TIME_PERIOD = { startTime: '12:00 AM', endTime: '11:59 PM' }
export const OVERRIDE_SCHEDULE_MESSAGE =
  'Do you really want to submit Closure? The Schedules in the following period will be canceled!'
