/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import {
  actions,
  getProgramLifeCycleProcessState
} from 'connectors/reducers/programLifeCycleProcess'
import { getProgramDataState, getPmcDataByProgramDataState } from 'reducers/programsForm'
import { withAuthContext } from 'helpers/withAuthContext'

import { DelayedDeactivation as DelayedDeactivationComponent } from './DelayedDeactivation'

const mapStateToProps = (state, props) => {
  const { dataId } = props
  const programFormState = getProgramDataState(state, dataId)
  const programLifeCycleState = getProgramLifeCycleProcessState(state, dataId)
  const pmCompany = getPmcDataByProgramDataState(programFormState)

  return {
    programData: programFormState.programData,
    programDataState: programFormState.programDataState,
    deactivateProgramDataState: programLifeCycleState.deactivateProgramDataState,
    pmCompany
  }
}

const mapDispatchToProps = dispatch => {
  const { deactivateProgramAction, deleteProgramDelayDeactivate } = actions
  return bindActionCreators({ deactivateProgramAction, deleteProgramDelayDeactivate }, dispatch)
}

export const DelayedDeactivation = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DelayedDeactivationComponent)
