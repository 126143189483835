/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const LIST_NAME = 'PROGRAM_UNITS_LIST'
const SMART_HOST_STATUS = `${LIST_NAME}_SMART_HOST_STATUS`
const EXPORT_LIST = `${LIST_NAME}_EXPORT_LIST`

export default {
  LIST_NAME,
  EXPORT_LIST,
  SMART_HOST_STATUS
}
