/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { LIST_NAME, EXPORT_LIST, SMART_HOST_STATUS } = types

const REQUEST_LIST = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`

const REQUEST_EXPORT_LIST = `${REQUEST_TYPE}_${EXPORT_LIST}`
const RESPONSE_EXPORT_LIST = `${RESPONSE_TYPE}_${EXPORT_LIST}`
const RESPONSE_EXPORT_LIST_ERROR = `${RESPONSE_ERROR_TYPE}_${EXPORT_LIST}`

const REQUEST_SMART_HOST_STATUS = `${REQUEST_TYPE}_${SMART_HOST_STATUS}`
const RESPONSE_SMART_HOST_STATUS = `${RESPONSE_TYPE}_${SMART_HOST_STATUS}`
const RESPONSE_SMART_HOST_STATUS_ERROR = `${RESPONSE_ERROR_TYPE}_${SMART_HOST_STATUS}`

const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  REQUEST_EXPORT_LIST,
  RESPONSE_EXPORT_LIST,
  RESPONSE_EXPORT_LIST_ERROR,
  RESET_DATA_STATE,
  REQUEST_SMART_HOST_STATUS,
  RESPONSE_SMART_HOST_STATUS,
  RESPONSE_SMART_HOST_STATUS_ERROR
}
