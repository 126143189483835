/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'
import { actions, selectors } from 'reducers/marketDictionary/marketDictionaryForm'

import { DeleteButton as DeleteButtonComponent } from './DeleteButton'

const { getMarketFormStateById } = selectors
const { deleteMarketById } = actions

function mapStateToProps(state) {
  const entityState = getMarketFormStateById(state)
  return {
    dataState: entityState.deleleDataState
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ deleteMarketById }, dispatch)
}

export const DeleteButton = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DeleteButtonComponent)
