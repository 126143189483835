/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import { canDeleteUniqPhoneNumber } from 'modules/UniqPhoneNumber'
import { DeleteEntityButton } from 'components/Buttons'
import ModalRemoval from 'modules/ModalRemoval'
import { ORANGE_SCHEMA, SMALLER_SIZE, DELETE_TITLE } from 'constants/buttonsOptions'

export function DeleteButton(props) {
  const { deleteAction, authUser, data } = props
  const [showModal, setShowModalState] = useState(false)

  const onShowModal = () => {
    setShowModalState(true)
  }

  const onCloseModal = () => {
    setShowModalState(false)
  }

  const onDeleteHandler = id => () => {
    deleteAction(id)
    onCloseModal()
  }

  const id = get(data.original, 'id')
  const canDelete = canDeleteUniqPhoneNumber(authUser) && id

  return (
    <>
      <DeleteEntityButton
        colorSchema={ORANGE_SCHEMA}
        size={SMALLER_SIZE}
        disabled={!canDelete}
        onClick={onShowModal}
      >
        {DELETE_TITLE}
      </DeleteEntityButton>
      <ModalRemoval
        actionCallback={onDeleteHandler(id)}
        cancelCallback={onCloseModal}
        originalData={showModal}
      />
    </>
  )
}

DeleteButton.propTypes = {
  data: PropTypes.object,
  authUser: PropTypes.object,
  deleteAction: PropTypes.func.isRequired
}

DeleteButton.defaultProps = {
  data: {},
  authUser: null
}
