/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import groupBy from 'lodash/groupBy'
import get from 'lodash/get'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { FORMAT } from 'constants/date'
import isObject from 'lodash/isObject'
import {
  NUMBER_TYPE,
  DATE_TYPE,
  EQUALS_MODE,
  NOT_EQUALS,
  CONTAINS,
  NOT_CONTAINS,
  PERIOD
} from 'constants/eligibility'
import { getNumberRange } from './NumberField/NumberPeriod'
import { getDateRange } from './DateField/DatePeriod'

import {
  MODE_INVALID_MESSAGE,
  NUMBERS_INTERSECTION_MESSAGE,
  DATES_INTERSECTION_MESSAGE
} from './types'

const moment = extendMoment(Moment)

function filterSameParameters(values, parameterId) {
  return Object.values(values).filter(value => value.parameter.id === parameterId)
}

export function numberPeriodsValidation(parameters = []) {
  const compared = parameters
    .filter(parameter => Boolean(parameter.value))
    .map(parameter => parameter.value)
    .map(getNumberRange)
    .sort((a, b) => a.start - b.start)
    .reduce(
      (acc, range, index) => {
        if (acc.hasError) {
          return acc
        }
        const result = { ...acc, compare: range }
        if (index !== 0) {
          const compareRange = acc.compare
          if (range.start <= compareRange.end) {
            result.hasError = !result.hasError
          }
        }
        return result
      },
      { compare: null, hasError: false }
    )

  return compared.hasError ? NUMBERS_INTERSECTION_MESSAGE : null
}

export function datePeriodsValidation(currentValue, parameters = []) {
  const { start, end } = getDateRange(currentValue.value)
  const currentRange = moment.range(moment(start, FORMAT), moment(end, FORMAT))

  return parameters
    .filter(value => value.id !== currentValue.id)
    .map(value => value.value)
    .find(value => {
      const dates = getDateRange(value)
      const range = moment.range(moment(dates.start, FORMAT), moment(dates.end, FORMAT))
      return currentRange.overlaps(range, { adjacent: true })
    })
    ? DATES_INTERSECTION_MESSAGE
    : null
}

export function periodsValidation(values, parameter) {
  if (!values) {
    throw new Error('values is mandatory')
  }
  if (!parameter) {
    throw new Error('parameter is mandatory')
  }
  const type = get(parameter, 'parameter.type')
  const parameterId = get(parameter, 'parameter.id')
  const sameParameters = filterSameParameters(values, parameterId)

  switch (type) {
    case DATE_TYPE:
      return datePeriodsValidation(parameter, sameParameters)
    case NUMBER_TYPE:
      return numberPeriodsValidation(sameParameters)
    default:
      // eslint-disable-next-line no-console
      console.warn(`Period validation do not support for ${type}`)
      return null
  }
}

function sameGroup(value) {
  switch (value.mode) {
    case CONTAINS:
    case EQUALS_MODE:
    case PERIOD:
      return EQUALS_MODE
    case NOT_CONTAINS:
    case NOT_EQUALS:
      return NOT_EQUALS
    default:
      return value.mode
  }
}

export function modeValidation(values, parameter) {
  if (!values) {
    throw new Error('values is mandatory')
  }
  if (!parameter) {
    throw new Error('parameter is mandatory')
  }

  const type = get(parameter, 'parameter.type')
  const parameterId = get(parameter, 'parameter.id')

  if (!type || !parameterId) {
    throw new Error('parameter should have type and id')
  }

  const sameParameters = filterSameParameters(values, parameterId)

  // if we have just one parameter, mode is valid
  if (sameParameters.length === 1) {
    return null
  }
  const typeGroups = groupBy(sameParameters, sameGroup)
  return Object.keys(typeGroups).length > 1 ? MODE_INVALID_MESSAGE : null
}

/**
 *
 * @param {Object} values
 * @param {Object} errors
 * @returns {Object} new errors
 */
export default function validate(values, errors) {
  if (!isObject(values) || !Object.keys(values).length) {
    return {}
  }
  return Object.keys(values).reduce((acc, key) => {
    const value = values[key]
    const currentErrors = errors[key]
    const fieldErrors = { ...currentErrors }

    // mode validation
    delete fieldErrors.mode
    const modeInvalidMessage = modeValidation(values, value)
    if (modeInvalidMessage) {
      fieldErrors.mode = modeInvalidMessage
    }

    // period validation
    // if we do not have value error and current mode is period
    const valueError = get(currentErrors, 'value')
    if (
      value.value &&
      value.mode &&
      value.mode === PERIOD &&
      (!valueError ||
        [NUMBERS_INTERSECTION_MESSAGE, DATES_INTERSECTION_MESSAGE].includes(valueError))
    ) {
      delete fieldErrors.value
      const periodInvalidMessage = periodsValidation(values, value)
      if (periodInvalidMessage) {
        fieldErrors.value = periodInvalidMessage
      }
    }

    if (Object.keys(fieldErrors).length > 0) {
      // collect all errors
      return { ...acc, [key]: fieldErrors }
    }
    return acc
  }, {})
}
