/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  createActivityCategory,
  updateActivityCategory,
  getActivityCategoryById,
  deleteActivityCategoryById,
  validateCategory
} from 'services/http/activityCategory'

import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID_ERROR,
  REQUEST_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID_ERROR,
  REQUEST_VALIDATE_CATEGORY_BY_ID,
  RESPONSE_VALIDATE_CATEGORY_BY_ID,
  RESPONSE_VALIDATE_CATEGORY_BY_ID_ERROR,
  buildDeleteMessage
} = helpers

const postCategoryForm = (contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await createActivityCategory(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM))
    dispatch(send(message))
    return 'postCategoryForm success'
  }

  return 'postCategoryForm failed'
}

const putCategoryForm = (contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await updateActivityCategory(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM))
    dispatch(send(message))
    return 'putCategoryForm success'
  }

  return 'putCategoryForm failed'
}

const getActivityCategoryDataById = (id, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_ENTITY_BY_ID))
  const request = await getActivityCategoryById(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID, request.data))
    return 'getActivityCategoryDataById success'
  }

  return 'getActivityCategoryDataById failed'
}

const removeActivityCategoryById = (id, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_REMOVE_ENTITY_BY_ID))
  const request = await deleteActivityCategoryById(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_REMOVE_ENTITY_BY_ID_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildDeleteMessage() }))
    dispatch(actionCreater(RESPONSE_REMOVE_ENTITY_BY_ID))
    return 'removeActivityCategoryById success'
  }

  return 'removeActivityCategoryById failed'
}

const validateCategoryById = id => async dispatch => {
  dispatch(actionCreater(REQUEST_VALIDATE_CATEGORY_BY_ID))
  const request = await validateCategory(id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_VALIDATE_CATEGORY_BY_ID_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_VALIDATE_CATEGORY_BY_ID, request.data))
    return 'validateCategoryById success'
  }

  return 'validateCategoryById failed'
}

const resetForm = () => ({
  type: RESET_DATA_STATE
})

export default {
  postCategoryForm,
  putCategoryForm,
  getActivityCategoryDataById,
  removeActivityCategoryById,
  validateCategoryById,
  resetForm
}
