/*
* COPYRIGHT:     Copyright © 2018 Xplorie LLC
* Warning:       This product is protected by United States and international copyright laws.
*                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
*/
import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

export const CurrentValue = ({ value }) =>
  !isEmpty(value) && <p className="help is-info">Current value: {value}</p>

CurrentValue.propTypes = {
  value: PropTypes.string.isRequired
}
