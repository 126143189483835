/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo, useCallback } from 'react'
import { BasicButton } from '@xplorie/ui-commons'
import {
  SUBMIT_TITLE,
  ORANGE_SCHEMA,
  YELLOW_GREEN_SCHEMA,
  DEACTIVATE_TITLE,
  DELETE_TITLE,
  ACTIVATE_TITLE,
  YES_TITLE,
  NO_TITLE
} from 'constants/buttonsOptions'
import { withModalWindow } from 'decorators'
import { BasicModal } from 'components/Modals'

import { useDealApi } from '../hooks'
import { buildDeleteMessage } from './helpers'
import { CHANGE_TO_DRAFT } from './types'
import styles from './styles.scss'

const ButtonWithModal = withModalWindow(BasicButton)

export function Buttons() {
  const api = useDealApi()
  const isSubmitVisible = useMemo(() => api.canEditForm(), [api])
  const canDeactivate = useMemo(() => api.canDeactivate(), [api])
  const canChangeToDraft = useMemo(() => api.canChangeToDraft(), [api])
  const canActivate = useMemo(() => api.canActivate(), [api])
  const isSubmitDisabled = useMemo(() => !api.canSubmit(), [api])
  const buildDeactivateMessage = useCallback(() => {
    const details = api.getDetails()
    return `Are you sure you want to Deactivate ${details.name} Deal?`
  }, [api])

  const deleteMessage = useMemo(() => buildDeleteMessage(api), [api])

  return (
    <div className={styles.wrapper}>
      {isSubmitVisible && (
        <BasicButton
          onClick={api.submitForm}
          colorSchema={ORANGE_SCHEMA}
          disabled={isSubmitDisabled}
        >
          {SUBMIT_TITLE}
        </BasicButton>
      )}
      {canChangeToDraft && (
        <BasicButton onClick={api.draftDeal} colorSchema={ORANGE_SCHEMA}>
          {CHANGE_TO_DRAFT}
        </BasicButton>
      )}
      {canActivate && (
        <BasicButton onClick={api.activate} colorSchema={ORANGE_SCHEMA}>
          {ACTIVATE_TITLE}
        </BasicButton>
      )}
      {canDeactivate && (
        <ButtonWithModal
          colorSchema={ORANGE_SCHEMA}
          onAction={api.deactivate}
          renderModal={({ isShown, onConfirm, onHide }) => (
            <BasicModal isOpen={isShown} onClose={onHide} message={buildDeactivateMessage()}>
              <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onConfirm}>
                {YES_TITLE}
              </BasicButton>
              <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onHide}>
                {NO_TITLE}
              </BasicButton>
            </BasicModal>
          )}
        >
          {DEACTIVATE_TITLE}
        </ButtonWithModal>
      )}
      {canActivate && (
        <ButtonWithModal
          colorSchema={ORANGE_SCHEMA}
          onAction={api.deleteDiscount}
          renderModal={({ isShown, onConfirm, onHide }) => (
            <BasicModal isOpen={isShown} onClose={onHide} message={deleteMessage}>
              <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onConfirm}>
                {YES_TITLE}
              </BasicButton>
              <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onHide}>
                {NO_TITLE}
              </BasicButton>
            </BasicModal>
          )}
        >
          {DELETE_TITLE}
        </ButtonWithModal>
      )}
    </div>
  )
}
