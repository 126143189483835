import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_LIST_ERROR,
  REQUEST_LIST_BY_PROVIDER,
  RESPONSE_LIST_BY_PROVIDER,
  RESPONSE_LIST_BY_PROVIDER_ERROR
} = helpers

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  data: [],
  itemsCount: 0,
  itemsPerPage: 10,
  pageNumber: 1
}

const activityCategoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_LIST:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        data: action.payload.data,
        ...action.payload.pagination
      }
    case RESPONSE_LIST_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case REQUEST_LIST_BY_PROVIDER:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_LIST_BY_PROVIDER_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case RESPONSE_LIST_BY_PROVIDER:
      return {
        ...state,
        data: action.payload,
        dataState: DATA_STATE_RECIEVED
      }
    default:
      return state
  }
}

export default filterActions(activityCategoryListReducer, [
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_LIST_ERROR,
  REQUEST_LIST_BY_PROVIDER,
  RESPONSE_LIST_BY_PROVIDER,
  RESPONSE_LIST_BY_PROVIDER_ERROR
])
