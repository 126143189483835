/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import {
  REQUEST_CHANGE_PASS_ACTION,
  RESPONSE_CHANGE_PASS_ACTION,
  RESPONSE_CHANGE_PASS_ACTION_ERROR,
  REQUEST_RESET_PASSWORD,
  RESPONSE_RESET_PASSWORD,
  RESPONSE_RESET_PASSWORD_ERROR,
  RESET_DATA_STATE
} from './helpers'

const initialState = {}

export const accountPasswordInitialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  changePassDataState: DATA_STATE_NOT_REQUESTED
}

const accountPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    // Reducers for change password
    case REQUEST_CHANGE_PASS_ACTION:
      return {
        ...state,
        [action.payload.key]: {
          ...accountPasswordInitialState,
          ...state[action.payload.key],
          changePassDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_CHANGE_PASS_ACTION_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...accountPasswordInitialState,
          ...state[action.payload.key],
          changePassDataState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_CHANGE_PASS_ACTION:
      return {
        ...state,
        [action.payload.key]: {
          ...accountPasswordInitialState,
          ...state[action.payload.key],
          changePassDataState: DATA_STATE_RECIEVED
        }
      }

    // Reducers for reset password
    case REQUEST_RESET_PASSWORD:
      return {
        ...state,
        [action.payload.key]: {
          ...accountPasswordInitialState,
          ...state[action.payload.key],
          dataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_RESET_PASSWORD_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...accountPasswordInitialState,
          ...state[action.payload.key],
          dataState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_RESET_PASSWORD:
      return {
        ...state,
        [action.payload.key]: {
          ...accountPasswordInitialState,
          ...state[action.payload.key],
          dataState: DATA_STATE_RECIEVED
        }
      }

    case RESET_DATA_STATE:
      return { ...initialState }

    default:
      return state
  }
}

export default filterActions(accountPasswordReducer, [
  REQUEST_CHANGE_PASS_ACTION,
  RESPONSE_CHANGE_PASS_ACTION,
  RESPONSE_CHANGE_PASS_ACTION_ERROR,
  REQUEST_RESET_PASSWORD,
  RESPONSE_RESET_PASSWORD,
  RESPONSE_RESET_PASSWORD_ERROR,
  RESET_DATA_STATE
])
