/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { ACTIVITY_CATEGORY_TAGS_URL, ACTIVITY_CATEGORY_TAGS_BY_ID_URL } from 'endpoints'

export const getActivityCategoryTags = () =>
  httpWrapper({
    url: ACTIVITY_CATEGORY_TAGS_URL,
    method: 'get',
  })

export const getActivityCategoryTagsById = id =>
  httpWrapper({
    url: ACTIVITY_CATEGORY_TAGS_BY_ID_URL,
    method: 'get',
    params: { categoryId: id }
  })
