/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

export const INFO_TYPE = 'info'
export const SUCCESS_TYPE = 'success'
export const WARNING_TYPE = 'warning'
export const DANGER_TYPE = 'danger'

export const INFO_TITLE = 'Info'
export const SUCCESS_TITLE = 'Success'
export const WARNING_TITLE = 'Warning'
export const DANGER_TITLE = 'Error'
