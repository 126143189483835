/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { BasicButton } from '@xplorie/ui-commons'

export function ForApproveButton(props) {
  const { onClick, onModal, canSubmit, ...restProps } = props

  const onClickHandler = useCallback(
    (...args) => {
      if (canSubmit()) {
        onClick(...args)
      } else if (onModal) {
        onModal()
      }
    },
    [canSubmit, onClick, onModal]
  )

  return <BasicButton {...restProps} onClick={onClickHandler} />
}

ForApproveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  onModal: PropTypes.func.isRequired,
  canSubmit: PropTypes.bool.isRequired
}
