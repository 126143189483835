/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { PM_SYSTEM } from 'constants/searchTypes'

const LIST_NAME = 'PMS_LIST'
const DEFAULT_PAGE_NUMBER = 1
const DEFAULT_ITEMS_PER_PAGE = 10
const ENTITY_TYPE = PM_SYSTEM

const DEFAULT_OPTIONS = {
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE
}

export default {
  LIST_NAME,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ITEMS_PER_PAGE,
  ENTITY_TYPE,
  DEFAULT_OPTIONS
}
