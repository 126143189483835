/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { listInitialState } from './reducer'

export function getProgramPmcValuesEligibilityListState(state) {
  return state.eligibility.programPmcEligibilityValuesList
}

export function getPmcEligibilityValuesListStateByProgramId(state, id) {
  return getProgramPmcValuesEligibilityListState(state)[id] || listInitialState
}
