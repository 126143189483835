/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'
import { entityInitialState, listDataState } from './reducer'

function getProgramHistoryState(state) {
  return state.program.programHistory
}

function getProgramHistoryStateById(state, id) {
  const historyStates = getProgramHistoryState(state)
  return historyStates[id] || entityInitialState
}

function getProgramYearHistoryStateById(state, id, year) {
  return get(getProgramHistoryStateById(state, id), ['years', year], listDataState)
}

function getProgramHistoryIds(state) {
  return Object.keys(getProgramHistoryState(state))
}

export default {
  getProgramHistoryState,
  getProgramHistoryStateById,
  getProgramHistoryIds,
  getProgramYearHistoryStateById
}
