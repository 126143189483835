/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './CurtainWrapper.scss'

export function CurtainWrapper(props) {
  const { className, ...rest } = props
  return <div className={classNames(styles.curtain, className)} {...rest} />
}
CurtainWrapper.propTypes = {
  className: PropTypes.string
}
CurtainWrapper.defaultProps = {
  className: ''
}
