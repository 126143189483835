/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const TITLE = 'Warning'

export const WARNING_MESSAGE_PROGRAMS =
  'This Destination has assigned Program(s). Please, update or remove from Program(s) before making changes to this Destination.'

export const WARNING_MESSAGE_ACTIVITIES =
  'This Destination has assigned AP Product(s). Please, update or remove from AP Product(s) before making changes to this Destination.'

export const VIEW_PROGRAMS_LINK = 'View Programs'
export const VIEW_ACTIVITIES_LINK = 'View AP Products'
