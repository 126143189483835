/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { canDeleteMarket } from 'modules/Market'
import { marketTableColumns, buildDefaultButtonColumn } from 'helpers/dataTablesHelpers'
import { EditButton, DeleteButton } from './Buttons'

export function buildTableColumns({ authUser }) {
  const canDelete = canDeleteMarket(authUser)
  const columns = [
    ...marketTableColumns,
    {
      ...buildDefaultButtonColumn(),
      id: 'edit-button',
      disableSortBy: true,
      disableResizing: true,
      Cell: ({ row }) => <EditButton authUser={authUser} data={row.original} />
    }
  ]

  if (canDelete) {
    columns.push({
      ...buildDefaultButtonColumn({ width: 100 }),
      disableSortBy: true,
      disableResizing: true,
      id: 'delete-button',
      Cell: ({ row }) => <DeleteButton authUser={authUser} data={row.original} />
    })
  }

  return columns
}
