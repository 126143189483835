/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Form } from 'informed'
import { TextInput } from 'components/FormComponents'
import { SubmitButton } from 'components/Buttons'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'

import styles from './SearchForm.scss'
import { buildPlaceholderByType } from './helpers'
import { SEARCH_BTN_TEXT } from './types'

export function SearchForm(props) {
  const {
    onSubmit,
    api,
    state: {
      parameters: { type, keyword }
    }
  } = props

  const formApi = useRef(null)

  useEffect(() => {
    if (keyword && keyword !== formApi.current.getValue('keyword')) {
      formApi.current.setValue('keyword', keyword)
    }
  }, [keyword])

  const onChangeHandler = formValues => {
    api.changeQueryParameters(formValues.values)
  }

  const onSubmitHandler = formValues => {
    api.onSearch()

    if (onSubmit) {
      onSubmit(formValues)
    }
  }

  return (
    <Form
      id="main-search-form"
      onSubmit={onSubmitHandler}
      onChange={onChangeHandler}
      getApi={informedApi => {
        formApi.current = informedApi
      }}
    >
      <TextInput
        id="search-input"
        fieldProps={{
          renderInner: ({ children }) => (
            <div className={styles.searchWrapper}>
              {children}
              <SubmitButton
                id="search-btn"
                colorSchema={ORANGE_SCHEMA}
                className={classNames(styles.searchBtn)}
              >
                {SEARCH_BTN_TEXT}
              </SubmitButton>
            </div>
          ),
          renderFooter: ({ error, className }) => <span className={className}>{error}</span>
        }}
        className="input is-three-quarters"
        field="keyword"
        placeholder={buildPlaceholderByType(type)}
        validateOnChange
      />
    </Form>
  )
}

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.object,
  error: PropTypes.string,
  className: PropTypes.string,
  api: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

SearchForm.defaultProps = {
  children: null,
  error: '',
  className: ''
}
