/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import moment from 'moment'
import values from 'lodash/values'
import compose from 'lodash/fp/compose'
import head from 'lodash/head'
import pick from 'lodash/pick'
import filter from 'lodash/filter'
import { FORMAT, DEFAULT_TIME_FORMAT } from 'constants/date'
import uniqueId from 'lodash/uniqueId'
import { STATUS_CANCELED } from 'constants/entityStatus'
import { CLOSURE_TYPE, SCHEDULE_TYPE, FULL_WEEK_VALUE } from './types'

export function createTimePeriodWithUniqId(target, timePeriod) {
  const timePeriodId = uniqueId('timePeriods')

  return {
    ...target,
    [timePeriodId]: timePeriod
  }
}

/**
 * Take first timePeriod
 * @param {Object} value
 * @returns
 */
export function takeFirstTimePeriod(value) {
  return compose(
    head,
    values
  )(value)
}

export function buildEventDate(date, time) {
  const momentDate = moment(date, FORMAT)
  const momentTime = moment(time, DEFAULT_TIME_FORMAT)

  return momentDate
    .hours(momentTime.hours())
    .minutes(momentTime.minutes())
    .seconds(momentTime.seconds())
    .toISOString()
}

/**
 *
 * @param {Object[]} schedules
 * @param {Object} schedule[]
 * @param {string} schedule[].id
 * @param {string} schedule[].activityId
 * @param {string} schedule[].status
 * @param {Object} schedule[].datePeriod
 * @param {string} schedule[].datePeriod.startDate
 * @param {string} schedule[].datePeriod.endDate
 * @param {Object} schedule[].timePeriod
 * @param {string} schedule[].timePeriod.startTime
 * @param {string} schedule[].timePeriod.endTime
 * @param {Object} schedule[].timePeriod.weekDays
 * @param {Object} schedule[].details
 * @param {string} schedule[].details.reason
 * @param {string} schedule[].details.confirmedBy
 * @param {boolean} schedule[].details.allDay
 * @param {Object[]} schedule[].occurrences
 * @param {string} schedule[].occurrence[].id
 * @param {string} schedule[].occurrence[].seriesId
 * @param {Object} schedule[].occurrence[].details
 * @param {string} schedule[].occurrence[].details.reason
 * @param {boolean} schedule[].occurrence[].details.allDay
 * @param {string} schedule[].occurrence[].datePeriod.startDate
 * @param {string} schedule[].occurrence[].datePeriod.endDate
 * @returns {Object[]}
 */
export function mapToEvents(schedules = []) {
  return schedules
    .map(value => {
      const hasOnlyOneCancellation =
        filter(value.occurrences, occurrence => occurrence.status === STATUS_CANCELED).length === 1
      const isSeries = value.occurrences.length > 1
      return {
        ...value,
        occurrences: value.occurrences.map(occurrence => ({
          ...occurrence,
          series: pick(value, ['datePeriod', 'timePeriod']),
          type: value.type,
          isSeries,
          hasOnlyOneCancellation
        }))
      }
    })
    .reduce((acc, value) => [...acc, ...value.occurrences], [])
    .map(schedule => {
      const title =
        schedule.type === CLOSURE_TYPE || schedule.status === STATUS_CANCELED
          ? schedule.details.reason || ''
          : schedule.details.note
      return schedule.details.allDay
        ? {
            id: schedule.id,
            title,
            date: moment(schedule.day, FORMAT).toISOString(false),
            allDay: true,
            extendedProps: schedule
          }
        : {
            id: schedule.id,
            title,
            start: buildEventDate(schedule.day, schedule.timePeriod.startTime),
            end: buildEventDate(schedule.day, schedule.timePeriod.endTime),
            weekDays: schedule.timePeriod.weekDays,
            extendedProps: schedule
          }
    })
}

export const getAllDayTimePeriod = days => ({
  weekDays: days || FULL_WEEK_VALUE
})

function mapFormValuesToDatePeriodPayload(formValues) {
  return {
    startDate: formValues.datePeriod.from,
    endDate: formValues.datePeriod.to
  }
}

function mapFormValuesToDetailsPayload(formValues) {
  return {
    reason: formValues.reason,
    confirmedBy: formValues.confirmedBy,
    allDay: Boolean(formValues.allDay)
  }
}

function mapFormValuesToScheduleDetailsPayload(formValues) {
  return {
    note: formValues.note || '',
    reason: formValues.reason || '',
    allDay: Boolean(formValues.allDay)
  }
}

export function mapToCreateClosurePayload(formValues) {
  return {
    datePeriod: mapFormValuesToDatePeriodPayload(formValues),
    timePeriods: formValues.allDay ? [getAllDayTimePeriod()] : values(formValues.timePeriods),
    details: mapFormValuesToDetailsPayload(formValues),
    type: CLOSURE_TYPE
  }
}

export function mapToCreateSchedulePayload(formValues) {
  return {
    datePeriod: mapFormValuesToDatePeriodPayload(formValues),
    timePeriods: formValues.allDay ? [getAllDayTimePeriod()] : values(formValues.timePeriods),
    details: mapFormValuesToScheduleDetailsPayload(formValues),
    type: SCHEDULE_TYPE
  }
}

export function mapToEditSeriesPayload(formValues) {
  const timePeriod = takeFirstTimePeriod(formValues.timePeriods)

  return {
    datePeriod: mapFormValuesToDatePeriodPayload(formValues),
    timePeriod: formValues.allDay ? getAllDayTimePeriod() : timePeriod,
    details: mapFormValuesToDetailsPayload(formValues)
  }
}

export function mapToEditScheduleSeriesPayload(formValues) {
  const timePeriod = takeFirstTimePeriod(formValues.timePeriods)

  return {
    datePeriod: mapFormValuesToDatePeriodPayload(formValues),
    timePeriod: formValues.allDay ? getAllDayTimePeriod(timePeriod.weekDays) : timePeriod,
    details: mapFormValuesToScheduleDetailsPayload(formValues)
  }
}

export function mapToEditOccurrencePayload(formValues) {
  const timePeriod = takeFirstTimePeriod(formValues.timePeriods)

  return {
    day: formValues.date,
    timePeriod: formValues.allDay ? null : timePeriod,
    details: mapFormValuesToDetailsPayload(formValues)
  }
}

export function mapOccurrenceToValidationPayload(formValues, type = CLOSURE_TYPE) {
  return {
    datePeriod: {
      startDate: formValues.date,
      endDate: formValues.date
    },
    timePeriods: formValues.allDay ? [getAllDayTimePeriod()] : values(formValues.timePeriods),
    details: mapFormValuesToDetailsPayload(formValues),
    type
  }
}

export function mapToEditScheduleOccurrencePayload(formValues) {
  const timePeriod = takeFirstTimePeriod(formValues.timePeriods)

  return {
    day: formValues.date,
    timePeriod: formValues.allDay ? null : timePeriod,
    details: mapFormValuesToScheduleDetailsPayload(formValues)
  }
}
/**
 * @param {Object} payload
 * @param {Object} payload.datePeriod
 * @param {string} payload.datePeriod.startDate
 * @param {string} payload.datePeriod.endDate
 * @param {Object} payload.timePeriod
 * @param {string} payload.timePeriod.startTime
 * @param {string} payload.timePeriod.endTime
 * @param {Object} payload[].timePeriod.weekDays
 * @param {Object} payload[].details
 * @param {string} payload[].details.reason
 * @param {string} payload[].details.confirmedBy
 * @param {boolean} payload[].details.allDay
 */
export function mapSeriesToFormValues(payload) {
  return {
    datePeriod: {
      from: payload.datePeriod.startDate,
      to: payload.datePeriod.endDate
    },
    timePeriods: payload.details.allDay
      ? getAllDayTimePeriod()
      : createTimePeriodWithUniqId({}, payload.timePeriod),
    allDay: payload.details.allDay,
    confirmedBy: payload.details.confirmedBy,
    reason: payload.details.reason,
    note: payload.details.note
  }
}

/**
 * @param {Object} payload
 * @param {Object} payload.datePeriod
 * @param {string} payload.datePeriod.startDate
 * @param {string} payload.datePeriod.endDate
 * @param {Object} payload.timePeriod
 * @param {string} payload.timePeriod.startTime
 * @param {string} payload.timePeriod.endTime
 * @param {Object} payload.timePeriod.weekDays
 * @param {Object} payload[].details
 * @param {string} payload[].details.reason
 * @param {string} payload[].details.confirmedBy
 * @param {boolean} payload[].details.allDay
 * @param {string} date - mm/dd/yyyy date
 */
export function mapOccurrenceToFormValues(payload) {
  return {
    datePeriod: null,
    date: payload.day,
    timePeriods: payload.details.allDay
      ? getAllDayTimePeriod()
      : createTimePeriodWithUniqId({}, payload.timePeriod),
    allDay: payload.details.allDay,
    confirmedBy: payload.details.confirmedBy,
    reason: payload.details.reason,
    note: payload.details.note
  }
}

/**
 *
 * @param {Object} initial
 * @param {Date} initial.startDate
 * @param {Date} initial.endDate
 * @param {Object} initial.weekDays
 */
export function mapInitialValues(initial) {
  return initial
    ? {
        datePeriod: {
          from: null,
          to: null
        },
        timePeriods: {}
      }
    : undefined
}
