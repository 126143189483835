/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { buildPath } from 'helpers/httpHelpers'

import { PROGRAMS_PREBOOK_INFO_URL } from 'endpoints'

export const getPrebookInfoByProgramId = programId =>
  httpWrapper({
    url: buildPath(PROGRAMS_PREBOOK_INFO_URL, { programId }),
    method: 'get'
  })

export const savePrebookInfoByProgramId = (programId, contractData = {}) =>
  httpWrapper({
    url: buildPath(PROGRAMS_PREBOOK_INFO_URL, { programId }),
    method: 'post',
    data: contractData
  })

export const deletePrebookInfoByProgramId = programId =>
  httpWrapper({
    url: buildPath(PROGRAMS_PREBOOK_INFO_URL, { programId }),
    method: 'delete'
  })
