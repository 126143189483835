/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { INVALID_PRICE_MESSAGE, INVALID_TICKET_TYPE_MESSAGE } from './types'

export function calcFinalTicketTypePrice(retailPrice, discount, percentageDiscount) {
  if (percentageDiscount) {
    return (Number(retailPrice) - (Number(retailPrice) * Number(discount)) / 100).toFixed(2)
  }
  return (Number(retailPrice) - Number(discount)).toFixed(2)
}

export function validateFinalPrice(value) {
  return Number(value) < 0 ? { INVALID_PRICE_MESSAGE } : null
}

export function ticketTypesValidation(ticketTypes) {
  return ticketTypes.every(value => !value.included) ? INVALID_TICKET_TYPE_MESSAGE : null
}
