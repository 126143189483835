/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getFileList, getGuestDataFile, getGuestTemplate } from 'services/http/guestData'

import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_DOWNLOAD,
  RESPONSE_DOWNLOAD,
  RESPONSE_ERROR_DOWNLOAD
} = helpers

const getGuestsFileList = (programId, options) => async dispatch => {
  const key = programId
  dispatch(actionCreater(REQUEST_LIST, { key }))
  const request = await getFileList(programId, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST, { key }))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { data: request.data, key }))
    return 'getGuestsFileList success'
  }

  return 'getGuestsFileList failed'
}

const downloadFile = (programId, fileId, fileName) => async dispatch => {
  const key = programId
  dispatch(actionCreater(REQUEST_DOWNLOAD, { key }))
  const request = await getGuestDataFile(programId, fileId, fileName).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_DOWNLOAD, { key }))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_DOWNLOAD, { key }))
    return 'downloadFile success'
  }

  return 'downloadFile failed'
}

const downloadTemplate = (programId, fileName) => async dispatch => {
  const key = programId
  dispatch(actionCreater(REQUEST_DOWNLOAD, { key }))
  const request = await getGuestTemplate(programId, fileName).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_DOWNLOAD, { key }))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_DOWNLOAD, { key }))
    return 'downloadFile success'
  }

  return 'downloadFile failed'
}

const resetList = programId => async dispatch => {
  dispatch(actionCreater(RESET_DATA_STATE, { key: programId }))
}

export default {
  getGuestsFileList,
  resetList,
  downloadFile,
  downloadTemplate
}
