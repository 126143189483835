/*
* COPYRIGHT:     Copyright © 2018 Xplorie LLC
* Warning:       This product is protected by United States and international copyright laws.
*                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
*/
import React from 'react'
import classNames from 'classnames'
import styles from './LogOutStub.scss'
import { LOG_OUT_MESSAGE } from './types'

export const LogOutStub = () => (
  <div className={classNames('has-text-centered', styles.commonWrapper)}>
    <div className={classNames('notification', 'is-info', styles.textWrapper)}>
      {LOG_OUT_MESSAGE}
    </div>
  </div>
)
