/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useMemo, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { Form } from 'informed'
import {
  FormContent,
  SelectField,
  ControlButtonsContainer,
  TextField
} from 'components/FormComponents'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, SUBMIT_TITLE } from 'constants/buttonsOptions'
import { useDidMount } from 'hooks'
import { canUpdateLocation } from 'modules/Locations'
import { useLocationCampaigns } from 'modules/Locations/LocationsCampaigns'
import { useLocationProgramsAndActivities } from 'modules/Locations/LocationProgramsAndActivities'
import { DESTINATION_TYPE } from 'constants/locationsTypes'
import { get } from 'lodash'
import { LocationSystemDetails } from './LocationSystemDetails'

import {
  NAME_LABEL,
  NAME_PLACEHOLDER,
  TYPE_LABEL,
  TYPE_PLACEHOLDER,
  ALT_LABEL,
  ALT_PLACEHOLDER,
  NAME_FIELD,
  ALT_FIELD,
  TYPE_FIELD
} from './types'

export function LocationForm(props) {
  const {
    createCountry,
    createLocation,
    updateLocation,
    resetForm,
    locationsOptions,
    onSubmit,
    authUser,
    onChange,
    formData,
    parentType,
    viewMode,
    addChildMode
  } = props
  const formApi = useRef()
  const canUpdate = useMemo(() => canUpdateLocation(authUser), [authUser])
  const isNewCountry = useMemo(() => !parentType && !addChildMode && !viewMode, [
    parentType,
    addChildMode,
    viewMode
  ])
  const isFormDisabled = useMemo(() => !canUpdate, [canUpdate])
  const [isSubmitDisabled, setIsSubmitDisabledState] = useState(true)
  const [isDestinationChanged, setIsDestinationChanged] = useState(false)
  const { getCampaigns } = useLocationCampaigns()
  const { getProgramsAndActivities } = useLocationProgramsAndActivities(getCampaigns)

  const locationCode = get(formData, 'businessCode', null)
  const locationType = get(formData, 'type', null)

  const onChangeHandler = useCallback(
    formState => {
      const { invalid, pristine, touched, values } = formState
      const isTouched = !isEmpty(touched)
      const submitDisabled = isFormDisabled || invalid || pristine || !isTouched
      const destinationChanged = Boolean(
        formData && formData.type === DESTINATION_TYPE && formData.type !== values.type
      )
      setIsDestinationChanged(destinationChanged)
      setIsSubmitDisabledState(submitDisabled)
      if (onChange) {
        onChange(formState)
      }
    },
    [formData, isFormDisabled, onChange]
  )

  const setFormValues = useCallback(values => {
    if (values) {
      formApi.current.setState({ values })
    }
  }, [])

  useDidMount(() => {
    setFormValues(formData)

    return () => {
      resetForm()
    }
  })

  const onSubmitResolved = useCallback(
    data => {
      if (data) {
        setFormValues(data)
      }
    },
    [setFormValues]
  )

  const onSubmitHandler = useCallback(
    formValues => {
      if (onSubmit) {
        return onSubmit(formValues).then(onSubmitResolved)
      }

      if (isNewCountry) {
        return createCountry(formValues)
      }

      const request = formValues.id ? updateLocation : createLocation
      return request(formValues)
    },
    [createLocation, onSubmit, onSubmitResolved, updateLocation, createCountry, isNewCountry]
  )

  const onGetApi = useCallback(api => {
    formApi.current = api
  }, [])

  const onSubmitClickHandler = useCallback(async () => {
    if (!formApi.current) {
      return
    }

    if (isDestinationChanged && formData.id) {
      const { canProceed } = await getProgramsAndActivities(formData.id)
      if (canProceed) {
        const campaignsRes = await getCampaigns(formData.id)
        if (campaignsRes && campaignsRes.canProceed) {
          formApi.current.submitForm()
        }
      }
      return
    }

    formApi.current.submitForm()
  }, [isDestinationChanged, formData, getCampaigns, getProgramsAndActivities])

  return (
    <Form getApi={onGetApi} onChange={onChangeHandler} onSubmit={onSubmitHandler}>
      <FormContent disabled={isFormDisabled}>
        <TextField
          fieldName={NAME_FIELD}
          label={NAME_LABEL}
          placeholder={NAME_PLACEHOLDER}
          isRequired
        />
        <TextField
          fieldName={ALT_FIELD}
          label={ALT_LABEL}
          placeholder={ALT_PLACEHOLDER}
          isRequired
        />
        {!isNewCountry && (
          <SelectField
            fieldName={TYPE_FIELD}
            options={locationsOptions}
            label={TYPE_LABEL}
            placeholder={TYPE_PLACEHOLDER}
            isRequired
          />
        )}
      </FormContent>
      {locationCode && (
        <LocationSystemDetails locationType={locationType} locationCode={locationCode} />
      )}
      <ControlButtonsContainer>
        <BasicButton
          colorSchema={ORANGE_SCHEMA}
          disabled={isSubmitDisabled}
          onClick={onSubmitClickHandler}
        >
          {SUBMIT_TITLE}
        </BasicButton>
      </ControlButtonsContainer>
    </Form>
  )
}

LocationForm.propTypes = {
  dataId: PropTypes.string.isRequired,
  createCountry: PropTypes.func.isRequired,
  createLocation: PropTypes.func.isRequired,
  updateLocation: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  locationsOptions: PropTypes.array.isRequired,
  onSubmit: PropTypes.func,
  authUser: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.object,
  showModeButton: PropTypes.bool,
  parentType: PropTypes.string.isRequired,
  viewMode: PropTypes.bool,
  addChildMode: PropTypes.bool
}
LocationForm.defaultProps = {
  onSubmit: null,
  formData: null,
  showModeButton: false,
  viewMode: false,
  addChildMode: false
}
