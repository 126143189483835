/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { withFormContext } from 'components/FormComponents/helpers'
import { CirclePlusIcon } from 'icons/svg/CirclePlusIcon'
import classNames from 'classnames'

import styles from './styles.scss'
import { useDealPeriodsApi } from './hooks'

function DiscountPeriodButtons(props) {
  const { disabled, canAddPeriod } = props
  const dealPeriodsApi = useDealPeriodsApi()
  const isDisabled = disabled || !canAddPeriod

  const onAddPeriod = useCallback(() => {
    if (isDisabled) {
      return
    }

    dealPeriodsApi.onAdd()
  }, [isDisabled, dealPeriodsApi])

  return (
    <div className={styles.sectionButtons}>
      <CirclePlusIcon
        className={classNames(styles.addButton, { [styles.disabled]: isDisabled })}
        onClick={onAddPeriod}
      />
    </div>
  )
}

DiscountPeriodButtons.propTypes = {
  canAddPeriod: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default withFormContext(DiscountPeriodButtons)
