/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  DEAL_GROUP_TRIGGER_TYPE,
  DEAL_GROUP_ANY_TYPE,
  DEAL_GROUP_OR_TYPE
} from 'constants/dealsTypes'

// titles
export const ACCESS_DENIED_MESSAGE = 'Access Denied'
export const DEAL_OPTIONS_TITLE = 'Discount Options'
export const TITLE_ADD_NEW_PERIOD = 'Add New Period'
export const DELETE_MODAL_TITLE = 'Warning!'
export const GROUP_POSTFIX = 'Group'
export const DEAL_POSTFIX = 'DEAL'
export const SETTINGS_TAB_TITLE = 'Settings'
export const HISTORY_TAB_TITLE = 'History'

// labels
export const DEAL_NAME_LABEL = 'Discount Name'
export const DEAL_TYPE_LABEL = 'Discount Type'
export const START_DATE_LABEL = 'Start Date'
export const END_DATE_LABEL = 'End Date'
export const SELECT_APP_LABEL = 'Select APP'
export const TICKET_TYPE_LABEL = 'Ticket Type'
export const RETAIL_PRICE_LABEL = 'Retail Price'
export const DISCOUNT_LABEL = 'Discount'
export const FREE_TICKETS_LABEL = 'Free Tickets'
export const FINAL_PRICE_LABEL = 'Final Price'
export const DESCRIPTION_LABEL = 'Description'

// placeholders
export const DEAL_NAME_PLACEHOLDER = 'Discount Name'
export const DEAL_TYPE_PLACEHOLDER = 'Select Discount Type'
export const START_DATE_PLACEHOLDER = 'Start Date'
export const END_DATE_PLACEHOLDER = 'End Date'
export const SELECT_APP_PLACEHOLDER = 'Select APP'
export const SELECT_GROUP_PLACEHOLDER = 'Select Group'
export const DESCRIPTION_PLACEHOLDER = 'Enter Description for Discount Pop-up'

// buttons
export const ADD_NEW_PERIOD = 'Add New Period'

// messages
export const RANGE_INTERSECTION_ERROR = 'Date have intersections with other dates'
export const DELETE_MODAL_MESSAGE = 'Are you sure you want to delete the Discount?'
export const CHOOSE_APP_TOLTIP_MESSAGE = 'To proceed, choose APP type'
export const DELETE_ACTIVITY_MESSAGE = 'Are you sure you want to delete this APP?'
export const NO_RESULT_MESSAGE = 'No results were found.'

// options
export const dealTypeOptions = []
export const dealGroupsTypeOptions = [
  { label: 'Trigger', value: DEAL_GROUP_TRIGGER_TYPE },
  { label: 'And', value: DEAL_GROUP_ANY_TYPE },
  { label: 'Or', value: DEAL_GROUP_OR_TYPE }
]
export const percentageDiscountOptions = [{ value: false, label: '$' }, { value: true, label: '%' }]
