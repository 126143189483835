/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from 'reducers/editQueue'
import Profile from './Profile'

const mapStateToProps = state => ({
  profileDataState: state.profileData.dataState,
  profileData: state.profileData.profileData
})

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

const ProfileConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)

export default ProfileConnect
