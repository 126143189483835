/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const ProgramsTabs = loadable(
  () => import(/* webpackChunkName: 'ProgramsTabs', webpackPrefetch: true */ './ProgramsTabs'),
  {
    resolveComponent: components => components.ProgramsTabs
  }
)

export * from './helpers'
export { ProgramsTabs }
export * from './ProgramsForm'
export * from './ProgramSeasons'
export * from './ProgramSeasonContainer'
export * from './ProgramModals'
export * from './ProgramUnits'
export { ProgramEligibility } from './ProgramEligibility'
export { DelayedDeactivation, DeactivateConfirmationModal } from './DelayedDeactivation'
export { MarketingTab, MarketingMaterialsForm } from './MarketingMaterials'
export { UploadGuestsTab, UploadGuestsForm, GuestsList } from './UploadGuests'
export { ProgramHistory } from './ProgramHistory'
export { PrebookInfo } from './PrebookInfo'
export { SmartHostSubscriptionFee, SmartHostMobileFee } from './SmartHostFee'
