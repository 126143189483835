import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID_ERROR,
  REQUEST_SWITCH_STATUS,
  RESPONSE_SWITCH_STATUS,
  RESPONSE_SWITCH_STATUS_ERROR
} = helpers

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  responseData: null,
  entityDataState: DATA_STATE_NOT_REQUESTED,
  entityData: {},
  switchStatusDataState: DATA_STATE_NOT_REQUESTED
}

const activityProductFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FORM:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_FORM_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case RESPONSE_FORM:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        responseData: action.payload
      }
    case REQUEST_GET_DATA_BY_ID:
      return {
        ...state,
        entityDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_GET_DATA_BY_ID_ERROR:
      return {
        ...state,
        entityDataState: DATA_STATE_ERROR
      }
    case RESPONSE_GET_DATA_BY_ID:
      return {
        ...state,
        entityDataState: DATA_STATE_RECIEVED,
        entityData: action.payload
      }
    case REQUEST_SWITCH_STATUS:
      return {
        ...state,
        switchStatusDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_SWITCH_STATUS_ERROR:
      return {
        ...state,
        switchStatusDataState: DATA_STATE_ERROR
      }
    case RESPONSE_SWITCH_STATUS:
      return {
        ...state,
        switchStatusDataState: DATA_STATE_RECIEVED
      }
    case RESET_DATA_STATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default filterActions(activityProductFormReducer, [
  REQUEST_FORM,
  RESPONSE_FORM_ERROR,
  RESPONSE_FORM,
  RESET_DATA_STATE,
  REQUEST_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID_ERROR,
  REQUEST_SWITCH_STATUS,
  RESPONSE_SWITCH_STATUS,
  RESPONSE_SWITCH_STATUS_ERROR
])
