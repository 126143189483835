import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_GET_CURRENT_PROGRAM,
  RESPONSE_GET_CURRENT_PROGRAM,
  RESPONSE_GET_CURRENT_PROGRAM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_RELATED_PROGRAM,
  RESPONSE_GET_RELATED_PROGRAM,
  RESPONSE_GET_RELATED_PROGRAM_ERROR
} = helpers

const initialState = {
  currentProgram: null,
  currentProgramDataState: DATA_STATE_NOT_REQUESTED,
  relatedProgram: null,
  relatedProgramDataState: DATA_STATE_NOT_REQUESTED
}

const relatedProgramsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_GET_CURRENT_PROGRAM:
      return {
        ...state,
        currentProgramDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_GET_CURRENT_PROGRAM_ERROR:
      return {
        ...state,
        currentProgramDataState: DATA_STATE_ERROR
      }
    case RESPONSE_GET_CURRENT_PROGRAM:
      return {
        ...state,
        currentProgramDataState: DATA_STATE_RECIEVED,
        currentProgram: action.payload.data
      }

    case REQUEST_GET_RELATED_PROGRAM:
      return {
        ...state,
        relatedProgramDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_GET_RELATED_PROGRAM_ERROR:
      return {
        ...state,
        relatedProgramDataState: DATA_STATE_ERROR
      }
    case RESPONSE_GET_RELATED_PROGRAM:
      return {
        ...state,
        relatedProgramDataState: DATA_STATE_RECIEVED,
        relatedProgram: action.payload.data
      }
    case RESET_DATA_STATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default filterActions(relatedProgramsReducer, [
  REQUEST_GET_CURRENT_PROGRAM,
  RESPONSE_GET_CURRENT_PROGRAM,
  RESPONSE_GET_CURRENT_PROGRAM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_RELATED_PROGRAM,
  RESPONSE_GET_RELATED_PROGRAM,
  RESPONSE_GET_RELATED_PROGRAM_ERROR
])
