import { httpWrapper } from 'services/http/helpers'
import { ACTIVITY_TAGS_URL } from 'endpoints'

export const createActivityTag = contractData =>
  httpWrapper({
    url: ACTIVITY_TAGS_URL,
    method: 'post',
    data: contractData
  })

export const getActivityTag = (id) =>
  httpWrapper({
    url: `${ACTIVITY_TAGS_URL}/${id}`,
    method: 'get',
  })

export const updateActivityTag = (contractData) =>
  httpWrapper({
    url: ACTIVITY_TAGS_URL,
    method: 'put',
    data: contractData
  })

export const deleteActivityTag = id =>
  httpWrapper({
    url: `${ACTIVITY_TAGS_URL}/${id}`,
    method: 'delete'
  })
