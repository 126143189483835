/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import { BouncingLoader } from 'components/Loaders'
import {
  YELLOW_GREEN_SCHEMA,
  ACTIVATE_TITLE,
  DECLINE_TITLE,
  ORANGE_SCHEMA
} from 'constants/buttonsOptions'
import classNames from 'classnames'
import { useLoading } from 'hooks'
import { TITLE, MAKE_ACTIVE_MESSAGE, NO_ACTIVE_APP_MESSAGE } from '../types'
import styles from '../APsActivation.scss'
import { createAPPFilters, APP_OPTIONS } from './types'

export function APPModal({
  resetList,
  isOpen,
  getProductsList,
  data,
  dataState,
  onClose,
  onSubmit,
  dataId
}) {
  const [isLoading, { isLoaded }] = useLoading(dataState)
  const isDisabledActivation = isLoading || !isLoaded

  useEffect(() => {
    if (dataId && isOpen) {
      getProductsList(createAPPFilters(dataId), APP_OPTIONS)
    }
    return () => {
      resetList(dataId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <BasicModal
      isOpen={isOpen}
      title={TITLE}
      onClose={onClose}
      renderBody={() => (
        <section className={styles.wrapper}>
          <span className={classNames({ [styles.invisible]: !isLoaded })}>
            {data.length ? MAKE_ACTIVE_MESSAGE : NO_ACTIVE_APP_MESSAGE}
          </span>
          <ul className={classNames(styles.list)}>
            {isLoading ? (
              <BouncingLoader />
            ) : (
              data.map(value => (
                <li key={value.id} className={styles.listItem}>
                  <span className={styles.name}>{value.name}</span>
                </li>
              ))
            )}
          </ul>
        </section>
      )}
    >
      <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onSubmit} disabled={isDisabledActivation}>
        {ACTIVATE_TITLE}
      </BasicButton>
      <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
        {DECLINE_TITLE}
      </BasicButton>
    </BasicModal>
  )
}
APPModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  dataState: PropTypes.string.isRequired,
  getProductsList: PropTypes.func.isRequired,
  dataId: PropTypes.string,
  resetList: PropTypes.func.isRequired
}
APPModal.defaultProps = {
  dataId: null
}
