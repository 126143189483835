/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { httpWrapper, buildListParams, buildURLForGettingList } from 'services/http/helpers'
import { ACCOUNT_SEARCH_URL } from 'endpoints'

export const getList = (options, filters) =>
  httpWrapper({
    url: buildURLForGettingList(ACCOUNT_SEARCH_URL),
    method: 'post',
    params: buildListParams(options),
    data: filters
  })
