import { actionCreater } from 'helpers/actionHelpers'
import { searchByColumns } from 'services/http/search'

import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  getRequestFilters,
  getRequestOptions
} = helpers

const getActivityCategoryApsById = (activityCategoryId, options = {}) => async dispatch => {
  const key = activityCategoryId
  dispatch(actionCreater(REQUEST_LIST, { key }))
  const request = await searchByColumns(
    getRequestFilters(activityCategoryId),
    getRequestOptions(options)
  ).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
  })

  if (request.data) {
    dispatch(actionCreater(RESPONSE_LIST, { data: request.data, key }))
    return 'getActivityCategoryApsById success'
  }

  return 'getActivityCategoryApsById failed'
}

const resetList = activityCategoryId => dispatch =>
  dispatch(actionCreater(RESET_DATA_STATE, { key: activityCategoryId }))

export default {
  getActivityCategoryApsById,
  resetList
}
