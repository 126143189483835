/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const LocationSlider = loadable(
  () => import(/* webpackChunkName: 'LocationSlider', webpackPrefetch: true */ './LocationSlider'),
  {
    resolveComponent: components => components.LocationSlider
  }
)

export { LocationsList } from './LocationsList'
export { LocationForm } from './LocationForm'
export { LocationSlider }
export { LocationTab } from './LocationTab'
export { MarketingTab } from './MarketingTab'
export { MarketingForm } from './MarketingForm'
export * from './helpers'
