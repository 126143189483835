/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

/**
 *
 * @param {string} string Parametric string like pie-product/locations/${id}/marketing
 * @param {object} parameters Object parameters {id:'some id'}
 * @returns {string} Path based on input string and parameters
 */
export function buildPath(string = '', parameters = {}) {
  return Object.keys(parameters).reduce(
    (acc, key) => acc.replace(new RegExp(`\\{${key}\\}`, 'g'), parameters[key]),
    string
  )
}
