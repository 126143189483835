/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Calendar } from 'modules/Closures'

import { useCalendarPagePresenter } from './useCalendarPagePresenter'

export function ClosureCalendar(props) {
  const { fetch, data, loading, id, series, occurrence, occurrences } = useCalendarPagePresenter(
    props
  )

  return (
    <Calendar
      fetch={fetch}
      events={data}
      loading={loading}
      activityId={id}
      onDeleteSeries={series.onDelete}
      onDeleteOccurrence={occurrence.onDelete}
      onDeleteOccurrences={occurrences.onDelete}
      onCancelSeries={series.onCancel}
      onCancelOccurrence={occurrence.onCancel}
      onActivateSeries={series.onActivate}
      onActivateOccurrence={occurrence.onActivate}
    />
  )
}

ClosureCalendar.propTypes = {
  id: PropTypes.string.isRequired
}
