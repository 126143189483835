/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import get from 'lodash/get'
import { buildRequestParams } from 'helpers/searchParamsHelper'

export const getProgramsByAppHandler = props => () => {
  const { data, history } = props
  const original = get(data, 'original', {})
  if (original.id) {
    history.push({
      pathname: '/admin/programs/app',
      search: buildRequestParams({ id: original.id }),
      state: original
    })
  }
}
