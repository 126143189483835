/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { PROGRAM, ACTIVITY, ACTIVITY_PROVIDER, PM_COMPANY, DISCOUNT } from 'constants/searchTypes'

import {
  PROGRAM_PLACEHOLDER,
  ACTIVITY_PLACEHOLDER,
  ACTIVITY_PROVIDER_PLACEHOLDER,
  PM_COMPANY_PLACEHOLDER,
  DEALS_PLACEHOLDER
} from './types'

export const buildPlaceholderByType = type => {
  switch (type) {
    case PROGRAM:
      return PROGRAM_PLACEHOLDER
    case PM_COMPANY:
      return PM_COMPANY_PLACEHOLDER
    case ACTIVITY:
      return ACTIVITY_PLACEHOLDER
    case ACTIVITY_PROVIDER:
      return ACTIVITY_PROVIDER_PLACEHOLDER
    case DISCOUNT:
      return DEALS_PLACEHOLDER
    default:
      return PM_COMPANY_PLACEHOLDER
  }
}
