/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose } from 'redux'
import { getActivities } from 'connectors/reducers/deals/dealFormActivities'
import { GroupsContent as GroupsContentComponent } from './GroupsContent'

function mapStateToProps(state) {
  return {
    data: getActivities(state)
  }
}

export const GroupsContent = compose(connect(mapStateToProps))(GroupsContentComponent)
