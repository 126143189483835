/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDidMount } from 'hooks'
import { BeforeCloseModal } from 'modules/Modals'
import { ProgramEligibilityForm } from './ProgramEligibilityForm'

export function ProgramEligibility(props) {
  const { dataId, slideEvents, tabsApi } = props
  const [isPristine, setPristineState] = useState(true)

  const beforeCloseModalApi = useRef()

  const onBeforeExit = useCallback(
    () => (!isPristine ? beforeCloseModalApi.current.open() : isPristine),
    [isPristine]
  )

  tabsApi.onBeforeChange = onBeforeExit
  slideEvents.onBeforeClose = onBeforeExit

  useDidMount(() => () => {
    slideEvents.onBeforeClose = null
    tabsApi.onBeforeChange = null
  })

  const onGetBeforeCloseModalApi = useCallback(api => {
    beforeCloseModalApi.current = api
  }, [])

  return (
    <div>
      <ProgramEligibilityForm dataId={dataId} onChangeFormState={setPristineState} />
      <BeforeCloseModal getApi={onGetBeforeCloseModalApi} />
    </div>
  )
}
ProgramEligibility.propTypes = {
  dataId: PropTypes.string.isRequired,
  slideEvents: PropTypes.object.isRequired,
  tabsApi: PropTypes.object.isRequired
}
