/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { PROGRAM, ACTIVITY, ACTIVITY_PROVIDER, PM_COMPANY, DISCOUNT } from 'constants/searchTypes'
import { APPROVE_LEVEL, READ_LEVEL } from 'constants/permissionTypes'

export const SEARCH_KEY = 'SEARCH_KEY'

export const DEFAULT_SEARCH_TYPE = PM_COMPANY

export const CHANGE_QUERY_PARAMETERS = 'CHANGE_QUERY_PARAMETERS'
export const CHANGE_FILTERS = 'CHANGE_FILTERS'
export const RESET_LIST_PARAMETERS = 'RESET_LIST_PARAMETERS'
export const ON_SEARCH = 'ON_SEARCH'

export const ALLOWED_QUERY_PARAMS_KEYS = ['type', 'pageNumber', 'itemsPerPage', 'keyword']

// Names of Search Tabs
export const PROGRAM_BTN_TEXT = 'Programs'
export const PMC_BTN_TEXT = 'PMCs'
export const AP_PRODUCT_BTN_TEXT = 'AP Products'
export const AP_BTN_TEXT = 'APs'
export const DEALS_BTN_TEXT = 'Discounts'

export const searchTabOptions = [
  { id: 'pmc-type', type: PM_COMPANY, text: PMC_BTN_TEXT, accessLevels: [READ_LEVEL] },
  { id: 'program-type', type: PROGRAM, text: PROGRAM_BTN_TEXT, accessLevels: [READ_LEVEL] },
  { id: 'ap-type', type: ACTIVITY_PROVIDER, text: AP_BTN_TEXT, accessLevels: [READ_LEVEL] },
  { id: 'app-type', type: ACTIVITY, text: AP_PRODUCT_BTN_TEXT, accessLevels: [READ_LEVEL] },
  {
    id: 'deals-type',
    type: DISCOUNT,
    text: DEALS_BTN_TEXT,
    accessLevels: [APPROVE_LEVEL]
  }
]
