/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import { Scope } from 'informed'
import PropTypes from 'prop-types'

export function CustomNestedField(props) {
  const { field, children, ...rest } = props
  return (
    <Scope scope={field} {...rest}>
      {children}
    </Scope>
  )
}

CustomNestedField.propTypes = {
  children: PropTypes.node.isRequired,
  field: PropTypes.string.isRequired
}
