/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { combineReducers } from 'redux'

import closuresForm from './closuresForm'
import closuresList from './closuresList'

export default combineReducers({ closuresList, closuresForm })
