/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import { isToday } from 'helpers/dateHelpers'
import types from './types'

const { NAME, DEACTIVATION, ACTIVATION } = types

const REQUEST_DEACTIVATION = `${REQUEST_TYPE}_${DEACTIVATION}`
const RESPONSE_DEACTIVATION = `${RESPONSE_TYPE}_${DEACTIVATION}`
const RESPONSE_ERROR_DEACTIVATION = `${RESPONSE_ERROR_TYPE}_${DEACTIVATION}`

const REQUEST_ACTIVATION = `${REQUEST_TYPE}_${ACTIVATION}`
const RESPONSE_ACTIVATION = `${RESPONSE_TYPE}_${ACTIVATION}`
const RESPONSE_ERROR_ACTIVATION = `${RESPONSE_ERROR_TYPE}_${ACTIVATION}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

const ACTIVATION_SUCCESS_MESSAGE = 'The Activity Provider has been activated.'

/**
 * @param {string} prevDate
 * @param {string} date
 * @returns {string}
 */
function buildSuccessDeactivatingMessage(prevDate, date) {
  if (isToday(date)) {
    return 'AP has been deactivated.'
  }
  if (!prevDate) {
    return `Deactivation has been set to ${date}.`
  }
  return `Deactivation Date has been updated to ${date}.`
}

/**
 * @returns {string}
 */
function buildSuccessCancelDeactivatingMessage() {
  return 'Scheduled Deactivation has been canceled.'
}

export default {
  REQUEST_DEACTIVATION,
  RESPONSE_DEACTIVATION,
  RESPONSE_ERROR_DEACTIVATION,
  REQUEST_ACTIVATION,
  RESPONSE_ACTIVATION,
  RESPONSE_ERROR_ACTIVATION,
  RESET_DATA_STATE,
  ACTIVATION_SUCCESS_MESSAGE,
  buildSuccessDeactivatingMessage,
  buildSuccessCancelDeactivatingMessage
}
