/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { AND_OPERATOR, buildSearchColumn, CAMPAIGN } from 'helpers/searchHelpers'

export const buildSearchByCampaignId = id =>
  buildSearchColumn({
    entity: CAMPAIGN,
    field: 'location_id',
    keyword: id,
    operator: AND_OPERATOR
  })
