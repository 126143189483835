/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useContext, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'informed'
import {
  FormContent,
  ControlButtonsContainer,
  TextField,
  TextAreaField,
  SelectField
} from 'components/FormComponents'
import { SliderContext } from 'modules/Eligibility'
import { SubmitButton } from 'components/Buttons'
import { useLoading, useUpdateSomeDataState } from 'hooks'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { ORANGE_SCHEMA, SUBMIT_TITLE } from 'constants/buttonsOptions'
import {
  NAME_LABEL,
  TYPE_LABEL,
  DESCRIPTION_LABEL,
  NAME_PLACEHOLDER,
  LABEL_PLACEHOLDER,
  TYPE_PLACEHOLDER,
  DESCRIPTION_PLACEHOLDER,
  TYPE_OPTIONS,
  LABEL
} from './types'

export function EligibilityForm({ dataState, entityData, entityDataState, putEligibilityForm }) {
  const formApi = useRef()

  const [isProcessing] = useLoading(dataState)
  const [isLoading] = useLoading(entityDataState)
  const { isFormDisabled } = useContext(SliderContext)

  const disabled = isProcessing || isLoading || isFormDisabled

  useUpdateSomeDataState(
    () => {
      // set form values after getting eligibility entity
      if (formApi.current) {
        formApi.current.setValues(entityData)
      }
    },
    [entityDataState],
    DATA_STATE_RECIEVED
  )

  const onGetFormApi = useCallback(api => {
    formApi.current = api
  }, [])

  const onSubmitHandler = useCallback(
    formValues => {
      if (!disabled) {
        const eligibilityId = formValues && formValues.id
        if (eligibilityId) {
          putEligibilityForm(formValues)
        }
      }
    },
    [disabled, putEligibilityForm]
  )

  return (
    <Form getApi={onGetFormApi} onSubmit={onSubmitHandler}>
      <FormContent disabled={disabled}>
        <TextField fieldName="label" label={LABEL} placeholder={LABEL_PLACEHOLDER} isRequired />
        <TextField
          fieldName="name"
          label={NAME_LABEL}
          placeholder={NAME_PLACEHOLDER}
          disabled
          isRequired
        />
        <SelectField
          fieldName="type"
          label={TYPE_LABEL}
          placeholder={TYPE_PLACEHOLDER}
          options={TYPE_OPTIONS}
          disabled
          isRequired
        />
        <TextAreaField
          fieldName="description"
          label={DESCRIPTION_LABEL}
          placeholder={DESCRIPTION_PLACEHOLDER}
        />
        <ControlButtonsContainer>
          <SubmitButton colorSchema={ORANGE_SCHEMA}>{SUBMIT_TITLE}</SubmitButton>
        </ControlButtonsContainer>
      </FormContent>
    </Form>
  )
}

EligibilityForm.propTypes = {
  dataState: PropTypes.string.isRequired,
  entityData: PropTypes.object,
  entityDataState: PropTypes.string.isRequired,
  putEligibilityForm: PropTypes.func.isRequired
}
EligibilityForm.defaultProps = {
  entityData: {}
}
