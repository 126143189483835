/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import {
  actions,
  getEligibilityListState,
  getEligibilityListPagination,
  getListWatchers
} from 'reducers/eligibility/eligibilityList'

import { withAuthContext } from 'helpers/withAuthContext'

import { EligibilityList as EligibilityListComponent } from './EligibilityList'

export const mapStateToProps = state => {
  const listState = getEligibilityListState(state)
  const listPagination = getEligibilityListPagination(state)
  return {
    dataState: listState.dataState,
    data: listState.data,
    ...listPagination,
    listWatchers: getListWatchers(state)
  }
}

export const mapDispatchToProps = dispatch => {
  const { getEligibilityList } = actions
  return bindActionCreators(
    {
      onGetData: getEligibilityList
    },
    dispatch
  )
}

export const EligibilityList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EligibilityListComponent)
