/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'

import {
  createProgram,
  updateProgram,
  deleteProgram as deleteProgramRequest,
  getProgramByIdExtended,
  assignPhoneNumber,
  unassignPhoneNumber,
  createEmptyOnBoardingProgram
} from 'services/http/program'

import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_DELETE_PROGRAM,
  RESPONSE_DELETE_PROGRAM,
  RESPONSE_DELETE_PROGRAM_ERROR,
  REQUEST_PROGRAM_ENTITY_DATA,
  RESPONSE_PROGRAM_ENTITY_DATA,
  RESPONSE_PROGRAM_ENTITY_DATA_ERROR,
  REQUEST_ASSIGN_PHONE_BY_ID,
  RESPONSE_ASSIGN_PHONE_BY_ID,
  RESPONSE_ASSIGN_PHONE_BY_ID_ERROR,
  REQUEST_UNASSIGN_PHONE_BY_ID,
  RESPONSE_UNASSIGN_PHONE_BY_ID,
  RESPONSE_UNASSIGN_PHONE_BY_ID_ERROR,
  REQUEST_CREATE_EMPTY_PROGRAM,
  RESPONSE_CREATE_EMPTY_PROGRAM,
  RESPONSE_CREATE_EMPTY_PROGRAM_ERROR
} = helpers

const postProgramsForm = (authKey, contractData = {}) => async dispatch => {
  const key = (contractData && contractData.id) || null
  dispatch(actionCreater(REQUEST_FORM, { key }))
  const request = await createProgram(authKey, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM, { key }))
    dispatch(send(message))
    return 'postProgramsForm success'
  }

  return 'postProgramsForm failed'
}

const putProgramsForm = (authKey, contractData = {}) => async dispatch => {
  const key = contractData && contractData.id
  dispatch(actionCreater(REQUEST_FORM, { key }))
  updateProgram(authKey, contractData)
    .then(request => {
      const {
        message: { message, send }
      } = request
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_FORM, { key }))
    })
    .catch(({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
    })
}
const deleteProgram = (authKey, id, params, payload) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_DELETE_PROGRAM, { key }))
  const request = await deleteProgramRequest(authKey, id, params, payload).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_DELETE_PROGRAM_ERROR, { key }))
    }
  )
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DELETE_PROGRAM, { key }))
    return 'deleteProgram success'
  }
  return 'deleteProgram failed'
}

const getProgramById = id => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_PROGRAM_ENTITY_DATA, { key }))
  const request = await getProgramByIdExtended(id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_PROGRAM_ENTITY_DATA_ERROR, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_PROGRAM_ENTITY_DATA, { key, data: request.data }))
    return 'deleteProgram success'
  }
  return 'deleteProgram failed'
}

const createEmptyProgram = name => async dispatch => {
  const key = null
  try {
    dispatch(actionCreater(REQUEST_CREATE_EMPTY_PROGRAM, { key }))
    const request = await createEmptyOnBoardingProgram(name)
    dispatch(actionCreater(RESPONSE_CREATE_EMPTY_PROGRAM, { key }))
    return Promise.resolve(request.data)
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_CREATE_EMPTY_PROGRAM_ERROR, { key }))
    return Promise.reject(err)
  }
}

const assignPhoneById = (programId, phoneNumberId, options) => async dispatch => {
  const key = programId
  dispatch(actionCreater(REQUEST_ASSIGN_PHONE_BY_ID, { key }))
  const request = await assignPhoneNumber(programId, phoneNumberId, options).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_ASSIGN_PHONE_BY_ID_ERROR, { key }))
    }
  )
  if (request) {
    dispatch(actionCreater(RESPONSE_ASSIGN_PHONE_BY_ID, { key }))
    return 'assignPhoneById success'
  }

  return 'assignPhoneById failed'
}

const unassignPhoneById = (id, options) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_UNASSIGN_PHONE_BY_ID, { key }))
  const request = await unassignPhoneNumber(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_UNASSIGN_PHONE_BY_ID_ERROR, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_UNASSIGN_PHONE_BY_ID, { key }))
    return 'unassignPhoneById success'
  }

  return 'unassignPhoneById failed'
}

const resetForm = key => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key }))

export default {
  resetForm,
  postProgramsForm,
  putProgramsForm,
  deleteProgram,
  getProgramById,
  assignPhoneById,
  unassignPhoneById,
  createEmptyProgram
}
