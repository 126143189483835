/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import helpers from './helpers'
import { defaultActivityState } from './reducer'

const { activityStateKeyBuilder } = helpers

export const getDealFormActivitiesState = state => state.deals.dealFormActivities

export const getActivityFormState = (state, dealId, activityId) =>
  state.deals.dealFormActivities.activities[activityStateKeyBuilder(dealId, activityId)] ||
  defaultActivityState

export const getActivities = state =>
  Object.values(getDealFormActivitiesState(state).activities).map(value => value.data)
