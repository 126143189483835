/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { httpWrapper } from 'services/http/helpers'
import {
  ACTIVITY_SCHEDULES,
  GET_SERIES_BY_ID_URL,
  SCHEDULE_SERIES_DAY_URL,
  CANCEL_SERIES_BY_ID_URL,
  CANCEL_OCCURRENCE_BY_ID_URL,
  ACTIVATE_SERIES_BY_ID_URL,
  ACTIVATE_OCCURRENCE_BY_ID_URL,
  GET_OVERLAPS_CLOSURE_URL
} from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'

/**
 *
 * @param {string} id - activity id
 * @param {Object} params
 * @param {string} params.startDate
 * @param {string} params.endDate
 * @returns {Promise}
 */
export const getSchedules = (id, params) =>
  httpWrapper({
    url: buildPath(ACTIVITY_SCHEDULES, { id }),
    method: 'get',
    params
  })

/**
 *
 * @param {string} activityId
 * @param {string} seriesId
 * @returns {Promise<Object>}
 */
export const getScheduleSeriesById = (activityId, seriesId) =>
  httpWrapper({
    url: buildPath(GET_SERIES_BY_ID_URL, { id: activityId, seriesId }),
    method: 'get'
  })

/**
 *
 * @param {string} id - activity id
 * @param {Object} payload
 * @returns {Promise<string>}
 */
export const getClosureOverlaps = (id, payload) =>
  httpWrapper({
    url: buildPath(GET_OVERLAPS_CLOSURE_URL, { id }),
    method: 'post',
    data: payload
  })
/**
 *
 * @param {string} id - activity id
 * @param {Object} payload
 * @returns {Promise<string>}
 */
export const createScheduleSeries = (id, payload) =>
  httpWrapper({
    url: buildPath(ACTIVITY_SCHEDULES, { id }),
    method: 'post',
    data: payload
  })

/**
 *
 * @param {string} activityId
 * @param {string} seriesId
 * @param {Object} payload
 * @returns {Promise<void>}
 */
export const updateScheduleSeriesById = (activityId, seriesId, payload) =>
  httpWrapper({
    url: buildPath(GET_SERIES_BY_ID_URL, { id: activityId, seriesId }),
    method: 'put',
    data: payload
  })

/**
 *
 * @param {string} activityId
 * @param {string} seriesId
 * @returns {Promise<void>}
 */
export const activateScheduleSeriesById = (activityId, seriesId) =>
  httpWrapper({
    url: buildPath(ACTIVATE_SERIES_BY_ID_URL, { id: activityId, seriesId }),
    method: 'put'
  })

/**
 *
 * @param {string} activityId
 * @param {Object} payload
 * @returns {Promise<void>}
 */
export const activateScheduleOccurenceById = (activityId, payload) =>
  httpWrapper({
    url: buildPath(ACTIVATE_OCCURRENCE_BY_ID_URL, { id: activityId }),
    method: 'put',
    data: payload
  })

/**
 *
 * @param {string} activityId
 * @param {string} seriesId
 * @param {Object} payload
 * @returns {Promise<void>}
 */
export const cancelScheduleSeriesById = (activityId, seriesId, payload) =>
  httpWrapper({
    url: buildPath(CANCEL_SERIES_BY_ID_URL, { id: activityId, seriesId }),
    method: 'put',
    data: payload
  })

/**
 *
 * @param {string} activityId
 * @param {Object} payload
 * @returns {Promise<void>}
 */
export const cancelScheduleOccurrenceById = (activityId, payload) =>
  httpWrapper({
    url: buildPath(CANCEL_OCCURRENCE_BY_ID_URL, { id: activityId }),
    method: 'put',
    data: payload
  })

/**
 *
 * @param {string} id  - activity id
 * @param {string} seriesId
 * @returns {Promise<void>}
 */

export const deleteScheduleSeriesById = (id, seriesId) =>
  httpWrapper({
    url: buildPath(GET_SERIES_BY_ID_URL, { id, seriesId }),
    method: 'delete'
  })

/**
 *
 * @param {string} id  - activity id
 * @param {array} body  - occurrences
 * @param {string} body[].seriesId - series id
 * @param {array} body[].days - occurrence days
 * @param {string} body[].days[] - occurrence day
 * @returns {Promise<void>}
 */
export const deleteScheduleOccurrences = (id, data) =>
  httpWrapper({
    url: buildPath(ACTIVITY_SCHEDULES, { id }),
    method: 'delete',
    data
  })

/**
 *
 * @param {string} id  - activity id
 * @param {string} seriesId
 * @param {string} day - mm/dd/yyyy
 * @returns {Promise<void>}
 */
export const getScheduleOccurrenceByIdAndDay = (id, seriesId, day) =>
  httpWrapper({
    url: buildPath(SCHEDULE_SERIES_DAY_URL, { id, seriesId }),
    method: 'get',
    params: { day }
  })

/**
 *
 * @param {string} activityId
 * @param {string} seriesId
 * @param {Object} payload
 * @returns {Promise<void>}
 */
export const updateScheduleOccurrenceById = (activityId, seriesId, payload) =>
  httpWrapper({
    url: buildPath(SCHEDULE_SERIES_DAY_URL, { id: activityId, seriesId }),
    method: 'put',
    data: payload
  })
