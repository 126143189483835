/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { useDispatch, useSelector } from 'react-redux'
import { useLoading } from 'hooks/hooks'
import { selectors, actions } from 'reducers/location/locationCampaigns'
import { useCallback } from 'react'
import { useRootModal } from 'hooks/useRootModal'
import { useAction } from 'hooks/useAction'
import { CampaignsModal } from './CampaignsModal'
import { buildSearchByCampaignId } from './helpers'

const { getLocationCampaignsState } = selectors

export function useLocationCampaigns() {
  const { dataState } = useSelector(state => getLocationCampaignsState(state))
  const dispatch = useDispatch()
  const rootModal = useRootModal()
  const getLocationCampaigns = useAction(actions.getLocationCampaigns)
  const resetForm = useAction(actions.resetForm)
  const [isLoading] = useLoading(dataState)

  const resetCampaigns = useCallback(() => dispatch(resetForm), [dispatch, resetForm])

  const onClose = useCallback(() => {
    resetCampaigns()
    rootModal.dequeue()
  }, [resetCampaigns, rootModal])

  const getCampaigns = useCallback(
    async id => {
      const { data } = await getLocationCampaigns([buildSearchByCampaignId(id)])
      const canProceed = data.length === 0

      if (!canProceed) {
        rootModal.enqueue(CampaignsModal, {
          isOpen: true,
          onClose,
          data
        })
      }

      return {
        canProceed,
        data
      }
    },
    [rootModal, getLocationCampaigns, onClose]
  )

  return {
    isLoading,
    getCampaigns,
    resetCampaigns
  }
}
