/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'

import {
  toPendingProgram,
  approveProgram,
  rejectProgram,
  createDraftProgramFromActive,
  updateProgram
} from 'services/http/program'

import helpers from './helpers'

const {
  RESET_APPROVAL_STATE,
  REQUEST_APPROVAL,
  RESPONSE_APPROVAL,
  RESPONSE_APPROVAL_ERROR,
  REQUEST_PROGRAM_DRAFT,
  RESPONSE_PROGRAM_DRAFT,
  RESPONSE_PROGRAM_DRAFT_ERROR
} = helpers

const createDraftProgram = (authKey, id, contractData) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_PROGRAM_DRAFT, { key }))
  const request = await createDraftProgramFromActive(authKey, id, contractData).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_PROGRAM_DRAFT_ERROR, { key }))
    }
  )
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_PROGRAM_DRAFT, { key }))
    dispatch(send(message))
    return 'createDraftProgram success'
  }

  return 'createDraftProgram failed'
}
const submitForApproval = (authKey, id, commentData, contractData = {}) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_APPROVAL, { key }))
  updateProgram(authKey, contractData)
    .then(() => toPendingProgram(authKey, id, commentData))
    .then(request => {
      const {
        message: { message, send }
      } = request
      dispatch(actionCreater(RESPONSE_APPROVAL, { key }))
      dispatch(send(message))
      return 'submitForApproval success'
    })
    .catch(({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_APPROVAL_ERROR, { key }))
    })

  return 'submitForApproval failed'
}

const approveDraft = (id, contractData, programData) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_APPROVAL, { key }))
  try {
    await updateProgram(null, programData)
    const request = await approveProgram(id, contractData)
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_APPROVAL, { key }))
    dispatch(send(message))
    return 'approveDraft success'
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_APPROVAL_ERROR, { key }))
  }

  return 'approveDraft failed'
}

const rejectDraft = (authKey, id, contractData) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_APPROVAL, { key }))
  const request = await rejectProgram(authKey, id, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_APPROVAL_ERROR, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_APPROVAL, { key }))
    dispatch(send(message))
    return 'rejectDraft success'
  }

  return 'rejectDraft failed'
}

const resetState = key => dispatch => dispatch(actionCreater(RESET_APPROVAL_STATE, { key }))

export default {
  resetState,
  submitForApproval,
  approveDraft,
  rejectDraft,
  createDraftProgram
}
