/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const BARCODE_TYPE_LABEL = 'Select Barcode Type:'
export const SELECT_FILE_CODE_LIST_LABEL = 'Select File (code list):'
export const TICKET_TEMPLATE_LABEL = 'Ticket Template:'

export const DEFAULT_HEADER_TEMPLATE = 'Use Default Template'
export const CUSTOM_HEADER_TEMPLATE = 'Use Custom Template'

export const SELECT_FILE_CODE_LIST_PLACEHOLDER = '+ Drag File Here or Click To Browse'
export const SELECT_TEMPLATE_PLACEHOLDER = '+ Drag File Here or Click To Browse'

export const BARCODE_TYPE_PLACEHOLDER = 'Barcode Type'

export const GENERATE_TEXT = 'Generate'

export const DEFAULT_TEMPLATE_RADIO_TYPE = 'DEFAULT_TEMPLATE_RADIO_TYPE'
export const CUSTOM_TEMPLATE_RADIO_TYPE = 'CUSTOM_TEMPLATE_RADIO_TYPE'

export const DOWNLOAD_TEMPLATE = 'download'
export const REMOVE_TEMPLATE = 'remove'
export const TEMPLATE_LOADED_LABEL = 'Template Loaded'

export const DOWNLOAD_TEMPLATE_LABEL = 'Template'
