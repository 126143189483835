/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { SEASON_RATE_DATA_URL } from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'

export const getRateAdjustmentList = (id, programId) =>
  httpWrapper({
    url: buildPath(SEASON_RATE_DATA_URL, {
      id,
      programId
    }),
    method: 'get'
  })
export const postRateAdjustmentList = (id, programId, data) =>
  httpWrapper({
    url: buildPath(SEASON_RATE_DATA_URL, {
      id,
      programId
    }),
    method: 'post',
    data
  })
