/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React from 'react'

import { SIGN_CURRENCY } from './types'
import styles from './Icons.scss'

export const CurrencyIcon = () => (
  <span className={styles.commonStyles}>{SIGN_CURRENCY}</span>
)
