/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { TICKET_TYPES, FORM_NAME } from './types'

const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

const REQUEST_GET_TICKET_TYPES = `${REQUEST_TYPE}_${TICKET_TYPES}`
const RESPONSE_GET_TICKET_TYPES = `${RESPONSE_TYPE}_${TICKET_TYPES}`
const RESPONSE_GET_TICKET_TYPES_ERROR = `${RESPONSE_ERROR_TYPE}_${TICKET_TYPES}`

export default {
  RESET_DATA_STATE,
  REQUEST_GET_TICKET_TYPES,
  RESPONSE_GET_TICKET_TYPES,
  RESPONSE_GET_TICKET_TYPES_ERROR
}
