/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import { isToday } from 'helpers/dateHelpers'

import {
  NAME,
  ACTIVATE,
  DEACTIVATE,
  ACTIVATION_SUCCESS_MESSAGE,
  DEACTIVATION_SUCCESS_MESSAGE,
  DEACTIVATION_CANCELED_MESSAGE,
  DEACTIVATION_SET_MESSAGE,
  DEACTIVATION_UPDATE_MESSAGE
} from './types'

const REQUEST_ACTIVATE = `${REQUEST_TYPE}_${ACTIVATE}`
const RESPONSE_ACTIVATE = `${RESPONSE_TYPE}_${ACTIVATE}`
const RESPONSE_ACTIVATE_ERROR = `${RESPONSE_ERROR_TYPE}_${ACTIVATE}`

const REQUEST_DEACTIVATE = `${REQUEST_TYPE}_${DEACTIVATE}`
const RESPONSE_DEACTIVATE = `${RESPONSE_TYPE}_${DEACTIVATE}`
const RESPONSE_DEACTIVATE_ERROR = `${RESPONSE_ERROR_TYPE}_${DEACTIVATE}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

const buildDeactivationMessage = data => {
  const { date, isEdit = false } = data

  if (isToday(date)) {
    return DEACTIVATION_SUCCESS_MESSAGE
  }

  const message = isEdit ? DEACTIVATION_UPDATE_MESSAGE : DEACTIVATION_SET_MESSAGE

  return `${message} ${date}`
}

export default {
  REQUEST_ACTIVATE,
  RESPONSE_ACTIVATE,
  RESPONSE_ACTIVATE_ERROR,
  REQUEST_DEACTIVATE,
  RESPONSE_DEACTIVATE,
  RESPONSE_DEACTIVATE_ERROR,
  RESET_DATA_STATE,
  ACTIVATION_SUCCESS_MESSAGE,
  buildDeactivationMessage,
  DEACTIVATION_CANCELED_MESSAGE
}
