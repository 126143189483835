/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import { NAVIGATION_DEFAULT_TYPE } from 'constants/navigationTypes'

import helpers from './helpers'

const { UPDATE_NAVIGATION_TYPE_STATE } = helpers

const initialState = {
  navigationType: NAVIGATION_DEFAULT_TYPE
}

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NAVIGATION_TYPE_STATE:
      return {
        ...state,
        navigationType: action.payload
      }
    default:
      return state
  }
}

export default filterActions(navigationReducer, [UPDATE_NAVIGATION_TYPE_STATE, initialState])
