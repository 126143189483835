/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

export const ADDRESS = 'ADDRESS'
export const SEASON = 'SEASON'
export const ACTIVITY_PRODUCT = 'ACTIVITY_PRODUCT'
export const ACTIVITY_PROVIDER = 'ACTIVITY_PROVIDER'
export const CAMPAIGNS = 'CAMPAIGNS'
export const PROGRAM = 'PROGRAM'
export const PROGRAMS_DIFF = 'PROGRAMS_DIFF'
export const ACCOUNT = 'ACCOUNT'
export const PMS = 'PMS'
export const PMC = 'PMC'
export const ACTIVITY_CATEGORY = 'ACTIVITY_CATEGORY'
export const LOCATION = 'LOCATION'
export const TILE_ACCORDION = 'TILE_ACCORDION'
export const ACTIVITY_MODULE = 'ACTIVITY_MODULE'
export const ACTIVITY_TAG = 'ACTIVITY_TAG'
export const RMS = 'RMS'
export const UNIQ_PHONE_NUMBER = 'UNIQ_PHONE_NUMBER'
export const DEAL = 'DEAL'
export const UNIT_COMMANDER = 'UNIT_COMMANDER'
export const ELIGIBILITY = 'ELIGIBILITY'
export const MARKET = 'MARKET'
export const UNIT_MANAGER = 'UNIT_MANAGER'
export const UNIT = 'UNIT'
export const UNIT_DETAILS = 'UNIT_DETAILS'
export const DEFAULT_TYPE = 'DEFAULT_TYPE'
export const TICKETS_SETTINGS = 'TICKETS_SETTINGS'

export default {
  ADDRESS,
  SEASON,
  ACTIVITY_PRODUCT,
  ACTIVITY_PROVIDER,
  PROGRAM,
  PROGRAMS_DIFF,
  ACCOUNT,
  PMS,
  PMC,
  ACTIVITY_CATEGORY,
  LOCATION,
  TILE_ACCORDION,
  ACTIVITY_TAG,
  ACTIVITY_MODULE,
  RMS,
  UNIQ_PHONE_NUMBER,
  DEAL,
  UNIT_COMMANDER,
  UNIT,
  ELIGIBILITY,
  MARKET,
  DEFAULT_TYPE,
  TICKETS_SETTINGS
}
