/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'

import { getList, getListByProviderId } from 'services/http/activityCategory'
import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_LIST_ERROR,
  REQUEST_LIST_BY_PROVIDER,
  RESPONSE_LIST_BY_PROVIDER,
  RESPONSE_LIST_BY_PROVIDER_ERROR
} = helpers

const getCategoriesList = (authKey, options = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await getList(authKey, {
    ...options
  }).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_LIST_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { ...request.data }))
    return 'getCategoriesList success'
  }

  return 'getCategoriesList failed'
}

const getCategoriesByProviderId = id => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST_BY_PROVIDER))
  const request = await getListByProviderId(id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(RESPONSE_LIST_BY_PROVIDER_ERROR)
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST_BY_PROVIDER, request.data))
    return 'getCategoriesByProvider success'
  }

  return 'getCategoriesByProvider failed'
}

export default {
  getCategoriesList,
  getCategoriesByProviderId
}
