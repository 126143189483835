/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { updateUnitsStatusBulk, uploadUnits } from 'services/http/pmcUnits'

import helpers from './helpers'

const {
  REQUEST_CHANGE_STATUS_BULK,
  RESPONSE_CHANGE_STATUS_BULK,
  RESPONSE_ERROR_CHANGE_STATUS_BULK,
  RESET_DATA_STATE,
  UNIT_STATUS_UPDATED_MESSAGE,
  REQUEST_UPLOAD_UNITS_FILE,
  RESPONSE_UPLOAD_UNITS_FILE,
  RESPONSE_ERROR_UPLOAD_UNITS_FILE,
  UNITS_LOADED_MESSAGE
} = helpers

const changeUnitsStatus = (pmcId, data) => async dispatch => {
  dispatch(actionCreater(REQUEST_CHANGE_STATUS_BULK))
  const request = await updateUnitsStatusBulk(pmcId, data).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_CHANGE_STATUS_BULK))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: UNIT_STATUS_UPDATED_MESSAGE }))
    dispatch(actionCreater(RESPONSE_CHANGE_STATUS_BULK))
    return 'changeUnitsStatus success'
  }

  return 'changeUnitsStatus failed'
}

const uploadUnitsViaFile = (pmcId, files) => async dispatch => {
  dispatch(actionCreater(REQUEST_UPLOAD_UNITS_FILE))
  const request = await uploadUnits(pmcId, files).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_UPLOAD_UNITS_FILE))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: UNITS_LOADED_MESSAGE }))
    dispatch(actionCreater(RESPONSE_UPLOAD_UNITS_FILE))
    return 'uploadUnitsViaFile success'
  }

  return 'uploadUnitsViaFile failed'
}

const resetState = () => dispatch => dispatch(actionCreater(RESET_DATA_STATE))

export default {
  resetState,
  changeUnitsStatus,
  uploadUnitsViaFile
}
