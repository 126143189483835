/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const DiscountsSlider = loadable(
  () =>
    import(
      /* webpackChunkName: 'DiscountsSlider', webpackPrefetch: true */ 'modules/Discounts/DiscountsSlider'
    ),
  {
    resolveComponent: components => components.DiscountsSlider
  }
)

export * from './DiscountsForm'
export * from './DiscountsList'

export { DiscountsSlider }
export { DiscountForm } from './DiscountForm'
export * from './helpers'
