/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import isObject from 'lodash/isObject'
import { actionCreater } from 'helpers/actionHelpers'
import { resetCommunication, checkResetProgress } from 'services/http/communicationConnection'

import helpers from './helpers'

const {
  REQUEST_RESET_COMMUNICATION,
  RESPONSE_RESET_COMMUNICATION,
  RESPONSE_RESET_COMMUNICATION_ERROR,
  REQUEST_CHECK_RESET_PROGRESS,
  RESPONSE_CHECK_RESET_PROGRESS,
  RESPONSE_CHECK_RESET_PROGRESS_ERROR,
  RESET_DATA_STATE
} = helpers

const resetCommunicationAction = (authKey, contractData, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_RESET_COMMUNICATION))
  const request = await resetCommunication(authKey, contractData, params).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_RESET_COMMUNICATION_ERROR))
    }
  )
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_RESET_COMMUNICATION))
    dispatch(send(message))
    return 'resetCommunicationAction success'
  }

  return 'resetCommunicationAction failed'
}
const checkResetProgressAction = (authKey, contractData, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_CHECK_RESET_PROGRESS))
  const request = await checkResetProgress(authKey, contractData, params).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_CHECK_RESET_PROGRESS_ERROR))
    }
  )
  if (request) {
    const responceData = isObject(request.data) ? request.data : {}
    dispatch(actionCreater(RESPONSE_CHECK_RESET_PROGRESS, responceData))
    return 'checkResetProgressAction success'
  }
  return 'checkResetProgressAction failed'
}

const resetState = () => ({
  type: RESET_DATA_STATE
})

export default {
  resetState,
  resetCommunicationAction,
  checkResetProgressAction
}
