/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useUpdateSomeDataState, useLoading } from 'hooks'
import { withRemovalModal } from 'decorators'
import { DATA_STATE_RECIEVED, DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { BasicButton } from '@xplorie/ui-commons'
import { PIECurtainLoader } from 'components/Loaders'
import { ORANGE_SCHEMA, SAVE_TITLE } from 'constants/buttonsOptions'
import { GroupForm } from './GroupForm'
import { FilesList } from './FilesList'

import {
  getGroupFormId,
  groupFormDisabledBuilder,
  groupFormLoadingBuilder,
  onSubmitHandler,
  onDeleteHandler,
  onUploadFilesHandler,
  onDownLoadFileHandler,
  fileListLoadingBuilder,
  onRemoveFilesHandler,
  onGenerateBarcode,
  buildTemplateName
} from './helpers'
import { DELETE_GROUP_TITLE, DELETE_MESSAGE, YES_TEXT, NO_TEXT, DELETE_FILES_TITLE } from './types'

import styles from './TicketGroupForm.scss'

// TODO: need refactoring

const DeleteButton = withRemovalModal(BasicButton, {
  message: DELETE_MESSAGE,
  cancelText: NO_TEXT,
  actionText: YES_TEXT
})

export function TicketGroupForm(props) {
  const {
    dataId,
    getEntityDataById,
    entityData,
    formDataState,
    activityId,
    getFilesByGroupId,
    resetForm,
    removeInvsDataState,
    groupFilesDataState,
    downloadCustomTemplate,
    removeCustomTemplate,
    downloadBarcodeTemplate,
    downloadBarcodeTemplateDataState
  } = props
  const groupFormApi = useRef(null)
  const groupFormId = getGroupFormId(props)
  const formData = { id: null, activityId, ...entityData }
  const [isDownloadingBarcodeTemplate] = useLoading(downloadBarcodeTemplateDataState)
  const isGroupFormLoading = groupFormLoadingBuilder(props)
  const isGroupFormDisabled = groupFormDisabledBuilder(props)
  const isFileListLoading = fileListLoadingBuilder(props)

  const [checkedFiles, setCheckedFilesState] = useState({})

  const isDisabledDeleteFilesButton =
    !Object.keys(checkedFiles).length || removeInvsDataState === DATA_STATE_REQUESTING

  const onGetData = () => {
    if (groupFormId) {
      getEntityDataById(activityId, groupFormId)
    }
  }

  const onSelectFile = value => {
    const { id } = value
    if (checkedFiles[id]) {
      delete checkedFiles[id]
      setCheckedFilesState({ ...checkedFiles })
    } else {
      setCheckedFilesState({ ...checkedFiles, [id]: value })
    }
  }

  const onDeleteFiles = () => {
    onRemoveFilesHandler(props)(Object.values(checkedFiles))
  }

  const onSubmitForm = () => {
    groupFormApi.current.submitForm()
  }

  const onGetFilesByGroupId = options => {
    if (groupFormId) {
      getFilesByGroupId(activityId, groupFormId, options)
    }
  }

  const onDownloadCustomTemplate = useCallback(() => {
    downloadCustomTemplate(activityId, groupFormId, buildTemplateName(formData.name))
  }, [activityId, downloadCustomTemplate, formData, groupFormId])

  const onRemoveCustomTemplate = useCallback(() => {
    removeCustomTemplate(activityId, groupFormId)
  }, [activityId, groupFormId, removeCustomTemplate])

  const onDownloadBarcodeTemplate = useCallback(() => {
    downloadBarcodeTemplate(activityId, groupFormId)
  }, [activityId, downloadBarcodeTemplate, groupFormId])

  useEffect(() => {
    if (dataId) {
      getEntityDataById(activityId, dataId)
      onGetFilesByGroupId()
    } else {
      resetForm()
    }
    return () => resetForm()
  }, [])

  useUpdateSomeDataState(
    () => {
      if (groupFormId) {
        getEntityDataById(activityId, groupFormId)
      }
    },
    [formDataState],
    DATA_STATE_RECIEVED
  )

  useUpdateSomeDataState(
    () => {
      setCheckedFilesState({})
    },
    [groupFilesDataState],
    DATA_STATE_RECIEVED
  )

  return (
    <div className={styles.wrapper}>
      {isDownloadingBarcodeTemplate && <PIECurtainLoader />}
      <div className={styles.formWrapper}>
        <GroupForm
          getApi={api => {
            groupFormApi.current = api
          }}
          isLoading={isGroupFormLoading}
          isDisabled={isGroupFormDisabled}
          dataId={groupFormId}
          onGetData={onGetData}
          onSubmit={onSubmitHandler(props)}
          onUpload={onUploadFilesHandler(props)}
          onGenerateBarcode={onGenerateBarcode(props)}
          downloadCustomTemplate={onDownloadCustomTemplate}
          downloadBarcodeTemplate={onDownloadBarcodeTemplate}
          removeCustomTemplate={onRemoveCustomTemplate}
          formData={formData}
        />
      </div>
      <FilesList
        isLoading={isFileListLoading}
        onDownload={onDownLoadFileHandler(props)}
        onGetFiles={onGetFilesByGroupId}
        onSelectFile={onSelectFile}
        checkedFiles={checkedFiles}
      />
      <div className={styles.buttonsWrapper}>
        <div className={styles.buttons}>
          <BasicButton
            colorSchema={ORANGE_SCHEMA}
            onClick={onDeleteFiles}
            disabled={isDisabledDeleteFilesButton}
          >
            {DELETE_FILES_TITLE}
          </BasicButton>
        </div>
        <div className={styles.buttons}>
          <BasicButton
            colorSchema={ORANGE_SCHEMA}
            disabled={isGroupFormDisabled}
            onClick={onSubmitForm}
          >
            {SAVE_TITLE}
          </BasicButton>
          {dataId && (
            <DeleteButton
              colorSchema={ORANGE_SCHEMA}
              disabled={isGroupFormDisabled}
              onClick={onDeleteHandler(props, groupFormApi)}
            >
              {DELETE_GROUP_TITLE}
            </DeleteButton>
          )}
        </div>
      </div>
    </div>
  )
}
TicketGroupForm.propTypes = {
  dataId: PropTypes.string,
  getEntityDataById: PropTypes.func.isRequired,
  activityId: PropTypes.string.isRequired,
  uploadDataState: PropTypes.string.isRequired,
  formDataState: PropTypes.string.isRequired,
  getFilesByGroupId: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  entityData: PropTypes.object,
  removeInvsDataState: PropTypes.string.isRequired,
  groupFilesDataState: PropTypes.string.isRequired,
  downloadCustomTemplate: PropTypes.func.isRequired,
  removeCustomTemplate: PropTypes.func.isRequired,
  removeTemplateDataState: PropTypes.string.isRequired,
  downloadBarcodeTemplate: PropTypes.func.isRequired,
  downloadBarcodeTemplateDataState: PropTypes.string.isRequired
}
TicketGroupForm.defaultProps = {
  dataId: null,
  entityData: {}
}
