/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  getEntityById,
  removeEntity,
  activateEntity,
  deactivateEntity,
  draftDeal
} from 'services/http/deals'

import helpers, { buildKey } from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID_ERROR,
  REQUEST_DELETE_DEAL,
  RESPONSE_DELETE_DEAL,
  RESPONSE_DELETE_DEAL_ERROR,
  REQUEST_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL_ERROR,
  REQUEST_DEACTIVATE_DEAL,
  RESPONSE_DEACTIVATE_DEAL,
  RESPONSE_DEACTIVATE_DEAL_ERROR,
  REQUEST_DRAFT_DEAL,
  RESPONSE_DRAFT_DEAL,
  RESPONSE_DRAFT_DEAL_ERROR,
  buildActivateSuccessMessage,
  buildDeactivateSuccessMessage
} = helpers

/**
 *
 * @param {string} id - deal id
 * @param {Object} [params] - request params
 */
const getDealDataById = (accessKey, id, params) => async dispatch => {
  const key = buildKey(accessKey)
  dispatch(actionCreater(REQUEST_GET_DATA_BY_ID, { key }))
  const request = await getEntityById(id, params).catch(err => {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_DATA_BY_ID_ERROR, { key }))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_GET_DATA_BY_ID, { data: request.data, key }))
    return 'getDealDataById success'
  }

  return 'getDealDataById failed'
}

/**
 *
 * @param {string} dealId - deal id
 * @param {Object} [params] - request params
 */
const removeDeal = (dealId, params) => async dispatch => {
  const key = dealId
  dispatch(actionCreater(REQUEST_DELETE_DEAL, { key }))
  const request = await removeEntity(dealId, params).catch(err => {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DELETE_DEAL_ERROR), { key })
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_DELETE_DEAL, { key }))
    return 'removeDeal success'
  }

  return 'removeDeal failed'
}

/**
 *
 * @param {string} dealId - deal id
 * @param {Object} params - additional params
 */
const activateDeal = (accessId, dealId) => async dispatch => {
  const key = buildKey(accessId)
  dispatch(actionCreater(REQUEST_ACTIVATE_DEAL, { key }))
  try {
    await activateEntity(dealId)
    const request = await getEntityById(dealId)
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildActivateSuccessMessage(request.data.name) }))
    dispatch(actionCreater(RESPONSE_ACTIVATE_DEAL, { data: request.data, key }))
    return request.data
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ACTIVATE_DEAL_ERROR, { key }))
  }
  return Promise.reject()
}

/**
 *
 * @param {string} dealId- deal id
 * @param {Object} params - additional params
 */
const deactivateDeal = (accessId, dealId) => async dispatch => {
  const key = buildKey(accessId)
  dispatch(actionCreater(REQUEST_DEACTIVATE_DEAL, { key }))
  try {
    await deactivateEntity(dealId)
    const request = await getEntityById(dealId)
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildDeactivateSuccessMessage(request.data.name) }))
    dispatch(actionCreater(RESPONSE_DEACTIVATE_DEAL, { data: request.data, key }))
    return request.data
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DEACTIVATE_DEAL_ERROR, { key }))
  }
  return Promise.reject()
}

/**
 *
 * @param {string} dealId- deal id
 * @param {Object} params - additional params
 */
const makeDraftDeal = (accessId, dealId) => async dispatch => {
  const key = buildKey(accessId)
  dispatch(actionCreater(REQUEST_DRAFT_DEAL, { key }))
  try {
    await draftDeal(dealId)
    const request = await getEntityById(dealId)
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DRAFT_DEAL, { data: request.data, key }))
    return request.data
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DRAFT_DEAL_ERROR, { key }))
  }
  return Promise.reject()
}

const resetForm = dealId => dispatch => {
  const key = buildKey(dealId)
  dispatch(actionCreater(RESET_DATA_STATE, { key }))
}

export default {
  resetForm,
  getDealDataById,
  removeDeal,
  activateDeal,
  deactivateDeal,
  makeDraftDeal
}
