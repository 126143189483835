/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
// title
export const META_TITLE_LABEL = 'Title'
export const META_TITLE_PLACEHOLDER = 'Title'
export const META_TITLE_FIELD = 'title'

// displayName
export const DISPLAY_NAME_LABEL = 'Display Name'
export const DISPLAY_NAME_PLACEHOLDER = 'Display Name'
export const DISPLAY_NAME_FIELD = 'displayName'

// coverImage
export const COVER_IMAGE_LABEL = 'Cover Image'
export const COVER_IMAGE_PLACEHOLDER = 'Cover Image'
export const COVER_IMAGE_FIELD = 'coverImage'

// description
export const META_DESCRIPTION_PLACEHOLDER = 'Description'
export const META_DESCRIPTION_LABEL = 'Description'
export const META_DESCRIPTION_FIELD = 'description'
export const META_DESCRIPTION_MAX_SYMBOLS_COUNT = 400

// keywords
export const META_KEYWORDS_LABEL = 'Keywords'
export const META_KEYWORDS_PLACEHOLDER = 'Keywords'
export const META_KEYWORDS_FIELD = 'keywords'

// destinationSq
export const DESTINATION_SQ_LABEL = 'Destination Square Image URL'
export const DESTINATION_SQ_PLACEHOLDER = 'Add Image URL'
export const DESTINATION_SQ_FIELD = 'destinationSqUrl'

// destinationMap
export const DESTINATION_MAP_LABEL = 'Destination Map URL'
export const DESTINATION_MAP_PLACEHOLDER = 'Add Google Address URL'
export const DESTINATION_MAP_FIELD = 'destinationMapUrl'
