/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { formInitialState } from './reducer'
import { buildKey } from './helpers'

export const getDiscountDealFormStates = state => state.discountDeal.discountDealForm

export const getDiscountDealFormStateById = (state, key) =>
  state.discountDeal.discountDealForm[buildKey(key)] || formInitialState
