/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_SEARCH,
  RESPONSE_SEARCH,
  RESPONSE_SEARCH_ERROR,
  RESET_DATA_TYPE_STATE,
  isOldRequest
} = helpers

export const initialState = {}
export const defaultContract = {
  dataState: DATA_STATE_NOT_REQUESTED,
  data: [],
  itemsPerPage: 10,
  pageNumber: 1,
  itemsCount: 0,
  meta: {}
}

const columnSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SEARCH:
      return {
        ...state,
        [action.payload.key]: {
          ...defaultContract,
          ...state[action.payload.key],
          dataState: DATA_STATE_REQUESTING,
          meta: action.payload.meta
        }
      }
    case RESPONSE_SEARCH:
      return (() => {
        const currentState = state[action.payload.key]
        return !currentState || isOldRequest(action.payload, currentState)
          ? { ...state }
          : {
              ...state,
              [action.payload.key]: {
                ...state[action.payload.key],
                dataState: DATA_STATE_RECIEVED,
                data: action.payload.data,
                ...action.payload.pagination
              }
            }
      })()
    case RESPONSE_SEARCH_ERROR:
      return (() => {
        const currentState = state[action.payload.key]
        return !currentState || isOldRequest(action.payload, currentState)
          ? { ...state }
          : {
              ...state,
              [action.payload.key]: { ...state[action.payload.key], dataState: DATA_STATE_ERROR }
            }
      })()
    case RESET_DATA_STATE:
      return {
        ...initialState
      }
    case RESET_DATA_TYPE_STATE:
      return (() => {
        const { [action.payload]: removedState, ...restState } = state
        return {
          ...restState
        }
      })()
    default:
      return state
  }
}

export default filterActions(columnSearchReducer, [
  RESET_DATA_STATE,
  REQUEST_SEARCH,
  RESPONSE_SEARCH,
  RESPONSE_SEARCH_ERROR,
  RESET_DATA_TYPE_STATE
])
