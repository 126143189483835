/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useContext, useCallback, useRef } from 'react'
import { useDidMount } from 'hooks'
import { FilteredSearchContext } from './context'

export function useFilteredSearch(props) {
  const { onGetData, buildFilters, ignoreDidMount } = props
  const filteredSearchApi = useRef()

  const getFilters = useCallback(() => buildFilters(filteredSearchApi.current.getFilters()), [
    buildFilters
  ])
  const getParams = useCallback(() => filteredSearchApi.current.getParams(), [])

  const onChange = useCallback(
    ({ filters, params }) => {
      onGetData(buildFilters(filters), params)
    },
    [buildFilters, onGetData]
  )

  const onGetDataHandler = useCallback(
    params => {
      if (filteredSearchApi.current) {
        filteredSearchApi.current.setParams(params)
      }
    },
    [filteredSearchApi]
  )

  const onGetApi = useCallback(api => {
    filteredSearchApi.current = api
  }, [])

  useDidMount(() => {
    if (ignoreDidMount) {
      return
    }
    filteredSearchApi.current.emitChange()
  })

  return {
    getFilters,
    getParams,
    onChange,
    onGetData: onGetDataHandler,
    onGetApi
  }
}

export function useFilters() {
  const { filters, setFilter } = useContext(FilteredSearchContext)

  return [filters, setFilter]
}

export function useFilteredState() {
  const { getState } = useContext(FilteredSearchContext)

  return [getState]
}

export function useParams() {
  const { params, setParams } = useContext(FilteredSearchContext)

  const setParameters = useCallback(
    newValue => {
      setParams(newValue)
    },
    [setParams]
  )

  return [{ value: params }, { setParameters }]
}
