import { REQUEST_TYPE, RESPONSE_TYPE, RESPONSE_ERROR_TYPE } from 'helpers/actionHelpers'
import { UNKNOWN_ERROR_MESSAGE } from 'helpers/types/messageHelpersTypes'
import {
  RECOVERY_PASSWORD_LINK_ACTION,
  RECOVERY_TOKEN_ACTION,
  RECOVERY_PASSWORD_ACTION
} from './types'

export const REQUEST_RECOVERY_PASSWORD_LINK_ACTION = `${REQUEST_TYPE}_${RECOVERY_PASSWORD_LINK_ACTION}`
export const RESPONSE_RECOVERY_PASSWORD_LINK_ACTION = `${RESPONSE_TYPE}_${RECOVERY_PASSWORD_LINK_ACTION}`
export const RESPONSE_RECOVERY_PASSWORD_LINK_ACTION_ERROR = `${RESPONSE_ERROR_TYPE}_${RECOVERY_PASSWORD_LINK_ACTION}`

export const REQUEST_RECOVERY_TOKEN_ACTION = `${REQUEST_TYPE}_${RECOVERY_TOKEN_ACTION}`
export const RESPONSE_RECOVERY_TOKEN_ACTION = `${RESPONSE_TYPE}_${RECOVERY_TOKEN_ACTION}`
export const RESPONSE_RECOVERY_TOKEN_ACTION_ERROR = `${RESPONSE_ERROR_TYPE}_${RECOVERY_TOKEN_ACTION}`

export const REQUEST_RECOVERY_PASSWORD_ACTION = `${REQUEST_TYPE}_${RECOVERY_PASSWORD_ACTION}`
export const RESPONSE_RECOVERY_PASSWORD_ACTION = `${RESPONSE_TYPE}_${RECOVERY_PASSWORD_ACTION}`
export const RESPONSE_RECOVERY_PASSWORD_ACTION_ERROR = `${RESPONSE_ERROR_TYPE}_${RECOVERY_PASSWORD_ACTION}`

export const RESET_DATA_STATE = `RESET_DATA_STATE_${RECOVERY_PASSWORD_LINK_ACTION}`

export const buildUnknownErrorMessage = () => ({
  dismissAfter: 4000,
  kind: 'warning',
  message: UNKNOWN_ERROR_MESSAGE
})

export default {
  REQUEST_RECOVERY_PASSWORD_LINK_ACTION,
  RESPONSE_RECOVERY_PASSWORD_LINK_ACTION,
  RESPONSE_RECOVERY_PASSWORD_LINK_ACTION_ERROR,
  REQUEST_RECOVERY_TOKEN_ACTION,
  RESPONSE_RECOVERY_TOKEN_ACTION,
  RESPONSE_RECOVERY_TOKEN_ACTION_ERROR,
  REQUEST_RECOVERY_PASSWORD_ACTION,
  RESPONSE_RECOVERY_PASSWORD_ACTION,
  RESPONSE_RECOVERY_PASSWORD_ACTION_ERROR,
  RESET_DATA_STATE,
  buildUnknownErrorMessage
}
