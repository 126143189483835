import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import helpers from './helpers'

import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE_NUMBER } from './types'

const {
  REQUEST_PREDEFINED_LIST,
  RESPONSE_PREDEFINED_LIST,
  RESPONSE_PREDEFINED_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_ROLE,
  RESPONSE_ROLE,
  RESPONSE_ROLE_ERROR
} = helpers

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  data: [],
  itemsCount: 0,
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
  pageNumber: DEFAULT_PAGE_NUMBER,
  accountRoleDataState: DATA_STATE_NOT_REQUESTED
}

const roleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PREDEFINED_LIST:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }

    case RESPONSE_PREDEFINED_LIST: {
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        data: action.payload.data,
        ...action.payload.pagination
      }
    }

    case RESPONSE_PREDEFINED_ERROR_LIST:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case REQUEST_ROLE:
      return {
        ...state,
        accountRoleDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_ROLE_ERROR:
      return {
        ...state,
        accountRoleDataState: DATA_STATE_ERROR
      }
    case RESPONSE_ROLE:
      return {
        ...state,
        accountRoleDataState: DATA_STATE_RECIEVED
      }
    case RESET_DATA_STATE:
      return initialState
    default:
      return state
  }
}

export default filterActions(roleListReducer, [
  REQUEST_PREDEFINED_LIST,
  RESPONSE_PREDEFINED_LIST,
  RESPONSE_PREDEFINED_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_ROLE,
  RESPONSE_ROLE,
  RESPONSE_ROLE_ERROR
])
