/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/reportsList'
import { withRouter } from 'react-router-dom'
import withAuthorization from 'helpers/withAuthorization'
import { ReportsComponent } from './Reports'

const mapStateToProps = state => ({
  reportType: state.reportsList.reportType
})

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

export const Reports = compose(
  withAuthorization,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReportsComponent)
