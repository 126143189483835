/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function ArrowIcon(props) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path d="M0 256l247.742 247.742v-148.645h264.258v-198.194h-264.258v-148.645z" />
    </svg>
  )
}
