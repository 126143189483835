/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { entityInitialState } from './reducer'

function getLocationMarketingFormState(state) {
  return state.location.marketingForm
}

function getMarketingFormStateById(state, id) {
  const marketingStates = getLocationMarketingFormState(state)
  return marketingStates[id] || entityInitialState
}

function getLocationMarketingChangedDataStates(state, id) {
  const marketingState = getMarketingFormStateById(state, id)
  return [marketingState.dataState]
}

function getMarketingFormIds(state) {
  return Object.keys(getLocationMarketingFormState(state))
}

function getMarketingsChangedDataStates(state) {
  return getMarketingFormIds(state).reduce(
    (acc, key) => [...acc, ...getLocationMarketingChangedDataStates(state, key)],
    []
  )
}

export default {
  getLocationMarketingFormState,
  getMarketingFormStateById,
  getLocationMarketingChangedDataStates,
  getMarketingFormIds,
  getMarketingsChangedDataStates
}
