/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import isUndefined from 'lodash/isUndefined'
import { DotsButton } from '@xplorie/ui-commons'
import { buildExportContract } from 'helpers/exportEntitiesHelper'
import { useLoading } from 'hooks'
import { DISCOUNT } from 'constants/searchTypes'
import { PIECurtainLoader } from 'components/Loaders'
import { buildCanExport } from './helpers'
import { EXPORT_TITLE } from './types'

export function DotsButtonWithExport({
  authUser,
  options,
  columns,
  exportEntityData,
  exportDataState,
  exportDiscounts,
  exportDiscountsState,
  onGetFilters,
  onGetParams,
  canExport: canExportParameter,
  filename,
  entity,
  ...rest
}) {
  const [isExporting] = useLoading(exportDataState)
  const canExport = useMemo(
    () => (isUndefined(canExportParameter) ? buildCanExport(authUser) : canExportParameter),
    [authUser, canExportParameter]
  )

  const exportData = useCallback(() => {
    if (!canExport) {
      return
    }

    const exportHandler = entity === DISCOUNT ? exportDiscounts : exportEntityData
    exportHandler(buildExportContract(columns, onGetFilters()), onGetParams(), filename)
  }, [
    canExport,
    columns,
    exportEntityData,
    filename,
    onGetFilters,
    onGetParams,
    exportDiscounts,
    entity
  ])

  const buildOptions = useCallback(() => {
    const preparedOptions = [...options]

    if (canExport) {
      preparedOptions.push({
        label: EXPORT_TITLE,
        props: { onClick: exportData }
      })
    }

    return preparedOptions
  }, [canExport, exportData, options])

  const dotsOptions = useMemo(() => buildOptions(), [buildOptions])

  return dotsOptions.length ? (
    <>
      {isExporting && <PIECurtainLoader />}
      <DotsButton align="left" options={dotsOptions} {...rest} />
    </>
  ) : null
}

DotsButtonWithExport.propTypes = {
  exportEntityData: PropTypes.func.isRequired,
  exportDataState: PropTypes.string.isRequired,
  exportDiscounts: PropTypes.func.isRequired,
  exportDiscountsState: PropTypes.string.isRequired,
  authUser: PropTypes.object.isRequired,
  options: PropTypes.array,
  columns: PropTypes.array,
  onGetFilters: PropTypes.func,
  canExport: PropTypes.bool,
  onGetParams: PropTypes.func,
  filename: PropTypes.string,
  entity: PropTypes.string
}

DotsButtonWithExport.defaultProps = {
  columns: [],
  options: [],
  canExport: undefined,
  onGetParams: () => ({}),
  onGetFilters: () => [],
  filename: null,
  entity: null
}
