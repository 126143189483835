/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import {
  NAME,
  UPLOAD_FILES,
  UPLOAD_FILES_PROCCESS,
  FILES_BY_GROUP_ID,
  REMOVE_INVENTORIES,
  REMOVE_INVENTORIE,
  REMOVE_INVENTORIE_FROM_STATE,
  DOWNLOAD_TICKET
} from './types'

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

const REQUEST_UPLOAD_FILES = `${REQUEST_TYPE}_${UPLOAD_FILES}`
const RESPONSE_UPLOAD_FILES = `${RESPONSE_TYPE}_${UPLOAD_FILES}`
const UPDATED_UPLOAD_FILE_PROGRESS = `UPDATED_UPLOAD_FILE_PROGRESS_${UPLOAD_FILES}`
const RESPONSE_UPLOAD_FILES_ERROR = `${RESPONSE_ERROR_TYPE}_${UPLOAD_FILES}`

const REQUEST_UPLOAD_FILES_PROCCESS = `${REQUEST_TYPE}_${UPLOAD_FILES_PROCCESS}`
const RESPONSE_UPLOAD_FILES_PROCCESS = `${RESPONSE_TYPE}_${UPLOAD_FILES_PROCCESS}`
const RESPONSE_UPLOAD_FILES_PROCCESS_ERROR = `${RESPONSE_ERROR_TYPE}_${UPLOAD_FILES_PROCCESS}`

const REQUEST_FILES_BY_GROUP_ID = `${REQUEST_TYPE}_${FILES_BY_GROUP_ID}`
const RESPONSE_FILES_BY_GROUP_ID = `${RESPONSE_TYPE}_${FILES_BY_GROUP_ID}`
const RESPONSE_FILES_BY_GROUP_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${FILES_BY_GROUP_ID}`

const REQUEST_DOWNLOAD_TICKET = `${REQUEST_TYPE}_${DOWNLOAD_TICKET}`
const RESPONSE_DOWNLOAD_TICKET = `${RESPONSE_TYPE}_${DOWNLOAD_TICKET}`
const RESPONSE_DOWNLOAD_TICKET_ERROR = `${RESPONSE_ERROR_TYPE}_${DOWNLOAD_TICKET}`

const REQUEST_REMOVE_INTENTORIES = `${REQUEST_TYPE}_${REMOVE_INVENTORIES}`
const RESPONSE_REMOVE_INTENTORIES = `${RESPONSE_TYPE}_${REMOVE_INVENTORIES}`
const RESPONSE_REMOVE_INTENTORIES_ERROR = `${RESPONSE_ERROR_TYPE}_${REMOVE_INVENTORIES}`

const REQUEST_REMOVE_INTENTORIE = `${REQUEST_TYPE}_${REMOVE_INVENTORIE}`
const RESPONSE_REMOVE_INTENTORIE = `${RESPONSE_TYPE}_${REMOVE_INVENTORIE}`
const RESPONSE_REMOVE_INTENTORIE_ERROR = `${RESPONSE_ERROR_TYPE}_${REMOVE_INVENTORIE}`

const buildDELETETicketsSuccessMessage = (requests = []) => {
  if (requests.length > 1) {
    return `${requests.length} of selected tickets have been successfully deleted!`
  }
  return `The ${requests[0].name} has been successfully deleted!`
}

const buildUploadingErrorMessage = () =>
  `An error occurred while loading the file. Check the file and try again.`

export default {
  RESET_DATA_STATE,
  REQUEST_UPLOAD_FILES,
  RESPONSE_UPLOAD_FILES,
  RESPONSE_UPLOAD_FILES_ERROR,
  UPDATED_UPLOAD_FILE_PROGRESS,
  REQUEST_UPLOAD_FILES_PROCCESS,
  RESPONSE_UPLOAD_FILES_PROCCESS,
  RESPONSE_UPLOAD_FILES_PROCCESS_ERROR,
  REQUEST_FILES_BY_GROUP_ID,
  RESPONSE_FILES_BY_GROUP_ID,
  RESPONSE_FILES_BY_GROUP_ID_ERROR,
  REQUEST_DOWNLOAD_TICKET,
  RESPONSE_DOWNLOAD_TICKET,
  RESPONSE_DOWNLOAD_TICKET_ERROR,
  REQUEST_REMOVE_INTENTORIES,
  RESPONSE_REMOVE_INTENTORIES,
  RESPONSE_REMOVE_INTENTORIES_ERROR,
  REQUEST_REMOVE_INTENTORIE,
  RESPONSE_REMOVE_INTENTORIE,
  RESPONSE_REMOVE_INTENTORIE_ERROR,
  REMOVE_INVENTORIE_FROM_STATE,
  buildDELETETicketsSuccessMessage,
  buildUploadingErrorMessage
}
