/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildURLForGettingList } from 'services/http/helpers'
import {
  ACTIVITY_CATEGORY_URL,
  ACTIVITY_CATEGORY_TREE_URL,
  ACTIVITY_CATEGORY_BY_ACTIVITY_ID_URL
} from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'

export const getList = () =>
  httpWrapper({
    url: buildURLForGettingList(ACTIVITY_CATEGORY_URL),
    method: 'get'
  })

export const getTree = () =>
  httpWrapper({
    url: buildURLForGettingList(ACTIVITY_CATEGORY_TREE_URL),
    method: 'get'
  })

export const getListByProviderId = id =>
  httpWrapper({
    url: buildPath(ACTIVITY_CATEGORY_BY_ACTIVITY_ID_URL, { providerId: id }),
    method: 'get'
  })
