/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'

import { Group } from './Group'
import { getGravityByType, getGroupNameByType } from './helpers'

import styles from './GroupsContent.scss'

export function GroupsContent(props) {
  const { data, ...rest } = props
  const groups = groupBy(data, 'type')
  return (
    <div className={styles.groupContentWrapper}>
      {Object.keys(groups)
        .sort((a, b) => getGravityByType(b) - getGravityByType(a))
        .map(groupName => (
          <Group
            {...rest}
            key={groupName}
            name={getGroupNameByType(groupName)}
            data={groups[groupName]}
          />
        ))}
    </div>
  )
}

GroupsContent.propTypes = {
  data: PropTypes.array
}
GroupsContent.defaultProps = {
  data: []
}
