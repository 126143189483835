/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'

export const getParentProgramId = (formData = {}) => {
  const parrentId = get(formData, 'versionData.previousId', null)
  return parrentId
}

export const getCurrentProgramId = (formData = {}) => {
  const id = get(formData, 'id', null)
  return id
}

export const getCurrentVersion = (formData = {}) => {
  const version = get(formData, 'versionData.version', null)
  return version
}

export const getPreviousVersion = (formData = {}) => {
  const version = Number(get(formData, 'versionData.version', 0))
  return version > 1 ? version - 1 : null
}
