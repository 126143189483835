/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import {
  VAC_SPECIALIST_LEVEL,
  APPROVE_LEVEL,
  READ_LEVEL,
  ADMIN_LEVEL
} from 'constants/permissionTypes'

import { isActionAllowed } from 'helpers/permissionHelper'
import {
  NAVIGATION_ADMIN_TYPE,
  NAVIGATION_RESALE_TYPE,
  NAVIGATION_DEFAULT_TYPE
} from 'constants/navigationTypes'

import {
  signOutItemNavData,
  profileItemNavData,
  adminItemNavData,
  nexeItemNavData,
  ticketNexeItemNavData,
  switchToItemNavData,
  searchItemNavData,
  myWorkItemNavData,
  unitTasksNavData,
  pmcsItemNavData,
  programsItemNavData,
  activityProvidersItemNavData,
  activityProductsItemNavData,
  libraryItemNavData,
  reportsItemNavData,
  accountsItemNavData,
  campaignsItemNavData,
  settingsItemNavData,
  separator,
  dealsItemNavData,
  activitySwitherItemNavData,
  TITLE_SUB_MENU_SWITCH_TO,
  TITLE_SUB_MENU_OPEN
} from './types'

import { Switcher } from './Switcher'

export const onUpdateNavigationType = option => props => () => props.updateNavigationType(option)
export const onLogOut = (event, props) => props.logOut(props.authUser)

export const getCustomNavItem = (originalItem, customizer = () => {}) => ({
  ...originalItem,
  ...customizer()
})

const signOutItemNav = getCustomNavItem(signOutItemNavData, () => ({
  onClick: onLogOut
}))
const resellProfile = getCustomNavItem(profileItemNavData, () => ({ navPath: '/resale/profile' }))
const adminProfile = getCustomNavItem(profileItemNavData, () => ({ navPath: '/admin/profile' }))
const adminItemNav = getCustomNavItem(adminItemNavData, () => ({
  onClick: onUpdateNavigationType(NAVIGATION_ADMIN_TYPE)
}))

const nexeItemNav = getCustomNavItem(nexeItemNavData, () => ({
  onClick: onUpdateNavigationType(NAVIGATION_RESALE_TYPE)
}))

const switchToItemNav = getCustomNavItem(switchToItemNavData, () => ({
  component: Switcher,
  switcherTitle: TITLE_SUB_MENU_SWITCH_TO,
  subMenuItems: [adminItemNav, nexeItemNav],
  navMenuItem: switchToItemNavData
}))

const switchProfileNav = getCustomNavItem(settingsItemNavData, () => ({
  component: Switcher,
  switcherTitle: TITLE_SUB_MENU_OPEN,
  subMenuItems: [
    getCustomNavItem(profileItemNavData, () => ({ navItemBehavior: false, NavIcon: null })),
    getCustomNavItem(accountsItemNavData, () => ({ navItemBehavior: false, NavIcon: null }))
  ],
  navMenuItem: settingsItemNavData
}))

const switchActivityNav = getCustomNavItem(activitySwitherItemNavData, () => ({
  component: Switcher,
  switcherTitle: TITLE_SUB_MENU_OPEN,
  subMenuItems: [
    getCustomNavItem(activityProductsItemNavData, () => ({
      navItemBehavior: false,
      NavIcon: null,
      navItemText: 'Product'
    })),
    getCustomNavItem(dealsItemNavData, () => ({ navItemBehavior: false, NavIcon: null }))
  ],
  navMenuItem: activitySwitherItemNavData
}))

export const navigationMenuItems = {
  [NAVIGATION_ADMIN_TYPE]: [
    searchItemNavData,
    myWorkItemNavData,
    unitTasksNavData,
    pmcsItemNavData,
    programsItemNavData,
    activityProvidersItemNavData,
    getCustomNavItem(activityProductsItemNavData, () => ({
      accessLevels: [{ level: READ_LEVEL, ignoreLevels: [APPROVE_LEVEL] }]
    })),
    switchActivityNav,
    campaignsItemNavData,
    libraryItemNavData,
    reportsItemNavData,
    separator,
    getCustomNavItem(switchToItemNav, () => ({ accessLevels: [VAC_SPECIALIST_LEVEL] })),
    getCustomNavItem(switchProfileNav, () => ({
      subMenuItems: [
        getCustomNavItem(adminProfile, () => ({ navItemBehavior: false, NavIcon: null })),
        getCustomNavItem(accountsItemNavData, () => ({ navItemBehavior: false, NavIcon: null }))
      ]
    })),
    getCustomNavItem(adminProfile, () => ({
      accessLevels: [
        { level: READ_LEVEL, ignoreLevels: [ADMIN_LEVEL] },
        { level: VAC_SPECIALIST_LEVEL, ignoreLevels: [ADMIN_LEVEL] }
      ]
    })),
    signOutItemNav
  ],
  [NAVIGATION_RESALE_TYPE]: [
    ticketNexeItemNavData,
    separator,
    getCustomNavItem(switchToItemNav, () => ({ accessLevels: [READ_LEVEL] })),
    getCustomNavItem(switchProfileNav, () => ({
      subMenuItems: [
        getCustomNavItem(resellProfile, () => ({ navItemBehavior: false, NavIcon: null })),
        getCustomNavItem(accountsItemNavData, () => ({ navItemBehavior: false, NavIcon: null }))
      ]
    })),
    getCustomNavItem(resellProfile, () => ({
      accessLevels: [
        { level: READ_LEVEL, ignoreLevels: [ADMIN_LEVEL] },
        { level: VAC_SPECIALIST_LEVEL, ignoreLevels: [ADMIN_LEVEL] }
      ]
    })),
    signOutItemNav
  ],
  [NAVIGATION_DEFAULT_TYPE]: [
    adminItemNav,
    nexeItemNav,
    separator,
    switchProfileNav,
    getCustomNavItem(profileItemNavData, () => ({
      accessLevels: [
        { level: READ_LEVEL, ignoreLevels: [ADMIN_LEVEL] },
        { level: VAC_SPECIALIST_LEVEL, ignoreLevels: [ADMIN_LEVEL] }
      ]
    })),
    signOutItemNav
  ]
}

export const buildNavigationItems = (authUser, prepareNavData, type) => {
  const navigationData = prepareNavData(navigationMenuItems)

  if (!isArray(navigationData[type])) {
    return []
  }

  return navigationData[type].filter(({ accessLevels = [] }) => {
    if (!accessLevels || !accessLevels.length) {
      return true
    }

    return accessLevels.some(level => {
      if (isObject(level)) {
        if (level.ignoreLevels) {
          return (
            isActionAllowed(level.level, authUser) &&
            !level.ignoreLevels.some(ignoreLevel => isActionAllowed(ignoreLevel, authUser))
          )
        }
      }
      return isActionAllowed(level, authUser)
    })
  })
}

export const getNavigationTypeByPath = currentPath => {
  const REGE_PATH_RESALE = /(resale)(.+)/
  const REGE_PATH_ADMIN = /(admin)(?!.*home.*).*/

  if (REGE_PATH_ADMIN.test(currentPath)) {
    return NAVIGATION_ADMIN_TYPE
  }

  if (REGE_PATH_RESALE.test(currentPath)) {
    return NAVIGATION_RESALE_TYPE
  }

  return NAVIGATION_DEFAULT_TYPE
}
