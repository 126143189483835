/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { buildAccessKey } from './helpers'
import { unitTasksByProgramListInitialState } from './reducer'

export const getUnitTasksListStateByProgram = (state, pmcId, programId) =>
  state.unitTasks.unitTasksByProgramList[buildAccessKey(pmcId, programId)] ||
  unitTasksByProgramListInitialState

export const getUnitTasksListState = state => state.unitTasks.unitTasksByProgramList
