/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import {
  createEntity,
  updateEntity,
  getEntityById,
  deleteEntityById
} from 'services/http/ticketType'
import { assingTicketToGroup } from 'services/http/ticketGroup'
import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID_ERROR,
  REQUEST_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID_ERROR,
  buildMessage,
  CREATED_MESSAGE_TYPE,
  UPDATE_MESSAGE_TYPE,
  DELETE_MESSAGE_TYPE
} = helpers

const postForm = (activityId, contractData = {}) => async dispatch => {
  const { ticketGroupId } = contractData
  dispatch(actionCreater(REQUEST_FORM))
  try {
    const createEntityRequest = await createEntity(activityId, contractData)
    const {
      data,
      message: { message, send }
    } = createEntityRequest
    if (ticketGroupId) {
      await assingTicketToGroup(activityId, ticketGroupId, data)
    }
    dispatch(actionCreater(RESPONSE_FORM, data))
    dispatch(send({ ...message, message: buildMessage(contractData, CREATED_MESSAGE_TYPE) }))
    return 'postForm success'
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
    return 'postForm failed'
  }
}

const putForm = (activityId, contractData = {}) => async dispatch => {
  const { id, ticketGroupId } = contractData
  dispatch(actionCreater(REQUEST_FORM))
  try {
    const createEntityRequest = await updateEntity(activityId, contractData)
    if (ticketGroupId) {
      await assingTicketToGroup(activityId, ticketGroupId, id)
    }
    const {
      message: { message, send }
    } = createEntityRequest
    dispatch(actionCreater(RESPONSE_FORM, id))
    dispatch(send({ ...message, message: buildMessage(contractData, UPDATE_MESSAGE_TYPE) }))
    return 'putForm success'
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
    return 'putForm failed'
  }
}

const getEntityDataById = (id, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_ENTITY_BY_ID))
  const request = await getEntityById(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID, request.data))
    return 'getEntityDataById success'
  }

  return 'getEntityDataById failed'
}

const removeEntityById = (activityId, id, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_REMOVE_ENTITY_BY_ID))
  const request = await deleteEntityById(activityId, id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_REMOVE_ENTITY_BY_ID_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_REMOVE_ENTITY_BY_ID))
    dispatch(send({ ...message, message: buildMessage(options, DELETE_MESSAGE_TYPE) }))
    return 'removeEntityById success'
  }

  return 'removeEntityById failed'
}

const resetForm = () => ({
  type: RESET_DATA_STATE
})

export default {
  postForm,
  putForm,
  getEntityDataById,
  removeEntityById,
  resetForm
}
