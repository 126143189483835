/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_LIST_ERROR,
  RESET_DATA_STATE,
  UPDATE_TREE_STATE,
  REQUEST_REMOVE_ACTIVITY_CATEGORY,
  RESPONSE_REMOVE_ACTIVITY_CATEGORY,
  RESPONSE_REMOVE_ACTIVITY_CATEGORY_ERROR
} = helpers

export const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  data: [],
  treeState: {},
  removeCategoryDataState: DATA_STATE_NOT_REQUESTED
}

const categoriesTreeReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING,
        data: []
      }
    case RESPONSE_LIST:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        data: action.payload
      }
    case RESPONSE_LIST_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case RESET_DATA_STATE:
      return initialState
    case UPDATE_TREE_STATE:
      return {
        ...state,
        treeState: {
          ...state.treeState,
          [action.payload.id]: action.payload
        }
      }
    case REQUEST_REMOVE_ACTIVITY_CATEGORY:
      return {
        ...state,
        removeCategoryDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_REMOVE_ACTIVITY_CATEGORY:
      return {
        ...state,
        removeCategoryDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_REMOVE_ACTIVITY_CATEGORY_ERROR:
      return {
        ...state,
        removeCategoryDataState: DATA_STATE_ERROR
      }
    default:
      return state
  }
}

export default filterActions(categoriesTreeReducer, [
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_LIST_ERROR,
  RESET_DATA_STATE,
  UPDATE_TREE_STATE,
  REQUEST_REMOVE_ACTIVITY_CATEGORY,
  RESPONSE_REMOVE_ACTIVITY_CATEGORY,
  RESPONSE_REMOVE_ACTIVITY_CATEGORY_ERROR
])
