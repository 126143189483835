/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const USER_AUTH = 'USER_AUTH'
export const PROFILE_ACTION = 'PROFILE_ACTION'
export const LOG_OUT_ACTION = 'LOG_OUT_ACTION'
export const CHANGE_PASS_ACTION = 'CHANGE_PASS_ACTION'
export const REFRESH_TOKEN_ACTION = 'REFRESH_TOKEN_ACTION'

export default {
  USER_AUTH,
  PROFILE_ACTION,
  LOG_OUT_ACTION,
  CHANGE_PASS_ACTION,
  REFRESH_TOKEN_ACTION
}
