/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { PREVIOUS_PROGRAMS_PATH } from 'router'
import { buildUrlWithParams } from 'helpers/searchParamsHelper'

export function openInNewTab(id) {
  if (!id) {
    return 'invalid params'
  }
  const url = buildUrlWithParams(PREVIOUS_PROGRAMS_PATH, { id })
  const win = window.open(url, '_blank')

  if (win) {
    win.focus()
  }
  return 'success goToPreviousProgramsPage'
}
