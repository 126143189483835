/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { ButtonsWrapper } from 'components/Wrappers'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { ERROR_MESSAGE, REFRESH_BUTTON_TEXT } from './types'
import styles from './FormErrorStub.scss'

export function FormErrorStub(props) {
  const { onRefresh, children } = props
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.error}>{ERROR_MESSAGE}</h1>
      <div className={styles.buttons}>
        <ButtonsWrapper>
          <Column>
            <BasicButton onClick={onRefresh} colorSchema={ORANGE_SCHEMA}>
              {REFRESH_BUTTON_TEXT}
            </BasicButton>
            {children}
          </Column>
        </ButtonsWrapper>
      </div>
    </div>
  )
}

FormErrorStub.propTypes = {
  onRefresh: PropTypes.func.isRequired,
  children: PropTypes.node
}
FormErrorStub.defaultProps = {
  children: null
}
