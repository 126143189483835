/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function LocationsIcon(props) {
  return (
    <svg viewBox="-3 -3 20 20" {...props}>
      <polygon
        points="14.069,5.304 14.069,0.16 8.926,0.16 8.926,2.533 5.143,4.861 5.143,4.064 0,4.064 0,9.208 
		5.143,9.208 5.143,8.26 8.926,11.069 8.926,13.91 14.069,13.91 14.069,8.766 8.926,8.766 8.926,9.939 5.143,7.128 5.143,5.927 
		8.926,3.599 8.926,5.304"
      />
    </svg>
  )
}
