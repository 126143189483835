/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { ContentWithNav } from 'components/ContentWithNav'
import { ButtonsWrapper, HeaderWrapper } from 'components/Wrappers'
import { DISCOUNT } from 'constants/searchTypes'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { RIGHT_ALIGN } from 'constants/alignTypes'
import { useSliderApi } from 'hooks'
import { DEAL } from 'constants/formCodes'
import { DiscountsList } from 'modules/Discounts'
import { DotsButtonWithExport } from 'modules/ExportEntity'
import { FilteredSearch, TableField, useFilteredSearch } from 'modules/FilteredSearch'
import { FormFilter } from 'components/FormComponents'
import { dealsExportTableColumns } from 'helpers/dataTablesHelpers'
import { buildFilters } from './helpers'
import {
  DEALS_FILTERS,
  DEALS_INITIAL_FILTERS,
  TEXT_BTN_ADD_NEW,
  TITLE_PAGE,
  EXPORT_FILENAME
} from './types'
import styles from './Discounts.scss'

export function Discounts(props) {
  const { onGetData } = props
  const { onGetApi, onChange, getFilters, getParams } = useFilteredSearch({
    onGetData,
    buildFilters
  })

  const dealSliderApi = useSliderApi(DEAL)

  const onAddNewHandler = () => {
    dealSliderApi.open(null, { headerProps: { title: 'New Discount' } })
  }

  const options = [
    {
      label: TEXT_BTN_ADD_NEW,
      props: { onClick: onAddNewHandler }
    }
  ]

  return (
    <ContentWithNav>
      <FilteredSearch getApi={onGetApi} onChange={onChange} initialFilters={DEALS_INITIAL_FILTERS}>
        <section className={styles.customWrapper}>
          <HeaderWrapper title={TITLE_PAGE} />
          <div className={styles.containerWrapper}>
            <ButtonsWrapper className={styles.buttonsPanelWithFilters} align={RIGHT_ALIGN}>
              <Column className={styles.customColumnWrapper}>
                <DotsButtonWithExport
                  options={options}
                  onGetFilters={getFilters}
                  onGetParams={getParams}
                  entity={DISCOUNT}
                  columns={dealsExportTableColumns}
                  filename={EXPORT_FILENAME}
                />
              </Column>
              <Column>
                <div className={styles.filterBarWrapper}>
                  <FormFilter filters={DEALS_FILTERS} />
                </div>
              </Column>
            </ButtonsWrapper>
            <TableField Component={DiscountsList} />
          </div>
        </section>
      </FilteredSearch>
    </ContentWithNav>
  )
}

Discounts.propTypes = {
  onGetData: PropTypes.func.isRequired
}
