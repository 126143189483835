/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { FilteredSearch, TableField } from 'modules/FilteredSearch'
import { ActivityProviderList } from 'modules/ActivityProviders/ActivityProviderList'
import { FormFilter } from 'components/FormComponents'
import { buildFilters } from 'containers/ActivityProviders/helpers'
import { ACTIVITY_PROVIDER } from 'constants/searchTypes'
import { activityProvidersTableColumns } from 'helpers/dataTablesHelpers'
import { DotsButtonWithExport } from 'modules/ExportEntity'
import {
  AP_FORM_FILTERS,
  AP_INITIAL_FILTERS,
  EXPORT_FILENAME
} from 'containers/ActivityProviders/types'
import styles from './APSearchResult.scss'

export function APSearchResult(props) {
  const { onChange, data, state, dataState, itemsPerPage, pageNumber, itemsCount } = props

  const filteredSearchApi = useRef()

  const onGetDataHandler = useCallback(
    ({ filters, params }) => {
      onChange({ parameters: params, filters: buildFilters(filters) })
    },
    [onChange]
  )

  const onGetApi = useCallback(api => {
    filteredSearchApi.current = api
  }, [])

  const getFilters = useCallback(() => buildFilters(filteredSearchApi.current.getFilters()), [])
  const getParams = useCallback(
    () => ({ ...state.parameters, ...filteredSearchApi.current.getParams() }),
    [state.parameters]
  )

  return (
    <FilteredSearch
      getApi={onGetApi}
      onChange={onGetDataHandler}
      initialFilters={AP_INITIAL_FILTERS}
    >
      <div className={styles.filterBarWrapper}>
        <FormFilter filters={AP_FORM_FILTERS} />
        <DotsButtonWithExport
          onGetFilters={getFilters}
          onGetParams={getParams}
          entity={ACTIVITY_PROVIDER}
          columns={activityProvidersTableColumns}
          filename={EXPORT_FILENAME}
        />
      </div>
      <TableField
        Component={ActivityProviderList}
        dataState={dataState}
        data={data}
        itemsPerPage={itemsPerPage}
        itemsCount={itemsCount}
        pageNumber={pageNumber}
        // TODO: Need adjust and refactor it with reimplement this functionality.
        withBasicAuth={false}
        needFirstGetData={false}
      />
    </FilteredSearch>
  )
}

APSearchResult.propTypes = {
  filters: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array,
  dataState: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number,
  itemsCount: PropTypes.number,
  pageNumber: PropTypes.number,
  enqueue: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired
}

APSearchResult.defaultProps = {
  filters: [],
  data: [],
  itemsCount: 0,
  itemsPerPage: 10,
  pageNumber: 1
}
