/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getList, getCount, exportUnitTasks as exportUnitTasksRequest } from 'services/http/unitTasks'
import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  REQUEST_LIST_COUNT,
  RESPONSE_ERROR_LIST_COUNT,
  RESPONSE_LIST_COUNT,
  REQUEST_LIST_EXPORT,
  RESPONSE_LIST_EXPORT,
  RESPONSE_ERROR_LIST_EXPORT
} = helpers

const getUnitTasksList = (options = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await getList(options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { ...request.data }))
    return 'getUnitTasksList success'
  }

  return 'getUnitTasksList failed'
}

const getUnitTasksCount = () => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST_COUNT))

  const request = await getCount().catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST_COUNT))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_LIST_COUNT, request.data))
    return 'getUnitTasksCount success'
  }

  return 'getUnitTasksCount failed'
}

const exportUnitTasks = (data, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST_EXPORT))
  const request = await exportUnitTasksRequest(data, { ...options }).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST_EXPORT))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST_EXPORT))
    return 'exportUnitTasks success'
  }

  return 'exportUnitTasks failed'
}

export default {
  getUnitTasksList,
  getUnitTasksCount,
  exportUnitTasks
}
