/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import { formActions } from 'reducers/pmsForm'
import { PMSForm as PMSFormComponent } from './PMSForm'

const mapStateToProps = state => ({
  ...state.pmsForm
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...formActions
    },
    dispatch
  )

export const PMSForm = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PMSFormComponent)
