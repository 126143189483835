/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import { actions as activityProviderActions, getListWatchers } from 'reducers/activityProviderList'
import { formActions } from 'reducers/activityProviderForm'
import { actions as queueActions } from 'reducers/editQueue'
import { withAuthContext } from 'helpers/withAuthContext'

import { ActivityProviderListComponent } from './ActivityProviderList'

export const mapStateToProps = state => ({
  dataState: state.activityProviderList.dataState,
  data: state.activityProviderList.data,
  itemsCount: state.activityProviderList.itemsCount,
  itemsPerPage: state.activityProviderList.itemsPerPage,
  pageNumber: state.activityProviderList.pageNumber,
  listWatchers: getListWatchers(state)
})

export const mapDispatchToProps = dispatch => {
  const { getProvidersList } = activityProviderActions
  const { deleteProviderById } = formActions

  return bindActionCreators(
    {
      onGetData: getProvidersList,
      deleteItemById: deleteProviderById,
      ...queueActions
    },
    dispatch
  )
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps
})

export const ActivityProviderList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )
)(ActivityProviderListComponent)
