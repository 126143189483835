/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { useLoading, useSlider } from 'hooks'
import { DeactivationInfo } from 'modules/DelayedDeactivation'
import { AppDeactivationModal } from 'modules/ActivityProducts'
import { PIECurtainLoader } from 'components/Loaders'
import { ACTIVITY_PROVIDER } from 'constants/formCodes'

import { buildDeactivationMessage, canDeactivateApp, getDeactivationMode } from './helpers'
import { DEACTIVATION_MODAL_STEP, UPDATE_DEACTIVATED_DATE_MODE, UPDATE_DATE_MODE } from './types'
import { WarningModal } from '../ActivityProductModals/AppDeactivation/WraningModal'

export function DelayedDeactivation(props) {
  const {
    entityData,
    entityDataState,
    authUser,
    deactivationDataState,
    deactivateAppById,
    unDeactivateAppById,
    dataId,
    getAPPbyId
  } = props

  // Deactivation Info Data
  const [, { isLoaded }] = useLoading(entityDataState)
  const canDeactivate = canDeactivateApp(authUser, entityData)
  const message = buildDeactivationMessage(entityData)

  const activityProviderData = get(entityData, 'activityProvider')
  const deactivationDateAP = get(activityProviderData, 'deactivationDate.date')
  const deactivationDateAPP = get(entityData, 'deactivationDate.date')

  const mode = getDeactivationMode(entityData)

  const deactivationDate = deactivationDateAPP
  const hasInfo = Boolean(deactivationDate) && isLoaded
  const [, apSliderApi] = useSlider(`${dataId}_${ACTIVITY_PROVIDER}`, ACTIVITY_PROVIDER, {
    onClose: () => {
      getAPPbyId(authUser, dataId)
    }
  })

  // Deactivation Modal Data
  const [isOpenModal, setIsModalState] = useState(false)
  const [isProcessing] = useLoading(deactivationDataState)
  const appName = (entityData && entityData.name) || ''

  const onToggleModal = useCallback(() => {
    setIsModalState(!isOpenModal)
  }, [isOpenModal])

  const onDeactivateHandler = useCallback(
    data => {
      if (deactivateAppById && dataId && data) {
        deactivateAppById(
          dataId,
          {
            ...data,
            isEdit: true
          },
          { isInactive: mode === UPDATE_DEACTIVATED_DATE_MODE }
        )
      }
      onToggleModal()
    },
    [deactivateAppById, dataId, onToggleModal, mode]
  )

  const onCancelDeactivateHandler = useCallback(() => {
    if (unDeactivateAppById && dataId) {
      unDeactivateAppById(dataId)
    }
    onToggleModal()
  }, [unDeactivateAppById, dataId, onToggleModal])

  const onClickViewAP = useCallback(() => {
    onToggleModal()
    const apId = get(activityProviderData, 'id')
    const shortPmcName = get(activityProviderData, 'name')

    apSliderApi.open({
      props: { dataId: apId },
      headerProps: { title: shortPmcName }
    })
  }, [activityProviderData, apSliderApi, onToggleModal])

  const deactivationModal = useMemo(() => {
    if (deactivationDateAP && mode === UPDATE_DATE_MODE) {
      return (
        <WarningModal
          isOpen={isOpenModal}
          onSubmit={onClickViewAP}
          onClose={onToggleModal}
          entityData={entityData}
        />
      )
    }

    return (
      <AppDeactivationModal
        isOpen={isOpenModal}
        onSubmit={onDeactivateHandler}
        onCancel={onCancelDeactivateHandler}
        onHide={onToggleModal}
        dataId={dataId}
        initialValues={{
          date: {
            date: deactivationDate
          }
        }}
        appName={appName}
        initialStep={DEACTIVATION_MODAL_STEP}
        stepsCount={DEACTIVATION_MODAL_STEP}
        mode={mode}
      />
    )
  }, [
    deactivationDateAP,
    isOpenModal,
    onDeactivateHandler,
    onCancelDeactivateHandler,
    onToggleModal,
    dataId,
    deactivationDate,
    appName,
    mode,
    onClickViewAP,
    entityData
  ])

  return (
    <>
      {hasInfo && (
        <DeactivationInfo
          message={message}
          date={deactivationDate}
          showIcon={canDeactivate}
          onClick={onToggleModal}
        />
      )}
      {isProcessing && <PIECurtainLoader />}
      {deactivationModal}
    </>
  )
}

DelayedDeactivation.propTypes = {
  authUser: PropTypes.object.isRequired,
  dataId: PropTypes.string.isRequired,
  deactivateAppById: PropTypes.func.isRequired,
  unDeactivateAppById: PropTypes.func.isRequired,
  deactivationDataState: PropTypes.string.isRequired,
  entityData: PropTypes.object,
  entityDataState: PropTypes.string.isRequired,
  getAPPbyId: PropTypes.func.isRequired
}

DelayedDeactivation.defaultProps = { entityData: null }
