/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildURLForGettingEntityById } from 'services/http/helpers'
import { ACTIVITY_CATEGORY_URL, ACTIVITY_CATEGORY_VALIDATION_URL } from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'

export const createActivityCategory = contractData =>
  httpWrapper({
    url: ACTIVITY_CATEGORY_URL,
    method: 'post',
    data: contractData
  })

export const updateActivityCategory = contractData =>
  httpWrapper({
    url: ACTIVITY_CATEGORY_URL,
    method: 'put',
    data: contractData
  })

export const getActivityCategoryById = (id, params) =>
  httpWrapper({
    url: buildURLForGettingEntityById(ACTIVITY_CATEGORY_URL, id),
    method: 'get',
    params
  })

export const deleteActivityCategoryById = (id, params) =>
  httpWrapper({
    url: buildURLForGettingEntityById(ACTIVITY_CATEGORY_URL, id),
    method: 'delete',
    params
  })

export const validateCategory = id =>
  httpWrapper({
    url: buildPath(ACTIVITY_CATEGORY_VALIDATION_URL, { id }),
    method: 'get'
  })
