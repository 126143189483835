/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'
import { RESET_STATE } from 'helpers/actionHelpers'

import types from './types'

const { LIST_NAME, UNASSIGNED_TYPES } = types

const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

const getUnassignedTicketTypes = (groups = []) => {
  const group = groups.find(ticketGroup => {
    const groupName = get(ticketGroup, 'name')
    return groupName === UNASSIGNED_TYPES
  })
  return get(group, 'ticketTypes', [])
}

export default {
  UNASSIGNED_TYPES,
  RESET_DATA_STATE,
  getUnassignedTicketTypes
}
