/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDidMount, useLoading } from 'hooks'
import { ContentWithNav } from 'components/ContentWithNav'
import { ButtonsWrapper, HeaderWrapper } from 'components/Wrappers'
import { UnitTasksList } from 'modules/UnitTasks/UnitTasksList'
import withAuthorization from 'helpers/withAuthorization'
import { PIECurtainLoader } from 'components/Loaders'
import { FilteredSearch, TableField } from 'modules/FilteredSearch'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { DotsButton } from '@xplorie/ui-commons'
import { buildMapper } from 'helpers/exportEntitiesHelper'
import { RIGHT_ALIGN } from 'constants/alignTypes'
import { unitTasksTableColumns } from 'helpers/dataTablesHelpers'
import { EXPORT_TITLE } from 'modules/ExportEntity/DotsButtonWithExport/types'
import { TITLE_PAGE } from './types'
import styles from './UnitTasks.scss'

function UnitTasks(props) {
  const { onGetData, data, exportUnitTasks, exportDataState } = props
  const [isExporting] = useLoading(exportDataState)
  const filteredSearchApi = useRef()

  const onChangeHandler = useCallback(
    ({ params }) => {
      onGetData(params)
    },
    [onGetData]
  )

  const onGetApi = useCallback(api => {
    filteredSearchApi.current = api
  }, [])

  useDidMount(() => {
    filteredSearchApi.current.emitChange()
  })

  const onExportUnitTasks = useCallback(() => {
    const params = filteredSearchApi.current.getParams()
    const options = { ...params, itemsPerPage: 0 }
    const contractData = buildMapper(unitTasksTableColumns)
    exportUnitTasks(contractData, options)
  }, [data, exportUnitTasks])

  const dotsButtonOptions = useMemo(
    () => [
      {
        label: EXPORT_TITLE,
        props: { onClick: onExportUnitTasks, disabled: !data.length }
      }
    ],
    [data.length, onExportUnitTasks]
  )

  return (
    <ContentWithNav>
      {isExporting && <PIECurtainLoader />}
      <FilteredSearch getApi={onGetApi} onChange={onChangeHandler}>
        <section className={styles.customWrapper}>
          <HeaderWrapper title={TITLE_PAGE} />
          <div className={styles.containerWrapper}>
            <ButtonsWrapper className={styles.buttonsPanelWithFilters} align={RIGHT_ALIGN}>
              <Column className={styles.customColumnWrapper}>
                <div className={styles.topButtons}>
                  <DotsButton align="left" options={dotsButtonOptions} />
                </div>
              </Column>
            </ButtonsWrapper>
            <TableField
              enqueue={props.enqueue}
              Component={UnitTasksList}
              needFirstGetData={false}
            />
          </div>
        </section>
      </FilteredSearch>
    </ContentWithNav>
  )
}

UnitTasks.propTypes = {
  enqueue: PropTypes.func.isRequired,
  onGetData: PropTypes.func.isRequired,
  exportEntityData: PropTypes.func.isRequired,
  exportDataState: PropTypes.string.isRequired,
  authUser: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  exportUnitTasks: PropTypes.func.isRequired
}

export default withAuthorization(UnitTasks)
