/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  getSmartHostMobileFeeByProgramId,
  saveSmartHostMobileFeeByProgramId
} from 'services/http/smartHostFee'

import { REQUEST_LIST, RESPONSE_LIST, RESPONSE_LIST_ERROR, RESET_DATA_STATE, MODE } from './helpers'

import { SUCCESS_RATE_ADJUSTMENT_MESSAGE } from './types'

export const setSmartHostFeeMode = mode => dispatch => {
  dispatch(
    actionCreater(MODE, {
      mode
    })
  )

  return 'setSmartHostFeeMode success'
}

export const getSmartHostFee = programId => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const response = await getSmartHostMobileFeeByProgramId(programId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_LIST_ERROR))
  })

  if (response) {
    dispatch(
      actionCreater(RESPONSE_LIST, {
        fees:
          response.data.fees &&
          response.data.fees
            .map(item => ({
              ...item,
              editable: true
            }))
            .sort((a, b) => new Date(a.period.startDate) - new Date(b.period.startDate))
      })
    )
    return 'getSmartHostMobileFee success'
  }

  return 'getSmartHostMobileFee failed'
}

export const saveSmartHostFee = (programId, data) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await saveSmartHostMobileFeeByProgramId(programId, data).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_LIST_ERROR))
    }
  )

  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: SUCCESS_RATE_ADJUSTMENT_MESSAGE }))
    dispatch(getSmartHostFee(programId))
    return 'saveSmartHostMobileFee success'
  }

  return 'saveSmartHostMobileFee failed'
}

export const resetData = () => ({
  type: RESET_DATA_STATE
})
