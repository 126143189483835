/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  createCountry,
  createLocation,
  updateLocation,
  getLocationById,
  linkChildForLocation,
  addLocationChildren
} from 'services/http/locations'

import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_COUNTRY_FORM,
  RESPONSE_COUNTRY_FORM,
  RESPONSE_COUNTRY_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_LOCATION,
  RESPONSE_LOCATION,
  RESPONSE_LOCATION_ERROR,
  REQUEST_LINK_CHILD_LOCATION,
  RESPONSE_LINK_CHILD_LOCATION,
  RESPONSE_LINK_CHILD_LOCATION_ERROR,
  REQUEST_CREATE_LOCATION_AND_LINK,
  RESPONSE_CREATE_LOCATION_AND_LINK,
  RESPONSE_CREATE_LOCATION_AND_LINK_ERROR,
  REQUEST_UPDATE_AND_ADD_CHILDREN,
  RESPONSE_UPDATE_AND_ADD_CHILDREN,
  RESPONSE_UPDATE_AND_ADD_CHILDREN_ERROR,
  NEW_KEY
} = helpers

const createCountryAction = (contractData = {}) => async dispatch => {
  const key = (contractData && contractData.id) || NEW_KEY
  dispatch(actionCreater(REQUEST_COUNTRY_FORM, { key }))
  const request = await createCountry(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_COUNTRY_FORM_ERROR, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request

    const data = { ...contractData, id: request.data }
    dispatch(actionCreater(RESPONSE_COUNTRY_FORM, { key, data }))
    dispatch(send(message))
    return 'createCountryAction success'
  }

  return 'createCountryAction failed'
}

const createLocationAction = (contractData = {}) => async dispatch => {
  const key = (contractData && contractData.id) || NEW_KEY
  dispatch(actionCreater(REQUEST_FORM, { key }))
  const request = await createLocation(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
  })

  if (!request) {
    return Promise.reject(new Error('createLocationAction failed'))
  }

  const {
    message: { message, send }
  } = request
  const data = { ...contractData, id: request.data }
  dispatch(actionCreater(RESPONSE_FORM, { key, data }))
  dispatch(send(message))
  return Promise.resolve(data)
}
const updateLocationAction = (contractData = {}) => async dispatch => {
  const key = contractData && contractData.id
  dispatch(actionCreater(REQUEST_FORM, { key }))
  const request = await updateLocation(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
  })

  if (!request) {
    return Promise.reject(new Error('updateLocationAction failed'))
  }

  const {
    message: { message, send }
  } = request
  dispatch(actionCreater(RESPONSE_FORM, { key, data: contractData }))
  dispatch(send(message))
  return Promise.resolve(contractData)
}

const getLocation = id => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_LOCATION, { key }))
  const request = await getLocationById(id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_LOCATION_ERROR, { key }))
  })

  if (!request) {
    return 'getLocation failed'
  }

  dispatch(
    actionCreater(RESPONSE_LOCATION, {
      data: request.data,
      key
    })
  )
  return 'getLocation success'
}

const linkChildLocation = (childId = '0', parentId = '0') => async dispatch => {
  const key = parentId
  dispatch(actionCreater(REQUEST_LINK_CHILD_LOCATION, { key }))
  const request = await linkChildForLocation(childId, parentId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_LINK_CHILD_LOCATION_ERROR, { key }))
  })

  if (!request) {
    return 'linkChildLocation failed'
  }

  const { send, message } = request.message
  dispatch(send(message))
  dispatch(actionCreater(RESPONSE_LINK_CHILD_LOCATION, { key }))
  return 'linkChildLocation success'
}

const createAndLinkChildLocation = (childContractData = {}, parentId = '0') => async dispatch => {
  const key = parentId
  dispatch(actionCreater(REQUEST_CREATE_LOCATION_AND_LINK, { key }))
  try {
    const locationRequest = await createLocation(childContractData)
    const linkRequest = await linkChildForLocation(locationRequest.data, parentId)
    const { message, send } = linkRequest.message
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_CREATE_LOCATION_AND_LINK, { key }))
    return 'createAndLinkChildLocation success'
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_CREATE_LOCATION_AND_LINK_ERROR, { key }))
  }
  return 'createAndLinkChildLocation failed'
}

const updateAndLinkChildren = contractData => async dispatch => {
  const id = contractData && contractData.id
  const key = id
  const { children, ...restContract } = contractData
  dispatch(actionCreater(REQUEST_UPDATE_AND_ADD_CHILDREN, { key }))
  try {
    const httpRequests = [updateLocation(restContract)]
    if (children && children.length) {
      const childIds = children.map(value => value.id)
      httpRequests.push(addLocationChildren(id, childIds))
    }
    const requests = await Promise.all(httpRequests)
    const [updateRequest] = requests
    const { message, send } = updateRequest.message
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_UPDATE_AND_ADD_CHILDREN, { key, data: contractData }))
    return Promise.resolve(restContract)
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_UPDATE_AND_ADD_CHILDREN_ERROR, { key }))
  }
  return Promise.reject(new Error('createAndLinkChildLocation failed'))
}

const resetForm = key => async dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key }))

export default {
  createCountryAction,
  createLocationAction,
  updateLocationAction,
  getLocation,
  linkChildLocation,
  createAndLinkChildLocation,
  resetForm,
  updateAndLinkChildren
}
