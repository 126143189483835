/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { appInitialState } from './reducer'

export const getAppLifeCycletateByAppId = (state, appId) =>
  state.app.appLifeCycle[appId] || appInitialState

export const isAppLifeCyclePending = (state, appId) => {
  const currentState = getAppLifeCycletateByAppId(state, appId)
  return [currentState.deactivationDataState, currentState.activationDataState].some(
    value => value === DATA_STATE_REQUESTING
  )
}

export const getAppLifeCycleDataStates = (state, appId) => {
  const currentState = getAppLifeCycletateByAppId(state, appId)
  return [currentState.deactivationDataState, currentState.activationDataState]
}

export const getAppLifeCycleState = state => state.app.appLifeCycle
