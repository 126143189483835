/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import get from 'lodash/get'
import moment from 'moment'
import { FORMAT } from 'constants/date'
import { GREATER_THEN_ZERO_MESSAGE, PERIOD_OVERLAP_MESSAGE } from 'helpers/types/formHelpers'
import { REGEX_ANY_NUMBER_VALIDATION } from 'constants/regExps'
import {
  MAX_RATE_FEE,
  MIN_RATE_FEE,
  NO_END_DATE_VALUE,
  REGEX_DECIMALS_VALIDATION_FEE
} from '../types'

export const borderValidationFee = value =>
  !value || (value >= MIN_RATE_FEE && value <= MAX_RATE_FEE) ? null : GREATER_THEN_ZERO_MESSAGE

export const decimalsValidationFee = value =>
  !value || REGEX_DECIMALS_VALIDATION_FEE.test(value) ? null : GREATER_THEN_ZERO_MESSAGE

export function anyNumberValidation(value) {
  return !value || REGEX_ANY_NUMBER_VALIDATION.test(value) ? null : GREATER_THEN_ZERO_MESSAGE
}

export const convertDates = fees =>
  fees.map(item => ({
    ...item,
    period: item.period && {
      startDate: item.period.startDate && moment(item.period.startDate, FORMAT).toDate(),
      endDate: item.period.endDate && moment(item.period.endDate, FORMAT).toDate()
    }
  }))

export const getDateRanges = (fees, currentItem) =>
  fees
    .filter(
      fee =>
        fee.id !== currentItem.id &&
        fee.period &&
        fee.period.startDate &&
        (fee.period.endDate || fee.noEndDate)
    )
    .map(fee => ({
      from: fee.period.startDate,
      to: fee.period.endDate || moment(NO_END_DATE_VALUE, FORMAT).toDate()
    }))

export const getDisabledRanges = (fees, currentItem) => {
  const dateRanges = getDateRanges(fees, currentItem)

  return { disabledDays: dateRanges }
}

export const periodOverlapValidation = index => (currentValue, values) => {
  const fees = convertDates(values.fees)
  const currentItem = get(fees, index)
  if (
    !currentItem.period ||
    !currentItem.period.startDate ||
    (!currentItem.period.endDate && !currentItem.noEndDate)
  )
    return null

  const dateRanges = getDateRanges(fees, currentItem)
  const isOverlap = dateRanges.some(
    range =>
      range.from >= currentItem.period.startDate &&
      range.to <= (currentItem.period.endDate || moment(NO_END_DATE_VALUE, FORMAT).toDate())
  )

  return isOverlap ? PERIOD_OVERLAP_MESSAGE : null
}
