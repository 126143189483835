/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { APPROVE_LEVEL } from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import get from 'lodash/get'
import has from 'lodash/has'
import { STATUS_DRAFT, STATUS_ACTIVE, STATUS_INACTIVE } from 'constants/entityStatus'

export function canUpdateForm(authUser, entityData) {
  const status = get(entityData, 'status')
  const hasId = has(entityData, 'id')
  const isAllowed = isActionAllowed(APPROVE_LEVEL, authUser)

  if (!hasId) {
    return isAllowed
  }

  return isAllowed && [STATUS_ACTIVE, STATUS_DRAFT].includes(status)
}

export function canDeactivateForm(authUser, entityData) {
  const status = get(entityData, 'status')
  const isAllowed = isActionAllowed(APPROVE_LEVEL, authUser)

  return isAllowed && [STATUS_ACTIVE].includes(status)
}

export function canActivateForm(authUser, entityData /* optionsFormState */) {
  const status = get(entityData, 'status')
  //  const activityStatus = get(optionsFormState, 'values.activity.parameters.status')

  const isAllowed = isActionAllowed(APPROVE_LEVEL, authUser)

  return isAllowed && [STATUS_DRAFT].includes(status)
}

export function canChangeToDraftForm(authUser, entityData) {
  const status = get(entityData, 'status')
  const isAllowed = isActionAllowed(APPROVE_LEVEL, authUser)

  return isAllowed && [STATUS_INACTIVE].includes(status)
}

export function canCreateNewDealFromApp(authUser) {
  const isAllowed = isActionAllowed(APPROVE_LEVEL, authUser)

  return isAllowed
}
