/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import {
  actions as programSeasonListActions,
  getSeasonsListState
} from 'reducers/programSeasonList'

import { SeasonItem as SeasonItemComponent } from './SeasonItem'

const mapStateToProps = (state, props) => {
  const { programId } = props
  const seasonsListState = getSeasonsListState(state, programId)
  return {
    seasonRemoveState: seasonsListState.seasonRemoveState,
    responseData: seasonsListState.responseData
  }
}
const mapDispatchToProps = (dispatch, props) => {
  const { programId } = props
  const { deleteSeasonById } = programSeasonListActions
  return bindActionCreators({ deleteSeasonById: deleteSeasonById.bind(null, programId) }, dispatch)
}

export const SeasonItem = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SeasonItemComponent)
