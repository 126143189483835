/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { pmcInitialState } from './reducer'

export const getPmcEligibilityFormByPmcId = (state, pmcId) =>
  state.eligibility.pmcEligibilityForm[pmcId] || pmcInitialState

export const getPmcEligibilityFormState = state => state.eligibility.pmcEligibilityForm
