/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'

import { buildUnitsColumns } from 'modules/Units/UnitsList/helpers'

export function buildTableColumns({ programData }) {
  const data = get(programData, 'pmsInfo.parameters')
  const programId = get(programData, 'id')

  return buildUnitsColumns(data, programId)
}
