import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import { withFormContext } from 'components/FormComponents/helpers/withFormContext'
import { PencilIcon, TrashIcon } from 'icons'
import { WeekSelectorField } from 'components/FormComponents'
import classNames from 'classnames'
import { TimePeriodsPopover } from '../TimePeriodsPopover'
import styles from './styles'
import { WEEK_DAYS_FIELD } from './types'

export const TimePeriodsListComponent = ({
  timePeriods,
  onEditTimePeriod,
  onRemoveTimePeriod,
  canSeeRemoveTimePeriod,
  formState,
  getWeekSelectorMapper
}) => {
  const canSeeRemove = canSeeRemoveTimePeriod()

  const renderTimePeriod = (timePeriod, key) => {
    if (key === WEEK_DAYS_FIELD) {
      return null
    }

    const { datePeriod } = formState

    return (
      <div key={key} className={styles.timePeriodItem}>
        <div className={styles.timePeriodWrapper}>
          <span className={styles.timePeriodLabel}>
            {`${timePeriod.startTime} - ${timePeriod.endTime}`}
          </span>
          <TimePeriodsPopover onSubmit={onEditTimePeriod(key)} initialValues={timePeriod} isEdit>
            <PencilIcon width="1.1rem" height="1.1rem" />
          </TimePeriodsPopover>
        </div>
        {datePeriod && (
          <WeekSelectorField
            className={styles.weekDaySelector}
            fieldName={`timePeriods.${key}.${WEEK_DAYS_FIELD}`}
            mapper={getWeekSelectorMapper(datePeriod)}
          />
        )}
        {canSeeRemove && (
          <TrashIcon onClick={onRemoveTimePeriod(key)} width="1.1rem" height="1.1rem" />
        )}
      </div>
    )
  }

  return (
    <div
      className={classNames(styles.timePeriodList, { [styles.hidden]: Boolean(formState.allDay) })}
    >
      {map(timePeriods, renderTimePeriod)}
    </div>
  )
}

TimePeriodsListComponent.propTypes = {
  timePeriods: PropTypes.object.isRequired,
  onEditTimePeriod: PropTypes.func.isRequired,
  onRemoveTimePeriod: PropTypes.func.isRequired,
  canSeeRemoveTimePeriod: PropTypes.func.isRequired,
  getWeekSelectorMapper: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    datePeriod: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string
    }),
    timePeriods: PropTypes.object,
    date: PropTypes.string,
    allDay: PropTypes.bool
  })
}

TimePeriodsListComponent.defaultProps = {
  formState: {
    datePeriod: {
      from: null,
      to: null
    },
    date: null,
    allDay: false,
    timePeriods: {}
  }
}
export const TimePeriodsList = withFormContext(TimePeriodsListComponent)
