import { actionCreater } from 'helpers/actionHelpers'
import { searchByColumns } from 'services/http/search'

import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  getRequestOptions
} = helpers

const getApsByActivityTagId = (filters = [], options = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await searchByColumns(filters, getRequestOptions(options)).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_ERROR_LIST))
    }
  )

  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { data: request.data }))
    return 'getApsByActivityTagId success'
  }

  return 'getApsByActivityTagId failed'
}

const resetList = activityTagId => dispatch =>
  dispatch(actionCreater(RESET_DATA_STATE, { key: activityTagId }))

export default {
  getApsByActivityTagId,
  resetList
}
