/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { activityProvidersTableColumns, buildDefaultButtonColumn } from 'helpers/dataTablesHelpers'
import { EditButton } from './AdditionalColumns'

export const buildTableColumns = props => {
  const tableColumns = activityProvidersTableColumns.concat({
    ...buildDefaultButtonColumn(),
    Cell: data => <EditButton {...props} data={data} />
  })

  return tableColumns
}
