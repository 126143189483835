/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React from 'react'
import classNames from 'classnames'

import { PIEIcon } from 'icons'
import { LoginForm } from 'modules/Login/LoginForm'
import { NAME_APPLICATION, NAME_APPLICATION_SHORT } from 'constants/common'

import styles from './Login.scss'

export const Login = () => (
  <div className={classNames(styles.wrapper)}>
    <header className={classNames(styles.headerWrapper)}>
      <div className={classNames(styles.logo)} />
    </header>
    <section className={classNames(styles.content)}>
      <div className={classNames(styles.pieLogo)}>
        <PIEIcon className={classNames(styles.svgLogo)} />
        <h1 className={classNames(styles.logoText)}>{NAME_APPLICATION_SHORT}</h1>
        <h2 className={classNames(styles.logoSubText)}>{NAME_APPLICATION}</h2>
      </div>
      <div className={classNames(styles.loginFormWrapper)}>
        <LoginForm />
      </div>
    </section>
  </div>
)
