/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_LOCATIONS_LIST,
  RESPONSE_LOCATIONS_LIST,
  RESPONSE_LOCATIONS_LIST_ERROR
} = helpers

export const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  locationsDataState: DATA_STATE_NOT_REQUESTED,
  responseData: null,
  locations: []
}

const addressFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FORM:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_FORM_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case RESPONSE_FORM:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        responseData: action.payload.responseData
      }
    // countries
    case REQUEST_LOCATIONS_LIST:
      return {
        ...state,
        locationsDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_LOCATIONS_LIST_ERROR:
      return {
        ...state,
        locationsDataState: DATA_STATE_ERROR
      }
    case RESPONSE_LOCATIONS_LIST:
      return {
        ...state,
        locationsDataState: DATA_STATE_RECIEVED,
        locations: action.payload
      }
    case RESET_DATA_STATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default filterActions(addressFormReducer, [
  REQUEST_FORM,
  RESPONSE_FORM_ERROR,
  RESPONSE_FORM,
  RESET_DATA_STATE,
  REQUEST_LOCATIONS_LIST,
  RESPONSE_LOCATIONS_LIST,
  RESPONSE_LOCATIONS_LIST_ERROR
])
