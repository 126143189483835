/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

export const STAND_ALONE_TYPE = 'STAND_ALONE'
export const STAND_ALONE_VALUE = 'Stand-Alone'

export const TOP_ACTIVITIES_TYPE = 'TOP_ACTIVITIES'
export const TOP_ACTIVITIES_VALUE = 'Top Activities'
