/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withFormContext } from 'components/FormComponents/helpers'
import { withBlankValidation } from 'helpers/formHelpers'
import styles from './TextArea.scss'
import { TextArea } from './TextArea'

export const TextAreaFieldComponent = props => {
  const {
    fieldName,
    validate,
    isRequired,
    className,
    placeholder,
    disabled,
    children,
    ...rest
  } = props
  const validateStrategy = isRequired ? withBlankValidation(validate) : validate

  return (
    <TextArea
      {...rest}
      isRequired={isRequired}
      fieldChildren={children}
      field={fieldName}
      validate={validateStrategy}
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      validateOnChange
    />
  )
}

TextAreaFieldComponent.propTypes = {
  fieldProps: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.func,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node.isRequired
}

TextAreaFieldComponent.defaultProps = {
  fieldProps: {},
  placeholder: '',
  disabled: false,
  validate: () => null,
  isRequired: false,
  className: styles.textarea,
  label: ''
}

export const TextAreaField = withFormContext(TextAreaFieldComponent)
