/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getList } from 'services/http/activityModule'

import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST } = helpers

const getActivityModuleList = options => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))

  const request = await getList(options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { ...request.data }))
    return 'getActivityModuleList success'
  }

  return 'getActivityModuleList failed'
}

export default {
  getActivityModuleList
}
