/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  downloadTemplate,
  addGroupTemplate,
  removeGroupTemplate,
  generateBarcodeTemplate
} from 'services/http/ticketGroup'
import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_UPLOAD_TEMPLATE,
  RESPONSE_UPLOAD_TEMPLATE,
  RESPONSE_UPLOAD_TEMPLATE_ERROR,
  REQUEST_REMOVE_TEMPLATE,
  RESPONSE_REMOVE_TEMPLATE,
  RESPONSE_REMOVE_TEMPLATE_ERROR,
  REQUEST_DOWNLOAD_TEMPLATE,
  RESPONSE_DOWNLOAD_TEMPLATE,
  RESPONSE_DOWNLOAD_TEMPLATE_ERROR,
  REQUEST_DOWNLOAD_BARCODE_TEMPLATE,
  RESPONSE_DOWNLOAD_BARCODE_TEMPLATE,
  RESPONSE_DOWNLOAD_BARCODE_TEMPLATE_ERROR
} = helpers

const downloadCustomTemplate = (activityId, groupId, fileName) => async dispatch => {
  dispatch(actionCreater(REQUEST_DOWNLOAD_TEMPLATE))
  try {
    await downloadTemplate(activityId, groupId, fileName)
    dispatch(actionCreater(RESPONSE_DOWNLOAD_TEMPLATE))
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DOWNLOAD_TEMPLATE_ERROR))
  }
}

const addCustomTemplate = (activityId, groupId) => async dispatch => {
  dispatch(actionCreater(REQUEST_UPLOAD_TEMPLATE))
  try {
    await addGroupTemplate(activityId, groupId)
    dispatch(actionCreater(RESPONSE_UPLOAD_TEMPLATE))
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_UPLOAD_TEMPLATE_ERROR))
  }
}

const removeCustomTemplate = (activityId, groupId) => async dispatch => {
  dispatch(actionCreater(REQUEST_REMOVE_TEMPLATE))
  try {
    await removeGroupTemplate(activityId, groupId)
    dispatch(actionCreater(RESPONSE_REMOVE_TEMPLATE))
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_REMOVE_TEMPLATE_ERROR))
  }
}

const downloadBarcodeTemplate = (activityId, groupId) => async dispatch => {
  dispatch(actionCreater(REQUEST_DOWNLOAD_BARCODE_TEMPLATE))
  try {
    await generateBarcodeTemplate(activityId, groupId)
    dispatch(actionCreater(RESPONSE_DOWNLOAD_BARCODE_TEMPLATE))
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DOWNLOAD_BARCODE_TEMPLATE_ERROR))
  }
}

const resetTemplateState = () => ({
  type: RESET_DATA_STATE
})

export default {
  resetTemplateState,
  downloadCustomTemplate,
  addCustomTemplate,
  removeCustomTemplate,
  downloadBarcodeTemplate
}
