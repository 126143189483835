/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { MarkIcon, CloseMarkIcon } from 'icons'
import { withModalWindow } from 'decorators'
import { WarningModal } from 'modules/Modals'
import { isSuccessUploaded } from '../helpers'
import { PIE_ERROR_MESSAGE } from '../types'

import styles from '../GuestsList.scss'

const CloseMarkWithModal = withModalWindow(CloseMarkIcon)

export function StatusCell({ status }) {
  const isSuccess = useMemo(() => isSuccessUploaded(status), [status])
  if (isSuccess) {
    return <MarkIcon className={styles.markIcon} />
  }
  return (
    <CloseMarkWithModal
      className={styles.closeIcon}
      renderModal={({ isShown, onConfirm, onHide }) => (
        <WarningModal
          isOpen={isShown}
          onConfirm={onConfirm}
          onHide={onHide}
          message={PIE_ERROR_MESSAGE}
        />
      )}
    />
  )
}
StatusCell.propTypes = {
  status: PropTypes.string
}
StatusCell.defaultProps = {
  status: null
}
