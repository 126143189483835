/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { PIEIcon } from 'icons'
import classNames from 'classnames'

import styles from './PIELoader.scss'

export function PIELoader() {
  return <PIEIcon className={classNames(styles.svgLogo, styles.animationStyles)} />
}
