/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { withAuthContext } from 'helpers/withAuthContext'
import { actions, getAppLifeCycletateByAppId } from 'reducers/app/appLifeCycle'
import { formActions } from 'reducers/activityProductForm'

import { DelayedDeactivation as DelayedDeactivationComponent } from './DelayedDeactivation'

const mapStateToProps = (state, { dataId }) => {
  const appState = getAppLifeCycletateByAppId(state, dataId)

  return {
    ...appState,
    // dataId,
    entityDataState: state.activityProductForm.entityDataState,
    entityData: state.activityProductForm.entityData
  }
}

const mapDispatchToProps = dispatch => {
  const { getAPPbyId } = formActions

  return bindActionCreators({ ...actions, getAPPbyId }, dispatch)
}

export const DelayedDeactivation = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DelayedDeactivationComponent)
