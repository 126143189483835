import { actionCreater } from 'helpers/actionHelpers'
import { getDiscountList } from 'services/http/program'

import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_ERROR_LIST, RESPONSE_LIST } = helpers

const getDiscountsListByProgramId = (id, data, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await getDiscountList(id, data, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { ...request.data }))
    return 'getDiscountsListByProgramId success'
  }

  return 'getDiscountsListByProgramId failed'
}

export default {
  getDiscountsListByProgramId
}
