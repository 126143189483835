/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { setCookie, getCookie, deleteCookie } from 'helpers/cookieHelper'
import { ACCESS_TOKEN_COOKIE_NAME, REFRESH_TOKEN_COOKIE_NAME, ENV_NAME } from './types'

export const saveAuthTokens = (authData = {}) => {
  const isDev = ENV_NAME === 'test'
  setCookie(ACCESS_TOKEN_COOKIE_NAME, authData.access_token, {
    domain: isDev ? 'localhost' : '.xplorie.com',
    expires: authData.expires_in,
    path: '/'
  })
  setCookie(REFRESH_TOKEN_COOKIE_NAME, authData.refresh_token, {
    domain: isDev ? 'localhost' : '.xplorie.com',
    path: '/'
  })
}

export const getAuthToken = () => {
  const accessToken = getCookie(ACCESS_TOKEN_COOKIE_NAME)
  return accessToken
}

export const getRefreshToken = () => getCookie(REFRESH_TOKEN_COOKIE_NAME)

export const clearAccessToken = () => {
  deleteCookie(ACCESS_TOKEN_COOKIE_NAME)
}

export const clearAuthCookie = () => {
  deleteCookie(ACCESS_TOKEN_COOKIE_NAME)
  deleteCookie(REFRESH_TOKEN_COOKIE_NAME)
}
