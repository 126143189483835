import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import over from 'lodash/over'
import Calendar from '@fullcalendar/react'

export const FullCalendar = React.forwardRef((props, ref) => {
  const {
    onDayContextMenu,
    onEventContextMenu,
    dayCellDidMount,
    dayCellWillUnmount,
    eventDidMount,
    eventWillUnmount,
    handleClickEvent,
    eventClick,
    ...rest
  } = props
  const sharedApi = useRef({
    onDayContextMenu,
    onEventContextMenu
  })

  useEffect(() => {
    sharedApi.current = {
      onDayContextMenu,
      onEventContextMenu
    }
  }, [onEventContextMenu, onDayContextMenu])

  const handleDayContext = info => event => {
    if (onDayContextMenu) {
      sharedApi.current.onDayContextMenu({ ...info, jsEvent: event })
    }
  }

  const handleEventContext = info => event => {
    if (onEventContextMenu) {
      sharedApi.current.onEventContextMenu({ ...info, jsEvent: event })
    }
  }

  const handleDayCellDidMount = info => {
    info.el.addEventListener('contextmenu', handleDayContext(info))

    if (dayCellDidMount) {
      dayCellDidMount(info)
    }
  }

  const handleDayCellWillUnmount = info => {
    info.el.removeEventListener('contextmenu', handleDayContext(info))

    if (dayCellWillUnmount) {
      dayCellWillUnmount(info)
    }
  }

  const handleEventCellDidMount = info => {
    info.el.addEventListener('contextmenu', handleEventContext(info))

    if (eventDidMount) {
      eventDidMount(info)
    }
  }

  const handleEventCellWillUnmount = info => {
    info.el.removeEventListener('contextmenu', handleEventContext(info))

    if (eventWillUnmount) {
      eventWillUnmount(info)
    }
  }

  const onClick = event => over([handleClickEvent, eventClick])(event)

  return (
    <Calendar
      {...rest}
      eventClick={onClick}
      ref={ref}
      dayCellDidMount={handleDayCellDidMount}
      dayCellWillUnmount={handleDayCellWillUnmount}
      eventDidMount={handleEventCellDidMount}
      eventWillUnmount={handleEventCellWillUnmount}
    />
  )
})

FullCalendar.propTypes = {
  handleClickEvent: PropTypes.func,
  eventClick: PropTypes.func,
  dayCellDidMount: PropTypes.func,
  dayCellWillUnmount: PropTypes.func,
  onDayContextMenu: PropTypes.func,
  eventDidMount: PropTypes.func,
  eventWillUnmount: PropTypes.func,
  onEventContextMenu: PropTypes.func
}

FullCalendar.defaultProps = {
  dayCellDidMount: null,
  dayCellWillUnmount: null,
  onDayContextMenu: null,
  eventDidMount: null,
  eventWillUnmount: null,
  onEventContextMenu: null,
  handleClickEvent: null,
  eventClick: null
}
