/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

const NAME = 'RELATED_PROGRAMS'
const GET_CURRENT_PROGRAM = 'GET_CURRENT_PROGRAM'
const GET_RELATED_PROGRAM = 'GET_RELATED_PROGRAM'
export default {
  NAME,
  GET_CURRENT_PROGRAM,
  GET_RELATED_PROGRAM
}
