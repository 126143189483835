/* eslint-disable max-lines */
/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

// Internal dependencies
import { LocationSlider } from 'modules/Locations'
import { ActivityCategoryForm } from 'modules/ActivityCategory'
import { PMSSlider } from 'modules/PMS'
import { TileAccordionForm } from 'modules/TileAccordion'
import { ActivityTagsForm } from "modules/ActivityTags";
import { ActivityModuleForm } from 'modules/ActivityModule'
import { RMSForm } from 'modules/RMS'
import { UniqPhoneNumberForm } from 'modules/UniqPhoneNumber'
import { AddressForm } from 'modules/Address'
import { titleBuilder, keyBuilder } from 'helpers/formHeaders'
import {
  ADDRESS,
  LOCATION,
  ACTIVITY_CATEGORY,
  PMS,
  TILE_ACCORDION,
  ACTIVITY_TAG,
  ACTIVITY_MODULE,
  RMS,
  UNIQ_PHONE_NUMBER,
} from 'constants/formCodes'

export const queueAddressForm = (props, formProps = {}) => e => {
  e.preventDefault()
  props.enqueue(
    keyBuilder(ADDRESS, formProps.formData),
    AddressForm,
    {
      ...formProps
    },
    {
      title: titleBuilder(ADDRESS, formProps.formData, 'city')
    }
  )
}

export const queueLocationForm = (props, formData = {}) => () => e => {
  e.preventDefault()
  props.enqueue(
    keyBuilder(LOCATION, formData),
    LocationSlider,
    {
      dataId: formData && formData.id,
      ...formData
    },
    {
      title: titleBuilder(LOCATION, formData)
    }
  )
}

export const queueActivityCategoryForm = (props, formData = {}) => callback => e => {
  e.preventDefault()
  props.enqueue(
    keyBuilder(ACTIVITY_CATEGORY, formData),
    ActivityCategoryForm,
    {
      dataId: formData && formData.id,
      onRequestCallback: callback
    },
    {
      title: titleBuilder(ACTIVITY_CATEGORY, formData)
    }
  )
}

export const queuePMSForm = (props, formData = {}) => callback => e => {
  e.preventDefault()
  props.enqueue(
    keyBuilder(PMS, formData),
    PMSSlider,
    {
      dataId: formData && formData.id,
      onRequestCallback: callback
    },
    {
      title: titleBuilder(PMS, formData)
    }
  )
}

export const queueTileAccordionForm = (props, formData = {}) => callback => e => {
  e.preventDefault()
  props.enqueue(
    keyBuilder(TILE_ACCORDION, formData),
    TileAccordionForm,
    {
      dataId: formData && formData.id,
      onRequestCallback: callback
    },
    {
      title: titleBuilder(TILE_ACCORDION, formData)
    }
  )
}

export const queueActivityTagsForm = (props, formData = {}) => callback => e => {
  e.preventDefault()
  props.enqueue(
    keyBuilder(ACTIVITY_TAG, formData),
    ActivityTagsForm,
    {
      dataId: formData && formData.id,
      onRequestCallback: callback
    },
    {
      title: titleBuilder(ACTIVITY_TAG, formData)
    }
  )
}

export const queueActivityModuleForm = (props, formData = {}) => callback => e => {
  e.preventDefault()
  props.enqueue(
    keyBuilder(ACTIVITY_MODULE, formData),
    ActivityModuleForm,
    {
      dataId: formData && formData.id,
      onRequestCallback: callback
    },
    {
      title: titleBuilder(ACTIVITY_MODULE, formData)
    }
  )
}

export const queueRMSForm = (props, formData = {}) => callback => e => {
  e.preventDefault()
  props.enqueue(
    keyBuilder(RMS, formData),
    RMSForm,
    {
      dataId: formData && formData.id,
      onRequestCallback: callback
    },
    {
      title: titleBuilder(RMS, formData)
    }
  )
}

export const queueUniqPhoneNumberForm = (props, formData = {}) => callback => e => {
  e.preventDefault()
  props.enqueue(
    keyBuilder(UNIQ_PHONE_NUMBER, formData),
    UniqPhoneNumberForm,
    {
      dataId: formData && formData.id,
      onRequestCallback: callback,
      programId: formData.programId
    },
    {
      title: titleBuilder(UNIQ_PHONE_NUMBER, formData)
    }
  )
}
