/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST, RESET_DATA_STATE } = helpers

export const initialState = {}

export const pmcProgramsListInitialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  data: [],
  itemsCount: 0,
  itemsPerPage: 10,
  pageNumber: 1
}

const pmcUnitsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      return {
        ...state,
        [action.payload.key]: {
          ...pmcProgramsListInitialState,
          ...state[action.payload.key],
          dataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_LIST:
      return {
        ...state,
        [action.payload.key]: {
          ...pmcProgramsListInitialState,
          ...state[action.payload.key],
          dataState: DATA_STATE_RECIEVED,
          data: action.payload.data,
          ...action.payload.pagination
        }
      }
    case RESPONSE_ERROR_LIST:
      return {
        ...state,
        [action.payload.key]: {
          ...pmcProgramsListInitialState,
          ...state[action.payload.key],
          dataState: DATA_STATE_ERROR
        }
      }
    case RESET_DATA_STATE: {
      const { [action.payload.key]: removedState, ...restState } = state
      return {
        ...restState
      }
    }
    default:
      return state
  }
}

export default filterActions(pmcUnitsListReducer, [
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE
])
