import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { PIEIcon } from 'icons'
import { NAME_APPLICATION, NAME_APPLICATION_SHORT } from 'constants/common'
import { PasswordRecoveryForm } from '../PasswordRecoveryForm'
import styles from './PasswordRecoveryPage.scss'

export function PasswordRecoveryPage({
  recoveryToken,
  recoverPassword,
  recoveryPasswordDataState
}) {
  return (
    <div className={styles.wrapper}>
      <header className={styles.headerWrapper}>
        <NavLink to="/">
          <div className={styles.logo} />
        </NavLink>
      </header>
      <section className={styles.content}>
        <div className={styles.pieLogo}>
          <PIEIcon className={styles.svgLogo} />
          <h1 className={styles.logoText}>{NAME_APPLICATION_SHORT}</h1>
          <h2 className={styles.logoSubText}>{NAME_APPLICATION}</h2>
        </div>
        <div className={styles.formWrapper}>
          <PasswordRecoveryForm
            recoveryToken={recoveryToken}
            recoverPassword={recoverPassword}
            recoveryPasswordDataState={recoveryPasswordDataState}
          />
        </div>
      </section>
    </div>
  )
}

PasswordRecoveryPage.propTypes = {
  recoveryToken: PropTypes.object.isRequired,
  recoverPassword: PropTypes.func.isRequired,
  recoveryPasswordDataState: PropTypes.string.isRequired
}
