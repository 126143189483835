/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { BouncingLoader } from 'components/Loaders'
import { AvailabilityForm, ClosuresModule } from 'modules/ActivityProducts'
import { useTabPresenter } from './useTabPresenter'
import styles from './styles.scss'

export function SchedulesTab(props) {
  const { loading, form, closures, id } = useTabPresenter(props)

  if (loading) {
    return <BouncingLoader />
  }

  return (
    <div>
      <div className={styles.module}>
        <ClosuresModule id={id} visible={closures.visible} onAction={closures.onAction} />
      </div>
      {!closures.visible && (
        <div className={styles.module}>
          <AvailabilityForm
            data={form.data}
            submitVisible={!form.disabled}
            disabled={form.disabled}
            onSubmit={form.onSubmit}
          />
        </div>
      )}
    </div>
  )
}
