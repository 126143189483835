/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

export const CLONE_TEXT = 'Clone Season'

export const NOT_INDICATED = 'Not Indicated'
export const IS_EMPTY_ACTIVITIES_LIST_TEXT = 'Issue with Activities'

export const LABEL_ACTIVITIES_ACTIVE = 'Activities (ACTIVE)'
export const LABEL_ACTIVITIES_TESTING = 'Activities (TESTING)'
export const LABEL_START_DATE = 'Start Date'
export const LABEL_END_DATE = 'End Date'
export const LABEL_FEE = 'Fee'
export const SEASON_LIST_ERRORS = 'Errors'

// error messages
export const BEGIN_END_MONTH_ERROR_MESSAGE =
  'The date of the end of the season can not be earlier than the date of the beginning'
export const NULL_ACTIVITIES_ERROR_MESSAGE = 'In a season there must be at least one activity'
export const INCCORECT_DATE_SEASON_ERROR_MESSAGE = 'Dates are incorrect'
export const UNKNOWN_ERROR_MESSAGE = 'Unknown error'
