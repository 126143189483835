/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'
import { BOOLEAN_TYPE, EQUALS_MODE } from 'constants/eligibility'

export function buildEligibilityOptions(data = []) {
  return data.map(value => ({ label: get(value, 'parameter.label'), value: value.id }))
}

export function getDefaultParamsByType(type) {
  switch (type) {
    case BOOLEAN_TYPE:
      return { value: false, mode: EQUALS_MODE }
    default:
      return {}
  }
}

export function createValue(parameter) {
  return {
    id: null,
    pmsParameterId: parameter.id,
    parameter: parameter.parameter,
    ...getDefaultParamsByType(parameter.parameter.type)
  }
}
