/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import {
  RESET_DATA_STATE,
  REQUEST_UNIT_BY_ID,
  RESPONSE_UNIT_BY_ID,
  RESPONSE_UNIT_BY_ID_ERROR,
  REQUEST_UNIT_ADDRESS_BY_ID,
  RESPONSE_UNIT_ADDRESS_BY_ID,
  RESPONSE_UNIT_ADDRESS_BY_ID_ERROR,
  REQUEST_UNIT_AMENITIES_BY_ID,
  RESPONSE_UNIT_AMENITIES_BY_ID,
  RESPONSE_UNIT_AMENITIES_BY_ID_ERROR,
  REQUEST_UNIT_IMAGES_BY_ID,
  RESPONSE_UNIT_IMAGES_BY_ID,
  RESPONSE_UNIT_IMAGES_BY_ID_ERROR,
  REQUEST_UNIT_SMART_HOST_BY_ID,
  RESPONSE_UNIT_SMART_HOST_BY_ID_ERROR,
  RESPONSE_UNIT_SMART_HOST_BY_ID,
  REQUEST_UPDATE_UNIT_SMART_HOST_BY_ID,
  RESPONSE_UPDATE_UNIT_SMART_HOST_BY_ID,
  RESPONSE_UPDATE_UNIT_SMART_HOST_BY_ID_ERROR,
} from './helpers'

const initialState = {
  entityDataState: DATA_STATE_NOT_REQUESTED,
  addressDataState: DATA_STATE_NOT_REQUESTED,
  amenitiesDataState: DATA_STATE_NOT_REQUESTED,
  imagesDataState: DATA_STATE_NOT_REQUESTED,
  smartHostDataState: DATA_STATE_NOT_REQUESTED,
  updateSmartHostDataState: DATA_STATE_NOT_REQUESTED,
  entityData: null,
  addressData: null,
  amenitiesData: null,
  imagesData: [],
  smartHostData: null,
}

const rmsDictionaryFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_UNIT_BY_ID:
      return {
        ...state,
        entityDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_UNIT_BY_ID_ERROR:
      return {
        ...state,
        entityDataState: DATA_STATE_ERROR
      }
    case RESPONSE_UNIT_BY_ID:
      return {
        ...state,
        entityData: action.payload,
        entityDataState: DATA_STATE_RECIEVED
      }
    case REQUEST_UNIT_ADDRESS_BY_ID:
      return {
        ...state,
        addressDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_UNIT_ADDRESS_BY_ID_ERROR:
      return {
        ...state,
        addressDataState: DATA_STATE_ERROR
      }
    case RESPONSE_UNIT_ADDRESS_BY_ID:
      return {
        ...state,
        addressData: action.payload,
        addressDataState: DATA_STATE_RECIEVED
      }
    case REQUEST_UNIT_AMENITIES_BY_ID:
      return {
        ...state,
        amenitiesDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_UNIT_AMENITIES_BY_ID_ERROR:
      return {
        ...state,
        amenitiesDataState: DATA_STATE_ERROR
      }
    case RESPONSE_UNIT_AMENITIES_BY_ID:
      return {
        ...state,
        amenitiesData: action.payload,
        amenitiesDataState: DATA_STATE_RECIEVED
      }
    case REQUEST_UNIT_IMAGES_BY_ID:
      return {
        ...state,
        imagesDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_UNIT_IMAGES_BY_ID_ERROR:
      return {
        ...state,
        imagesDataState: DATA_STATE_ERROR
      }
    case RESPONSE_UNIT_IMAGES_BY_ID:
      return {
        ...state,
        imagesDataState: DATA_STATE_RECIEVED,
        imagesData: action.payload
      }
    case REQUEST_UNIT_SMART_HOST_BY_ID:
      return {
        ...state,
        smartHostDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_UNIT_SMART_HOST_BY_ID_ERROR:
      return {
        ...state,
        smartHostDataState: DATA_STATE_ERROR
      }
    case RESPONSE_UNIT_SMART_HOST_BY_ID:
      return {
        ...state,
        smartHostDataState: DATA_STATE_RECIEVED,
        smartHostData: action.payload
      }
    case REQUEST_UPDATE_UNIT_SMART_HOST_BY_ID:
      return {
        ...state,
        updateSmartHostDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_UPDATE_UNIT_SMART_HOST_BY_ID_ERROR:
      return {
        ...state,
        updateSmartHostDataState: DATA_STATE_ERROR
      }
    case RESPONSE_UPDATE_UNIT_SMART_HOST_BY_ID:
      return {
        ...state,
        updateSmartHostDataState: DATA_STATE_RECIEVED,
      }
    case RESET_DATA_STATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default filterActions(rmsDictionaryFormReducer, [
  RESET_DATA_STATE,
  REQUEST_UNIT_BY_ID,
  RESPONSE_UNIT_BY_ID,
  RESPONSE_UNIT_BY_ID_ERROR,
  REQUEST_UNIT_ADDRESS_BY_ID,
  RESPONSE_UNIT_ADDRESS_BY_ID,
  RESPONSE_UNIT_ADDRESS_BY_ID_ERROR,
  REQUEST_UNIT_AMENITIES_BY_ID,
  RESPONSE_UNIT_AMENITIES_BY_ID,
  RESPONSE_UNIT_AMENITIES_BY_ID_ERROR,
  REQUEST_UNIT_IMAGES_BY_ID,
  RESPONSE_UNIT_IMAGES_BY_ID,
  RESPONSE_UNIT_IMAGES_BY_ID_ERROR,
  REQUEST_UNIT_SMART_HOST_BY_ID,
  RESPONSE_UNIT_SMART_HOST_BY_ID,
  RESPONSE_UNIT_SMART_HOST_BY_ID_ERROR,
  REQUEST_UPDATE_UNIT_SMART_HOST_BY_ID,
  RESPONSE_UPDATE_UNIT_SMART_HOST_BY_ID,
  RESPONSE_UPDATE_UNIT_SMART_HOST_BY_ID_ERROR,
])
