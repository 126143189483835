/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { BeforeCloseModal } from 'modules/Modals'
import { useUpdateSomeDataState, useDidMount } from 'hooks'
import { PROGRAM_SEASON } from 'constants/searchTypes'
import { isSmartHostStandaloneType, ProgramSeasonContext } from 'modules/Programs/helpers'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { buildSortPropertyByType } from 'helpers/tableHelpers'
import {
  ProgramsForm,
  buildPermissions,
  buildFormConditions,
  ProgramSeasons,
  PrebookInfo,
  canSeePrebookInfo,
  canSeeSmartHostFees,
  SmartHostMobileFee,
  SmartHostSubscriptionFee
} from 'modules/Programs'

import { SEASON, PROGRAM } from 'constants/formCodes'
import get from 'lodash/get'
import { ProgramExclusiveOffers } from '../ProgramExclusiveOffers/container'

import { ButtonContainer } from './ButtonContainer'

import {
  NAME_FIELD_KEY,
  PMC_NAME_FIELD_KEY,
  LOCATION_FIELD_KEY,
  PROGRAM_TYPE_FIELD_KEY
} from '../ProgramsForm/types'
import { SMART_HOST_MOBILE_TYPE, SMART_HOST_SUBSCRIPTION_TYPE } from '../SmartHostFee/types'
import styles from './ProgramSeasonContainer.scss'

export function ProgramSeasonContainer(props) {
  const {
    dataId,
    authUser,
    getSeasonsList,
    tabKey,
    programData,
    programDataState,
    isProgramDataLoading,
    tabsApi,
    slideEvents,
    cloneDataState,
    getProgramById
  } = props

  const programApi = useRef()
  const seasonsApi = useRef()
  const programSeasonApi = useRef({})
  const beforeCloseModalApi = useRef()
  const programFormState = programApi.current && programApi.current.getState()
  const programType = get(programFormState, 'values.type')
  const [seasonsState, setSeasonsState] = useState([])

  const refreshProgramData = () => {
    if (dataId) {
      getProgramById(dataId)
    }
  }

  const [programSeasonContext, setProgramSeasonContext] = useState({
    permissions: {},
    conditions: {}
  })
  const [programSeasonsState, setProgramSeasonsState] = useState({
    program: {},
    seasons: [],
    readyForSave: false
  })

  const onGetProgramApi = useCallback(api => {
    programApi.current = api
  }, [])

  const onGetSeasonsApi = useCallback(api => {
    seasonsApi.current = api
  }, [])

  const onGetBeforeCloseModalApi = useCallback(api => {
    beforeCloseModalApi.current = api
  }, [])

  const buildContext = () => {
    const permissions = buildPermissions({ authUser, data: programData })
    setProgramSeasonContext({
      ...programSeasonContext,
      permissions,
      conditions: buildFormConditions({ dataId, data: programData, permissions })
    })
  }
  // crutch to validate form before submit for approve (informed form do tot support this)
  const validateProgram = useCallback(() => {
    if (!programApi.current) {
      return
    }
    programApi.current.notify([
      NAME_FIELD_KEY,
      PMC_NAME_FIELD_KEY,
      LOCATION_FIELD_KEY,
      PROGRAM_TYPE_FIELD_KEY
    ])
  }, [])

  const buildState = useCallback(() => {
    if (programApi.current && seasonsApi.current) {
      const programState = programApi.current.getState()
      const seasonsFormState = seasonsApi.current.getState()
      const currentProgramType = get(programState, 'values.type')
      const seasonsErrors = seasonsApi.current.onValidate(currentProgramType)
      const notReadyForSave = programState.invalid || programState.pristine
      const readySaveForApprove = !notReadyForSave && !seasonsErrors.length

      return {
        program: {
          api: { ...programApi.current, validate: validateProgram },
          value: programState.values,
          errors: programState.errors,
          isTouched: !isEmpty(programState.touched)
        },
        seasons: {
          api: seasonsApi.current,
          value: seasonsFormState.values,
          errors: seasonsErrors,
          isTouched: seasonsFormState.isTouched
        },
        readyForSave: !notReadyForSave,
        readySaveForApprove
      }
    }
    return programSeasonsState
  }, [programSeasonsState, validateProgram])

  const getState = () => buildState()

  const getProgramSeasons = useCallback(() => {
    if (dataId) {
      getSeasonsList(dataId, {
        sort: buildSortPropertyByType('startDate', PROGRAM_SEASON),
        itemsPerPage: 0
      })
    }
  }, [dataId, getSeasonsList])

  useDidMount(() => {
    getProgramSeasons()
    buildContext()
    programSeasonApi.current = {
      getState
    }
    return () => {
      // unsubscribe slider events before un mount
      slideEvents.onBeforeClose = null
    }
  })

  useUpdateSomeDataState(
    () => {
      buildContext()
    },
    [programDataState],
    DATA_STATE_RECIEVED
  )

  useUpdateSomeDataState(
    () => {
      getProgramSeasons()
    },
    [cloneDataState],
    DATA_STATE_RECIEVED
  )

  const onChangeHandler = () => {
    if (programApi.current && seasonsApi.current) {
      setProgramSeasonsState(buildState())
    }
  }

  const onBeforeCloseHandler = useCallback(
    (isAllowed = false) => {
      const { program } = buildState()
      if (isAllowed) {
        return isAllowed
      }
      if (program.isTouched) {
        return beforeCloseModalApi.current.open()
      }
      return true
    },
    [buildState]
  )

  tabsApi.onBeforeChange = nextTab => onBeforeCloseHandler([PROGRAM].includes(nextTab))

  slideEvents.onBeforeClose = onBeforeCloseHandler

  return (
    <>
      <ProgramSeasonContext.Provider value={programSeasonContext}>
        <div className={classNames(styles.element, { [styles.visible]: tabKey === PROGRAM })}>
          <ProgramsForm getApi={onGetProgramApi} onChange={onChangeHandler} {...props} />
        </div>
        <div className={classNames(styles.element, { [styles.visible]: tabKey === SEASON })}>
          {canSeePrebookInfo(programData) && <PrebookInfo {...props} />}
          {canSeeSmartHostFees(programData) && (
            <>
              <SmartHostSubscriptionFee {...props} type={SMART_HOST_SUBSCRIPTION_TYPE} />
              <SmartHostMobileFee {...props} type={SMART_HOST_MOBILE_TYPE} />
            </>
          )}
          <ProgramSeasons
            getApi={onGetSeasonsApi}
            onChange={onChangeHandler}
            programFormState={programFormState}
            seasonsState={seasonsState}
            setSeasonsState={setSeasonsState}
            {...props}
          />
          {!isSmartHostStandaloneType(programType) && <ProgramExclusiveOffers {...props} />}
        </div>
        {!isProgramDataLoading && (
          <ButtonContainer
            {...props}
            seasonsState={seasonsState}
            formState={programSeasonsState}
            api={programSeasonApi.current}
          />
        )}
      </ProgramSeasonContext.Provider>
      <BeforeCloseModal getApi={onGetBeforeCloseModalApi} onSubmit={refreshProgramData} />
    </>
  )
}
ProgramSeasonContainer.propTypes = {
  dataId: PropTypes.string,
  authUser: PropTypes.object.isRequired,
  getSeasonsList: PropTypes.func.isRequired,
  tabKey: PropTypes.string.isRequired,
  programData: PropTypes.object,
  programDataState: PropTypes.string.isRequired,
  isProgramDataLoading: PropTypes.bool.isRequired,
  tabsApi: PropTypes.object.isRequired,
  slideEvents: PropTypes.object.isRequired,
  seasonsListState: PropTypes.string.isRequired,
  cloneDataState: PropTypes.string.isRequired,
  getProgramById: PropTypes.func
}

ProgramSeasonContainer.defaultProps = {
  dataId: null,
  programData: {},
  getProgramById: () => {}
}
