/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const NAME = 'COMMUNICATION_CONNECTION'
export const RESET_COMMUNICATION = `${NAME}_RESET`
export const CHECK_RESET_PROGRESS = `${NAME}_CHECK_RESET_PROGRESS`

export default {
  NAME,
  RESET_COMMUNICATION,
  CHECK_RESET_PROGRESS
}
