/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const UnitsCommander = loadable(
  () => import(/* webpackChunkName: 'UnitsCommander', webpackPrefetch: true */ './UnitsCommander'),
  {
    resolveComponent: components => components.UnitsCommander
  }
)
const UnitManager = loadable(
  () => import(/* webpackChunkName: 'UnitManager', webpackPrefetch: true */ './UnitManager'),
  {
    resolveComponent: components => components.UnitManager
  }
)
const UnitsTabs = loadable(
  () => import(/* webpackChunkName: 'UnitsTabs', webpackPrefetch: true */ './UnitsTabs'),
  {
    resolveComponent: components => components.UnitsTabs
  }
)

export * from './UnitsList'
export { UnitsCommander }
export * from './ProgramUnitsTable'
export * from './UnitsStats'
export * from './SmartHostUnitsList'
export { UnitManager }
export { UnitsTabs }
export * from './UnitsDetailsTab'
export * from './UnitsAmenitiesTab'
