/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const SELECTOR_EXTRAS_PRICE = 'extrasPrice'
export const SELECTOR_PERCENTAGE_EXTRAS_PRICE = 'percentageExtrasPrice'

export const SELECTOR_XPLORIE_PRICE = 'xploriePrice'
export const SELECTOR_PERCENTAGE_XPLORIE_PRICE = 'percentageXploriePrice'
