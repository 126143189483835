/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'
import { NO_END_DATE_LABEL } from './types'

export function CalendarEndDateCheckbox({ classNames, children, selectedDay, ...props }) {
  const {
    overlayProps: { noEndDateEnabled = true, onChange }
  } = children.props

  const onEndDateChange = () => {
    if (onChange) {
      onChange(!noEndDateEnabled)
    }
  }

  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay}>
        {children}
        <div className={styles.checkBoxWrapper}>
          <input
            type="checkbox"
            id="end_date_enabled"
            checked={noEndDateEnabled}
            onChange={onEndDateChange}
          />
          <span className={styles.checkBoxLabel}>{NO_END_DATE_LABEL}</span>
        </div>
      </div>
    </div>
  )
}

CalendarEndDateCheckbox.propTypes = {
  classNames: PropTypes.string,
  children: PropTypes.any,
  selectedDay: PropTypes.object
}

CalendarEndDateCheckbox.defaultProps = {
  children: null,
  classNames: null,
  selectedDay: null
}
