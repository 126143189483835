/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { compose } from 'redux'
import { withFormState, withFormApi } from 'informed'
import { withFormContext } from 'components/FormComponents/helpers'
import { ActivityTable as ActivityTableComponent } from './ActivityTable'

export const ActivityTable = compose(
  withFormContext,
  withFormState,
  withFormApi
)(ActivityTableComponent)
