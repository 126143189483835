/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withFormContext } from 'components/FormComponents/helpers'
import { withValidation, blankValidate, basicTextValidate } from 'helpers/formHelpers'

import styles from './TimePickerField.scss'
import TimePickerFieldConnected from './TimePickerFieldConnected'

export const TimePickerFieldComponent = props => {
  const { fieldName, isRequired, validate, ...rest } = props
  const validateStrategy = isRequired ? withValidation(blankValidate, validate) : validate
  return (
    <TimePickerFieldConnected
      {...rest}
      field={fieldName}
      validate={validateStrategy}
      isRequired={isRequired}
      validateOnChange
    />
  )
}

TimePickerFieldComponent.propTypes = {
  isRequired: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.func,
  className: PropTypes.string
}

TimePickerFieldComponent.defaultProps = {
  isRequired: false,
  validate: basicTextValidate,
  className: styles.timePicker
}

export const TimePickerField = withFormContext(TimePickerFieldComponent)
