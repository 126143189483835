/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ButtonsWrapper } from 'components/Wrappers'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { SearchTab } from './SearchTab'
import styles from './SearchTabs.scss'

export function SearchTabs(props) {
  const { onChange, api, state, searchTabsOptions } = props

  const {
    parameters: { type }
  } = state

  const onChangeTypeHandler = useCallback(
    value => {
      api.onResetListParameters()
      api.changeQueryParameters({ type: value })

      if (onChange) {
        onChange(value)
      }
    },
    [api, onChange]
  )

  return (
    <ButtonsWrapper className={styles.wrapper}>
      <Column className={styles.tabsWrapper}>
        {searchTabsOptions.map(tab => (
          <SearchTab
            key={tab.id}
            id={tab.id}
            type={tab.type}
            currentType={type}
            onChange={onChangeTypeHandler}
          >
            {tab.text}
          </SearchTab>
        ))}
      </Column>
    </ButtonsWrapper>
  )
}

SearchTabs.propTypes = {
  onChange: PropTypes.func.isRequired,
  api: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  searchTabsOptions: PropTypes.array.isRequired
}
