/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { ProgramHistoryItem } from '../ProgramHistoryItem'

export function ProgramHistoryItems(props) {
  const { items } = props

  return items.map(item => <ProgramHistoryItem item={item} key={item.id} />)
}

ProgramHistoryItems.propTypes = {
  items: PropTypes.array.isRequired
}
