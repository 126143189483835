/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */
import { Discounts } from './container'

export { Discounts as DiscountsComponent } from './Discounts'
export { Discounts } from './container'
export * from './Permissions'

export default Discounts
