/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESET_STATE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE
} from 'helpers/actionHelpers'
import { isToday } from 'helpers/dateHelpers'

import types from './types'

const { ACTIVATE_PROGRAM, DEACTIVATE_PROGRAM, NAME } = types

const REQUEST_ACTIVATE_PROGRAM = `${REQUEST_TYPE}_${ACTIVATE_PROGRAM}`
const RESPONSE_ACTIVATE_PROGRAM = `${RESPONSE_TYPE}_${ACTIVATE_PROGRAM}`
const RESPONSE_ACTIVATE_PROGRAM_ERROR = `${RESPONSE_ERROR_TYPE}_${ACTIVATE_PROGRAM}`

const REQUEST_DEACTIVATE_PROGRAM = `${REQUEST_TYPE}_${DEACTIVATE_PROGRAM}`
const RESPONSE_DEACTIVATE_PROGRAM = `${RESPONSE_TYPE}_${DEACTIVATE_PROGRAM}`
const RESPONSE_DEACTIVATE_PROGRAM_ERROR = `${RESPONSE_ERROR_TYPE}_${DEACTIVATE_PROGRAM}`

const RESET_STATE_PROGRAM_LIFE_CYCLE = `${RESET_STATE}_${NAME}`

/**
 * @param {string} prevDate
 * @param {string} date
 * @returns {string}
 */
function buildSuccessDeactivatingMessage(prevDate, date) {
  if (isToday(date)) {
    return 'Program has been deactivated.'
  }
  if (!prevDate) {
    return `Deactivation has been set to ${date}.`
  }
  return `Deactivation Date has been updated to ${date}.`
}

/**
 * @returns {string}
 */
function buildSuccessCancelDeactivatingMessage() {
  return 'Scheduled Deactivation has been canceled.'
}

export default {
  REQUEST_ACTIVATE_PROGRAM,
  RESPONSE_ACTIVATE_PROGRAM,
  RESPONSE_ACTIVATE_PROGRAM_ERROR,
  REQUEST_DEACTIVATE_PROGRAM,
  RESPONSE_DEACTIVATE_PROGRAM,
  RESPONSE_DEACTIVATE_PROGRAM_ERROR,
  RESET_STATE_PROGRAM_LIFE_CYCLE,
  buildSuccessDeactivatingMessage,
  buildSuccessCancelDeactivatingMessage
}
