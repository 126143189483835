/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

/*
TODO: This solution is temporary and requires modification both from the UI side and the back-end
*/

import React from 'react'
import PropTypes from 'prop-types'
import { asField } from 'informed'

import { formatComments } from './helpers'
import { DEFAULT_COMMENT } from './types'

import styles from './ProgramComments.scss'

const ProgramCommentsComponent = props => {
  const {
    fieldState: { value }
  } = props
  const comments = value || DEFAULT_COMMENT
  return (
    <div className="box">
      <span className={styles.commentWrapper}>{formatComments(comments)}</span>
    </div>
  )
}

ProgramCommentsComponent.defaultProps = {
  fieldState: null
}
ProgramCommentsComponent.propTypes = {
  fieldState: PropTypes.object
}

export const ProgramComments = asField(ProgramCommentsComponent)
