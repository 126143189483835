/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  httpWrapper,
  buildURLForGettingEntityById,
  buildURLForGettingEntityByIdExtended
} from 'services/http/helpers'
import { UNIQ_PHONE_NUMBER_URL } from 'endpoints'

export const createEntity = contractData =>
  httpWrapper({ url: UNIQ_PHONE_NUMBER_URL, method: 'post', data: contractData })

export const updateEntity = contractData =>
  httpWrapper({ url: UNIQ_PHONE_NUMBER_URL, method: 'put', data: contractData })

export const getFreeEntityById = (id, params) =>
  httpWrapper({
    url: buildURLForGettingEntityByIdExtended(UNIQ_PHONE_NUMBER_URL, id),
    method: 'get',
    params
  })

// Get Unique phone number: Finds extended entity by idEntity.
export const getEntityById = (id, programId, options) =>
  httpWrapper({
    url: `${UNIQ_PHONE_NUMBER_URL}/${id}/extended/${programId}`,
    method: 'get',
    data: options
  })

export const deleteEntityById = (id, params) =>
  httpWrapper({
    url: buildURLForGettingEntityById(UNIQ_PHONE_NUMBER_URL, id),
    method: 'delete',
    params
  })
