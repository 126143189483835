/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import isArray from 'lodash/isArray'
import { Column } from 'components/FormComponents'

export function BoxColumns(props) {
  const { children } = props
  if (!children) {
    return null
  }

  return (
    <div className="columns">
      {isArray(children) &&
        children.length > 1 &&
        React.Children.map(children, child => <Column {...props}>{child}</Column>)}
      {!isArray(children) && <Column {...props}>{children}</Column>}
    </div>
  )
}

BoxColumns.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array])
}

BoxColumns.defaultProps = {
  children: null
}
