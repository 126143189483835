/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { createFilterBuilder } from 'modules/FilteredSearch/helpers'
import {
  ACTIVE_FILTER,
  ACTIVE_FILTER_KEY,
  DESTINATION_GUIDE_FILTER,
  DESTINATION_GUIDE_FILTER_KEY,
  INACTIVE_FILTER,
  INACTIVE_FILTER_KEY
} from './types'

export const buildFilters = createFilterBuilder(
  {
    [ACTIVE_FILTER_KEY]: ACTIVE_FILTER,
    [INACTIVE_FILTER_KEY]: INACTIVE_FILTER,
    [DESTINATION_GUIDE_FILTER_KEY]: DESTINATION_GUIDE_FILTER
  },
  filters => (filters.length === 1 ? filters.map(({ operator, ...item }) => item) : filters),
  false
)
