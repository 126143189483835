import { actionCreater } from 'helpers/actionHelpers'
import {
  createActivityTag as createActivityTagRequest,
  getActivityTag,
  updateActivityTag as updateActivityTagRequest,
  deleteActivityTag
} from 'services/http/activityTags'

import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID_ERROR,
  REQUEST_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID_ERROR,
  RESET_DATA_STATE
} = helpers

const createActivityTag = (contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await createActivityTagRequest(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM))
    dispatch(send(message))
    return 'createActivityTag success'
  }

  return 'createActivityTag failed'
}

const getActivityTagById = (id) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_ENTITY_BY_ID))
  const request = await getActivityTag(id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID, request.data))
    return 'getActivityTagById success'
  }

  return 'getActivityTagById failed'
}

const updateActivityTag = (contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await updateActivityTagRequest(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM))
    dispatch(send(message))
    return 'updateActivityTag success'
  }

  return 'updateActivityTag failed'
}

const removeActivityTagById = id => async dispatch => {
  dispatch(actionCreater(REQUEST_REMOVE_ENTITY_BY_ID))
  const request = await deleteActivityTag(id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_REMOVE_ENTITY_BY_ID_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_REMOVE_ENTITY_BY_ID))
    dispatch(send(message))
    return 'removeActivityTag success'
  }

  return 'removeActivityTag failed'
}

const resetForm = () => ({
  type: RESET_DATA_STATE
})

export default {
  createActivityTag,
  getActivityTagById,
  updateActivityTag,
  removeActivityTagById,
  resetForm
}
