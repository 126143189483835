/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { SelectField } from 'components/FormComponents'
import { useDidMount } from 'hooks'

export function SelectMarketField({ options, getList, fieldName, label, placeholder }) {
  useDidMount(() => {
    getList({ itemsPerPage: 0 })
  })

  return (
    <SelectField
      fieldName={fieldName}
      label={label}
      placeholder={placeholder}
      options={options}
      path={['id']}
    />
  )
}
SelectMarketField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  getList: PropTypes.func.isRequired
}
