/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { RMS } from 'constants/searchTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'

export const rmsTableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', RMS)
  },
  {
    Header: 'Total APs',
    accessor: 'totalActivityProviders',
    id: buildSortPropertyByType('totalActivityProviders', RMS)
  },
  {
    Header: 'Total AP Products',
    accessor: 'totalActivities',
    id: buildSortPropertyByType('totalActivities', RMS)
  }
]
