/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { searchProgramsByAppId } from 'services/http/search'
import { exportProgramsByAppList } from 'services/http/programsByApp'
import { getActivityById } from 'services/http/activity'
import { getProgramsByAppIdAndTypeOffer } from 'services/http/program'

import helpers from './helpers'

const {
  REQUEST_DATA,
  RESPONSE_DATA,
  RESPONSE_ERROR_DATA,
  RESET_DATA_STATE,
  REQUEST_APP_ONE,
  RESPONSE_APP_ONE,
  RESPONSE_APP_ONE_ERROR,
  REQUEST_EXPORT,
  RESPONSE_EXPORT,
  RESPONSE_ERROR_EXPORT
} = helpers

const getProgramsListByAppId = (authKey, id, options = {}, payload) => async dispatch => {
  dispatch(actionCreater(REQUEST_DATA))
  const request = await searchProgramsByAppId(authKey, id, options, payload).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_ERROR_DATA))
    }
  )

  if (request) {
    dispatch(actionCreater(RESPONSE_DATA, { ...request.data }))
    return 'getProgramsListByAppId success'
  }

  return 'getProgramsListByAppId failed'
}

const getProgramsListByAppIdAndOfferType = (id, payload, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_DATA))
  const request = await getProgramsByAppIdAndTypeOffer(id, payload, options).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_ERROR_DATA))
    }
  )

  if (request) {
    dispatch(actionCreater(RESPONSE_DATA, { ...request.data }))
    return 'getProgramsListByAppIdAndOfferType success'
  }

  return 'getProgramsListByAppIdAndOfferType failed'
}

const exportProgramsListByAppId = (id, data, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_EXPORT))
  const request = await exportProgramsByAppList(id, data, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_EXPORT))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_EXPORT, { ...request.data }))
    return 'exportProgramsListByAppId success'
  }

  return 'exportProgramsListByAppId failed'
}

const getAppById = (authKey, id = null, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_APP_ONE))
  const request = await getActivityById(id, params).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_APP_ONE_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_APP_ONE, request.data))
    return 'getAppById success'
  }
  return 'getAppById failed'
}

const resetData = () => dispatch => {
  dispatch(actionCreater(RESET_DATA_STATE))
}

export default {
  exportProgramsListByAppId,
  getProgramsListByAppId,
  resetData,
  getAppById,
  getProgramsListByAppIdAndOfferType
}
