/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/activityProductList'
import { withAuthContext } from 'helpers/withAuthContext'

import { ActivityProductListComponent } from './ActivityProductList'

export const mapStateToProps = state => ({
  dataState: state.activityProductList.dataState,
  data: state.activityProductList.data,
  itemsCount: state.activityProductList.itemsCount,
  itemsPerPage: state.activityProductList.itemsPerPage,
  pageNumber: state.activityProductList.pageNumber,
  dataStateWatchers: [
    state.activityProductForm.dataState,
    state.activityProductForm.switchStatusDataState
  ]
})

export const mapDispatchToProps = dispatch => {
  const { getProductsList } = actions
  return bindActionCreators({ onGetData: getProductsList }, dispatch)
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps
})

export const ActivityProductList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )
)(ActivityProductListComponent)
