/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useMemo } from 'react'
import { BasicButton, SwitchButton } from '@xplorie/ui-commons'
import { actions, getResellTestingState } from 'reducers/resellTesting/testingMode'
import { useAction } from 'hooks'
import { useSelector } from 'react-redux'
import { withModalWindow } from 'decorators'
import { BasicModal } from 'components/Modals'
import { ORANGE_SCHEMA, YELLOW_GREEN_SCHEMA } from 'constants/colorSchemas'
import { NO_TITLE, YES_TITLE } from 'constants/buttonsOptions'
import {
  ACTIVATE_MODAL_MESSAGE,
  DEACTIVATE_MODAL_MESSAGE,
  TESTING_DISABLED_LABEL,
  TESTING_ENABLED_LABEL,
  WARNING_MODAL_TITLE
} from './types'
import styles from './styles.scss'

const SwitchButtonModal = withModalWindow(SwitchButton)

export function TestModeSwitcher() {
  const { isModeDisabled, isModeEnabled } = useSelector(getResellTestingState)
  const setModeValue = useAction(actions.setModeValue)
  const [modalMessage, switchMessage] = useMemo(
    () =>
      isModeEnabled
        ? [DEACTIVATE_MODAL_MESSAGE, TESTING_ENABLED_LABEL]
        : [ACTIVATE_MODAL_MESSAGE, TESTING_DISABLED_LABEL],
    [isModeEnabled]
  )

  const onChangeHandler = useCallback(() => {
    setModeValue(!isModeEnabled)
  }, [isModeEnabled, setModeValue])

  return (
    <div className={styles.wrapper}>
      <SwitchButtonModal
        checked={isModeEnabled}
        disabled={isModeDisabled}
        eventName="onChange"
        onAction={onChangeHandler}
        renderModal={({ isShown, onConfirm, onHide }) => (
          <BasicModal
            isOpen={isShown}
            onClose={onHide}
            message={modalMessage}
            title={WARNING_MODAL_TITLE}
          >
            <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onConfirm}>
              {YES_TITLE}
            </BasicButton>
            <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onHide}>
              {NO_TITLE}
            </BasicButton>
          </BasicModal>
        )}
      />
      <span className={styles.label}>{switchMessage}</span>
    </div>
  )
}
