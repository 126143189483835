/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function CirclePlusIcon(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="-2 -2 44 44"
      fill="#F26A24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22 10H18V18H10V22H18V30H22V22H30V18H22V10ZM20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z" />
    </svg>
  )
}
