/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  RESET_LIST_STATE,
  REQUEST_ACTIVITY_LIST,
  RESPONSE_ACTIVITY_LIST,
  RESPONSE_ACTIVITY_LIST_ERROR,
  REQUEST_ACTIVITY,
  RESPONSE_ACTIVITY,
  RESPONSE_ACTIVITY_ERROR
} = helpers

const initialState = {
  activities: [],
  activityDataState: DATA_STATE_NOT_REQUESTED,
  activityListDataState: DATA_STATE_NOT_REQUESTED
}

const seasonActivityListReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTIVITY_LIST:
      return {
        ...state,
        activityListDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_ACTIVITY_LIST_ERROR:
      return {
        ...state,
        activityListDataState: DATA_STATE_ERROR
      }
    case RESPONSE_ACTIVITY_LIST:
      return {
        ...state,
        activityListDataState: DATA_STATE_RECIEVED,
        activities: action.payload.data
      }
    case REQUEST_ACTIVITY:
      return {
        ...state,
        activityDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_ACTIVITY_ERROR:
      return {
        ...state,
        activityDataState: DATA_STATE_ERROR
      }
    case RESPONSE_ACTIVITY:
      return {
        ...state,
        activityDataState: DATA_STATE_RECIEVED
      }
    case RESET_LIST_STATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default filterActions(seasonActivityListReducer, [
  RESET_LIST_STATE,
  REQUEST_ACTIVITY_LIST,
  RESPONSE_ACTIVITY_LIST,
  RESPONSE_ACTIVITY_LIST_ERROR,
  REQUEST_ACTIVITY,
  RESPONSE_ACTIVITY,
  RESPONSE_ACTIVITY_ERROR
])
