/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'

import { useLoading } from 'hooks'

import { AppDeactivationModal } from 'modules/ActivityProducts'
import { PIECurtainLoader } from 'components/Loaders'

import { DEACTIVATE_BUTTON_TEXT } from './types'

export function DeactivateButton(props) {
  const { deactivationDataState, deactivateAppById, disabled, dataId, entityData } = props

  const [isOpenModal, setIsModalState] = useState(false)

  const [isProcessing] = useLoading(deactivationDataState)
  const appName = entityData && entityData.name
  const deactivationDate = get(entityData, 'deactivationDate.date')

  const onToggleModalHandler = useCallback(() => {
    setIsModalState(!isOpenModal)
  }, [isOpenModal])

  const onDeactivateHandler = useCallback(
    data => {
      if (deactivateAppById && dataId && data) {
        deactivateAppById(dataId, data)
      }
      onToggleModalHandler()
    },
    [deactivateAppById, dataId, onToggleModalHandler]
  )

  if (deactivationDate) {
    return null
  }

  return (
    <>
      <BasicButton colorSchema={ORANGE_SCHEMA} disabled={disabled} onClick={onToggleModalHandler}>
        {DEACTIVATE_BUTTON_TEXT}
      </BasicButton>
      <AppDeactivationModal
        isOpen={isOpenModal}
        onSubmit={onDeactivateHandler}
        onHide={onToggleModalHandler}
        dataId={dataId}
        appName={appName}
      />
      {isProcessing && <PIECurtainLoader />}
    </>
  )
}

DeactivateButton.propTypes = {
  authUser: PropTypes.object.isRequired,
  deactivationDataState: PropTypes.string.isRequired,
  deactivateAppById: PropTypes.func.isRequired,
  dataId: PropTypes.string,
  disabled: PropTypes.bool,
  entityData: PropTypes.object
}

DeactivateButton.defaultProps = {
  dataId: null,
  disabled: false,
  entityData: {}
}
