/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { getListParametersByPMCId } from 'services/http/eligibility'
import { actionCreater } from 'helpers/actionHelpers'

import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST, RESET_DATA_STATE } = helpers

const getEligibilityParamsListByPMCId = (id, options = {}) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_LIST, { key }))
  const request = await getListParametersByPMCId(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { data: request.data, key }))
    return 'getEligibilityParamsListByPMCId success'
  }

  return 'getEligibilityParamsListByPMCId failed'
}
const resetList = key => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key }))

export default {
  getEligibilityParamsListByPMCId,
  resetList
}
