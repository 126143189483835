/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/programsByAppList'
import { DiscountPrograms as DiscountProgramsComponent } from './DiscountPrograms'

const mapStateToProps = state => ({
  programDataState: state.programsByAppList.dataState,
  programData: state.programsByAppList.data
})

export const mapDispatchToProps = dispatch => {
  const { getProgramsListByAppIdAndOfferType } = actions

  return bindActionCreators(
    {
      onGetData: getProgramsListByAppIdAndOfferType
    },
    dispatch
  )
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps
})

export const DiscountPrograms = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )
)(DiscountProgramsComponent)
