/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { createContext } from 'react'
import { SMART_HOST_FEE_MODE } from 'constants/smartHostFee'
import { SmartHostFeeListMode } from './SmartHostFeeListMode'
import { SmartHostFeeEditMode } from './SmartHostFeeEditMode'
import { SmartHostFeeAddMode } from './SmartHostFeeAddMode'

export const SMART_HOST_MOBILE_TYPE = 'MOBILE'
export const SMART_HOST_SUBSCRIPTION_TYPE = 'SUBSCRIPTION'

export const ENABLED_SMART_HOST_LABEL = 'SmartHost enabled'
export const DISABLED_SMART_HOST_LABEL = 'SmartHost disabled'
export const ENABLED_SMART_HOST_MOBILE_LABEL = 'SmartHost Mobile enabled'
export const DISABLED_SMART_HOST_MOBILE_LABEL = 'SmartHost Mobile disabled'
export const PER_DEVICE_FEE_LABEL = 'Per Device Fee:'
export const START_DATE_LABEL = 'Start Date:'
export const END_DATE_LABEL = 'End Date:'
export const SAVE_LABEL = 'Save'
export const CANCEL_LABEL = 'Cancel'
export const ADD_SMART_HOST_FEE_LABEL = 'Add SmartHost Subscription Fee'
export const ADD_SMART_HOST_MOBILE_FEE_LABEL = 'Add SmartHost Mobile Fee'
export const SMART_HOST_FEE_TITLE = 'SmartHost Subscription Fee'
export const SMART_HOST_MOBILE_FEE_TITLE = 'SmartHost Mobile Fee'
export const INVALID_FEE = 'Invalid Fee'
export const START_DATE_PLACEHOLDER = 'Start Date'
export const END_DATE_PLACEHOLDER = 'End Date'
export const FEE_PLACEHOLDER = 'Fee'
export const NO_END_DATE_VALUE = '12/31/9999'

export const MIN_RATE_FEE = 0
export const MAX_RATE_FEE = 100000.0
export const MAX_DECIMAL_PLACES = 2

export const REGEX_DECIMALS_VALIDATION_FEE = /^(\d{1,6}|100000)(\.\d{1,2})?$/

export const getSmartHostFeeOptions = (setAddMode, setEditMode) => [
  { label: 'Add', props: { onClick: setAddMode } },
  { label: 'Edit', props: { onClick: setEditMode } }
]

export const getComponentByMode = mode => {
  switch (mode) {
    case SMART_HOST_FEE_MODE.ADD:
      return SmartHostFeeAddMode
    case SMART_HOST_FEE_MODE.EDIT:
      return SmartHostFeeEditMode
    default:
      return SmartHostFeeListMode
  }
}

export const SmartHostFeeFormContext = createContext({
  fees: [],
  fromApi: {},
  setFees: () => null
})
