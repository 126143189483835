import React from 'react'
import { BasicModal } from 'components/Modals'
import * as PropTypes from 'prop-types'
import { ForgotPasswordForm } from '../ForgotPasswordForm'
import { MODAL_TITLE_TEXT } from './types'
import styles from './styles.scss'

export function ForgotPasswordModal(props) {
  const { isOpen, onClose } = props

  return (
    <>
      <BasicModal
        isOpen={isOpen}
        onClose={onClose}
        renderBody={() => (
          <div className={styles.modalBody}>
            <div className={styles.modalTitle}>{MODAL_TITLE_TEXT}</div>
            <ForgotPasswordForm onClose={onClose} />
          </div>
        )}
      />
    </>
  )
}

ForgotPasswordModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
}
