/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
// base
export const BASE_PREFIX = '/'

export const PIE_FACADE_PREFIX = '/pie-facade'
export const FACADE_ENDPOINT = `${BASE_PREFIX}pie/products`
export const AUTHENTICATION_ENDPOINT = `${BASE_PREFIX}pie/accounts`
export const REPORT_ENDPOINT = `${FACADE_ENDPOINT}/reports`

// account
export const ACCOUNT_URL = `${AUTHENTICATION_ENDPOINT}/users`
export const ACCOUNT_SEARCH_URL = `${AUTHENTICATION_ENDPOINT}/search`
export const ACCOUNT_PASSWORD_URL = `${ACCOUNT_URL}/password`
export const ACCOUNT_LIST_URL = `${ACCOUNT_URL}/`
export const ACCOUNT_PASSWORD_RECOVERY_URL = `${ACCOUNT_URL}/password/recovery`
// activity category
export const ACTIVITY_CATEGORY_URL = `${FACADE_ENDPOINT}/activities/categories`
export const ACTIVITY_CATEGORY_VALIDATION_URL = `${FACADE_ENDPOINT}/activities/categories/{id}/connections`
export const ACTIVITY_CATEGORY_LIST_URL = `${FACADE_ENDPOINT}/activities/categories/`
export const ACTIVITY_CATEGORY_TREE_URL = `${ACTIVITY_CATEGORY_URL}/tree`
export const ACTIVITY_CATEGORY_BY_ACTIVITY_ID_URL = `${FACADE_ENDPOINT}/activities/providers/{providerId}/categories/`
export const ACTIVITY_CATEGORY_TAGS_URL = `${FACADE_ENDPOINT}/activities/categories/tags`
export const ACTIVITY_CATEGORY_TAGS_BY_ID_URL = `${FACADE_ENDPOINT}/activities/tags`

// activity product
export const ACTIVITY_URL = `${FACADE_ENDPOINT}/activities`
export const ACTIVITY_LIST_URL = `${FACADE_ENDPOINT}/activities/extended`
export const ACTIVITY_LIST_COUNT_URL = `${FACADE_ENDPOINT}/activities/count`
export const ACTIVITY_EXPORT_MASTER_URL = `${REPORT_ENDPOINT}/app/master`
export const ACTIVITY_RESELL_SEARCH_URL = `${ACTIVITY_URL}/resell/search`

// activity product history
export const ACTIVITY_PRODUCTS_HISTORY_YEARS_URL = `${ACTIVITY_URL}/{activityProductId}/history/years`
export const ACTIVITY_PRODUCTS_HISTORY_URL = `${ACTIVITY_URL}/{activityProductId}/history/{year}`

// schedules
export const ACTIVITY_SCHEDULES = `${ACTIVITY_URL}/{id}/series` // Finds series list for requested period by activity identifier.
export const GET_SERIES_BY_ID_URL = `${ACTIVITY_URL}/{id}/series/{seriesId}`
export const SCHEDULE_SERIES_DAY_URL = `${ACTIVITY_URL}/{id}/series/{seriesId}/occurrences`
export const CANCEL_SERIES_BY_ID_URL = `${ACTIVITY_URL}/{id}/series/{seriesId}/cancellation`
export const CANCEL_OCCURRENCE_BY_ID_URL = `${ACTIVITY_URL}/{id}/series/cancellation`
export const ACTIVATE_SERIES_BY_ID_URL = `${ACTIVITY_URL}/{id}/series/{seriesId}/activation`
export const ACTIVATE_OCCURRENCE_BY_ID_URL = `${ACTIVITY_URL}/{id}/series/activation`
export const GET_OVERLAPS_CLOSURE_URL = `${ACTIVITY_URL}/{id}/series/overlaps`

// barcode template
export const GENERATE_BARCODE_TEMPLATE_URL = `${ACTIVITY_URL}/{activityId}/tickets/groups/{id}/templates/barcode`

// address
export const ADDRESS_URL = `${FACADE_ENDPOINT}/addresses`

// activity provider
export const ACTIVITY_PROVIDER_URL = `${FACADE_ENDPOINT}/activities/providers`
export const ACTIVITY_PROVIDER_LIST_URL = `${FACADE_ENDPOINT}/activities/providers/extended`
export const ACTIVITY_PROVIDER_LIST_COUNT_URL = `${FACADE_ENDPOINT}/activities/providers/count/`

// search
export const SEARCH_URL = `${FACADE_ENDPOINT}/search`

// Orders page
export const FILTERED_SEARCH_URL = `${FACADE_ENDPOINT}/filtered-search`

// location
export const LOCATIONS_URL = `${FACADE_ENDPOINT}/locations`
export const COUNTRY_URL = `${LOCATIONS_URL}/countries`
export const LOCATION_ACTIVITIES_URL = `${LOCATIONS_URL}/{id}/activities`
export const LOCATION_DEPENDENCIES_URL = `${LOCATIONS_URL}/{id}/dependencies`
export const PROGRAMS_BY_LOCATION_URL = `${LOCATIONS_URL}/{id}/dependencies/programs`
export const APPS_BY_LOCATION_URL = `${LOCATIONS_URL}/{id}/dependencies/activities`

// Location tree
export const STATES_LOCATION_TREE_URL = `${LOCATIONS_URL}/tree/states`

// pmc
export const PMCS_URL = `${FACADE_ENDPOINT}/pmc`
export const PMCS_LIST_URL = `${FACADE_ENDPOINT}/pmc/`
export const PMCS_EXTENDED_LIST_URL = `${FACADE_ENDPOINT}/pmc/extended/`
export const PMCS_LIST_COUNT_URL = `${FACADE_ENDPOINT}/pmc/count/`
export const PMC_EXPORT_MASTER_URL = `${REPORT_ENDPOINT}/pmc/master`

// pmc units
export const PMC_PROGRAM_UNITS = `${PMCS_URL}/{pmcId}/programs/units`
export const GENERATE_UNITS_TEMPLATE_URL = `${PMCS_URL}/{pmcId}/units/templates/new`
export const EXPORT_PMC_UNITS_URL = `${PMCS_URL}/{pmcId}/units/export`

// pms
export const PMS_URL = `${FACADE_ENDPOINT}/pms`

// programs
export const PROGRAMS_URL = `${FACADE_ENDPOINT}/programs`
export const PROGRAMS_LIST_URL = `${FACADE_ENDPOINT}/programs/extended`
export const PROGRAMS_SUBMIT_URL = `${FACADE_ENDPOINT}/programs/submit/`
export const PROGRAMS_APPROVE_URL = `${FACADE_ENDPOINT}/programs/approve/`
export const PROGRAMS_REJECT_URL = `${FACADE_ENDPOINT}/programs/reject/`
export const PROGRAM_ONBOARDING_URL = `${FACADE_ENDPOINT}/programs/on-boarding`

// programs by app id
export const PROGRAMS_BY_APP_EXPORT_URL = `${ACTIVITY_URL}/{id}/programs/export`

// program units
export const PROGRAMS_UNITS_EXPORT_URL = `${PROGRAMS_URL}/{programId}/units/export`
export const PROGRAMS_UNITS_SMART_HOST_URL = `${PROGRAMS_URL}/{programId}/units/smarthost`

// Units
export const UNIT_DETAILS_URL = `${FACADE_ENDPOINT}/units/{unitId}/details`
export const UNIT_ADDRESS_URL = `${FACADE_ENDPOINT}/units/{unitId}/address`
export const UNIT_AMENITY_URL = `${FACADE_ENDPOINT}/units/{unitId}/amenities`
export const UNIT_IMAGES_URL = `${FACADE_ENDPOINT}/units/{unitId}/images`
export const UNIT_SMART_HOST_URL = `${FACADE_ENDPOINT}/units/{unitId}/smarthost`

// program prebook info
export const PROGRAMS_PREBOOK_INFO_URL = `${PROGRAMS_URL}/{programId}/prebook`

// SmartHost Fee settings
export const SMART_HOST_SUBSCRIPTION_FEE_URL = `${PROGRAMS_URL}/{programId}/fees/smarthost/subscription`
export const SMART_HOST_MOBILE_FEE_URL = `${PROGRAMS_URL}/{programId}/fees/smarthost/mobile`

// program history
export const PROGRAMS_HISTORY_URL = `${PROGRAMS_URL}/{programId}/history/{year}`
export const PROGRAMS_HISTORY_YEARS_URL = `${PROGRAMS_URL}/{programId}/history/years`

// program exclusive offers
export const SEASON_EXCLUSIVE_ACTIVITIES_URL = `${PROGRAMS_URL}/{programId}/activities/exclusive`
export const EXCLUSIVE_ACTIVITY_BY_ID_URL = `${PROGRAMS_URL}/{programId}/activities/exclusive/{activityId}`

// seasons
export const SEASONS_LIST_BY_PROGRAM_URL = `${FACADE_ENDPOINT}/programs/{programId}/seasons`
export const SEASON_BY_ID_URL = `${FACADE_ENDPOINT}/programs/{programId}/seasons`
export const SEASON_URL = `${FACADE_ENDPOINT}/programs/{programId}/seasons/{id}`
export const SEASON_LIST_URL = `${FACADE_ENDPOINT}/programs/{programId}/seasons/`
export const SEASON_ACTIVITIES_URL = `${FACADE_ENDPOINT}/programs/{programId}/seasons/{id}/activities`

// role
export const ROLE_URL = `${AUTHENTICATION_ENDPOINT}/roles/predefined`

//
export const ROLE_LIST_COUNT_URL = `${AUTHENTICATION_ENDPOINT}/roles/count/`

// region
export const REGION_URL = `${FACADE_ENDPOINT}/regions`

// logout
export const LOGOUT_URL = `${PIE_FACADE_PREFIX}/logout`

// service
export const BACK_END_API_VERSION_URL = '/actuator/info'

// settings
export const SETTINGS_URL = `${AUTHENTICATION_ENDPOINT}/settings`

// integration
export const INTEGRATION_URL = `${FACADE_ENDPOINT}/integration`

// fee tag
export const FEE_URL = `${FACADE_ENDPOINT}/fees`
export const FEE_TAG_URL = `${FACADE_ENDPOINT}/fees/tags`
export const FEE_PROGRAM_URL = `${FEE_URL}/programs`

// oauth
export const OAUTH_URL = '/oauth/token'
export const PROFILE_URL = `${PIE_FACADE_PREFIX}/user`

// accordion
export const ACCORDION_DICTIONARY_URL = `${FACADE_ENDPOINT}/dictionary/accordion`

// holidays
export const HOLIDAYS_URL = `${FACADE_ENDPOINT}/integration/holidays`

// activity module
export const ACTIVITY_MODULE_URL = `${FACADE_ENDPOINT}/dictionary/activities/modules`

// activity options
export const ACTIVITY_OPTIONS_URL = `${FACADE_ENDPOINT}/programs/seasons/activities`

// activity tags
export const ACTIVITY_TAGS_URL = `${FACADE_ENDPOINT}/tags`

// rms dictionary
export const RMS_URL = `${FACADE_ENDPOINT}/dictionary/rms`

// Unique Phone Number dictionary
export const UNIQ_PHONE_NUMBER_URL = `${FACADE_ENDPOINT}/dictionary/phones`

export const RESALE_REPORTS_INVENTORY_API_URL = `${FACADE_ENDPOINT}/resale/reports/inventory`

// deals
export const DEALS_ENDPOINT = `${FACADE_ENDPOINT}/discounts`
export const DISCOUNT_DEAL_URL = `${DEALS_ENDPOINT}/discounts`
export const DISCOUNT_DEAL_URL_ID = `${DEALS_ENDPOINT}/discounts/{id}`
export const DRAFT_DEAL_URL = `${DEALS_ENDPOINT}/{id}/draft`
export const DISCOUNT_LIST_BY_PROGRAM_ID_URL = `${FACADE_ENDPOINT}/programs`
export const DISCOUNTS_EXPORT_URL = `${FACADE_ENDPOINT}/discounts/export`

// discount history
export const DISCOUNT_HISTORY_URL = `${DEALS_ENDPOINT}/{discountId}/history/{year}`
export const DISCOUNT_HISTORY_YEARS_URL = `${DEALS_ENDPOINT}/{discountId}/history/years`

// eligibility
export const ELIGIBILITY_ENDPOINT = `${FACADE_ENDPOINT}/eligibilities`
export const PMC_ELIGIBILITY_PROPERTIES_ENDPOINT = `${FACADE_ENDPOINT}/pmc/{id}/eligibilities/properties`
export const PROGRAM_ELIGIBILITY_PROPERTIES_ENDPOINT = `${FACADE_ENDPOINT}/programs/{id}/eligibilities/properties`

// market dictionary
export const MARKET_ENDPOINT = `${FACADE_ENDPOINT}/dictionary/markets`

// location marketing
export const LOCATION_MARKETING_URL = `${LOCATIONS_URL}/{id}/marketings`

// guest data
export const PROGRAM_GUESTS_DATA_URL = `${PROGRAMS_URL}/{programId}/guests`
export const PROGRAM_GUEST_DATA_URL = `${PROGRAM_GUESTS_DATA_URL}/{guestsId}`
export const PROGRAM_GUEST_TEMPLATE_URL = `${PROGRAM_GUESTS_DATA_URL}/templates/new`

// rates
export const SEASON_RATE_DATA_URL = `${PROGRAMS_URL}/{programId}/seasons/{id}/rates`

// search export
export const SEARCH_EXPORT_URL = `${FACADE_ENDPOINT}/export`
export const FILTERED_SEARCH_EXPORT_URL = `${FACADE_ENDPOINT}/filtered-search/export`
// stripe
export const CHANGE_INFO_URL = `${FACADE_ENDPOINT}/resale/stripe/charges/{chargeId}`

// campaign
export const CAMPAIGN_DATA_URL = `${FACADE_ENDPOINT}/campaigns`
export const CAMPAIGNS_DATA_URL = `${CAMPAIGN_DATA_URL}/`
export const CAMPAIGN_DATA_BY_ID_URL = `${CAMPAIGN_DATA_URL}/{id}`
export const CAMPAIGN_ACTIVITIES_BY_ID_URL = `${CAMPAIGN_DATA_URL}/{id}/activities`
export const CAMPAIGN_ACTIVATE_URL = `${CAMPAIGN_DATA_URL}/{id}/activate`
export const CAMPAIGN_DEACTIVATE_URL = `${CAMPAIGN_DATA_URL}/{id}/deactivate`
export const CAMPAIGN_DRAFT_URL = `${CAMPAIGN_DATA_URL}/{id}/draft`

// how to
export const ACTIVITY_HOW_TO_URL = `${ACTIVITY_URL}/{activityId}/tickets/settings/howto?type={type}`

// credentials
export const CREDENTIALS_URL = `${ACTIVITY_URL}/{activityId}/tickets/settings/accounts/{accountId}/credentials`

// unit tasks
export const UNIT_TASKS_URL = `${FACADE_ENDPOINT}/tasks/pmc/units`
export const UNIT_TASKS_BY_PROGRAM_URL = `${FACADE_ENDPOINT}/tasks/units`
export const UNIT_TASKS_COUNT_URL = `${UNIT_TASKS_BY_PROGRAM_URL}/count`
export const UNIT_TASKS_EXPORT_URL = `${UNIT_TASKS_BY_PROGRAM_URL}/export`
