/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import { SMART_HOST_FEE_MODE } from 'constants/smartHostFee'
import {
  REQUEST_LIST,
  RESPONSE_LIST_ERROR,
  RESPONSE_LIST,
  RESET_DATA_STATE,
  MODE,
} from './helpers'

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  mode: SMART_HOST_FEE_MODE.LIST,
  fees: []
}

const smartHostFeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_LIST_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case RESPONSE_LIST:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        fees: action.payload.fees
      }
    case MODE :
      return {
        ...state,
        mode: action.payload.mode
      }
    case RESET_DATA_STATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default filterActions(smartHostFeeReducer, [
  REQUEST_LIST,
  RESPONSE_LIST_ERROR,
  RESPONSE_LIST,
  RESET_DATA_STATE,
  MODE
])
