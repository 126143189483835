/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ToggleRadio } from 'shared'
import { useInitialFieldValue } from 'hooks'

import { Field } from 'components/FormComponents'

export function ToggleRadioInput(props) {
  const {
    label,
    fieldProps,
    isRequired,
    fieldApi: { setValue, setTouched },
    fieldState: { value, error },
    options,
    onChange,
    disabled,
    initial,
    ...rest
  } = props

  const onChangeHandler = useCallback(
    (newValue, event) => {
      if (disabled) {
        return
      }
      setValue(newValue)
      setTouched(true)
      if (onChange) {
        onChange(newValue, event)
      }
    },
    [disabled, onChange, setTouched, setValue]
  )

  useInitialFieldValue(() => setValue(initial), initial, value)

  return (
    <Field label={label} isRequired={isRequired} error={error} {...fieldProps}>
      <ToggleRadio
        value={value}
        onChange={onChangeHandler}
        options={options}
        disabled={disabled}
        {...rest}
      />
    </Field>
  )
}
ToggleRadioInput.propTypes = {
  label: PropTypes.string,
  fieldProps: PropTypes.object,
  isRequired: PropTypes.bool,
  fieldApi: PropTypes.object.isRequired,
  fieldState: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  initial: PropTypes.any
}
ToggleRadioInput.defaultProps = {
  label: null,
  fieldProps: {},
  isRequired: false,
  onChange: null,
  disabled: false,
  initial: null
}
