/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { SEASON_BY_ID_URL, SEASON_URL } from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'

export const createProgramSeason = (programId, contractData) =>
  httpWrapper({
    url: buildPath(SEASON_BY_ID_URL, { programId }),
    method: 'post',
    data: contractData
  })

export const updateProgramSeason = (programId, contractData) =>
  httpWrapper({
    url: buildPath(SEASON_BY_ID_URL, { programId }),
    method: 'put',
    data: contractData
  })

export const deleteById = (programId, id) =>
  httpWrapper({ url: buildPath(SEASON_URL, { programId, id }), method: 'delete' })

export const getSeasonById = (programId, id) =>
  httpWrapper({ url: buildPath(SEASON_URL, { programId, id }), method: 'get' })

export const cloneSeasonById = (programId, id) =>
  httpWrapper({ url: buildPath(SEASON_URL, { programId, id }), method: 'post' })
