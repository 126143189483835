/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { VAC_SPECIALIST_LEVEL, READ_LEVEL } from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import { DEFAULT_RESALE_REDIRECT_URL, DEFAULT_PIE_REDIRECT_URL } from 'services/auth'

export const getRedirectPath = authUser => {
  const isAllowedForPie = isActionAllowed([READ_LEVEL], authUser)
  const isAllowedForResale = isActionAllowed([VAC_SPECIALIST_LEVEL], authUser)
  if (isAllowedForPie && isAllowedForResale) {
    return null
  }
  if (isAllowedForResale) {
    return DEFAULT_RESALE_REDIRECT_URL
  }
  return DEFAULT_PIE_REDIRECT_URL
}

export const isAllowedRoute = (accessLevels, authUser) =>
  accessLevels.some(level => isActionAllowed(level, authUser))
