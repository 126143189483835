/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withFormContext } from 'components/FormComponents/helpers'
import { ConditionalYesNoFieldInput } from './ConditionalYesNoFieldInput'

export function ConditionalYesNoFieldComponent(props) {
  const { fieldName, ...rest } = props
  return <ConditionalYesNoFieldInput field={fieldName} {...rest} />
}
ConditionalYesNoFieldComponent.propTypes = {
  fieldName: PropTypes.string.isRequired
}
ConditionalYesNoFieldComponent.defaultProps = {}

export const ConditionalYesNoField = compose(withFormContext)(
  ConditionalYesNoFieldComponent
)
