/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { PM_COMPANY, ACTIVITY } from 'constants/searchTypes'

export const reportTypeExist = type => {
  switch (type) {
    case PM_COMPANY:
      return true
    case ACTIVITY:
      return true
    default:
      return false
  }
}
