/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useCallback, useMemo, useState } from 'react'
import { DateUtils } from 'react-day-picker'
import every from 'lodash/every'
import some from 'lodash/some'
import isUndefined from 'lodash/isUndefined'
import { FORMAT } from 'constants/date'
import moment from 'moment'
import {
  configureCalendarModifiers,
  excludeAvailability,
  configureAvailabilities,
  getInitialPeriod,
  includeAvailability,
  createRange,
  isAvailableRangeInPeriods
} from './helpers'
import { useDealContextMenuApiBuilder } from './DiscountContextMenu'

export function useDealCalendarApiBuilder(state) {
  const { setAvailabilities } = state
  const availabilities = configureAvailabilities(state.availabilities)
  const [selectedRange, setSelectedRange] = useState(getInitialPeriod())
  const [contextMenuState, contextMenuApi] = useDealContextMenuApiBuilder()

  const onExclude = useCallback(
    period => {
      setAvailabilities(excludeAvailability(availabilities, period))
      setSelectedRange(getInitialPeriod())
    },
    [availabilities, setAvailabilities]
  )

  const onInclude = useCallback(
    period => {
      setAvailabilities(includeAvailability(availabilities, period))
      setSelectedRange(getInitialPeriod())
    },
    [availabilities, setAvailabilities]
  )

  const canSelectDay = useCallback(
    (period = { startDate: null, endDate: null }) => {
      const periodRange = createRange({
        startDate: moment(period.startDate).format(FORMAT),
        endDate: moment(period.endDate).format(FORMAT)
      })

      return isAvailableRangeInPeriods(periodRange, availabilities)
    },
    [availabilities]
  )

  const onDayClick = useCallback(
    day => {
      if (
        !canSelectDay({
          startDate: day,
          endDate: day
        })
      ) {
        return
      }

      const range = DateUtils.addDayToRange(day, selectedRange)
      setSelectedRange(range)
    },
    [canSelectDay, selectedRange]
  )

  const calendarModifiers = useMemo(() => configureCalendarModifiers(availabilities), [
    availabilities
  ])

  const modifiers = useMemo(
    () => ({
      ...calendarModifiers,
      start: selectedRange.from,
      end: selectedRange.to
    }),
    [calendarModifiers, selectedRange]
  )

  const hasSelectedRange = useMemo(() => every(selectedRange, value => value), [selectedRange])
  const canOpenContextMenu = useMemo(() => some(selectedRange, value => !isUndefined(value)), [
    selectedRange
  ])

  return [
    {
      contextMenuState,
      selectedRange,
      hasSelectedRange,
      canOpenContextMenu,
      modifiers
    },
    {
      onExclude,
      onInclude,
      onDayClick,
      canSelectDay,
      contextMenuApi
    }
  ]
}
