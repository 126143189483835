/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import get from 'lodash/get'
import isNaN from 'lodash/isNaN'

export const responseToFile = (response, name) => {
  const { data } = response
  const contentDisposition = get(response, 'requestData.headers.content-disposition', '')
  const matchedFileName = contentDisposition.match(/filename="(.+)"/)[1]
  const fileName = name || matchedFileName;
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(data)
  if (fileName) {
    link.download = fileName
  }
  link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }))
  window.URL.revokeObjectURL(data)
  return Promise.resolve(response)
}

export const downloadFile = (url, name) =>
  httpWrapper({ url, responseType: 'blob', method: 'get' }).then(response =>
    responseToFile(response, name)
  )

export const calcProgressValue = (ev = {}) => {
  const { total, loaded } = ev
  const value = ((loaded * 100) / total).toFixed()
  return isNaN(value) ? 0 : value
}
