/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const ProgramsDiffContent = loadable(
  () =>
    import(
      /* webpackChunkName: 'ProgramsDiffContent', webpackPrefetch: true */ './ProgramsDiffContent'
    ),
  {
    resolveComponent: components => components.ProgramsDiffContent
  }
)

export { ProgramsDiffContent }
