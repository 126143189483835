/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, YELLOW_GREEN_SCHEMA, NO_TITLE } from 'constants/buttonsOptions'
import { MESSAGE, TITLE, CONFIRM_TEXT } from './types'
import styles from './BeforeCloseModal.scss'

export function BeforeCloseModal({ onSubmit, getApi }) {
  const [isOpen, setIsOpen] = useState(false)

  const modalPromise = useRef()
  const modalResolve = useRef()

  const onOpenAction = useCallback(() => {
    setIsOpen(true)
    modalPromise.current = new Promise(resolve => {
      modalResolve.current = resolve
    })
    return modalPromise.current
  }, [])

  const onHideAction = useCallback(() => {
    setIsOpen(false)
    modalResolve.current(false)
  }, [])

  const onConfirmAction = useCallback(() => {
    modalResolve.current(true)
    onHideAction()
    if (onSubmit) {
      onSubmit()
    }
  }, [onHideAction, onSubmit])

  useEffect(() => {
    if (getApi) {
      getApi({ open: onOpenAction, hide: onHideAction })
    }
  }, [getApi, onHideAction, onOpenAction])

  return (
    <BasicModal
      isOpen={isOpen}
      title={TITLE}
      message={MESSAGE}
      wrapperClassName={styles.wrapper}
      onClose={onHideAction}
    >
      <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onConfirmAction}>
        {CONFIRM_TEXT}
      </BasicButton>
      <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onHideAction}>
        {NO_TITLE}
      </BasicButton>
    </BasicModal>
  )
}
BeforeCloseModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  getApi: PropTypes.func
}
BeforeCloseModal.defaultProps = {
  getApi: () => null
}
