/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_FORM_DATA,
  RESPONSE_GET_FORM_DATA,
  RESPONSE_GET_FORM_DATA_ERROR,
  REQUEST_DELETE_ENTITY_DATA,
  RESPONSE_DELETE_ENTITY_DATA,
  RESPONSE_DELETE_ENTITY_DATA_ERROR
} = helpers

const initialState = {}

export const entityInitialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  formData: {},
  formDataState: DATA_STATE_NOT_REQUESTED,
  deleleDataState: DATA_STATE_NOT_REQUESTED
}

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FORM:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          dataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_FORM_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          dataState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_FORM:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          dataState: DATA_STATE_RECIEVED
        }
      }
    case REQUEST_GET_FORM_DATA:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          formDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_GET_FORM_DATA_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          formDataState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_GET_FORM_DATA:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          formDataState: DATA_STATE_RECIEVED,
          formData: action.payload.data
        }
      }
    // delete entity
    case REQUEST_DELETE_ENTITY_DATA:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          deleleDataState: DATA_STATE_REQUESTING
        }
      }
    case RESPONSE_DELETE_ENTITY_DATA_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          deleleDataState: DATA_STATE_ERROR
        }
      }
    case RESPONSE_DELETE_ENTITY_DATA:
      return {
        ...state,
        [action.payload.key]: {
          ...entityInitialState,
          ...state[action.payload.key],
          deleleDataState: DATA_STATE_RECIEVED
        }
      }
    case RESET_DATA_STATE:
      return (() => {
        const { [action.payload.key]: removedState, ...restState } = state
        return {
          ...restState
        }
      })()

    default:
      return state
  }
}

export default filterActions(formReducer, [
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_FORM_DATA,
  RESPONSE_GET_FORM_DATA,
  RESPONSE_GET_FORM_DATA_ERROR,
  REQUEST_DELETE_ENTITY_DATA,
  RESPONSE_DELETE_ENTITY_DATA,
  RESPONSE_DELETE_ENTITY_DATA_ERROR
])
