/*
* COPYRIGHT:     Copyright © 2018 Xplorie LLC
* Warning:       This product is protected by United States and international copyright laws.
*                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
*/

import React from 'react'
import PropTypes from 'prop-types'

export const TileWrapper = ({ children, label }) => (
  <div className="tile is-ancestor">
    <div className="tile is-12 is-vertical is-parent">
      <div className="tile is-child box">
        <div className="title is-size-6">{label}</div>
        {children}
      </div>
    </div>
  </div>
)
TileWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired
}
