/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export function openNewTab(url) {
  const win = window.open(url, '_blank')
  if (win) {
    win.focus()
  }
  return win
}

export function buildRoute(baseUrl, mapper) {
  return Object.keys(mapper).reduce(
    (acc, key) => acc.replace(new RegExp(`\\:${key}`, 'g'), mapper[key]),
    baseUrl
  )
}
