/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildListParams } from 'services/http/helpers'
import { PROGRAMS_BY_APP_EXPORT_URL } from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'
import { responseToFile } from 'helpers/downloadHelper'

export const exportProgramsByAppList = (id, data, options) =>
  httpWrapper({
    url: buildPath(PROGRAMS_BY_APP_EXPORT_URL, { id }),
    method: 'post',
    data,
    params: buildListParams(options),
    responseType: 'blob'
  }).then(responseToFile)
