/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const XPLORIE_PRICE_LABEL = 'Xplorie Price'
export const RETAIL_PRICE_LABEL = 'Retail Price'
export const EXTRAS_PRICE_LABEL = 'Extras Price'
export const LIST_PRICE_LABEL = 'List Price'
export const XPLORIE_FREE_ENABLED_LABEL = 'Xplorie Free Enabled'
export const XPLORIE_FREE_DISABLED_LABEL = 'Xplorie Free Disabled'

export const TICKET_TYPE = 'TICKET_TYPE'
