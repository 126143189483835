/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const FORM_NAME = 'UNITS_FORM'
export const GET_UNIT_BY_ID = `${FORM_NAME}_UNIT_BY_ID`
export const GET_UNIT_ADDRESS_BY_ID = `${FORM_NAME}_UNIT_ADDRESS_BY_ID`
export const GET_UNIT_AMENITIES_BY_ID = `${FORM_NAME}_UNIT_AMENITIES_BY_ID`
export const GET_UNIT_IMAGES_BY_ID = `${FORM_NAME}_UNIT_IMAGES_BY_ID`
export const GET_UNIT_SMART_HOST_BY_ID = `${FORM_NAME}_UNIT_SMART_HOST_BY_ID`
export const UPDATE_UNIT_SMART_HOST_BY_ID = `${FORM_NAME}_UPDATE_UNIT_SMART_HOST_BY_ID`
