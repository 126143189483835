/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as editQueueActions } from 'reducers/editQueue'
import { ProgramsDiff as ProgramsDiffComponent } from './ProgramsDiff'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => bindActionCreators(editQueueActions, dispatch)

export const ProgramsDiff = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramsDiffComponent)
