/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { HOLIDAYS_URL } from 'endpoints'
import { buildGettingHolidaysURL } from './helpers'

export const getList = options =>
  httpWrapper({
    url: buildGettingHolidaysURL(HOLIDAYS_URL, options),
    method: 'get'
  })
