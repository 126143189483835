/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { httpWrapper } from 'services/http/helpers'
import { ACTIVITY_URL } from 'endpoints'

export const activateAPP = (id, data, params) =>
  httpWrapper({
    url: `${ACTIVITY_URL}/${id}/activate`,
    method: 'put',
    data,
    params
  })

export const deactivateAPP = (id, data, params) =>
  httpWrapper({
    url: `${ACTIVITY_URL}/${id}/deactivation`,
    method: 'put',
    data,
    params
  })

export const updateDeactivationAPP = (id, data, params) =>
  httpWrapper({
    url: `${ACTIVITY_URL}/${id}/deactivation/update`,
    method: 'put',
    data,
    params
  })

export const unDeactivateAPP = (id, data, params) =>
  httpWrapper({
    url: `${ACTIVITY_URL}/${id}/deactivation`,
    method: 'delete',
    data,
    params
  })
