/*
* COPYRIGHT:     Copyright © 2018 Xplorie LLC
* Warning:       This product is protected by United States and international copyright laws.
*                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
*/
import { withAuthContext } from 'helpers/withAuthContext'
import { ButtonWithPermission as ButtonWithPermissionComponent } from './ButtonWithPermission'

export const ButtonWithPermission = withAuthContext(
  ButtonWithPermissionComponent
)
