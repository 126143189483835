/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const ACCOUNT_LIFE_CYCLE = 'ACCOUNT_LIFE_CYCLE'

export const ACTIVATION_ACCOUNT = 'ACTIVATION_ACCOUNT'
export const DEACTIVATION_ACCOUNT = 'DEACTIVATION_ACCOUNT'

// Messages of notification
export const ACTIVATION_SUCCESS_MESSAGE = 'Account has been activated!'
export const DEACTIVATION_SUCCESS_MESSAGE = 'Account has been deactivated!'

export default {
  ACTIVATION_ACCOUNT,
  DEACTIVATION_ACCOUNT,
  ACTIVATION_SUCCESS_MESSAGE,
  DEACTIVATION_SUCCESS_MESSAGE
}
