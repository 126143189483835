import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import reduce from 'lodash/reduce'
import get from 'lodash/get'

import helpers from './helpers'

const {
  REQUEST_GET_DATA,
  RESET_DATA_STATE,
  RESPONSE_GET_DATA,
  RESPONSE_GET_DATA_ERROR,
  REQUEST_GET_YEARS,
  RESPONSE_GET_YEARS,
  RESPONSE_GET_YEARS_ERROR
} = helpers

export const initialState = {}

export const listDataState = {
  year: 0,
  count: 0,
  data: [],
  itemsCount: 0,
  itemsPerPage: 5,
  pageNumber: 0,
  dataState: DATA_STATE_NOT_REQUESTED
}

export const entityInitialState = {
  years: {},
  dataState: DATA_STATE_NOT_REQUESTED
}

const buildData = (state, action) => data => ({
  ...state,
  [action.payload.key]: {
    ...entityInitialState,
    ...state[action.payload.key],
    ...data
  }
})

const buildListData = (state, action) => data => {
  const year = get(action, 'payload.year', 0)
  const years = get(state, [action.payload.key, 'years'], {})
  const yearState = get(state, [action.payload.key, 'years', year], {})
  return {
    years: {
      ...years,
      [year]: { ...listDataState, ...yearState, ...data }
    }
  }
}

const listReducer = (state = initialState, action) => {
  const append = buildData(state, action)
  const appendList = buildListData(state, action)

  switch (action.type) {
    case REQUEST_GET_DATA:
      return append(appendList({ dataState: DATA_STATE_REQUESTING }))
    case RESPONSE_GET_DATA_ERROR:
      return append(appendList({ dataState: DATA_STATE_ERROR }))
    case RESPONSE_GET_DATA: {
      return append(
        appendList({
          dataState: DATA_STATE_RECIEVED,
          data: [
            ...get(state, [action.payload.key, 'years', action.payload.year, 'data'], []),
            ...action.payload.data.data
          ],
          ...action.payload.data.pagination
        })
      )
    }
    // get years
    case REQUEST_GET_YEARS:
      return append({
        dataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_GET_YEARS_ERROR:
      return append({
        dataState: DATA_STATE_ERROR
      })
    case RESPONSE_GET_YEARS:
      return append({
        dataState: DATA_STATE_RECIEVED,
        years: reduce(
          action.payload.data,
          (acc, value) => ({ ...acc, [get(value, 'year')]: { ...listDataState, ...value } }),
          {}
        )
      })
    case RESET_DATA_STATE: {
      const { [action.payload.key]: removedState, ...restState } = state
      return {
        ...restState
      }
    }
    default:
      return state
  }
}

export default filterActions(listReducer, [
  REQUEST_GET_DATA,
  RESET_DATA_STATE,
  RESPONSE_GET_DATA,
  RESPONSE_GET_DATA_ERROR,
  REQUEST_GET_YEARS,
  RESPONSE_GET_YEARS,
  RESPONSE_GET_YEARS_ERROR
])
