/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { TickIcon, InfoIcon, WarningIcon, CrossIcon } from 'icons'

import {
  INFO_TYPE,
  SUCCESS_TYPE,
  WARNING_TYPE,
  DANGER_TYPE,
  INFO_TITLE,
  SUCCESS_TITLE,
  WARNING_TITLE,
  DANGER_TITLE
} from './types'

export function getNotifOption(type) {
  switch (type) {
    case SUCCESS_TYPE:
      return {
        title: SUCCESS_TITLE,
        component: TickIcon
      }
    case INFO_TYPE:
      return {
        title: INFO_TITLE,
        component: InfoIcon
      }
    case WARNING_TYPE:
      return {
        title: WARNING_TITLE,
        component: WarningIcon
      }
    case DANGER_TYPE:
      return {
        title: DANGER_TITLE,
        component: CrossIcon
      }
    default:
      return null
  }
}
