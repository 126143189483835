/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const NUMBER_OF_MONTHS = 2
export const SAMPLE_DAY_LABEL = '1'
export const INCLUDED_LABEL = 'Included'
export const EXCLUDED_LABEL = 'Excluded'
export const AVAILABLE_LABEL = 'Available'
