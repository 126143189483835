/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const MESSAGE = 'Do you want to proceed without Saving Changes?'

export const TITLE = 'Warning!'

export const CONFIRM_TEXT = 'Leave Without Saving'
