/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import values from 'lodash/values'
import { CHARGE_METHOD_LABEL } from 'modules/Programs/SeasonForm/types'
import {
  BILLING_TYPE_FIELD,
  COMMENCEMENT_DATE_FIELD,
  KEYWORD_FIELD,
  MARKET_NAME_FIELD,
  NAME_FIELD,
  SEASON_NAME_FIELD,
  PMC_DESTINATION_FIELD,
  PMC_NAME_FIELD,
  TIME_ZONE_FIELD,
  UNIQUE_PHONE_NUMBER_FIELD,
  XE_PROGRAM_ID_FIELD,
  XPLORIE_MOBILE_URL_FIELD,
  TEXT_LANDING_SITE_URL_FIELD,
  PHONE_NUMBER_FIELD,
  ADDITIONAL_PHONE_NUMBER_FIELD,
  PMC_SQUARE_IMAGE_URL_FIELD,
  WELCOME_HERO_IMAGE_URL_FIELD,
  PREBOOK_CONFIRMATION_ID,
  PREBOOK_RATE_LABEL,
  PREBOOK_RATE_DATE_LABEL,
  SEASON_DETAILS_EVENT,
  START_DATE_FIELD,
  END_DATE_FIELD,
  FEE_DATE_FIELD,
  COMPANY_ID_FIELD_LABEL,
  BILLING_TYPE_FIELD_LABEL,
  LABEL_KEYWORD,
  LABEL_DESTINATION,
  LABEL_PMC_NAME,
  LABEL_PROGRAM_COMMENCEMENT_DATE,
  LABEL_PROGRAM_NAME,
  LABEL_SEASON_NAME,
  LABEL_TIME_ZONE,
  LABEL_UNIC_PHONE_NUMBER,
  LABEL_XE_PROGRAM_ID,
  LABEL_XPLORIE_MOBILE_URL,
  MARKET_LABEL,
  PROGRAM_TYPE_FIELD_LABEL,
  PROGRAM_TYPE_FIELD_KEY,
  COMPANY_ID_FIELD_KEY,
  ADDITIONAL_PHONE_NUMBER_LABEL,
  CARD_FORM_URL_FIELD,
  CARD_FORM_URL_LABEL,
  CHECK_IN_FOLDER_FIELD,
  CHECK_IN_FOLDER_LABEL,
  CADENCE_TEXT_LABEL,
  CADENCE_TEXT_FIELD,
  CONFIRMATION_IMG_URL_FIELD,
  CONFIRMATION_IMG_URL_LABEL,
  DESTINATION_TITLE_FIELD,
  DESTINATION_TITLE_LABEL,
  EMAIL_FIELD,
  EMAIL_LABEL,
  PHONE_IMAGE_URL_FIELD,
  PHONE_IMAGE_URL_LABEL,
  PHONE_NUMBER_LABEL,
  PMC_DESCRIPTION_FIELD,
  PMC_DESCRIPTION_LABEL,
  PMC_SQUARE_IMAGE_URL_LABEL,
  TEXT_LANDING_SITE_URL_LABEL,
  WELCOME_HERO_IMAGE_URL_LABEL,
  PREBOOK_CONFIRMATION_LABEL,
  RATE_VALUE_FIELD,
  START_VALUE_FIELD,
  LABEL_END_DATE,
  LABEL_FEE,
  LABEL_START_DATE,
  PROGRAM_RENEWAL_DATE_LABEL,
  PROGRAM_RENEWAL_DATE_FIELD,
  DISABLE_UNITS_LABEL,
  DISABLE_UNITS_FIELD,
  DISABLE_RESERVATION_LABEL,
  DISABLE_RESERVATION_FIELD,
  ON_E_COMMERCE_LABEL,
  ON_E_COMMERCE_FIELD,
  CHARGE_METHOD_FIELD,
  DELAYED_DEACTIVATION_LABEL,
  DELAYED_DEACTIVATION_FIELD,
  DEACTIVATION_DELAYED_EVENT,
  PMC_DEACTIVATION_EVENT,
  PMC_DEACTIVATION_DELAYED_EVENT,
  LAST_APP_DEACTIVATION_EVENT,
  LAST_APP_DEACTIVATION_DELAYED_EVENT,
  LAST_APP_DEACTIVATION_BY_AP_EVENT,
  LAST_APP_DEACTIVATION_BY_AP_DELAYED_EVENT,
  APP_DEACTIVATION_EVENT,
  APP_DEACTIVATION_DELAYED_EVENT,
  AP_DEACTIVATION_EVENT,
  AP_DEACTIVATION_DELAYED_EVENT,
  DEACTIVATION_DELAYED_LABEL,
  PMC_DEACTIVATION_LABEL,
  PMC_DEACTIVATION_DELAYED_LABEL,
  LAST_APP_DEACTIVATION_LABEL,
  LAST_APP_DEACTIVATION_DELAYED_LABEL,
  LAST_APP_DEACTIVATION_BY_AP_LABEL,
  LAST_APP_DEACTIVATION_BY_AP_DELAYED_LABEL,
  APP_DEACTIVATION_LABEL,
  APP_DEACTIVATION_DELAYED_LABEL,
  AP_DEACTIVATION_LABEL,
  AP_DEACTIVATION_DELAYED_LABEL,
  NETSUITE_ID_LADEL,
  NETSUITE_ID_FIELD,
  EMAIL_CADENCE_LABEL,
  EMAIL_CADENCE_FIELD,
  CADENCE_EMAIL_FIELD,
  CADENCE_EMAIL_LABEL,
  SMART_HOST_ENABLED_FIELD,
  SMART_HOST_LABEL,
  SMART_HOST_MOBILE_LABEL,
  SMART_HOST_EMAIL_CADENCE_FIELD,
  SMART_HOST_CALENDAR_INTEGRATION_FIELD,
  CALENDAR_INTEGRATION_LABEL,
  AMAZON_PROVISIONING_ACCOUNT_FIELD,
  AMAZON_PROVISIONING_ACCOUNT_LABEL,
  AMAZON_PROVISIONING_PASSWORD_FIELD,
  AMAZON_PROVISIONING_PASSWORD_LABEL,
  UNIT_PROGRAM_FIELD,
  UNIT_STATUS_FIELD,
  UNIT_STATUS_NOTE_FIELD,
  UNIT_STATUS_DATE_FIELD,
  UNIT_PROGRAM_LABEL,
  UNIT_STATUS_LABEL,
  UNIT_STATUS_NOTE_LABEL,
  UNIT_STATUS_DATE_LABEL,
  SMART_HOST_MOBILE_FIELD
} from './types'

export function prepareHistoryYears(collection) {
  const historyYears = values(collection)
  return historyYears && orderBy(historyYears, 'year', 'desc')
}

export const HISTORY_FIELDS_MAPPER = {
  // program fields
  [BILLING_TYPE_FIELD]: BILLING_TYPE_FIELD_LABEL,
  [COMMENCEMENT_DATE_FIELD]: LABEL_PROGRAM_COMMENCEMENT_DATE,
  [KEYWORD_FIELD]: LABEL_KEYWORD,
  [MARKET_NAME_FIELD]: MARKET_LABEL,
  [NAME_FIELD]: LABEL_PROGRAM_NAME,
  [SEASON_NAME_FIELD]: LABEL_SEASON_NAME,
  [PMC_DESTINATION_FIELD]: LABEL_DESTINATION,
  [PMC_NAME_FIELD]: LABEL_PMC_NAME,
  [TIME_ZONE_FIELD]: LABEL_TIME_ZONE,
  [UNIQUE_PHONE_NUMBER_FIELD]: LABEL_UNIC_PHONE_NUMBER,
  [XE_PROGRAM_ID_FIELD]: LABEL_XE_PROGRAM_ID,
  [XPLORIE_MOBILE_URL_FIELD]: LABEL_XPLORIE_MOBILE_URL,
  [PROGRAM_TYPE_FIELD_KEY]: PROGRAM_TYPE_FIELD_LABEL,
  [COMPANY_ID_FIELD_KEY]: COMPANY_ID_FIELD_LABEL,
  [PROGRAM_RENEWAL_DATE_FIELD]: PROGRAM_RENEWAL_DATE_LABEL,
  [DISABLE_UNITS_FIELD]: DISABLE_UNITS_LABEL,
  [DISABLE_RESERVATION_FIELD]: DISABLE_RESERVATION_LABEL,
  [ON_E_COMMERCE_FIELD]: ON_E_COMMERCE_LABEL,
  [DELAYED_DEACTIVATION_FIELD]: DELAYED_DEACTIVATION_LABEL,
  [NETSUITE_ID_FIELD]: NETSUITE_ID_LADEL,
  // marketing fields
  [EMAIL_FIELD]: EMAIL_LABEL,
  [PHONE_NUMBER_FIELD]: PHONE_NUMBER_LABEL,
  [ADDITIONAL_PHONE_NUMBER_FIELD]: ADDITIONAL_PHONE_NUMBER_LABEL,
  [DESTINATION_TITLE_FIELD]: DESTINATION_TITLE_LABEL,
  [PMC_DESCRIPTION_FIELD]: PMC_DESCRIPTION_LABEL,
  [CONFIRMATION_IMG_URL_FIELD]: CONFIRMATION_IMG_URL_LABEL,
  [PHONE_IMAGE_URL_FIELD]: PHONE_IMAGE_URL_LABEL,
  [PMC_SQUARE_IMAGE_URL_FIELD]: PMC_SQUARE_IMAGE_URL_LABEL,
  [WELCOME_HERO_IMAGE_URL_FIELD]: WELCOME_HERO_IMAGE_URL_LABEL,
  [TEXT_LANDING_SITE_URL_FIELD]: TEXT_LANDING_SITE_URL_LABEL,
  [CARD_FORM_URL_FIELD]: CARD_FORM_URL_LABEL,
  [CHECK_IN_FOLDER_FIELD]: CHECK_IN_FOLDER_LABEL,
  [CADENCE_TEXT_FIELD]: CADENCE_TEXT_LABEL,
  [EMAIL_CADENCE_FIELD]: EMAIL_CADENCE_LABEL,
  [CADENCE_EMAIL_FIELD]: CADENCE_EMAIL_LABEL,
  [SMART_HOST_ENABLED_FIELD]: SMART_HOST_LABEL,
  [SMART_HOST_MOBILE_FIELD]: SMART_HOST_MOBILE_LABEL,
  [SMART_HOST_EMAIL_CADENCE_FIELD]: EMAIL_CADENCE_LABEL,
  [SMART_HOST_CALENDAR_INTEGRATION_FIELD]: CALENDAR_INTEGRATION_LABEL,
  [AMAZON_PROVISIONING_ACCOUNT_FIELD]: AMAZON_PROVISIONING_ACCOUNT_LABEL,
  [AMAZON_PROVISIONING_PASSWORD_FIELD]: AMAZON_PROVISIONING_PASSWORD_LABEL,
  // prebook fields
  [PREBOOK_CONFIRMATION_ID]: PREBOOK_CONFIRMATION_LABEL,
  [RATE_VALUE_FIELD]: PREBOOK_RATE_LABEL,
  [START_VALUE_FIELD]: PREBOOK_RATE_DATE_LABEL,
  // season fields
  [CHARGE_METHOD_FIELD]: CHARGE_METHOD_LABEL,
  [START_DATE_FIELD]: LABEL_START_DATE,
  [END_DATE_FIELD]: LABEL_END_DATE,
  [FEE_DATE_FIELD]: LABEL_FEE
}

export const SYSTEM_PROGRAMS_FIELDS_MAPPER = {
  [DEACTIVATION_DELAYED_EVENT]: DEACTIVATION_DELAYED_LABEL,
  [PMC_DEACTIVATION_EVENT]: PMC_DEACTIVATION_LABEL,
  [PMC_DEACTIVATION_DELAYED_EVENT]: PMC_DEACTIVATION_DELAYED_LABEL,
  [LAST_APP_DEACTIVATION_EVENT]: LAST_APP_DEACTIVATION_LABEL,
  [LAST_APP_DEACTIVATION_DELAYED_EVENT]: LAST_APP_DEACTIVATION_DELAYED_LABEL,
  [LAST_APP_DEACTIVATION_BY_AP_EVENT]: LAST_APP_DEACTIVATION_BY_AP_LABEL,
  [LAST_APP_DEACTIVATION_BY_AP_DELAYED_EVENT]: LAST_APP_DEACTIVATION_BY_AP_DELAYED_LABEL
}

export const SYSTEM_SEASONS_FIELDS_MAPPER = {
  [APP_DEACTIVATION_EVENT]: APP_DEACTIVATION_LABEL,
  [APP_DEACTIVATION_DELAYED_EVENT]: APP_DEACTIVATION_DELAYED_LABEL,
  [AP_DEACTIVATION_EVENT]: AP_DEACTIVATION_LABEL,
  [AP_DEACTIVATION_DELAYED_EVENT]: AP_DEACTIVATION_DELAYED_LABEL
}

export const UNIT_FIELDS_MAPPER = {
  [UNIT_PROGRAM_FIELD]: UNIT_PROGRAM_LABEL,
  [UNIT_STATUS_FIELD]: UNIT_STATUS_LABEL,
  [UNIT_STATUS_NOTE_FIELD]: UNIT_STATUS_NOTE_LABEL,
  [UNIT_STATUS_DATE_FIELD]: UNIT_STATUS_DATE_LABEL
}

export function getHistoryFieldLabel(fieldName) {
  return get(HISTORY_FIELDS_MAPPER, fieldName, fieldName)
}

export function getSystemProgramHistoryFieldLabel(fieldName) {
  return get(SYSTEM_PROGRAMS_FIELDS_MAPPER, fieldName, fieldName)
}

export function getSystemSeasonsHistoryFieldLabel(fieldName) {
  return get(SYSTEM_SEASONS_FIELDS_MAPPER, fieldName, fieldName)
}

export function getUnitHistoryFieldLabel(fieldName) {
  return get(UNIT_FIELDS_MAPPER, fieldName, fieldName)
}

export function isSeasonEvent(event) {
  return event === SEASON_DETAILS_EVENT
}

export const getPreparedFilters = filters =>
  filters.filter(filterItem => filterItem.value).map(filterItem => filterItem.field)
