/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { NAME, ASSIGN_UNITS, UNASSIGN_UNITS } = types

const REQUEST_ASSIGN_UNITS = `${REQUEST_TYPE}_${ASSIGN_UNITS}`
const RESPONSE_ASSIGN_UNITS = `${RESPONSE_TYPE}_${ASSIGN_UNITS}`
const RESPONSE_ERROR_ASSIGN_UNITS = `${RESPONSE_ERROR_TYPE}_${ASSIGN_UNITS}`

const REQUEST_UNASSIGN_UNITS = `${REQUEST_TYPE}_${UNASSIGN_UNITS}`
const RESPONSE_UNASSIGN_UNITS = `${RESPONSE_TYPE}_${UNASSIGN_UNITS}`
const RESPONSE_ERROR_UNASSIGN_UNITS = `${RESPONSE_ERROR_TYPE}_${UNASSIGN_UNITS}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

export const buildAccessKey = (pmcId, programId) => `${pmcId}_${programId}`
export const buildSuccessMessage = () => `Units have been assigned successfully!`

export default {
  REQUEST_ASSIGN_UNITS,
  RESPONSE_ASSIGN_UNITS,
  RESPONSE_ERROR_ASSIGN_UNITS,
  REQUEST_UNASSIGN_UNITS,
  RESPONSE_UNASSIGN_UNITS,
  RESPONSE_ERROR_UNASSIGN_UNITS,
  RESET_DATA_STATE
}
