/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL_ERROR
} = helpers

const initialState = {}

export const formInitialState = {
  formDataState: DATA_STATE_NOT_REQUESTED,
  activateDataState: DATA_STATE_NOT_REQUESTED
}

const buildData = (state, action) => data => ({
  ...state,
  [action.payload.key]: {
    ...formInitialState,
    ...state[action.payload.key],
    ...data
  }
})

const formReducer = (state = initialState, action) => {
  const appendData = buildData(state, action)

  switch (action.type) {
    // create/update deal
    case REQUEST_FORM:
      return appendData({ formDataState: DATA_STATE_REQUESTING })
    case RESPONSE_FORM:
      return appendData({ formDataState: DATA_STATE_RECIEVED })
    case RESPONSE_FORM_ERROR:
      return appendData({ formDataState: DATA_STATE_ERROR })
    // activate discount deal
    case REQUEST_ACTIVATE_DEAL:
      return appendData({ activateDataState: DATA_STATE_REQUESTING })
    case RESPONSE_ACTIVATE_DEAL:
      return appendData({ activateDataState: DATA_STATE_RECIEVED, entityData: action.payload })
    case RESPONSE_ACTIVATE_DEAL_ERROR:
      return appendData({ activateDataState: DATA_STATE_ERROR })
    // reset
    case RESET_DATA_STATE: {
      const { [action.payload.key]: removedState, ...restState } = state
      return {
        ...restState
      }
    }
    default:
      return state
  }
}

export default filterActions(formReducer, [
  RESET_DATA_STATE,
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL_ERROR
])
