/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const NAME = 'UNITS_TEMPLATES'
const DOWNLOAD_UNITS_TEMPLATE = `${NAME}_DOWNLOAD_UNITS_TEMPLATE`

export default {
  NAME,
  DOWNLOAD_UNITS_TEMPLATE
}
