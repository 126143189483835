/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import { FIELDS_CHANGED, TABLE_CHANGED } from './types'

export const initialState = {
  formState: {
    asyncErrors: {},
    dirty: false,
    errors: {},
    invalid: false,
    pristine: true,
    submits: 0,
    touched: {},
    values: {}
  },
  tableState: {},
  fieldsState: {}
}

const mergeStates = (formState, relatedState, newState) => {
  const clonedNewState = cloneDeep(newState)
  const clonedRelatedState = cloneDeep(relatedState)
  const { submits, ...restState } = merge(clonedRelatedState, clonedNewState)

  return {
    ...formState,
    ...restState,
    invalid: relatedState.invalid || newState.invalid,
    dirty: relatedState.dirty || newState.dirty,
    pristine: relatedState.pristine && newState.pristine,
    submits: relatedState.submits === newState.submits ? newState.submits : formState.submits
  }
}

export function reducer(state, action) {
  switch (action.type) {
    case FIELDS_CHANGED: {
      const fieldsState = { ...action.payload.formState }
      return {
        ...state,
        fieldsState,
        formState: mergeStates(state.formState, state.tableState, fieldsState)
      }
    }
    case TABLE_CHANGED: {
      const tableState = { ...action.payload.formState }
      return {
        ...state,
        tableState,
        formState: mergeStates(state.formState, state.fieldsState, tableState)
      }
    }

    default:
      throw new Error()
  }
}
