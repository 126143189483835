export const groupOptions = [
  {
    value: 0,
    label: 'Individual Activity'
  },
  {
    value: 1,
    label: 'Cruise Experience'
  },
  {
    value: 2,
    label: 'Fishing Experience'
  },
  {
    value: 3,
    label: 'Fun Center Experience'
  },
  {
    value: 4,
    label: 'Golfing Experience'
  },
  {
    value: 5,
    label: 'Local Tour Experience'
  },
  {
    value: 6,
    label: 'Show Package'
  },
  {
    value: 7,
    label: 'Water Sports Experience'
  },
  {
    value: 8,
    label: 'Zip Line Fun'
  }
]

export const groupNameObject = groupOptions.reduce((acc, next) => {
  acc[next.value] = next.label
  return acc
}, {})
