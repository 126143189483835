/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { entityInitialState } from './reducer'
import { NEW_KEY } from './types'

function getLocationState(state) {
  return state.location.locationForm
}

function getLocationStateById(state, id) {
  const key = id || NEW_KEY
  return getLocationState(state)[key] || entityInitialState
}

function getLocationStateIds(state) {
  return Object.keys(getLocationState(state))
}

function getLocationChangedDataStates(state, id) {
  const locationState = getLocationStateById(state, id)
  return [
    locationState.dataState,
    locationState.countryDataState,
    locationState.linkLocationDataState,
    locationState.updateAndLinkChildrenDataState,
  ]
}
function getLocationFormCloseDataStates(state, id) {
  const locationState = getLocationStateById(state, id)
  return [
    locationState.countryDataState,
    locationState.linkLocationDataState,
    locationState.updateAndLinkChildrenDataState,
  ]
}

function getLocationsChangedDataStates(state) {
  return getLocationStateIds(state).reduce(
    (acc, key) => [...acc, ...getLocationChangedDataStates(state, key)],
    []
  )
}

export default {
  getLocationState,
  getLocationStateById,
  getLocationStateIds,
  getLocationChangedDataStates,
  getLocationsChangedDataStates,
  getLocationFormCloseDataStates
}
