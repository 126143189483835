/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { asField, BasicText } from 'informed'
import classNames from 'classnames'
import { Field } from 'components/FormComponents'

function TextInputWrapper(props) {
  const {
    fieldState: { error },
    fieldState,
    label,
    isRequired,
    fieldProps,
    children,
    id,
    className,
    errorClassName,
    ...rest
  } = props
  return (
    <Field id={id} label={label} isRequired={isRequired} error={error} {...fieldProps}>
      <BasicText
        {...rest}
        className={classNames(className, { [errorClassName]: Boolean(error) })}
        id={id}
        fieldState={fieldState}
      />
      {children}
    </Field>
  )
}

TextInputWrapper.propTypes = {
  fieldApi: PropTypes.object.isRequired,
  children: PropTypes.object,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  fieldState: PropTypes.object,
  fieldProps: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  errorClassName: PropTypes.string
}

TextInputWrapper.defaultProps = {
  onChange: () => null,
  onFocus: () => null,
  children: null,
  id: null,
  label: null,
  fieldState: {},
  fieldProps: {},
  isRequired: false,
  className: '',
  errorClassName: ''
}

export const TextInput = asField(TextInputWrapper)
