/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useEffect, useCallback } from 'react'
import { Text } from 'informed'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { TextField, ToggleField, CheckBoxField, SQUARE_TYPE } from 'components/FormComponents'
import { percentageDiscountOptions } from 'modules/Discounts/types'
import get from 'lodash/get'
import { numberValidate, decimalsValidation, formatCostValue } from 'helpers/formHelpers'

import { calcFinalTicketTypePrice, validateFinalPrice } from './helpers'

import styles from './ActivityTable.scss'

export function Row(props) {
  const {
    editMode,
    finalPrice,
    discount,
    disabled,
    freeTickets,
    percentageDiscount,
    ticketType,
    errorsState,
    formApi,
    prefix
  } = props
  const name = get(ticketType, 'name')
  const retailPrice = get(ticketType, 'parameters.retailPrice')
  const isDisabledFields = disabled || !editMode

  const {
    discount: discountError,
    freeTickets: freeTicketsError,
    finalPrice: finalPriceError
  } = errorsState

  useEffect(() => {
    if (editMode) {
      const newFinalPrice = calcFinalTicketTypePrice(retailPrice, discount, percentageDiscount)
      formApi.setValue(`${prefix}.finalPrice`, newFinalPrice)
      formApi.setError(`${prefix}.finalPrice`, validateFinalPrice(newFinalPrice))
    }
  }, [editMode, formApi, retailPrice, discount, percentageDiscount, prefix])

  const onSetDefaultValueBlur = useCallback(
    fieldName => () => {
      const currentValue = formApi.getValue(`${prefix}.${fieldName}`)
      if (!currentValue && currentValue !== 0) {
        formApi.setValue(`${prefix}.${fieldName}`, 0)
      }
    },
    [formApi, prefix, ticketType]
  )

  return (
    <div className={styles.rowWrapper}>
      <div className={classNames(styles.column, styles.checkBoxStyle)}>
        <CheckBoxField
          disabled={isDisabledFields}
          id={`ticket-type-${ticketType.id}`}
          type="checkbox"
          styleType={SQUARE_TYPE}
          field="included"
          notify={['ticketTypes']}
        />
      </div>
      <div className={classNames(styles.column, styles.name)}>
        <span className={styles.value}>{name}</span>
      </div>
      <div className={classNames(styles.column)}>
        <span className={styles.value}>{formatCostValue(retailPrice)}</span>
      </div>
      <div
        className={classNames(
          styles.column,
          { [styles.editable]: editMode },
          { [styles.error]: Boolean(discountError) }
        )}
      >
        <TextField
          className={styles.input}
          fieldName="discount"
          disabled={isDisabledFields}
          initialValue={discount}
          validate={decimalsValidation}
          onBlur={onSetDefaultValueBlur('discount')}
          isRequired
          fieldProps={{
            classNameWrapper: styles.fieldWrapper,
            renderHeader: () => null,
            renderInner: ({ children }) => (
              <div className={styles.feeWrapper}>
                {children}
                <ToggleField
                  field="percentageDiscount"
                  options={percentageDiscountOptions}
                  classNameWrapper={styles.wrapper}
                  className={styles.button}
                  activeClassName={styles.activeButton}
                  disabled={isDisabledFields}
                />
              </div>
            )
          }}
        />
      </div>
      <div
        className={classNames(
          styles.column,
          { [styles.editable]: editMode },
          { [styles.error]: Boolean(freeTicketsError) }
        )}
      >
        <TextField
          fieldProps={{
            classNameWrapper: styles.fieldWrapper,
            renderHeader: () => null
          }}
          className={styles.input}
          fieldName="freeTickets"
          disabled={isDisabledFields}
          initialValue={freeTickets}
          validate={numberValidate}
          onBlur={onSetDefaultValueBlur('freeTickets')}
          isRequired
        />
      </div>
      <div
        className={classNames(styles.column, {
          [styles.error]: Boolean(finalPriceError)
        })}
      >
        <span className={styles.value}>{formatCostValue(finalPrice)}</span>
      </div>
      <Text field="finalPrice" type="hidden" />
    </div>
  )
}

Row.propTypes = {
  editMode: PropTypes.bool,
  finalPrice: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  percentageDiscount: PropTypes.bool,
  ticketType: PropTypes.object.isRequired,
  discount: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  children: PropTypes.node,
  freeTickets: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  disabled: PropTypes.bool,
  included: PropTypes.bool,
  errorsState: PropTypes.object,
  prefix: PropTypes.string.isRequired,
  formApi: PropTypes.object.isRequired
}

Row.defaultProps = {
  editMode: false,
  finalPrice: 0.0,
  percentageDiscount: false,
  discount: 0,
  children: null,
  freeTickets: 1,
  included: true,
  disabled: false,
  errorsState: {}
}
