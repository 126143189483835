import { UNIT_TASK_COUNTER } from 'constants/counterTypes'
import { useDispatch, useSelector } from 'react-redux'
import { actions as unitTasksListActions } from 'reducers/unitTasks/unitTasksList'

// actions
const { getUnitTasksCount } = unitTasksListActions

export function useCounter(type) {
  const dispatch = useDispatch()
  const unitTasksCount = useSelector(state => state.unitTasks.unitTasksList.count)

  switch (type) {
    case UNIT_TASK_COUNTER:
      return {
        getCount: () => dispatch(getUnitTasksCount()),
        count: unitTasksCount
      }
    default:
      return {
        getCount: () => {},
        count: 0
      }
  }
}
