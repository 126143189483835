/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildListParams, buildURLForGettingList } from 'services/http/helpers'
import { MARKET_ENDPOINT } from 'endpoints'

export const getList = options =>
  httpWrapper({
    url: buildURLForGettingList(MARKET_ENDPOINT),
    method: 'get',
    params: buildListParams(options)
  })
