/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { BLOCK_NAME, GET_ENTITY_NAME } from './types'

const REQUEST_FORM = `${REQUEST_TYPE}_${BLOCK_NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${BLOCK_NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${BLOCK_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${BLOCK_NAME}`

const REQUEST_ACCOUNT_SETTINGS = `${REQUEST_TYPE}_${GET_ENTITY_NAME}`
const RESPONSE_ACCOUNT_SETTINGS = `${RESPONSE_TYPE}_${GET_ENTITY_NAME}`
const RESPONSE_ACCOUNT_SETTINGS_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_ENTITY_NAME}`

export default {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_ACCOUNT_SETTINGS,
  RESPONSE_ACCOUNT_SETTINGS,
  RESPONSE_ACCOUNT_SETTINGS_ERROR
}
