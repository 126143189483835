/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { BOOLEAN_TYPE, NUMBER_TYPE, STRING_TYPE, DATE_TYPE } from 'constants/eligibility'

// labels
export const LABEL = 'Label'
export const NAME_LABEL = 'Name'
export const TYPE_LABEL = 'Type'
export const DESCRIPTION_LABEL = 'Description'

// placeholders
export const LABEL_PLACEHOLDER = 'Label'
export const NAME_PLACEHOLDER = 'Add Name'
export const TYPE_PLACEHOLDER = 'Select type'
export const DESCRIPTION_PLACEHOLDER = 'Describe the parameter'

// other
export const TYPE_OPTIONS = [
  { value: DATE_TYPE, label: 'Date' },
  { value: STRING_TYPE, label: 'String' },
  { value: NUMBER_TYPE, label: 'Number' },
  { value: BOOLEAN_TYPE, label: 'Boolean' }
]
