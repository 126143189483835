/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { parseDecimals } from 'helpers/formHelpers'
import { PERCENTAGE_SIGN } from './types'
import styles from './Field.scss'

export function Field({ label, value, isPercentageOption }) {
  const shownValue = value ? parseDecimals(value) : null
  const percentSign = (value && isPercentageOption && ` ${PERCENTAGE_SIGN}`) || null
  return (
    <li className={styles.field}>
      <span className={styles.fieldLabel}>{label}</span>
      <span className={styles.fieldValue}>
        {shownValue}
        {percentSign}
      </span>
    </li>
  )
}
Field.propTypes = {
  isPercentageOption: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
Field.defaultProps = {
  label: '',
  value: null,
  isPercentageOption: null
}
