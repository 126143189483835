/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const ActivityProviderSlider = loadable(
  () =>
    import(
      /* webpackChunkName: 'ActivityProviderSlider', webpackPrefetch: true */ './ActivityProviderSlider'
    ),
  {
    resolveComponent: components => components.ActivityProviderSlider
  }
)

export * from './APLifeCycleButtons'
export * from './APModals'
export { ActivityProviderSlider }
export { DelayedDeactivation, DeactivateConfirmationModal } from './DelayedDeactivation'
export * from './helpers'
