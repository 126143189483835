/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getSettingsByAccountId, updateSettingsByAccountId } from 'services/http/account'

import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_ACCOUNT_SETTINGS,
  RESPONSE_ACCOUNT_SETTINGS,
  RESPONSE_ACCOUNT_SETTINGS_ERROR
} = helpers

const updateSettingsAction = (id, contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await updateSettingsByAccountId(id, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    // TODO: Hide success message on UI
    // const {
    //   message: { message, send }
    // } = request
    dispatch(actionCreater(RESPONSE_FORM))
    // dispatch(send(message))
    return 'updateSettingsAction success'
  }

  return 'updateSettingsAction failed'
}

const getAccountSettings = (authKey, id) => async dispatch => {
  dispatch(actionCreater(REQUEST_ACCOUNT_SETTINGS))
  const request = await getSettingsByAccountId(authKey, id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ACCOUNT_SETTINGS_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_ACCOUNT_SETTINGS, request.data))
    return 'getAccountSettings success'
  }

  return 'getAccountSettings failed'
}

const resetForm = () => ({
  type: RESET_DATA_STATE
})

export default {
  updateSettingsAction,
  getAccountSettings,
  resetForm
}
