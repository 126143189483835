/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { EXCLUSIVE_ACTIVITY } from 'constants/searchTypes'

const EXCLUSIVE_ACTIVITY_LIST_NAME = 'EXCLUSIVE_ACTIVITY_LIST_NAME'
const EXCLUSIVE_ACTIVITY_NAME = 'EXCLUSIVE_ACTIVITY_NAME'

const ENTITY_TYPE = EXCLUSIVE_ACTIVITY

export default {
  ENTITY_TYPE,
  EXCLUSIVE_ACTIVITY_LIST_NAME
}

export { ENTITY_TYPE, EXCLUSIVE_ACTIVITY_LIST_NAME, EXCLUSIVE_ACTIVITY_NAME }
