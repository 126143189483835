/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import {
  createEntity,
  getEntityById,
  updateEntity,
  sendEmailPreview,
  markAsRead
} from 'services/http/ticketSettings'
import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID_ERROR,
  REQUEST_PREVIEW_EMAIL,
  RESPONSE_PREVIEW_EMAIL,
  RESPONSE_PREVIEW_EMAIL_ERROR
} = helpers

const getEntityPromise = async (...args) => {
  try {
    const request = await getEntityById(...args)
    return Promise.resolve(request)
  } catch (err) {
    const { statusCode } = err
    if (statusCode === 404) {
      return Promise.resolve({ data: {} })
    }
    return Promise.reject(err)
  }
}

const postForm = (activityId, contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await createEntity(activityId, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM))
    dispatch(send(message))
    return 'postForm success'
  }

  return 'postForm failed'
}

const putForm = (activityId, contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await updateEntity(activityId, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM))
    dispatch(send(message))
    return 'putForm success'
  }

  return 'putForm failed'
}

const getEntityDataById = (activityId, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_ENTITY_BY_ID))
  const request = await getEntityPromise(activityId, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID, request.data))
    return 'getEntityDataById success'
  }

  return 'getEntityDataById failed'
}

const previewEmail = (activityId, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_PREVIEW_EMAIL))
  const request = await sendEmailPreview(activityId, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_PREVIEW_EMAIL_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_PREVIEW_EMAIL))
    dispatch(send(message))
    return 'previewEmail success'
  }

  return 'previewEmail failed'
}

const resetForm = () => ({
  type: RESET_DATA_STATE
})

const markCredentialsAsRead = (accountId, activityId, type) => async dispatch => {
  await markAsRead(accountId, activityId, type).catch(({ send, message }) => {
    dispatch(send(message))
  })
}

export default {
  postForm,
  putForm,
  getEntityDataById,
  previewEmail,
  resetForm,
  markCredentialsAsRead
}
