import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Filter } from '@xplorie/ui-commons'
import get from 'lodash/get'
import { useFilters } from 'modules/FilteredSearch'
import { useDidMount } from 'hooks/hooks'

export const FormFilter = props => {
  const { filters } = props
  const [contextFilters, setContextFilter] = useFilters()

  const filterActions = useMemo(
    () =>
      filters.map(item => ({
        ...item,
        value: get(contextFilters, item.field, false)
      })),
    [contextFilters, filters]
  )

  const excludeFiltersWithSameValue = useCallback(
    ({ field, value }) => {
      const prevValue = get(contextFilters, field)

      return prevValue !== value
    },
    [contextFilters]
  )

  const onFilterChange = useCallback(
    newFilters => {
      newFilters.filter(excludeFiltersWithSameValue).forEach(({ field, value, onChange }) => {
        if (onChange) {
          onChange(value)
        }

        if (setContextFilter) {
          setContextFilter(field, value)
        }
      })
    },
    [setContextFilter, excludeFiltersWithSameValue]
  )

  useDidMount(() => {
    onFilterChange(filterActions)
  })

  return <Filter filters={filterActions} onChange={onFilterChange} />
}

FormFilter.propTypes = {
  filters: PropTypes.array.isRequired
}
