/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { useRef, useEffect, useReducer, useLayoutEffect, useState, useCallback } from 'react'
import get from 'lodash/get'
import {
  DATA_STATE_REQUESTING,
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import isNil from 'lodash/isNil'
import { useLocation } from 'react-router'

const DATA_STATES = [
  DATA_STATE_REQUESTING,
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
]

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export function usePreviousValues(values = []) {
  const ref = useRef()

  useEffect(() => {
    ref.current = values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...values])

  return ref.current
}

export function useUpdateSomeDataState(
  callback = () => null,
  dataStates = [],
  stateValue = DATA_STATE_RECIEVED
) {
  const prevStates = usePreviousValues(dataStates)
  useEffect(() => {
    const needUpdate =
      prevStates &&
      prevStates.some(
        (value, index) => value !== dataStates[index] && dataStates[index] === stateValue
      )
    if (needUpdate) {
      callback(...prevStates)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dataStates])
}

/**
 * @param lock {boolean}
 */
export function useLockBodyScroll(lock) {
  useLayoutEffect(() => {
    // Get original value of body overflow
    const body = window.document.getElementsByTagName('body')[0]
    const originalStyle = window.getComputedStyle(body).overflow
    // Prevent scrolling on mount
    body.style.overflow = lock ? 'hidden' : originalStyle
    // Re-enable scrolling when component unmounts
    return () => {
      body.style.overflow = originalStyle
    }
  }, [lock]) // Empty array ensures effect is only run on mount and unmount
}

export function useUnLockBodyScroll(sliders) {
  useLayoutEffect(
    () => () => {
      // the last slider is closing
      if (sliders.length === 1) {
        const body = window.document.getElementsByTagName('body')[0]
        body.style.overflow = 'visible'
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}

export function useLoadingDataState(dataState = '') {
  const initialState = { isFirstLoading: true, isLoading: false, isError: false, isLoaded: false }

  function reducer(state, action) {
    switch (action.type) {
      case 'isFirstLoading':
        return { ...state, isFirstLoading: false, isLoading: true, isError: false, isLoaded: false }
      case DATA_STATE_REQUESTING:
        return { ...state, isError: false, isLoaded: false, isLoading: true }
      case DATA_STATE_RECIEVED:
        return { ...state, isError: false, isLoaded: true, isLoading: false }
      case DATA_STATE_ERROR:
        return { ...state, isError: true, isLoaded: false, isLoading: false }
      case DATA_STATE_NOT_REQUESTED:
        return { ...initialState }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (DATA_STATE_REQUESTING === dataState && state.isFirstLoading) {
      dispatch({ type: 'isFirstLoading' })
    } else {
      dispatch({ type: dataState })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataState])

  return [state.isLoading, { ...state }]
}

export function useDidMount(callback) {
  if (!isFunction(callback)) {
    throw new Error('callback must be a function')
  }
  useEffect(
    callback,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}

export function useDidUpdate(callback, deps = [], props = {}) {
  if (!isFunction(callback)) {
    throw new Error('callback must be a function')
  }
  const prevProps = usePrevious(props)

  useEffect(
    () => {
      if (!prevProps) {
        return
      }

      callback(prevProps)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )
}

export const useBeforeCloseModal = props => {
  const { tabsApi, slideEvents } = props
  const beforeCloseModalApi = useRef()
  const [isPristine, setPristineState] = useState(true)

  const onBeforeExit = useCallback(
    () => (!isPristine ? beforeCloseModalApi.current.open() : isPristine),
    [isPristine]
  )
  const setBeforeChange = useCallback(
    callback => {
      if (tabsApi) {
        tabsApi.onBeforeChange = callback
      }

      if (slideEvents) {
        slideEvents.onBeforeClose = callback
      }
    },
    [tabsApi, slideEvents]
  )

  const handleFormChange = useCallback(
    tableState => {
      const hasChanged = Object.keys(tableState.touched).length > 0

      setPristineState(!hasChanged)
    },
    [setPristineState]
  )

  setBeforeChange(onBeforeExit)

  const onGetBeforeCloseModalApi = useCallback(api => {
    beforeCloseModalApi.current = api
  }, [])

  useDidMount(() => () => {
    setBeforeChange(null)
  })

  return {
    setPristineState,
    onGetBeforeCloseModalApi,
    handleFormChange
  }
}

/**
 * more stable version of use loading
 * @param {string} dataState
 * @returns {Array} [isLoading, state] state = {isFirstLoading, isLoading isError isLoaded }
 */
export function useLoading(
  dataState,
  initialState = { isFirstLoading: true, isLoading: false, isError: false, isLoaded: false }
) {
  if (!isString(dataState)) {
    throw new Error(`dataState must be have type String`)
  }
  if (DATA_STATES.every(value => value !== dataState)) {
    throw new Error(`dataState must be one of ${DATA_STATES.toString()}`)
  }

  function reducer(state, action) {
    switch (action.type) {
      case DATA_STATE_REQUESTING:
        return { ...state, isFirstLoading: false, isError: false, isLoaded: false, isLoading: true }
      case DATA_STATE_RECIEVED:
        return { ...state, isFirstLoading: false, isError: false, isLoaded: true, isLoading: false }
      case DATA_STATE_ERROR:
        return { ...state, isFirstLoading: false, isError: true, isLoaded: false, isLoading: false }
      case DATA_STATE_NOT_REQUESTED:
        return { ...initialState }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState, initState =>
    reducer(initState, { type: dataState })
  )

  useEffect(() => {
    dispatch({ type: dataState })
  }, [dataState])

  return [state.isLoading, { ...state }]
}

/**
 *
 * @param {function} callback function
 * @param {array} dataStates array of dataStates
 * @param {string} dataState  dataState value for equal
 */
export function useDataStatesComparison(
  callback = () => null,
  dataStates = [],
  dataState = DATA_STATE_RECIEVED,
  options = {}
) {
  const ignoreLength = get(options, 'ignoreLength', false)

  const prevValues = usePrevious(dataStates)
  useEffect(() => {
    if (
      prevValues &&
      dataStates &&
      (prevValues.length === dataStates.length || ignoreLength) &&
      prevValues.some(
        (value, index) => value !== dataStates[index] && dataStates[index] === dataState
      )
    ) {
      callback()
    }
  }, [prevValues, dataStates, dataState, callback, ignoreLength])
}

export function useInitialFieldValue(callback, initValue, currentValue) {
  const prevValue = usePrevious(currentValue)
  useEffect(() => {
    if (!callback) {
      return
    }
    if (!isNil(initValue) && isUndefined(prevValue) && isNil(currentValue)) {
      callback(initValue)
    }
  }, [callback, currentValue, initValue, prevValue])
}

export function useInitialLoading(dataState, enabled = true) {
  const [isLoading, setIsLoading] = useState(Boolean(enabled))

  useEffect(() => {
    setIsLoading(dataState === DATA_STATE_REQUESTING)
  }, [dataState])

  return [
    isLoading,
    { isLoaded: dataState === DATA_STATE_RECIEVED, isError: dataState === DATA_STATE_ERROR }
  ]
}

export function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export function useIsFirstRender() {
  const isFirst = useRef(true)

  if (isFirst.current) {
    isFirst.current = false

    return true
  }

  return isFirst.current
}
