/*
* COPYRIGHT:     Copyright © 2018 Xplorie LLC
* Warning:       This product is protected by United States and international copyright laws.
*                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
*/
import {
  REQUEST_TYPE,
  RESET_STATE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE
} from 'helpers/actionHelpers'

import types from './types'

const { FORM_NAME, PROGRAM_DRAFT, PROGRAM_APPROVAL } = types

const REQUEST_APPROVAL = `${REQUEST_TYPE}_${FORM_NAME}`
const RESPONSE_APPROVAL = `${RESPONSE_TYPE}_${FORM_NAME}`
const RESPONSE_APPROVAL_ERROR = `${RESPONSE_ERROR_TYPE}_${FORM_NAME}`

const REQUEST_PROGRAM_DRAFT = `${REQUEST_TYPE}_${PROGRAM_DRAFT}`
const RESPONSE_PROGRAM_DRAFT = `${RESPONSE_TYPE}_${PROGRAM_DRAFT}`
const RESPONSE_PROGRAM_DRAFT_ERROR = `${RESPONSE_ERROR_TYPE}_${PROGRAM_DRAFT}`
const RESET_APPROVAL_STATE = `${RESET_STATE}_${PROGRAM_APPROVAL}`

export default {
  REQUEST_APPROVAL,
  RESPONSE_APPROVAL,
  RESPONSE_APPROVAL_ERROR,
  REQUEST_PROGRAM_DRAFT,
  RESPONSE_PROGRAM_DRAFT,
  RESPONSE_PROGRAM_DRAFT_ERROR,
  RESET_APPROVAL_STATE
}
