/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const SEASONS_TITLE_SPOT = 'Seasons'
export const NO_DATE = 'N/A'

// Messages
export const BEGIN_END_COINCEDE_ERROR_MESSAGE =
  'The year of the beginning of the season and the year of end must coincide'
export const BEGIN_END_MONTH_ERROR_MESSAGE =
  'The month of the end of the season can not be earlier than the month of the beginning'
export const BEGIN_END_DAY_ERROR_MESSAGE =
  'The day of end of the season can not be before the day start of the season'
export const NULL_SEASONS_ERROR_MESSAGE = 'There must be one or more seasons.'
export const HAS_CONFLICTS_ERROR_MESSAGE = 'Season has conflicting dates'
export const SEASONS_CONTAIN_ERRORS = 'Seasons contain validation errors!'
export const DANGER_MESSAGE = 'In a season there must be at least one activity'

// errors keys
export const BEGIN_END_YEAR_ERROR_KEY = 'BEGIN_END_YEAR_ERROR_KEY'
export const BEGIN_END_MONTH_ERROR_KEY = 'BEGIN_END_MONTH_ERROR_KEY'
export const BEGIN_END_DAY_ERROR_KEY = 'BEGIN_END_DAY_ERROR_KEY'
export const NULL_SEASONS_ERROR_KEY = 'NULL_SEASONS_ERROR_KEY'
export const NULL_ACTIVITIES_SEASONS_ERROR_KEY = 'NULL_ACTIVITIES_SEASONS_ERROR_KEY'

// errors
export const BEGIN_END_COINCEDE_ERROR = {
  id: 1,
  message: BEGIN_END_COINCEDE_ERROR_MESSAGE
}
export const BEGIN_END_MONTH_ERROR = {
  id: 2,
  message: BEGIN_END_MONTH_ERROR_MESSAGE
}
export const BEGIN_END_DAY_ERROR = {
  id: 3,
  message: BEGIN_END_DAY_ERROR_MESSAGE
}
export const NULL_SEASONS_ERROR = {
  id: 4,
  message: NULL_SEASONS_ERROR_MESSAGE
}
export const HAS_CONFLICTS_ERROR = {
  id: 6,
  message: HAS_CONFLICTS_ERROR_MESSAGE
}
export const SEASONS_HAS_ERRORS = {
  id: 7,
  message: SEASONS_CONTAIN_ERRORS
}
export const SEASONS_HAS_EMPTY_ACTIVITIES_LIST = {
  id: 8,
  message: DANGER_MESSAGE
}
