/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getFreeList } from 'services/http/uniqPhoneNumDictionary'

import helpers from './helpers'

const { REQUEST_FREE_LIST, RESPONSE_FREE_LIST, RESPONSE_ERROR_FREE_LIST, RESET_FREE_LIST } = helpers

const getFreeUniqPhoneNumberList = options => async dispatch => {
  dispatch(actionCreater(REQUEST_FREE_LIST))

  const request = await getFreeList(options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_FREE_LIST))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_FREE_LIST, [...request.data]))
    return 'getFreeUniqPhoneNumberList success'
  }

  return 'getFreeUniqPhoneNumberList failed'
}

const resetFreeList = () => ({
  type: RESET_FREE_LIST
})

export default {
  getFreeUniqPhoneNumberList,
  resetFreeList
}
