/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { accountFormInitialState } from './reducer'

export const getAccountFormState = state => state.account.accountForm

export const getAccountFormStateById = (state, accountId) => {
  const accountFormState = getAccountFormState(state)
  if (!accountId) {
    return accountFormState.new || accountFormInitialState
  }
  return accountFormState[accountId] || accountFormInitialState
}
