import { CLOSURE_TYPE, SCHEDULE_TYPE } from 'modules/Closures/types'
import { STATUS_CANCELED } from 'constants/entityStatus'
import {
  SCHEDULE_EVENT_LABEL,
  CLOSURE_EVENT_LABEL,
  FULL_DAY_SCHEDULE_EVENT_LABEL,
  FULL_DAY_CLOSURE_EVENT_LABEL,
  CANCELLATION_LABEL,
  DELETE_SCHEDULE_OCCURRENCE_MESSAGE,
  DELETE_CLOSURE_OCCURRENCE_MESSAGE,
  SUCCESS_DELETE_SCHEDULE_MESSAGE,
  ERROR_DELETE_SCHEDULE_MESSAGE,
  SUCCESS_DELETE_CLOSURE_MESSAGE,
  ERROE_DELETE_CLOSURE_MESSAGE
} from './types'
import { ClosureModal } from '../ClosureModal/ClosureModal'
import { ScheduleModal } from '../ScheduleModal/ScheduleModal'

export function getEventItemHeader(event) {
  if (event.status === STATUS_CANCELED) {
    return CANCELLATION_LABEL
  }

  switch (event.type) {
    case SCHEDULE_TYPE:
      return event.details.allDay ? FULL_DAY_SCHEDULE_EVENT_LABEL : SCHEDULE_EVENT_LABEL
    case CLOSURE_TYPE:
      return event.details.allDay ? FULL_DAY_CLOSURE_EVENT_LABEL : CLOSURE_EVENT_LABEL
    default:
      return CLOSURE_EVENT_LABEL
  }
}

const getOneDayContextMenu = context =>
  context.map(menu => ({
    ...menu,
    options: menu.options.filter(submenu => submenu.isOneDayOption)
  }))

export const getClosureContextMenu = ({
  info,
  handleEditSeries,
  handleEditOccurrence,
  handleDeleteItem
}) => {
  const { isSeries } = info.event.extendedProps

  const contextMenu = [
    {
      label: 'Edit Closure',
      options: [
        { label: 'Edit Series', onClick: handleEditSeries(info, ClosureModal) },
        {
          label: 'Edit Occurrence',
          onClick: handleEditOccurrence(info, ClosureModal),
          isOneDayOption: true
        }
      ]
    },
    {
      label: 'Delete Closure',
      options: [
        { label: 'Delete Series', onClick: handleDeleteItem(info, true) },
        { label: 'Delete Occurrence', onClick: handleDeleteItem(info), isOneDayOption: true }
      ]
    }
  ]

  return isSeries ? contextMenu : getOneDayContextMenu(contextMenu)
}

export const getScheduleContextMenu = ({
  info,
  handleEditOccurrence,
  handleEditSeries,
  handleDeleteItem,
  handleCancelSchedule,
  handleActivateSchedule
}) => {
  const { status, isSeries, hasOnlyOneCancellation } = info.event.extendedProps
  const isCancelled = status === STATUS_CANCELED
  const contextMenu = [
    {
      label: 'Edit Schedule',
      options: [
        {
          label: 'Edit Series',
          onClick: handleEditSeries(info, ScheduleModal)
        },
        {
          label: 'Edit Occurrence',
          onClick: handleEditOccurrence(info, ScheduleModal),
          isOneDayOption: true
        }
      ]
    },
    {
      label: 'Delete Schedule',
      options: [
        { label: 'Delete Series', onClick: handleDeleteItem(info, true) },
        { label: 'Delete Occurrence', onClick: handleDeleteItem(info), isOneDayOption: true }
      ]
    }
  ]

  const cancellationContextMenu = {
    label: 'Cancel Schedule',
    options: [
      {
        label: 'Cancel Series',
        onClick: handleCancelSchedule(info, true)
      },
      { label: 'Cancel Occurrence', onClick: handleCancelSchedule(info), isOneDayOption: true }
    ]
  }

  const activationContextMenu = {
    label: 'Activate Schedule',
    options: hasOnlyOneCancellation
      ? [
          {
            label: 'Activate Occurrence',
            onClick: handleActivateSchedule(info),
            isOneDayOption: true
          }
        ]
      : [
          { label: 'Activate Series', onClick: handleActivateSchedule(info, true) },
          {
            label: 'Activate Occurrence',
            onClick: handleActivateSchedule(info),
            isOneDayOption: true
          }
        ]
  }

  contextMenu.push(isCancelled ? activationContextMenu : cancellationContextMenu)

  return isSeries ? contextMenu : getOneDayContextMenu(contextMenu)
}

export const getEventContextMenu = props => {
  const { info } = props
  const { type } = info.event.extendedProps
  if (type === CLOSURE_TYPE) {
    return getClosureContextMenu(props)
  }

  return getScheduleContextMenu(props)
}

export const getDeleteOccurrenceMessage = type => {
  switch (type) {
    case SCHEDULE_TYPE:
      return DELETE_SCHEDULE_OCCURRENCE_MESSAGE
    case CLOSURE_TYPE:
    default:
      return DELETE_CLOSURE_OCCURRENCE_MESSAGE
  }
}

export const getDeleteOccurrenceStatusMessages = type => {
  switch (type) {
    case SCHEDULE_TYPE:
      return {
        successMessage: SUCCESS_DELETE_SCHEDULE_MESSAGE,
        errorMessage: ERROR_DELETE_SCHEDULE_MESSAGE
      }
    case CLOSURE_TYPE:
    default:
      return {
        successMessage: SUCCESS_DELETE_CLOSURE_MESSAGE,
        errorMessage: ERROE_DELETE_CLOSURE_MESSAGE
      }
  }
}
