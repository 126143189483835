/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getCommonDealFormState } from 'reducers/deals/dealCommon'
import { useDidMount } from 'hooks'
import { PIECurtainLoader } from 'components/Loaders'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { DiscountOptions, useDealOptionsApiBuilder } from './DiscountOptions'
import { DiscountPeriods, useDealPeriodsApiBuilder } from './DiscountPeriods'
import { DiscountTicketTypes, useDealTicketTypesApiBuilder } from './DiscountTicketTypes'
import { DiscountSystemDetails } from './DiscountSystemDetails'
import { Buttons } from './Buttons'
import { DiscountContext } from './helpers'
import { useDiscountDealApiBuilder } from './hooks'
import { useTicketTypesMediator } from './useTicketTypesMediator'

export function DiscountForm({ dataId, onClose, getApi }) {
  const { entityData } = useSelector(state => getCommonDealFormState(state, dataId))

  const [periodsFormState, dealPeriods] = useDealPeriodsApiBuilder()
  const [optionsFormState, dealOptions] = useDealOptionsApiBuilder()
  const [ticketTypesFormState, dealTicketTypes] = useDealTicketTypesApiBuilder()
  const discountCode = get(entityData, 'businessCode', null)

  const [dealState, dealApi] = useDiscountDealApiBuilder(
    dataId,
    { optionsFormState },
    { dealOptions, dealPeriods, dealTicketTypes, sliderApi: { onClose } }
  )

  const state = useMemo(
    () => ({
      dealState,
      optionsFormState,
      periodsFormState,
      ticketTypesFormState,
      discountCode
    }),
    [dealState, optionsFormState, periodsFormState, ticketTypesFormState, discountCode]
  )

  const contextValue = useMemo(
    () => ({
      api: {
        dealApi,
        dealPeriods,
        dealOptions,
        dealTicketTypes
      },
      state
    }),
    [dealApi, dealOptions, dealPeriods, dealTicketTypes, state]
  )

  const { isFormSubmitting, isDeactivating, isChangingToDraft, isActivating } = dealState

  const showLoader = useMemo(
    () => isFormSubmitting || isDeactivating || isChangingToDraft || isActivating,
    [isActivating, isChangingToDraft, isDeactivating, isFormSubmitting]
  )

  useTicketTypesMediator(state, contextValue.api)

  useDidMount(() => {
    if (dataId) {
      dealApi.setDealFormValues(entityData)
    }

    if (getApi) {
      getApi(contextValue.api)
    }

    return () => {
      dealApi.reset()
    }
  })

  return (
    <div>
      {showLoader && <PIECurtainLoader />}
      <DiscountContext.Provider value={contextValue}>
        <DiscountOptions />
        <DiscountPeriods />
        <DiscountTicketTypes />
        {dataId && <DiscountSystemDetails />}
        <Buttons />
      </DiscountContext.Provider>
    </div>
  )
}

DiscountForm.propTypes = {
  dataId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  getApi: PropTypes.func
}
DiscountForm.defaultProps = {
  dataId: null,
  getApi: null
}
