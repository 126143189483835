/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'
import { getProgramDataState } from 'reducers/programsForm'
import get from 'lodash/get'
import {
  actions as programEligibilityParamListActions,
  getEligibilityParamListByProgramId
} from 'reducers/eligibility/programEligibilityParamList'

import {
  actions as programEligibilityValuesListActions,
  getEligibilityValuesListStateByProgramId
} from 'reducers/eligibility/programEligibilityValuesList'

import {
  actions as programEligibilityFormActions,
  getEligibilityProgramFormById
} from 'reducers/eligibility/programEligibilityForm'

import { ProgramEligibilityForm as ProgramEligibilityFormComponent } from './ProgramEligibilityForm'

const mapStateToProps = (state, { dataId }) => {
  const programState = getProgramDataState(state, dataId)
  const eligibilityParamsList = getEligibilityParamListByProgramId(state, dataId)
  const eligibilityValuesList = getEligibilityValuesListStateByProgramId(state, dataId)
  const eligibilityForm = getEligibilityProgramFormById(state, dataId)
  const data = get(eligibilityValuesList, 'data', {})

  return {
    data,
    eligibilityParams: eligibilityParamsList.data,
    programValuesDataState: eligibilityValuesList.dataState,
    programParamsDataState: eligibilityParamsList.dataState,
    formDataState: eligibilityForm.bulkAssigmentDataState,
    programData: programState.programData
  }
}

const mapDispatchToProps = dispatch => {
  const { updateEligibilityParamsByProgram } = programEligibilityFormActions
  const {
    getEligibilityParamsListByProgramId,
    resetList: resetListParams
  } = programEligibilityParamListActions
  const {
    getEligibilityValuesListByProgramId,
    resetList: resetListValues
  } = programEligibilityValuesListActions
  return bindActionCreators(
    {
      getEligibilityParamsListByProgramId,
      getEligibilityValuesListByProgramId,
      updateEligibilityParamsByProgram,
      resetListParams,
      resetListValues
    },
    dispatch
  )
}
export const ProgramEligibilityForm = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProgramEligibilityFormComponent)
