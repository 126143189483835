/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { CurtainPortal } from 'portals/CurtainPortal'

import styles from './SimpleModalWrapper.scss'

export class SimpleModalWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.modalRef = React.createRef()
  }

  componentDidMount = () => {
    if (this.modalRef.current) {
      this.modalRef.current.focus()
    }
  }

  onEscHandler = cancelHandler => ev => {
    if (ev.keyCode === 27) {
      ev.stopPropagation()
      cancelHandler(ev)
    }
  }

  render() {
    const { children, cancelHandler, classNameWrapper, classNameContent } = this.props

    return (
      <CurtainPortal>
        <div
          tabIndex="0"
          onKeyDown={this.onEscHandler(cancelHandler)}
          onFocus={this.onFocusHandler}
          ref={this.modalRef}
        >
          <div className={classNames(styles.modalWrapper, classNameWrapper)}>
            <div className={classNames(styles.modalContent, classNameContent)}>{children}</div>
          </div>
        </div>
      </CurtainPortal>
    )
  }
}

SimpleModalWrapper.propTypes = {
  cancelHandler: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
  classNameWrapper: PropTypes.string,
  classNameContent: PropTypes.string
}

SimpleModalWrapper.defaultProps = {
  classNameWrapper: '',
  classNameContent: ''
}
