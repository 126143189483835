/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useUpdateSomeDataState } from 'hooks'
import { connect } from 'react-redux'
import { actions } from 'reducers/currentUser'
import { withRouter } from 'react-router'
import {
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR,
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING
} from 'helpers/actionHelpers'
import { buildBackUrl } from 'services/auth'
import { AuthContext } from 'context'
import { SimpleStub } from 'modules/Stubs'

const withAuthorization = Component => {
  function WithAuthorization(props) {
    const { checkAuth, resetData, authUser, dataState, logOutDataState, location } = props

    useEffect(() => {
      if (!authUser && dataState === DATA_STATE_NOT_REQUESTED) {
        checkAuth()
      }
    }, [authUser, checkAuth, dataState])

    useUpdateSomeDataState(
      () => {
        window.location.href = buildBackUrl(location)
        resetData()
      },
      [logOutDataState],
      DATA_STATE_RECIEVED
    )

    useUpdateSomeDataState(
      () => {
        window.location.href = buildBackUrl(location)
      },
      [dataState],
      DATA_STATE_ERROR
    )

    if (dataState === DATA_STATE_REQUESTING) {
      return <SimpleStub message="Authorization check..." />
    }
    if (logOutDataState === DATA_STATE_REQUESTING) {
      return <SimpleStub message="Logout..." />
    }
    if (dataState === DATA_STATE_RECIEVED) {
      return (
        <AuthContext.Provider value={authUser}>
          <Component {...props} />
        </AuthContext.Provider>
      )
    }
    return null
  }

  WithAuthorization.propTypes = {
    authUser: PropTypes.object,
    dataState: PropTypes.string.isRequired,
    checkAuth: PropTypes.func.isRequired,
    logOutDataState: PropTypes.string.isRequired,
    resetData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    refreshTokenDataState: PropTypes.object.isRequired
  }
  WithAuthorization.defaultProps = {
    authUser: null
  }
  const WithAuthorizationConnected = connect(
    state => ({
      dataState: state.currentUser.dataState,
      logOutDataState: state.currentUser.logOutDataState,
      authUser: state.currentUser.authUser,
      refreshTokenDataState: state.currentUser.refreshTokenDataState
    }),
    dispatch => ({
      checkAuth: authUser => dispatch(actions.checkAuth(authUser)),
      resetData: () => dispatch(actions.resetData())
    })
  )(WithAuthorization)
  return withRouter(WithAuthorizationConnected)
}

export default withAuthorization
