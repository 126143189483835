/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const ActivityCategoryForm = loadable(
  () =>
    import(
      /* webpackChunkName: 'ActivityCategoryForm', webpackPrefetch: true */ './ActivityCategoryForm'
    ),
  {
    resolveComponent: components => components.ActivityCategoryForm
  }
)

export { ActivityCategoryForm }
export { ActivityCategoryList } from './ActivityCategoryList'
export * from './helpers'
