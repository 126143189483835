/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { ACTIVITY, ACTIVITY_CATEGORY, ACTIVITY_PROVIDER, MARKET, RMS } from 'constants/searchTypes'
import { PRIMARY_ADDRESS } from 'helpers/types/dataTablesTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'
import get from 'lodash/get'
import { findFirstPrimaryAddress } from 'helpers/formHelpers'
import { getStatusValue } from './commonHelpers'

const defaultActivityProductsColumns = [
  {
    Header: 'RMS',
    accessor: 'activityInfo.rms.name',
    id: buildSortPropertyByType('name', RMS)
  },
  {
    Header: 'City',
    accessor: value => {
      const addressees = get(value, 'addresses.addressValues')
      const address = findFirstPrimaryAddress(addressees)
      return get(address, 'address.city')
    },
    id: buildSortPropertyByType('city', PRIMARY_ADDRESS),
    pathToProperty: 'addresses.addressValues[0].address.city'
  },
  {
    Header: 'State/Province',
    accessor: value => {
      const addressees = get(value, 'addresses.addressValues')
      const address = findFirstPrimaryAddress(addressees)
      return get(address, 'address.state')
    },
    id: buildSortPropertyByType('state', PRIMARY_ADDRESS),
    pathToProperty: 'addresses.addressValues[0].address.state'
  }
]

export const activityProductTableColumns = [
  {
    Header: 'Activity Provider',
    accessor: 'activityProvider.name',
    id: buildSortPropertyByType('name', ACTIVITY_PROVIDER)
  },
  {
    Header: 'AP Product',
    accessor: 'name',
    id: buildSortPropertyByType('name', ACTIVITY)
  },
  ...defaultActivityProductsColumns,
  {
    Header: 'Status',
    accessor: getStatusValue,
    id: buildSortPropertyByType('status', ACTIVITY),
    pathToProperty: 'status'
  }
]

export const appsColumns = [
  {
    Header: 'AP',
    accessor: 'activityProvider.name',
    id: buildSortPropertyByType('name', ACTIVITY_PROVIDER)
  },
  {
    Header: 'APP',
    accessor: 'name',
    id: buildSortPropertyByType('name', ACTIVITY)
  },
  ...defaultActivityProductsColumns
]

export const appsByMarketColumns = [
  {
    Header: 'Market',
    accessor: 'activityInfo.market.name',
    id: buildSortPropertyByType('name', MARKET)
  },
  ...appsColumns
]

export const appsByActivityCategoryColumns = [
  {
    Header: 'Activity Category',
    accessor: 'activityCategory.name',
    id: buildSortPropertyByType('name', ACTIVITY_CATEGORY)
  },
  ...appsColumns
]

export const appsWithoutCategoryColumns = [
  {
    Header: 'Activity Category',
    accessor: value => {
      const activityCategory = get(value, 'activityCategory')
      return activityCategory === null ? 'None' : activityCategory.name
    },
    id: buildSortPropertyByType('name', ACTIVITY_CATEGORY)
  },
  ...appsColumns
]

export const appsByActivityTagColumns = [
  ...appsColumns,
  {
    Header: 'Status',
    accessor: getStatusValue,
    id: buildSortPropertyByType('status', ACTIVITY),
    pathToProperty: 'status'
  }
]

export const appsByDestinationColumns = [
  {
    Header: 'AP',
    accessor: 'activityProviderName',
    id: buildSortPropertyByType('name', ACTIVITY_PROVIDER)
  },
  {
    Header: 'APP',
    accessor: 'name',
    id: buildSortPropertyByType('name', ACTIVITY)
  },
  {
    Header: 'RMS',
    accessor: 'rmsName',
    id: buildSortPropertyByType('rms_name', ACTIVITY)
  },
  {
    Header: 'City',
    accessor: 'city',
    id: buildSortPropertyByType('city', ACTIVITY)
  },
  {
    Header: 'State/Province',
    accessor: 'state',
    id: buildSortPropertyByType('state', ACTIVITY)
  },
  {
    Header: 'Status',
    accessor: getStatusValue,
    id: buildSortPropertyByType('status', ACTIVITY),
    pathToProperty: 'status'
  }
]

export const appsByAPColumns = [...appsColumns]
