/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLoading } from 'hooks'
import get from 'lodash/get'
import { DeactivationInfo } from 'modules/DelayedDeactivation'
import { canDeactivateProgram } from 'modules/Programs/helpers'
import { ConfirmationModal } from './ConfirmationModal'
import { buildHeaderDeactivationInfo, getDeactivationMode } from './helpers'
import { UPDATE_DEACTIVATED_DATE_MODE } from './types'

export function DelayedDeactivation(props) {
  const {
    authUser,
    programData,
    programDataState,
    deactivateProgramAction,
    deleteProgramDelayDeactivate,
    dataId,
    pmCompany,
    ...rest
  } = props
  const modalApi = useRef()
  const canDeactivate = useMemo(() => canDeactivateProgram(authUser, programData), [
    authUser,
    programData
  ])

  const deactivateInfoMessage = useMemo(() => buildHeaderDeactivationInfo(programData), [
    programData
  ])
  const [, { isLoaded }] = useLoading(programDataState)
  const deactivationInfo = get(programData, 'deactivationDate')
  const deactivationUnassignUnits = get(programData, 'deactivationUnassignUnits')
  const deactivationDate = get(deactivationInfo, 'date')

  const mode = getDeactivationMode(programData)

  const programName = get(programData, 'name')

  const onGetModalApi = useCallback(api => {
    modalApi.current = api
  }, [])

  const onClickHandler = useCallback(() => {
    if (modalApi.current) {
      modalApi.current.toggleModal()
    }
  }, [])

  const onDeactivateHandler = useCallback(
    ({ date, deactivationUnassignUnits: unassignUnits }) => {
      if (dataId && modalApi.current) {
        modalApi.current.closeModal()
        const { date: currentDate } = date
        const contractData = {
          prevDate: deactivationDate,
          currentDate,
          date,
          unassignUnits,
          isInactive: mode === UPDATE_DEACTIVATED_DATE_MODE
        }
        deactivateProgramAction(dataId, contractData)
      }
    },
    [dataId, deactivateProgramAction, deactivationDate, mode]
  )

  const onCancelDeactivateHandler = useCallback(() => {
    if (modalApi.current) {
      modalApi.current.closeModal()
      deleteProgramDelayDeactivate(dataId)
    }
  }, [dataId, deleteProgramDelayDeactivate])

  return (
    <>
      {deactivationDate && isLoaded && (
        <DeactivationInfo
          message={deactivateInfoMessage}
          date={deactivationDate}
          onClick={onClickHandler}
          showIcon={canDeactivate}
          disabled={!canDeactivate}
        />
      )}
      <ConfirmationModal
        pmcData={pmCompany}
        getApi={onGetModalApi}
        name={programName}
        deactivationInfo={deactivationInfo}
        deactivationUnassignUnits={deactivationUnassignUnits}
        onAction={onDeactivateHandler}
        onCancel={onCancelDeactivateHandler}
        mode={mode}
        {...rest}
      />
    </>
  )
}
DelayedDeactivation.propTypes = {
  programData: PropTypes.object,
  programDataState: PropTypes.string.isRequired,
  deactivateProgramAction: PropTypes.func.isRequired,
  deleteProgramDelayDeactivate: PropTypes.func.isRequired,
  dataId: PropTypes.string.isRequired,
  pmCompany: PropTypes.object,
  authUser: PropTypes.object.isRequired
}
DelayedDeactivation.defaultProps = {
  programData: null,
  pmCompany: null
}
