/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, SMALLER_SIZE, VIEW_TITLE } from 'constants/buttonsOptions'
import { useSliderApi } from 'hooks/useSliderApi'
import { UNIT } from 'constants/formCodes'
import get from 'lodash/get'

export function ViewButton(props) {
  const { data } = props
  const unitSlider = useSliderApi(UNIT)

  const onViewClick = useCallback(() => {
    const id = get(data.original, 'id', null)
    const name = get(data.original, 'name', null)

    unitSlider.open(id, { headerProps: { title: `Unit: ${name}` } })
  }, [unitSlider, data.original])

  return (
    <BasicButton size={SMALLER_SIZE} colorSchema={ORANGE_SCHEMA} data={data} onClick={onViewClick}>
      {VIEW_TITLE}
    </BasicButton>
  )
}

ViewButton.propTypes = {
  data: PropTypes.object,
  dataId: PropTypes.object,
  programData: PropTypes.object
}

ViewButton.defaultProps = {
  data: null,
  dataId: null,
  programData: null
}
