/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { ADMIN_LEVEL } from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import { activityTagsTableColumns, buildDefaultButtonColumn } from 'helpers/dataTablesHelpers'
import { EditButton, DeleteButton } from './AdditionalColumns'

export const buildTableColumns = props => {
  const { authUser, removeActivityTagById } = props
  const isAdmin = isActionAllowed(ADMIN_LEVEL, authUser)
  const tableColumns = activityTagsTableColumns.concat({
    ...buildDefaultButtonColumn(),
    Cell: data => <EditButton {...props} data={data} />
  })

  if (isAdmin) {
    tableColumns.push({
      ...buildDefaultButtonColumn({ width: 95 }),
      Cell: data => <DeleteButton {...props} data={data} deleteAction={removeActivityTagById} />
    })
  }

  return tableColumns
}
