/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

// errors keys
export const BEGIN_END_DATE_SEASON_ERROR_KEY = 'BEGIN_END_DATE_SEASON_ERROR_KEY'
export const NULL_SEASONS_ERROR_KEY = 'NULL_SEASONS_ERROR_KEY'
export const NULL_ACTIVITIES_SEASONS_ERROR_KEY = 'NULL_ACTIVITIES_SEASONS_ERROR_KEY'
export const INCORRECT_DATE_SEASON_ERROR_KEY = 'INCORRECT_DATE_SEASON_ERROR_KEY'
export const DATE_INTERSECTIONS_SEASONS_ERROR_KEY = 'DATE_INTERSECTIONS_SEASONS_ERROR_KEY'
