/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  withValidation,
  blankValidate,
  dateValidate,
  dateInFutureValidation
} from 'helpers/formHelpers'
import { TooltipComponent, TOP_DIRECTION } from 'components/Tooltip'
import { FORMAT } from 'constants/date'
import { Form } from 'informed'
import { CalendarField } from 'components/FormComponents'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, LINK_SCHEMA, YELLOW_GREEN_SCHEMA } from 'constants/buttonsOptions'
import { BasicModal } from 'components/Modals'
import {
  UPDATE_DATE_MODE,
  UPDATE_DEACTIVATED_DATE_MODE,
  SET_DEACTIVATION_MODE
} from 'modules/ActivityProducts/DelayedDeactivation/types'
import {
  DATE_INPUT_LABEL,
  DATE_INPUT_PLACEHOLDER,
  DATE_FIELD,
  PICK_TODAY_BUTTON_TEXT,
  DEACTIVATE_TEXT,
  DECLINE_TEXT,
  MODAL_TITLE,
  DEACTIVATION_MESSAGE_START,
  DEACTIVATION_MESSAGE_END,
  UPDATE_TEXT,
  CANCEL_TEXT
} from './types'
import styles from './styles.scss'

const validation = withValidation(blankValidate, dateValidate)
const updateDeactivatedDateValidation = withValidation(validation, dateInFutureValidation)

export function DelayedDeactivationModal(props) {
  const formApi = useRef()
  const { onClose, onCancel, onSubmit, isOpen, initialValues, appName, mode } = props
  const [isDisabledAction, setIsDisabledState] = useState(true)

  const onGetApi = useCallback(api => {
    formApi.current = api
  }, [])

  const onFormChangeHandler = useCallback(formState => {
    const isDisabled = formState.invalid || formState.pristine
    setIsDisabledState(isDisabled)
  }, [])

  const validateDate = value =>
    mode === UPDATE_DEACTIVATED_DATE_MODE
      ? updateDeactivatedDateValidation(value.date)
      : validation(value.date)

  const onClickTodayHandler = useCallback(() => {
    if (formApi.current) {
      formApi.current.setValue(DATE_FIELD, { date: moment().format(FORMAT) })
    }
  }, [])

  const onSubmitHandler = useCallback(
    formValues => {
      if (onSubmit && formValues) {
        const { date } = formValues
        onSubmit(date)
      }
    },
    [onSubmit]
  )

  const onClickDeactivate = useCallback(() => {
    formApi.current.submitForm()
  }, [])

  const onCancelDeactivationHandler = useCallback(() => {
    if (onCancel) {
      onCancel()
    }
  }, [onCancel])

  const modalBodyContent = () => (
    <section className={styles.bodyWrapper}>
      <span>
        {DEACTIVATION_MESSAGE_START}
        <b>{appName}</b>
        {DEACTIVATION_MESSAGE_END}
      </span>
      <Form
        onSubmit={onSubmitHandler}
        className={styles.formWrapper}
        getApi={onGetApi}
        initialValues={initialValues}
        onChange={onFormChangeHandler}
      >
        <CalendarField
          fieldName={DATE_FIELD}
          label={DATE_INPUT_LABEL}
          placeholder={DATE_INPUT_PLACEHOLDER}
          validate={validateDate}
          isRequired
          withHolidays
          fieldProps={{
            renderInner: ({ className, children: Component, error }) => (
              <div className={className}>
                {Component}
                {error && (
                  <div className={styles.errorTooltipWrapper}>
                    <TooltipComponent directionOption={TOP_DIRECTION} contentTooltip={error} />
                  </div>
                )}
                <BasicButton
                  colorSchema={LINK_SCHEMA}
                  className={styles.todayButton}
                  onClick={onClickTodayHandler}
                >
                  {PICK_TODAY_BUTTON_TEXT}
                </BasicButton>
              </div>
            )
          }}
        />
      </Form>
    </section>
  )

  return (
    <BasicModal isOpen={isOpen} title={MODAL_TITLE} onClose={onClose} renderBody={modalBodyContent}>
      {mode === UPDATE_DATE_MODE && (
        <>
          <BasicButton
            colorSchema={ORANGE_SCHEMA}
            onClick={onClickDeactivate}
            disabled={isDisabledAction}
          >
            {UPDATE_TEXT}
          </BasicButton>
          <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onCancelDeactivationHandler}>
            {CANCEL_TEXT}
          </BasicButton>
        </>
      )}
      {mode === SET_DEACTIVATION_MODE && (
        <>
          <BasicButton
            colorSchema={ORANGE_SCHEMA}
            onClick={onClickDeactivate}
            disabled={isDisabledAction}
          >
            {DEACTIVATE_TEXT}
          </BasicButton>
          <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
            {DECLINE_TEXT}
          </BasicButton>
        </>
      )}
      {mode === UPDATE_DEACTIVATED_DATE_MODE && (
        <>
          <BasicButton
            colorSchema={ORANGE_SCHEMA}
            onClick={onClickDeactivate}
            disabled={isDisabledAction}
          >
            {UPDATE_TEXT}
          </BasicButton>
          <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
            {DECLINE_TEXT}
          </BasicButton>
        </>
      )}
    </BasicModal>
  )
}

DelayedDeactivationModal.propTypes = {
  appName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    date: PropTypes.string
  }),
  className: PropTypes.string,
  children: PropTypes.element,
  error: PropTypes.string,
  mode: PropTypes.string.isRequired
}

DelayedDeactivationModal.defaultProps = {
  initialValues: {},
  className: '',
  children: null,
  error: null
}
