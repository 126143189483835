/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { compose } from 'redux'
import { withFormContext } from 'components/FormComponents/helpers'
import { asField } from 'informed'

import { NumberFieldInput as NumberFieldInputComponent } from './NumberFieldInput'

export const NumberFieldInput = compose(
  withFormContext,
  asField
)(NumberFieldInputComponent)
