/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { ACTIVITY_URL } from 'endpoints'

export const buildTicketTypeURL = (activityId = '') => `${ACTIVITY_URL}/${activityId}/tickets/types`
export const buildTicketTypeURLById = (activityId = '', id = '') =>
  `${ACTIVITY_URL}/${activityId}/tickets/types/${id}`

export const buildOrderTicketTypesURL = (activityId, groupId) =>
  `${ACTIVITY_URL}/${activityId}/tickets/types/${groupId}/ordering`
