import {
  TOKEN_EXPIRED_ERROR_KEY,
  TOKEN_INVALID_ERROR_KEY,
  TOKEN_NOT_EXISTS_ERROR_KEY,
  EXPIRED_PAGE_TEXT,
  INVALID_PAGE_TEXT,
} from "./types";

export const buildErrorMessage = (errorType) => {
  switch (errorType) {
    case TOKEN_EXPIRED_ERROR_KEY:
      return EXPIRED_PAGE_TEXT
    case TOKEN_INVALID_ERROR_KEY:
    case TOKEN_NOT_EXISTS_ERROR_KEY:
    default:
      return INVALID_PAGE_TEXT
  }
}