/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withDiscountsPermissions } from 'containers/Discounts'
import { getActivityFormState, actions } from 'connectors/reducers/deals/dealFormActivities'
import { ActivityForm as ActivityFormComponent } from './ActivityForm'

function mapStateToProps(state, props) {
  const { dealId, activityId } = props
  const formState = getActivityFormState(state, dealId, activityId)
  return {
    ...formState
  }
}

function mapDispatchToProps(dispatch, props) {
  const { dealId, activityId } = props
  return bindActionCreators(
    {
      ...actions,
      resetState: actions.resetState.bind(null, dealId, activityId),
      updateActivityType: actions.updateActivityType.bind(null, dealId, activityId),
      deleteActivity: actions.deleteActivity.bind(null, dealId, activityId),
      updateActivity: actions.updateActivity.bind(null, dealId, activityId),
      updateEditMode: actions.updateEditMode.bind(null, dealId, activityId)
    },
    dispatch
  )
}

export const ActivityForm = compose(
  withDiscountsPermissions,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ActivityFormComponent)
