/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import helpers from './helpers'

const {
  REQUEST_DATA,
  RESPONSE_DATA,
  RESPONSE_ERROR_DATA,
  RESET_DATA_STATE,
  REQUEST_APP_ONE,
  RESPONSE_APP_ONE,
  RESPONSE_APP_ONE_ERROR,
  REQUEST_EXPORT,
  RESPONSE_ERROR_EXPORT,
  RESPONSE_EXPORT
} = helpers

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  data: [],
  itemsCount: 0,
  itemsPerPage: 10,
  pageNumber: 1,
  appDataState: DATA_STATE_NOT_REQUESTED,
  appData: null,
  exportDataState: DATA_STATE_NOT_REQUESTED
}

const programsByAppList = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING,
        data: []
      }
    case RESPONSE_DATA:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        data: action.payload.data,
        ...action.payload.pagination
      }
    case RESPONSE_ERROR_DATA:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case RESET_DATA_STATE:
      return {
        ...initialState
      }
    case REQUEST_APP_ONE:
      return {
        ...state,
        appDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_APP_ONE:
      return {
        ...state,
        appDataState: DATA_STATE_RECIEVED,
        appData: action.payload
      }
    case RESPONSE_APP_ONE_ERROR:
      return {
        ...state,
        appDataState: DATA_STATE_ERROR
      }
    case REQUEST_EXPORT:
      return {
        ...state,
        exportDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_EXPORT:
      return {
        ...state,
        exportDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_ERROR_EXPORT:
      return {
        ...state,
        exportDataState: DATA_STATE_ERROR
      }
    default:
      return state
  }
}

export default filterActions(programsByAppList, [
  REQUEST_DATA,
  RESPONSE_DATA,
  RESPONSE_ERROR_DATA,
  RESET_DATA_STATE,
  REQUEST_APP_ONE,
  RESPONSE_APP_ONE,
  RESPONSE_APP_ONE_ERROR,
  RESPONSE_EXPORT,
  RESPONSE_ERROR_EXPORT,
  REQUEST_EXPORT
])
