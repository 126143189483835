/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useState, useEffect } from 'react'
import { DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { usePrevious } from 'hooks/hooks'
import {
  ALL_PROGRAMS_OPTION,
  ALL_PROGRAMS_OPTION_KEY,
  DEFAULT_PROGRAMS_DROPDOWN_OPTION
} from './types'
import {
  mapValuesToOptions,
  checkDublicateProgram,
  getProgramOptionByAssigmentType
} from './helpers'

export const useDiscountProgramsPresenter = props => {
  const {
    api: { setProgramValue, setProgramOptionValue },
    onGetData,
    programDataState,
    programData,
    offerType,
    activity,
    disabled,
    discountId,
    program: discountProgram
  } = props
  const [programs, setPrograms] = useState(discountProgram.items)
  const options = mapValuesToOptions(programData)
  const activityId = activity ? activity.id : null
  const previousActivityId = usePrevious(activityId)
  const previousOfferType = usePrevious(offerType)

  const updatePrograms = value => {
    setPrograms(value)
    setProgramValue(value)
  }

  const addProgram = program => {
    if (program.id === ALL_PROGRAMS_OPTION_KEY) {
      updatePrograms([])
      return
    }
    const isDublicate = checkDublicateProgram(program, programs)
    const newPrograms = isDublicate ? programs : [...programs, program]
    updatePrograms(newPrograms)
    setProgramOptionValue(null)
  }

  const removeProgram = program => {
    const newPrograms = programs.filter(p => p.id !== program.id)
    updatePrograms(newPrograms)
    setProgramOptionValue(newPrograms.length ? null : ALL_PROGRAMS_OPTION)
  }

  useEffect(() => {
    if (
      (previousActivityId && activityId !== previousActivityId) ||
      (previousOfferType && offerType !== previousOfferType)
    ) {
      onGetData(activityId, { offerTypes: [offerType] }, DEFAULT_PROGRAMS_DROPDOWN_OPTION)
      updatePrograms([])
      setProgramOptionValue(ALL_PROGRAMS_OPTION)
      return
    }
    if (activityId && offerType) {
      onGetData(activityId, { offerTypes: [offerType] }, DEFAULT_PROGRAMS_DROPDOWN_OPTION)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerType, activityId])

  useEffect(() => {
    if (!discountId) {
      setProgramOptionValue(ALL_PROGRAMS_OPTION)
      return
    }
    setProgramOptionValue(getProgramOptionByAssigmentType(discountProgram.assignmentType))
    updatePrograms(discountProgram.items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountId])

  return {
    programs,
    addProgram,
    removeProgram,
    options,
    disabled: disabled || programDataState === DATA_STATE_REQUESTING,
    hasPrograms: programs && Boolean(programs.length)
  }
}
