/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ModalRemoval from 'modules/ModalRemoval'

export function withRemovalModal(Component, modalProps = {}) {
  function WithRemovalModal(props) {
    const [showModal, setShowModalState] = useState(false)
    const { onClick, ...rest } = props
    const onShowModal = () => {
      setShowModalState(true)
    }

    const onCloseModal = () => {
      setShowModalState(false)
    }

    const onDeleteHandler = () => {
      onClick()
      onCloseModal()
    }

    return (
      <>
        <Component onClick={onShowModal} {...rest} />
        <ModalRemoval
          {...modalProps}
          actionCallback={onDeleteHandler}
          cancelCallback={onCloseModal}
          originalData={showModal}
        />
      </>
    )
  }

  const componentName = Component.displayName || Component.name || 'Component'

  WithRemovalModal.displayName = `withRemovalModal(${componentName})`

  WithRemovalModal.propTypes = {
    onClick: PropTypes.func.isRequired
  }

  return WithRemovalModal
}
