/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import Result from 'folktale/result'

import { safeGetStorage, safeSetStorage } from 'helpers/localstorage'
import { defineUserPermissions } from 'helpers/permissionHelper'
import { validateAuthUser } from './validateHelpers'
import { APPLICATION_LOCALSTORAGE_NAME, APP_VERSION } from './types'

export const updateUserData = (user, accountData) => {
  if (!user) {
    safeSetStorage(window.localStorage)(APPLICATION_LOCALSTORAGE_NAME)({
      authUser: null
    })

    return null
  }

  const permission = user.roles.map(item => item.name)

  const authUserData = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.identityId,
    isLoggedIn: true,
    permission,
    roles: user.roles,
    id: user.id,
    accountData,
    authKey: 'deprecated' // TODO: deprecated (left for compatibility)
  }

  const authUser = defineUserPermissions(authUserData)
  safeSetStorage(window.localStorage)(APPLICATION_LOCALSTORAGE_NAME)({
    authUser
  })
  // eslint-disable-next-line no-undef
  safeSetStorage(window.localStorage)(APP_VERSION)(uiAppVersion)

  return { ...authUser }
}

// Authenticate user on navigation/state change
export const savedAuthState = () => {
  const version = safeGetStorage(window.localStorage)(APP_VERSION)
  // eslint-disable-next-line no-undef
  if (version !== uiAppVersion) {
    return null
  }
  const localStoreAuth = safeGetStorage(window.localStorage)()
  const result = Result.Error.hasInstance(localStoreAuth) ? null : localStoreAuth.value.authUser
  return validateAuthUser(result)
}
