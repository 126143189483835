/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { ACCOUNT } from 'helpers/types/dataTablesTypes'
import { buildSortPropertyByType, getRoleName } from 'helpers/tableHelpers'
import { getStatusValue } from 'helpers/dataTablesHelpers/commonHelpers'

import { ADMIN_GROUP_TYPE, NEXE_GROUP_TYPE } from 'constants/rolesTypes'

export const accountTableColumns = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    id: buildSortPropertyByType('firstName', ACCOUNT)
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    id: buildSortPropertyByType('lastName', ACCOUNT)
  },
  {
    Header: 'Identity Id',
    accessor: 'identityId',
    id: buildSortPropertyByType('identityId', ACCOUNT)
  },
  {
    Header: 'Admin Role',
    accessor: option => getRoleName(option.roles, ADMIN_GROUP_TYPE),
    id: buildSortPropertyByType('pieRole', ACCOUNT)
  },
  {
    Header: 'Resell Role',
    accessor: option => getRoleName(option.roles, NEXE_GROUP_TYPE),
    id: buildSortPropertyByType('resellRole', ACCOUNT)
  },
  {
    Header: 'Status',
    accessor: getStatusValue,
    id: buildSortPropertyByType('status', ACCOUNT)
  }
]
