/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const TITLE = 'Warning!'
export const MESSAGE = 'Are you sure you want to deactivate'
export const MAKE_INACTIVE_MESSAGE =
  'The Activity Product will be removed from the following programs:'
export const MESSAGE_NO_PROGRAMS = 'The Activity Product will not be removed from any programs'

export const WARNING_DEACTIVATION_MESSAGE_START =
  'The Deactivation Date is set on the Activity Provider '
export const WARNING_DEACTIVATION_MESSAGE_END = ', Do you want to view this Activity Provider?'

export const DEACTIVATED_PROGRAM_EXTRA_MESSAGE = ' - The program will be deactivated'

export const STEPS_COUNT = 3
