/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import { OK_TITLE, ORANGE_SCHEMA } from 'constants/buttonsOptions'

import { TITLE, WARNING_MESSAGE } from './types'

import styles from './styles.scss'

export function CampaignsModal(props) {
  const { isOpen, onClose, data } = props

  const onHideAction = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <BasicModal
      isOpen={isOpen}
      title={TITLE}
      onClose={onClose}
      renderBody={() => (
        <section className={styles.wrapper}>
          <div className={styles.messageWrapper}>
            {WARNING_MESSAGE}
            <ul className={styles.campaignsList}>
              {data.map(item => (
                <li key={item.id}>
                  <span className={styles.campaignsItem}>{item.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </section>
      )}
    >
      <BasicButton
        colorSchema={ORANGE_SCHEMA}
        className={styles.centerButton}
        onClick={onHideAction}
      >
        {OK_TITLE}
      </BasicButton>
    </BasicModal>
  )
}

CampaignsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
}
