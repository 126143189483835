/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const SELECT_PROGRAM_LABEL = 'Select Program'
export const SELECT_PROGRAM_PLACEHOLDER = 'Select Program'

export const PROGRAM_FIELD_NAME = 'program'
export const PROGRAM_OPTION_FIELD_NAME = 'programOption'

export const NONE_OPTION_KEY = 'NONE'
export const NONE_OPTION_LABEL = 'None'
export const ALL_PROGRAMS_OPTION_KEY = 'ALL'
export const ALL_PROGRAMS_OPTION_LABEL = 'All Programs'

export const ASSIGMENT_TYPES = {
  ALL: 'ALL',
  NONE: 'NONE',
  SPECIFIC: 'SPECIFIC'
}

export const NONE_OPTION = {
  name: NONE_OPTION_LABEL,
  id: NONE_OPTION_KEY
}

export const ALL_PROGRAMS_OPTION = {
  name: ALL_PROGRAMS_OPTION_LABEL,
  id: ALL_PROGRAMS_OPTION_KEY
}

export const DEFAULT_OPTIONS = [
  {
    key: NONE_OPTION_KEY,
    label: NONE_OPTION_LABEL,
    value: NONE_OPTION,
    hidden: true
  },
  {
    key: ALL_PROGRAMS_OPTION_KEY,
    label: ALL_PROGRAMS_OPTION_LABEL,
    value: ALL_PROGRAMS_OPTION
  }
]

export const DEFAULT_PROGRAMS_DROPDOWN_OPTION = {
  itemsPerPage: 0
}
