/*
* COPYRIGHT:     Copyright © 2018 Xplorie LLC
* Warning:       This product is protected by United States and international copyright laws.
*                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
*/
const FORM_NAME = 'PROGRAM_FORM_NAME'
const PROGRAM_DRAFT = 'DRAFT_FOR_PROGRAM_CREATING'
const PROGRAM_APPROVAL = 'PROGRAM_APPROVAL'

export default {
  FORM_NAME,
  PROGRAM_DRAFT,
  PROGRAM_APPROVAL
}
