/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const CATEGORY_NOT_VALID_MESSAGE =
  'Activity Category cannot be deleted because it is assigned to the Activity Provider or AP Product'
export const RELATED_AP = 'View related Activity Providers'
export const RELATED_APP = 'View related AP Products'
