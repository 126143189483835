/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { NAME, GET_PREBOOK_INFO } = types

const REQUEST_FORM = `${REQUEST_TYPE}_${NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${NAME}`

const REQUEST_GET_PREBOOK_INFO = `${REQUEST_TYPE}_${GET_PREBOOK_INFO}`
const RESPONSE_GET_PREBOOK_INFO = `${RESPONSE_TYPE}_${GET_PREBOOK_INFO}`
const RESPONSE_GET_PREBOOK_INFO_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_PREBOOK_INFO}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

export default {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_PREBOOK_INFO,
  RESPONSE_GET_PREBOOK_INFO,
  RESPONSE_GET_PREBOOK_INFO_ERROR,
  RESET_DATA_STATE
}
