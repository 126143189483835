/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildURLForGettingEntityByIdExtended } from 'services/http/helpers'
import { PMCS_URL } from 'endpoints'

export const createPMC = contractData =>
  httpWrapper({
    url: PMCS_URL,
    method: 'post',
    data: contractData
  })

export const updatePMC = contractData =>
  httpWrapper({
    url: PMCS_URL,
    method: 'put',
    data: contractData
  })

export const getPMCById = (id, params) =>
  httpWrapper({
    url: buildURLForGettingEntityByIdExtended(PMCS_URL, id),
    method: 'get',
    params
  })

export const deactivatePMCById = (id, data) =>
  httpWrapper({
    url: `${PMCS_URL}/${id}/deactivation`,
    method: 'put',
    data
  })

export const updateDeactivationPMCById = (id, data) =>
  httpWrapper({
    url: `${PMCS_URL}/${id}/deactivation/update`,
    method: 'put',
    data
  })

export const removePMCDeactivatingById = (id, params) =>
  httpWrapper({
    url: `${PMCS_URL}/${id}/deactivation`,
    method: 'delete',
    params
  })

export const activatePMCById = (id, params) =>
  httpWrapper({
    url: `${PMCS_URL}/${id}/activate`,
    method: 'put',
    params
  })
