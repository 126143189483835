/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { LOCATIONS_TYPES_RELEVANCE } from 'constants/locationsTypes'

export const buildTitleForAddChildForm = (parrentName = '') => `Add Children for ${parrentName}`

export const canLinkChildLocations = (type, canUpdate = false) =>
  canUpdate &&
  LOCATIONS_TYPES_RELEVANCE[type] > Math.min(...Object.values(LOCATIONS_TYPES_RELEVANCE))
