/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Nav } from 'modules/Nav'

const ContentWithNavComponent = props => {
  const { children, Navigation } = props
  return (
    <div className="content-wrapper">
      <Navigation />
      {children}
    </div>
  )
}

ContentWithNavComponent.propTypes = {
  children: PropTypes.node.isRequired,
  Navigation: PropTypes.node
}
ContentWithNavComponent.defaultProps = {
  Navigation: Nav
}
export { ContentWithNavComponent }
