/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useMemo, useState, useCallback, Fragment } from 'react'
import PropTypes from 'prop-types'
import { CollapseBox } from '@xplorie/ui-commons'
import classNames from 'classnames'

import { PROGRAM_STATUS_EVENT } from 'modules/Programs/ProgramHistory/types'
import styles from './styles.scss'
import { ProgramHistoryContent } from '../ProgramHistoryContent'
import { getDataForView } from './helpers'
import { isCreateAction, isDeleteAction } from '../ProgramHistoryContent/helpers'

export function ProgramHistoryItem(props) {
  const { item } = props
  const [isOpen, setIsOpen] = useState(false)

  const {
    action,
    actionLabel,
    headerLabels,
    dateTime,
    eventClassName,
    eventLabel,
    vacationSpecialistFullName
  } = useMemo(() => getDataForView(item), [item])
  const title = useMemo(
    () => (
      <Fragment>
        <div className={styles.title}>
          <b>
            {eventLabel}
            {actionLabel && (
              <span
                className={classNames({
                  [styles.added]: isCreateAction(action),
                  [styles.removed]: isDeleteAction(action)
                })}
              >
                {actionLabel}
              </span>
            )}
          </b>
          <span className={!actionLabel ? styles.ml5 : ''}>by</span>
          <span className={styles.userName}>{vacationSpecialistFullName}</span>,
          <span className={styles.dateTime}>{dateTime}</span>
        </div>
        {headerLabels.map(({ label, value }) => (
          <p key={label} className={styles.title}>
            <b>{label}</b>
            <span className={styles.titleValue}>{value}</span>
          </p>
        ))}
      </Fragment>
    ),
    [eventLabel, headerLabels, dateTime, vacationSpecialistFullName, actionLabel, action]
  )

  const onExpand = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  if (item.event === PROGRAM_STATUS_EVENT) {
    return (
      <div
        key={item.id}
        className={classNames(styles.wrapper, styles.simpleBoxWrapper, eventClassName)}
      >
        {title}
      </div>
    )
  }

  return (
    <CollapseBox
      className={classNames(styles.wrapper, eventClassName)}
      title={title}
      canExpand
      isOpen
      onExpand={onExpand}
    >
      <ProgramHistoryContent item={item} />
    </CollapseBox>
  )
}

ProgramHistoryItem.propTypes = {
  item: PropTypes.object.isRequired
}
