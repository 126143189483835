import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import { withFormContext } from 'components/FormComponents/helpers/withFormContext'
import { PencilIcon, TrashIcon } from 'icons'
import styles from './styles'
import { TimePeriodsPopover } from '../TimePeriodsPopover'
import { WEEK_DAYS_FIELD } from './types'

export const TimePeriodsListComponent = ({
  timePeriods,
  onEditTimePeriod,
  onRemoveTimePeriod,
  canSeeRemoveTimePeriod
}) => {
  const canSeeRemove = canSeeRemoveTimePeriod()

  const renderTimePeriod = (timePeriod, key) => {
    if (key === WEEK_DAYS_FIELD) {
      return null
    }

    return (
      <div key={key} className={styles.timePeriodItem}>
        <span className={styles.timePeriodLabel}>
          {`${timePeriod.startTime} - ${timePeriod.endTime}`}
        </span>
        <TimePeriodsPopover onSubmit={onEditTimePeriod(key)} initialValues={timePeriod} isEdit>
          <PencilIcon width="1.1rem" height="1.1rem" />
        </TimePeriodsPopover>
        {canSeeRemove && (
          <TrashIcon onClick={onRemoveTimePeriod(key)} width="1.1rem" height="1.1rem" />
        )}
      </div>
    )
  }

  return <div className={styles.timePeriodList}>{map(timePeriods, renderTimePeriod)}</div>
}

TimePeriodsListComponent.propTypes = {
  timePeriods: PropTypes.object.isRequired,
  onEditTimePeriod: PropTypes.func.isRequired,
  onRemoveTimePeriod: PropTypes.func.isRequired,
  canSeeRemoveTimePeriod: PropTypes.func.isRequired
}

export const TimePeriodsList = withFormContext(TimePeriodsListComponent)
