/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Input } from '@xplorie/ui-commons'
import styles from './ReactTable.scss'
import {
  DEFAULT_PAGE_SIZE_OPTIONS,
  PREV_BUTTON_TEXT,
  NEXT_BUTTON_TEXT,
  PAGE_SIZE_OPTION_POSTFIX,
  PAGE_JUMP_PREFIX,
  PAGE_JUMP_POSTFIX
} from './types'

export function Pagination(props) {
  const {
    canNextPage,
    canPreviousPage,
    pageIndex,
    pageSize,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    pageCount
  } = props

  const onNextPage = useCallback(() => {
    if (canNextPage) {
      nextPage()
    }
  }, [canNextPage, nextPage])

  const onPrevPage = useCallback(() => {
    if (canPreviousPage) {
      previousPage()
    }
  }, [canPreviousPage, previousPage])

  const onSetPageSize = useCallback(
    event => {
      setPageSize(Number(event.target.value))
    },
    [setPageSize]
  )

  const onChangePage = useCallback(
    event => {
      const { value } = event.target
      const page = value ? Number(value) - 1 : 1
      if (page <= pageCount) {
        gotoPage(page)
      }
    },
    [gotoPage, pageCount]
  )

  return (
    <div className={styles.paginationWrapper}>
      <div className={styles.previous}>
        <button className={styles.button} disabled={!canPreviousPage} onClick={onPrevPage}>
          {PREV_BUTTON_TEXT}
        </button>
      </div>
      <div className={styles.center}>
        <span className={styles.pageInfo}>
          {PAGE_JUMP_PREFIX}
          <Input
            className={styles.pageInput}
            type="number"
            min={1}
            max={pageSize}
            value={pageIndex + 1}
            onChange={onChangePage}
          />
          {PAGE_JUMP_POSTFIX} <span>{pageCount}</span>
        </span>
        <span className={styles.selectWrapper}>
          <select className={styles.select} value={pageSize} onChange={onSetPageSize}>
            {DEFAULT_PAGE_SIZE_OPTIONS.map(option => (
              <option key={option} value={option}>
                {option} {PAGE_SIZE_OPTION_POSTFIX}
              </option>
            ))}
          </select>
        </span>
      </div>
      <div className={styles.next}>
        <button className={styles.button} disabled={!canNextPage} onClick={onNextPage}>
          {NEXT_BUTTON_TEXT}
        </button>
      </div>
    </div>
  )
}

Pagination.propTypes = {
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  gotoPage: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired
}
