/*
* COPYRIGHT:     Copyright © 2018 Xplorie LLC
* Warning:       This product is protected by United States and international copyright laws.
*                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
*/

import React from 'react'
import PropTypes from 'prop-types'

export const BoxNestedFields = props => {
  const { children, label, className, classNameWrapper } = props
  return (
    <div className={`box-fields-wrapper ${classNameWrapper}`}>
      {label && <span className="label is-size-7">{label}</span>}
      <div className={`box ${className}`}>{children}</div>
    </div>
  )
}
BoxNestedFields.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  classNameWrapper: PropTypes.string
}
BoxNestedFields.defaultProps = {
  label: null,
  className: '',
  classNameWrapper: ''
}
