/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import isBoolean from 'lodash/isBoolean'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import { buildSortPropertyByType } from 'helpers/tableHelpers'
import * as searchTypes from 'constants/searchTypes'
import {
  AND_OPERATOR,
  OR_OPERATOR,
  LIKE_START_WITH_MODE,
  LIKE_END_WITH_MODE,
  NULL_MODE,
  STRICT_MODE
} from 'constants/search'

const SEARCH_TYPES_VALUES = Object.values(searchTypes)
const OPERATORS = [AND_OPERATOR, OR_OPERATOR]
const MODES = [LIKE_START_WITH_MODE, LIKE_END_WITH_MODE, NULL_MODE, STRICT_MODE]

/**
 * Building payload for column search.
 * @param {Object} parameters - payload parameters
 * @param {string} parameters.entity - search entity type (constants/searchTypes)
 * @param {string} parameters.field - field name which includes in entity
 * @param {boolean} parameters.ignore - should we ignore that column
 * @param {string} parameters.operator - which mode we use (OR/AND) (constants/search)
 * @param {string} parameters.mode - which mode we use (like start with / like end with)
 * @returns {Object} payload for column search
 */

export function buildSearchColumn(parameters = {}) {
  const payload = {}
  const { entity, field, keyword, ignore, operator, mode, groupOperator } = parameters
  if (!isString(entity)) {
    throw new Error(`[entity] param should have String type but now it ${entity}`)
  }
  if (!isString(field)) {
    throw new Error(`[field] param should have String type but now it ${field}`)
  }
  if (SEARCH_TYPES_VALUES.every(value => value !== entity)) {
    throw new Error(
      `[entity] param should includes to Search Types (${SEARCH_TYPES_VALUES.join()}), but entity=${entity}`
    )
  }
  if (!isUndefined(operator) && OPERATORS.every(value => value !== operator)) {
    throw new Error(
      `[operator] param should includes to Search Operators (${OPERATORS.join()}), but operator=${operator}`
    )
  }
  if (!isUndefined(ignore) && !isBoolean(ignore)) {
    throw new Error(`[ignore] param should have Boolean Type`)
  }
  if (!isUndefined(mode) && MODES.every(value => value !== mode)) {
    throw new Error(
      `[mode] param should includes to Search Modes (${MODES.join()}), but operator=${mode}`
    )
  }
  payload.column = buildSortPropertyByType(field, entity)
  payload.keyword = keyword
  if (!isUndefined(ignore)) {
    payload.ignore = ignore
  }
  if (!isUndefined(operator)) {
    payload.operator = operator
  }
  if (!isUndefined(mode)) {
    payload.mode = mode
  }
  if (!isUndefined(groupOperator)) {
    payload.groupOperator = groupOperator
  }
  return payload
}

export * from 'constants/searchTypes'
export * from 'constants/search'
