import { useState } from 'react'
import moment from 'moment'
import { MMMM_DD_YYY } from 'constants/date'

export function useClosurePopoverPresenter(wrapperRef) {
  const [closurePopover, setClosurePopoverState] = useState(null)

  const handleCloseClosurePopover = () => {
    setClosurePopoverState(null)
  }

  const handleEventClick = info => {
    if (info.jsEvent.ctrlKey || info.jsEvent.metaKey) {
      return
    }
    /* eslint no-underscore-dangle: 0 */
    const closureItem = info.event._def.extendedProps
    const item = {
      time: closureItem.timePeriod
        ? `${closureItem.timePeriod.startTime} - ${closureItem.timePeriod.endTime}`
        : 'Full Day,',
      reason: closureItem.details.reason,
      note: closureItem.details.note,
      type: closureItem.type
    }
    const wrapperRect = wrapperRef.current.getBoundingClientRect()
    const dayRect = info.el.getBoundingClientRect()
    const y = dayRect.top + info.jsEvent.offsetY - wrapperRect.top
    const x = dayRect.left + info.jsEvent.offsetX - wrapperRect.left

    setClosurePopoverState({
      x,
      y,
      item,
      title: moment(new Date(closureItem.day)).format(MMMM_DD_YYY)
    })
  }

  return {
    closurePopover,
    handleCloseClosurePopover,
    handleEventClick
  }
}
