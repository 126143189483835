/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { ACTIVITY } from 'constants/searchTypes'

const FORM_NAME = 'SEASON_FORM'
const ACTIVITY_NAME = 'ACTIVITY_NAME'
const ACTIVITY_LIST_NAME = 'ACTIVITY_LIST_NAME'
const DEFAULT_PAGE_NUMBER = 1
const DEFAULT_ITEMS_PER_PAGE = 10
const ENTITY_TYPE = ACTIVITY

export default {
  FORM_NAME,
  ACTIVITY_NAME,
  ACTIVITY_LIST_NAME,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ITEMS_PER_PAGE,
  ENTITY_TYPE
}

export {
  FORM_NAME,
  ACTIVITY_NAME,
  ACTIVITY_LIST_NAME,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ITEMS_PER_PAGE,
  ENTITY_TYPE
}
