/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_ERROR,
  DATA_STATE_RECIEVED
} from 'helpers/actionHelpers'
import { CalendarInput } from 'modules/CalendarInput'
import { FORMAT } from 'constants/date'
import { useUpdateSomeDataState } from 'hooks'
import { OverlayComponent } from './OverlayComponent'
import { modifiersStyles } from './types'
import { processHolidays, findHolidayDescription, processShownValue } from './helpers'

export function CalendarWithHolidays(props) {
  const {
    onChange,
    format,
    shownFormat,
    value: fieldValue,
    dataState,
    getHolidaysByYear,
    years,
    overlayProps,
    ...rest
  } = props

  const originalDate = get(fieldValue, 'date')
  const fieldValueDate = moment(originalDate, format)
  const { enableHoliday = true } = overlayProps
  const modifiers = {
    holiday: processHolidays(years)
  }

  const getHolidaysListByYear = year => {
    if (
      !years[year] ||
      ((years[year] && years[year].dataState === DATA_STATE_ERROR) ||
        years[year].dataState === DATA_STATE_NOT_REQUESTED)
    ) {
      getHolidaysByYear({ year })
    }
  }

  const onMonthChangeHandler = day => {
    const newYearValue = moment(day).year()
    getHolidaysListByYear(newYearValue)
  }

  const onFormatDateHandler = date => {
    const holiday = findHolidayDescription(years, date) || {}
    return holiday.name && enableHoliday ? holiday.name : moment(date).format(shownFormat)
  }

  const onDayPickerShowHandler = () => {
    if (fieldValue && fieldValueDate.isValid()) {
      getHolidaysListByYear(fieldValueDate.year())
    } else {
      getHolidaysListByYear(moment().year())
    }
  }

  const onChangedHandler = (value, date, modif) => {
    const options = { date }
    if (modif && modif.holiday) {
      const holiday = findHolidayDescription(years, date)
      options.name = holiday.name || null
      options.description = holiday.description
    }
    if (date && moment(date).isValid()) {
      getHolidaysListByYear(moment(date).year())
    }
    onChange({ date: value, description: options.name })
  }

  useUpdateSomeDataState(
    () => {
      if (fieldValueDate.isValid()) {
        if (enableHoliday) {
          const { name = null } = findHolidayDescription(years, fieldValueDate.toDate())
          if (name) {
            onChange({ date: originalDate, description: name })
          }
        }
      }
    },
    [dataState],
    DATA_STATE_RECIEVED
  )

  const shownValue = processShownValue(fieldValue, {
    enableHoliday,
    dateFormat: format,
    shownFormat
  })

  return (
    <CalendarInput
      additionalProps={{
        modifiers,
        modifiersStyles,
        onMonthChange: onMonthChangeHandler,
        overlayProps
      }}
      overlayComponent={OverlayComponent}
      onChange={onChangedHandler}
      format={format}
      shownFormat={shownFormat}
      value={shownValue}
      originalDate={originalDate}
      onDayPickerShow={onDayPickerShowHandler}
      formatDate={onFormatDateHandler}
      {...rest}
    />
  )
}

CalendarWithHolidays.propTypes = {
  format: PropTypes.string,
  shownFormat: PropTypes.string,
  overlayProps: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
  dataState: PropTypes.string,
  getHolidaysByYear: PropTypes.func,
  years: PropTypes.string
}

CalendarWithHolidays.defaultProps = {
  format: FORMAT,
  shownFormat: FORMAT,
  overlayProps: {},
  onChange: () => {},
  value: '',
  dataState: '',
  getHolidaysByYear: () => {},
  years: ''
}
