/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

export const TITLE_PROFILE_DATA_AREA = 'Profile Data'
export const TITLE_PAGE = 'Profile'
export const UPDATE_PROFILE_BUTTON = 'Update Profile'
export const UPDATE_XPLORIE_BUTTON = 'Update Xplorie.com'
export const CHECK_TICKET_INVENTORY_BUTTON = 'Check Ticket Inventory'
export const RESET_COMMUNICATION_BUTTON = 'Reset Communications'
