/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole
 *                or in part, is prohibited.
 */

import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import get from 'lodash/get'
import { DotsButton, Tag } from '@xplorie/ui-commons'
import { EntityBox } from 'components/FormComponents'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { Column, Field } from 'components/FormComponents/Boxes/EntityBox'
import { useUpdateSomeDataState, usePrevious } from 'hooks'
import { LIGHT_RED_SCHEMA, LIGHT_BLUE_SCHEMA } from 'constants/colorSchemas'
import { EDIT_TITLE, VIEW_TITLE, DELETE_TITLE } from 'constants/buttonsOptions'

import {
  LABEL_START_DATE,
  LABEL_END_DATE,
  LABEL_FEE,
  SEASON_LIST_ERRORS,
  CLONE_TEXT
} from './types'
import { getXplorieFee, isInvalidSeason, validateSeason, prepareActivities } from './helpers'
import styles from './SeasonItem.scss'

export function SeasonItem({
  value,
  getApi,
  onView,
  onUpdate,
  onDelete,
  canUpdate,
  canDelete,
  canView,
  canClone,
  onClone,
  onChange,
  seasonRemoveState,
  deleteSeasonById,
  responseData,
  programType
}) {
  const [errors, setErrorsState] = useState({})

  const prevGetApi = usePrevious(getApi)
  const prevValue = usePrevious(value)
  const prevProgramType = usePrevious(programType)

  const startDate = get(value, 'startDate')
  const endDate = get(value, 'endDate')
  const activities = get(value, 'activities', [])
  const valueXplorieFee = get(value, 'xplorieData.xplorieFee')
  const getXplorieFeeContent = getXplorieFee(valueXplorieFee)

  const groupedActivities = useMemo(() => prepareActivities(activities), [activities])
  const isValid = useMemo(() => !isInvalidSeason(activities, endDate, errors, programType), [
    activities,
    endDate,
    errors,
    programType
  ])

  const onChangeSeason = useCallback(
    type => {
      if (onChange) {
        onChange(type)
      }
    },
    [onChange]
  )

  const onCloneHandler = useCallback(() => {
    if (value.id) {
      onClone(value.id)
    }
  }, [onClone, value])

  const onDeleteHandler = useCallback(() => {
    if (value.id) {
      deleteSeasonById(value.id)
    } else {
      onDelete()
    }
  }, [deleteSeasonById, onDelete, value])

  const onGetState = useCallback(() => ({ value, errors }), [errors, value])
  const onValidate = useCallback(
    type => {
      const validationErrors = validateSeason(value, type)
      setErrorsState(validationErrors)
      return validationErrors
    },
    [value]
  )

  useEffect(() => {
    if (prevValue !== value || prevProgramType !== programType) {
      onValidate(programType)
      onChangeSeason(programType)
    }
  }, [onChangeSeason, onValidate, prevProgramType, prevValue, programType, value])

  useEffect(() => {
    if (prevGetApi !== getApi || prevProgramType !== programType) {
      getApi({ onGetState, onValidate })
    }
  }, [getApi, onChangeSeason, onGetState, onValidate, prevGetApi, prevProgramType, programType])

  useUpdateSomeDataState(
    () => {
      if (value && responseData && responseData === value.id) {
        onDelete()
      }
    },
    [seasonRemoveState],
    DATA_STATE_RECIEVED
  )

  const actions = useMemo(
    () =>
      [
        { label: EDIT_TITLE, props: { onClick: onUpdate, disabled: !canUpdate } },
        { label: VIEW_TITLE, props: { onClick: onView, disabled: !canView } },
        { label: CLONE_TEXT, props: { onClick: onCloneHandler, disabled: !canClone } },
        { label: DELETE_TITLE, props: { onClick: onDeleteHandler, disabled: !canDelete } }
      ].filter(action => !action.props.disabled),
    [canClone, canDelete, canUpdate, canView, onCloneHandler, onDeleteHandler, onUpdate, onView]
  )

  return (
    <EntityBox
      title={value.name}
      colorSchema={isValid ? LIGHT_BLUE_SCHEMA : LIGHT_RED_SCHEMA}
      ButtonsComponent={() => actions.length > 0 && <DotsButton options={actions} align="left" />}
    >
      <Column>
        <Field label={LABEL_START_DATE}>{startDate}</Field>
        <Field label={LABEL_END_DATE}>{endDate}</Field>
        {groupedActivities.map(({ data, label, colorSchema }) => (
          <Field key={label} wrapperClassNames={styles.tagWrapper} label={label}>
            <div className={styles.tags}>
              {data.map(({ activity }) => (
                <Tag key={activity.id} colorSchema={colorSchema}>
                  {activity.name}
                </Tag>
              ))}
            </div>
          </Field>
        ))}
        {Object.keys(errors).length > 0 && (
          <Field label={SEASON_LIST_ERRORS}>
            <div className={styles.tags}>
              {Object.values(errors).map(({ message }) => (
                <span key={message} className={classNames(styles.tag, styles.invalid)}>
                  {message}
                </span>
              ))}
            </div>
          </Field>
        )}
      </Column>
      <Column>
        <Field label={LABEL_FEE}>{getXplorieFeeContent}</Field>
      </Column>
    </EntityBox>
  )
}

SeasonItem.propTypes = {
  value: PropTypes.objectOf(PropTypes.any).isRequired,
  onView: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  canUpdate: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canClone: PropTypes.bool.isRequired,
  canView: PropTypes.bool.isRequired,
  getApi: PropTypes.func,
  onChange: PropTypes.func,
  seasonRemoveState: PropTypes.string.isRequired,
  deleteSeasonById: PropTypes.func.isRequired,
  responseData: PropTypes.string,
  onClone: PropTypes.func.isRequired,
  programType: PropTypes.string.isRequired
}

SeasonItem.defaultProps = {
  getApi: () => null,
  onChange: () => null,
  responseData: null
}
