/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import isString from 'lodash/isString'

import { TextField, ConditionalYesNoField } from 'components/FormComponents'

import {
  BOOLEAN_TYPE,
  NUMBER_TYPE,
  STRING_TYPE,
  DATE_TYPE,
  EQUALS_MODE,
  NOT_EQUALS,
  MORE_OR_EQUALS,
  LESS_OR_EQUALS,
  CONTAINS,
  NOT_CONTAINS,
  PERIOD
} from 'constants/eligibility'

import { NumberField } from './NumberField'
import { DateField } from './DateField'

import {
  STRING_PARAMETER_PLACEHOLDER,
  EQUALS_MODE_LABEL,
  NOT_EQUALS_MODE_LABEL,
  MORE_OR_EQUALS_MODE_LABEL,
  LESS_OR_EQUALS_MODE_LABEL,
  NEW_PARAMETER_PREFIX,
  CONTAINS_MODE_LABEL,
  NOT_CONTAINS_MODE_LABEL,
  PERIOD_MODE_LABEL
} from './types'

/**
 *
 * @param {function} component - React Component
 * @param {Object} props - React Props
 * @returns {Object} {Component, props}
 */
function createFieldValue(component = null, props = {}) {
  return { Component: component, props }
}

/**
 *
 * @param {Object} fieldState
 */
export function createFieldValueState(fieldState) {
  if (!fieldState) {
    return { ...createFieldValue() }
  }
  return { ...fieldState }
}

export function getComponentByTypeMode(type, mode) {
  switch (type) {
    case BOOLEAN_TYPE:
      return createFieldValue(ConditionalYesNoField, {
        initialValue: false
      })
    case NUMBER_TYPE:
      return createFieldValue(NumberField, {
        mode
      })
    case STRING_TYPE:
      return createFieldValue(TextField, {
        type: 'text',
        placeholder: STRING_PARAMETER_PLACEHOLDER
      })
    case DATE_TYPE:
      return createFieldValue(DateField, {
        mode
      })
    default:
      return createFieldValue(TextField)
  }
}

export function getModesOptionsByEligibilityType(type) {
  const options = [
    {
      label: EQUALS_MODE_LABEL,
      value: EQUALS_MODE,
      types: [STRING_TYPE, DATE_TYPE, NUMBER_TYPE, BOOLEAN_TYPE]
    },
    {
      label: NOT_EQUALS_MODE_LABEL,
      value: NOT_EQUALS,
      types: [STRING_TYPE, DATE_TYPE, NUMBER_TYPE]
    },
    {
      label: MORE_OR_EQUALS_MODE_LABEL,
      value: MORE_OR_EQUALS,
      types: [DATE_TYPE, NUMBER_TYPE]
    },
    {
      label: LESS_OR_EQUALS_MODE_LABEL,
      value: LESS_OR_EQUALS,
      types: [DATE_TYPE, NUMBER_TYPE]
    },
    {
      label: CONTAINS_MODE_LABEL,
      value: CONTAINS,
      types: [STRING_TYPE]
    },
    {
      label: NOT_CONTAINS_MODE_LABEL,
      value: NOT_CONTAINS,
      types: [STRING_TYPE]
    },
    {
      label: PERIOD_MODE_LABEL,
      value: PERIOD,
      types: [DATE_TYPE, NUMBER_TYPE]
    }
  ]

  return options
    .filter(value => value.types.some(valueType => valueType === type))
    .map(value => ({ label: value.label, value: value.value }))
}

export function buildIsInvalid(formState) {
  const { invalid, values } = formState

  return (
    invalid ||
    !Object.values(values).every(
      value =>
        !isUndefined(value.mode) &&
        !isNull(value.mode) &&
        !isUndefined(value.value) &&
        !isNull(value.value)
    )
  )
}

export function isNewParameter(id) {
  if (!id || !isString(id)) {
    return true
  }
  return id.indexOf(NEW_PARAMETER_PREFIX) !== -1
}

export function buildContract(formValues) {
  return Object.values(formValues).map(value => ({
    ...value,
    id: isNewParameter(value.id) ? null : value.id
  }))
}
