/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import { actions, getEligibilityStateById } from 'reducers/eligibility/eligibilityForm'

import { EligibilityForm as EligibilityFormComponent } from './EligibilityForm'

const mapStateToProps = (state, { dataId }) => {
  const entityState = getEligibilityStateById(state, dataId)

  return {
    dataState: entityState.dataState,
    entityData: entityState.formData,
    entityDataState: entityState.formDataState
  }
}

const mapDispatchToProps = dispatch => {
  const { putEligibilityForm, resetForm } = actions
  return bindActionCreators(
    {
      putEligibilityForm,
      resetForm
    },
    dispatch
  )
}

export const EligibilityForm = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EligibilityFormComponent)
