/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose } from 'redux'
import { asField } from 'informed'
import { withFormContext } from 'components/FormComponents/helpers'
import { withAuthContext } from 'helpers/withAuthContext'
import { TypeOfTicketField as TypeOfTicketFieldComponent } from './TypeOfTicketField'

const mapStateToProps = state => ({
  ...state.ticketTypesList
})

export const TypeOfTicketField = compose(
  withFormContext,
  withAuthContext,
  asField,
  connect(mapStateToProps)
)(TypeOfTicketFieldComponent)
