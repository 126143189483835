/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { buildTicketTypeURL, buildTicketTypeURLById } from './helpers'

export const createEntity = (activityId, contractData) =>
  httpWrapper({ url: buildTicketTypeURL(activityId), method: 'post', data: contractData })

export const updateEntity = (activityId, contractData) =>
  httpWrapper({ url: buildTicketTypeURL(activityId), method: 'put', data: contractData })

export const getEntityById = (id, params) =>
  httpWrapper({ url: buildTicketTypeURLById(null, id), method: 'get', params })

export const deleteEntityById = (activityId, id, params) =>
  httpWrapper({ url: buildTicketTypeURLById(activityId, id), method: 'delete', params })
