/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const LIST_NAME = 'ACTIVITY_CATEGORIES_TREE'
export const ACTIVITY_CATEGORIES_TREE_STATE = 'ACTIVITY_CATEGORIES_TREE_STATE'
export const REMOVE_ACTIVITY_CATEGORY = 'ACTIVITY_CATEGORIES_TREE_REMOVE_CATEGORY'

export default {
  LIST_NAME,
  ACTIVITY_CATEGORIES_TREE_STATE,
  REMOVE_ACTIVITY_CATEGORY
}
