/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { UNIT_TASKS_LIST_NAME, UNIT_TASKS_LIST_COUNT_NAME, UNIT_TASKS_EXPORT } = types

const REQUEST_LIST = `${REQUEST_TYPE}_${UNIT_TASKS_LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${UNIT_TASKS_LIST_NAME}`
const RESPONSE_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${UNIT_TASKS_LIST_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${UNIT_TASKS_LIST_NAME}`

const REQUEST_LIST_COUNT = `${REQUEST_TYPE}_${UNIT_TASKS_LIST_COUNT_NAME}`
const RESPONSE_LIST_COUNT = `${RESPONSE_TYPE}_${UNIT_TASKS_LIST_COUNT_NAME}`
const RESPONSE_ERROR_LIST_COUNT = `${RESPONSE_ERROR_TYPE}_${UNIT_TASKS_LIST_COUNT_NAME}`

const REQUEST_LIST_EXPORT = `${REQUEST_TYPE}_${UNIT_TASKS_EXPORT}`
const RESPONSE_LIST_EXPORT = `${RESPONSE_TYPE}_${UNIT_TASKS_EXPORT}`
const RESPONSE_ERROR_LIST_EXPORT = `${RESPONSE_ERROR_TYPE}_${UNIT_TASKS_EXPORT}`

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_LIST_COUNT,
  RESPONSE_LIST_COUNT,
  RESPONSE_ERROR_LIST_COUNT,
  REQUEST_LIST_EXPORT,
  RESPONSE_LIST_EXPORT,
  RESPONSE_ERROR_LIST_EXPORT
}
