/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import {
  unitTableColumns,
  buildDefaultButtonColumn,
  buildDefaultColumn
} from 'helpers/dataTablesHelpers'
import get from 'lodash/get'
import { ViewButton } from './AdditionalColumns/ViewButton'

export const buildTableColumns = () => unitTableColumns

export const buildUnitsColumns = (pmSystem, dataId) => {
  const companyIdEnabled = get(pmSystem, 'companyIdEnabled', false)

  const tableColumns = dataId
    ? unitTableColumns.filter(item => item.accessor !== 'address')
    : unitTableColumns

  return tableColumns
    .map(column =>
      column.accessor === 'name' ? { ...buildDefaultColumn({ width: 227 }), ...column } : column
    )
    .filter(item => !(item.accessor === 'serviceId' && !companyIdEnabled))
}

export const buildUnitsActionColumns = () => [
  { ...buildDefaultButtonColumn(), Cell: data => <ViewButton data={data} readOnly /> }
]
