/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { PROGRAMS_URL } from 'endpoints'

export const activateProgram = (authKey, id, params) =>
  httpWrapper({ url: `${PROGRAMS_URL}/${id}/activate`, method: 'put', params })

export const deactivateProgram = (id, data, params) =>
  httpWrapper({ url: `${PROGRAMS_URL}/${id}/deactivation`, method: 'put', data, params })

export const deleteProgramDeactivate = (id, params) =>
  httpWrapper({ url: `${PROGRAMS_URL}/${id}/deactivation`, method: 'delete', params })

export const updateProgramDeactivation = (id, data, params) =>
  httpWrapper({ url: `${PROGRAMS_URL}/${id}/deactivation/update`, method: 'put', data, params })
