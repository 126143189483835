/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { DiscountsDataProvider } from 'containers/Discounts'
import { SimpleStub } from 'modules/Stubs'
import { PIECurtainLoader, BouncingLoader } from 'components/Loaders'
import { useUpdateSomeDataState, useLoadingDataState, useDidMount } from 'hooks'
import classNames from 'classnames'
import get from 'lodash/get'
import { DATA_STATE_RECIEVED, DATA_STATE_ERROR } from 'helpers/actionHelpers'
import { ACCESS_DENIED_MESSAGE } from 'modules/Discounts/types'
import { buildDealOptionsContract, buildAssignNewGroupContract } from './helpers'
import { DealOptionsForm } from './DealOptionsForm'
import { ControlPanel } from './ControlPanel'
import { NewActivitySection } from './NewActivitySection'
import { GroupsContent } from './GroupsContent'

import styles from './DiscountsForm.scss'

export function DiscountsForm(props) {
  const {
    entityDataState,
    dataId,
    entityData,
    onSetHeaderProps,
    onError,
    createDeal,
    updateDeal,
    getDealDataById,
    assignDealToGroup,
    removeDeal,
    deleteDataState,
    onClose,
    activateDeal,
    deactivateDeal,
    activateDataState,
    deactivateDataState,
    createOptionsDataState,
    updateOptionsDataState,
    resetDealForm,
    readOnly
  } = props
  const [dealsProviderState, setDealsProviderState] = useState({})

  const { onChange: onChangeDealsData } = dealsProviderState

  const dealOptionsFormApi = useRef(null)

  const [isDealOptionsLoading] = useLoadingDataState(entityDataState)
  const [isDeleting] = useLoadingDataState(deleteDataState)
  const [isActivating] = useLoadingDataState(activateDataState)
  const [isDeactivating] = useLoadingDataState(deactivateDataState)

  const isShownCurtainLoader = [isDeleting, isActivating, isDeactivating].some(value =>
    Boolean(value)
  )

  const isShownLoader = dataId && isDealOptionsLoading
  const dealId = entityData && entityData.id
  const dealType = get(entityData, 'type')
  const dealName = get(entityData, 'name')

  const onCreateDeal = useCallback(
    formValues => {
      if (createDeal) {
        createDeal(buildDealOptionsContract(formValues))
      }
    },
    [createDeal]
  )

  const onUpdateDeal = useCallback(
    formValues => {
      if (updateDeal && formValues && formValues.id) {
        updateDeal(formValues.id, buildDealOptionsContract(formValues))
      }
    },
    [updateDeal]
  )

  const onGetDealsOptionsFormApi = useCallback(
    api => {
      dealOptionsFormApi.current = api
    },
    [dealOptionsFormApi]
  )

  const onAddNewActivity = useCallback(
    formValues => {
      assignDealToGroup(dealId, buildAssignNewGroupContract(formValues))
    },
    [dealId, assignDealToGroup]
  )

  const onRemoveDeal = useCallback(() => {
    removeDeal(dealId)
  }, [removeDeal, dealId])

  const onActivateDeal = useCallback(() => {
    if (dealId) {
      activateDeal(dealId, { dealName })
    }
  }, [activateDeal, dealName, dealId])

  const onDeactivateDeal = useCallback(() => {
    if (dealId) {
      deactivateDeal(dealId, { dealName })
    }
  }, [deactivateDeal, dealName, dealId])

  useDidMount(() => {
    if (dataId) {
      getDealDataById(dataId)
    }
    return () => {
      resetDealForm()
    }
  })

  useUpdateSomeDataState(
    (prevEntityDataState, prevCreateOptionsDataState) => {
      if (entityData && entityData.id) {
        const { name, description, type, availabilities, id, status } = entityData
        onChangeDealsData(entityData, { readOnly })
        onSetHeaderProps({ title: name, info: status })

        if (
          dealOptionsFormApi.current &&
          (prevEntityDataState !== entityDataState ||
            prevCreateOptionsDataState !== createOptionsDataState)
        ) {
          dealOptionsFormApi.current.setValues({ id, name, type, availabilities, description })
        }
      }
    },
    [
      entityDataState,
      createOptionsDataState,
      updateOptionsDataState,
      activateDataState,
      deactivateDataState
    ],
    DATA_STATE_RECIEVED
  )

  useUpdateSomeDataState(
    () => {
      onClose()
    },
    [deleteDataState],
    DATA_STATE_RECIEVED
  )

  useUpdateSomeDataState(
    () => {
      onError()
    },
    [entityDataState],
    DATA_STATE_ERROR
  )

  return (
    <DiscountsDataProvider onChange={setDealsProviderState}>
      {({ isViewAllowed, isExist }) =>
        isViewAllowed ? (
          <>
            {isShownLoader && <BouncingLoader />}
            <div className={classNames({ [styles.isLoadingContent]: isShownLoader })}>
              <DealOptionsForm
                createDeal={onCreateDeal}
                updateDeal={onUpdateDeal}
                getFormApi={onGetDealsOptionsFormApi}
                dealType={dealType}
              />
              {isExist && (
                <>
                  <NewActivitySection dealId={dealId} onSubmit={onAddNewActivity} />
                  <GroupsContent dealId={dealId} />
                </>
              )}
              <ControlPanel
                onDelete={onRemoveDeal}
                onActivate={onActivateDeal}
                onDeactivate={onDeactivateDeal}
              />
            </div>
            {isShownCurtainLoader && <PIECurtainLoader />}
          </>
        ) : (
          <SimpleStub message={ACCESS_DENIED_MESSAGE} />
        )
      }
    </DiscountsDataProvider>
  )
}

DiscountsForm.propTypes = {
  getDealDataById: PropTypes.func.isRequired,
  entityDataState: PropTypes.string.isRequired,
  dataId: PropTypes.string,
  entityData: PropTypes.object.isRequired,
  onSetHeaderProps: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  createDeal: PropTypes.func.isRequired,
  updateDeal: PropTypes.func.isRequired,
  createOptionsDataState: PropTypes.string.isRequired,
  updateOptionsDataState: PropTypes.string.isRequired,
  assignDealToGroup: PropTypes.func.isRequired,
  assignDataState: PropTypes.string.isRequired,
  removeDeal: PropTypes.func.isRequired,
  deleteDataState: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  activateDeal: PropTypes.func.isRequired,
  deactivateDeal: PropTypes.func.isRequired,
  activateDataState: PropTypes.string.isRequired,
  deactivateDataState: PropTypes.string.isRequired,
  resetDealForm: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
}
DiscountsForm.defaultProps = {
  dataId: null,
  readOnly: false
}
