/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import { PM_COMPANY } from 'constants/searchTypes'
import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  SET_REPORT_TYPE
} = helpers

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  data: [],
  reportType: PM_COMPANY
}

const reportListReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING,
        data: []
      }
    case RESPONSE_LIST:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        ...action.payload
      }
    case RESPONSE_ERROR_LIST:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case SET_REPORT_TYPE:
      return {
        ...state,
        reportType: action.payload.reportType
      }
    case RESET_DATA_STATE:
      return { ...initialState }
    default:
      return state
  }
}

export default filterActions(reportListReducer, [
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  SET_REPORT_TYPE
])
