/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { useSliderApi } from 'hooks/useSliderApi'
import { useCallback, useMemo } from 'react'
import { DIFF_SLIDER_TITLE } from 'helpers/types/formEventHandlers'
import get from 'lodash/get'
import { PROGRAMS_DIFF } from 'constants/formCodes'

export const useProgramsDiffSlider = (currentProgramId, relatedProgramId, params) => {
  const type = useMemo(() => `${currentProgramId}_${relatedProgramId}`, [
    currentProgramId,
    relatedProgramId
  ])
  const programsDiffSlider = useSliderApi(PROGRAMS_DIFF)

  const open = useCallback(() => {
    const currentProgramVersion = get(params, 'currentProgramVersion', null)
    const relatedProgramVersion = get(params, 'relatedProgramVersion', null)
    const subtitle =
      currentProgramVersion && relatedProgramVersion
        ? `between version ${currentProgramVersion} and ${relatedProgramVersion}`
        : ''

    programsDiffSlider.open(type, {
      headerProps: {
        title: DIFF_SLIDER_TITLE,
        subtitle
      },
      props: {
        currentProgramId,
        relatedProgramId
      }
    })
  }, [currentProgramId, params, programsDiffSlider, relatedProgramId, type])

  return {
    open
  }
}
