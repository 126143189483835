/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import { actions as editQueue } from 'reducers/editQueue'
import { actions } from 'reducers/uniqPhoneNumList'
import { formActions } from 'reducers/uniqPhoneNumForm'
import { withAuthContext } from 'helpers/withAuthContext'

import { UniqPhoneNumberList as UniqPhoneNumberListComponent } from './UniqPhoneNumberList'

export const mapStateToProps = state => ({
  ...state.uniqPhoneNumList,
  removeDataState: state.uniqPhoneNumForm.removeDataState,
  formDataState: state.uniqPhoneNumForm.formDataState
})

export const mapDispatchToProps = dispatch => {
  const { getUniqPhoneNumberList: onGetData, ...rest } = actions
  return bindActionCreators({ ...editQueue, ...formActions, onGetData, ...rest }, dispatch)
}

export const UniqPhoneNumberList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UniqPhoneNumberListComponent)
