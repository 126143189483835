/*
 * COPYRIGHT:     Copyright © 2018 - 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  CalendarField,
  FormContent,
  SelectField,
  TextField,
  CustomNestedField,
  TagInputField,
  BoxColumns,
  ConditionalYesNoField
} from 'components/FormComponents'
import { DEPARTURE_DATE } from 'constants/billingTypes'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { BILLING_TYPE_OPTIONS } from 'mockData/billingType'
import get from 'lodash/get'
import {
  timeZoneOptions,
  numberValidate,
  numberValidateWithMessage,
  withValidation,
  numberValidationWithoutLimit,
  characterLimitValidation,
  greaterThenZeroValidation
} from 'helpers/formHelpers'
import { VALUE_VALIDATION_MESSAGE } from 'helpers/types/formHelpers'
import { SelectMarketField } from 'modules/Market'
import { usePrevious } from 'hooks'

import { ACTIVITY_PACKAGE } from 'constants/programTypes'
import { STATUS_DRAFT } from 'constants/entityStatus'
import { isActionAllowed, APPROVE_LEVEL, SYS_ADMIN_LEVEL } from 'helpers/permissionHelper'
import { ProgramComments } from '../ProgramComments'

import { PMCField } from '../PMCField'

import { ProgramAdditionalData } from '../ProgramAdditionalData'

import {
  LABEL_PROGRAM_NAME,
  PLACEHOLDER_PROGRAM_NAME,
  LABEL_PROGRAM_COMMENCEMENT_DATE,
  PLACEHOLDER_PROGRAM_COMMENCEMENT_DATE,
  LABEL_XE_PROGRAM_ID,
  PLACEHOLDER_XE_PROGRAM_ID,
  MARKET_LABEL,
  MARKET_PLACEHOLDER,
  LABEL_TIME_ZONE,
  PLACEHOLDER_TIME_ZONE,
  LABEL_UNIT_COUNT,
  PLACEHOLDER_UNIT_COUNT,
  LABEL_XPLORIE_MOBILE_URL,
  PLACEHOLDER_XPLORIE_MOBILE_URL,
  LABEL_UNIC_PHONE_NUMBER,
  PLACEHOLDER_UNIC_PHONE_NUMBER,
  LABEL_KEYWORD,
  PLACEHOLDER_KEYWORD,
  UNASSIGN_TITLE_BUTTON,
  NAME_FIELD_KEY,
  BILLING_TYPE_FIELD_LABEL,
  BILLING_TYPE_FIELD_PLACEHOLDER,
  BILLING_TYPE_FIELD_KEY,
  COMPANY_ID_FIELD_KEY,
  COMPANY_ID_LABEL,
  COMPANY_ID_PLACEHOLDER,
  PROGRAM_TYPE_FIELD_LABEL,
  PROGRAM_TYPE_FIELD_KEY,
  PROGRAM_TYPE_FIELD_PLACEHOLDER,
  PROGRAM_RENEWAL_DATE_LABEL,
  PROGRAM_RENEWAL_DATE_PLACEHOLDER,
  PROGRAM_RENEWAL_DATE_FIELD,
  DISABLE_UNITS_LABEL,
  DISABLE_UNITS_FIELD,
  DISABLE_RESERVATION_LABEL,
  DISABLE_RESERVATION_FIELD,
  NETSUITE_ID_LADEL,
  PLACEHOLDER_NETSUITE_ID,
  ON_E_COMMERCE_FIELD,
  ON_E_COMMERCE_LABEL
} from '../types'

import {
  buildProgramTypeOptions,
  isProgramTypePopulated,
  canChangeProgramType,
  onGetFullFreeUniqPhoneList
} from '../helpers'

import styles from './ProgramFormFields.scss'

export function ProgramsFormInnerContent(props) {
  const {
    formApi,
    formData,
    formState,
    formEnabled,
    dataId,
    showComments,
    isExistEntity,
    canUnassignPhoneNumber,
    isVisibleUnassignPhoneNumberButton,
    unassignPhoneById,
    authUser,
    isOwnProgram
  } = props
  const unassignHandlerEvent = () => {
    const programId = get(formData, 'id', '')
    const uniquePhoneNumberId = get(formData, 'uniquePhoneNumber.id', '')

    if (uniquePhoneNumberId) {
      unassignPhoneById(programId)
    }
  }
  const uniqFreePhoneNumList = get(props, 'uniqFreePhoneNumList')
  const formValues = get(formState, 'values')
  const pmcId = get(formValues, 'pmcId')
  const programType = get(formValues, 'type')
  const isDraft = get(formValues, 'status') === STATUS_DRAFT
  const prevProgramType = usePrevious(programType)
  const companyIdEnabled = get(formData, 'pmsInfo.parameters.companyIdEnabled', false)
  const isApprover = isActionAllowed(APPROVE_LEVEL, authUser)
  const isSysadmin = isActionAllowed(SYS_ADMIN_LEVEL, authUser)
  const isProgramTypeDisabled = useMemo(
    () => !canChangeProgramType(formData, isApprover, isSysadmin, isOwnProgram, isDraft),
    [formData, isApprover, isSysadmin, isOwnProgram, isDraft]
  )
  const netsuiteIdValidation = withValidation(
    withValidation(numberValidationWithoutLimit, greaterThenZeroValidation),
    characterLimitValidation()
  )

  useEffect(() => {
    if (
      isDraft &&
      prevProgramType &&
      ((programType === ACTIVITY_PACKAGE && prevProgramType !== ACTIVITY_PACKAGE) ||
        (programType !== ACTIVITY_PACKAGE && prevProgramType === ACTIVITY_PACKAGE))
    ) {
      formApi.setValue('uniquePhoneNumber', null)
    }

    if (programType !== prevProgramType) {
      onGetFullFreeUniqPhoneList(props, programType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programType, prevProgramType])

  return (
    <FormContent formApi={formApi} disabled={!formEnabled} classNameWrapper={styles.bottomIndent}>
      <TextField
        label={LABEL_PROGRAM_NAME}
        fieldName={NAME_FIELD_KEY}
        placeholder={PLACEHOLDER_PROGRAM_NAME}
        isRequired
      />
      <PMCField formState={formState} pmcId={pmcId} dataId={dataId} formData={formData} />
      <SelectField
        label={PROGRAM_TYPE_FIELD_LABEL}
        fieldName={PROGRAM_TYPE_FIELD_KEY}
        options={buildProgramTypeOptions(formData)}
        disabled={isProgramTypeDisabled}
        placeholder={PROGRAM_TYPE_FIELD_PLACEHOLDER}
        isRequired
      />
      <SelectField
        label={BILLING_TYPE_FIELD_LABEL}
        fieldName={BILLING_TYPE_FIELD_KEY}
        options={BILLING_TYPE_OPTIONS}
        placeholder={BILLING_TYPE_FIELD_PLACEHOLDER}
        initial={DEPARTURE_DATE}
        isRequired
      />
      <CustomNestedField field="externalSettings">
        <BoxColumns>
          <ConditionalYesNoField
            label={DISABLE_UNITS_LABEL}
            fieldName={DISABLE_UNITS_FIELD}
            disabled={!formEnabled}
          />
          <ConditionalYesNoField
            label={DISABLE_RESERVATION_LABEL}
            fieldName={DISABLE_RESERVATION_FIELD}
            disabled={!formEnabled}
          />
          <ConditionalYesNoField
            label={ON_E_COMMERCE_LABEL}
            fieldName={ON_E_COMMERCE_FIELD}
            disabled={!formEnabled}
          />
        </BoxColumns>
      </CustomNestedField>
      {companyIdEnabled && (
        <TagInputField
          fieldName={COMPANY_ID_FIELD_KEY}
          label={COMPANY_ID_LABEL}
          placeholder={COMPANY_ID_PLACEHOLDER}
          validate={numberValidateWithMessage(VALUE_VALIDATION_MESSAGE)}
          canDelete
        />
      )}
      <CustomNestedField field="programInfo">
        <TextField
          fieldName="unitCount"
          label={LABEL_UNIT_COUNT}
          placeholder={PLACEHOLDER_UNIT_COUNT}
          validate={numberValidate}
          readOnly
        />
      </CustomNestedField>
      <BoxColumns>
        <CalendarField
          label={LABEL_PROGRAM_COMMENCEMENT_DATE}
          placeholder={PLACEHOLDER_PROGRAM_COMMENCEMENT_DATE}
          fieldName="commencementDate"
        />
        <CalendarField
          label={PROGRAM_RENEWAL_DATE_LABEL}
          placeholder={PROGRAM_RENEWAL_DATE_PLACEHOLDER}
          fieldName={PROGRAM_RENEWAL_DATE_FIELD}
        />
      </BoxColumns>
      <div className={styles.combineGroupSelectWithButton}>
        <SelectField
          label={LABEL_UNIC_PHONE_NUMBER}
          fieldName="uniquePhoneNumber"
          options={uniqFreePhoneNumList}
          placeholder={PLACEHOLDER_UNIC_PHONE_NUMBER}
          disabled={!formEnabled || !isProgramTypePopulated(formValues)}
          path={['id']}
        />
        {isVisibleUnassignPhoneNumberButton && (
          <BasicButton
            colorSchema={ORANGE_SCHEMA}
            onClick={unassignHandlerEvent}
            className={styles.assignUnassignSection}
            disabled={!canUnassignPhoneNumber}
          >
            {UNASSIGN_TITLE_BUTTON}
          </BasicButton>
        )}
      </div>
      <CustomNestedField field="programInfo">
        <TextField
          label={LABEL_KEYWORD}
          fieldName="callKeyword"
          placeholder={PLACEHOLDER_KEYWORD}
        />
        <TextField
          fieldName="xplorieMobileUrl"
          label={LABEL_XPLORIE_MOBILE_URL}
          placeholder={PLACEHOLDER_XPLORIE_MOBILE_URL}
        />
        <SelectMarketField
          fieldName="market"
          label={MARKET_LABEL}
          placeholder={MARKET_PLACEHOLDER}
        />
        <SelectField
          fieldName="timeZone"
          options={timeZoneOptions}
          label={LABEL_TIME_ZONE}
          placeholder={PLACEHOLDER_TIME_ZONE}
        />
      </CustomNestedField>
      <TextField
        label={LABEL_XE_PROGRAM_ID}
        placeholder={PLACEHOLDER_XE_PROGRAM_ID}
        fieldName="xeProgramId"
        validate={numberValidate}
      />
      <TextField
        label={NETSUITE_ID_LADEL}
        placeholder={PLACEHOLDER_NETSUITE_ID}
        fieldName="netsuiteId"
        validate={netsuiteIdValidation}
      />
      {isExistEntity && <ProgramAdditionalData formData={formData} />}
      {showComments && <ProgramComments field="comments" />}
    </FormContent>
  )
}

ProgramsFormInnerContent.propTypes = {
  formApi: PropTypes.object.isRequired,
  formData: PropTypes.oneOfType([PropTypes.any]),
  formEnabled: PropTypes.bool.isRequired,
  formState: PropTypes.object,
  dataId: PropTypes.string,
  unassignPhoneById: PropTypes.func,
  getFreeUniqPhoneNumberList: PropTypes.func,
  showComments: PropTypes.bool,
  isExistEntity: PropTypes.bool,
  isOwnProgram: PropTypes.bool.isRequired,
  canUnassignPhoneNumber: PropTypes.bool,
  isVisibleUnassignPhoneNumberButton: PropTypes.bool,
  authUser: PropTypes.object.isRequired
}

ProgramsFormInnerContent.defaultProps = {
  formData: null,
  formState: {},
  dataId: null,
  unassignPhoneById: () => {},
  getFreeUniqPhoneNumberList: () => {},
  showComments: false,
  isExistEntity: false,
  canUnassignPhoneNumber: false,
  isVisibleUnassignPhoneNumberButton: false
}
