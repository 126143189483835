/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useEffect, useRef } from 'react'
import { queueLocationForm } from 'helpers/formEventHandlers'
import { buildMenuPosition } from 'helpers/contextMenuHelper'
import PropTypes from 'prop-types'
import { buildLocationTypeOptions } from 'modules/Locations'
import styles from './LocationsContextMenu.scss'
import { VIEW_BUTTON, ADD_CHILD_BUTTON, REMOVE_BUTTON } from './types'
import { buildTitleForAddChildForm, canLinkChildLocations } from './helpers'

export function LocationsContextMenu(props) {
  const menuEl = useRef(null)
  const {
    pageX,
    pageY,
    onClose,
    id,
    type,
    name,
    canUpdate,
    children,
    onRemoveLocation,
    parentType,
    addChildLocation,
    updateAndAddChild
  } = props

  const onFocusHandler = () => {
    menuEl.current.focus()
  }

  const onBlurHandler = event => {
    onClose(event)
  }

  const onViewHandler = e => {
    queueLocationForm(props, {
      id,
      name,
      parentType,
      children,
      onSubmit: updateAndAddChild,
      locationsOptions: buildLocationTypeOptions(parentType, children),
      viewMode: true
    })()(e)
  }

  const onRemoveLocationHandler = event => {
    onRemoveLocation(id, type)
    onClose(event)
  }

  const onAddChildHandler = e => {
    const locationsOptions = buildLocationTypeOptions(type)
    queueLocationForm(props, {
      name: buildTitleForAddChildForm(name),
      parentId: id,
      parentType: type,
      showModeButton: true,
      watchLocationId: id,
      onSubmit: addChildLocation(id),
      locationsOptions,
      addChildMode: true
    })()(e)
  }

  useEffect(() => {
    onFocusHandler()
  }, [])

  return (
    <ul
      ref={menuEl}
      className={styles.menuWrapper}
      style={{ ...buildMenuPosition(pageX, pageY) }}
      tabIndex="-1"
      onBlur={onBlurHandler}
    >
      <li className={styles.menuItem} onClick={onViewHandler}>
        {VIEW_BUTTON}
      </li>
      {canLinkChildLocations(type, canUpdate) && (
        <li className={styles.menuItem} onClick={onAddChildHandler}>
          {ADD_CHILD_BUTTON}
        </li>
      )}
      {canUpdate && children && !children.length && (
        <li className={styles.menuItem} onClick={onRemoveLocationHandler}>
          {REMOVE_BUTTON}
        </li>
      )}
    </ul>
  )
}
LocationsContextMenu.propTypes = {
  pageX: PropTypes.number,
  pageY: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.array,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onRemoveLocation: PropTypes.func.isRequired,
  canUpdate: PropTypes.bool.isRequired,
  parentType: PropTypes.string,
  addChildLocation: PropTypes.func.isRequired,
  updateAndAddChild: PropTypes.func.isRequired
}
LocationsContextMenu.defaultProps = {
  pageX: 0,
  pageY: 0,
  children: [],
  parentType: null
}
