/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withFormContext } from 'components/FormComponents/helpers'
import { withValidation, blankValidate, basicTextValidate } from 'helpers/formHelpers'

import styles from './TextField.scss'
import { TextInput } from './TextInput'

export const TextFieldComponent = props => {
  const {
    disabled,
    placeholder,
    fieldName,
    validate,
    requiredValidate,
    isRequired,
    className,
    type,
    preValidate,
    children,
    ...rest
  } = props
  const validateStrategy = isRequired ? withValidation(requiredValidate, validate) : validate

  return (
    <>
      <TextInput
        {...rest}
        isRequired={isRequired}
        className={className}
        field={fieldName}
        placeholder={placeholder}
        disabled={disabled}
        validate={validateStrategy}
        mask={preValidate}
        type={type}
        validateOnChange
      />
      {children}
    </>
  )
}
TextFieldComponent.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.func,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  preValidate: PropTypes.func,
  type: PropTypes.oneOf(['text', 'password', 'email', 'hidden']),
  children: PropTypes.node,
  requiredValidate: PropTypes.func
}
TextFieldComponent.defaultProps = {
  placeholder: '',
  disabled: false,
  validate: basicTextValidate,
  isRequired: false,
  className: styles.input,
  label: '',
  type: 'text',
  preValidate: (value = '') => value,
  children: null,
  requiredValidate: blankValidate
}

export const TextField = withFormContext(TextFieldComponent)
