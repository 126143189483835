/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  getEntityById,
  updateEntity,
  createEntity,
  deleteEntityById
} from 'services/http/marketDictionary'

import helpers from './helpers'

const {
  NEW_ENTITY_KEY,
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_FORM_DATA,
  RESPONSE_GET_FORM_DATA,
  RESPONSE_GET_FORM_DATA_ERROR,
  REQUEST_DELETE_ENTITY_DATA,
  RESPONSE_DELETE_ENTITY_DATA,
  RESPONSE_DELETE_ENTITY_DATA_ERROR,
  CREATED_MESSAGE,
  UPDATED_MESSAGE,
  DELETED_MESSAGE
} = helpers

const getMarketById = id => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_GET_FORM_DATA, { key }))
  const request = await getEntityById(id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_FORM_DATA_ERROR, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_FORM_DATA, { key, data: request.data }))
    return 'getMarketById success'
  }
  return 'getMarketById failed'
}

const updateMarketForm = contractData => async dispatch => {
  const key = contractData && contractData.id
  dispatch(actionCreater(REQUEST_FORM, { key }))
  const request = await updateEntity(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: UPDATED_MESSAGE }))
    dispatch(actionCreater(RESPONSE_FORM, { key, data: request.data }))
    return 'updateMarketForm success'
  }
  return 'updateMarketForm failed'
}

const createMarketForm = contractData => async dispatch => {
  const key = (contractData && contractData.id) || NEW_ENTITY_KEY
  dispatch(actionCreater(REQUEST_FORM, { key }))
  const request = await createEntity(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: CREATED_MESSAGE }))
    dispatch(actionCreater(RESPONSE_FORM, { key, data: request.data }))
    return 'createMarketForm success'
  }
  return 'createMarketForm failed'
}

const deleteMarketById = id => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_DELETE_ENTITY_DATA, { key }))
  const request = await deleteEntityById(id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DELETE_ENTITY_DATA_ERROR, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: DELETED_MESSAGE }))
    dispatch(actionCreater(RESPONSE_DELETE_ENTITY_DATA, { key }))
    return 'deleteMarketById success'
  }
  return 'deleteMarketById failed'
}

const resetForm = key => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key }))

export default {
  getMarketById,
  updateMarketForm,
  createMarketForm,
  deleteMarketById,
  resetForm
}
