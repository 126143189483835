/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from 'reducers/editQueue'

import { LocationsContextMenu as LocationsContextMenuComponent } from './LocationsContextMenu'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

export const LocationsContextMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsContextMenuComponent)
