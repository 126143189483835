/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const FORM_NAME = 'DISCOUNT_DEAL_TICKET_FORM'
export const TICKET_TYPES = `${FORM_NAME}_TICKET_TYPES`

export default {
  FORM_NAME,
  TICKET_TYPES
}
