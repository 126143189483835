/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function BicycleIcon(props) {
  return (
    <svg viewBox="125 100 550 550" {...props}>
      <path
        d="M561.8,354.4c-6.6,0-13.1,0.6-19.4,1.8l-38-112.7c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.2-0.6-0.4-0.9
	c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.3-0.3-0.5-0.4-0.8c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.3-0.4-0.5-0.7
	c-0.1-0.2-0.2-0.3-0.4-0.5c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.1-0.3-0.3-0.5-0.4
	c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.5-0.3-0.7-0.4c-0.2-0.1-0.4-0.2-0.5-0.3
	c-0.3-0.1-0.5-0.2-0.8-0.3c-0.2-0.1-0.4-0.1-0.6-0.2c-0.3-0.1-0.5-0.2-0.8-0.3c-0.2-0.1-0.4-0.1-0.7-0.2c-0.2-0.1-0.5-0.1-0.7-0.2
	c-0.3-0.1-0.6-0.1-0.8-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.3,0-0.7,0-1-0.1c-0.1,0-0.2,0-0.4,0h-36.6c-8.3,0-15,6.7-15,15s6.7,15,15,15
	h25.8l11.9,35.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6-0.1-0.8-0.1c-0.2,0-0.4,0-0.6-0.1c-0.3,0-0.6,0-0.9,0c-0.1,0-0.2,0-0.3,0H312.3
	l-11-16.9c10-1.3,20.1-2.5,29.4-2.5c33.9,0,33.7-29.1,1.7-29.1c-51.8,0-77.6-15.5-106.7-15.5c-22,0-2.8,29.6,12.2,44.6
	c6.1,6.1,17.1,7.3,30.1,6.4l14.3,22c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1-0.1,0.1l-19.5,49.6c-7.9-1.9-16.1-2.9-24.5-2.9
	C179.6,354.4,132,402,132,460.6s47.6,106.2,106.2,106.2s106.2-47.6,106.2-106.2c0-39.5-21.7-74-53.7-92.3l11.9-30.2l71.7,110.3
	c-1.7,3.7-2.7,7.8-2.7,12.1c0,15.7,12.8,28.5,28.5,28.5s28.5-12.8,28.5-28.5c0-4.7-1.2-9.1-3.2-13.1l74.4-124l14.3,42.3
	c-34.6,17.5-58.4,53.4-58.4,94.8c0,58.5,47.6,106.2,106.2,106.2c58.5,0,106.2-47.6,106.2-106.2S620.4,354.4,561.8,354.4z
	 M314.3,460.6c0,42-34.2,76.2-76.2,76.2S162,502.6,162,460.6s34.2-76.2,76.2-76.2c4.6,0,9,0.4,13.4,1.2l-21,53.4
	c-8.9,3.1-15.2,11.6-15.2,21.6c0,12.6,10.3,22.9,22.9,22.9s22.9-10.3,22.9-22.9c0-3.8-0.9-7.4-2.6-10.6l21-53.4
	C300.4,410.2,314.3,433.8,314.3,460.6z M461.9,328.1l-62.3,103.9c-0.1,0-0.2,0-0.2,0l-67.6-103.9L461.9,328.1L461.9,328.1z
	 M561.8,536.7c-42,0-76.2-34.2-76.2-76.2c0-28.1,15.3-52.7,38.1-65.9l18.3,54.4c-2,3.4-3.1,7.3-3.1,11.6c0,12.6,10.3,22.9,22.9,22.9
	s22.9-10.3,22.9-22.9c0-9.5-5.9-17.7-14.2-21.2L552.2,385c3.2-0.4,6.4-0.6,9.7-0.6c42,0,76.2,34.2,76.2,76.2
	S603.8,536.7,561.8,536.7z"
      />
    </svg>
  )
}
