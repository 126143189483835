/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import {
  REQUEST_PROFILE_ACTION,
  RESPONSE_PROFILE_ACTION,
  RESPONSE_PROFILE_ACTION_ERROR
} from './helpers'

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  profileData: null
}

const profileDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PROFILE_ACTION:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_PROFILE_ACTION_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case RESPONSE_PROFILE_ACTION:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        ...action.payload
      }
    default:
      return state
  }
}

export default filterActions(profileDataReducer, [
  REQUEST_PROFILE_ACTION,
  RESPONSE_PROFILE_ACTION,
  RESPONSE_PROFILE_ACTION_ERROR
])
