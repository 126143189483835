/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'
import { getTicketGroupInventoryState } from 'reducers/activityResale/ticketGroupInventory'
import { FilesList as FilesListComponent } from './FilesList'

const mapStateToProps = state => ({
  ...getTicketGroupInventoryState(state)
})

export const FilesList = compose(
  withAuthContext,
  connect(mapStateToProps)
)(FilesListComponent)
