/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import helpers from './helpers'

const {
  REQUEST_LOCATION_TREE_DATA,
  RESPONSE_LOCATION_TREE_DATA,
  RESPONSE_LOCATION_TREE_DATA_ERROR,
  RESET_DATA_STATE
} = helpers

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  locationTreeDataState: DATA_STATE_NOT_REQUESTED,
  locationTreeData: {}
}

const locationTreeModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LOCATION_TREE_DATA:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING,
        locationTreeDataState: DATA_STATE_REQUESTING,
        locationTreeData: {}
      }
    case RESPONSE_LOCATION_TREE_DATA:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        locationTreeDataState: DATA_STATE_RECIEVED,
        locationTreeData: action.payload
      }
    case RESPONSE_LOCATION_TREE_DATA_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR,
        locationTreeDataState: DATA_STATE_ERROR
      }
    case RESET_DATA_STATE:
      return initialState
    default:
      return state
  }
}

export default filterActions(locationTreeModalReducer, [
  REQUEST_LOCATION_TREE_DATA,
  RESPONSE_LOCATION_TREE_DATA,
  RESPONSE_LOCATION_TREE_DATA_ERROR,
  RESET_DATA_STATE
])
