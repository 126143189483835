/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useCallback, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CheckBoxButton } from '@xplorie/ui-commons'
import styles from './CheckBoxDropdownButton.scss'

export function CheckBoxDropdownButton(props) {
  const { options, onClick, onOptionClick, ...rest } = props
  const [isOpen, setIsOpenState] = useState(false)
  const [dropDownStyles, setDropDownStylesState] = useState({ display: 'none' })
  const drowpdownRef = useRef(null)
  const arrowRef = useRef()

  const onTogleDropdown = useCallback(() => {
    setIsOpenState(!isOpen)
  }, [isOpen])

  const onHideDropdown = useCallback(() => {
    setIsOpenState(false)
  }, [])

  const onClickCheckBoxHandler = useCallback(
    (value, event) => {
      onHideDropdown()
      if (onClick) {
        onClick(value, event)
      }
    },
    [onClick, onHideDropdown]
  )

  const onClickOptionHandler = useCallback(
    value => event => {
      if (onOptionClick) {
        onOptionClick(value, event)
      }
      onHideDropdown()
    },
    [onHideDropdown, onOptionClick]
  )

  useEffect(() => {
    if (isOpen) {
      drowpdownRef.current.focus()
      const position = arrowRef.current.getBoundingClientRect()
      setDropDownStylesState({ left: position.left, top: position.top })
    } else {
      setDropDownStylesState({ display: 'none' })
    }
  }, [isOpen])

  return (
    <div className={styles.wrapper} tabIndex="-1" onBlur={onHideDropdown} ref={drowpdownRef}>
      <CheckBoxButton onClick={onClickCheckBoxHandler} {...rest} />
      <div className={styles.dropdownWrapper}>
        <a
          type="button"
          className={styles.arrow}
          onClick={onTogleDropdown}
          ref={arrowRef}
          data-testid="arrorButton"
        />
        {isOpen && (
          <div className={styles.content} style={{ ...dropDownStyles }}>
            <ul className={styles.dropdownList}>
              {options.map(value => (
                <li
                  key={value.label}
                  className={styles.dropdownItem}
                  onClick={onClickOptionHandler(value)}
                >
                  {value.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}
CheckBoxDropdownButton.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })),
  onOptionClick: PropTypes.func,
  onClick: PropTypes.func
}

CheckBoxDropdownButton.defaultProps = {
  options: [],
  onClick: null,
  onOptionClick: null
}
