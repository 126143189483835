/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { pmcInitialState } from './reducer'

export const getPmcLifeCycleStateByPmcId = (state, pmcId) =>
  state.pmc.pmcLifeCycle[pmcId] || pmcInitialState

export const isPmcLifeCyclePending = (state, pmcId) => {
  const currentState = getPmcLifeCycleStateByPmcId(state, pmcId)
  return [currentState.deactivationDataState, currentState.activationDataState].some(
    value => value === DATA_STATE_REQUESTING
  )
}

export const getPmcLifeCycleDataStates = (state, pmcId) => {
  const currentState = getPmcLifeCycleStateByPmcId(state, pmcId)
  return [currentState.deactivationDataState, currentState.activationDataState]
}

export const getPmcLifeCycleState = state => state.pmc.pmcLifeCycle
