/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { getQueryStringParams, buildRequestParams } from 'helpers/searchParamsHelper'
import {
  PROGRAM,
  ACTIVITY,
  ACTIVITY_PROVIDER,
  PM_COMPANY,
  DEAL,
  DISCOUNT
} from 'constants/searchTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import {
  CHANGE_QUERY_PARAMETERS,
  CHANGE_FILTERS,
  RESET_LIST_PARAMETERS,
  ON_SEARCH,
  ALLOWED_QUERY_PARAMS_KEYS,
  searchTabOptions
} from './types'

/**
 *
 * @param {string} type
 * @returns {boolean}
 */
export const isSearchTypeExists = type =>
  Array.prototype.some.call(
    [PROGRAM, PM_COMPANY, ACTIVITY, ACTIVITY_PROVIDER, DEAL, DISCOUNT],
    value => value === type
  )

/**
 *
 * @param {string} basePath
 * @param {Object} params
 * @returns {Object}
 */
export const buildUrl = (basePath, params = {}) => {
  const filteredParams = Object.keys(params)
    .filter(key => Boolean(params[key]) && ALLOWED_QUERY_PARAMS_KEYS.some(value => value === key))
    .reduce((acc, key) => ({ ...acc, [key]: params[key] }), {})
  return { pathname: basePath, search: buildRequestParams(filteredParams) }
}

export const convertQueryToStateParameters = search => {
  const urlParameters = getQueryStringParams(search)
  if (urlParameters.type && !isSearchTypeExists(urlParameters.type)) {
    delete urlParameters.type
  }
  return Object.keys(urlParameters)
    .filter(key => urlParameters[key] && ALLOWED_QUERY_PARAMS_KEYS.some(value => value === key))
    .reduce((acc, key) => ({ ...acc, [key]: urlParameters[key] }), {})
}

export function reducer(state, action) {
  switch (action.type) {
    case CHANGE_QUERY_PARAMETERS:
      return {
        ...state,
        parameters: { ...state.parameters, ...action.payload }
      }
    case CHANGE_FILTERS:
      return { ...state, filters: action.payload || [] }
    case ON_SEARCH:
      return {
        ...state,
        isShowResult: state.isShowResult ? state.isShowResult : !state.isShowResult,
        submits: state.submits + 1
      }
    case RESET_LIST_PARAMETERS:
      return {
        ...state,
        filters: [],
        parameters: { keyword: state.parameters.keyword, type: state.parameters.type }
      }
    default:
      throw new Error()
  }
}

export function buildSearchTabs(authUser) {
  return searchTabOptions.filter(({ accessLevels }) =>
    accessLevels.some(level => isActionAllowed([level], authUser))
  )
}
