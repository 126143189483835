/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'

import helpers from './helpers'

const { SET_MODE_DISABLE, SET_MODE_VALUE, RESET_DATA_STATE } = helpers

const setModeValue = value => dispatch => {
  dispatch(actionCreater(SET_MODE_VALUE, Boolean(value)))
}

const setModeDisabled = value => dispatch => {
  dispatch(actionCreater(SET_MODE_DISABLE, Boolean(value)))
}

const resetState = () => dispatch => {
  dispatch(actionCreater(RESET_DATA_STATE))
}

export default { setModeValue, setModeDisabled, resetState }
