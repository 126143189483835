/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import moment from 'moment'

export const modifiersStyles = {
  conflicts: {
    color: 'white',
    backgroundColor: '#ff3860'
  },
  selectedDates: {
    color: 'white',
    backgroundColor: '#00d1b2'
  },
  selectedRange: {
    border: '1px solid blue'
  }
}

export const buildAdditionProps = ({
  selectedDates,
  conflicts,
  selectedRange,
  disabledDays = [],
  ...rest
}) => {
  const currentYear = moment().year()
  const startWithDate = moment({ year: currentYear, month: 0 }).toDate()

  return {
    modifiersStyles,
    fixedWeeks: true,
    fromMonth: startWithDate,
    disabledDays: [{ before: startWithDate }, ...disabledDays],
    modifiers: {
      selectedDates,
      conflicts,
      selectedRange
    },
    ...rest
  }
}
