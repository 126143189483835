/* eslint-disable max-lines */
/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React, { useContext, useRef, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { BasicButton } from '@xplorie/ui-commons'
import { SEASON } from 'constants/formCodes'
import { ProgramSeasonContext } from 'modules/Programs/helpers'
import { ControlButtonsContainer } from 'components/FormComponents'
import {
  ActivateButton,
  DeactivateButton,
  DraftInProgressButton
} from 'modules/Programs/ProgramsForm/Buttons'
import { InvalidFormModal } from 'modules/Programs'
import { SMARTHOST_STANDALONE } from 'constants/programTypes'
import { useRootModal } from 'hooks/useRootModal'
import { updateSeasonsAfterProgramTypeChange } from 'modules/Programs/ProgramsForm/ProgramFormFields/helpers'
import { ProgramButton } from './ProgramButton'
import { ForApproveButton } from './ForApproveButton'
import styles from './ButtonContainer.scss'
import {
  buildSeasonsData,
  buildFinalProgramData,
  buildInvalidModalErrors,
  isValidSmartHostFee,
  hasSeasonsToUpdate
} from './helpers'
import {
  SUBMIT_BUTTON_TEXT,
  APPROVE_BUTTON_TEXT,
  REJECT_BUTTON_TEXT,
  SAVE_BTN,
  BASIC_BTN_VALUE
} from './types'
import { ChangeProgramTypeModal } from './ChangeProgramTypeModal/ChangeProgramTypeModal'

export function ButtonContainer(props) {
  const {
    authUser,
    formState,
    smartHostFormState,
    dataId,
    isProgramActionPending,
    submitForApproval,
    postProgramsForm,
    putProgramsForm,
    api,
    rejectDraft,
    approveDraft,
    createDraftProgram,
    tabKey,
    programData: entityData,
    onClose,
    seasonsState,
    onUpdateSeason,
    saveRateAdjustmentList
  } = props
  const rootModal = useRootModal()
  const invalidModalApi = useRef()
  const { permissions, conditions } = useContext(ProgramSeasonContext)
  const { canApprove, canReject } = permissions
  const {
    canInitDraft,
    showApprovalButton,
    showRejectButton,
    showSaveToDraftButton,
    showInitUpdateButton,
    showForApprovalButton,
    isVisibleActivateButton,
    isVisibleDeactivateButton,
    isVisibleDraftInProgressButton
  } = conditions
  const isSeasonTab = tabKey === SEASON
  const createdBy = (dataId && get(formState, 'program.value.metadata.createdBy')) || null
  const programType = get(entityData, 'type')
  const currentProgramType = dataId && get(formState, 'program.value.type', null)
  const isOnBoardedProgram = get(entityData, 'onBoarded')
  const smartHostFees = get(smartHostFormState, 'fees', null)
  const authUserEmail = get(authUser, 'email')

  const canSaveDraft = get(formState, 'readyForSave') && !isProgramActionPending
  const canSaveForApproveDraft =
    get(formState, 'readySaveForApprove') &&
    !isProgramActionPending &&
    isValidSmartHostFee(currentProgramType, smartHostFees)

  const canRejectProgram = canReject && !isProgramActionPending
  const canApproveProgram = canApprove && !isProgramActionPending

  const canSeeSubmit = useMemo(
    () => !isVisibleDraftInProgressButton && showSaveToDraftButton && !isSeasonTab,
    [isSeasonTab, isVisibleDraftInProgressButton, showSaveToDraftButton]
  )

  const saveDraft = () => {
    const formApi = get(formState, 'program.api')
    if (formApi) {
      formApi.submitForm().then(() => {
        const updatedState = api.getState()
        const ready = get(updatedState, 'readyForSave')
        if (ready) {
          const request = dataId ? putProgramsForm : postProgramsForm
          const programData = get(updatedState, 'program.value', {})
          const seasons = get(updatedState, 'seasons.value', [])
          request(null, buildFinalProgramData(programData), buildSeasonsData(seasons))
        }
      })
    }
  }

  const onHandleSubmitDraft = () => {
    updateSeasonsAfterProgramTypeChange(
      dataId,
      seasonsState,
      onUpdateSeason,
      saveRateAdjustmentList,
      isOnBoardedProgram
    )
    saveDraft()
  }

  const onOpenModal = () => {
    rootModal.enqueue(ChangeProgramTypeModal, {
      isOpen: true,
      onClose: rootModal.dequeue,
      onSubmit: onHandleSubmitDraft
    })
  }

  const updateSeasons = () => {
    if (isOnBoardedProgram) {
      return onHandleSubmitDraft()
    }

    return onOpenModal()
  }

  const onSaveDraft = () => {
    if (
      currentProgramType === SMARTHOST_STANDALONE &&
      currentProgramType !== programType &&
      hasSeasonsToUpdate(seasonsState, isOnBoardedProgram)
    ) {
      return updateSeasons()
    }

    return saveDraft()
  }

  // crutch and pain here
  //  TODO: we should refactor all program season functionality
  const onSaveForApprove = useCallback(
    comment => {
      if (canSaveForApproveDraft) {
        const updatedState = api.getState()
        const programData = get(updatedState, 'program.value', {})
        const seasons = get(updatedState, 'seasons.value', [])
        submitForApproval(
          null,
          dataId,
          { comment },
          buildFinalProgramData(programData),
          buildSeasonsData(seasons)
        )
      }
    },
    [api, canSaveForApproveDraft, dataId, submitForApproval]
  )

  const buildCanSubmitForApprove = useCallback(() => {
    api.getState().program.api.validate()
    return api.getState().readySaveForApprove && canSaveForApproveDraft
  }, [api, canSaveForApproveDraft])

  const onRejectProgram = comment => {
    if (canReject) {
      rejectDraft(null, dataId, { comment })
    }
  }

  const onApproveProgram = comment => {
    if (canApproveProgram) {
      const updatedState = api.getState()
      const programData = get(updatedState, 'program.value', {})
      approveDraft(dataId, { comment }, buildFinalProgramData(programData))
    }
  }

  const onCreateDraftFromActiveProgram = useCallback(() => {
    if (canInitDraft) {
      createDraftProgram(null, dataId)
    }
  }, [canInitDraft, createDraftProgram, dataId])

  const onClickDraftProgressHandler = useCallback(
    applyDraftId => {
      const updatedState = api.getState()
      const draftProgramId = get(updatedState, 'program.value.draftIds.0', null)
      if (draftProgramId) {
        applyDraftId(draftProgramId)
        onClose()
      }
    },
    [api, onClose]
  )

  const onGetInvalidModalApi = modalApi => {
    invalidModalApi.current = modalApi
  }

  const onOpenInvalidModal = () => {
    const updatedState = api.getState()
    const invalidSmartHost = !isValidSmartHostFee(currentProgramType, smartHostFees)
    const programData = get(updatedState, 'program.errors', {})
    const seasons = get(updatedState, 'seasons.errors', [])
    invalidModalApi.current.open(buildInvalidModalErrors(programData, seasons, invalidSmartHost))
  }

  return (
    <>
      <ControlButtonsContainer className={styles.wrapper}>
        {showInitUpdateButton && isSeasonTab && (
          <ProgramButton
            onClick={onCreateDraftFromActiveProgram}
            disabled={isProgramActionPending}
            ButtonComponent={BasicButton}
          >
            {BASIC_BTN_VALUE}
          </ProgramButton>
        )}
        {isVisibleDraftInProgressButton && (
          <DraftInProgressButton
            onClick={onClickDraftProgressHandler}
            disabled={isProgramActionPending}
          />
        )}
        {canSeeSubmit && (
          <ProgramButton
            onClick={onSaveDraft}
            disabled={!canSaveDraft}
            isVisible={showSaveToDraftButton}
            ButtonComponent={BasicButton}
          >
            {SAVE_BTN}
          </ProgramButton>
        )}
        <ProgramButton
          userEmail={authUserEmail}
          createdBy={createdBy}
          isValid={!canApproveProgram}
          onClick={onApproveProgram}
          isVisible={showApprovalButton}
          ButtonComponent={BasicButton}
          withComment
        >
          {APPROVE_BUTTON_TEXT}
        </ProgramButton>
        <ProgramButton
          onClick={onRejectProgram}
          disabled={!canRejectProgram}
          isVisible={showRejectButton}
          ButtonComponent={BasicButton}
          withComment
        >
          {REJECT_BUTTON_TEXT}
        </ProgramButton>
        <ProgramButton
          userEmail={authUserEmail}
          createdBy={createdBy}
          disabled={isProgramActionPending}
          onClick={onSaveForApprove}
          isVisible={showForApprovalButton}
          ButtonComponent={ForApproveButton}
          onModal={onOpenInvalidModal}
          canSubmit={buildCanSubmitForApprove}
          withComment
        >
          {SUBMIT_BUTTON_TEXT}
        </ProgramButton>
        {isVisibleActivateButton && (
          <ActivateButton disabled={isProgramActionPending} programId={dataId} />
        )}
        {isVisibleDeactivateButton && (
          <DeactivateButton
            disabled={isProgramActionPending}
            programId={dataId}
            name={entityData.name}
          />
        )}
      </ControlButtonsContainer>
      <InvalidFormModal getApi={onGetInvalidModalApi} />
    </>
  )
}

ButtonContainer.propTypes = {
  authUser: PropTypes.object.isRequired,
  formState: PropTypes.object,
  smartHostFormState: PropTypes.object,
  dataId: PropTypes.string,
  isProgramActionPending: PropTypes.bool.isRequired,
  submitForApproval: PropTypes.func.isRequired,
  postProgramsForm: PropTypes.func.isRequired,
  putProgramsForm: PropTypes.func.isRequired,
  api: PropTypes.func.isRequired,
  rejectDraft: PropTypes.func.isRequired,
  approveDraft: PropTypes.func.isRequired,
  createDraftProgram: PropTypes.func.isRequired,
  tabKey: PropTypes.string.isRequired,
  programData: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  seasonsState: PropTypes.array,
  onUpdateSeason: PropTypes.func,
  saveRateAdjustmentList: PropTypes.func
}
ButtonContainer.defaultProps = {
  dataId: null,
  formState: {},
  programData: {},
  smartHostFormState: {},
  seasonsState: [],
  onUpdateSeason: () => {},
  saveRateAdjustmentList: () => {}
}
