/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { withFormContext } from 'components/FormComponents/helpers'
import { withValidation, blankValidate } from 'helpers/formHelpers'
import { getRange } from './helpers'
import { RangePickerInput } from './RangePickerInput'

export function RangePickerFieldComponent(props) {
  const { placeholder, fieldName, validate, isRequired, disabled, formApi, ...rest } = props

  const blankValidation = value => {
    const { from, to } = getRange(value)

    return blankValidate(from) || blankValidate(to)
  }

  const validateStrategy = isRequired ? withValidation(blankValidation, validate) : validate

  return (
    <RangePickerInput
      {...rest}
      isRequired={isRequired}
      field={fieldName}
      placeholder={placeholder}
      disabled={disabled}
      validate={validateStrategy}
      validateOnChange
    />
  )
}

RangePickerFieldComponent.propTypes = {
  fieldProps: PropTypes.object,
  formApi: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.func,
  isRequired: PropTypes.bool,
  label: PropTypes.string
}

RangePickerFieldComponent.defaultProps = {
  fieldProps: {},
  placeholder: '',
  disabled: false,
  validate: () => null,
  isRequired: false,
  formApi: null,
  label: null
}

export const RangePickerField = withFormContext(RangePickerFieldComponent)
