/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  createEntity,
  updateEntity,
  getEntityById,
  getFreeEntityById,
  deleteEntityById
} from 'services/http/uniqPhoneNumDictionary'

import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID_ERROR,
  REQUEST_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID_ERROR,
  buildDeleteMessage
} = helpers

const postForm = (contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await createEntity(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM))
    dispatch(send(message))
    return 'postForm success'
  }

  return 'postForm failed'
}

const putForm = (contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await updateEntity(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM))
    dispatch(send(message))
    return 'putForm success'
  }

  return 'putForm failed'
}

const getEntityDataById = (id, programId, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_ENTITY_BY_ID))
  const httpRequest = programId ? getEntityById : getFreeEntityById
  const request = await httpRequest(id, programId, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID, request.data))
    return 'getEntityDataById success'
  }

  return 'getEntityDataById failed'
}

const removeEntityById = (id, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_REMOVE_ENTITY_BY_ID))
  const request = await deleteEntityById(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_REMOVE_ENTITY_BY_ID_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildDeleteMessage() }))
    dispatch(actionCreater(RESPONSE_REMOVE_ENTITY_BY_ID))
    return 'removeEntityById success'
  }

  return 'removeEntityById failed'
}

const resetForm = () => ({
  type: RESET_DATA_STATE
})

export default {
  postForm,
  putForm,
  getEntityDataById,
  removeEntityById,
  resetForm
}
