/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  getEntityById,
  getEntityAddressById,
  getEntityAmenitiesById,
  getEntityImagesById,
  getEntitySmartHostById,
  updateSmartHostById
} from 'services/http/units'

import {
  RESET_DATA_STATE,
  REQUEST_UNIT_BY_ID,
  RESPONSE_UNIT_BY_ID,
  RESPONSE_UNIT_BY_ID_ERROR,
  REQUEST_UNIT_ADDRESS_BY_ID,
  RESPONSE_UNIT_ADDRESS_BY_ID,
  RESPONSE_UNIT_ADDRESS_BY_ID_ERROR,
  REQUEST_UNIT_AMENITIES_BY_ID,
  RESPONSE_UNIT_AMENITIES_BY_ID,
  RESPONSE_UNIT_AMENITIES_BY_ID_ERROR,
  REQUEST_UNIT_IMAGES_BY_ID,
  RESPONSE_UNIT_IMAGES_BY_ID,
  RESPONSE_UNIT_IMAGES_BY_ID_ERROR,
  REQUEST_UNIT_SMART_HOST_BY_ID,
  RESPONSE_UNIT_SMART_HOST_BY_ID,
  RESPONSE_UNIT_SMART_HOST_BY_ID_ERROR,
  REQUEST_UPDATE_UNIT_SMART_HOST_BY_ID,
  RESPONSE_UPDATE_UNIT_SMART_HOST_BY_ID,
  RESPONSE_UPDATE_UNIT_SMART_HOST_BY_ID_ERROR,
} from './helpers'

export const getUnitById = unitId => async dispatch => {
  dispatch(actionCreater(REQUEST_UNIT_BY_ID))

  const request = await getEntityById(unitId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_UNIT_BY_ID_ERROR))
  })

  if (request) {
    const { data } = request
    dispatch(actionCreater(RESPONSE_UNIT_BY_ID, data))
    return 'getUnitById success'
  }

  return 'getUnitById failed'
}

export const getUnitAddressById = unitId => async dispatch => {
  dispatch(actionCreater(REQUEST_UNIT_ADDRESS_BY_ID))

  const request = await getEntityAddressById(unitId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_UNIT_ADDRESS_BY_ID_ERROR))
  })

  if (request) {
    const { data } = request
    dispatch(actionCreater(RESPONSE_UNIT_ADDRESS_BY_ID, data))
    return 'getUnitAddressById success'
  }

  return 'getUnitAddressById failed'
}

export const getUnitAmenitiesById = unitId => async dispatch => {
  dispatch(actionCreater(REQUEST_UNIT_AMENITIES_BY_ID))

  const request = await getEntityAmenitiesById(unitId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_UNIT_AMENITIES_BY_ID_ERROR))
  })

  if (request) {
    const { data } = request
    dispatch(actionCreater(RESPONSE_UNIT_AMENITIES_BY_ID, data))
    return 'getUnitAmenitiesById success'
  }

  return 'getUnitAmenitiesById failed'
}

export const getUnitImagesById = unitId => async dispatch => {
  dispatch(actionCreater(REQUEST_UNIT_IMAGES_BY_ID))

  const request = await getEntityImagesById(unitId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_UNIT_IMAGES_BY_ID_ERROR))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_UNIT_IMAGES_BY_ID, request.data))

    return 'getUnitImagesById success'
  }

  return 'getUnitImagesById failed'
}

export const getUnitSmartHostById = unitId => async dispatch => {
  dispatch(actionCreater(REQUEST_UNIT_SMART_HOST_BY_ID))

  const request = await getEntitySmartHostById(unitId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_UNIT_SMART_HOST_BY_ID_ERROR))
  })

  if (request) {
    const { data } = request
    dispatch(actionCreater(RESPONSE_UNIT_SMART_HOST_BY_ID, data))

    return 'getSmartHostById success'
  }

  return 'getSmartHostById failed'
}

export const updateUnitSmartHostById = (contractData, unitId) => async dispatch => {
  dispatch(actionCreater(REQUEST_UPDATE_UNIT_SMART_HOST_BY_ID))

  const request = await updateSmartHostById(contractData, unitId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_UPDATE_UNIT_SMART_HOST_BY_ID_ERROR))
  })

  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_UPDATE_UNIT_SMART_HOST_BY_ID))

    return 'updateSmartHostById success'
  }

  return 'updateSmartHostById failed'
}

export const resetForm = () => ({
  type: RESET_DATA_STATE
})
