/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose } from 'redux'
import { selectors } from 'reducers/location/locationForm'
import { withAuthContext } from 'helpers/withAuthContext'
import { LocationTab as LocationTabComponent } from './LocationTab'

const { getLocationFormCloseDataStates } = selectors

function mapStateToProps(state, { dataId, watchLocationId }) {
  const closeDataStates = getLocationFormCloseDataStates(state, dataId)
  const watchCloseDataStates = getLocationFormCloseDataStates(state, watchLocationId)
  return {
    closeDataStates: [...watchCloseDataStates, ...closeDataStates]
  }
}

export const LocationTab = compose(
  withAuthContext,
  connect(mapStateToProps)
)(LocationTabComponent)
