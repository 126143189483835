/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export default {
  ENQUEUE: 'ENQUEUE',
  DEQUEUE: 'DEQUEUE',
  UPDATE_SLIDE: 'UPDATE_SLIDE',
  CLEAR_QUEUE: 'CLEAR_QUEUE'
}
