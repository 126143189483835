/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
// buttons
export const VIEW_PROGRAMS_BUTTONS_TEXT = 'View Programs'
export const VIEW_APPS_BUTTON_TEXT = 'View APPs'

// Name field
export const NAME_LABEL = 'Name'
export const NAME_PLACEHOLDER = 'Name'
export const NAME_FIELD = 'name'

// How it Works Hero Image field
export const HIW_LABEL = 'How it Works Hero Image'
export const HIW_PLACEHOLDER = 'Add Image URL'
export const HIW_FIELD = 'howitworksHeroUrl'

// Activity Popular Hero Image field
export const ACTIVITY_POPULAR_LABEL = 'Activity Popular - Hero Image'
export const ACTIVITY_POPULAR_PLACEHOLDER = 'Add Image URL'
export const ACTIVITY_POPULAR_FIELD = 'activityPopularHeroUrl'

// Activity Gem Hero Image field
export const ACTIVITY_GEM_LABEL = 'Activity Gem - Hero Image'
export const ACTIVITY_GEM_PLACEHOLDER = 'Add Image URL'
export const ACTIVITY_GEM_FIELD = 'activityGemHeroUrl'

// Almost Time Hero Image field
export const ALMOST_TIME_LABEL = 'Almost Time - Hero Image'
export const ALMOST_TIME_PLACEHOLDER = 'Add Image URL'
export const ALMOST_TIME_FIELD = 'almostTimeHeroUrl'

// FAQ Hero Image field
export const FAQ_HERO_LABEL = 'FAQ - Hero Image'
export const FAQ_HERO_PLACEHOLDER = 'Add Image URL'
export const FAQ_HERO_FIELD = 'faqHeroUrl'

// In House Hero Image field
export const IN_HOUSE_LABEL = 'In House - Hero Image'
export const IN_HOUSE_PLACEHOLDER = 'Add Image URL'
export const IN_HOUSE_FIELD = 'inhouseHeroUrl'

// Post Hero Image field
export const POST_HERO_LABEL = 'Post - Hero Image'
export const POST_HERO_PLACEHOLDER = 'Add Image URL'
export const POST_HERO_FIELD = 'postHeroUrl'

// One Year Hero Image field
export const ONE_YEAR_LABEL = 'One Year - Hero Image'
export const ONE_YEAR_PLACEHOLDER = 'Add Image URL'
export const ONE_YEAR_FIELD = 'oneYearHeroUrl'

// Footer Background Image field
export const FOOTER_BKG_LABEL = 'Footer Background - Image'
export const FOOTER_BKG_PLACEHOLDER = 'Add Image URL'
export const FOOTER_BKG_FIELD = 'footerBackgroundUrl'
