/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useContext } from 'react'
import { CirclePlusIcon } from 'icons'
import { RateFormContext } from '../types'
import { RateFormBox } from '../RateFormBox'
import { appendEmptyRate } from '../helpers'
import styles from './RateAddMode.scss'

function RateAddMode() {
  const { rates, onRemoveRate, formApi, setRates } = useContext(RateFormContext)

  const onAddRate = useCallback(() => {
    const formRates = formApi.getValue('rates') || []
    const nextRates = appendEmptyRate(formRates)

    formApi.setValues({ rates: nextRates })
    setRates(nextRates)
  }, [formApi, setRates])

  return (
    <>
      {rates.map((item, index) => (
        <RateFormBox key={item.id} index={index} item={item} onRemoveRate={onRemoveRate} />
      ))}
      <div className={styles.buttonWrapper}>
        <CirclePlusIcon onClick={onAddRate} />
      </div>
    </>
  )
}

export default RateAddMode
