/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
// Importing redux connector
import { connect } from 'react-redux'

import { ContentWithNavComponent } from './ContentWithNavComponent'

// Mapping component state
const mapStateToProps = () => ({})

const ContentWithNav = connect(mapStateToProps)(ContentWithNavComponent)
export { ContentWithNav, ContentWithNavComponent }
