/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { assignGroups, getEntityById } from 'services/http/deals'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'

import helpers from './helpers'
import { getActivities } from './selectors'

const {
  RESET_DATA_STATE,
  REQUEST_UPDATE_ACTIVITY,
  RESPONSE_UPDATE_ACTIVITY,
  RESPONSE_UPDATE_ACTIVITY_ERROR,
  activityStateKeyBuilder,
  REQUEST_UPDATE_ACTIVITY_TYPE,
  RESPONSE_UPDATE_ACTIVITY_TYPE,
  RESPONSE_UPDATE_ACTIVITY_TYPE_ERROR,
  REQUEST_DELETE_ACTIVITY,
  RESPONSE_DELETE_ACTIVITY,
  RESPONSE_DELETE_ACTIVITY_ERROR,
  CHANGE_EDIT_MODE
} = helpers

const updateActivity = (dealId, activityId, contractData, params) => async (dispatch, getState) => {
  const key = activityStateKeyBuilder(dealId, activityId)
  const activities = [...getActivities(getState())]
  const currentIndex = findIndex(activities, ['activity.id', activityId])
  activities.splice(currentIndex, 1, contractData)
  dispatch(actionCreater(REQUEST_UPDATE_ACTIVITY, key))
  try {
    await assignGroups(dealId, activities, params)
    const dealDataRequest = await getEntityById(dealId)
    const updatedActivityData = find(dealDataRequest.data.groups, ['activity.id', activityId])
    dispatch(actionCreater(RESPONSE_UPDATE_ACTIVITY, { key, data: updatedActivityData }))
    return 'updateActivity success'
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_UPDATE_ACTIVITY_ERROR, key))
  }
  return 'updateActivity failed'
}

const updateActivityType = (dealId, activityId, contractData, params) => async (
  dispatch,
  getState
) => {
  const key = activityStateKeyBuilder(dealId, activityId)
  const activities = [...getActivities(getState())]
  const currentIndex = findIndex(activities, ['activity.id', activityId])
  activities.splice(currentIndex, 1, { ...activities[currentIndex], type: contractData.type })
  dispatch(actionCreater(REQUEST_UPDATE_ACTIVITY_TYPE, key))
  const request = await assignGroups(dealId, activities, params).catch(err => {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_UPDATE_ACTIVITY_TYPE_ERROR, key))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_UPDATE_ACTIVITY_TYPE, { key, data: contractData }))
    return 'updateActivityType success'
  }

  return 'updateActivityType failed'
}

const deleteActivity = (dealId, activityId, params) => async (dispatch, getState) => {
  const key = activityStateKeyBuilder(dealId, activityId)
  const activities = [...getActivities(getState())]
  const currentIndex = findIndex(activities, ['activity.id', activityId])
  activities.splice(currentIndex, 1)
  dispatch(actionCreater(REQUEST_DELETE_ACTIVITY, key))
  const request = await assignGroups(dealId, activities, params).catch(err => {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DELETE_ACTIVITY_ERROR, key))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_DELETE_ACTIVITY, key))
    return 'deleteActivity success'
  }

  return 'deleteActivity failed'
}

const updateEditMode = (dealId, activityId, newValue) => ({
  type: CHANGE_EDIT_MODE,
  payload: { key: activityStateKeyBuilder(dealId, activityId), data: newValue }
})

const resetState = (dealId, activityId) => ({
  type: RESET_DATA_STATE,
  payload: activityStateKeyBuilder(dealId, activityId)
})

export default { updateActivity, resetState, updateActivityType, deleteActivity, updateEditMode }
