/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole
 *                or in part, is prohibited.
 */

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ButtonsWrapper } from 'components/Wrappers'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { BasicButton } from '@xplorie/ui-commons'
import { HIDE_BUTTON_TITLE, SHOW_BUTTON_TITLE } from 'constants/buttonsOptions'

import styles from './ToggleVisibilityArea.scss'

export function ToggleVisibilityArea(props) {
  const {
    children,
    titleArea,
    btnTitleShow,
    btnTitleHide,
    ButtonComponent,
    buttonProps,
    classNameWrapper,
    defaultOpen
  } = props

  const [isOpen, setIsOpen] = useState(defaultOpen)

  const onToggleVisibility = useCallback(() => {
    setIsOpen(!isOpen)
  }, [setIsOpen, isOpen])

  const titleBtn = !isOpen ? btnTitleShow : btnTitleHide

  return (
    <div className={classNames('box', classNameWrapper)}>
      <ButtonsWrapper>
        <Column>
          <h1 className={styles.title}>{titleArea}</h1>
        </Column>
        <Column>
          <ButtonComponent onClick={onToggleVisibility} {...buttonProps}>
            {titleBtn}
          </ButtonComponent>
        </Column>
      </ButtonsWrapper>
      {isOpen && children}
    </div>
  )
}

ToggleVisibilityArea.propTypes = {
  children: PropTypes.node.isRequired,
  titleArea: PropTypes.string,
  btnTitleShow: PropTypes.string,
  btnTitleHide: PropTypes.string,
  classNameWrapper: PropTypes.string,
  ButtonComponent: PropTypes.any,
  defaultOpen: PropTypes.boolean,
  buttonProps: PropTypes.object
}

ToggleVisibilityArea.defaultProps = {
  titleArea: '',
  btnTitleShow: SHOW_BUTTON_TITLE,
  btnTitleHide: HIDE_BUTTON_TITLE,
  ButtonComponent: BasicButton,
  classNameWrapper: null,
  defaultOpen: false,
  buttonProps: {}
}
