/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'

import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/currentUser'
import { withRouter } from 'react-router'
import { LoginForm as LoginFormComponent } from './LoginForm'

const mapStateToProps = state => ({
  dataState: state.currentUser.dataState,
  currentUser: state.currentUser.authUser
})

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

export const LoginForm = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LoginFormComponent)
