/* eslint-disable max-lines */
/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef } from 'react'
import { useRootModal } from 'hooks'
import classNames from 'classnames'
import compose from 'lodash/fp/compose'
import { CLOSURE_TYPE, SCHEDULE_TYPE } from 'modules/Closures/types'
import { STATUS_CANCELED } from 'constants/entityStatus'
import { useSelectedEventsApi } from './use-selected-events-api'
import { ClosureModal } from '../ClosureModal'
import { ScheduleModal } from '../ScheduleModal'
import {
  mapOccurrencesToBody,
  mapOccurrenceToCancelBody,
  mapOccurrenceToActivateBody
} from './mappers'
import { ScheduleWarningModal } from '../ScheduleWarningModal'
import { ScheduleCancellationModal } from '../ScheduleCancellationModal'
import styles from './styles.scss'
import './dayEvents.css'
import {
  getEventItemHeader,
  getEventContextMenu,
  getDeleteOccurrenceMessage,
  getDeleteOccurrenceStatusMessages
} from './helpers'
import {
  CANCEL_SCHEDULE_SERIES_MESSAGE,
  CANCEL_SCHEDULE_MODAL_TITLE,
  RECURRENCE_ICON,
  ACTIVATE_SCHEDULE_MESSAGE,
  ACTIVATE_SCHEDULE_MODAL_TITLE
} from './types'

export function useCalendarPresenter(props) {
  const {
    activityId,
    onDeleteSeries,
    onDeleteOccurrence,
    onDeleteOccurrences,
    onCancelSeries,
    onCancelOccurrence,
    onActivateSeries,
    onActivateOccurrence,
    fetch
  } = props
  const calendarRef = useRef()
  const rootModal = useRootModal()
  const selectedEventsApi = useSelectedEventsApi()

  const renderEvent = info => {
    const { extendedProps: event, title } = info.event

    return (
      <>
        <div className={styles.eventHeader}>
          {getEventItemHeader(event)}
          {event.isSeries && <img src={RECURRENCE_ICON} alt="recurrence-icon" />}
        </div>
        <div className="fc-event-time">{info.timeText}</div>
        {title && <div className={classNames('fc-event-title', styles.eventBody)}>{title}</div>}
      </>
    )
  }

  const buildEventClassNames = info => {
    const {
      day,
      seriesId,
      type,
      details: { allDay },
      status
    } = info.event.extendedProps

    return classNames(styles.eventWrapper, {
      [styles.eventSelected]: selectedEventsApi.isSelected(seriesId, day),
      [styles.schedule]: type === SCHEDULE_TYPE,
      [styles.scheduleAllDay]: type === SCHEDULE_TYPE && allDay,
      [styles.closureAllDay]: type === CLOSURE_TYPE && allDay,
      [styles.cancellation]: status === STATUS_CANCELED
    })
  }

  const handleDataChanged = () => {
    const api = calendarRef.current.getApi()
    const currentDate = api.getDate()
    // event trigger datesSet callback
    api.gotoDate(currentDate)
  }

  const handleSeriesActivate = async seriesId => {
    try {
      await onActivateSeries(seriesId)
      handleDataChanged()
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  const handleOccurrenceActivate = async (day, seriesId) => {
    try {
      const payload = mapOccurrenceToActivateBody(day, seriesId)
      await onActivateOccurrence(payload)
      handleDataChanged()
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  const handleSeriesCancel = async (payload, seriesId) => {
    try {
      await onCancelSeries(seriesId, payload)
      handleDataChanged()
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  const handleOccurrenceCancel = async (form, day, seriesId) => {
    try {
      const payload = mapOccurrenceToCancelBody(form, day, seriesId)
      await onCancelOccurrence(payload)
      handleDataChanged()
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  const handleSeriesDelete = async (seriesId, successMessage, errorMessage) => {
    try {
      await onDeleteSeries(seriesId, successMessage, errorMessage)
      handleDataChanged()
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  const handleOccurrenceDelete = async (seriesId, day, successMessage, errorMessage) => {
    try {
      await onDeleteOccurrence(seriesId, day, successMessage, errorMessage)
      handleDataChanged()
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  const handleOccurrencesDelete = async occurrences => {
    try {
      await onDeleteOccurrences(mapOccurrencesToBody(occurrences))
      handleDataChanged()
      // eslint-disable-next-line no-empty
    } catch (err) {
    } finally {
      selectedEventsApi.clearEvents()
    }
  }

  const handleAddClosure = info => () => {
    rootModal.enqueue(ClosureModal, {
      isOpen: true,
      onClose: rootModal.dequeue,
      activityId,
      onChange: handleDataChanged,
      initialValues: { startDate: info.date, endDate: info.date }
    })
  }

  const handleAddSchedule = info => () => {
    rootModal.enqueue(ScheduleModal, {
      isOpen: true,
      onClose: rootModal.dequeue,
      activityId,
      onChange: handleDataChanged,
      initialValues: { startDate: info.date, endDate: info.date }
    })
  }

  const handleEditSeries = (info, modal) => () => {
    rootModal.enqueue(modal, {
      isOpen: true,
      onClose: rootModal.dequeue,
      id: info.event.extendedProps.seriesId,
      activityId,
      onChange: handleDataChanged
    })
  }

  const handleEditOccurrence = (info, modal) => () => {
    rootModal.enqueue(modal, {
      isOpen: true,
      onClose: rootModal.dequeue,
      id: info.event.extendedProps.seriesId,
      activityId,
      day: info.event.extendedProps.day,
      onChange: handleDataChanged
    })
  }

  const handleDeleteItem = (info, isSeries = false) => () => {
    const {
      type,
      seriesId,
      series: { datePeriod, timePeriod },
      day
    } = info.event.extendedProps
    const { successMessage, errorMessage } = getDeleteOccurrenceStatusMessages(type)

    rootModal.enqueue(ScheduleWarningModal, {
      isOpen: true,
      onClose: rootModal.dequeue,
      message: getDeleteOccurrenceMessage(type),
      onSubmit: () =>
        isSeries
          ? handleSeriesDelete(seriesId, successMessage, errorMessage)
          : handleOccurrenceDelete(seriesId, day, successMessage, errorMessage),
      date: isSeries
        ? {
            start: datePeriod.startDate,
            end: datePeriod.endDate
          }
        : day,
      time: timePeriod
        ? {
            start: timePeriod.startTime,
            end: timePeriod.endTime
          }
        : null
    })
  }

  const handleDeleteOccurrences = occurrences => {
    rootModal.enqueue(ScheduleWarningModal, {
      isOpen: true,
      onClose: rootModal.dequeue,
      message: 'Do you really want to delete the selected Occurrences?',
      onSubmit: () => handleOccurrencesDelete(occurrences)
    })
  }

  const handleCancelSchedule = (info, isSeries = false) => () => {
    const {
      seriesId,
      series: { datePeriod, timePeriod },
      day
    } = info.event.extendedProps

    rootModal.enqueue(ScheduleCancellationModal, {
      isOpen: true,
      onClose: rootModal.dequeue,
      message: CANCEL_SCHEDULE_SERIES_MESSAGE,
      title: CANCEL_SCHEDULE_MODAL_TITLE,
      onSubmit: form =>
        isSeries ? handleSeriesCancel(form, seriesId) : handleOccurrenceCancel(form, day, seriesId),
      date: isSeries
        ? {
            start: datePeriod.startDate,
            end: datePeriod.endDate
          }
        : day,
      time: timePeriod
        ? {
            start: timePeriod.startTime,
            end: timePeriod.endTime
          }
        : null
    })
  }

  const handleActivateSchedule = (info, isSeries = false) => () => {
    const {
      seriesId,
      series: { datePeriod, timePeriod },
      day
    } = info.event.extendedProps

    rootModal.enqueue(ScheduleWarningModal, {
      isOpen: true,
      onClose: rootModal.dequeue,
      title: ACTIVATE_SCHEDULE_MODAL_TITLE,
      message: ACTIVATE_SCHEDULE_MESSAGE,
      onSubmit: () =>
        isSeries ? handleSeriesActivate(seriesId) : handleOccurrenceActivate(day, seriesId),
      date: isSeries
        ? {
            start: datePeriod.startDate,
            end: datePeriod.endDate
          }
        : day,
      time: timePeriod
        ? {
            start: timePeriod.startTime,
            end: timePeriod.endTime
          }
        : null
    })
  }

  const buildEventContextMenu = info => {
    if (selectedEventsApi.hasSelectedEvents()) {
      return [
        {
          label: 'Delete Closure',
          options: [
            {
              label: 'Delete Occurrences',
              onClick: compose(
                handleDeleteOccurrences,
                selectedEventsApi.getEvents
              )
            }
          ]
        }
      ]
    }

    return getEventContextMenu({
      info,
      handleEditSeries,
      handleEditOccurrence,
      handleDeleteItem,
      handleCancelSchedule,
      handleActivateSchedule
    })
  }

  const buildDayContextMenu = info => [
    { label: 'Add Closure', onClick: handleAddClosure(info) },
    { label: 'Add Schedule', onClick: handleAddSchedule(info) }
  ]

  const handleFetch = info => {
    fetch({
      start: info.start,
      end: info.end
    })
  }

  return {
    calendarRef,
    selectedEventsApi,
    renderEvent,
    buildEventClassNames,
    handleFetch,
    buildEventContextMenu,
    buildDayContextMenu
  }
}
