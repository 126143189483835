/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { getPmcUnitsTemplate } from 'services/http/pmcUnits'
import helpers from './helpers'

const {
  REQUEST_DOWNLOAD_UNITS_TEMPLATE,
  RESPONSE_DOWNLOAD_UNITS_TEMPLATE,
  RESPONSE_ERROR_DOWNLOAD_UNITS_TEMPLATE,
  RESET_DATA_STATE
} = helpers

const downloadUnitsTemplate = pmcId => async dispatch => {
  dispatch(actionCreater(REQUEST_DOWNLOAD_UNITS_TEMPLATE))
  const request = await getPmcUnitsTemplate(pmcId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_DOWNLOAD_UNITS_TEMPLATE))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_DOWNLOAD_UNITS_TEMPLATE))
    return 'downloadUnitsTemplate success'
  }

  return 'downloadUnitsTemplate failed'
}

const resetState = () => dispatch => dispatch(actionCreater(RESET_DATA_STATE))

export default {
  downloadUnitsTemplate,
  resetState
}
