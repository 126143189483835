/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const AccountSlider = loadable(
  () => import(/* webpackChunkName: 'AccountSlider', webpackPrefetch: true */ './container'),
  {
    resolveComponent: components => components.AccountSlider
  }
)

export { AccountSlider }
