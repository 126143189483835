/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { UNIT_TASK_COUNTER } from 'constants/counterTypes'
import {
  ADMIN_LEVEL,
  VAC_SPECIALIST_LEVEL,
  READ_LEVEL,
  APPROVE_LEVEL,
  USER_LEVEL,
  UNIT_TASKS_MANAGE_LEVEL
} from 'constants/permissionTypes'
import {
  CampaignIcon,
  BicycleIcon,
  CalendarIcon,
  CompassIcon,
  GraphIcon,
  HomeIcon,
  NotebookIcon,
  CheckBoxIcon,
  PeopleIcon,
  SearchIcon,
  SettingsIcon,
  LocationsIcon,
  TicketIcon,
  SwitchToIcon
} from 'icons'
import styles from './Nav.scss'

export const TITLE_SUB_MENU_SWITCH_TO = 'Switch To'
export const TITLE_SUB_MENU_OPEN = 'Open'

// The links for comon menu (For all Roles)
export const signOutItemNavData = {
  id: 'signout-btn',
  navItemText: 'Sign Out',
  navPath: '',
  accessLevels: null,
  activeClassName: false
}

export const profileItemNavData = {
  id: 'profile-btn',
  navItemText: 'Profile',
  navPath: '/profile',
  NavIcon: SettingsIcon,
  navItemBehavior: true,
  accessLevels: [READ_LEVEL, VAC_SPECIALIST_LEVEL]
}

// The links for start-menu only for admin (With Role of Admin)
export const adminItemNavData = {
  id: 'admin-home-btn',
  navItemText: 'Pie',
  navPath: '/admin/search',
  navItemBehavior: false,
  accessLevels: [READ_LEVEL, VAC_SPECIALIST_LEVEL],
  activeClassName: false
}

export const nexeItemNavData = {
  id: 'nexe-home-btn',
  navItemText: 'NEXE',
  navPath: process.env.APP_NEXE_URL,
  navItemBehavior: false,
  navExternal: true,
  accessLevels: [READ_LEVEL, VAC_SPECIALIST_LEVEL],
  activeClassName: false
}

// The link for inherit menu Resale/Admin
export const switchToItemNavData = {
  id: 'switch-to-resale-btn',
  navItemText: TITLE_SUB_MENU_SWITCH_TO,
  navPath: '/',
  NavIcon: SwitchToIcon,
  navItemBehavior: true,
  accessLevels: [READ_LEVEL, VAC_SPECIALIST_LEVEL],
  activeClassName: false
}

// The links for the menu for VS users
export const ticketNexeItemNavData = {
  id: 'nexe-btn',
  navItemText: 'NEXE',
  navPath: process.env.APP_NEXE_URL,
  navExternal: true,
  NavIcon: TicketIcon,
  navItemBehavior: true,
  accessLevels: [VAC_SPECIALIST_LEVEL]
}

// The links for the menu for All users except for VS Role
export const searchItemNavData = {
  id: 'search-btn',
  navItemText: 'Search',
  navPath: '/admin/search',
  NavIcon: SearchIcon,
  navItemBehavior: true,
  accessLevels: [READ_LEVEL]
}

export const myWorkItemNavData = {
  id: 'mywork-btn',
  navItemText: 'My Work',
  navPath: '/admin/my-work',
  NavIcon: NotebookIcon,
  navItemBehavior: true,
  accessLevels: [READ_LEVEL]
}

export const unitTasksNavData = {
  id: 'unit-tasks-btn',
  navItemText: 'Unit Tasks',
  navPath: '/admin/unit-tasks',
  NavIcon: CheckBoxIcon,
  navItemBehavior: true,
  accessLevels: [UNIT_TASKS_MANAGE_LEVEL],
  counterType: UNIT_TASK_COUNTER
}

export const pmcsItemNavData = {
  id: 'pmcs-btn',
  navItemText: 'PMCs',
  navPath: '/admin/property-management-companies',
  NavIcon: HomeIcon,
  navItemBehavior: true,
  accessLevels: [READ_LEVEL]
}

export const programsItemNavData = {
  id: 'programs-btn',
  navItemText: 'Programs',
  navPath: '/admin/programs',
  NavIcon: CalendarIcon,
  navItemBehavior: true,
  accessLevels: [READ_LEVEL]
}

export const activityProvidersItemNavData = {
  id: 'aproviders-btn',
  navItemText: 'APs',
  navPath: '/admin/activity-providers',
  NavIcon: CompassIcon,
  navItemBehavior: true,
  accessLevels: [READ_LEVEL]
}

export const activityProductsItemNavData = {
  id: 'aproducts-btn',
  navItemText: 'AP Products',
  navPath: '/admin/activity-products',
  NavIcon: BicycleIcon,
  navItemBehavior: true,
  accessLevels: [READ_LEVEL]
}

export const campaignsItemNavData = {
  id: 'campaigns-btn',
  navItemText: 'Campaigns',
  navPath: '/admin/campaigns',
  NavIcon: CampaignIcon,
  navItemBehavior: true,
  accessLevels: [READ_LEVEL]
}

export const libraryItemNavData = {
  id: 'library-btn',
  navItemText: 'Library',
  navPath: '/admin/library',
  NavIcon: LocationsIcon,
  navItemBehavior: true,
  accessLevels: [READ_LEVEL]
}

export const reportsItemNavData = {
  id: 'reports-btn',
  navItemText: 'Reports',
  navPath: '/admin/reports',
  NavIcon: GraphIcon,
  navItemBehavior: true,
  accessLevels: [ADMIN_LEVEL, APPROVE_LEVEL, USER_LEVEL]
}

export const accountsItemNavData = {
  id: 'accounts-btn',
  navItemText: 'Accounts',
  navPath: '/admin/accounts',
  NavIcon: PeopleIcon,
  navItemBehavior: true,
  accessLevels: [ADMIN_LEVEL]
}

export const settingsItemNavData = {
  id: 'settings-btn',
  navItemText: 'Settings',
  navPath: '/',
  NavIcon: SettingsIcon,
  navItemBehavior: true,
  accessLevels: [ADMIN_LEVEL],
  activeClassName: false
}

export const separator = {
  id: 'separator',
  navItemText: '',
  navItemBehavior: false,
  activeClassName: false,
  className: styles.separator,
  navPath: '',
  onClick: ev => {
    ev.preventDefault()
    ev.stopPropagation()
  }
}

export const dealsItemNavData = {
  id: 'deals-btn',
  navItemText: 'Discounts',
  navPath: '/admin/deals',
  NavIcon: null,
  navItemBehavior: true,
  accessLevels: [APPROVE_LEVEL]
}

export const activitySwitherItemNavData = {
  id: 'activity-swither-btn',
  navItemText: 'AP Products',
  navPath: '/',
  NavIcon: BicycleIcon,
  navItemBehavior: true,
  accessLevels: [APPROVE_LEVEL],
  activeClassName: false
}
