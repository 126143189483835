/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { createFilterBuilder } from 'modules/FilteredSearch/helpers'
import {
  INACTIVE_FILTER,
  INACTIVE_FILTER_KEY,
  ACTIVE_FILTER_KEY,
  ACTIVE_FILTER,
  DRAFT_FILTER,
  DRAFT_FILTER_KEY,
  COMPLIMENTARY_FILTER,
  COMPLIMENTARY_FILTER_KEY,
  NON_COMPLIMENTARY_FILTER,
  NON_COMPLIMENTARY_FILTER_KEY
} from './types'

export const buildFilters = createFilterBuilder({
  [ACTIVE_FILTER_KEY]: ACTIVE_FILTER,
  [DRAFT_FILTER_KEY]: DRAFT_FILTER,
  [INACTIVE_FILTER_KEY]: INACTIVE_FILTER,
  [COMPLIMENTARY_FILTER_KEY]: COMPLIMENTARY_FILTER,
  [NON_COMPLIMENTARY_FILTER_KEY]: NON_COMPLIMENTARY_FILTER
})
