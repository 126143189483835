/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { CONFIRMATION_MODE_FIELD, CONFIRMATION_ID_VALUE } from '../PrebookInfo/PrebookForm/types'
import { RATE_ADJUSTMENTS_TITLE } from '../SeasonForm/RateAdjustments/types'

// common events
export const PROGRAM_DETAILS_EVENT = 'PROGRAM_DETAILS'
export const ELIGIBILITY_DETAILS_EVENT = 'ELIGIBILITY'
export const MARKETING_DETAILS_EVENT = 'MARKETING'
export const PREBOOK_INFO_EVENT = 'PREBOOK_INFO'
export const SEASON_DETAILS_EVENT = 'SEASON'
export const SEASON_RATE_EVENT = 'SEASON_RATE'
export const EXCLUSIVE_ACTIVITY_EVENT = 'EXCLUSIVE_ACTIVITY'
export const DELAYED_DEACTIVATION_EVENT = 'SCHEDULE_DEACTIVATION'
export const PROGRAM_STATUS_EVENT = 'STATUS_CHANGE'
export const PMS_CHANGE_EVENT = 'PMS_CHANGE'
export const PROGRAM_DEACTIVATION_BY_SYSTEM_EVENT = 'DEACTIVATION_BY_SYSTEM'
export const SEASON_APP_EXCLUSION_BY_SYSTEM_EVENT = 'SEASON_APP_EXCLUSION_BY_SYSTEM'
export const SMART_HOST_FEE_EVENT = 'SMART_HOST_FEE'
export const SMART_HOST_MOBILE_FEE_EVENT = 'SMART_HOST_MOBILE_FEE'
export const UNIT_ASSIGNMENT_EVENT = 'UNIT_ASSIGNMENT'
export const UNIT_SYSTEM_ASSIGNMENT_EVENT = 'UNIT_SYSTEM_ASSIGNMENT'
export const UNIT_UPDATE_EVENT = 'UNIT_UPDATE'
export const UNIT_SYSTEM_UPDATE_EVENT = 'UNIT_SYSTEM_UPDATE'

// system events
export const DEACTIVATION_DELAYED_EVENT = 'DEACTIVATION_DELAYED'
export const PMC_DEACTIVATION_EVENT = 'PMC_DEACTIVATION'
export const PMC_DEACTIVATION_DELAYED_EVENT = 'PMC_DEACTIVATION_DELAYED'
export const LAST_APP_DEACTIVATION_EVENT = 'LAST_APP_DEACTIVATION'
export const LAST_APP_DEACTIVATION_DELAYED_EVENT = 'LAST_APP_DEACTIVATION_DELAYED'
export const LAST_APP_DEACTIVATION_BY_AP_EVENT = 'LAST_APP_DEACTIVATION_BY_AP'
export const LAST_APP_DEACTIVATION_BY_AP_DELAYED_EVENT = 'LAST_APP_DEACTIVATION_BY_AP_DELAYED'
export const APP_DEACTIVATION_EVENT = 'DEACTIVATION_BY_USER'
export const APP_DEACTIVATION_DELAYED_EVENT = 'DEACTIVATION_DELAYED'
export const AP_DEACTIVATION_EVENT = 'DEACTIVATION_BY_AP'
export const AP_DEACTIVATION_DELAYED_EVENT = 'DEACTIVATION_BY_AP_DELAYED'

// system history labels
export const DEACTIVATION_DELAYED_LABEL = 'The Program was set to deactivate'
export const PMC_DEACTIVATION_LABEL = 'PMC was deactivated'
export const PMC_DEACTIVATION_DELAYED_LABEL = 'PMC was set to deactivate'
export const LAST_APP_DEACTIVATION_LABEL = 'AP Product was deactivated'
export const LAST_APP_DEACTIVATION_DELAYED_LABEL = 'AP Product was set to deactivate'
export const LAST_APP_DEACTIVATION_BY_AP_LABEL = 'Activity Provider was deactivated'
export const LAST_APP_DEACTIVATION_BY_AP_DELAYED_LABEL = 'Activity Provider was set to deactivate'
export const APP_DEACTIVATION_LABEL = 'AP Product was deactivated'
export const APP_DEACTIVATION_DELAYED_LABEL = 'AP Product was set to deactivate'
export const AP_DEACTIVATION_LABEL = 'Activity Provider was deactivated'
export const AP_DEACTIVATION_DELAYED_LABEL = 'Activity Provider was set to deactivate'

// program history fields
export const BILLING_TYPE_FIELD = 'billingType'
export const COMMENCEMENT_DATE_FIELD = 'commencementDate'
export const KEYWORD_FIELD = 'keyword'
export const MARKET_NAME_FIELD = 'marketName'
export const NAME_FIELD = 'name'
export const PMC_DESTINATION_FIELD = 'pmcDestination'
export const PMC_NAME_FIELD = 'pmcName'
export const TIME_ZONE_FIELD = 'timeZone'
export const UNIQUE_PHONE_NUMBER_FIELD = 'uniquePhoneNumber'
export const XE_PROGRAM_ID_FIELD = 'xeProgramId'
export const XPLORIE_MOBILE_URL_FIELD = 'xplorieMobileUrl'
export const COMPANY_ID_FIELD_LABEL = 'Company Ids'
export const DELAYED_DEACTIVATION_FIELD = 'deactivationDate'
export const DELAYED_DEACTIVATION_LABEL = 'Delayed Deactivation'
export const PMS_CHANGE_FIELD = 'pmsName'
export const PMS_CHANGE_LABEL = 'PMS name'
export const NETSUITE_ID_FIELD = 'netsuiteId'
export const SMART_HOST_ENABLED_FIELD = 'smartHostEnabled'
export const SMART_HOST_MOBILE_FIELD = 'smartHostMobile'
export const SMART_HOST_EMAIL_CADENCE_FIELD = 'smartHostEmailCadence'
export const SMART_HOST_CALENDAR_INTEGRATION_FIELD = 'smartHostCalendarIntegration'
export const AMAZON_PROVISIONING_ACCOUNT_FIELD = 'amazonProvisioningAccount'
export const AMAZON_PROVISIONING_PASSWORD_FIELD = 'amazonProvisioningPassword'
// marketing history fields
export const TEXT_LANDING_SITE_URL_FIELD = 'landingSiteUrl'
export const PHONE_NUMBER_FIELD = 'phoneNumber'
export const ADDITIONAL_PHONE_NUMBER_FIELD = 'additionalPhoneNumber'
export const PMC_SQUARE_IMAGE_URL_FIELD = 'pmcSquareImageUrl'
export const WELCOME_HERO_IMAGE_URL_FIELD = 'welcomeHeroImageUrl'

// prebook fields
export const PREBOOK_CONFIRMATION_ID = 'prebookConfirmationId'
export const PREBOOK_RATE_LABEL = 'Prebook Rate'
export const PREBOOK_RATE_DATE_LABEL = 'Prebook Rate Date'
export { CONFIRMATION_MODE_FIELD, CONFIRMATION_ID_VALUE }

// season history fields
export const CHARGE_METHOD_FIELD = 'chargeMethod'
export const START_DATE_FIELD = 'startDate'
export const END_DATE_FIELD = 'endDate'
export const FEE_DATE_FIELD = 'fee'
export const SEASON_NAME_FIELD = 'seasonName'

// unit history fields
export const UNIT_PROGRAM_FIELD = 'program'
export const UNIT_STATUS_FIELD = 'status'
export const UNIT_STATUS_NOTE_FIELD = 'statusNote'
export const UNIT_STATUS_DATE_FIELD = 'statusDate'

// unit history labels
export const UNIT_PROGRAM_LABEL = 'Program'
export const UNIT_STATUS_LABEL = 'Status'
export const UNIT_STATUS_NOTE_LABEL = 'Status Note'
export const UNIT_STATUS_DATE_LABEL = 'Status Date'

// rate adjustments
export { RATE_ADJUSTMENTS_TITLE }

export {
  BILLING_TYPE_FIELD_LABEL,
  LABEL_KEYWORD,
  LABEL_DESTINATION,
  LABEL_PMC_NAME,
  LABEL_PROGRAM_COMMENCEMENT_DATE,
  LABEL_PROGRAM_NAME,
  LABEL_TIME_ZONE,
  LABEL_UNIC_PHONE_NUMBER,
  LABEL_XE_PROGRAM_ID,
  LABEL_XPLORIE_MOBILE_URL,
  MARKET_LABEL,
  PROGRAM_TYPE_FIELD_LABEL,
  PROGRAM_TYPE_FIELD_KEY,
  COMPANY_ID_FIELD_KEY,
  PROGRAM_RENEWAL_DATE_LABEL,
  PROGRAM_RENEWAL_DATE_FIELD,
  DISABLE_UNITS_LABEL,
  DISABLE_UNITS_FIELD,
  DISABLE_RESERVATION_LABEL,
  DISABLE_RESERVATION_FIELD,
  ON_E_COMMERCE_LABEL,
  ON_E_COMMERCE_FIELD,
  NETSUITE_ID_LADEL
} from '../ProgramsForm/types'
export {
  ADDITIONAL_PHONE_NUMBER_LABEL,
  CARD_FORM_URL_FIELD,
  CARD_FORM_URL_LABEL,
  CHECK_IN_FOLDER_FIELD,
  CHECK_IN_FOLDER_LABEL,
  CADENCE_TEXT_LABEL,
  CADENCE_TEXT_FIELD,
  CONFIRMATION_IMG_URL_FIELD,
  CONFIRMATION_IMG_URL_LABEL,
  DESTINATION_TITLE_FIELD,
  DESTINATION_TITLE_LABEL,
  EMAIL_CADENCE_FIELD,
  EMAIL_CADENCE_LABEL,
  EMAIL_FIELD,
  EMAIL_LABEL,
  PHONE_IMAGE_URL_FIELD,
  PHONE_IMAGE_URL_LABEL,
  PHONE_NUMBER_LABEL,
  PMC_DESCRIPTION_FIELD,
  PMC_DESCRIPTION_LABEL,
  PMC_SQUARE_IMAGE_URL_LABEL,
  TEXT_LANDING_SITE_URL_LABEL,
  WELCOME_HERO_IMAGE_URL_LABEL,
  SMART_HOST_LABEL,
  SMART_HOST_MOBILE_LABEL,
  CALENDAR_INTEGRATION_LABEL,
  CADENCE_EMAIL_FIELD,
  CADENCE_EMAIL_LABEL,
  AMAZON_PROVISIONING_ACCOUNT_LABEL,
  AMAZON_PROVISIONING_PASSWORD_LABEL
} from '../MarketingMaterials/MarketingMaterialsForm/types'
export { PREBOOK_CONFIRMATION_LABEL } from '../PrebookInfo/PrebookForm/types'
export { RATE_VALUE_FIELD, START_VALUE_FIELD } from '../PrebookInfo/PrebookRateForm/types'
export { LABEL_END_DATE, LABEL_FEE, LABEL_START_DATE, LABEL_SEASON_NAME } from '../SeasonForm/types'
export { SMART_HOST_FEE_TITLE, SMART_HOST_MOBILE_FEE_TITLE } from '../SmartHostFee/types'

const ELIGIBILITY_OPTION_LABEL = 'Eligibility'
const ELIGIBILITY_OPTION_VALUE = 'ELIGIBILITY'
const MARKETING_OPTION_LABEL = 'Marketing'
const MARKETING_OPTION_VALUE = 'MARKETING'
const PROGRAM_OPTION_LABEL = 'Program'
const PROGRAM_OPTION_VALUE = 'PROGRAM'
const SEASONS_OPTION_LABEL = 'Seasons'
const SEASONS_OPTION_VALUE = 'SEASONS'
const PMS_CHANGE_OPTION_LABEL = 'PMS change'
const PMS_CHANGE_OPTION_VALUE = 'PMS_CHANGE'
const UNIT_OPTION_LABEL = 'Unit'
const UNIT_OPTION_VALUE = 'UNIT'

export const PROGRAM_HISTORY_FILTER_OPTIONS = [
  { label: ELIGIBILITY_OPTION_LABEL, field: ELIGIBILITY_OPTION_VALUE, value: true },
  { label: MARKETING_OPTION_LABEL, field: MARKETING_OPTION_VALUE, value: true },
  { label: PMS_CHANGE_OPTION_LABEL, field: PMS_CHANGE_OPTION_VALUE, value: false },
  { label: PROGRAM_OPTION_LABEL, field: PROGRAM_OPTION_VALUE, value: true },
  { label: SEASONS_OPTION_LABEL, field: SEASONS_OPTION_VALUE, value: true },
  { label: UNIT_OPTION_LABEL, field: UNIT_OPTION_VALUE, value: false }
]
