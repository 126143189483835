import PropTypes from 'prop-types'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { useOnClickOutside, useDidMount } from 'hooks'
import classNames from 'classnames'
import { SCHEDULE_TYPE } from 'modules/Closures/types'
import styles from './styles.scss'

export function ClosurePopover({ x, y, onClose, item, header }) {
  const [position, setPosition] = useState(null)
  const popupRef = useRef()
  const message = item.type === SCHEDULE_TYPE ? item.note : item.reason

  useOnClickOutside(popupRef, onClose)

  const handleClose = () => {
    onClose()
  }

  useDidMount(() => {
    window.addEventListener('resize', handleClose)
    return () => {
      window.removeEventListener('resize', handleClose)
    }
  })

  useLayoutEffect(() => {
    const offset = window.innerWidth - x

    if (offset <= 300) {
      setPosition(styles.leftPosition)
    } else if (position) {
      setPosition(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x])

  return (
    <div
      ref={popupRef}
      className={classNames(styles.wrapper, position)}
      style={{ left: x, top: y }}
    >
      <div className={styles.header}>
        {header}
        <div className={styles.cross} onClick={handleClose} />
      </div>
      <div className={styles.body}>
        <div className={styles.dot} />
        {`${item.time} ${message}`}
      </div>
    </div>
  )
}

ClosurePopover.propTypes = {
  onClose: PropTypes.func.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  header: PropTypes.node.isRequired,
  item: PropTypes.shape({
    time: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    note: PropTypes.string
  }).isRequired
}
