/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { withFormContext } from 'components/FormComponents/helpers'
import { withBlankValidation } from 'helpers/formHelpers'

import { Select } from './Select'

function GroupSelectFieldComponent(props) {
  const { fieldName, validate, isRequired, ...rest } = props

  const validateStrategy = useMemo(() => (isRequired ? withBlankValidation(validate) : validate), [
    isRequired,
    validate
  ])

  return (
    <Select
      isRequired={isRequired}
      field={fieldName}
      validate={validateStrategy}
      validateOnChange
      {...rest}
    />
  )
}

GroupSelectFieldComponent.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validate: PropTypes.func
}

GroupSelectFieldComponent.defaultProps = {
  isRequired: false,
  validate: () => null
}

export const GroupSelectField = withFormContext(GroupSelectFieldComponent)
