/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { combineReducers } from 'redux'

import programUnitsList from './programUnitsList'
import pmcProgramUnitsList from './pmcProgramUnitsList'
import programUnits from './programUnits'
import unitsLifeCycle from './unitsLifeCycle'
import unitsTemplates from './unitsTemplates'

export default combineReducers({
  programUnitsList,
  pmcProgramUnitsList,
  programUnits,
  unitsLifeCycle,
  unitsTemplates
})
