/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  getQueryStringParams,
  buildRequestParams
} from 'helpers/searchParamsHelper'

export const setReportType = (props, type) => () => {
  const { setReportType: setReportTypeAction, history, location } = props
  if (type && setReportTypeAction) {
    setReportTypeAction(type)
    const params = getQueryStringParams(location.search)
    history.push({
      search: buildRequestParams({ ...params, type })
    })
  }
}
