/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useCallback, useContext, useRef, useState } from 'react'
import get from 'lodash/get'
import { DiscountContext } from '../helpers'
import { convertToFormValues, convertToPayload } from './helpers'
import { ACTIVITY_FIELD, TYPE_FIELD } from './types'

export function useDealOptionsApi() {
  const { api } = useContext(DiscountContext)

  return api.dealOptions
}

export function useDealOptionsState() {
  const { state } = useContext(DiscountContext)

  return state.optionsFormState
}

export function useDealOptionsApiBuilder() {
  const formApi = useRef()
  const [invalid, setInvalidState] = useState(false)
  const [isDisabledApp, setIsAppDisabled] = useState(false)
  const [formState, setFormState] = useState({})

  const getApi = useCallback(api => {
    formApi.current = api
  }, [])

  const setValues = useCallback(values => {
    formApi.current.setState({ values: convertToFormValues(values) })
  }, [])

  const onChange = useCallback(state => {
    setFormState(state)
    setInvalidState(state.invalid)
  }, [])

  const canChangeApp = useCallback(() => !isDisabledApp, [isDisabledApp])
  const disableApp = useCallback(() => setIsAppDisabled(true), [])
  const enableApp = useCallback(() => setIsAppDisabled(false), [])
  const setAppValue = useCallback(app => {
    formApi.current.setValue(ACTIVITY_FIELD, app)
  }, [])
  const setProgramValue = useCallback(value => {
    formApi.current.setValue('program', value)
  }, [])
  const setProgramOptionValue = useCallback(value => {
    formApi.current.setValue('programOption', value)
  }, [])
  const submit = useCallback(async () => {
    await formApi.current.submitForm()
    const state = formApi.current.getState()
    if (state.invalid) {
      return null
    }
    return convertToPayload(state.values)
  }, [])

  const canSubmit = useCallback(() => !invalid, [invalid])

  const getTypeFromValues = useCallback(values => get(values, TYPE_FIELD), [])
  const getActivityFromValues = useCallback(values => get(values, ACTIVITY_FIELD), [])

  return [
    formState,
    {
      getApi,
      setValues,
      onChange,
      submit,
      canChangeApp,
      disableApp,
      enableApp,
      setAppValue,
      setProgramValue,
      canSubmit,
      getTypeFromValues,
      getActivityFromValues,
      setProgramOptionValue
    }
  ]
}
