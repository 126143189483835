/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'

export const buildCountCharactersError = count =>
  `Input must contain at least ${count} characters`

export const canFetch = (value, minCountCharacters) => {
  const checkValue = isEmpty(value) || value.length > minCountCharacters - 1
  return isString(value) && checkValue
}
