/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actions as notifActions } from 'components/ReduxNotifications'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  UNKNOWN_ACTION_TYPE,
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_NEED_UPDATE,
  DATA_STATE_ERROR,
  RESET_STATE
} from 'helpers/types/actionHelpersTypes'
import { MESSAGE_KIND_INFO, messagesKinds } from 'helpers/types/messageHelpersTypes'
import { getMessageByResponse } from 'helpers/messageHelper'

const { notifSend } = notifActions

const actionCreater = (type = UNKNOWN_ACTION_TYPE, payload = null) => ({
  type,
  payload
})

const messageCreator = (options = {}) => {
  const response = get(options, 'response')
  if (response) {
    const { message, delay, kind } = getMessageByResponse(response)
    return {
      message: {
        id: `${Date.now()}.${message.toString()}`,
        kind,
        message,
        dismissAfter: delay
      },
      send: notifSend
    }
  }

  const optionsKind = get(options, 'kind')
  const message = get(options, 'message', 'Undefined Message')
  const dismissAfterOptions = get(options, 'dismissAfter')
  const isValidKind = messagesKinds.some(item => item === options.kind)
  const messageKind = optionsKind && isValidKind ? optionsKind : MESSAGE_KIND_INFO
  const dismissAfter =
    dismissAfterOptions && isNumber(dismissAfterOptions) ? dismissAfterOptions : 3000
  return {
    message: {
      id: `${Date.now()}.${message.toString()}`,
      kind: messageKind,
      message: String(message),
      dismissAfter
    },
    send: notifSend
  }
}

export {
  actionCreater,
  messageCreator,
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  UNKNOWN_ACTION_TYPE,
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_NEED_UPDATE,
  DATA_STATE_ERROR,
  RESET_STATE
}

export default {
  actionCreater,
  messageCreator,
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  UNKNOWN_ACTION_TYPE,
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_NEED_UPDATE,
  DATA_STATE_ERROR,
  RESET_STATE
}
