/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './CollapseWrapper.scss'

export function CollapseWrapper(props) {
  const {
    title,
    onOpen,
    onClose,
    disabled,
    children,
    expand,
    renderHeader,
    wrapperClassName,
    headerClassName,
    contentClassName,
    onChange
  } = props
  const [isOpen, setIsOpenState] = useState(expand)

  const toggleHandler = () => {
    setIsOpenState(!isOpen)
  }

  const onOpenHandler = () => {
    if (onOpen) {
      onOpen()
    }
  }

  const onCloseHandler = () => {
    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    if (isOpen !== expand) {
      setIsOpenState(expand)
    }
  }, [expand])

  useEffect(() => {
    if (isOpen) {
      onOpenHandler()
    } else {
      onCloseHandler()
    }
    onChange(isOpen)
  }, [isOpen])

  return (
    <div className={classNames(wrapperClassName)}>
      <div className={classNames(headerClassName)} onClick={toggleHandler}>
        {renderHeader ? (
          renderHeader(props)
        ) : (
          <>
            <h1 className={styles.title}>{title}</h1>
            {!disabled && <div className={styles.arrow} />}
          </>
        )}
      </div>
      <div className={classNames(contentClassName, { [styles.isClose]: !isOpen })}>{children}</div>
    </div>
  )
}

CollapseWrapper.propTypes = {
  expand: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  renderHeader: PropTypes.func,
  wrapperClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  onChange: PropTypes.func
}
CollapseWrapper.defaultProps = {
  expand: false,
  title: '',
  onOpen: () => null,
  onClose: () => null,
  disabled: false,
  renderHeader: null,
  wrapperClassName: styles.wrapper,
  headerClassName: styles.header,
  contentClassName: styles.content,
  onChange: () => null
}
