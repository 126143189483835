/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const { REQUEST_FREE_LIST, RESPONSE_FREE_LIST, RESPONSE_ERROR_FREE_LIST, RESET_FREE_LIST } = helpers

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  data: [],
  itemsCount: 0
}

const uniqFreePhoneNumListReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FREE_LIST:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_FREE_LIST:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        data: action.payload
      }
    case RESPONSE_ERROR_FREE_LIST:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case RESET_FREE_LIST:
      return initialState
    default:
      return state
  }
}

export default filterActions(uniqFreePhoneNumListReducer, [
  REQUEST_FREE_LIST,
  RESPONSE_FREE_LIST,
  RESPONSE_ERROR_FREE_LIST,
  RESET_FREE_LIST
])
