import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Popover } from 'antd'
import { withFormContext } from 'components/FormComponents/helpers/withFormContext'
import { ADD_TIME_SLOT_TITLE, EDIT_TIME_SLOT_TITLE } from './types'
import styles from './styles.scss'
import { TimePeriodsForm } from './TimePeriodsForm'

function TimePeriodsPopoverComponent({ disabled, children, onSubmit, initialValues, isEdit }) {
  const [visible, setVisible] = useState(false)
  const onClose = () => setVisible(false)

  const handleSubmit = values => {
    onSubmit(values)
    onClose()
  }

  useEffect(() => {
    if (disabled && visible) {
      setVisible(false)
    }
  }, [visible, disabled])

  return (
    <Popover
      title={isEdit ? EDIT_TIME_SLOT_TITLE : ADD_TIME_SLOT_TITLE}
      trigger="click"
      content={
        <TimePeriodsForm onClose={onClose} onSubmit={handleSubmit} initialValues={initialValues} />
      }
      visible={visible}
      onVisibleChange={setVisible}
      overlayClassName={styles.overlay}
      destroyTooltipOnHide
    >
      {children}
    </Popover>
  )
}

TimePeriodsPopoverComponent.propTypes = {
  disabled: PropTypes.bool,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
}

TimePeriodsPopoverComponent.defaultProps = {
  disabled: false,
  initialValues: null,
  children: null,
  isEdit: false
}

export const TimePeriodsPopover = withFormContext(TimePeriodsPopoverComponent)
