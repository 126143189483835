/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

const LIST_NAME = 'TICKET_GROUPS_LIST'
const RE_ORDER_LIST = `${LIST_NAME}_RE_ORDER`
const RE_ORDER_TICKET_TYPES = `${LIST_NAME}_RE_ORDER_TICKET_TYPES`

export default {
  LIST_NAME,
  RE_ORDER_LIST,
  RE_ORDER_TICKET_TYPES
}
