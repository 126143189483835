/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { compose } from 'redux'
import { asField, withFormApi } from 'informed'
import { withFormContext } from 'components/FormComponents/helpers'

import { CheckBoxField as CheckBoxFieldComponent } from './CheckBoxField'

export const CheckBoxField = compose(
  asField,
  withFormApi,
  withFormContext
)(CheckBoxFieldComponent)
