/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'

export function PIEIcon(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path d="M49.93 39.42C49.93 28.52 50 17.55 49.93 6.64C49.94 5.11 50.36 4.63 51.89 4.64C62.79 4.85 72 9 79.87 16.35C80.49 16.91 80.84 17.32 80 18.15C70.22 27.74 60.43 37.4 50.72 47C50.58 47.2 50.37 47.34 49.88 47.75C49.9 44.7 49.92 42.06 49.93 39.42Z" />
      <path d="M52.78 47.64C62.15 38.26 71.53 28.96 80.76 19.58C81.87 18.47 82.43 18.68 83.4 19.58C89.79 26.04 93.33 33.75 94.65 42.64C94.86 44.31 94.86 45.97 95.07 47.64C95.21 48.75 94.72 49.03 93.68 49.03C87.29 48.96 80.9 49.03 74.51 49.03C67.22 49.03 59.93 49.03 52.71 49.03C52.43 49.03 52.15 48.96 51.53 48.96C52.08 48.33 52.43 47.99 52.78 47.64Z" />
      <path d="M62.43 50.69C72.64 50.69 82.85 50.69 93.12 50.69C94.24 50.69 94.86 50.83 94.86 52.15C95 63.4 90.63 72.71 83.19 80.83C82.71 81.39 82.22 81.53 81.67 80.9C81.39 80.62 81.11 80.35 80.83 80.07C71.6 70.83 62.29 61.53 52.99 52.29C52.57 51.87 51.94 51.6 51.46 51.32C51.67 51.11 51.81 50.9 51.94 50.69C55.42 50.69 58.96 50.69 62.43 50.69Z" />
      <path d="M51.67 53.47C61.04 62.78 70.35 72.15 79.72 81.39C80.83 82.5 80.69 83.06 79.65 84.1C71.88 91.81 62.43 95.35 51.6 95.69C50.63 95.76 50.28 95.42 50.28 94.38C50.42 80.56 50.35 66.6 50.35 52.57C51.04 52.71 51.32 53.13 51.67 53.47Z" />
      <path d="M48.61 54.03C48.61 67.36 48.61 80.69 48.68 93.96C48.68 95.35 48.26 95.76 46.94 95.76C37.5 95.9 29.24 92.64 21.87 87.01C20.49 85.97 18.33 84.93 18.19 83.4C18.06 81.94 20.21 80.9 21.39 79.65C29.86 71.11 38.4 62.64 46.94 54.1C47.36 53.68 47.71 53.13 48.06 52.64C48.26 52.71 48.4 52.85 48.61 52.92C48.61 53.26 48.61 53.68 48.61 54.03Z" />
      <path d="M46.11 52.08C36.81 61.53 27.36 70.97 17.99 80.42C17.08 81.32 16.6 81.46 15.63 80.49C8.54 73.47 4.93 64.86 3.96 55.07C3.96 54.93 3.96 54.72 3.89 54.58C3.61 50.97 3.61 50.97 7.15 50.97C19.72 50.97 32.36 50.97 44.93 50.97C45.56 50.97 46.18 50.97 47.15 50.97C46.67 51.53 46.39 51.88 46.11 52.08Z" />
      <path d="M45.49 49.03C32.29 49.03 19.1 49.03 5.83 49.17C4.31 49.17 3.89 48.82 3.89 47.22C3.96 36.32 8.13 27.08 15.49 19.17C16.04 18.61 16.39 18.19 17.15 18.96C26.87 28.75 36.67 38.54 46.46 48.26C46.67 48.47 46.74 48.61 46.88 48.82C46.39 49.17 45.9 49.03 45.49 49.03Z" />
      <path d="M41.84 41.69C34.15 33.96 26.46 26.13 18.72 18.45C17.65 17.36 17.61 16.72 18.7 15.65C26.57 8.11 36.03 4.56 46.79 4.22C47.63 4.18 48.16 4.23 48.15 5.41C47.98 19.11 47.86 32.86 47.74 46.51C47.78 46.75 47.73 47 47.68 47.64C45.54 45.46 43.69 43.57 41.84 41.69Z" />
    </svg>
  )
}
