/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import moment from 'moment'
import isArray from 'lodash/isArray'
import { FORMAT } from 'constants/date'
import { validationTypes } from 'modules/Programs/ProgramSeasons'

import {
  BEGIN_END_MONTH_ERROR_MESSAGE,
  NULL_ACTIVITIES_ERROR_MESSAGE,
  INCCORECT_DATE_SEASON_ERROR_MESSAGE,
  UNKNOWN_ERROR_MESSAGE,
  DATE_INTERSECTIONS_SEASONS_ERROR_MESSAGE,
  NULL_SEASONS_ERROR_MESSAGE
} from './types'

const {
  BEGIN_END_DATE_SEASON_ERROR_KEY,
  NULL_ACTIVITIES_SEASONS_ERROR_KEY,
  INCORRECT_DATE_SEASON_ERROR_KEY,
  DATE_INTERSECTIONS_SEASONS_ERROR_KEY,
  NULL_SEASONS_ERROR_KEY
} = validationTypes

export const buildErrorMessageByType = errorKey => {
  switch (errorKey) {
    case BEGIN_END_DATE_SEASON_ERROR_KEY:
      return BEGIN_END_MONTH_ERROR_MESSAGE
    case NULL_ACTIVITIES_SEASONS_ERROR_KEY:
      return NULL_ACTIVITIES_ERROR_MESSAGE
    case INCORRECT_DATE_SEASON_ERROR_KEY:
      return INCCORECT_DATE_SEASON_ERROR_MESSAGE
    case DATE_INTERSECTIONS_SEASONS_ERROR_KEY:
      return DATE_INTERSECTIONS_SEASONS_ERROR_MESSAGE
    case NULL_SEASONS_ERROR_KEY:
      return NULL_SEASONS_ERROR_MESSAGE
    default:
      return UNKNOWN_ERROR_MESSAGE
  }
}

export const buildErrors = (errors = []) => {
  if (!errors || !errors.length) {
    return []
  }
  return errors.reduce(
    (acc, value, index) => [
      ...acc,
      ...Object.keys(value).map(key => ({
        id: `${key}_${index}`,
        message: `${buildErrorMessageByType(key)} ${
          value[key].meta && value[key].meta.name ? `(${value[key].meta.name})` : ''
        }`
      }))
    ],
    []
  )
}

export const builldDayPickerDates = (data = {}) => {
  const output = { selectedDates: [], conflicts: [] }

  if (!data || !data.values || !isArray(data.values)) {
    return output
  }
  output.selectedDates = data.values.map(value => ({
    from: moment(value.startDate, FORMAT).toDate(),
    to: moment(value.endDate, FORMAT).toDate()
  }))
  if (data.errors && data.errors.length) {
    output.conflicts = data.errors
      .filter(value => Boolean(value[DATE_INTERSECTIONS_SEASONS_ERROR_KEY]))
      .map(value => value[DATE_INTERSECTIONS_SEASONS_ERROR_KEY])
      .map(value => ({
        from: moment(value.startDate, FORMAT).toDate(),
        to: moment(value.endDate, FORMAT).toDate()
      }))
  }
  return output
}
