/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { selectors } from 'reducers/marketDictionary/marketDictionaryForm'

const { getMarketFormDataStatesById, getMarketFormIds } = selectors

function getMarketListState(state) {
  return state.marketDictionary.marketDictionaryList
}

function getMarketListWatchers(state) {
  const marketFormsWatchers = getMarketFormIds(state).reduce(
    (acc, marketFormId) => [...acc, ...getMarketFormDataStatesById(state, marketFormId)],
    []
  )
  return marketFormsWatchers
}

export default {
  getMarketListState,
  getMarketListWatchers
}
