/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import {
  createScheduleSeries,
  updateScheduleOccurrenceById,
  getScheduleOccurrenceByIdAndDay,
  updateScheduleSeriesById,
  getScheduleSeriesById,
  cancelScheduleSeriesById,
  cancelScheduleOccurrenceById,
  activateScheduleSeriesById,
  activateScheduleOccurenceById
} from 'services/http/schedules'
import helpers, { buildKey } from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA_ERROR,
  RESET_DATA_STATE,
  SUCCESS_ADD_MESSAGE,
  SUCCESS_UPDATED_MESSAGE,
  ERROE_UPDATED_MESSAGE,
  REQUEST_CANCEL_SERIES,
  RESPONSE_CANCEL_SERIES,
  RESPONSE_CANCEL_SERIES_ERROR,
  ERROR_CANCEL_SCHEDULE_MESSAGE,
  SUCCESS_CANCEL_SCHEDULE_MESSAGE,
  REQUEST_CANCEL_OCCURRENCE,
  RESPONSE_CANCEL_OCCURRENCE,
  RESPONSE_CANCEL_OCCURRENCE_ERROR,
  SUCCESS_ACTIVATE_SCHEDULE_MESSAGE,
  ERROR_ACTIVATE_SCHEDULE_MESSAGE,
  REQUEST_ACTIVATE_SERIES,
  RESPONSE_ACTIVATE_SERIES,
  RESPONSE_ACTIVATE_SERIES_ERROR,
  REQUEST_ACTIVATE_OCCURRENCE,
  RESPONSE_ACTIVATE_OCCURRENCE,
  RESPONSE_ACTIVATE_OCCURRENCE_ERROR
} = helpers

const createSchedule = (activityId, contractData) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_FORM, { key }))
    const request = await createScheduleSeries(activityId, contractData)
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: SUCCESS_ADD_MESSAGE }))
    dispatch(actionCreater(RESPONSE_FORM, { data: request.data, key }))

    return Promise.resolve(request.data)
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))

    return Promise.reject()
  }
}

const getScheduleById = (activityId, id) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_GET_ENTITY_DATA, { key }))
    const request = await getScheduleSeriesById(activityId, id)
    dispatch(actionCreater(RESPONSE_GET_ENTITY_DATA, { data: request.data, key }))

    return Promise.resolve(request.data)
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ENTITY_DATA_ERROR, { key }))

    return Promise.reject()
  }
}

const updateScheduleSeries = (activityId, seriesId, contractData) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_FORM, { key }))
    const request = await updateScheduleSeriesById(activityId, seriesId, contractData)
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: SUCCESS_UPDATED_MESSAGE }))
    dispatch(actionCreater(RESPONSE_FORM, { data: contractData, key }))

    return Promise.resolve(contractData)
  } catch ({ send, message }) {
    dispatch(send({ ...message, message: ERROE_UPDATED_MESSAGE }))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))

    return Promise.reject()
  }
}

const updateScheduleOccurrence = (activityId, seriesId, contractData) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_FORM, { key }))
    const request = await updateScheduleOccurrenceById(activityId, seriesId, contractData)
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: SUCCESS_UPDATED_MESSAGE }))
    dispatch(actionCreater(RESPONSE_FORM, { data: contractData, key }))

    return Promise.resolve(contractData)
  } catch ({ send, message }) {
    dispatch(send({ ...message, message: ERROE_UPDATED_MESSAGE }))
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))

    return Promise.reject()
  }
}

const getOccurrenceByIdAndDay = (activityId, seriesId, day) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_GET_ENTITY_DATA, { key }))
    const request = await getScheduleOccurrenceByIdAndDay(activityId, seriesId, day)
    dispatch(actionCreater(RESPONSE_GET_ENTITY_DATA, { data: request.data, key }))

    return Promise.resolve(request.data)
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ENTITY_DATA_ERROR, { key }))

    return Promise.reject()
  }
}

const activateSeriesById = (activityId, seriesId, contractData) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_ACTIVATE_SERIES, { key }))
    const request = await activateScheduleSeriesById(activityId, seriesId, contractData)
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_ACTIVATE_SERIES, { data: request.data, key }))
    dispatch(send({ ...message, message: SUCCESS_ACTIVATE_SCHEDULE_MESSAGE }))

    return Promise.resolve(request.data)
  } catch ({ send, message }) {
    dispatch(send({ ...message, message: ERROR_ACTIVATE_SCHEDULE_MESSAGE }))
    dispatch(actionCreater(RESPONSE_ACTIVATE_SERIES_ERROR, { key }))

    return Promise.reject()
  }
}

const activateOccurrenceById = (activityId, contractData) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_ACTIVATE_OCCURRENCE, { key }))
    const request = await activateScheduleOccurenceById(activityId, contractData)
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_ACTIVATE_OCCURRENCE, { data: request.data, key }))
    dispatch(send({ ...message, message: SUCCESS_ACTIVATE_SCHEDULE_MESSAGE }))

    return Promise.resolve(request.data)
  } catch ({ send, message }) {
    dispatch(send({ ...message, message: ERROR_ACTIVATE_SCHEDULE_MESSAGE }))
    dispatch(actionCreater(RESPONSE_ACTIVATE_OCCURRENCE_ERROR, { key }))

    return Promise.reject()
  }
}

const cancelSeriesById = (activityId, seriesId, contractData) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_CANCEL_SERIES, { key }))
    const request = await cancelScheduleSeriesById(activityId, seriesId, contractData)
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_CANCEL_SERIES, { data: request.data, key }))
    dispatch(send({ ...message, message: SUCCESS_CANCEL_SCHEDULE_MESSAGE }))

    return Promise.resolve(request.data)
  } catch ({ send, message }) {
    dispatch(send({ ...message, message: ERROR_CANCEL_SCHEDULE_MESSAGE }))
    dispatch(actionCreater(RESPONSE_CANCEL_SERIES_ERROR, { key }))

    return Promise.reject()
  }
}

const cancelOccurrenceById = (activityId, contractData) => async dispatch => {
  const key = buildKey(activityId)
  try {
    dispatch(actionCreater(REQUEST_CANCEL_OCCURRENCE, { key }))
    const request = await cancelScheduleOccurrenceById(activityId, contractData)
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_CANCEL_OCCURRENCE, { data: request.data, key }))
    dispatch(send({ ...message, message: SUCCESS_CANCEL_SCHEDULE_MESSAGE }))

    return Promise.resolve(request.data)
  } catch ({ send, message }) {
    dispatch(send({ ...message, message: ERROR_CANCEL_SCHEDULE_MESSAGE }))
    dispatch(actionCreater(RESPONSE_CANCEL_OCCURRENCE_ERROR, { key }))

    return Promise.reject()
  }
}

const resetState = accessKey => dispatch =>
  dispatch(actionCreater(RESET_DATA_STATE, { key: buildKey(accessKey) }))

export default {
  createSchedule,
  resetState,
  updateScheduleOccurrence,
  getOccurrenceByIdAndDay,
  updateScheduleSeries,
  getScheduleById,
  cancelSeriesById,
  cancelOccurrenceById,
  activateSeriesById,
  activateOccurrenceById
}
