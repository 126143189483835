/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { CustomNestedField, TextField, ToggleField } from 'components/FormComponents'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { HORIZONTAL_ALIGN } from 'constants/alignTypes'
import { decimalsValidation } from 'helpers/formHelpers'
import { SimpleBox } from '@xplorie/ui-commons'

import styles from './styles.scss'
import {
  TICKET_TYPE_LABEL,
  FINAL_PRICE_LABEL,
  RETAIL_PRICE_LABEL,
  DISCOUNT_FIELD,
  DISCOUNT_LABEL,
  DISCOUNT_PLACEHOLDER,
  percentageDiscountOptions,
  PERCENTAGE_DISCOUNT_FIELD
} from './types'

export function DiscountTicketType(props) {
  const { item } = props
  const { discount, ticketTypeName, finalPrice, retailPrice } = item

  const displayFinalPrice = discount !== ''
  const wrongFinalPrice = Number(finalPrice) < 0

  return (
    <CustomNestedField field={`ticketTypes.${item.id}`}>
      <SimpleBox className={styles.dealTicketTypes}>
        <div className={styles.column}>
          <div className={styles.field}>
            <span className={styles.label}>{TICKET_TYPE_LABEL}</span>
            <span className={styles.value}>{ticketTypeName}</span>
          </div>
          <div className={styles.field}>
            <span className={styles.label}>{RETAIL_PRICE_LABEL}</span>
            <span className={styles.priceValue}>{retailPrice}</span>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.textField}>
            <TextField
              fieldProps={{
                classNameLabel: styles.label,
                align: HORIZONTAL_ALIGN,
                // eslint-disable-next-line react/prop-types
                renderInner: ({ children, error, errorClassName }) => (
                  <div className={styles.discountContainer}>
                    <div className={styles.discountWrapper}>
                      {children}
                      <ToggleField
                        field={PERCENTAGE_DISCOUNT_FIELD}
                        options={percentageDiscountOptions}
                        classNameWrapper={styles.wrapper}
                        className={styles.button}
                      />
                    </div>
                    {error && <span className={errorClassName}>{error}</span>}
                  </div>
                )
              }}
              autoComplete="off"
              label={DISCOUNT_LABEL}
              placeholder={DISCOUNT_PLACEHOLDER}
              fieldName={DISCOUNT_FIELD}
              validate={decimalsValidation}
            />
          </div>
          <div className={styles.field}>
            <span className={styles.label}>{FINAL_PRICE_LABEL}</span>
            {displayFinalPrice && (
              <span
                className={classNames(styles.finalPriceValue, { [styles.error]: wrongFinalPrice })}
              >
                {finalPrice}
              </span>
            )}
          </div>
        </div>
      </SimpleBox>
    </CustomNestedField>
  )
}

DiscountTicketType.propTypes = {
  item: PropTypes.object.isRequired
}
