/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { queuePMSForm } from 'helpers/formEventHandlers'
import { canUpdatePMSForm } from 'modules/PMS'
import { ViewEntityButton, UpdateEntityButton } from 'components/Buttons'
import { ORANGE_SCHEMA, SMALLER_SIZE, EDIT_TITLE, VIEW_TITLE } from 'constants/buttonsOptions'

export function EditButton(props) {
  const { data, authUser } = props
  const canUpdate = canUpdatePMSForm(authUser)

  const TITLE = canUpdate ? EDIT_TITLE : VIEW_TITLE
  const Button = canUpdate ? UpdateEntityButton : ViewEntityButton

  return (
    <Button
      size={SMALLER_SIZE}
      colorSchema={ORANGE_SCHEMA}
      data={data.original}
      onClick={queuePMSForm(props, data.original)()}
    >
      {TITLE}
    </Button>
  )
}

EditButton.propTypes = {
  authUser: PropTypes.object.isRequired,
  data: PropTypes.object
}

EditButton.defaultProps = {
  data: null
}
