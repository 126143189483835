/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

// Constants for buttons
export const SUBMIT_BUTTON_TEXT = 'Sign In'
export const FORGOT_PASSWORD_BUTTON_TEXT = 'Forgot your Password?'

// Constants for labels
export const PASSWORD_FIELD_LABEL = 'Password'
export const EMAIL_FIELD_LABEL = 'Email'

// Constants for placeholders
export const PASSWORD_FIELD_PLACEHOLDER = 'Password'
export const EMAIL_FIELD_PLACEHOLDER = 'Email'

//
export const INCORRECT_PASSWORD_MESSAGE = 'Incorrect email or password'
