/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  defaultLabelTooltip,
  TOP_DIRECTION,
  RIGHT_DIRECTION,
  BOTTOM_DIRECTION,
  LEFT_DIRECTION
} from './types'

import { getDirectionStyle } from './helpers'

import styles from './TooltipComponent.scss'

export function TooltipComponent(props) {
  const { labelTooltip, contentTooltip, directionOption, className } = props

  const [isShowTooltip, setShowTooltipState] = useState(false)

  const onToggleShowTooltip = opt => {
    setShowTooltipState(opt)
  }

  const directionStyles = getDirectionStyle(directionOption)

  return (
    contentTooltip && (
      <span
        onMouseOver={() => onToggleShowTooltip(true)}
        onMouseLeave={() => onToggleShowTooltip(false)}
        className={className}
      >
        {labelTooltip}
        {isShowTooltip && (
          <span className={classNames(styles.tooltipContent)}>
            <span className={classNames(styles.tooltipInnerContent, directionStyles)}>
              {contentTooltip}
            </span>
          </span>
        )}
      </span>
    )
  )
}

TooltipComponent.propTypes = {
  labelTooltip: PropTypes.string,
  contentTooltip: PropTypes.string,
  children: PropTypes.any,
  classNameOptions: PropTypes.string,
  directionOption: PropTypes.oneOf([
    TOP_DIRECTION,
    RIGHT_DIRECTION,
    BOTTOM_DIRECTION,
    LEFT_DIRECTION
  ]),
  className: PropTypes.string
}

TooltipComponent.defaultProps = {
  labelTooltip: defaultLabelTooltip,
  contentTooltip: null,
  classNameOptions: null,
  directionOption: TOP_DIRECTION,
  className: styles.tooltipMarker,
  children: null
}
