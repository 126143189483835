/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { BasicButton } from '@xplorie/ui-commons'
import get from 'lodash/get'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { useLoading } from 'hooks'
import {
  canDeactivate as canDeactivateBuilder,
  DeactivateConfirmationModal
} from 'modules/ActivityProviders'
import { DEACTIVATE_BUTTON_TEXT } from './types'

export function DeactivateButton(props) {
  const { deactivationDataState, deactivateAPById, authUser, dataId, entityData } = props
  const modalApi = useRef()
  const [isProcessing] = useLoading(deactivationDataState)
  const name = get(entityData, 'name')
  const deactivationDate = get(entityData, 'deactivationDate.date')
  const canDeactivate = canDeactivateBuilder(authUser, entityData)

  const isDisabled = isProcessing || !dataId || !canDeactivate

  const onGetApi = useCallback(api => {
    modalApi.current = api
  }, [])

  const onClickDeactivateHandler = useCallback(() => {
    modalApi.current.toggleModal()
  }, [])

  const onDeactivateHandler = useCallback(
    ({ date }) => {
      modalApi.current.toggleModal()
      if (dataId) {
        const { date: currentDate } = date
        const contractData = { currentDate, date }
        deactivateAPById(dataId, contractData)
      }
    },
    [dataId, deactivateAPById]
  )

  return (
    <>
      {!deactivationDate && (
        <BasicButton
          colorSchema={ORANGE_SCHEMA}
          disabled={isDisabled}
          onClick={onClickDeactivateHandler}
        >
          {DEACTIVATE_BUTTON_TEXT}
        </BasicButton>
      )}
      <DeactivateConfirmationModal
        dataId={dataId}
        name={name}
        getApi={onGetApi}
        date={deactivationDate}
        onAction={onDeactivateHandler}
        deactivateDataState={deactivationDataState}
      />
    </>
  )
}
DeactivateButton.propTypes = {
  deactivationDataState: PropTypes.string.isRequired,
  deactivateAPById: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired,
  dataId: PropTypes.string,
  entityData: PropTypes.object
}
DeactivateButton.defaultProps = {
  dataId: null,
  entityData: {}
}
