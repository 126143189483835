/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import {
  updateRole as updateRoleRequest,
  getRoleList as getRoleListRequest
} from 'services/http/account'
import helpers from './helpers'

const {
  REQUEST_PREDEFINED_LIST,
  RESPONSE_PREDEFINED_LIST,
  RESPONSE_PREDEFINED_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_ROLE,
  RESPONSE_ROLE,
  RESPONSE_ROLE_ERROR
} = helpers

const getRoleList = (authKey, options = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_PREDEFINED_LIST))
  const request = await getRoleListRequest(authKey, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_PREDEFINED_ERROR_LIST))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_PREDEFINED_LIST, { ...request.data }))
    return 'getRoleList success'
  }

  return 'getRoleList failed'
}

const updateRole = (authKey, accountId, roleIds) => async dispatch => {
  dispatch(actionCreater(REQUEST_ROLE))
  const request = await updateRoleRequest(authKey, accountId, roleIds).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_ROLE_ERROR))
    }
  )
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_ROLE))
    dispatch(send(message))
    return 'updateRoles success'
  }
  return 'updateRoles failed'
}

const resetList = () => ({
  type: RESET_DATA_STATE
})

export default {
  getRoleList,
  resetList,
  updateRole
}
