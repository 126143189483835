/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  httpWrapper,
  buildURLForGettingEntityById,
  buildListParams,
  buildURLForGettingExtendedList
} from 'services/http/helpers'
import { PROGRAMS_URL, PMCS_URL, ACTIVITY_URL, DISCOUNT_LIST_BY_PROGRAM_ID_URL } from 'endpoints'

export const getPreviousPrograms = (id, options) =>
  httpWrapper({
    url: `${buildURLForGettingEntityById(PROGRAMS_URL, id)}/archived`,
    method: 'get',
    params: options
  })

export const getProgramsExtendedList = (authKey, id, options) =>
  httpWrapper({
    url: buildURLForGettingExtendedList(PROGRAMS_URL, id),
    method: 'get',
    params: buildListParams(options)
  })

export const getOnboardedProgramsList = options =>
  httpWrapper({
    url: `${PROGRAMS_URL}/onboarded`,
    method: 'get',
    params: buildListParams(options)
  })

export const getProgramsByPMCId = (id, options) =>
  httpWrapper({
    url: `${PMCS_URL}/${id}/programs`,
    method: 'get',
    params: buildListParams(options)
  })

// TODO: This is call exist, but not used in system
export const getProgramsByAppId = (id, options) =>
  httpWrapper({
    url: `${ACTIVITY_URL}/${id}/programs`,
    method: 'get',
    params: buildListParams(options)
  })

export const getProgramsInfoByAppId = (id, options) =>
  httpWrapper({
    url: `${ACTIVITY_URL}/${id}/programs/info`,
    method: 'get',
    params: buildListParams(options)
  })

export const getDiscountList = (id, data, options) =>
  httpWrapper({
    url: `${DISCOUNT_LIST_BY_PROGRAM_ID_URL}/${id}/discounts`,
    method: 'post',
    data,
    params: buildListParams(options)
  })

export const getProgramsByAppIdAndTypeOffer = (id, data, options) =>
  httpWrapper({
    url: `${ACTIVITY_URL}/${id}/programs`,
    method: 'post',
    data,
    params: buildListParams(options)
  })
