/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { ACTIVITY } from 'constants/searchTypes'

const FORM_NAME = 'SEASON_FORM'
const DEFAULT_PAGE_NUMBER = 1
const DEFAULT_ITEMS_PER_PAGE = 10
const ENTITY_TYPE = ACTIVITY
const GET_SEASON_BY_ID = `${FORM_NAME}_GET_SEASON_BY_ID`
const CLONE_SEASON = `${FORM_NAME}_CLONE_SEASON`

export default {
  FORM_NAME,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ITEMS_PER_PAGE,
  ENTITY_TYPE,
  GET_SEASON_BY_ID,
  CLONE_SEASON
}

export {
  FORM_NAME,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ITEMS_PER_PAGE,
  ENTITY_TYPE,
  GET_SEASON_BY_ID,
  CLONE_SEASON
}
