/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function CopyIcon(props) {
  return (
    <svg viewBox="0 0 144 144" {...props}>
      <path d="M55.6,127.3c-10.6,0-19.1-8.6-19.1-19.1V20.7H17.3c-6,0-10.9,4.9-10.9,10.9v93c0,6,4.9,10.9,10.9,10.9h71.1 c5.1,0,9.3-3.5,10.5-8.2H55.6z" />
      <path d="M136.4,32.8L109.1,5.5c-0.8-0.8-1.8-1.2-2.9-1.2H55.6c-6,0-10.9,4.9-10.9,10.9v93c0,6,4.9,10.9,10.9,10.9 h71.1c6,0,10.9-4.9,10.9-10.9V35.7C137.6,34.7,137.2,33.6,136.4,32.8z M104.8,34.4V12.5l24.6,24.6l-21.9,0 C106,37.1,104.8,35.9,104.8,34.4z" />
    </svg>
  )
}
