const CATEGORY_LIST_NAME = 'CATEGORY_LIST'
const CATEGORY_LIST_BY_PROVIDER = 'CATEGORY_LIST_BY_PROVIDER'
const CATEGORY_LIST_COUNT_NAME = 'CATEGORY_LIST_COUNT'
const DEFAULT_OPTIONS = {
  itemsPerPage: 0
}

export default {
  CATEGORY_LIST_NAME,
  CATEGORY_LIST_COUNT_NAME,
  CATEGORY_LIST_BY_PROVIDER,
  DEFAULT_OPTIONS
}
