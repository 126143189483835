/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'informed'
import {
  FormContent,
  TextField,
  ControlButtonsContainer,
  SelectField,
  TextAreaField
} from 'components/FormComponents'
import { useUpdateSomeDataState, useLoadingDataState } from 'hooks'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { ORANGE_SCHEMA, SAVE_TITLE } from 'constants/buttonsOptions'
import { SubmitButton } from 'components/Buttons'
import { CollapseContent } from 'modules/Discounts/DiscountsForm/CollapseContent'
import {
  DEAL_NAME_LABEL,
  DEAL_TYPE_LABEL,
  DEAL_NAME_PLACEHOLDER,
  DEAL_TYPE_PLACEHOLDER,
  DESCRIPTION_LABEL,
  DESCRIPTION_PLACEHOLDER,
  dealTypeOptions
} from 'modules/Discounts/types'
import { buildCollapseTitle } from './helpers'
import { DealAvailabilityField } from './DealAvailabilityField'

import styles from './DealOptionsForm.scss'

export function DealOptionsForm(props) {
  const {
    createDeal,
    updateDeal,
    isExist,
    isCreateAllowed,
    isEditAllowed,
    createOptionsDataState,
    updateOptionsDataState,
    entityDataState,
    getFormApi,
    dealType
  } = props
  const formApi = useRef(null)
  const [isExpand, setIsExpandState] = useState(true)
  const [isSubmitDisabled, setIsSubmitDisabledState] = useState(true)
  const [isCreating] = useLoadingDataState(createOptionsDataState)
  const [isUpdating] = useLoadingDataState(updateOptionsDataState)
  const isFormDisabled = [!isEditAllowed, isCreating, isUpdating].some(value => Boolean(value))

  const formOnChangeHandler = useCallback(
    formState => {
      const { name, type, availabilities } = formState.values
      setIsSubmitDisabledState(
        Array.prototype.some.call([name, type, availabilities], value => !value)
      )
    },
    [setIsSubmitDisabledState]
  )

  const onGetApi = useCallback(
    api => {
      if (formApi.current !== api) {
        formApi.current = api
        if (getFormApi) {
          getFormApi(api)
        }
      }
    },
    [formApi, getFormApi]
  )

  const onSubmit = useCallback(
    formData => {
      if (!formData) {
        return 'empty data'
      }
      if (isEditAllowed && formData.id) {
        updateDeal(formData)
        return 'update deal'
      }
      if (isCreateAllowed) {
        createDeal(formData)
        return 'create deal'
      }
      return 'no access'
    },
    [createDeal, isCreateAllowed, isEditAllowed, updateDeal]
  )

  useUpdateSomeDataState(
    () => {
      if (isExpand) {
        setIsExpandState(!isExpand)
      }
    },
    [entityDataState, createOptionsDataState, updateOptionsDataState],
    DATA_STATE_RECIEVED
  )

  return (
    <CollapseContent
      title={buildCollapseTitle({ isExpand, dealType, isExist })}
      expand={isExpand}
      onChange={setIsExpandState}
    >
      <Form onSubmit={onSubmit} getApi={onGetApi} onChange={formOnChangeHandler}>
        <FormContent disabled={isFormDisabled}>
          <TextField
            fieldName="name"
            label={DEAL_NAME_LABEL}
            placeholder={DEAL_NAME_PLACEHOLDER}
            isRequired
          />
          <SelectField
            fieldName="type"
            label={DEAL_TYPE_LABEL}
            placeholder={DEAL_TYPE_PLACEHOLDER}
            options={dealTypeOptions}
            isRequired
          />
          <TextAreaField
            label={DESCRIPTION_LABEL}
            placeholder={DESCRIPTION_PLACEHOLDER}
            fieldName="description"
          />
          <DealAvailabilityField field="availabilities" />
          <ControlButtonsContainer className={styles.buttons}>
            {isEditAllowed && (
              <SubmitButton
                colorSchema={ORANGE_SCHEMA}
                disabled={isFormDisabled || isSubmitDisabled}
              >
                {SAVE_TITLE}
              </SubmitButton>
            )}
          </ControlButtonsContainer>
        </FormContent>
      </Form>
    </CollapseContent>
  )
}

DealOptionsForm.propTypes = {
  createDeal: PropTypes.func.isRequired,
  isExist: PropTypes.bool.isRequired,
  updateDeal: PropTypes.func.isRequired,
  isCreateAllowed: PropTypes.bool.isRequired,
  isEditAllowed: PropTypes.bool.isRequired,
  entityData: PropTypes.object,
  entityDataState: PropTypes.string.isRequired,
  createOptionsDataState: PropTypes.string.isRequired,
  updateOptionsDataState: PropTypes.string.isRequired,
  getFormApi: PropTypes.func.isRequired,
  dealType: PropTypes.string
}
DealOptionsForm.defaultProps = {
  entityData: {},
  dealType: null
}
