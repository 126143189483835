/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { compose } from 'redux'
import { asField } from 'informed'
import { withFormContext } from 'components/FormComponents/helpers'
import { SwitchField as SwitchFieldComponent } from './SwitchField'

export const SwitchField = compose(
  asField,
  withFormContext
)(SwitchFieldComponent)
