/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { BasicButton } from '@xplorie/ui-commons'

import { YELLOW_GREEN_SCHEMA } from 'constants/buttonsOptions'

export function TagElement({ currentType, type, children, onChange, ...rest }) {
  const isActive = currentType === type
  return (
    <BasicButton
      {...rest}
      onClick={e => onChange(type, e)}
      disabled={isActive}
      isActive={isActive}
      colorSchema={YELLOW_GREEN_SCHEMA}
    >
      {children}
    </BasicButton>
  )
}

TagElement.propTypes = {
  currentType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
}
