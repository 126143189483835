/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { buildSortPropertyByType } from 'helpers/tableHelpers'
import {
  buildSearchColumn,
  LOCATION,
  AND_OPERATOR,
  OPEN_GROUP_OPERATOR,
  CLOSE_GROUP_OPERATOR,
  OR_OPERATOR
} from 'helpers/searchHelpers'
import { DESTINATION_TYPE } from 'constants/locationsTypes'
import { STATUS_ACTIVE } from 'constants/entityStatus'

export const onSearch = (props = {}) => keyword => {
  const { searchByType, buildFilterParams } = props
  const filterOptions = (buildFilterParams && buildFilterParams({ keyword })) || [
    buildSearchColumn({ entity: LOCATION, field: 'type', keyword: DESTINATION_TYPE }),
    buildSearchColumn({
      entity: LOCATION,
      field: 'status',
      keyword: STATUS_ACTIVE,
      operator: AND_OPERATOR
    }),
    buildSearchColumn({
      entity: LOCATION,
      field: 'name',
      keyword,
      operator: AND_OPERATOR,
      groupOperator: OPEN_GROUP_OPERATOR
    }),
    buildSearchColumn({
      entity: LOCATION,
      field: 'altName',
      keyword,
      operator: OR_OPERATOR,
      groupOperator: CLOSE_GROUP_OPERATOR
    })
  ]
  if (searchByType) {
    searchByType(filterOptions, {
      type: LOCATION,
      sort: buildSortPropertyByType('name', LOCATION),
      itemsPerPage: 0
    })
  }
}
