/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FilteredSearch } from 'modules/FilteredSearch'
import { getResellTestingState } from 'reducers/resellTesting/testingMode'
import { useSelector } from 'react-redux'

export function FilteredSearchWithTestMode({ onChange, getApi, ...rest }) {
  const filteredSearchApi = useRef()
  const { isModeEnabled } = useSelector(getResellTestingState)

  const onGetApi = useCallback(
    api => {
      filteredSearchApi.current = api

      if (getApi) {
        getApi(api)
      }
    },
    [getApi]
  )

  const onChangeHandler = useCallback(
    state => {
      if (onChange) {
        onChange({ ...state, params: { ...state.params, testMode: isModeEnabled } })
      }
    },
    [isModeEnabled, onChange]
  )

  useEffect(() => {
    filteredSearchApi.current.emitChange()
  }, [isModeEnabled])

  return <FilteredSearch getApi={onGetApi} onChange={onChangeHandler} {...rest} />
}

FilteredSearchWithTestMode.propTypes = {
  onChange: PropTypes.func,
  getApi: PropTypes.func
}
FilteredSearchWithTestMode.defaultProps = {
  getApi: null,
  onChange: null
}
