/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const NAVIGATION_ADMIN_TYPE = 'NAVIGATION_TYPE_ADMIN'
export const NAVIGATION_RESALE_TYPE = 'NAVIGATION_TYPE_RESALE'
export const NAVIGATION_DEFAULT_TYPE = 'NAVIGATION_TYPE_COMMON'

export const NAVIGATION_CHILD_TYPE = 'NAVIGATION_CHILD_TYPE'
