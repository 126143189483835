/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { DATA_STATE_NOT_REQUESTED } from 'helpers/actionHelpers'
import { getQueryStringParams } from 'helpers/searchParamsHelper'
import { ControlledTable } from 'components/Tables'
import { buildListDataByType } from './helpers'

export class ReportsList extends React.Component {
  componentWillUnmount() {
    const { resetDataState } = this.props
    resetDataState()
  }

  getData = options => {
    const { reportType, authUser } = this.props
    const { getList } = buildListDataByType(reportType, this.props)
    getList(authUser, options)
  }

  render() {
    const { dataState, data, reportType, location, ...rest } = this.props
    const { columns, header } = buildListDataByType(reportType, rest)
    const { type } = getQueryStringParams(location.search)
    const onDidMountRequest = !type || dataState === DATA_STATE_NOT_REQUESTED
    return (
      <ControlledTable
        dataState={dataState}
        columns={columns}
        onGetData={this.getData}
        tableHeader={header}
        data={data}
        entityType={reportType}
        showPagination={false}
        onDidMountRequest={onDidMountRequest}
      />
    )
  }
}

ReportsList.defaultProps = {
  formDataState: null,
  extendedOptions: {},
  needUpdate: false
}
ReportsList.propTypes = {
  dataState: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  formDataState: PropTypes.string,
  needUpdate: PropTypes.bool,
  reportType: PropTypes.string.isRequired,
  authUser: PropTypes.object.isRequired,
  resetDataState: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}
