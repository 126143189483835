/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { lensPath, view, hasPath as isPathExist } from 'ramda'

const getPath = pathArray => obj => view(lensPath(pathArray), obj)

const safeArray = maybeArray => (Array.isArray(maybeArray) ? maybeArray : [])

const hasPath = pathArray => obj => isPathExist(pathArray, obj)

const isNullable = value => value === 'null' || value === null

const final = {
  getPath,
  safeArray,
  hasPath,
  isNullable
}

export { getPath, safeArray, hasPath, isNullable }

export default final
