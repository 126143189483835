/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole
 *                or in part, is prohibited.
 */

import React from 'react'
import classNames from 'classnames'
import { ReduxNotification } from 'components/Notifications'
import { SliderContainer } from 'modules/SliderContainer'
import { ReduxModalsContainer } from 'modules/ReduxModalsContainer'
import { withThemeProvider } from '@xplorie/ui-commons'
import * as Sentry from '@sentry/react'
import FallbackPage from 'containers/FallbackPage'
import Routes from 'router'
import styles from './App.scss'
import './app.css'

const App = () =>
  withThemeProvider(
    <Sentry.ErrorBoundary fallback={FallbackPage}>
      <div className={classNames(styles.appWrapper)}>
        <ReduxNotification />
        <SliderContainer />
        <ReduxModalsContainer />
        <Routes />
      </div>
    </Sentry.ErrorBoundary>
  )

export default App
