/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const TileAccordionForm = loadable(
  () =>
    import(
      /* webpackChunkName: 'TileAccordionForm', webpackPrefetch: true */ './TileAccordionForm'
    ),
  {
    resolveComponent: components => components.TileAccordionForm
  }
)

export { TileAccordionForm }
export { TileAccordionList } from './TileAccordionList'
export * from './helpers'
