/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { APPS_BY_MARKET_PATH, PROGRAMS_BY_LOCATION_PATH, PROGRAMS_BY_MARKET_PATH } from 'router'
import { ADMIN_LEVEL } from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'

export function canUpdateMarketForm(authUser /* marketData */) {
  return isActionAllowed(ADMIN_LEVEL, authUser)
}

export function canDeleteMarket(authUser) {
  return isActionAllowed(ADMIN_LEVEL, authUser)
}

export function buildShowProgramsPath(id = null) {
  return `${PROGRAMS_BY_MARKET_PATH}${id}`
}

export function buildProgramsByLocationPath(id) {
  return `${PROGRAMS_BY_LOCATION_PATH}${id}`
}

export function buildShowAPPsPath(id = null) {
  return `${APPS_BY_MARKET_PATH}${id}`
}

export function canSeeAppsList(authUser) {
  return isActionAllowed(ADMIN_LEVEL, authUser)
}
