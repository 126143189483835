/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { selectors, actions } from 'reducers/location/marketingForm'
import { withAuthContext } from 'helpers/withAuthContext'
import { MarketingTab as MarketingTabComponent } from './MarketingTab'

const { getMarketingFormStateById, getLocationMarketingChangedDataStates } = selectors
const { getLocationMarketingById } = actions

function mapStateToProps(state, { dataId, watchLocationId }) {
  const marketingState = getMarketingFormStateById(state, dataId)
  const closeDataStates = getLocationMarketingChangedDataStates(state, dataId)
  const watchCloseDataStates = getLocationMarketingChangedDataStates(state, watchLocationId)
  return {
    entityDataState: marketingState.formDataState,
    closeDataStates: [...watchCloseDataStates, ...closeDataStates]
  }
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getLocationMarketingById }, dispatch)
}

export const MarketingTab = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MarketingTabComponent)
