import {
  mapToCreateSchedulePayload,
  mapInitialValues,
  mapOccurrenceToFormValues,
  mapToEditScheduleOccurrencePayload,
  mapToEditScheduleSeriesPayload,
  mapSeriesToFormValues,
  mapOccurrenceToValidationPayload
} from 'modules/Closures/mappers'
import { SCHEDULE_TYPE } from '../types'
import { submitWithValidation } from '../helpers'
import { OVERRIDE_SCHEDULE_MESSAGE } from './types'
import { ScheduleWarningModal } from '../ScheduleWarningModal/ScheduleWarningModal'

export function addScheduleStrategy({ activityId, createSchedule, rootModal, getIntersections }) {
  const onSubmit = formValues => {
    const payload = mapToCreateSchedulePayload(formValues)

    return submitWithValidation({
      payload,
      activityId,
      rootModal,
      onSubmit: createSchedule,
      getIntersections,
      message: OVERRIDE_SCHEDULE_MESSAGE,
      warningModal: ScheduleWarningModal
    })
  }

  return {
    mapToFormValues: null,
    onSubmit,
    getData: null,
    mapToInitialValues: mapInitialValues
  }
}

export function editSeriesStrategy({
  activityId,
  id,
  updateScheduleSeries,
  getScheduleById,
  rootModal,
  getIntersections
}) {
  const onSubmit = formValues => {
    const validationPayload = mapToCreateSchedulePayload(formValues)
    const updateSeries = () => {
      const payload = mapToEditScheduleSeriesPayload(formValues)
      updateScheduleSeries(activityId, id, payload)
    }
    return submitWithValidation({
      payload: validationPayload,
      activityId,
      rootModal,
      onSubmit: updateSeries,
      getIntersections,
      message: OVERRIDE_SCHEDULE_MESSAGE,
      warningModal: ScheduleWarningModal
    })
  }

  const getData = () => getScheduleById(activityId, id)

  return {
    mapToFormValues: mapSeriesToFormValues,
    onSubmit,
    getData,
    mapToInitialValues: mapInitialValues
  }
}

export function editOccurrenceStrategy({
  activityId,
  id,
  updateScheduleOccurrence,
  getOccurrenceByIdAndDay,
  day,
  rootModal,
  getIntersections
}) {
  const onSubmit = formValues => {
    const validationPayload = mapOccurrenceToValidationPayload(formValues, SCHEDULE_TYPE)
    const updateOccurrence = () => {
      const payload = mapToEditScheduleOccurrencePayload(formValues)
      updateScheduleOccurrence(activityId, id, payload)
    }

    return submitWithValidation({
      payload: validationPayload,
      activityId,
      rootModal,
      onSubmit: updateOccurrence,
      getIntersections,
      message: OVERRIDE_SCHEDULE_MESSAGE,
      warningModal: ScheduleWarningModal
    })
  }

  const getData = () => getOccurrenceByIdAndDay(activityId, id, day)

  return {
    mapToFormValues: mapOccurrenceToFormValues,
    onSubmit,
    getData,
    mapToInitialValues: mapInitialValues
  }
}

export function ModalStrategy(props) {
  function getStrategy() {
    const { id, day } = props

    if (id && day) {
      return editOccurrenceStrategy(props)
    }

    if (id) {
      return editSeriesStrategy(props)
    }

    return addScheduleStrategy(props)
  }

  return {
    getStrategy
  }
}
