/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { useLoading } from 'hooks'
import { ADMIN_LEVEL } from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import { APsActivation } from 'modules/ActivityProviders'
import { PIECurtainLoader } from 'components/Loaders'
import { STATUS_INACTIVE } from 'constants/entityStatus'
import { ACTIVATE_BUTTON_TEXT } from './types'

export function ActivateButton(props) {
  const { activationDataState, activateAPById, authUser, dataId, entityData } = props
  const [isOpenModal, setIsModalState] = useState(false)
  const [isProccesing] = useLoading(activationDataState)
  const isAdmin = isActionAllowed(ADMIN_LEVEL, authUser)
  const name = entityData && entityData.name
  const status = entityData && entityData.status
  const isDisabled = isProccesing || !isAdmin || !dataId || status !== STATUS_INACTIVE

  const onToggleModalHandler = useCallback(() => {
    setIsModalState(!isOpenModal)
  }, [isOpenModal])

  const onActivateHandler = useCallback(() => {
    if (dataId) {
      activateAPById(dataId)
    }
    onToggleModalHandler()
  }, [activateAPById, dataId, onToggleModalHandler])

  return (
    <>
      <BasicButton colorSchema={ORANGE_SCHEMA} disabled={isDisabled} onClick={onToggleModalHandler}>
        {ACTIVATE_BUTTON_TEXT}
      </BasicButton>
      <APsActivation
        name={name}
        isOpen={isOpenModal}
        onSubmit={onActivateHandler}
        onHide={onToggleModalHandler}
        dataId={dataId}
      />
      {isProccesing && <PIECurtainLoader />}
    </>
  )
}

ActivateButton.propTypes = {
  activationDataState: PropTypes.string.isRequired,
  activateAPById: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired,
  dataId: PropTypes.string,
  entityData: PropTypes.object
}

ActivateButton.defaultProps = {
  dataId: null,
  entityData: {}
}
