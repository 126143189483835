/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { useDidMount, useLoading, useAction } from 'hooks'
import { useSelector } from 'react-redux'
import { selectors, actions } from 'reducers/program/programHistory'
import { useMemo, useCallback } from 'react'

import { getPreparedFilters, prepareHistoryYears } from './helpers'

const { getProgramHistoryStateById, getProgramYearHistoryStateById } = selectors

export function useProgramHistoryApi(props) {
  const { dataId: programId, filters } = props
  const programHistoryState = useSelector(state => getProgramHistoryStateById(state, [programId]))

  const [isLoading, { isLoaded, isError }] = useLoading(programHistoryState.dataState)

  const history = useMemo(() => prepareHistoryYears(programHistoryState.years), [
    programHistoryState.years
  ])

  const getHistoryYears = useAction(actions.getHistoryYears)
  const resetHistory = useAction(actions.resetHistory)

  const onGetData = params => {
    getHistoryYears(programId, { types: params })
  }

  useDidMount(() => {
    onGetData(getPreparedFilters(filters))

    return () => {
      resetHistory(programId)
    }
  })

  return [
    {
      ...programHistoryState,
      isLoading: isLoading || !isLoaded || isError,
      history,
      onGetData
    }
  ]
}

export function useHistoryByYear(dataId, year, filters) {
  const getHistory = useAction(actions.getHistoryById.bind(null, dataId, year))
  const programHistoryState = useSelector(state =>
    getProgramYearHistoryStateById(state, dataId, year)
  )
  const { itemsCount, pageNumber, data, dataState } = programHistoryState

  const hasMoreItems = useMemo(() => data.length < itemsCount, [data.length, itemsCount])
  const [isLoading] = useLoading(dataState)

  const getLazyHistory = useCallback(() => {
    getHistory({ itemsPerPage: 5, pageNumber: pageNumber + 1 }, { types: filters })
  }, [getHistory, pageNumber, filters])

  return [{ getHistory: getLazyHistory }, programHistoryState, { hasMoreItems, isLoading }]
}
