/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
// eligibility types
export const BOOLEAN_TYPE = 'BOOLEAN'
export const NUMBER_TYPE = 'NUMBER'
export const STRING_TYPE = 'STRING'
export const DATE_TYPE = 'DATE'

// eligibility modes
export const EQUALS_MODE = 'EQUALS'
export const NOT_EQUALS = 'NOT_EQUALS'
export const MORE_OR_EQUALS = 'GREATER_THAN_OR_EQUALS'
export const LESS_OR_EQUALS = 'LESS_THAN_OR_EQUALS'
export const CONTAINS = 'CONTAINS'
export const NOT_CONTAINS = 'NOT_CONTAINS'
export const PERIOD = 'PERIOD'

// eligibility conditions
export const INCLUDE_CONDITION = 'INCLUSION'
export const EXCLUDE_CONDITION = 'EXCLUSION'
