import { useEffect, useState } from 'react'

export const useShowCalendar = fullScreen => {
  const [showCalendar, setShowCalendar] = useState(false)

  const handleShowCalendar = () => {
    setShowCalendar(isShowed => {
      if (isShowed) {
        fullScreen.minimize()
        return false
      }

      fullScreen.maximize()
      return true
    })
  }

  useEffect(() => {
    if (!fullScreen.active) {
      setShowCalendar(false)
    }
  }, [fullScreen.active])

  return {
    showCalendar,
    handleShowCalendar
  }
}
