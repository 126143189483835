/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useContext, useCallback } from 'react'
import { actions as dealCommonActions, getCommonDealFormState } from 'reducers/deals/dealCommon'
import { actions, getDiscountDealFormStateById } from 'reducers/discountDeal/discountDealForm'
import { useAction, useAuthUser, useLoading } from 'hooks'
import has from 'lodash/has'
import {
  canUpdateForm,
  canDeactivateForm,
  canChangeToDraftForm,
  canActivateForm
} from 'modules/Discounts'
import { useSelector } from 'react-redux'
import { preparePayload, DiscountContext } from './helpers'

export function useDiscountApi() {
  const { api } = useContext(DiscountContext)
  return api
}

export function useDiscountState() {
  const { state } = useContext(DiscountContext)
  return state
}

export function useDealApi() {
  const { api } = useContext(DiscountContext)
  return api.dealApi
}

export function useDiscountDealApiBuilder(
  dataId,
  { optionsFormState },
  { dealOptions, dealPeriods, dealTicketTypes, sliderApi }
) {
  const authUser = useAuthUser()
  const { entityData, deactivateDataState, draftDataState } = useSelector(state =>
    getCommonDealFormState(state, dataId)
  )
  const { formDataState, activateDataState } = useSelector(state =>
    getDiscountDealFormStateById(state, dataId)
  )

  const [isFormSubmitting] = useLoading(formDataState)
  const [isDeactivating] = useLoading(deactivateDataState)
  const [isActivating] = useLoading(activateDataState)
  const [isChangingToDraft] = useLoading(draftDataState)

  // actions
  const updateDeal = useAction(actions.updateDeal)
  const createDeal = useAction(actions.createDeal)
  const resetForm = useAction(actions.resetForm)
  const deleteDeal = useAction(dealCommonActions.removeDeal)
  const deactivateDeal = useAction(dealCommonActions.deactivateDeal)
  const makeDraftDeal = useAction(dealCommonActions.makeDraftDeal)
  const activateDeal = useAction(actions.activateDiscountDeal)

  const setDealFormValues = useCallback(
    values => {
      dealOptions.setValues(values)
      dealPeriods.setValues(values)
      dealTicketTypes.setValues(values)
    },
    [dealOptions, dealPeriods, dealTicketTypes]
  )

  const getDetails = useCallback(() => entityData, [entityData])
  const getId = useCallback(() => entityData.id, [entityData])

  const canEditForm = useCallback(() => canUpdateForm(authUser, entityData), [authUser, entityData])
  const canEditPeriods = useCallback(
    () => has(optionsFormState, 'values.activity') && has(optionsFormState, 'values.type'),
    [optionsFormState]
  )
  const compareFormValues = useCallback(async () => {
    const [optionsValues, periodsValues, ticketTypesValues] = await Promise.all([
      dealOptions.submit(),
      dealPeriods.submit(),
      dealTicketTypes.submit()
    ])

    if (!optionsValues || !periodsValues || !ticketTypesValues) {
      return null
    }

    return preparePayload({ ...optionsValues, ...periodsValues, ...ticketTypesValues })
  }, [dealOptions, dealPeriods, dealTicketTypes])

  const deactivate = useCallback(() => {
    deactivateDeal(dataId, getId()).then(values => setDealFormValues(values))
  }, [dataId, deactivateDeal, getId, setDealFormValues])

  const draftDeal = useCallback(() => {
    makeDraftDeal(dataId, getId()).then(values => setDealFormValues(values))
  }, [dataId, getId, makeDraftDeal, setDealFormValues])

  const activate = useCallback(async () => {
    const formValues = await compareFormValues()
    if (!formValues) {
      return
    }
    activateDeal(dataId, getId(), formValues)
      .then(values => setDealFormValues(values))
      .then(() => sliderApi.onClose())
  }, [activateDeal, compareFormValues, dataId, getId, setDealFormValues, sliderApi])

  const deleteDiscount = useCallback(async () => {
    await deleteDeal(dataId)
    sliderApi.onClose()
  }, [deleteDeal, dataId, sliderApi])

  const submitForm = useCallback(async () => {
    const formValues = await compareFormValues()
    if (!formValues) {
      return
    }
    const hasId = has(formValues, 'id')
    const request = hasId ? updateDeal : createDeal
    request(dataId, formValues)
      .then(values => setDealFormValues(values))
      .then(() => {
        if (hasId) {
          sliderApi.onClose()
        }
      })
  }, [compareFormValues, createDeal, dataId, setDealFormValues, sliderApi, updateDeal])

  const canDeactivate = useCallback(() => canDeactivateForm(authUser, entityData), [
    authUser,
    entityData
  ])

  const canChangeToDraft = useCallback(() => canChangeToDraftForm(authUser, entityData), [
    authUser,
    entityData
  ])

  const canActivate = useCallback(() => canActivateForm(authUser, entityData, optionsFormState), [
    authUser,
    entityData,
    optionsFormState
  ])

  const canSubmit = useCallback(
    () => [dealOptions, dealPeriods, dealTicketTypes].every(api => api.canSubmit()),
    [dealOptions, dealPeriods, dealTicketTypes]
  )

  const reset = useCallback(() => {
    resetForm(dataId)
  }, [dataId, resetForm])

  return [
    { isFormSubmitting, isDeactivating, isActivating, isChangingToDraft },
    {
      getId,
      setDealFormValues,
      canEditForm,
      submitForm,
      getDetails,
      deactivate,
      deleteDiscount,
      canDeactivate,
      draftDeal,
      canChangeToDraft,
      canActivate,
      activate,
      canEditPeriods,
      canSubmit,
      reset
    }
  ]
}
