/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useRef, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions, getModalStateById } from 'reducers/reduxModals'

export function useRootModal() {
  const dispatch = useDispatch()
  const id = useRef()
  const modalState = useSelector(state => getModalStateById(state, id.current))

  const isOpen = useMemo(() => Boolean(modalState), [modalState])

  const enqueue = useCallback(
    /**
     * Open Modal Window
     * @param {React.Component} Component react modal component
     * @param {Object} props modal props
     * @returns {number} modal id
     */
    (Component, props) => {
      id.current = dispatch(actions.enqueue(Component, props))
      return id.current
    },
    [dispatch]
  )

  const dequeue = useCallback(() => {
    if (id.current) {
      dispatch(actions.dequeue(id.current))
    }
  }, [dispatch, id])

  return { enqueue, dequeue, id, isOpen }
}
