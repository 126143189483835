/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import {
  ACTIVATION_ACCOUNT,
  DEACTIVATION_ACCOUNT,
  ACTIVATION_SUCCESS_MESSAGE,
  DEACTIVATION_SUCCESS_MESSAGE,
  ACCOUNT_LIFE_CYCLE
} from './types'

const REQUEST_ACTIVATION_ACCOUNT = `${REQUEST_TYPE}_${ACTIVATION_ACCOUNT}`
const RESPONSE_ACTIVATION_ACCOUNT = `${RESPONSE_TYPE}_${ACTIVATION_ACCOUNT}`
const RESPONSE_ACTIVATION_ACCOUNT_ERROR = `${RESPONSE_ERROR_TYPE}_${ACTIVATION_ACCOUNT}`

const REQUEST_DEACTIVATION_ACCOUNT = `${REQUEST_TYPE}_${DEACTIVATION_ACCOUNT}`
const RESPONSE_DEACTIVATION_ACCOUNT = `${RESPONSE_TYPE}_${DEACTIVATION_ACCOUNT}`
const RESPONSE_DEACTIVATION_ACCOUNT_ERROR = `${RESPONSE_ERROR_TYPE}_${DEACTIVATION_ACCOUNT}`

const RESET_ACCOUNT_LIFE_CYCLE_DATA_STATE = `${RESET_STATE}_${ACCOUNT_LIFE_CYCLE}`

export default {
  REQUEST_ACTIVATION_ACCOUNT,
  RESPONSE_ACTIVATION_ACCOUNT,
  RESPONSE_ACTIVATION_ACCOUNT_ERROR,
  REQUEST_DEACTIVATION_ACCOUNT,
  RESPONSE_DEACTIVATION_ACCOUNT,
  RESPONSE_DEACTIVATION_ACCOUNT_ERROR,
  ACTIVATION_SUCCESS_MESSAGE,
  DEACTIVATION_SUCCESS_MESSAGE,
  RESET_ACCOUNT_LIFE_CYCLE_DATA_STATE
}
