/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const NAME = 'ACTIVITY_LIFECYCLE_PROCESS'

export const ACTIVATE = `${NAME}_ACTIVATE`
export const DEACTIVATE = `${NAME}_DEACTIVATE`

export const ACTIVATION_SUCCESS_MESSAGE = 'The Activity Product has been activated.'
export const DEACTIVATION_SUCCESS_MESSAGE = 'The Activity Product has been deactivated.'

export const DEACTIVATION_SET_MESSAGE = 'Deactivation has been set to '
export const DEACTIVATION_UPDATE_MESSAGE = 'Deactivation Date has been updated to '
export const DEACTIVATION_CANCELED_MESSAGE = 'Scheduled Deactivation has been canceled.'

export default {
  NAME,
  ACTIVATE,
  DEACTIVATE
}
