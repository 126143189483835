/* eslint-disable class-methods-use-this */
/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ContentWithNav } from 'components/ContentWithNav'
import { HeaderWrapper, ButtonsWrapper } from 'components/Wrappers'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { ReportsList, ReportsBuilder, ReportsExporter } from 'modules/Reports'
import { getQueryStringParams, buildRequestParams } from 'helpers/searchParamsHelper'
import { reportTypeExist } from 'modules/Reports/helpers'
import styles from './Reports.scss'
import { TITLE_PAGE } from './types'

export class ReportsComponent extends React.Component {
  componentDidMount() {
    const { location, setReportType, history, reportType } = this.props
    const params = getQueryStringParams(location.search)
    const { type } = params
    if (type && reportTypeExist(type) && type !== reportType) {
      setReportType(type)
    }
    if (!reportTypeExist(type) && type !== reportType) {
      history.replace({
        ...location,
        search: buildRequestParams({ ...params, type: reportType })
      })
    }
  }

  render() {
    return (
      <ContentWithNav>
        <section className={classNames(styles.customWrapper)}>
          <HeaderWrapper title={TITLE_PAGE} />
          <div className={classNames(styles.containerWrapper)}>
            <ButtonsWrapper>
              <Column className={styles.tabsWrapper}>
                <ReportsBuilder />
              </Column>
              <Column className={styles.tabsWrapper}>
                <ReportsExporter />
              </Column>
            </ButtonsWrapper>
            <div>
              <ReportsList />
            </div>
          </div>
        </section>
      </ContentWithNav>
    )
  }
}

ReportsComponent.propTypes = {
  location: PropTypes.object.isRequired,
  setReportType: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  reportType: PropTypes.string.isRequired
}
