/*
 * COPYRIGHT:     Copyright © 2018 - 1019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import isObject from 'lodash/isObject'
import get from 'lodash/get'

import {
  MESSAGE_KIND_WARNING,
  MESSAGE_KIND_DANGER,
  MESSAGE_KIND_SUCCESS,
  UNKNOWN_ERROR_MESSAGE,
  INTERNAL_SERVER_ERROR,
  INTERNAL_ERROR_MESSAGE,
  FORBIDDEN_ERROR,
  NOT_AUTH_ERROR,
  BUSINESS_EXCEPTION,
  VALIDATION_ERROR,
  SUCCESS_MESSAGE
} from 'helpers/types/messageHelpersTypes'

export const defaultErrorStrategy = () => ({
  message: UNKNOWN_ERROR_MESSAGE,
  kind: MESSAGE_KIND_WARNING,
  delay: 3000
})

export const businessStrategy = error => {
  const responseError = get(error, 'response.data')
  const bodyKey = get(responseError, 'body.keys')
  const businessError = get(responseError, 'status')
  switch (businessError && businessError.code) {
    case VALIDATION_ERROR:
      return {
        message: `Error code: ${bodyKey} \n ${INTERNAL_ERROR_MESSAGE}`,
        kind: MESSAGE_KIND_DANGER,
        delay: null
      }
    case BUSINESS_EXCEPTION:
      return {
        message: businessError.message,
        kind: MESSAGE_KIND_WARNING,
        delay: 6000
      }
    default:
      return defaultErrorStrategy()
  }
}
export const backEndStrategy = response => {
  const responseStatus = get(response, 'response.status')
  switch (true) {
    case responseStatus === 403:
      return {
        message: FORBIDDEN_ERROR,
        kind: MESSAGE_KIND_DANGER,
        delay: 3000
      }
    case responseStatus === 401:
      return {
        message: NOT_AUTH_ERROR,
        kind: MESSAGE_KIND_DANGER,
        delay: 3000
      }
    case responseStatus >= 500:
      return {
        message: INTERNAL_SERVER_ERROR,
        kind: MESSAGE_KIND_DANGER,
        delay: 4000
      }
    case responseStatus >= 200 && responseStatus < 300:
      return {
        message: SUCCESS_MESSAGE,
        kind: MESSAGE_KIND_SUCCESS,
        delay: 2000
      }
    case responseStatus === 400:
      return businessStrategy(response)
    default:
      return defaultErrorStrategy()
  }
}

export const getMessageByResponse = error => {
  if (isObject(error)) {
    return backEndStrategy(error)
  }
  return defaultErrorStrategy()
}
