/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { LIST_NAME, DOWNLOAD_FILE } = types

const REQUEST_LIST = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`

const REQUEST_DOWNLOAD = `${REQUEST_TYPE}_${DOWNLOAD_FILE}`
const RESPONSE_DOWNLOAD = `${RESPONSE_TYPE}_${DOWNLOAD_FILE}`
const RESPONSE_ERROR_DOWNLOAD = `${RESPONSE_ERROR_TYPE}_${DOWNLOAD_FILE}`

const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_DOWNLOAD,
  RESPONSE_DOWNLOAD,
  RESPONSE_ERROR_DOWNLOAD
}
