/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { entityInitialState } from './reducer'

export const getAPLifeCycleState = state => state.activityProvider.apLifeCycle

export const getAPLifeCycleById = (state, apId) =>
  getAPLifeCycleState(state)[apId] || entityInitialState

export const isAPLifeCyclePending = (state, apId) => {
  const currentState = getAPLifeCycleById(state, apId)
  return [currentState.deactivationDataState, currentState.activationDataState].some(
    value => value === DATA_STATE_REQUESTING
  )
}

export const getAPLifeCycleDataStates = (state, apId) => {
  const currentState = getAPLifeCycleById(state, apId)
  return [currentState.deactivationDataState, currentState.activationDataState]
}
