/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { canDeleteTileAccordion } from 'modules/TileAccordion'
import { tileAccordionTableColumns, buildDefaultButtonColumn } from 'helpers/dataTablesHelpers'
import { EditButton, DeleteButton } from './AdditionalColumns'

export const buildTableColumns = props => {
  const { removeAccordionDataById, authUser } = props
  const canDelete = canDeleteTileAccordion(authUser)

  const tableColumns = tileAccordionTableColumns.concat({
    ...buildDefaultButtonColumn(),
    Cell: data => <EditButton {...props} data={data} />
  })

  if (canDelete) {
    tableColumns.push({
      ...buildDefaultButtonColumn({ width: 95 }),
      Cell: data => <DeleteButton {...props} data={data} deleteAction={removeAccordionDataById} />
    })
  }

  return tableColumns
}
