/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { compose } from 'redux'
import { asField, withFormState } from 'informed'
import { withFormContext } from 'components/FormComponents/helpers'
import { withAuthContext } from 'helpers/withAuthContext'
import { AvailabilitySection as AvailabilitySectionComponent } from './AvailabilitySection'

export const AvailabilitySection = compose(
  withFormContext,
  withAuthContext,
  asField,
  withFormState
)(AvailabilitySectionComponent)
