import groupBy from 'lodash/groupBy'
import map from 'lodash/map'

export const mapOccurrencesToBody = occurrences => {
  const occurrencesBySeriesId = groupBy(occurrences, 'seriesId')

  return map(occurrencesBySeriesId, (values, seriesId) => ({
    seriesId,
    days: values.map(value => value.day)
  }))
}

export const mapOccurrenceToCancelBody = (formValues, day, seriesId) => [
  {
    days: [day],
    seriesId,
    reason: formValues.reason
  }
]

export const mapOccurrenceToActivateBody = (day, seriesId) => [
  {
    days: [day],
    seriesId
  }
]
