/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const VIEW_ACTION_TYPE = 'VIEW_ACTION_TYPE'
export const ADD_CHILDREN_TYPE = 'ADD_CHILDREN_TYPE'
export const ADD_NEW_LOCATION_ACTION_TYPE = 'ADD_NEW_LOCATION_ACTION_TYPE'

export const STATE_LABEL = 'State'
export const DESTINATION_LABEL = 'Destination'
export const COUNTRY_LABEL = 'Country'

// Obsolete types
export const CITY_LABEL = 'City'
export const DISTRICT_LABEL = 'District'
