/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { ACTIVITY } from 'constants/searchTypes'

export const SMART_HOST_MOBILE_FEE_LIST = 'SMART_HOST_MOBILE_FEE'
export const SET_SMART_HOST_MOBILE_FEE_MODE = 'SET_SMART_HOST_MOBILE_FEE_MODE'
export const ENTITY_TYPE = ACTIVITY

export const SUCCESS_SMART_HOST_MOBILE_FEE_MESSAGE = 'SmartHost Mobile Fee are successfully added'
