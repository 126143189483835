/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildURLForGettingList } from 'services/http/helpers'
import { ACTIVITY_OPTIONS_URL } from 'endpoints'

export const getList = () =>
  httpWrapper({
    url: buildURLForGettingList(ACTIVITY_OPTIONS_URL),
    method: 'get'
  })
