/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/reportsList'
import { withRouter } from 'react-router-dom'
import { withAuthContext } from 'helpers/withAuthContext'
import { ReportsBuilderComponent } from './ReportsBuilder'

const mapStateToProps = state => ({
  dataState: state.reportsList.dataState,
  data: state.reportsList.data,
  itemsCount: state.reportsList.itemsCount,
  itemsPerPage: state.reportsList.itemsPerPage,
  pageNumber: state.reportsList.pageNumber,
  reportType: state.reportsList.reportType
})

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

export const ReportsBuilder = compose(
  withAuthContext,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReportsBuilderComponent)
