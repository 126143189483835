/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const COUNTRY_TYPE = 'COUNTRY'
export const STATE_TYPE = 'STATE'
export const DESTINATION_TYPE = 'DESTINATION'
// Obsolete types
export const CITY_TYPE = 'CITY'
export const DISTRICT_TYPE = 'DISTRICT'

export const LOCATIONS_TYPES_RELEVANCE = {
  [COUNTRY_TYPE]: 100,
  [STATE_TYPE]: 90,
  [DESTINATION_TYPE]: 80
  // CITY_TYPE and DISTRICT_TYPE are obsolete
  // [CITY_TYPE]: 70,
  // [DISTRICT_TYPE]: 60
}
