/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'

import styles from './AdditionalField.scss'

export function AdditionalDataField(props) {
  const { label, value } = props
  return (
    <div className={styles.mainWrapper}>
      <span className={styles.label}>{label}</span>
      <span>{value}</span>
    </div>
  )
}

AdditionalDataField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

AdditionalDataField.defaultProps = {
  label: '',
  value: ''
}
