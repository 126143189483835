/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { MARKET } from 'constants/searchTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'

export const marketTableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', MARKET)
  }
]
