/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'
import { actions as smartHostFeeActions, getSmartHostFeeState } from 'reducers/program/smartHostFee'
import {
  actions as smartHostMobileFeeActions,
  getSmartHostMobileFeeState
} from 'reducers/program/smartHostMobileFee'
import { getProgramDataState } from 'reducers/programsForm'
import SmartHostFee from './SmartHostFee'
import { withSmartHostFeeForm } from './withSmartHostFeeForm'
import { canChangeFees } from './helpers'

export const SmartHostSubscriptionFee = compose(
  connect(
    (state, props) => {
      const { dataId, authUser } = props
      const { programData } = getProgramDataState(state, dataId)

      return {
        canChangeFees: canChangeFees(programData, authUser),
        smartHostFees: getSmartHostFeeState(state, dataId)
      }
    },
    dispatch =>
      bindActionCreators(
        {
          ...smartHostFeeActions
        },
        dispatch
      )
  ),
  withSmartHostFeeForm,
  withAuthContext
)(SmartHostFee)

export const SmartHostMobileFee = compose(
  connect(
    (state, props) => {
      const { dataId, authUser } = props
      const { programData } = getProgramDataState(state, dataId)

      return {
        canChangeFees: canChangeFees(programData, authUser),
        smartHostFees: getSmartHostMobileFeeState(state, dataId)
      }
    },
    dispatch =>
      bindActionCreators(
        {
          ...smartHostMobileFeeActions
        },
        dispatch
      )
  ),
  withSmartHostFeeForm,
  withAuthContext
)(SmartHostFee)
