/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useEffect, useRef, useReducer, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { useDidMount, usePrevious } from 'hooks'
import { SearchTabs } from './SearchTabs'
import { SearchForm } from './SearchForm'
import { SearchResults } from './SearchResults'
import {
  DEFAULT_SEARCH_TYPE,
  CHANGE_QUERY_PARAMETERS,
  CHANGE_FILTERS,
  RESET_LIST_PARAMETERS,
  ON_SEARCH
} from './types'
import { buildUrl, reducer, convertQueryToStateParameters, buildSearchTabs } from './helpers'

export function HomeSearch(props) {
  const {
    match,
    searchByType,
    dataState,
    data,
    itemsPerPage,
    pageNumber,
    itemsCount,
    authUser
  } = props
  const history = useHistory()
  const location = useLocation()
  const [state, dispatch] = useReducer(reducer, {
    isShowResult: false,
    parameters: { keyword: '', type: DEFAULT_SEARCH_TYPE },
    filters: [],
    submits: 0
  })
  const prevSubmits = usePrevious(state.submits)
  const searchTabsOptions = useMemo(() => buildSearchTabs(authUser), [authUser])

  const homeSearchApi = useRef({
    changeQueryParameters: queryParameters => {
      dispatch({ type: CHANGE_QUERY_PARAMETERS, payload: queryParameters })
    },
    changeSearchFilters: filters => {
      dispatch({ type: CHANGE_FILTERS, payload: filters })
    },
    onSearch: () => {
      dispatch({ type: ON_SEARCH })
    },
    onResetListParameters: () => {
      dispatch({ type: RESET_LIST_PARAMETERS })
    }
  })

  useEffect(() => {
    if (prevSubmits !== state.submits && state.submits) {
      searchByType(state.filters, state.parameters)
      history.replace(buildUrl(match.path, state.parameters))
    }
  }, [
    history,
    match.path,
    prevSubmits,
    searchByType,
    state.filters,
    state.parameters,
    state.submits
  ])

  useDidMount(() => {
    const queryParams = convertQueryToStateParameters(location.search)
    const isExistParam = searchTabsOptions.some(tabItem => tabItem.type === queryParams.type)
    if (!isExistParam) {
      homeSearchApi.current.onSearch()
      return
    }
    if (Object.keys(queryParams).length) {
      homeSearchApi.current.changeQueryParameters(queryParams)
      if (queryParams && queryParams.type === DEFAULT_SEARCH_TYPE) {
        homeSearchApi.current.onSearch()
      }
    }
  })

  return (
    <>
      <SearchForm api={homeSearchApi.current} state={state} />
      <SearchTabs api={homeSearchApi.current} state={state} searchTabsOptions={searchTabsOptions} />
      <SearchResults
        dataState={dataState}
        data={data}
        itemsPerPage={itemsPerPage}
        pageNumber={pageNumber}
        itemsCount={itemsCount}
        state={state}
        api={homeSearchApi.current}
      />
    </>
  )
}

HomeSearch.propTypes = {
  authUser: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  searchByType: PropTypes.func.isRequired,
  dataState: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired
}
