/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { helpers } from 'reducers/activityCategoriesTree'
import { ActivityCategoryContainer } from '../ActivityCategoryContainer'
import styles from './ActivityCategoryItem.scss'

export function ActivityCategoryItem(props) {
  const {
    category: { id, name, tags },
    category,
    setMenuOpenedState,
    hasMenuOpened,
    menuProps,
    updateTreeState,
    treeState,
    parentId,
    isTag,
  } = props
  const globalId = helpers.buildIdForGlobalTree(id, parentId)
  const isCollapsed = treeState[globalId] && treeState[globalId].isCollapsed

  const collapseToggle = () => {
    if (tags && tags.length > 0) {
      updateTreeState({
        id: helpers.buildIdForGlobalTree(id, parentId),
        isCollapsed: !isCollapsed
      })
    }
  }

  const onContextMenuHandler = e => {
    setMenuOpenedState({ ...category, tags, parentId }, e)
    e.preventDefault()
  }

  return (
    <div className={styles.itemWrapper}>
      {tags && tags.length > 0 && (
        <div className={styles.controlWrapper}>
          <a
            className={classNames(styles.collapseButton, {
              [styles.isCollapsed]: isCollapsed
            })}
            onClick={collapseToggle}
          />
        </div>
      )}
      <div
        className={styles.itemContent}
        onClick={collapseToggle}
        {...!isTag && {onContextMenu: onContextMenuHandler}}
      >
        <div
          className={classNames(styles.title, {
            [styles.hasMenuOpened]:
              hasMenuOpened && menuProps.id === id && parentId === menuProps.parentId
          })}
        >
          <span>{name}</span>
        </div>
      </div>
      {isCollapsed && (
        <div className={styles.container}>
          <ActivityCategoryContainer
            setMenuOpenedState={setMenuOpenedState}
            hasMenuOpened={hasMenuOpened}
            menuProps={menuProps}
            data={tags}
            updateTreeState={updateTreeState}
            treeState={treeState}
            parentId={id}
            isTag
          />
        </div>
      )}
    </div>
  )
}
ActivityCategoryItem.propTypes = {
  name: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })),
  setMenuOpenedState: PropTypes.func.isRequired,
  hasMenuOpened: PropTypes.bool.isRequired,
  menuProps: PropTypes.object.isRequired,
  updateTreeState: PropTypes.func.isRequired,
  treeState: PropTypes.object.isRequired,
  parentId: PropTypes.string,
  category: PropTypes.object,
  isTag: PropTypes.bool
}
ActivityCategoryItem.defaultProps = {
  name: null,
  parentId: null,
  tags: [],
  category: {},
  isTag: false
}
