/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  activateAPP,
  deactivateAPP,
  unDeactivateAPP,
  updateDeactivationAPP
} from 'services/http/activity'
import { helpers as activityProductFormHelpers } from 'connectors/reducers/activityProductForm'

import helpers from './helpers'

const {
  REQUEST_ACTIVATE,
  RESPONSE_ACTIVATE,
  RESPONSE_ACTIVATE_ERROR,
  REQUEST_DEACTIVATE,
  RESPONSE_DEACTIVATE,
  RESPONSE_DEACTIVATE_ERROR,
  RESET_DATA_STATE,
  ACTIVATION_SUCCESS_MESSAGE,
  buildDeactivationMessage,
  DEACTIVATION_CANCELED_MESSAGE
} = helpers

const activateAPPById = (appId, contractData = {}) => async dispatch => {
  const key = appId

  dispatch(actionCreater(REQUEST_ACTIVATE, { key }))
  dispatch(actionCreater(activityProductFormHelpers.REQUEST_FORM))

  const request = await activateAPP(appId, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ACTIVATE_ERROR))
    dispatch(actionCreater(activityProductFormHelpers.RESPONSE_FORM_ERROR, { key }))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_ACTIVATE, { key }))
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(activityProductFormHelpers.RESPONSE_FORM))
    dispatch(send({ ...message, message: ACTIVATION_SUCCESS_MESSAGE }))

    return 'activateAPPById success'
  }

  return 'activateAPPById failed'
}

const deactivateAppById = (appId, contractData = {}, options = {}) => async dispatch => {
  const key = appId

  const { isInactive = false } = options

  dispatch(actionCreater(REQUEST_DEACTIVATE, { key }))
  dispatch(actionCreater(activityProductFormHelpers.REQUEST_FORM))

  const deactivationRequest = isInactive ? updateDeactivationAPP : deactivateAPP

  const request = await deactivationRequest(appId, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DEACTIVATE_ERROR, { key }))
    dispatch(actionCreater(activityProductFormHelpers.RESPONSE_FORM_ERROR))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_DEACTIVATE, { key }))

    const {
      message: { message, send }
    } = request

    dispatch(actionCreater(activityProductFormHelpers.RESPONSE_FORM))
    dispatch(send({ ...message, message: buildDeactivationMessage(contractData) }))

    return 'deactivateAppById success'
  }

  return 'deactivateAppById failed'
}

const unDeactivateAppById = appId => async dispatch => {
  const key = appId

  dispatch(actionCreater(REQUEST_DEACTIVATE, { key }))
  dispatch(actionCreater(activityProductFormHelpers.REQUEST_FORM))

  const request = await unDeactivateAPP(appId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DEACTIVATE_ERROR, { key }))
    dispatch(actionCreater(activityProductFormHelpers.RESPONSE_FORM_ERROR))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_DEACTIVATE, { key }))

    const {
      message: { message, send }
    } = request

    dispatch(actionCreater(activityProductFormHelpers.RESPONSE_FORM))
    dispatch(send({ ...message, message: DEACTIVATION_CANCELED_MESSAGE }))

    return 'deactivateAppById success'
  }

  return 'deactivateAppById failed'
}

const resetForm = () => ({
  type: RESET_DATA_STATE
})

const resetState = appId => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key: appId }))

export default {
  resetForm,
  activateAPPById,
  deactivateAppById,
  unDeactivateAppById,
  resetState
}
