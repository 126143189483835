import get from 'lodash/get'
import moment from 'moment'

export function getRange(value) {
  const from = get(value, 'from', null)
  const to = get(value, 'to', null)

  return {
    from,
    to
  }
}

export function createRange(value, format) {
  const { from, to } = getRange(value)
  const start = moment(from, format)
  const end = moment(to, format)

  return {
    from: start && start.isValid() ? start.toDate() : null,
    to: end && end.isValid() ? end.toDate() : null
  }
}

export function createValue(value, format) {
  const { from, to } = getRange(value)

  return {
    from: from ? moment(from).format(format) : null,
    to: to ? moment(to).format(format) : null
  }
}
