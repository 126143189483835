/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildURLForGettingEntityById } from 'services/http/helpers'
import { PMS_URL } from 'endpoints'

export const createPMS = contractData =>
  httpWrapper({
    url: PMS_URL,
    method: 'post',
    data: contractData
  })

export const updatePMS = contractData =>
  httpWrapper({
    url: PMS_URL,
    method: 'put',
    data: contractData
  })

export const getPMSById = (id, params) =>
  httpWrapper({
    url: buildURLForGettingEntityById(PMS_URL, id),
    method: 'get',
    params
  })

export const deletePMSById = (id, params) =>
  httpWrapper({
    url: buildURLForGettingEntityById(PMS_URL, id),
    method: 'delete',
    params
  })
