/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions as editQueueActions } from 'reducers/editQueue'
import { isProgramActionPending, getProgramDataState } from 'reducers/programsForm'
import { getSeasonsListState } from 'reducers/programSeasonList'
import { ProgramSeasons as ProgramSeasonsComponent } from './ProgramSeasons'

const mapStateToProps = (state, props) => {
  const { dataId } = props
  const seasonsListState = getSeasonsListState(state, dataId)
  const programFormState = getProgramDataState(state, dataId)
  return {
    seasonsData: seasonsListState.seasons,
    seasonsListState: seasonsListState.dataState,
    seasonsDataState: state.seasonForm.dataState,
    isProgramActionPending: isProgramActionPending(state, dataId),
    programData: programFormState.programData,
    programDataState: programFormState.programDataState,
    cloneDataState: state.seasonForm.cloneDataState
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...editQueueActions
    },
    dispatch
  )

export const ProgramSeasons = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProgramSeasonsComponent)
