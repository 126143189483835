/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { UPDATE_LEVEL } from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import { isTagImmutable } from 'modules/ActivityTags/helpers'
import { DeleteEntityButton } from 'components/Buttons'
import ModalRemoval from 'modules/ModalRemoval'
import {
  ORANGE_SCHEMA,
  SMALLER_SIZE,
  DELETE_TITLE,
  SUBMIT_TITLE,
  CANCEL_TITLE
} from 'constants/buttonsOptions'

export function DeleteButton(props) {
  const { deleteAction, authUser, data } = props
  const [showModal, setShowModalState] = useState(false)

  const onShowModal = () => {
    setShowModalState(true)
  }

  const onCloseModal = () => {
    setShowModalState(false)
  }

  const onDeleteHandler = id => () => {
    deleteAction(id)
    onCloseModal()
  }

  const id = get(data.original, 'id')
  const tagType = get(data.original, 'type')
  const canUpdate = isActionAllowed(UPDATE_LEVEL, authUser, data.original) && id && !isTagImmutable(tagType)

  return (
    <>
      <DeleteEntityButton
        colorSchema={ORANGE_SCHEMA}
        size={SMALLER_SIZE}
        disabled={!canUpdate}
        onClick={onShowModal}
      >
        {DELETE_TITLE}
      </DeleteEntityButton>
      <ModalRemoval
        actionText={SUBMIT_TITLE}
        cancelText={CANCEL_TITLE}
        actionCallback={onDeleteHandler(id)}
        cancelCallback={onCloseModal}
        originalData={showModal}
      />
    </>
  )
}
DeleteButton.propTypes = {
  data: PropTypes.object,
  authUser: PropTypes.object,
  deleteAction: PropTypes.func.isRequired
}
DeleteButton.defaultProps = {
  data: {},
  authUser: null
}
