/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'

import { withFormContext } from 'components/FormComponents/helpers'
import { withBlankValidation } from 'helpers/formHelpers'
import classNames from 'classnames'

import { Select } from './Select'
import { DEFAULT_PLACEHOLDER } from './types'
import styles from './Select.scss'

export const SelectFieldComponent = props => {
  const {
    disabled,
    options,
    placeholder,
    fieldName,
    validate,
    showSelect,
    isLoading,
    loadingMessage,
    isRequired,
    requiredActionMessage,
    children,
    className,
    ...rest
  } = props

  const validateStrategy = isRequired ? withBlankValidation(validate) : validate

  const dynamicPlaceholder =
    (isLoading && loadingMessage) ||
    (!showSelect && !isLoading && requiredActionMessage) ||
    placeholder
  const dynamicDisabling = !showSelect || (isLoading && disabled) || disabled

  return (
    <Select
      className={classNames(styles.customSelectStyles, className)}
      isRequired={isRequired}
      fieldChildren={children}
      field={fieldName}
      options={options}
      placeholder={dynamicPlaceholder}
      disabled={dynamicDisabling}
      validate={validateStrategy}
      validateOnChange
      {...rest}
    />
  )
}

SelectFieldComponent.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.func,
  isRequired: PropTypes.bool,
  options: PropTypes.array,
  showSelect: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  requiredActionMessage: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

SelectFieldComponent.defaultProps = {
  placeholder: DEFAULT_PLACEHOLDER,
  isLoading: false,
  loadingMessage: 'Loading...',
  showSelect: true,
  disabled: false,
  validate: () => null,
  options: [],
  isRequired: false,
  requiredActionMessage: '',
  className: styles.input
}

export const SelectField = withFormContext(SelectFieldComponent)
