/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  createEntity,
  updateEntity,
  getEntityById,
  deleteEntityById
} from 'services/http/activityModule'

import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID_ERROR,
  REQUEST_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID_ERROR,
  buildDeleteMessage
} = helpers

const postActivityModuleForm = (contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await createEntity(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM))
    dispatch(send(message))
    return 'postActivityModuleForm success'
  }

  return 'postActivityModuleForm failed'
}

const putActivityModuleForm = (contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await updateEntity(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM))
    dispatch(send(message))
    return 'putActivityModuleForm success'
  }

  return 'putActivityModuleForm failed'
}

const getActivityModuleById = (id, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_ENTITY_BY_ID))
  const request = await getEntityById(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID, request.data))
    return 'getActivityModuleById success'
  }

  return 'getActivityModuleById failed'
}

const removeActivityModuleById = (id, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_REMOVE_ENTITY_BY_ID))
  const request = await deleteEntityById(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_REMOVE_ENTITY_BY_ID_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildDeleteMessage() }))
    dispatch(actionCreater(RESPONSE_REMOVE_ENTITY_BY_ID))
    return 'removeActivityModuleById success'
  }

  return 'removeActivityModuleById failed'
}

const resetForm = () => ({
  type: RESET_DATA_STATE
})

export default {
  postActivityModuleForm,
  putActivityModuleForm,
  getActivityModuleById,
  removeActivityModuleById,
  resetForm
}
