/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { searchByColumns } from 'services/http/search'
import { ACTIVITY_PROVIDER } from 'constants/searchTypes'
import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST } = helpers

const getProvidersList = (filters = [], options = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await searchByColumns(filters, {
    keyword: '',
    type: ACTIVITY_PROVIDER,
    ...options
  }).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { ...request.data }))
    return 'getProvidersList success'
  }

  return 'getProvidersList failed'
}

export default {
  getProvidersList
}
