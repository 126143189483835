/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { PERIOD } from 'constants/eligibility'
import { anyNumberValidation } from 'helpers/formHelpers'
import { NumberPeriod, numberRangeValidation } from './NumberPeriod'
import { NumberInput } from './NumberInput'
import {
  NUMBER_PARAMETER_PLACEHOLDER,
  START_NUMBER_PLACEHOLDER,
  END_NUMBER_PLACEHOLDER
} from './types'

export function getComponentByMode(mode) {
  switch (mode) {
    case PERIOD:
      return {
        Component: NumberPeriod,
        props: {
          validate: numberRangeValidation,
          startNumber: { placeholder: START_NUMBER_PLACEHOLDER },
          endNumber: { placeholder: END_NUMBER_PLACEHOLDER }
        }
      }
    default:
      return {
        Component: NumberInput,
        props: {
          validate: anyNumberValidation,
          placeholder: NUMBER_PARAMETER_PLACEHOLDER
        }
      }
  }
}
