/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import get from 'lodash/get'

import {
  FORM_NAME,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_TYPE,
  DELETE_ACTIVITY,
  CHANGE_EDIT_MODE
} from './types'

const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`
const RESET_ACTIVITY_DATA_STATE = `${RESET_STATE}_ACTIVITY_${FORM_NAME}`

const REQUEST_UPDATE_ACTIVITY = `${REQUEST_TYPE}_${UPDATE_ACTIVITY}`
const RESPONSE_UPDATE_ACTIVITY = `${RESPONSE_TYPE}_${UPDATE_ACTIVITY}`
const RESPONSE_UPDATE_ACTIVITY_ERROR = `${RESPONSE_ERROR_TYPE}_${UPDATE_ACTIVITY}`

const REQUEST_UPDATE_ACTIVITY_TYPE = `${REQUEST_TYPE}_${UPDATE_ACTIVITY_TYPE}`
const RESPONSE_UPDATE_ACTIVITY_TYPE = `${RESPONSE_TYPE}_${UPDATE_ACTIVITY_TYPE}`
const RESPONSE_UPDATE_ACTIVITY_TYPE_ERROR = `${RESPONSE_ERROR_TYPE}_${UPDATE_ACTIVITY_TYPE}`

const REQUEST_DELETE_ACTIVITY = `${REQUEST_TYPE}_${DELETE_ACTIVITY}`
const RESPONSE_DELETE_ACTIVITY = `${RESPONSE_TYPE}_${DELETE_ACTIVITY}`
const RESPONSE_DELETE_ACTIVITY_ERROR = `${RESPONSE_ERROR_TYPE}_${DELETE_ACTIVITY}`

const activityStateKeyBuilder = (dealId, activityId) => `${dealId}_${activityId}`

function buildActivitiesState(state, action, defaultActivityState) {
  const { id, groups } = action.payload
  return {
    ...state,
    activities: {
      ...groups.reduce(
        (acc, value) => ({
          ...acc,
          [activityStateKeyBuilder(id, get(value, 'activity.id'))]: {
            ...defaultActivityState,
            data: value
          }
        }),
        {}
      )
    }
  }
}

export default {
  RESET_DATA_STATE,
  REQUEST_UPDATE_ACTIVITY,
  RESPONSE_UPDATE_ACTIVITY,
  RESPONSE_UPDATE_ACTIVITY_ERROR,
  activityStateKeyBuilder,
  REQUEST_UPDATE_ACTIVITY_TYPE,
  RESPONSE_UPDATE_ACTIVITY_TYPE,
  RESPONSE_UPDATE_ACTIVITY_TYPE_ERROR,
  REQUEST_DELETE_ACTIVITY,
  RESPONSE_DELETE_ACTIVITY,
  RESPONSE_DELETE_ACTIVITY_ERROR,
  CHANGE_EDIT_MODE,
  RESET_ACTIVITY_DATA_STATE,
  buildActivitiesState
}
