import {
  REQUEST_TYPE,
  RESET_STATE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE
} from 'helpers/actionHelpers'

import types from './types'

const { LIST_NAME, GET_YEARS } = types

const REQUEST_GET_DATA = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_GET_DATA = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_GET_DATA_ERROR = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`

const REQUEST_GET_YEARS = `${REQUEST_TYPE}_${GET_YEARS}`
const RESPONSE_GET_YEARS = `${RESPONSE_TYPE}_${GET_YEARS}`
const RESPONSE_GET_YEARS_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_YEARS}`

const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

export default {
  RESET_DATA_STATE,
  REQUEST_GET_DATA,
  RESPONSE_GET_DATA,
  RESPONSE_GET_DATA_ERROR,
  REQUEST_GET_YEARS,
  RESPONSE_GET_YEARS,
  RESPONSE_GET_YEARS_ERROR
}
