/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { FilteredSearch, TableField } from 'modules/FilteredSearch'
import { PMCsList } from 'modules/PMCs/PMCsList'
import { PM_COMPANY } from 'constants/searchTypes'
import { pmcTableColumns } from 'helpers/dataTablesHelpers'
import { DotsButtonWithExport } from 'modules/ExportEntity'
import { FormFilter } from 'components/FormComponents'
import { buildFilters } from 'containers/PMCs/helpers'
import { PMC_FILTERS, PMC_INITIAL_FILTERS, EXPORT_FILENAME } from 'containers/PMCs/types'
import styles from './PMCSearchResult.scss'

export function PMCSearchResult(props) {
  const { onChange, data, state, dataState, itemsPerPage, pageNumber, itemsCount } = props
  const filteredSearchApi = useRef()

  const onGetApi = useCallback(api => {
    filteredSearchApi.current = api
  }, [])

  const getFilters = useCallback(() => buildFilters(filteredSearchApi.current.getFilters()), [])
  const getParams = useCallback(
    () => ({ ...state.parameters, ...filteredSearchApi.current.getParams() }),
    [state.parameters]
  )

  const onGetDataHandler = useCallback(
    ({ filters, params }) => {
      onChange({ parameters: params, filters: buildFilters(filters) })
    },
    [onChange]
  )

  return (
    <FilteredSearch
      getApi={onGetApi}
      onChange={onGetDataHandler}
      initialFilters={PMC_INITIAL_FILTERS}
    >
      <div className={styles.barWrapper}>
        <FormFilter filters={PMC_FILTERS} />
        <DotsButtonWithExport
          onGetFilters={getFilters}
          onGetParams={getParams}
          entity={PM_COMPANY}
          columns={pmcTableColumns}
          filename={EXPORT_FILENAME}
        />
      </div>
      <TableField
        Component={PMCsList}
        dataState={dataState}
        data={data}
        itemsPerPage={itemsPerPage}
        itemsCount={itemsCount}
        pageNumber={pageNumber}
        // TODO: Need adjust and refactor it with reimplement this functionality.
        withBasicAuth={false}
        needFirstGetData={false}
      />
    </FilteredSearch>
  )
}

PMCSearchResult.propTypes = {
  filters: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array,
  dataState: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number,
  itemsCount: PropTypes.number,
  pageNumber: PropTypes.number,
  enqueue: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired
}

PMCSearchResult.defaultProps = {
  filters: [],
  data: [],
  itemsCount: 0,
  itemsPerPage: 10,
  pageNumber: 1
}
