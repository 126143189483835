/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const {
  NAME,
  GET_ENTITY_DATA,
  DELETE_SERIES,
  DELETE_OCCURRENCE,
  DELETE_OCCURRENCES,
  GET_INTERSECTIONS
} = types

const REQUEST_FORM = `${REQUEST_TYPE}_${NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${NAME}`

const REQUEST_GET_ENTITY_DATA = `${REQUEST_TYPE}_${GET_ENTITY_DATA}`
const RESPONSE_GET_ENTITY_DATA = `${RESPONSE_TYPE}_${GET_ENTITY_DATA}`
const RESPONSE_GET_ENTITY_DATA_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_ENTITY_DATA}`

const REQUEST_DELETE_SERIES = `${REQUEST_TYPE}_${DELETE_SERIES}`
const RESPONSE_DELETE_SERIES = `${RESPONSE_TYPE}_${DELETE_SERIES}`
const RESPONSE_DELETE_SERIES_ERROR = `${RESPONSE_ERROR_TYPE}_${DELETE_SERIES}`

const REQUEST_DELETE_OCCURRENCE = `${REQUEST_TYPE}_${DELETE_OCCURRENCE}`
const RESPONSE_DELETE_OCCURRENCE = `${RESPONSE_TYPE}_${DELETE_OCCURRENCE}`
const RESPONSE_DELETE_OCCURRENCE_ERROR = `${RESPONSE_ERROR_TYPE}_${DELETE_OCCURRENCE}`

const REQUEST_DELETE_OCCURRENCES = `${REQUEST_TYPE}_${DELETE_OCCURRENCES}`
const RESPONSE_DELETE_OCCURRENCES = `${RESPONSE_TYPE}_${DELETE_OCCURRENCES}`
const RESPONSE_DELETE_OCCURRENCES_ERROR = `${RESPONSE_ERROR_TYPE}_${DELETE_OCCURRENCES}`

const REQUEST_INTERSECTIONS = `${REQUEST_TYPE}_${GET_INTERSECTIONS}`
const RESPONSE_INTERSECTIONS = `${RESPONSE_TYPE}_${GET_INTERSECTIONS}`
const RESPONSE_INTERSECTIONS_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_INTERSECTIONS}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

export const buildKey = key => key || 'new'

const SUCCESS_ADD_MESSAGE = 'Closure has been added!'
const SUCCESS_UPDATED_MESSAGE = 'Closure has been updated!'

const SUCCESS_DELETE_SERIES_MESSAGE = 'Closure has been removed!'
const ERROR_DELETE_SERIES_MESSAGE = 'Closure is not removed!'

const SUCCESS_DELETE_OCCURRENCE_MESSAGE = SUCCESS_DELETE_SERIES_MESSAGE
const ERROR_DELETE_OCCURRENCE_MESSAGE = ERROR_DELETE_SERIES_MESSAGE

const SUCCESS_DELETE_OCCURRENCES_MESSAGE = SUCCESS_DELETE_SERIES_MESSAGE
const ERROR_DELETE_OCCURRENCES_MESSAGE = ERROR_DELETE_SERIES_MESSAGE

export default {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA_ERROR,
  RESET_DATA_STATE,
  buildKey,
  SUCCESS_ADD_MESSAGE,
  SUCCESS_UPDATED_MESSAGE,
  REQUEST_DELETE_SERIES,
  RESPONSE_DELETE_SERIES,
  RESPONSE_DELETE_SERIES_ERROR,
  SUCCESS_DELETE_SERIES_MESSAGE,
  ERROR_DELETE_SERIES_MESSAGE,
  SUCCESS_DELETE_OCCURRENCE_MESSAGE,
  ERROR_DELETE_OCCURRENCE_MESSAGE,
  REQUEST_DELETE_OCCURRENCE,
  RESPONSE_DELETE_OCCURRENCE,
  RESPONSE_DELETE_OCCURRENCE_ERROR,
  REQUEST_DELETE_OCCURRENCES,
  RESPONSE_DELETE_OCCURRENCES,
  RESPONSE_DELETE_OCCURRENCES_ERROR,
  SUCCESS_DELETE_OCCURRENCES_MESSAGE,
  ERROR_DELETE_OCCURRENCES_MESSAGE,
  REQUEST_INTERSECTIONS,
  RESPONSE_INTERSECTIONS,
  RESPONSE_INTERSECTIONS_ERROR
}
