/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const GROUP_NAME_PLACEHOLDER = 'Name your group'
export const TICKETS_TYPE_LABEL = 'Select Type of Ticket for Group'

export const INV_NOTIFICATION_LABEL = 'Inventory Notification'
export const INV_NOTIFICATION_PLACEHOLDER = 'Inventory Notification'
