/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const buildURLForGettingEntityById = (baseURL = '404', id = '0') => `${baseURL}/${id}`

export const buildURLForGettingEntityByIdExtended = (baseURL, id) =>
  `${buildURLForGettingEntityById(baseURL, id)}/extended`

export const buildURLForUnassignPhoneNumber = (baseURL, id) =>
  `${buildURLForGettingEntityById(baseURL, id)}/phone`

export const buildURLForAssignPhoneNumber = (baseURL, id, phoneId) =>
  `${buildURLForGettingEntityById(baseURL, id)}/phone/${phoneId}`

export const buildURLForGettingSingleEntityById = (baseURL, id) =>
  `${buildURLForGettingEntityById(baseURL, id)}`
