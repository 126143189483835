/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  httpWrapper,
  buildListParams,
  buildURLForGettingExtendedList,
  buildURLForGettingEntityById,
  buildURLForGettingListCount
} from 'services/http/helpers'
import {
  ACTIVITY_URL,
  PROGRAMS_URL,
  ACTIVITY_EXPORT_MASTER_URL,
  ACTIVITY_PROVIDER_URL,
  SEASON_ACTIVITIES_URL,
  LOCATION_ACTIVITIES_URL,
  ACTIVITY_RESELL_SEARCH_URL
} from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'

export const getListExtended = (authKey, options) =>
  httpWrapper({
    url: buildURLForGettingExtendedList(ACTIVITY_URL),
    method: 'get',
    params: buildListParams(options)
  })

export const getListBySeasonId = (programId, id, options) =>
  httpWrapper({
    url: buildPath(SEASON_ACTIVITIES_URL, { programId, id }),
    method: 'get',
    params: buildListParams(options)
  })

export const getListCount = (authKey, params) =>
  httpWrapper({
    url: buildURLForGettingListCount(ACTIVITY_URL),
    method: 'get',
    params
  })

export const getReport = (authKey, options) =>
  httpWrapper({
    url: ACTIVITY_EXPORT_MASTER_URL,
    method: 'get',
    params: options
  })

export const getListByProgramId = (id, options) =>
  httpWrapper({
    url: `${buildURLForGettingEntityById(PROGRAMS_URL, id)}/activities/resale`,
    method: 'get',
    params: options
  })

export const getActivitiesListByAPId = (apId, options) =>
  httpWrapper({
    url: `${ACTIVITY_PROVIDER_URL}/${apId}/activities`,
    method: 'get',
    params: options
  })

export const getActivitiesListByLocationId = (id, options) =>
  httpWrapper({
    url: buildPath(LOCATION_ACTIVITIES_URL, { id }),
    method: 'get',
    params: buildListParams(options)
  })

export const searchResellActivities = (filtersBody, options) =>
  httpWrapper({
    url: buildPath(ACTIVITY_RESELL_SEARCH_URL),
    method: 'post',
    data: filtersBody,
    params: buildListParams(options)
  })
