/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildURLForGettingEntityByIdExtended } from 'services/http/helpers'
import { PROGRAMS_URL, PROGRAM_ONBOARDING_URL } from 'endpoints'
import { buildRequestParams } from 'helpers/searchParamsHelper'

export const createProgram = (authKey, contractData) =>
  httpWrapper({ url: PROGRAMS_URL, method: 'post', data: contractData })

export const updateProgram = (authKey, contractData) =>
  httpWrapper({ url: PROGRAMS_URL, method: 'put', data: contractData })

export const deleteProgram = (authKey, id, params, payload) =>
  httpWrapper({ url: `${PROGRAMS_URL}/${id}`, method: 'delete', params, data: payload })

export const getProgramByIdExtended = (id, params) =>
  httpWrapper({
    url: buildURLForGettingEntityByIdExtended(PROGRAMS_URL, id),
    method: 'get',
    params
  })

export const createEmptyOnBoardingProgram = initialName => {
  const params = buildRequestParams({ initialName });

  return httpWrapper({
    url: `${PROGRAM_ONBOARDING_URL}${params}`,
    method: 'post',
    data: {}
  })
}
