/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDidMount, useSlider, useLoading } from 'hooks'
import { SmartHostUnitsList, buildSmartHostExportUnitsColumns, UnitsStats } from 'modules/Units'
import { canEditProgramUnits } from 'modules/Programs'
import { BasicButton, DotsButton } from '@xplorie/ui-commons'
import { UNIT_MANAGER } from 'constants/formCodes'
import { ORANGE_SCHEMA, UNIT_MANAGER_TITLE } from 'constants/buttonsOptions'
import { PIECurtainLoader } from 'components/Loaders'
import { STATUS_ACTIVE, STATUS_INACTIVE, STATUS_PENDING } from 'constants/entityStatus'
import get from 'lodash/get'
import { buildUnitsActionColumns } from 'modules/Units/UnitsList/helpers'
import { buildTableColumns } from './helpers'
import { EXPORT_LABEL } from './types'
import styles from './ProgramUnits.scss'

export function ProgramUnits(props) {
  const {
    onGetData,
    dataId,
    data,
    dataState,
    itemsPerPage,
    itemsCount,
    pageNumber,
    exportDataState,
    exportUnitsByProgramIdList,
    authUser,
    programData,
    ...rest
  } = props

  const listApi = useRef()
  const [isExporting] = useLoading(exportDataState)
  const [, { isLoaded }] = useLoading(dataState)
  // const canExport = useMemo(() => canExportUnits(authUser), [authUser])

  const pmcData = useMemo(() => get(programData, 'pmCompany'), [programData])
  const pmcId = useMemo(() => get(pmcData, 'id'), [pmcData])
  const tableColumns = buildTableColumns(props)

  const isVisibleEditUnits = useMemo(
    () =>
      canEditProgramUnits(authUser) &&
      ![STATUS_INACTIVE, STATUS_PENDING].includes(get(programData, 'status')),
    [authUser, programData]
  )

  const isEditUnitsDisabled = useMemo(
    () => !pmcId || (pmcData && pmcData.status !== STATUS_ACTIVE) || !isLoaded,
    [isLoaded, pmcData, pmcId]
  )

  const prepareOptions = useCallback(options => ({ ...options, itemsPerPage: 0 }), [])

  const onGetUnitsList = useCallback(
    (options = {}) => {
      if (dataId) {
        onGetData(dataId, prepareOptions(options))
      }
    },
    [dataId, onGetData, prepareOptions]
  )

  const onGetListApi = useCallback(api => {
    listApi.current = api
  }, [])

  const onExportProgramUnits = useCallback(() => {
    exportUnitsByProgramIdList(
      dataId,
      buildSmartHostExportUnitsColumns(programData),
      prepareOptions(listApi.current.getListParameters())
    )
  }, [dataId, exportUnitsByProgramIdList, prepareOptions, programData])

  useDidMount(() => {
    onGetUnitsList()
  })

  const dotsButtonOptions = useMemo(
    () => [
      {
        label: EXPORT_LABEL,
        props: { onClick: onExportProgramUnits, disabled: !data.length }
      }
    ],
    [data.length, onExportProgramUnits]
  )

  const [, sliderApi] = useSlider(`${pmcId}_${UNIT_MANAGER}`, UNIT_MANAGER, {
    onClose: () => {
      // get list when unit commander will close
      const options = listApi.current.getListParameters()
      onGetUnitsList(options)
    }
  })

  const onEditUnitsHandler = useCallback(() => {
    if (pmcId) {
      sliderApi.open({
        props: { pmcId, programId: dataId }
      })
    }
  }, [dataId, pmcId, sliderApi])

  return (
    <>
      {isExporting && <PIECurtainLoader />}
      <div className={styles.topButtons}>
        <DotsButton align="left" options={dotsButtonOptions} />
      </div>
      <SmartHostUnitsList
        getApi={onGetListApi}
        data={data}
        dataState={dataState}
        onGetData={onGetUnitsList}
        itemsPerPage={itemsPerPage}
        itemsCount={itemsCount}
        pageNumber={pageNumber}
        showPagination={false}
        programData={programData}
        columns={tableColumns}
        actions={buildUnitsActionColumns()}
        {...rest}
      />
      <div className={styles.footer}>
        <UnitsStats data={data} />
        {isVisibleEditUnits && (
          <BasicButton
            colorSchema={ORANGE_SCHEMA}
            onClick={onEditUnitsHandler}
            disabled={isEditUnitsDisabled}
          >
            {UNIT_MANAGER_TITLE}
          </BasicButton>
        )}
      </div>
    </>
  )
}
ProgramUnits.propTypes = {
  onGetData: PropTypes.func.isRequired,
  dataId: PropTypes.string,
  dataState: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  programDataState: PropTypes.string.isRequired,
  getProgramById: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  exportDataState: PropTypes.string.isRequired,
  exportUnitsByProgramIdList: PropTypes.func.isRequired,
  programData: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired
}
ProgramUnits.defaultProps = {
  dataId: null
}
