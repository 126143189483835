/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const START_FIELD_LABEL = 'Start Time'
export const START_FIELD_PLACEHOLDER = 'Start Time'
export const START_FIELD_FIELD = 'startTime'

export const END_FIELD_LABEL = 'End Time'
export const END_FIELD_PLACEHOLDER = 'End Time'
export const END_FIELD_FIELD = 'endTime'

export const ADD_TIME_SLOT_TITLE = 'Add Time Slot'
export const EDIT_TIME_SLOT_TITLE = 'Edit Time Slot'

export const TIME_PERIOD_FORMAT = 'hh:mm A'
