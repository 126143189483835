/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import isNumber from 'lodash/isNumber'

export function setCookie(name, value, props = {}) {
  if (!name) {
    return null
  }
  const preparedOptions = {
    ...props
  }
  if (isNumber(preparedOptions.expires) && preparedOptions.expires) {
    const currentDate = new Date()
    currentDate.setTime(currentDate.getTime() + preparedOptions.expires * 1000)
    preparedOptions.expires = currentDate
  }
  if (preparedOptions.expires && preparedOptions.expires.toUTCString) {
    preparedOptions.expires = preparedOptions.expires.toUTCString()
  }
  const encodedValue = encodeURIComponent(value)
  const updatedCookie = Object.keys(preparedOptions).reduce(
    (acc, key) =>
      preparedOptions[key] ? `${acc}; ${key}=${preparedOptions[key]}` : acc,
    `${name}=${encodedValue}`
  )
  document.cookie = updatedCookie
  return updatedCookie
}

export function deleteCookie(name) {
  setCookie(name, '', {
    expires: -1
  })
}

export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line no-useless-escape
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : null
}
