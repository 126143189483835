/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const NAME = 'PROGRAM_LIFE_CYCLE'
const ACTIVATE_PROGRAM = `${NAME}_ACTIVATE`
const DEACTIVATE_PROGRAM = `${NAME}_DEACTIVATE`

export default {
  NAME,
  ACTIVATE_PROGRAM,
  DEACTIVATE_PROGRAM
}
