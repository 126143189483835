/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { FORM_NAME, GET_ENTITY_BY_ID, REMOVE_ENTITY_BY_ID } from './types'

const REQUEST_FORM = `${REQUEST_TYPE}_${FORM_NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${FORM_NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${FORM_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

const REQUEST_GET_ENTITY_BY_ID = `${REQUEST_TYPE}_${GET_ENTITY_BY_ID}`
const RESPONSE_GET_ENTITY_BY_ID = `${RESPONSE_TYPE}_${GET_ENTITY_BY_ID}`
const RESPONSE_GET_ENTITY_BY_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_ENTITY_BY_ID}`

const REQUEST_REMOVE_ENTITY_BY_ID = `${REQUEST_TYPE}_${REMOVE_ENTITY_BY_ID}`
const RESPONSE_REMOVE_ENTITY_BY_ID = `${RESPONSE_TYPE}_${REMOVE_ENTITY_BY_ID}`
const RESPONSE_REMOVE_ENTITY_BY_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${REMOVE_ENTITY_BY_ID}`

const CREATED_MESSAGE_TYPE = 'CREATED_MESSAGE_TYPE'
const UPDATE_MESSAGE_TYPE = 'UPDATE_MESSAGE_TYPE'
const DELETE_MESSAGE_TYPE = 'DELETE_MESSAGE_TYPE'
const buildMessage = (contractData = {}, type) => {
  switch (type) {
    case CREATED_MESSAGE_TYPE:
      return `The ${contractData.name} has been successfully created!`
    case UPDATE_MESSAGE_TYPE:
      return `You have successfully edited ${contractData.name}`
    case DELETE_MESSAGE_TYPE:
      return `The ${contractData.name} has been successfully deleted!`
    default:
      return 'default Message'
  }
}

export default {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID_ERROR,
  REQUEST_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID_ERROR,
  buildMessage,
  CREATED_MESSAGE_TYPE,
  UPDATE_MESSAGE_TYPE,
  DELETE_MESSAGE_TYPE
}
