/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { SMART_HOST_FEE_LIST, SET_SMART_HOST_FEE_MODE } from './types'

export const REQUEST_LIST = `${REQUEST_TYPE}_${SMART_HOST_FEE_LIST}`
export const RESPONSE_LIST = `${RESPONSE_TYPE}_${SMART_HOST_FEE_LIST}`
export const RESPONSE_LIST_ERROR = `${RESPONSE_ERROR_TYPE}_${SMART_HOST_FEE_LIST}`
export const RESET_DATA_STATE = `${RESET_STATE}_${SMART_HOST_FEE_LIST}`
export const MODE = `${SET_SMART_HOST_FEE_MODE}_${SMART_HOST_FEE_LIST}`
