/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions as exclusiveOffersActions } from 'reducers/programExclusiveOffers'
import { ProgramExclusiveOffers as ProgramExclusiveOffersComponent } from './ProgramExclusiveOffers'

const {
  getProgramExclusiveActivitiesList,
  removeProgramExclusiveActivity,
  addProgramExclusiveActivity
} = exclusiveOffersActions

const mapStateToProps = state => ({
  exclusiveOffersDataState: state.programExclusiveOffers.exclusiveActivitiesDataState,
  exclusiveOffersData: state.programExclusiveOffers.exclusiveActivities
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProgramExclusiveActivitiesList,
      removeProgramExclusiveActivity,
      addProgramExclusiveActivity
    },
    dispatch
  )

export const ProgramExclusiveOffers = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProgramExclusiveOffersComponent)
