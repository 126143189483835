/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { initialListState } from './reducer'

export const dealsListStates = state => state.deals.dealsListByActivity

export const dealsListStateByActivityId = (state, id) =>
  dealsListStates(state)[id] || initialListState
