/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'

import { DeleteCategoryModal as DeleteCategoryModalComponent } from './DeleteCategoryModal'

const mapStateToProps = (state, props) => ({
  ...props,
  ...state.activityCategoryForm
})

export const DeleteCategoryModal = compose(
  withAuthContext,
  connect(mapStateToProps)
)(DeleteCategoryModalComponent)
