/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withLoader } from 'decorators'
import { NOT_INDICATED } from 'constants/field'
import { getRoleAlies } from 'helpers/permissionHelper'

import { ProfileField } from './ProfileFields'

import { FIRST_NAME_LABEL, LAST_NAME_LABEL, EMAIL_ADDRESS_LABEL, ROLE_LABEL } from './types'

function ProfileDataComponent(props) {
  const { firstName, lastName, email, roles } = props
  const aliases = roles.map(itemValue => getRoleAlies(itemValue.name)).join(', ')

  return (
    <div>
      <ProfileField label={FIRST_NAME_LABEL} value={firstName} />
      <ProfileField label={LAST_NAME_LABEL} value={lastName} />
      <ProfileField label={EMAIL_ADDRESS_LABEL} value={email} />
      <ProfileField label={ROLE_LABEL} value={aliases} />
    </div>
  )
}

ProfileDataComponent.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  roles: PropTypes.array,
  notificationRequired: PropTypes.string
}

ProfileDataComponent.defaultProps = {
  firstName: NOT_INDICATED,
  lastName: NOT_INDICATED,
  email: NOT_INDICATED,
  roles: null,
  notificationRequired: NOT_INDICATED
}

export const ProfileData = withLoader(ProfileDataComponent)
