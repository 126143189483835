/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function CheckBoxIcon(props) {
  return (
    <svg
      width="35"
      height="37"
      viewBox="0 0 35 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35 1.8352V35.1648C34.9959 35.6506 34.7911 36.1155 34.4299 36.4589C34.0686 36.8022 33.5798 36.9966 33.0692 37H1.93083C1.41908 37 0.928261 36.8067 0.566215 36.4626C0.204169 36.1185 0.00051536 35.6517 0 35.1648V1.8352C0.00407293 1.34935 0.208868 0.884532 0.570145 0.541149C0.931423 0.197766 1.42018 0.00338558 1.93083 0H33.0692C34.1347 0 35 0.8214 35 1.8352ZM31.1111 3.7H3.88889V33.3H31.1111V3.7ZM16.1253 20.5739L24.3756 12.7261L27.125 15.3421L16.1253 25.8075L8.56139 18.611L11.3128 15.9951L16.1253 20.5739Z"
        fill="white"
      />
    </svg>
  )
}
