/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions, selectors } from 'reducers/guestsData/programGuestsDataForm'
import { actions as listActions } from 'reducers/guestsData/programGuestsDataList'
import { getProgramDataState } from 'reducers/programsForm'
import { withAuthContext } from 'helpers/withAuthContext'
import { UploadGuestsForm as UploadGuestsFormComponent } from './UploadGuestsForm'

const { uploadGuestDataFile, cancelUploading, resetForm } = actions
const { downloadTemplate } = listActions
const { getProgramGuestsDataFormStateById } = selectors

function mapStateToProps(state, { dataId }) {
  const formState = getProgramGuestsDataFormStateById(state, dataId)
  const programState = getProgramDataState(state, dataId)
  return {
    dataState: formState.dataState,
    progress: formState.progress,
    programData: programState.programData
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      downloadTemplate,
      uploadGuestDataFile,
      cancelUploading,
      resetForm
    },
    dispatch
  )
}

export const UploadGuestsForm = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UploadGuestsFormComponent)
