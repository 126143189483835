/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const FORM_NAME = 'DEAL_FORM'
export const GET_DATA_BY_ID = `${FORM_NAME}_GET_DATA_BY_ID`
export const CREATE_DEAL_OPTIONS = `${FORM_NAME}_CREATE_DEAL_OPTIONS`
export const UPDATE_DEAL_OPTIONS = `${FORM_NAME}_UPDATE_DEAL_OPTIONS`
export const DELETE_DEAL = `${FORM_NAME}_DELETE_DEAL`
export const ACTIVATE_DEAL = `${FORM_NAME}_ACTIVATE_DEAL`
export const DEACTIVATE_DEAL = `${FORM_NAME}_DEACTIVATE_DEAL`

export default {
  FORM_NAME,
  GET_DATA_BY_ID,
  CREATE_DEAL_OPTIONS,
  UPDATE_DEAL_OPTIONS,
  DELETE_DEAL,
  ACTIVATE_DEAL,
  DEACTIVATE_DEAL
}
