/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'

import { CurtainPortal } from 'portals/CurtainPortal'
import { PIELoader } from 'components/Loaders'

import styles from './PIECurtainLoader.scss'

export function PIECurtainLoader(props) {
  const { message } = props
  return (
    <CurtainPortal>
      <div className={styles.wrapper}>
        <div className={styles.loaderWrapper}>
          <PIELoader />
          <h3 className={styles.message}>{message}</h3>
        </div>
      </div>
    </CurtainPortal>
  )
}

PIECurtainLoader.propTypes = {
  message: PropTypes.string
}

PIECurtainLoader.defaultProps = {
  message: 'Processing...'
}
