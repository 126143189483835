/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA_ERROR,
  REQUEST_GET_ENTITY_ACTIVITIES,
  RESPONSE_GET_ENTITY_ACTIVITIES,
  RESPONSE_GET_ENTITY_ACTIVITIES_ERROR,
  REQUEST_SAVE_ENTITY_ACTIVITIES,
  RESPONSE_SAVE_ENTITY_ACTIVITIES,
  RESPONSE_SAVE_ENTITY_ACTIVITIES_ERROR,
  REQUEST_GET_ENTITY_ACTIVITIES_BY_LOCATION,
  RESPONSE_GET_ENTITY_ACTIVITIES_BY_LOCATION,
  RESPONSE_GET_ENTITY_ACTIVITIES_BY_LOCATION_ERROR,
  REQUEST_CHANGE_STATUS,
  RESPONSE_CHANGE_STATUS,
  RESPONSE_CHANGE_STATUS_ERROR,
  RESET_DATA_STATE,
  UPDATE_STATE_LIST_ACTIVITIES
} = helpers

export const initialState = {}

export const formInitialState = {
  formDataState: DATA_STATE_NOT_REQUESTED,
  activitiesDataState: DATA_STATE_NOT_REQUESTED,
  activities: [],
  saveActivitiesDataState: DATA_STATE_NOT_REQUESTED,
  activityOptions: [],
  activityOptionsDataState: DATA_STATE_NOT_REQUESTED,
  statusDataState: DATA_STATE_NOT_REQUESTED,
  entityDataState: DATA_STATE_NOT_REQUESTED,
  entityData: {}
}

const buildData = (state, action) => data => ({
  ...state,
  [action.payload.key]: {
    ...formInitialState,
    ...state[action.payload.key],
    ...data
  }
})

const reducer = (state = initialState, action) => {
  const appendData = buildData(state, action)

  switch (action.type) {
    case REQUEST_FORM:
      return appendData({
        formDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_FORM:
      return appendData({
        formDataState: DATA_STATE_RECIEVED,
        entityData: action.payload.data,
        entityDataState: DATA_STATE_RECIEVED
      })
    case RESPONSE_FORM_ERROR:
      return appendData({
        formDataState: DATA_STATE_ERROR
      })
    // get entity data
    case REQUEST_GET_ENTITY_DATA:
      return appendData({
        entityDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_GET_ENTITY_DATA:
      return appendData({
        entityDataState: DATA_STATE_RECIEVED,
        entityData: action.payload.data
      })
    case RESPONSE_GET_ENTITY_DATA_ERROR:
      return appendData({
        entityDataState: DATA_STATE_ERROR
      })
    // getting activities entity data
    case REQUEST_GET_ENTITY_ACTIVITIES:
      return appendData({
        activitiesDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_GET_ENTITY_ACTIVITIES:
      return appendData({
        activitiesDataState: DATA_STATE_RECIEVED,
        activities: action.payload.data
      })
    case RESPONSE_GET_ENTITY_ACTIVITIES_ERROR:
      return appendData({
        activitiesDataState: DATA_STATE_ERROR
      })
    // save activities entity data
    case REQUEST_SAVE_ENTITY_ACTIVITIES:
      return appendData({
        saveActivitiesDataState: DATA_STATE_REQUESTING,
        activities: action.payload.data
      })
    case RESPONSE_SAVE_ENTITY_ACTIVITIES:
      return appendData({
        saveActivitiesDataState: DATA_STATE_RECIEVED,
        activities: action.payload.data
      })
    case RESPONSE_SAVE_ENTITY_ACTIVITIES_ERROR:
      return appendData({
        saveActivitiesDataState: DATA_STATE_ERROR,
        activities: action.payload.data
      })
    // get activities by location
    case REQUEST_GET_ENTITY_ACTIVITIES_BY_LOCATION:
      return appendData({
        activityOptionsDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_GET_ENTITY_ACTIVITIES_BY_LOCATION:
      return appendData({
        activityOptionsDataState: DATA_STATE_RECIEVED,
        activityOptions: action.payload.data
      })
    case RESPONSE_GET_ENTITY_ACTIVITIES_BY_LOCATION_ERROR:
      return appendData({
        activityOptionsDataState: DATA_STATE_ERROR
      })
    // change status
    case REQUEST_CHANGE_STATUS:
      return appendData({
        statusDataState: DATA_STATE_REQUESTING
      })
    case RESPONSE_CHANGE_STATUS:
      return appendData({
        statusDataState: DATA_STATE_RECIEVED,
        entityData: action.payload.data,
        activities: action.payload.activities
      })
    case RESPONSE_CHANGE_STATUS_ERROR:
      return appendData({
        statusDataState: DATA_STATE_ERROR
      })
    // change status
    case UPDATE_STATE_LIST_ACTIVITIES:
      return appendData({
        activities: action.payload.data
      })
    case RESET_DATA_STATE: {
      const { [action.payload.key]: removedState, ...restState } = state
      return {
        ...restState
      }
    }
    default:
      return state
  }
}

export default filterActions(reducer, [
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_ENTITY_ACTIVITIES,
  RESPONSE_GET_ENTITY_ACTIVITIES,
  RESPONSE_GET_ENTITY_ACTIVITIES_ERROR,
  REQUEST_SAVE_ENTITY_ACTIVITIES,
  RESPONSE_SAVE_ENTITY_ACTIVITIES,
  RESPONSE_SAVE_ENTITY_ACTIVITIES_ERROR,
  REQUEST_GET_ENTITY_ACTIVITIES_BY_LOCATION,
  RESPONSE_GET_ENTITY_ACTIVITIES_BY_LOCATION,
  RESPONSE_GET_ENTITY_ACTIVITIES_BY_LOCATION_ERROR,
  REQUEST_CHANGE_STATUS,
  RESPONSE_CHANGE_STATUS,
  RESPONSE_CHANGE_STATUS_ERROR,
  UPDATE_STATE_LIST_ACTIVITIES
])
