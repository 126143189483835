/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { ACTIVITY_MODULE } from 'constants/searchTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'

export const activityModuleTableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', ACTIVITY_MODULE)
  }
]
