/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { listInitialState } from './reducer'

export function getPMCEligibilityListState(state) {
  return state.eligibility.pmcEligibilityList
}

export function getPMCEligibilityListStateByPMCId(state, id) {
  return getPMCEligibilityListState(state)[id] || listInitialState
}
