/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  httpWrapper,
  buildURLForGettingEntityByIdExtended,
  buildURLForGettingEntityById,
  buildListParams
} from 'services/http/helpers'
import { ACTIVITY_URL } from 'endpoints'

export const createActivity = (authKey, contractData) =>
  httpWrapper({
    url: ACTIVITY_URL,
    method: 'post',
    data: contractData
  })

export const updateActivity = (authKey, contractData) =>
  httpWrapper({
    url: ACTIVITY_URL,
    method: 'put',
    data: contractData
  })

export const getActivityById = (id, params) =>
  httpWrapper({
    url: buildURLForGettingEntityByIdExtended(ACTIVITY_URL, id),
    method: 'get',
    params
  })

export const switchTesting = id =>
  httpWrapper({
    url: `${buildURLForGettingEntityById(ACTIVITY_URL, id)}/testing`,
    method: 'put'
  })

export const findDealsByActivityId = (id, params) =>
  httpWrapper({
    url: `${buildURLForGettingEntityById(ACTIVITY_URL, id)}/discounts`,
    method: 'get',
    params: buildListParams(params)
  })

export const findExtendedDealsByActivityId = (id, params) =>
  httpWrapper({
    url: `${buildURLForGettingEntityById(ACTIVITY_URL, id)}/discounts/extended`,
    method: 'get',
    params: buildListParams(params)
  })
