/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { TASK_GROUP } from 'constants/searchTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'

const PMCColumn = {
  Header: 'PMC',
  accessor: 'pmcName',
  id: buildSortPropertyByType('pmc_name', TASK_GROUP)
}

const ProgramColumn = {
  Header: 'Program',
  accessor: 'programName',
  id: buildSortPropertyByType('program_name', TASK_GROUP)
}

const StatusConfirmationColumn = {
  Header: 'Status Confirmation',
  accessor: 'confirmationRequiredCount',
  id: buildSortPropertyByType('confirmation_required_count', TASK_GROUP)
}

const UnassignedColumn = {
  Header: 'Unassigned',
  accessor: 'unassignedCount',
  id: buildSortPropertyByType('unassigned_count', TASK_GROUP)
}

const LastTaskDateColumn = {
  Header: 'Last Task date',
  accessor: 'lastTaskDate',
  id: buildSortPropertyByType('last_task_date', TASK_GROUP)
}

export const unitTasksTableColumns = [
  PMCColumn,
  ProgramColumn,
  StatusConfirmationColumn,
  UnassignedColumn,
  LastTaskDateColumn
]
