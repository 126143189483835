/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import moment from 'moment'
import { FORMAT } from 'constants/date'

/**
 * @return {number}
 */
export function getCurrentYear() {
  return moment().year()
}
/**
 *
 * @param {number} year
 * @returns {boolean}
 */
export function isCurrentYear(year) {
  return Number(year) === getCurrentYear()
}
/**
 * @param {object} params
 * @param {string} params.date
 * @param {string} params.description
 * @param {boolean} param.holidayEnabled
 * @returns {boolean}
 */
export function getHolidayDate({ date = null, description = null, holidayEnabled = true }) {
  return holidayEnabled && description ? description : date
}

/**
 *
 * @param {string} value
 * @param {string} format
 * @returns {boolean}
 */
export function isToday(value, format = FORMAT) {
  const date = moment(value, format)
  return moment().isSame(date, 'day')
}

export const isLaterThanToday = (value, format = FORMAT) => {
  const date = moment(value, format)
  return moment().isSameOrBefore(date, 'day')
}
