/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const DELETE_FILES_TITLE = 'Delete Files'
export const DELETE_GROUP_TITLE = 'Delete Group'
export const DELETE_MESSAGE = 'Are you sure you want to delete the group?'
export const YES_TEXT = 'Yes'
export const NO_TEXT = 'No'
