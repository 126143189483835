/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import store from 'connectors'

import { helpers } from 'reducers/currentUser'
import { getAuthToken, clearAuthCookie } from 'services/auth/cookieHelper'
import { actionCreater } from 'helpers/actionHelpers'

// TODO: not used the function
export const onAccessDenied = () => {
  if (getAuthToken()) {
    clearAuthCookie()
    store.dispatch(actionCreater(helpers.RESPONSE_AUTH_ERROR))
  }
}
