/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { NAME, DOWNLOAD_UNITS_TEMPLATE } = types

const REQUEST_DOWNLOAD_UNITS_TEMPLATE = `${REQUEST_TYPE}_${DOWNLOAD_UNITS_TEMPLATE}`
const RESPONSE_DOWNLOAD_UNITS_TEMPLATE = `${RESPONSE_TYPE}_${DOWNLOAD_UNITS_TEMPLATE}`
const RESPONSE_ERROR_DOWNLOAD_UNITS_TEMPLATE = `${RESPONSE_ERROR_TYPE}_${DOWNLOAD_UNITS_TEMPLATE}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

export default {
  REQUEST_DOWNLOAD_UNITS_TEMPLATE,
  RESPONSE_DOWNLOAD_UNITS_TEMPLATE,
  RESPONSE_ERROR_DOWNLOAD_UNITS_TEMPLATE,
  RESET_DATA_STATE
}
