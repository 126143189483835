/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const NAME = 'PROGRAM_ENTITY_ELIGIBILITY_PARAMETERS_FROM'

const ELIGIBILITY_ASSIGN_BULK = `${NAME}_ELIGIBILITY_ASSIGN_BULK`

export default {
  NAME,
  ELIGIBILITY_ASSIGN_BULK
}
