/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  createTileAccordion,
  updateTileAccordion,
  getTileAccordionById,
  deleteTileAccordionById
} from 'services/http/accordionDictionary'

import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID,
  RESPONSE_GET_ENTITY_BY_ID_ERROR,
  REQUEST_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID,
  RESPONSE_REMOVE_ENTITY_BY_ID_ERROR,
  buildDeleteMessage
} = helpers

const postAccordionForm = (contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await createTileAccordion(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM))
    dispatch(send(message))
    return 'postAccordionForm success'
  }

  return 'postAccordionForm failed'
}

const putAccordionForm = (contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await updateTileAccordion(contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_FORM))
    dispatch(send(message))
    return 'putAccordionForm success'
  }

  return 'putAccordionForm failed'
}

const getAccordionDataById = (id, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_ENTITY_BY_ID))
  const request = await getTileAccordionById(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_ENTITY_BY_ID, request.data))
    return 'getAccordionDataById success'
  }

  return 'getPMSDataById failed'
}

const removeAccordionDataById = (id, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_REMOVE_ENTITY_BY_ID))
  const request = await deleteTileAccordionById(id, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_REMOVE_ENTITY_BY_ID_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildDeleteMessage() }))
    dispatch(actionCreater(RESPONSE_REMOVE_ENTITY_BY_ID))
    return 'removeAccordionDataById success'
  }

  return 'removeAccordionDataById failed'
}

const resetForm = () => ({
  type: RESET_DATA_STATE
})

export default {
  postAccordionForm,
  putAccordionForm,
  getAccordionDataById,
  removeAccordionDataById,
  resetForm
}
