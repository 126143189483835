/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { BasicButton } from '@xplorie/ui-commons'
import { Form } from 'informed'
import {
  SUBMIT_TITLE,
  CANCEL_TITLE,
  ORANGE_SCHEMA,
  YELLOW_GREEN_SCHEMA
} from 'constants/buttonsOptions'
import { BasicModal } from 'components/Modals'
import { TextField } from 'components/FormComponents'
import { useSmartHostDateField } from 'modules/Units/SmartHostUnitsList/hooks'

import { REASON_LABEL, REASON_MESSAGE } from './types'
import styles from './styles.scss'

export function DisableSmartHostModal({ isShown, onHide, onConfirm }) {
  const formApi = useRef()

  const getApi = useCallback(api => {
    formApi.current = api
  }, [])

  const DateField = useSmartHostDateField(formApi)

  const bodySection = () => (
    <section className={styles.wrapper}>
      <p className={styles.message}>{REASON_MESSAGE}</p>
      <Form getApi={getApi} onSubmit={onConfirm} initialValues={{ description: '', date: '' }}>
        {DateField}
        <TextField label={REASON_LABEL} fieldName="description" isRequired />
      </Form>
    </section>
  )
  return (
    <BasicModal isOpen={isShown} onClose={onHide} renderBody={bodySection}>
      <BasicButton
        colorSchema={ORANGE_SCHEMA}
        onClick={() => {
          formApi.current.submitForm()
        }}
      >
        {SUBMIT_TITLE}
      </BasicButton>
      <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onHide}>
        {CANCEL_TITLE}
      </BasicButton>
    </BasicModal>
  )
}

DisableSmartHostModal.propTypes = {
  isShown: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired
}
