/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */
import { ACTIVITY, RESALE_SETTINGS } from 'constants/searchTypes'
import {
  AND_OPERATOR,
  OR_OPERATOR,
  OPEN_GROUP_OPERATOR,
  CLOSE_GROUP_OPERATOR,
  buildSearchColumn,
  LIKE_START_WITH_MODE
} from 'helpers/searchHelpers'
import { STATUS_ACTIVE, STATUS_INACTIVE, STATUS_TESTING } from 'constants/entityStatus'

export const TITLE_PAGE = 'Activity Provider Products'
export const TEXT_BTN_ADD_NEW = 'Add New AP Product'

export const BUSINESS_TYPE = 'business_type'
export const TRANSFER_TO_TEAM_LEAD = 'transfer_to_lead'

export const MARKETING = 'MARKETING'
export const NO_RESERVATION_REQUIRED = 'NO_RESERVATION_REQUIRED'
export const RESERVATION_REQUIRED = 'RESERVATION_REQUIRED'

export const ACTIVE_FILTER_KEY = 'ACTIVE_FILTER_KEY'
export const ACTIVE_LABEL = 'Active'

export const INACTIVE_FILTER_KEY = 'INACTIVE_FILTER_KEY'
export const INACTIVE_LABEL = 'Inactive'

export const TESTING_FILTER_KEY = 'TESTING_FILTER_KEY'
export const TESTING_LABEL = 'Testing'

export const MARKETING_FILTER_KEY = 'MARKETING_FILTER_KEY'
export const MARKETING_LABEL = 'Marketing'

export const NO_RESERVATION_REQUIRED_FILTER_KEY = 'NO_RESERVATION_REQUIRED_FILTER_KEY'
export const NO_RESERVATION_REQUIRED_LABEL = 'No Reservation required'

export const RESERVATION_REQUIRED_FILTER_KEY = 'RESERVATION_REQUIRED_FILTER_KEY'
export const RESERVATION_REQUIRED_LABEL = 'Reservation required'

export const TRANSFER_TO_TEAM_LEAD_FILTER_KEY = 'TRANSFER_TO_LEAD_FILTER_KEY'
export const TRANSFER_TO_TEAM_LEAD_LABEL = 'Transfer to Team Lead'

export const EXPORT_FILENAME = 'activity_list.csv'

export const APP_FILTERS = [
  { field: ACTIVE_FILTER_KEY, label: ACTIVE_LABEL },
  { field: INACTIVE_FILTER_KEY, label: INACTIVE_LABEL },
  { field: TESTING_FILTER_KEY, label: TESTING_LABEL },
  { field: MARKETING_FILTER_KEY, label: MARKETING_LABEL },
  { field: NO_RESERVATION_REQUIRED_FILTER_KEY, label: NO_RESERVATION_REQUIRED_LABEL },
  { field: RESERVATION_REQUIRED_FILTER_KEY, label: RESERVATION_REQUIRED_LABEL },
  { field: TRANSFER_TO_TEAM_LEAD_FILTER_KEY, label: TRANSFER_TO_TEAM_LEAD_LABEL }
]

export const APP_INITIAL_FILTERS = {
  [ACTIVE_FILTER_KEY]: true,
  [INACTIVE_FILTER_KEY]: false,
  [TESTING_FILTER_KEY]: true,
  [MARKETING_FILTER_KEY]: false,
  [NO_RESERVATION_REQUIRED_FILTER_KEY]: false,
  [RESERVATION_REQUIRED_FILTER_KEY]: false,
  [TRANSFER_TO_TEAM_LEAD_FILTER_KEY]: false
}

export const ACTIVE_FILTER = buildSearchColumn({
  entity: ACTIVITY,
  field: 'status',
  operator: AND_OPERATOR,
  keyword: STATUS_ACTIVE,
  mode: LIKE_START_WITH_MODE
})

export const INACTIVE_FILTER = buildSearchColumn({
  entity: ACTIVITY,
  field: 'status',
  operator: AND_OPERATOR,
  keyword: STATUS_INACTIVE,
  mode: LIKE_START_WITH_MODE
})

export const TESTING_FILTER = buildSearchColumn({
  entity: ACTIVITY,
  field: 'status',
  operator: AND_OPERATOR,
  keyword: STATUS_TESTING,
  mode: LIKE_START_WITH_MODE
})

export const MARKETING_FILTER = buildSearchColumn({
  entity: ACTIVITY,
  field: BUSINESS_TYPE,
  operator: AND_OPERATOR,
  keyword: MARKETING,
  mode: LIKE_START_WITH_MODE
})

export const NO_RESERVATION_REQUIRED_FILTER = buildSearchColumn({
  entity: ACTIVITY,
  field: BUSINESS_TYPE,
  operator: AND_OPERATOR,
  keyword: NO_RESERVATION_REQUIRED,
  mode: LIKE_START_WITH_MODE
})

export const RESERVATION_REQUIRED_FILTER = buildSearchColumn({
  entity: ACTIVITY,
  field: BUSINESS_TYPE,
  operator: AND_OPERATOR,
  keyword: RESERVATION_REQUIRED,
  mode: LIKE_START_WITH_MODE
})

export const TRANSFER_TO_TEAM_LEAD_FILTER = buildSearchColumn({
  entity: RESALE_SETTINGS,
  field: TRANSFER_TO_TEAM_LEAD,
  operator: AND_OPERATOR,
  keyword: true,
  mode: LIKE_START_WITH_MODE
})

export const IGNORE_FILTERS = [STATUS_ACTIVE, STATUS_INACTIVE, STATUS_TESTING].map(keyword =>
  buildSearchColumn({
    entity: ACTIVITY,
    field: 'status',
    operator: AND_OPERATOR,
    keyword,
    ignore: true,
    mode: LIKE_START_WITH_MODE
  })
)

export const STATUS_FILTERS = [
  {
    ...ACTIVE_FILTER,
    groupOperator: OPEN_GROUP_OPERATOR
  },
  {
    ...INACTIVE_FILTER,
    operator: OR_OPERATOR
  },
  {
    ...TESTING_FILTER,
    operator: OR_OPERATOR,
    groupOperator: CLOSE_GROUP_OPERATOR
  }
]
