import get from 'lodash/get'
import { entityInitialState, listDataState } from './reducer'

function getDiscountHistoryState(state) {
  return state.deals.discountHistory
}

function getDiscountHistoryStateById(state, id) {
  const historyStates = getDiscountHistoryState(state)
  return historyStates[id] || entityInitialState
}

function getDiscountYearHistoryStateById(state, id, year) {
  return get(getDiscountHistoryStateById(state, id), ['years', year], listDataState)
}

export default {
  getDiscountHistoryState,
  getDiscountHistoryStateById,
  getDiscountYearHistoryStateById
}
