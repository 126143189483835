/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE
} = helpers

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  years: {}
}

const holidaysDictionaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING,
        years: {
          ...state.years,
          [action.payload.year]: {
            dataState: DATA_STATE_REQUESTING,
            data: []
          }
        }
      }
    case RESPONSE_LIST:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        years: {
          ...state.years,
          [action.payload.year]: {
            dataState: DATA_STATE_RECIEVED,
            data: action.payload.data
          }
        }
      }
    case RESPONSE_ERROR_LIST:
      return {
        ...state,
        dataState: DATA_STATE_ERROR,
        years: {
          ...state.years,
          [action.payload.year]: {
            dataState: DATA_STATE_ERROR,
            data: []
          }
        }
      }
    case RESET_DATA_STATE:
      return { ...initialState }
    default:
      return state
  }
}

export default filterActions(holidaysDictionaryReducer, [
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE
])
