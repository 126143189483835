/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildURLForGettingEntityById } from 'services/http/helpers'
import {
  PROGRAMS_URL,
  PMCS_URL,
  ELIGIBILITY_ENDPOINT,
  PMS_URL,
  PMC_ELIGIBILITY_PROPERTIES_ENDPOINT,
  PROGRAM_ELIGIBILITY_PROPERTIES_ENDPOINT
} from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'

export const updateEntity = contractData =>
  httpWrapper({
    url: ELIGIBILITY_ENDPOINT,
    method: 'put',
    data: contractData
  })

export const getEntityById = (id, params) =>
  httpWrapper({
    url: buildURLForGettingEntityById(ELIGIBILITY_ENDPOINT, id),
    method: 'get',
    params
  })

export const deletePmcParameter = (pmcId, eligibilityId) =>
  httpWrapper({
    url: `${PMCS_URL}/${pmcId}/eligibilities/${eligibilityId}`,
    method: 'delete'
  })

export const savePmcEligibilityProperties = (id, effectivenessDate) =>
  httpWrapper({
    url: buildPath(PMC_ELIGIBILITY_PROPERTIES_ENDPOINT, { id }),
    method: 'put',
    params: { effectivenessDate },
    data: {}
  })

export const deleteProgramParameter = (programId, eligibilityId) =>
  httpWrapper({
    url: `${PROGRAMS_URL}/${programId}/eligibilities/${eligibilityId}`,
    method: 'delete'
  })

export const saveProgramEligibilityProperties = (id, effectivenessDate) =>
  httpWrapper({
    url: buildPath(PROGRAM_ELIGIBILITY_PROPERTIES_ENDPOINT, { id }),
    method: 'put',
    params: { effectivenessDate },
    data: {}
  })

export const deletePmsParameter = (pmsId, eligibilityId) =>
  httpWrapper({
    url: `${PMS_URL}/${pmsId}/eligibilities/${eligibilityId}`,
    method: 'delete'
  })

export const updatePmsEligibilityValues = (pmsId, data) =>
  httpWrapper({
    url: `${PMS_URL}/${pmsId}/eligibilities`,
    method: 'put',
    data
  })
