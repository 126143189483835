/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const MODAL_TITLE = 'Warning!'

export const WARNING_MESSAGE = 'Are you sure you want to Deactivate '

export const SELECT_DATE_DEACTIVATE_MESSAGE = 'Please select a date when you want the '
export const PROGRAM_BECOME_INACTIVE_MESSAGE = ' program to become inactive.'
export const WARNING_DEACTIVATION_MESSAGE_START = 'The Deactivation Date is set on the PMC '
export const WARNING_DEACTIVATION_MESSAGE_END = ', Do you want to view this PMC?'

export const DATE_INPUT_LABEL = 'Deactivation Date'
export const DATE_INPUT_PLACEHOLDER = 'Deactivation Date'

export const DEACTIVATION_UNASSIGN_UNITS_FIELD = 'deactivationUnassignUnits'
export const DEACTIVATION_UNASSIGN_UNITS_FIELD_ID = 'deactivation-unassign-units'
export const DEACTIVATION_UNASSIGN_UNITS_LABEL = 'Unassign Units related to Programs'

export const DATE_FIELD = 'date'

export const PICK_TODAY_BUTTON_TEXT = 'Pick today'
export const DEACTIVATE_TEXT = 'Deactivate'
export const DECLINE_TEXT = 'Decline'
export const UPDATE_TEXT = 'Update'
export const CANCEL_TEXT = 'Cancel Deactivation'

export const QUESTION_MARK = '?'

// Modal types
export const CONFIRMATION_MODAL_TYPE = 'CONFIRMATION_MODAL_TYPE'
export const DEACTIVATE_MODAL_TYPE = 'DEACTIVATE_MODAL_TYPE'
export const WARNING_MODAL_TYPE = 'WARNING_MODAL_TYPE'
