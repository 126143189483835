/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { BARCODES, WILL_CALL, NOT_REQUIRED } from 'constants/ticketFormats'
import { types } from 'reducers/ticketTypesList'

export const isWillCallTicketFormat = currentFormat => currentFormat === WILL_CALL

export const isBarcodeTicketFormat = currentFormat => currentFormat === BARCODES

export const hasAssignedTicketGroups = data =>
  data && data.filter(value => value.name !== types.UNASSIGNED_TYPES).length > 0

export const hasTicketGroups = ticketFormat => ![WILL_CALL, NOT_REQUIRED].includes(ticketFormat)
