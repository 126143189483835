/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import get from 'lodash/get'

import {
  GUEST_ROLE,
  USER_ROLE,
  APPROVER_ROLE,
  ADMIN_ROLE,
  SYS_ADMIN_ROLE,
  VAC_SPECIALIST_ROLE,
  GUEST_TITLE,
  USER_TITLE,
  APPROVER_TITLE,
  SYSADMIN_TITLE,
  ADMIN_TITLE,
  VACATION_SPECIALIST_TITLE,
  VAC_SPECIALIST_TIER_2_ROLE,
  VACATION_SPECIALIST_TIER_2_TITLE,
  UNIT_TASK_MANAGE_ROLE,
  UNIT_TASK_MANAGE_TITLE
} from 'constants/rolesTypes'

import {
  READ_LEVEL,
  USER_LEVEL,
  CREATE_LEVEL,
  UPDATE_LEVEL,
  UPDATE_OWNED_LEVEL,
  APPROVE_LEVEL,
  DELETE_LEVEL,
  DELETE_OWNED_LEVEL,
  ADMIN_LEVEL,
  SYS_ADMIN_LEVEL,
  VAC_SPECIALIST_LEVEL,
  USER_DATA_PERMISSION_FIELD,
  USER_ROLE_LEVEL,
  VAC_SPECIALIST_TIER_2_LEVEL,
  UNIT_TASKS_MANAGE_LEVEL
} from 'constants/permissionTypes'

const defaultPermissions = {
  [USER_ROLE_LEVEL]: [USER_ROLE],
  [READ_LEVEL]: [GUEST_ROLE, USER_ROLE, APPROVER_ROLE, ADMIN_ROLE, SYS_ADMIN_ROLE],
  [USER_LEVEL]: [USER_ROLE, APPROVER_ROLE, ADMIN_ROLE, SYS_ADMIN_ROLE],
  [CREATE_LEVEL]: [USER_ROLE, APPROVER_ROLE, ADMIN_ROLE, SYS_ADMIN_ROLE],
  [UPDATE_LEVEL]: [ADMIN_ROLE, SYS_ADMIN_ROLE],
  [APPROVE_LEVEL]: [APPROVER_ROLE, ADMIN_ROLE, SYS_ADMIN_ROLE],
  [DELETE_LEVEL]: [ADMIN_ROLE, SYS_ADMIN_ROLE],
  [ADMIN_LEVEL]: [ADMIN_ROLE, SYS_ADMIN_ROLE],
  [SYS_ADMIN_LEVEL]: [SYS_ADMIN_ROLE],
  [UPDATE_OWNED_LEVEL]: [USER_ROLE, APPROVER_ROLE, ADMIN_ROLE, SYS_ADMIN_ROLE],
  [DELETE_OWNED_LEVEL]: [USER_ROLE, APPROVER_ROLE, ADMIN_ROLE, SYS_ADMIN_ROLE],
  [VAC_SPECIALIST_LEVEL]: [
    VAC_SPECIALIST_ROLE,
    VAC_SPECIALIST_TIER_2_ROLE,
    ADMIN_ROLE,
    SYS_ADMIN_ROLE
  ],
  [VAC_SPECIALIST_TIER_2_LEVEL]: [VAC_SPECIALIST_TIER_2_ROLE, ADMIN_ROLE, SYS_ADMIN_ROLE],
  [UNIT_TASKS_MANAGE_LEVEL]: [UNIT_TASK_MANAGE_ROLE]
}

export const levelHasRole = (userRole = GUEST_ROLE, levelRoles = []) =>
  levelRoles.some(role => role === userRole)

const isActionAllowed = (action = READ_LEVEL, authUser = {}, data = null) => {
  const canAction = get(authUser, `${USER_DATA_PERMISSION_FIELD}.${action}`)
  const email = get(authUser, 'email')
  const createdBy = get(data, 'metadata.createdBy', email)
  if (action === UPDATE_LEVEL && !canAction) {
    const canUpdateOwned = get(authUser, `${USER_DATA_PERMISSION_FIELD}.${UPDATE_OWNED_LEVEL}`)
    return canUpdateOwned && createdBy === email
  }
  if (action === DELETE_LEVEL && !canAction) {
    const canDeleteOwned = get(authUser, `${USER_DATA_PERMISSION_FIELD}.${UPDATE_OWNED_LEVEL}`)
    return canDeleteOwned && createdBy === email
  }
  return canAction
}

const isLevelAllowed = (
  level = READ_LEVEL,
  userRoles = [],
  permissionsMatrix = defaultPermissions
) => {
  if (!{}.hasOwnProperty.call(permissionsMatrix, level)) {
    return false
  }

  return userRoles.some(role => levelHasRole(role, defaultPermissions[level]))
}

const checkPermissions = (rolesList = [], permissionsMatrix = defaultPermissions) => {
  // TODO possibly change this use of .map() to a .reduce() since we're mutating
  // something outside the scope of .map()
  const permissions = {}
  Object.keys(defaultPermissions).map(level => {
    const levelAllowed = isLevelAllowed(level, rolesList, permissionsMatrix)
    permissions[level] = levelAllowed

    return level
  })

  return permissions
}

const defineUserPermissions = user => {
  if (!user || !{}.hasOwnProperty.call(user, USER_DATA_PERMISSION_FIELD)) {
    return user
  }

  const userData = Object.assign({}, user, {
    permission: checkPermissions(user[USER_DATA_PERMISSION_FIELD])
  })

  return userData
}

export function getRoleAlies(roleType) {
  switch (roleType) {
    case GUEST_ROLE:
      return GUEST_TITLE
    case USER_ROLE:
      return USER_TITLE
    case APPROVER_ROLE:
      return APPROVER_TITLE
    case ADMIN_ROLE:
      return ADMIN_TITLE
    case SYS_ADMIN_ROLE:
      return SYSADMIN_TITLE
    case VAC_SPECIALIST_ROLE:
      return VACATION_SPECIALIST_TITLE
    case VAC_SPECIALIST_TIER_2_ROLE:
      return VACATION_SPECIALIST_TIER_2_TITLE
    case UNIT_TASK_MANAGE_ROLE:
      return UNIT_TASK_MANAGE_TITLE
    default:
      return roleType
  }
}

export {
  READ_LEVEL,
  USER_LEVEL,
  CREATE_LEVEL,
  ADMIN_LEVEL,
  SYS_ADMIN_LEVEL,
  UPDATE_LEVEL,
  APPROVE_LEVEL,
  DELETE_LEVEL,
  VAC_SPECIALIST_LEVEL,
  checkPermissions,
  isLevelAllowed,
  isActionAllowed,
  defineUserPermissions
}
