export const RECOVERY_PASSWORD_LINK_ACTION = 'RECOVERY_PASSWORD_LINK_ACTION'
export const RECOVERY_TOKEN_ACTION = 'RECOVERY_TOKEN_ACTION'
export const RECOVERY_PASSWORD_ACTION = 'RECOVERY_PASSWORD_ACTION'
export const RECOVERY_PASSWORD_LINK_SUCCESS_MESSAGE =
  'Your password reset email was sent to your Xplorie email address!'

export default {
  RECOVERY_PASSWORD_LINK_ACTION,
  RECOVERY_TOKEN_ACTION,
  RECOVERY_PASSWORD_ACTION
}
