import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import {
  REQUEST_AUTH,
  RESPONSE_AUTH,
  RESPONSE_AUTH_ERROR,
  RESET_DATA_STATE,
  REQUEST_LOG_OUT_ACTION,
  RESPONSE_LOG_OUT_ACTION,
  RESPONSE_LOG_OUT_ACTION_ERROR,
  REQUEST_REFRESH_TOKEN_ACTION,
  RESPONSE_REFRESH_TOKEN_ACTION,
  RESPONSE_REFRESH_TOKEN_ACTION_ERROR
} from './helpers'

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  logOutDataState: DATA_STATE_NOT_REQUESTED,
  refreshTokenDataState: DATA_STATE_NOT_REQUESTED,
  authUser: null
}

const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_AUTH:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_AUTH_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case RESPONSE_AUTH:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        ...action.payload
      }
    case REQUEST_LOG_OUT_ACTION:
      return {
        ...state,
        logOutDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_LOG_OUT_ACTION_ERROR:
      return {
        ...state,
        logOutDataState: DATA_STATE_ERROR
      }
    case RESPONSE_LOG_OUT_ACTION:
      return {
        ...state,
        logOutDataState: DATA_STATE_RECIEVED,
        ...action.payload
      }
    case REQUEST_REFRESH_TOKEN_ACTION:
      return {
        ...state,
        refreshTokenDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_REFRESH_TOKEN_ACTION_ERROR:
      return {
        ...state,
        refreshTokenDataState: DATA_STATE_ERROR
      }
    case RESPONSE_REFRESH_TOKEN_ACTION:
      return {
        ...state,
        refreshTokenDataState: DATA_STATE_RECIEVED,
        ...action.payload
      }
    case RESET_DATA_STATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default filterActions(currentUserReducer, [
  REQUEST_AUTH,
  RESPONSE_AUTH,
  RESPONSE_AUTH_ERROR,
  RESET_DATA_STATE,
  REQUEST_LOG_OUT_ACTION,
  RESPONSE_LOG_OUT_ACTION,
  RESPONSE_LOG_OUT_ACTION_ERROR,
  REQUEST_REFRESH_TOKEN_ACTION,
  RESPONSE_REFRESH_TOKEN_ACTION,
  RESPONSE_REFRESH_TOKEN_ACTION_ERROR
])
