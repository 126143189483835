/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { usePrevious } from 'hooks'
import { getResultComponent, getInitialFilters } from './helpers'

export function SearchResults(props) {
  const { state, api } = props
  const {
    isShowResult,
    parameters: { type }
  } = state
  const prevType = usePrevious(type)
  const Component = getResultComponent(type)

  const onChangeHandler = (options = {}) => {
    const { parameters, filters } = options
    if (parameters) {
      api.changeQueryParameters(parameters)
    }
    if (filters) {
      api.changeSearchFilters(filters)
    }
    api.onSearch()
  }

  useEffect(() => {
    api.changeSearchFilters(getInitialFilters(type))
    if (prevType && prevType !== type) {
      api.onSearch()
    }
  }, [api, prevType, type])

  return Component && isShowResult ? (
    <Component {...props} state={state} onChange={onChangeHandler} />
  ) : null
}
SearchResults.propTypes = {
  state: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired
}
SearchResults.defaultProps = {}
