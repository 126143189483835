/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'
import {
  ADDRESS,
  SEASON,
  ACTIVITY_PRODUCT,
  ACTIVITY_PROVIDER,
  PROGRAM,
  ACCOUNT,
  PMS,
  PMC,
  ACTIVITY_CATEGORY,
  DESTINATION,
  LOCATION,
  TILE_ACCORDION,
  ACTIVITY_TAG,
  ACTIVITY_MODULE,
  RMS,
  UNIQ_PHONE_NUMBER,
  ELIGIBILITY,
  MARKET,
  CAMPAIGNS
} from 'constants/formCodes'

const TITLE_PREFIX = 'New'
const DEFAULT_KEY = 'NEW'
const DEFAULT_PROP_NAME = 'name'
const DEFAULT_CODE = 'DEFAULT_CODE'

const FORMS = {
  [ADDRESS]: 'Address',
  [SEASON]: 'Season',
  [ACTIVITY_PRODUCT]: 'Activity Product',
  [ACTIVITY_PROVIDER]: 'Activity Provider',
  [PMC]: 'PMC',
  [PROGRAM]: 'Program',
  [ACCOUNT]: 'Account',
  [PMS]: 'PMS',
  [ACTIVITY_CATEGORY]: 'Activity Category',
  [CAMPAIGNS]: 'Campaign',
  [DESTINATION]: 'Destination',
  [LOCATION]: 'Location',
  [TILE_ACCORDION]: 'Tile Accordion',
  [ACTIVITY_TAG]: 'Activity Tag',
  [ACTIVITY_MODULE]: 'Activity Module',
  [RMS]: 'RMS',
  [UNIQ_PHONE_NUMBER]: 'Uniq Phone Number',
  [ELIGIBILITY]: 'Eligibility',
  [MARKET]: 'Market',
  [DEFAULT_CODE]: ''
}

const titleBuilder = (code = DEFAULT_CODE, formData = {}, propertyName = DEFAULT_PROP_NAME) => {
  const formName = get(FORMS, code, FORMS[DEFAULT_CODE])
  const title = get(formData, propertyName, `${TITLE_PREFIX} ${formName}`)
  return title
}
const keyBuilder = (code = DEFAULT_CODE, formData = {}, propertyName = 'id') => {
  const id = get(formData, propertyName, DEFAULT_KEY)
  return `${code}:[${id}]`
}
export { titleBuilder, keyBuilder }
export default { titleBuilder, keyBuilder }
