/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withFormApi, withFormState } from 'informed'
import { withFormContext } from 'components/FormComponents/helpers'
import { BasicButton } from '@xplorie/ui-commons'
import isEmpty from 'lodash/isEmpty'
import reject from 'lodash/reject'

export const SubmitButtonComponent = props => {
  const {
    formApi,
    formState: { invalid, pristine, values },
    isValid,
    disabled,
    onClick
  } = props

  const filledValue = reject(values, isEmpty)
  const isDisabled = invalid || pristine || disabled || !isValid || isEmpty(filledValue)

  return (
    <BasicButton
      {...props}
      disabled={isDisabled}
      onClick={e => onClick(e, formApi)}
      type="submit"
    />
  )
}

SubmitButtonComponent.propTypes = {
  formApi: PropTypes.object,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  formState: PropTypes.object
}

SubmitButtonComponent.defaultProps = {
  isValid: true,
  formApi: null,
  disabled: false,
  formState: null,
  onClick: (e, formApi) => formApi.submitForm(e)
}

export const SubmitButton = compose(
  withFormApi,
  withFormContext,
  withFormState
)(SubmitButtonComponent)
