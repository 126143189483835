/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useEffect } from 'react'
import { useUpdateSomeDataState } from 'hooks'
import PropTypes from 'prop-types'
import { DATA_STATE_REQUESTING, DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { SYS_ADMIN_LEVEL } from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import { BasicButton } from '@xplorie/ui-commons'
import { PENDING_STATUS } from './types'

export function ResetCommunication(props) {
  const {
    children,
    authUser,
    checkResetProgressAction,
    resetCommunicationAction,
    dataState,
    progressData,
    updateDataState
  } = props

  const isSysAdmin = isActionAllowed(SYS_ADMIN_LEVEL, authUser)
  const isDisabled =
    dataState === DATA_STATE_REQUESTING || (progressData && progressData.status === PENDING_STATUS)

  const onClickHandler = () => {
    if (isSysAdmin && !isDisabled) {
      const payload = {
        operationType: 'RESTART'
      }
      resetCommunicationAction(authUser, payload)
    }
  }

  const checkUpdateProgress = () => {
    if (isSysAdmin) {
      checkResetProgressAction(authUser)
    }
  }

  useEffect(() => {
    checkUpdateProgress()
  }, [])

  useUpdateSomeDataState(
    () => {
      checkUpdateProgress()
    },
    [updateDataState],
    DATA_STATE_RECIEVED
  )

  return isSysAdmin ? (
    <BasicButton colorSchema={ORANGE_SCHEMA} disabled={isDisabled} onClick={onClickHandler}>
      {children}
    </BasicButton>
  ) : null
}

ResetCommunication.propTypes = {
  children: PropTypes.node.isRequired,
  authUser: PropTypes.object.isRequired,
  checkResetProgressAction: PropTypes.func.isRequired,
  resetCommunicationAction: PropTypes.func.isRequired,
  dataState: PropTypes.string.isRequired,
  progressData: PropTypes.object.isRequired,
  updateDataState: PropTypes.string.isRequired
}
