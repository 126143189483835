/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

import { canDeleteUniqPhoneNumber } from 'modules/UniqPhoneNumber'
import { uniqPhoneNumberTableColumns, buildDefaultButtonColumn } from 'helpers/dataTablesHelpers'
import { EditButton, DeleteButton } from './AdditionalColumns'

export const buildTableColumns = props => {
  const { removeEntityById, authUser } = props
  const canDelete = canDeleteUniqPhoneNumber(authUser)

  const tableColumns = uniqPhoneNumberTableColumns.concat({
    ...buildDefaultButtonColumn(),
    Cell: data => <EditButton {...props} data={data} />
  })

  if (canDelete) {
    tableColumns.push({
      ...buildDefaultButtonColumn({ width: 95 }),
      Cell: data => <DeleteButton {...props} data={data} deleteAction={removeEntityById} />
    })
  }

  return tableColumns
}
