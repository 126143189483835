/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const TESTING_DISABLED_LABEL = 'Test'
export const TESTING_ENABLED_LABEL = 'Testing'

export const WARNING_MODAL_TITLE = 'Warning!'
export const ACTIVATE_MODAL_MESSAGE = 'Are you sure you want to Activate test mode?'
export const DEACTIVATE_MODAL_MESSAGE = 'Are you sure you want to Deactivate test mode?'
