/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { CancelToken } from 'axios'
import { httpWrapper } from 'services/http/helpers'
import {
  PROGRAM_GUESTS_DATA_URL,
  PROGRAM_GUEST_DATA_URL,
  PROGRAM_GUEST_TEMPLATE_URL
} from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'
import { downloadFile } from 'helpers/downloadHelper'

export const saveGuestDataFile = (programId, file, options = {}) => {
  const { onUploadProgress, getCancelToken } = options
  const data = new FormData()
  data.append('file', file)
  return httpWrapper({
    url: buildPath(PROGRAM_GUESTS_DATA_URL, { programId }),
    method: 'post',
    data,
    onUploadProgress,
    cancelToken: new CancelToken(getCancelToken)
  })
}

export const getGuestDataFile = (programId, guestsId, name) =>
  downloadFile(buildPath(PROGRAM_GUEST_DATA_URL, { programId, guestsId }), name)

export const getGuestTemplate = (programId, name) =>
  downloadFile(buildPath(PROGRAM_GUEST_TEMPLATE_URL, { programId }), name)
