/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function CompassIcon(props) {
  return (
    <svg viewBox="14 10 124 124" {...props}>
      <path
        d="M72.4,20c-29.7,0-53.9,24.2-53.9,53.9c0,29.7,24.2,53.9,53.9,53.9c29.7,0,53.9-24.2,53.9-53.9
			C126.3,44.1,102.1,20,72.4,20z M72.4,116.4c-23.5,0-42.6-19.1-42.6-42.6c0-23.5,19.1-42.6,42.6-42.6c23.5,0,42.6,19.1,42.6,42.6
			C114.9,97.3,95.9,116.4,72.4,116.4z"
      />
      <path
        d="M93.5,50.6L67,63.6c-2.1,1-3.8,2.7-4.8,4.8L49.1,95c-0.3,0.6-0.2,1.4,0.3,1.9c0.5,0.5,1.2,0.6,1.9,0.3
			l26.5-13.1c2.1-1,3.8-2.7,4.8-4.8l13.1-26.5c0.3-0.6,0.2-1.4-0.3-1.9C94.9,50.4,94.1,50.3,93.5,50.6z M78.2,79.6
			c-3.2,3.2-8.4,3.2-11.5,0c-3.2-3.2-3.2-8.4,0-11.5c3.2-3.2,8.4-3.2,11.5,0C81.3,71.3,81.3,76.4,78.2,79.6z"
      />
      <circle cx="72.4" cy="39" r="4.7" />
      <circle cx="72.4" cy="108.7" r="4.7" />
      <path d="M42.3,73.9c0,2.6-2.1,4.7-4.7,4.7c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7C40.2,69.2,42.3,71.3,42.3,73.9z" />
      <path d="M107.2,69.2c-2.6,0-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7 C111.9,71.3,109.8,69.2,107.2,69.2z" />
    </svg>
  )
}
