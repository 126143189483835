/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const TABLE_HEADER = 'Uploaded Files'

// uploadStatus
export const UPLOAD_STATUS_ACCESSOR = 'uploadStatus'

// fileName
export const FILENAME_TITLE = 'Filename'
export const FILENAME_ACCESSOR = 'fileName'

// uploaded at
export const UPLOAD_AT_ACCESSOR = ['metadata', 'createDate']
export const UPLOAD_AT_TITLE = 'Uploaded at'

// uploaded by
export const UPLOAD_BY_ACCESSOR = 'uploaderName'
export const UPLOAD_BY_TITLE = 'Uploaded by'

// view file
export const VIEW_FILE_TITLE = 'View File'

// download
export const DOWNLOAD_FILE_TEXT = 'Download File'
export const DOWNLOAD_ERROR_REPORT_TEXT = 'Download Error Report'

export const DWH_MAX_MINUTES_DELAY = 60

export const DWH_ERROR_MESSAGE =
  'The file experienced an error and wasn’t loaded into the Guest Database.'
export const PIE_ERROR_MESSAGE =
  'The file did not pass the validation. Please download the error Report.'
