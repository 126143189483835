/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildListParams } from 'services/http/helpers'
import {
  PMCS_URL,
  PROGRAMS_URL,
  PMC_PROGRAM_UNITS,
  PROGRAMS_UNITS_EXPORT_URL,
  PROGRAMS_UNITS_SMART_HOST_URL,
  EXPORT_PMC_UNITS_URL
} from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'
import { responseToFile } from 'helpers/downloadHelper'

export const getPmcUnitsListByProgramId = (programId, options) =>
  httpWrapper({
    url: `${PROGRAMS_URL}/${programId}/units`,
    method: 'get',
    params: buildListParams(options)
  })

export const getUnitsByPmcId = (pmcId, listOptions) =>
  httpWrapper({
    url: `${PMCS_URL}/${pmcId}/units`,
    method: 'get',
    params: buildListParams(listOptions)
  })

export const assignUnitsProgram = (pmcId, programId, data, params) =>
  httpWrapper({
    url: `${PMCS_URL}/${pmcId}/programs/${programId}/units`,
    method: 'put',
    data,
    params
  })

export const unassignUnitsProgram = (pmcId, progranId, data, params) =>
  httpWrapper({
    url: `${PMCS_URL}/${pmcId}/programs/${progranId}/units`,
    method: 'delete',
    data,
    params
  })

export const updateUnitsStatusBulk = (pmcId, data) =>
  httpWrapper({
    url: `${PMCS_URL}/${pmcId}/units`,
    method: 'put',
    data
  })

export const uploadUnits = (pmcId, file) => {
  const formData = new FormData()
  formData.append('file', file)
  return httpWrapper({
    url: `${PMCS_URL}/${pmcId}/units`,
    method: 'post',
    data: formData
  })
}

export const getProgramsUnitsCountByPmcId = pmcId =>
  httpWrapper({
    url: buildPath(PMC_PROGRAM_UNITS, { pmcId }),
    method: 'get'
  })

export const exportUnitsListByProgramId = (programId, data, options) =>
  httpWrapper({
    url: buildPath(PROGRAMS_UNITS_EXPORT_URL, { programId }),
    method: 'post',
    data,
    params: buildListParams(options),
    responseType: 'blob'
  }).then(responseToFile)

export const updateUnitsSmartHostStatusByProgramId = (programId, data) =>
  httpWrapper({
    url: buildPath(PROGRAMS_UNITS_SMART_HOST_URL, { programId }),
    method: 'put',
    data
  })

export const exportPmcUnitsList = (pmcId, data, options) =>
  httpWrapper({
    url: buildPath(EXPORT_PMC_UNITS_URL, { pmcId }),
    method: 'post',
    data,
    params: buildListParams(options),
    responseType: 'blob'
  }).then(responseToFile)
