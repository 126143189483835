/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { PROGRAMS_BY_APP_NAME, APP_ONE_NAME, EXPORT_PROGRAMS_BY_APP } = types

const REQUEST_DATA = `${REQUEST_TYPE}_${PROGRAMS_BY_APP_NAME}`
const RESPONSE_DATA = `${RESPONSE_TYPE}_${PROGRAMS_BY_APP_NAME}`
const RESPONSE_ERROR_DATA = `${RESPONSE_ERROR_TYPE}_${PROGRAMS_BY_APP_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${PROGRAMS_BY_APP_NAME}`

const REQUEST_EXPORT = `${REQUEST_TYPE}_${EXPORT_PROGRAMS_BY_APP}_`
const RESPONSE_EXPORT = `${RESPONSE_TYPE}_${EXPORT_PROGRAMS_BY_APP}`
const RESPONSE_ERROR_EXPORT = `${RESPONSE_ERROR_TYPE}_${EXPORT_PROGRAMS_BY_APP}`

const REQUEST_APP_ONE = `${REQUEST_TYPE}_${APP_ONE_NAME}`
const RESPONSE_APP_ONE = `${RESPONSE_TYPE}_${APP_ONE_NAME}`
const RESPONSE_APP_ONE_ERROR = `${RESPONSE_ERROR_TYPE}_${APP_ONE_NAME}`

export default {
  REQUEST_DATA,
  RESPONSE_DATA,
  RESPONSE_ERROR_DATA,
  RESET_DATA_STATE,
  REQUEST_APP_ONE,
  RESPONSE_APP_ONE,
  RESPONSE_APP_ONE_ERROR,
  REQUEST_EXPORT,
  RESPONSE_EXPORT,
  RESPONSE_ERROR_EXPORT
}
