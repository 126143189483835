/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { selectors, actions } from 'reducers/location/marketingForm'
import { withAuthContext } from 'helpers/withAuthContext'
import { MarketingForm as MarketingFormComponent } from './MarketingForm'

const { getMarketingFormStateById } = selectors
const { updateLocationMarketing } = actions

function mapStateToProps(state, { dataId }) {
  const marketingState = getMarketingFormStateById(state, dataId)
  return {
    formData: marketingState.formData,
    dataState: marketingState.dataState
  }
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateLocationMarketing }, dispatch)
}

export const MarketingForm = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MarketingFormComponent)
