import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import types from './types'

const { PROGRAMS_LIST_NAME, GET_CURRENT_PROGRAM_NAME } = types

const REQUEST_LIST = `${REQUEST_TYPE}_${PROGRAMS_LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${PROGRAMS_LIST_NAME}`
const RESPONSE_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${PROGRAMS_LIST_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${PROGRAMS_LIST_NAME}`

const REQUEST_GET_PROGRAM = `${REQUEST_TYPE}_${GET_CURRENT_PROGRAM_NAME}`
const RESPONSE_GET_PROGRAM = `${RESPONSE_TYPE}_${GET_CURRENT_PROGRAM_NAME}`
const RESPONSE_ERROR_GET_PROGRAM = `${RESPONSE_ERROR_TYPE}_${GET_CURRENT_PROGRAM_NAME}`

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_GET_PROGRAM,
  RESPONSE_GET_PROGRAM,
  RESPONSE_ERROR_GET_PROGRAM
}
