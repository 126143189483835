/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { UPDATE_LEVEL } from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import { ViewEntityButton, UpdateEntityButton } from 'components/Buttons'
import { ORANGE_SCHEMA, SMALLER_SIZE, EDIT_TITLE, VIEW_TITLE } from 'constants/buttonsOptions'
import { useSliderApi } from 'hooks/useSliderApi'
import { PMC } from 'constants/formCodes'

export function EditButton(props) {
  const { data, authUser } = props
  const canUpdate = isActionAllowed(UPDATE_LEVEL, authUser, data.original)
  const TITLE = canUpdate ? EDIT_TITLE : VIEW_TITLE
  const Button = canUpdate ? UpdateEntityButton : ViewEntityButton
  const pmcSlider = useSliderApi(PMC)

  const onEditClick = useCallback(() => {
    const { id, label } = data.original
    pmcSlider.open(id, { headerProps: { title: label } })
  }, [pmcSlider, data.original])

  return (
    <Button
      size={SMALLER_SIZE}
      colorSchema={ORANGE_SCHEMA}
      data={data.original}
      onClick={onEditClick}
    >
      {TITLE}
    </Button>
  )
}

EditButton.propTypes = {
  authUser: PropTypes.object.isRequired,
  data: PropTypes.object
}

EditButton.defaultProps = {
  data: null
}
