/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { entityInitialState } from './reducer'

export const getPmcEligibilityFormState = state => state.eligibility.programEligibilityForm

export const getEligibilityProgramFormById = (state, id) =>
  getPmcEligibilityFormState(state)[id] || entityInitialState

export const getProgramEligibilityDataStateById = (state, id) => {
  const formState = getPmcEligibilityFormState(state)[id] || entityInitialState
  return formState.bulkAssigmentDataState
}

export const getProgramEligibilityDataStatesByIds = (state, ids = []) => {
  const states = ids.map(value => getProgramEligibilityDataStateById(state, value))
  return states
}
