/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getList as getTicketGroupList } from 'services/http/ticketGroup'

import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_GET_TICKET_TYPES,
  RESPONSE_GET_TICKET_TYPES_ERROR,
  RESPONSE_GET_TICKET_TYPES
} = helpers

const getTicketTypes = activityId => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_TICKET_TYPES))
  const request = await getTicketGroupList(activityId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_TICKET_TYPES_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_TICKET_TYPES, request.data))
    return 'getTicketTypes success'
  }

  return 'getTicketTypes failed'
}

const resetForm = () => dispatch => {
  dispatch(actionCreater(RESET_DATA_STATE))
}

export default {
  getTicketTypes,
  resetForm
}
