/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions as editQueueActions } from 'reducers/editQueue'
import get from 'lodash/get'
import {
  actions as programsFormActions,
  isProgramDataLoading,
  isProgramActionPending,
  getProgramDataState
} from 'reducers/programsForm'
import { getProgramApprovalState } from 'reducers/programApproval'
import { getProgramLifeCycleProcessState } from 'reducers/programLifeCycleProcess'
import { actions as uniqFreePhoneNumListActions } from 'reducers/uniqFreePhoneNumList'
import { withAuthContext } from 'helpers/withAuthContext'
import { getPmcLifeCycleDataStates } from 'reducers/pmc/pmcLifeCycle'

import ProgramsForm from './ProgramsForm'
import { buildOptionsUniqPhoneNumbers } from './helpers'

const mapStateToProps = (state, props) => {
  const { dataId } = props
  const programFormState = getProgramDataState(state, dataId)
  const { programData } = programFormState
  const programApprovalState = getProgramApprovalState(state, dataId)
  const programLifeCycleState = getProgramLifeCycleProcessState(state, dataId)
  const pmCompanyId = get(programData, 'pmCompany.id')
  const pmcLifeCycleListDataStates = getPmcLifeCycleDataStates(state, pmCompanyId)

  return {
    isProgramActionPending: isProgramActionPending(state, dataId),
    isProgramDataLoading: isProgramDataLoading(state, dataId),
    panelStack: state.editQueue.panelStack,
    dataState: programFormState.dataState,
    programApprovalState: programApprovalState.approvalState,
    programDraftState: programApprovalState.programDraftState,
    programData: programFormState.programData,
    pmCompanyId,
    programDataState: programFormState.programDataState,
    unassignPhoneDataState: programFormState.unassignPhoneDataState,
    activateProgramDataState: programLifeCycleState.activateProgramDataState,
    deactivateProgramDataState: programLifeCycleState.deactivateProgramDataState,
    pmcLifeCycleListDataStates,
    uniqFreePhoneNumList: buildOptionsUniqPhoneNumbers(
      state.uniqFreePhoneNumList.data,
      programFormState.programData
    )
  }
}

const mapDispatchToProps = dispatch => {
  const { getProgramById, unassignPhoneById } = programsFormActions
  const { getFreeUniqPhoneNumberList, resetFreeList } = uniqFreePhoneNumListActions

  return bindActionCreators(
    {
      ...editQueueActions,
      unassignPhoneById,
      getFreeUniqPhoneNumberList,
      resetFreeList,
      getProgramById
    },
    dispatch
  )
}

export default compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProgramsForm)
