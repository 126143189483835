/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { LOCATION_MARKETING_URL } from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'

export const getEntityByLocationId = id =>
  httpWrapper({
    url: buildPath(LOCATION_MARKETING_URL, { id }),
    method: 'get'
  })

export const updateEntityByLocationId = (id, data) =>
  httpWrapper({
    url: buildPath(LOCATION_MARKETING_URL, { id }),
    method: 'put',
    data
  })
