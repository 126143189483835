/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { Form, Text } from 'informed'
import { FormContent, ControlButtonsContainer } from 'components/FormComponents'
import get from 'lodash/get'
import pick from 'lodash/pick'
import { SubmitButton } from 'components/Buttons'
import { ORANGE_SCHEMA, SUBMIT_TITLE } from 'constants/buttonsOptions'
import { useDidMount } from 'hooks'
import {
  AvailabilitySection,
  buildIdsForDateObjects
} from '../ActivityProductForm/AvailabilitySection'

const AVAILABILITY_FIELD = 'activityAvailability'

export function AvailabilityForm({ data, submitVisible, disabled, onSubmit }) {
  const formApi = useRef()

  const handleGetApi = api => {
    formApi.current = api
  }

  const handleHolidayEnabledHandler = newValue => {
    if (formApi.current.fieldExists('holidayEnabled')) {
      formApi.current.setValue('holidayEnabled', newValue)
    }
  }

  const buildValues = () => ({
    activityAvailability: buildIdsForDateObjects(get(data, AVAILABILITY_FIELD), [])
  })

  const handleSubmit = formValues => {
    if (onSubmit) {
      onSubmit(pick(formValues, [AVAILABILITY_FIELD]))
    }
  }

  useDidMount(() => {
    formApi.current.setValues(buildValues())
  })

  return (
    <Form getApi={handleGetApi} onSubmit={handleSubmit}>
      {({ formState }) => (
        <FormContent disabled={disabled}>
          <Text field="holidayEnabled" type="hidden" initialValue />
          <AvailabilitySection
            field={AVAILABILITY_FIELD}
            initialValue={[]}
            enableHoliday={get(formState, 'values.holidayEnabled')}
            onEnableHolidays={handleHolidayEnabledHandler}
          />
          <ControlButtonsContainer>
            {submitVisible && (
              <SubmitButton colorSchema={ORANGE_SCHEMA}>{SUBMIT_TITLE}</SubmitButton>
            )}
          </ControlButtonsContainer>
        </FormContent>
      )}
    </Form>
  )
}

AvailabilityForm.propTypes = {
  data: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitVisible: PropTypes.bool.isRequired
}
