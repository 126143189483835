/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { initialState } from './reducer'

export function getDiscountsExportState(state) {
  return state.exportEntity.discountsExport || initialState
}
