/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function AddPlusIcon(props) {
  return (
    <svg viewBox="-3 -3 106 106" {...props}>
      <path
        fillOpacity="0"
        strokeWidth="6"
        d="M50 100C22.41 100 0 77.6 0 50C0 22.4 22.41 0 50 0C77.6 0 100 22.4 100 50C100 77.6 77.6 100 50 100Z"
      />
      <path d="M80 48.85C80.69 48.85 81.25 49.41 81.25 50.1C81.25 50.7 81.25 51.25 81.25 51.85C81.25 52.54 80.69 53.1 80 53.1C67.97 53.1 33.15 53.1 21.13 53.1C20.43 53.1 19.88 52.54 19.88 51.85C19.88 51.25 19.88 50.7 19.88 50.1C19.88 49.41 20.43 48.85 21.13 48.85C33.15 48.85 67.97 48.85 80 48.85Z" />
      <path d="M53.72 80.54C53.72 81.23 53.16 81.79 52.47 81.79C51.87 81.79 51.32 81.79 50.72 81.78C50.03 81.78 49.47 81.22 49.47 80.53C49.51 68.51 49.62 33.68 49.65 21.66C49.65 20.97 50.21 20.41 50.91 20.41C51.51 20.41 52.06 20.41 52.66 20.42C53.35 20.42 53.9 20.98 53.9 21.67C53.87 33.69 53.76 68.52 53.72 80.54Z" />
    </svg>
  )
}
