/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { hasPath } from 'helpers/utilities'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { useSliderApi } from 'hooks'
import { PROGRAM } from 'constants/formCodes'
import { MY_WORK_PATH } from 'router'
import { BUTTON_TITLE } from './types'

export function ActivateButton(props) {
  const { authUser, activateProgramAction, programId, ...rest } = props

  const history = useHistory()
  const sliderApi = useSliderApi(PROGRAM)

  const onActivateProgram = useCallback(() => {
    if (authUser && activateProgramAction) {
      activateProgramAction(authUser, programId).then(draftProgram => {
        history.push(MY_WORK_PATH)
        if (hasPath(['id'])(draftProgram)) {
          sliderApi.open(draftProgram.id)
        }
      })
    }
  }, [activateProgramAction, authUser, history, programId, sliderApi])

  return (
    <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onActivateProgram} {...rest}>
      {BUTTON_TITLE}
    </BasicButton>
  )
}

ActivateButton.propTypes = {
  authUser: PropTypes.object.isRequired,
  activateProgramAction: PropTypes.func.isRequired,
  programId: PropTypes.string
}
ActivateButton.defaultProps = {
  programId: null
}
