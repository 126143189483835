/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { usePrevious } from 'hooks'
import { RoundButton } from '@xplorie/ui-commons'
import styles from './ToggleField.scss'

export function ToggleField(props) {
  const {
    options,
    disabled,
    onChange,
    fieldApi,
    fieldState: { value },
    fieldApi: { setValue, setTouched },
    forwardedRef,
    classNameWrapper,
    className,
    initialValue,
    notify,
    formApi,
    ...rest
  } = props
  const prevValue = usePrevious(value)

  const onClickHandler = optionValue => e => {
    setValue(optionValue)
    setTouched()
    if (onChange) {
      onChange(optionValue, e)
    }
  }

  useEffect(() => {
    if (prevValue !== value) {
      if (notify) {
        formApi.notify(notify)
      }
    }
  }, [prevValue, formApi, value, notify])

  return (
    <div className={classNameWrapper}>
      {options.map(option => (
        <RoundButton
          key={option.key || option.label}
          className={className}
          onClick={onClickHandler(option.value)}
          disabled={disabled}
          isActive={value === option.value}
          {...rest}
        >
          {option.label}
        </RoundButton>
      ))}
    </div>
  )
}
ToggleField.propTypes = {
  fieldState: PropTypes.object.isRequired,
  fieldApi: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.any, key: PropTypes.any })
  ).isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  forwardedRef: PropTypes.object,
  classNameWrapper: PropTypes.string,
  className: PropTypes.string,
  initialValue: PropTypes.any,
  notify: PropTypes.array,
  formApi: PropTypes.object.isRequired
}
ToggleField.defaultProps = {
  onChange: () => null,
  disabled: false,
  forwardedRef: null,
  classNameWrapper: styles.wrapper,
  className: styles.button,
  initialValue: null,
  notify: null
}
