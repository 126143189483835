/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  createActivityProvider,
  updateActivityProvider,
  getActivityProviderById
} from 'services/http/activityProvider'

import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_ENTITY_DATA,
  RESPONSE_ENTITY_DATA,
  RESPONSE_ENTITY_DATA_ERROR
} = helpers
const postProviderForm = (authKey, contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await createActivityProvider(authKey, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(
      actionCreater(RESPONSE_FORM, {
        responseData: request.data
      })
    )
    dispatch(send(message))
    return 'postProviderForm success'
  }

  return 'postProviderForm failed'
}
const putProviderForm = (authKey, contractData = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_FORM))
  const request = await updateActivityProvider(authKey, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_FORM_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(
      actionCreater(RESPONSE_FORM, {
        responseData: request.data
      })
    )
    dispatch(send(message))
    return 'putProviderForm success'
  }
  return 'putProviderForm failed'
}

const getAPById = (authKey, id) => async dispatch => {
  dispatch(actionCreater(REQUEST_ENTITY_DATA))
  const request = await getActivityProviderById(authKey, id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ENTITY_DATA_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_ENTITY_DATA, request.data))
    return 'getAPById success'
  }
  return 'getAPById failed'
}

const resetForm = () => ({
  type: RESET_DATA_STATE
})

export default {
  postProviderForm,
  putProviderForm,
  resetForm,
  getAPById
}
