/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { NAME } = types

const REQUEST_EXPORT = `${REQUEST_TYPE}_${NAME}`
const RESPONSE_EXPORT = `${RESPONSE_TYPE}_${NAME}`
const RESPONSE_ERROR_EXPORT = `${RESPONSE_ERROR_TYPE}_${NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

export default {
  REQUEST_EXPORT,
  RESPONSE_EXPORT,
  RESPONSE_ERROR_EXPORT,
  RESET_DATA_STATE
}
