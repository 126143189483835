/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { InitialModal } from './InitialModal'
import { AppDeactivationModal } from './AppDeactivationModal'
import { DelayedDeactivationModal } from './DelayedDeactivationModal'

export function getComponentByStep(stepNumber) {
  switch (stepNumber) {
    case 1:
      return InitialModal
    case 2:
      return AppDeactivationModal
    case 3:
      return DelayedDeactivationModal
    default:
      return InitialModal
  }
}
