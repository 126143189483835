/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const REQUEST_TYPE = 'REQUEST'
export const RESPONSE_TYPE = 'RESPONSE'
export const RESPONSE_ERROR_TYPE = 'RESPONSE_ERROR'
export const UNKNOWN_ACTION_TYPE = 'UNKNOWN_ACTION_TYPE'

export const DATA_STATE_NOT_REQUESTED = 'DATA_NOT_REQUESTED'
export const DATA_STATE_REQUESTING = 'REQUESTING_DATA'
export const DATA_STATE_RECIEVED = 'DATA_RECEIVED'
export const DATA_STATE_ERROR = 'ERROR'
export const RESET_STATE = 'RESET_TO_DEFAULT'
export const DATA_STATE_NEED_UPDATE = 'DATA_STATE_NEED_UPDATE'

export default {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  UNKNOWN_ACTION_TYPE,
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR,
  RESET_STATE,
  DATA_STATE_NEED_UPDATE
}
