/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { formActions } from 'reducers/ticketGroupForm'
import {
  actions as ticketGroupInventoryActions,
  getTicketGroupInventoryState
} from 'reducers/activityResale/ticketGroupInventory'
import {
  actions as ticketGroupTemplateActions,
  getTicketGroupTemplateState
} from 'reducers/activityResale/ticketGroupTemplate'
import { withAuthContext } from 'helpers/withAuthContext'
import { withActivityData } from 'modules/ActivityProducts/ActivityResaleTab/decorators'
import { TicketGroupForm as TicketGroupFormComponent } from './TicketGroupForm'

const mapStateToProps = state => {
  const ticketInventoryState = getTicketGroupInventoryState(state)
  const ticketTemplateState = getTicketGroupTemplateState(state)
  return {
    ...state.ticketGroupForm,
    ...ticketInventoryState,
    ...ticketTemplateState,
    downloadTemplateDataState: ticketTemplateState.downloadDataState,
    removeTemplateDataState: ticketTemplateState.removeDataState
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...formActions, ...ticketGroupInventoryActions, ...ticketGroupTemplateActions },
    dispatch
  )

export const TicketGroupForm = compose(
  withAuthContext,
  withActivityData,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TicketGroupFormComponent)
