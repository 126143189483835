/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import isUndefined from 'lodash/isUndefined'
import { withFormContext } from 'components/FormComponents/helpers'
import { Field, SimpleRadio, RadioConditionallyLabel } from 'components/FormComponents'

const YES_OPTION_TITLE = 'Yes'
const NO_OPTION_TITLE = 'No'

export class ConditionallyVisibledComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      radioValue: isUndefined(props.initialValue) ? props.value : props.initialValue
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ radioValue: this.props.value })
    }
  }

  handleOptionChange = ev => {
    const { onChange } = this.props
    const isTrueSet = ev.target.value === 'true'
    this.setState({ radioValue: isTrueSet })
    onChange(isTrueSet)
  }

  render() {
    const {
      condition,
      children,
      InputComponent = SimpleRadio,
      disabled,
      id,
      fieldProps
    } = this.props

    const { radioValue } = this.state
    const labelId = id ? `label-condition-${id}` : null
    const yesOptionId = id ? `yes-option-${id}` : null
    const noOptionId = id ? `no-option-${id}` : null
    return (
      <>
        <Field id={id} label={condition} className="tags has-addons" {...fieldProps}>
          <RadioConditionallyLabel id={labelId} isChecked={radioValue}>
            <InputComponent
              id={yesOptionId}
              mainValue={radioValue}
              value
              onChange={this.handleOptionChange}
              disabled={disabled}
            />
            {YES_OPTION_TITLE}
          </RadioConditionallyLabel>
          <RadioConditionallyLabel isChecked={!radioValue}>
            <InputComponent
              id={noOptionId}
              mainValue={radioValue}
              value={false}
              onChange={this.handleOptionChange}
              disabled={disabled}
            />
            {NO_OPTION_TITLE}
          </RadioConditionallyLabel>
        </Field>
        {radioValue && children}
      </>
    )
  }
}

ConditionallyVisibledComponent.propTypes = {
  condition: PropTypes.string.isRequired,
  InputComponent: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  fieldProps: PropTypes.object,
  initialValue: PropTypes.bool
}

ConditionallyVisibledComponent.defaultProps = {
  InputComponent: SimpleRadio,
  onChange: () => null,
  value: false,
  children: null,
  disabled: false,
  id: null,
  fieldProps: {},
  initialValue: undefined
}

export const ConditionallyVisibled = withFormContext(ConditionallyVisibledComponent)
