/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDidMount, useLoading } from 'hooks'
import { BasicModal } from 'components/Modals'
import { PIECurtainLoader } from 'components/Loaders'
import { DeactivateWarning } from './DeactivateWarning'
import { DeactivateModal } from './DeactivateModal'
import { MODAL_STEPS_COUNT, MODAL_TITLE, CONFIRMATION_STEP, DEACTIVATE_STEP } from './types'
import { SET_DEACTIVATION_MODE } from '../types'

export function ConfirmationModal({
  name,
  getApi,
  date,
  onAction,
  onCancel,
  deactivateDataState,
  dataId,
  mode
}) {
  const [isOpen, setIsOpenState] = useState(false)
  const [modalStep, setModalStepState] = useState(1)
  const [isDeactivating] = useLoading(deactivateDataState)

  const toggleModal = useCallback(() => {
    setIsOpenState(!isOpen)
  }, [isOpen])

  const closeModal = useCallback(() => {
    setIsOpenState(false)
  }, [])

  const nextStep = useCallback(() => {
    if (modalStep < MODAL_STEPS_COUNT) {
      setModalStepState(modalStep + 1)
    }
  }, [modalStep])

  useEffect(() => {
    if (date) {
      setModalStepState(DEACTIVATE_STEP)
    }
  }, [date, modalStep])

  const renderModalBodyByStep = useCallback(() => {
    switch (modalStep) {
      case CONFIRMATION_STEP:
        return <DeactivateWarning name={name} onAction={nextStep} onClose={toggleModal} />
      case DEACTIVATE_STEP:
        return (
          <DeactivateModal
            dataId={dataId}
            name={name}
            onAction={onAction}
            onCancel={onCancel}
            onClose={toggleModal}
            initialValue={date}
            mode={mode}
          />
        )
      default:
        throw new Error('incorrect modal step')
    }
  }, [dataId, date, modalStep, mode, name, nextStep, onAction, onCancel, toggleModal])

  useDidMount(() => {
    if (getApi) {
      getApi({ toggleModal, closeModal })
    }
  })

  return (
    <>
      <BasicModal
        isOpen={isOpen}
        title={MODAL_TITLE}
        onClose={toggleModal}
        renderBody={renderModalBodyByStep}
      />
      {isDeactivating && <PIECurtainLoader />}
    </>
  )
}
ConfirmationModal.propTypes = {
  name: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  getApi: PropTypes.func,
  date: PropTypes.string,
  deactivateDataState: PropTypes.string.isRequired,
  dataId: PropTypes.string.isRequired,
  mode: PropTypes.string
}
ConfirmationModal.defaultProps = { getApi: null, date: null, mode: SET_DEACTIVATION_MODE }
