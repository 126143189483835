/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'

import { asField, BasicTextArea } from 'informed'
import { Field } from 'components/FormComponents'

function TextAreaWrapper(props) {
  const {
    fieldState: { error },
    fieldState,
    label,
    isRequired,
    fieldChildren,
    fieldProps,
    id,
    ...rest
  } = props

  return (
    <Field
      id={id}
      label={label}
      isRequired={isRequired}
      fieldChildren={fieldChildren}
      error={error}
      {...fieldProps}
    >
      <BasicTextArea {...rest} id={id} fieldState={fieldState} />
    </Field>
  )
}

TextAreaWrapper.propTypes = {
  fieldState: PropTypes.object,
  label: PropTypes.sting,
  isRequired: PropTypes.bool,
  fieldChildren: PropTypes.any,
  fieldProps: PropTypes.any,
  id: PropTypes.string
}

TextAreaWrapper.defaultProps = {
  fieldState: {},
  label: '',
  isRequired: false,
  fieldChildren: null,
  fieldProps: null,
  id: null
}

export const TextArea = asField(TextAreaWrapper)
