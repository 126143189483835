/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { createContext } from 'react'
import get from 'lodash/get'
import { PERIOD_SPECIFIC } from './types'

export const DiscountContext = createContext({ api: {}, state: {} })

export const isPeriodSpecificActivity = formValues =>
  get(formValues, 'activity.parameters.specificType') === PERIOD_SPECIFIC

export const preparePayload = values => {
  const { activity, ...restValues } = values

  return {
    availabilities: [],
    ticketTypes: [],
    ...restValues
  }
}
