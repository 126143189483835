/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

// tabs tittles
export const ACTIVITY_CATEGORY_TITLE = 'Activity Category'
export const ACTIVITY_TAGS_TITLE = 'Activity Tags'
export const ACTIVITY_MODULE_TITLE = 'Activity Module'
export const LOCATIONS_TITLE = 'Locations'
export const PMS_NAME_TITLE = 'PMS Name'
export const RMS_NAME_TITLE = 'RMS Name'
export const TILE_ACCORDIONS_TITLE = 'Tile Accordions'
export const UNIQ_PHONE_NUMBER_TITLE = 'Unique Phone Numbers'
export const ELIGIBILITY_TITLE = 'Eligibility Parameters'
export const MARKET_TITLE = 'Markets'
