/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { DEAL_COMPLIMENTARY_TYPE, DEAL_NON_COMPLIMENTARY_TYPE } from 'constants/dealsTypes'

export const SECTION_TITLE = 'Discount Options'

export const SELECT_APP_DISCOUNT_DEAL_SEARCH_KEY = 'SELECT_APP_DISCOUNT_DEAL_SEARCH_KEY'

export const TYPES_OPTIONS = [
  { label: 'Complimentary', value: DEAL_COMPLIMENTARY_TYPE },
  { label: 'Exclusive', value: DEAL_NON_COMPLIMENTARY_TYPE }
]

export const NAME_LABEL = 'Discount Name'
export const NAME_PLACEHOLDER = 'Discount Name'
export const NAME_FIELD = 'name'

export const TYPE_LABEL = 'Offer Type'
export const TYPE_PLACEHOLDER = 'Offer Type'
export const TYPE_FIELD = 'type'

export const APP_LABEL = 'Select APP'
export const APP_PLACEHOLDER = 'Select APP'
export const ACTIVITY_FIELD = 'activity'

export const DESCRIPTION_LABEL = 'Description'
export const DESCRIPTION_PLACEHOLDER = 'Description'
export const DESCRIPTION_FIELD = 'description'

export const IN_TESTING_LABEL = '(In Testing)'

export const MAX_SYMBOL_COUNT_DEAL_NAME = 250

export const DATE_PERIOD_LABEL = 'Date Period'
export const DATE_SPECIFIC_LABEL = 'Date Specific'

export const SQUARE_IMAGE_LABEL = 'Discount Square image URL'
export const SQUARE_IMAGE_PLACEHOLDER = 'Discount Square image URL'
export const SQUARE_IMAGE_FIELD = 'sqImageUrl'
