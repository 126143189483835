/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE
} from 'helpers/actionHelpers'

import { PROFILE_ACTION } from './types'

export const REQUEST_PROFILE_ACTION = `${REQUEST_TYPE}_${PROFILE_ACTION}`
export const RESPONSE_PROFILE_ACTION = `${RESPONSE_TYPE}_${PROFILE_ACTION}`
export const RESPONSE_PROFILE_ACTION_ERROR = `${RESPONSE_ERROR_TYPE}_${PROFILE_ACTION}`

export default {
  REQUEST_PROFILE_ACTION,
  RESPONSE_PROFILE_ACTION,
  RESPONSE_PROFILE_ACTION_ERROR
}
