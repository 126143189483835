/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function GraphIcon(props) {
  return (
    <svg viewBox="0 0 144 144" {...props}>
      <path d="M25.6,98.7L42.1,61c0.3-0.6,1-0.8,1.7-0.5l2.3,1c0.7,0.3,1.1,1,0.8,1.6l-16.2,37" />
      <circle cx="46.1" cy="54" r="11.4" />
      <path d="M21.7,99.1c-7,1.9-11.2,9.1-9.3,16.1s9.1,11.2,16.1,9.3c7-1.9,11.2-9.1,9.3-16.1 C35.9,101.4,28.7,97.2,21.7,99.1" />
      <path d="M104.3,70.4l16.5-37.5c0.3-0.7,0-1.5-0.7-1.9l-2.3-1c-0.7-0.3-1.5,0-1.9,0.7L99.4,68.2	c-0.3,0.7,0,1.5,0.7,1.9l2.3,1C103.2,71.4,104,71.1,104.3,70.4z" />
      <circle cx="120" cy="26" r="11.9" />
      <circle cx="99" cy="81.3" r="13.2" />
      <path d="M90.9,70.7L56.2,55.5c-0.7-0.3-1.5,0-1.9,0.7l-1,2.3c-0.3,0.7,0,1.5,0.7,1.9l34.7,15.2c0.7,0.3,1.5,0,1.9-0.7	l1-2.3C91.9,71.8,91.6,71,90.9,70.7z" />
    </svg>
  )
}
