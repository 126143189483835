/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

// Defined url's
export const DEFAULT_HOME_REDIRECT_URL = '/home'
export const DEFAULT_PIE_REDIRECT_URL = '/admin/search'

export const UNAUTHORIZED_DEFAULT_REDIRECT_URL = process.env.APP_SSO_URL || '/login'

export const BACK_URL_QUERY_PARAM = 'backUrl'
export const APPLICATION_LOCALSTORAGE_NAME = 'XPLORIE_PROGRAM_IMPLEMENTATION_ENGINE'
export const APP_VERSION = 'APP_VERSION'
export const ACCESS_TOKEN_COOKIE_NAME = 'ACCESS_TOKEN'
export const REFRESH_TOKEN_COOKIE_NAME = 'REFRESH_TOKEN'
export const ENV_NAME = process.env.ENV_NAME || 'test'
