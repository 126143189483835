import PropTypes from 'prop-types'
import React from 'react'
import { PIECurtainLoader } from 'components/Loaders'
import { CalendarPopover as FullCalendar } from 'components/FullCalendar'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { useCalendarPresenter } from './useCalendarPresenter'

export function Calendar({
  activityId,
  fetch,
  events,
  loading,
  onDeleteSeries,
  onDeleteOccurrence,
  onDeleteOccurrences,
  onCancelSeries,
  onCancelOccurrence,
  onActivateSeries,
  onActivateOccurrence
}) {
  const {
    calendarRef,
    renderEvent,
    selectedEventsApi,
    buildEventClassNames,
    buildEventContextMenu,
    buildDayContextMenu,
    handleFetch
  } = useCalendarPresenter({
    activityId,
    onDeleteSeries,
    onDeleteOccurrence,
    onDeleteOccurrences,
    onCancelSeries,
    onCancelOccurrence,
    onActivateSeries,
    onActivateOccurrence,
    fetch
  })

  return (
    <>
      {loading && <PIECurtainLoader />}
      <FullCalendar
        ref={calendarRef}
        plugins={[interactionPlugin, dayGridPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{ right: 'dayGridMonth,dayGridWeek,today,prevYear,prev,next,nextYear' }}
        dayMaxEvents={3}
        dayMenuContext
        eventMenuContext
        displayEventEnd
        eventContent={renderEvent}
        eventOverlap={false}
        eventClassNames={buildEventClassNames}
        handleClickEvent={selectedEventsApi.handleClickEvent}
        dayContextMenuItems={buildDayContextMenu}
        eventContextMenuItems={buildEventContextMenu}
        events={events}
        eventTimeFormat={{ hour: 'numeric', minute: '2-digit', hour12: true }}
        datesSet={handleFetch}
      />
    </>
  )
}

Calendar.propTypes = {
  fetch: PropTypes.func,
  events: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  activityId: PropTypes.string.isRequired,
  onDeleteSeries: PropTypes.func.isRequired,
  onDeleteOccurrence: PropTypes.func.isRequired,
  onDeleteOccurrences: PropTypes.func.isRequired,
  onCancelSeries: PropTypes.func.isRequired,
  onCancelOccurrence: PropTypes.func.isRequired,
  onActivateSeries: PropTypes.func.isRequired,
  onActivateOccurrence: PropTypes.func.isRequired
}

Calendar.defaultProps = {
  fetch: null,
  loading: false,
  events: []
}
