/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { compose } from 'redux'
import { withFormApi, asField } from 'informed'
import { withFormContext } from 'components/FormComponents/helpers'
import { ToggleField as ToggleFieldComponent } from './ToggleField'

export const ToggleField = compose(
  asField,
  withFormApi,
  withFormContext
)(ToggleFieldComponent)
