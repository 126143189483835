/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { DiscountsContext } from './context'

export function withDiscountsPermissions(Component) {
  function WithDiscountsPermissions(props) {
    return (
      <DiscountsContext.Consumer>
        {context => <Component {...context} {...props} />}
      </DiscountsContext.Consumer>
    )
  }

  const componentName = Component.displayName || Component.name || 'Component'

  WithDiscountsPermissions.displayName = `withDiscountsPermissions(${componentName})`
  return WithDiscountsPermissions
}
