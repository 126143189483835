/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef } from 'react'
import { queueActivityCategoryForm } from 'helpers/formEventHandlers'
import PropTypes from 'prop-types'
import { useDidMount } from 'hooks/hooks'
import { isActionAllowed } from 'helpers/permissionHelper'
import { buildMenuPosition } from 'helpers/contextMenuHelper'
import { SYS_ADMIN_LEVEL } from 'constants/permissionTypes'
import { useRootModal } from 'hooks/useRootModal'
import styles from './ActivityCategoryContextMenu.scss'
import { VIEW_BUTTON, REMOVE_BUTTON } from './types'

import { DeleteCategoryModal } from '../DeleteCategoryModal/index'

export function ActivityCategoryContextMenu(props) {
  const menuEl = useRef(null)
  const {
    pageX,
    pageY,
    onClose,
    id,
    parentId,
    onRemoveCategory,
    authUser,
    validateCategoryById
  } = props
  const isSysAdmin = isActionAllowed(SYS_ADMIN_LEVEL, authUser)
  const rootModal = useRootModal()
  const onDeleteCategory = () => {
    validateCategoryById(id)
    rootModal.enqueue(DeleteCategoryModal, {
      isOpen: true,
      onClose: rootModal.dequeue,
      onSubmit: () => onRemoveCategory(id),
      message: 'Do you really want to delete the entry?',
      categoryId: id,
      parentId
    })
  }

  const onFocusHandler = () => {
    menuEl.current.focus()
  }

  const onBlurHandler = event => {
    onClose(event)
  }

  const onViewHandler = e => {
    if (!parentId) {
      queueActivityCategoryForm(props, { id })()(e)
    }
  }

  useDidMount(() => {
    onFocusHandler()
  })

  return (
    <>
      <ul
        ref={menuEl}
        className={styles.menuWrapper}
        style={{ ...buildMenuPosition(pageX, pageY) }}
        tabIndex="-1"
        onBlur={onBlurHandler}
      >
        <li className={styles.menuItem} onClick={onViewHandler}>
          {VIEW_BUTTON}
        </li>
        {isSysAdmin && (
          <li className={styles.menuItem} onClick={onDeleteCategory}>
            {REMOVE_BUTTON}
          </li>
        )}
      </ul>
    </>
  )
}
ActivityCategoryContextMenu.propTypes = {
  pageX: PropTypes.number,
  pageY: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  onRemoveCategory: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired,
  validateCategoryById: PropTypes.func.isRequired
}
ActivityCategoryContextMenu.defaultProps = {
  pageX: 0,
  pageY: 0,
  parentId: null
}
