/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { findDealsByActivityId } from 'services/http/activity'

import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST, RESET_DATA_STATE } = helpers

const getDealsListByActivityId = (id, options = {}) => async dispatch => {
  const key = id
  try {
    dispatch(actionCreater(REQUEST_LIST, { key }))
    const request = await findDealsByActivityId(id, options)
    dispatch(actionCreater(RESPONSE_LIST, { data: request.data, key }))
    return request.data
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
    return Promise.reject()
  }
}

const resetList = id => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key: id }))

export default {
  getDealsListByActivityId,
  resetList
}
