/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const POPUP_ENABLE_LABEL = 'Enable'
export const POPUP_DISABLE_LABEL = 'Disable'

export const DATE_FIELD = 'date'
export const DATE_INPUT_LABEL = 'Date'
export const DATE_INPUT_PLACEHOLDER = 'Input date'
export const TOP_DIRECTION = 'top'

export const PICK_TODAY_BUTTON_TEXT = "Today's date"

export const DEVICE_TYPE_NONE = 'NONE'
export const DEVICE_TYPE_DOT = 'DOT'
export const DEVICE_TYPE_SHOW = 'SHOW'

export const DEVICE_TYPE_NONE_LABEL = 'None'
export const DEVICE_TYPE_DOT_LABEL = 'Dot'
export const DEVICE_TYPE_SHOW_LABEL = 'Show'

export const TOGGLE_MODAL_EVENT_NAME = 'onOptionClick'
export const PENDING_ENABLE_LABEL = 'Pending enable'
export const PENDING_DISABLE_LABEL = 'Pending disable'
