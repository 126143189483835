/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { getEligibilityFormState } from 'reducers/eligibility/eligibilityForm'

export const getEligibilityListState = state => state.eligibility.eligibilityList
export const getEligibilityListPagination = state => {
  const eligibilityState = getEligibilityListState(state)
  return eligibilityState.pagination
}
export const getListWatchers = state => {
  const eligibilityFormsState = getEligibilityFormState(state)

  return [
    ...Object.keys(eligibilityFormsState).reduce((acc, key) => {
      const formState = eligibilityFormsState[key]
      return [...acc, formState.dataState]
    }, [])
  ]
}
