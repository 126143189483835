/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import {
  AND_OPERATOR,
  buildSearchColumn,
  LOCATIONS,
  PROGRAM,
  STRICT_MODE
} from 'helpers/searchHelpers'
import { STATUS_ACTIVE } from 'constants/entityStatus'
import types from './types'

const { LIST_NAME } = types

const REQUEST_LIST = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

const buildSearchLocationColumns = id => [
  buildSearchColumn({
    entity: PROGRAM,
    field: 'status',
    keyword: STATUS_ACTIVE,
    mode: STRICT_MODE
  }),
  buildSearchColumn({
    entity: LOCATIONS,
    field: 'locationId',
    keyword: id,
    operator: AND_OPERATOR,
    mode: STRICT_MODE
  })
]

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  buildSearchLocationColumns
}
