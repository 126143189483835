/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { combineReducers } from 'redux'

import accountFormReducer from './accountForm'
import accountListReducer from './accountList'
import accountPasswordReducer from './accountPassword'
import accountPasswordRecoveryReducer from './accountPasswordRecovery'
import accountSettingsReducer from './accountSettings'
import accountLifeCycleReducer from './accountLifeCycle'

export default combineReducers({
  accountForm: accountFormReducer,
  accountLifeCycle: accountLifeCycleReducer,
  accountList: accountListReducer,
  accountPassword: accountPasswordReducer,
  accountPasswordRecovery: accountPasswordRecoveryReducer,
  accountSettings: accountSettingsReducer
})
