/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useState, useEffect, useRef } from 'react'
import isUndefined from 'lodash/isUndefined'
import PropTypes from 'prop-types'
import { useLockBodyScroll, useUnLockBodyScroll } from 'hooks'
import { FormSlider } from 'components/Sliders'
import { FormErrorStub } from 'modules/Stubs'

import styles from '../SliderContainer.scss'
import { useFullScreenApi } from './use-full-screen-api'

export function SlideItem(props) {
  const {
    id,
    positionNumber,
    activePanel,
    headerProps,
    onClose,
    onSetHeaderProps,
    formProps,
    SlideContent,
    sliders
  } = props
  const [isLoaded, setIsLoadedState] = useState(false)
  const [hasError, setHasError] = useState(false)
  const slideEvents = useRef({})
  const fullScreen = useFullScreenApi(false)

  const isActive = activePanel === id

  useLockBodyScroll(isActive)

  useUnLockBodyScroll(sliders)

  const onErrorHandler = () => {
    if (!hasError) {
      setHasError(true)
    }
  }
  const onRefreshHandler = () => {
    if (hasError) {
      setHasError(false)
    }
  }
  const onCloseForce = () => {
    setIsLoadedState(false)
    onClose(id)
  }

  const onCloseHandler = async () => {
    const { onBeforeClose } = slideEvents.current
    let shouldClose = true
    if (onBeforeClose) {
      const needClose = await onBeforeClose()
      if (!isUndefined(needClose)) {
        shouldClose = needClose
      }
    }
    if (shouldClose) {
      setIsLoadedState(false)
      onClose(id)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoadedState(true)
    }, 400)
  }, [])

  useEffect(() => {
    slideEvents.current = { onBeforeClose: null }
  }, [fullScreen])

  return (
    <FormSlider
      positionNumber={positionNumber}
      isActive={isActive}
      onClose={onCloseHandler}
      fullScreen={fullScreen}
      {...headerProps}
    >
      {hasError ? (
        <FormErrorStub onRefresh={onRefreshHandler} />
      ) : (
        isLoaded && (
          <SlideContent
            {...formProps}
            onClose={onCloseForce}
            onError={onErrorHandler}
            onSetHeaderProps={onSetHeaderProps(id)}
            fullScreen={fullScreen}
            slideEvents={slideEvents.current}
          />
        )
      )}
      {isActive && (
        <>
          <div className={styles.slideCurtain} onClick={onCloseHandler} />
          <div className={styles.safeArea} />
        </>
      )}
    </FormSlider>
  )
}

SlideItem.propTypes = {
  id: PropTypes.string.isRequired,
  positionNumber: PropTypes.number.isRequired,
  activePanel: PropTypes.string.isRequired,
  headerProps: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSetHeaderProps: PropTypes.func.isRequired,
  formProps: PropTypes.func.isRequired,
  SlideContent: PropTypes.func.isRequired,
  sliders: PropTypes.array.isRequired
}

SlideItem.defaultProps = {}
