/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { listInitialState } from './reducer'

/**
 *
 * @param {object} state
 * @param {string} locationId
 */
function getProgramsListStateByLocationId(state, locationId) {
  return state.program.locationPrograms[locationId] || listInitialState
}

export default {
  getProgramsListStateByLocationId
}
