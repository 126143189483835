/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { combineReducers } from 'redux'
import searchExport from './searchExport'
import filteredSearchExport from './filteredSearchExport'
import discountsExport from './discountsExport'

export default combineReducers({
  searchExport,
  filteredSearchExport,
  discountsExport
})
