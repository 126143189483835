/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { getPmcLifeCycleState } from 'reducers/pmc/pmcLifeCycle'

export function getListWatchers(state) {
  const pmcLifeCycleState = getPmcLifeCycleState(state)

  return [
    state.pmcsForm.dataState,
    ...Object.keys(pmcLifeCycleState).reduce(
      (acc, pmcId) => [
        ...acc,
        pmcLifeCycleState[pmcId].activationDataState,
        pmcLifeCycleState[pmcId].deactivationDataState
      ],
      []
    )
  ]
}
