/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'

import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID_ERROR,
  REQUEST_CREATE_DEAL_OPTIONS,
  RESPONSE_CREATE_DEAL_OPTIONS,
  RESPONSE_CREATE_DEAL_OPTIONS_ERROR,
  REQUEST_UPDATE_DEAL_OPTIONS,
  RESPONSE_UPDATE_DEAL_OPTIONS,
  RESPONSE_UPDATE_DEAL_OPTIONS_ERROR,
  REQUEST_DELETE_DEAL,
  RESPONSE_DELETE_DEAL,
  RESPONSE_DELETE_DEAL_ERROR,
  REQUEST_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL_ERROR,
  REQUEST_DEACTIVATE_DEAL,
  RESPONSE_DEACTIVATE_DEAL,
  RESPONSE_DEACTIVATE_DEAL_ERROR
} = helpers

export const initialState = {
  createOptionsDataState: DATA_STATE_NOT_REQUESTED,
  updateOptionsDataState: DATA_STATE_NOT_REQUESTED,
  entityDataState: DATA_STATE_NOT_REQUESTED,
  deleteDataState: DATA_STATE_NOT_REQUESTED,
  activateDataState: DATA_STATE_NOT_REQUESTED,
  deactivateDataState: DATA_STATE_NOT_REQUESTED,
  entityData: {}
}

const dealFormReducer = (state = initialState, action) => {
  switch (action.type) {
    // create deal
    case REQUEST_CREATE_DEAL_OPTIONS:
      return {
        ...state,
        createOptionsDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_CREATE_DEAL_OPTIONS:
      return {
        ...state,
        createOptionsDataState: DATA_STATE_RECIEVED,
        entityData: action.payload
      }
    case RESPONSE_CREATE_DEAL_OPTIONS_ERROR:
      return {
        ...state,
        createOptionsDataState: DATA_STATE_ERROR
      }
    // update deal
    case REQUEST_UPDATE_DEAL_OPTIONS:
      return {
        ...state,
        updateOptionsDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_UPDATE_DEAL_OPTIONS:
      return {
        ...state,
        updateOptionsDataState: DATA_STATE_RECIEVED,
        entityData: action.payload
      }
    case RESPONSE_UPDATE_DEAL_OPTIONS_ERROR:
      return {
        ...state,
        updateOptionsDataState: DATA_STATE_ERROR
      }
    // get deal by id
    case REQUEST_GET_DATA_BY_ID:
      return {
        ...state,
        entityDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_GET_DATA_BY_ID:
      return {
        ...state,
        entityDataState: DATA_STATE_RECIEVED,
        entityData: action.payload
      }
    case RESPONSE_GET_DATA_BY_ID_ERROR:
      return {
        ...state,
        entityDataState: DATA_STATE_ERROR
      }
    // delete deal
    case REQUEST_DELETE_DEAL:
      return {
        ...state,
        deleteDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_DELETE_DEAL:
      return {
        ...state,
        deleteDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_DELETE_DEAL_ERROR:
      return {
        ...state,
        deleteDataState: DATA_STATE_ERROR
      }
    // activate deal
    case REQUEST_ACTIVATE_DEAL:
      return {
        ...state,
        activateDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_ACTIVATE_DEAL:
      return {
        ...state,
        activateDataState: DATA_STATE_RECIEVED,
        entityData: action.payload
      }
    case RESPONSE_ACTIVATE_DEAL_ERROR:
      return {
        ...state,
        activateDataState: DATA_STATE_ERROR
      }
    // deactivate deal
    case REQUEST_DEACTIVATE_DEAL:
      return {
        ...state,
        deactivateDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_DEACTIVATE_DEAL:
      return {
        ...state,
        deactivateDataState: DATA_STATE_RECIEVED,
        entityData: action.payload
      }
    case RESPONSE_DEACTIVATE_DEAL_ERROR:
      return {
        ...state,
        deactivateDataState: DATA_STATE_ERROR
      }
    // reset
    case RESET_DATA_STATE:
      return {
        ...initialState
      }

    default:
      return state
  }
}

export default filterActions(dealFormReducer, [
  RESET_DATA_STATE,
  REQUEST_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID_ERROR,
  REQUEST_CREATE_DEAL_OPTIONS,
  RESPONSE_CREATE_DEAL_OPTIONS,
  RESPONSE_CREATE_DEAL_OPTIONS_ERROR,
  REQUEST_UPDATE_DEAL_OPTIONS,
  RESPONSE_UPDATE_DEAL_OPTIONS,
  RESPONSE_UPDATE_DEAL_OPTIONS_ERROR,
  REQUEST_DELETE_DEAL,
  RESPONSE_DELETE_DEAL,
  RESPONSE_DELETE_DEAL_ERROR,
  REQUEST_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL_ERROR,
  REQUEST_DEACTIVATE_DEAL,
  RESPONSE_DEACTIVATE_DEAL,
  RESPONSE_DEACTIVATE_DEAL_ERROR
])
