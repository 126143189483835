/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

// https://github.com/indexiatech/re-notif

import reducer from './reducer'
import * as actions from './actions'
import Notifs from './components/Notifs'

export { Notifs, actions, reducer }
