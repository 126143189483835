import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import { WEEK_DAYS_FIELD } from '../ClosureForm/TimePeriodsList/types'
import { createTimePeriodWithUniqId } from '../mappers'
import { FULL_DAY_TIME_PERIOD } from './types'

export function useTimePeriodApi(formApi) {
  const appendTimePeriodsToForm = timePeriods => {
    const { values } = formApi.current.getState()

    formApi.current.setValues({
      ...values,
      timePeriods: {
        ...values.timePeriods,
        ...timePeriods
      }
    })
  }

  const getTimePeriod = () => {
    const { values } = formApi.current.getState()

    return values.allDay ? {} : values.timePeriods
  }

  const canSeeRemoveTimePeriod = () => {
    const { values } = formApi.current.getState()

    return keys(values.timePeriods).filter(key => key !== WEEK_DAYS_FIELD).length > 1
  }

  const addTimePeriod = timePeriod => {
    const { values } = formApi.current.getState()
    const timePeriods = createTimePeriodWithUniqId(values.timePeriods, timePeriod)

    appendTimePeriodsToForm(timePeriods)
  }

  const editTimePeriod = timePeriodId => timePeriodValue => {
    const timePeriods = { [timePeriodId]: timePeriodValue }

    appendTimePeriodsToForm(timePeriods)
  }

  const removeTimePeriod = timePeriodId => () => {
    const { values } = formApi.current.getState()
    const { [timePeriodId]: removedTimePeriod, ...timePeriods } = values.timePeriods

    formApi.current.setValues({
      ...values,
      timePeriods
    })
  }

  const changeAllDay = allDay => {
    const { values } = formApi.current.getState()

    if (!allDay && (isEmpty(values.timePeriods) || values.timePeriods.weekDays)) {
      const fullDayTimePeriod = {
        ...FULL_DAY_TIME_PERIOD,
        weekDays: values.timePeriods.weekDays || []
      }
      const timePeriods = createTimePeriodWithUniqId({}, fullDayTimePeriod)

      formApi.current.setValues({
        ...values,
        timePeriods: {
          ...timePeriods
        }
      })
    }
  }

  return {
    appendTimePeriodsToForm,
    changeAllDay,
    getTimePeriod,
    addTimePeriod,
    editTimePeriod,
    removeTimePeriod,
    canSeeRemoveTimePeriod
  }
}
