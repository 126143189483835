/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export { updateUserData, savedAuthState } from './storageHelper'
export { buildBackUrl, logInRedirect } from './redirectHelper'
export { buildAuthKey } from './tokenService'
export { validateAuthUser } from './validateHelpers'
export * from './types'
export * from './cookieHelper'

// TODO:this list of files not used with dependency
export { accessTokenMiddleware, refreshTokenFlow } from './middlewares'
export { onAccessDenied } from './commonHelper'
