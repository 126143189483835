/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  LINK_REGISTRATION_TYPE,
  CALL_2_BOOK_REGISTRATION_TYPE,
  STRIPE_REQUIRED_VALUE,
  STRIPE_NOT_REQUIRED_VALUE
} from 'constants/resell'
import {
  COLLECTED_BY_AP_BOOKING,
  COLLECTED_BY_AP_ARRIVAL,
  PAYMENT_NOT_REQUIRED
} from 'constants/paymentMethod'
import { REDEMPTION_METHOD } from 'constants/app'

export const TICKET_SETTINGS_TITLE = 'Ticket Settings'

// fieldName
export const REG_TYPE_FIELD = 'type'
export const BUTTON_LINK_FIELD = 'buttonLink'
export const PHONE_NUMBER_FIELD = 'phoneNumber'

// Fees
export const FEES_FIELD = 'fees'
export const PER_TICKET_FEE_FIELD = 'perTicketFee'
export const FEES_PER_ORDER_VALUE = false
export const FEES_PER_ORDER_LABEL = 'Per Order'
export const FEES_PER_TICKET_VALUE = true
export const FEES_PER_TICKET_LABEL = 'Per Ticket'
export const feesTypeOptions = [
  { value: FEES_PER_ORDER_VALUE, label: FEES_PER_ORDER_LABEL },
  { value: FEES_PER_TICKET_VALUE, label: FEES_PER_TICKET_LABEL }
]

export const CANCELLATION_POLICY_FIELD = 'cancellationPolicy'
export const HOW_TO_FIELD = 'howToFile'
export const REDEMPTION_METHOD_FIELD = 'redemptionType'

// Labels:
export const TAXES_LABEL = 'Taxes'
export const FEES_LABEL = 'Taxable fees'
export const DATE_SPECIFIC_LABEL = 'Is This Ticket Date Specific'
export const DATE_PERIOD_TICKET_LABEL = 'Is this Ticket for a Date Period'
export const TIME_SPECIFIC_LABEL = 'Is This Ticket Time Specific'
export const CONFIRMATION_NUMBER_LABEL = 'Is A Confirmation # Required'
export const TICKET_FORMAT_LABEL = 'Ticket Format'
export const EXTERNAL_REGISTRATION_TITLE = 'External Registration'
export const EXTERNAL_CANCELATION_TITLE = 'External Cancellation'
export const EXTERNAL_REGISTRATION_LABEL = 'External Registration Page'
export const EXTERNAL_CANCELATION_PAGE_LABEL = 'External Cancellation Page'
export const NO_RETAIL_LABEL = 'Deactivate Retail'
export const REGISTRATION_AREA_LABEL = 'Registration Directions'
export const CANCELLATION_DIRECTIONS_LABEL = 'Cancellation Directions'
export const BOOKING_DIRECTIONS_LABEL = 'Booking Directions'
export const SHOW_BARCODES_LABEL = 'Show Ticket/Barcode number'
export const LINK_REG_TYPE_LABEL = 'Link'
export const REQUIRED_TYPE_LABEL = 'Required'
export const NOT_REQUIRED_TYPE_LABEL = 'Not Required'
export const CALL_2_BOOK_REG_TYPE_LABEL = 'Call-2-book'
export const BUTTON_LINK_LABEL = 'Button-Link'
export const AP_PHONE_NUMBER_LABEL = 'AP Phone Number'
export const NON_TAXABLE_FEES_LABEL = 'Non-Taxable fees'
export const COLLECTION_METHOD_LABEL = 'Collection Method'
export const CANCELLATION_POLICY_LABEL = 'Cancellation Policy'
export const HOW_TO_LABEL = 'How To'
export const REDEMPTION_METHOD_LABEL = 'Redemption Method'
export const CONFIRMED_BY_LABEL = 'Is a Confirmed by Required'
export const RESELLER_ID_LABEL = 'Reseller ID'
export const USER_NAME_LABEL = 'Username'
export const PASSWORD_LABEL = 'Password'
export const TRANSFER_TO_TEAM_LEAD_LABEL = 'Transfer to Team Lead'
export const LESSONLY_LINK_LABEL = 'Lessonly Link'
export const REMINDER_POP_UP_WINDOW_LABEL = 'Reminder pop-up window '
export const CANCELLATION_REMINDER_POP_UP_WINDOW_LABEL = 'Cancellation Reminder pop-up window'
export const BOOKING_WINDOW_TITLE = 'Booking window'
export const CANCELLATION_WINDOW_TITLE = 'Cancellation Window'

// Placeholders:
export const TAXES_PLACEHOLDER = 'Taxes'
export const FEES_PLACEHOLDER = 'Taxable fees'
export const TICKET_FORMAT_PLACEHOLDER = 'Select Ticket Format'
export const CUSTOM_TEXT_PLACEHOLDER = 'Custom Text / Note'
export const CANCELLATION_DIRECTIONS_PLACEHOLDER = 'Cancellation Directions'
export const BUTTON_LINK_PLACEHOLDER = 'Enter a URL for the button'
export const AP_PHONE_NUMBER_PLACEHOLDER = 'Enter Phone Number to call for Bookings'
export const NON_TAXABLE_FEES_PLACEHOLDER = 'Non-Taxable fees'
export const COLLECTION_METHOD_PLACEHOLDER = 'Collection Method'
export const CANCELLATION_POLICY_PLACEHOLDER = 'Cancellation Policy'
export const HOW_TO_PLACEHOLDER = 'Drag File Here or Click To Browse'
export const REDEMPTION_METHOD_PLACEHOLDER = 'Redemption Method'
export const RESELLER_ID_PLACEHOLDER = 'Reseller ID'
export const USER_NAME_PLACEHOLDER = 'Username'
export const PASSWORD_PLACEHOLDER = 'Password'
export const LESSONLY_LINK_PLACEHOLDER = 'Enter Lessonly URL'
export const REMINDER_POP_UP_WINDOW_PLACEHOLDER = 'Enter reminder note'
export const CANCELLATION_REMINDER_POP_UP_WINDOW_PLACEHOLDER = 'Cancellation Reminder pop-up window'

// Modal types
export const MODAL_TITLE = 'Warning!'
export const MESSAGE_FIRST_PART = 'Are you sure want to change '
export const PRETEXT_TO = ' to '
export const QUESTION_MARK = '?'

export const PERCENT_SYMBOL = '%'

export const percentageFeesOptions = [{ value: false, label: '$' }, { value: true, label: '%' }]
export const registrationsTypeOptions = [
  { value: LINK_REGISTRATION_TYPE, label: LINK_REG_TYPE_LABEL },
  { value: CALL_2_BOOK_REGISTRATION_TYPE, label: CALL_2_BOOK_REG_TYPE_LABEL }
]

export const COLLECTION_METHOD_OPTIONS = [
  { value: PAYMENT_NOT_REQUIRED, label: 'Payment not collected' },
  { value: COLLECTED_BY_AP_BOOKING, label: 'Collected by AP at time of Booking' },
  { value: COLLECTED_BY_AP_ARRIVAL, label: 'Collected by AP upon arrival' }
]

export const REDEMPTIONS_METHOD_LABELS_MAP = {
  [REDEMPTION_METHOD.DOWNLOAD_TICKETS]: 'Download tickets',
  [REDEMPTION_METHOD.PRESENT_PHOTO_ID]: 'Present Photo ID',
  [REDEMPTION_METHOD.PRESENT_CONFIRMATION_NUMBER]: 'Present Confirmation #'
}

// Stripe Payment Field
export const STRIPE_PAYMENT_LABEL = 'Stripe Payment'
export const STRIPE_PAYMENT_FIELD = 'stripeEnabled'

export const stripePaymentOptions = [
  { value: STRIPE_REQUIRED_VALUE, label: REQUIRED_TYPE_LABEL },
  { value: STRIPE_NOT_REQUIRED_VALUE, label: NOT_REQUIRED_TYPE_LABEL }
]

// Registration Types
export const LINK_REG_TYPE = 'LINK'

// Hints
export const BOOKING_WINDOW_HINT = `If the value is set in days the Booking will be restricted from 12:00 AM of the current date. If the value is set in hours the Booking will be restricted from the exact time of Booking.`

export const REGISTRATION_TYPE = 'REGISTRATION'
export const CANCELLATION_TYPE = 'CANCELLATION'
