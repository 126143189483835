/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import isObject from 'lodash/isObject'
import { actionCreater } from 'helpers/actionHelpers'
import { checkUpdateProgress } from 'services/http/ibeConnection'

import helpers from './helpers'

const {
  REQUEST_CHECK_UPDATE_PROGRESS,
  RESPONSE_CHECK_UPDATE_PROGRESS,
  RESPONSE_CHECK_UPDATE_PROGRESS_ERROR,
  RESET_DATA_STATE
} = helpers

const checkUpdateProgressAction = (authKey, contractData, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_CHECK_UPDATE_PROGRESS))
  const request = await checkUpdateProgress(authKey, contractData, params).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_CHECK_UPDATE_PROGRESS_ERROR))
    }
  )
  if (request) {
    const responceData = isObject(request.data) ? request.data : {}
    dispatch(actionCreater(RESPONSE_CHECK_UPDATE_PROGRESS, responceData))
    return 'checkUpdateProgressAction success'
  }
  return 'checkUpdateProgressAction failed'
}

const resetState = () => ({
  type: RESET_DATA_STATE
})

export default {
  resetState,
  checkUpdateProgressAction
}
