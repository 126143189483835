/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { httpWrapper } from 'services/http/helpers'
import { downloadFile, responseToFile } from 'helpers/downloadHelper'
import { GENERATE_BARCODE_TEMPLATE_URL } from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'
import {
  buildTicketGroupsURL,
  buildTicketGroupsURLByGroupId,
  buildInventoryURLByGroupId,
  buildDownloadTicketURL,
  removeTicketURL,
  assingTicketTypeURL,
  generateBarcodeByIdURL,
  buildGroupTemplateURL
} from './helpers'

export const createEntity = (contractData = {}) => {
  const { activityId } = contractData
  return httpWrapper({ url: buildTicketGroupsURL(activityId), method: 'post', data: contractData })
}

export const updateEntity = (contractData = {}) => {
  const { activityId } = contractData
  return httpWrapper({ url: buildTicketGroupsURL(activityId), method: 'put', data: contractData })
}
export const getEntityById = (activityId, groupId, params) =>
  httpWrapper({ url: buildTicketGroupsURLByGroupId(activityId, groupId), method: 'get', params })

export const deleteEntityById = (activityId, groupId, params) =>
  httpWrapper({ url: buildTicketGroupsURLByGroupId(activityId, groupId), method: 'delete', params })

export const uploadTicketById = (
  activityId,
  groupId,
  data,
  options = { onUploadProgress: () => null }
) =>
  httpWrapper({
    url: buildInventoryURLByGroupId(activityId, groupId),
    method: 'post',
    data,
    ...options
  })

export const downloadTicket = ({ activityId, groupId, fileId, name }) =>
  downloadFile(buildDownloadTicketURL(activityId, groupId, fileId), name)

export const removeTicketById = (activityId, groupId, data) =>
  httpWrapper({
    url: removeTicketURL(activityId, groupId),
    method: 'post',
    data
  })

export const assingTicketToGroup = (activityId, groupId, ticketId, data) =>
  httpWrapper({ url: assingTicketTypeURL(activityId, groupId, ticketId), method: 'put', data })

export const generateBarcodeById = (
  activityId,
  groupId,
  data = {},
  options = { onUploadProgress: () => null }
) => {
  const { codeFile, template, useDefault } = data
  const formData = new FormData()
  formData.append('codeFile', codeFile)
  if (template) {
    formData.append('template', template)
  }
  return httpWrapper({
    url: generateBarcodeByIdURL(activityId, groupId),
    method: 'post',
    data: formData,
    params: { useDefault },
    ...options
  })
}

export const downloadTemplate = (activityId, groupId, fileName = 'template') =>
  downloadFile(buildGroupTemplateURL(activityId, groupId), fileName)

export const addGroupTemplate = (activityId, groupId, params) =>
  httpWrapper({ url: buildGroupTemplateURL(activityId, groupId), method: 'put', params })

export const removeGroupTemplate = (activityId, groupId, params) =>
  httpWrapper({ url: buildGroupTemplateURL(activityId, groupId), method: 'delete', params })

export const generateBarcodeTemplate = (activityId, groupId) =>
  httpWrapper({
    url: buildPath(GENERATE_BARCODE_TEMPLATE_URL, { activityId, id: groupId }),
    method: 'get',
    responseType: 'blob'
  }).then(responseToFile)
