import get from 'lodash/get'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'

export async function submitWithValidation({
  payload,
  activityId,
  rootModal,
  onSubmit,
  getIntersections,
  message,
  warningModal
}) {
  const overlaps = await getIntersections(activityId, payload)
  if (isEmpty(overlaps)) {
    return onSubmit(activityId, payload)
  }
  const overlapsPeriod = head(overlaps)
  const { datePeriod, timePeriod } = overlapsPeriod

  return rootModal.enqueue(warningModal, {
    isOpen: true,
    onClose: rootModal.dequeue,
    message,
    onSubmit: () => onSubmit(activityId, payload),
    date: {
      start: datePeriod.startDate,
      end: datePeriod.endDate
    },
    time: timePeriod
      ? {
          start: timePeriod.startTime,
          end: timePeriod.endTime
        }
      : null
  })
}

export function buildDate(date) {
  const isPeriod = Boolean(date && date.start && date.end)
  const startDate = get(date, 'start')
  const endDate = get(date, 'end')

  return isPeriod ? `${startDate} - ${endDate}` : date
}

export function buildTime(time) {
  const startTime = get(time, 'start')
  const endTime = get(time, 'end')

  return startTime && endTime ? `${startTime} - ${endTime}` : 'all day'
}

export function buildDateTime({ date, time }) {
  if (!date && !time) {
    return null
  }

  return `${buildDate(date)} @ ${buildTime(time)}`
}
