/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef, useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
  blankValidate,
  withValidation,
  dateValidate,
  dateInFutureValidation
} from 'helpers/formHelpers'
import { TooltipComponent, TOP_DIRECTION } from 'components/Tooltip'
import { FORMAT } from 'constants/date'
import { Form } from 'informed'
import { CalendarField, FormContent } from 'components/FormComponents'
import classNames from 'classnames'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, LINK_SCHEMA, YELLOW_GREEN_SCHEMA } from 'constants/buttonsOptions'
import styles from './styles.scss'

import {
  DATE_INPUT_LABEL,
  DATE_INPUT_PLACEHOLDER,
  SELECT_DATE_DEACTIVATE_MESSAGE,
  BECOME_INACTIVE_MESSAGE,
  DATE_FIELD,
  PICK_TODAY_BUTTON_TEXT,
  DEACTIVATE_TEXT,
  DECLINE_TEXT,
  UPDATE_TEXT,
  CANCEL_TEXT,
  RELATED_ENTITIES_BUTTON_TEXT
} from './types'
import { buildEntitiesPath } from '../helpers'
import { UPDATE_DATE_MODE, UPDATE_DEACTIVATED_DATE_MODE, SET_DEACTIVATION_MODE } from '../types'

const dateValidation = withValidation(dateValidate, blankValidate)
const updateDeactivatedDateValidation = withValidation(dateValidation, dateInFutureValidation)

export function DeactivateModal({ name, onAction, onClose, onCancel, initialValue, dataId, mode }) {
  const formApi = useRef()
  const [isDisabledAction, setIsDisabledState] = useState(true)

  const showEntitiesPath = useMemo(() => buildEntitiesPath(dataId), [dataId])

  const onGetApi = useCallback(api => {
    formApi.current = api
  }, [])

  const onFormChangeHandler = useCallback(formState => {
    const isDisabled = formState.invalid || formState.pristine
    setIsDisabledState(isDisabled)
  }, [])

  const validateDate = value => {
    const { date } = value

    return mode === UPDATE_DEACTIVATED_DATE_MODE
      ? updateDeactivatedDateValidation(date)
      : dateValidation(date)
  }

  const onClickTodayHandler = useCallback(() => {
    if (formApi.current) {
      formApi.current.setValue(DATE_FIELD, { date: moment().format(FORMAT) })
    }
  }, [])

  const onCancelDeactivationHandler = useCallback(() => {
    if (onCancel) {
      onCancel()
    }
  }, [onCancel])

  const onSubmitHandler = useCallback(
    formValues => {
      if (onAction && formValues) {
        onAction(formValues)
      }
    },
    [onAction]
  )

  const onClickDeactivate = useCallback(() => {
    formApi.current.submitForm()
  }, [])

  return (
    <div className={styles.bodyWrapper}>
      <span className={styles.message}>
        <span className={styles.text}>{SELECT_DATE_DEACTIVATE_MESSAGE}</span>
        <span className={classNames(styles.text, styles.bold)}>{name}</span>
        <span className={styles.text}>{BECOME_INACTIVE_MESSAGE}</span>
      </span>
      <Link className={styles.showPrograms} to={showEntitiesPath} target="_blank">
        {RELATED_ENTITIES_BUTTON_TEXT}
      </Link>
      <Form
        onSubmit={onSubmitHandler}
        className={styles.formWrapper}
        getApi={onGetApi}
        initialValues={{ date: initialValue }}
        onChange={onFormChangeHandler}
      >
        <FormContent disabled={false}>
          <CalendarField
            fieldName={DATE_FIELD}
            label={DATE_INPUT_LABEL}
            placeholder={DATE_INPUT_PLACEHOLDER}
            validate={validateDate}
            isRequired
            withHolidays
            fieldProps={{
              renderInner: ({ className, children: Component, error }) => (
                <div className={className}>
                  {Component}
                  {error && (
                    <div className={styles.errorTooltipWrapper}>
                      <TooltipComponent directionOption={TOP_DIRECTION} contentTooltip={error} />
                    </div>
                  )}
                  <BasicButton
                    colorSchema={LINK_SCHEMA}
                    className={styles.todayButton}
                    onClick={onClickTodayHandler}
                  >
                    {PICK_TODAY_BUTTON_TEXT}
                  </BasicButton>
                </div>
              )
            }}
          />
        </FormContent>
      </Form>
      <div className={styles.buttons}>
        {mode === UPDATE_DATE_MODE && (
          <>
            <BasicButton
              colorSchema={ORANGE_SCHEMA}
              onClick={onClickDeactivate}
              disabled={isDisabledAction}
            >
              {UPDATE_TEXT}
            </BasicButton>
            <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onCancelDeactivationHandler}>
              {CANCEL_TEXT}
            </BasicButton>
          </>
        )}
        {mode === SET_DEACTIVATION_MODE && (
          <>
            <BasicButton
              colorSchema={ORANGE_SCHEMA}
              onClick={onClickDeactivate}
              disabled={isDisabledAction}
            >
              {DEACTIVATE_TEXT}
            </BasicButton>
            <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
              {DECLINE_TEXT}
            </BasicButton>
          </>
        )}
        {mode === UPDATE_DEACTIVATED_DATE_MODE && (
          <>
            <BasicButton
              colorSchema={ORANGE_SCHEMA}
              onClick={onClickDeactivate}
              disabled={isDisabledAction}
            >
              {UPDATE_TEXT}
            </BasicButton>
            <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
              {DECLINE_TEXT}
            </BasicButton>
          </>
        )}
      </div>
    </div>
  )
}
DeactivateModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  dataId: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired
}
DeactivateModal.defaultProps = {
  error: null,
  children: null,
  className: '',
  initialValue: null
}
