/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'
import isBoolean from 'lodash/isBoolean'
import store from 'connectors'
import { helpers, actions } from 'reducers/currentUser'
import { getAuthToken, getRefreshToken } from 'services/auth/cookieHelper'
import { actionCreater, DATA_STATE_REQUESTING } from 'helpers/actionHelpers'

let refreshTokenFlowPromise = null

// TODO: not used the function
export const refreshTokenFlow = () => {
  const { refreshTokenDataState } = store.getState().currentUser
  if (refreshTokenDataState === DATA_STATE_REQUESTING) {
    return refreshTokenFlowPromise
  }
  refreshTokenFlowPromise = new Promise(async (resolve, reject) => {
    const errorResponce = { response: { status: 401 } }
    const refreshToken = getRefreshToken()
    if (!refreshToken) {
      store.dispatch(actionCreater(helpers.RESPONSE_AUTH_ERROR))
      return reject(errorResponce)
    }
    let refreshTokenRequest
    try {
      refreshTokenRequest = await actions.refreshToken(refreshToken)(store.dispatch)
      return resolve(refreshTokenRequest)
    } catch (err) {
      return reject(errorResponce)
    }
  })
  return refreshTokenFlowPromise
}

/**
 *
 * @param {Object} options
 * @param {boolean} options.secure  - check access token before request
 */
// TODO: not used the function
export const accessTokenMiddleware = (options = { secure: true }) =>
  new Promise(async (resolve, reject) => {
    const secure = get(options, 'secure')
    // do not check access token if secure:false
    if ((!secure && isBoolean(secure)) || getAuthToken()) {
      return resolve()
    }
    let refreshTokenRequest
    try {
      refreshTokenRequest = await refreshTokenFlow()
      return resolve(refreshTokenRequest)
    } catch (err) {
      return reject(err)
    }
  })
