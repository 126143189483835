/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const NAME = 'AP_ENTITY_LIFE_CYCLE'

const DEACTIVATION = `${NAME}_DEACTIVATION`
const ACTIVATION = `${NAME}_ACTIVATION`

export default {
  NAME,
  DEACTIVATION,
  ACTIVATION
}
