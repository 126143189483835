/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { compose } from 'redux'
import { withFormApi, withFormState } from 'informed'
import { UploadBarcodes as UploadBarcodesComponent } from './UploadBarcodes'

export const UploadBarcodes = compose(
  withFormApi,
  withFormState
)(UploadBarcodesComponent)
