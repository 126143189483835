/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/holidaysDictionary'
import { withAuthContext } from 'helpers/withAuthContext'
import { CalendarWithHolidays as CalendarWithHolidaysComponent } from './CalendarWithHolidays'

const mapStateToProps = state => ({
  ...state.holidaysDictionary
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions
    },
    dispatch
  )

export const CalendarWithHolidays = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CalendarWithHolidaysComponent)
