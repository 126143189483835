/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const FORMAT = 'MM/DD/YYYY'
export const MM_DD_YYYY_DASH_FORMAT = 'MM-DD-YYYY'
export const YYYY_MM_DD_DASH_FORMAT = 'YYYY-MM-DD'
export const MM_DD_FORMAT = 'MM/DD'
export const DDDD_MM_DD_YYY = 'dddd. MM/DD/YYYY'
export const DAY_MM_DD_YYY = 'dddd, MM/DD/YYYY'
export const YYY_MM_DD_AT_TIME = 'YYYY-MM-DD [at] hh:mm A'
export const DDDD_MMM_DD_YYYY = 'dddd, MMM DD, YYYY'
export const DDDD_MMM_DD_YYYY_AT_TIME = 'dddd, MMM DD, YYYY [at] hh:mm A'
export const MMMM_DD_YYY = 'LL'
export const DD_MM_YYYY_AT_TIME = 'DD/MM/YYYY [at] hh:mm A'

export const DEFAULT_TIME_FORMAT = 'h:mm A'
