/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { SimpleBox } from '@xplorie/ui-commons'
import { formatCostValue } from 'helpers/formHelpers'
import styles from './RateBox.scss'
import { RateItem } from '../RateItem'
import { END_DATE_LABEL, NEW_RATE_LABEL, START_DATE_LABEL, REASON_LABEL } from '../../types'

function RateBox(props) {
  const { item } = props

  return (
    <SimpleBox className={styles.box}>
      <div className={styles.rows}>
        <div className={styles.column}>
          <div className={styles.row}>
            <RateItem label={NEW_RATE_LABEL} value={`$${formatCostValue(item.value)}`} />
          </div>
          <div className={styles.row}>
            <RateItem label={START_DATE_LABEL} value={item.startDate} />
            <RateItem label={END_DATE_LABEL} value={item.endDate} />
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.reasonField}>
            <RateItem label={REASON_LABEL} value={item.reason} />
          </div>
        </div>
      </div>
    </SimpleBox>
  )
}

RateBox.propTypes = {
  item: PropTypes.object.isRequired
}

export default RateBox
