/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  deactivatePMCById as deactivatePMCRequest,
  activatePMCById as activatePMCRequest,
  removePMCDeactivatingById,
  updateDeactivationPMCById
} from 'services/http/pmc'

import helpers from './helpers'

const {
  REQUEST_PMC_DEACTIVATION,
  RESPONSE_PMC_DEACTIVATION,
  RESPONSE_ERROR_PMC_DEACTIVATION,
  REQUEST_PMC_ACTIVATION,
  RESPONSE_PMC_ACTIVATION,
  RESPONSE_ERROR_PMC_ACTIVATION,
  RESET_DATA_STATE,
  ACTIVATION_SUCCESS_MESSAGE,
  buildSuccessDeactivatingMessage,
  buildSuccessCancelDeactivatingMessage
} = helpers

const deactivatePmcById = (pmcId, options = {}) => async dispatch => {
  const { prevDate, currentDate, date, isInactive = false } = options
  const key = pmcId
  dispatch(actionCreater(REQUEST_PMC_DEACTIVATION, { key }))
  const deactivateRequest = isInactive ? updateDeactivationPMCById : deactivatePMCRequest
  const request = await deactivateRequest(pmcId, date).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_PMC_DEACTIVATION, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_PMC_DEACTIVATION, { key }))
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildSuccessDeactivatingMessage(prevDate, currentDate) }))
    return 'deactivatePmcById success'
  }

  return 'deactivatePmcById failed'
}

const activatePmcById = (pmcId, options) => async dispatch => {
  const key = pmcId
  dispatch(actionCreater(REQUEST_PMC_ACTIVATION, { key }))
  const request = await activatePMCRequest(pmcId, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_PMC_ACTIVATION, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_PMC_ACTIVATION, { key }))
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: ACTIVATION_SUCCESS_MESSAGE }))
    return 'activatePmcById success'
  }

  return 'activatePmcById failed'
}

const deletePmcDelayDeactivate = (pmcId, options) => async dispatch => {
  const key = pmcId
  dispatch(actionCreater(REQUEST_PMC_DEACTIVATION, { key }))
  const request = await removePMCDeactivatingById(pmcId, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_PMC_DEACTIVATION, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_PMC_DEACTIVATION, { key }))
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildSuccessCancelDeactivatingMessage() }))
    return 'deletePmcDelayDeactivate success'
  }

  return 'deletePmcDelayDeactivate failed'
}

const resetState = pmcId => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key: pmcId }))

export default {
  deactivatePmcById,
  activatePmcById,
  resetState,
  deletePmcDelayDeactivate
}
