/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildListParams } from 'services/http/helpers'
import {
  buildTicketGroupsListURL,
  buildInventoryURLByGroupId,
  buildAssignGroupsOrderURL,
  buildGroupBarcodesURL
} from './helpers'

export const getList = (activityId, options) =>
  httpWrapper({
    url: buildTicketGroupsListURL(activityId, options),
    method: 'get',
    params: buildListParams(options)
  })

export const getTicketsList = (activityId, groupId, options) =>
  httpWrapper({
    url: buildInventoryURLByGroupId(activityId, groupId, options),
    method: 'get',
    params: buildListParams(options)
  })

export const assignGroupsOrder = (activityId, contractData) =>
  httpWrapper({
    url: buildAssignGroupsOrderURL(activityId),
    method: 'put',
    data: contractData
  })

export const regenerateGroupBarcodes = activityId =>
  httpWrapper({
    url: buildGroupBarcodesURL(activityId),
    method: 'put'
  })
