/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */
import loadable from '@loadable/component'

const SeasonFormConnect = loadable(() =>
  import(/* webpackChunkName: 'SeasonForm', webpackPrefetch: true */ './container')
)

export default SeasonFormConnect
