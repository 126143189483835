/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { LIST_NAME, ROLE_PROP_NAME } from './types'

// TODO delete PREDEFINED after custom roles deployment
const REQUEST_PREDEFINED_LIST = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_PREDEFINED_LIST = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_PREDEFINED_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`

const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

const REQUEST_ROLE = `${REQUEST_TYPE}_${ROLE_PROP_NAME}`
const RESPONSE_ROLE = `${RESPONSE_TYPE}_${ROLE_PROP_NAME}`
const RESPONSE_ROLE_ERROR = `${RESPONSE_ERROR_TYPE}_${ROLE_PROP_NAME}`

export default {
  REQUEST_PREDEFINED_LIST,
  RESPONSE_PREDEFINED_LIST,
  RESPONSE_PREDEFINED_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_ROLE,
  RESPONSE_ROLE,
  RESPONSE_ROLE_ERROR
}
