/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, YELLOW_GREEN_SCHEMA, YES_TITLE, NO_TITLE } from 'constants/buttonsOptions'
import { WARNING_MESSAGE } from './types'
import styles from './styles.scss'

export function DeactivateWarning({ name, onClose, onAction }) {
  return (
    <div className={styles.bodyWrapper}>
      <span className={styles.message}>
        <span className={styles.text}>{WARNING_MESSAGE}</span>
        {name && (
          <span className={classNames(styles.text, styles.bold, styles.question)}>{name}</span>
        )}
      </span>
      <div className={styles.buttons}>
        <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onAction}>
          {YES_TITLE}
        </BasicButton>
        <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
          {NO_TITLE}
        </BasicButton>
      </div>
    </div>
  )
}
DeactivateWarning.propTypes = {
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired
}
