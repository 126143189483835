/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { RESET_SELECT_OPTION } from './types'

export function buildOptions(data = []) {
  const buildedList = data.map(({ id, name }) => ({ key: id, label: name, value: { id, name } }))
  return [RESET_SELECT_OPTION, ...buildedList]
}
