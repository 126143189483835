/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import moment from 'moment'
import { FORMAT } from 'constants/date'
import get from 'lodash/get'

export const processHolidays = (years = {}) =>
  Object.values(years).reduce(
    (holidays, { data }) =>
      holidays.concat(
        data.map(value => {
          const { year, month, day } = value.date
          return moment({ year, day, month: month - 1 }).toDate()
        })
      ),
    []
  )

export const findHolidayDescription = (years = {}, date) => {
  const dateValue = moment(date)
  const holidays = get(years, `${dateValue.year()}.data`, [])
  return (
    holidays.find(value => {
      const { year, month, day } = value.date
      return moment({ year, day, month: month - 1 }).format(FORMAT) === dateValue.format(FORMAT)
    }) || {}
  )
}

export const processShownValue = (
  fieldValue = {},
  { enableHoliday = true, dateFormat = FORMAT, shownFormat = FORMAT }
) => {
  if (!fieldValue || !fieldValue.date) {
    return ''
  }
  const dateValue = moment(fieldValue.date, dateFormat, true)
  if (!dateValue.isValid()) {
    return ''
  }
  return enableHoliday && fieldValue.description
    ? fieldValue.description
    : dateValue.format(shownFormat)
}
