/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import {
  getLocationTree as getLocationTreeRequest,
  deleteLocationById,
  moveChildLocation
} from 'services/http/locations'
import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_LIST_ERROR,
  RESET_DATA_STATE,
  UPDATE_TREE_STATE,
  REQUEST_REMOVE_LOCATION,
  RESPONSE_REMOVE_LOCATION,
  RESPONSE_REMOVE_LOCATION_ERROR,
  REQUEST_MOVE_LOCATION,
  RESPONSE_MOVE_LOCATION,
  RESPONSE_MOVE_LOCATION_ERROR,
  buildIdForGlobalTree
} = helpers

const getLocationTree = (authKey, options) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await getLocationTreeRequest(authKey, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_LIST_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, request.data))
    return 'getLocationTree success'
  }

  return 'getLocationTree failed'
}

const updateTreeState = (data = {}) => dispatch => {
  dispatch(actionCreater(UPDATE_TREE_STATE, data))
}

const removeLocation = locationId => async dispatch => {
  dispatch(actionCreater(REQUEST_REMOVE_LOCATION))
  const request = await deleteLocationById(locationId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_REMOVE_LOCATION_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_REMOVE_LOCATION))
    return 'removeLocation success'
  }

  return 'removeLocation failed'
}

const moveLocation = ({ childId, parentId, oldParentId, targetParentId }) => async dispatch => {
  dispatch(actionCreater(REQUEST_MOVE_LOCATION))
  const request = await moveChildLocation(childId, parentId, oldParentId).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_MOVE_LOCATION_ERROR))
    }
  )
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_MOVE_LOCATION))
    dispatch(
      updateTreeState({ id: buildIdForGlobalTree(parentId, targetParentId), isCollapsed: true })
    )
    return 'moveLocation success'
  }

  return 'moveLocation failed'
}

const resetList = () => ({
  type: RESET_DATA_STATE
})
export default {
  getLocationTree,
  updateTreeState,
  resetList,
  removeLocation,
  moveLocation
}
