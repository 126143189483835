/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useDidMount, useCounter } from 'hooks'
import { Badge } from 'components/Basic/Badge/Badge'

import styles from './Nav.scss'

export function NavItemMenu(props) {
  const {
    id,
    navPath,
    navItemText,
    navItemBehavior,
    NavIcon,
    onClick,
    className,
    activeClassName,
    component,
    updateNavigationType,
    accessLevels,
    logOut,
    authUser,
    navExternal,
    counterType,
    ...rest
  } = props

  const onClickHandler = useCallback(
    event => {
      if (onClick) {
        onClick(event, { authUser, logOut })
      }
    },
    [authUser, logOut, onClick]
  )

  const { getCount, count } = useCounter(counterType)
  const Component = component || (navExternal ? 'a' : NavLink)
  const navProps = navExternal
    ? {
        href: navPath
      }
    : {
        ...rest,
        to: navPath,
        activeClassName: classNames({
          [styles.activeItem]: activeClassName
        }),
        onClick: onClick && onClickHandler
      }

  useDidMount(() => {
    if (counterType) {
      getCount()
    }
  })

  return (
    <Component {...navProps} id={id} className={classNames(styles.link, className)}>
      <div
        className={classNames({
          [styles.navlabel]: navItemBehavior
        })}
      >
        {navItemText}
      </div>
      {NavIcon && (
        <div className={styles.svgwrapper}>
          <NavIcon className={styles.navicon} />
          {Boolean(count) && <Badge value={count} className={styles.badge} />}
        </div>
      )}
    </Component>
  )
}

NavItemMenu.propTypes = {
  id: PropTypes.string.isRequired,
  navPath: PropTypes.string.isRequired,
  navItemText: PropTypes.string.isRequired,
  navItemBehavior: PropTypes.bool,
  navExternal: PropTypes.bool,
  NavIcon: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  component: PropTypes.node,
  updateNavigationType: PropTypes.func,
  accessLevels: PropTypes.array,
  logOut: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired,
  counterType: PropTypes.string
}

NavItemMenu.defaultProps = {
  navItemBehavior: null,
  NavIcon: null,
  onClick: () => {},
  className: null,
  activeClassName: true,
  navExternal: false,
  component: null,
  updateNavigationType: () => null,
  accessLevels: [],
  counterType: ''
}
