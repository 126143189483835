/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const FORM_NAME = 'ACTIVITY_PRODUCT_FORM'
export const GET_DATA_BY_ID = 'ACTIVITY_PRODUCT_ENTITY_BY_ID'
export const SWITCH_STATUS = `${FORM_NAME}_SWITCH_STATUS`

export default {
  FORM_NAME,
  GET_DATA_BY_ID,
  SWITCH_STATUS
}
