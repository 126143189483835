import { connect } from 'react-redux'

import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/account/accountPasswordRecovery'
import { PasswordRecovery as PasswordRecoveryComponent } from './PasswordRecovery'

const mapStateToProps = state => ({
  recoveryTokenDataState: state.account.accountPasswordRecovery.recoveryTokenDataState,
  recoveryPasswordDataState: state.account.accountPasswordRecovery.recoveryPasswordDataState,
  tokenError: state.account.accountPasswordRecovery.tokenError,
})

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

export const PasswordRecovery = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PasswordRecoveryComponent)
