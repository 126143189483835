/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { SimpleBox, BasicButton } from '@xplorie/ui-commons'
import { TicketGroupModal } from 'modules/ActivityProducts/ActivityResaleTab'
import { ORANGE_SCHEMA, EDIT_TITLE, SMALLER_SIZE } from 'constants/buttonsOptions'
import { IN_STOCK_MESSAGE } from './types'
import styles from './TicketGroupItem.scss'

export function TicketGroupItem(props) {
  const { id, totalTickets, name, enqueue, disabled, showButtons } = props
  const onEditHandler = () => {
    enqueue(TicketGroupModal, { id, dataId: id })
  }

  return (
    <SimpleBox className={styles.wrapper}>
      <div className={styles.buttons}>
        {showButtons && (
          <BasicButton
            colorSchema={ORANGE_SCHEMA}
            size={SMALLER_SIZE}
            onClick={onEditHandler}
            disabled={disabled}
          >
            {EDIT_TITLE}
          </BasicButton>
        )}
      </div>
      <div className={styles.infoBlock}>
        <h1 className={styles.mainText}>{name}</h1>
        <h3 className={styles.number}>{totalTickets}</h3>
        <small className={styles.small}>{IN_STOCK_MESSAGE}</small>
      </div>
    </SimpleBox>
  )
}

TicketGroupItem.propTypes = {
  id: PropTypes.string,
  totalTickets: PropTypes.number,
  name: PropTypes.string,
  enqueue: PropTypes.func,
  disabled: PropTypes.bool,
  showButtons: PropTypes.bool.isRequired
}

TicketGroupItem.defaultProps = {
  id: null,
  totalTickets: 0,
  name: null,
  enqueue: null,
  disabled: false
}
