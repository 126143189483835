/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { SYS_ADMIN_LEVEL } from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import { BasicButton } from '@xplorie/ui-commons'
import { PIECurtainLoader } from 'components/Loaders'
import { useLoading } from 'hooks'

export function CheckTicketInventory(props) {
  const { children, authUser, dataState, checkInventoryTicket } = props
  const isSysAdmin = isActionAllowed(SYS_ADMIN_LEVEL, authUser)
  const [isLoading] = useLoading(dataState)
  const onClickHandler = useCallback(() => {
    if (!isSysAdmin) {
      return
    }

    checkInventoryTicket()
  }, [isSysAdmin, checkInventoryTicket])

  if (!isSysAdmin) {
    return null
  }

  return (
    <>
      {isLoading && <PIECurtainLoader />}
      <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onClickHandler}>
        {children}
      </BasicButton>
    </>
  )
}

CheckTicketInventory.propTypes = {
  authUser: PropTypes.object.isRequired,
  dataState: PropTypes.object.isRequired,
  checkInventoryTicket: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}
