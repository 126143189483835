/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { SimpleTable } from 'components/Tables'
import { HEADER } from './types'
import { buildTableColumns } from './helpers'

export function ActivityTagsList(props) {
  const {
    dataState,
    itemsCount,
    itemsPerPage,
    data,
    onGetData,
    needUpdate,
    authUser,
    TableComponent,
    formDataState,
    removeDataState,
    ...rest
  } = props

  return (
    <TableComponent
      {...rest}
      dataState={dataState}
      columns={buildTableColumns(props, authUser)}
      onGetData={onGetData}
      itemsPerPage={itemsPerPage}
      itemsCount={itemsCount}
      tableHeader={HEADER}
      data={data}
      // TODO: Need remove it after refactoring.
      needUpdateList={needUpdate}
      dataStateWatchers={[formDataState, removeDataState]}
      withBasicAuth={false}
    />
  )
}

ActivityTagsList.defaultProps = {
  extendedOptions: {},
  needUpdate: false,
  TableComponent: SimpleTable,
  itemsPerPage: 10
}

ActivityTagsList.propTypes = {
  dataState: PropTypes.string.isRequired,
  formDataState: PropTypes.string.isRequired,
  removeDataState: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemsCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number,
  onGetData: PropTypes.func.isRequired,
  needUpdate: PropTypes.bool,
  authUser: PropTypes.object.isRequired,
  TableComponent: PropTypes.func
}
