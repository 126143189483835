/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import moment from 'moment'
import 'twix'
import { FORMAT } from 'constants/date'
import { SMARTHOST_STANDALONE } from 'constants/programTypes'
import { NOT_BE_BLANK_MESSAGE } from 'helpers/types/formHelpers'
import { SMARTHOST_FIELD_KEY } from 'modules/Programs/ProgramsForm/types'
import get from 'lodash/get'
import { isFutureSeason } from 'modules/Programs/ProgramSeasons/SeasonItem/helpers'
import { PROGRAM_TITLE, SEASONS_TITLE } from './types'
import { getLabelByFieldKey } from '../../ProgramsForm/helpers'

export const getEmptySeasons = () => {
  const currentYear = moment().year()
  const startDate = moment(new Date(currentYear, 0, 1)).format(FORMAT)
  const endDate = moment(new Date(currentYear, 11, 31)).format(FORMAT)
  return [
    {
      name: `Default Season ${currentYear}`,
      startDate,
      endDate
    }
  ]
}

export const buildSeasonsData = (seasons = []) =>
  seasons && seasons.length > 0
    ? seasons
        .filter(season => !season.programId || !season.id)
        .map(season => {
          const { activities, ...restSeason } = season
          return {
            ...restSeason,
            activities: activities.map(({ activity, ...restActivity }) => ({
              activityId: activity.id,
              ...restActivity
            }))
          }
        })
    : getEmptySeasons()

export const buildFinalProgramData = fieldValues => {
  const {
    draftIds,
    versionData,
    programCode,
    comments,
    seasons,
    seasonYears,
    ...rest
  } = fieldValues

  return rest
}

export const buildInvalidModalErrors = (
  programErrors = {},
  seasonsErrors = [],
  invalidSmartHost = false
) => {
  const output = {}

  if (!programErrors || !seasonsErrors) {
    return output
  }

  const programDataErrors = invalidSmartHost
    ? { ...programErrors, [SMARTHOST_FIELD_KEY]: NOT_BE_BLANK_MESSAGE }
    : programErrors

  if (Object.keys(programDataErrors).length) {
    output[PROGRAM_TITLE] = Object.keys(programDataErrors).map(key => ({
      field: getLabelByFieldKey(key),
      message: programDataErrors[key]
    }))
  }
  if (seasonsErrors.length) {
    output[SEASONS_TITLE] = seasonsErrors.reduce(
      (acc, value) => [
        ...acc,
        ...Object.keys(value)
          .reduce((internalAcc, errorKey) => [...internalAcc, value[errorKey]], [])
          .map(seasonError => ({ field: seasonError.meta.name, message: seasonError.message }))
      ],
      []
    )
  }

  return output
}

export const isValidSmartHostFee = (programType, fees) =>
  programType !== SMARTHOST_STANDALONE || (fees && fees.length)

export const hasFutureSeasons = seasons =>
  seasons.some(season => {
    const startDate = get(season, 'data.startDate')
    return isFutureSeason(startDate)
  })

export const hasSeasonsToUpdate = (seasons, isOnBoarded) =>
  isOnBoarded ? Boolean(seasons && seasons.length) : hasFutureSeasons(seasons)
