/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { NAME, DOWNLOAD, REMOVE, UPLOAD } = types

const REQUEST_DOWNLOAD = `${REQUEST_TYPE}_${DOWNLOAD}`
const RESPONSE_DOWNLOAD = `${RESPONSE_TYPE}_${DOWNLOAD}`
const RESPONSE_ERROR_DOWNLOAD = `${RESPONSE_ERROR_TYPE}_${DOWNLOAD}`

const REQUEST_REMOVE = `${REQUEST_TYPE}_${REMOVE}`
const RESPONSE_REMOVE = `${RESPONSE_TYPE}_${REMOVE}`
const RESPONSE_ERROR_REMOVE = `${RESPONSE_ERROR_TYPE}_${REMOVE}`

const REQUEST_UPLOAD = `${REQUEST_TYPE}_${UPLOAD}`
const RESPONSE_UPLOAD = `${RESPONSE_TYPE}_${UPLOAD}`
const RESPONSE_ERROR_UPLOAD = `${RESPONSE_ERROR_TYPE}_${UPLOAD}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

export default {
  REQUEST_DOWNLOAD,
  RESPONSE_DOWNLOAD,
  RESPONSE_ERROR_DOWNLOAD,
  REQUEST_REMOVE,
  RESPONSE_REMOVE,
  RESPONSE_ERROR_REMOVE,
  RESET_DATA_STATE,
  REQUEST_UPLOAD,
  RESPONSE_UPLOAD,
  RESPONSE_ERROR_UPLOAD
}
