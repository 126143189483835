/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  TICKET_TYPE_LABEL,
  RETAIL_PRICE_LABEL,
  DISCOUNT_LABEL,
  FREE_TICKETS_LABEL,
  FINAL_PRICE_LABEL
} from 'modules/Discounts/types'

export const INVALID_PRICE_MESSAGE = 'final price invalid'
export const INVALID_TICKET_TYPE_MESSAGE = 'Please include at least one ticket type'

export const tableHeadData = [
  TICKET_TYPE_LABEL,
  RETAIL_PRICE_LABEL,
  DISCOUNT_LABEL,
  FREE_TICKETS_LABEL,
  FINAL_PRICE_LABEL
]
