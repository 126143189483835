/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { SimpleModalWrapper } from 'components/SimpleModalWrapper'
import { SimpleBox } from '@xplorie/ui-commons'
import { CloseIcon } from 'icons'

import styles from './SliderModal.scss'

export function SliderModal(props) {
  const { cancelHandler, children, wrapperClassName, className, modalContentClassName } = props
  return (
    <SimpleModalWrapper
      classNameWrapper={classNames(styles.modalWrapper, wrapperClassName)}
      classNameContent={classNames(styles.modalContent, modalContentClassName)}
      cancelHandler={cancelHandler}
    >
      <SimpleBox className={classNames(styles.boxWrapper, className)}>
        <div className={styles.header}>
          <CloseIcon dynamicClassName={styles.closeIcon} dynamicEvent={cancelHandler} />
        </div>
        {children}
      </SimpleBox>
    </SimpleModalWrapper>
  )
}

SliderModal.propTypes = {
  cancelHandler: PropTypes.func.isRequired,
  children: PropTypes.any,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  modalContentClassName: PropTypes.string
}
SliderModal.defaultProps = {
  children: null,
  wrapperClassName: '',
  className: '',
  modalContentClassName: ''
}
