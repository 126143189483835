/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { searchByKeyword } from 'services/http/search'
import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_SEARCH,
  RESPONSE_SEARCH,
  RESPONSE_SEARCH_ERROR,
  RESET_DATA_TYPE_STATE
} = helpers

const searchByType = (searchKey, columns = [], params = {}) => async dispatch => {
  if (!searchKey) {
    throw new Error(`searchKey is requared param`)
  }
  const meta = { searchKey, timestamp: +new Date() }
  const actionParams = { key: searchKey, meta }
  dispatch(actionCreater(REQUEST_SEARCH, actionParams))
  try {
    const request = await searchByKeyword(columns, params)
    dispatch(actionCreater(RESPONSE_SEARCH, { ...request.data, ...actionParams }))
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_SEARCH_ERROR, actionParams))
  }
}

const resetState = () => ({
  type: RESET_DATA_STATE
})

const resetStateByType = type => ({
  type: RESET_DATA_TYPE_STATE,
  payload: type
})

export default {
  resetState,
  resetStateByType,
  searchByType
}
