/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { SimpleTable } from 'components/Tables'
import { buildTableColumns } from './helpers'
import { HEADER } from './types'

export function UnitsList(props) {
  const {
    dataState,
    onGetData,
    pageNumber,
    itemsPerPage,
    itemsCount,
    data,
    listWatchers,
    TableComponent,
    ...rest
  } = props

  return (
    <TableComponent
      dataState={dataState}
      columns={buildTableColumns()}
      onGetData={onGetData}
      itemsPerPage={itemsPerPage}
      itemsCount={itemsCount}
      pageNumber={pageNumber}
      tableHeader={HEADER}
      data={data}
      // TODO: Need adjust and refactor it with reimplement this functionality.
      withBasicAuth={false}
      needFirstGetData={false}
      //
      {...rest}
    />
  )
}

UnitsList.propTypes = {
  dataState: PropTypes.string.isRequired,
  onGetData: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  listWatchers: PropTypes.array.isRequired,
  TableComponent: PropTypes.node
}
UnitsList.defaultProps = {
  TableComponent: SimpleTable
}
