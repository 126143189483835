/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { DeleteEntityButton } from 'components/Buttons'
import { buildPermissions } from 'modules/Programs'
import ModalRemoval from 'modules/ModalRemoval'
import { ORANGE_SCHEMA, SMALLER_SIZE, DELETE_TITLE } from 'constants/buttonsOptions'

export function DeleteButton(props) {
  const { deleteProgram, authUser, data } = props
  const [showModal, setShowModalState] = useState(false)
  const id = get(data.original, 'id')
  const { canDelete } = buildPermissions({ authUser, data: data.original })

  const onShowModal = () => {
    setShowModalState(true)
  }

  const onCloseModal = () => {
    setShowModalState(false)
  }

  const onDeleteHandler = () => {
    deleteProgram(authUser, id)
    onCloseModal()
  }

  return (
    <>
      <DeleteEntityButton
        colorSchema={ORANGE_SCHEMA}
        size={SMALLER_SIZE}
        disabled={!canDelete}
        onClick={onShowModal}
      >
        {DELETE_TITLE}
      </DeleteEntityButton>
      <ModalRemoval
        actionCallback={onDeleteHandler}
        cancelCallback={onCloseModal}
        originalData={showModal}
      />
    </>
  )
}

DeleteButton.propTypes = {
  data: PropTypes.object,
  authUser: PropTypes.object,
  deleteProgram: PropTypes.func.isRequired
}
DeleteButton.defaultProps = {
  data: {},
  authUser: null
}
