/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { createContext } from 'react'
import { RATE_ADJUSTMENT_MODE } from 'constants/rateAdjustments'
import { RateListMode } from './RateListMode'
import { RateEditMode } from './RateEditMode'
import { RateAddMode } from './RateAddMode'

export const NEW_RATE_LABEL = 'New Rate:'
export const START_DATE_LABEL = 'Start:'
export const END_DATE_LABEL = 'End:'
export const REASON_LABEL = 'Reason for Adjustment:'

export const SAVE_LABEL = 'Save'
export const CANCEL_LABEL = 'Cancel'

export const ADD_RATE_LABEL = 'Add Adjustment Rate'
export const RATE_ADJUSTMENTS_TITLE = 'Rate Adjustments'

export const INVALID_RATE_FEE = 'Invalid Rate'

export const MIN_RATE_FEE = 0.01
export const MAX_RATE_FEE = 100000.0
export const MAX_DECIMAL_PLACES = 2
export const MAX_REASON_LENGTH = 140

export const REGEX_DECIMALS_VALIDATION_RATE_FEE = /^(\d{1,6}|100000)(\.\d{1,2})?$/

export const getRateOptions = (setAddMode, setEditMode) => [
  { label: 'Add', props: { onClick: setAddMode } },
  { label: 'Edit', props: { onClick: setEditMode } }
]

export const getComponentByMode = mode => {
  switch (mode) {
    case RATE_ADJUSTMENT_MODE.ADD:
      return RateAddMode
    case RATE_ADJUSTMENT_MODE.EDIT:
      return RateEditMode
    default:
      return RateListMode
  }
}

export const RateFormContext = createContext({
  rates: [],
  fromApi: {},
  setRates: () => null
})
