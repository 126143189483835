import {
  REQUEST_TYPE,
  RESET_STATE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE
} from 'helpers/actionHelpers'

import { FORM_NAME, PMC_ITEM } from './types'

const REQUEST_FORM = `${REQUEST_TYPE}_${FORM_NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${FORM_NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${FORM_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

const REQUEST_PMC_ITEM = `${REQUEST_TYPE}_${PMC_ITEM}`
const RESPONSE_PMC_ITEM = `${RESPONSE_TYPE}_${PMC_ITEM}`
const RESPONSE_PMC_ITEM_ERROR = `${RESPONSE_ERROR_TYPE}_${PMC_ITEM}`

export default {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_PMC_ITEM,
  RESPONSE_PMC_ITEM,
  RESPONSE_PMC_ITEM_ERROR
}
