/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { SimpleBox } from '@xplorie/ui-commons'
import { formatCostValue } from 'helpers/formHelpers'
import styles from './SmartHostFeeBox.scss'
import { SmartHostFeeItem } from '../SmartHostFeeItem'
import { END_DATE_LABEL, NO_END_DATE_VALUE, PER_DEVICE_FEE_LABEL, START_DATE_LABEL } from '../../types'

function SmartHostFeeBox(props) {
  const { item } = props;
  const value = item && item.fee;
  const startDate = item && item.period && item.period.startDate;
  const endDate = item && item.period && item.period.endDate;

  return (
    <SimpleBox className={styles.box}>
      <div className={styles.rows}>
        <div className={styles.row}>
          <SmartHostFeeItem label={PER_DEVICE_FEE_LABEL} value={`$${formatCostValue(value)}`} />
        </div>
        <div className={styles.row}>
          <SmartHostFeeItem label={START_DATE_LABEL} value={startDate} />
          {endDate !== NO_END_DATE_VALUE && <SmartHostFeeItem label={END_DATE_LABEL} value={endDate} />}
        </div>
      </div>
    </SimpleBox>
  )
}

SmartHostFeeBox.propTypes = {
  item: PropTypes.object.isRequired
}

export default SmartHostFeeBox
