/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CollapseWrapper } from 'components/Wrappers'
import { ArrowDownIcon } from 'icons'

import styles from './CollapseContent.scss'

export function CollapseContent(props) {
  const { children, title, onChange, ...rest } = props
  const [isOpen, setIsOpenState] = useState(false)

  const onChangeCollapse = useCallback(
    value => {
      setIsOpenState(value)
      if (onChange) {
        onChange(value)
      }
    },
    [onChange, setIsOpenState]
  )

  return (
    <CollapseWrapper
      wrapperClassName={classNames(styles.wrapper, { [styles.isClosed]: !isOpen })}
      headerClassName={classNames(styles.header)}
      contentClassName={classNames(styles.content, { [styles.isClosed]: !isOpen })}
      renderHeader={() => (
        <div className={styles.titleWrapper}>
          <span>{title} </span>
          <ArrowDownIcon className={styles.icon} />
        </div>
      )}
      onChange={onChangeCollapse}
      {...rest}
    >
      {children}
    </CollapseWrapper>
  )
}
CollapseContent.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func
}
CollapseContent.defaultProps = {
  onChange: () => null
}
