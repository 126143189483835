/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import { RATE_ADJUSTMENT_MODE } from 'constants/rateAdjustments'
import {
  REQUEST_LIST,
  RESPONSE_LIST_ERROR,
  RESPONSE_LIST,
  RESET_DATA_STATE,
  RATE_MODE
} from './helpers'

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  mode: RATE_ADJUSTMENT_MODE.LIST,
  rates: []
}

const seasonFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_LIST_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case RESPONSE_LIST:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        rates: action.payload.rates
      }
    case RATE_MODE:
      return {
        ...state,
        mode: action.payload.mode
      }
    case RESET_DATA_STATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default filterActions(seasonFormReducer, [
  REQUEST_LIST,
  RESPONSE_LIST_ERROR,
  RESPONSE_LIST,
  RESET_DATA_STATE,
  RATE_MODE
])
