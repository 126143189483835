/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { dateValidate } from 'helpers/formHelpers'
import moment from 'moment'
import { FORMAT } from 'constants/date'
import {
  SEPARATOR,
  START_DATE_REQUARED_MESSAGE,
  END_DATE_REQUARED_MESSAGE,
  INCORRECT_RANGE_MESSAGE
} from './types'

export function getStartDateFromValue(value) {
  return (value && String(value).split(SEPARATOR)[0]) || ''
}

export function getEndDateFromValue(value) {
  return (value && String(value).split(SEPARATOR)[1]) || ''
}

export function rangeValidation(value) {
  const startDate = getStartDateFromValue(value)
  const endDate = getEndDateFromValue(value)
  if (!startDate) {
    return START_DATE_REQUARED_MESSAGE
  }
  if (!endDate) {
    return END_DATE_REQUARED_MESSAGE
  }
  const startNotDateError = dateValidate(startDate)
  const endNotDateError = dateValidate(endDate)
  if (startNotDateError) {
    return startNotDateError
  }
  if (endNotDateError) {
    return endNotDateError
  }

  return moment(startDate, FORMAT).isSameOrAfter(moment(endDate, FORMAT))
    ? INCORRECT_RANGE_MESSAGE
    : null
}

export function buildValue(startDate, endDate) {
  return `${startDate}${SEPARATOR}${endDate}`
}

export function getDateRange(value) {
  const start = getStartDateFromValue(value)
  const end = getEndDateFromValue(value)

  return { start, end }
}
