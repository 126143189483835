/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { DeleteMinusIcon, GearIcon } from 'icons'
import { Form } from 'informed'
import { SubmitButton } from 'components/Buttons'
import { BasicButton } from '@xplorie/ui-commons'
import {
  ORANGE_SCHEMA,
  YELLOW_GREEN_SCHEMA,
  YES_TITLE,
  NO_TITLE,
  SMALLER_SIZE,
  SAVE_TITLE
} from 'constants/buttonsOptions'
import { useLoadingDataState } from 'hooks'
import { BasicModal } from 'components/Modals'
import { FormContent, SelectField } from 'components/FormComponents'
import { withModalWindow } from 'decorators'
import {
  DELETE_MODAL_TITLE,
  DELETE_ACTIVITY_MESSAGE,
  SELECT_GROUP_PLACEHOLDER,
  dealGroupsTypeOptions
} from 'modules/Discounts/types'

import { ActivityTable } from '../../ActivityTable'

import styles from '../GroupsContent.scss'

const DeleteMinusIconWithModal = withModalWindow(DeleteMinusIcon)

export function ActivityForm(props) {
  const {
    data,
    updateActivityType,
    updateDataState,
    updateTypeDataState,
    deleteDataState,
    updateActivity,
    deleteActivity,
    updateEditMode,
    editMode,
    isEditAllowed
  } = props
  const formApi = useRef(null)

  const [isTypeUpdating] = useLoadingDataState(updateTypeDataState)
  const [isDeleting] = useLoadingDataState(deleteDataState)
  const [isUpdating] = useLoadingDataState(updateDataState)

  const isFormDisabled = [!isEditAllowed, isTypeUpdating, isDeleting, isUpdating].some(value =>
    Boolean(value)
  )

  const onToggleEditMode = useCallback(() => {
    if (!isFormDisabled) {
      updateEditMode(!editMode)
    }
  }, [isFormDisabled, updateEditMode, editMode])

  const onGetApi = useCallback(
    api => {
      formApi.current = api
    },
    [formApi]
  )

  const onSaveActivity = useCallback(
    formData => {
      if (!isFormDisabled) {
        updateActivity(formData)
      }
    },
    [isFormDisabled, updateActivity]
  )

  const onDeleteActivity = useCallback(() => {
    if (!isFormDisabled) {
      deleteActivity()
    }
  }, [isFormDisabled, deleteActivity])

  const onChangeActivityType = useCallback(() => {
    if (!isFormDisabled) {
      updateActivityType(formApi.current.getState().values)
    }
  }, [isFormDisabled, updateActivityType, formApi])

  useEffect(() => {
    if (data) {
      formApi.current.setValues({ ...data })
    }
  }, [data])

  return (
    <Form getApi={onGetApi} onSubmit={onSaveActivity}>
      <FormContent disabled={isFormDisabled}>
        <ActivityTable
          editMode={editMode}
          renderControl={() =>
            isEditAllowed ? (
              <div className={styles.control}>
                <SelectField
                  fieldName="type"
                  options={dealGroupsTypeOptions}
                  fieldProps={{
                    classNameWrapper: styles.activityTypeWrapper,
                    isRequired: false,
                    error: null
                  }}
                  className={styles.select}
                  placeholder={SELECT_GROUP_PLACEHOLDER}
                  onSelectHandler={onChangeActivityType}
                  isRequired
                />
                {editMode ? (
                  <SubmitButton
                    size={SMALLER_SIZE}
                    colorSchema={YELLOW_GREEN_SCHEMA}
                    className={styles.saveButton}
                  >
                    {SAVE_TITLE}
                  </SubmitButton>
                ) : (
                  <div
                    className={classNames(styles.controlButtonsWrapper, {
                      [styles.disabled]: isFormDisabled
                    })}
                  >
                    <GearIcon className={classNames(styles.icon)} onClick={onToggleEditMode} />
                    <DeleteMinusIconWithModal
                      className={classNames(styles.icon)}
                      onAction={onDeleteActivity}
                      isDisabledModal={isFormDisabled}
                      renderModal={({ isShown, onConfirm, onHide }) => (
                        <BasicModal
                          isOpen={isShown}
                          onClose={onHide}
                          message={DELETE_ACTIVITY_MESSAGE}
                          title={DELETE_MODAL_TITLE}
                          wrapperClassName={styles.deleteAppModalWrapper}
                        >
                          <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onConfirm}>
                            {YES_TITLE}
                          </BasicButton>
                          <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onHide}>
                            {NO_TITLE}
                          </BasicButton>
                        </BasicModal>
                      )}
                    />
                  </div>
                )}
              </div>
            ) : null
          }
        />
      </FormContent>
    </Form>
  )
}

ActivityForm.propTypes = {
  data: PropTypes.array,
  updateActivityType: PropTypes.func.isRequired,
  updateTypeDataState: PropTypes.string.isRequired,
  deleteActivity: PropTypes.func.isRequired,
  updateActivity: PropTypes.func.isRequired,
  updateDataState: PropTypes.string.isRequired,
  deleteDataState: PropTypes.string.isRequired,
  updateEditMode: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  isEditAllowed: PropTypes.bool.isRequired
}
ActivityForm.defaultProps = {
  data: []
}
