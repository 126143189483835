/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { BeforeCloseModal } from 'modules/Modals'
import { MarketingMaterialsForm } from 'modules/Programs'
import { BouncingLoader } from 'components/Loaders'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { useDidMount, useLoading, useDataStatesComparison } from 'hooks'

export function MarketingTab(props) {
  const { dataId, dataState, slideEvents, closeFormDataStates, onClose, getMaterialsById } = props
  const [, { isLoaded, isError }] = useLoading(dataState)
  const showLoading = dataId && (!isLoaded && !isError)
  const beforeCloseModalApi = useRef()
  const [showBeforeCloseModal, setShowBeforeCloseModalState] = useState(false)

  const onChangeHandler = useCallback(formState => {
    const isTouched = Object.keys(formState.touched).length > 0
    setShowBeforeCloseModalState(isTouched)
  }, [])

  const onGetModalApi = useCallback(api => {
    beforeCloseModalApi.current = api
  }, [])

  slideEvents.onBeforeClose = useCallback(
    () => (showBeforeCloseModal ? beforeCloseModalApi.current.open() : !showBeforeCloseModal),
    [showBeforeCloseModal]
  )

  useDidMount(() => {
    if (dataId) {
      getMaterialsById(dataId)
    }
  })

  /* watch by the dataStates from redux state
     and close slider if neded */
  useDataStatesComparison(
    () => {
      onClose()
    },
    closeFormDataStates,
    DATA_STATE_RECIEVED
  )

  if (showLoading) {
    return <BouncingLoader />
  }

  return (
    <>
      <MarketingMaterialsForm dataId={dataId} onChange={onChangeHandler} onClose={onClose} />
      <BeforeCloseModal getApi={onGetModalApi} />
    </>
  )
}
MarketingTab.propTypes = {
  dataId: PropTypes.string,
  dataState: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  getMaterialsById: PropTypes.func.isRequired,
  slideEvents: PropTypes.object.isRequired,
  closeFormDataStates: PropTypes.array.isRequired
}
MarketingTab.defaultProps = {
  dataId: null
}
