/* eslint-disable max-lines */
/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'

export const buildMenuPosition = (pageX, pageY) => {
  if (typeof document !== 'undefined') {
    const blockHeight = 150
    const windowVisibleHeight = get(document, 'documentElement.clientHeight')
    const windowScroll = get(window, 'pageYOffset')
    const positionY =
      windowVisibleHeight + windowScroll < pageY + blockHeight ? pageY - blockHeight : pageY

    return { top: `${positionY}px`, left: `${pageX}px` }
  }
  return null
}
