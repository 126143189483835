/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function LockIcon(props) {
  return (
    <svg viewBox="0 0 600 600" {...props}>
      <path
        d="M195.4,246.2l-0.1-79.6c0-54.8,44.5-99.4,99.3-99.4c54.8,0,99.4,44.6,99.4,99.3l0.1,79.6L195.4,246.2z
	 M468.1,246.1h-16l-0.1-79.6C452,79.6,381.4,9.1,294.6,9.1c-86.8,0.1-157.4,70.6-157.3,157.4l0.1,79.6h-5.7
	c-29.8,0.1-54,24.3-54,54.1v237.1c0,29.8,24.3,54,54.1,54l336.3-0.2c29.8,0,54-24.2,54-54V300C522.2,270.2,497.9,246.1,468.1,246.1
	L468.1,246.1z"
      />
    </svg>
  )
}
