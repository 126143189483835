/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildListParams } from 'services/http/helpers'
import { ACTIVITY_URL, SEARCH_URL } from 'endpoints'
import { buildRequestParams } from 'helpers/searchParamsHelper'

export const searchByColumns = (contractData, options) => {
  const params = buildRequestParams(buildListParams(options))

  return httpWrapper({
    url: `${SEARCH_URL}${params}`,
    method: 'post',
    data: contractData,
  })
}

export const searchProgramsByAppId = (authKey, appId, options, data) =>
  httpWrapper({
    url: `${ACTIVITY_URL}/${appId}/programs`,
    method: 'post',
    data,
    params: buildListParams(options)
  })
