/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions as programsFormActions, getEmptyProgramDataState } from 'reducers/programsForm'
import { useLoading } from 'hooks'
import { PROGRAM } from 'constants/formCodes'
import { PromptModal } from 'modules/Modals'
import { useSliderApi } from './useSliderApi'
import { useRootModal } from './useRootModal'

const { createEmptyProgram } = programsFormActions

export const useProgramSlider = () => {
  const programSliderApi = useSliderApi(PROGRAM)
  const rootModal = useRootModal()
  const dispatchAction = useDispatch()
  const createProgramDataState = useSelector(state => getEmptyProgramDataState(state, null))
  const [isCreating] = useLoading(createProgramDataState)

  const createEmptyProgramRequest = useCallback(
    programName => dispatchAction(createEmptyProgram(programName)),
    [dispatchAction]
  )

  const onCreateProgram = useCallback(
    params => programName => {
      if (programName) {
        createEmptyProgramRequest(programName).then(programId =>
          programSliderApi.open(programId, { headerProps: { title: programName }, ...params })
        )
      }
    },
    [createEmptyProgramRequest, programSliderApi]
  )

  const onOpenHandler = useCallback(
    /**
     * @param {string} id
     * @param {object} params slider params
     */
    (id, params = {}) => {
      if (id) {
        programSliderApi.open(id, params)
      } else {
        rootModal.enqueue(PromptModal, {
          title: 'Enter Program Name',
          placeholder: 'Program Name',
          onClose: rootModal.dequeue,
          onSubmit: onCreateProgram(params)
        })
      }
    },
    [onCreateProgram, programSliderApi, rootModal]
  )

  return { ...programSliderApi, open: onOpenHandler, isCreating }
}
