/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const SEPARATOR = '|'

export const START_NUMBER_REQUARED_MESSAGE = 'Start Number is Requared'
export const END_NUMBER_REQUARED_MESSAGE = 'End Number is Requared'

export const START_END_RANGE_MESSAGE = 'Start Number should be less then End Number'
