/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import map from 'lodash/map'
import reduce from 'lodash/reduce'
import { isSeasonEvent } from '../helpers'

export function createHistoryItem(data, parent) {
  const { id, timestamp, vacationSpecialist, event } = parent
  const { data: details, ...rest } = data

  return {
    ...rest,
    id,
    details,
    event,
    vacationSpecialist,
    timestamp
  }
}

export function convertHistoryData(data) {
  return reduce(
    data,
    (acc, item) => {
      const { event, details } = item

      if (isSeasonEvent(event)) {
        return [...acc, ...map(details, detail => createHistoryItem(detail, item))]
      }

      return [...acc, item]
    },
    []
  )
}
