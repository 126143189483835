/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const ADMIN_BASE_ROUTE = '/admin'

export const PROGRAMS_PATH = `${ADMIN_BASE_ROUTE}/programs`
export const SEARCH_PATH_NAME = `${ADMIN_BASE_ROUTE}/search`
export const APP_PROGRAMS_PATH = `${PROGRAMS_PATH}/app`
export const PREVIOUS_PROGRAMS_PATH = `${PROGRAMS_PATH}/previous`
export const PROGRAMS_BY_PMC_PATH = `${PROGRAMS_PATH}/pmc/`
export const PROGRAMS_BY_MARKET_PATH = `${PROGRAMS_PATH}/market/`
export const PROGRAMS_BY_LOCATION_PATH = `${PROGRAMS_PATH}/location/`

export const ACTIVITY_PRODUCTS_PATH = `${ADMIN_BASE_ROUTE}/activity-products`
export const ACTIVITY_PROVIDER_PATH = `${ADMIN_BASE_ROUTE}/activity-provider`
export const CAMPAIGNS_PATH = `${ADMIN_BASE_ROUTE}/campaigns`
export const APS_BY_ACTIVITY_CATEGORY_PATH = `${ACTIVITY_PROVIDER_PATH}/activity-category/`
export const APPS_BY_MARKET_PATH = `${ACTIVITY_PRODUCTS_PATH}/market/`
export const APPS_BY_AP_PATH = `${ACTIVITY_PRODUCTS_PATH}/activity-provider/`
export const APPS_BY_ACTIVITY_CATEGORY_PATH = `${ACTIVITY_PRODUCTS_PATH}/activity-category/`
export const APPS_BY_ACTIVITY_TAG_PATH = `${ACTIVITY_PRODUCTS_PATH}/activity-tag/`
export const APS_BY_ACTIVITY_TAG_PATH = `${ACTIVITY_PROVIDER_PATH}/activity-tag/`
export const MY_WORK_PATH = `${ADMIN_BASE_ROUTE}/my-work`

export const PROGRAMS_BY_DESTINATION_PATH = `${PROGRAMS_PATH}/destination/`
export const APPS_BY_DESTINATION_PATH = `${ACTIVITY_PRODUCTS_PATH}/destination/`
export const PROGRAMS_BY_PMC_DESTINATION_PATH = `${PROGRAMS_PATH}/pmc/destination/`
