/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { TagInputField as TagInputComponent } from './TagInput'

export function TagInputField(props) {
  const { fieldName, validate, ...rest } = props

  return <TagInputComponent field={fieldName} validator={validate} {...rest} />
}

TagInputField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.func
}

TagInputField.defaultProps = {
  validate: () => null
}
