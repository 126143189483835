/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const FORM_NAME = 'ACTIVITY_PROVIDER_FORM'
export const ENTITY_DATA = 'ACTIVITY_PROVIDER_ENTITY_DATA'

export default { FORM_NAME, ENTITY_DATA }
