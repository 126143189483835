/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'informed'

import { useUpdateSomeDataState } from 'hooks'
import get from 'lodash/get'
import { DATA_STATE_RECIEVED, DATA_STATE_REQUESTING, DATA_STATE_ERROR } from 'helpers/actionHelpers'
import { canUpdatePMSForm } from 'modules/PMS'

import { InnerContent } from './InnerContent'

import { onSubmit } from './helpers'
import {
  PREBOOK_FIELD,
  GUEST_API_FIELD,
  LISTING_API_FIELD,
  COMPANY_ID_ENABLED_FIELD
} from './types'

export function PMSForm(props) {
  const {
    dataId,
    onError,
    dataState,
    entityDataState,
    entityData,
    onSetHeaderProps,
    authUser,
    onClose,
    formDataState
  } = props
  const isContentLoading = entityDataState === DATA_STATE_REQUESTING
  const isFormRequesting = dataState === DATA_STATE_REQUESTING
  const isAllowed = canUpdatePMSForm(authUser)

  const isDisabled = isContentLoading || isFormRequesting || !isAllowed

  useUpdateSomeDataState(
    (prevFormDataState, prevEntityDataState) => {
      if (entityDataState !== prevEntityDataState) {
        const entityDataName = get(entityData, 'name')
        onSetHeaderProps({ title: entityDataName })
      }
      if (formDataState !== prevFormDataState) {
        onClose()
      }
    },
    [formDataState, entityDataState],
    DATA_STATE_RECIEVED
  )

  useUpdateSomeDataState(
    () => {
      onError()
    },
    [entityDataState],
    DATA_STATE_ERROR
  )

  return (
    <Form
      onSubmit={onSubmit(props)}
      initialValues={{
        [GUEST_API_FIELD]: false,
        [LISTING_API_FIELD]: false,
        [COMPANY_ID_ENABLED_FIELD]: false,
        [PREBOOK_FIELD]: false
      }}
    >
      {({ formApi }) => (
        <InnerContent
          {...props}
          isLoading={isContentLoading}
          formApi={formApi}
          dataId={dataId}
          formData={entityData}
          disabled={isDisabled}
        />
      )}
    </Form>
  )
}

PMSForm.propTypes = {
  dataId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired,
  onSetHeaderProps: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  dataState: PropTypes.string.isRequired,
  entityDataState: PropTypes.string.isRequired,
  entityData: PropTypes.object,
  formDataState: PropTypes.string.isRequired
}

PMSForm.defaultProps = {
  dataId: null,
  entityData: null
}
