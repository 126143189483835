/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  COMMON_ICON_SIZE,
  commonStrokeWidth,
  DEFAULT_CIRCLE_FILL,
  DEFAULT_STROKE_COLOR,
  defaultCircleWrapperSize
} from './types'
import { getInnerIndentOption, buildRadiusSize } from './helpers'

import styles from './CloseIcon.scss'

export function CloseIcon({
  dynamicClassName,
  isDisabledStyle,
  dynamicEvent,
  isCircleWrapper,
  strokeColor,
  ...rest
}) {
  const commonCircleSize = isCircleWrapper && defaultCircleWrapperSize
  const commonInnerIndent = getInnerIndentOption(isCircleWrapper, defaultCircleWrapperSize)
  const circleRadius = isCircleWrapper && buildRadiusSize(commonCircleSize)

  return (
    <svg
      viewport="0 0 15 15"
      className={classNames(styles.CloseIconDefault, dynamicClassName, {
        [styles.DisabledCloseIcon]: isDisabledStyle
      })}
      onClick={dynamicEvent}
      {...rest}
    >
      <line
        x1={commonInnerIndent}
        y2={commonInnerIndent}
        y1={COMMON_ICON_SIZE}
        x2={COMMON_ICON_SIZE}
        stroke={strokeColor}
        strokeWidth={commonStrokeWidth}
      />
      <line
        x1={commonInnerIndent}
        y1={commonInnerIndent}
        x2={COMMON_ICON_SIZE}
        y2={COMMON_ICON_SIZE}
        stroke={strokeColor}
        strokeWidth={commonStrokeWidth}
      />
      {isCircleWrapper && (
        <circle
          cx={commonCircleSize}
          cy={commonCircleSize}
          r={circleRadius}
          stroke={strokeColor}
          strokeWidth={commonStrokeWidth}
          fill={DEFAULT_CIRCLE_FILL}
        />
      )}
    </svg>
  )
}

CloseIcon.propTypes = {
  dynamicClassName: PropTypes.string,
  isDisabledStyle: PropTypes.bool,
  dynamicEvent: PropTypes.func,
  isCircleWrapper: PropTypes.bool,
  strokeColor: PropTypes.string
}

CloseIcon.defaultProps = {
  dynamicClassName: null,
  isDisabledStyle: false,
  dynamicEvent: () => null,
  isCircleWrapper: false,
  strokeColor: DEFAULT_STROKE_COLOR
}
