/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withValidation, blankValidate } from 'helpers/formHelpers'
import { ToggleRadioInput } from './ToggleRadioInput/container'

export function ToggleRadioField(props) {
  const { isRequired, fieldName, validate, ...rest } = props

  const validateStrategy = useMemo(
    () => (isRequired ? withValidation(blankValidate, validate) : validate),
    [isRequired, validate]
  )

  return (
    <ToggleRadioInput
      field={fieldName}
      isRequired={isRequired}
      validate={validateStrategy}
      validateOnChange
      {...rest}
    />
  )
}
ToggleRadioField.propTypes = {
  isRequired: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.func
}
ToggleRadioField.defaultProps = {
  isRequired: false,
  validate: null
}
