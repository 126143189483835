import React, { useContext } from 'react'
import { SystemDetailsField } from 'modules/SystemDetails/SystemDetailsField'
import { SimpleBox } from '@xplorie/ui-commons/lib/index'
import { DiscountContext } from '../helpers'
import styles from './styles.scss'
import { SYSTEM_DETAILS_LABEL, DISCOUNT_CODE } from './types'

export function DiscountSystemDetails() {
  const { state } = useContext(DiscountContext)

  return (
    <SimpleBox className={styles.section}>
      <div className={styles.title}>{SYSTEM_DETAILS_LABEL}</div>
      <SystemDetailsField label={DISCOUNT_CODE} value={state.discountCode} />
    </SimpleBox>
  )
}
