/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { MORE_OR_EQUALS, LESS_OR_EQUALS } from 'constants/eligibility'

export const MORE_OR_EQUALS_MODE_LABEL = 'More than or Equals to'
export const LESS_OR_EQUALS_MODE_LABEL = 'Less than or Equals to'

export const CONFIRMATION_MODE_FIELD = 'confirmationMode'
export const CONFIRMATION_ID_VALUE = 'confirmationIdValue'

export const PARAMETER_LABEL = 'Parameter'
export const MODE_LABEL = 'Mode'
export const VALUE_LABEL = 'Value'
export const PREBOOK_CONFIRMATION_LABEL = 'Prebook Confirmation Id'

export const MODE_PLACEHOLDER = 'Select mode'
export const VALUE_PLACEHOLDER = 'Value'

export const modes = [
  { label: MORE_OR_EQUALS_MODE_LABEL, value: MORE_OR_EQUALS },
  { label: LESS_OR_EQUALS_MODE_LABEL, value: LESS_OR_EQUALS }
]
