/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

// For Xplorie
export const LABEL_FOR_XPLORIE = 'For Xplorie.com'

export const CONTRACT_DATA_FIELD = 'contactData'

// email field
export const EMAIL_LABEL = 'Email'
export const EMAIL_PLACEHOLDER = 'Email'
export const EMAIL_FIELD = 'email'

// contactPhoneNumber field
export const PHONE_NUMBER_LABEL = 'Phone Number'
export const PHONE_NUMBER_PLACEHOLDER = 'Phone Number'
export const PHONE_FIELD = 'contactPhoneNumber'

// additionalContactPhoneNumber field
export const ADDITIONAL_PHONE_NUMBER_LABEL = 'Additional Phone Number'
export const ADDITIONAL_PHONE_NUMBER_PLACEHOLDER = 'Phone Number'
export const ADDITIONAL_PHONE_NUMBER_FIELD = 'additionalContactPhoneNumber'

// destinationTitle field
export const DESTINATION_TITLE_LABEL = 'Destination Title'
export const DESTINATION_TITLE_PLACEHOLDER = 'Destination Title'
export const DESTINATION_TITLE_FIELD = 'destinationTitle'

// pmcDescription field
export const PMC_DESCRIPTION_LABEL = 'PMC Description'
export const PMC_DESCRIPTION_PLACEHOLDER = 'PMC Description'
export const PMC_DESCRIPTION_FIELD = 'pmcDescription'

// Materials Fields

export const MATERIALS_FIELD = 'materials'

// confirmationImageUrl field
export const CONFIRMATION_IMG_URL_LABEL = 'Confirmation Image URL'
export const CONFIRMATION_IMG_URL_PLACEHOLDER = 'Confirmation Image URL'
export const CONFIRMATION_IMG_URL_FIELD = 'confirmationImageUrl'

// phoneImageUrl field
export const PHONE_IMAGE_URL_LABEL = 'Phone Image URL'
export const PHONE_IMAGE_URL_PLACEHOLDER = 'Phone Image URL'
export const PHONE_IMAGE_URL_FIELD = 'phoneImageUrl'

// pmcSqUrl field
export const PMC_SQUARE_IMAGE_URL_LABEL = 'PMC Square Image URL'
export const PMC_SQUARE_IMAGE_URL_PLACEHOLDER = 'PMC Square Image URL'
export const PMC_SQUARE_IMAGE_URL_FIELD = 'pmcSqUrl'

// welcomeHeroUrl field
export const WELCOME_HERO_IMAGE_URL_LABEL = 'Welcome Hero Image URL'
export const WELCOME_HERO_IMAGE_URL_PLACEHOLDER = 'Welcome Hero Image URL'
export const WELCOME_HERO_IMAGE_URL_FIELD = 'welcomeHeroUrl'

// micrositeUrl field
export const TEXT_LANDING_SITE_URL_LABEL = 'Landing Site URL'
export const TEXT_LANDING_SITE_URL_PLACEHOLDER = 'Landing Site URL'
export const TEXT_LANDING_SITE_URL_FIELD = 'micrositeUrl'

// cardFormUrl field
export const CARD_FORM_URL_LABEL = 'Card Form URL'
export const CARD_FORM_URL_PLACEHOLDER = 'Card Form URL'
export const CARD_FORM_URL_FIELD = 'cardFormUrl'

// checkInFolder field
export const CHECK_IN_FOLDER_LABEL = 'Check-in Folder?'
export const CHECK_IN_FOLDER_FIELD = 'checkInFolder'

export const CADENCE_TEXT_LABEL = 'Program Text Cadence?'
export const CADENCE_TEXT_FIELD = 'cadenceText'

export const CADENCE_EMAIL_LABEL = 'Program Email Cadence?'
export const CADENCE_EMAIL_FIELD = 'cadenceEmail'

// smartHost Fields
export const SMART_HOST_FIELDS = 'smartHost'
export const SMART_HOST_FIELD = 'enabled'
export const SMART_HOST_LABEL = 'SmartHost?'

export const SMART_HOST_MOBILE_LABEL = 'SmartHost Mobile?'
export const SMART_HOST_MOBILE_FIELD = 'mobile'

export const EMAIL_CADENCE_LABEL = 'SmartHost Email Cadence?'
export const EMAIL_CADENCE_FIELD = 'emailCadence'

export const CALENDAR_INTEGRATION_LABEL = 'Calendar Integration?'
export const CALENDAR_INTEGRATION_FIELD = 'calendarIntegration'

export const AMAZON_PROVISIONING_FIELDS = 'amazonProvisioning'
export const AMAZON_PROVISIONING_ACCOUNT_FIELD = 'account'
export const AMAZON_PROVISIONING_ACCOUNT_LABEL = 'Amazon Provisioning Account'
export const AMAZON_PROVISIONING_ACCOUNT_PLACEHOLDER = 'Enter Amazon Account address…'

export const AMAZON_PROVISIONING_PASSWORD_FIELD = 'password'
export const AMAZON_PROVISIONING_PASSWORD_LABEL = 'Amazon Provisioning Password'
export const AMAZON_PROVISIONING_PASSWORD_PLACEHOLDER = 'Enter Amazon Password…'
