/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const EFFECTIVE_DATE_LABEL = 'Effective Date'
export const EFFECTIVE_DATE_PLACEHOLDER = 'Effective Date'
export const EFFECTIVE_DATE_FIELD = 'effectivenessDate'

export const EFFECTIVENESS_DATE_VALIDATE_MESSAGE = 'Date to pick: 365 days in the past until now'
