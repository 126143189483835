/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { ROLE } from 'helpers/types/dataTablesTypes'

export const LIST_NAME = 'ROLE_LIST'
export const DEFAULT_PAGE_NUMBER = 1
export const DEFAULT_ITEMS_PER_PAGE = 10
export const ENTITY_TYPE = ROLE
export const ROLE_PROP_NAME = 'ROLE_PROP_NAME'

export default {
  LIST_NAME,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ITEMS_PER_PAGE,
  ENTITY_TYPE,
  ROLE_PROP_NAME
}
