/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import {
  getPmcUnitsListByProgramId,
  exportUnitsListByProgramId,
  updateUnitsSmartHostStatusByProgramId
} from 'services/http/pmcUnits'

import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_EXPORT_LIST,
  RESPONSE_EXPORT_LIST,
  RESPONSE_EXPORT_LIST_ERROR,
  REQUEST_SMART_HOST_STATUS,
  RESPONSE_SMART_HOST_STATUS,
  RESPONSE_SMART_HOST_STATUS_ERROR
} = helpers

const getUnitsByProgramIdList = (programId, options) => async dispatch => {
  const key = programId
  dispatch(actionCreater(REQUEST_LIST, { key }))
  const request = await getPmcUnitsListByProgramId(programId, options).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_ERROR_LIST, { key }))
    }
  )
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { data: request.data, key }))
    return 'getUnitsByProgramIdList success'
  }

  return 'getUnitsByProgramIdList failed'
}

const exportUnitsByProgramIdList = (programId, data, options) => async dispatch => {
  const key = programId
  dispatch(actionCreater(REQUEST_EXPORT_LIST, { key }))
  const request = await exportUnitsListByProgramId(programId, data, options).catch(err => {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_EXPORT_LIST_ERROR, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_EXPORT_LIST, { key }))
    return 'exportUnitsByProgramIdList success'
  }

  return 'exportUnitsByProgramIdList failed'
}

const updateUnitsSmartHostByProgramId = (programId, data) => async dispatch => {
  const key = programId
  dispatch(actionCreater(REQUEST_SMART_HOST_STATUS, { key }))
  const request = await updateUnitsSmartHostStatusByProgramId(programId, data).catch(err => {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_SMART_HOST_STATUS_ERROR, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_SMART_HOST_STATUS, { key }))
    return 'updateUnitsSmartHostByProgramId success'
  }

  return 'updateUnitsSmartHostByProgramId failed'
}

const resetList = key => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key }))

export default {
  getUnitsByProgramIdList,
  updateUnitsSmartHostByProgramId,
  exportUnitsByProgramIdList,
  resetList
}
