/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'

import helpers from './helpers'

const { DEFAULT_NAVIGATION_TYPE, UPDATE_NAVIGATION_TYPE_STATE } = helpers

const updateNavigationType = (data = DEFAULT_NAVIGATION_TYPE) => dispatch => {
  dispatch(actionCreater(UPDATE_NAVIGATION_TYPE_STATE, data))
}

export default {
  updateNavigationType
}
