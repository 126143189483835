/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import withAuthorization from 'helpers/withAuthorization'
import { DEFAULT_HOME_REDIRECT_URL } from 'services/auth'

import { isAllowedRoute } from './helpers'

function PrivateRouteComponent({ component: Component, authUser, accessLevels, ...rest }) {
  const isAllowed = isAllowedRoute(accessLevels, authUser)
  return (
    <Route
      {...rest}
      render={props =>
        !isAllowed ? <Redirect to={DEFAULT_HOME_REDIRECT_URL} /> : <Component {...props} />
      }
    />
  )
}

PrivateRouteComponent.propTypes = {
  component: PropTypes.func,
  accessLevels: PropTypes.array,
  authUser: PropTypes.object.isRequired
}

PrivateRouteComponent.defaultProps = {
  component: () => {},
  accessLevels: []
}

export const PrivateRoute = withAuthorization(PrivateRouteComponent)
