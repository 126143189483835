/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'
import { withLoader } from 'decorators'
import { getTicketGroupInventoryState } from 'reducers/activityResale/ticketGroupInventory'
import { getTicketGroupTemplateState } from 'reducers/activityResale/ticketGroupTemplate'

import { GroupForm as GroupFormComponent } from './GroupForm'

const mapStateToProps = state => {
  const ticketInventoryState = getTicketGroupInventoryState(state)
  const ticketTemplateState = getTicketGroupTemplateState(state)

  return {
    ...state.ticketGroupForm,
    ...ticketInventoryState,
    ticketSettings: state.ticketSettingsForm.entityData,
    removeTemplateDataState: ticketTemplateState.removeDataState
  }
}

export const GroupForm = compose(
  withAuthContext,
  withLoader,
  connect(mapStateToProps)
)(GroupFormComponent)
