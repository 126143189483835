/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import moment from 'moment'
import { FORMAT } from 'constants/date'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import { formatDate, parseDate } from 'react-day-picker/moment'
import { DEFAULT_START_LABEL, DEFAULT_END_LABEL } from './types'
import styles from './styles.scss'

export function RangePicker({ placeholder, onChange, value, format, disabled }) {
  const ref = useRef()

  const range = { from: (value && value.from) || null, to: (value && value.to) || null }

  const modifiers = { start: range.from, end: range.to }

  const showMonth = month => {
    if (month) {
      ref.current.getDayPicker().showMonth(month)
    }
  }

  const handleChange = day => {
    const newRange = DateUtils.addDayToRange(day, range)

    if (onChange) {
      onChange(newRange)
    }

    // go back to start month
    showMonth(newRange.from)
  }

  const momentFrom = moment(range.from)
  const momentTo = moment(range.to)

  const shownValue =
    momentFrom.isValid() || momentTo.isValid()
      ? `${momentFrom.isValid() ? momentFrom.format(format) : DEFAULT_START_LABEL} - ${
          momentTo.isValid() ? momentTo.format(format) : DEFAULT_END_LABEL
        }`
      : null

  return (
    <DayPickerInput
      ref={ref}
      value={range.from}
      placeholder={placeholder}
      format={format}
      formatDate={formatDate}
      parseDate={parseDate}
      hideOnDayClick={false}
      onDayPickerShow={() => showMonth(range.from)}
      value={shownValue}
      dayPickerProps={{
        className: 'Selectable',
        selectedDays: [range.from, { from: range.from, to: range.to }],
        month: range.from,
        initialMonth: range.from,
        modifiers,
        numberOfMonths: 2
      }}
      inputProps={{ readOnly: true, className: styles.input, disabled }}
      onDayChange={handleChange}
    />
  )
}

RangePicker.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    from: PropTypes.object,
    to: PropTypes.object
  }).isRequired,
  format: PropTypes.string,
  disabled: PropTypes.bool
}

RangePicker.defaultProps = {
  placeholder: '',
  onChange: null,
  format: FORMAT,
  disabled: false
}
