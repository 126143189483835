/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const NAME = 'APP_RESELL_SETTINGS_HOW_TO'

const DOWNLOAD = `${NAME}_DOWNLOAD`
const REMOVE = `${NAME}_REMOVE`
const UPLOAD = `${NAME}_UPLOAD`

export const INCORRECT_FORMAT_TEXT_MESSAGE =
  'This file has an incorrect extension. Only Word files are acceptable'

export default {
  NAME,
  DOWNLOAD,
  REMOVE,
  UPLOAD
}
