/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'
import compose from 'lodash/fp/compose'
import isEmpty from 'lodash/isEmpty'
import indexOf from 'lodash/indexOf'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import { FORMAT } from 'constants/date'
import Moment from 'moment'
import keys from 'lodash/keys'
import { extendMoment } from 'moment-range'
import uuid4 from 'uuid4'
import { WEEK_SELECTOR_DAYS } from '@xplorie/ui-commons'
import {
  blankValidate,
  dateInPastValidation,
  dateValidate,
  numberValidate
} from 'helpers/formHelpers'
import { NUMBER_VALIDATION_MESSAGE } from 'helpers/types/formHelpers'
import {
  NO_END_DATE_VALUE,
  AVAILABILITIES_FIELD,
  START_DATE_FIELD,
  NEW_DISCOUNT_PREFIX
} from './types'

const moment = extendMoment(Moment)

const WEEK_SELECTOR_MAP = {
  [WEEK_SELECTOR_DAYS.MONDAY]: { isoWeekday: 1, label: 'Mon' },
  [WEEK_SELECTOR_DAYS.TUESDAY]: { isoWeekday: 2, label: 'Tue' },
  [WEEK_SELECTOR_DAYS.WEDNESDAY]: { isoWeekday: 3, label: 'Wed' },
  [WEEK_SELECTOR_DAYS.THURSDAY]: { isoWeekday: 4, label: 'Thu' },
  [WEEK_SELECTOR_DAYS.FRIDAY]: { isoWeekday: 5, label: 'Fri' },
  [WEEK_SELECTOR_DAYS.SATURDAY]: { isoWeekday: 6, label: 'Sat' },
  [WEEK_SELECTOR_DAYS.SUNDAY]: { isoWeekday: 7, label: 'Sun' },
  [WEEK_SELECTOR_DAYS.ALL]: { label: 'All Days' }
}

export const DEFAULT_DAYS = [
  WEEK_SELECTOR_DAYS.MONDAY,
  WEEK_SELECTOR_DAYS.TUESDAY,
  WEEK_SELECTOR_DAYS.WEDNESDAY,
  WEEK_SELECTOR_DAYS.THURSDAY,
  WEEK_SELECTOR_DAYS.FRIDAY,
  WEEK_SELECTOR_DAYS.SATURDAY,
  WEEK_SELECTOR_DAYS.SUNDAY
]

export const getDealAvailabilities = formValues => get(formValues, AVAILABILITIES_FIELD, [])

export const createDealPeriod = (item = {}) => {
  const { datePeriod, range, included = true, days = DEFAULT_DAYS } = item
  const id = item.id || `${NEW_DISCOUNT_PREFIX}_${uuid4()}`
  const startDate = get(datePeriod, 'startDate', '')
  const endDate = get(datePeriod, 'endDate', '')
  const from = get(range, 'from', 1)
  const to = get(range, 'to', '')
  const noEndDateEnabled = endDate === NO_END_DATE_VALUE

  return {
    [id]: {
      id,
      startDate,
      endDate: noEndDateEnabled ? '' : endDate,
      noEndDateEnabled,
      included,
      days,
      from,
      to
    }
  }
}

export const getDefaultAvailabilities = () => ({
  availabilities: {
    ...createDealPeriod()
  }
})

export const sortPeriodsByStartDate = items =>
  sortBy(items, item => moment(item.startDate, FORMAT).valueOf())

export const convertPeriodsToObject = items =>
  items.length
    ? items.reduce(
        (acc, item) => ({
          ...acc,
          ...createDealPeriod(item)
        }),
        {}
      )
    : createDealPeriod()

export const convertContractToPeriodsFormValues = formValues => {
  if (isEmpty(formValues)) {
    return getDefaultAvailabilities()
  }

  const availabilities = compose(
    convertPeriodsToObject,
    sortPeriodsByStartDate,
    getDealAvailabilities
  )(formValues)

  return {
    availabilities
  }
}

export const prepareDealPeriodsPayload = formValues => {
  const { availabilities } = formValues
  return {
    availabilities: Object.values(availabilities || [])
      .map(({ startDate, endDate, days, noEndDateEnabled, included, from, to, id }) => {
        const dealId = id.includes(NEW_DISCOUNT_PREFIX) ? undefined : id
        return {
          datePeriod: {
            startDate,
            endDate: noEndDateEnabled ? NO_END_DATE_VALUE : endDate
          },
          range: {
            from,
            to
          },
          days,
          included,
          id: dealId
        }
      })
      .filter(({ datePeriod: { startDate, endDate } }) => startDate && endDate)
  }
}

export const validateDate = (validator, relatedField, index, isNewDiscount) => (
  value,
  formValues
) => {
  const currentElement = get(formValues, `${AVAILABILITIES_FIELD}.${index}`)
  let currentValue = value

  const { noEndDateEnabled } = currentElement
  const isEndDateField = relatedField.endsWith(START_DATE_FIELD)

  if (isEndDateField && noEndDateEnabled) {
    currentValue = NO_END_DATE_VALUE
  }

  const blankError = blankValidate(currentValue)

  if (blankError) {
    return blankError
  }

  if (isNewDiscount) {
    // date can no be in past
    const dateInPastError = dateInPastValidation(currentValue)

    if (dateInPastError) {
      return dateInPastError
    }
  }

  // validate current date and related
  // start date before end date
  // end date after start date
  const baseError = validator(relatedField)(currentValue, formValues)
  return baseError
}

export function isValidDate(value) {
  return Boolean(value) && !dateValidate(value)
}

export function isValidRange({ startDate, endDate } = {}) {
  return isValidDate(startDate) && isValidDate(endDate)
}

const fullDaysWeek = [1, 2, 3, 4, 5, 6, 7]

export const daysInRange = (start, end) => {
  const startDate = moment(start, FORMAT)
  const endDate = moment(end, FORMAT)

  if (!isValidRange({ startDate: start, endDate: end })) {
    return fullDaysWeek
  }

  const daysCount = endDate.diff(startDate, 'days')
  if (daysCount > 7) {
    return fullDaysWeek
  }

  const range = moment.range(startDate, endDate)
  const dayCodes = Array.from(range.by('days', { step: 1 }))

  return dayCodes.map(m => m.isoWeekday())
}

export const buildWeekSelectorMapper = (availableDaysOfWeek = []) =>
  keys(WEEK_SELECTOR_MAP).reduce((acc, dayCode) => {
    const { isoWeekday, ...restParams } = WEEK_SELECTOR_MAP[dayCode]
    return {
      ...acc,
      [dayCode]: {
        ...restParams,
        disabled: isoWeekday && indexOf(availableDaysOfWeek, isoWeekday) === -1
      }
    }
  }, {})

export function filterAvailableWeekDays(availableDaysOfWeek, weekDaysValues = []) {
  return filter(
    weekDaysValues,
    code => indexOf(availableDaysOfWeek, WEEK_SELECTOR_MAP[code].isoWeekday) !== -1
  )
}

export const validateNumberRange = (validator, relatedField) => (value, formValues) => {
  const currentValue = value

  if (currentValue && Number(currentValue) === 0) {
    return NUMBER_VALIDATION_MESSAGE
  }

  const numberError = numberValidate(currentValue)

  if (numberError) {
    return numberError
  }

  // validate number range
  const baseError = validator(relatedField)(currentValue, formValues)

  return baseError
}
