/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'

import { PMSEligibilityList } from './PMSEligibilityList'

export function PMSEligibility(props) {
  const { dataId } = props

  return <PMSEligibilityList dataId={dataId} />
}

PMSEligibility.propTypes = {
  dataId: PropTypes.string
}

PMSEligibility.defaultProps = {
  dataId: null
}
