/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import Moment from 'moment'
import { extendMoment } from 'moment-range'
import 'twix'
import isArray from 'lodash/isArray'
import get from 'lodash/get'
import { FORMAT } from 'constants/date'
import groupBy from 'lodash/groupBy'

import { validationTypes } from 'modules/Programs/ProgramSeasons'
import { HAS_CONFLICTS_ERROR_MESSAGE, NULL_SEASONS_ERROR_MESSAGE, NO_DATE } from './types'

const { DATE_INTERSECTIONS_SEASONS_ERROR_KEY, NULL_SEASONS_ERROR_KEY } = validationTypes

const moment = extendMoment(Moment)

export const buildCollapseTitle = (title, year) => `${title} (${year})`

export const groupSeasonsByYear = (seasons = []) => {
  if (seasons) {
    return groupBy(seasons, season => {
      const { startDate } = season.data
      const date = moment(startDate, FORMAT)
      return date.isValid() ? date.year() : NO_DATE
    })
  }
  return []
}
export const createSeason = (season = {}) => ({
  ...season
})

export const createSeasonItem = (data = {}) => ({
  data: (data && createSeason(data)) || null,
  api: null,
  meta: {
    id: data && data.id ? data.id : +new Date(),
    isNew: !data.id
  }
})
export const getSeasonId = (season = {}) => {
  const seasonId = get(season, 'data.id', null)
  const metaId = get(season, 'meta.id')
  return seasonId || metaId
}

export const validateDateIntersections = seasons => {
  if (!seasons) {
    return []
  }
  const ranges = seasons
    .filter(
      value =>
        moment(get(value, 'data.startDate'), FORMAT).isValid() &&
        moment(get(value, 'data.endDate'), FORMAT).isValid()
    )
    .map(value => {
      const startDate = moment(value.data.startDate, FORMAT)
      const endDate = moment(value.data.endDate, FORMAT)
      return {
        startDate,
        endDate,
        range: moment.range(startDate, endDate),
        ...value
      }
    })
    .reduce((acc, value) => ({ ...acc, [getSeasonId(value)]: value }), {})

  // check intersections
  const intersections = Object.keys(ranges)
    .filter(key => {
      const { [key]: currentElement, ...restElements } = ranges
      return Object.values(restElements).some(value =>
        currentElement.range.overlaps(value.range, {
          adjacent: true
        })
      )
    })
    .map(key => {
      const value = ranges[key]
      return {
        range: value.range,
        startDate: value.data.startDate,
        endDate: value.data.endDate,
        meta: { id: value.data.id, name: value.data.name, format: FORMAT },
        timestamp: +new Date(),
        message: HAS_CONFLICTS_ERROR_MESSAGE
      }
    })

  if (intersections.length) {
    return intersections.reduce(
      (acc, value) => [...acc, { [DATE_INTERSECTIONS_SEASONS_ERROR_KEY]: value }],
      []
    )
  }
  return []
}

export const validateBasicErrors = (seasons = []) => {
  const errors = []
  if (!seasons || !isArray(seasons)) {
    return []
  }
  if (!seasons.length) {
    errors.push({
      [NULL_SEASONS_ERROR_KEY]: {
        meta: { id: null, name: null },
        timestamp: +new Date(),
        message: NULL_SEASONS_ERROR_MESSAGE
      }
    })
  }
  return errors
}
