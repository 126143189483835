/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withLoader } from 'decorators'

import { FormContent, TextField, TextAreaField, CustomNestedField } from 'components/FormComponents'
import {
  UNIT_NAME_LABEL,
  UNIT_ID_LABEL,
  PMC_NAME_LABEL,
  PROPERTY_TYPE_LABEL,
  SHORT_DESCRIPTION_LABEL,
  LONG_DESCRIPTION_LABEL,
  SLEEPS_LABEL,
  BEDROOMS_LABEL,
  BATHROOMS_LABEL,
  PMC_LOGO_URL_LABEL,
  PMC_PHONE_NUMBER_LABEL,
  PMC_EMAIL_LABEL
} from '../types'

function InnerContentComponent({ disabled }) {
  return (
    <FormContent disabled={disabled}>
      <TextField fieldName="name" label={UNIT_NAME_LABEL} readOnly />
      <TextField fieldName="businessId" label={UNIT_ID_LABEL} readOnly />
      <CustomNestedField field="pmc">
        <TextField fieldName="name" label={PMC_NAME_LABEL} readOnly />
      </CustomNestedField>
      <TextField fieldName="propertyType" label={PROPERTY_TYPE_LABEL} readOnly />

      <TextAreaField fieldName="shortDescription" label={SHORT_DESCRIPTION_LABEL} readOnly />
      <TextAreaField fieldName="description" label={LONG_DESCRIPTION_LABEL} readOnly />

      <CustomNestedField field="accommodation">
        <TextField fieldName="sleeps" label={SLEEPS_LABEL} readOnly />
        <TextField fieldName="bedrooms" label={BEDROOMS_LABEL} readOnly />
        <TextField fieldName="bathrooms" label={BATHROOMS_LABEL} readOnly />
      </CustomNestedField>
      <CustomNestedField field="pmc">
        <TextField fieldName="logoUrl" label={PMC_LOGO_URL_LABEL} readOnly />
        <TextField fieldName="phonenumber" label={PMC_PHONE_NUMBER_LABEL} readOnly />
        <TextField fieldName="email" label={PMC_EMAIL_LABEL} readOnly />
      </CustomNestedField>
    </FormContent>
  )
}

InnerContentComponent.propTypes = {
  disabled: PropTypes.bool
}

InnerContentComponent.defaultProps = {
  disabled: true
}

export const InnerContent = withLoader(InnerContentComponent)
