/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDataStatesComparison, useLoading } from 'hooks'
import { ReactTable } from 'modules/ReactTable'

import { HEADER } from './types'
import { buildTableColumns } from './helpers'

export function CampaignsListComponent(props) {
  const {
    dataState,
    itemsCount,
    itemsPerPage,
    pageNumber,
    data,
    onGetData,
    listWatchers,
    authUser
  } = props
  const [isLoading] = useLoading(dataState)
  const listParameters = useRef()
  const columns = useMemo(() => buildTableColumns({ authUser }), [authUser])

  useDataStatesComparison(() => {
    onGetData(listParameters.current)
  }, listWatchers)

  const fetchData = useCallback(
    parameters => {
      onGetData(parameters)
      listParameters.current = parameters
    },
    [onGetData]
  )

  return (
    <ReactTable
      data={data}
      columns={columns}
      fetchData={fetchData}
      itemsCount={itemsCount}
      pageNumber={pageNumber}
      itemsPerPage={itemsPerPage}
      tableHeader={HEADER}
      isLoading={isLoading}
    />
  )
}

CampaignsListComponent.propTypes = {
  dataState: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  itemsCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  onGetData: PropTypes.func.isRequired,
  listWatchers: PropTypes.array.isRequired,
  authUser: PropTypes.object.isRequired
}
