/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { uploadHowToFile, downloadHowToFile, removeHowToFile } from 'services/http/ticketSettings'
import { INCORRECT_FORMAT_TEXT_MESSAGE } from './types'
import helpers from './helpers'

const {
  REQUEST_DOWNLOAD,
  RESPONSE_DOWNLOAD,
  RESPONSE_ERROR_DOWNLOAD,
  REQUEST_REMOVE,
  RESPONSE_REMOVE,
  RESPONSE_ERROR_REMOVE,
  RESET_DATA_STATE,
  REQUEST_UPLOAD,
  RESPONSE_UPLOAD,
  RESPONSE_ERROR_UPLOAD
} = helpers

const uploadHowTo = (activityId, file, type) => async dispatch => {
  const key = activityId

  dispatch(actionCreater(REQUEST_UPLOAD, { key }))
  // eslint-disable-next-line consistent-return
  const request = await uploadHowToFile(activityId, file, type).catch(({ send, message }) => {
    const fileExtension = file && file.name.split('.').pop()
    const isIncorrectFormat = fileExtension !== 'doc' && fileExtension !== 'docx'

    if (isIncorrectFormat) {
      dispatch(
        send({
          ...message,
          message: INCORRECT_FORMAT_TEXT_MESSAGE
        })
      )
      return 'uploadHowTo failed'
    }

    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_UPLOAD, { key }))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_UPLOAD, { data: request.data, key }))
    return 'uploadHowTo success'
  }

  return 'uploadHowTo failed'
}

const downloadHowTo = (activityId, type) => async dispatch => {
  const key = activityId
  dispatch(actionCreater(REQUEST_DOWNLOAD, { key }))
  const request = await downloadHowToFile(activityId, type).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_DOWNLOAD, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_DOWNLOAD, { data: request.data, key }))
    return 'downloadHowTo success'
  }

  return 'downloadHowTo failed'
}

const removeHowTo = (activityId, type) => async dispatch => {
  const key = activityId
  dispatch(actionCreater(REQUEST_REMOVE, { key }))
  const request = await removeHowToFile(activityId, type).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_REMOVE, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_REMOVE, { data: request.data, key }))
    return 'removeHowTo success'
  }

  return 'removeHowTo failed'
}

const reset = activityId => dispatch =>
  dispatch(actionCreater(RESET_DATA_STATE, { key: activityId }))

export default {
  downloadHowTo,
  removeHowTo,
  uploadHowTo,
  reset
}
