/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useIsFirstRender } from 'hooks'

import { SimpleTable } from 'components/Tables'

import { buildTableColumns } from './helpers'
import { HEADER } from './types'

export function ActivityProductListComponent(props) {
  const {
    dataState,
    itemsCount,
    itemsPerPage,
    formDataState,
    data,
    onGetData,
    needUpdate,
    extendedOptions,
    authUser,
    TableComponent,
    // TODO: Need remove it after refactoring.
    dataStateWatchers,
    //
    ...rest
  } = props

  const isFirstRender = useIsFirstRender()

  return (
    <TableComponent
      dataState={dataState}
      columns={buildTableColumns(props)}
      onGetData={onGetData}
      itemsPerPage={itemsPerPage}
      itemsCount={itemsCount}
      tableHeader={HEADER}
      data={data}
      extendedOptions={extendedOptions}
      // TODO: Need remove it after refactoring.
      dataStateWatchers={dataStateWatchers}
      withBasicAuth={false}
      isFirstRender={isFirstRender}
      {...rest}
    />
  )
}

ActivityProductListComponent.propTypes = {
  dataState: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemsCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  formDataState: PropTypes.string,
  onGetData: PropTypes.func.isRequired,
  needUpdate: PropTypes.bool,
  extendedOptions: PropTypes.shape({
    type: PropTypes.string,
    keyword: PropTypes.string
  }),
  authUser: PropTypes.object.isRequired,
  TableComponent: PropTypes.func,
  // TODO: Need remove it after refactoring.
  dataStateWatchers: PropTypes.array.isRequired
}

ActivityProductListComponent.defaultProps = {
  formDataState: null,
  extendedOptions: {},
  needUpdate: false,
  TableComponent: SimpleTable
}
