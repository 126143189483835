/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import isBoolean from 'lodash/isBoolean'
import { split, join } from 'lodash'
import { getRoleAlies } from 'helpers/permissionHelper'

export function buildSortPropertyByType(name, type) {
  return `${type}.${name}`
}

export function convertBooleanToConditionalValue(value) {
  if (isBoolean(value)) {
    return value ? 'YES' : 'NO'
  }
  return value
}

export function getRoleName(roles, applicationType) {
  return roles
    .filter(role => role.applicationType === applicationType)
    .map(role => getRoleAlies(role.name))
    .join()
}

export function convertBooleanToString(value) {
  if (!isBoolean(value)) {
    return value
  }

  return value ? 'True' : 'False'
}

export function prepareDestinationsToView(value) {
  const splittedValue = split(value, '|')

  return join(splittedValue, ' | ')
}
