/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSliderApi } from 'hooks'
import { MY_WORK_PATH } from 'router'
import { PROGRAM } from 'constants/formCodes'
import { useHistory } from 'react-router-dom'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { BUTTON_TITLE } from './types'

export function DraftInProgressButton({ onClick, draftId, ...rest }) {
  const history = useHistory()
  const programSliderApi = useSliderApi(PROGRAM)

  const handleAction = useCallback(
    id => {
      if (id) {
        history.push(MY_WORK_PATH)
        programSliderApi.open(id)
      }
    },
    [history, programSliderApi]
  )

  const handleClick = useCallback(
    e => {
      if (draftId) {
        handleAction(draftId)
      }

      if (onClick) {
        onClick(draftId || handleAction, e)
      }
    },
    [draftId, handleAction, onClick]
  )

  return (
    <BasicButton colorSchema={ORANGE_SCHEMA} onClick={handleClick} {...rest}>
      {BUTTON_TITLE}
    </BasicButton>
  )
}

DraftInProgressButton.propTypes = {
  draftId: PropTypes.string,
  onClick: PropTypes.func
}
DraftInProgressButton.defaultProps = {
  onClick: null,
  draftId: null
}
