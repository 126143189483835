/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const ActivityProductSlider = loadable(
  () =>
    import(
      /* webpackChunkName: 'ActivityProductSlider', webpackPrefetch: true */ './ActivityProductSlider'
    ),
  {
    resolveComponent: components => components.ActivityProductSlider
  }
)

export * from './helpers'
export { ActivityProductSlider }
export * from './ActivityResaleTab'
export * from './ActivityDiscountsTab'
export * from './AppLifeCycleButtons'
export * from './SchedulesTab'
export * from './AvailabilityForm'
export * from './ClosuresModule'

export {
  AppActivation as AppActivationModal,
  AppDeactivation as AppDeactivationModal
} from './ActivityProductModals'
export { DelayedDeactivation } from './DelayedDeactivation'
