/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useDealCalendarState, useDealCalendarApi } from '../hooks'

export function useDealContextMenuState() {
  const { contextMenuState } = useDealCalendarState()

  return contextMenuState
}

export function useDealContextMenuApi() {
  const { contextMenuApi } = useDealCalendarApi()

  return contextMenuApi
}
