/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { accountPasswordInitialState } from './reducer'

const getAccountPasswordState = state => state.account.accountPassword

export const getAccountPasswordStateById = (state, id) => {
  const accountPasswordState = getAccountPasswordState(state)

  return accountPasswordState[id] || accountPasswordInitialState
}
