/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { LocationsItem } from 'modules/Locations/LocationsList/LocationsItem'

export function LocationsContainer(props) {
  const { data, ...rest } = props
  return data.map(value => <LocationsItem key={value.location.id} {...value} {...rest} />)
}
