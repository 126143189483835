/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const selectorExtRegistrationField = 'ticketReservationSettings.externalRegistrationRequired'
export const selectorDateSpecificField = 'ticketReservationSettings.dateSpecific'
export const selectorPeriodSpecificField = 'ticketReservationSettings.periodSpecific'
export const selectorTimeSpecificField = 'ticketReservationSettings.timeSpecific'
export const selectorConfirmationNumberField =
  'ticketReservationSettings.confirmationNumberRequired'
export const selectorTicketFormatField = 'ticketReservationSettings.ticketFormat'
export const selectorFeeField = 'ticketReservationSettings.fees'
export const selectorPercentageFees = 'ticketReservationSettings.percentageFees'
export const selectorConfirmationNumberRequired =
  'ticketReservationSettings.confirmationNumberRequired'
export const selectorRedemptionMethod = 'ticketReservationSettings.redemptionType'
export const selectorNoSoonerThanType = 'reservationWindow.min.type'
export const selectorNoFurtherThanType = 'reservationWindow.max.type'
export const selectorCancellationNoSoonerThanType = 'ticketCancellationSettings.cancellationWindow.min.type'
export const selectorCancellationNoFurtherThanType = 'ticketCancellationSettings.cancellationWindow.max.type'

