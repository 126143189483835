/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'informed'
import withAuthorization from 'helpers/withAuthorization'
import { DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { ChangePasswordInnerContent } from './ChangePasswordInnerContent'
import { SUBTITLE_TEXT, TYPE_OLD_PASSWORD, TYPE_NEW_PASSWORD } from './types'

import styles from './ChangePasswordForm.scss'

class ChangePasswordFormComponent extends React.Component {
  onSubmitForm = formData => {
    const { changePassword, authUser } = this.props

    const passwordDataToSend = {
      oldPassword: formData[TYPE_OLD_PASSWORD],
      newPassword: formData[TYPE_NEW_PASSWORD]
    }

    return changePassword(authUser, passwordDataToSend)
  }

  render() {
    const { changePassDataState } = this.props
    const formEnabled = changePassDataState !== DATA_STATE_REQUESTING
    return (
      <div className="tile is-vertical is-5">
        <div className="tile is-parent is-vertical">
          <h4
            id="chg-pass-title"
            className={`title is-5 ${styles.customSubTitle}`}
          >
            {SUBTITLE_TEXT}
          </h4>
          <div className="tile is-parent is-vertical">
            <Form onSubmit={this.onSubmitForm}>
              {({ formApi }) => (
                <ChangePasswordInnerContent
                  formApi={formApi}
                  formEnabled={formEnabled}
                  changePassDataState={changePassDataState}
                />
              )}
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

ChangePasswordFormComponent.propTypes = {
  changePassword: PropTypes.func.isRequired,
  changePassDataState: PropTypes.string.isRequired,
  authUser: PropTypes.object.isRequired
}

export const ChangePasswordForm = withAuthorization(ChangePasswordFormComponent)
