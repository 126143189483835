/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { DATA_STATE_REQUESTING, DATA_STATE_ERROR, DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { ProgressBar, BasicButton, RoundRadioButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, SMALLER_SIZE, VIEW_TITLE } from 'constants/buttonsOptions'
import { UPLOAD_ERROR_TEXT } from './types'
import styles from './FileItem.scss'

export function FileItem({
  id,
  progressValue,
  dataState,
  onDownload,
  name,
  onSelect,
  downloadFileDataState,
  isActive
}) {
  const isUploadError = dataState === DATA_STATE_ERROR
  const isUploading = dataState === DATA_STATE_REQUESTING
  const isDowloading = downloadFileDataState === DATA_STATE_REQUESTING
  const isLoaded = dataState === DATA_STATE_RECIEVED
  const canSeeProgressBar = progressValue && isUploading
  const isVisibleViewButton = isLoaded
  const progress = Number(progressValue)

  const onChangeHandler = value => {
    onSelect({ id: value, name })
  }

  const onDownloadhandler = () => {
    if (id) {
      onDownload({ name, id })
    }
  }

  return (
    <li className={styles.itemWrapper}>
      <div>
        <RoundRadioButton
          id={`select-file-${id}`}
          value={id}
          isActive={isActive}
          onChange={onChangeHandler}
          disabled={isDowloading || isUploading}
          type="checkbox"
        />
      </div>
      <h1 className={styles.fileName}>{name}</h1>
      {canSeeProgressBar && (
        <ProgressBar classNameWrapper={styles.progressWrapper} value={progress} type="striped" />
      )}
      <div>
        {isVisibleViewButton && (
          <BasicButton
            colorSchema={ORANGE_SCHEMA}
            size={SMALLER_SIZE}
            onClick={onDownloadhandler}
            disabled={isDowloading}
          >
            {VIEW_TITLE}
          </BasicButton>
        )}
        {isUploadError && <span>{UPLOAD_ERROR_TEXT}</span>}
        {canSeeProgressBar && <span>{progressValue}%</span>}
      </div>
    </li>
  )
}

FileItem.propTypes = {
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  dataState: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  progressValue: PropTypes.any,
  file: PropTypes.object,
  downloadFileDataState: PropTypes.string,
  isActive: PropTypes.bool.isRequired
}

FileItem.defaultProps = {
  progressValue: null,
  file: null,
  downloadFileDataState: null
}
