/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import {
  actions as profileDataActions,
  getProfileDatatState,
  getWatchersState
} from 'reducers/profileData'
import { withAuthContext } from 'helpers/withAuthContext'

import { ProfileInfo as ProfileInfoComponent } from './ProfileInfo'

const mapStateToProps = state => {
  const profileDatatState = getProfileDatatState(state)

  return {
    ...profileDatatState,
    listWatchersState: getWatchersState(state)
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(profileDataActions, dispatch)

export const ProfileInfo = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProfileInfoComponent)
