/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { SimpleBox, BasicButton } from '@xplorie/ui-commons'
import { BoxLabel } from 'components/Basic/Labels'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { ClosureCalendar } from 'modules/Closures'
import styles from './styles.scss'
import { CLOSE_CALENDAR_LABEL, SCHEDULES_AND_CLOSURES_TITLE, SHOW_CALENDAR_LABEL } from './constant'

export function ClosuresModule({ onAction, id, visible }) {
  const label = visible ? CLOSE_CALENDAR_LABEL : SHOW_CALENDAR_LABEL

  return (
    <SimpleBox>
      <div className={styles.boxHeader}>
        <BoxLabel>{SCHEDULES_AND_CLOSURES_TITLE}</BoxLabel>
        <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onAction}>
          {label}
        </BasicButton>
      </div>
      {visible && (
        <div className={styles.boxContent}>
          <ClosureCalendar id={id} />
        </div>
      )}
    </SimpleBox>
  )
}

ClosuresModule.propTypes = {
  id: PropTypes.string.isRequired,
  visible: PropTypes.boolean.isRequired,
  onAction: PropTypes.func.isRequired
}
