/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

// Labels
export const NAME_LABEL = 'Name'
export const TOTAL_PMCS_LABEL = 'Total PMCs'
export const TOTAL_UNIT_COUNT_LABEL = 'Total Unit Count'
export const GUEST_API_LABEL = 'Guest API'
export const COMPANY_ID_LABEL = 'Company Id enabled?'
export const LISTING_API_LABEL = 'Listing API'

// Placeholders
export const NAME_PLACEHOLDER = 'Add Name'
export const TOTAL_PMCS_PLACEHOLDER = 'Total PMCs'
export const TOTAL_UNIT_COUNT_PLACEHOLDER = 'Total Unit Count'

// Other Constants
export const VIEW_PMC_TITLE = 'View PMCs'

// prebookOnly field
export const PREBOOK_FIELD_LABEL = 'Upload Guests'
export const PREBOOK_FIELD_OPTIONS = [
  { label: 'Prebook', value: true },
  { label: 'All Guests', value: false }
]
export const PREBOOK_FIELD = 'prebookOnly'
export const GUEST_API_FIELD = 'guestApi'
export const LISTING_API_FIELD = 'listingApi'
export const COMPANY_ID_ENABLED_FIELD = 'companyIdEnabled'
