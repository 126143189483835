/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { DESTINATION } from 'constants/searchTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'

export const destinationTableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', DESTINATION)
  },
  {
    Header: 'Display Name',
    accessor: 'displayName',
    id: buildSortPropertyByType('displayName', DESTINATION)
  }
]
