/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { togglePercentageSymbol } from 'helpers/formHelpers'
import {
  SELECTOR_EXTRAS_PRICE,
  SELECTOR_PERCENTAGE_EXTRAS_PRICE,
  SELECTOR_XPLORIE_PRICE,
  SELECTOR_PERCENTAGE_XPLORIE_PRICE
} from './selectors'

export function onUpdateStateForm(formApi, priceSelector, percentageSelector) {
  const priceValue = formApi && formApi.getValue(priceSelector)
  if (priceValue) {
    const currentPriceValue = togglePercentageSymbol(priceValue, percentageSelector)
    formApi.setValue(priceSelector, currentPriceValue)
  }
}

export function getPercentageSelector(name) {
  switch (name) {
    case SELECTOR_XPLORIE_PRICE:
      return SELECTOR_PERCENTAGE_XPLORIE_PRICE
    case SELECTOR_EXTRAS_PRICE:
      return SELECTOR_PERCENTAGE_EXTRAS_PRICE
    default:
      return null
  }
}
