/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import { isToday } from 'helpers/dateHelpers'
import types from './types'

const { NAME, PMC_DEACTIVATION, PMC_ACTIVATION, ACTIVATION_SUCCESS_MESSAGE } = types

const REQUEST_PMC_DEACTIVATION = `${REQUEST_TYPE}_${PMC_DEACTIVATION}`
const RESPONSE_PMC_DEACTIVATION = `${RESPONSE_TYPE}_${PMC_DEACTIVATION}`
const RESPONSE_ERROR_PMC_DEACTIVATION = `${RESPONSE_ERROR_TYPE}_${PMC_DEACTIVATION}`

const REQUEST_PMC_ACTIVATION = `${REQUEST_TYPE}_${PMC_ACTIVATION}`
const RESPONSE_PMC_ACTIVATION = `${RESPONSE_TYPE}_${PMC_ACTIVATION}`
const RESPONSE_ERROR_PMC_ACTIVATION = `${RESPONSE_ERROR_TYPE}_${PMC_ACTIVATION}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

/**
 * @param {string} prevDate
 * @param {string} date
 * @returns {string}
 */
function buildSuccessDeactivatingMessage(prevDate, date) {
  if (isToday(date)) {
    return 'PMC has been deactivated.'
  }
  if (!prevDate) {
    return `Deactivation has been set to ${date}.`
  }
  return `Deactivation Date has been updated to ${date}.`
}

/**
 * @returns {string}
 */
function buildSuccessCancelDeactivatingMessage() {
  return 'Scheduled Deactivation has been canceled.'
}

export default {
  REQUEST_PMC_DEACTIVATION,
  RESPONSE_PMC_DEACTIVATION,
  RESPONSE_ERROR_PMC_DEACTIVATION,
  REQUEST_PMC_ACTIVATION,
  RESPONSE_PMC_ACTIVATION,
  RESPONSE_ERROR_PMC_ACTIVATION,
  RESET_DATA_STATE,
  ACTIVATION_SUCCESS_MESSAGE,
  buildSuccessDeactivatingMessage,
  buildSuccessCancelDeactivatingMessage
}
