/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { LocationForm } from 'modules/Locations'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { useDataStatesComparison } from 'hooks'

export function LocationTab(props) {
  const { onClose, closeDataStates } = props
  useDataStatesComparison(
    () => {
      onClose()
    },
    closeDataStates,
    DATA_STATE_RECIEVED
  )

  return <LocationForm {...props} />
}

LocationTab.propTypes = {
  onClose: PropTypes.func.isRequired,
  closeDataStates: PropTypes.array.isRequired
}
