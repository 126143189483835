/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions, selectors } from 'reducers/guestsData/programGuestsDataList'
import { withAuthContext } from 'helpers/withAuthContext'
import { GuestsList as GuestsListComponent } from './GuestsList'

const { getGuestsFileList, resetList, downloadFile } = actions
const { getProgramGuestsDataListState, getProgramGuestsDataListWatchers } = selectors

function mapStateToProps(state, { dataId }) {
  const listState = getProgramGuestsDataListState(state, dataId)
  const listWatchers = getProgramGuestsDataListWatchers(state, dataId)
  return {
    dataState: listState.dataState,
    data: listState.data,
    downloadDataState: listState.downloadDataState,
    listWatchers
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getGuestsFileList, resetList, downloadFile }, dispatch)
}

export const GuestsList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(GuestsListComponent)
