/*
* COPYRIGHT:     Copyright © 2018 Xplorie LLC
* Warning:       This product is protected by United States and international copyright laws.
*                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
*/
import React from 'react'
import PropTypes from 'prop-types'
import { FormContext } from 'context'

export function FormContent(props) {
  const { children, disabled, titleForm, className } = props
  return (
    <FormContext.Provider value={{ disabled }}>
      <div className={className}>
        {titleForm && <h3 className="subtitle">{titleForm}</h3>}
        {children}
      </div>
    </FormContext.Provider>
  )
}

FormContent.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,

  titleForm: PropTypes.string
}
FormContent.defaultProps = {
  disabled: true,
  className: '',
  titleForm: ''
}
