/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { BasicButton } from '@xplorie/ui-commons'
import classNames from 'classnames'
import { usePrevious } from 'hooks'
import { CalendarField, SelectField, TextField } from 'components/FormComponents'
import { HORIZONTAL_ALIGN } from 'constants/alignTypes'
import { TooltipComponent } from 'components/Tooltip'
import get from 'lodash/get'
import { newRateValidate } from './helpers'
import { TrashButton } from '../TrashButton'
import { CONFIRMATION_MODE_FIELD, CONFIRMATION_ID_VALUE } from '../PrebookForm/types'
import {
  PREBOOK_RATE_LABEL,
  RATE_VALUE_FIELD,
  START_VALUE_FIELD,
  RATE_LABEL,
  RATE_PLACEHOLDER,
  START_VALUE_LABEL,
  START_VALUE_PLACEHOLDER,
  HINT_MESSAGE,
  CHARGE_PER_NIGHT_VALUE,
  chargeMethodTypes,
  CHARGE_METHOD_FIELD,
  CHARGE_METHOD_PLACEHOLDER,
} from './types'

import styles from './PrebookRateForm.scss'

const PREBOOK_RATE_FIELDS = [RATE_VALUE_FIELD, START_VALUE_FIELD]

export function PrebookRateForm({ formApi, disabled, entityData, formState }) {
  const hasFormValues = Boolean(
    get(formState.values, RATE_VALUE_FIELD) || get(formState.values, START_VALUE_FIELD)
  )
  const prevHasFromValues = usePrevious(hasFormValues)

  const [showFields, setShowFieldsState] = useState(false)
  const prevShowFields = usePrevious(showFields)

  const onCleanFields = useCallback(
    () =>
      PREBOOK_RATE_FIELDS.forEach(field => {
        if (formApi.fieldExists(field)) {
          formApi.setValue(field, null)
          formApi.setError(field, null)
        }
      }),
    [formApi]
  )

  const onFillFormFields = useCallback(
    () =>
      PREBOOK_RATE_FIELDS.forEach(
        field => formApi.fieldExists(field) && formApi.setValue(field, get(entityData, field, null))
      ),
    [entityData, formApi]
  )

  useEffect(() => {
    if (!prevHasFromValues && prevHasFromValues !== hasFormValues) {
      setShowFieldsState(hasFormValues)
    }
  }, [hasFormValues, prevHasFromValues])

  useEffect(() => {
    if (prevShowFields !== showFields) {
      const formOperation = showFields ? onFillFormFields : onCleanFields
      formOperation()
    }
  }, [entityData, formApi, onCleanFields, onFillFormFields, prevShowFields, showFields])

  const onToggleShowFields = useCallback(() => {
    setShowFieldsState(!showFields)

    PREBOOK_RATE_FIELDS.forEach(field => {
      if (formApi.fieldExists(field)) {
        formApi.setTouched(field, true)
      }
    })
  }, [formApi, showFields])

  return (
    <section className={styles.wrapper}>
      {!disabled && !showFields && (
        <BasicButton
          className={styles.addButton}
          colorSchema={ORANGE_SCHEMA}
          onClick={onToggleShowFields}
        >
          {PREBOOK_RATE_LABEL}
        </BasicButton>
      )}

      <article className={classNames(styles.fieldsWrapper, { [styles.hide]: !showFields })}>
        <div className={classNames(styles.col, styles.fields)}>
          <TextField
            className={styles.input}
            fieldName={RATE_VALUE_FIELD}
            validate={newRateValidate}
            notify={[START_VALUE_FIELD, CONFIRMATION_MODE_FIELD, CONFIRMATION_ID_VALUE]}
            placeholder={RATE_PLACEHOLDER}
            fieldProps={{
              align: HORIZONTAL_ALIGN,
              renderHeader: () => <span className={styles.label}>{RATE_LABEL}</span>,
              // eslint-disable-next-line react/prop-types
              renderInner: ({ children, error, errorClassName }) => (
                <div className={styles.inputFieldsWrapper}>
                  <div>
                    <div>{children}</div>
                    {error && (
                      <span className={classNames(errorClassName, styles.error)}>{error}</span>
                    )}
                  </div>
                  <div className={styles.toggleFieldWrapper}>
                    <SelectField
                      fieldName={CHARGE_METHOD_FIELD}
                      options={chargeMethodTypes}
                      placeholder={CHARGE_METHOD_PLACEHOLDER}
                      initial={CHARGE_PER_NIGHT_VALUE}
                      isRequired
                    />
                  </div>
                </div>
              )
            }}
          />
          <CalendarField
            className={styles.input}
            fieldName={START_VALUE_FIELD}
            placeholder={START_VALUE_PLACEHOLDER}
            notify={[RATE_VALUE_FIELD, CONFIRMATION_MODE_FIELD, CONFIRMATION_ID_VALUE]}
            fieldProps={{
              align: HORIZONTAL_ALIGN,
              renderHeader: () => <span className={styles.label}>{START_VALUE_LABEL}</span>,
              // eslint-disable-next-line react/prop-types
              renderInner: ({ children, error, errorClassName }) => (
                <div className={styles.fieldWrapper}>
                  <div className={styles.inputWithHintWrapper}>
                    {children}
                    <TooltipComponent contentTooltip={HINT_MESSAGE} />
                  </div>
                  {error && (
                    <span className={classNames(errorClassName, styles.error)}>{error}</span>
                  )}
                </div>
              )
            }}
          />
        </div>
        <div className={styles.col}>
          <TrashButton onClick={onToggleShowFields} />
        </div>
      </article>
    </section>
  )
}

PrebookRateForm.propTypes = {
  formState: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  entityData: PropTypes.object.isRequired,
  formApi: PropTypes.object.isRequired
}
