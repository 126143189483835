/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'

import { ButtonWithPermission } from 'components/Buttons'
import { BasicButton } from '@xplorie/ui-commons'

import { APPROVE_LEVEL } from 'constants/permissionTypes'

export const RejectButton = props => {
  const { isValid } = props
  const isDisabled = !isValid
  return (
    <ButtonWithPermission
      accessLevel={APPROVE_LEVEL}
      Component={BasicButton}
      disabled={isDisabled}
      {...props}
    />
  )
}
RejectButton.propTypes = {
  isValid: PropTypes.bool
}
RejectButton.defaultProps = {
  isValid: true
}
