/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useEffect } from 'react'
import get from 'lodash/get'
import {
  actions,
  getDiscountDealTicketTypesFormStates
} from 'connectors/reducers/discountDeal/discountDealTicketTypesForm'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { usePrevious, useAction, useUpdateSomeDataState } from 'hooks'
import { useSelector } from 'react-redux'

export const useTicketTypesMediator = (state, api) => {
  const { optionsFormState } = state
  const { dealTicketTypes, dealOptions } = api

  const loadTicketTypes = useAction(actions.getTicketTypes)
  const { dataState, data } = useSelector(getDiscountDealTicketTypesFormStates)

  const activityId = get(dealOptions.getActivityFromValues(optionsFormState.values), 'id')
  const activityName = get(dealOptions.getActivityFromValues(optionsFormState.values), 'name')
  const type = dealOptions.getTypeFromValues(optionsFormState.values)

  const prevActivityId = usePrevious(activityId)
  const prevType = usePrevious(type)

  useEffect(() => {
    if ((prevActivityId !== activityId || (prevType !== type && !prevType)) && activityId && type) {
      loadTicketTypes(activityId)
    }
  }, [activityId, dealTicketTypes, loadTicketTypes, prevActivityId, prevType, type])

  // set title when activity changed
  useEffect(() => {
    if (prevActivityId !== activityId && activityId && activityName) {
      dealTicketTypes.setTitle(activityName)
    }
  }, [activityId, activityName, dealTicketTypes, prevActivityId])

  // groups loaded
  useUpdateSomeDataState(
    () => {
      dealTicketTypes.mergeTicketGroupsToTicketTypes(data)
    },
    [dataState],
    DATA_STATE_RECIEVED
  )
}
