/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { ACTIVITY_CATEGORY, LOCATION, PM_SYSTEM } from 'constants/searchTypes'

export const TITLE_PAGE = 'Library'
export const TEXT_BTN_ADD_NEW = 'Add New'

// library tabs types
export const ACTIVITY_CATEGORY_TYPE = ACTIVITY_CATEGORY
export const ACTIVITY_TAGS_TYPE = 'activity_tags'
export const ACTIVITY_MODULE_TYPE = 'activity_module'
export const LOCATIONS_TYPE = LOCATION
export const PMS_NAME_TYPE = PM_SYSTEM
export const RMS_NAME_TYPE = 'rms_name'
export const TILE_ACCORDIONS_TYPE = 'tile_accordions'
export const UNIQ_PHONE_NUMBER_TYPE = 'uniq_phone_num'
export const ELIGIBILITY_TYPE = 'eligibility'
export const MARKET_TYPE = 'markets'
