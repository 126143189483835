/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import { actions, getProgramUnitsListState } from 'reducers/units/programUnitsList'
import { withAuthContext } from 'helpers/withAuthContext'

import { SmartHostCell as SmartHostCellComponent } from './SmartHostCell'
import { CalendarIntegrationCell as CalendarIntegrationCellComponent } from './CalendarIntegrationCell'
import { EmailCadenceCell as EmailCadenceCellComponent } from './EmailCadenceCell'

export const mapStateToProps = (state, { dataId }) => {
  const programUnitsState = getProgramUnitsListState(state, dataId)

  return {
    programUnitsState
  }
}

export const mapDispatchToProps = dispatch => {
  const { updateUnitsSmartHostByProgramId: updateSmartHostByProgramId, ...rest } = actions
  return bindActionCreators({ updateSmartHostByProgramId, ...rest }, dispatch)
}

export const SmartHostCell = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SmartHostCellComponent)

export const CalendarIntegrationCell = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CalendarIntegrationCellComponent)

export const EmailCadenceCell = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EmailCadenceCellComponent)
