/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildListParams } from 'services/http/helpers'
import { SEASON_LIST_URL, SEASONS_LIST_BY_PROGRAM_URL } from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'

export const createBulkProgramSeasonList = (programId, contractData) =>
  httpWrapper({
    url: buildPath(SEASON_LIST_URL, { programId }),
    method: 'post',
    data: contractData
  })

export const getListByProgramId = (id, options) =>
  httpWrapper({
    url: buildPath(SEASONS_LIST_BY_PROGRAM_URL, { programId: id }),
    method: 'get',
    params: buildListParams(options)
  })
