/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'informed'
import isFunction from 'lodash/isFunction'
import {
  FormContent,
  TextField,
  CalendarField,
  RangePickerField,
  SwitchField
} from 'components/FormComponents'
import { HORIZONTAL_ALIGN } from 'constants/alignTypes'
import { CirclePlusIcon } from 'icons'
import classNames from 'classnames'
import {
  RANGE_FIELD_LABEL,
  RANGE_FIELD_PLACEHOLDER,
  RANGE_FIELD_FIELD,
  ADD_DAY_FIELD_LABEL,
  ADD_DAY_FIELD,
  CONFIRMED_BY_FIELD_LABEL,
  CONFIRMED_BY_FIELD_PLACEHOLDER,
  CONFIRMED_BY_FIELD_FIELD,
  REASON_FIELD_LABEL,
  REASON_FIELD_PLACEHOLDER,
  REASON_FIELD_FIELD,
  TIME_PERIODS_FIELD,
  TIME_SLOTS_LABEL,
  DATE_FIELD
} from './types'
import styles from './styles.scss'
import { TimePeriodsPopover } from './TimePeriodsPopover'
import { TimePeriodsList } from './TimePeriodsList/TimePeriodsList'
import { FULL_WEEK_VALUE } from '../types'

/**
 *
 * @param {Object} param0
 * @param {boolean} param0.disabled
 * @param {boolean} param0.isEdit
 * @param {*} param0.children
 * @param {Object} param0.closureApi
 * @param {Function} param0.closureApi.changeAllDay
 * @param {Function} param0.closureApi.getTimePeriod
 * @param {Function} param0.closureApi.addTimePeriod
 * @param {Function} param0.closureApi.editTimePeriod
 * @param {Function} param0.closureApi.removeTimePeriod
 * @param {Function} param0.closureApi.canSeeRemoveTimePeriod
 * @returns
 */
export function ClosureForm({ disabled, isEdit, children, closureApi, ...rest }) {
  const initialValues = {
    startTime: null,
    endTime: null,
    weekDays: FULL_WEEK_VALUE
  }

  return (
    <Form {...rest}>
      {formProps => {
        const { formState } = formProps
        const { allDay, date } = formState.values

        return (
          <FormContent disabled={disabled}>
            {date ? (
              <CalendarField
                label={RANGE_FIELD_LABEL}
                placeholder={RANGE_FIELD_PLACEHOLDER}
                fieldName={DATE_FIELD}
                disabled
                isRequired
              />
            ) : (
              <RangePickerField
                label={RANGE_FIELD_LABEL}
                placeholder={RANGE_FIELD_PLACEHOLDER}
                fieldName={RANGE_FIELD_FIELD}
                isRequired
              />
            )}
            <div className={styles.timePeriodWrapper}>
              <div className={styles.timePeriodLabelWrapper}>
                <span className={styles.label}>{TIME_SLOTS_LABEL}</span>
                {!isEdit && (
                  <TimePeriodsPopover
                    onSubmit={closureApi.addTimePeriod}
                    initialValues={initialValues}
                    disabled={allDay}
                  >
                    <CirclePlusIcon
                      width="1.1rem"
                      height="1.1rem"
                      className={classNames({ [styles.disabledPopover]: Boolean(allDay) })}
                    />
                  </TimePeriodsPopover>
                )}
              </div>
              <SwitchField
                label={ADD_DAY_FIELD_LABEL}
                field={ADD_DAY_FIELD}
                fieldProps={{
                  align: HORIZONTAL_ALIGN,
                  classNameLabel: styles.allDayLabel
                }}
                onChange={closureApi.changeAllDay}
              />
            </div>
            <TimePeriodsList
              timePeriods={closureApi.getTimePeriod()}
              onEditTimePeriod={closureApi.editTimePeriod}
              onRemoveTimePeriod={closureApi.removeTimePeriod}
              canSeeRemoveTimePeriod={closureApi.canSeeRemoveTimePeriod}
            />
            <TextField
              label={CONFIRMED_BY_FIELD_LABEL}
              placeholder={CONFIRMED_BY_FIELD_PLACEHOLDER}
              fieldName={CONFIRMED_BY_FIELD_FIELD}
              isRequired
            />
            <TextField fieldName={TIME_PERIODS_FIELD} type="hidden" />
            <TextField
              label={REASON_FIELD_LABEL}
              placeholder={REASON_FIELD_PLACEHOLDER}
              fieldName={REASON_FIELD_FIELD}
              isRequired
            />
            {isFunction(children) ? children(formProps) : children}
          </FormContent>
        )
      }}
    </Form>
  )
}

ClosureForm.propTypes = {
  disabled: PropTypes.bool,
  isEdit: PropTypes.bool,
  closureApi: PropTypes.shape({
    changeAllDay: PropTypes.func.isRequired,
    getTimePeriod: PropTypes.func.isRequired,
    addTimePeriod: PropTypes.func.isRequired,
    editTimePeriod: PropTypes.func.isRequired,
    removeTimePeriod: PropTypes.func.isRequired,
    canSeeRemoveTimePeriod: PropTypes.func.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
}

ClosureForm.defaultProps = {
  isEdit: false,
  disabled: false,
  children: null
}
