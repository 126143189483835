/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSliderApi } from 'hooks'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, SMALLER_SIZE, VIEW_TITLE } from 'constants/buttonsOptions'
import { DEAL } from 'constants/formCodes'

export function EditButton(props) {
  const { data, readOnly } = props
  const dealSliderApi = useSliderApi(DEAL)

  const onClickHandler = useCallback(() => {
    const { id, name, status } = data.original
    dealSliderApi.open(id, { headerProps: { title: name, info: status }, props: { readOnly } })
  }, [data.original, readOnly, dealSliderApi])

  return (
    <BasicButton size={SMALLER_SIZE} colorSchema={ORANGE_SCHEMA} onClick={onClickHandler}>
      {VIEW_TITLE}
    </BasicButton>
  )
}

EditButton.propTypes = {
  authUser: PropTypes.object.isRequired,
  data: PropTypes.object,
  readOnly: PropTypes.bool.isRequired
}

EditButton.defaultProps = {
  data: null,
  readOnly: false
}
