/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export { getAccordionList } from './entityList'
export {
  createTileAccordion,
  updateTileAccordion,
  getTileAccordionById,
  deleteTileAccordionById
} from './oneEntity'
