import { actionCreater } from 'helpers/actionHelpers'
import { searchByColumns } from 'services/http/search'

import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  getRequestFilters,
  getRequestOptions
} = helpers

const getActivityCategoryAppsById = (
  activityCategoryId,
  options = {},
  pageType
) => async dispatch => {
  const key = activityCategoryId
  dispatch(actionCreater(REQUEST_LIST, { key }))
  const request = await searchByColumns(
    getRequestFilters(activityCategoryId, pageType),
    getRequestOptions(options)
  ).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST, { key }))
  })

  if (request.data) {
    dispatch(actionCreater(RESPONSE_LIST, { data: request.data, key }))
    return 'getActivityCategoryAppsById success'
  }

  return 'getActivityCategoryAppsById failed'
}

const resetList = activityCategoryId => dispatch =>
  dispatch(actionCreater(RESET_DATA_STATE, { key: activityCategoryId }))

export default {
  getActivityCategoryAppsById,
  resetList
}
