import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import {
  AND_OPERATOR,
  buildSearchColumn,
  ACTIVITY_PROVIDER,
  ACTIVITY_PROVIDER_CATEGORY,
  ACTIVITY_CATEGORY,
  STRICT_MODE,
  NULL_MODE
} from 'helpers/searchHelpers'
import { STATUS_ACTIVE } from 'constants/entityStatus'
import { isNullable } from 'helpers/utilities'
import types from './types'

const { LIST_NAME } = types

const REQUEST_LIST = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

const STATUS_FIELD = 'status'
const ACTIVITY_CATEGORY_ID_FIELD = 'activity_category_id'
const ACTIVITY_CATEGORY_ID_FIELD_NULLABLE = 'activityCategoryId'

const getRequestFilters = id => {
  if (isNullable(id)) {
    return [
      buildSearchColumn({
        entity: ACTIVITY_PROVIDER,
        field: STATUS_FIELD,
        keyword: STATUS_ACTIVE,
        mode: STRICT_MODE
      }),
      buildSearchColumn({
        entity: ACTIVITY_PROVIDER_CATEGORY,
        field: ACTIVITY_CATEGORY_ID_FIELD_NULLABLE,
        mode: NULL_MODE,
        operator: AND_OPERATOR
      })
    ]
  }

  return [
    buildSearchColumn({
      entity: ACTIVITY_CATEGORY,
      field: ACTIVITY_CATEGORY_ID_FIELD,
      keyword: id,
      mode: STRICT_MODE
    }),
    buildSearchColumn({
      entity: ACTIVITY_PROVIDER,
      field: STATUS_FIELD,
      keyword: STATUS_ACTIVE,
      operator: AND_OPERATOR,
      mode: STRICT_MODE
    })
  ]
}

const getRequestOptions = options => ({ type: ACTIVITY_PROVIDER, ...options })

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  getRequestOptions,
  getRequestFilters
}
