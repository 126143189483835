/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAction, useRootModal } from 'hooks'
import { useSelector } from 'react-redux'
import {
  actions,
  getAPPResellSearchList
} from 'reducers/activityProduct/activityProductResellSearch'

import { Tag } from '@xplorie/ui-commons'

import { TypeaheadField } from 'components/FormComponents'
import { withFormContext } from 'components/FormComponents/helpers'
import { ACTIVITY, RESALE_SETTINGS } from 'constants/searchTypes'
import get from 'lodash/get'
import {
  OR_OPERATOR,
  buildSearchColumn,
  LIKE_START_WITH_MODE,
  OPEN_GROUP_OPERATOR,
  CLOSE_GROUP_OPERATOR,
  AND_OPERATOR,
  STRICT_MODE
} from 'helpers/searchHelpers'
import { STATUS_ACTIVE, STATUS_TESTING } from 'constants/entityStatus'
import { useDealOptionsState, useDealOptionsApi } from './hooks'
import styles from './styles.scss'
import {
  SELECT_APP_DISCOUNT_DEAL_SEARCH_KEY,
  APP_LABEL,
  APP_PLACEHOLDER,
  ACTIVITY_FIELD,
  IN_TESTING_LABEL,
  DATE_PERIOD_LABEL,
  DATE_SPECIFIC_LABEL
} from './types'
import { isPeriodSpecificActivity } from '../helpers'
import { ChangeAPPWarningModal } from './ChangeAPPWarningModal'
import { useDealPeriodsApi } from '../DiscountPeriods/hooks'
import { TITLE, MESSAGE } from './ChangeAPPWarningModal/types'
import { PERIOD_SPECIFIC } from '../DiscountPeriods/types'
import { useDealApi } from '../hooks'

function SelectAppFieldComponent({ disabled }) {
  const clear = useAction(actions.resetList.bind(null, SELECT_APP_DISCOUNT_DEAL_SEARCH_KEY))
  const search = useAction(
    actions.getAPPResellByKeyword.bind(null, SELECT_APP_DISCOUNT_DEAL_SEARCH_KEY)
  )
  const { data } = useSelector(state =>
    getAPPResellSearchList(state, SELECT_APP_DISCOUNT_DEAL_SEARCH_KEY)
  )
  const rootModal = useRootModal()
  const optionsFormState = useDealOptionsState()
  const dealOptionsApi = useDealOptionsApi()
  const dealPeriodsApi = useDealPeriodsApi()
  const api = useDealApi()
  const isNewDiscount = useMemo(() => !api.getId(), [api])
  const isDisabledApp = useMemo(() => !dealOptionsApi.canChangeApp() || disabled, [
    dealOptionsApi,
    disabled
  ])
  const isPeriodSpecific = isPeriodSpecificActivity(optionsFormState.values)

  const initValue = useMemo(() => get(optionsFormState, 'values.activity.name', ''), [
    optionsFormState
  ])

  const renderSuggestion = useCallback(suggestion => {
    const isTestingApp = get(suggestion, 'parameters.status') === STATUS_TESTING
    return (
      <div className={styles.suggestion}>
        <span>{suggestion.name}</span>
        {isTestingApp && <span className={styles.status}>{IN_TESTING_LABEL}</span>}
      </div>
    )
  }, [])

  const onSearch = useCallback(
    keyword => {
      search(
        [
          buildSearchColumn({
            entity: ACTIVITY,
            field: 'status',
            keyword: STATUS_ACTIVE,
            mode: LIKE_START_WITH_MODE,
            groupOperator: OPEN_GROUP_OPERATOR
          }),
          buildSearchColumn({
            entity: ACTIVITY,
            field: 'status',
            operator: OR_OPERATOR,
            keyword: STATUS_TESTING,
            mode: LIKE_START_WITH_MODE,
            groupOperator: CLOSE_GROUP_OPERATOR
          }),
          buildSearchColumn({
            entity: RESALE_SETTINGS,
            field: 'retailDeactivated',
            operator: AND_OPERATOR,
            keyword: false,
            mode: STRICT_MODE
          }),
          buildSearchColumn({
            entity: RESALE_SETTINGS,
            field: 'stripeEnabled',
            operator: AND_OPERATOR,
            keyword: true,
            mode: STRICT_MODE
          }),
          buildSearchColumn({
            entity: ACTIVITY,
            field: 'name',
            operator: AND_OPERATOR,
            keyword
          })
        ],
        { itemsPerPage: 20, keyword: keyword || '' }
      )
    },
    [search]
  )

  const resetDealPeriods = () => {
    dealPeriodsApi.setValues([])
  }

  const onBeforeChange = (nextState, onChange, onChangeHandle) => {
    const previousState = get(optionsFormState, 'values.activity')

    if (
      !isNewDiscount &&
      previousState &&
      previousState.parameters.specificType !== nextState.parameters.specificType
    ) {
      rootModal.enqueue(ChangeAPPWarningModal, {
        title: TITLE,
        isOpen: true,
        message: MESSAGE,
        onClose: rootModal.dequeue,
        onSubmit: () => {
          onChange(nextState)
          resetDealPeriods()
        },
        onCancel: () => {
          onChange(previousState)
          onChangeHandle(previousState.name)
        }
      })
    } else {
      if (!previousState && nextState.parameters.specificType === PERIOD_SPECIFIC) {
        resetDealPeriods()
      }
      onChange(nextState)
    }
  }

  const PeriodTag = initValue && (
    <div className={styles.tagWrapperSearch}>
      <Tag>{isPeriodSpecific ? DATE_PERIOD_LABEL : DATE_SPECIFIC_LABEL}</Tag>
    </div>
  )

  return (
    <TypeaheadField
      label={APP_LABEL}
      fieldName={ACTIVITY_FIELD}
      suggestions={data}
      suggestionsFetch={onSearch}
      clearSuggestions={clear}
      onValueConverter={value => ({ title: value.name, value })}
      placeholder={APP_PLACEHOLDER}
      initValue={initValue}
      disabled={isDisabledApp}
      renderer={renderSuggestion}
      onBeforeChange={onBeforeChange}
      isRequired
    >
      {PeriodTag}
    </TypeaheadField>
  )
}

SelectAppFieldComponent.propTypes = {
  disabled: PropTypes.bool.isRequired
}

export const SelectAppField = withFormContext(SelectAppFieldComponent)
