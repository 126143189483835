/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import { getProgramDataState } from 'reducers/programsForm'
import { actions, getProgramUnitsListState } from 'reducers/units/programUnitsList'
import { withAuthContext } from 'helpers/withAuthContext'

import { ProgramUnits as ProgramUnitsComponent } from './ProgramUnits'

export const mapStateToProps = (state, { dataId }) => {
  const programUnitsState = getProgramUnitsListState(state, dataId)
  const programState = getProgramDataState(state, dataId)

  return {
    ...programUnitsState,
    programDataState: programState.programDataState
  }
}

export const mapDispatchToProps = dispatch => {
  const { getUnitsByProgramIdList: onGetData, ...rest } = actions
  return bindActionCreators({ onGetData, ...rest }, dispatch)
}

export const ProgramUnits = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProgramUnitsComponent)
