// Unit status note types

export const CONFIRMATION_REQUIRED_TYPE = 'CONFIRMATION_REQUIRED'
export const CONFIRMATION_REQUIRED_VALUE = 'Confirmation Required'
export const CONFIRMATION_REQUIRED_DESCRIPTION = 'Confirmation required'

export const CONFIRMED_TYPE = 'CONFIRMED'
export const CONFIRMED_VALUE = 'Confirmed'

export const SYSTEM_CONFIRMED_TYPE = 'SYSTEM_CONFIRMED'
export const SYSTEM_CONFIRMED_VALUE = 'System Confirmed'
export const SYSTEM_CONFIRMED_DESCRIPTION = 'System confirmed'

export const SYSTEM_UPDATED_TYPE = 'SYSTEM_UPDATED'
export const SYSTEM_UPDATED_VALUE = 'System Updated'
export const SYSTEM_UPDATED_DESCRIPTION = 'System updated'

export const LEAVING_MANAGEMENT_TYPE = 'LEAVING_MANAGEMENT'
export const LEAVING_MANAGEMENT_VALUE = 'Leaving Management'
export const LEAVING_MANAGEMENT_DESCRIPTION = 'Leaving management'

export const DUPLICATE_TYPE = 'DUPLICATE'
export const DUPLICATE_VALUE = 'Duplicate'

export const OUTSIDE_MARKET_TYPE = 'OUTSIDE_MARKET'
export const OUTSIDE_MARKET_VALUE = 'Outside Market'
export const OUTSIDE_MARKET_DESCRIPTION = 'Outside market'

export const EXCLUDE_FROM_PROGRAM_TYPE = 'EXCLUDE_FROM_PROGRAM'
export const EXCLUDE_FROM_PROGRAM_VALUE = 'Exclude from Program'
export const EXCLUDE_FROM_PROGRAM_DESCRIPTION = 'Exclude from program'

export const NOT_ON_XPLORIE_TYPE = 'NOT_ON_XPLORIE'
export const NOT_ON_XPLORIE_VALUE = 'Not on Xplorie'

export const PENDING_CHANGE_TYPE = 'PENDING_CHANGE'
export const PENDING_CHANGE_VALUE = 'Pending Change'
export const PENDING_CHANGE_DESCRIPTION = 'Pending change'
