/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { anyNumberValidation } from 'helpers/formHelpers'
import {
  SEPARATOR,
  START_NUMBER_REQUARED_MESSAGE,
  END_NUMBER_REQUARED_MESSAGE,
  START_END_RANGE_MESSAGE
} from './types'

export function numberMask(value) {
  if (!value) {
    return ''
  }
  return String(value).replace(/\D/g, '')
}

export function getStartNumberFromValue(value) {
  if (!value) {
    return ''
  }
  return String(value).split(SEPARATOR)[0] || ''
}

export function getEndNumberFromValue(value) {
  if (!value) {
    return ''
  }
  return String(value).split(SEPARATOR)[1] || ''
}

export function numberRangeValidation(value) {
  const startNumber = getStartNumberFromValue(value)
  const endNumber = getEndNumberFromValue(value)
  if (!startNumber) {
    return START_NUMBER_REQUARED_MESSAGE
  }
  if (!endNumber) {
    return END_NUMBER_REQUARED_MESSAGE
  }
  const startNotNumberError = anyNumberValidation(startNumber)
  const endNotNumberError = anyNumberValidation(endNumber)
  if (startNotNumberError) {
    return startNotNumberError
  }
  if (endNotNumberError) {
    return endNotNumberError
  }
  return Number(startNumber) >= Number(endNumber) ? START_END_RANGE_MESSAGE : null
}

export function buildValue(startNumber, endNumber) {
  return `${startNumber}${SEPARATOR}${endNumber}`
}

export function getNumberRange(value) {
  const start = +getStartNumberFromValue(value)
  const end = +getEndNumberFromValue(value)

  return { start, end }
}
