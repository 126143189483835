/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { actions } from 'reducers/editQueue'
import { SliderContainer as SliderContainerComponent } from './SliderContainer'

const mapStateToProps = state => ({
  ...state.editQueue,
  authUser: state.currentUser.authUser,
  authDataState: state.currentUser.dataState
})

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

export const SliderContainer = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SliderContainerComponent)
