/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import withAuthorization from 'helpers/withAuthorization'
import { actions, getColumnSearchStateByType } from 'reducers/search/columnSearch'
import { HomeSearch as HomeSearchComponent } from './HomeSearch'

import { SEARCH_KEY } from './types'

const mapStateToProps = state => {
  const searchState = getColumnSearchStateByType(state, SEARCH_KEY)
  return { ...searchState }
}

const mapDispatchToProps = dispatch => {
  const { searchByType, resetStateByType } = actions
  return bindActionCreators(
    {
      resetSearch: resetStateByType.bind(null, SEARCH_KEY),
      searchByType: searchByType.bind(null, SEARCH_KEY)
    },
    dispatch
  )
}

export const HomeSearch = compose(
  withAuthorization,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HomeSearchComponent)
