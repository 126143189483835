/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const FORM_NAME = 'DISCOUNT_DEAL_FORM'
export const ACTIVATE_DEAL = `${FORM_NAME}_ACTIVATE_DEAL`

export default {
  FORM_NAME,
  ACTIVATE_DEAL
}
