/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Input } from '@xplorie/ui-commons'
import { getStartNumberFromValue, getEndNumberFromValue, buildValue } from './helpers'

import styles from './NumberPeriod.scss'

export function NumberPeriod(props) {
  const {
    fieldState: { value },
    fieldApi: { setValue, setTouched },
    startNumber: startNumberProps,
    endNumber: endNumberProps,
    errorClassName,
    onChange,
    disabled
  } = props

  const startNumberMask = useCallback(
    startNumber => buildValue(startNumber, getEndNumberFromValue(value)),
    [value]
  )
  const endNumberMask = useCallback(
    endNumber => buildValue(getStartNumberFromValue(value), endNumber),
    [value]
  )

  const onChangeHandler = useCallback(
    mask => event => {
      const { value: newValue } = event.target
      setValue(mask(newValue))
      setTouched(true)
      if (onChange) {
        onChange(newValue, event)
      }
    },
    [onChange, setTouched, setValue]
  )

  return (
    <div className={styles.wrapper}>
      <Input
        value={getStartNumberFromValue(value)}
        onChange={onChangeHandler(startNumberMask)}
        type="number"
        className={errorClassName}
        disabled={disabled}
        {...startNumberProps}
      />
      <Input
        value={getEndNumberFromValue(value)}
        onChange={onChangeHandler(endNumberMask)}
        type="number"
        className={errorClassName}
        disabled={disabled}
        {...endNumberProps}
      />
    </div>
  )
}

NumberPeriod.propTypes = {
  fieldState: PropTypes.object.isRequired,
  fieldApi: PropTypes.object.isRequired,
  startNumber: PropTypes.object,
  endNumber: PropTypes.object,
  errorClassName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
NumberPeriod.defaultProps = {
  endNumber: {},
  startNumber: {},
  onChange: null,
  disabled: false
}
