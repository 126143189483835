/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDidMount, useSliderApi } from 'hooks'
import { ContentWithNav } from 'components/ContentWithNav'
import { ButtonsWrapper, HeaderWrapper } from 'components/Wrappers'
import { PMCsList } from 'modules/PMCs/PMCsList'
import { PM_COMPANY } from 'constants/searchTypes'
import { pmcTableColumns } from 'helpers/dataTablesHelpers'
import { DotsButtonWithExport } from 'modules/ExportEntity'
import withAuthorization from 'helpers/withAuthorization'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { RIGHT_ALIGN } from 'constants/alignTypes'
import { FilteredSearch, TableField } from 'modules/FilteredSearch'
import { FormFilter } from 'components/FormComponents'
import { PMC } from 'constants/formCodes'
import {
  EXPORT_FILENAME,
  PMC_FILTERS,
  PMC_INITIAL_FILTERS,
  TEXT_BTN_ADD_NEW,
  TITLE_PAGE
} from './types'
import { buildFilters, canAddNewPmc } from './helpers'
import styles from './PMCs.scss'

function PMCs(props) {
  const { onGetData, authUser } = props
  const pmcSlider = useSliderApi(PMC)
  const filteredSearchApi = useRef()

  const onAddNew = useCallback(() => {
    pmcSlider.open()
  }, [pmcSlider])

  const onChangeHandler = useCallback(
    ({ filters, params }) => {
      onGetData(buildFilters(filters), params)
    },
    [onGetData]
  )

  const onGetApi = useCallback(api => {
    filteredSearchApi.current = api
  }, [])

  const getFilters = useCallback(() => buildFilters(filteredSearchApi.current.getFilters()), [])
  const getParams = useCallback(() => filteredSearchApi.current.getParams(), [])

  const buildDotsButtonOptions = useCallback(() => {
    if (canAddNewPmc(authUser)) {
      return [
        {
          label: TEXT_BTN_ADD_NEW,
          props: { onClick: onAddNew }
        }
      ]
    }

    return []
  }, [onAddNew, authUser])

  useDidMount(() => {
    filteredSearchApi.current.emitChange()
  })

  return (
    <ContentWithNav>
      <FilteredSearch
        getApi={onGetApi}
        onChange={onChangeHandler}
        initialFilters={PMC_INITIAL_FILTERS}
      >
        <section className={styles.customWrapper}>
          <HeaderWrapper title={TITLE_PAGE} />
          <div className={styles.containerWrapper}>
            <ButtonsWrapper className={styles.buttonsPanelWithFilters} align={RIGHT_ALIGN}>
              <Column className={styles.customColumnWrapper}>
                <DotsButtonWithExport
                  options={buildDotsButtonOptions()}
                  onGetFilters={getFilters}
                  onGetParams={getParams}
                  entity={PM_COMPANY}
                  columns={pmcTableColumns}
                  filename={EXPORT_FILENAME}
                />
              </Column>
              <Column>
                <div className={styles.filterBarWrapper}>
                  <FormFilter filters={PMC_FILTERS} />
                </div>
              </Column>
            </ButtonsWrapper>
            <TableField
              enqueue={props.enqueue}
              Component={PMCsList}
              // TODO: Need adjust and refactor it with reimplement this functionality.
              needFirstGetData={false}
            />
          </div>
        </section>
      </FilteredSearch>
    </ContentWithNav>
  )
}

PMCs.propTypes = {
  enqueue: PropTypes.func.isRequired,
  onGetData: PropTypes.func.isRequired,
  exportEntityData: PropTypes.func.isRequired,
  exportDataState: PropTypes.string.isRequired,
  authUser: PropTypes.object.isRequired
}

export default withAuthorization(PMCs)
