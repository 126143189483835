/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const SEPARATOR = '|'

export const START_DATE_REQUARED_MESSAGE = 'Start Date is Requared'
export const END_DATE_REQUARED_MESSAGE = 'End Date is Requared'

export const INCORRECT_RANGE_MESSAGE = 'Start Date should be less then End Date'
