/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getList } from 'services/http/pms'
import { PM_SYSTEM } from 'constants/searchTypes'
import { buildSortPropertyByType } from 'helpers/tableHelpers'
import get from 'lodash/get'

import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST } = helpers

const getPMSList = options => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const sort = get(options, 'sort', buildSortPropertyByType('name', PM_SYSTEM))
  const request = await getList({ ...options, sort }).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { ...request.data }))
    return 'getPMSList success'
  }
  return 'getPMSList failed'
}

export default {
  getPMSList
}
