/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { groupOptions } from 'mockData/orGroupOptions'

export const SUBMIT_BUTTON_TEXT = 'Submit'
export const NO_AUTH_USER_ERROR = 'NO_AUTH_USER_ERROR'
export const METHOD_IS_NOT_FUNCTION_ERROR = 'METHOD_IS_NOT_FUNCTION_ERROR'
export const ACTIVITY_STATUS = 'ACTIVE'
export const OPERATION_SUCCESS = 'OPERATION_SUCCESS'
export const ACTIVITY_PROPERTY = 'ACTIVITY'

export const RATE_CONFLICT_DAY_TOOLTIP =
  'There is a conflict between Season range and existing Rate Adjustments.'

export const BTN_TEXT_SUBMIT = 'Submit'
export const BTN_TEXT_REMOVE = 'Remove'

export const LABEL_ACTIVITY_CATEGORY = 'Activity category'
export const LABEL_OPEN = 'Open'
export const LABEL_CLOSE = 'Close'

export const NO_VALUE_MESSAGE = 'Not Indicated'
export const ACTIVITY_BOX_PLACEHOLDER = 'Start typing to adding activity'
export const ACTIVITY_BOX_TITLE = 'Activities'
export const ACTIVITY_BOX_LABEL = 'Select activity from drop-down list'
export const defaultGroup = {
  groupId: 0,
  groupName: groupOptions.find(group => group.value === 0)
}

export const LABEL_SEASON_NAME = 'Season Name'
export const LABEL_START_DATE = 'Start Date'
export const LABEL_END_DATE = 'End Date'
export const LABEL_XPLORIE_DATA = 'Xplorie Data'
export const LABEL_FEE = 'Fee'

export const PLACEHOLDER_SEASON_NAME = 'Season Name'
export const PLACEHOLDER_START_DATE = 'Start Date'
export const PLACEHOLDER_END_DATE = 'End Date'
export const PLACEHOLDER_FEE = 'Fee'

export const ACTIVITY_AVAILABILITY_ERROR_MESSAGE =
  'Activity does not have available dates during the period'

export const LABEL_CHARGE_METHOD = 'Fee type'
export const PLACEHOLDER_CHARGE_METHOD = 'Select one...'
export const CHARGE_METHOD_LABEL = 'Charge Method'
export const CHARGE_PER_NIGHT_VALUE = 'NIGHT'
export const CHARGE_PER_NIGHT_LABEL = 'Per Night'
export const CHARGE_PER_STAY_VALUE = 'STAY'
export const CHARGE_PER_STAY_LABEL = 'Per Stay'
export const CHARGE_PER_UNIT_VALUE = 'UNIT'
export const CHARGE_PER_UNIT_LABEL = 'Per Unit'
export const chargeMethodTypes = [
  { value: CHARGE_PER_UNIT_VALUE, label: CHARGE_PER_UNIT_LABEL },
  { value: CHARGE_PER_NIGHT_VALUE, label: CHARGE_PER_NIGHT_LABEL },
  { value: CHARGE_PER_STAY_VALUE, label: CHARGE_PER_STAY_LABEL }
]
