/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const getDealFormState = state => state.deals.dealForm
export const getListWatcherStates = state => {
  const {
    createOptionsDataState,
    updateOptionsDataState,
    deleteDataState,
    activateDataState,
    deactivateDataState
  } = getDealFormState(state)
  return [
    createOptionsDataState,
    updateOptionsDataState,
    deleteDataState,
    activateDataState,
    deactivateDataState
  ]
}
