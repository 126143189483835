/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { NAME, UPDATE_XPLORIE, CHECK_UPDATE_PROGRESS } from './types'

const REQUEST_UPDATE_XPLORIE = `${REQUEST_TYPE}_${UPDATE_XPLORIE}`
const RESPONSE_UPDATE_XPLORIE = `${RESPONSE_TYPE}_${UPDATE_XPLORIE}`
const RESPONSE_UPDATE_XPLORIE_ERROR = `${RESPONSE_ERROR_TYPE}_${UPDATE_XPLORIE}`

const REQUEST_CHECK_UPDATE_PROGRESS = `${REQUEST_TYPE}_${CHECK_UPDATE_PROGRESS}`
const RESPONSE_CHECK_UPDATE_PROGRESS = `${RESPONSE_TYPE}_${CHECK_UPDATE_PROGRESS}`
const RESPONSE_CHECK_UPDATE_PROGRESS_ERROR = `${RESPONSE_ERROR_TYPE}_${CHECK_UPDATE_PROGRESS}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

export default {
  REQUEST_UPDATE_XPLORIE,
  RESPONSE_UPDATE_XPLORIE,
  RESPONSE_UPDATE_XPLORIE_ERROR,
  REQUEST_CHECK_UPDATE_PROGRESS,
  RESPONSE_CHECK_UPDATE_PROGRESS,
  RESPONSE_CHECK_UPDATE_PROGRESS_ERROR,
  RESET_DATA_STATE
}
