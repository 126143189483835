/*
 * COPYRIGHT:     Copyright © 2018 - 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */
import { EXPERIENCE_LIGHT, EXPERIENCE_PRO, SMARTHOST_STANDALONE } from 'constants/programTypes'

export const LOADING_MESSAGE = 'Loading...'
export const SUBMIT_BUTTON_TEXT = 'Submit for approval'
export const STATUS_DRAFT = 'DRAFT'
export const STATUS_PENDING = 'PENDING'
export const STATUS_ACTIVE = 'ACTIVE'
export const MARKET_LABEL = 'Market'
export const MARKET_PLACEHOLDER = 'Market'
export const LABEL_DESTINATION = 'Destination'
export const PLACEHOLDER_DESTINATION = 'Select Destination'
export const SEASONS_TITLE_SPOT = 'Seasons'
export const CONFIRMATION_BUTTON_TEXT = 'Yes, submit.'
export const BASIC_BTN_VALUE = 'Initiate Update'
export const DRAFT_IN_PROGRESS_BTN = 'Draft In Progress'
export const LABEL_TIME_ZONE = 'Time Zone'
export const PLACEHOLDER_TIME_ZONE = 'Select Time Zone...'
export const LABEL_UNIT_COUNT = 'Unit Count'
export const PLACEHOLDER_UNIT_COUNT = 'Unit Count'
export const LABEL_PROGRAM_NAME = 'Program Name'
export const PLACEHOLDER_PROGRAM_NAME = 'Program Name'
export const LABEL_PMC_NAME = 'PMC Name'
export const PLACEHOLDER_PMC_NAME = 'PMC Name'
export const LABEL_PROGRAM_COMMENCEMENT_DATE = 'Program Commencement Date'
export const PLACEHOLDER_PROGRAM_COMMENCEMENT_DATE = 'Program Commencement Date'
export const LABEL_XE_PROGRAM_ID = 'XE Program ID'
export const PLACEHOLDER_XE_PROGRAM_ID = 'XE Program ID'
export const NETSUITE_ID_LADEL = 'NetSuite Internal ID'
export const PLACEHOLDER_NETSUITE_ID = 'NetSuite Internal ID'
export const COMPANY_ID_LABEL = 'Company Id'
export const COMPANY_ID_PLACEHOLDER = 'Add id (then press enter)'
export const BILLING_TYPE_FIELD_LABEL = 'Billing Type'
export const BILLING_TYPE_FIELD_PLACEHOLDER = 'Select Billing Type'

// Messages
export const NO_PMC_MESSAGE = 'Will be available after selecting PMC'

// Other labels for form
export const LABEL_UNIC_PHONE_NUMBER = 'Unique Phone Number'
export const LABEL_KEYWORD = 'Keyword'
export const LABEL_XPLORIE_MOBILE_URL = 'Xplorie Mobile URL'

// Texts for placeholders of form
export const PLACEHOLDER_UNIC_PHONE_NUMBER = 'Unique Phone Number'
export const PLACEHOLDER_KEYWORD = 'Keyword'
export const PLACEHOLDER_XPLORIE_MOBILE_URL = 'Xplorie Mobile URL'
export const UNASSIGN_TITLE_BUTTON = 'Unassign'
export const ASSIGN_TITLE_BUTTON = 'Assign'

export const SEARCH_PMC_KEY = 'SEARCH_PMC_IN_PROGRAM_FORM'

// field keys
export const NAME_FIELD_KEY = 'name'
export const PMC_NAME_FIELD_KEY = 'pmCompany.id'
export const LOCATION_FIELD_KEY = 'location.id'
export const BILLING_TYPE_FIELD_KEY = 'billingType'
export const COMPANY_ID_FIELD_KEY = 'companyIds'
export const PROGRAM_ON_BOARDED_FIELD_KEY = 'onBoarded'
export const PROGRAM_STATUS_FIELD_KEY = 'status'

// type for identification smartHost fee error
export const SMARTHOST_FIELD_KEY = 'smartHost'

// program type field
export const PROGRAM_TYPE_FIELD_LABEL = 'Program type'
export const PROGRAM_TYPE_FIELD_KEY = 'type'
export const PROGRAM_TYPE_FIELD_PLACEHOLDER = 'Select Program type'
export const PROGRAM_TYPE_OPTIONS = [
  { label: 'SmartHost Standalone', value: SMARTHOST_STANDALONE },
  { label: 'Experience Pro', value: EXPERIENCE_PRO },
  { label: 'Experience Light', value: EXPERIENCE_LIGHT }
]

// Program Renewal Date
export const PROGRAM_RENEWAL_DATE_LABEL = 'Program Renewal Date'
export const PROGRAM_RENEWAL_DATE_PLACEHOLDER = 'Program Renewal Date'
export const PROGRAM_RENEWAL_DATE_FIELD = 'renewalDate'

// Disable Units
export const DISABLE_UNITS_LABEL = 'Disable Units'
export const DISABLE_UNITS_FIELD = 'unitsDisabled'

// Disable Reservation
export const DISABLE_RESERVATION_LABEL = 'Disable Reservation #'
export const DISABLE_RESERVATION_FIELD = 'reservationNumbersDisabled'

// On E-Commerce
export const ON_E_COMMERCE_LABEL = 'On E-Commerce'
export const ON_E_COMMERCE_FIELD = 'onECommerce'
