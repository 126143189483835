/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { TOP_DIRECTION, RIGHT_DIRECTION, BOTTOM_DIRECTION, LEFT_DIRECTION } from './types'

import styles from './TooltipComponent.scss'

export const getDirectionStyle = option => {
  switch (option) {
    case TOP_DIRECTION:
      return styles.tooltipContentTopDirection
    case RIGHT_DIRECTION:
      return styles.tooltipContentRightDirection
    case BOTTOM_DIRECTION:
      return styles.tooltipContentBottomDirection
    case LEFT_DIRECTION:
      return styles.tooltipContentLeftDirection
    default:
      return styles.tooltipContentTopDirection
  }
}
