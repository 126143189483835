/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  updateProgramEligibilityValues,
  saveProgramEligibilityProperties
} from 'services/http/eligibility'
import get from 'lodash/get'
import helpers from './helpers'

const {
  REQUEST_ELIGIBILITY_ASSIGN_BULK,
  RESPONSE_ELIGIBILITY_ASSIGN_BULK,
  RESPONSE_ERROR_ELIGIBILITY_ASSIGN_BULK,
  RESET_DATA_STATE,
  buildUpdatedMessage
} = helpers

const updateEligibilityParamsByProgram = (id, data) => async dispatch => {
  const key = id
  try {
    dispatch(actionCreater(REQUEST_ELIGIBILITY_ASSIGN_BULK, { key }))
    const effectivenessDate = get(data, 'effectivenessDate')
    const values = get(data, 'values', [])
    const request = await updateProgramEligibilityValues(id, values)
    await saveProgramEligibilityProperties(id, effectivenessDate)
    dispatch(actionCreater(RESPONSE_ELIGIBILITY_ASSIGN_BULK, { key }))
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildUpdatedMessage() }))
    return 'updateEligibilityParamsByProgram success'
  } catch ({ send, message }) {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_ELIGIBILITY_ASSIGN_BULK, { key }))
    return 'updateEligibilityParamsByProgram failed'
  }
}

const resetState = id => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key: id }))

export default {
  updateEligibilityParamsByProgram,
  resetState
}
