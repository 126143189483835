/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function DataHouseIcon(props) {
  return (
    <svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.584 3.792H5.417c-.596 0-1.083.487-1.083 1.083v3.25c0 .596.487 1.083 1.083 1.083h15.166c.596 0 1.084-.487 1.084-1.083v-3.25c0-.596-.488-1.083-1.084-1.083zM20.584 10.292H5.417c-.596 0-1.083.487-1.083 1.083v3.25c0 .596.487 1.083 1.083 1.083h15.166c.596 0 1.084-.487 1.084-1.083v-3.25c0-.596-.488-1.083-1.084-1.083zM20.584 16.792H5.417c-.596 0-1.083.487-1.083 1.083v3.25c0 .596.487 1.083 1.083 1.083h15.166c.596 0 1.084-.487 1.084-1.083v-3.25c0-.596-.488-1.083-1.084-1.083z"
        fill="#B2D135"
      />
      <path
        d="M24.483 20.637c.055-.216.055-.433.055-.595 0-.163 0-.434-.055-.596l1.246-.921c.108-.108.163-.27.108-.38l-1.245-2.112c-.054-.108-.217-.162-.38-.108l-1.408.65a4.428 4.428 0 00-1.083-.65l-.163-1.57c0-.163-.162-.272-.27-.272H18.85c-.163 0-.27.109-.27.271l-.163 1.571a4.43 4.43 0 00-1.084.65l-1.408-.65c-.163-.054-.325 0-.38.108L14.3 18.146c-.054.108-.054.325.108.379l1.246.92c-.054.218-.054.434-.054.597 0 .162 0 .433.054.595l-1.246.921c-.108.109-.162.271-.108.38l1.246 2.112c.054.108.216.162.379.108l1.408-.65c.325.271.705.488 1.084.65l.162 1.571c0 .163.163.271.271.271h2.438c.162 0 .27-.108.27-.27l.163-1.572c.379-.162.758-.379 1.083-.65l1.409.65c.162.054.325 0 .379-.108l1.245-2.113c.055-.108.055-.325-.108-.379l-1.246-.92zm-4.441 2.221a2.797 2.797 0 01-2.817-2.816 2.797 2.797 0 012.817-2.817 2.797 2.797 0 012.816 2.817 2.797 2.797 0 01-2.816 2.816z"
        fill="#F26A24"
      />
      <path
        d="M20.041 16.792a3.26 3.26 0 00-3.25 3.25 3.26 3.26 0 003.25 3.25 3.26 3.26 0 003.25-3.25 3.26 3.26 0 00-3.25-3.25zm0 4.875c-.92 0-1.625-.705-1.625-1.625 0-.921.705-1.625 1.625-1.625.921 0 1.625.704 1.625 1.625 0 .92-.704 1.625-1.625 1.625z"
        fill="#FFC021"
      />
    </svg>
  )
}
