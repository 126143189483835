/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  httpWrapper,
  buildListParams,
  buildURLForGettingList,
  buildURLForGettingExtendedList,
  buildURLForGettingFreeList
} from 'services/http/helpers'
import { UNIQ_PHONE_NUMBER_URL } from 'endpoints'

export const getList = options =>
  httpWrapper({
    url: buildURLForGettingList(buildURLForGettingExtendedList(UNIQ_PHONE_NUMBER_URL)),
    method: 'get',
    params: buildListParams(options)
  })

export const getFreeList = options =>
  httpWrapper({
    url: buildURLForGettingList(buildURLForGettingFreeList(UNIQ_PHONE_NUMBER_URL)),
    method: 'get',
    params: buildListParams(options)
  })
