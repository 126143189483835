/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/editQueue'
import { formActions } from 'reducers/unitsSlider'
import { withAuthContext } from 'helpers/withAuthContext'
import { UnitsDetailsTab as UnitsDetailsTabComponent } from './UnitsDetailsTab'

const mapStateToProps = state => ({
  ...state.unitsSlider
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
      ...formActions
    },
    dispatch
  )

export const UnitsDetailsTab = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UnitsDetailsTabComponent)
