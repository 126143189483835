/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { actions as programsByAppActions } from 'reducers/programsByAppList'

import { ProgramsByAPP as ProgramsByAPPCompomnent } from './ProgramsByAPP'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => bindActionCreators({ ...programsByAppActions }, dispatch)

export const ProgramsByAPP = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProgramsByAPPCompomnent)
