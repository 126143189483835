/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'

export function CartIcon(props) {
  return (
    <svg viewBox="10 0 600 600" {...props}>
      <path
        d="M540.8,178.3l-349-24.9l-12.6-65.7c-1-5.1-4.9-9.3-9.9-10.5L63.9,50.7c-8.7-2.2-18.1,2.6-20.5,11.3
        c-2.6,9,2.8,18.2,11.8,20.5l85.7,21.7c5.1,1.3,8.9,5.4,9.9,10.5l62.1,324c1.2,6.3,6.7,10.8,13.1,10.8h292.4c8.8,0,16.4-6.6,17-15.4
        c0.6-9.6-7-17.6-16.4-17.6H253.2c-6.4,0-11.9-4.5-13.1-10.8l-2.4-12.7c-0.5-2.8,1.5-5.5,4.4-5.7l275-19.7
        c10.7-0.8,19.5-8.8,21.3-19.4l23.7-143C564.3,191.8,554.4,179.3,540.8,178.3z"
      />
      <circle cx="264.9" cy="504.8" r="41.2" />
      <circle cx="487.2" cy="504.8" r="41.2" />
    </svg>
  )
}
