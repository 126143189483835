/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const FORM_NAME = 'DEAL_FORM_GROUPS'
export const ASSIGN_GROUPS = `${FORM_NAME}_ASSIGN_GROUPS`
export const FILL_TICKET_TYPES = `${FORM_NAME}_FILL_TICKET_TYPES`
export const GET_ACTIVITIES_BY_DEAL_ID = `${FORM_NAME}_GET_ACTIVITIES_BY_DEAL_ID`

export default {
  FORM_NAME,
  ASSIGN_GROUPS,
  FILL_TICKET_TYPES,
  GET_ACTIVITIES_BY_DEAL_ID
}
