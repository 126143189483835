/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { PERIOD } from 'constants/eligibility'
import { dateValidate } from 'helpers/formHelpers'
import { DatePeriod, rangeValidation } from './DatePeriod'
import { DateInput } from './DateInput'
import { DATE_PARAMETER_PLACEHOLDER, START_DATE_PLACEHOLDER, END_DATE_PLACEHOLDER } from './types'

export function getComponentByMode(mode) {
  switch (mode) {
    case PERIOD:
      return {
        Component: DatePeriod,
        props: {
          validate: rangeValidation,
          startDate: { placeholder: START_DATE_PLACEHOLDER },
          endDate: { placeholder: END_DATE_PLACEHOLDER }
        }
      }
    default:
      return {
        Component: DateInput,
        props: {
          placeholder: DATE_PARAMETER_PLACEHOLDER,
          validate: dateValidate
        }
      }
  }
}
