/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  httpWrapper,
  buildUrlForApprove,
  buildUrlForReject,
  buildUrlToPending,
  buildUrlForCreatingDraft
} from 'services/http/helpers'
import { PROGRAMS_URL } from 'endpoints'

export const toPendingProgram = (authKey, id, contractData) =>
  httpWrapper({
    url: buildUrlToPending(PROGRAMS_URL, id),
    method: 'put',
    data: contractData
  })

export const approveProgram = (id, contractData) =>
  httpWrapper({
    url: buildUrlForApprove(PROGRAMS_URL, id),
    method: 'put',
    data: contractData
  })

export const rejectProgram = (authKey, id, contractData) =>
  httpWrapper({
    url: buildUrlForReject(PROGRAMS_URL, id),
    method: 'put',
    data: contractData
  })

export const createDraftProgramFromActive = (authKey, id, contractData) =>
  httpWrapper({
    url: buildUrlForCreatingDraft(PROGRAMS_URL, id),
    method: 'post',
    data: contractData
  })
