/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import DayPicker from 'react-day-picker'
import classNames from 'classnames'
import './DayPickerStyles.css'

import { DiscountContextMenu } from './DiscountContextMenu'
import { useDealContextMenuState } from './DiscountContextMenu/hooks'
import { useDealCalendarApi, useDealCalendarState } from './hooks'
import { DiscountCalendarDay } from './DiscountCalendarDay'
import styles from './styles.scss'
import {
  AVAILABLE_LABEL,
  EXCLUDED_LABEL,
  INCLUDED_LABEL,
  NUMBER_OF_MONTHS,
  SAMPLE_DAY_LABEL
} from './types'

const modifiersStyles = {
  include: {
    color: '#ffffff',
    backgroundColor: '#98B336'
  },
  exclude: {
    color: '#ffffff',
    backgroundColor: '#ff0000'
  }
}

function renderDay(day, dayModifiers) {
  return <DiscountCalendarDay day={day} modifiers={dayModifiers} />
}

export function DiscountCalendar() {
  const { onDayClick } = useDealCalendarApi()
  const { modifiers, selectedRange } = useDealCalendarState()
  const { isMenuOpened } = useDealContextMenuState()

  return (
    <div className={classNames(styles.wrapper, 'DealCalendar')}>
      <DayPicker
        numberOfMonths={NUMBER_OF_MONTHS}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        renderDay={renderDay}
        selectedDays={[selectedRange.from, { ...selectedRange }]}
        onDayClick={onDayClick}
      />
      <div className={styles.explanatoryNotes}>
        <div className={styles.note}>
          <div className={styles.day}>{SAMPLE_DAY_LABEL}</div>
          <div className={styles.label}>{AVAILABLE_LABEL}</div>
        </div>
        <div className={styles.note}>
          <div className={classNames(styles.day, styles.included)}>{SAMPLE_DAY_LABEL}</div>
          <div className={styles.label}>{INCLUDED_LABEL}</div>
        </div>
        <div className={styles.note}>
          <div className={classNames(styles.day, styles.excluded)}>{SAMPLE_DAY_LABEL}</div>
          <div className={styles.label}>{EXCLUDED_LABEL}</div>
        </div>
      </div>
      {isMenuOpened && <DiscountContextMenu />}
    </div>
  )
}
