/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useDidMount } from 'hooks'
import { CloseIcon, FullScreen, ExitFullScreen } from 'icons'

import styles from './FormSlider.scss'

export function FormSlider(props) {
  const { children, onClose, title, subtitle, info, additional, fullScreen } = props
  const sliderRef = useRef(null)

  useDidMount(() => {
    if (sliderRef.current) {
      sliderRef.current.focus()
    }
  })

  const ScreenIcon = useMemo(() => (fullScreen.active ? ExitFullScreen : FullScreen), [
    fullScreen.active
  ])

  return (
    <div
      className={classNames(styles.slideWrapper, { [styles.fullscreen]: fullScreen.active })}
      tabIndex="-1"
      ref={sliderRef}
    >
      <header className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
        {info && <div className={styles.info}>{info}</div>}
        {additional}
        <div className={styles.control}>
          <ScreenIcon className={styles.screen} onClick={fullScreen.toggle} />
          <CloseIcon dynamicClassName={styles.closeIcon} dynamicEvent={onClose} />
        </div>
      </header>
      {children && <section className={styles.content}>{children}</section>}
    </div>
  )
}

FormSlider.propTypes = {
  additional: PropTypes.node,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  info: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  positionNumber: PropTypes.number.isRequired,
  fullScreen: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
  }).isRequired
}

FormSlider.defaultProps = {
  additional: null,
  children: null,
  title: '',
  subtitle: null,
  info: null
}
