/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { bindActionCreators, compose } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'
import { actions } from 'reducers/checkTicketInventory'
import { connect } from 'react-redux'
import { CheckTicketInventory as CheckTicketInventoryComponent } from './CheckTicketInventory'

const mapStateToProps = state => ({
  ...state.checkTicketInventory
})

const mapDispatchToProps = dispatch => bindActionCreators({ ...actions }, dispatch)

export const CheckTicketInventory = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CheckTicketInventoryComponent)
