/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { NAME, ELIGIBILITY_ASSIGN_BULK } = types

const REQUEST_ELIGIBILITY_ASSIGN_BULK = `${REQUEST_TYPE}_${ELIGIBILITY_ASSIGN_BULK}`
const RESPONSE_ELIGIBILITY_ASSIGN_BULK = `${RESPONSE_TYPE}_${ELIGIBILITY_ASSIGN_BULK}`
const RESPONSE_ERROR_ELIGIBILITY_ASSIGN_BULK = `${RESPONSE_ERROR_TYPE}_${ELIGIBILITY_ASSIGN_BULK}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

const buildUpdatedMessage = () => 'Parameters have been updated.'

export default {
  REQUEST_ELIGIBILITY_ASSIGN_BULK,
  RESPONSE_ELIGIBILITY_ASSIGN_BULK,
  RESPONSE_ERROR_ELIGIBILITY_ASSIGN_BULK,
  RESET_DATA_STATE,
  buildUpdatedMessage
}
