/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withLoader } from 'decorators'
import get from 'lodash/get'
import { useUpdateSomeDataState, useDataStatesComparison, useRootModal } from 'hooks'
import { ADMIN_LEVEL } from 'constants/permissionTypes'
import { DESTINATION_TYPE } from 'constants/locationsTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import { DATA_STATE_REQUESTING, DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import ModalRemoval from 'modules/ModalRemoval'
import { PIECurtainLoader } from 'components/Loaders'

import { useLocationCampaigns } from '../LocationsCampaigns'
import { useLocationProgramsAndActivities } from '../LocationProgramsAndActivities'
import { LocationsContextMenu } from './LocationsContextMenu'
import { LocationsContainer } from './LocationsContainer'
import styles from './LocationsList.scss'

const LocationsContainerWithLoader = withLoader(LocationsContainer)

export function LocationsList(props) {
  const {
    updateTreeState,
    treeState,
    data,
    authUser,
    getLocationTree,
    dataState,
    removeLocation,
    moveLocation,
    updateListDataStates,
    linkChildLocation,
    createAndLinkChildLocation,
    updateAndLinkChildren
  } = props

  const listWrapperRef = useRef(null)
  const [contentScrollTop, setContentScrollTopState] = useState(0)
  const [hasMenuOpened, setMenuOpenedState] = useState(false)
  const [locationsTreeData, setLocationsTreeState] = useState([])
  const [menuProps, setMenuState] = useState({ pageX: 0, pageY: 0 })
  const isLoading = dataState === DATA_STATE_REQUESTING
  const canUpdate = isActionAllowed(ADMIN_LEVEL, authUser)
  const { isLoading: isCampaignsLoading, getCampaigns } = useLocationCampaigns()
  const {
    isLoading: isProgramsAndActivitiesLoading,
    getProgramsAndActivities
  } = useLocationProgramsAndActivities(getCampaigns)
  const rootModal = useRootModal()

  useEffect(() => {
    getLocationTree(authUser)
  }, [authUser, getLocationTree])

  useUpdateSomeDataState(() => {
    setLocationsTreeState(data)
    listWrapperRef.current.scrollTop = contentScrollTop
  }, [dataState])

  useDataStatesComparison(
    () => {
      getLocationTree(authUser)
    },
    updateListDataStates,
    DATA_STATE_RECIEVED
  )

  const setMenuOpenedStateHandler = (value = {}, event = {}) => {
    setMenuState({ ...value, pageX: event.pageX, pageY: event.pageY })
    setMenuOpenedState(true)
  }

  const onCloseHandler = () => {
    setMenuOpenedState(false)
  }

  const linkLocations = params => {
    moveLocation(params)
  }

  const onRemoveLocation = useCallback(
    id => () => {
      removeLocation(id)
      rootModal.dequeue()
    },
    [rootModal, removeLocation]
  )

  const onRemoveLocationHandler = useCallback(
    async (id, type) => {
      if (type === DESTINATION_TYPE) {
        const { canProceed } = await getProgramsAndActivities(id)
        if (!canProceed) {
          return
        }
      }
      const { canProceed } = await getCampaigns(id)
      if (canProceed) {
        rootModal.enqueue(ModalRemoval, {
          actionCallback: onRemoveLocation(id),
          cancelCallback: rootModal.dequeue,
          originalData: id
        })
      }
    },
    [getCampaigns, getProgramsAndActivities, rootModal, onRemoveLocation]
  )

  const onScrollHandler = event => {
    const scrollTop = +event.target.scrollTop
    if (dataState === DATA_STATE_RECIEVED && scrollTop !== contentScrollTop) {
      setContentScrollTopState(scrollTop)
    }
  }

  const addChildLocation = useCallback(
    parentId => formValues => {
      if (!formValues) {
        return
      }
      if (formValues.location) {
        const childId = get(formValues, 'location.0.id')
        if (childId) {
          linkChildLocation(childId, parentId)
        }
        return
      }
      if (!formValues.id) {
        createAndLinkChildLocation(formValues, parentId)
      }
    },
    [createAndLinkChildLocation, linkChildLocation]
  )

  const updateAndAddChild = useCallback(contractData => updateAndLinkChildren(contractData), [
    updateAndLinkChildren
  ])

  return (
    <div
      ref={listWrapperRef}
      onScroll={onScrollHandler}
      className={classNames(styles.listWrapper, {
        [styles.isLoading]: isLoading
      })}
    >
      {(isCampaignsLoading || isProgramsAndActivitiesLoading) && <PIECurtainLoader />}
      <LocationsContainerWithLoader
        isLoading={isLoading}
        data={locationsTreeData}
        hasMenuOpened={hasMenuOpened}
        setMenuOpenedState={setMenuOpenedStateHandler}
        menuProps={menuProps}
        updateTreeState={updateTreeState}
        treeState={treeState}
        linkLocations={linkLocations}
        canDrag={canUpdate}
      />
      {hasMenuOpened && (
        <LocationsContextMenu
          {...menuProps}
          onClose={onCloseHandler}
          canUpdate={canUpdate}
          onRemoveLocation={onRemoveLocationHandler}
          addChildLocation={addChildLocation}
          updateAndAddChild={updateAndAddChild}
        />
      )}
    </div>
  )
}

LocationsList.propTypes = {
  updateTreeState: PropTypes.func,
  treeState: PropTypes.object,
  data: PropTypes.array,
  authUser: PropTypes.object.isRequired,
  getLocationTree: PropTypes.object.isRequired,
  dataState: PropTypes.string.isRequired,
  formDataState: PropTypes.string.isRequired,
  unlinkActionDataState: PropTypes.string.isRequired,
  linkLocationDataState: PropTypes.string.isRequired,
  removeLocationDataState: PropTypes.string.isRequired,
  removeLocation: PropTypes.func,
  moveLocation: PropTypes.func.isRequired,
  moveLocationDataState: PropTypes.string.isRequired,
  updateListDataStates: PropTypes.array.isRequired,
  linkChildLocation: PropTypes.func.isRequired,
  createAndLinkChildLocation: PropTypes.func.isRequired,
  updateAndLinkChildren: PropTypes.func.isRequired
}

LocationsList.defaultProps = {
  updateTreeState: () => {},
  treeState: {},
  data: [],
  removeLocation: () => {}
}
