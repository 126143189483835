/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { FormContext } from 'context'

export function withFormContext(Component) {
  function WithFormContext(props) {
    return (
      <FormContext.Consumer>
        {({ disabled }) => <Component disabled={disabled} {...props} />}
      </FormContext.Consumer>
    )
  }

  const componentName = Component.displayName || Component.name || 'Component'

  WithFormContext.displayName = `withFormContext(${componentName})`
  return WithFormContext
}
