/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

export const NOT_FOUND_PAGE_MESSAGE =
  "404 - Oh no's! We couldn't find that page :("

export const GO_HOMEPAGE = 'go to homepage'
