/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'

import { ButtonWithPermission } from 'components/Buttons'
import { BasicButton } from '@xplorie/ui-commons'
import { UPDATE_LEVEL } from 'constants/permissionTypes'

export const UpdateEntityButton = props => (
  <ButtonWithPermission {...props} accessLevel={UPDATE_LEVEL} Component={BasicButton} />
)
