/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import get from 'lodash/get'

export const processLocationResult = (locations = []) =>
  locations.map(({ id, name }) => ({
    label: name,
    value: id
  }))

export const getLocationOptionForArchivedProgram = formData => {
  const location = get(formData, 'location')
  const id = get(location, 'id')
  const name = get(location, 'name')

  return [{ label: name, value: id }]
}
