/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const PREBOOK_RATE_LABEL = 'Add Prebook Rate'

export const RATE_VALUE_FIELD = 'rateValue'
export const START_VALUE_FIELD = 'startDate'

export const RATE_LABEL = 'New Rate:'
export const RATE_PLACEHOLDER = 'New Rate'
export const START_VALUE_LABEL = 'Start:'
export const START_VALUE_PLACEHOLDER = 'Start'

export const HINT_MESSAGE = 'The Rate will be applied to actual billable nights'

export const RATE_VALUE_BLANK_VALIDATION_MESSAGE = 'Prebook Rate is required'

export const CHARGE_METHOD_FIELD = 'chargeMethod'
export const CHARGE_METHOD_PLACEHOLDER = 'Select one...'
export const CHARGE_PER_NIGHT_VALUE = 'NIGHT'
export const CHARGE_PER_NIGHT_LABEL = 'Per Night'
export const CHARGE_PER_STAY_VALUE = 'STAY'
export const CHARGE_PER_STAY_LABEL = 'Per Stay'
export const CHARGE_PER_UNIT_VALUE = 'UNIT'
export const CHARGE_PER_UNIT_LABEL = 'Per Unit'
export const chargeMethodTypes = [
  { value: CHARGE_PER_UNIT_VALUE, label: CHARGE_PER_UNIT_LABEL },
  { value: CHARGE_PER_NIGHT_VALUE, label: CHARGE_PER_NIGHT_LABEL },
  { value: CHARGE_PER_STAY_VALUE, label: CHARGE_PER_STAY_LABEL }
]