/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { UPDATE_LEVEL, CREATE_LEVEL, ADMIN_LEVEL, APPROVE_LEVEL } from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import { APP_TYPES } from 'constants/app'
import get from 'lodash/get'
import { STATUS_ACTIVE, STATUS_TESTING } from 'constants/entityStatus'
import { NONE_OPTION_VALUE } from './ActivityProductForm/types'

// permissions
export const buildPermissions = ({ authUser, data }) => {
  const isAdmin = isActionAllowed(ADMIN_LEVEL, authUser)
  const canApprove = isActionAllowed(APPROVE_LEVEL, authUser)
  const canCreate = isActionAllowed(CREATE_LEVEL, authUser, data)
  const canUpdate = isActionAllowed(UPDATE_LEVEL, authUser, data)
  const entityStatus = data.status

  return {
    canUpdate: (canUpdate || canApprove) && [STATUS_ACTIVE, STATUS_TESTING].includes(entityStatus),
    canCreate,
    isAdmin,
    canApprove,
    entityStatus
  }
}

export const canEditResell = authUser => isActionAllowed(APPROVE_LEVEL, authUser)

export const canSeeTicketsTab = (data, authUser) => {
  const isCreateLevel = isActionAllowed(CREATE_LEVEL, authUser)

  return (
    isCreateLevel &&
    data &&
    data.businessType &&
    data.businessType !== APP_TYPES.MARKETING &&
    data.businessType !== APP_TYPES.NO_RESERVATION_REQUIRED
  )
}

export const canSeeSettingsTab = (data, authUser, settings) =>
  canSeeTicketsTab(data, authUser) &&
  settings &&
  settings.ticketReservationSettings &&
  settings.ticketReservationSettings.ticketFormat &&
  settings.ticketReservationSettings.redemptionType &&
  settings.ticketReservationSettings.taxes &&
  settings.ticketReservationSettings.fees

export const canSeeDealsTab = data =>
  data &&
  data.businessType &&
  data.businessType !== APP_TYPES.MARKETING &&
  data.businessType !== APP_TYPES.NO_RESERVATION_REQUIRED

export const canEditSchedulesForm = authUser => {
  const canUpdate = isActionAllowed(UPDATE_LEVEL, authUser)

  return canUpdate
}

export const convertToView = data => {
  const activityProviderId = get(data, 'activityProvider.id')
  const activityCategoryId = get(data, 'activityCategory.id', NONE_OPTION_VALUE)

  return {
    ...data,
    activityProviderId,
    activityCategoryId
  }
}

export const convertToContractValues = data => {
  const activityProviderId = get(data, 'activityProvider.id')
  const activityCategory = get(data, 'activityCategory.id')

  return {
    ...data,
    activityProviderId,
    activityCategoryId: activityCategory
  }
}
