/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { STATUS_ACTIVE } from 'constants/entityStatus'
import {
  buildSearchColumn,
  LIKE_START_WITH_MODE,
  AND_OPERATOR,
  PROGRAM
} from 'helpers/searchHelpers'

const PROGRAMS_LIST_NAME = 'PROGRAMS_LIST'
const PROGRAMS_LIST_COUNT_NAME = 'PROGRAMS_LIST_COUNT'
const DEFAULT_PAGE_NUMBER = 1
const DEFAULT_ITEMS_PER_PAGE = 10
const ENTITY_TYPE = PROGRAM

const REQUEST_PAYLOAD = [
  buildSearchColumn({
    entity: ENTITY_TYPE,
    field: 'status',
    keyword: STATUS_ACTIVE,
    operator: AND_OPERATOR,
    mode: LIKE_START_WITH_MODE
  })
]

const REQUEST_DEFAULT_OPTIONS = {
  type: PROGRAM,
  keyword: ''
}

export default {
  PROGRAMS_LIST_NAME,
  PROGRAMS_LIST_COUNT_NAME,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_ITEMS_PER_PAGE,
  ENTITY_TYPE,
  REQUEST_PAYLOAD,
  REQUEST_DEFAULT_OPTIONS
}
