/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'
import {
  actions,
  getProgramLifeCycleProcessState
} from 'connectors/reducers/programLifeCycleProcess'
import { DeactivateButton as DeactivateButtonComponent } from './DeactivateButton'

const mapStateToProps = (state, props) => {
  const { programId } = props
  const programLifeCycleState = getProgramLifeCycleProcessState(state, programId)
  return { ...programLifeCycleState }
}

const mapDispatchToProps = dispatch => bindActionCreators({ ...actions }, dispatch)

export const DeactivateButton = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DeactivateButtonComponent)
