/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function HomeIcon(props) {
  return (
    <svg viewBox="0 0 150 150" {...props}>
      <path d="M139.2,82c2.7-2.6,2.7-6.9,0-9.6L76.5,9.7c-2.6-2.6-6.9-2.6-9.6,0L4.2,72.4c-2.6,2.7-2.6,6.9,0,9.6 c2.6,2.6,6.9,2.6,9.6,0L71.7,24L129.7,82C132.3,84.6,136.6,84.6,139.2,82z" />
      <path d="M71.7,32L24,79.8v42.7c0,2.1,1.7,3.9,3.9,3.9h32.5V87.7h22.8v38.7h32.5c2.1,0,3.9-1.7,3.9-3.9V79.8L71.7,32z" />
    </svg>
  )
}
