/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  ACTIVITY_CATEGORY_TYPE,
  ACTIVITY_TAGS_TYPE,
  ACTIVITY_MODULE_TYPE,
  ELIGIBILITY_TYPE,
  LOCATIONS_TYPE,
  MARKET_TYPE,
  PMS_NAME_TYPE,
  RMS_NAME_TYPE,
  TILE_ACCORDIONS_TYPE,
  UNIQ_PHONE_NUMBER_TYPE
} from 'containers/Library/types'

import { MarketContent } from 'modules/Library'
import { LocationsList } from 'modules/Locations'
import { ActivityCategoryList } from 'modules/ActivityCategory'
import { PMSList } from 'modules/PMS'
import { TileAccordionList } from 'modules/TileAccordion'
import { ActivityTagsList } from 'modules/ActivityTags'
import { ActivityModuleList } from 'modules/ActivityModule'
import { RMSList } from 'modules/RMS'
import { UniqPhoneNumberList } from 'modules/UniqPhoneNumber'
import { EligibilityList } from 'modules/Eligibility'
import {
  ACTIVITY_CATEGORY,
  ACTIVITY_TAG,
  ACTIVITY_MODULE,
  ELIGIBILITY,
  LOCATION,
  MARKET,
  PMS,
  RMS,
  TILE_ACCORDION,
  UNIQ_PHONE_NUMBER
} from 'constants/formCodes'
import { ADD_NEW_BUTTON, ADD_NEW_COUNTRY_BUTTON } from 'modules/Library/LibraryTabContent/types'

export const createOption = (
  Component = null,
  sliderType = ACTIVITY_CATEGORY,
  showAction = false
) => ({
  Component,
  sliderType,
  showAction
})

export const getOptionsByType = type => {
  switch (type) {
    case ACTIVITY_CATEGORY_TYPE:
      return createOption(ActivityCategoryList, ACTIVITY_CATEGORY, true)
    case ACTIVITY_TAGS_TYPE:
      return createOption(ActivityTagsList, ACTIVITY_TAG, true)
    case ACTIVITY_MODULE_TYPE:
      return createOption(ActivityModuleList, ACTIVITY_MODULE, true)
    case LOCATIONS_TYPE:
      return createOption(LocationsList, LOCATION, true)
    case PMS_NAME_TYPE:
      return createOption(PMSList, PMS, true)
    case RMS_NAME_TYPE:
      return createOption(RMSList, RMS, true)
    case TILE_ACCORDIONS_TYPE:
      return createOption(TileAccordionList, TILE_ACCORDION, true)
    case UNIQ_PHONE_NUMBER_TYPE:
      return createOption(UniqPhoneNumberList, UNIQ_PHONE_NUMBER, true)
    case ELIGIBILITY_TYPE:
      return createOption(EligibilityList, ELIGIBILITY)
    case MARKET_TYPE:
      return createOption(MarketContent, MARKET)
    default:
      return createOption()
  }
}

export const buildAddButtonText = (sliderType) => {
  switch (sliderType) {
    case LOCATION:
      return ADD_NEW_COUNTRY_BUTTON
    default:
      return ADD_NEW_BUTTON
  }
}
