/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

const FORM_NAME = 'MARKET_DICTIONARY_FORM'
const GET_FORM_DATA = `GET_FORM_DATA_${FORM_NAME}`
const DELETE_ENTITY_DATA = `DELETE_ENTITY_DATA_${FORM_NAME}`

const NEW_ENTITY_KEY = 'new_market'

export default {
  FORM_NAME,
  GET_FORM_DATA,
  DELETE_ENTITY_DATA,
  NEW_ENTITY_KEY
}
