/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from './hooks'

export function TableField({ Component, onGetData, ...rest }) {
  const [, { setParameters }] = useParams()

  const onGetDataHandler = options => {
    setParameters(options)

    if (onGetData) {
      onGetData(options)
    }
  }

  return <Component {...rest} onGetData={onGetDataHandler} withBasicAuth={false} />
}
TableField.propTypes = {
  Component: PropTypes.element.isRequired,
  onGetData: PropTypes.func
}
TableField.defaultProps = {
  onGetData: null
}
