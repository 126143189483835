/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  RESPONSE_EXCLUSIVE_ACTIVITIES_LIST,
  RESPONSE_EXCLUSIVE_ACTIVITIES_ERROR,
  REQUEST_EXCLUSIVE_ACTIVITIES_LIST,
  REQUEST_REMOVE_EXCLUSIVE_ACTIVITY,
  RESPONSE_REMOVE_EXCLUSIVE_ACTIVITY,
  RESPONSE_REMOVE_EXCLUSIVE_ACTIVITY_ERROR,
  REQUEST_ADD_EXCLUSIVE_ACTIVITY,
  RESPONSE_ADD_EXCLUSIVE_ACTIVITY,
  RESPONSE_ADD_EXCLUSIVE_ACTIVITY_ERROR
} = helpers

const initialState = {
  exclusiveActivities: [],
  exclusiveActivitiesDataState: DATA_STATE_NOT_REQUESTED,
  removingActivityDataState: DATA_STATE_NOT_REQUESTED,
  addingActivityDataState: DATA_STATE_NOT_REQUESTED
}

const exclusiveActivityListReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_EXCLUSIVE_ACTIVITIES_LIST:
      return {
        ...state,
        exclusiveActivitiesDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_EXCLUSIVE_ACTIVITIES_ERROR:
      return {
        ...state,
        exclusiveActivitiesDataState: DATA_STATE_ERROR
      }
    case RESPONSE_EXCLUSIVE_ACTIVITIES_LIST:
      return {
        ...state,
        exclusiveActivitiesDataState: DATA_STATE_RECIEVED,
        exclusiveActivities: action.payload.data
      }
    case REQUEST_REMOVE_EXCLUSIVE_ACTIVITY:
      return {
        ...state,
        removingActivityDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_REMOVE_EXCLUSIVE_ACTIVITY:
      return {
        ...state,
        removingActivityDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_REMOVE_EXCLUSIVE_ACTIVITY_ERROR:
      return {
        ...state,
        removingActivityDataState: DATA_STATE_ERROR
      }
    case REQUEST_ADD_EXCLUSIVE_ACTIVITY:
      return {
        ...state,
        addingActivityDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_ADD_EXCLUSIVE_ACTIVITY:
      return {
        ...state,
        addingActivityDataState: DATA_STATE_RECIEVED
      }
    case RESPONSE_ADD_EXCLUSIVE_ACTIVITY_ERROR:
      return {
        ...state,
        addingActivityDataState: DATA_STATE_ERROR
      }
    default:
      return state
  }
}

export default filterActions(exclusiveActivityListReducer, [
  REQUEST_EXCLUSIVE_ACTIVITIES_LIST,
  RESPONSE_EXCLUSIVE_ACTIVITIES_ERROR,
  RESPONSE_EXCLUSIVE_ACTIVITIES_LIST,
  REQUEST_REMOVE_EXCLUSIVE_ACTIVITY,
  RESPONSE_REMOVE_EXCLUSIVE_ACTIVITY,
  RESPONSE_REMOVE_EXCLUSIVE_ACTIVITY_ERROR,
  REQUEST_ADD_EXCLUSIVE_ACTIVITY,
  RESPONSE_ADD_EXCLUSIVE_ACTIVITY,
  RESPONSE_ADD_EXCLUSIVE_ACTIVITY_ERROR
])
