/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import classNames from 'classnames'
import {
  YELLOW_GREEN_SCHEMA,
  ORANGE_SCHEMA,
  SUBMIT_TITLE,
  CANCEL_TITLE
} from 'constants/buttonsOptions'
import { ControlButtonsContainer } from 'components/FormComponents'
import { SubmitButton } from 'components/Buttons'
import { buildDateTime } from '../helpers'
import styles from './styles.scss'
import { SchedulCancellationForm } from './ScheduleCancellationForm'

export function ScheduleCancellationModal(props) {
  const { isOpen, onClose, date, time, message, onSubmit, title } = props
  const dateTime = buildDateTime({ date, time })

  const handleSubmit = formValues => {
    if (onSubmit) {
      onSubmit(formValues)
    }

    onClose()
  }

  return (
    <BasicModal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      wrapperClassName={classNames(styles.modalWrapper)}
      renderBody={() => (
        <SchedulCancellationForm dateTime={dateTime} message={message} onSubmit={handleSubmit}>
          {formProps => (
            <ControlButtonsContainer className={styles.buttons}>
              <SubmitButton {...formProps} colorSchema={ORANGE_SCHEMA}>
                {SUBMIT_TITLE}
              </SubmitButton>
              <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
                {CANCEL_TITLE}
              </BasicButton>
            </ControlButtonsContainer>
          )}
        </SchedulCancellationForm>
      )}
    />
  )
}

ScheduleCancellationModal.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  time: PropTypes.object,
  title: PropTypes.string.isRequired
}

ScheduleCancellationModal.defaultProps = {
  time: null
}
