/*
* COPYRIGHT:     Copyright © 2018 Xplorie LLC
* Warning:       This product is protected by United States and international copyright laws.
*                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
*/
export const validateAuthUser = authUser => {
  if (!authUser || !authUser.roles || !authUser.id) {
    return null
  }
  return authUser
}
