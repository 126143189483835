export const TYPE_NEW_PASSWORD = 'password'
export const TYPE_CONFIRM_NEW_PASSWORD = 'confirmPassword'

export const LABEL_NEW_PASSWORD = 'New password'
export const LABEL_CONFIRM_PASSWORD = 'Confirm new password'

export const PLACEHOLDER_NEW_PASSWORD = 'New password'
export const PLACEHOLDER_CONFIRM_PASSWORD = 'Confirm new password'

export const SUBMIT_BUTTON_TEXT = 'submit'

export const TYPE_INPUT_PASSWORD = 'password'

export const PASSWORDS_NOT_MATCH = 'The new password and confirm new password not match!'
