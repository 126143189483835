/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import isArray from 'lodash/isArray'
import findIndex from 'lodash/findIndex'
import { CollapseWrapper } from 'components/Wrappers'
import { isCurrentYear } from 'helpers/dateHelpers'
import { usePrevious } from 'hooks'
import { AvailabilityItem } from '../AvailabilityItem'

export function YearAvailability(props) {
  const { year, masterData, isNew, ...rest } = props
  const [expand, setExpandState] = useState(false)
  const prevMasterData = usePrevious(masterData)

  // expand CollapseWrapper when it is current year
  // or when some availability id from current data list, can not be found in previous list
  useEffect(() => {
    if (!expand) {
      if (
        isCurrentYear(year) ||
        isNew ||
        (isArray(prevMasterData) &&
          isArray(masterData) &&
          masterData.some(value => findIndex(prevMasterData, ['id', value.id]) < 0))
      ) {
        setExpandState(!expand)
      }
    }
  }, [expand, isNew, masterData, prevMasterData, year])

  return (
    <CollapseWrapper key={year} title={String(year)} expand={expand} onChange={setExpandState}>
      {masterData.map((dateObj, index) => (
        <AvailabilityItem {...rest} key={dateObj.id || index} value={dateObj} />
      ))}
    </CollapseWrapper>
  )
}

YearAvailability.propTypes = {
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  masterData: PropTypes.array.isRequired,
  isNew: PropTypes.bool.isRequired
}
