/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { activateAccountById, deactivateAccountById } from 'services/http/account'

import helpers from './helpers'

const {
  REQUEST_ACTIVATION_ACCOUNT,
  RESPONSE_ACTIVATION_ACCOUNT,
  RESPONSE_ACTIVATION_ACCOUNT_ERROR,
  REQUEST_DEACTIVATION_ACCOUNT,
  RESPONSE_DEACTIVATION_ACCOUNT,
  RESPONSE_DEACTIVATION_ACCOUNT_ERROR,
  DEACTIVATION_SUCCESS_MESSAGE,
  ACTIVATION_SUCCESS_MESSAGE,
  RESET_ACCOUNT_LIFE_CYCLE_DATA_STATE
} = helpers

const deactivateAccount = (accountId, options) => async dispatch => {
  const key = accountId
  dispatch(actionCreater(REQUEST_DEACTIVATION_ACCOUNT, { key }))
  const request = await deactivateAccountById(accountId, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DEACTIVATION_ACCOUNT_ERROR, { key }))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_DEACTIVATION_ACCOUNT, { key }))
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: DEACTIVATION_SUCCESS_MESSAGE }))
    return 'deactivateAccount success'
  }

  return 'deactivateAccount failed'
}

const activateAccount = (accountId, options) => async dispatch => {
  const key = accountId
  dispatch(actionCreater(REQUEST_ACTIVATION_ACCOUNT, { key }))
  const request = await activateAccountById(accountId, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ACTIVATION_ACCOUNT_ERROR, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_ACTIVATION_ACCOUNT, { key }))
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: ACTIVATION_SUCCESS_MESSAGE }))
    return 'activateAccount success'
  }

  return 'activateAccount failed'
}

const resetState = accountId => dispatch =>
  dispatch(actionCreater(RESET_ACCOUNT_LIFE_CYCLE_DATA_STATE, { key: accountId }))

export default {
  deactivateAccount,
  activateAccount,
  resetState
}
