/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { getLocationTreeById } from 'services/http/locations'
import helpers from './helpers'

const {
  REQUEST_LOCATION_TREE_DATA,
  RESPONSE_LOCATION_TREE_DATA,
  RESPONSE_LOCATION_TREE_DATA_ERROR,
  RESET_DATA_STATE
} = helpers

const getLocationTreeByIdAction = (authKey, id, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_LOCATION_TREE_DATA))
  const request = await getLocationTreeById(authKey, id, params).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_LOCATION_TREE_DATA_ERROR))
    }
  )
  if (request) {
    dispatch(actionCreater(RESPONSE_LOCATION_TREE_DATA, request.data))
    return 'getLocationTreeByIdAction success'
  }

  return 'getLocationTreeByIdAction failed'
}

const resetState = () => ({
  type: RESET_DATA_STATE
})
export default {
  getLocationTreeByIdAction,
  resetState
}
