/*
* COPYRIGHT:     Copyright © 2018 Xplorie LLC
* Warning:       This product is protected by United States and international copyright laws.
*                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
*/
export const buildAuthKey = ({ email, password }) => {
  if (!email || !password) {
    return ''
  }
  const basicAuthKey = btoa(
    unescape(encodeURIComponent(`${email}:${password}`))
  )

  return `Basic ${basicAuthKey}`
}
