/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useRef } from 'react'
import get from 'lodash/get'
import {
  useDealContextMenuApi,
  useDealContextMenuState
} from 'modules/Discounts/DiscountForm/DiscountPeriods/DiscountCalendar/DiscountContextMenu/hooks'
import moment from 'moment'
import { FORMAT } from 'constants/date'

import { useDidMount } from 'hooks/hooks'
import { useDealCalendarApi, useDealCalendarState } from '../hooks'

import { EXCLUDE_LABEL, INCLUDE_LABEL } from './types'
import styles from './styles.scss'

export function DiscountContextMenu() {
  const { onCloseContextMenu } = useDealContextMenuApi()
  const { menuState } = useDealContextMenuState()
  const { onExclude, onInclude } = useDealCalendarApi()
  const { selectedRange, hasSelectedRange } = useDealCalendarState()
  const contextMenuRef = useRef()

  const { posX, posY, modifiers, day } = menuState
  const isIncluded = get(modifiers, 'include', false)

  const optionLabel = isIncluded ? EXCLUDE_LABEL : INCLUDE_LABEL
  const optionEvent = isIncluded ? onExclude : onInclude

  const onFocus = () => {
    contextMenuRef.current.focus()
  }

  const onBlur = useCallback(
    event => {
      onCloseContextMenu(event)
    },
    [onCloseContextMenu]
  )

  const onOptionClick = useCallback(() => {
    const startDate = hasSelectedRange ? selectedRange.from : day
    const endDate = hasSelectedRange ? selectedRange.to : day
    const period = {
      startDate: moment(startDate).format(FORMAT),
      endDate: moment(endDate).format(FORMAT)
    }
    optionEvent(period)
    onBlur()
  }, [onBlur, optionEvent, day, hasSelectedRange, selectedRange])

  useDidMount(() => {
    onFocus()
  })

  return (
    <ul
      ref={contextMenuRef}
      tabIndex="-1"
      className={styles.wrapper}
      onBlur={onBlur}
      style={{
        left: `${posX}px`,
        top: `${posY}px`
      }}
    >
      <li className={styles.item} onClick={onOptionClick}>
        {optionLabel}
      </li>
    </ul>
  )
}
