/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_DELETE_PROGRAM,
  RESPONSE_DELETE_PROGRAM,
  RESPONSE_DELETE_PROGRAM_ERROR,
  REQUEST_PROGRAM_ENTITY_DATA,
  RESPONSE_PROGRAM_ENTITY_DATA,
  RESPONSE_PROGRAM_ENTITY_DATA_ERROR,
  REQUEST_ASSIGN_PHONE_BY_ID,
  RESPONSE_ASSIGN_PHONE_BY_ID,
  RESPONSE_ASSIGN_PHONE_BY_ID_ERROR,
  REQUEST_UNASSIGN_PHONE_BY_ID,
  RESPONSE_UNASSIGN_PHONE_BY_ID,
  RESPONSE_UNASSIGN_PHONE_BY_ID_ERROR,
  REQUEST_CREATE_EMPTY_PROGRAM,
  RESPONSE_CREATE_EMPTY_PROGRAM,
  RESPONSE_CREATE_EMPTY_PROGRAM_ERROR
} = helpers

const initialState = {}

export const programInitialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  deleteProgramDataState: DATA_STATE_NOT_REQUESTED,
  programData: {},
  programDataState: DATA_STATE_NOT_REQUESTED,
  unassignPhoneDataState: DATA_STATE_NOT_REQUESTED,
  assignPhoneDataState: DATA_STATE_NOT_REQUESTED,
  emptyProgramDataState: DATA_STATE_NOT_REQUESTED
}

const buildState = (state, action) => data => ({
  ...state,
  [action.payload.key]: { ...programInitialState, ...state[action.payload.key], ...data }
})

const programsFormReducer = (state = initialState, action) => {
  const appendData = buildState(state, action)

  switch (action.type) {
    case REQUEST_FORM:
      return appendData({ dataState: DATA_STATE_REQUESTING })
    case RESPONSE_FORM_ERROR:
      return appendData({ dataState: DATA_STATE_ERROR })
    case RESPONSE_FORM:
      return appendData({ dataState: DATA_STATE_RECIEVED })

    case REQUEST_DELETE_PROGRAM:
      return appendData({ deleteProgramDataState: DATA_STATE_REQUESTING })
    case RESPONSE_DELETE_PROGRAM_ERROR:
      return appendData({ deleteProgramDataState: DATA_STATE_ERROR })
    case RESPONSE_DELETE_PROGRAM:
      return appendData({ deleteProgramDataState: DATA_STATE_RECIEVED })

    case REQUEST_PROGRAM_ENTITY_DATA:
      return appendData({ programDataState: DATA_STATE_REQUESTING })
    case RESPONSE_PROGRAM_ENTITY_DATA_ERROR:
      return appendData({ programDataState: DATA_STATE_ERROR })
    case RESPONSE_PROGRAM_ENTITY_DATA:
      return appendData({ programDataState: DATA_STATE_RECIEVED, programData: action.payload.data })

    case REQUEST_ASSIGN_PHONE_BY_ID:
      return appendData({ assignPhoneDataState: DATA_STATE_REQUESTING })
    case RESPONSE_ASSIGN_PHONE_BY_ID_ERROR:
      return appendData({ assignPhoneDataState: DATA_STATE_ERROR })
    case RESPONSE_ASSIGN_PHONE_BY_ID:
      return appendData({ assignPhoneDataState: DATA_STATE_RECIEVED })

    case REQUEST_UNASSIGN_PHONE_BY_ID:
      return appendData({ unassignPhoneDataState: DATA_STATE_REQUESTING })
    case RESPONSE_UNASSIGN_PHONE_BY_ID_ERROR:
      return appendData({ unassignPhoneDataState: DATA_STATE_ERROR })
    case RESPONSE_UNASSIGN_PHONE_BY_ID:
      return appendData({ unassignPhoneDataState: DATA_STATE_RECIEVED })

    case REQUEST_CREATE_EMPTY_PROGRAM:
      return appendData({ emptyProgramDataState: DATA_STATE_REQUESTING })
    case RESPONSE_CREATE_EMPTY_PROGRAM_ERROR:
      return appendData({ emptyProgramDataState: DATA_STATE_ERROR })
    case RESPONSE_CREATE_EMPTY_PROGRAM:
      return appendData({ emptyProgramDataState: DATA_STATE_RECIEVED })

    case RESET_DATA_STATE: {
      const { [action.payload.key]: removedState, ...restState } = state
      return {
        ...restState
      }
    }

    default:
      return state
  }
}

export default filterActions(programsFormReducer, [
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_DELETE_PROGRAM,
  RESPONSE_DELETE_PROGRAM,
  RESPONSE_DELETE_PROGRAM_ERROR,
  REQUEST_PROGRAM_ENTITY_DATA,
  RESPONSE_PROGRAM_ENTITY_DATA,
  RESPONSE_PROGRAM_ENTITY_DATA_ERROR,
  REQUEST_ASSIGN_PHONE_BY_ID,
  RESPONSE_ASSIGN_PHONE_BY_ID,
  RESPONSE_ASSIGN_PHONE_BY_ID_ERROR,
  REQUEST_UNASSIGN_PHONE_BY_ID,
  RESPONSE_UNASSIGN_PHONE_BY_ID,
  RESPONSE_UNASSIGN_PHONE_BY_ID_ERROR,
  REQUEST_CREATE_EMPTY_PROGRAM,
  RESPONSE_CREATE_EMPTY_PROGRAM,
  RESPONSE_CREATE_EMPTY_PROGRAM_ERROR
])
