/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */
import get from 'lodash/get'

export const checkNewPassword = (path, errorMessage) => (newPasswordVal, values) => {
  const oldPasswordVal = get(values, path)
  return newPasswordVal === oldPasswordVal && newPasswordVal ? errorMessage : null
}

export const checkConfirmNewPassword = (path, errorMessage) => (confirmNewPasswordVal, values) => {
  const newPasswordVal = get(values, path)
  return newPasswordVal !== confirmNewPasswordVal ? errorMessage : null
}
