/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import cloneDeep from 'lodash/cloneDeep'
import React, { useCallback, useContext, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { SimpleBox } from '@xplorie/ui-commons'
import {
  CalendarField,
  CustomNestedField,
  FormContent,
  TextField
} from 'components/FormComponents'
import { TrashIcon } from 'icons'
import { 
  withValidation,
  validateBeforeDate,
  validateAfterDate,
} from 'helpers/formHelpers'
import { CalendarEndDateCheckbox } from 'modules/Discounts/DiscountForm/DiscountPeriods/CalendarEndDateCheckbox';
import { FORMAT } from 'constants/date'
import styles from './SmartHostFeeFormBox.scss'
import {
  END_DATE_LABEL,
  START_DATE_LABEL,
  PER_DEVICE_FEE_LABEL,
  SmartHostFeeFormContext,
  START_DATE_PLACEHOLDER,
  END_DATE_PLACEHOLDER,
  FEE_PLACEHOLDER,
  NO_END_DATE_VALUE
} from '../types'
import { SmartHostFeeFormItem } from './SmartHostFeeFormItem'
import { 
  borderValidationFee,
  decimalsValidationFee,
  anyNumberValidation,
  periodOverlapValidation,
} from './helpers'
import { useCalendarValidation } from './hooks'

function SmartHostFeeFormBox(props) {
  const { item, index } = props
  const { setFees, formApi } = useContext(SmartHostFeeFormContext)
  const itemName = useMemo(() => `fees[${index}]`, [index])
  const disabled = useMemo(() => !item.editable, [item.editable])

  const onRemoveClick = useCallback(() => {
    const formFees = formApi.getValue('fees') || []
    const nextFees = formFees.filter(fees => fees.id !== item.id)

    formApi.setValues({ fees: nextFees })
    setFees(nextFees)
  }, [item.id, formApi, setFees])

  const valueValidate = withValidation(
    withValidation(anyNumberValidation, borderValidationFee),
    decimalsValidationFee
  )

  const startDateValidate = withValidation(
    validateBeforeDate(`${itemName}.period.endDate`),
    periodOverlapValidation(index)
  )

  const endDateValidate = withValidation(
    validateAfterDate(`${itemName}.period.startDate`),
    periodOverlapValidation(index)
  )

  const { disabledRanges } = useCalendarValidation(index)

  const onNoEndDateCheckboxChange = async value => {
    const formFees = formApi.getValue('fees') || []
    const nextFees = formFees.map(fee => {
      if (fee.id === item.id) {
        const nextFee = cloneDeep(fee)
        if (!nextFee.period) {
          nextFee.period = {}
        }
        nextFee.period.endDate = value ? '' : nextFee.period.endDate;
        nextFee.noEndDate = value;
        return nextFee;
      }
      return fee;
    })
    formApi.setValues({ fees: nextFees })
    setFees(nextFees)
  }

  useEffect(() => {
    if (item.period && item.period.endDate === NO_END_DATE_VALUE) {
      onNoEndDateCheckboxChange(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormContent disabled={disabled}>
      <SimpleBox className={styles.box}>
        <CustomNestedField field={itemName}>
          {!disabled && (
            <div className={styles.remove}>
              <TrashIcon onClick={onRemoveClick} />
            </div>
          )}
          <div className={styles.row}>
            <div className={styles.valueField}>
              <SmartHostFeeFormItem
                Field={TextField}
                fieldName="fee"
                label={PER_DEVICE_FEE_LABEL}
                validate={valueValidate}
                hasCurrency
                placeholder={FEE_PLACEHOLDER}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.dateField}>
              <SmartHostFeeFormItem
                Field={CalendarField}
                fieldName="period.startDate"
                label={START_DATE_LABEL}
                additionalProps={disabledRanges}
                placeholder={START_DATE_PLACEHOLDER}
                validate={startDateValidate}
                notify={[`${itemName}.period.endDate`]}
              />
              <SmartHostFeeFormItem
                Field={CalendarField}
                fieldName="period.endDate"
                label={END_DATE_LABEL}
                isRequired={!item.noEndDate}
                validate={endDateValidate}
                notify={[`${itemName}.period.startDate`]}
                placeholder={END_DATE_PLACEHOLDER}
                overlayComponent={CalendarEndDateCheckbox}
                additionalProps={{
                  disabledDays: item.noEndDate
                  ? [{ before: moment(NO_END_DATE_VALUE, FORMAT).toDate() }]
                  : disabledRanges.disabledDays,
                  overlayProps: {
                    noEndDateEnabled: item.noEndDate || false,
                    onChange: onNoEndDateCheckboxChange,
                  }
                }}
              />
            </div>
          </div>
        </CustomNestedField>
      </SimpleBox>
    </FormContent>
  )
}

SmartHostFeeFormBox.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired
}

export default SmartHostFeeFormBox
