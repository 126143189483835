/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { buildSortPropertyByType } from 'helpers/tableHelpers'
import { CAMPAIGN, LOCATION } from 'constants/searchTypes'
import { getStatusValue } from 'helpers/dataTablesHelpers/commonHelpers'
import {
  STAND_ALONE_TYPE,
  STAND_ALONE_VALUE,
  TOP_ACTIVITIES_TYPE,
  TOP_ACTIVITIES_VALUE
} from 'constants/campaignTypes'

function getCampaignTypeValue(value) {
  switch (value.type) {
    case STAND_ALONE_TYPE:
      return STAND_ALONE_VALUE
    case TOP_ACTIVITIES_TYPE:
      return TOP_ACTIVITIES_VALUE
    default:
      return ''
  }
}

export const campaignsTableColumns = [
  {
    Header: 'Campaign Name',
    accessor: 'name',
    id: buildSortPropertyByType('name', CAMPAIGN)
  },
  {
    Header: 'Destination',
    accessor: 'location.name',
    id: buildSortPropertyByType('name', LOCATION)
  },
  {
    Header: 'Campaign Type',
    accessor: getCampaignTypeValue,
    id: buildSortPropertyByType('type', CAMPAIGN),
    pathToProperty: 'type'
  },
  {
    Header: 'Status',
    accessor: getStatusValue,
    id: buildSortPropertyByType('status', CAMPAIGN),
    pathToProperty: 'status'
  }
]

export const campaignsReportsColumns = [
  {
    Header: 'Campaign Name',
    accessor: 'name'
  },
  {
    Header: 'Destination',
    accessor: 'location.name'
  },
  {
    Header: 'Campaign Type',
    accessor: 'type'
  },
  {
    Header: 'Status',
    accessor: 'status'
  }
]
