/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'

import { isActionAllowed } from 'helpers/permissionHelper'

export const ButtonWithPermission = props => {
  const { accessLevel, Component, disabled, authUser } = props
  const isDisabled = !isActionAllowed(accessLevel, authUser) || disabled

  return <Component {...props} disabled={isDisabled} />
}

ButtonWithPermission.propTypes = {
  authUser: PropTypes.object.isRequired,
  accessLevel: PropTypes.string.isRequired,
  Component: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

ButtonWithPermission.defaultProps = {
  disabled: false
}
