/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'

import { actions } from 'reducers/activityProductList'

import { APPModal as APPModalComponent } from './APPModal'

export const mapStateToProps = state => {
  const {
    activityProductList: { data, dataState }
  } = state

  return {
    data,
    dataState
  }
}

export const mapDispatchToProps = dispatch => {
  const { getProductsList, resetList } = actions
  return bindActionCreators({ getProductsList, resetList }, dispatch)
}

export const APPModal = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(APPModalComponent)
