/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  WHITE_SCHEMA,
  LIGHT_RED_SCHEMA,
  LIGHT_BLUE_SCHEMA
} from 'constants/colorSchemas'

import styles from './EntityBox.scss'

export const buildColorSchemaByType = colorSchema => {
  switch (colorSchema) {
    case WHITE_SCHEMA:
      return styles.whiteColorSchema
    case LIGHT_RED_SCHEMA:
      return styles.lightRedColorSchema
    case LIGHT_BLUE_SCHEMA:
      return styles.lightBlueColorSchema
    default:
      return styles.whiteColorSchema
  }
}
