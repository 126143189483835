/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useEffect } from 'react'
import { useAction, usePrevious } from 'hooks'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { actions, getResellTestingState } from 'reducers/resellTesting/testingMode'
import { useLocation } from 'react-router-dom'

export function useResellTestMode() {
  const state = useSelector(getResellTestingState)
  const setModeValue = useAction(actions.setModeValue)
  const setModeDisabled = useAction(actions.setModeDisabled)
  const resetState = useAction(actions.resetState)

  return [state, { setModeValue, setModeDisabled, resetState }]
}

export function useTestModeChanged(callback) {
  const state = useSelector(getResellTestingState)

  const prevState = usePrevious(state)

  useEffect(() => {
    if (callback && get(prevState, 'isModeEnabled') !== get(state, 'isModeEnabled')) {
      callback(prevState, state)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isModeEnabled])
}

// check routing to update test mode enable toggle
// disable test mode when we leave resell part of app
export function useTestModeRouteControl() {
  const location = useLocation()
  const prevLocation = usePrevious(location)
  const [, api] = useResellTestMode()

  useEffect(() => {
    if (prevLocation !== location) {
      const isResell = /resale/.test(location.pathname)
      if (!isResell) {
        api.setModeValue(false)
      }
    }
  }, [api, location, prevLocation])
}
