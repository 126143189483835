/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { anyNumberValidation, blankValidate } from 'helpers/formHelpers'
import { compose } from 'redux'
import get from 'lodash/get'
import some from 'lodash/some'
import flatten from 'lodash/flatten'
import map from 'lodash/map'

export const relatedFieldBlankValidate = fieldNames => (value, values) => {
  if (
    compose(
      array => some(array, Boolean),
      array => map(array, fieldName => get(values, fieldName)),
      flatten
    )(fieldNames)
  ) {
    return blankValidate(value)
  }

  return null
}

export const anyNumberWithRelatedBlankValidate = fieldNames => (value, values) =>
  relatedFieldBlankValidate(fieldNames)(value, values) || anyNumberValidation(value)
