/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate as dayPickerFormatDate, parseDate } from 'react-day-picker/moment'
import { FORMAT } from 'constants/date'
import { buildSelectedDay } from './helpers'
import 'react-day-picker/lib/style.css'
import styles from './CalendarInput.scss'

export class CalendarInput extends React.Component {
  setDayInForm = (...args) => {
    const { onChange } = this.props
    onChange(...args)
  }

  handleSelectedDay = (day, modifiers) => {
    const { format } = this.props
    const dayToFormat = moment(day, format)
    if (dayToFormat.isValid()) {
      const formatedValue = dayToFormat.format(format)
      this.setDayInForm(formatedValue, day, modifiers)
    }
  }

  handleOnInput = ev => {
    this.setDayInForm(ev.target.value)
  }

  render() {
    const {
      placeholder,
      value,
      additionalProps,
      disabled,
      onBlur,
      onFocus,
      id,
      format,
      onDayPickerShow,
      formatDate,
      overlayComponent,
      readOnly,
      originalDate,
      inputProps,
      ref,
      className,
      classNames
    } = this.props

    const selectedDay = buildSelectedDay({ value, originalDate, format })

    return (
      <DayPickerInput
        ref={ref}
        formatDate={formatDate}
        parseDate={parseDate}
        format={format}
        onDayChange={this.handleSelectedDay}
        placeholder={placeholder}
        value={value}
        inputProps={{
          id,
          className,
          onBlur,
          onInput: this.handleOnInput,
          onFocus,
          disabled,
          readOnly,
          ...inputProps
        }}
        dayPickerProps={{
          selectedDays: selectedDay,
          month: selectedDay,
          ...additionalProps
        }}
        onDayPickerShow={onDayPickerShow}
        overlayComponent={overlayComponent}
        classNames={classNames}
      />
    )
  }
}

CalendarInput.defaultProps = {
  placeholder: '',
  value: '',
  additionalProps: {},
  disabled: false,
  onChange: () => null,
  onBlur: () => null,
  onFocus: () => null,
  onDayPickerShow: () => null,
  formatDate: dayPickerFormatDate,
  id: null,
  format: FORMAT,
  originalDate: '',
  overlayComponent: undefined,
  readOnly: false,
  inputProps: {},
  ref: null,
  className: styles.input,
  classNames: undefined
}
CalendarInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  additionalProps: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  id: PropTypes.string,
  format: PropTypes.string,
  originalDate: PropTypes.string,
  onDayPickerShow: PropTypes.func,
  formatDate: PropTypes.func,
  overlayComponent: PropTypes.any,
  readOnly: PropTypes.bool,
  inputProps: PropTypes.object,
  ref: PropTypes.object,
  className: PropTypes.string,
  classNames: PropTypes.object
}
