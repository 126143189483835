/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

// labels
export const TICKET_TITLE_LABEL = 'Ticket Title/Type:'
export const XPLORIE_PRICE_LABEL = 'Xplorie Price:'
export const EXTRAS_PRICE_LABEL = 'Extras Price:'
export const DESCRIPTION_LABEL = 'Description:'
export const TICKET_TO_GROUP_LABEL = 'Assign Ticket To Group:'
export const RETAIL_PRICE_LABEL = 'Retail Price:'
export const SHOW_XPLORIE_TICKET_LABEL = 'Show Xplorie Ticket:'
export const LIST_PRICE_LABEL = 'List Price:'

// placeholders
export const TICKET_TITLE_PLACEHOLDER = 'Ticket Title/Type'
export const XPLORIE_PRICE_PLACEHOLDER = 'Xplorie Price'
export const EXTRAS_PRICE_PLACEHOLDER = 'Extras Price'
export const DESCRIPTION_PLACEHOLDER = 'Description'
export const TICKET_TO_GROUP_PLACEHOLDER = 'Group'
export const RETAIL_PRICE_PLACEHOLDER = 'Retail Price'
export const LIST_PRICE_PLACEHOLDER = 'List Price'

// messages
export const DELETE_MESSAGE = 'Are you sure you want to delete the Ticket Type?'
export const YES_TEXT = 'Yes'
export const NO_TEXT = 'No'

export const switcherOptions = [{ value: false, label: '$' }, { value: true, label: '%' }]

export const LIST_PRICE_LIMIT = 9999
