/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import get from 'lodash/get'

const DEFAULT_ITEMS_PER_PAGE = 25

export const buildListParams = (options = {}) => {
  const type = get(options, 'type')
  const pageNumber = get(options, 'pageNumber')
  const itemsPerPage = get(options, 'itemsPerPage', DEFAULT_ITEMS_PER_PAGE)
  const sort = get(options, 'sort')
  const order = get(options, 'order')

  return {
    ...options,
    type,
    pageNumber,
    itemsPerPage,
    sort,
    order
  }
}

export const buildKeywordSearchOptions = options => ({
  keyword: get(options, 'keyword', ''),
  ...buildListParams(options)
})

export const buildURLForGettingList = baseUrl => `${baseUrl}/`
export const buildURLForGettingListCount = baseUrl => `${baseUrl}/count`
export const buildURLForGettingExtendedList = baseUrl => `${baseUrl}/extended`
export const buildURLForGettingFreeList = baseUrl => `${baseUrl}/free`
