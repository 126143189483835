/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import { activityProductTableColumns, buildDefaultButtonColumn } from 'helpers/dataTablesHelpers'
import { isActionAllowed, USER_LEVEL } from 'helpers/permissionHelper'
import { EditButton, ProgramsByAPP } from './AdditionalColumns'

export const buildTableColumns = props => {
  const { authUser } = props
  const showProgramByAppButton = isActionAllowed(USER_LEVEL, authUser)

  const tableColumns = activityProductTableColumns.concat({
    ...buildDefaultButtonColumn(),
    Cell: data => <EditButton {...props} data={data} />
  })

  if (showProgramByAppButton) {
    tableColumns.push({
      ...buildDefaultButtonColumn({ width: 125 }),
      Cell: data => <ProgramsByAPP {...props} data={data} />
    })
  }

  return tableColumns
}
