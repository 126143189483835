/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import classNames from 'classnames'
import {
  YELLOW_GREEN_SCHEMA,
  ORANGE_SCHEMA,
  SUBMIT_TITLE,
  CANCEL_TITLE
} from 'constants/buttonsOptions'
import { ControlButtonsContainer } from 'components/FormComponents'
import { buildDateTime } from '../helpers'
import styles from './styles.scss'

export function ScheduleWarningModal({ isOpen, onClose, date, time, message, onSubmit, title }) {
  const dateTime = buildDateTime({ date, time })

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit()
    }

    onClose()
  }

  return (
    <BasicModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      wrapperClassName={classNames(styles.modalWrapper)}
      renderBody={() => (
        <section className={styles.wrapper}>
          <span className={styles.message}>{message}</span>
          {dateTime && <span className={classNames(styles.message, styles.bold)}>{dateTime}</span>}
        </section>
      )}
    >
      <ControlButtonsContainer>
        <BasicButton colorSchema={ORANGE_SCHEMA} onClick={handleSubmit}>
          {SUBMIT_TITLE}
        </BasicButton>
        <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
          {CANCEL_TITLE}
        </BasicButton>
      </ControlButtonsContainer>
    </BasicModal>
  )
}

ScheduleWarningModal.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  time: PropTypes.object,
  title: PropTypes.string.isRequired
}

ScheduleWarningModal.defaultProps = {
  time: null
}
