/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { ViewEntityButton } from 'components/Buttons'
import get from 'lodash/get'
import { ORANGE_SCHEMA, SMALLER_SIZE } from 'constants/buttonsOptions'

import { PAST_VERSION_BUTTON_TITLE } from './types'
import { openInNewTab } from './helpers'

export function PreviousProgramsButtonComponent(props) {
  const { formData } = props
  const { id } = formData
  const hasPreviousId = Boolean(get(formData, 'versionData.previousId'))

  const onClickHandler = useCallback(() => {
    openInNewTab(id)
  }, [id])

  return (
    <ViewEntityButton
      size={SMALLER_SIZE}
      colorSchema={ORANGE_SCHEMA}
      data={formData}
      disabled={!hasPreviousId}
      onClick={onClickHandler}
    >
      {PAST_VERSION_BUTTON_TITLE}
    </ViewEntityButton>
  )
}

PreviousProgramsButtonComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  formData: PropTypes.object
}

PreviousProgramsButtonComponent.defaultProps = {
  formData: null
}

export const PreviousProgramsButton = withRouter(PreviousProgramsButtonComponent)
