/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

const PROGRAMS_LIST_NAME = 'PREVIOUS_PROGRAMS_LIST'
const GET_CURRENT_PROGRAM_NAME = 'GET_CURRENT_PROGRAM_NAME'

export default {
  PROGRAMS_LIST_NAME,
  GET_CURRENT_PROGRAM_NAME
}
