/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from 'reducers/editQueue'

import { LibraryTabContent as LibraryTabContentComponent } from './LibraryTabContent'

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

export const LibraryTabContent = connect(
  null,
  mapDispatchToProps
)(LibraryTabContentComponent)
