/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const TITLE = 'Warning!'
export const MESSAGE = 'Are you sure you want to activate'
export const MAKE_ACTIVE_MESSAGE = 'The following Activity Products will be set to “test mode”'
export const NO_ACTIVE_APP_MESSAGE = 'No Activity Products will become set to “test mode”.'
export const STEPS_COUNT = 2
