/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { SimpleTable } from 'components/Tables'
import { useDataStatesComparison } from 'hooks'
import { TableColumns } from './TableColumns'
import { HEADER } from './types'

function AccountList(props) {
  const {
    dataState,
    itemsCount,
    itemsPerPage,
    formDataState,
    data,
    needUpdate,
    getApi,
    extendedOptions,
    authUser,
    listWatchersState,
    onGetData,
    ...rest
  } = props

  const listApi = useRef()

  const onGetListApi = useCallback(
    api => {
      listApi.current = api
      if (getApi) {
        getApi(api)
      }
    },
    [getApi]
  )

  useDataStatesComparison(() => {
    onGetData(listApi.current.getListParameters())
  }, listWatchersState)

  return (
    <SimpleTable
      {...rest}
      onGetData={onGetData}
      getApi={onGetListApi}
      dataState={dataState}
      columns={TableColumns(props, authUser)}
      itemsPerPage={itemsPerPage}
      itemsCount={itemsCount}
      tableHeader={HEADER}
      data={data}
      extendedOptions={extendedOptions}
    />
  )
}

AccountList.propTypes = {
  dataState: PropTypes.string.isRequired,
  authUser: PropTypes.object.isRequired,
  onGetData: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemsCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  formDataState: PropTypes.string,
  listWatchersState: PropTypes.array,
  needUpdate: PropTypes.bool,
  getApi: PropTypes.func,
  extendedOptions: PropTypes.shape({
    type: PropTypes.string,
    keyword: PropTypes.string
  })
}

AccountList.defaultProps = {
  formDataState: null,
  extendedOptions: {},
  needUpdate: false,
  listWatchersState: [],
  getApi: null
}

export default AccountList
