/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { listInitialState } from './reducer'

export function getPMCEligibilityParamsListState(state) {
  return state.eligibility.pmcEligibilityParametersList
}
export function getPMCEligibilityParamsListStateByPMCId(state, id) {
  return getPMCEligibilityParamsListState(state)[id] || listInitialState
}
