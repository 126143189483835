/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { APPROVE_LEVEL } from 'constants/permissionTypes'
import { isActionAllowed } from 'helpers/permissionHelper'
import { STATUS_ACTIVE, STATUS_INACTIVE } from 'constants/entityStatus'
/**
 *
 * @param {Object} authUser - auth user data
 * @param {Object} entityData - pmc entity data
 * @returns {boolean}
 */
export function canDeactivate(authUser, entityData) {
  const isAllowed = isActionAllowed(APPROVE_LEVEL, authUser)

  if (!entityData || !isAllowed) {
    return false
  }
  const status = entityData && entityData.status
  return [STATUS_ACTIVE, STATUS_INACTIVE].includes(status)
}
