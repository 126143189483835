/* eslint-disable max-lines */
/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useEffect, useState } from 'react'
import store from 'connectors'
import { actions } from 'reducers/editQueue'
import { DiscountsSlider } from 'modules/Discounts'
import { AddressForm } from 'modules/Address'
import SeasonForm from 'modules/Programs/SeasonForm'
import { ProgramsTabs } from 'modules/Programs'
import { UnitsCommander, UnitManager, UnitsTabs } from 'modules/Units'
import { EligibilitySlider } from 'modules/Eligibility'
import { ActivityProviderSlider } from 'modules/ActivityProviders'
import { PMCsSlider } from 'modules/PMCs/PMCsSlider'
import { AccountSlider } from 'modules/Accounts/AccountSlider'
import { LocationSlider } from 'modules/Locations'
import { ActivityCategoryForm } from 'modules/ActivityCategory'
import { PMSSlider } from 'modules/PMS'
import { TileAccordionForm } from 'modules/TileAccordion'
import { ActivityTagsForm } from 'modules/ActivityTags'
import { ActivityModuleForm } from 'modules/ActivityModule'
import { RMSForm } from 'modules/RMS'
import { UniqPhoneNumberForm } from 'modules/UniqPhoneNumber'
import { ActivityProductSlider } from 'modules/ActivityProducts'
import { MarketSlider } from 'modules/Market'
import { CampaignsSlider } from 'modules/Campaigns'
import { ProgramsDiffContent } from 'modules/ProgramsDiff'

import {
  DEAL,
  PROGRAM,
  ADDRESS,
  SEASON,
  UNIT_COMMANDER,
  ELIGIBILITY,
  ACTIVITY_PRODUCT,
  ACTIVITY_PROVIDER,
  ACCOUNT,
  PMC,
  LOCATION,
  ACTIVITY_CATEGORY,
  PMS,
  TILE_ACCORDION,
  ACTIVITY_TAG,
  ACTIVITY_MODULE,
  RMS,
  UNIQ_PHONE_NUMBER,
  MARKET,
  CAMPAIGNS,
  PROGRAMS_DIFF,
  UNIT_MANAGER,
  UNIT
} from 'constants/formCodes'
import { NEW_SEASON_TITLE } from './types'
import { usePrevious, useDidMount } from './hooks'

const { dispatch } = store

function getSliderByType(type) {
  switch (type) {
    case DEAL:
      return {
        component: DiscountsSlider,
        defaultHeaderProps: {
          title: ''
        }
      }
    case ADDRESS:
      return {
        component: AddressForm,
        defaultHeaderProps: {
          title: 'New Address'
        }
      }
    case UNIT:
      return {
        component: UnitsTabs,
        defaultHeaderProps: {
          title: ''
        }
      }
    case SEASON:
      return {
        component: SeasonForm,
        defaultHeaderProps: {
          title: NEW_SEASON_TITLE
        }
      }
    case PROGRAM:
      return {
        component: ProgramsTabs,
        defaultHeaderProps: {
          title: 'New Program'
        }
      }
    case UNIT_COMMANDER:
      return {
        component: UnitsCommander,
        defaultHeaderProps: {
          title: 'Units assignment'
        }
      }
    case ELIGIBILITY:
      return {
        component: EligibilitySlider,
        defaultHeaderProps: {
          title: 'New Eligibility'
        }
      }
    case PROGRAMS_DIFF:
      return {
        component: ProgramsDiffContent,
        defaultHeaderProps: {}
      }
    case ACTIVITY_PRODUCT:
      return {
        component: ActivityProductSlider,
        defaultHeaderProps: {}
      }
    case ACTIVITY_PROVIDER:
      return {
        component: ActivityProviderSlider,
        defaultHeaderProps: {}
      }
    case CAMPAIGNS:
      return {
        component: CampaignsSlider,
        defaultHeaderProps: {}
      }
    case ACCOUNT:
      return {
        component: AccountSlider,
        defaultHeaderProps: {}
      }
    case PMC:
      return {
        component: PMCsSlider,
        defaultHeaderProps: {}
      }
    case LOCATION:
      return {
        component: LocationSlider,
        defaultHeaderProps: {
          title: 'New Country'
        }
      }
    case ACTIVITY_CATEGORY:
      return {
        component: ActivityCategoryForm,
        defaultHeaderProps: {}
      }
    case PMS:
      return {
        component: PMSSlider,
        defaultHeaderProps: {}
      }
    case TILE_ACCORDION:
      return {
        component: TileAccordionForm,
        defaultHeaderProps: {}
      }
    case ACTIVITY_TAG:
      return {
        component: ActivityTagsForm,
        defaultHeaderProps: {}
      }
    case ACTIVITY_MODULE:
      return {
        component: ActivityModuleForm,
        defaultHeaderProps: {}
      }
    case RMS:
      return {
        component: RMSForm,
        defaultHeaderProps: {}
      }
    case UNIQ_PHONE_NUMBER:
      return {
        component: UniqPhoneNumberForm,
        defaultHeaderProps: {}
      }
    case UNIT_MANAGER:
      return {
        component: UnitManager,
        defaultHeaderProps: {
          title: 'Unit Manager'
        }
      }
    case MARKET:
      return {
        component: MarketSlider,
        defaultHeaderProps: {
          title: 'New Market'
        }
      }
    default:
      return null
  }
}

function generateApiId(type) {
  return `new_${type.toLowerCase()}`
}

const close = id => {
  dispatch(actions.dequeue(id))
}

const update = (id, data = {}) => {
  dispatch(actions.updateSlide(id, data))
}

const open = (dataId, type, params = {}) => {
  const parameters = getSliderByType(type)
  const { component, defaultHeaderProps } = parameters
  const { props, headerProps } = params
  const sliderId = dataId || generateApiId(type)
  dispatch(
    actions.enqueue(
      sliderId,
      component,
      { dataId, ...props },
      { ...defaultHeaderProps, ...headerProps },
      type
    )
  )
  return sliderId
}

export function useSliderApi(type, changeCallback) {
  const parameters = getSliderByType(type)
  if (!parameters) {
    throw new Error(`Type [${type}] not found`)
  }

  const closeSlider = id => {
    close(id)
  }

  const updateSlider = (id, data = {}) => {
    update(id, data)
  }

  let currentPanels
  const handleSlidersChange = () => {
    if (changeCallback) {
      const previousPanels = currentPanels
      const panels = store.getState().editQueue.panelStack
      currentPanels = Object.keys(panels)
        .filter(key => panels[key] && panels[key].type && panels[key].type === type)
        .reduce((acc, key) => ({ ...acc, [key]: panels[key] }), {})

      if (previousPanels !== currentPanels && changeCallback) {
        changeCallback(currentPanels)
      }
    }
  }

  const unsubscribe = store.subscribe(handleSlidersChange)

  useEffect(
    () => () => {
      unsubscribe()
    },
    [unsubscribe]
  )

  const openSlider = (dataId, params = {}) => open(dataId, type, params)

  return { type, open: openSlider, close: closeSlider, update: updateSlider }
}

/**
 *
 * @param {string} sliderId
 * @param {string} type
 * @param {Object} options
 * @param {callback} options.onClose
 * @param {callback} options.onChange
 * @param {callback} options.onOpen
 *
 */
export const useSlider = (
  sliderId,
  type,
  options = { onClose: null, onChange: null, onOpen: null }
) => {
  if (!sliderId) {
    throw new Error('sliderId is mandatory')
  }
  if (!type) {
    throw new Error('type is mandatory')
  }
  if (!getSliderByType(type)) {
    throw new Error('Type not found')
  }

  const select = state => state.editQueue.panelStack[sliderId]

  const [currentState, setCurrentState] = useState(select(store.getState()))
  const previousState = usePrevious(currentState)

  const handleChange = () => {
    const state = select(store.getState())
    setCurrentState(state)
  }

  useDidMount(() => {
    const unsubscribe = store.subscribe(handleChange)
    return () => {
      unsubscribe()
    }
  })

  useEffect(() => {
    if (options && previousState !== currentState) {
      if (options.onChange) {
        options.onChange(previousState, currentState)
      }
      if (previousState && !currentState && options.onClose) {
        options.onClose()
      }
      if (!previousState && currentState && options.onOpen) {
        options.onOpen()
      }
    }
  }, [currentState, options, previousState])

  return [
    currentState,
    {
      close: close.bind(null, sliderId),
      update: update.bind(null, sliderId),
      open: open.bind(null, sliderId, type)
    }
  ]
}
