/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import styles from './styles.scss'
import { useDealContextMenuApi } from '../DiscountContextMenu/hooks'
import { useDealCalendarApi } from '../hooks'

export function DiscountCalendarDay(props) {
  const { day, modifiers } = props
  const { onOpenContextMenu } = useDealContextMenuApi()
  const { canSelectDay, canOpenContextMenu } = useDealCalendarApi()

  const onContextMenu = useCallback(
    event => {
      event.preventDefault()
      const canSelect = canSelectDay({
        startDate: day,
        endDate: day
      })
      if (!canSelect && !canOpenContextMenu) {
        return
      }

      const posX = get(event, 'nativeEvent.layerX', 0)
      const posY = get(event, 'nativeEvent.layerY', 0)

      onOpenContextMenu({
        posX,
        posY,
        day,
        modifiers
      })
    },
    [canSelectDay, day, canOpenContextMenu, onOpenContextMenu, modifiers]
  )

  return (
    <div className={styles.day} onContextMenu={onContextMenu}>
      {day.getDate()}
    </div>
  )
}

DiscountCalendarDay.propTypes = {
  day: PropTypes.object.isRequired,
  modifiers: PropTypes.object.isRequired
}
