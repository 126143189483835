import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { FORM_NAME, GET_DATA_BY_ID, SWITCH_STATUS } from './types'

const REQUEST_FORM = `${REQUEST_TYPE}_${FORM_NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${FORM_NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${FORM_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

const REQUEST_GET_DATA_BY_ID = `${REQUEST_TYPE}_${GET_DATA_BY_ID}`
const RESPONSE_GET_DATA_BY_ID = `${RESPONSE_TYPE}_${GET_DATA_BY_ID}`
const RESPONSE_GET_DATA_BY_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_DATA_BY_ID}`

const REQUEST_SWITCH_STATUS = `${REQUEST_TYPE}_${SWITCH_STATUS}`
const RESPONSE_SWITCH_STATUS = `${RESPONSE_TYPE}_${SWITCH_STATUS}`
const RESPONSE_SWITCH_STATUS_ERROR = `${RESPONSE_ERROR_TYPE}_${SWITCH_STATUS}`

export default {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID_ERROR,
  REQUEST_SWITCH_STATUS,
  RESPONSE_SWITCH_STATUS,
  RESPONSE_SWITCH_STATUS_ERROR
}
