/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { getAccountFormState } from 'reducers/account/accountForm'
import { getAccountLifeCycleState } from 'reducers/account/accountLifeCycle'

export const getAccountListState = state => state.account.accountList

export const getWatchersState = state => {
  const accountFormState = getAccountFormState(state)
  const accountLifeCycleState = getAccountLifeCycleState(state)

  return [
    ...Object.keys(accountFormState).reduce((acc, key) => {
      const formState = accountFormState[key]
      return [...acc, formState.dataState]
    }, []),
    ...Object.keys(accountLifeCycleState).reduce((acc, key) => {
      const formState = accountLifeCycleState[key]
      return [...acc, formState.activateAccountDataState, formState.deactivateAccountDataState]
    }, [])
  ]
}
