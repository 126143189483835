/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'informed'
import isFunction from 'lodash/isFunction'
import { FormContent, TextField } from 'components/FormComponents'
import classNames from 'classnames'
import styles from './styles'
import { REASON_FIELD_LABEL, REASON_FIELD_PLACEHOLDER, REASON_FIELD_FIELD } from './types'
/**
 *
 * @param {Object} param0
 * @param {*} param0.children
 * @returns
 */
export function SchedulCancellationForm({ dateTime, message, children, ...rest }) {
  return (
    <Form {...rest}>
      {formProps => (
        <FormContent disabled={false}>
          <section className={styles.wrapper}>
            <span className={styles.message}>{message}</span>
            {dateTime && (
              <span className={classNames(styles.message, styles.bold)}>{dateTime}</span>
            )}
            <div className={styles.reason}>
              <TextField
                label={REASON_FIELD_LABEL}
                placeholder={REASON_FIELD_PLACEHOLDER}
                fieldName={REASON_FIELD_FIELD}
                isRequired
              />
            </div>
            {isFunction(children) ? children(formProps) : children}
          </section>
        </FormContent>
      )}
    </Form>
  )
}

SchedulCancellationForm.propTypes = {
  dateTime: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
}

SchedulCancellationForm.defaultProps = {
  children: null
}
