/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { GENERATE_UNITS_TEMPLATE_URL } from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'
import { responseToFile } from 'helpers/downloadHelper'

export const getPmcUnitsTemplate = pmcId =>
  httpWrapper({
    url: buildPath(GENERATE_UNITS_TEMPLATE_URL, { pmcId }),
    method: 'get',
    responseType: 'blob'
  }).then(responseToFile)
