/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getProgramsInfoByAppId } from 'services/http/program'

import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST, RESET_DATA_STATE } = helpers

const getAppProgramsById = (appId, options = {}) => async dispatch => {
  const key = appId
  dispatch(actionCreater(REQUEST_LIST, { key }))

  const request = await getProgramsInfoByAppId(appId, options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST, { key }))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { data: request.data, key }))
    return 'getAppProgramsById success'
  }

  return 'getAppProgramsById failed'
}

const resetList = appId => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key: appId }))

export default {
  getAppProgramsById,
  resetList
}
