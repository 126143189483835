/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'

import { ButtonWithPermission } from 'components/Buttons'
import { BasicButton } from '@xplorie/ui-commons'

import { UPDATE_LEVEL } from 'constants/permissionTypes'

export const SubmitToPendingButton = props => {
  const { userEmail, createdBy, isValid } = props
  const isDisabled = userEmail !== createdBy || !isValid
  return (
    <ButtonWithPermission
      {...props}
      accessLevel={UPDATE_LEVEL}
      Component={BasicButton}
      disabled={isDisabled}
    />
  )
}
SubmitToPendingButton.propTypes = {
  userEmail: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  isValid: PropTypes.bool
}
SubmitToPendingButton.defaultProps = {
  isValid: true
}
