/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const ADMIN_GROUP_TYPE = 'PIE'
export const RESALE_GROUP_TYPE = 'RESELLER'
export const NEXE_GROUP_TYPE = 'NEXE'
export const ADDITIONAL_GROUP_TYPE = 'ADDITIONAL'

// Keys of roles
export const GUEST_ROLE = 'guest'
export const USER_ROLE = 'user'
export const APPROVER_ROLE = 'approver'
export const ADMIN_ROLE = 'admin'
export const SYS_ADMIN_ROLE = 'sysadmin'
export const VAC_SPECIALIST_ROLE = 'vacation_specialist'
export const VAC_SPECIALIST_TIER_2_ROLE = 'vacation_specialist_tier_2'
export const UNIT_TASK_MANAGE_ROLE = 'unit_tasks_manage'

// Values of roles
export const GUEST_TITLE = 'Guest'
export const USER_TITLE = 'User'
export const APPROVER_TITLE = 'Approver'
export const SYSADMIN_TITLE = 'Sysadmin'
export const ADMIN_TITLE = 'Admin'
export const VACATION_SPECIALIST_TITLE = 'Destination Guide'
export const VACATION_SPECIALIST_TIER_2_TITLE = 'Destination Guide Tier 2'
export const UNIT_TASK_MANAGE_TITLE = 'Manage Unit Tasks'
