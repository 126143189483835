import { useState } from 'react'
import find from 'lodash/find'

export const useSelectedEventsApi = () => {
  const [selectedEvents, setSelectedEvents] = useState([])

  const addEvent = event => setSelectedEvents(data => [...data, event])

  const removeEvent = ({ day, seriesId }) => {
    setSelectedEvents(data => data.filter(item => item.day !== day || item.seriesId !== seriesId))
  }

  const clearEvents = () => setSelectedEvents([])

  const isSelected = (seriesId, day) => find(selectedEvents, { day, seriesId })

  const getEvents = () => selectedEvents

  const hasSelectedEvents = () => selectedEvents.length > 0

  const handleClickEvent = info => {
    if (!info.jsEvent.ctrlKey && !info.jsEvent.metaKey) {
      return
    }
    const event = info.event.extendedProps

    if (isSelected(event.seriesId, event.day)) {
      removeEvent(event)
      return
    }

    addEvent(event)
  }

  return { handleClickEvent, isSelected, hasSelectedEvents, getEvents, clearEvents }
}
