/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'

export const RadioConditionallyLabel = ({ isChecked, children }) => (
  <label
    className={`tag button ${
      isChecked ? 'has-background-grey-light' : 'has-background-grey-lighter '
    }`}
  >
    {children}
  </label>
)

RadioConditionallyLabel.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired
}
