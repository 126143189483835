/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ViewEntityButton } from 'components/Buttons'
import { ORANGE_SCHEMA, SMALLER_SIZE, VIEW_TITLE } from 'constants/buttonsOptions'
import { useSliderApi } from 'hooks/useSliderApi'
import { ACTIVITY_PRODUCT } from 'constants/formCodes'

export function ViewButton(props) {
  const { data, readOnly } = props
  const activityProductSlider = useSliderApi(ACTIVITY_PRODUCT)

  const onOpenSlider = useCallback(() => {
    const { id, name } = data.original
    activityProductSlider.open(id, { headerProps: { title: name }, props: { readOnly } })
  }, [activityProductSlider, data, readOnly])

  return (
    <ViewEntityButton
      size={SMALLER_SIZE}
      colorSchema={ORANGE_SCHEMA}
      data={data.original}
      onClick={onOpenSlider}
    >
      {VIEW_TITLE}
    </ViewEntityButton>
  )
}

ViewButton.propTypes = {
  authUser: PropTypes.object.isRequired,
  data: PropTypes.object,
  readOnly: PropTypes.bool
}

ViewButton.defaultProps = {
  data: null,
  readOnly: false
}
