/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions as editQueue } from 'reducers/editQueue'
import { actions } from 'reducers/pmsList'
import { formActions } from 'reducers/pmsForm'
import { withAuthContext } from 'helpers/withAuthContext'

import { PMSList as PMSListComponent } from './PMSList'

export const mapStateToProps = state => ({
  ...state.pmsList,
  removeDataState: state.pmsForm.removeDataState,
  formDataState: state.pmsForm.formDataState
})

export const mapDispatchToProps = dispatch => {
  const { getPMSList: onGetData, ...rest } = actions
  return bindActionCreators({ ...editQueue, ...formActions, onGetData, ...rest }, dispatch)
}

export const PMSList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PMSListComponent)
