/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getActivityCategoryTags, getActivityCategoryTagsById } from 'services/http/activityCategory'

import helpers from './helpers'

const {
  REQUEST_GET_ACTIVITY_CATEGORY_TAGS,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_ERROR,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS,
  REQUEST_GET_ACTIVITY_CATEGORY_TAGS_BY_ID,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_BY_ID_ERROR,
  RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_BY_ID
} = helpers

const getTags = () => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_ACTIVITY_CATEGORY_TAGS))
  const request = await getActivityCategoryTags().catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_ACTIVITY_CATEGORY_TAGS, request.data))
    return 'getActivityCategoryTags success'
  }

  return 'getActivityCategoryTags failed'
}

const getTagsById = (id) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_ACTIVITY_CATEGORY_TAGS_BY_ID))
  const request = await getActivityCategoryTagsById(id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_BY_ID_ERROR))
  })
  if (request) {
    
    dispatch(actionCreater(RESPONSE_GET_ACTIVITY_CATEGORY_TAGS_BY_ID, request.data))
    return 'getActivityCategoryTagsById success'
  }

  return 'getActivityCategoryTagsById failed'
}

export default {
  getTags,
  getTagsById
}
