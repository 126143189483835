import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import {
  AND_OPERATOR,
  buildSearchColumn,
  STRICT_MODE,
  ACTIVITY,
  OPEN_GROUP_OPERATOR,
  CLOSE_GROUP_OPERATOR,
  OR_OPERATOR,
  ACTIVITY_TAGS,
} from 'helpers/searchHelpers'
import { STATUS_ACTIVE, STATUS_TESTING } from 'constants/entityStatus'
import { LIST_NAME, STATUS_FIELD, TAG_ID_FIELD } from './types'

const REQUEST_LIST = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

const getRequestFilters = id => [
  buildSearchColumn({
    entity: ACTIVITY,
    field: STATUS_FIELD,
    keyword: STATUS_ACTIVE,
    mode: STRICT_MODE,
    groupOperator: OPEN_GROUP_OPERATOR
  }),
  buildSearchColumn({
    entity: ACTIVITY,
    field: STATUS_FIELD,
    keyword: STATUS_TESTING,
    operator: OR_OPERATOR,
    mode: STRICT_MODE,
    groupOperator: CLOSE_GROUP_OPERATOR
  }),
  buildSearchColumn({
    entity: ACTIVITY_TAGS,
    field: TAG_ID_FIELD,
    keyword: id,
    operator: AND_OPERATOR,
    mode: STRICT_MODE
  })
]

const getRequestOptions = options => ({ type: ACTIVITY, ...options })

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  getRequestOptions,
  getRequestFilters
}
