/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'
import { STATUS_ACTIVE, STATUS_TESTING, STATUS_INACTIVE } from 'constants/entityStatus'
import { APPROVE_LEVEL, isActionAllowed } from 'helpers/permissionHelper'
import {
  DELAY_DEACTIVATION_MESSAGE,
  INACTIVE_MESSAGE,
  UPDATE_DATE_MODE,
  UPDATE_DEACTIVATED_DATE_MODE,
  SET_DEACTIVATION_MODE
} from './types'

export const canDeactivateApp = (authUser, entityData) => {
  const status = get(entityData, 'status')
  const canUpdate = isActionAllowed(APPROVE_LEVEL, authUser)

  return canUpdate && [STATUS_ACTIVE, STATUS_TESTING, STATUS_INACTIVE].includes(status)
}

export const buildDeactivationMessage = entityData => {
  const status = get(entityData, 'status')

  return [STATUS_ACTIVE, STATUS_TESTING].includes(status)
    ? DELAY_DEACTIVATION_MESSAGE
    : INACTIVE_MESSAGE
}

export function getDeactivationMode(entityData) {
  const deactivationDate = get(entityData, 'deactivationDate.date')
  const status = get(entityData, 'status')

  if (!deactivationDate && status === STATUS_ACTIVE) {
    return SET_DEACTIVATION_MODE
  }

  if (status === STATUS_INACTIVE && deactivationDate) {
    return UPDATE_DEACTIVATED_DATE_MODE
  }

  return UPDATE_DATE_MODE
}
