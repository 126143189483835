/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import withAuthorization from 'helpers/withAuthorization'

import { getRedirectPath } from './helpers'

function HomeRouteComponent({ component: Component, authUser, ...rest }) {
  const redirectPath = getRedirectPath(authUser)
  return (
    <Route
      {...rest}
      render={props => (redirectPath ? <Redirect to={redirectPath} /> : <Component {...props} />)}
    />
  )
}

HomeRouteComponent.propTypes = {
  component: PropTypes.func,
  authUser: PropTypes.object.isRequired
}

HomeRouteComponent.defaultProps = {
  component: () => {},
  accessLevels: []
}

export const HomeRoute = withAuthorization(HomeRouteComponent)
