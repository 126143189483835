/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  httpWrapper,
  buildListParams,
  buildURLForGettingEntityById,
  buildKeywordSearchOptions
} from 'services/http/helpers'
import {
  COUNTRY_URL,
  LOCATIONS_URL,
  SEARCH_URL,
  LOCATION_DEPENDENCIES_URL,
  PROGRAMS_BY_LOCATION_URL,
  APPS_BY_LOCATION_URL
} from 'endpoints'
import { buildPath } from 'helpers/httpHelpers'
import { buildURLForLinkLocations } from './helpers'

export const createCountry = contractData =>
  httpWrapper({
    url: COUNTRY_URL,
    method: 'post',
    data: contractData
  })

export const createLocation = contractData =>
  httpWrapper({
    url: LOCATIONS_URL,
    method: 'post',
    data: contractData
  })

export const updateLocation = contractData =>
  httpWrapper({
    url: LOCATIONS_URL,
    method: 'put',
    data: contractData
  })

export const getLocationById = (id, options) =>
  httpWrapper({
    url: buildURLForGettingEntityById(LOCATIONS_URL, id),
    method: 'get',
    options
  })

export const getLocationCampaignsById = (options, contractData) =>
  httpWrapper({
    url: `${SEARCH_URL}`,
    method: 'post',
    params: buildKeywordSearchOptions(options),
    data: contractData
  })

export const linkChildForLocation = (childId, parentId) =>
  httpWrapper({
    url: buildURLForLinkLocations(childId, parentId),
    method: 'post'
  })

export const getLocationTreeById = (authKey, id, params) =>
  httpWrapper({
    url: `${LOCATIONS_URL}/tree/${id}`,
    method: 'get',
    params
  })

export const deleteLocationById = (id, params) =>
  httpWrapper({
    url: `${LOCATIONS_URL}/${id}`,
    method: 'delete',
    params
  })

export const moveChildLocation = (childId, parentId, oldParentId) =>
  httpWrapper({
    url: `${LOCATIONS_URL}/tree/child/${childId}/parent/${parentId}/old-parent/${oldParentId}`,
    method: 'put'
  })

export const addLocationChildren = (parentId, contractData) =>
  httpWrapper({
    url: `${LOCATIONS_URL}/tree/${parentId}/parent`,
    method: 'post',
    data: contractData
  })

export const getLocationDependenciesById = id =>
  httpWrapper({
    url: buildPath(LOCATION_DEPENDENCIES_URL, { id }),
    method: 'get'
  })

export const getProgramsByDestinationId = (id, options, contractData) =>
  httpWrapper({
    url: buildPath(PROGRAMS_BY_LOCATION_URL, { id }),
    method: 'post',
    data: contractData,
    params: buildListParams(options)
  })

export const getActivitiesByDestinationId = (id, options, contractData) =>
  httpWrapper({
    url: buildPath(APPS_BY_LOCATION_URL, { id }),
    method: 'post',
    data: contractData,
    params: buildListParams(options)
  })
