/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from '@xplorie/ui-commons'
import classNames from 'classnames'
import styles from './TagWithAction.scss'
import { EXCLUDE_ACTION_TYPE, INCLUDE_ACTION_TYPE } from './types'

export function TagWithAction(props) {
  const { innerContent, colorSchema, action, actionType } = props

  return (
    <Tag colorSchema={colorSchema}>
      <div className={styles.innerContentWrapper}>
        <div className={styles.text}>{innerContent}</div>
        <div
          className={classNames(styles.action, {
            [styles.minus]: actionType === EXCLUDE_ACTION_TYPE,
            [styles.plus]: actionType === INCLUDE_ACTION_TYPE
          })}
          onClick={action}
        />
      </div>
    </Tag>
  )
}

TagWithAction.propTypes = {
  innerContent: PropTypes.string.isRequired,
  colorSchema: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired
}
