/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { initialAccountLifeCycleState } from './reducer'

export const getAccountLifeCycleState = state => state.account.accountLifeCycle

export const getAccountLifeCycleStateById = (state, accountId) => {
  const accountLifeCycleState = getAccountLifeCycleState(state)

  return accountLifeCycleState[accountId] || initialAccountLifeCycleState
}
