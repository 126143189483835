/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const MODAL_TITLE = 'Upload Guests through a CSV file'
export const BUTTON_TEXT = 'Upload Guest'
export const BUTTON_TEMPLATE_TEXT = 'Download Template'
export const MAX_SIZE_MESSAGE = 'Max Size - 1 MB'
export const FILE_FORMAT = '.csv'

