/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import { Field } from 'components/FormComponents'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { TicketTypeItem } from './TicketTypeItem'
import styles from './TypeOfTicketField.scss'
import { TICKETS_TYPE_LABEL } from '../types'

export function TypeOfTicketField(props) {
  const {
    data,
    dataState,
    fieldApi: { setValue },
    fieldState: { value = [] }
  } = props
  const [bufferTypes, setBufferTypesState] = useState([])
  const isLoaded = dataState === DATA_STATE_RECIEVED
  const ticketTypes = sortBy(uniqBy([...bufferTypes, ...value, ...data], 'id'), ['id'])

  const findTicketTypeIndex = ticketType => value.findIndex(({ id }) => id === ticketType.id)

  const onClickHandler = ticketType => () => {
    const index = findTicketTypeIndex(ticketType)
    if (index === -1) {
      setValue([...value, ticketType])
    } else {
      const removedTypes = value.splice(index, 1)
      setValue([...value])
      setBufferTypesState([...bufferTypes, ...removedTypes])
    }
  }

  return (
    <Field className={styles.content} classNameWrapper={styles.wrapper} label={TICKETS_TYPE_LABEL}>
      {isLoaded &&
        ticketTypes.map(type => {
          const isActive = findTicketTypeIndex(type) !== -1
          return (
            <TicketTypeItem
              key={type.id}
              onClick={onClickHandler(type)}
              isActive={isActive}
              {...type}
            />
          )
        })}
    </Field>
  )
}
TypeOfTicketField.propTypes = {
  data: PropTypes.array.isRequired,
  dataState: PropTypes.string.isRequired,
  fieldApi: PropTypes.object.isRequired,
  fieldState: PropTypes.object.isRequired
}
