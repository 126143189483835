/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  getEntityById,
  updateEntity,
  createEntity,
  removeEntity,
  activateEntity,
  deactivateEntity
} from 'services/http/deals'

import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID,
  RESPONSE_GET_DATA_BY_ID_ERROR,
  REQUEST_CREATE_DEAL_OPTIONS,
  RESPONSE_CREATE_DEAL_OPTIONS,
  RESPONSE_CREATE_DEAL_OPTIONS_ERROR,
  REQUEST_UPDATE_DEAL_OPTIONS,
  RESPONSE_UPDATE_DEAL_OPTIONS,
  RESPONSE_UPDATE_DEAL_OPTIONS_ERROR,
  REQUEST_DELETE_DEAL,
  RESPONSE_DELETE_DEAL,
  RESPONSE_DELETE_DEAL_ERROR,
  REQUEST_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL,
  RESPONSE_ACTIVATE_DEAL_ERROR,
  REQUEST_DEACTIVATE_DEAL,
  RESPONSE_DEACTIVATE_DEAL,
  RESPONSE_DEACTIVATE_DEAL_ERROR,
  buildActivateSuccessMessage,
  buildDeactivateSuccessMessage
} = helpers

/**
 *
 * @param {string} dealId - deal id
 * @param {Object} entityData - contract deal data
 * @param {Object} [params] - request params
 */
const updateDeal = (dealId, entityData, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_UPDATE_DEAL_OPTIONS))
  try {
    await updateEntity(dealId, entityData, params)
    const getEntityRequest = await getEntityById(dealId)
    dispatch(actionCreater(RESPONSE_UPDATE_DEAL_OPTIONS, getEntityRequest.data))
    return 'updateDeal success'
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_UPDATE_DEAL_OPTIONS_ERROR))
  }
  return 'updateDeal failed'
}
/**
 *
 * @param {string} id - deal id
 * @param {Object} [params] - request params
 */
const getDealDataById = (id, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_DATA_BY_ID))
  const request = await getEntityById(id, params).catch(err => {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_DATA_BY_ID_ERROR))
  })

  if (request) {
    dispatch(actionCreater(RESPONSE_GET_DATA_BY_ID, request.data))
    return 'getDealDataById success'
  }

  return 'getDealDataById failed'
}

/**
 *
 * @param {Object} entityData - contract data
 * @param {Object} [params] - request params
 */
const createDeal = (entityData, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_CREATE_DEAL_OPTIONS))
  try {
    const createRequest = await createEntity(entityData, params)
    const getEntityRequest = await getEntityById(createRequest.data)
    dispatch(actionCreater(RESPONSE_CREATE_DEAL_OPTIONS, getEntityRequest.data))
    return 'createDeal success'
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_CREATE_DEAL_OPTIONS_ERROR))
  }
  return 'createDeal failed'
}

/**
 *
 * @param {string} dealId - deal id
 * @param {Object} [params] - request params
 */
const removeDeal = (dealId, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_DELETE_DEAL))
  const request = await removeEntity(dealId, params).catch(err => {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DELETE_DEAL_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_DELETE_DEAL))
    return 'removeDeal success'
  }

  return 'removeDeal failed'
}

/**
 *
 * @param {string} dealId - deal id
 * @param {Object} params - additional params
 * @param {string} [params.dealName = ] - current deal name (used for notification)
 */
const activateDeal = (dealId, params = { dealName: '' }) => async dispatch => {
  dispatch(actionCreater(REQUEST_ACTIVATE_DEAL))
  try {
    await activateEntity(dealId)
    const request = await getEntityById(dealId)
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildActivateSuccessMessage(params.dealName) }))
    dispatch(actionCreater(RESPONSE_ACTIVATE_DEAL, request.data))
    return 'activateDeal success'
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ACTIVATE_DEAL_ERROR))
  }
  return 'activateDeal failed'
}

/**
 *
 * @param {string} dealId- deal id
 * @param {Object} params - additional params
 * @param {string} [params.dealName = ] - current deal name (used for notification)
 */
const deactivateDeal = (dealId, params = { dealName: '' }) => async dispatch => {
  dispatch(actionCreater(REQUEST_DEACTIVATE_DEAL))
  try {
    await deactivateEntity(dealId)
    const request = await getEntityById(dealId)
    const {
      message: { message, send }
    } = request
    dispatch(send({ ...message, message: buildDeactivateSuccessMessage(params.dealName) }))
    dispatch(actionCreater(RESPONSE_DEACTIVATE_DEAL, request.data))
    return 'deactivateDeal success'
  } catch (err) {
    const { send, message } = err
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_DEACTIVATE_DEAL_ERROR))
  }
  return 'deactivateDeal failed'
}

const resetDealForm = () => ({
  type: RESET_DATA_STATE
})

export default {
  resetDealForm,
  createDeal,
  updateDeal,
  getDealDataById,
  removeDeal,
  activateDeal,
  deactivateDeal
}
