/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { LocationsContainer } from 'modules/Locations/LocationsList/LocationsContainer'
import { helpers } from 'reducers/locationsTree'
import styles from './LocationsItem.scss'

export function LocationsItem(props) {
  const {
    location: { id, name, altName, type },
    location,
    children,
    setMenuOpenedState,
    hasMenuOpened,
    menuProps,
    updateTreeState,
    treeState,
    parentId,
    parentType,
    linkLocations,
    canDrag
  } = props

  const globalId = helpers.buildIdForGlobalTree(id, parentId)
  const isCollapsed = treeState[globalId] && treeState[globalId].isCollapsed

  const collapseToggle = () => {
    if (children.length > 0) {
      updateTreeState({
        id: helpers.buildIdForGlobalTree(id, parentId),
        isCollapsed: !isCollapsed
      })
    }
  }

  const onContextMenuHandler = e => {
    setMenuOpenedState({ ...location, children, parentId, parentType }, e)
    e.preventDefault()
  }

  const onDragOverHandler = ev => {
    ev.preventDefault()
    ev.stopPropagation()
  }

  const onDragStartHandler = ev => {
    ev.dataTransfer.setData('id', id)
    ev.dataTransfer.setData('oldParent', parentId)
    ev.stopPropagation()
  }

  const onDropHandler = ev => {
    ev.preventDefault()
    ev.stopPropagation()
    const originalId = ev.dataTransfer.getData('id')
    const oldParent = ev.dataTransfer.getData('oldParent')
    const targetId = id
    if (originalId !== targetId) {
      linkLocations({
        childId: originalId,
        parentId: targetId,
        oldParentId: oldParent,
        targetParentId: parentId
      })
    }
  }

  return (
    <div
      className={styles.itemWrapper}
      draggable={canDrag}
      onDragStart={onDragStartHandler}
      onDrop={onDropHandler}
      onDragOver={onDragOverHandler}
    >
      {children.length > 0 && (
        <div className={styles.controlWrapper}>
          <a
            className={classNames(styles.collapseButton, {
              [styles.isCollapsed]: isCollapsed
            })}
            onClick={collapseToggle}
          />
        </div>
      )}
      <div
        className={styles.itemContent}
        onClick={collapseToggle}
        onContextMenu={onContextMenuHandler}
      >
        <div
          className={classNames(styles.title, {
            [styles.hasMenuOpened]:
              hasMenuOpened && menuProps.id === id && parentId === menuProps.parentId
          })}
        >
          <span>{name}</span>
          {altName && <span className={styles.altName}>{altName}</span>}
          {type && <span className={styles.locationType}>{type.toLowerCase()}</span>}
        </div>
      </div>
      {isCollapsed && (
        <div className={styles.container}>
          <LocationsContainer
            setMenuOpenedState={setMenuOpenedState}
            hasMenuOpened={hasMenuOpened}
            menuProps={menuProps}
            data={children}
            updateTreeState={updateTreeState}
            treeState={treeState}
            parentId={id}
            parentType={type}
            linkLocations={linkLocations}
            canDrag={canDrag}
          />
        </div>
      )}
    </div>
  )
}
LocationsItem.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.array,
  setMenuOpenedState: PropTypes.func.isRequired,
  hasMenuOpened: PropTypes.bool.isRequired,
  menuProps: PropTypes.object.isRequired,
  updateTreeState: PropTypes.func.isRequired,
  treeState: PropTypes.object.isRequired,
  parentId: PropTypes.string,
  location: PropTypes.object,
  parentType: PropTypes.string,
  linkLocations: PropTypes.func.isRequired,
  canDrag: PropTypes.bool.isRequired
}
LocationsItem.defaultProps = {
  name: null,
  type: null,
  parentId: null,
  children: [],
  location: {},
  parentType: null
}
