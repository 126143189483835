/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
// deal types
export const DEAL_TRIGGERED_TYPE = 'TRIGGERED'
export const DEAL_COMPLIMENTARY_TYPE = 'COMPLIMENTARY'
export const DEAL_NON_COMPLIMENTARY_TYPE = 'EXCLUSIVE'
export const DEAL_PACKAGE_TYPE = 'PACKAGE'
export const DEAL_DISCOUNT_TYPE = 'DISCOUNT'

// deal group types
export const DEAL_GROUP_TRIGGER_TYPE = 'TRIGGER'
export const DEAL_GROUP_ANY_TYPE = 'ANY'
export const DEAL_GROUP_OR_TYPE = 'OR'
