/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const INACTIVE_MESSAGE = 'This AP Product was deactivated on'
export const DELAY_DEACTIVATION_MESSAGE = 'This AP Product will become inactive on'

export const DEACTIVATION_MODAL_STEP = 3

export const UPDATE_DATE_MODE = 'UPDATE_DATE_MODE'
export const UPDATE_DEACTIVATED_DATE_MODE = 'UPDATE_DEACTIVATED_DATE_MODE'
export const SET_DEACTIVATION_MODE = 'SET_DEACTIVATION_MODE'
