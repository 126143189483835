/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CalendarInput } from 'modules/CalendarInput'
import { getStartDateFromValue, getEndDateFromValue, buildValue } from './helpers'

import styles from './DatePeriod.scss'

export function DatePeriod(props) {
  const {
    fieldState: { value },
    fieldApi: { setValue, setTouched },
    startDate: startDateProps,
    endDate: endDateProps,
    errorClassName,
    onChange,
    disabled
  } = props

  const startDateMask = useCallback(
    startNumber => buildValue(startNumber, getEndDateFromValue(value)),
    [value]
  )
  const endDateMask = useCallback(
    endNumber => buildValue(getStartDateFromValue(value), endNumber),
    [value]
  )

  const onChangeHandler = useCallback(
    mask => newValue => {
      setValue(mask(newValue))
      setTouched(true)
      if (onChange) {
        onChange(newValue)
      }
    },
    [onChange, setTouched, setValue]
  )

  return (
    <div className={styles.wrapper}>
      <CalendarInput
        value={getStartDateFromValue(value)}
        onChange={onChangeHandler(startDateMask)}
        className={classNames(styles.input, errorClassName)}
        disabled={disabled}
        {...startDateProps}
      />
      <CalendarInput
        value={getEndDateFromValue(value)}
        onChange={onChangeHandler(endDateMask)}
        className={classNames(styles.input, errorClassName)}
        classNames={{
          overlay: styles.dayPickerOverlay,
          overlayWrapper: styles.dayPickerOverlayWrapper
        }}
        disabled={disabled}
        {...endDateProps}
      />
    </div>
  )
}

DatePeriod.propTypes = {
  fieldState: PropTypes.object.isRequired,
  fieldApi: PropTypes.object.isRequired,
  endDate: PropTypes.object,
  startDate: PropTypes.object,
  errorClassName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
DatePeriod.defaultProps = {
  startDate: {},
  endDate: {},
  onChange: null,
  disabled: false
}
