/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { ContentWithNav } from 'components/ContentWithNav'
import { AddEntityButton } from 'components/Buttons'
import { HeaderWrapper, ButtonsWrapper } from 'components/Wrappers'
import { usePrevious, useDidMount, useProgramSlider } from 'hooks'
import { ProgramsList } from 'modules/Programs/ProgramsList'
import withAuthorization from 'helpers/withAuthorization'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { PIECurtainLoader } from 'components/Loaders'
import { getQueryStringParams, buildRequestParams } from 'helpers/searchParamsHelper'

import { Tabs } from './Tabs'
import { getParamsBySearchType, tabTypeExist, buildTableColumns } from './helpers'
import { MY_DRAFTS_TYPE, TITLE_PAGE, TEXT_BTN_ADD_NEW } from './types'
import styles from './MyWork.scss'

export function MyWork(props) {
  const { authUser, getListState, getList, history, location } = props
  const listApi = useRef()
  const [tab, setTab] = useState(null)
  const { dataState, data, itemsCount, itemsPerPage, pageNumber } = getListState(tab)
  const prevTab = usePrevious(tab)
  const prevLocation = usePrevious(location)
  const { contractData, listHeader } = getParamsBySearchType(authUser, tab)
  const tableColumns = buildTableColumns(tab, props)
  const { open, isCreating } = useProgramSlider()

  const onGetListApi = useCallback(api => {
    listApi.current = api
  }, [])

  const onChangeTabHandler = useCallback(
    newTab => {
      const params = getQueryStringParams(location.search)
      history.push({ search: buildRequestParams({ ...params, type: newTab }) })
    },
    [history, location.search]
  )

  const onGetPrograms = useCallback(
    options => {
      const requestOptions = options || listApi.current.getListParameters()
      getList(tab, requestOptions, contractData)
    },
    [contractData, getList, tab]
  )

  const onCreateNewProgram = useCallback(() => {
    open()
  }, [open])

  useEffect(() => {
    if (prevTab !== tab && tabTypeExist(tab)) {
      onGetPrograms()
    }
  }, [onGetPrograms, prevTab, tab])

  // setting default tab if type do not exist
  useDidMount(() => {
    const params = getQueryStringParams(location.search)
    if (params && !params.type) {
      setTab(MY_DRAFTS_TYPE)
    }
  })

  useEffect(() => {
    if (location !== prevLocation) {
      const params = getQueryStringParams(location.search)
      if (params && params.type && tabTypeExist(params.type)) {
        setTab(params.type)
      }
    }
  }, [location, onGetPrograms, prevLocation, prevTab, tab])

  return (
    <ContentWithNav>
      <section className={styles.customWrapper}>
        {isCreating && <PIECurtainLoader />}
        <HeaderWrapper title={TITLE_PAGE} />
        <div className={styles.containerWrapper}>
          <ButtonsWrapper>
            <Column className={styles.tabsWrapper}>
              <Tabs activeType={tab} onChange={onChangeTabHandler} />
            </Column>
            <Column>
              <AddEntityButton
                id="create-program"
                onClick={onCreateNewProgram}
                colorSchema={ORANGE_SCHEMA}
              >
                {TEXT_BTN_ADD_NEW}
              </AddEntityButton>
            </Column>
          </ButtonsWrapper>
          <div>
            <ProgramsList
              getApi={onGetListApi}
              onGetData={onGetPrograms}
              dataState={dataState}
              data={data}
              itemsCount={itemsCount}
              itemsPerPage={itemsPerPage}
              pageNumber={pageNumber}
              tableHeader={listHeader}
              columns={tableColumns}
              // TODO: Need adjust and refactor it with reimplement this functionality.
              needFirstGetData={false}
            />
          </div>
        </div>
      </section>
    </ContentWithNav>
  )
}

MyWork.propTypes = {
  authUser: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getListState: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired
}

export default withAuthorization(MyWork)
