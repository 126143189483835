/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { PROGRAMS_URL } from 'endpoints'

export const getEntityByProgramId = id =>
  httpWrapper({ url: `${PROGRAMS_URL}/${id}/marketings`, method: 'get' })

export const updateEntityByProgramId = (id, contractData) =>
  httpWrapper({ url: `${PROGRAMS_URL}/${id}/marketings`, method: 'put', data: contractData })
