/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const AddressForm = loadable(
  () => import(/* webpackChunkName: 'AddressForm', webpackPrefetch: true */ './AddressForm'),
  {
    resolveComponent: components => components.AddressForm
  }
)

export { AddressForm }
