/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useMemo, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { useDataStatesComparison, useLoading, useDidMount } from 'hooks'
import { BeforeCloseModal } from 'modules/Modals'
import { MarketingForm } from 'modules/Locations'
import { BouncingLoader } from 'components/Loaders'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'

export function MarketingTab(props) {
  const {
    dataId,
    tabsApi,
    closeDataStates,
    getLocationMarketingById,
    entityDataState,
    onClose,
    slideEvents
  } = props
  const [showBeforeCloseModal, setShowBeforeCloseModalState] = useState(false)
  const beforeCloseModalApi = useRef()
  const [, { isLoaded, isError }] = useLoading(entityDataState)
  const showLoading = useMemo(() => dataId && (!isLoaded && !isError), [dataId, isError, isLoaded])

  useDidMount(() => {
    if (dataId) {
      getLocationMarketingById(dataId)
    }
    return () => {
      slideEvents.onBeforeClose = null
      tabsApi.onBeforeChange = null
    }
  })

  const onGetModalApi = useCallback(api => {
    beforeCloseModalApi.current = api
  }, [])

  const onChangeHandler = useCallback(formState => {
    const isTouched = !isEmpty(formState.touched)
    setShowBeforeCloseModalState(isTouched)
  }, [])

  const beforeCloseHandler = useCallback(
    () => (showBeforeCloseModal ? beforeCloseModalApi.current.open() : !showBeforeCloseModal),
    [showBeforeCloseModal]
  )

  useDataStatesComparison(
    () => {
      onClose()
    },
    closeDataStates,
    DATA_STATE_RECIEVED
  )

  slideEvents.onBeforeClose = useMemo(() => beforeCloseHandler, [beforeCloseHandler])
  tabsApi.onBeforeChange = useMemo(() => beforeCloseHandler, [beforeCloseHandler])

  if (showLoading) {
    return <BouncingLoader />
  }

  return (
    <>
      <MarketingForm {...props} onChange={onChangeHandler} />
      <BeforeCloseModal getApi={onGetModalApi} />
    </>
  )
}

MarketingTab.propTypes = {
  dataId: PropTypes.string,
  tabsApi: PropTypes.object.isRequired,
  slideEvents: PropTypes.object.isRequired,
  closeDataStates: PropTypes.array.isRequired,
  getLocationMarketingById: PropTypes.func.isRequired,
  entityDataState: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}
MarketingTab.defaultProps = {
  dataId: null
}
