/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import { NAME, LOCATION_TREE_DATA } from './types'

const REQUEST_LOCATION_TREE_DATA = `${REQUEST_TYPE}_${LOCATION_TREE_DATA}`
const RESPONSE_LOCATION_TREE_DATA = `${RESPONSE_TYPE}_${LOCATION_TREE_DATA}`
const RESPONSE_LOCATION_TREE_DATA_ERROR = `${RESPONSE_ERROR_TYPE}_${LOCATION_TREE_DATA}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

export default {
  REQUEST_LOCATION_TREE_DATA,
  RESPONSE_LOCATION_TREE_DATA,
  RESPONSE_LOCATION_TREE_DATA_ERROR,
  RESET_DATA_STATE
}
