/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { getListWatcherStates } from 'reducers/deals/dealCommon'
import { actions, getDealsListState } from 'reducers/deals/dealsList'
import { withAuthContext } from 'helpers/withAuthContext'

import { DiscountsList as DiscountsListComponent } from './DiscountsList'

export const mapStateToProps = state => {
  const listState = getDealsListState(state)

  return {
    dataStateWatchers: getListWatcherStates(state),
    ...listState
  }
}

export const mapDispatchToProps = dispatch => {
  const { getDealsList: onGetData } = actions
  return bindActionCreators({ onGetData }, dispatch)
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps
})

export const DiscountsList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )
)(DiscountsListComponent)
