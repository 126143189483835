/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function MarkIcon(props) {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5.9999 11.2L1.7999 7.00001L0.399902 8.40001L5.9999 14L17.9999 2.00001L16.5999 0.600006L5.9999 11.2Z" />
    </svg>
  )
}
