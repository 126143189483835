/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

const SEASONS_LIST_NAME = 'SEASONS_LIST_NAME'
const SEASON_REMOVE_ACTION = 'SEASON_REMOVE_ACTION'

export default {
  SEASONS_LIST_NAME,
  SEASON_REMOVE_ACTION
}
