/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { buildPermissions } from 'modules/Programs'
import { ViewEntityButton, UpdateEntityButton } from 'components/Buttons'

import { ORANGE_SCHEMA, SMALLER_SIZE, EDIT_TITLE, VIEW_TITLE } from 'constants/buttonsOptions'
import { useSliderApi } from 'hooks/useSliderApi'
import { PROGRAM } from 'constants/formCodes'

export function EditButton(props) {
  const { data, authUser } = props
  const { canUpdateProgramData } = buildPermissions({ authUser, data: data.original })
  const TITLE = canUpdateProgramData ? EDIT_TITLE : VIEW_TITLE
  const Button = canUpdateProgramData ? UpdateEntityButton : ViewEntityButton
  const programSlider = useSliderApi(PROGRAM)

  const onEditClick = useCallback(() => {
    const { id, label } = data.original
    programSlider.open(id, { headerProps: { title: label } })
  }, [programSlider, data.original])

  return (
    <Button
      size={SMALLER_SIZE}
      colorSchema={ORANGE_SCHEMA}
      data={data.original}
      onClick={onEditClick}
    >
      {TITLE}
    </Button>
  )
}

EditButton.propTypes = {
  authUser: PropTypes.object.isRequired,
  data: PropTypes.object
}

EditButton.defaultProps = {
  data: null
}
