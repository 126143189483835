/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function CampaignIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M504.5 68.5H7.5A7.499 7.499 0 000 76v360c0 4.143 3.357 7.5 7.5 7.5h497c4.143 0 7.5-3.357 7.5-7.5V76c0-4.143-3.357-7.5-7.5-7.5zm-7.5 360H15v-345h482v345z" />
      <path d="M479.691 400.587L329.014 256.054l150.68-144.644a7.5 7.5 0 00.216-10.604 7.497 7.497 0 00-10.604-.217L313.755 249.91a7.333 7.333 0 00-1.555 1.493l-18.251 17.52c-20.924 20.207-54.97 20.209-75.913-.016l-18.234-17.504a7.461 7.461 0 00-1.557-1.495L42.694 100.59a7.5 7.5 0 00-10.388 10.821l150.679 144.643L32.309 400.588a7.5 7.5 0 00-.221 10.604 7.476 7.476 0 005.413 2.309 7.47 7.47 0 005.19-2.088L193.815 266.45l13.817 13.263c13.338 12.882 30.855 19.32 48.374 19.32 17.511 0 35.022-6.437 48.346-19.305l13.833-13.279 151.124 144.963a7.47 7.47 0 005.19 2.088c1.972 0 3.94-.772 5.413-2.309a7.498 7.498 0 00-.221-10.604z" />
    </svg>
  )
}
