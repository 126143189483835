/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Scope } from 'informed'
import { SelectField } from 'components/FormComponents'
import { JustTable } from '@xplorie/ui-commons'
import get from 'lodash/get'
import { DeleteButton } from './DeleteButton'
import { getModesOptionsByEligibilityType, getComponentByTypeMode } from './helpers'
import { MODE_PLACEHOLDER } from './types'
import styles from './AssignedEligibilityTable.scss'

export function EligibilityRow({
  value,
  readOnly,
  onDelete,
  disabled,
  formState,
  validate,
  showLevelColumn
}) {
  const level = get(value, 'level')
  const type = get(value, 'parameter.type')
  const label = get(value, 'parameter.label')
  const mode = get(formState, `values.${value.id}.mode`)

  const modes = getModesOptionsByEligibilityType(type)
  const { Component: ValueField, props: fieldProps } = getComponentByTypeMode(type, mode)

  const onDeleteHandler = useCallback(() => {
    if (onDelete) {
      onDelete(value.id)
    }
  }, [onDelete, value])

  return (
    <Scope scope={value.id}>
      <JustTable.Row>
        <JustTable.Cell>{label}</JustTable.Cell>
        {showLevelColumn && <JustTable.Cell>{level}</JustTable.Cell>}
        <JustTable.Cell className={styles.type}>{type}</JustTable.Cell>
        <JustTable.Cell>
          <SelectField
            fieldName="mode"
            errorClassName={styles.error}
            options={modes}
            placeholder={MODE_PLACEHOLDER}
            isRequired
            onChange={validate}
            fieldProps={{
              error: null
            }}
          />
        </JustTable.Cell>
        <JustTable.Cell>
          <ValueField
            fieldName="value"
            errorClassName={styles.error}
            {...fieldProps}
            isRequired
            fieldProps={{ error: null }}
            onChange={validate}
          />
        </JustTable.Cell>
        {!readOnly && (
          <JustTable.Cell>
            <DeleteButton onClick={onDeleteHandler} disabled={disabled} />
          </JustTable.Cell>
        )}
      </JustTable.Row>
    </Scope>
  )
}
EligibilityRow.propTypes = {
  value: PropTypes.object.isRequired,
  formApi: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  children: PropTypes.any,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  showLevelColumn: PropTypes.bool,
  validate: PropTypes.func.isRequired
}
EligibilityRow.defaultProps = {
  children: null,
  error: null,
  disabled: false,
  showLevelColumn: false,
  readOnly: false
}
