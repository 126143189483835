/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { TagInput } from '@xplorie/ui-commons'
import { asField } from 'informed'
import get from 'lodash/get'
import { Field } from 'components/FormComponents/Field'
import { compose } from 'redux'
import { withFormContext } from 'components/FormComponents/helpers'
import styles from './style.scss'

function TagInputComponent(props) {
  const {
    id,
    label,
    fieldApi: { setValue, setTouched, setError },
    forwardedRef,
    onChange,
    formApi,
    notify,
    filterKey,
    validator,
    ...rest
  } = props

  const items = get(props, 'fieldState.value', [])
  const [inputValue, setInputValue] = useState('')
  const tags = useMemo(
    () =>
      items.map((value, index) => ({
        id: index,
        value
      })),
    [items]
  )
  const error = useMemo(() => (validator ? validator(inputValue) : null), [inputValue, validator])

  const setValues = useCallback(
    nextItems => {
      if (!setTouched || !setValue) {
        return
      }

      const values = nextItems.map(item => Number(item.value))

      setValue(values)
      setTouched()

      if (onChange) {
        onChange(nextItems)
      }
    },
    [setValue, setTouched, onChange]
  )

  const onInputChange = useCallback(value => {
    setInputValue(value)
  }, [])

  const onAdd = useCallback(
    (nextItem, nextItems) => {
      if (error) {
        return
      }
      setValues(nextItems)
    },
    [setValues, error]
  )

  const onRemove = useCallback(
    nextItem => {
      const nextItems = tags.filter(item => item[filterKey] !== nextItem[filterKey])

      setValues(nextItems)
    },
    [setValues, tags, filterKey]
  )

  useEffect(() => {
    setError(error)
  }, [setError, error])

  return (
    <Field label={label} error={error}>
      <TagInput
        id={id}
        items={tags}
        value={inputValue}
        onChange={onInputChange}
        onAdd={onAdd}
        onRemove={onRemove}
        className={styles.tagInput}
        {...rest}
      />
    </Field>
  )
}

TagInputComponent.propTypes = {
  id: PropTypes.string.isRequired,
  fieldState: PropTypes.object.isRequired,
  fieldApi: PropTypes.object.isRequired,
  formApi: PropTypes.object.isRequired,
  validator: PropTypes.func.isRequired,
  filterKey: PropTypes.string,
  label: PropTypes.string,
  forwardedRef: PropTypes.object,
  disabled: PropTypes.bool,
  notify: PropTypes.array,
  onChange: PropTypes.func
}

TagInputComponent.defaultProps = {
  filterKey: 'id',
  label: 'value',
  disabled: false,
  forwardedRef: null,
  notify: null,
  onChange: () => {}
}

export const TagInputField = compose(
  asField,
  withFormContext
)(TagInputComponent)
