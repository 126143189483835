/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { GetContentErrorMessages } from './GetContentErrorMessages'
import { SEASONS_LABEL_CONFLICTS } from './types'

export const SeasonsCollectionIssues = props => {
  const { errors, classNameWrapper } = props
  const listClassNames = `message tile ${classNameWrapper}`

  return (
    <div className="level">
      <div className={listClassNames}>
        <div className="message-body">
          <p className="title is-size-5">{SEASONS_LABEL_CONFLICTS}</p>
          <ul>
            <GetContentErrorMessages errors={errors} />
          </ul>
        </div>
      </div>
    </div>
  )
}
SeasonsCollectionIssues.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.object),
  classNameWrapper: PropTypes.string
}
SeasonsCollectionIssues.defaultProps = {
  errors: [],
  classNameWrapper: 'is-danger'
}
