/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/reportsList'
import { withAuthContext } from 'helpers/withAuthContext'
import { withRouter } from 'react-router-dom'
import { ReportsList as ReportsListComponent } from './ReportsList'

const mapStateToProps = state => ({
  dataState: state.reportsList.dataState,
  data: state.reportsList.data,
  reportType: state.reportsList.reportType
})

const mapDispatchToProps = dispatch => {
  const { resetDataState, getReportAPPList, getReportPMCList } = actions
  return bindActionCreators(
    {
      onGetPMCReport: getReportPMCList,
      onGetAPPReport: getReportAPPList,
      resetDataState
    },
    dispatch
  )
}

export const ReportsList = compose(
  withAuthContext,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReportsListComponent)
