/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { entityInitialState } from './reducer'

function getProgramMarketingFormState(state) {
  return state.program.programMaterialsForm
}

function getProgramMarketingFormStateById(state, id) {
  const marketStates = getProgramMarketingFormState(state)
  return marketStates[id] || entityInitialState
}

function getProgramMarketingFormDataStatesById(state, id) {
  const marketingState = getProgramMarketingFormStateById(state, id)
  return [marketingState.dataState]
}

function getProgramMarketingFormIds(state) {
  return Object.keys(getProgramMarketingFormState(state))
}

function getCloseFormDataStates(state, id) {
  const marketState = getProgramMarketingFormStateById(state, id)
  return [marketState.dataState]
}

export default {
  getProgramMarketingFormState,
  getProgramMarketingFormStateById,
  getProgramMarketingFormDataStatesById,
  getProgramMarketingFormIds,
  getCloseFormDataStates
}
