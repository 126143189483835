/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const XPL_NAME_LABEL = 'Xplorie Name'
export const LEVEL_LABEL = 'Level'
export const DATA_TYPE_LABEL = 'Data Type'
export const MODE_LABEL = 'Mode'
export const PARAMETER_LABEL = 'Parameter Value'

export const MODE_PLACEHOLDER = 'Select mode'
export const PARAMETER_PLACEHOLDER = 'Select Parameter Value'

export const STRING_PARAMETER_PLACEHOLDER = 'Parameter Value'

export const EQUALS_MODE_LABEL = 'Equals'
export const NOT_EQUALS_MODE_LABEL = 'Not Equals'
export const MORE_OR_EQUALS_MODE_LABEL = 'More than or Equals to'
export const LESS_OR_EQUALS_MODE_LABEL = 'Less than or Equals to'
export const CONTAINS_MODE_LABEL = 'Contains'
export const NOT_CONTAINS_MODE_LABEL = 'Not Contains'
export const PERIOD_MODE_LABEL = 'Period'

export const INCLUDE_LABEL = 'Include'
export const EXCLUDE_LABEL = 'Exclude'

export const MODE_INVALID_MESSAGE = 'Mode contains some unacceptable intersections'
export const NUMBERS_INTERSECTION_MESSAGE = 'Numbers have intersections'
export const DATES_INTERSECTION_MESSAGE = 'Dates have intersections'

export const NEW_PARAMETER_PREFIX = 'new_eligibility_'
