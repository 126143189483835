/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function TrashIcon(props) {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="#C4C4C4"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 16.5283C1 17.6283 1.9 18.5283 3 18.5283H11C12.1 18.5283 13 17.6283 13 16.5283V6.52832C13 5.42832 12.1 4.52832 11 4.52832H3C1.9 4.52832 1 5.42832 1 6.52832V16.5283ZM13 1.52832H10.5L9.79 0.81832C9.61 0.63832 9.35 0.52832 9.09 0.52832H4.91C4.65 0.52832 4.39 0.63832 4.21 0.81832L3.5 1.52832H1C0.45 1.52832 0 1.97832 0 2.52832C0 3.07832 0.45 3.52832 1 3.52832H13C13.55 3.52832 14 3.07832 14 2.52832C14 1.97832 13.55 1.52832 13 1.52832Z" />
    </svg>
  )
}
