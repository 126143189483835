/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'

import { BasicButton } from '@xplorie/ui-commons'
import { SimpleModalWrapper } from 'components/SimpleModalWrapper/SimpleModalWrapper'

import { blankValidate } from 'helpers/formHelpers'
import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import {
  DEFAULT_PLACEHOLDER,
  DEFAULT_TITLE,
  DEFAULT_SUBMIT_BUTTON_TEXT,
  DEFAULT_CANCEL_BUTTON_TEXT
} from './types'

import styles from './SubmitWithComment.scss'

export class SubmitWithCommentModal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { value: '', error: null, isTouched: false }
  }

  onInputHandler = ev => {
    this.setState({ value: ev.target.value })
  }

  onChangeHandler = ev => {
    const error = blankValidate(ev.target.value)
    this.setState({ isTouched: true, error })
  }

  onAction = comment => () => {
    const { onAction } = this.props
    const { error } = this.state
    if (!error) {
      onAction(comment)
    }
  }

  render() {
    const { isOpen, onCancel, placeholder, title, submitButtonText, cancelButtonText } = this.props
    const { value, error, isTouched } = this.state
    return (
      isOpen && (
        <SimpleModalWrapper cancelHandler={onCancel}>
          <div className={`box ${styles.wrapper}`}>
            <div className={styles.labelWrapper}>
              <h1 className={styles.label}>{title}</h1>
            </div>
            <textarea
              className="textarea"
              placeholder={placeholder}
              value={value}
              onInput={this.onInputHandler}
              onChange={this.onChangeHandler}
            />
            <p className="help is-danger">{error}</p>
            <div className={styles.buttons}>
              <span className={styles.button}>
                <BasicButton
                  colorSchema={ORANGE_SCHEMA}
                  onClick={this.onAction(value)}
                  disabled={!isTouched || !!error}
                >
                  {submitButtonText}
                </BasicButton>
              </span>
              <span className={styles.button}>
                <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onCancel}>
                  {cancelButtonText}
                </BasicButton>
              </span>
            </div>
          </div>
        </SimpleModalWrapper>
      )
    )
  }
}

SubmitWithCommentModal.propTypes = {
  isOpen: PropTypes.bool,
  onAction: PropTypes.func,
  onCancel: PropTypes.func,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  submitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string
}
SubmitWithCommentModal.defaultProps = {
  isOpen: false,
  onAction: () => null,
  onCancel: () => null,
  placeholder: DEFAULT_PLACEHOLDER,
  title: DEFAULT_TITLE,
  submitButtonText: DEFAULT_SUBMIT_BUTTON_TEXT,
  cancelButtonText: DEFAULT_CANCEL_BUTTON_TEXT
}
