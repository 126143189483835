/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { TogglePopupButton } from '@xplorie/ui-commons'
import { DISABLED, ENABLED } from 'constants/smartHostStatus'

import { buildPopupOptions } from '../helpers'
import { POPUP_ENABLE_LABEL, TOGGLE_MODAL_EVENT_NAME } from '../types'

import { getLabelByStatus } from './helpers'
import styles from './styles'


export function EmailCadenceCell(props) {
  const { cellData, updateSmartHostByProgramId, dataId } = props
  const status = useMemo(() => 
    get(cellData, 'original.smartHost.emailCadence', false) ? ENABLED : DISABLED, [cellData])
  const label = useMemo(() => getLabelByStatus(status), [status])
  const unitId = useMemo(() => get(cellData, 'original.id', false), [cellData])
  const options = useMemo(() => buildPopupOptions(status), [status])
  const togglePopupProps = useMemo(
    () => ({
      options,
      disabled: status !== ENABLED,
      label
    }),
    [options, status, label]
  )

  const onSmartHostUpdate = useCallback(
    value => {
      const action = get(value, 'label');
      const isEnableAction = action === POPUP_ENABLE_LABEL;
      updateSmartHostByProgramId(dataId, {
        unitIds: [unitId],
        smartHost: { 
          emailCadence: isEnableAction,
        },
      })
    },
    [updateSmartHostByProgramId, dataId, unitId]
  )

  return (
    <TogglePopupButton
      {...togglePopupProps}
      className={styles.toggle}
      eventName={TOGGLE_MODAL_EVENT_NAME}
      onOptionClick={value => onSmartHostUpdate(value)}
    />
  )
}

EmailCadenceCell.propTypes = {
  dataId: PropTypes.string.isRequired,
  cellData: PropTypes.object.isRequired,
  updateSmartHostByProgramId: PropTypes.func.isRequired,
  programUnitsState: PropTypes.object.isRequired
}
