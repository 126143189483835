/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { getAccountFormState } from 'reducers/account/accountForm'

export const getProfileDatatState = state => state.profileData

export const getWatchersState = state => {
  const accountFormState = getAccountFormState(state)

  return [
    ...Object.keys(accountFormState).reduce((acc, key) => {
      const formState = accountFormState[key]
      return [...acc, formState.dataState]
    }, [])
  ]
}
