/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import helpers from './helpers'

const { ENQUEUE_MODAL, DEQUEUE_MODAL, RESET_ALL_MODALS } = helpers

export const initialState = {}

export const modalInitialState = {
  id: null,
  Component: null,
  props: {}
}

const buildState = (state, action) => data => ({
  ...state,
  [action.payload.key]: {
    ...modalInitialState,
    id: action.payload.key,
    ...state[action.payload.key],
    ...data
  }
})

const modalsReducer = (state = initialState, action) => {
  const appendData = buildState(state, action)

  switch (action.type) {
    case ENQUEUE_MODAL:
      return appendData({ Component: action.payload.Component, props: action.payload.props })
    case DEQUEUE_MODAL: {
      const { [action.payload.key]: removedState, ...restState } = state
      return {
        ...restState
      }
    }
    case RESET_ALL_MODALS:
      return {}
    default:
      return state
  }
}

export default filterActions(modalsReducer, [ENQUEUE_MODAL, DEQUEUE_MODAL, RESET_ALL_MODALS])
