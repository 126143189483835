/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper } from 'services/http/helpers'
import { ADDRESS_URL } from 'endpoints'

export const createAddress = (authKey, contractData) =>
  httpWrapper({
    url: ADDRESS_URL,
    method: 'post',
    data: contractData
  })

export const updateAddress = (authKey, contractData) =>
  httpWrapper({
    url: ADDRESS_URL,
    method: 'put',
    data: contractData
  })
