/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { BasicModal } from 'components/Modals'
import { BasicButton } from '@xplorie/ui-commons'
import { YELLOW_GREEN_SCHEMA, ORANGE_SCHEMA, YES_TITLE, NO_TITLE } from 'constants/buttonsOptions'

import {
  TITLE,
  WARNING_DEACTIVATION_MESSAGE_END,
  WARNING_DEACTIVATION_MESSAGE_START
} from '../types'

import styles from '../AppDeactivation.scss'

export function WarningModal(props) {
  const { isOpen, onClose, onSubmit, entityData } = props

  const apName = get(entityData, 'activityProvider.name')

  const modalBodyContent = () => (
    <section className={styles.wrapper}>
      <span>{WARNING_DEACTIVATION_MESSAGE_START}</span>
      <span className={styles.bold}>{apName}</span>
      <span>{WARNING_DEACTIVATION_MESSAGE_END}</span>
    </section>
  )

  return (
    <BasicModal isOpen={isOpen} title={TITLE} onClose={onClose} renderBody={modalBodyContent}>
      <BasicButton colorSchema={ORANGE_SCHEMA} onClick={onSubmit}>
        {YES_TITLE}
      </BasicButton>
      <BasicButton colorSchema={YELLOW_GREEN_SCHEMA} onClick={onClose}>
        {NO_TITLE}
      </BasicButton>
    </BasicModal>
  )
}

WarningModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  entityData: PropTypes.object.isRequired
}
