/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { TextField, FormContent, ControlButtonsContainer } from 'components/FormComponents'
import { DATA_STATE_RECIEVED, DATA_STATE_ERROR } from 'helpers/actionHelpers'
import { SubmitButton } from 'components/Buttons'
import { SAVE_TITLE, ORANGE_SCHEMA } from 'constants/buttonsOptions'
import {
  LABEL_OLD_PASSWORD,
  LABEL_NEW_PASSWORD,
  LABEL_CONFIRM_PASSWORD,
  PLACEHOLDER_OLD_PASSWORD,
  PLACEHOLDER_NEW_PASSWORD,
  PLACEHOLDER_CONFIRM_PASSWORD,
  TYPE_INPUT_PASSWORD,
  TYPE_OLD_PASSWORD,
  TYPE_NEW_PASSWORD,
  TYPE_CONFIRM_NEW_PASSWORD,
  ERROR_MESSAGE_OLD_PASSWORD_AND_NEW_PASSWORD,
  ERROR_MESSAGE_NEW_PASSWORD_AND_CONFIRM_NEW_PASSWORD
} from './types'
import { checkNewPassword, checkConfirmNewPassword } from './helpers'

export class ChangePasswordInnerContent extends React.PureComponent {
  componentDidMount() {
    const { formApi, formData } = this.props
    if (formData) {
      formApi.setValues(formData)
    }
  }

  componentDidUpdate(prevProps) {
    const { changePassDataState, formApi } = this.props

    if (
      (changePassDataState === DATA_STATE_RECIEVED || changePassDataState === DATA_STATE_ERROR) &&
      prevProps.changePassDataState !== changePassDataState
    ) {
      formApi.reset()
    }
  }

  render() {
    const { formEnabled } = this.props

    return (
      <FormContent disabled={!formEnabled}>
        <TextField
          id="old-pass"
          fieldName={TYPE_OLD_PASSWORD}
          label={LABEL_OLD_PASSWORD}
          placeholder={PLACEHOLDER_OLD_PASSWORD}
          type={TYPE_INPUT_PASSWORD}
          isRequired
        />
        <TextField
          id="new-pass"
          fieldName={TYPE_NEW_PASSWORD}
          label={LABEL_NEW_PASSWORD}
          placeholder={PLACEHOLDER_NEW_PASSWORD}
          type={TYPE_INPUT_PASSWORD}
          validate={checkNewPassword(
            TYPE_OLD_PASSWORD,
            ERROR_MESSAGE_OLD_PASSWORD_AND_NEW_PASSWORD
          )}
          notify={[TYPE_OLD_PASSWORD, TYPE_CONFIRM_NEW_PASSWORD]}
          isRequired
        />
        <TextField
          id="confirm-new-pass"
          fieldName={TYPE_CONFIRM_NEW_PASSWORD}
          label={LABEL_CONFIRM_PASSWORD}
          placeholder={PLACEHOLDER_CONFIRM_PASSWORD}
          type={TYPE_INPUT_PASSWORD}
          validate={checkConfirmNewPassword(
            TYPE_NEW_PASSWORD,
            ERROR_MESSAGE_NEW_PASSWORD_AND_CONFIRM_NEW_PASSWORD
          )}
          notify={[TYPE_NEW_PASSWORD]}
          isRequired
        />
        <ControlButtonsContainer>
          <SubmitButton id="chg-pass-btn" colorSchema={ORANGE_SCHEMA}>
            {SAVE_TITLE}
          </SubmitButton>
        </ControlButtonsContainer>
      </FormContent>
    )
  }
}
ChangePasswordInnerContent.defaultProps = {
  formData: null,
  formApi: null,
  formEnabled: true
}
ChangePasswordInnerContent.propTypes = {
  formApi: PropTypes.oneOfType([PropTypes.any]),
  formEnabled: PropTypes.bool,
  formData: PropTypes.oneOfType([PropTypes.any]),
  changePassDataState: PropTypes.string.isRequired
}
