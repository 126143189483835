/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const {
  NAME,
  GET_ENTITY_DATA,
  GET_ENTITY_ACTIVITIES,
  SAVE_ENTITY_ACTIVITIES,
  GET_ENTITY_ACTIVITIES_BY_LOCATION,
  CHANGE_STATUS,
  UPDATE_STATE_LIST_ACTIVITIES
} = types

const REQUEST_FORM = `${REQUEST_TYPE}_${NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${NAME}`

const REQUEST_GET_ENTITY_DATA = `${REQUEST_TYPE}_${GET_ENTITY_DATA}`
const RESPONSE_GET_ENTITY_DATA = `${RESPONSE_TYPE}_${GET_ENTITY_DATA}`
const RESPONSE_GET_ENTITY_DATA_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_ENTITY_DATA}`

const REQUEST_GET_ENTITY_ACTIVITIES = `${REQUEST_TYPE}_${GET_ENTITY_ACTIVITIES}`
const RESPONSE_GET_ENTITY_ACTIVITIES = `${RESPONSE_TYPE}_${GET_ENTITY_ACTIVITIES}`
const RESPONSE_GET_ENTITY_ACTIVITIES_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_ENTITY_ACTIVITIES}`

const REQUEST_SAVE_ENTITY_ACTIVITIES = `${REQUEST_TYPE}_${SAVE_ENTITY_ACTIVITIES}`
const RESPONSE_SAVE_ENTITY_ACTIVITIES = `${RESPONSE_TYPE}_${SAVE_ENTITY_ACTIVITIES}`
const RESPONSE_SAVE_ENTITY_ACTIVITIES_ERROR = `${RESPONSE_ERROR_TYPE}_${SAVE_ENTITY_ACTIVITIES}`

const REQUEST_GET_ENTITY_ACTIVITIES_BY_LOCATION = `${REQUEST_TYPE}_${GET_ENTITY_ACTIVITIES_BY_LOCATION}`
const RESPONSE_GET_ENTITY_ACTIVITIES_BY_LOCATION = `${RESPONSE_TYPE}_${GET_ENTITY_ACTIVITIES_BY_LOCATION}`
const RESPONSE_GET_ENTITY_ACTIVITIES_BY_LOCATION_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_ENTITY_ACTIVITIES_BY_LOCATION}`

const REQUEST_CHANGE_STATUS = `${REQUEST_TYPE}_${CHANGE_STATUS}`
const RESPONSE_CHANGE_STATUS = `${RESPONSE_TYPE}_${CHANGE_STATUS}`
const RESPONSE_CHANGE_STATUS_ERROR = `${RESPONSE_ERROR_TYPE}_${CHANGE_STATUS}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

export const buildKey = key => key || 'new'

export default {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA,
  RESPONSE_GET_ENTITY_DATA_ERROR,
  REQUEST_GET_ENTITY_ACTIVITIES,
  RESPONSE_GET_ENTITY_ACTIVITIES,
  RESPONSE_GET_ENTITY_ACTIVITIES_ERROR,
  REQUEST_SAVE_ENTITY_ACTIVITIES,
  RESPONSE_SAVE_ENTITY_ACTIVITIES,
  RESPONSE_SAVE_ENTITY_ACTIVITIES_ERROR,
  REQUEST_GET_ENTITY_ACTIVITIES_BY_LOCATION,
  RESPONSE_GET_ENTITY_ACTIVITIES_BY_LOCATION,
  RESPONSE_GET_ENTITY_ACTIVITIES_BY_LOCATION_ERROR,
  REQUEST_CHANGE_STATUS,
  RESPONSE_CHANGE_STATUS,
  RESPONSE_CHANGE_STATUS_ERROR,
  RESET_DATA_STATE,
  UPDATE_STATE_LIST_ACTIVITIES,
  buildKey
}
