/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  httpWrapper,
  buildURLForUnassignPhoneNumber,
  buildURLForAssignPhoneNumber
} from 'services/http/helpers'
import { PROGRAMS_URL } from 'endpoints'

// Assign unique phone number to program.
export const assignPhoneNumber = (programId, phoneId, contractData) =>
  httpWrapper({
    url: buildURLForAssignPhoneNumber(PROGRAMS_URL, programId, phoneId),
    method: 'put',
    data: contractData
  })

// Unassign unique phone number from program.
export const unassignPhoneNumber = (programId, params) =>
  httpWrapper({
    url: buildURLForUnassignPhoneNumber(PROGRAMS_URL, programId),
    method: 'delete',
    params
  })
