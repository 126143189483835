/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildListParams, buildURLForGettingExtendedList } from 'services/http/helpers'
import { PMCS_URL, PMC_EXPORT_MASTER_URL } from 'endpoints'

export const getListExtended = options =>
  httpWrapper({
    url: buildURLForGettingExtendedList(PMCS_URL),
    method: 'get',
    params: buildListParams(options)
  })

export const getReport = (authKey, options) =>
  httpWrapper({
    url: PMC_EXPORT_MASTER_URL,
    method: 'get',
    params: options
  })
