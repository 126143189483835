/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useCallback, useContext, useRef, useState } from 'react'
import compose from 'lodash/fp/compose'
import values from 'lodash/values'
import get from 'lodash/get'
import { DiscountContext } from '../helpers'

import {
  convertContractToTicketTypesFormValues,
  convertGroupTicketTypesToFormValues,
  convertTicketTypes,
  getFormTicketTypes,
  prepareDealTicketTypesPayload,
  mergeTicketTypeFromVales
} from './helpers'
import { TICKET_TYPES_FIELD } from './types'

export function useDealTicketTypesApi() {
  const { api } = useContext(DiscountContext)

  return api.dealTicketTypes
}

export function useDealTicketTypesState() {
  const { state } = useContext(DiscountContext)

  return state.ticketTypesFormState
}

export function useDealTicketTypesApiBuilder() {
  const ticketTypesApi = useRef()
  const [ticketTypesFormState, setTicketTypesFormState] = useState({})
  const [ticketTypeTitle, setTicketTypeTitle] = useState('')

  const getApi = useCallback(formApi => {
    ticketTypesApi.current = formApi
  }, [])

  const getTicketTypes = useCallback(() => {
    const ticketTypes = compose(
      convertTicketTypes,
      getFormTicketTypes
    )(ticketTypesFormState)

    return values(ticketTypes)
  }, [ticketTypesFormState])

  const getTicketTypesFormValues = useCallback(
    () => get(ticketTypesApi.current.getState(), 'values.ticketTypes'),
    []
  )

  const setTitle = useCallback(activityName => {
    setTicketTypeTitle(activityName)
  }, [])

  const setValues = useCallback(formValues => {
    if (!ticketTypesApi.current) {
      return
    }
    ticketTypesApi.current.setValues(convertContractToTicketTypesFormValues(formValues))
  }, [])

  const onChange = useCallback(formState => {
    setTicketTypesFormState(formState)
  }, [])

  const canSubmit = useCallback(() => getTicketTypes().every(item => item.finalPrice >= 0), [
    getTicketTypes
  ])

  const submit = useCallback(async () => {
    if (!ticketTypesApi.current) {
      return {}
    }

    await ticketTypesApi.current.submitForm()
    const formState = ticketTypesApi.current.getState()

    if (formState.invalid) {
      return null
    }

    return prepareDealTicketTypesPayload(formState.values)
  }, [])

  const mergeTicketGroupsToTicketTypes = useCallback(
    ticketGroups => {
      const mergedValues = mergeTicketTypeFromVales(
        getTicketTypesFormValues(),
        convertGroupTicketTypesToFormValues(ticketGroups)
      )

      ticketTypesApi.current.setValues({ [TICKET_TYPES_FIELD]: mergedValues })
    },
    [getTicketTypesFormValues]
  )

  return [
    ticketTypesFormState,
    {
      getApi,
      getTicketTypes,
      setTitle,
      setValues,
      setTicketTypesFormState,
      onChange,
      ticketTypeTitle,
      canSubmit,
      submit,
      mergeTicketGroupsToTicketTypes
    }
  ]
}
