/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { combineReducers } from 'redux'
import { reducer as notifReducer } from 'components/ReduxNotifications'

import editQueue from './editQueue'
import activityProviderList from './activityProviderList'
import activityProductList from './activityProductList'
import activityCategoryList from './activityCategoryList'
import activityProductForm from './activityProductForm'
import activityProviderForm from './activityProviderForm'
import activityCategoriesTree from './activityCategoriesTree'
import activityOptions from './activityOptions'
import campaigns from './campaigns'
import pmcsList from './pmcsList'
import pmcsForm from './pmcsForm'
import programsForm from './programsForm'
import programsList from './programsList'
import programApproval from './programApproval'
import programSeasonList from './programSeasonList'
import programsByAppList from './programsByAppList'
import addressForm from './addressForm'
import activityCategoryForm from './activityCategoryForm'
import seasonForm from './seasonForm'
import seasonActivityList from './seasonActivityList'
import pmsFormReducer from './pmsForm'
import roleListReducer from './roleList'
import myWorkReducer from './myWork'
import pmsList from './pmsList'
import currentUser from './currentUser'
import profileData from './profileData'
import reportsList from './reportsList'
import reportExport from './reportExport'
import relatedPrograms from './relatedPrograms'
import previousPrograms from './previousPrograms'
import locationsTree from './locationsTree'
import programLifeCycleProcess from './programLifeCycleProcess'
import locationTreeModal from './locationTreeModal'
import ibeConnection from './ibeConnection'
import accordionDictionary from './accordionDictionary'
import holidaysDictionary from './holidaysDictionary'
import accordionForm from './accordionForm'
import activityModuleForm from './activityModuleForm'
import activityModuleList from './activityModuleList'
import rmsDictionaryForm from './rmsDictionaryForm'
import rmsDictionaryList from './rmsDictionaryList'
import uniqPhoneNumForm from './uniqPhoneNumForm'
import uniqPhoneNumList from './uniqPhoneNumList'
import uniqFreePhoneNumList from './uniqFreePhoneNumList'
import statesListByCountry from './statesListByCountry'
import ticketGroupForm from './ticketGroupForm'
import ticketGroupsList from './ticketGroupsList'
import ticketTypesList from './ticketTypesList'
import ticketTypeForm from './ticketTypeForm'
import ticketSettingsForm from './ticketSettingsForm'
import account from './account'
import search from './search'
import activityResale from './activityResale'
import navigationReducer from './navigation'
import deals from './deals'
import program from './program'
import pmc from './pmc'
import app from './app'
import units from './units'
import activityProvider from './activityProvider'
import activityProduct from './activityProduct'
import eligibility from './eligibility'
import marketDictionary from './marketDictionary'
import location from './location'
import guestsData from './guestsData'
import rateAdjustments from './rateAdjustments'
import exportEntity from './exportEntity'
import checkTicketInventory from './checkTicketInventory'
import reduxModals from './reduxModals'
import resellTesting from './resellTesting'
import discountDeal from './discountDeal'
import closures from './closures'
import schedules from './schedules'
import unitsSlider from './unitsSlider'
import programExclusiveOffers from './programExclusiveOffers'
import communicationConnection from './communicationConnection'
import activityCategoryTags from './activityCategoryTags'
import activityTagsList from './activityTagsList'
import activityTagsForm from './activityTagsForm'
import unitTasks from './unitTasks'

const reducer = combineReducers({
  notifs: notifReducer,
  account,
  editQueue,
  activityProviderList,
  activityCategoryList,
  activityCategoriesTree,
  activityTagsList,
  activityTagsForm,
  activityProductList,
  activityProductForm,
  activityProviderForm,
  activityOptions,
  campaigns,
  checkTicketInventory,
  pmcsList,
  pmcsForm,
  programsForm,
  programApproval,
  seasonForm,
  seasonActivityList,
  programsList,
  programSeasonList,
  programsByAppList,
  addressForm,
  activityCategoryForm,
  roleList: roleListReducer,
  myWork: myWorkReducer,
  pmsForm: pmsFormReducer,
  pmsList,
  currentUser,
  profileData,
  reportsList,
  reportExport,
  relatedPrograms,
  previousPrograms,
  locationsTree,
  programLifeCycleProcess,
  locationTreeModal,
  ibeConnection,
  communicationConnection,
  accordionDictionary,
  holidaysDictionary,
  accordionForm,
  activityModuleForm,
  activityModuleList,
  rmsDictionaryForm,
  rmsDictionaryList,
  uniqPhoneNumForm,
  uniqPhoneNumList,
  uniqFreePhoneNumList,
  statesListByCountry,
  ticketGroupForm,
  ticketGroupsList,
  ticketTypesList,
  ticketTypeForm,
  ticketSettingsForm,
  navigation: navigationReducer,
  search,
  activityResale,
  deals,
  program,
  pmc,
  app,
  units,
  activityProvider,
  activityProduct,
  eligibility,
  marketDictionary,
  location,
  guestsData,
  rateAdjustments,
  exportEntity,
  reduxModals,
  resellTesting,
  discountDeal,
  closures,
  schedules,
  unitsSlider,
  programExclusiveOffers,
  activityCategoryTags,
  unitTasks,
  // Add any reducers whos values need to persist in localstorage to the persist store object below
  persist: {}
})

export default reducer
