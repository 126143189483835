/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { UpdateEntityButton, DeleteEntityButton, ViewEntityButton } from 'components/Buttons'
import ModalRemoval from 'modules/ModalRemoval'
import {
  DELETE_TITLE,
  EDIT_TITLE,
  VIEW_TITLE,
  SMALLER_SIZE,
  ORANGE_SCHEMA
} from 'constants/buttonsOptions'

export class EntityControlBox extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isOpenDeleteModal: false
    }
  }

  showDeleteModal = () => this.setState({ isOpenDeleteModal: true })

  cancelDeleteModal = () => this.setState({ isOpenDeleteModal: false })

  render() {
    const { canUpdate, onUpdate, onView, onDelete, canDelete, canView } = this.props
    const { isOpenDeleteModal } = this.state
    return (
      <>
        {canUpdate ? (
          <UpdateEntityButton onClick={onUpdate} size={SMALLER_SIZE} colorSchema={ORANGE_SCHEMA}>
            {EDIT_TITLE}
          </UpdateEntityButton>
        ) : (
          canView && (
            <ViewEntityButton onClick={onView} size={SMALLER_SIZE} colorSchema={ORANGE_SCHEMA}>
              {VIEW_TITLE}
            </ViewEntityButton>
          )
        )}
        {canDelete && (
          <DeleteEntityButton
            onClick={this.showDeleteModal}
            size={SMALLER_SIZE}
            colorSchema={ORANGE_SCHEMA}
          >
            {DELETE_TITLE}
          </DeleteEntityButton>
        )}
        <ModalRemoval
          originalData={isOpenDeleteModal}
          actionCallback={() => {
            onDelete()
            this.cancelDeleteModal()
          }}
          cancelCallback={this.cancelDeleteModal}
        />
      </>
    )
  }
}

EntityControlBox.propTypes = {
  canUpdate: PropTypes.bool,
  onUpdate: PropTypes.func,
  onView: PropTypes.func,
  onDelete: PropTypes.func,
  canDelete: PropTypes.bool,
  canView: PropTypes.bool
}
EntityControlBox.defaultProps = {
  canUpdate: false,
  onUpdate: () => null,
  onView: () => null,
  onDelete: () => null,
  canDelete: true,
  canView: true
}
