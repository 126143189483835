/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { PM_COMPANY } from 'constants/searchTypes'

export const onPMCSearchFetch = props => keyword => {
  const { searchPMCByKeyword } = props
  searchPMCByKeyword([], {
    type: PM_COMPANY,
    keyword
  })
}

export const onGetFullUniqPhoneList = props => {
  const { getUniqPhoneNumberList } = props

  getUniqPhoneNumberList({
    itemsPerPage: 0
  })

  return 'success onGetFullUniqPhoneList'
}

export const onGetFullFreeUniqPhoneList = (props, programType) => {
  const { getFreeUniqPhoneNumberList } = props

  if (!programType) {
    return 'failed onGetFullFreeUniqPhoneList'
  }

  getFreeUniqPhoneNumberList({
    programType
  })

  return 'success onGetFullFreeUniqPhoneList'
}
