/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import {
  changePassword as changePasswordRequest,
  resetPassword as resetPasswordRequest
} from 'services/http/account'

import {
  REQUEST_CHANGE_PASS_ACTION,
  RESPONSE_CHANGE_PASS_ACTION,
  RESPONSE_CHANGE_PASS_ACTION_ERROR,
  REQUEST_RESET_PASSWORD,
  RESPONSE_RESET_PASSWORD,
  RESPONSE_RESET_PASSWORD_ERROR,
  RESET_DATA_STATE
} from './helpers'

export const changePassword = (authUser, contractData = {}) => async dispatch => {
  const key = contractData && contractData.id
  dispatch(actionCreater(REQUEST_CHANGE_PASS_ACTION, { key }))
  const request = await changePasswordRequest(authUser, contractData).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_CHANGE_PASS_ACTION_ERROR, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_CHANGE_PASS_ACTION, { key }))
    dispatch(send(message))
    return 'changePassword success'
  }

  return 'changePassword failed'
}

const resetPassword = (authKey, accountId) => async dispatch => {
  const key = accountId
  dispatch(actionCreater(REQUEST_RESET_PASSWORD, { key }))

  const request = await resetPasswordRequest(authKey, accountId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_RESET_PASSWORD_ERROR, { key }))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(actionCreater(RESPONSE_RESET_PASSWORD, { key }))
    dispatch(send(message))
    return 'resetPassword success'
  }
  return 'resetPassword failed'
}

const resetDataState = () => dispatch => {
  dispatch(actionCreater(RESET_DATA_STATE))
}

export default {
  changePassword,
  resetPassword,
  resetDataState
}
