/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { exportDiscountsData } from 'services/http/deals'

import helpers from './helpers'

const { REQUEST_EXPORT, RESPONSE_EXPORT, RESPONSE_ERROR_EXPORT, RESET_DATA_STATE } = helpers

const exportDiscounts = (data, options, filename) => async dispatch => {
  dispatch(actionCreater(REQUEST_EXPORT))
  const request = await exportDiscountsData(data, { keyword: '', ...options }, filename).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_ERROR_EXPORT))
    }
  )
  if (request) {
    dispatch(actionCreater(RESPONSE_EXPORT))
    return 'exportDiscounts success'
  }

  return 'exportDiscounts failed'
}

const reset = () => dispatch => dispatch(actionCreater(RESET_DATA_STATE))

export default {
  exportDiscounts,
  reset
}
