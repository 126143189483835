/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import ticketGroupHelpers from 'connectors/reducers/ticketGroupsList/helpers'

import helpers from './helpers'

const { RESET_DATA_STATE, getUnassignedTicketTypes } = helpers
const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST } = ticketGroupHelpers

const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  data: []
}

const ticketTypesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LIST:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_LIST:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        data: getUnassignedTicketTypes(action.payload)
      }
    case RESPONSE_ERROR_LIST:
      return {
        ...state,
        dataState: DATA_STATE_ERROR
      }
    case RESET_DATA_STATE:
      return initialState
    default:
      return state
  }
}

export default filterActions(ticketTypesListReducer, [
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE
])
