import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'
import { ACTIVITY_PROVIDER } from 'helpers/searchHelpers'

import { LIST_NAME } from './types'

const REQUEST_LIST = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

const getRequestOptions = options => ({ type: ACTIVITY_PROVIDER, ...options })

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  getRequestOptions
}
