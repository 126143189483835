/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function CalendarIcon(props) {
  return (
    <svg viewBox="0 0 160 160" {...props}>
      <path d="M66.1,30.5L66.1,30.5h12.2c0,0,0,0,0.1,0s0.1-1.1,0.1-2.5V11.2c-0.2-3.3-2.9-6-6.2-6c0,0-0.1,0-0.1,0 c0,0-0.1,0-0.1,0c-3.4,0-6.2,2.8-6.2,6.2c0,0.4,0,0.9,0.1,1.3L66,28C66,29.4,66,30.5,66.1,30.5z" />
      <path d="M23.3,30.5h7.3c1.4,0,2.5-1.1,2.5-2.5V11.2c-0.2-3.3-2.9-6-6.2-6c-3.4,0-6.2,2.8-6.2,6.2c0,0.4,0,0.7,0.1,1V28 C20.8,29.4,21.9,30.5,23.3,30.5z" />
      <path d="M113.9,30.5h7.3c1.4,0,2.5-1.1,2.5-2.5V11.2h-0.1c-0.1-3.3-2.8-6-6.2-6c-3.4,0-6.2,2.8-6.2,6.2 c0,0.4,0,0.9,0.1,1.3l0,15.3C111.4,29.4,112.5,30.5,113.9,30.5z" />
      <path
        d="M132.4,18.8c-0.9,0-1.6,1.1-1.6,2.5v14.8c0,1.4-1.1,2.5-2.5,2.5h-21.5c-1.4,0-2.5-1.1-2.5-2.5V21.3
		c0-1.4-1.1-2.5-2.5-2.5H88c-1.4,0-2.5,1.1-2.5,2.5l-0.1,14.8c0,1.4-1.1,2.5-2.5,2.5H61.6c-1.4,0-2.5-1.1-2.5-2.5V21.3
		c0-1.4-1.1-2.5-2.5-2.5H42.7c-1.4,0-2.5,1.1-2.5,2.5v14.8c0,1.4-1.1,2.5-2.5,2.5H16.2c-1.4,0-2.5-1.1-2.5-2.5V21.3
		c0-1.4-0.6-2.5-1.4-2.5h-1.4c-3.6,0-6.4,2.9-6.4,6.4v107c0,3.6,2.9,6.4,6.4,6.4h45.4h32.4H134c3.6,0,6.4-2.9,6.4-6.4v-107
		c0-3.6-2.9-6.4-6.4-6.4C134,18.8,133.3,18.8,132.4,18.8z M123.6,125.6H21.3c-1.4,0-2.5-1.1-2.5-2.5V55.6c0-1.4,1.1-2.5,2.5-2.5
		h102.3c1.4,0,2.5,1.1,2.5,2.5v67.5C126.1,124.5,125,125.6,123.6,125.6z"
      />
      <path d="M118.1,71.2H26.2c-0.9,0-1.7-0.7-1.7-1.7l0,0c0-0.9,0.7-1.7,1.7-1.7h91.9c0.9,0,1.7,0.7,1.7,1.7l0,0	C119.8,70.4,119,71.2,118.1,71.2z" />
      <path d="M118.1,90.9H26.2c-0.9,0-1.7-0.7-1.7-1.7l0,0c0-0.9,0.7-1.7,1.7-1.7h91.9c0.9,0,1.7,0.7,1.7,1.7l0,0 C119.8,90.1,119,90.9,118.1,90.9z" />
      <path d="M118.1,109.2H26.2c-0.9,0-1.7-0.7-1.7-1.7l0,0c0-0.9,0.7-1.7,1.7-1.7h91.9c0.9,0,1.7,0.7,1.7,1.7l0,0 C119.8,108.4,119,109.2,118.1,109.2z" />
      <path d="M40.6,120.8L40.6,120.8c-0.8,0-1.4-0.6-1.4-1.4V59c0-0.8,0.6-1.4,1.4-1.4h0c0.8,0,1.4,0.6,1.4,1.4v60.4	C42,120.2,41.4,120.8,40.6,120.8z" />
      <path d="M61.8,120.8L61.8,120.8c-0.8,0-1.4-0.6-1.4-1.4V59c0-0.8,0.6-1.4,1.4-1.4h0c0.8,0,1.4,0.6,1.4,1.4v60.4	C63.2,120.2,62.6,120.8,61.8,120.8z" />
      <path d="M83.3,120.8L83.3,120.8c-0.8,0-1.4-0.6-1.4-1.4V59c0-0.8,0.6-1.4,1.4-1.4h0c0.8,0,1.4,0.6,1.4,1.4v60.4	C84.7,120.2,84.1,120.8,83.3,120.8z" />
      <path d="M104.5,120.8L104.5,120.8c-0.8,0-1.4-0.6-1.4-1.4V59c0-0.8,0.6-1.4,1.4-1.4l0,0c0.8,0,1.4,0.6,1.4,1.4v60.4	C105.9,120.2,105.3,120.8,104.5,120.8z" />
    </svg>
  )
}
