/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { FORM_NAME } from './types'

const REQUEST_FORM = `${REQUEST_TYPE}_${FORM_NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${FORM_NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${FORM_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

const SET_UPLOADING_PROGRESS = `SET_UPLOADING_PROGRESS_${FORM_NAME}`
const SET_CANCELLATION_TOKEN = `SET_CANCELLATION_TOKEN_${FORM_NAME}`

const SUCCESS_UPLOAD_MESSAGE = 'File has been uploaded successfully.'

export default {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  SET_UPLOADING_PROGRESS,
  SET_CANCELLATION_TOKEN,
  SUCCESS_UPLOAD_MESSAGE
}
