/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { combineReducers } from 'redux'

import appLifeCycle from './appLifeCycle'
import appsByMarket from './appsByMarket'
import appsByAp from './appsByAp'
import appsByActivityCategory from './appsByActivityCategory'
import appsByActivityTag from './appsByActivityTag'
import appsByDestination from './appsByDestination'

export default combineReducers({
  appLifeCycle,
  appsByMarket,
  appsByAp,
  appsByActivityCategory,
  appsByActivityTag,
  appsByDestination
})
