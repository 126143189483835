import {
  mapToCreateClosurePayload,
  mapToEditSeriesPayload,
  mapSeriesToFormValues,
  mapInitialValues,
  mapOccurrenceToFormValues,
  mapToEditOccurrencePayload,
  mapOccurrenceToValidationPayload
} from 'modules/Closures/mappers'
import { submitWithValidation } from '../helpers'
import { ScheduleWarningModal } from '../ScheduleWarningModal/ScheduleWarningModal'
import { OVERRIDE_SCHEDULE_MESSAGE } from './types'

export function addClosureStrategy({ activityId, createClosure, getIntersections, rootModal }) {
  const onSubmit = formValues => {
    const payload = mapToCreateClosurePayload(formValues)

    return submitWithValidation({
      payload,
      activityId,
      rootModal,
      onSubmit: createClosure,
      getIntersections,
      message: OVERRIDE_SCHEDULE_MESSAGE,
      warningModal: ScheduleWarningModal
    })
  }

  return {
    mapToFormValues: null,
    onSubmit,
    getData: null,
    mapToInitialValues: mapInitialValues
  }
}

export function editSeriesStrategy({
  activityId,
  id,
  updateClosureSeries,
  getClosureById,
  getIntersections,
  rootModal
}) {
  const onSubmit = formValues => {
    const validationPayload = mapToCreateClosurePayload(formValues)
    const updateSeries = () => {
      const payload = mapToEditSeriesPayload(formValues)
      updateClosureSeries(activityId, id, payload)
    }
    return submitWithValidation({
      payload: validationPayload,
      activityId,
      rootModal,
      onSubmit: updateSeries,
      getIntersections,
      message: OVERRIDE_SCHEDULE_MESSAGE,
      warningModal: ScheduleWarningModal
    })
  }

  const getData = () => getClosureById(activityId, id)

  return {
    mapToFormValues: mapSeriesToFormValues,
    onSubmit,
    getData,
    mapToInitialValues: mapInitialValues
  }
}

export function editOccurrenceStrategy({
  activityId,
  id,
  updateClosureOccurrence,
  getOccurrenceByIdAndDay,
  day,
  rootModal,
  getIntersections
}) {
  const onSubmit = formValues => {
    const validationPayload = mapOccurrenceToValidationPayload(formValues)
    const updateOccurrence = () => {
      const payload = mapToEditOccurrencePayload(formValues)
      updateClosureOccurrence(activityId, id, payload)
    }

    return submitWithValidation({
      payload: validationPayload,
      activityId,
      rootModal,
      onSubmit: updateOccurrence,
      getIntersections,
      message: OVERRIDE_SCHEDULE_MESSAGE,
      warningModal: ScheduleWarningModal
    })
  }

  const getData = () => getOccurrenceByIdAndDay(activityId, id, day)

  return {
    mapToFormValues: mapOccurrenceToFormValues,
    onSubmit,
    getData,
    mapToInitialValues: mapInitialValues
  }
}

export function ModalStrategy(props) {
  function getStrategy() {
    const { id, day } = props

    if (id && day) {
      return editOccurrenceStrategy(props)
    }

    if (id) {
      return editSeriesStrategy(props)
    }

    return addClosureStrategy(props)
  }

  return {
    getStrategy
  }
}
