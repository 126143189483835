/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'

import { withFormContext } from 'components/FormComponents/helpers'
import { dateValidate, withValidation, blankValidate, basicTextValidate } from 'helpers/formHelpers'

import { Calendar } from './CalendarInputs'
import { preValidateDateValue } from './helpers'

export function CalendarFieldComponent(props) {
  const {
    placeholder,
    fieldName,
    validate,
    isRequired,
    connectedValidator,
    disabled,
    formApi,
    preValidate,
    ...rest
  } = props

  const validateStrategy = isRequired
    ? withValidation(withValidation(blankValidate, validate), basicTextValidate)
    : withValidation(validate, basicTextValidate)

  return (
    <Calendar
      {...rest}
      isRequired={isRequired}
      field={fieldName}
      placeholder={placeholder}
      disabled={disabled}
      mask={preValidate}
      validate={validateStrategy}
      validateOnChange
    />
  )
}

CalendarFieldComponent.propTypes = {
  fieldProps: PropTypes.object,
  formApi: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  validate: PropTypes.func,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  preValidate: PropTypes.func,
  connectedValidator: PropTypes.any
}

CalendarFieldComponent.defaultProps = {
  fieldProps: {},
  placeholder: '',
  disabled: false,
  validate: dateValidate,
  isRequired: false,
  formApi: null,
  label: null,
  connectedValidator: null,
  preValidate: preValidateDateValue
}

export const CalendarField = withFormContext(CalendarFieldComponent)
