/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const NAME = 'TICKET_GROUP_INVENTORY'
export const UPLOAD_FILES = `${NAME}_UPLOAD_FILES`
export const UPLOAD_FILES_PROCCESS = `${NAME}_UPLOAD_FILES_PROCCESS`
export const FILES_BY_GROUP_ID = `${NAME}_FILES_BY_GROUP_ID`
export const DOWNLOAD_TICKET = `${NAME}_DOWNLOAD_TICKET`
export const REMOVE_INVENTORIES = `${NAME}_REMOVE_INVENTORIES`
export const REMOVE_INVENTORIE = `${NAME}_REMOVE_INVENTORIE`
export const REMOVE_INVENTORIE_FROM_STATE = `${NAME}_REMOVE_INVENTORIE_FROM_STATE`

export default {
  NAME,
  UPLOAD_FILES,
  UPLOAD_FILES_PROCCESS,
  FILES_BY_GROUP_ID,
  REMOVE_INVENTORIES,
  REMOVE_INVENTORIE,
  REMOVE_INVENTORIE_FROM_STATE
}
