/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const NAME = 'PREBOOK_INFO_PROGRAM_FORM'
const GET_PREBOOK_INFO = `${NAME}_GET_PREBOOK_INFO`
const CHARGE_METHOD_FIELD = 'chargeMethod'

export default {
  NAME,
  GET_PREBOOK_INFO,
  CHARGE_METHOD_FIELD
}
