/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import { Input } from '@xplorie/ui-commons'
import PropTypes from 'prop-types'

export function NumberInput(props) {
  const {
    fieldState: { value },
    fieldApi: { setValue, setTouched },
    errorClassName,
    placeholder,
    onChange,
    disabled
  } = props

  const onChangeHandler = useCallback(
    event => {
      const { value: newValue } = event.target
      setValue(newValue)
      setTouched(true)
      if (onChange) {
        onChange(newValue, event)
      }
    },
    [onChange, setTouched, setValue]
  )

  return (
    <Input
      value={value}
      onChange={onChangeHandler}
      className={errorClassName}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}
NumberInput.propTypes = {
  fieldState: PropTypes.object.isRequired,
  fieldApi: PropTypes.object.isRequired,
  errorClassName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
NumberInput.defaultProps = {
  onChange: null,
  disabled: false
}
