/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { saveGuestDataFile } from 'services/http/guestData'
import { calcProgressValue } from 'helpers/downloadHelper'
import helpers from './helpers'
import selectors from './selectors'

const {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  SET_UPLOADING_PROGRESS,
  SET_CANCELLATION_TOKEN,
  SUCCESS_UPLOAD_MESSAGE
} = helpers
const { getUploadCancellationTokenById } = selectors

const uploadGuestDataFile = (programId, contractData) => async dispatch => {
  const key = programId
  const onUploadProgress = event => {
    dispatch(actionCreater(SET_UPLOADING_PROGRESS, { key, progress: calcProgressValue(event) }))
  }
  const getCancelToken = cancelToken => {
    dispatch(actionCreater(SET_CANCELLATION_TOKEN, { key, cancelToken }))
  }
  dispatch(actionCreater(REQUEST_FORM, { key }))
  const request = await saveGuestDataFile(programId, contractData, {
    onUploadProgress,
    getCancelToken
  }).catch(({ send, message, statusCode }) => {
    if (statusCode) {
      dispatch(send(message))
    }
    dispatch(actionCreater(RESPONSE_FORM_ERROR, { key }))
  })

  if (!request) {
    return 'uploadGuestDataFile failed'
  }

  const {
    message: { message, send }
  } = request
  dispatch(actionCreater(RESPONSE_FORM, { key }))
  dispatch(send({ ...message, message: SUCCESS_UPLOAD_MESSAGE }))
  return 'uploadGuestDataFile success'
}

const cancelUploading = key => async (dispatch, getState) => {
  const cancelToken = getUploadCancellationTokenById(getState(), key)
  if (cancelToken) {
    cancelToken()
    dispatch(actionCreater(SET_CANCELLATION_TOKEN, { key, cancelToken: null }))
  }
}

const resetForm = key => async dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key }))

export default {
  uploadGuestDataFile,
  resetForm,
  cancelUploading
}
