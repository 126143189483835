/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { selectors } from 'reducers/guestsData/programGuestsDataForm'
import { entityInitialState } from './reducer'

const { getProgramGuestsDataFormDataStates } = selectors

function getProgramGuestsDataListState(state, id) {
  return state.guestsData.programGuestsDataList[id] || entityInitialState
}

function getProgramGuestsDataListWatchers(state, id) {
  return getProgramGuestsDataFormDataStates(state, id)
}

export default { getProgramGuestsDataListState, getProgramGuestsDataListWatchers }
