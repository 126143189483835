/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { BasicButton } from '@xplorie/ui-commons'
import { ORANGE_SCHEMA, SMALLER_SIZE } from 'constants/buttonsOptions'

import { BUTTON_TEXT } from './types'
import { getProgramsByAppHandler } from './helpers'

export function ProgramsByAPP(props) {
  return (
    <BasicButton
      size={SMALLER_SIZE}
      colorSchema={ORANGE_SCHEMA}
      onClick={getProgramsByAppHandler(props)}
    >
      {BUTTON_TEXT}
    </BasicButton>
  )
}

ProgramsByAPP.propTypes = {
  authUser: PropTypes.object.isRequired,
  setSearchType: PropTypes.func.isRequired,
  data: PropTypes.object
}

ProgramsByAPP.defaultProps = {
  data: null
}
