import { actionCreater } from 'helpers/actionHelpers'
import { getDiscountHistoryById, getDiscountHistoryYears } from 'services/http/discountHistory'

import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_GET_DATA,
  RESPONSE_GET_DATA,
  RESPONSE_GET_DATA_ERROR,
  REQUEST_GET_YEARS,
  RESPONSE_GET_YEARS,
  RESPONSE_GET_YEARS_ERROR
} = helpers

const getHistoryById = (id, year, params) => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_GET_DATA, { key, year }))
  const request = await getDiscountHistoryById(id, year, params).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_DATA_ERROR, { key, year }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_DATA, { key, year, data: request.data }))
    return 'getHistoryById success'
  }
  return 'getHistoryById failed'
}

const getHistoryYears = id => async dispatch => {
  const key = id
  dispatch(actionCreater(REQUEST_GET_YEARS, { key }))
  const request = await getDiscountHistoryYears(id).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_GET_YEARS_ERROR, { key }))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_GET_YEARS, { key, data: request.data }))
    return 'getHistoryYears success'
  }
  return 'getHistoryYears failed'
}

const resetHistory = key => dispatch => dispatch(actionCreater(RESET_DATA_STATE, { key }))

export default {
  getHistoryById,
  getHistoryYears,
  resetHistory
}
