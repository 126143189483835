/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { asField } from 'informed'
import { Field } from 'components/FormComponents'
import { DEFAULT_TIME_FORMAT } from 'constants/date'
import TimePicker from 'antd/es/time-picker'

function TimePickerFieldConnected(props) {
  const {
    fieldState: { error, value },
    format,
    fieldApi: { setValue, setTouched },
    fieldState,
    label,
    isRequired,
    fieldProps,
    children,
    onChange,
    id,
    use12Hours,
    ...rest
  } = props
  const timePickerValue = value ? moment(value, format) : null

  const onChangeHandler = (time, timeString) => {
    setValue(timeString)
    setTouched()
    if (onChange) {
      onChange(time, timeString)
    }
  }

  return (
    <Field id={id} label={label} isRequired={isRequired} error={error} {...fieldProps}>
      <TimePicker
        id={id}
        onChange={onChangeHandler}
        format={format}
        use12Hours={use12Hours}
        value={timePickerValue}
        {...rest}
      />
    </Field>
  )
}
TimePickerFieldConnected.propTypes = {
  fieldApi: PropTypes.object.isRequired,
  children: PropTypes.object,
  onChange: PropTypes.func,
  use12Hours: PropTypes.bool,
  format: PropTypes.string,
  fieldState: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  fieldProps: PropTypes.object,
  id: PropTypes.string
}

TimePickerFieldConnected.defaultProps = {
  onChange: () => null,
  children: null,
  use12Hours: true,
  format: DEFAULT_TIME_FORMAT,
  fieldState: {},
  label: '',
  isRequired: false,
  fieldProps: {},
  id: null
}

export default asField(TimePickerFieldConnected)
