/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CurtainWrapper } from 'components/Wrappers'

import { NavItemMenu } from './NavItemMenu'
import styles from './Nav.scss'

export function Switcher(props) {
  const { navMenuItem, subMenuItems, switcherTitle } = props
  const [isShownPanel, setIsShownPanelState] = useState(false)

  const onTogglePanel = ev => {
    setIsShownPanelState(!isShownPanel)
    if (ev) {
      ev.preventDefault()
    }
  }

  return (
    <div id="switcher-wrapper" className={classNames(styles.switcherWrapper)}>
      <NavItemMenu
        {...navMenuItem}
        activeClassName={classNames({
          [styles.activeItem]: isShownPanel
        })}
        className={classNames(styles.switcherWrapper)}
        onClick={onTogglePanel}
      />
      <div
        className={classNames(styles.switcherInnerContent, {
          [styles.hideSwitcher]: !isShownPanel
        })}
      >
        <div className={classNames(styles.switchTitle)}>{switcherTitle}</div>
        {subMenuItems.map(itemMenu => (
          <NavItemMenu key={itemMenu.id} {...itemMenu} />
        ))}
      </div>
      {isShownPanel && <CurtainWrapper onClick={onTogglePanel} />}
    </div>
  )
}

Switcher.propTypes = {
  navMenuItem: PropTypes.object.isRequired,
  subMenuItems: PropTypes.array.isRequired,
  switcherTitle: PropTypes.string.isRequired
}
