/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import uniqueId from 'lodash/uniqueId'
import { Form } from 'informed'
import { SmartHostFeeFormContext } from '../types'
import { convertFeeValue, convertFees, PREFIX } from '../helpers'

function withSmartHostFeeForm(Component) {
  function WithSmartHostFeeForm(props) {
    const { formData } = props
    const [fees, setFees] = useState([])
    const propsRef = useRef(props)

    useEffect(() => {
      propsRef.current = props
    }, [props])

    const feesWithId = useMemo(
      () =>
      fees.map(item => ({
          ...item,
          id: item.id || uniqueId(PREFIX),
          fee: convertFeeValue(item.fee)
        })),
      [fees]
    )

    const onSubmit = values => {
      const {
        dataId,
        saveSmartHostFee
      } = propsRef.current
      const nextFees = convertFees(get(values, 'fees', []))

      setFees(nextFees)
      saveSmartHostFee(dataId, {fees: nextFees })
    }

    return (
      <Form onSubmit={onSubmit} initialValues={feesWithId}>
        {({ formApi }) => (
          <SmartHostFeeFormContext.Provider
            value={{
              formApi,
              formData,
              fees: feesWithId,
              setFees
            }}
          >
            <Component {...props} />
          </SmartHostFeeFormContext.Provider>
        )}
      </Form>
    )
  }

  WithSmartHostFeeForm.propTypes = {
    formData: PropTypes.object.isRequired,
    saveSmartHostFee: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
    fees: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    dataId: PropTypes.number.isRequired
  }

  const componentName = Component.displayName || Component.name || 'Component'

  WithSmartHostFeeForm.displayName = `withFormContext(${componentName})`

  return WithSmartHostFeeForm
}

export default withSmartHostFeeForm
