/*
 * COPYRIGHT:     Copyright © 2019 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

export const E_TICKETS = 'E_TICKETS'
export const BARCODES = 'BARCODES'
export const WILL_CALL = 'WILL_CALL'

export const CODE_39 = 'CODE_39'
export const CODE_128 = 'CODE_128'
export const CODE_QR = 'CODE_QR'

export const NOT_REQUIRED = 'NOT_REQUIRED'
