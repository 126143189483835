/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'

import { getProgramByIdExtended } from 'services/http/program'
import { getListByProgramId } from 'services/http/programSeason'
import helpers from './helpers'

const {
  REQUEST_GET_CURRENT_PROGRAM,
  RESPONSE_GET_CURRENT_PROGRAM,
  RESPONSE_GET_CURRENT_PROGRAM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_RELATED_PROGRAM,
  RESPONSE_GET_RELATED_PROGRAM,
  RESPONSE_GET_RELATED_PROGRAM_ERROR,
  compareProgramAndSeasonData
} = helpers

const getCurrentProgramById = (id, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_CURRENT_PROGRAM))

  Promise.all([getProgramByIdExtended(id, params), getListByProgramId(id, { itemsPerPage: 0 })])
    .then(request => {
      dispatch(
        actionCreater(RESPONSE_GET_CURRENT_PROGRAM, {
          data: compareProgramAndSeasonData(request[0].data, request[1].data)
        })
      )
    })
    .catch(({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_GET_CURRENT_PROGRAM_ERROR))
    })
}

const getRelatedProgramById = (id, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_GET_RELATED_PROGRAM))

  Promise.all([getProgramByIdExtended(id, params), getListByProgramId(id, { itemsPerPage: 0 })])
    .then(request => {
      dispatch(
        actionCreater(RESPONSE_GET_RELATED_PROGRAM, {
          data: compareProgramAndSeasonData(request[0].data, request[1].data)
        })
      )
    })
    .catch(({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_GET_RELATED_PROGRAM_ERROR))
    })
}

const resetDataState = () => ({
  type: RESET_DATA_STATE
})

export default {
  resetDataState,
  getCurrentProgramById,
  getRelatedProgramById
}
