/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getReport as getPMCReport } from 'services/http/pmc'
import { getReport as getAPPReport } from 'services/http/activity'

import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  SET_REPORT_TYPE,
  proccessAPPReportList,
  RESET_DATA_STATE
} = helpers

const getReportPMCList = (authKey, options = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await getPMCReport(authKey, options).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_ERROR_LIST))
    }
  )
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { data: request.data }))
    return 'getReportPMCList success'
  }

  return 'getReportPMCList failed'
}

const getReportAPPList = (authKey, options = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await getAPPReport(authKey, options).catch(
    ({ send, message }) => {
      dispatch(send(message))
      dispatch(actionCreater(RESPONSE_ERROR_LIST))
    }
  )
  if (request) {
    dispatch(
      actionCreater(RESPONSE_LIST, {
        data: proccessAPPReportList(request.data)
      })
    )
    return 'getReportAPList success'
  }

  return 'getReportAPPList failed'
}

const setReportType = type => dispatch => {
  dispatch(actionCreater(SET_REPORT_TYPE, { reportType: type }))
}

const resetDataState = () => ({
  type: RESET_DATA_STATE
})
export default {
  getReportPMCList,
  getReportAPPList,
  setReportType,
  resetDataState
}
