/* eslint-disable max-lines */
/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import map from 'lodash/map'
import reduce from 'lodash/reduce'
import compose from 'lodash/fp/compose'
import fpMap from 'lodash/fp/map'
import fpFilter from 'lodash/fp/filter'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import isNil from 'lodash/isNil'
import isArray from 'lodash/isArray'
import fpValues from 'lodash/fp/values'
import fpEvery from 'lodash/fp/every'
import groupBy from 'lodash/groupBy'
import {
  EQUALS_MODE,
  NOT_EQUALS,
  MORE_OR_EQUALS,
  LESS_OR_EQUALS,
  CONTAINS,
  NOT_CONTAINS,
  PERIOD
} from 'constants/eligibility'
import { getVacationSpecialistFullName } from 'modules/Programs/ProgramHistory/ProgramHistoryItem/helpers'
import moment from 'moment'
import { DD_MM_YYYY_AT_TIME } from 'constants/date'
import {
  ACTIVITIES_LABEL,
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_UPDATE,
  ACTION_EXCLUDE,
  ACTION_INCLUDE,
  ADDED_LABEL,
  REMOVED_LABEL,
  EQUALS_MODE_LABEL,
  NOT_EQUALS_MODE_LABEL,
  MORE_OR_EQUALS_MODE_LABEL,
  LESS_OR_EQUALS_MODE_LABEL,
  CONTAINS_MODE_LABEL,
  NOT_CONTAINS_MODE_LABEL,
  PERIOD_MODE_LABEL,
  EXCLUSIVE_OFFER_ACTIVITY_LABEL,
  CHARGE_NIGHT_VALUE,
  CHARGE_NIGHT_VALUE_LABEL,
  CHARGE_STAY_VALUE,
  CHARGE_STAY_VALUE_LABEL,
  CHARGE_UNIT_VALUE,
  CHARGE_UNIT_VALUE_LABEL,
  SCHEDULED_LABEL,
  CANCELED_LABEL,
  ACTIVITY_NAME_LABEL
} from './types'
import {
  getHistoryFieldLabel,
  getSystemProgramHistoryFieldLabel,
  getSystemSeasonsHistoryFieldLabel,
  getUnitHistoryFieldLabel
} from '../helpers'
import {
  ELIGIBILITY_DETAILS_EVENT,
  PROGRAM_DETAILS_EVENT,
  PREBOOK_INFO_EVENT,
  CONFIRMATION_MODE_FIELD,
  CONFIRMATION_ID_VALUE,
  SEASON_DETAILS_EVENT,
  SEASON_RATE_EVENT,
  RATE_ADJUSTMENTS_TITLE,
  RATE_VALUE_FIELD,
  EXCLUSIVE_ACTIVITY_EVENT,
  CHARGE_METHOD_FIELD,
  NAME_FIELD,
  SEASON_NAME_FIELD,
  DELAYED_DEACTIVATION_EVENT,
  PMS_CHANGE_EVENT,
  PMS_CHANGE_LABEL,
  PROGRAM_DEACTIVATION_BY_SYSTEM_EVENT,
  SEASON_APP_EXCLUSION_BY_SYSTEM_EVENT,
  SMART_HOST_FEE_TITLE,
  SMART_HOST_MOBILE_FEE_TITLE,
  SMART_HOST_FEE_EVENT,
  SMART_HOST_MOBILE_FEE_EVENT,
  UNIT_ASSIGNMENT_EVENT,
  UNIT_SYSTEM_ASSIGNMENT_EVENT,
  UNIT_UPDATE_EVENT,
  UNIT_SYSTEM_UPDATE_EVENT,
  UNIT_STATUS_DATE_FIELD,
  UNIT_STATUS_NOTE_FIELD
} from '../types'

export const PROGRAM_VALUE_MAPPER = {
  [EQUALS_MODE]: EQUALS_MODE_LABEL,
  [NOT_EQUALS]: NOT_EQUALS_MODE_LABEL,
  [MORE_OR_EQUALS]: MORE_OR_EQUALS_MODE_LABEL,
  [LESS_OR_EQUALS]: LESS_OR_EQUALS_MODE_LABEL,
  [CONTAINS]: CONTAINS_MODE_LABEL,
  [NOT_CONTAINS]: NOT_CONTAINS_MODE_LABEL,
  [PERIOD]: PERIOD_MODE_LABEL
}

export function isEmptyValues(data) {
  return compose(
    fpEvery(isNil),
    fpValues
  )(data)
}

export function isCreateAction(action) {
  return action === ACTION_CREATE
}

export function isUpdateAction(action) {
  return action === ACTION_UPDATE
}

export function isDeleteAction(action) {
  return action === ACTION_DELETE
}

export function isExcludeAction(action) {
  return action === ACTION_EXCLUDE
}

export function isIncludeAction(action) {
  return action === ACTION_INCLUDE
}

export function convertSystemTimestampToDateTime(timestamp) {
  return moment(timestamp).format(DD_MM_YYYY_AT_TIME)
}

export function getChargeMethodLabel(fieldValue) {
  switch (fieldValue) {
    case CHARGE_NIGHT_VALUE:
      return CHARGE_NIGHT_VALUE_LABEL
    case CHARGE_UNIT_VALUE:
      return CHARGE_UNIT_VALUE_LABEL
    case CHARGE_STAY_VALUE:
    default:
      return CHARGE_STAY_VALUE_LABEL
  }
}

export function buildValueByFieldName(value, fieldName) {
  switch (fieldName) {
    case CHARGE_METHOD_FIELD:
      return getChargeMethodLabel(value)
    case RATE_VALUE_FIELD:
      return `$${value}`
    default:
      return value
  }
}

export function buildPrebookValue(data, field) {
  if (isNil(data)) {
    return 'empty'
  }
  if (!isObject(data)) {
    return buildValueByFieldName(data, field)
  }

  const { confirmationMode, confirmationIdValue } = data

  return `${PROGRAM_VALUE_MAPPER[confirmationMode]} ${confirmationIdValue}`
}

export function buildValue(value, field) {
  if (isArray(value)) {
    return value.join(', ')
  }

  return buildValueByFieldName(value, field)
}

export function convertHistoryDetails(collection) {
  return map(collection, item => {
    const { from, to, fieldName } = item

    const isAdded = isEmpty(from)
    const isRemoved = isEmpty(to)
    const isBold = isAdded || isRemoved

    const previousValue = isAdded ? ADDED_LABEL : buildValue(from, fieldName)
    const nextValue = isRemoved ? REMOVED_LABEL : buildValue(to, fieldName)

    return {
      fieldName: getHistoryFieldLabel(fieldName),
      previousValue,
      nextValue,
      isAdded,
      isRemoved,
      isBold
    }
  })
}
// eligibility history
export function getEligibilityValue(data) {
  if (isEmpty(data)) {
    return 'empty'
  }

  const { mode, value } = data
  const prefix = PROGRAM_VALUE_MAPPER[mode]

  return `${prefix} ${value}`
}

export function convertEligibilityDetails(collection) {
  return map(collection, item => {
    const { attributes, parameter, updated, action } = item
    const isAdded = isCreateAction(action)
    const isRemoved = isDeleteAction(action)
    const isBold = isAdded || isRemoved
    const from = attributes
    const to = isAdded ? attributes : updated

    const previousValue = isAdded ? ADDED_LABEL : getEligibilityValue(from)
    const nextValue = isRemoved ? REMOVED_LABEL : getEligibilityValue(to)
    const fieldName = parameter.label

    return {
      fieldName,
      previousValue,
      nextValue,
      isAdded,
      isRemoved,
      isBold
    }
  })
}

export function convertPrebookInfoDetails(collection) {
  return compose(
    fpMap(item => {
      const { from, to, fieldName } = item

      const isAdded = isNil(from) || (isObject(from) && isEmptyValues(from))
      const isRemoved = isNil(to) || (isObject(to) && isEmptyValues(to))
      const isBold = isAdded || isRemoved

      const previousValue = isAdded ? ADDED_LABEL : buildPrebookValue(from, fieldName)
      const nextValue = isRemoved ? REMOVED_LABEL : buildPrebookValue(to, fieldName)

      return {
        fieldName: getHistoryFieldLabel(fieldName),
        previousValue,
        nextValue,
        isAdded,
        isRemoved,
        isBold
      }
    }),
    fpFilter(item => ![CONFIRMATION_MODE_FIELD, CONFIRMATION_ID_VALUE].includes(item.fieldName))
  )(collection)
}
// Season history
export function convertActivitiesHistory(collection) {
  const collectionByAction = groupBy(collection, 'action')

  return map(collectionByAction, (values, action) => {
    const isAdded = isCreateAction(action)
    const isRemoved = isDeleteAction(action)
    const isBold = isAdded || isRemoved
    const value = values.map(({ name }) => name).join(', ')

    const previousValue = isAdded ? ADDED_LABEL : value
    const nextValue = isRemoved ? REMOVED_LABEL : value

    return {
      fieldName: ACTIVITIES_LABEL,
      previousValue,
      nextValue,
      isAdded,
      isRemoved,
      isBold
    }
  })
}

export function convertFieldsMap(collection) {
  return map(collection, (value, field) => ({
    fieldName: getHistoryFieldLabel(field),
    nextValue: buildValueByFieldName(value, field),
    isBold: true
  }))
}

export function buildSeasonRateValue(item) {
  if (!item) {
    return 'None'
  }

  const { endDate, startDate, value } = item
  return `$${value}\n${startDate} - ${endDate}`
}

export function convertRateAdjustment({ action, updated, attributes }) {
  const isAdded = isCreateAction(action)
  const isRemoved = isDeleteAction(action)

  const previousValue = isAdded ? ADDED_LABEL : buildSeasonRateValue(attributes)
  const nextValue = isRemoved ? REMOVED_LABEL : buildSeasonRateValue(updated || attributes)

  return [
    {
      fieldName: RATE_ADJUSTMENTS_TITLE,
      previousValue,
      nextValue,
      isAdded,
      isRemoved,
      isBold: true
    }
  ]
}

export function convertSeasonsDetails(collection) {
  if (isArray(collection)) {
    const [item] = collection
    return convertRateAdjustment(item)
  }

  const { activities, differences, name, ...fieldsMap } = collection

  const normalizedFieldsMap = name
    ? {
        ...fieldsMap,
        [SEASON_NAME_FIELD]: name
      }
    : fieldsMap

  const normalizedDifferences =
    differences &&
    differences.map(el => {
      if (el.fieldName === NAME_FIELD) {
        return { ...el, fieldName: SEASON_NAME_FIELD }
      }
      return el
    })

  return [
    ...convertFieldsMap(normalizedFieldsMap),
    ...convertHistoryDetails(normalizedDifferences),
    ...convertActivitiesHistory(activities)
  ]
}

export const mapResponseToUnitHistory = collection =>
  collection
    .map(item => {
      const { differences, unit } = item
      const { id, name } = unit

      return differences.map(el => ({
        ...el,
        unitName: name,
        id: `${el.fieldName}_${id}`
      }))
    })
    .flat()
    .sort((a, b) => {
      if (
        a.unitName === b.unitName &&
        b.fieldName === UNIT_STATUS_DATE_FIELD &&
        a.fieldName === UNIT_STATUS_NOTE_FIELD
      ) {
        return -1
      }
      return 0
    })

export function convertUnitHistory(collection) {
  const mappedCollection = mapResponseToUnitHistory(collection)

  return map(mappedCollection, item => {
    const { from, to, unitName, fieldName, id } = item

    const isAdded = isEmpty(from)
    const isRemoved = isEmpty(to)
    const isBold = isAdded || isRemoved

    const previousValue = isAdded ? ADDED_LABEL : buildValue(from, fieldName)
    const nextValue = isRemoved ? REMOVED_LABEL : buildValue(to, fieldName)

    return {
      id,
      unitName,
      fieldName: getUnitHistoryFieldLabel(fieldName),
      previousValue,
      nextValue,
      isAdded,
      isRemoved,
      isBold
    }
  })
}

export function convertSeasonRate(collection) {
  return reduce(
    collection,
    (acc, value) => {
      const { data } = value
      return [
        ...acc,
        ...map(data, (item, index) => {
          const isAdded = isCreateAction(item.action)
          const isRemoved = isDeleteAction(item.action)

          const previousValue = isAdded ? ADDED_LABEL : buildSeasonRateValue(item.attributes)
          const nextValue = isRemoved
            ? REMOVED_LABEL
            : buildSeasonRateValue(item.updated || item.attributes)

          return {
            id: `rate_history_${index}`,
            fieldName: RATE_ADJUSTMENTS_TITLE,
            previousValue,
            nextValue,
            isAdded,
            isRemoved,
            isBold: true
          }
        })
      ]
    },
    []
  )
}

export const convertSmartHostFeeHistory = event => collection => {
  function buildSmartHostFeeValue(item) {
    if (!item) {
      return 'None'
    }

    const {
      period: { endDate, startDate },
      fee
    } = item
    return `$${fee}\n${startDate} - ${endDate}`
  }

  return reduce(
    collection,
    (acc, item, index) => {
      const isAdded = isCreateAction(item.action)
      const isRemoved = isDeleteAction(item.action)

      const previousValue = isAdded ? ADDED_LABEL : buildSmartHostFeeValue(item.value)
      const nextValue = isRemoved
        ? REMOVED_LABEL
        : buildSmartHostFeeValue(item.updated || item.value)

      return [
        ...acc,
        {
          id: `smart_host_fee_history_${index}`,
          fieldName:
            event === SMART_HOST_FEE_EVENT ? SMART_HOST_FEE_TITLE : SMART_HOST_MOBILE_FEE_TITLE,
          previousValue,
          nextValue,
          isAdded,
          isRemoved,
          isBold: true
        }
      ]
    },
    []
  )
}

export function convertExclusiveActivityDetails(collection) {
  return map(collection, item => {
    const { action, name } = item

    const isAdded = isIncludeAction(action)
    const isRemoved = isExcludeAction(action)
    const isBold = isAdded || isRemoved

    const previousValue = isAdded ? ADDED_LABEL : name
    const nextValue = isRemoved ? REMOVED_LABEL : name

    return {
      fieldName: EXCLUSIVE_OFFER_ACTIVITY_LABEL,
      previousValue,
      nextValue,
      isAdded,
      isRemoved,
      isBold
    }
  })
}

export function convertDelayedDeactivationHistory(collection) {
  const { action, data } = collection
  const { from, to, fieldName } = data
  const isAdded = isCreateAction(action)
  const isRemoved = isDeleteAction(action)
  const isBold = isAdded || isRemoved

  const previousValue = isAdded ? SCHEDULED_LABEL : buildValue(from, fieldName)
  const nextValue = isRemoved ? CANCELED_LABEL : buildValue(to, fieldName)

  return [
    {
      fieldName: getHistoryFieldLabel(fieldName),
      previousValue,
      nextValue,
      isAdded,
      isRemoved,
      isBold
    }
  ]
}

export function convertPmsChangeHistory(collection) {
  const { from, to, fieldName } = collection.data

  const previousValue = buildValue(from, fieldName)
  const nextValue = buildValue(to, fieldName)

  return [
    {
      fieldName: PMS_CHANGE_LABEL,
      previousValue,
      nextValue
    }
  ]
}

export function convertSeasonAppExclusionBySystemHistory([{ activities }]) {
  return map(activities, item => {
    const { id, action, name } = item

    const isAdded = isIncludeAction(action)
    const isRemoved = isExcludeAction(action)
    const isBold = isAdded || isRemoved

    const previousValue = isAdded ? ADDED_LABEL : name
    const nextValue = isRemoved ? REMOVED_LABEL : name

    return {
      id,
      fieldName: ACTIVITY_NAME_LABEL,
      previousValue,
      nextValue,
      isAdded,
      isRemoved,
      isBold
    }
  })
}

export function convertSystemProgramHistory({ initiator, reason }) {
  if (!initiator) {
    return null
  }

  const { vacationSpecialist, timestamp } = initiator
  const { event, entity } = reason
  const { name } = entity

  return {
    name,
    reason: getSystemProgramHistoryFieldLabel(event),
    performedBy: getVacationSpecialistFullName(vacationSpecialist),
    dateTime: convertSystemTimestampToDateTime(timestamp)
  }
}

export function convertSystemSeasonsHistory([{ initiator, reason }]) {
  if (!initiator) {
    return null
  }

  const { vacationSpecialist, timestamp } = initiator
  const { event, entity } = reason
  const { name } = entity

  return {
    name,
    reason: getSystemSeasonsHistoryFieldLabel(event),
    performedBy: getVacationSpecialistFullName(vacationSpecialist),
    dateTime: convertSystemTimestampToDateTime(timestamp)
  }
}

export function getDetailsConverter(event) {
  switch (event) {
    case ELIGIBILITY_DETAILS_EVENT:
      return convertEligibilityDetails
    case UNIT_ASSIGNMENT_EVENT:
    case UNIT_UPDATE_EVENT:
    case UNIT_SYSTEM_ASSIGNMENT_EVENT:
    case UNIT_SYSTEM_UPDATE_EVENT:
      return convertUnitHistory
    case PREBOOK_INFO_EVENT:
      return convertPrebookInfoDetails
    case SEASON_DETAILS_EVENT:
      return convertSeasonsDetails
    case SEASON_RATE_EVENT:
      return convertSeasonRate
    case EXCLUSIVE_ACTIVITY_EVENT:
      return convertExclusiveActivityDetails
    case DELAYED_DEACTIVATION_EVENT:
      return convertDelayedDeactivationHistory
    case PMS_CHANGE_EVENT:
      return convertPmsChangeHistory
    case SEASON_APP_EXCLUSION_BY_SYSTEM_EVENT:
      return convertSeasonAppExclusionBySystemHistory
    case SMART_HOST_FEE_EVENT:
    case SMART_HOST_MOBILE_FEE_EVENT:
      return convertSmartHostFeeHistory(event)
    case PROGRAM_DETAILS_EVENT:
    default:
      return convertHistoryDetails
  }
}

export function getSystemDetailsConverter(event) {
  switch (event) {
    case PROGRAM_DEACTIVATION_BY_SYSTEM_EVENT:
      return convertSystemProgramHistory
    case SEASON_APP_EXCLUSION_BY_SYSTEM_EVENT:
      return convertSystemSeasonsHistory
    default:
      return null
  }
}

export const isUnitTypeEvent = event =>
  event === UNIT_ASSIGNMENT_EVENT ||
  event === UNIT_SYSTEM_ASSIGNMENT_EVENT ||
  event === UNIT_UPDATE_EVENT ||
  event === UNIT_SYSTEM_UPDATE_EVENT
