/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { FORM_NAME, GET_SEASON_BY_ID, CLONE_SEASON } from './types'

const REQUEST_FORM = `${REQUEST_TYPE}_${FORM_NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${FORM_NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${FORM_NAME}`

const REQUEST_GET_SEASON_BY_ID = `${REQUEST_TYPE}_${GET_SEASON_BY_ID}`
const RESPONSE_GET_SEASON_BY_ID = `${RESPONSE_TYPE}_${GET_SEASON_BY_ID}`
const RESPONSE_GET_SEASON_BY_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_SEASON_BY_ID}`

const REQUEST_CLONE_SEASON = `${REQUEST_TYPE}_${CLONE_SEASON}`
const RESPONSE_CLONE_SEASON = `${RESPONSE_TYPE}_${CLONE_SEASON}`
const RESPONSE_CLONE_SEASON_ERROR = `${RESPONSE_ERROR_TYPE}_${CLONE_SEASON}`

const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

export default {
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  REQUEST_GET_SEASON_BY_ID,
  RESPONSE_GET_SEASON_BY_ID,
  RESPONSE_GET_SEASON_BY_ID_ERROR,
  REQUEST_CLONE_SEASON,
  RESPONSE_CLONE_SEASON,
  RESPONSE_CLONE_SEASON_ERROR,
  RESET_DATA_STATE
}
