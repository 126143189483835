/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { usePrevious } from 'hooks'
import classNames from 'classnames'

import { ROUND_TYPE, SQUARE_TYPE } from './types'

import styles from './style.scss'

export function CheckBoxField(props) {
  const {
    id,
    disabled,
    fieldApi,
    fieldState: { value },
    fieldApi: { setValue, setTouched },
    forwardedRef,
    onChange,
    styleType,
    formApi,
    notify,
    ...rest
  } = props
  const prevValue = usePrevious(value)

  const onChangeHandler = ev => {
    if (!disabled) {
      if (setTouched && setValue) {
        const newValue = !value
        setValue(newValue)
        setTouched()
        if (onChange) {
          onChange(newValue, ev)
        }
      }
    }
  }
  useEffect(() => {
    if (prevValue !== value) {
      if (notify) {
        formApi.notify(notify)
      }
    }
  }, [prevValue, formApi, value, notify])

  return (
    <>
      <label
        htmlFor={id}
        className={classNames(
          styles.labelDefault,
          { [styles.labelRoundType]: styleType === ROUND_TYPE },
          { [styles.inputSquareType]: styleType === SQUARE_TYPE },
          { [styles.disabled]: disabled },
          { [styles.active]: Boolean(value) }
        )}
      />
      <input
        id={id}
        value={Boolean(value)}
        checked={Boolean(value)}
        type="checkbox"
        className={styles.input}
        onChange={onChangeHandler}
        disabled={disabled}
        {...rest}
      />
    </>
  )
}

CheckBoxField.propTypes = {
  id: PropTypes.string.isRequired,
  fieldState: PropTypes.object.isRequired,
  fieldApi: PropTypes.object.isRequired,
  formApi: PropTypes.object.isRequired,
  styleType: PropTypes.oneOf([ROUND_TYPE, SQUARE_TYPE]),
  forwardedRef: PropTypes.object,
  disabled: PropTypes.bool,
  notify: PropTypes.array,
  onChange: PropTypes.func
}

CheckBoxField.defaultProps = {
  styleType: ROUND_TYPE,
  disabled: false,
  forwardedRef: null,
  notify: null,
  onChange: () => {}
}
