/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import types from './types'

const enqueue = (
  id = +new Date(),
  component,
  props = {},
  headerProps = {
    title: null,
    subtitle: null,
    info: null
  },
  type
) => ({
  type: types.ENQUEUE,
  payload: {
    id,
    component,
    props,
    headerProps,
    type
  }
})

const dequeue = panelId => dispatch => {
  dispatch(actionCreater(types.DEQUEUE, panelId))
}

const updateSlide = (id, data) => dispatch => {
  dispatch(actionCreater(types.UPDATE_SLIDE, { id, data }))
}

const resetAllSlides = () => ({
  type: types.CLEAR_QUEUE
})

export default {
  enqueue,
  dequeue,
  updateSlide,
  resetAllSlides
}
