/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { LIST_NAME, RE_ORDER_LIST, RE_ORDER_TICKET_TYPES } = types

const REQUEST_LIST = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`

const REQUEST_RE_ORDER_LIST = `${REQUEST_TYPE}_${RE_ORDER_LIST}`
const RESPONSE_RE_ORDER_LIST = `${RESPONSE_TYPE}_${RE_ORDER_LIST}`
const RESPONSE_ERROR_RE_ORDER_LIST = `${RESPONSE_ERROR_TYPE}_${RE_ORDER_LIST}`

const REQUEST_RE_ORDER_TICKET_TYPES = `${REQUEST_TYPE}_${RE_ORDER_TICKET_TYPES}`
const RESPONSE_RE_ORDER_TICKET_TYPES = `${RESPONSE_TYPE}_${RE_ORDER_TICKET_TYPES}`
const RESPONSE_ERROR_RE_ORDER_TICKET_TYPES = `${RESPONSE_ERROR_TYPE}_${RE_ORDER_TICKET_TYPES}`

const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_RE_ORDER_LIST,
  RESPONSE_RE_ORDER_LIST,
  RESPONSE_ERROR_RE_ORDER_LIST,
  REQUEST_RE_ORDER_TICKET_TYPES,
  RESPONSE_RE_ORDER_TICKET_TYPES,
  RESPONSE_ERROR_RE_ORDER_TICKET_TYPES
}
