/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const FORM_NAME = 'ACCOUNT_FORM'
export const ACCOUNT_PROP_NAME = 'ACCOUNT_PROP_NAME'
export const ROLES_PROP_NAME = 'ROLES_PROP_NAME'
export const RESET_PASSWORD = 'RESET_PASSWORD'

export default {
  FORM_NAME,
  ACCOUNT_PROP_NAME,
  ROLES_PROP_NAME,
  RESET_PASSWORD
}
