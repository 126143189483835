/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  NOT_ACCEPTABLE_FILE_MESSAGE,
  TOO_LARGE_FILE_MESSAGE,
  FILE_FORMAT_REGEX,
  MAX_FILE_SIZE
} from './types/uploadHelperTypes'

export function validate(files) {
  const { some } = Array.prototype
  if (some.call(files, file => !FILE_FORMAT_REGEX.test(file.name))) {
    return NOT_ACCEPTABLE_FILE_MESSAGE
  }
  if (some.call(files, file => file > MAX_FILE_SIZE)) {
    return TOO_LARGE_FILE_MESSAGE
  }
  return null
}
