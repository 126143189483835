/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { ButtonsWrapper } from 'components/Wrappers'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { NavTabs } from 'modules/Library'

import styles from './DefaultTemplate.scss'

export function DefaultTemplate({ children, Actions, ...rest }) {
  return (
    <>
      <ButtonsWrapper>
        <Column className={styles.tabsWrapper}>
          <NavTabs {...rest} />
        </Column>
        {Actions && <Column>{Actions}</Column>}
      </ButtonsWrapper>
      {children}
    </>
  )
}
DefaultTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  Actions: PropTypes.node
}
DefaultTemplate.defaultProps = {
  Actions: null
}
