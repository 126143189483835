/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import get from 'lodash/get'
import {
  UNAUTHORIZED_DEFAULT_REDIRECT_URL,
  BACK_URL_QUERY_PARAM,
  DEFAULT_HOME_REDIRECT_URL
} from './types'

export const buildBackUrl = (location = {}) => {
  const { pathname = '', search = '' } = location
  return pathname && pathname !== UNAUTHORIZED_DEFAULT_REDIRECT_URL
    ? `${UNAUTHORIZED_DEFAULT_REDIRECT_URL}?${BACK_URL_QUERY_PARAM}=${pathname}${search}`
    : UNAUTHORIZED_DEFAULT_REDIRECT_URL
}

export const logInRedirect = location => {
  const search = get(location, 'search')
  return !search ? DEFAULT_HOME_REDIRECT_URL : search.split('backUrl=')[1]
}
