/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { getAccountById } from 'services/http/account'

import {
  REQUEST_PROFILE_ACTION,
  RESPONSE_PROFILE_ACTION,
  RESPONSE_PROFILE_ACTION_ERROR
} from './helpers'

export const getProfile = (authUser, params) => async dispatch => {
  dispatch(actionCreater(REQUEST_PROFILE_ACTION))
  const request = await getAccountById(authUser, params).catch(() => {
    dispatch(actionCreater(RESPONSE_PROFILE_ACTION_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_PROFILE_ACTION, { profileData: request.data }))
    return 'getProfile success'
  }

  return 'getProfile failed'
}

export default {
  getProfile
}
