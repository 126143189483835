/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { buildKey } from 'reducers/deals/dealCommon/helpers'
import { formInitialState } from './reducer'

export const getDiscountDealTicketTypesFormStates = state =>
  state.discountDeal.discountDealTicketTypesForm

export const getDiscountDealTicketTypesFormStateById = (state, key) =>
  state.discountDeal.discountDealTicketTypesForm[buildKey(key)] || formInitialState
