/* eslint-disable react/jsx-filename-extension */
/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { programsTableColumns, buildDefaultButtonColumn } from 'helpers/dataTablesHelpers'
import { EditButton } from './AdditionalColumns'

export const buildTableColumns = (props, baseColumns = programsTableColumns) => {
  const tableColumns = baseColumns.concat({
    ...buildDefaultButtonColumn({ width: 80 }),
    Cell: data => <EditButton {...props} data={data} />
  })

  return tableColumns
}
