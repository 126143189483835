/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { DownloadCloudIcon, WrongDocumentIcon } from 'icons'
import { isSuccessUploaded } from '../helpers'
import { DOWNLOAD_FILE_TEXT, DOWNLOAD_ERROR_REPORT_TEXT } from '../types'

import styles from '../GuestsList.scss'

export function DownloadCell({ status, fileId, onClick }) {
  const isSuccess = useMemo(() => isSuccessUploaded(status), [status])

  const onClickHandler = useCallback(() => {
    onClick(fileId)
  }, [fileId, onClick])

  return (
    <div className={styles.downloadWrapper} onClick={onClickHandler}>
      {isSuccess ? (
        <span>
          <DownloadCloudIcon className={styles.downloadIcon} />
          {DOWNLOAD_FILE_TEXT}
        </span>
      ) : (
        <span>
          <WrongDocumentIcon className={styles.wrongDocument} />
          {DOWNLOAD_ERROR_REPORT_TEXT}
        </span>
      )}
    </div>
  )
}
DownloadCell.propTypes = {
  status: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  fileId: PropTypes.string.isRequired
}
DownloadCell.defaultProps = {
  status: null
}
