/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { getList } from 'services/http/eligibility'
import { actionCreater } from 'helpers/actionHelpers'

import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST, RESET_DATA_STATE } = helpers

const getEligibilityList = (options = {}) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await getList(options).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, request.data))
    return 'getEligibilityList success'
  }

  return 'getEligibilityList failed'
}
const resetList = () => ({
  type: RESET_DATA_STATE
})

export default {
  getEligibilityList,
  resetList
}
