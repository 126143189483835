/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'
import { BasicButton, SimpleBox, Tag } from '@xplorie/ui-commons'
import { BoxLabel } from 'components/Basic/Labels'
import { canShift } from 'helpers/dragAndDropHelpers'
import { EDIT_TITLE, ORANGE_SCHEMA, SMALLER_SIZE } from 'constants/buttonsOptions'
import { TicketTypeModal } from 'modules/ActivityProducts/ActivityResaleTab'
import { GRAY_SCHEMA } from 'constants/colorSchemas'
import { Field } from './Field'
import {
  EXTRAS_PRICE_LABEL,
  RETAIL_PRICE_LABEL,
  TICKET_TYPE,
  XPLORIE_FREE_DISABLED_LABEL,
  XPLORIE_FREE_ENABLED_LABEL,
  XPLORIE_PRICE_LABEL,
  LIST_PRICE_LABEL
} from './types'
import styles from './TicketType.scss'

export function TicketType(props) {
  const {
    description,
    disabled,
    enqueue,
    extrasPrice,
    id,
    index,
    moveTicketType,
    name,
    percentageExtrasPrice,
    percentageXploriePrice,
    retailPrice,
    ticketGroupId,
    xploriePrice,
    xplorieTicketEnabled,
    listPrice,
    showButtons
  } = props

  const ref = useRef(null)
  const onEditHandler = useCallback(() => {
    enqueue(TicketTypeModal, { id, dataId: id })
  }, [enqueue, id])
  const [xplorieFreeLabel, xplorieFreeScheme] = useMemo(
    () =>
      xplorieTicketEnabled
        ? [XPLORIE_FREE_ENABLED_LABEL, ORANGE_SCHEMA]
        : [XPLORIE_FREE_DISABLED_LABEL, GRAY_SCHEMA],
    [xplorieTicketEnabled]
  )

  const [, drop] = useDrop({
    accept: TICKET_TYPE,
    canDrop: item => item.ticketGroupId === ticketGroupId,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (item.ticketGroupId !== ticketGroupId) {
        return
      }
      if (!canShift({ dragIndex, hoverIndex, monitor, ref })) {
        return
      }

      moveTicketType(dragIndex, hoverIndex)
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: TICKET_TYPE, id, index, ticketGroupId },
    canDrag: () => Boolean(ticketGroupId),
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const opacity = isDragging ? 0 : 1

  drag(drop(ref))

  return (
    <div ref={ref} style={{ opacity }}>
      <SimpleBox className={styles.boxWrapper}>
        <div className={styles.boxHeader}>
          <div className={styles.titles}>
            <BoxLabel>{name}</BoxLabel>
            <h2 className={styles.subTitle}>{description}</h2>
          </div>
          <div className={styles.boxButtons}>
            {showButtons && (
              <BasicButton
                colorSchema={ORANGE_SCHEMA}
                size={SMALLER_SIZE}
                onClick={onEditHandler}
                disabled={disabled}
              >
                {EDIT_TITLE}
              </BasicButton>
            )}
          </div>
        </div>
        <ul className={styles.content}>
          <Field
            label={XPLORIE_PRICE_LABEL}
            isPercentageOption={percentageXploriePrice}
            value={xploriePrice}
            isRequired
          />
          <Field label={RETAIL_PRICE_LABEL} value={retailPrice} />
          <Field
            label={EXTRAS_PRICE_LABEL}
            isPercentageOption={percentageExtrasPrice}
            value={extrasPrice}
          />
          {xplorieTicketEnabled && <Field label={LIST_PRICE_LABEL} value={listPrice} />}
        </ul>
        <Tag colorSchema={xplorieFreeScheme}>{xplorieFreeLabel}</Tag>
      </SimpleBox>
    </div>
  )
}

TicketType.propTypes = {
  name: PropTypes.string,
  xploriePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  retailPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  listPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  enqueue: PropTypes.func.isRequired,
  extrasPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  percentageExtrasPrice: PropTypes.bool.isRequired,
  percentageXploriePrice: PropTypes.bool.isRequired,
  xplorieTicketEnabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  moveTicketType: PropTypes.func.isRequired,
  ticketGroupId: PropTypes.string,
  showButtons: PropTypes.bool.isRequired
}
TicketType.defaultProps = {
  name: null,
  xploriePrice: null,
  retailPrice: null,
  extrasPrice: null,
  description: null,
  ticketGroupId: null,
  listPrice: null
}
