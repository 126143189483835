/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const LIST_NAME = 'HOLIDAYS_DICTIONARY_LIST'

export default {
  LIST_NAME
}
