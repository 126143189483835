/* eslint-disable max-lines */
/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import ticketGroupFormHelpers from '../../ticketGroupForm/helpers'
import helpers from './helpers'

const {
  RESET_DATA_STATE,
  REQUEST_UPLOAD_FILES,
  RESPONSE_UPLOAD_FILES,
  RESPONSE_UPLOAD_FILES_ERROR,
  UPDATED_UPLOAD_FILE_PROGRESS,
  REQUEST_UPLOAD_FILES_PROCCESS,
  RESPONSE_UPLOAD_FILES_PROCCESS,
  RESPONSE_UPLOAD_FILES_PROCCESS_ERROR,
  REQUEST_FILES_BY_GROUP_ID,
  RESPONSE_FILES_BY_GROUP_ID,
  RESPONSE_FILES_BY_GROUP_ID_ERROR,
  REQUEST_DOWNLOAD_TICKET,
  RESPONSE_DOWNLOAD_TICKET,
  RESPONSE_DOWNLOAD_TICKET_ERROR,
  REQUEST_REMOVE_INTENTORIES,
  RESPONSE_REMOVE_INTENTORIES,
  RESPONSE_REMOVE_INTENTORIES_ERROR,
  REQUEST_REMOVE_INTENTORIE,
  RESPONSE_REMOVE_INTENTORIE,
  RESPONSE_REMOVE_INTENTORIE_ERROR,
  REMOVE_INVENTORIE_FROM_STATE
} = helpers

export const initialState = {
  uploadFiles: { data: {}, pagination: { itemsCount: 0, itemsPerPage: 10, pageNumber: 1 } },
  uploadDataState: DATA_STATE_NOT_REQUESTED,
  groupFilesDataState: DATA_STATE_NOT_REQUESTED,
  removeInvsDataState: DATA_STATE_NOT_REQUESTED
}

const ticketGroupInventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_UPLOAD_FILES:
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          data: {
            [action.payload.fileId]: {
              dataState: DATA_STATE_REQUESTING,
              downloadFileDataState: DATA_STATE_NOT_REQUESTED,
              data: action.payload.data
            },
            ...state.uploadFiles.data
          }
        }
      }
    case RESPONSE_UPLOAD_FILES_ERROR:
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          data: {
            ...state.uploadFiles.data,
            [action.payload.fileId]: {
              ...state.uploadFiles.data[action.payload.fileId],
              dataState: DATA_STATE_ERROR,
              data: action.payload.data
            }
          }
        }
      }
    case RESPONSE_UPLOAD_FILES:
      // eslint-disable-next-line no-case-declarations
      const {
        data: { [action.payload.oldId]: oldValue, ...restData }
      } = state.uploadFiles
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          data: {
            ...restData,
            [action.payload.fileId]: {
              ...state.uploadFiles.data[action.payload.fileId],
              dataState: DATA_STATE_RECIEVED,
              data: action.payload.data
            }
          }
        }
      }
    case UPDATED_UPLOAD_FILE_PROGRESS:
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          data: {
            ...state.uploadFiles.data,
            [action.payload.fileId]: {
              ...state.uploadFiles.data[action.payload.fileId],
              value: action.payload.value
            }
          }
        }
      }
    case REQUEST_UPLOAD_FILES_PROCCESS:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING,
        uploadDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_UPLOAD_FILES_PROCCESS_ERROR:
      return {
        ...state,
        dataState: DATA_STATE_ERROR,
        uploadDataState: DATA_STATE_ERROR
      }
    case RESPONSE_UPLOAD_FILES_PROCCESS:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        uploadDataState: DATA_STATE_RECIEVED
      }
    case REQUEST_FILES_BY_GROUP_ID:
      return {
        ...state,
        dataState: DATA_STATE_REQUESTING,
        groupFilesDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_FILES_BY_GROUP_ID_ERROR:
      return {
        ...state,
        groupFilesDataState: DATA_STATE_ERROR
      }
    case RESPONSE_FILES_BY_GROUP_ID:
      return {
        ...state,
        dataState: DATA_STATE_RECIEVED,
        groupFilesDataState: DATA_STATE_RECIEVED,
        uploadFiles: {
          ...state.uploadFiles,
          pagination: { ...action.payload.pagination },
          data: {
            ...Object.keys(state.uploadFiles.data)
              .filter(key => state.uploadFiles.data[key].dataState === DATA_STATE_ERROR)
              .reduce(
                (errorFiles, key) => ({
                  ...errorFiles,
                  [key]: {
                    ...state.uploadFiles.data[key]
                  }
                }),
                {}
              ),
            ...action.payload.data.reduce((acc, value) => {
              acc[value.id] = {
                dataState: DATA_STATE_RECIEVED,
                downloadFileDataState: DATA_STATE_NOT_REQUESTED,
                data: {
                  ...value
                }
              }
              return acc
            }, {})
          }
        }
      }
    case REQUEST_DOWNLOAD_TICKET:
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          data: {
            ...state.uploadFiles.data,
            [action.payload.fileId]: {
              ...state.uploadFiles.data[action.payload.fileId],
              downloadFileDataState: DATA_STATE_REQUESTING
            }
          }
        }
      }
    case RESPONSE_DOWNLOAD_TICKET:
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          data: {
            ...state.uploadFiles.data,
            [action.payload.fileId]: {
              ...state.uploadFiles.data[action.payload.fileId],
              downloadFileDataState: DATA_STATE_RECIEVED
            }
          }
        }
      }
    case RESPONSE_DOWNLOAD_TICKET_ERROR:
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          data: {
            ...state.uploadFiles.data,
            [action.payload.fileId]: {
              ...state.uploadFiles.data[action.payload.fileId],
              downloadFileDataState: DATA_STATE_ERROR
            }
          }
        }
      }
    case REQUEST_REMOVE_INTENTORIES:
      return {
        ...state,
        removeInvsDataState: DATA_STATE_REQUESTING
      }
    case RESPONSE_REMOVE_INTENTORIES_ERROR:
      return {
        ...state,
        removeInvsDataState: DATA_STATE_ERROR
      }
    case RESPONSE_REMOVE_INTENTORIES:
      return {
        ...state,
        removeInvsDataState: DATA_STATE_RECIEVED
      }
    case REQUEST_REMOVE_INTENTORIE:
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          data: {
            ...state.uploadFiles.data,
            [action.payload.fileId]: {
              ...state.uploadFiles.data[action.payload.fileId],
              removeFileDataState: DATA_STATE_REQUESTING
            }
          }
        }
      }
    case RESPONSE_REMOVE_INTENTORIE_ERROR:
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          data: {
            ...state.uploadFiles.data,
            [action.payload.fileId]: {
              ...state.uploadFiles.data[action.payload.fileId],
              removeFileDataState: DATA_STATE_ERROR
            }
          }
        }
      }
    case RESPONSE_REMOVE_INTENTORIE:
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          data: {
            ...state.uploadFiles.data,
            [action.payload.fileId]: {
              ...state.uploadFiles.data[action.payload.fileId],
              removeFileDataState: DATA_STATE_RECIEVED
            }
          }
        }
      }
    case REMOVE_INVENTORIE_FROM_STATE:
      // eslint-disable-next-line no-case-declarations
      const {
        data: { [action.payload.fileId]: removedFile, ...restFiles }
      } = state.uploadFiles
      return {
        ...state,
        uploadFiles: {
          ...state.uploadFiles,
          data: {
            ...restFiles
          }
        }
      }
    case RESET_DATA_STATE:
      return {
        ...initialState
      }
    case ticketGroupFormHelpers.RESET_DATA_STATE:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default filterActions(ticketGroupInventoryReducer, [
  RESET_DATA_STATE,
  REQUEST_UPLOAD_FILES,
  RESPONSE_UPLOAD_FILES,
  RESPONSE_UPLOAD_FILES_ERROR,
  REQUEST_UPLOAD_FILES_PROCCESS,
  RESPONSE_UPLOAD_FILES_PROCCESS,
  RESPONSE_UPLOAD_FILES_PROCCESS_ERROR,
  UPDATED_UPLOAD_FILE_PROGRESS,
  REQUEST_FILES_BY_GROUP_ID,
  RESPONSE_FILES_BY_GROUP_ID,
  RESPONSE_FILES_BY_GROUP_ID_ERROR,
  REQUEST_DOWNLOAD_TICKET,
  RESPONSE_DOWNLOAD_TICKET,
  RESPONSE_DOWNLOAD_TICKET_ERROR,
  REQUEST_REMOVE_INTENTORIES,
  RESPONSE_REMOVE_INTENTORIES,
  RESPONSE_REMOVE_INTENTORIES_ERROR,
  REQUEST_REMOVE_INTENTORIE,
  RESPONSE_REMOVE_INTENTORIE,
  RESPONSE_REMOVE_INTENTORIE_ERROR,
  REMOVE_INVENTORIE_FROM_STATE,
  ticketGroupFormHelpers.RESET_DATA_STATE
])
