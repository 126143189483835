/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { TextField, ImageField } from 'components/FormComponents'
import { linkValidate } from 'helpers/formHelpers'
import {
  NAME_LABEL,
  NAME_PLACEHOLDER,
  NAME_FIELD,
  HIW_LABEL,
  HIW_PLACEHOLDER,
  HIW_FIELD,
  ACTIVITY_POPULAR_LABEL,
  ACTIVITY_POPULAR_PLACEHOLDER,
  ACTIVITY_POPULAR_FIELD,
  ACTIVITY_GEM_LABEL,
  ACTIVITY_GEM_PLACEHOLDER,
  ACTIVITY_GEM_FIELD,
  ALMOST_TIME_LABEL,
  ALMOST_TIME_PLACEHOLDER,
  ALMOST_TIME_FIELD,
  FAQ_HERO_LABEL,
  FAQ_HERO_PLACEHOLDER,
  FAQ_HERO_FIELD,
  IN_HOUSE_LABEL,
  IN_HOUSE_PLACEHOLDER,
  IN_HOUSE_FIELD,
  POST_HERO_LABEL,
  POST_HERO_PLACEHOLDER,
  POST_HERO_FIELD,
  ONE_YEAR_LABEL,
  ONE_YEAR_PLACEHOLDER,
  ONE_YEAR_FIELD,
  FOOTER_BKG_LABEL,
  FOOTER_BKG_PLACEHOLDER,
  FOOTER_BKG_FIELD
} from './types'

export function createField(Component, props = {}) {
  const { fieldName } = props
  if (!fieldName) {
    throw new Error('fieldName is mandatory')
  }
  return {
    key: fieldName,
    Component,
    props
  }
}

function createTextField(options = {}) {
  return createField(TextField, options)
}

function createImageField(options = {}) {
  return createField(ImageField, { validate: linkValidate, ...options })
}

export function getListOfFields() {
  return [
    createTextField({
      fieldName: NAME_FIELD,
      label: NAME_LABEL,
      placeholder: NAME_PLACEHOLDER,
      isRequired: true
    }),
    createImageField({ fieldName: HIW_FIELD, label: HIW_LABEL, placeholder: HIW_PLACEHOLDER }),
    createImageField({
      fieldName: ACTIVITY_POPULAR_FIELD,
      label: ACTIVITY_POPULAR_LABEL,
      placeholder: ACTIVITY_POPULAR_PLACEHOLDER
    }),
    createImageField({
      fieldName: ACTIVITY_GEM_FIELD,
      label: ACTIVITY_GEM_LABEL,
      placeholder: ACTIVITY_GEM_PLACEHOLDER
    }),
    createImageField({
      fieldName: ALMOST_TIME_FIELD,
      label: ALMOST_TIME_LABEL,
      placeholder: ALMOST_TIME_PLACEHOLDER
    }),
    createImageField({
      fieldName: FAQ_HERO_FIELD,
      label: FAQ_HERO_LABEL,
      placeholder: FAQ_HERO_PLACEHOLDER
    }),
    createImageField({
      label: IN_HOUSE_LABEL,
      placeholder: IN_HOUSE_PLACEHOLDER,
      fieldName: IN_HOUSE_FIELD
    }),
    createImageField({
      label: POST_HERO_LABEL,
      placeholder: POST_HERO_PLACEHOLDER,
      fieldName: POST_HERO_FIELD
    }),
    createImageField({
      label: ONE_YEAR_LABEL,
      placeholder: ONE_YEAR_PLACEHOLDER,
      fieldName: ONE_YEAR_FIELD
    }),
    createImageField({
      label: FOOTER_BKG_LABEL,
      placeholder: FOOTER_BKG_PLACEHOLDER,
      fieldName: FOOTER_BKG_FIELD
    })
  ]
}
