/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useReducer, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDidMount, usePrevious } from 'hooks'

import { FilteredSearchContext } from './context'
import { init, reducer } from './helpers'
import { SET_FILTER, SET_PARAMS, SUBMIT } from './types'

export function FilteredSearch({ getApi, children, onChange, initialFilters }) {
  const [state, dispatch] = useReducer(reducer, initialFilters, init)
  const stateRef = useRef(state)

  const prevState = usePrevious(state)

  const setFilter = useCallback((field, value) => {
    dispatch({ type: SET_FILTER, payload: { key: field, value } })
  }, [])

  const setParams = useCallback(newParams => {
    dispatch({ type: SET_PARAMS, payload: newParams })
  }, [])

  const getState = useCallback(() => stateRef.current, []);
  const getFilters = useCallback(() => stateRef.current.filters, [])
  const getParams = useCallback(() => stateRef.current.params, [])

  const emitChange = useCallback(() => {
    dispatch({ type: SUBMIT })
  }, [])

  useEffect(() => {
    if (prevState && prevState.changes !== state.changes && state.changes > 0) {
      if (onChange) {
        onChange(state)
      }
    }
  }, [onChange, prevState, state])

  useDidMount(() => {
    if (getApi) {
      getApi({ setFilter, setParams, getParams, getFilters, emitChange, getState })
    }
  })

  useEffect(() => {
    stateRef.current = state
  })

  return (
    <FilteredSearchContext.Provider value={{ ...state, setFilter, setParams, getState }}>
      {children}
    </FilteredSearchContext.Provider>
  )
}

FilteredSearch.propTypes = {
  getApi: PropTypes.func,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  initialFilters: PropTypes.object
}
FilteredSearch.defaultProps = {
  getApi: null,
  onChange: null,
  initialFilters: {}
}
