/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { SelectField, TextField } from 'components/FormComponents'
import { TrashButton } from '../TrashButton'
import { relatedFieldBlankValidate, anyNumberWithRelatedBlankValidate } from './helpers'
import {
  CONFIRMATION_MODE_FIELD,
  CONFIRMATION_ID_VALUE,
  PARAMETER_LABEL,
  MODE_LABEL,
  VALUE_LABEL,
  PREBOOK_CONFIRMATION_LABEL,
  MODE_PLACEHOLDER,
  VALUE_PLACEHOLDER,
  modes
} from './types'
import { RATE_VALUE_FIELD, START_VALUE_FIELD } from '../PrebookRateForm/types'
import styles from './PrebookForm.scss'

const FORM_FIELDS = [CONFIRMATION_MODE_FIELD, CONFIRMATION_ID_VALUE]

export function PrebookForm({ formApi }) {
  const onCleanFields = useCallback(() => {
    FORM_FIELDS.forEach(field => formApi.fieldExists(field) && formApi.setValue(field, null))
  }, [formApi])

  return (
    <div className={styles.table}>
      <div className={classNames(styles.row, styles.header)}>
        <div className={styles.col}>
          <span className={styles.text}>{PARAMETER_LABEL}</span>
        </div>
        <div className={styles.col}>
          <span className={styles.text}>{MODE_LABEL}</span>
        </div>
        <div className={styles.col}>
          <span className={styles.text}>{VALUE_LABEL}</span>
        </div>
        <div className={styles.col}> </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <span className={classNames(styles.text, styles.bold)}>{PREBOOK_CONFIRMATION_LABEL}</span>
        </div>
        <div className={styles.col}>
          <SelectField
            fieldName={CONFIRMATION_MODE_FIELD}
            placeholder={MODE_PLACEHOLDER}
            options={modes}
            fieldProps={{ classNameError: styles.selectError }}
            notify={[CONFIRMATION_ID_VALUE]}
            validate={relatedFieldBlankValidate([
              CONFIRMATION_ID_VALUE,
              RATE_VALUE_FIELD,
              START_VALUE_FIELD
            ])}
          />
        </div>
        <div className={styles.col}>
          <TextField
            fieldName={CONFIRMATION_ID_VALUE}
            placeholder={VALUE_PLACEHOLDER}
            fieldProps={{ classNameError: styles.error }}
            notify={[CONFIRMATION_MODE_FIELD]}
            validate={anyNumberWithRelatedBlankValidate([
              CONFIRMATION_MODE_FIELD,
              RATE_VALUE_FIELD,
              START_VALUE_FIELD
            ])}
          />
        </div>
        <div className={styles.col}>
          <TrashButton onClick={onCleanFields} />
        </div>
      </div>
    </div>
  )
}

PrebookForm.propTypes = {
  formApi: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired
}
