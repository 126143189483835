/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { entityInitialState } from './reducer'

function getProgramGuestsDataFormState(state) {
  return state.guestsData.programGuestsDataForm
}

function getProgramGuestsDataFormStateById(state, id) {
  return getProgramGuestsDataFormState(state)[id] || entityInitialState
}

function getUploadCancellationTokenById(state, id) {
  const entityState = getProgramGuestsDataFormStateById(state, id)
  return entityState.cancelToken
}

function getProgramGuestsDataFormDataStates(state, id) {
  const entityState = getProgramGuestsDataFormStateById(state, id)
  return [entityState.dataState]
}

export default {
  getProgramGuestsDataFormState,
  getProgramGuestsDataFormStateById,
  getUploadCancellationTokenById,
  getProgramGuestsDataFormDataStates
}
