/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { BasicButton } from '@xplorie/ui-commons'
import { ButtonsWrapper } from 'components/Wrappers'
import { Column } from 'components/Wrappers/ButtonsWrapper'
import { useProgramsDiffSlider } from 'modules/Programs/hooks'
import {
  getParentProgramId,
  getCurrentProgramId,
  getCurrentVersion,
  getPreviousVersion
} from './helpers'
import { SHOW_CHANGES_TITLE, SHOW_BUTTON, IT_IS_NEW_PROGRAM } from './types'
import styles from './ProgramsDiff.scss'

export function ProgramsDiff(props) {
  const { formData } = props
  const relatedId = getParentProgramId(formData)
  const currentId = getCurrentProgramId(formData)
  const currentProgramVersion = getCurrentVersion(formData)
  const relatedProgramVersion = getPreviousVersion(formData)
  const isDisabled = !relatedId || !currentId
  const showButtonTitle = isDisabled ? IT_IS_NEW_PROGRAM : SHOW_BUTTON
  const programsDiffSlider = useProgramsDiffSlider(currentId, relatedId, {
    currentProgramVersion,
    relatedProgramVersion
  })
  const onClickDiff = useCallback(() => {
    programsDiffSlider.open()
  }, [programsDiffSlider])

  return (
    <div className={styles.wrapper}>
      <ButtonsWrapper>
        <Column>
          <h1 className={styles.title}>{SHOW_CHANGES_TITLE}</h1>
        </Column>
        <Column>
          <BasicButton id="show-changes-btn" disabled={isDisabled} onClick={onClickDiff}>
            {showButtonTitle}
          </BasicButton>
        </Column>
      </ButtonsWrapper>
    </div>
  )
}

ProgramsDiff.propTypes = {
  formData: PropTypes.object.isRequired
}
ProgramsDiff.defaultProps = {}
