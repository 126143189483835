/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { actionCreater } from 'helpers/actionHelpers'
import { getTree, deleteActivityCategoryById } from 'services/http/activityCategory'
import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_LIST_ERROR,
  RESET_DATA_STATE,
  UPDATE_TREE_STATE,
  REQUEST_REMOVE_ACTIVITY_CATEGORY,
  RESPONSE_REMOVE_ACTIVITY_CATEGORY,
  RESPONSE_REMOVE_ACTIVITY_CATEGORY_ERROR
} = helpers

const getActivityCategoriesTree = authKey => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await getTree(authKey).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_LIST_ERROR))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, request.data))
    return 'getActivityCategoriesTree success'
  }

  return 'getActivityCategoriesTree failed'
}

const updateTreeState = (data = {}) => dispatch => {
  dispatch(actionCreater(UPDATE_TREE_STATE, data))
}

const removeActivityCategory = categoryId => async dispatch => {
  dispatch(actionCreater(REQUEST_REMOVE_ACTIVITY_CATEGORY))
  const request = await deleteActivityCategoryById(categoryId).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_REMOVE_ACTIVITY_CATEGORY_ERROR))
  })
  if (request) {
    const {
      message: { message, send }
    } = request
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_REMOVE_ACTIVITY_CATEGORY))
    return 'removeActivityCategory success'
  }

  return 'removeActivityCategory failed'
}

const resetList = () => ({
  type: RESET_DATA_STATE
})
export default {
  getActivityCategoriesTree,
  updateTreeState,
  resetList,
  removeActivityCategory
}
