/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'
import {
  DATA_STATE_ERROR,
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_RECIEVED,
  DATA_STATE_REQUESTING
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_PROGRAMS_AND_ACTIVITIES,
  RESPONSE_PROGRAMS_AND_ACTIVITIES,
  RESPONSE_PROGRAMS_AND_ACTIVITIES_ERROR,
  RESET_DATA_STATE
} = helpers

export const initialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  data: {}
}

const locationFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PROGRAMS_AND_ACTIVITIES:
      return { ...state, dataState: DATA_STATE_REQUESTING }
    case RESPONSE_PROGRAMS_AND_ACTIVITIES:
      return { ...state, dataState: DATA_STATE_RECIEVED, data: action.payload }
    case RESPONSE_PROGRAMS_AND_ACTIVITIES_ERROR:
      return { ...state, dataState: DATA_STATE_ERROR }
    case RESET_DATA_STATE:
      return initialState
    default:
      return state
  }
}

export default filterActions(locationFormReducer, [
  REQUEST_PROGRAMS_AND_ACTIVITIES,
  RESPONSE_PROGRAMS_AND_ACTIVITIES,
  RESPONSE_PROGRAMS_AND_ACTIVITIES_ERROR,
  RESET_DATA_STATE
])
