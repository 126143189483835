/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { exportReport as exportPMCReportRequest } from 'services/http/pmc'
import { exportReport as exportAPPReportRequest } from 'services/http/activity'

import helpers from './helpers'

const { REQUEST_EXPORT, RESPONSE_EXPORT, RESPONSE_ERROR_EXPORT } = helpers

const exportAPPReport = (authKey, format) => async dispatch => {
  dispatch(actionCreater(REQUEST_EXPORT))
  const request = await exportAPPReportRequest(authKey, format).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_EXPORT))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_EXPORT, { data: request.response }))
    return 'exportAPPReport success'
  }

  return 'exportAPPReport failed'
}

const exportPMCReport = (authKey, format) => async dispatch => {
  dispatch(actionCreater(REQUEST_EXPORT))
  const request = await exportPMCReportRequest(authKey, format).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_EXPORT))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_EXPORT, { data: request.response }))
    return 'exportAPReport success'
  }

  return 'exportAPReport failed'
}

export default {
  exportPMCReport,
  exportAPPReport
}
