/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { withFormContext } from 'components/FormComponents/helpers'
import PropTypes from 'prop-types'
import { SelectProgram } from './SelectProgram'
import { Program } from './Program'
import styles from './styles.scss'
import { useDiscountProgramsPresenter } from './useDiscountProgramsPresenter'

function DiscountProgramsComponent(props) {
  const {
    programs,
    addProgram,
    removeProgram,
    options,
    disabled,
    hasPrograms
  } = useDiscountProgramsPresenter(props)

  return (
    <div className={styles.wrapper}>
      <SelectProgram onChange={addProgram} disabled={disabled} options={options} />
      {hasPrograms && (
        <div className={styles.programs}>
          {programs.map(program => (
            <Program
              key={program.id}
              program={program}
              onDelete={removeProgram}
              disabled={disabled}
            />
          ))}
        </div>
      )}
    </div>
  )
}

DiscountProgramsComponent.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onGetData: PropTypes.func.isRequired,
  programsDataState: PropTypes.string.isRequired,
  programsData: PropTypes.array.isRequired,
  offerType: PropTypes.string.isRequired,
  activity: PropTypes.object,
  discountId: PropTypes.string,
  api: PropTypes.shape({
    setProgramValue: PropTypes.func.isRequired,
    setProgramOptionValue: PropTypes.func.isRequired
  }).isRequired,
  program: PropTypes.shape({
    items: PropTypes.array,
    assignmentType: PropTypes.string
  })
}

DiscountProgramsComponent.defaultProps = {
  activity: {
    id: null
  },
  discountId: null,
  program: {
    items: [],
    assignmentType: null
  }
}

export const DiscountPrograms = withFormContext(DiscountProgramsComponent)
