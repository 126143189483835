/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESET_STATE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE
} from 'helpers/actionHelpers'

import types from './types'

const { FORM_NAME, GET_FORM_DATA, DELETE_ENTITY_DATA, NEW_ENTITY_KEY } = types

const REQUEST_FORM = `${REQUEST_TYPE}_${FORM_NAME}`
const RESPONSE_FORM = `${RESPONSE_TYPE}_${FORM_NAME}`
const RESPONSE_FORM_ERROR = `${RESPONSE_ERROR_TYPE}_${FORM_NAME}`

const REQUEST_GET_FORM_DATA = `${REQUEST_TYPE}_${GET_FORM_DATA}`
const RESPONSE_GET_FORM_DATA = `${RESPONSE_TYPE}_${GET_FORM_DATA}`
const RESPONSE_GET_FORM_DATA_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_FORM_DATA}`

const REQUEST_DELETE_ENTITY_DATA = `${REQUEST_TYPE}_${DELETE_ENTITY_DATA}`
const RESPONSE_DELETE_ENTITY_DATA = `${RESPONSE_TYPE}_${DELETE_ENTITY_DATA}`
const RESPONSE_DELETE_ENTITY_DATA_ERROR = `${RESPONSE_ERROR_TYPE}_${DELETE_ENTITY_DATA}`

const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

const CREATED_MESSAGE = 'Market has been created.'
const UPDATED_MESSAGE = 'Market has been updated.'
const DELETED_MESSAGE = 'Market has been deleted.'

export default {
  NEW_ENTITY_KEY,
  REQUEST_FORM,
  RESPONSE_FORM,
  RESPONSE_FORM_ERROR,
  RESET_DATA_STATE,
  REQUEST_GET_FORM_DATA,
  RESPONSE_GET_FORM_DATA,
  RESPONSE_GET_FORM_DATA_ERROR,
  REQUEST_DELETE_ENTITY_DATA,
  RESPONSE_DELETE_ENTITY_DATA,
  RESPONSE_DELETE_ENTITY_DATA_ERROR,
  CREATED_MESSAGE,
  UPDATED_MESSAGE,
  DELETED_MESSAGE
}
