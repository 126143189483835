/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLoading } from 'hooks'
import get from 'lodash/get'
import { DeactivationInfo } from 'modules/DelayedDeactivation'
import { canDeactivate as canDeactivateBuilder } from 'modules/ActivityProviders'

import { ConfirmationModal } from './ConfirmationModal'
import { buildInfoTitle, getDeactivationMode } from './helpers'
import { UPDATE_DEACTIVATED_DATE_MODE } from './types'

export function DelayedDeactivation({
  entityData,
  entityDataState,
  deactivateDataState,
  deactivateById,
  deleteDelayDeactivate,
  dataId,
  authUser,
  ...rest
}) {
  const modalApi = useRef()
  const canDeactivate = useMemo(() => canDeactivateBuilder(authUser, entityData), [
    authUser,
    entityData
  ])
  const deactivateInfoMessage = useMemo(() => buildInfoTitle(entityData), [entityData])
  const [, { isLoaded }] = useLoading(entityDataState)
  const deactivationInfo = get(entityData, 'deactivationDate')
  const deactivationDate = get(deactivationInfo, 'date')

  const name = get(entityData, 'name')

  const mode = getDeactivationMode(entityData)

  const onGetModalApi = useCallback(api => {
    modalApi.current = api
  }, [])

  const onClickHandler = useCallback(() => {
    if (modalApi.current) {
      modalApi.current.toggleModal()
    }
  }, [])

  const onDeactivateHandler = useCallback(
    ({ date }) => {
      if (dataId && modalApi.current) {
        modalApi.current.closeModal()
        const { date: currentDate } = date
        deactivateById(dataId, {
          prevDate: deactivationDate,
          currentDate,
          date,
          isInactive: mode === UPDATE_DEACTIVATED_DATE_MODE
        })
      }
    },
    [dataId, deactivateById, deactivationDate, mode]
  )

  const onCancelDeactivateHandler = useCallback(() => {
    if (dataId && modalApi.current) {
      modalApi.current.closeModal()
      deleteDelayDeactivate(dataId)
    }
  }, [dataId, deleteDelayDeactivate])

  return (
    <>
      {deactivationDate && isLoaded && (
        <DeactivationInfo
          message={deactivateInfoMessage}
          date={deactivationDate}
          onClick={onClickHandler}
          showIcon={canDeactivate}
          disabled={!canDeactivate}
        />
      )}
      <ConfirmationModal
        dataId={dataId}
        getApi={onGetModalApi}
        name={name}
        date={deactivationInfo}
        onAction={onDeactivateHandler}
        onCancel={onCancelDeactivateHandler}
        deactivateDataState={deactivateDataState}
        mode={mode}
        {...rest}
      />
    </>
  )
}
DelayedDeactivation.propTypes = {
  entityData: PropTypes.object,
  entityDataState: PropTypes.string.isRequired,
  deactivateDataState: PropTypes.string.isRequired,
  deactivateById: PropTypes.func.isRequired,
  deleteDelayDeactivate: PropTypes.func.isRequired,
  dataId: PropTypes.string.isRequired,
  authUser: PropTypes.object.isRequired
}
DelayedDeactivation.defaultProps = {
  entityData: null
}
