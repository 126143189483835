/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions as editQueue } from 'reducers/editQueue'
import { actions } from 'reducers/locationsTree'
import { selectors, formActions } from 'reducers/location/locationForm'
import { withAuthContext } from 'helpers/withAuthContext'

import { LocationsList as LocationsListComponent } from './LocationsList'

const { getLocationsChangedDataStates } = selectors
const { linkChildLocation, createAndLinkChildLocation, updateAndLinkChildren } = formActions

export function mapStateToProps(state) {
  const formsDataStates = getLocationsChangedDataStates(state)
  return {
    ...state.locationsTree,
    updateListDataStates: [
      ...formsDataStates,
      state.locationsTree.unlinkActionDataState,
      state.locationsTree.removeLocationDataState,
      state.locationsTree.moveLocationDataState
    ]
  }
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...editQueue,
      ...actions,
      linkChildLocation,
      createAndLinkChildLocation,
      updateAndLinkChildren
    },
    dispatch
  )
}

export const LocationsList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LocationsListComponent)
