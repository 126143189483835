/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import moment from 'moment'

export const buildSelectedDay = ({ originalDate, value, format }) => {
  const originalValue = moment(originalDate, format)
  const dateValue = moment(value, format)
  if (originalValue.isValid()) {
    return originalValue.toDate()
  }
  if (dateValue.isValid()) {
    return dateValue.toDate()
  }
  return null
}
