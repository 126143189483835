/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useEffect } from 'react'
import { usePrevious } from 'hooks'
import PropTypes from 'prop-types'
import { asField } from 'informed'
import get from 'lodash/get'
import moment from 'moment'
import classNames from 'classnames'

import { FORMAT } from 'constants/date'
import { CalendarWithHolidays } from 'modules/CalendarWithHolidays'
import { CalendarInput } from 'modules/CalendarInput'
import { Field } from 'components/FormComponents'

import styles from './CalendarInput.scss'

export function CalendarWrapper(props) {
  const {
    fieldState: { value: fieldValue, error },
    fieldApi: { setValue, setTouched },
    fieldState,
    fieldApi,
    onChange,
    onBlur,
    onFocus,
    field,
    forwardedRef,
    label,
    fieldProps,
    isRequired,
    id,
    withHolidays,
    shownFormat,
    format,
    className,
    errorClassName,
    ...rest
  } = props

  const CalendarComponent = withHolidays ? CalendarWithHolidays : CalendarInput
  const fieldPropsClassName = get(fieldProps, 'className')
  const prevIsRequired = usePrevious(isRequired)

  const onChangeHandler = value => {
    setValue(value)

    if (onChange) {
      onChange(value)
    }
  }

  const onFocusHandler = () => {
    setTouched()
  }

  useEffect(() => {
    if (fieldValue !== undefined) {
      const momentDate = moment(fieldValue, format, true)
      const inputValue = momentDate.isValid() ? momentDate.format(shownFormat) : fieldValue
      if (inputValue !== fieldValue) {
        setValue(inputValue)
      }

      if (isRequired !== prevIsRequired) {
        setValue(fieldValue)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue, isRequired])

  return (
    <Field
      id={id}
      label={label}
      className={classNames(fieldPropsClassName, { hero: !fieldPropsClassName })}
      isRequired={isRequired}
      error={error}
      {...fieldProps}
    >
      <CalendarComponent
        {...rest}
        className={classNames(className, { [errorClassName]: Boolean(error) })}
        id={id}
        ref={forwardedRef}
        format={format}
        shownFormat={shownFormat}
        name={field}
        value={fieldValue || ''}
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
      />
    </Field>
  )
}

CalendarWrapper.propTypes = {
  fieldApi: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  withHolidays: PropTypes.bool,
  shownFormat: PropTypes.string,
  fieldState: PropTypes.shape({
    asyncError: PropTypes.any,
    error: PropTypes.string,
    touched: PropTypes.bool,
    value: PropTypes.string
  }),
  onFocus: PropTypes.func,
  field: PropTypes.string.isRequired,
  forwardedRef: PropTypes.any,
  label: PropTypes.string,
  fieldProps: PropTypes.object,
  isRequired: PropTypes.bool,
  id: PropTypes.string,
  format: PropTypes.string,
  defaultValue: PropTypes.string,
  errorClassName: PropTypes.string,
  className: PropTypes.string
}

CalendarWrapper.defaultProps = {
  onChange: null,
  onBlur: () => null,
  onFocus: () => null,
  withHolidays: false,
  forwardedRef: null,
  fieldState: PropTypes.shape({
    asyncError: null,
    error: '',
    touched: true,
    value: ''
  }),
  format: FORMAT,
  shownFormat: FORMAT,
  label: '',
  fieldProps: {},
  isRequired: false,
  id: null,
  defaultValue: null,
  errorClassName: '',
  className: styles.input
}

export const Calendar = asField(CalendarWrapper)
