/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { withAuthContext } from 'helpers/withAuthContext'
import { compose, bindActionCreators } from 'redux'

import { actions as queueActions } from 'reducers/editQueue'
import { actions as programsListActions } from 'reducers/activityProductList'
import { formActions } from 'reducers/activityProductForm/index'

import ActivityProducts from './ActivityProducts'

const mapStateToProps = state => ({
  panelStack: state.editQueue.panelStack,
  entityData: state.activityProductForm.entityData,
  entityDataState: state.activityProductForm.entityDataState
})

const mapDispatchToProps = dispatch => {
  const { getProductsList } = programsListActions
  const { getAPPbyId } = formActions
  return bindActionCreators({ onGetData: getProductsList, getAPPbyId, ...queueActions }, dispatch)
}

const ActivityProductsConnect = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ActivityProducts)

export default ActivityProductsConnect
