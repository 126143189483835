/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions as editQueue } from 'reducers/editQueue'
import { actions } from 'reducers/activityTagsList'
import { formActions } from 'reducers/activityTagsForm'
import { withAuthContext } from 'helpers/withAuthContext'

import { ActivityTagsList as ActivityTagsListComponent } from './ActivityTagsList'

export const mapStateToProps = state => ({
  ...state.activityTagsList,
  formDataState: state.activityTagsForm.formDataState,
  removeDataState: state.activityTagsForm.removeDataState
})

export const mapDispatchToProps = dispatch => {
  const { getActivityTagsList: onGetData, ...rest } = actions

  return bindActionCreators({ ...editQueue, ...formActions, onGetData, ...rest }, dispatch)
}

export const ActivityTagsList = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ActivityTagsListComponent)
