/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions, getAPLifeCycleById } from 'reducers/activityProvider/apLifeCycle'
import { withAuthContext } from 'helpers/withAuthContext'

import { DelayedDeactivation as DelayedDeactivationComponent } from './DelayedDeactivation'

const { deactivateAPById, deleteDelayDeactivate } = actions

const mapStateToProps = (state, props) => {
  const { dataId } = props
  const lifeCycleState = getAPLifeCycleById(state, dataId)
  return {
    entityData: state.activityProviderForm.entityData,
    entityDataState: state.activityProviderForm.entityDataState,
    deactivateDataState: lifeCycleState.deactivationDataState
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ deactivateById: deactivateAPById, deleteDelayDeactivate }, dispatch)

export const DelayedDeactivation = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DelayedDeactivationComponent)
