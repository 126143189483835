/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { formActions, selectors } from 'reducers/location/locationForm'
import { withAuthContext } from 'helpers/withAuthContext'
import { NEW_KEY } from 'reducers/location/locationForm/types'
import { LocationForm as LocationFormComponent } from './LocationForm'

const { createCountryAction, createLocationAction, updateLocationAction, resetForm } = formActions
const { getLocationStateById } = selectors

function mapStateToProps(state, { dataId }) {
  const locationState = getLocationStateById(state, dataId)
  return {
    formData: locationState.locationData
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createCountry: createCountryAction,
      createLocation: createLocationAction,
      updateLocation: updateLocationAction,
      resetForm: () => resetForm(NEW_KEY)
    },
    dispatch
  )
}

export const LocationForm = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LocationFormComponent)
