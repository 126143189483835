/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import find from 'lodash/find'
import { BoxColumns, Field } from 'components/FormComponents'
import { DeleteEntityButton } from 'components/Buttons'
import { CalendarWithHolidays } from 'modules/CalendarWithHolidays'
import { DELETE_TITLE, SMALLER_SIZE, ORANGE_SCHEMA } from 'constants/buttonsOptions'
import {
  START_DATE_LABEL,
  END_DATE_LABEL,
  START_DATE_PLACEHOLDER,
  END_DATE_PLACEHOLDER
} from '../types'

import styles from './AvailabilityItem.scss'

export function AvailabilityItem(props) {
  const {
    enableHoliday,
    onEnableHolidays: enableHolidayChanged,
    value,
    onChange,
    onDelete,
    errors,
    disabled
  } = props

  const [datesBufferValue, setDatesBufferState] = useState(value)

  const id = get(value, 'id', null)
  const startDate = get(datesBufferValue, 'startDate', {})
  const endDate = get(datesBufferValue, 'endDate', {})
  const error = find(errors, item => item.id === id) || {}
  const { startDate: startDateError, endDate: endDateError } = error

  const onChangeHandler = useCallback(
    key => newValue => {
      setDatesBufferState({ ...value, [key]: newValue })
    },
    [value]
  )

  const onBlurHandler = useCallback(() => {
    onChange(datesBufferValue, value)
  }, [datesBufferValue, onChange, value])

  const onDeleteHandler = useCallback(() => {
    onDelete(value)
  }, [onDelete, value])

  return (
    <div className={styles.wrapper}>
      <div className={styles.fields}>
        <BoxColumns>
          <Field label={START_DATE_LABEL} error={startDateError} className="hero">
            <CalendarWithHolidays
              placeholder={START_DATE_PLACEHOLDER}
              withHolidays
              overlayProps={{
                enableHolidayChanged,
                enableHoliday
              }}
              value={startDate}
              onChange={onChangeHandler('startDate')}
              onBlur={onBlurHandler}
              disabled={disabled}
            />
          </Field>
          <Field label={END_DATE_LABEL} error={endDateError} className="hero">
            <CalendarWithHolidays
              placeholder={END_DATE_PLACEHOLDER}
              withHolidays
              overlayProps={{
                enableHolidayChanged,
                enableHoliday
              }}
              value={endDate}
              onChange={onChangeHandler('endDate')}
              onBlur={onBlurHandler}
              disabled={disabled}
            />
          </Field>
        </BoxColumns>
      </div>
      {!disabled && (
        <div className={styles.buttons}>
          <DeleteEntityButton
            onClick={onDeleteHandler}
            size={SMALLER_SIZE}
            colorSchema={ORANGE_SCHEMA}
            disabled={disabled}
          >
            {DELETE_TITLE}
          </DeleteEntityButton>
        </div>
      )}
    </div>
  )
}

AvailabilityItem.propTypes = {
  enableHoliday: PropTypes.bool,
  onEnableHolidays: PropTypes.func,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errors: PropTypes.object,
  disabled: PropTypes.bool.isRequired
}
AvailabilityItem.defaultProps = {
  enableHoliday: true,
  onEnableHolidays: () => null,
  value: {},
  errors: {}
}
