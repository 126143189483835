/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import findIndex from 'lodash/findIndex'
import { STATUS_ACTIVE, STATUS_INELIGIBLE, STATUS_INACTIVE } from 'constants/entityStatus'
import {
  ACTIVE_OPTION_LABEL,
  INACTIVE_OPTION_LABEL,
  INELIGIBLE_OPTION_LABEL,
  FROM_LABEL
} from './types'
import { isStatusEqual } from './helpers'

import styles from './ProgramUnitsTable.scss'

export function ListStats({ data, checked }) {
  const checkedUnits = useMemo(() => Object.keys(checked).filter(id => Boolean(checked[id])), [
    checked
  ])
  const activeLength = useMemo(() => data.filter(isStatusEqual(STATUS_ACTIVE)).length, [data])
  const inactiveLength = useMemo(() => data.filter(isStatusEqual(STATUS_INACTIVE)).length, [data])
  const ineligibleLength = useMemo(() => data.filter(isStatusEqual(STATUS_INELIGIBLE)).length, [
    data
  ])

  const activeChecked = useMemo(
    () => checkedUnits.filter(id => findIndex(data, { id, status: STATUS_ACTIVE }) !== -1).length,
    [data, checkedUnits]
  )
  const inactiveChecked = useMemo(
    () => checkedUnits.filter(id => findIndex(data, { id, status: STATUS_INACTIVE }) !== -1).length,
    [data, checkedUnits]
  )
  const ineligibleChecked = useMemo(
    () =>
      checkedUnits.filter(id => findIndex(data, { id, status: STATUS_INELIGIBLE }) !== -1).length,
    [data, checkedUnits]
  )

  return (
    <div className={styles.statsWrapper}>
      <ul className={styles.column}>
        <li className={styles.element}>
          <span className={styles.label}>{ACTIVE_OPTION_LABEL}</span>
          <span className={styles.text}>{activeChecked}</span>
          <span className={styles.text}>{FROM_LABEL}</span>
          <span className={styles.text}>{activeLength}</span>
        </li>
      </ul>
      <ul className={styles.column}>
        <li className={styles.element}>
          <span className={styles.label}>{INACTIVE_OPTION_LABEL}</span>
          <span className={styles.text}>{inactiveChecked}</span>
          <span className={styles.text}>{FROM_LABEL}</span>
          <span className={styles.text}>{inactiveLength}</span>
        </li>
        <li className={styles.element}>
          <span className={styles.label}>{INELIGIBLE_OPTION_LABEL}</span>
          <span className={styles.text}>{ineligibleChecked}</span>
          <span className={styles.text}>{FROM_LABEL}</span>
          <span className={styles.text}>{ineligibleLength}</span>
        </li>
      </ul>
    </div>
  )
}
ListStats.propTypes = {
  data: PropTypes.array,
  checked: PropTypes.object
}
ListStats.defaultProps = {
  checked: {},
  data: []
}
