/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Column.scss'

export function Column(props) {
  const { children, className } = props

  return children ? (
    <div className={classNames(styles.columnWrapper, className)}>{children}</div>
  ) : null
}

Column.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

Column.defaultProps = {
  children: null,
  className: ''
}
