/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

export const REGEX_DECIMALS_VALIDATION_FEE = /^(\d{1,2}|100)(\.\d{1,2})?$/
export const REGEX_ENTITY_NAME_PRE_VALIDATOR = /[^a-zA-Z0-9 \-']/g
export const REGEX_NUMBER_VALIDATION = /^[0-9]\d*$/
export const REGEX_DATE_VALIDATION = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.][1-9]\d{3}$/
export const REGEX_EMAIL_VALIDATION = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const REGEX_LATITUDE_VALIDATION = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
export const REGEX_LONGITUDE_VALIDATION = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/
export const REGEX_LINK_VALIDATION = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
export const REGEX_PHONE_VALIDATION = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
export const REGEX_ZIP_CANADA_VALIDATION = /^[A-Za-z]\d[A-Za-z][-/ ]?\d[A-Za-z]\d$/
export const REGEX_ZIP_USA_VALIDATION = /^\d{5}(?:[-\s]\d{4})?$/
export const REGEX_DECIMALS_VALIDATION = /^\d{1,8}(\.\d{2})?$/
export const REGEX_DECIMALS_TWO_DIGIT_REQUIRED_VALIDATION = /^\d{1,8}(\.\d{2})$/
export const REGEX_DECIMALS_ONE_DIGIT_REQUIRED_VALIDATION = /^\d{1,8}(\.\d{1})$/
export const REGEX_DECIMALS_ONE_DIGIT_VALIDATION = /^\d{1,8}(\.\d{1})?$/
export const REGEX_DECIMALS_TWO_DIGIT_VALIDATION = /^[0-9]\d*(\.\d{1,2})?$/
export const REGEX_PHONE_NUMBER_PRE_VALIDATION = /(\d{3})-?(\d{3})-?(\d{4})/
export const REGEX_PHONE_NUMBER_VALIDATION = /^\(?(\d{3})\)?[-]?(\d{3})[-]?(\d{4})$/
export const REGEX_DATE_WITH_WEEK_DAY_VALIDATION = /^(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)\.\s(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/
export const REGEX_DECIMALS_PERIOD_VALIDATION = /^([1-9][0-9]{0,2}|999)$/
export const MULTI_EMAIL_VALIDATION = /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(,)?(\s)?)+$/
export const REGEX_ANY_NUMBER_VALIDATION = /^(-{1})?\d+(\.\d+)?$/
export const REGEX_THEE_TREE_FOUR_PHONE_NUMBER_VALIDATION = /^\(?(\d{3})\)?[-]?(\d{3})[-]?(\d{4})$/
