/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { CollapseBox } from '@xplorie/ui-commons'
import PropTypes from 'prop-types'
import { isCurrentYear } from 'helpers/dateHelpers'
import { usePrevious } from 'hooks'
import { BouncingLoader } from 'components/Loaders'
import { DATA_STATE_NOT_REQUESTED } from 'helpers/actionHelpers'
import { ProgramHistoryItems } from '../ProgramHistoryItems'
import { useHistoryByYear } from '../useProgramHistoryApi'

import styles from './styles.scss'
import { convertHistoryData } from './helpers'

export function ProgramHistorySeason(props) {
  const { year, dataId, filters } = props
  const [{ getHistory }, { data, dataState }, { hasMoreItems, isLoading }] = useHistoryByYear(
    dataId,
    year,
    filters
  )

  const items = useMemo(() => convertHistoryData(data), [data])
  const isInitiallyOpen = isCurrentYear(year)

  const [isOpen, setIsOpen] = useState(isInitiallyOpen)
  const prevOpenState = usePrevious(isOpen)

  const onExpand = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  useEffect(() => {
    if (prevOpenState !== isOpen && isOpen && dataState === DATA_STATE_NOT_REQUESTED) {
      getHistory()
    }
  }, [dataState, getHistory, isOpen, prevOpenState])

  return (
    <CollapseBox
      className={styles.collapseWrapper}
      title={String(year)}
      canExpand
      isOpen={isOpen}
      onExpand={onExpand}
    >
      <ProgramHistoryItems items={items} />
      {isLoading ? (
        <BouncingLoader />
      ) : (
        hasMoreItems && (
          <div className={styles.footer}>
            <a className={styles.showMore} onClick={getHistory}>
              Show More
            </a>
          </div>
        )
      )}
    </CollapseBox>
  )
}

ProgramHistorySeason.propTypes = {
  dataId: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  filters: PropTypes.array
}

ProgramHistorySeason.defaultProps = {
  filters: []
}
