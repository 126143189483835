/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { httpWrapper, buildListParams, buildURLForGettingList } from 'services/http/helpers'
import { ACCORDION_DICTIONARY_URL } from 'endpoints'

export const getAccordionList = options =>
  httpWrapper({
    url: buildURLForGettingList(ACCORDION_DICTIONARY_URL),
    method: 'get',
    params: buildListParams(options)
  })
