/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTable, usePagination, useSortBy, useResizeColumns, useFlexLayout } from 'r-table'
import classNames from 'classnames'
import { DESC, ASC } from 'constants/sortOptions'
import { Pagination } from './Pagination'
import { Loader } from './Loader'
import { useTableScrollReset } from './helpers'
import { DEFAULT_PAGE_SIZE } from './types'
import styles from './ReactTable.scss'

export function ReactTable({
  columns,
  data,
  fetchData,
  itemsPerPage,
  itemsCount,
  pageNumber,
  tableHeader,
  defaultSorting,
  isLoading
}) {
  const perPageSize = useMemo(() => itemsPerPage || DEFAULT_PAGE_SIZE, [itemsPerPage])
  const calcPageCount = useMemo(() => Math.ceil(itemsCount / perPageSize), [
    itemsCount,
    perPageSize
  ])
  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400
    }),
    []
  )
  const defaultSort = useMemo(
    () =>
      defaultSorting ? [{ id: defaultSorting.sort, desc: defaultSorting.order === DESC }] : [],
    [defaultSorting]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy }
  } = useTable(
    {
      initialState: { sortBy: defaultSort },
      pageSize: perPageSize,
      pageIndex: pageNumber - 1,
      pageCount: calcPageCount,
      manualPagination: true,
      manualSortBy: true,
      columns,
      defaultColumn,
      data
    },
    useSortBy,
    usePagination,
    useResizeColumns,
    useFlexLayout
  )
  const { tableRef, resetScroll } = useTableScrollReset()

  useEffect(() => {
    if (fetchData) {
      const options = {
        pageNumber: pageIndex + 1,
        itemsPerPage: pageSize
      }
      const [sortProperty] = sortBy
      if (sortProperty) {
        options.sort = sortProperty.id
        options.order = sortProperty.desc ? DESC : ASC
      }
      fetchData(options)
    }
  }, [fetchData, pageIndex, pageSize, sortBy])

  useEffect(() => {
    resetScroll()
  }, [page, resetScroll])

  return (
    <div className={styles.wrapper}>
      {isLoading && <Loader />}
      <table ref={tableRef} {...getTableProps()} className={styles.table}>
        <thead className={styles.thead}>
          {tableHeader && (
            <tr className={classNames(styles.tr, styles.header)}>
              <th className={styles.th}>{tableHeader}</th>
            </tr>
          )}
          {headerGroups.map(headerGroup => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className={classNames(styles.tr, styles.rowHeaders)}
            >
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps({ title: '' }))}
                  className={classNames(
                    styles.th,
                    { [styles.isSorted]: column.isSorted },
                    { [styles.isSortedDesc]: column.isSortedDesc }
                  )}
                >
                  {column.render('Header')}
                  <div
                    {...(column.getResizerProps && column.getResizerProps())}
                    className={classNames(styles.resizer)}
                  />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className={styles.tbody}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} className={styles.tr}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} className={styles.td}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      <Pagination
        pageIndex={pageIndex}
        nextPage={nextPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        pageSize={pageSize}
        pageCount={pageCount}
        setPageSize={setPageSize}
        gotoPage={gotoPage}
      />
    </div>
  )
}

ReactTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  tableHeader: PropTypes.string,
  defaultSorting: PropTypes.object,
  isLoading: PropTypes.bool
}
ReactTable.defaultProps = {
  tableHeader: null,
  isLoading: false,
  defaultSorting: null
}
