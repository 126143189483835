/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { asField } from 'informed'
import { ConditionallyVisibled, RadioGroupField } from 'components/FormComponents'

export function ConditionalYesNoFieldInputComponent(props) {
  const {
    label,
    fieldState: { value },
    fieldProps,
    disabled,
    ...rest
  } = props

  const onChangeHandler = option => {
    const {
      fieldApi: { setTouched, setValue },
      onChange
    } = props

    setValue(option)
    setTouched()
    if (onChange) {
      onChange(option)
    }
  }
  // sometimes back-end send string instead of Boolean
  const preparedValue = Boolean(value) && value !== 'false'

  return (
    <RadioGroupField {...rest} disabled={disabled}>
      <ConditionallyVisibled
        fieldProps={fieldProps}
        condition={label}
        value={preparedValue}
        onChange={onChangeHandler}
        disabled={disabled}
      />
    </RadioGroupField>
  )
}

ConditionalYesNoFieldInputComponent.propTypes = {
  label: PropTypes.string,
  fieldState: PropTypes.object,
  fieldApi: PropTypes.object,
  onChange: PropTypes.func,
  fieldProps: PropTypes.object,
  disabled: PropTypes.bool
}

ConditionalYesNoFieldInputComponent.defaultProps = {
  label: '',
  fieldState: {},
  fieldApi: {},
  onChange: () => null,
  fieldProps: {},
  disabled: false
}

export const ConditionalYesNoFieldInput = asField(ConditionalYesNoFieldInputComponent)
