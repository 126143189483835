import React from 'react'
import PropTypes from 'prop-types'
import { SystemDetailsField } from 'modules/SystemDetails/SystemDetailsField'
import { SimpleBox } from '@xplorie/ui-commons/lib/index'
import styles from './styles.scss'
import { SYSTEM_DETAILS_LABEL } from './types'
import { getLabel } from './helpers'

export function LocationSystemDetails(props) {
  const { locationCode, locationType } = props
  const label = getLabel(locationType)

  return (
    <SimpleBox className={styles.section}>
      <div className={styles.title}>{SYSTEM_DETAILS_LABEL}</div>
      <SystemDetailsField label={label} value={locationCode} />
    </SimpleBox>
  )
}

LocationSystemDetails.propTypes = {
  locationCode: PropTypes.string.isRequired,
  locationType: PropTypes.string.isRequired
}
