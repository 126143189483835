/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import moment from 'moment'
import findIndex from 'lodash/findIndex'
import sortBy from 'lodash/sortBy'
import { FORMAT } from 'constants/date'
import get from 'lodash/get'
import { dateValidate } from 'helpers/formHelpers'
import groupBy from 'lodash/groupBy'
import {
  START_DATE_VALIDATION_MESSAGE,
  END_DATE_VALIDATION_MESSAGE,
  FILL_START_DATE_MESSAGE,
  FILL_END_DATE_MESSAGE
} from './types'

export const findAvlbIndex = (originalValue, array) =>
  findIndex(array, dateObj => dateObj.id && originalValue.id && originalValue.id === dateObj.id)

export const buildId = () => `f${(+new Date()).toString(16)}${(Math.random() * 1e8).toString(16)}`

export const buildIdsForDateObjects = (array = []) =>
  array.map(value => ({
    ...value,
    id: !value.id ? buildId() : value.id
  }))

export const groupAvailabilityByStartYear = (array = []) => {
  const sortedArray = sortBy(array, item => moment(item.startDate.date, FORMAT).toDate())
  const nanAvailability = sortedArray.filter(
    item =>
      !moment(item.startDate.date, FORMAT).isValid() || !moment(item.endDate.date, FORMAT).isValid()
  )
  const validAvailability = sortedArray.filter(
    item =>
      moment(item.startDate.date, FORMAT).isValid() && moment(item.endDate.date, FORMAT).isValid()
  )
  return [
    nanAvailability,
    groupBy(validAvailability, value => {
      const stringDate = get(value, 'startDate.date', '')
      return moment(stringDate, FORMAT).year()
    })
  ]
}

const validateBeforeOrAfterDate = (momentStartDate, momentEndDate) => {
  if (!momentStartDate.isValid() || !momentEndDate.isValid()) {
    return {
      startDate: !momentStartDate.isValid() ? FILL_START_DATE_MESSAGE : null,
      endDate: !momentEndDate.isValid() ? FILL_END_DATE_MESSAGE : null
    }
  }
  if (moment(momentStartDate).isSameOrAfter(momentEndDate)) {
    return {
      startDate: START_DATE_VALIDATION_MESSAGE,
      endDate: END_DATE_VALIDATION_MESSAGE
    }
  }
  return null
}

const validateElements = (array = []) => {
  const sortedArray = sortBy(array, item => moment(item.startDate.date, FORMAT).toDate())
  return sortedArray.reduce((acc, value, index) => {
    const prevElement = sortedArray[index - 1]
    const prevMomentEndDate = moment(get(prevElement, 'endDate.date'), FORMAT)
    const startDate = get(value, 'startDate.date', null)
    const endDate = get(value, 'endDate.date', null)
    const momentStartDate = moment(startDate, FORMAT)
    const momentEndDate = moment(endDate, FORMAT)
    const startDateError = dateValidate(startDate)
    const endDateError = dateValidate(endDate)
    if (startDateError || endDateError) {
      return {
        ...acc,
        [value.id]: {
          id: value.id,
          startDate: startDateError,
          endDate: endDateError
        }
      }
    }
    const beforeAfterError = validateBeforeOrAfterDate(momentStartDate, momentEndDate)
    if (beforeAfterError) {
      return {
        ...acc,
        [value.id]: {
          id: value.id,
          ...beforeAfterError
        }
      }
    }
    if (prevElement) {
      if (prevMomentEndDate.isValid() && moment(prevMomentEndDate).isSameOrAfter(momentStartDate)) {
        return {
          ...acc,
          [value.id]: {
            id: value.id,
            startDate: START_DATE_VALIDATION_MESSAGE
          }
        }
      }
    }
    return acc
  }, {})
}

export const validateDate = ({ value, fieldApi }) => {
  const validationErrors = validateElements(value)
  if (validationErrors && Object.keys(validationErrors).length > 0) {
    fieldApi.setError(validationErrors)
    return 'has errors'
  }
  fieldApi.setError()
  return 'success'
}

export const getYearFromDate = value => {
  const stringDate = get(value, 'startDate.date', '')
  return moment(stringDate, FORMAT).year()
}
