/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withAuthContext } from 'helpers/withAuthContext'

import { actions, getAPLifeCycleById } from 'reducers/activityProvider/apLifeCycle'

import { ActivateButton as ActivateButtonComponent } from './ActivateButton'
import { DeactivateButton as DeactivateButtonComponent } from './DeactivateButton'

export const mapStateToProps = (state, { dataId }) => {
  const apState = getAPLifeCycleById(state, dataId)
  return {
    ...apState
  }
}

export const mapDispatchToProps = dispatch => bindActionCreators({ ...actions }, dispatch)

export const ActivateButton = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ActivateButtonComponent)

export const DeactivateButton = compose(
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DeactivateButtonComponent)
