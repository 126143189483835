/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { PROGRAM } from 'constants/searchTypes'
import { AND_OPERATOR, buildSearchColumn, LIKE_START_WITH_MODE } from 'helpers/searchHelpers'
import {
  STATUS_ACTIVE,
  STATUS_ARCHIVED,
  STATUS_DRAFT,
  STATUS_INACTIVE,
  STATUS_PENDING
} from 'constants/entityStatus'

export const TITLE_PAGE = 'Programs'
export const ADD_ENTITY_BUTTON = 'Create New Program'
export const ACTIVE_LABEL = 'Active'
export const INACTIVE_LABEL = 'Inactive'

export const ACTIVE_FILTER_KEY = 'ACTIVE_FILTER_KEY'

export const INACTIVE_FILTER_KEY = 'INACTIVE_FILTER_KEY'

export const EXPORT_FILENAME = 'program_list.csv'

export const PROGRAM_INITIAL_FILTERS = {
  [ACTIVE_FILTER_KEY]: true,
  [INACTIVE_FILTER_KEY]: false
}

export const PROGRAM_FORM_FILTERS = [
  { label: ACTIVE_LABEL, field: ACTIVE_FILTER_KEY },
  { label: INACTIVE_LABEL, field: INACTIVE_FILTER_KEY }
]

export const ACTIVE_FILTER = buildSearchColumn({
  entity: PROGRAM,
  field: 'status',
  operator: AND_OPERATOR,
  keyword: STATUS_ACTIVE,
  mode: LIKE_START_WITH_MODE
})

export const INACTIVE_FILTER = buildSearchColumn({
  entity: PROGRAM,
  field: 'status',
  operator: AND_OPERATOR,
  keyword: STATUS_INACTIVE,
  mode: LIKE_START_WITH_MODE
})

export const IGNORE_ACTIVE_FILTER = buildSearchColumn({
  entity: PROGRAM,
  field: 'status',
  keyword: STATUS_ACTIVE,
  ignore: true,
  operator: AND_OPERATOR
})

export const IGNORE_INACTIVE_FILTER = buildSearchColumn({
  entity: PROGRAM,
  field: 'status',
  keyword: STATUS_INACTIVE,
  ignore: true,
  operator: AND_OPERATOR
})

export const IGNORE_PENDING_FILTER = buildSearchColumn({
  entity: PROGRAM,
  field: 'status',
  keyword: STATUS_PENDING,
  ignore: true,
  operator: AND_OPERATOR
})

export const IGNORE_DRAFT_FILTER = buildSearchColumn({
  entity: PROGRAM,
  field: 'status',
  keyword: STATUS_DRAFT,
  ignore: true,
  operator: AND_OPERATOR
})

export const IGNORE_ARCHIVED_FILTER = buildSearchColumn({
  entity: PROGRAM,
  field: 'status',
  keyword: STATUS_ARCHIVED,
  ignore: true,
  operator: AND_OPERATOR
})
