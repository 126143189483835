/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { NAME, RESET_COMMUNICATION, CHECK_RESET_PROGRESS } from './types'

const REQUEST_RESET_COMMUNICATION = `${REQUEST_TYPE}_${RESET_COMMUNICATION}`
const RESPONSE_RESET_COMMUNICATION = `${RESPONSE_TYPE}_${RESET_COMMUNICATION}`
const RESPONSE_RESET_COMMUNICATION_ERROR = `${RESPONSE_ERROR_TYPE}_${RESET_COMMUNICATION}`

const REQUEST_CHECK_RESET_PROGRESS = `${REQUEST_TYPE}_${CHECK_RESET_PROGRESS}`
const RESPONSE_CHECK_RESET_PROGRESS = `${RESPONSE_TYPE}_${CHECK_RESET_PROGRESS}`
const RESPONSE_CHECK_RESET_PROGRESS_ERROR = `${RESPONSE_ERROR_TYPE}_${CHECK_RESET_PROGRESS}`

const RESET_DATA_STATE = `${RESET_STATE}_${NAME}`

export default {
  REQUEST_RESET_COMMUNICATION,
  RESPONSE_RESET_COMMUNICATION,
  RESPONSE_RESET_COMMUNICATION_ERROR,
  REQUEST_CHECK_RESET_PROGRESS,
  RESPONSE_CHECK_RESET_PROGRESS,
  RESPONSE_CHECK_RESET_PROGRESS_ERROR,
  RESET_DATA_STATE
}
