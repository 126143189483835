/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'

export function UploadCloudIcon(props) {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path d="M56.25 95.8333H43.75C41.45 95.8333 39.5833 93.9625 39.5833 91.6667V70.8333H29.1666C28.325 70.8333 27.5625 70.325 27.2416 69.5458C26.9208 68.7667 27.1 67.8708 27.6916 67.275L48.525 46.4417C49.3375 45.6292 50.6583 45.6292 51.4708 46.4417L72.3042 67.275C72.9 67.8708 73.0792 68.7667 72.7542 69.5458C72.4292 70.325 71.675 70.8333 70.8333 70.8333H60.4166V91.6667C60.4166 93.9625 58.55 95.8333 56.25 95.8333ZM34.1958 66.6667H41.6666C42.8166 66.6667 43.75 67.6 43.75 68.75V91.6667H56.25V68.75C56.25 67.6 57.1833 66.6667 58.3333 66.6667H65.8042L50 50.8625L34.1958 66.6667Z" />
        <path d="M18.7542 70.7083C18.675 70.7083 18.6 70.7042 18.5167 70.6958C7.9625 69.525 0 60.625 0 50C0 38.9917 8.57917 29.9542 19.4 29.2167C22.2833 14.8167 35.1375 4.16668 50 4.16668C61.6708 4.16668 72.4208 10.7417 77.7208 21.0167C90.3125 22.3375 100 33.0083 100 45.8333C100 57 92.5167 66.875 81.8083 69.8417C80.6917 70.1458 79.55 69.4958 79.2458 68.3875C78.9375 67.2792 79.5875 66.1292 80.6958 65.825C89.6083 63.3583 95.8333 55.1333 95.8333 45.8333C95.8333 34.8042 87.2375 25.6875 76.2625 25.0792C75.4917 25.0375 74.8125 24.575 74.4875 23.8792C70.0792 14.4375 60.4708 8.33334 50 8.33334C36.6042 8.33334 25.0958 18.3125 23.2292 31.5417C23.0833 32.5667 22.2042 33.3333 21.1667 33.3333C11.6417 33.3333 4.16667 40.8083 4.16667 50C4.16667 58.5 10.5333 65.6167 18.9833 66.5542C20.125 66.6833 20.95 67.7125 20.8208 68.8542C20.7042 69.9208 19.8 70.7083 18.7542 70.7083Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
