/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { useLoading } from 'hooks'

import { PIECurtainLoader } from 'components/Loaders'
import { BasicButton } from '@xplorie/ui-commons'

import { ORANGE_SCHEMA } from 'constants/buttonsOptions'
import { ADMIN_LEVEL } from 'constants/permissionTypes'
import { STATUS_INACTIVE } from 'constants/entityStatus'

import { isActionAllowed } from 'helpers/permissionHelper'

import { AppActivationModal } from 'modules/ActivityProducts'

import { BUTTON_TITLE } from './types'

export function ActivateButton(props) {
  const { activationDataState, activateAPPById, authUser, dataId, entityData } = props

  const [isOpenModal, setIsModalState] = useState(false)
  const [isProccesing] = useLoading(activationDataState)
  const isAdmin = isActionAllowed(ADMIN_LEVEL, authUser)
  const status = entityData && entityData.status
  const appName = entityData && entityData.name

  const isDisabled = isProccesing || !isAdmin || !dataId || status !== STATUS_INACTIVE

  const onToggleModalHandler = useCallback(() => {
    setIsModalState(!isOpenModal)
  }, [isOpenModal])

  const onActivateActivity = useCallback(() => {
    if (activateAPPById && dataId) {
      activateAPPById(dataId)
    }
    onToggleModalHandler()
  }, [activateAPPById, dataId, onToggleModalHandler])

  return (
    <>
      <BasicButton colorSchema={ORANGE_SCHEMA} disabled={isDisabled} onClick={onToggleModalHandler}>
        {BUTTON_TITLE}
      </BasicButton>
      <AppActivationModal
        isOpen={isOpenModal}
        onSubmit={onActivateActivity}
        onHide={onToggleModalHandler}
        appName={appName}
      />
      {isProccesing && <PIECurtainLoader />}
    </>
  )
}

ActivateButton.propTypes = {
  authUser: PropTypes.object.isRequired,
  activationDataState: PropTypes.string.isRequired,
  activateAPPById: PropTypes.func.isRequired,
  entityData: PropTypes.object,
  dataId: PropTypes.string
}

ActivateButton.defaultProps = {
  dataId: null,
  entityData: {}
}
