import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { DATA_STATE_RECIEVED, DATA_STATE_REQUESTING } from 'helpers/actionHelpers'
import { InvalidPage } from './InvalidPage'
import { PasswordRecoveryPage } from './PasswordRecoveryPage'

export function PasswordRecovery({
  tokenError,
  checkRecoveryToken,
  recoverPassword,
  resetDataState,
  recoveryPasswordDataState,
  recoveryTokenDataState
}) {
  const recoveryToken = useParams()

  const isTokenError = recoveryTokenDataState === DATA_STATE_RECIEVED && tokenError
  const isLoading = recoveryTokenDataState === DATA_STATE_REQUESTING

  useEffect(() => {
    checkRecoveryToken(recoveryToken)
  }, [])

  if (isTokenError) {
    return <InvalidPage tokenError={tokenError} />
  }

  return (
    <>
      {!isLoading && (
        <PasswordRecoveryPage
          recoveryToken={recoveryToken}
          recoverPassword={recoverPassword}
          recoveryPasswordDataState={recoveryPasswordDataState}
          resetDataState={resetDataState}
        />
      )}
    </>
  )
}

PasswordRecovery.propTypes = {
  checkRecoveryToken: PropTypes.func.isRequired,
  tokenError: PropTypes.object.isRequired,
  recoveryTokenDataState: PropTypes.string.isRequired,
  recoveryPasswordDataState: PropTypes.string.isRequired,
  recoverPassword: PropTypes.func.isRequired,
  resetDataState: PropTypes.func.isRequired
}

PasswordRecovery.defaultProps = {
  tokenError: null,
}
