/*
 * COPYRIGHT:     Copyright © 2021 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const FIELD_NAME_LABEL = 'Field Name'
export const PREVIOUS_VALUE_LABEL = 'Previous Value'
export const NEXT_VALUE_LABEL = 'Next Value'
export const PREVIOUS_VALUE_STATUS_LABEL = 'Previous Value / Status'
export const NEXT_VALUE_LABEL_STATUS = 'Next Value / Status'
export const UNIT_NAME_LABEL = 'Unit Name'

export const ADDED_VALUE_LABEL = 'Added Value'
export const DELETED_VALUE_LABEL = 'Deleted Value'
export const ACTIVITIES_LABEL = 'Activities'
export const EXCLUSIVE_OFFER_ACTIVITY_LABEL = 'Exclusive Offer Activity'
export const ACTIVITY_NAME_LABEL = 'Activity Name'

export const STATUS_LABEL = 'Status'
export const REMOVED_LABEL = 'removed'
export const ADDED_LABEL = 'added'
export const SCHEDULED_LABEL = 'scheduled'
export const CANCELED_LABEL = 'canceled'

export const ACTION_CREATE = 'CREATE'
export const ACTION_DELETE = 'DELETE'
export const ACTION_UPDATE = 'UPDATE'
export const ACTION_EXCLUDE = 'EXCLUDE'
export const ACTION_INCLUDE = 'INCLUDE'
export const ACTION_CANCELED = 'CANCELED'
export const ACTION_ACTIVATE = 'ACTIVATE'
export const ACTION_DEACTIVATE = 'DEACTIVATE'
export const ACTION_APPROVE = 'APPROVE'

export const EQUALS_MODE_LABEL = 'Equals to'
export const NOT_EQUALS_MODE_LABEL = 'Not Equals to'
export const MORE_OR_EQUALS_MODE_LABEL = 'More than or Equals to'
export const LESS_OR_EQUALS_MODE_LABEL = 'Less than or Equals to'
export const CONTAINS_MODE_LABEL = 'Contains'
export const NOT_CONTAINS_MODE_LABEL = 'Not Contains'
export const PERIOD_MODE_LABEL = 'Period'

export const CHARGE_NIGHT_VALUE = 'NIGHT'
export const CHARGE_NIGHT_VALUE_LABEL = 'Per Night'
export const CHARGE_STAY_VALUE = 'STAY'
export const CHARGE_STAY_VALUE_LABEL = 'Per Stay'
export const CHARGE_UNIT_VALUE = 'UNIT'
export const CHARGE_UNIT_VALUE_LABEL = 'Per Unit'
