/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import helpers from './helpers'

const { SET_MODE_DISABLE, SET_MODE_VALUE, RESET_DATA_STATE } = helpers

export const initialState = { isModeDisabled: false, isModeEnabled: false }

const resellTestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODE_VALUE:
      return state.isModeDisabled ? state : { ...state, isModeEnabled: action.payload }
    case SET_MODE_DISABLE:
      return { ...state, isModeDisabled: action.payload }
    case RESET_DATA_STATE:
      return { ...initialState }
    default:
      return state
  }
}

export default filterActions(resellTestReducer, [
  SET_MODE_DISABLE,
  SET_MODE_VALUE,
  RESET_DATA_STATE
])
