/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { pmcReportColumns, activityProviderReportsColumns } from 'helpers/dataTablesHelpers'
import { PM_COMPANY, ACTIVITY } from 'constants/searchTypes'

export const buildListDataByType = (type, props) => {
  const { onGetPMCReport, onGetAPPReport } = props

  switch (type) {
    case PM_COMPANY:
      return {
        columns: pmcReportColumns,
        header: 'PMC Masterlist',
        getList: onGetPMCReport
      }
    case ACTIVITY:
      return {
        columns: activityProviderReportsColumns,
        header: 'APP Masterlist',
        getList: onGetAPPReport
      }
    default:
      return {
        columns: pmcReportColumns,
        header: 'PMC Masterlist',
        getList: onGetPMCReport
      }
  }
}
