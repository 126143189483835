/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

export const FIRST_NAME_LABEL = 'First Name'
export const LAST_NAME_LABEL = 'Last Name'
export const EMAIL_ADDRESS_LABEL = 'Email Address'
export const ROLE_LABEL = 'Role'
