/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDidMount, useLoading, useSliderApi } from 'hooks'
import get from 'lodash/get'
import { BasicModal } from 'components/Modals'
import { PIECurtainLoader } from 'components/Loaders'
import { PMC } from 'constants/formCodes'
import { DeactivateWarning } from './DeactivateWarning'
import { DeactivateModal } from './DeactivateModal'
import { MessageContent } from './MessageContent'
import {
  CONFIRMATION_MODAL_TYPE,
  DEACTIVATE_MODAL_TYPE,
  MODAL_TITLE,
  PROGRAM_BECOME_INACTIVE_MESSAGE,
  QUESTION_MARK,
  SELECT_DATE_DEACTIVATE_MESSAGE,
  WARNING_DEACTIVATION_MESSAGE_END,
  WARNING_DEACTIVATION_MESSAGE_START,
  WARNING_MESSAGE,
  WARNING_MODAL_TYPE
} from './types'
import { SET_DEACTIVATION_MODE, UPDATE_DATE_MODE } from '../types'

export function ConfirmationModal(props) {
  const {
    name,
    getApi,
    deactivationInfo,
    deactivationUnassignUnits,
    onAction,
    onCancel,
    deactivateProgramDataState,
    pmcData,
    mode
  } = props
  const [isOpen, setIsOpenState] = useState(false)
  const [modalType, setModalTypeState] = useState(CONFIRMATION_MODAL_TYPE)
  const [isDeactivating] = useLoading(deactivateProgramDataState)
  const pmcSliderApi = useSliderApi(PMC)

  const toggleModal = useCallback(() => {
    setIsOpenState(!isOpen)
  }, [isOpen])

  const closeModal = useCallback(() => {
    setIsOpenState(false)
  }, [])

  const onClickViewPmcHandler = useCallback(() => {
    toggleModal()
    const pmcId = get(pmcData, 'id')
    const shortPmcName = get(pmcData, 'shortName')
    pmcSliderApi.open(pmcId, { headerProps: { title: shortPmcName } })
  }, [pmcData, pmcSliderApi, toggleModal])

  const onSetDeactivationStep = useCallback(() => {
    setModalTypeState(DEACTIVATE_MODAL_TYPE)
  }, [])

  const renderModalBodyByModalType = useCallback(() => {
    switch (modalType) {
      case CONFIRMATION_MODAL_TYPE:
        return (
          <DeactivateWarning
            Message={
              <MessageContent
                name={name}
                startMessage={WARNING_MESSAGE}
                endMessage={QUESTION_MARK}
              />
            }
            onAction={onSetDeactivationStep}
            onClose={toggleModal}
          />
        )
      case DEACTIVATE_MODAL_TYPE:
        return (
          <DeactivateModal
            Message={
              <MessageContent
                name={name}
                startMessage={SELECT_DATE_DEACTIVATE_MESSAGE}
                endMessage={PROGRAM_BECOME_INACTIVE_MESSAGE}
              />
            }
            onAction={onAction}
            onCancel={onCancel}
            onClose={toggleModal}
            initialValues={{
              date: deactivationInfo,
              deactivationUnassignUnits
            }}
            mode={mode}
          />
        )
      case WARNING_MODAL_TYPE:
        return (
          <DeactivateWarning
            Message={
              <MessageContent
                name={pmcData.name}
                startMessage={WARNING_DEACTIVATION_MESSAGE_START}
                endMessage={WARNING_DEACTIVATION_MESSAGE_END}
              />
            }
            onAction={onClickViewPmcHandler}
            onClose={toggleModal}
          />
        )
      default:
        throw new Error('incorrect modal type')
    }
  }, [
    deactivationInfo,
    deactivationUnassignUnits,
    modalType,
    mode,
    name,
    onAction,
    onCancel,
    onClickViewPmcHandler,
    onSetDeactivationStep,
    pmcData,
    toggleModal
  ])

  useEffect(() => {
    const pmcDeactivationDate = get(pmcData, 'deactivationDate.date')
    if (deactivationInfo) {
      const initialModalType =
        pmcDeactivationDate && mode === UPDATE_DATE_MODE
          ? WARNING_MODAL_TYPE
          : DEACTIVATE_MODAL_TYPE
      setModalTypeState(initialModalType)
    }
  }, [deactivationInfo, modalType, mode, pmcData])

  useDidMount(() => {
    if (getApi) {
      getApi({ toggleModal, closeModal })
    }
  })

  return (
    <>
      <BasicModal
        isOpen={isOpen}
        title={MODAL_TITLE}
        onClose={toggleModal}
        renderBody={renderModalBodyByModalType}
      />
      {isDeactivating && <PIECurtainLoader />}
    </>
  )
}

ConfirmationModal.propTypes = {
  name: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  getPMCItemById: PropTypes.func.isRequired,
  getApi: PropTypes.func,
  deactivationInfo: PropTypes.shape({
    date: PropTypes.string,
    description: PropTypes.string
  }),
  deactivationUnassignUnits: PropTypes.bool,
  pmcData: PropTypes.object,
  deactivateProgramDataState: PropTypes.string.isRequired,
  mode: PropTypes.string
}
ConfirmationModal.defaultProps = {
  getApi: null,
  deactivationInfo: {},
  deactivationUnassignUnits: false,
  pmcData: null,
  mode: SET_DEACTIVATION_MODE
}
