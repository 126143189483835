/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useContext, useRef, useState } from 'react'
import { useDidMount } from 'hooks'
import get from 'lodash/get'
import { DiscountContext } from '../helpers'

import {
  convertContractToPeriodsFormValues,
  createDealPeriod,
  getDealAvailabilities,
  getDefaultAvailabilities,
  prepareDealPeriodsPayload
} from './helpers'
import { useDealCalendarApiBuilder } from './DiscountCalendar'

export function useDealPeriodsApi() {
  const { api } = useContext(DiscountContext)

  return api.dealPeriods
}

export function useDealPeriodsState() {
  const { state } = useContext(DiscountContext)

  return state.periodsFormState
}

export function useDealPeriodsApiBuilder() {
  const dealPeriodsApi = useRef()

  const [, setState] = useState(0)
  const update = () => setState(_ => _ + 1)

  const validateForm = async () => {
    await dealPeriodsApi.current.submitForm()
    const formState = dealPeriodsApi.current.getState()
    return formState.invalid
  }

  const getApi = formApi => {
    dealPeriodsApi.current = formApi
  }

  const dealFormState = dealPeriodsApi.current ? dealPeriodsApi.current.getState() : {}
  const dealFormValues = get(dealFormState, 'values', {})
  const availabilities = getDealAvailabilities(dealFormValues)

  const setContractValues = data => {
    const values = convertContractToPeriodsFormValues(data)
    dealPeriodsApi.current.setState({ values })
    update()
  }

  const setContractAvailabilities = data => setContractValues({ availabilities: data })

  const getPeriods = item => Object.values(item)

  const setAvailabilities = data => {
    const values = { availabilities: data }
    dealPeriodsApi.current.setState({ values })
    update()
  }

  const [calendarState, calendarApi] = useDealCalendarApiBuilder({
    availabilities,
    setAvailabilities: setContractAvailabilities
  })

  useDidMount(() => {
    dealPeriodsApi.current.setState({ values: getDefaultAvailabilities() })
  })

  const onAdd = () => {
    setAvailabilities({
      ...availabilities,
      ...createDealPeriod()
    })
  }

  const onRemove = id => {
    delete availabilities[id]
    setAvailabilities({
      ...availabilities
    })
  }

  const onUpdate = period => {
    setAvailabilities({
      ...availabilities,
      [period.id]: period
    })
    validateForm()
  }

  const canSubmit = () => !dealFormValues.invalid

  const submit = async () => {
    await dealPeriodsApi.current.submitForm()
    const formState = dealPeriodsApi.current.getState()

    if (formState.invalid) {
      return null
    }
    return prepareDealPeriodsPayload(formState.values)
  }

  return [
    {
      ...dealFormState,
      calendarState
    },
    {
      getApi,
      setValues: setContractValues,
      getPeriods,
      onAdd,
      onUpdate,
      onRemove,
      submit,
      canSubmit,
      validateForm,
      calendarApi
    }
  ]
}
