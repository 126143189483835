/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { defaultContract } from './reducer'

export const getColumnSearchState = state => state.search.columnSearch
export const getColumnSearchStateByType = (state, type) =>
  state.search.columnSearch[type] || defaultContract
