/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { COUNTRIES_TREE } from './types'

const REQUEST_LIST = `${REQUEST_TYPE}_${COUNTRIES_TREE}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${COUNTRIES_TREE}`
const RESPONSE_LIST_ERROR = `${RESPONSE_ERROR_TYPE}_${COUNTRIES_TREE}`
const RESET_DATA_STATE = `${RESET_STATE}_${COUNTRIES_TREE}`

const buildStatesList = defaultStatesData =>
  defaultStatesData.map(item => ({
    value: item.location.altName,
    label: item.location.altName
  }))

const buildStatesTreeByCountries = defaultData =>
  defaultData.map(item => ({
    countryName: item.location.name,
    countryAltName: item.location.altName,
    key: item.location.id,
    states: buildStatesList(item.children)
  }))

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_LIST_ERROR,
  RESET_DATA_STATE,
  buildStatesTreeByCountries
}
