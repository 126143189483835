/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import { RoundedLabel } from 'shared'
import styles from './SliderRoundedLabel.scss'

export function SliderRoundedLabel({ ...rest }) {
  return <RoundedLabel className={styles.label} {...rest} />
}
