/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
const NAME = 'PROGRAM_UNITS'
const ASSIGN_UNITS = `${NAME}_ASSIGN_UNITS`
const UNASSIGN_UNITS = `${NAME}_UNASSIGN_UNITS`

export default {
  NAME,
  UNASSIGN_UNITS,
  ASSIGN_UNITS
}
