/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { actions } from 'reducers/editQueue'
import { actions as accountListActions } from 'reducers/account/accountList'
import { withAuthContext } from 'helpers/withAuthContext'
import withAuthorization from 'helpers/withAuthorization'
import Account from './Accounts'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
  const { getAccountList } = accountListActions

  return bindActionCreators(
    {
      ...actions,
      onGetData: getAccountList
    },
    dispatch
  )
}

const AccountsConnect = compose(
  withAuthorization,
  withAuthContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Account)

export default AccountsConnect
