/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { PENDING_DISABLE, PENDING_ENABLE } from 'constants/smartHostStatus'
import { PENDING_DISABLE_LABEL, PENDING_ENABLE_LABEL } from '../types'

export function getLabelByStatus(status) {
  switch (status) {
    case PENDING_DISABLE:
      return PENDING_DISABLE_LABEL
    case PENDING_ENABLE:
      return PENDING_ENABLE_LABEL
    default:
      return null
  }
}
