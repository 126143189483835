/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { getNotifOption } from './helpers'

import styles from './Notification.scss'

export function Notification(props) {
  const { message, kind } = props

  const dismiss = () => {
    const { id, onActionClick } = props
    onActionClick(id)
  }

  const Icon = getNotifOption(kind).component
  const titleNotification = getNotifOption(kind).title

  return (
    <div className={classNames(styles.alertNotification, styles[kind])}>
      {Icon && (
        <div className={styles.iconWrapper}>
          <Icon />
        </div>
      )}
      <div className={styles.messageWrapper}>
        <div className={styles.titleNotif}>{titleNotification}</div>
        <span className={styles.message}>{message}</span>
      </div>
      <button className={styles.btnClose} onClick={dismiss} />
    </div>
  )
}

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  onActionClick: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired
}
