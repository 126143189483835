/*
 * COPYRIGHT:     Copyright © 2019 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Text } from 'informed'
import isArray from 'lodash/isArray'
import classNames from 'classnames'

import { CustomNestedField } from 'components/FormComponents'
import { BicycleIcon } from 'icons'
import get from 'lodash/get'
import { RoundRadioButton } from '@xplorie/ui-commons'

import { TicketTypesField } from './TicketTypesField'
import { Row } from './Row'
import { tableHeadData } from './types'
import { ticketTypesValidation } from './helpers'

import styles from './ActivityTable.scss'

export function ActivityTable(props) {
  const { formState, formApi, editMode, disabled, renderControl } = props
  const name = get(formState, 'values.activity.name')
  const ticketTypes = get(formState, 'values.ticketTypes', [])
  const [isCheckedAll, setCheckedAll] = useState(false)

  useEffect(() => {
    const newTicketTypes = get(formState, 'values.ticketTypes', [])
    if (isArray(newTicketTypes)) {
      const isAllIncluded = newTicketTypes.every(value => value.included)
      if (isAllIncluded !== isCheckedAll) {
        setCheckedAll(isAllIncluded)
      }
    }
  }, [formState])

  const onChecked = () => {
    if (editMode) {
      const currentState = formApi.getState()
      const newTicketTypes = get(currentState, 'values.ticketTypes', [])
      if (isArray(newTicketTypes)) {
        newTicketTypes.forEach((value, index) => {
          const key = `ticketTypes[${index}].included`
          if (formApi.fieldExists(key)) {
            formApi.setValue(key, !isCheckedAll)
          }
          setCheckedAll(!isCheckedAll)
        })
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <BicycleIcon className={styles.icon} />
        <span className={styles.name}>{name}</span>
        {renderControl()}
      </div>
      <div className={styles.content}>
        <Text field="activity" type="hidden" />
        <div className={styles.rowWrapper}>
          <div className={classNames(styles.column, styles.checkBoxStyle)}>
            <RoundRadioButton
              disabled={!editMode || disabled}
              value={isCheckedAll}
              isActive={isCheckedAll}
              id={`checkbox-${name}`}
              onChange={onChecked}
              isSquareTypes
              type="checkbox"
            />
          </div>
          {tableHeadData.map((itemColumnValue, index) => (
            <div
              key={`item-column-${itemColumnValue}`}
              className={classNames(styles.column, { [styles.name]: !index })}
            >
              <span className={styles.value}>{itemColumnValue}</span>
            </div>
          ))}
        </div>
        {ticketTypes.map((value, index) => {
          const prefixIndex = `ticketTypes[${index}]`
          const errorState = get(formState, `errors.ticketTypes[${index}]`, {})

          return (
            <CustomNestedField field={prefixIndex} key={get(value, 'ticketType.id')}>
              <Row
                {...value}
                editMode={editMode}
                disabled={disabled}
                errorsState={errorState}
                prefix={prefixIndex}
                formApi={formApi}
              />
            </CustomNestedField>
          )
        })}
        <TicketTypesField field="ticketTypes" type="hidden" validate={ticketTypesValidation} />
      </div>
    </div>
  )
}

ActivityTable.propTypes = {
  name: PropTypes.string.isRequired,
  renderControl: PropTypes.func,
  ticketTypes: PropTypes.array,
  formState: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
  disabled: PropTypes.bool,
  formApi: PropTypes.object.isRequired
}

ActivityTable.defaultProps = {
  renderControl: () => null,
  ticketTypes: [],
  editMode: false,
  disabled: false
}
