/*
 * COPYRIGHT:     Copyright © 2022 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { find } from 'lodash'
import { ALL_PROGRAMS_OPTION, ASSIGMENT_TYPES, DEFAULT_OPTIONS, NONE_OPTION } from './types'

export const mapValuesToOptions = programsData => {
  if (!programsData) {
    return DEFAULT_OPTIONS
  }

  const preparedOptions = programsData.map(program => ({
    key: program.id,
    label: program.name,
    value: {
      id: program.id,
      name: program.name
    }
  }))
  return [...DEFAULT_OPTIONS, ...preparedOptions]
}

export const checkDublicateProgram = (newProgram, programs) =>
  find(programs, prog => prog.id === newProgram.id)

export const getProgramOptionByAssigmentType = assigmentType => {
  switch (assigmentType) {
    case ASSIGMENT_TYPES.NONE:
      return NONE_OPTION
    case ASSIGMENT_TYPES.ALL:
      return ALL_PROGRAMS_OPTION
    case ASSIGMENT_TYPES.SPECIFIC:
    default:
      return null
  }
}
