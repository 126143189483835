/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { httpWrapper, buildListParams, buildURLForGettingList } from 'services/http/helpers'
import { LOCATIONS_URL } from 'endpoints'
import { buildURLForLocationTree } from './helpers'

export const getList = (authKey, options) =>
  httpWrapper({
    url: buildURLForGettingList(LOCATIONS_URL),
    method: 'get',
    params: buildListParams(options)
  })

export const getLocationTree = (authKey, options) =>
  httpWrapper({
    url: buildURLForLocationTree(),
    method: 'get',
    params: buildListParams(options)
  })
