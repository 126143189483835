/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { useRef } from 'react'
import { findDOMNode } from 'react-dom'
import { DEFAULT_PAGE_SIZE_OPTIONS } from './types'

export const buildPageSizeOptions = (data, itemsPerPage) =>
  data && data.length > 0 ? DEFAULT_PAGE_SIZE_OPTIONS : [itemsPerPage]

export const resetTableScrollByRef = tableRef => {
  const table = findDOMNode(tableRef.current)
  const tableBody = table.querySelector('.rt-tbody')
  if (tableBody) {
    tableBody.scrollTo(0, 0)
  }
}

export const useTableScrollReset = () => {
  const tableRef = useRef(null)

  const resetScroll = () => {
    resetTableScrollByRef(tableRef)
  }

  return {
    tableRef,
    resetScroll
  }
}
