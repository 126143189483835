/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import types from './types'

const { LIST_NAME } = types

const REQUEST_LIST = `${REQUEST_TYPE}_${LIST_NAME}`
const RESPONSE_LIST = `${RESPONSE_TYPE}_${LIST_NAME}`
const RESPONSE_ERROR_LIST = `${RESPONSE_ERROR_TYPE}_${LIST_NAME}`
const RESET_DATA_STATE = `${RESET_STATE}_${LIST_NAME}`

export default {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE
}
