/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part,
 *                is prohibited.
 */

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import {
  actions as keywordSearchActions,
  getKeywordSearchStateByType
} from 'reducers/search/keywordSearch'
import { actions as searchActions, getColumnSearchStateByType } from 'reducers/search/columnSearch'
import { withAuthContext } from 'helpers/withAuthContext'
import { withFormContext } from 'components/FormComponents/helpers'
import { SEARCH_KEY } from 'modules/LocationsField/types'
import { PMCField as PMCFieldComponent } from './PMCField'
import { processLocationResult } from './helpers'
import { SEARCH_PMC_KEY } from '../types'

const mapStateToProps = state => {
  const locations = getColumnSearchStateByType(state, SEARCH_KEY)

  return {
    locationListOptions: processLocationResult(locations.data),
    locationsState: locations.dataState,
    pmcSearchResult: getKeywordSearchStateByType(state, SEARCH_PMC_KEY).data
  }
}

const mapDispatchToProps = dispatch => {
  const { searchByType, resetStateByType } = keywordSearchActions
  const { searchByType: searchLocations } = searchActions

  return bindActionCreators(
    {
      clearPMCSearchResult: resetStateByType.bind(null, SEARCH_PMC_KEY),
      searchPMCByKeyword: searchByType.bind(null, SEARCH_PMC_KEY),
      getLocationsList: searchLocations.bind(null, SEARCH_KEY)
    },
    dispatch
  )
}

export const PMCField = compose(
  withAuthContext,
  withFormContext,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PMCFieldComponent)
