/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import {
  REQUEST_TYPE,
  RESPONSE_TYPE,
  RESPONSE_ERROR_TYPE,
  RESET_STATE
} from 'helpers/actionHelpers'

import { FORM_NAME, ASSIGN_GROUPS, FILL_TICKET_TYPES, GET_ACTIVITIES_BY_DEAL_ID } from './types'

const RESET_DATA_STATE = `${RESET_STATE}_${FORM_NAME}`

const REQUEST_ASSIGN_GROUPS = `${REQUEST_TYPE}_${ASSIGN_GROUPS}`
const RESPONSE_ASSIGN_GROUPS = `${RESPONSE_TYPE}_${ASSIGN_GROUPS}`
const RESPONSE_ASSIGN_GROUPS_ERROR = `${RESPONSE_ERROR_TYPE}_${ASSIGN_GROUPS}`

const REQUEST_FILL_TICKET_TYPES = `${REQUEST_TYPE}_${FILL_TICKET_TYPES}`
const RESPONSE_FILL_TICKET_TYPES = `${RESPONSE_TYPE}_${FILL_TICKET_TYPES}`
const RESPONSE_FILL_TICKET_TYPES_ERROR = `${RESPONSE_ERROR_TYPE}_${FILL_TICKET_TYPES}`

const REQUEST_GET_ACTIVITIES_BY_DEAL_ID = `${REQUEST_TYPE}_${GET_ACTIVITIES_BY_DEAL_ID}`
const RESPONSE_GET_ACTIVITIES_BY_DEAL_ID = `${RESPONSE_TYPE}_${GET_ACTIVITIES_BY_DEAL_ID}`
const RESPONSE_GET_ACTIVITIES_BY_DEAL_ID_ERROR = `${RESPONSE_ERROR_TYPE}_${GET_ACTIVITIES_BY_DEAL_ID}`
const RESET_SEARCH_DATA_STATE = `${RESET_STATE}_${GET_ACTIVITIES_BY_DEAL_ID}`

export default {
  RESET_DATA_STATE,
  REQUEST_ASSIGN_GROUPS,
  RESPONSE_ASSIGN_GROUPS,
  RESPONSE_ASSIGN_GROUPS_ERROR,
  REQUEST_FILL_TICKET_TYPES,
  RESPONSE_FILL_TICKET_TYPES,
  RESPONSE_FILL_TICKET_TYPES_ERROR,
  REQUEST_GET_ACTIVITIES_BY_DEAL_ID,
  RESPONSE_GET_ACTIVITIES_BY_DEAL_ID,
  RESPONSE_GET_ACTIVITIES_BY_DEAL_ID_ERROR,
  RESET_SEARCH_DATA_STATE
}
