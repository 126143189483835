/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { filterActions } from 'redux-ignore'

import {
  DATA_STATE_NOT_REQUESTED,
  DATA_STATE_REQUESTING,
  DATA_STATE_RECIEVED,
  DATA_STATE_ERROR
} from 'helpers/actionHelpers'
import helpers from './helpers'

const {
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_DOWNLOAD,
  RESPONSE_DOWNLOAD,
  RESPONSE_ERROR_DOWNLOAD
} = helpers

export const initialState = {}

export const entityInitialState = {
  dataState: DATA_STATE_NOT_REQUESTED,
  data: [],
  downloadDataState: DATA_STATE_NOT_REQUESTED
}
const buildState = (state, action) => data => ({
  ...state,
  [action.payload.key]: { ...entityInitialState, ...state[action.payload.key], ...data }
})

const listReducer = (state = initialState, action) => {
  const buildListState = buildState(state, action)

  switch (action.type) {
    case REQUEST_LIST:
      return buildListState({ dataState: DATA_STATE_REQUESTING })
    case RESPONSE_LIST:
      return buildListState({
        dataState: DATA_STATE_RECIEVED,
        data: action.payload.data
      })
    case RESPONSE_ERROR_LIST:
      return buildListState({
        dataState: DATA_STATE_ERROR
      })
    case RESET_DATA_STATE: {
      const { [action.payload.key]: prevState, ...restStates } = state
      return { ...restStates }
    }
    // download file
    case REQUEST_DOWNLOAD:
      return buildListState({ downloadDataState: DATA_STATE_REQUESTING })
    case RESPONSE_DOWNLOAD:
      return buildListState({
        downloadDataState: DATA_STATE_RECIEVED
      })
    case RESPONSE_ERROR_DOWNLOAD:
      return buildListState({
        downloadDataState: DATA_STATE_ERROR
      })
    default:
      return state
  }
}

export default filterActions(listReducer, [
  REQUEST_LIST,
  RESPONSE_LIST,
  RESPONSE_ERROR_LIST,
  RESET_DATA_STATE,
  REQUEST_DOWNLOAD,
  RESPONSE_DOWNLOAD,
  RESPONSE_ERROR_DOWNLOAD
])
