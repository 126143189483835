/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import groupBy from 'lodash/groupBy'
import {
  AND_OPERATOR,
  CLOSE_GROUP_OPERATOR,
  OPEN_GROUP_OPERATOR,
  OR_OPERATOR
} from 'constants/search'
import { SET_FILTER, SET_PARAMS, SUBMIT } from './types'

export function init(initialFilters) {
  return { changes: 0, params: {}, filters: initialFilters }
}

export function reducer(state, action) {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        changes: state.changes + 1,
        params: { ...state.params, pageNumber: 1 },
        filters: { ...state.filters, [action.payload.key]: action.payload.value }
      }
    case SET_PARAMS:
      return {
        ...state,
        changes: state.changes + 1,
        params: action.payload
      }
    case SUBMIT:
      return {
        ...state,
        changes: state.changes + 1
      }
    default:
      throw new Error(`Action type [${action.type}] do not exist`)
  }
}

export function getOperator(index, length) {
  return { operator: length === 1 || index === 0 ? AND_OPERATOR : OR_OPERATOR }
}

export function getOperatorIgnore(index, length) {
  if (index === 0) {
    return {}
  }

  return { operator: length === 1 ? AND_OPERATOR : OR_OPERATOR }
}

export function getGroupOperator(index, length) {
  if (length < 2 || (index !== 0 && length - 1 !== index)) {
    return {}
  }

  return {
    groupOperator: index === 0 ? OPEN_GROUP_OPERATOR : CLOSE_GROUP_OPERATOR
  }
}

export function createFilter(value, index, { length }) {
  return {
    ...value,
    ...this.getOperator(index, length),
    ...this.getGroupOperator(index, length)
  }
}

export function createFilterBuilder(fieldFilters, cb = null, hasFirstOperator = true) {
  const filterContext = {
    getOperator: hasFirstOperator ? getOperator : getOperatorIgnore,
    getGroupOperator
  }

  return filters => {
    const columnFilters = Object.values(
      groupBy(
        Object.keys(filters)
          .filter(key => filters[key])
          .map(key => fieldFilters[key])
          .filter(item => item),
        'column'
      )
    ).reduce((acc, group) => [...acc, ...group.map(createFilter.bind(filterContext))], [])

    return cb ? cb(columnFilters) : columnFilters
  }
}
