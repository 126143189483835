/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useContext } from 'react'
import { RateFormContext } from '../types'
import { RateBox } from './RateBox'

function RateListMode() {
  const { rates } = useContext(RateFormContext)

  return (
    <>
      {rates.map(item => (
        <RateBox item={item} key={item.id} />
      ))}
    </>
  )
}

export default RateListMode
