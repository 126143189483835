/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import loadable from '@loadable/component'

const MarketSlider = loadable(
  () => import(/* webpackChunkName: 'MarketSlider', webpackPrefetch: true */ './MarketSlider'),
  {
    resolveComponent: components => components.MarketSlider
  }
)

export { MarketList } from './MarketList'
export { MarketForm } from './MarketForm'
export { MarketSlider }
export { SelectMarketField } from './SelectMarketField'
export * from './helpers'
