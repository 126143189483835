/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { entityInitialState } from './reducer'

export const getEligibilityFormState = state => state.eligibility.eligibilityForm

export const getEligibilityStateById = (state, id) => {
  const eligibilityState = getEligibilityFormState(state)
  if (!id) {
    return eligibilityState.new || entityInitialState
  }
  return eligibilityState[id] || entityInitialState
}

export const getFormCloseDataStates = (state, id) => {
  const eligibilityState = getEligibilityStateById(state, id)
  return [eligibilityState.dataState]
}
