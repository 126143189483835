/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useLockBodyScroll } from 'hooks'
import { SimpleModalWrapper } from 'components/SimpleModalWrapper'
import { CloseIcon } from 'icons'
import { TITLE } from './types'
import styles from './BasicModal.scss'

export function BasicModal(props) {
  const {
    isOpen,
    onClose,
    children,
    message,
    title,
    wrapperClassName,
    renderBody,
    footerClassName,
    showCloseIcon
  } = props

  useLockBodyScroll(isOpen)

  return isOpen ? (
    <SimpleModalWrapper cancelHandler={onClose}>
      <div className={classNames(styles.wrapper, wrapperClassName)}>
        <div className={styles.header}>
          <span className={styles.title}>{title}</span>
          {showCloseIcon && <CloseIcon dynamicClassName={styles.icon} dynamicEvent={onClose} />}
        </div>
        {renderBody ? renderBody() : <div className={styles.body}>{message}</div>}
        {children && <div className={footerClassName}>{children}</div>}
      </div>
    </SimpleModalWrapper>
  ) : null
}

BasicModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  wrapperClassName: PropTypes.string,
  renderBody: PropTypes.func,
  footerClassName: PropTypes.string,
  showCloseIcon: PropTypes.bool
}
BasicModal.defaultProps = {
  title: TITLE,
  children: null,
  wrapperClassName: null,
  renderBody: null,
  footerClassName: styles.buttons,
  showCloseIcon: true
}
