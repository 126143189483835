/*
 * COPYRIGHT:     Copyright © 2018 - 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'

import styles from './style.scss'

export const SimpleRadio = props => {
  const { mainValue, value, onChange, ...rest } = props
  const isChecked = value === mainValue
  return (
    <input
      {...rest}
      className={styles.radio}
      type="radio"
      value={value}
      onChange={onChange}
      checked={isChecked}
    />
  )
}

SimpleRadio.propTypes = {
  mainValue: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
}
