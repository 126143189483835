/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const TITLE = 'Warning'

export const WARNING_MESSAGE =
  'This Destination has assigned Campaign(s). Please, update or deactivate Campaign(s) before making changes to this Destination.'
