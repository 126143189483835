/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import get from 'lodash/get'
import { useContext } from 'react'
import {
  convertDates,
  createDisabledDays,
  createMatchAfter,
  createMatchBefore,
  getDisabledRanges
} from './helpers'
import { RateFormContext } from '../types'

export const useCalendarValidation = index => {
  const { formData, formApi } = useContext(RateFormContext)
  const rates = formApi.getValue('rates') || []
  const currentItem = get(rates, index)

  const disabledRanges = getDisabledRanges(convertDates(rates), currentItem)

  // Disable Season Dates in Calendar
  disabledRanges.push({
    ...createMatchBefore(get(formData, 'startDate')),
    ...createMatchAfter(get(formData, 'endDate'))
  })

  const startDateProps = createDisabledDays(
    createMatchAfter(get(currentItem, 'endDate')),
    disabledRanges
  )
  const endDateProps = createDisabledDays(
    createMatchBefore(get(currentItem, 'startDate')),
    disabledRanges
  )

  return [startDateProps, endDateProps]
}
