/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Column.scss'

export function Column(props) {
  const { children } = props

  return <div className={classNames(styles.columnWrapper)}>{children}</div>
}

Column.propTypes = {
  children: PropTypes.node.isRequired
}
