/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { BoxColumns } from 'components/FormComponents'
import { DayPickerSeasons } from 'modules/Programs/SeasonsDatesPicker'
import { buildAdditionProps } from 'modules/Programs/SeasonsDatesPicker/helpers'
import { SeasonsCollectionIssues } from './SeasonsCollectionIssues'

import { FootnoteBoard } from './FootnoteBoard'
import { FOOTNOTE_SELECTED_POINT, FOOTNOTE_WRONG_SELECTED_POINT } from './types'
import { buildErrors, builldDayPickerDates } from './helpers'
import styles from './SeasonsValidateBoard.scss'

const SeasonsValidateBoardInfo = props => {
  const { data } = props
  const dayPickerProps = buildAdditionProps(builldDayPickerDates(data))
  const errors = buildErrors(data.errors)
  return (
    <>
      <BoxColumns>
        <div className={styles.calendarWrapper}>
          <DayPickerSeasons {...dayPickerProps} />
        </div>
        <>
          {errors.length > 0 && <SeasonsCollectionIssues errors={errors} />}
          <FootnoteBoard
            classNamesChild={`has-text-white ${styles.ErrorgDate}`}
            message={FOOTNOTE_WRONG_SELECTED_POINT}
          />
          <FootnoteBoard
            classNamesChild={`has-text-white ${styles.SelectedDate}`}
            message={FOOTNOTE_SELECTED_POINT}
          />
        </>
      </BoxColumns>
    </>
  )
}
SeasonsValidateBoardInfo.propTypes = {
  data: PropTypes.object
}
SeasonsValidateBoardInfo.defaultProps = {
  data: {}
}
export { SeasonsValidateBoardInfo }
