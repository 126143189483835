/*
 * COPYRIGHT:     Copyright © 2018 - 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { actionCreater } from 'helpers/actionHelpers'
import { getList } from 'services/http/account'

import helpers from './helpers'

const { REQUEST_LIST, RESPONSE_LIST, RESPONSE_ERROR_LIST, RESET_DATA_STATE } = helpers

const getAccountList = (options = {}, filters = []) => async dispatch => {
  dispatch(actionCreater(REQUEST_LIST))
  const request = await getList(options, filters).catch(({ send, message }) => {
    dispatch(send(message))
    dispatch(actionCreater(RESPONSE_ERROR_LIST))
  })
  if (request) {
    dispatch(actionCreater(RESPONSE_LIST, { ...request.data }))
    return 'getAccountList success'
  }

  return 'getAccountList failed'
}

const resetList = () => ({
  type: RESET_DATA_STATE
})

export default {
  getAccountList,
  resetList
}
