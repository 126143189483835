/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ExtendedEligibilityForm } from 'modules/Eligibility'
import { canUpdateEligibilityProgram } from 'modules/Programs'
import { PIECurtainLoader, BouncingLoader } from 'components/Loaders'
import { useDidMount, useLoading, useUpdateSomeDataState } from 'hooks'
import { BasicButton } from '@xplorie/ui-commons'
import get from 'lodash/get'
import { DATA_STATE_RECIEVED } from 'helpers/actionHelpers'
import { ORANGE_SCHEMA, SUBMIT_TITLE } from 'constants/buttonsOptions'
import { buildContract } from './helpers'
import { TABLE_NAME, SELECT_PARAMETER_LABEL } from './types'

import styles from './ProgramEligibilityForm.scss'

export function ProgramEligibilityForm({
  authUser,
  getEligibilityParamsListByProgramId,
  getEligibilityValuesListByProgramId,
  data,
  dataId,
  formDataState,
  programValuesDataState,
  programParamsDataState,
  eligibilityParams,
  updateEligibilityParamsByProgram,
  resetListParams,
  resetListValues,
  onChangeFormState,
  programData
}) {
  const [isFormInvalid, setIsFormInvalidState] = useState(false)
  const tableApi = useRef()
  const valuesRef = useRef([])
  const canUpdate = canUpdateEligibilityProgram(authUser, programData)
  const [isFormSubmiting] = useLoading(formDataState)
  const [isParametersLoading, { isLoaded: isParametersLoaded }] = useLoading(programParamsDataState)
  const [isValuesLoading, { isLoaded }] = useLoading(programValuesDataState)

  const isDisabled = !canUpdate || !isLoaded || isParametersLoading

  const isShowCurtainLoader = isFormSubmiting

  const onSubmitClickHandler = useCallback(() => {
    tableApi.current.submit()
  }, [])

  const onGetTableApi = useCallback(api => {
    tableApi.current = api
  }, [])

  const onTableChangeHandler = useCallback(
    tableState => {
      setIsFormInvalidState(tableState.invalid)

      const hasChanged =
        (get(tableState, 'values.values') &&
          valuesRef.current.length !== Object.keys(tableState.values.values).length) ||
        Object.keys(tableState.touched).length > 0

      onChangeFormState(!hasChanged)

      setIsFormInvalidState(tableState.invalid || !hasChanged)
    },
    [onChangeFormState]
  )

  const onSubmitHandler = useCallback(
    formValues => {
      updateEligibilityParamsByProgram(dataId, buildContract(formValues))
    },
    [dataId, updateEligibilityParamsByProgram]
  )

  const onDeleteHandler = useCallback(id => {
    tableApi.current.removeParameterById(id)
  }, [])

  useDidMount(() => {
    if (dataId) {
      if (canUpdate) {
        getEligibilityParamsListByProgramId(dataId)
      }
      getEligibilityValuesListByProgramId(dataId)
    }
    return () => {
      resetListParams(dataId)
      resetListValues(dataId)
    }
  })

  useUpdateSomeDataState(
    () => {
      tableApi.current.reset()
      tableApi.current.setValues(data)
    },
    [programValuesDataState],
    DATA_STATE_RECIEVED
  )

  useUpdateSomeDataState(
    () => {
      if (dataId) {
        getEligibilityValuesListByProgramId(dataId)
      }
    },
    [formDataState],
    DATA_STATE_RECIEVED
  )

  useEffect(() => {
    valuesRef.current = data.values
  })

  return (
    <div className={styles.formWrapper}>
      {isValuesLoading && <BouncingLoader />}
      {isShowCurtainLoader && <PIECurtainLoader />}
      <ExtendedEligibilityForm
        name={TABLE_NAME}
        eligibilityParams={eligibilityParams}
        selectProps={{ placeholder: SELECT_PARAMETER_LABEL, disabled: isParametersLoading }}
        getApi={onGetTableApi}
        showSelect={canUpdate}
        disabled={isDisabled}
        onChange={onTableChangeHandler}
        isHidden={!isLoaded}
        onSubmit={onSubmitHandler}
        onDelete={onDeleteHandler}
        showLevelColumn
      />
      {isLoaded && isParametersLoaded && (
        <div className={styles.buttons}>
          <BasicButton
            colorSchema={ORANGE_SCHEMA}
            onClick={onSubmitClickHandler}
            disabled={isDisabled || isFormInvalid}
          >
            {SUBMIT_TITLE}
          </BasicButton>
        </div>
      )}
    </div>
  )
}
ProgramEligibilityForm.propTypes = {
  authUser: PropTypes.object.isRequired,
  getEligibilityParamsListByProgramId: PropTypes.func.isRequired,
  getEligibilityValuesListByProgramId: PropTypes.func.isRequired,
  formDataState: PropTypes.string.isRequired,
  programValuesDataState: PropTypes.string.isRequired,
  programParamsDataState: PropTypes.string.isRequired,
  eligibilityParams: PropTypes.array.isRequired,
  updateEligibilityParamsByProgram: PropTypes.func.isRequired,
  resetListParams: PropTypes.func.isRequired,
  resetListValues: PropTypes.func.isRequired,
  dataId: PropTypes.string.isRequired,
  deleteParameterDataState: PropTypes.string.isRequired,
  onChangeFormState: PropTypes.func.isRequired,
  programData: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
}
