/*
 * COPYRIGHT:     Copyright © 2018 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import isArray from 'lodash/isArray'
import PropTypes from 'prop-types'
import get from 'lodash/get'

export function APDestinationColumn(props) {
  const destinations = get(props, 'value', [])
  return (
    <div>
      {destinations &&
        isArray(destinations) &&
        destinations.map((value, index) => {
          const id = get(value, 'id', index)
          const name = get(value, 'name', null)
          return <div key={id}>{name}</div>
        })}
    </div>
  )
}
APDestinationColumn.propTypes = {
  value: PropTypes.array
}
APDestinationColumn.defaultProps = {
  value: []
}
