/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'

export function CrossIcon(props) {
  return (
    <svg width="100" height="100" x="0px" y="0px" viewBox="0 0 100 100" {...props}>
      <ellipse
        ry="35.5"
        rx="35.5"
        cy="249.64"
        cx="10.84"
        strokeWidth="1.5"
        transform="matrix(1.26,0,0,1.26,36.35,-264.34)"
      />
      <path
        stroke="null"
        d="m70.23,61.75c0,0.81 -0.34,1.62 -0.95,2.21l-4.63,4.42c-0.61,0.58 -1.46,0.91 -2.32,0.91s-1.70,-0.32 -2.32,-0.91l-10.02,-9.55l-10.02,9.55c-0.61,0.58 -1.46,0.91 -2.32,0.91s-1.70,-0.32 -2.32,-0.91l-4.63,-4.42c-0.61,-0.58 -0.95,-1.40 -0.95,-2.21s0.34,-1.62 0.95,-2.21l10.02,-9.55l-10.02,-9.55c-0.61,-0.58 -0.95,-1.40 -0.95,-2.21s0.34,-1.62 0.95,-2.21l4.63,-4.42c0.61,-0.58 1.46,-0.91 2.32,-0.91s1.70,0.32 2.32,0.91l10.02,9.55l10.02,-9.55c0.61,-0.58 1.46,-0.91 2.32,-0.91s1.70,0.32 2.32,0.91l4.63,4.42c0.61,0.58 0.95,1.40 0.95,2.21s-0.34,1.62 -0.95,2.21l-10.02,9.55l10.02,9.55c0.61,0.58 0.95,1.40 0.95,2.21z"
      />
    </svg>
  )
}
