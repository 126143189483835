/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
export const MODAL_TITLE = 'Warning!'

export const WARNING_MESSAGE = 'Are you sure you want to Deactivate '

export const SELECT_DATE_DEACTIVATE_MESSAGE = 'Please select a date when you want the '
export const BECOME_INACTIVE_MESSAGE =
  ' Activity Provider and its relative APPs to become inactive.'

export const DATE_INPUT_LABEL = 'Deactivation Date'
export const DATE_INPUT_PLACEHOLDER = 'Deactivation Date'

export const DATE_FIELD = 'date'

export const PICK_TODAY_BUTTON_TEXT = 'Today’s date'
export const DEACTIVATE_TEXT = 'Deactivate'
export const DECLINE_TEXT = 'Decline'
export const UPDATE_TEXT = 'Update'
export const CANCEL_TEXT = 'Cancel Deactivation'
export const RELATED_ENTITIES_BUTTON_TEXT = 'Related APPs'

// steps
export const CONFIRMATION_STEP = 1
export const DEACTIVATE_STEP = 2

export const MODAL_STEPS_COUNT = 2
