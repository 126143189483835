/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

export const FORM_NAME = 'DEAL_FORM_ACTIVITIES'
export const UPDATE_ACTIVITY = `${FORM_NAME}_UPDATE_ACTIVITY`
export const UPDATE_ACTIVITY_TYPE = `${FORM_NAME}_UPDATE_ACTIVITY_TYPE`
export const DELETE_ACTIVITY = `${FORM_NAME}_DELETE_ACTIVITY`
export const CHANGE_EDIT_MODE = `${FORM_NAME}_CHANGE_EDIT_MODE`

export default {
  FORM_NAME,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_TYPE,
  DELETE_ACTIVITY,
  CHANGE_EDIT_MODE
}
