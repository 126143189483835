/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDidMount, useDataStatesComparison, useLoading } from 'hooks'
import { JustTable } from '@xplorie/ui-commons'
import { DATA_STATE_ERROR } from 'helpers/actionHelpers'
import { RefreshIcon } from 'icons'
import { BouncingLoader, PIECurtainLoader } from 'components/Loaders'
import { buildRows, buildColumns } from './tableHelpers'
import { TABLE_HEADER } from './types'

import styles from './GuestsList.scss'

export function GuestsList(props) {
  const {
    dataState,
    data,
    getGuestsFileList,
    resetList,
    dataId,
    listWatchers,
    downloadFile,
    downloadDataState
  } = props
  const [isDownloading] = useLoading(downloadDataState)
  const [, { isLoaded, isError }] = useLoading(dataState)
  const showLoading = dataId && (!isLoaded && !isError)

  const getFileList = useCallback(() => {
    if (dataId) {
      getGuestsFileList(dataId)
    }
  }, [dataId, getGuestsFileList])

  const onClickDownloadHandler = useCallback(
    fileId => {
      if (dataId) {
        downloadFile(dataId, fileId)
      }
    },
    [dataId, downloadFile]
  )

  useDidMount(() => {
    getFileList()
    return () => {
      resetList(dataId)
    }
  })

  // refresh list after success uploading
  useDataStatesComparison(() => {
    getFileList()
  }, listWatchers)

  // refresh list after uploading with error
  useDataStatesComparison(
    () => {
      getFileList()
    },
    listWatchers,
    DATA_STATE_ERROR
  )

  const rows = useMemo(() => buildRows(), [])
  const columns = useMemo(() => buildColumns({ onDelete: onClickDownloadHandler }), [
    onClickDownloadHandler
  ])

  if (showLoading) {
    return <BouncingLoader />
  }

  return (
    <>
      <JustTable
        title={TABLE_HEADER}
        data={data}
        columns={columns}
        rows={rows}
        HeaderControl={<RefreshIcon className={styles.refreshIcon} onClick={getFileList} />}
      />
      {isDownloading && <PIECurtainLoader />}
    </>
  )
}

GuestsList.propTypes = {
  dataState: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  getGuestsFileList: PropTypes.func.isRequired,
  dataId: PropTypes.string.isRequired,
  listWatchers: PropTypes.array.isRequired,
  value: PropTypes.any.isRequired,
  original: PropTypes.object,
  resetList: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  downloadDataState: PropTypes.string.isRequired
}
GuestsList.defaultProps = {
  original: {}
}
