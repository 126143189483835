/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import { combineReducers } from 'redux'
import dealForm from './dealForm'
import dealsList from './dealsList'
import dealFormGroups from './dealFormGroups'
import dealFormActivities from './dealFormActivities'
import activityDealsList from './activityDealsList'
import extendedDealsList from './extendedDealsList'
import dealCommon from './dealCommon'
import dealsListByActivity from './dealsListByActivity'
import programDiscountsList from './programDiscountsList'
import discountHistory from './discountHistory'

export default combineReducers({
  dealForm,
  dealsList,
  dealFormGroups,
  dealFormActivities,
  activityDealsList,
  extendedDealsList,
  dealCommon,
  dealsListByActivity,
  programDiscountsList,
  discountHistory
})
