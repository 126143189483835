/*
 * COPYRIGHT:     Copyright © 2020 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */
import { listInitialState } from './reducer'

export function getProgramEligibilityParamListState(state) {
  return state.eligibility.programEligibilityParamList
}
export function getEligibilityParamListByProgramId(state, id) {
  return getProgramEligibilityParamListState(state)[id] || listInitialState
}
