/*
 * COPYRIGHT:     Copyright © 2019 Xplorie LLC
 * Warning:       This product is protected by United States and international copyright laws.
 *                Unauthorized use or duplication of this software, in whole or in part, is prohibited.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { DATA_STATE_ERROR } from 'helpers/actionHelpers'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { AuthContext } from 'context'
import { useUpdateSomeDataState } from 'hooks'
import { SlideItem } from './SlideItem'
import styles from './SliderContainer.scss'

export function SliderContainer(props) {
  const {
    panelStack,
    authUser,
    authDataState,
    activePanel,
    dequeue,
    resetAllSlides,
    updateSlide
  } = props
  const sliders = Object.keys(panelStack)

  const onDequeueHandler = id => {
    dequeue(id)
  }

  const onSetHeaderPropsHandler = id => (
    headerProps = { title: null, subtitle: null, info: null }
  ) => {
    updateSlide(id, {
      headerProps
    })
  }

  useUpdateSomeDataState(
    () => {
      resetAllSlides()
    },
    [authDataState],
    DATA_STATE_ERROR
  )

  return (
    <AuthContext.Provider value={authUser}>
      <div className={styles.slideContainerWrapper}>
        <TransitionGroup>
          {sliders.map((slideKey, index) => {
            const { id, component: SlideContent, props: formProps, headerProps } = panelStack[
              slideKey
            ]
            const isLast = index === sliders.length - 1

            return (
              <CSSTransition key={id} classNames="slide" timeout={{ enter: 400, exit: 400 }}>
                <>
                  {isLast && <div className="curtain" />}
                  <SlideItem
                    id={id}
                    positionNumber={index}
                    SlideContent={SlideContent}
                    headerProps={headerProps}
                    formProps={formProps}
                    activePanel={activePanel}
                    onClose={onDequeueHandler}
                    onSetHeaderProps={onSetHeaderPropsHandler}
                    sliders={sliders}
                  />
                </>
              </CSSTransition>
            )
          })}
        </TransitionGroup>
      </div>
    </AuthContext.Provider>
  )
}

SliderContainer.propTypes = {
  panelStack: PropTypes.object,
  updateSlide: PropTypes.func.isRequired,
  dequeue: PropTypes.func.isRequired,
  authUser: PropTypes.object,
  activePanel: PropTypes.string,
  resetAllSlides: PropTypes.func.isRequired,
  authDataState: PropTypes.string.isRequired
}

SliderContainer.defaultProps = {
  panelStack: {},
  authUser: null,
  activePanel: null
}
